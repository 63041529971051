import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ApAdvanceEntity = AuditEntity & {
  __typename?: 'APAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ApAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ApAllocationEntity = AuditEntity & {
  __typename?: 'APAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  nonEditable: Scalars['Boolean'];
};

export type ApCreditNoteEntity = AuditEntity & {
  __typename?: 'APCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type ApCreditNoteEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApCreditNoteItemEntity = AuditEntity & {
  __typename?: 'APCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  APCreditNoteID: Scalars['String'];
  APCreditNote?: Maybe<ApCreditNoteEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  APCreditNoteTaxItem?: Maybe<Array<ApCreditNoteTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApCreditNoteItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApCreditNoteTaxItemEntity = AuditEntity & {
  __typename?: 'APCreditNoteTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  APCreditNoteItemID: Scalars['String'];
  APCreditNoteItem?: Maybe<ApCreditNoteItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type ApDebitNoteEntity = AuditEntity & {
  __typename?: 'APDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type ApDebitNoteEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApDebitNoteItemEntity = AuditEntity & {
  __typename?: 'APDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  APDebitNoteID: Scalars['String'];
  APDebitNote?: Maybe<ApDebitNoteEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  APDebitNoteTaxItem?: Maybe<Array<ApDebitNoteTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApDebitNoteItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApDebitNoteTaxItemEntity = AuditEntity & {
  __typename?: 'APDebitNoteTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  APDebitNoteItemID: Scalars['String'];
  APDebitNoteItem?: Maybe<ApDebitNoteItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type ApeInvoiceTransactionEntity = AuditEntity & {
  __typename?: 'APEInvoiceTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
};

export type ApInvoiceEntity = AuditEntity & {
  __typename?: 'APInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  discountAmt: Scalars['Float'];
  otherChargeAmt: Scalars['Float'];
  /** CustomFieldResolver */
  invoiceAmt?: Maybe<Scalars['Float']>;
  grnMatch?: Maybe<Array<Scalars['JSON']>>;
  supplierAllocation: Array<ApAllocationEntity>;
  APInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  pendingPayment?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  invoiceDo?: Maybe<Array<DoEntity>>;
  /** CustomFieldResolver */
  outstanding?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type ApInvoiceEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApInvoiceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  isEInvoice?: Maybe<Scalars['Boolean']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<ApTransactionItemInput>>;
  discountAmt?: Maybe<Scalars['Float']>;
  otherChargeAmt?: Maybe<Scalars['Float']>;
  doAmt?: Maybe<Scalars['Float']>;
};

export type ApInvoiceItemEntity = AuditEntity & {
  __typename?: 'APInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  APInvoiceID: Scalars['String'];
  APInvoice?: Maybe<ApInvoiceEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  doItemID?: Maybe<Scalars['String']>;
  doItem?: Maybe<DoItemEntity>;
  withDO: Scalars['Boolean'];
  APInvoiceTaxItem?: Maybe<Array<ApInvoiceTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApInvoiceItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApInvoiceItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wbsID?: Maybe<Scalars['String']>;
  costClass?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  doID?: Maybe<Scalars['String']>;
  doItemID?: Maybe<Scalars['String']>;
};

export type ApInvoiceTaxItemEntity = AuditEntity & {
  __typename?: 'APInvoiceTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  APInvoiceItemID: Scalars['String'];
  APInvoiceItem?: Maybe<ApInvoiceItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type ApPaymentEntity = AuditEntity & {
  __typename?: 'APPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  bankAccount?: Maybe<BankAccountEntity>;
  supplierID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierEntity>;
  supplierAllocation: Array<ApAllocationEntity>;
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ApPaymentEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ApPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type ApRefundEntity = AuditEntity & {
  __typename?: 'APRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
};

export type ApTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  isEInvoice?: Maybe<Scalars['Boolean']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<ApTransactionItemInput>>;
};

export type ApTransactionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wbsID?: Maybe<Scalars['String']>;
  costClass?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
};

export type AccountSummary = {
  __typename?: 'AccountSummary';
  VOSum: Scalars['String'];
  claimSum: Scalars['String'];
  originalClaimSum: Scalars['String'];
  adjustmentSum: Scalars['String'];
  retentionSum: Scalars['String'];
  contractSum: Scalars['String'];
  progressRetention: Scalars['String'];
  maxRetention: Scalars['String'];
  expectedRetentionReleaseDate: Scalars['String'];
  cpcDate: Scalars['String'];
  cmgdDate: Scalars['String'];
  penaltyAmt: Scalars['String'];
  revisedContractSum: Scalars['String'];
  billtoDate: Scalars['String'];
  maxRetentionAmt: Scalars['String'];
  balancetoClaim: Scalars['String'];
  name: Scalars['String'];
  retentionReleaseSum: Scalars['String'];
  taxCode: Scalars['String'];
  customerID: Scalars['String'];
};

export type AccountXUserEntity = AuditEntity & {
  __typename?: 'AccountXUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  remarks?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ActivityLogInput = {
  remarks?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum ActivityStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Closed = 'CLOSED'
}

/** JsonType */
export type Address = {
  __typename?: 'Address';
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AdjustmentItem = AuditEntity & {
  __typename?: 'AdjustmentItem';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
};

export type AdjustmentTaxItem = AuditEntity & {
  __typename?: 'AdjustmentTaxItem';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
};

export type AdjustmentTypeEntity = AuditEntity & {
  __typename?: 'AdjustmentTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  commonStatus: CommonStatus;
  glAdjustmentDynamic?: Maybe<Array<GlAdjustmentDynamicEntity>>;
  contractDn?: Maybe<Array<ContractDnEntity>>;
  contractCn?: Maybe<Array<ContractCnEntity>>;
  clientDn?: Maybe<Array<ClientDnEntity>>;
  clientCn?: Maybe<Array<ClientCnEntity>>;
  subcontractDn?: Maybe<Array<SubcontractDnEntity>>;
  subcontractCn?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcon?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcon?: Maybe<Array<CNtoSubconEntity>>;
  apDn?: Maybe<Array<ApDebitNoteEntity>>;
  apCn?: Maybe<Array<ApCreditNoteEntity>>;
  dnToSupplier?: Maybe<Array<DNtoSupplierEntity>>;
  cnToSupplier?: Maybe<Array<CNtoSupplierEntity>>;
};

export type AdjustmentTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
};

export type AlertAssignmentEntity = AuditEntity & {
  __typename?: 'AlertAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  alertID: Scalars['String'];
  alert?: Maybe<AlertEntity>;
  userID: Scalars['String'];
  reminder?: Maybe<Scalars['String']>;
  isAdminControl: Scalars['Boolean'];
};

export type AlertAssignmentInput = {
  alertID: Scalars['String'];
  userID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  reminder?: Maybe<Scalars['String']>;
};

export type AlertCountInput = {
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sqlCode: Scalars['String'];
};

export type AlertEntity = AuditEntity & {
  __typename?: 'AlertEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sqlCode: Scalars['String'];
  commonStatus: CommonStatus;
  alertAssignment?: Maybe<Array<AlertAssignmentEntity>>;
  /** CustomFieldResolver */
  defaultFromAdmin?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  alertCount?: Maybe<Scalars['Float']>;
};

export type AlertInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  userID: Scalars['String'];
  reminder: Scalars['String'];
};

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  created_ts: Scalars['DateTime'];
  created_by?: Maybe<Scalars['String']>;
  mod_ts: Scalars['DateTime'];
  mod_by?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export enum AllocationStatus {
  Credit = 'CREDIT',
  Retention = 'RETENTION',
  Pending = 'PENDING'
}

export enum ApprovalLevel {
  First = 'FIRST',
  Second = 'SECOND',
  Third = 'THIRD',
  Fourth = 'FOURTH',
  Fifth = 'FIFTH'
}

export type ApprovalList = {
  __typename?: 'ApprovalList';
  stepNo?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ApprovalListInput = {
  stepNo?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ApprovalLog = {
  __typename?: 'ApprovalLog';
  approvalID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ApprovalLogInput = {
  approvalID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ApprovalPolicyAssignmentDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  listlength?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentEntity = AuditEntity & {
  __typename?: 'ApprovalPolicyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalPolicyID: Scalars['String'];
  approvalPolicy?: Maybe<ApprovalPolicyEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  approvalList?: Maybe<Array<Scalars['JSON']>>;
  category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  latestLimitAssignment: Scalars['JSON'];
};

export type ApprovalPolicyAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  approvalPolicyID?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalList: Array<ApprovalPolicyList>;
  contractID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  category?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyEntity = AuditEntity & {
  __typename?: 'ApprovalPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalList?: Maybe<Array<Scalars['JSON']>>;
  category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  latestLimit: Scalars['JSON'];
};

export type ApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  category?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalList: Array<ApprovalPolicyList>;
};

export type ApprovalPolicyList = {
  stepNo?: Maybe<Scalars['Float']>;
  predicate?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export enum ApprovalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE',
  Cancelled = 'CANCELLED'
}

export type ApprovePaymentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associateID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  batchPaymentDetailInput: Array<BatchPaymentDetailInput>;
};

export type ApprovedVendorsEntity = AuditEntity & {
  __typename?: 'ApprovedVendorsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierName: Scalars['String'];
  registrationNo: Scalars['String'];
  description: Scalars['String'];
  address: Scalars['JSON'];
  email: Scalars['String'];
  gstRegNo: Scalars['String'];
  gstExpDate: Scalars['String'];
  bankDetails?: Maybe<Scalars['JSON']>;
  ratingType: RatingType;
  taxSchemeID: Scalars['String'];
  taxDetail?: Maybe<ConTaxSchemeEntity>;
};

export enum AsgMainStatus {
  Active = 'ACTIVE',
  Returned = 'RETURNED',
  PartiallyReturned = 'PARTIALLY_RETURNED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type AssignmentEntity = AuditEntity & {
  __typename?: 'AssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  plantMachineryID: Scalars['String'];
  plantMachinery?: Maybe<PlantMachineryEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  description?: Maybe<Scalars['String']>;
  assignedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  assignmentDate: Scalars['DateTime'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  trackingLog?: Maybe<Array<Scalars['JSON']>>;
  assignmentRate: RateUomType;
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  assignmentStatus: AsgMainStatus;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type AssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  assignedQty?: Maybe<Scalars['Float']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  cost?: Maybe<Scalars['Float']>;
  assignmentRate: RateUomType;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type AssignmentReturnInput = {
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
};

export type AssociatedDetails = {
  __typename?: 'AssociatedDetails';
  revisedBudgetSubcontractSum?: Maybe<Scalars['Float']>;
  subcontCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetMaterialSum?: Maybe<Scalars['Float']>;
  materialCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetPlantSum?: Maybe<Scalars['Float']>;
  plantCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetLabourSum?: Maybe<Scalars['Float']>;
  labourCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetMiscSum?: Maybe<Scalars['Float']>;
  miscCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetTotalSum?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  totalActualCost?: Maybe<Scalars['Float']>;
  latestOriginalDate?: Maybe<Scalars['DateTime']>;
  latestRevisedDate?: Maybe<Scalars['DateTime']>;
  latestActualCostDate?: Maybe<Scalars['DateTime']>;
  originalBudgetTotalSum?: Maybe<Scalars['Float']>;
  budgetVarianceAmt?: Maybe<Scalars['Float']>;
  origMaterialItemBudget?: Maybe<Scalars['JSON']>;
  origSubconItemBudget?: Maybe<Scalars['JSON']>;
  origPlantItemBudget?: Maybe<Scalars['JSON']>;
  origLabourItemBudget?: Maybe<Scalars['JSON']>;
  origMiscItemBudget?: Maybe<Scalars['JSON']>;
  revMaterialItemBudget?: Maybe<Scalars['JSON']>;
  revSubconItemBudget?: Maybe<Scalars['JSON']>;
  revPlantItemBudget?: Maybe<Scalars['JSON']>;
  revLabourItemBudget?: Maybe<Scalars['JSON']>;
  revMiscItemBudget?: Maybe<Scalars['JSON']>;
  doSumPerCC?: Maybe<Scalars['JSON']>;
  originalBudgetLabourSum?: Maybe<Scalars['Float']>;
  originalBudgetMaterialSum?: Maybe<Scalars['Float']>;
  originalBudgetMiscSum?: Maybe<Scalars['Float']>;
  originalBudgetPlantSum?: Maybe<Scalars['Float']>;
  originalBudgetSubcontractSum?: Maybe<Scalars['Float']>;
  transferredSubconBudget?: Maybe<Scalars['Float']>;
  transferredMaterialBudget?: Maybe<Scalars['Float']>;
  transferredLabourBudget?: Maybe<Scalars['Float']>;
  transferredPlantBudget?: Maybe<Scalars['Float']>;
  transferredMiscBudget?: Maybe<Scalars['Float']>;
  subconCommCost?: Maybe<Scalars['Float']>;
  subContractCommittedCost?: Maybe<Scalars['Float']>;
  subconBalCommCost?: Maybe<Scalars['Float']>;
  materialCommCost?: Maybe<Scalars['Float']>;
  labourCommCost?: Maybe<Scalars['Float']>;
  plantCommCost?: Maybe<Scalars['Float']>;
  miscCommCost?: Maybe<Scalars['Float']>;
  subconCostToDate?: Maybe<Scalars['Float']>;
  materialCostToDate?: Maybe<Scalars['Float']>;
  plantCostToDate?: Maybe<Scalars['Float']>;
  labourCostToDate?: Maybe<Scalars['Float']>;
  miscCostToDate?: Maybe<Scalars['Float']>;
  earnedValue?: Maybe<Scalars['Float']>;
  costVariance?: Maybe<Scalars['Float']>;
  cpi?: Maybe<Scalars['Float']>;
  completionPerc?: Maybe<Scalars['Float']>;
};

export enum AssociatedStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blacklisted = 'BLACKLISTED'
}

export enum AssociationType {
  ClientAccount = 'CLIENT_ACCOUNT',
  SubcontractorAccount = 'SUBCONTRACTOR_ACCOUNT',
  SupplierAccount = 'SUPPLIER_ACCOUNT',
  ProjectPurchasing = 'PROJECT_PURCHASING',
  PurchasingOnBehalf = 'PURCHASING_ON_BEHALF'
}

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type BqEntity = AuditEntity & {
  __typename?: 'BQEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  billNo?: Maybe<Scalars['Float']>;
  pageNo?: Maybe<Scalars['Float']>;
  itemNo?: Maybe<Scalars['Float']>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  description: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  isLastNode: Scalars['Boolean'];
  bqWbsAssignment?: Maybe<Array<BqWbsAssignmentEntity>>;
  childrenBq?: Maybe<Array<BqEntity>>;
  parentBq?: Maybe<BqEntity>;
  /** CustomFieldResolver */
  numOfWbsBudgetDetail?: Maybe<Scalars['Float']>;
};


export type BqEntityChildrenBqArgs = {
  wbsID?: Maybe<Scalars['String']>;
};

export type BqExcelInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  billNo?: Maybe<Scalars['Float']>;
  pageNo?: Maybe<Scalars['Float']>;
  itemNo?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  rowNo?: Maybe<Scalars['Float']>;
  wbsName?: Maybe<Scalars['String']>;
  uomName?: Maybe<Scalars['String']>;
};

export type BqInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  billNo?: Maybe<Scalars['Float']>;
  pageNo?: Maybe<Scalars['Float']>;
  itemNo?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  rowNo?: Maybe<Scalars['Float']>;
};

export type BqWbsAssignmentEntity = AuditEntity & {
  __typename?: 'BQWbsAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  bqID: Scalars['String'];
  bq?: Maybe<BqEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
};

export type BqWbsAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  bqID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
};

export type BankAccountCancellationInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BankAccountDeleteInput = {
  ID: Scalars['String'];
};

export type BankAccountEntity = AuditEntity & {
  __typename?: 'BankAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  branch: Scalars['String'];
  swiftCode?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  status: BankAccountStatus;
  address: Scalars['JSON'];
  contactPerson: Array<Scalars['JSON']>;
  cancellationDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  contract?: Maybe<Array<ContractEntity>>;
  contractReceipt?: Maybe<Array<ContractReceiptEntity>>;
  contractAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  contractRefund?: Maybe<Array<ContractRefundEntity>>;
  subcontractAdvance?: Maybe<Array<SubcontractAdvanceEntity>>;
  subcontractPayment?: Maybe<Array<SubcontractPaymentEntity>>;
  subcontractRefund?: Maybe<Array<SubcontractRefundEntity>>;
  APAdvance?: Maybe<Array<ApAdvanceEntity>>;
  APPayment?: Maybe<Array<ApPaymentEntity>>;
  batchPaymentHeader?: Maybe<Array<BatchPaymentHeaderEntity>>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type BankAccountInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  contactNo: Scalars['String'];
  companyID: Scalars['String'];
  branch: Scalars['String'];
  swiftCode?: Maybe<Scalars['String']>;
  address: AddressInput;
  contactPerson: Array<ContactPersonInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum BankAccountStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

/** JsonType */
export type BankDetails = {
  __typename?: 'BankDetails';
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BaseFuelInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BatchPaymentDetailDeleteInput = {
  ID: Scalars['String'];
};

export type BatchPaymentDetailEntity = AuditEntity & {
  __typename?: 'BatchPaymentDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  batchPaymentHeaderID: Scalars['String'];
  batchPaymentHeader?: Maybe<BatchPaymentHeaderEntity>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  associateRefTable: Scalars['String'];
  associateRefID: Scalars['String'];
  paymentAmt: Scalars['Float'];
  APPaymentID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  ledgerInfo?: Maybe<Scalars['JSON']>;
};

export type BatchPaymentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  associateRefTable?: Maybe<Scalars['String']>;
  associateRefID?: Maybe<Scalars['String']>;
  paymentAmt?: Maybe<Scalars['Float']>;
  APPaymentID?: Maybe<Scalars['String']>;
};

export type BatchPaymentHeaderDeleteInput = {
  ID: Scalars['String'];
};

export type BatchPaymentHeaderEntity = AuditEntity & {
  __typename?: 'BatchPaymentHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  bankAccount?: Maybe<BankAccountEntity>;
  submittedDate?: Maybe<Scalars['String']>;
  submittedBy?: Maybe<Scalars['String']>;
  paidDate?: Maybe<Scalars['String']>;
  paidBy?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  batchPaymentDetail?: Maybe<Array<BatchPaymentDetailEntity>>;
  /** CustomFieldResolver */
  totalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  batchPaymentAssocDetail?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  batchPaymentDocNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type BatchPaymentHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description: Scalars['String'];
  bankAccountID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BillInterestEntity = AuditEntity & {
  __typename?: 'BillInterestEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerEntity>;
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  docRefTable?: Maybe<Scalars['String']>;
  docID: Scalars['String'];
  billDate: Scalars['String'];
  billAmt: Scalars['Float'];
  interestAmt: Scalars['Float'];
  waiveAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type BillInterestInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docID: Scalars['String'];
  billDate: Scalars['String'];
  billAmt: Scalars['Float'];
  interestAmt: Scalars['Float'];
  waiveAmt: Scalars['Float'];
  remarks: Scalars['String'];
};

export type BudgetAmt = {
  __typename?: 'BudgetAmt';
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BudgetAmtInput = {
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum BudgetChecker {
  Ignore = 'IGNORE',
  Warning = 'WARNING',
  Block = 'BLOCK'
}

export type BudgetInfo = {
  __typename?: 'BudgetInfo';
  isExceeded?: Maybe<Scalars['Boolean']>;
  isAllocated?: Maybe<Scalars['Boolean']>;
  budgetAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
};

export enum BudgetStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING'
}

export enum BudgetType {
  Cost = 'COST',
  Revenue = 'REVENUE'
}

export type BulkSubcontractorInput = {
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentTo?: Maybe<Scalars['String']>;
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<ContactInput>;
  ratingType: RatingType;
  cidbDetails: CidbDetailsInput;
  costCategoryID?: Maybe<Scalars['String']>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  statusRemarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  nominatedSC?: Maybe<Scalars['Boolean']>;
  creditorAC?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type BulkSupplierInput = {
  currencyID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<ContactInput>;
  ratingType: RatingType;
  bankDetails?: Maybe<BankDetailsInput>;
  creditorAC?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export type CbExportDeleteInput = {
  ID: Scalars['String'];
};

export type CbExportDetailEntity = AuditEntity & {
  __typename?: 'CBExportDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  cbExportID: Scalars['String'];
  bankAccountID: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  branch: Scalars['String'];
  clientName: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  docDate: Scalars['String'];
  docNo: Scalars['String'];
  docRef?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  cbExport?: Maybe<CbExportEntity>;
};

export type CbExportDetailInput = {
  advCustomerID: Array<Scalars['String']>;
  advCustomerRefTable: Scalars['String'];
  recCustomerID: Array<Scalars['String']>;
  recCustomerRefTable: Scalars['String'];
  refundCustomerID: Array<Scalars['String']>;
  refundCustomerRefTable: Scalars['String'];
  advSubcontractorID: Array<Scalars['String']>;
  advSubcontractorRefTable: Scalars['String'];
  recSubcontractorID: Array<Scalars['String']>;
  recSubcontractorRefTable: Scalars['String'];
  refundSubcontractorID: Array<Scalars['String']>;
  refundSubcontractorRefTable: Scalars['String'];
  advSupplierID: Array<Scalars['String']>;
  advSupplierRefTable: Scalars['String'];
  recSupplierID: Array<Scalars['String']>;
  recSupplierRefTable: Scalars['String'];
  refundSupplierID: Array<Scalars['String']>;
  refundSupplierRefTable: Scalars['String'];
};

export type CbExportEntity = AuditEntity & {
  __typename?: 'CBExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  cbExportDetail?: Maybe<Array<CbExportDetailEntity>>;
};

export type CbExportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type CbLedgerInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type CNtoSubconEntity = AuditEntity & {
  __typename?: 'CNtoSubconEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  chargeToRevenue: Scalars['Boolean'];
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type CNtoSubconEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type CNtoSubconItemEntity = AuditEntity & {
  __typename?: 'CNtoSubconItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  CNtoSubconID: Scalars['String'];
  CNtoSubcon?: Maybe<CNtoSubconEntity>;
  costItem?: Maybe<CostItemEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  CNtoSubconTaxItem?: Maybe<Array<CNtoSubconTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type CNtoSubconItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type CNtoSubconTaxItemEntity = AuditEntity & {
  __typename?: 'CNtoSubconTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  CNtoSubconItemID: Scalars['String'];
  CNtoSubconItem?: Maybe<CNtoSubconItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type CNtoSupplierEntity = AuditEntity & {
  __typename?: 'CNtoSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type CNtoSupplierEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type CNtoSupplierItemEntity = AuditEntity & {
  __typename?: 'CNtoSupplierItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  CNtoSupplierID: Scalars['String'];
  CNtoSupplier?: Maybe<CNtoSupplierEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  CNtoSupplierTaxItem?: Maybe<Array<CNtoSupplierTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type CNtoSupplierItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type CNtoSupplierTaxItemEntity = AuditEntity & {
  __typename?: 'CNtoSupplierTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  CNtoSupplierItemID: Scalars['String'];
  CNtoSupplierItem?: Maybe<CNtoSupplierItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type CalendarDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarEntity = AuditEntity & {
  __typename?: 'CalendarEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  stateID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  state: Scalars['String'];
  year: Scalars['String'];
  isDefault: Scalars['Boolean'];
  weekend: Scalars['JSON'];
  commonStatus: CommonStatus;
  publicHolConnection?: Maybe<Array<CalendarPhPolicyEntity>>;
  holidayType?: Maybe<HolidayTypeEntity>;
  publicHoliday: Array<PublicHolidayEntity>;
  costCategory: Array<CalendarEntity>;
};

export type CalendarInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  state?: Maybe<Scalars['String']>;
  stateID?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  weekend?: Maybe<Scalars['JSON']>;
};

export type CalendarPhPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarPhPolicyEntity = AuditEntity & {
  __typename?: 'CalendarPHPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  calendarID: Scalars['String'];
  publicHolidayID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  calendar?: Maybe<CalendarEntity>;
  publicHoliday?: Maybe<PublicHolidayEntity>;
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type CalendarPhPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  publicHolidayID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
};

export type CalendarWeekendPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarWeekendPolicyEntity = AuditEntity & {
  __typename?: 'CalendarWeekendPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  calendarID: Scalars['String'];
  daysOfWeek: DaysofWeek;
};

export type CalendarWeekendPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
  daysOfWeek: DaysofWeek;
};

export type CancelInput = {
  companyID?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
};

export type CashFlowEntity = AuditEntity & {
  __typename?: 'CashFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  cashFlowSum: Scalars['Float'];
  inflowAmt: Scalars['Float'];
  outFlowAmt: Scalars['Float'];
  netCashFlowAmt: Scalars['Float'];
  projectedCashFlowAmt: Scalars['Float'];
};

export type CashFlowInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  cashFlowSum: Scalars['Float'];
  inflowAmt: Scalars['Float'];
  outFlowAmt: Scalars['Float'];
  netCashFlowAmt: Scalars['Float'];
  projectedCashFlowAmt: Scalars['Float'];
};

export type CheckMultipleWbsBudgetDetailInput = {
  wbsID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type CheckingInput = {
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ChecklistCategoryEntity = AuditEntity & {
  __typename?: 'ChecklistCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  safetyChecklist?: Maybe<Array<SafetyChecklistEntity>>;
};

export type CidbDetails = {
  __typename?: 'CidbDetails';
  registrationNo?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type CidbDetailsInput = {
  registrationNo?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type ClaimDetailsInput = {
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ClaimLog = {
  __typename?: 'ClaimLog';
  logDate?: Maybe<Scalars['String']>;
  approvalAmt?: Maybe<Scalars['Float']>;
  approvalID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ClaimLogInput = {
  logDate?: Maybe<Scalars['String']>;
  approvalAmt?: Maybe<Scalars['Float']>;
  approvalID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docDueDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};

export type ClaimSummary = {
  __typename?: 'ClaimSummary';
  previousFinalClaim?: Maybe<Scalars['Float']>;
  previousClaim?: Maybe<Scalars['Float']>;
  previousMOS?: Maybe<Scalars['Float']>;
  previousVOClaim?: Maybe<Scalars['Float']>;
  previousDeduction?: Maybe<Scalars['Float']>;
  previousDayWork?: Maybe<Scalars['Float']>;
  retentionPerc?: Maybe<Scalars['Float']>;
  previousRetentionAmt?: Maybe<Scalars['Float']>;
  previousRetentionSum?: Maybe<Scalars['Float']>;
  retentionBalance?: Maybe<Scalars['Float']>;
  advanceSum?: Maybe<Scalars['Float']>;
  advanceRecouped?: Maybe<Scalars['Float']>;
  advanceBalance?: Maybe<Scalars['Float']>;
  deductionSum?: Maybe<Scalars['Float']>;
  deductionRecouped?: Maybe<Scalars['Float']>;
  deductionBalance?: Maybe<Scalars['Float']>;
  checkPendingSubmit?: Maybe<Scalars['Boolean']>;
  previousDocDate?: Maybe<Scalars['DateTime']>;
  submittedDocDate?: Maybe<Scalars['DateTime']>;
};

export type ClientCnEntity = AuditEntity & {
  __typename?: 'ClientCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ClientCnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ClientCnItemEntity = AuditEntity & {
  __typename?: 'ClientCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  costItemID: Scalars['String'];
  clientCNID: Scalars['String'];
  clientCN?: Maybe<ClientCnEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  clientCNTaxItem?: Maybe<Array<ClientCnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ClientCnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ClientCnTaxItemEntity = AuditEntity & {
  __typename?: 'ClientCNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  clientCNItemID: Scalars['String'];
  clientCNItem?: Maybe<ClientCnItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type ClientConAdjustmentTransaction = AuditEntity & {
  __typename?: 'ClientConAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type ClientContractAccReceipt = AuditEntity & {
  __typename?: 'ClientContractAccReceipt';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ClientDnEntity = AuditEntity & {
  __typename?: 'ClientDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ClientDnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ClientDnItemEntity = AuditEntity & {
  __typename?: 'ClientDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  costItemID: Scalars['String'];
  clientDNID: Scalars['String'];
  clientDN?: Maybe<ClientDnEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  clientDNTaxItem?: Maybe<Array<ClientDnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ClientDnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ClientDnTaxItemEntity = AuditEntity & {
  __typename?: 'ClientDNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  clientDNItemID: Scalars['String'];
  clientDNItem?: Maybe<ClientDnItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type ClientEInvoiceAccTransaction = AuditEntity & {
  __typename?: 'ClientEInvoiceAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ClientEInvoiceAdjustmentTransaction = AuditEntity & {
  __typename?: 'ClientEInvoiceAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type ClientEInvoiceClaimTransaction = AuditEntity & {
  __typename?: 'ClientEInvoiceClaimTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
};

export type ClientSoaInfo = {
  __typename?: 'ClientSOAInfo';
  totalOsIvCount?: Maybe<Scalars['Float']>;
  totalOsIvAmt?: Maybe<Scalars['Float']>;
};

export type CollectionActivityDeleteId = {
  ID?: Maybe<Scalars['String']>;
};

export type CollectionActivityEntity = AuditEntity & {
  __typename?: 'CollectionActivityEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contactID?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactEntity>;
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  date: Scalars['String'];
  time: Scalars['String'];
  description: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  activityStatus: ActivityStatus;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
};

export type CollectionActivityInput = {
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contactID?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  time: Scalars['String'];
  description: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  activityStatus?: Maybe<ActivityStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CollectionActivityStatusInput = {
  ID?: Maybe<Scalars['String']>;
  contactID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CommonBaseInput = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type Company = {
  __typename?: 'Company';
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
};

export type CompanyEntity = AuditEntity & {
  __typename?: 'CompanyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  ParentCompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  Currency: CurrencyEntity;
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  ContactNo?: Maybe<Scalars['String']>;
  OfficeTel?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  GroupGSTRegNo?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation: Scalars['Boolean'];
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation: Scalars['Boolean'];
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax: CompanyTax;
  TourismTaxNo?: Maybe<Scalars['String']>;
  Tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvIntegrated: Scalars['Boolean'];
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  RecordStatus: Scalars['String'];
  companyMsicAssignment?: Maybe<Array<CompanyMsicAssignmentEntity>>;
};

export type CompanyInput = {
  CompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  GroupGSTRegNo?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  Tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  FileImage?: Maybe<Scalars['Upload']>;
};

export type CompanyLogoInput = {
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
};

export type CompanyMsicAssignmentEntity = AuditEntity & {
  __typename?: 'CompanyMsicAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  company?: Maybe<CompanyEntity>;
  eInvoiceMsicCodeID: Scalars['String'];
  eInvoiceMsicCode?: Maybe<EInvoiceMsicEntity>;
  isDefault: Scalars['Boolean'];
};

export enum CompanyTax {
  NoTax = 'NO_TAX',
  SstNo = 'SST_NO',
  GstNo = 'GST_NO'
}

export type ConDocTypeEntity = AuditEntity & {
  __typename?: 'ConDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
  docCode: ContractDocNum;
  code?: Maybe<Scalars['String']>;
  numberFormat: Array<ConNumberFormatEntity>;
  docNumHeader: Array<DocNumHeaderEntity>;
};

export type ConNumberFormatEntity = AuditEntity & {
  __typename?: 'ConNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
  docType: ConDocTypeEntity;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  documentDivider: Scalars['String'];
  prefixExtra?: Maybe<Scalars['String']>;
  runningNum: Array<ConRunningNumberSetupEntity>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type ConRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'ConRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
  accountID: Scalars['String'];
  numberFormat: ConNumberFormatEntity;
};

export type ConTaxEffectiveDateEntity = AuditEntity & {
  __typename?: 'ConTaxEffectiveDateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  date?: Maybe<Scalars['DateTime']>;
  taxRate: Scalars['Float'];
};

export type ConTaxEffectiveDateInput = {
  taxSchemeID: Scalars['String'];
  date: Scalars['String'];
  description: Scalars['String'];
};

export type ConTaxEffectiveDateRateInput = {
  effectiveDate?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
};

export type ConTaxSchemeEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isClaimable?: Maybe<Scalars['Boolean']>;
  taxClass?: Maybe<TaxClass>;
  taxCategory?: Maybe<TaxCategory>;
  commonStatus?: Maybe<CommonStatus>;
  taxSchemeID: Scalars['String'];
  effectiveDateRate: Array<ConTaxEffectiveDateRateInput>;
};

export type ConTaxSchemeEntity = AuditEntity & {
  __typename?: 'ConTaxSchemeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID: Scalars['String'];
  taxType?: Maybe<ConTaxTypeEntity>;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  taxCategory: TaxCategory;
  taxClass: TaxClass;
  isClaimable: Scalars['Boolean'];
  commonStatus: CommonStatus;
  /** CustomFieldResolver */
  latestTax?: Maybe<TaxObject>;
  taxEffective?: Maybe<Array<ConTaxEffectiveDateEntity>>;
  contract?: Maybe<Array<ContractEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  subcontractCostWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  revenueCategory?: Maybe<Array<RevenueCategoryEntity>>;
  miscExpense?: Maybe<Array<MiscExpenseEntity>>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  customer?: Maybe<Array<CustomerEntity>>;
  supplier?: Maybe<Array<SupplierEntity>>;
  subcontractor?: Maybe<Array<SubcontractorEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  approvedVendors?: Maybe<Array<ApprovedVendorsEntity>>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
};


export type ConTaxSchemeEntityLatestTaxArgs = {
  docDate?: Maybe<Scalars['String']>;
};

export type ConTaxSchemeInput = {
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isClaimable?: Maybe<Scalars['Boolean']>;
  taxClass?: Maybe<TaxClass>;
  taxCategory?: Maybe<TaxCategory>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ConTaxTypeEntity = AuditEntity & {
  __typename?: 'ConTaxTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  taxScheme?: Maybe<Array<ConTaxSchemeEntity>>;
  glTaxDynamic?: Maybe<Array<GlTaxDynamicEntity>>;
};

export type ConTaxTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum ContPermission {
  ContractMgmtIcon = 'CONTRACT_MGMT__________ICON',
  ContractMgmtView = 'CONTRACT_MGMT__________VIEW',
  ContractMgmtCreate = 'CONTRACT_MGMT__________CREATE',
  ContractMgmtProjectWbsBudgettingView = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______VIEW',
  ContractMgmtProjectWbsBudgettingCreate = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______CREATE',
  ContractMgmtProjectWbsBudgettingUpdate = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______UPDATE',
  ContractMgmtProjectWbsBudgettingDelete = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______DELETE',
  ContractMgmtProjectWbsBudgettingCancel = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______CANCEL',
  ContractMgmtProjectWbsBudgettingMoveDown = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______MOVE_DOWN',
  ContractMgmtProjectWbsBudgettingMoveUp = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______MOVE_UP',
  ContractMgmtProjectWbsBudgettingUpload = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______UPLOAD',
  ContractMgmtWbsDayworkView = 'CONTRACT_MGMT_WBS_DAYWORK_______VIEW',
  ContractMgmtWbsDayworkUpdate = 'CONTRACT_MGMT_WBS_DAYWORK_______UPDATE',
  ContractMgmtBudgetRevisionCreate = 'CONTRACT_MGMT_BUDGET_REVISION_______CREATE',
  ContractMgmtBudgetRevisionUpdate = 'CONTRACT_MGMT_BUDGET_REVISION_______UPDATE',
  ContractMgmtBudgetRevisionDelete = 'CONTRACT_MGMT_BUDGET_REVISION_______DELETE',
  ContractMgmtBudgetRevisionCancel = 'CONTRACT_MGMT_BUDGET_REVISION_______CANCEL',
  ContractMgmtBudgetRevisionView = 'CONTRACT_MGMT_BUDGET_REVISION_______VIEW',
  ContractMgmtUtilizedBudgetView = 'CONTRACT_MGMT_UTILIZED_BUDGET_______VIEW',
  ContractMgmtUtilizedBudgetCreate = 'CONTRACT_MGMT_UTILIZED_BUDGET_______CREATE',
  ContractMgmtUtilizedBudgetUpdate = 'CONTRACT_MGMT_UTILIZED_BUDGET_______UPDATE',
  ContractMgmtUtilizedBudgetDelete = 'CONTRACT_MGMT_UTILIZED_BUDGET_______DELETE',
  ContractMgmtUtilizedBudgetCancel = 'CONTRACT_MGMT_UTILIZED_BUDGET_______CANCEL',
  ContractMgmtBudgetTransferView = 'CONTRACT_MGMT_BUDGET_TRANSFER_______VIEW',
  ContractMgmtBudgetTransferCreate = 'CONTRACT_MGMT_BUDGET_TRANSFER_______CREATE',
  ContractMgmtContractInfoView = 'CONTRACT_MGMT_CONTRACT_INFO_______VIEW',
  ContractMgmtContractInfoUpdate = 'CONTRACT_MGMT_CONTRACT_INFO_______UPDATE',
  ContractMgmtContractGuaranteeView = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtContractGuaranteeCreate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CREATE',
  ContractMgmtContractGuaranteeUpdate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______UPDATE',
  ContractMgmtContractGuaranteeDelete = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______DELETE',
  ContractMgmtContractGuaranteeCancel = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CANCEL',
  ContractMgmtContractInsuranceView = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtContractInsuranceCreate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CREATE',
  ContractMgmtContractInsuranceUpdate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______UPDATE',
  ContractMgmtContractInsuranceDelete = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______DELETE',
  ContractMgmtContractInsuranceCancel = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CANCEL',
  ContractMgmtContractVoView = 'CONTRACT_MGMT_CONTRACT_VO_______VIEW',
  ContractMgmtContractVoCreate = 'CONTRACT_MGMT_CONTRACT_VO_______CREATE',
  ContractMgmtContractVoUpdate = 'CONTRACT_MGMT_CONTRACT_VO_______UPDATE',
  ContractMgmtContractVoApproveReject = 'CONTRACT_MGMT_CONTRACT_VO_______APPROVE_REJECT',
  ContractMgmtContractVoHealthIndexView = 'CONTRACT_MGMT_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  ContractMgmtContractAccStatusView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_______VIEW',
  ContractMgmtContractAccStatusClientProfileView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_PROFILE____VIEW',
  ContractMgmtContractAccStatusAdvancesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_ADVANCES____VIEW',
  ContractMgmtContractAccStatusProgressClaimView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_PROGRESS_CLAIM____VIEW',
  ContractMgmtContractAccStatusRetentionClaimsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  ContractMgmtContractAccStatusMiscellaneousInvoiceView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  ContractMgmtContractAccStatusDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusReceiptsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RECEIPTS____VIEW',
  ContractMgmtContractAccStatusRefundsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_REFUNDS____VIEW',
  ContractMgmtContractAccStatusClientDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusClientCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusOsReceivableView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  ContractMgmtMiscellaneousExpenseView = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______VIEW',
  ContractMgmtMiscellaneousExpenseCreate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CREATE',
  ContractMgmtMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______UPDATE',
  ContractMgmtMiscellaneousExpenseCancel = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CANCEL',
  ContractMgmtRetentionView = 'CONTRACT_MGMT_RETENTION_______VIEW',
  ContractMgmtRetentionCreate = 'CONTRACT_MGMT_RETENTION_______CREATE',
  ContractMgmtRetentionApproveReject = 'CONTRACT_MGMT_RETENTION_______APPROVE_REJECT',
  ContractMgmtRetentionDelete = 'CONTRACT_MGMT_RETENTION_______DELETE',
  ContractMgmtRetentionUpdate = 'CONTRACT_MGMT_RETENTION_______UPDATE',
  ContractMgmtExtensionOfTimeView = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______VIEW',
  ContractMgmtExtensionOfTimeCreate = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______CREATE',
  ContractMgmtContractClosureCreate = 'CONTRACT_MGMT_CONTRACT_CLOSURE_______CREATE',
  ContractMgmtContractTerminationCreate = 'CONTRACT_MGMT_CONTRACT_TERMINATION_______CREATE',
  ContractMgmtDigitalDocumentsView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInfoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInfoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DELETE',
  ContractMgmtDigitalDocumentsContractGuaranteeView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtDigitalDocumentsContractGuaranteeUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DELETE',
  ContractMgmtDigitalDocumentsContractInsuranceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractInsuranceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractVoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______VIEW',
  ContractMgmtDigitalDocumentsContractVoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractVoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractVoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DELETE',
  ContractMgmtDigitalDocumentsContractAdvanceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractAdvanceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractClaimView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______VIEW',
  ContractMgmtDigitalDocumentsContractClaimUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClaimDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClaimDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DELETE',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______VIEW',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______UPLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DELETE',
  ContractMgmtDigitalDocumentsContractDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______VIEW',
  ContractMgmtDigitalDocumentsContractDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DELETE',
  ContractMgmtDigitalDocumentsContractCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______VIEW',
  ContractMgmtDigitalDocumentsContractCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractReceiptView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______VIEW',
  ContractMgmtDigitalDocumentsContractReceiptUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______UPLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DELETE',
  ContractMgmtDigitalDocumentsContractRefundView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______VIEW',
  ContractMgmtDigitalDocumentsContractRefundUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRefundDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRefundDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DELETE',
  ContractMgmtDigitalDocumentsClientDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______VIEW',
  ContractMgmtDigitalDocumentsClientDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DELETE',
  ContractMgmtDigitalDocumentsClientCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______VIEW',
  ContractMgmtDigitalDocumentsClientCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCpcView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCpcUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionDlpView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionDlpUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCmgView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCmgUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DELETE',
  ContractMgmtDigitalDocumentsContractExtensionView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______VIEW',
  ContractMgmtDigitalDocumentsContractExtensionUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DELETE',
  ContractMgmtDigitalDocumentsContractClosureView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______VIEW',
  ContractMgmtDigitalDocumentsContractClosureUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClosureDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClosureDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DELETE',
  ContractMgmtDigitalDocumentsContractTerminationView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______VIEW',
  ContractMgmtDigitalDocumentsContractTerminationUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DELETE',
  ContractMgmtGeneralSettingsIcon = 'CONTRACT_MGMT_GENERAL_SETTINGS_______ICON',
  ContractMgmtGeneralSettingsSubcontractTradeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ContractMgmtGeneralSettingsSubcontractTradeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ContractMgmtGeneralSettingsSubcontractTradeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ContractMgmtGeneralSettingsSubcontractTradeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ContractMgmtGeneralSettingsSubcontractTradeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ContractMgmtGeneralSettingsSubcontractTradeUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ContractMgmtGeneralSettingsLabourCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsLabourCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsLabourCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsLabourCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsLabourCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsLabourCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsPmCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsPmCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsPmCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsPmCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsPmCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsPmCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseView = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ContractMgmtGeneralSettingsMiscellaneousExpenseCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ContractMgmtGeneralSettingsGuaranteeTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  ContractMgmtGeneralSettingsGuaranteeTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  ContractMgmtGeneralSettingsGuaranteeTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsGuaranteeTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  ContractMgmtGeneralSettingsInsuranceTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  ContractMgmtGeneralSettingsInsuranceTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsInsuranceTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsInsuranceTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  ContractMgmtContractAprovalPolicyIcon = 'CONTRACT_MGMT_CONTRACT_APROVAL_POLICY_______ICON',
  ProfitRecognitionIcon = 'PROFIT_RECOGNITION__________ICON',
  ProfitRecognitionView = 'PROFIT_RECOGNITION__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________UPDATE',
  ProfitRecognitionNumberingStructureView = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________VIEW',
  ProfitRecognitionNumberingStructureUpdate = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________UPDATE',
  ProfitRecognitionProfitRecognitionGenerationView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________VIEW',
  ProfitRecognitionProfitRecognitionGenerationCreate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________CREATE',
  ProfitRecognitionProfitRecognitionGenerationUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________UPDATE',
  ProfitRecognitionGeneralSettingsIcon = 'PROFIT_RECOGNITION_GENERAL_SETTINGS__________ICON',
  ProfitRecognitionContractApprovalPolicyIcon = 'PROFIT_RECOGNITION_CONTRACT_APPROVAL_POLICY__________ICON',
  ClientAcctIcon = 'CLIENT_ACCT__________ICON',
  ClientAcctView = 'CLIENT_ACCT__________VIEW',
  ClientAcctClientProfileView = 'CLIENT_ACCT_CLIENT_PROFILE_______VIEW',
  ClientAcctClientProfileCreate = 'CLIENT_ACCT_CLIENT_PROFILE_______CREATE',
  ClientAcctClientProfileUpdate = 'CLIENT_ACCT_CLIENT_PROFILE_______UPDATE',
  ClientAcctAccTransactionsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_______VIEW',
  ClientAcctAccTransactionsAdvancesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____VIEW',
  ClientAcctAccTransactionsAdvancesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CREATE',
  ClientAcctAccTransactionsAdvancesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____UPDATE',
  ClientAcctAccTransactionsAdvancesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____APPROVE_REJECT',
  ClientAcctAccTransactionsAdvancesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CANCEL',
  ClientAcctAccTransactionsAdvancesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____DELETE',
  ClientAcctAccTransactionsProgressClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____VIEW',
  ClientAcctAccTransactionsProgressClaimsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CREATE',
  ClientAcctAccTransactionsProgressClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____UPDATE',
  ClientAcctAccTransactionsProgressClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsProgressClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CANCEL',
  ClientAcctAccTransactionsProgressClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____VIEW',
  ClientAcctAccTransactionsRetentionClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____CANCEL',
  ClientAcctAccTransactionsRetentionClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____UPDATE',
  ClientAcctAccTransactionsRetentionClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____VIEW',
  ClientAcctAccTransactionsMiscellaneousInvoicesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CREATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CANCEL',
  ClientAcctAccTransactionsMiscellaneousInvoicesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____UPDATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____DELETE',
  ClientAcctAccTransactionsDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsCreditNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____UPDATE',
  ClientAcctAccTransactionsCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsCreditNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsReceiptsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____VIEW',
  ClientAcctAccTransactionsReceiptsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CREATE',
  ClientAcctAccTransactionsReceiptsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____APPROVE_REJECT',
  ClientAcctAccTransactionsReceiptsAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____ALLOCATION',
  ClientAcctAccTransactionsReceiptsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____UPDATE',
  ClientAcctAccTransactionsReceiptsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CANCEL',
  ClientAcctAccTransactionsReceiptsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____DELETE',
  ClientAcctAccTransactionsRefundsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____VIEW',
  ClientAcctAccTransactionsRefundsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CREATE',
  ClientAcctAccTransactionsRefundsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____APPROVE_REJECT',
  ClientAcctAccTransactionsRefundsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CANCEL',
  ClientAcctAccTransactionsRefundsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____UPDATE',
  ClientAcctAccTransactionsRefundsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____DELETE',
  ClientAcctAccTransactionsClientDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsClientDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientDebitNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsClientCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientCreditNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsOsReceivableView = 'CLIENT_ACCT_ACC_TRANSACTIONS_OS_RECEIVABLE____VIEW',
  ClientAcctCreditControlView = 'CLIENT_ACCT_CREDIT_CONTROL_______VIEW',
  ClientAcctCreditControlOutstandingCollectionView = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION____VIEW',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityView = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_VIEW',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityCreate = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_CREATE',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityUpdate = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_UPDATE',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityReschedule = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_RESCHEDULE',
  ClientAcctCreditControlCollectionActivityView = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____VIEW',
  ClientAcctCreditControlCollectionActivityUpdate = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____UPDATE',
  ClientAcctCreditControlCollectionActivityReschedule = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____RESCHEDULE',
  ClientAcctCreditControlCollectionActivityCreate = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____CREATE',
  ClientAcctCreditControlPaymentReminderView = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____VIEW',
  ClientAcctCreditControlPaymentReminderDownload = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____DOWNLOAD',
  ClientAcctCreditControlPaymentReminderCreate = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____CREATE',
  ClientAcctStatementAccountsView = 'CLIENT_ACCT_STATEMENT_ACCOUNTS_______VIEW',
  ClientAcctPaymentReminderView = 'CLIENT_ACCT_PAYMENT_REMINDER_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingView = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingCreate = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______CREATE',
  ClientAcctDigitalReportingView = 'CLIENT_ACCT_DIGITAL_REPORTING_______VIEW',
  ClientAcctDigitalReportingClientLedgerReportParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____PARAMETER',
  ClientAcctDigitalReportingClientLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____VIEW',
  ClientAcctDigitalReportingClientLedgerReportReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____REPORTING',
  ClientAcctDigitalReportingClientAgingDetailByContractParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____PARAMETER',
  ClientAcctDigitalReportingClientAgingDetailByContractView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____VIEW',
  ClientAcctDigitalReportingClientAgingDetailByContractReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____REPORTING',
  ClientAcctDigitalReportingClientAgingSummaryParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____PARAMETER',
  ClientAcctDigitalReportingClientAgingSummaryView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____VIEW',
  ClientAcctDigitalReportingClientAgingSummaryReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____REPORTING',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____PARAMETER',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____VIEW',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____REPORTING',
  ClientAcctDigitalReportingStatementOfAccountParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____PARAMETER',
  ClientAcctDigitalReportingStatementOfAccountView = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____VIEW',
  ClientAcctDigitalReportingStatementOfAccountReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____REPORTING',
  ClientAcctDigitalReportingClientTaxLedgerReportParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____PARAMETER',
  ClientAcctDigitalReportingClientTaxLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____VIEW',
  ClientAcctDigitalReportingClientTaxLedgerReportReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____REPORTING',
  ClientAcctDigitalReportingRetentionLedgerReportParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_RETENTION_LEDGER_REPORT____PARAMETER',
  ClientAcctDigitalReportingClientRetentionLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_RETENTION_LEDGER_REPORT____VIEW',
  ClientAcctGeneralSettingsIcon = 'CLIENT_ACCT_GENERAL_SETTINGS_____ICON',
  ClientAcctContractApprovalPolicyIcon = 'CLIENT_ACCT_CONTRACT_APPROVAL_POLICY_____ICON',
  SubconTenderIcon = 'SUBCON_TENDER__________ICON',
  SubconTenderView = 'SUBCON_TENDER__________VIEW',
  SubconTenderCreate = 'SUBCON_TENDER__________CREATE',
  SubconTenderUpdate = 'SUBCON_TENDER__________UPDATE',
  SubconTenderDelete = 'SUBCON_TENDER__________DELETE',
  SubconTenderTenderInviteesView = 'SUBCON_TENDER_TENDER_INVITEES_______VIEW',
  SubconTenderTenderInviteesCreate = 'SUBCON_TENDER_TENDER_INVITEES_______CREATE',
  SubconTenderTenderInviteesUninvite = 'SUBCON_TENDER_TENDER_INVITEES_______UNINVITE',
  SubconTenderTenderInviteesTenderSubmissionView = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____VIEW',
  SubconTenderTenderInviteesTenderSubmissionCreate = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____CREATE',
  SubconTenderEvaluationSelectionCreate = 'SUBCON_TENDER_EVALUATION_SELECTION_______CREATE',
  SubconTenderGeneralSettingsIcon = 'SUBCON_TENDER_GENERAL_SETTINGS_____ICON',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeView = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_VIEW',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeCreate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_CREATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpdate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPDATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDownload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DOWNLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDelete = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DELETE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeActive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_ACTIVE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeInactive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_INACTIVE',
  SubcontractsIcon = 'SUBCONTRACTS__________ICON',
  SubcontractsView = 'SUBCONTRACTS__________VIEW',
  SubcontractsCreate = 'SUBCONTRACTS__________CREATE',
  SubcontractsSubcontractInfoView = 'SUBCONTRACTS_SUBCONTRACT_INFO_______VIEW',
  SubcontractsSubcontractInfoUpdate = 'SUBCONTRACTS_SUBCONTRACT_INFO_______UPDATE',
  SubcontractsSubcontractGuaranteeView = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsSubcontractGuaranteeCreate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CREATE',
  SubcontractsSubcontractGuaranteeApproveReject = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______APPROVE_REJECT',
  SubcontractsSubcontractGuaranteeUpdate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______UPDATE',
  SubcontractsSubcontractGuaranteeDelete = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______DELETE',
  SubcontractsSubcontractGuaranteeCancel = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CANCEL',
  SubcontractsSubcontractInsuranceView = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsSubcontractInsuranceCreate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CREATE',
  SubcontractsSubcontractInsuranceUpdate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______UPDATE',
  SubcontractsSubcontractInsuranceDelete = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______DELETE',
  SubcontractsSubcontractInsuranceCancel = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CANCEL',
  SubcontractsSubcontractInsuranceApproveReject = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______APPROVE_REJECT',
  SubcontractsSubcontractVoView = 'SUBCONTRACTS_SUBCONTRACT_VO_______VIEW',
  SubcontractsSubcontractVoCreate = 'SUBCONTRACTS_SUBCONTRACT_VO_______CREATE',
  SubcontractsSubcontractVoApproveReject = 'SUBCONTRACTS_SUBCONTRACT_VO_______APPROVE_REJECT',
  SubcontractsSubcontractVoUpdate = 'SUBCONTRACTS_SUBCONTRACT_VO_______UPDATE',
  SubcontractsSubcontractVoDelete = 'SUBCONTRACTS_SUBCONTRACT_VO_______DELETE',
  SubcontractsSubcontractAccStatusView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_______VIEW',
  SubcontractsSubcontractAccStatusSubcontractorProfileView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_SUBCONTRACTOR_PROFILE___VIEW',
  SubcontractsSubcontractAccStatusAdvancesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_ADVANCES____VIEW',
  SubcontractsSubcontractAccStatusProgressClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PROGRESS_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusRetentionClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusMiscellaneousInvoiceView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusPaymentsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PAYMENTS____VIEW',
  SubcontractsSubcontractAccStatusRefundsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_REFUNDS____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusOsReceivableView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  SubcontractsRetentionView = 'SUBCONTRACTS_RETENTION_______VIEW',
  SubcontractsRetentionCreate = 'SUBCONTRACTS_RETENTION_______CREATE',
  SubcontractsRetentionUpdate = 'SUBCONTRACTS_RETENTION_______UPDATE',
  SubcontractsRetentionApproveReject = 'SUBCONTRACTS_RETENTION_______APPROVE_REJECT',
  SubcontractsRetentionDelete = 'SUBCONTRACTS_RETENTION_______DELETE',
  SubcontractsExtensionOfTimeView = 'SUBCONTRACTS_EXTENSION_OF_TIME_______VIEW',
  SubcontractsExtensionOfTimeCreate = 'SUBCONTRACTS_EXTENSION_OF_TIME_______CREATE',
  SubcontractsSubcontractClosureCreate = 'SUBCONTRACTS_SUBCONTRACT_CLOSURE_______CREATE',
  SubcontractsSubcontractTerminationCreate = 'SUBCONTRACTS_SUBCONTRACT_TERMINATION_______CREATE',
  SubcontractsDigitalDocumentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DELETE',
  SubcontractsDigitalDocumentSubcontractGuaranteeView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsDigitalDocumentSubcontractGuaranteeUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DELETE',
  SubcontractsDigitalDocumentSubcontractInsuranceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractInsuranceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractVoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______VIEW',
  SubcontractsDigitalDocumentSubcontractVoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractVoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractVoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DELETE',
  SubcontractsDigitalDocumentSubcontractAdvanceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractAdvanceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractClaimView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______VIEW',
  SubcontractsDigitalDocumentSubcontractClaimUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DELETE',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______VIEW',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DELETE',
  SubcontractsDigitalDocumentSubcontractDnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______VIEW',
  SubcontractsDigitalDocumentSubcontractDnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractDnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractDnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DELETE',
  SubcontractsDigitalDocumentSubcontractCnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______VIEW',
  SubcontractsDigitalDocumentSubcontractCnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractCnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractCnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DELETE',
  SubcontractsDigitalDocumentSubcontractPaymentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractPaymentUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DELETE',
  SubcontractsDigitalDocumentSubcontractRefundView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______VIEW',
  SubcontractsDigitalDocumentSubcontractRefundUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DELETE',
  SubcontractsDigitalDocumentDnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentDnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentDnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentDnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentCnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentCnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentCnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentCnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCpcView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCpcUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionDlpView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionDlpUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCmgView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCmgUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DELETE',
  SubcontractsDigitalDocumentSubcontractExtensionView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______VIEW',
  SubcontractsDigitalDocumentSubcontractExtensionUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DELETE',
  SubcontractsDigitalDocumentSubcontractClosureView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______VIEW',
  SubcontractsDigitalDocumentSubcontractClosureUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DELETE',
  SubcontractsDigitalDocumentSubcontractTerminationView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______VIEW',
  SubcontractsDigitalDocumentSubcontractTerminationUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DELETE',
  SubcontractsGeneralSettingsIcon = 'SUBCONTRACTS_GENERAL_SETTINGS_______ICON',
  SubcontractsGeneralSettingsSubcontractTradeView = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubcontractsGeneralSettingsSubcontractTradeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubcontractsGeneralSettingsSubcontractTradeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubcontractsGeneralSettingsSubcontractTradeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubcontractsGeneralSettingsSubcontractTradeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeDownload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubcontractsGeneralSettingsSubcontractTradeUpload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubcontractsGeneralSettingsGuaranteeTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  SubcontractsGeneralSettingsGuaranteeTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  SubcontractsGeneralSettingsGuaranteeTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  SubcontractsGeneralSettingsGuaranteeTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  SubcontractsGeneralSettingsInsuranceTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  SubcontractsGeneralSettingsInsuranceTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  SubcontractsGeneralSettingsInsuranceTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  SubcontractsGeneralSettingsInsuranceTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  SubcontractsContractApprovalPolicyIcon = 'SUBCONTRACTS_CONTRACT_APPROVAL_POLICY____ICON',
  SubconAccountIcon = 'SUBCON_ACCOUNT__________ICON',
  SubconAccountView = 'SUBCON_ACCOUNT__________VIEW',
  SubconAccountCreate = 'SUBCON_ACCOUNT__________CREATE',
  SubconAccountDownload = 'SUBCON_ACCOUNT__________DOWNLOAD',
  SubconAccountUpload = 'SUBCON_ACCOUNT__________UPLOAD',
  SubconAccountSubcontractorProfileView = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______VIEW',
  SubconAccountSubcontractorProfileCreate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______CREATE',
  SubconAccountSubcontractorProfileUpdate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______UPDATE',
  SubconAccountAccountTransactionView = 'SUBCON_ACCOUNT_ACCOUNT_TRANSACTION_______VIEW',
  SubconAccountAdvancesView = 'SUBCON_ACCOUNT_ADVANCES_______VIEW',
  SubconAccountAdvancesCreate = 'SUBCON_ACCOUNT_ADVANCES_______CREATE',
  SubconAccountAdvancesCancel = 'SUBCON_ACCOUNT_ADVANCES_______CANCEL',
  SubconAccountAdvancesApproveReject = 'SUBCON_ACCOUNT_ADVANCES_______APPROVE_REJECT',
  SubconAccountAdvancesUpdate = 'SUBCON_ACCOUNT_ADVANCES_______UPDATE',
  SubconAccountAdvancesDelete = 'SUBCON_ACCOUNT_ADVANCES_______DELETE',
  SubconAccountProgressClaimsView = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______VIEW',
  SubconAccountProgressClaimsCreate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CREATE',
  SubconAccountProgressClaimsUpdate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______UPDATE',
  SubconAccountProgressClaimsApproveReject = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______APPROVE_REJECT',
  SubconAccountProgressClaimsCancel = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CANCEL',
  SubconAccountProgressClaimsDelete = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______DELETE',
  SubconAccountRetentionClaimsView = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______VIEW',
  SubconAccountRetentionClaimsCreate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CREATE',
  SubconAccountRetentionClaimsUpdate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______UPDATE',
  SubconAccountRetentionClaimsCancel = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CANCEL',
  SubconAccountRetentionClaimsApproveReject = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______APPROVE_REJECT',
  SubconAccountRetentionClaimsDelete = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______DELETE',
  SubconAccountMiscellaneousInvoiceView = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______VIEW',
  SubconAccountMiscellaneousInvoiceCreate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CREATE',
  SubconAccountMiscellaneousInvoiceUpdate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______UPDATE',
  SubconAccountMiscellaneousInvoiceCancel = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CANCEL',
  SubconAccountMiscellaneousInvoiceApproveReject = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______APPROVE_REJECT',
  SubconAccountMiscellaneousInvoiceDelete = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______DELETE',
  SubconAccountDebitNotesView = 'SUBCON_ACCOUNT_DEBIT_NOTES_______VIEW',
  SubconAccountDebitNotesCreate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CREATE',
  SubconAccountDebitNotesUpdate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______UPDATE',
  SubconAccountDebitNotesDelete = 'SUBCON_ACCOUNT_DEBIT_NOTES_______DELETE',
  SubconAccountDebitNotesCancel = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CANCEL',
  SubconAccountDebitNotesApproveReject = 'SUBCON_ACCOUNT_DEBIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesView = 'SUBCON_ACCOUNT_CREDIT_NOTES_______VIEW',
  SubconAccountCreditNotesCreate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CREATE',
  SubconAccountCreditNotesUpdate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______UPDATE',
  SubconAccountCreditNotesApproveReject = 'SUBCON_ACCOUNT_CREDIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesAllocation = 'SUBCON_ACCOUNT_CREDIT_NOTES_______ALLOCATION',
  SubconAccountCreditNotesCancel = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CANCEL',
  SubconAccountCreditNotesDelete = 'SUBCON_ACCOUNT_CREDIT_NOTES_______DELETE',
  SubconAccountPaymentView = 'SUBCON_ACCOUNT_PAYMENT_______VIEW',
  SubconAccountPaymentCreate = 'SUBCON_ACCOUNT_PAYMENT_______CREATE',
  SubconAccountPaymentCancel = 'SUBCON_ACCOUNT_PAYMENT_______CANCEL',
  SubconAccountPaymentUpdate = 'SUBCON_ACCOUNT_PAYMENT_______UPDATE',
  SubconAccountPaymentApproveReject = 'SUBCON_ACCOUNT_PAYMENT_______APPROVE_REJECT',
  SubconAccountPaymentDelete = 'SUBCON_ACCOUNT_PAYMENT_______DELETE',
  SubconAccountRefundsView = 'SUBCON_ACCOUNT_REFUNDS_______VIEW',
  SubconAccountRefundsCreate = 'SUBCON_ACCOUNT_REFUNDS_______CREATE',
  SubconAccountRefundsApproveReject = 'SUBCON_ACCOUNT_REFUNDS_______APPROVE_REJECT',
  SubconAccountRefundsCancel = 'SUBCON_ACCOUNT_REFUNDS_______CANCEL',
  SubconAccountRefundsUpdate = 'SUBCON_ACCOUNT_REFUNDS_______UPDATE',
  SubconAccountRefundsDelete = 'SUBCON_ACCOUNT_REFUNDS_______DELETE',
  SubconAccountRefundsAllocation = 'SUBCON_ACCOUNT_REFUNDS_______ALLOCATION',
  SubconAccountCnSubconView = 'SUBCON_ACCOUNT_CN_SUBCON_______VIEW',
  SubconAccountCnSubconCreate = 'SUBCON_ACCOUNT_CN_SUBCON_______CREATE',
  SubconAccountCnSubconCancel = 'SUBCON_ACCOUNT_CN_SUBCON_______CANCEL',
  SubconAccountCnSubconUpdate = 'SUBCON_ACCOUNT_CN_SUBCON______UPDATE',
  SubconAccountCnSubconApproveReject = 'SUBCON_ACCOUNT_CN_SUBCON______APPROVE_REJECT',
  SubconAccountCnSubconDelete = 'SUBCON_ACCOUNT_CN_SUBCON______DELETE',
  SubconAccountDnSubconView = 'SUBCON_ACCOUNT_DN_SUBCON_______VIEW',
  SubconAccountDnSubconCreate = 'SUBCON_ACCOUNT_DN_SUBCON_______CREATE',
  SubconAccountDnSubconCancel = 'SUBCON_ACCOUNT_DN_SUBCON_______CANCEL',
  SubconAccountDnSubconUpdate = 'SUBCON_ACCOUNT_DN_SUBCON______UPDATE',
  SubconAccountDnSubconApproveReject = 'SUBCON_ACCOUNT_DN_SUBCON______APPROVE_REJECT',
  SubconAccountDnSubconDelete = 'SUBCON_ACCOUNT_DN_SUBCON______DELETE',
  SubconAccountDnSubconAllocation = 'SUBCON_ACCOUNT_DN_SUBCON______ALLOCATION',
  SubconAccountDigitalReportingView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_______VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____VIEW',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____PARAMETER',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____REPORTING',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____PARAMETER',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____REPORTING',
  SubconAccountDigitalReportingSubcontractorControlSummaryView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____VIEW',
  SubconAccountDigitalReportingSubcontractorControlSummaryParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____PARAMETER',
  SubconAccountDigitalReportingSubcontractorControlSummaryReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____REPORTING',
  SubconAccountDigitalReportingSubcontractorRetentionLedgerView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_RETENTION_LEDGER____VIEW',
  SubconAccountDigitalReportingSubcontractorRetentionLedgerParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_RETENTION_LEDGER____PARAMETER',
  SubconAccountOsReceivableView = 'SUBCON_ACCOUNT_OS_RECEIVABLE_______VIEW',
  SubconAccountGeneralSettingsIcon = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_______ICON',
  SubconAccountGeneralSettingsSubcontractTradeView = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubconAccountGeneralSettingsSubcontractTradeUpdate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubconAccountGeneralSettingsSubcontractTradeCreate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubconAccountGeneralSettingsSubcontractTradeDownload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubconAccountGeneralSettingsSubcontractTradeUpload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubconAccountGeneralSettingsSubcontractTradeDelete = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubconAccountGeneralSettingsSubcontractTradeInactive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubconAccountGeneralSettingsSubcontractTradeActive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  SubconAccountContractApprovalPolicyIcon = 'SUBCON_ACCOUNT_CONTRACT_APPROVAL_POLICY_______ICON',
  ProjectPurchaseIcon = 'PROJECT_PURCHASE__________ICON',
  ProjectPurchaseContractView = 'PROJECT_PURCHASE_CONTRACT_______VIEW',
  ProjectPurchaseNegotiatedSupplyView = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______VIEW',
  ProjectPurchaseNegotiatedSupplyCreate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CREATE',
  ProjectPurchaseNegotiatedSupplyUpdate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______UPDATE',
  ProjectPurchaseNegotiatedSupplyCancel = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CANCEL',
  ProjectPurchasePurchaseRequisitionView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______VIEW',
  ProjectPurchasePurchaseRequisitionCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CREATE',
  ProjectPurchasePurchaseRequisitionUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______UPDATE',
  ProjectPurchasePurchaseRequisitionCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CANCEL',
  ProjectPurchasePurchaseRequisitionApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______APPROVE_REJECT',
  ProjectPurchasePurchaseRequisitionDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______DRAFT',
  ProjectPurchasePurchaseRequisitionDelete = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______DELETE',
  ProjectPurchasePurchaseRequisitionBehalfView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______VIEW',
  ProjectPurchasePurchaseRequisitionBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CREATE',
  ProjectPurchasePurchaseRequisitionBehalfDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______DRAFT',
  ProjectPurchasePurchaseRequisitionBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______UPDATE',
  ProjectPurchasePurchaseRequisitionBehalfCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CANCEL',
  ProjectPurchasePurchaseRequisitionBehalfDelete = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______DELETE',
  ProjectPurchasePurchaseRequisitionBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______APPROVE_REJECT',
  ProjectPurchaseRequestForQuotationView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______VIEW',
  ProjectPurchaseRequestForQuotationCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______CREATE',
  ProjectPurchaseRequestForQuotationUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______UPDATE',
  ProjectPurchaseRequestForQuotationApproveReject = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______APPROVE_REJECT',
  ProjectPurchaseRequestForQuotationDelete = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______DELETE',
  ProjectPurchaseRequestForQuotationRfqInviteesView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesUninvite = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____UNINVITE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_UPDATE',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____VIEW',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____CREATE',
  ProjectPurchasePurchaseOrderView = 'PROJECT_PURCHASE_PURCHASE_ORDER_______VIEW',
  ProjectPurchasePurchaseOrderCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CREATE',
  ProjectPurchasePurchaseOrderUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______UPDATE',
  ProjectPurchasePurchaseOrderApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CLOSE',
  ProjectPurchasePurchaseOrderBehalfView = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______VIEW',
  ProjectPurchasePurchaseOrderBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______UPDATE',
  ProjectPurchasePurchaseOrderBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CREATE',
  ProjectPurchasePurchaseOrderBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderBehalfClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CLOSE',
  ProjectPurchaseDeliveryOrderView = 'PROJECT_PURCHASE_DELIVERY_ORDER_______VIEW',
  ProjectPurchaseDeliveryOrderCreate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______CREATE',
  ProjectPurchaseDeliveryOrderUpdate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______UPDATE',
  ProjectPurchaseDeliveryOrderDelete = 'PROJECT_PURCHASE_DELIVERY_ORDER_______DELETE',
  ProjectPurchaseDeliveryOrderApproveReject = 'PROJECT_PURCHASE_DELIVERY_ORDER_______APPROVE_REJECT',
  ProjectPurchaseDeliveryOrderClose = 'PROJECT_PURCHASE_DELIVERY_ORDER_______CLOSE',
  ProjectPurchaseGoodReturnNoteView = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______VIEW',
  ProjectPurchaseGoodReturnNoteCreate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______CREATE',
  ProjectPurchaseGoodReturnNoteUpdate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______UPDATE',
  ProjectPurchaseGoodReturnNoteDelete = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______DELETE',
  ProjectPurchaseGoodReturnNoteApproveReject = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______APPROVE_REJECT',
  ProjectPurchaseGoodReturnNoteClose = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______CLOSE',
  ProjectPurchaseDigitalReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______PARAMETER',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______REPORTING',
  ProjectPurchaseDigitalReportingLateDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingLateDeliveryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingLateDeliveryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPricingHistoryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPricingHistoryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingPricingHistoryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______REPORTING',
  ProjectPurchaseGeneralSettingsIcon = 'PROJECT_PURCHASE_GENERAL_SETTINGS_______ICON',
  ProjectPurchaseGeneralSettingsBuildingMaterialView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsLabourCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsLabourCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsLabourCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectPurchaseGeneralSettingsLabourCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsPMCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsPMCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsPMCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsPMCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  ProjectPurchaseContractApprovalPolicyIcon = 'PROJECT_PURCHASE_CONTRACT_APPROVAL_POLICY_______ICON',
  SupplierAcctIcon = 'SUPPLIER_ACCT__________ICON',
  SupplierAcctView = 'SUPPLIER_ACCT__________VIEW',
  SupplierAcctCreate = 'SUPPLIER_ACCT__________CREATE',
  SupplierAcctSupplierProfileView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______VIEW',
  SupplierAcctSupplierProfileCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______CREATE',
  SupplierAcctSupplierProfileUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______UPDATE',
  SupplierAcctSupplierProfileDelete = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______DELETE',
  SupplierAcctSupplierProfileDownload = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______DOWNLOAD',
  SupplierAcctSupplierProfileUpload = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______UPLOAD',
  SupplierAcctSupplierProfilePreferredSupplierProductsCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctSupplierProfilePreferredSupplierProductsView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______VIEW',
  SupplierAcctSupplierProfilePreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctSupplierProfileOsPayableView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_OS_PAYABLE_______VIEW',
  SupplierAcctPreferredSupplierProductsCreate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctPreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctAccTransactionsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_______VIEW',
  SupplierAcctAdvanceDepositsView = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____VIEW',
  SupplierAcctAdvanceDepositsCreate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____CREATE',
  SupplierAcctAdvanceDepositsUpdate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____UPDATE',
  SupplierAcctAdvanceDepositsCancel = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____CANCEL',
  SupplierAcctAdvanceDepositsApproveReject = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAdvanceDepositsDelete = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____DELETE',
  SupplierAcctAdvanceDepositsAllocation = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____ALLOCATION',
  SupplierAcctAccTransactionsDepositsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____VIEW',
  SupplierAcctAccTransactionsDepositsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CREATE',
  SupplierAcctAccTransactionsDepositsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____UPDATE',
  SupplierAcctAccTransactionsDepositsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAccTransactionsDepositsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CANCEL',
  SupplierAcctAccTransactionsDepositsAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____ALLOCATION',
  SupplierAcctAccTransactionsDepositsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____DELETE',
  SupplierAcctInvoicesView = 'SUPPLIER_ACCT_INVOICES____VIEW',
  SupplierAcctInvoicesCreate = 'SUPPLIER_ACCT_INVOICES____CREATE',
  SupplierAcctInvoicesUpdate = 'SUPPLIER_ACCT_INVOICES____UPDATE',
  SupplierAcctInvoicesApproveReject = 'SUPPLIER_ACCT_INVOICES____APPROVE_REJECT',
  SupplierAcctInvoicesCancel = 'SUPPLIER_ACCT_INVOICES____CANCEL',
  SupplierAcctInvoicesDelete = 'SUPPLIER_ACCT_INVOICES____DELETE',
  SupplierAcctAccTransactionsInvoicesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____VIEW',
  SupplierAcctAccTransactionsInvoicesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CREATE',
  SupplierAcctAccTransactionsInvoicesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____UPDATE',
  SupplierAcctAccTransactionsInvoicesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____APPROVE_REJECT',
  SupplierAcctAccTransactionsInvoicesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CANCEL',
  SupplierAcctAccTransactionsInvoicesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____DELETE',
  SupplierAcctDebitNotesView = 'SUPPLIER_ACCT_DEBIT_NOTES____VIEW',
  SupplierAcctDebitNotesCreate = 'SUPPLIER_ACCT_DEBIT_NOTES____CREATE',
  SupplierAcctDebitNotesUpdate = 'SUPPLIER_ACCT_DEBIT_NOTES____UPDATE',
  SupplierAcctDebitNotesApproveReject = 'SUPPLIER_ACCT_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctDebitNotesCancel = 'SUPPLIER_ACCT_DEBIT_NOTES____CANCEL',
  SupplierAcctDebitNotesDelete = 'SUPPLIER_ACCT_DEBIT_NOTES____DELETE',
  SupplierAcctAccTransactionsDebitNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  SupplierAcctAccTransactionsDebitNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  SupplierAcctAccTransactionsDebitNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsDebitNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsDebitNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsDebitNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  SupplierAcctCreditNotesView = 'SUPPLIER_ACCT_CREDIT_NOTES____VIEW',
  SupplierAcctCreditNotesCreate = 'SUPPLIER_ACCT_CREDIT_NOTES____CREATE',
  SupplierAcctCreditNotesUpdate = 'SUPPLIER_ACCT_CREDIT_NOTES____UPDATE',
  SupplierAcctCreditNotesApproveReject = 'SUPPLIER_ACCT_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctCreditNotesCancel = 'SUPPLIER_ACCT_CREDIT_NOTES____CANCEL',
  SupplierAcctCreditNotesDelete = 'SUPPLIER_ACCT_CREDIT_NOTES____DELETE',
  SupplierAcctCreditNotesAllocation = 'SUPPLIER_ACCT_CREDIT_NOTES____ALLOCATION',
  SupplierAcctAccTransactionsCreditNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  SupplierAcctAccTransactionsCreditNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  SupplierAcctAccTransactionsCreditNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsCreditNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsCreditNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsCreditNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  SupplierAcctAccTransactionsCreditNotesAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  SupplierAcctPaymentsView = 'SUPPLIER_ACCT_PAYMENTS____VIEW',
  SupplierAcctPaymentsCreate = 'SUPPLIER_ACCT_PAYMENTS____CREATE',
  SupplierAcctPaymentsUpdate = 'SUPPLIER_ACCT_PAYMENTS____UPDATE',
  SupplierAcctPaymentsApproveReject = 'SUPPLIER_ACCT_PAYMENTS____APPROVE_REJECT',
  SupplierAcctPaymentsCancel = 'SUPPLIER_ACCT_PAYMENTS____CANCEL',
  SupplierAcctPaymentsDelete = 'SUPPLIER_ACCT_PAYMENTS____DELETE',
  SupplierAcctAccTransactionsPaymentsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____VIEW',
  SupplierAcctAccTransactionsPaymentsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CREATE',
  SupplierAcctAccTransactionsPaymentsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____UPDATE',
  SupplierAcctAccTransactionsPaymentsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____APPROVE_REJECT',
  SupplierAcctAccTransactionsPaymentsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CANCEL',
  SupplierAcctAccTransactionsPaymentsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____DELETE',
  SupplierAcctDnSupplierCreate = 'SUPPLIER_ACCT_DN_SUPPLIER____CREATE',
  SupplierAcctDnSupplierView = 'SUPPLIER_ACCT_DN_SUPPLIER____VIEW',
  SupplierAcctDnSupplierUpdate = 'SUPPLIER_ACCT_DN_SUPPLIER____UPDATE',
  SupplierAcctDnSupplierApproveReject = 'SUPPLIER_ACCT_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctDnSupplierCancel = 'SUPPLIER_ACCT_DN_SUPPLIER____CANCEL',
  SupplierAcctDnSupplierDelete = 'SUPPLIER_ACCT_DN_SUPPLIER____DELETE',
  SupplierAcctDnSupplierAllocation = 'SUPPLIER_ACCT_DN_SUPPLIER____ALLOCATION',
  SupplierAcctAccTransactionsDnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsDnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsDnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsDnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsDnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsDnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsDnSupplierAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____ALLOCATION',
  SupplierAcctCnSupplierCreate = 'SUPPLIER_ACCT_CN_SUPPLIER____CREATE',
  SupplierAcctCnSupplierView = 'SUPPLIER_ACCT_CN_SUPPLIER____VIEW',
  SupplierAcctCnSupplierUpdate = 'SUPPLIER_ACCT_CN_SUPPLIER____UPDATE',
  SupplierAcctCnSupplierCancel = 'SUPPLIER_ACCT_CN_SUPPLIER____CANCEL',
  SupplierAcctCnSupplierApproveReject = 'SUPPLIER_ACCT_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctCnSupplierDelete = 'SUPPLIER_ACCT_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsCnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsCnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsCnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsCnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsCnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsCnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsOsPayableView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_OS_PAYABLE____VIEW',
  SupplierAcctDigitalReportingView = 'SUPPLIER_ACCT_DIGITAL_REPORTING____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierLedgerReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____REPORTING',
  SupplierAcctDigitalReportingSupplierControlSummaryReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierControlSummaryReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierControlSummaryReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____PARAMETER',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____REPORTING',
  SupplierAcctGeneralSettingsIcon = 'SUPPLIER_ACCT_GENERAL_SETTINGS_______ICON',
  SupplierAcctGeneralSettingsBuildingMaterialView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsPAndMCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsPAndMCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsPAndMCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsPAndMCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsLabourCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsLabourCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsLabourCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsLabourCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsLabourCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsLabourCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  SupplierAcctGeneralSettingsMiscellaneousExpenseCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  SupplierAcctContractApprovalPolicyIcon = 'SUPPLIER_ACCT_CONTRACT_APPROVAL_POLICY_______ICON',
  SiteMgmtIcon = 'SITE_MGMT__________ICON',
  ProjectCostingIcon = 'PROJECT_COSTING__________ICON',
  ProjectCostingContractView = 'PROJECT_COSTING_CONTRACT_______VIEW',
  ProjectCostingBudgetingView = 'PROJECT_COSTING_BUDGETING_______VIEW',
  ProjectCostingBudgetingCostCategoryView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY____VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingCostCategoryReviseBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST____VIEW',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST____VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMaterialCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST____VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingPAndMCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST____VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingLabourCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES____VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMiscExpensesReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_UPDATE',
  ProjectCostingMiscellaneousExpenseView = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectCostingMiscellaneousExpenseCreate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectCostingMiscellaneousExpenseUpdate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectCostingMiscellaneousExpenseCancel = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CANCEL',
  ProjectCostingMiscellaneousExpenseDelete = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____DELETE',
  ProjectCostingMiscellaneousExpenseApproveReject = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____APPROVE_REJECT',
  ProjectCostingProjectCostingView = 'PROJECT_COSTING_PROJECT_COSTING_______VIEW',
  ProjectCostingProjectCostingExport = 'PROJECT_COSTING_PROJECT_COSTING_______EXPORT',
  ProjectCostingProjectCostingShare = 'PROJECT_COSTING_PROJECT_COSTING_______SHARE',
  ProjectCostingProjectCostingSubcontractCostView = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  ProjectCostingProjectCostingSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectCostingMaterialCostView = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  ProjectCostingProjectCostingMaterialCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectCostingPAndMCostView = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____VIEW',
  ProjectCostingProjectCostingPAndMCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectCostingLabourCostView = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  ProjectCostingProjectCostingLabourCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectCostingMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingProjectCostingMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectPlView = 'PROJECT_COSTING_PROJECT_PL_______VIEW',
  ProjectCostingProjectPlShare = 'PROJECT_COSTING_PROJECT_PL_______SHARE',
  ProjectCostingProjectPlDownload = 'PROJECT_COSTING_PROJECT_PL____DOWNLOAD',
  ProjectCostingProjectPlExport = 'PROJECT_COSTING_PROJECT_PL____EXPORT',
  ProjectCostingProjectPlRevisedContractSumsView = 'PROJECT_COSTING_PROJECT_PL_REVISED_CONTRACT_SUMS_____VIEW',
  ProjectCostingProjectPlProgressIncomeView = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME_____VIEW',
  ProjectCostingProjectPlProgressIncomeDownload = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentClientView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT_____VIEW',
  ProjectCostingProjectPlAdjustmentClientDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoicesArView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoicesArDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR____DOWNLOAD',
  ProjectCostingProjectPlSubcontractCostView = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST_____VIEW',
  ProjectCostingProjectPlSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectPlMaterialCostView = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST_____VIEW',
  ProjectCostingProjectPlMaterialCostDownload = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectPlPAndMCostView = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST_____VIEW',
  ProjectCostingProjectPlPAndMCostDownload = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectPlLabourCostView = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST_____VIEW',
  ProjectCostingProjectPlLabourCostDownload = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoiceApView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoiceApDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentSupplierSubconView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON_____VIEW',
  ProjectCostingProjectPlAdjustmentSupplierSubconDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES_____VIEW',
  ProjectCostingProjectPlMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectCashFlowView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______VIEW',
  ProjectCostingProjectCashFlowShare = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______SHARE',
  ProjectCostingProjectCashFlowReceivedFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowReceivedFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowPaidToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowPaidToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCashFlowOtherExpensesView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCostPerformanceView = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______VIEW',
  ProjectCostingProjectCostPerformanceShare = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______SHARE',
  ProjectCostingProjectCostPerformancePercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  ProjectCostingProjectFinancialStatusShare = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______SHARE',
  ProjectCostingProjectFinancialStatusPercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusBillingToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusCollectionToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPayableToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYABLE_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPaymentToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  ProjectCostingProjectBudgetAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______SHARE',
  ProjectCostingProjectBudgetAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetAnalysisOriginalBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET_______VIEW',
  ProjectCostingProjectBudgetAnalysisRevisedBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______SHARE',
  ProjectCostingDigitalReportView = 'PROJECT_COSTING_DIGITAL_REPORT_______VIEW',
  ProjectCostingDigitalReportExecutiveSummaryReportView = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______VIEW',
  ProjectCostingDigitalReportExecutiveSummaryReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______PARAMETER',
  ProjectCostingDigitalReportExecutiveSummaryReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______REPORTING',
  ProjectCostingDigitalReportProjectCostAnalysisReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectCostAnalysisReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______PARAMETER',
  ProjectCostingDigitalReportProjectCostAnalysisReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______REPORTING',
  ProjectCostingDigitalReportProjectLedgerReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectLedgerReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______PARAMETER',
  ProjectCostingDigitalReportProjectLedgerReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______REPORTING',
  ProjectCostingGeneralSettingsIcon = 'PROJECT_COSTING_GENERAL_SETTINGS_______ICON',
  ProjectCostingGeneralSettingsSubcontractTradeCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ProjectCostingGeneralSettingsSubcontractTradeView = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ProjectCostingGeneralSettingsSubcontractTradeUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ProjectCostingGeneralSettingsSubcontractTradeDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ProjectCostingGeneralSettingsSubcontractTradeDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ProjectCostingGeneralSettingsSubcontractTradeActive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsLabourCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsLabourCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsLabourCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsLabourCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsLabourCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsLabourCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsPAndMCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsPAndMCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsPAndMCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsPAndMCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesView = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingGeneralSettingsMiscellaneousExpensesCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____CREATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPDATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DELETE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____INACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesActive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____ACTIVE',
  PlantMachineryIcon = 'PLANT_MACHINERY__________ICON',
  PlantMachineryView = 'PLANT_MACHINERY__________VIEW',
  PlantMachineryOwnedPMView = 'PLANT_MACHINERY_OWNED_P_M_______VIEW',
  PlantMachineryOwnedPMCreate = 'PLANT_MACHINERY_OWNED_P_M_______CREATE',
  PlantMachineryOwnedPMUpdate = 'PLANT_MACHINERY_OWNED_P_M_______UPDATE',
  PlantMachineryOwnedPMAssignmentHistoryView = 'PLANT_MACHINERY_OWNED_P_M_ASSIGNMENT_HISTORY____VIEW',
  PlantMachineryOwnedPMMaintenanceHistoryView = 'PLANT_MACHINERY_OWNED_P_M_MAINTENANCE_HISTORY____VIEW',
  PlantMachineryRentedPMView = 'PLANT_MACHINERY_RENTED_P_M_______VIEW',
  PlantMachineryRentedPMCreate = 'PLANT_MACHINERY_RENTED_P_M_______CREATE',
  PlantMachineryRentedPMUpdate = 'PLANT_MACHINERY_RENTED_P_M_______UPDATE',
  PlantMachineryRentedPMReturn = 'PLANT_MACHINERY_RENTED_P_M_______RETURN',
  PlantMachineryAssignmentRecordsView = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______VIEW',
  PlantMachineryAssignmentRecordsCreate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CREATE',
  PlantMachineryAssignmentRecordsUpdate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______UPDATE',
  PlantMachineryAssignmentRecordsReturn = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______RETURN',
  PlantMachineryAssignmentRecordsCancel = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CANCEL',
  PlantMachineryAssignmentRecordsSendMaintenance = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______SEND_MAINTENANCE',
  PlantMachineryMaintanenceRecordsView = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______VIEW',
  PlantMachineryMaintanenceRecordsCreate = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CREATE',
  PlantMachineryMaintanenceRecordsReturn = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______RETURN',
  PlantMachineryMaintanenceRecordsCancel = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CANCEL',
  PlantMachineryGeneralSettingsIcon = 'PLANT_MACHINERY_GENERAL_SETTINGS_______ICON',
  PlantMachineryGeneralSettingsPMCategoryView = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  PlantMachineryGeneralSettingsPMCategoryCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  PlantMachineryFuelConsumptionView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_______VIEW',
  PlantMachineryFuelConsumptionFuelTankSetupView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____VIEW',
  PlantMachineryFuelConsumptionFuelTankSetupCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____CREATE',
  PlantMachineryFuelConsumptionFuelTankSetupUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____UPDATE',
  PlantMachineryFuelConsumptionFuelTankSetupDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____DELETE',
  PlantMachineryFuelConsumptionFuelTankMeterReadingView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____VIEW',
  PlantMachineryFuelConsumptionFuelTankMeterReadingCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____CREATE',
  PlantMachineryFuelConsumptionFuelTankMeterReadingUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____UPDATE',
  PlantMachineryFuelConsumptionFuelTankMeterReadingDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____DELETE',
  PlantMachineryFuelConsumptionFuelSupplyView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____VIEW',
  PlantMachineryFuelConsumptionFuelSupplyCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____CREATE',
  PlantMachineryFuelConsumptionFuelSupplyUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____UPDATE',
  PlantMachineryFuelConsumptionFuelSupplyDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____DELETE',
  PlantMachineryFuelConsumptionFuelSupplyApprove = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____APPROVE',
  PlantMachineryFuelConsumptionFuelSupplyReject = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____REJECT',
  PlantMachineryFuelConsumptionFuelDischargeView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____VIEW',
  PlantMachineryFuelConsumptionFuelDischargeCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____CREATE',
  PlantMachineryFuelConsumptionFuelDischargeUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____UPDATE',
  PlantMachineryFuelConsumptionFuelDischargeDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____DELETE',
  PlantMachineryFuelConsumptionFuelDischargeApprove = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____APPROVE',
  PlantMachineryFuelConsumptionFuelDischargeReject = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____REJECT',
  PlantMachineryDigitalReportingView = 'PLANT_MACHINERY_DIGITAL_REPORTING____VIEW',
  PlantMachineryDigitalReportingFuelConsumptionDetailReportView = 'PLANT_MACHINERY_DIGITAL_REPORTING_FUEL_CONSUMPTION_DETAIL_REPORT____VIEW',
  PlantMachineryDigitalReportingFuelConsumptionSummaryReportView = 'PLANT_MACHINERY_DIGITAL_REPORTING_FUEL_CONSUMPTION_SUMMARY_REPORT____VIEW',
  PlantMachineryGeneralSettingsPMCategoryUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  PlantMachineryGeneralSettingsPMCategoryDownload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  PlantMachineryGeneralSettingsPMCategoryUpload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  PlantMachineryGeneralSettingsPMCategoryInactive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  PlantMachineryGeneralSettingsPMCategoryActive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  PlantMachineryGeneralSettingsPMCategoryDelete = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceView = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____VIEW',
  PlantMachineryGeneralSettingsWorkshopMaintenanceCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____CREATE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____UPDATE',
  GlInterfaceIcon = 'GL_INTERFACE__________ICON',
  GlInterfaceView = 'GL_INTERFACE_______VIEW',
  GlInterfaceGeneralLedgerExportView = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____VIEW',
  GlInterfaceGeneralLedgerExportCreate = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____CREATE',
  GlInterfaceGeneralLedgerExportExport = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____EXPORT',
  GlInterfaceCashBookView = 'GL_INTERFACE_CASH_BOOK____VIEW',
  GlInterfaceCashBookCreate = 'GL_INTERFACE_CASH_BOOK____CREATE',
  GlInterfaceCashBookExport = 'GL_INTERFACE_CASH_BOOK____EXPORT',
  GlInterfaceDigitalReportingView = 'GL_INTERFACE_DIGITAL_REPORTING_______VIEW',
  GlInterfaceDigitalReportingTaxListingReportView = 'GL_INTERFACE_DIGITAL_REPORTING_TAX_LISTING_REPORT____VIEW',
  GlInterfaceDigitalReportingGlTaxLedgerReportView = 'GL_INTERFACE_DIGITAL_REPORTING_GL_TAX_LEDGER_REPORT____VIEW',
  GlInterfaceDigitalReportingFiTaxLedgerReportView = 'GL_INTERFACE_DIGITAL_REPORTING_FI_TAX_LEDGER_REPORT____VIEW',
  GlInterfaceDigitalReportingGstSummaryReportView = 'GL_INTERFACE_DIGITAL_REPORTING_GST_SUMMARY_REPORT____VIEW',
  GlInterfaceGeneralSettingsIcon = 'GL_INTERFACE_GENERAL_SETTINGS_______ICON',
  AccessSecurityIcon = 'ACCESS_SECURITY__________ICON',
  AccessSecurityView = 'ACCESS_SECURITY__________VIEW',
  AccessSecurityUserView = 'ACCESS_SECURITY_USER_______VIEW',
  AccessSecurityUserCreate = 'ACCESS_SECURITY_USER_______CREATE',
  AccessSecurityUserUpdate = 'ACCESS_SECURITY_USER_______UPDATE',
  AccessSecurityUserChangePassword = 'ACCESS_SECURITY_USER_______CHANGE_PASSWORD',
  AccessSecurityUserUnblock = 'ACCESS_SECURITY_USER_______UNBLOCK',
  AccessSecurityUserAssign = 'ACCESS_SECURITY_USER_______ASSIGN',
  AccessSecurityRolesView = 'ACCESS_SECURITY_ROLES_______VIEW',
  AccessSecurityRolesCreate = 'ACCESS_SECURITY_ROLES_______CREATE',
  AccessSecurityRolesUpdate = 'ACCESS_SECURITY_ROLES_______UPDATE',
  AccessSecurityRolesDelete = 'ACCESS_SECURITY_ROLES_______DELETE',
  AccessSecurityDigitalReportView = 'ACCESS_SECURITY_DIGITAL_REPORT_______VIEW',
  AccessSecurityProjectRoleAuthView = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____VIEW',
  AccessSecurityProjectRoleAuthCreate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____CREATE',
  AccessSecurityProjectRoleAuthUpdate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____UPDATE',
  CommonSettingsIcon = 'COMMON_SETTINGS__________ICON',
  CommonSettingsCurrencySetupView = 'COMMON_SETTINGS_CURRENCY_SETUP_______VIEW',
  CommonSettingsCurrencySetupCreate = 'COMMON_SETTINGS_CURRENCY_SETUP_______CREATE',
  CommonSettingsCurrencySetupUpdate = 'COMMON_SETTINGS_CURRENCY_SETUP_______UPDATE',
  CommonSettingsCurrencySetupDelete = 'COMMON_SETTINGS_CURRENCY_SETUP_______DELETE',
  CommonSettingsTaxSetupView = 'COMMON_SETTINGS_TAX_SETUP_______VIEW',
  CommonSettingsTaxSetupCreate = 'COMMON_SETTINGS_TAX_SETUP_______CREATE',
  CommonSettingsTaxSetupUpdate = 'COMMON_SETTINGS_TAX_SETUP_______UPDATE',
  CommonSettingsTaxSetupDelete = 'COMMON_SETTINGS_TAX_SETUP_______DELETE',
  CommonSettingsTaxSetupInactive = 'COMMON_SETTINGS_TAX_SETUP_______INACTIVE',
  CommonSettingsTaxSetupActive = 'COMMON_SETTINGS_TAX_SETUP_______ACTIVE',
  CommonSettingsTaxSetupViewHistory = 'COMMON_SETTINGS_TAX_SETUP_______VIEW_HISTORY',
  CommonSettingsUomSetupView = 'COMMON_SETTINGS_UOM_SETUP_______VIEW',
  CommonSettingsUomSetupCreate = 'COMMON_SETTINGS_UOM_SETUP_______CREATE',
  CommonSettingsUomSetupUpdate = 'COMMON_SETTINGS_UOM_SETUP_______UPDATE',
  CommonSettingsUomSetupDelete = 'COMMON_SETTINGS_UOM_SETUP_______DELETE',
  CommonSettingsUomSetupInactive = 'COMMON_SETTINGS_UOM_SETUP_______INACTIVE',
  CommonSettingsUomSetupActive = 'COMMON_SETTINGS_UOM_SETUP_______ACTIVE',
  CommonSettingsUomSetupDownload = 'COMMON_SETTINGS_UOM_SETUP_______DOWNLOAD',
  CommonSettingsUomSetupUpload = 'COMMON_SETTINGS_UOM_SETUP_______UPLOAD',
  CommonSettingsUomConversionSetupView = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______VIEW',
  CommonSettingsUomConversionSetupUpdate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______UPDATE',
  CommonSettingsUomConversionSetupCreate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______CREATE',
  CommonSettingsUomConversionSetupDelete = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______DELETE',
  CommonSettingsAlertAssignmentView = 'COMMON_SETTINGS_ALERT_ASSIGNMENT_______VIEW',
  CommonSettingsAlertAssignmentUpdate = 'COMMON_SETTINGS_ALERT_ASSIGNMENT_______UPDATE',
  CompanySetupIcon = 'COMPANY_SETUP__________ICON',
  CompanySetupView = 'COMPANY_SETUP__________VIEW',
  CompanySetupCreate = 'COMPANY_SETUP__________CREATE',
  CompanySetupCompanyProfileView = 'COMPANY_SETUP_COMPANY_PROFILE_______VIEW',
  CompanySetupCompanyProfileUpdate = 'COMPANY_SETUP_COMPANY_PROFILE_______UPDATE',
  CompanySetupDocumentNumberingSetupView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_______VIEW',
  CompanySetupDocumentNumberingSetupAccountReceivableView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_ACCOUNT_RECEIVABLE____VIEW',
  CompanySetupDocumentNumberingSetupAccountPayableView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_ACCOUNT_PAYABLE____VIEW',
  CompanySetupDocumentNumberingSetupProjectPurchaseView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_PROJECT_PURCHASE____VIEW',
  CompanySetupDocumentNumberingSetupSiteManagementView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_SITE_MANAGEMENT____VIEW',
  CompanySetupBankAccountView = 'COMPANY_SETUP_BANK_ACCOUNT_______VIEW',
  CompanySetupBankAccountCreate = 'COMPANY_SETUP_BANK_ACCOUNT_______CREATE',
  CompanySetupBankAccountUpdate = 'COMPANY_SETUP_BANK_ACCOUNT_______UPDATE',
  CompanySetupBankAccountActive = 'COMPANY_SETUP_BANK_ACCOUNT_______ACTIVE',
  CompanySetupBankAccountInactive = 'COMPANY_SETUP_BANK_ACCOUNT_______INACTIVE',
  CompanySetupBankAccountDelete = 'COMPANY_SETUP_BANK_ACCOUNT_______DELETE',
  CompanySetupWatermarkLogoSetupUpdate = 'COMPANY_SETUP_WATERMARK_LOGO_SETUP_______UPDATE',
  CompanySetupCompanyAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_______VIEW',
  CompanySetupCompanyAssignmentUserAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_USER_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentUserAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_USER_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentUserAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_USER_ASSIGNMENT____UNASSIGN',
  CompanySetupCompanyAssignmentSupplierAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUPPLIER_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentSupplierAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUPPLIER_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentSupplierAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUPPLIER_ASSIGNMENT____UNASSIGN',
  CompanySetupCompanyAssignmentClientAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_CLIENT_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentClientAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_CLIENT_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentClientAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_CLIENT_ASSIGNMENT____UNASSIGN',
  CompanySetupCompanyAssignmentSubcontractorAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUBCONTRACTOR_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentSubcontractorAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUBCONTRACTOR_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentSubcontractorAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUBCONTRACTOR_ASSIGNMENT____UNASSIGN',
  GeneralSettingsIcon = 'GENERAL_SETTINGS__________ICON',
  GeneralSettingsSubcontractTradeView = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______VIEW',
  GeneralSettingsSubcontractTradeCreate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______CREATE',
  GeneralSettingsSubcontractTradeUpdate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPDATE',
  GeneralSettingsSubcontractTradeActive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______ACTIVE',
  GeneralSettingsSubcontractTradeInactive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______INACTIVE',
  GeneralSettingsSubcontractTradeDelete = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DELETE',
  GeneralSettingsSubcontractTradeUpload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPLOAD',
  GeneralSettingsSubcontractTradeDownload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DOWNLOAD',
  GeneralSettingsCostCategoryView = 'GENERAL_SETTINGS_COST_CATEGORY_______VIEW',
  GeneralSettingsCostCategoryCreate = 'GENERAL_SETTINGS_COST_CATEGORY_______CREATE',
  GeneralSettingsCostCategoryUpdate = 'GENERAL_SETTINGS_COST_CATEGORY_______UPDATE',
  GeneralSettingsCostCategoryActive = 'GENERAL_SETTINGS_COST_CATEGORY_______ACTIVE',
  GeneralSettingsCostCategoryInactive = 'GENERAL_SETTINGS_COST_CATEGORY_______INACTIVE',
  GeneralSettingsCostCategoryDelete = 'GENERAL_SETTINGS_COST_CATEGORY_______DELETE',
  GeneralSettingsCostCategoryDownload = 'GENERAL_SETTINGS_COST_CATEGORY_______DOWNLOAD',
  GeneralSettingsCostCategoryUpload = 'GENERAL_SETTINGS_COST_CATEGORY_______UPLOAD',
  GeneralSettingsLabourCategoryView = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______VIEW',
  GeneralSettingsLabourCategoryCreate = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______CREATE',
  GeneralSettingsLabourCategoryUpdate = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______UPDATE',
  GeneralSettingsLabourCategoryActive = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______ACTIVE',
  GeneralSettingsLabourCategoryInactive = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______INACTIVE',
  GeneralSettingsLabourCategoryDelete = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______DELETE',
  GeneralSettingsLabourCategoryUpload = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______UPLOAD',
  GeneralSettingsLabourCategoryDownload = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______DOWNLOAD',
  GeneralSettingsBuildingMaterialView = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______VIEW',
  GeneralSettingsBuildingMaterialCreate = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______CREATE',
  GeneralSettingsBuildingMaterialUpdate = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______UPDATE',
  GeneralSettingsBuildingMaterialActive = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______ACTIVE',
  GeneralSettingsBuildingMaterialInactive = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______INACTIVE',
  GeneralSettingsBuildingMaterialDelete = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______DELETE',
  GeneralSettingsBuildingMaterialUpload = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______UPLOAD',
  GeneralSettingsBuildingMaterialDownload = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______DOWNLOAD',
  GeneralSettingsMiscellaneousExpenseView = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______VIEW',
  GeneralSettingsMiscellaneousExpenseCreate = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______CREATE',
  GeneralSettingsMiscellaneousExpenseUpdate = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPDATE',
  GeneralSettingsMiscellaneousExpenseActive = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______ACTIVE',
  GeneralSettingsMiscellaneousExpenseInactive = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______INACTIVE',
  GeneralSettingsMiscellaneousExpenseDelete = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DELETE',
  GeneralSettingsMiscellaneousExpenseUpload = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPLOAD',
  GeneralSettingsMiscellaneousExpenseDownload = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DOWNLOAD',
  GeneralSettingsGuaranteeTypeView = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______VIEW',
  GeneralSettingsGuaranteeTypeCreate = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______CREATE',
  GeneralSettingsGuaranteeTypeUpdate = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______UPDATE',
  GeneralSettingsGuaranteeTypeInactive = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______INACTIVE',
  GeneralSettingsGuaranteeTypeDelete = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______DELETE',
  GeneralSettingsSupplierTypeView = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______VIEW',
  GeneralSettingsSupplierTypeCreate = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______CREATE',
  GeneralSettingsSupplierTypeUpdate = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______UPDATE',
  GeneralSettingsSupplierTypeDelete = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______DELETE',
  GeneralSettingsSupplierTypeActive = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______ACTIVE',
  GeneralSettingsSupplierTypeInactive = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______INACTIVE',
  GeneralSettingsSubcontractorTypeView = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______VIEW',
  GeneralSettingsSubcontractorTypeCreate = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______CREATE',
  GeneralSettingsSubcontractorTypeUpdate = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______UPDATE',
  GeneralSettingsSubcontractorTypeDelete = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______DELETE',
  GeneralSettingsSubcontractorTypeActive = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______ACTIVE',
  GeneralSettingsSubcontractorTypeInactive = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______INACTIVE',
  GeneralSettingsInsuranceTypeView = 'GENERAL_SETTINGS_INSURANCE_TYPE_______VIEW',
  GeneralSettingsInsuranceTypeCreate = 'GENERAL_SETTINGS_INSURANCE_TYPE_______CREATE',
  GeneralSettingsInsuranceTypeUpdate = 'GENERAL_SETTINGS_INSURANCE_TYPE_______UPDATE',
  GeneralSettingsInsuranceTypeInactive = 'GENERAL_SETTINGS_INSURANCE_TYPE_______INACTIVE',
  GeneralSettingsInsuranceTypeDelete = 'GENERAL_SETTINGS_INSURANCE_TYPE_______DELETE',
  GeneralSettingsWorkingHoursPolicyView = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____VIEW',
  GeneralSettingsWorkingHoursPolicyUpdate = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  GeneralSettingsWagesRatePolicyCreate = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____CREATE',
  GeneralSettingsWagesRatePolicyView = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  GeneralSettingsWagesRatePolicyUpdate = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  GeneralSettingsWorkerProfileCreate = 'GENERAL_SETTINGS_WORKER_PROFILE____CREATE',
  GeneralSettingsWorkerProfileView = 'GENERAL_SETTINGS_WORKER_PROFILE____VIEW',
  GeneralSettingsWorkerProfileUpdate = 'GENERAL_SETTINGS_WORKER_PROFILE____UPDATE',
  GeneralSettingsPmCategoryView = 'GENERAL_SETTINGS_PM_CATEGORY_______VIEW',
  GeneralSettingsPmCategoryCreate = 'GENERAL_SETTINGS_PM_CATEGORY_______CREATE',
  GeneralSettingsPmCategoryUpdate = 'GENERAL_SETTINGS_PM_CATEGORY_______UPDATE',
  GeneralSettingsPmCategoryActive = 'GENERAL_SETTINGS_PM_CATEGORY_______ACTIVE',
  GeneralSettingsPmCategoryInactive = 'GENERAL_SETTINGS_PM_CATEGORY_______INACTIVE',
  GeneralSettingsPmCategoryDelete = 'GENERAL_SETTINGS_PM_CATEGORY_______DELETE',
  GeneralSettingsPmCategoryUpload = 'GENERAL_SETTINGS_PM_CATEGORY_______UPLOAD',
  GeneralSettingsPmCategoryDownload = 'GENERAL_SETTINGS_PM_CATEGORY_______DOWNLOAD',
  GeneralSettingsWorkshopMaintenanceView = 'GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______VIEW',
  GeneralSettingsWorkshopMaintenanceCreate = 'GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______CREATE',
  GeneralSettingsWorkshopMaintenanceUpdate = 'GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______UPDATE',
  GeneralSettingsClientAccountSetupView = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsClientAccountSetupCreate = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______CREATE',
  GeneralSettingsClientAccountSetupUpdate = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsClientAccountSetupAssign = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______ASSIGN',
  GeneralSettingsClientAccountSetupDownload = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______DOWNLOAD',
  GeneralSettingsClientAccountSetupUpload = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______UPLOAD',
  GeneralSettingsSubcontractorAccountSetupView = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsSubcontractorAccountSetupCreate = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______CREATE',
  GeneralSettingsSubcontractorAccountSetupUpdate = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsSubcontractorAccountSetupAssign = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______ASSIGN',
  GeneralSettingsSubcontractorAccountSetupDownload = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______DOWNLOAD',
  GeneralSettingsSubcontractorAccountSetupUpload = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______UPLOAD',
  GeneralSettingsSupplierAccountSetupView = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsSupplierAccountSetupCreate = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______CREATE',
  GeneralSettingsSupplierAccountSetupUpdate = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsSupplierAccountSetupAssign = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______ASSIGN',
  GeneralSettingsSupplierAccountSetupDownload = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______DOWNLOAD',
  GeneralSettingsSupplierAccountSetupUpload = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______UPLOAD',
  GeneralSettingsPurchaseAccountSetupView = 'GENERAL_SETTINGS_PURCHASE_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsPurchaseAccountSetupUpdate = 'GENERAL_SETTINGS_PURCHASE_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsPobAccountSetupView = 'GENERAL_SETTINGS_POB_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsPobAccountSetupUpdate = 'GENERAL_SETTINGS_POB_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsBankAccountSetupView = 'GENERAL_SETTINGS_BANK_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsBankAccountSetupUpdate = 'GENERAL_SETTINGS_BANK_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsTaxAccountSetupView = 'GENERAL_SETTINGS_TAX_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsTaxAccountSetupUpdate = 'GENERAL_SETTINGS_TAX_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsTradeAccountSetupView = 'GENERAL_SETTINGS_TRADE_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsTradeAccountSetupUpdate = 'GENERAL_SETTINGS_TRADE_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsMaterialCategorySetupView = 'GENERAL_SETTINGS_MATERIAL_CATEGORY_SETUP_______VIEW',
  GeneralSettingsMaterialCategorySetupUpdate = 'GENERAL_SETTINGS_MATERIAL_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsPmCategorySetupView = 'GENERAL_SETTINGS_PM_CATEGORY_SETUP_______VIEW',
  GeneralSettingsPmCategorySetupUpdate = 'GENERAL_SETTINGS_PM_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsLabourCategorySetupView = 'GENERAL_SETTINGS_LABOUR_CATEGORY_SETUP_______VIEW',
  GeneralSettingsLabourCategorySetupUpdate = 'GENERAL_SETTINGS_LABOUR_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsMiscCategorySetupView = 'GENERAL_SETTINGS_MISC_CATEGORY_SETUP_______VIEW',
  GeneralSettingsMiscCategorySetupUpdate = 'GENERAL_SETTINGS_MISC_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsJournalTypeSetupView = 'GENERAL_SETTINGS_JOURNAL_TYPE_SETUP_______VIEW',
  GeneralSettingsJournalTypeSetupUpdate = 'GENERAL_SETTINGS_JOURNAL_TYPE_SETUP_______UPDATE',
  GeneralSettingsGlAccountCodeSetupView = 'GENERAL_SETTINGS_GL_ACCOUNT_CODE_SETUP_______VIEW',
  GeneralSettingsGlAccountCodeSetupCreate = 'GENERAL_SETTINGS_GL_ACCOUNT_CODE_SETUP_______CREATE',
  GeneralSettingsStandardApprovalPolicyView = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_______VIEW',
  GeneralSettingsStandardApprovalPolicyUpdate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____UPDATE',
  GeneralSettingsStandardApprovalPolicyCreate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____CREATE',
  GeneralSettingsRevenueCategoryView = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______VIEW',
  GeneralSettingsRevenueCategoryCreate = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______CREATE',
  GeneralSettingsRevenueCategoryUpdate = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______UPDATE',
  GeneralSettingsRevenueCategoryActive = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______ACTIVE',
  GeneralSettingsRevenueCategoryInactive = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______INACTIVE',
  GeneralSettingsRevenueCategoryDelete = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______DELETE',
  GeneralSettingsRevenueCategoryDownload = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______DOWNLOAD',
  GeneralSettingsRevenueCategoryUpload = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______UPLOAD',
  GeneralSettingsLabourItemsView = 'GENERAL_SETTINGS_LABOUR_ITEMS_______VIEW',
  GeneralSettingsLabourItemsCreate = 'GENERAL_SETTINGS_LABOUR_ITEMS_______CREATE',
  GeneralSettingsLabourItemsUpdate = 'GENERAL_SETTINGS_LABOUR_ITEMS_______UPDATE',
  GeneralSettingsLabourItemsActive = 'GENERAL_SETTINGS_LABOUR_ITEMS_______ACTIVE',
  GeneralSettingsLabourItemsInactive = 'GENERAL_SETTINGS_LABOUR_ITEMS_______INACTIVE',
  GeneralSettingsLabourItemsDelete = 'GENERAL_SETTINGS_LABOUR_ITEMS_______DELETE',
  GeneralSettingsLabourItemsDownload = 'GENERAL_SETTINGS_LABOUR_ITEMS_______DOWNLOAD',
  GeneralSettingsLabourItemsUpload = 'GENERAL_SETTINGS_LABOUR_ITEMS_______UPLOAD',
  GeneralSettingsMaterialItemsView = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______VIEW',
  GeneralSettingsMaterialItemsCreate = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______CREATE',
  GeneralSettingsMaterialItemsUpdate = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______UPDATE',
  GeneralSettingsMaterialItemsActive = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______ACTIVE',
  GeneralSettingsMaterialItemsInactive = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______INACTIVE',
  GeneralSettingsMaterialItemsDelete = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______DELETE',
  GeneralSettingsMaterialItemsDownload = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______DOWNLOAD',
  GeneralSettingsMaterialItemsUpload = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______UPLOAD',
  GeneralSettingsPmItemsView = 'GENERAL_SETTINGS_PM_ITEMS_______VIEW',
  GeneralSettingsPmItemsCreate = 'GENERAL_SETTINGS_PM_ITEMS_______CREATE',
  GeneralSettingsPmItemsUpdate = 'GENERAL_SETTINGS_PM_ITEMS_______UPDATE',
  GeneralSettingsPmItemsActive = 'GENERAL_SETTINGS_PM_ITEMS_______ACTIVE',
  GeneralSettingsPmItemsInactive = 'GENERAL_SETTINGS_PM_ITEMS_______INACTIVE',
  GeneralSettingsPmItemsDelete = 'GENERAL_SETTINGS_PM_ITEMS_______DELETE',
  GeneralSettingsPmItemsDownload = 'GENERAL_SETTINGS_PM_ITEMS_______DOWNLOAD',
  GeneralSettingsPmItemsUpload = 'GENERAL_SETTINGS_PM_ITEMS_______UPLOAD',
  GeneralSettingsMiscExpenseItemsView = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______VIEW',
  GeneralSettingsMiscExpenseItemsCreate = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______CREATE',
  GeneralSettingsMiscExpenseItemsUpdate = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______UPDATE',
  GeneralSettingsMiscExpenseItemsActive = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______ACTIVE',
  GeneralSettingsMiscExpenseItemsInactive = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______INACTIVE',
  GeneralSettingsMiscExpenseItemsDelete = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______DELETE',
  GeneralSettingsMiscExpenseItemsDownload = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______DOWNLOAD',
  GeneralSettingsMiscExpenseItemsUpload = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______UPLOAD',
  GeneralSettingsPaymentReminderSettingView = 'GENERAL_SETTINGS_PAYMENT_REMINDER_SETTING_______VIEW',
  GeneralSettingsSubcontractTradeView = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  GeneralSettingsSubcontractTradeCreate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  GeneralSettingsSubcontractTradeUpdate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  GeneralSettingsSubcontractTradeInactive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  GeneralSettingsSubcontractTradeActive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  GeneralSettingsSubcontractTradeDelete = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  GeneralSettingsSubcontractTradeDownload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  GeneralSettingsSubcontractTradeUpload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  GeneralSettingsPublicHolidayView = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  GeneralSettingsPublicHolidayCreate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  GeneralSettingsPublicHolidayUpdate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  GeneralSettingsPublicHolidayInactive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____INACTIVE',
  GeneralSettingsPublicHolidayActive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  GeneralSettingsPublicHolidayDelete = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  GeneralSettingsPublicHolidayCancel = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  GeneralSettingsCalendarPolicyView = 'GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  GeneralSettingsCalendarPolicyCreate = 'GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  GeneralSettingsCalendarPolicyUpdate = 'GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  GeneralSettingsJobTypeView = 'GENERAL_SETTINGS_JOB_TYPE____VIEW',
  GeneralSettingsJobTypeCreate = 'GENERAL_SETTINGS_JOB_TYPE____CREATE',
  GeneralSettingsJobTypeUpdate = 'GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  GeneralSettingsJobTypeInactive = 'GENERAL_SETTINGS_JOB_TYPE____INACTIVE',
  GeneralSettingsJobTypeActive = 'GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  GeneralSettingsJobTypeCancel = 'GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  GeneralSettingsJobTypeDelete = 'GENERAL_SETTINGS_JOB_TYPE____DELETE',
  GeneralSettingsBuildingMaterialView = 'GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  GeneralSettingsBuildingMaterialCreate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  GeneralSettingsBuildingMaterialUpdate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsBuildingMaterialInactive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsBuildingMaterialActive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsBuildingMaterialDelete = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  GeneralSettingsBuildingMaterialDownload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsBuildingMaterialUpload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteBuildingMaterialView = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____VIEW',
  GeneralSettingsSiteBuildingMaterialCreate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____CREATE',
  GeneralSettingsSiteBuildingMaterialUpdate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsSiteBuildingMaterialInactive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsSiteBuildingMaterialActive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsSiteBuildingMaterialDelete = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DELETE',
  GeneralSettingsSiteBuildingMaterialDownload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsSiteBuildingMaterialUpload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteMaterialPolicyView = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____VIEW',
  GeneralSettingsSiteMaterialPolicyUpdate = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____UPDATE',
  GeneralSettingsVideoCamSetupView = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  GeneralSettingsVideoCamSetupCreate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  GeneralSettingsVideoCamSetupUpdate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  GeneralSettingsVideoCamSetupDelete = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  SystemAdminIcon = 'SYSTEM_ADMIN__________ICON',
  SystemAdminView = 'SYSTEM_ADMIN__________VIEW',
  SystemAdminProjectSiteView = 'SYSTEM_ADMIN_PROJECT_SITE_______VIEW',
  SystemAdminProjectSiteCreate = 'SYSTEM_ADMIN_PROJECT_SITE_______CREATE',
  SystemAdminProjectSiteUpdate = 'SYSTEM_ADMIN_PROJECT_SITE_______UPDATE',
  SystemAdminProjectSiteDelete = 'SYSTEM_ADMIN_PROJECT_SITE_______DELETE',
  SystemAdminSubconSetupView = 'SYSTEM_ADMIN_SUBCON_SETUP_______VIEW',
  SystemAdminSubconSetupCreate = 'SYSTEM_ADMIN_SUBCON_SETUP_______CREATE',
  SystemAdminSubconSetupUpdate = 'SYSTEM_ADMIN_SUBCON_SETUP_______UPDATE',
  SystemAdminSubconSetupDelete = 'SYSTEM_ADMIN_SUBCON_SETUP_______DELETE',
  SystemAdminTaxSetupView = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW',
  SystemAdminTaxSetupCreate = 'SYSTEM_ADMIN_TAX_SETUP_______CREATE',
  SystemAdminTaxSetupUpdate = 'SYSTEM_ADMIN_TAX_SETUP_______UPDATE',
  SystemAdminTaxSetupDelete = 'SYSTEM_ADMIN_TAX_SETUP_______DELETE',
  SystemAdminTaxSetupActive = 'SYSTEM_ADMIN_TAX_SETUP_______ACTIVE',
  SystemAdminTaxSetupInactive = 'SYSTEM_ADMIN_TAX_SETUP_______INACTIVE',
  SystemAdminTaxSetupViewHistory = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW_HISTORY',
  SystemAdminUomSetupView = 'SYSTEM_ADMIN_UOM_SETUP_______VIEW',
  SystemAdminUomSetupCreate = 'SYSTEM_ADMIN_UOM_SETUP_______CREATE',
  SystemAdminUomSetupUpdate = 'SYSTEM_ADMIN_UOM_SETUP_______UPDATE',
  SystemAdminUomSetupDelete = 'SYSTEM_ADMIN_UOM_SETUP_______DELETE',
  SystemAdminUomSetupActive = 'SYSTEM_ADMIN_UOM_SETUP_______ACTIVE',
  SystemAdminUomSetupInactive = 'SYSTEM_ADMIN_UOM_SETUP_______INACTIVE',
  SystemAdminUomSetupDownload = 'SYSTEM_ADMIN_UOM_SETUP_______DOWNLOAD',
  SystemAdminUomSetupUpload = 'SYSTEM_ADMIN_UOM_SETUP_______UPLOAD',
  SystemAdminUomConversionView = 'SYSTEM_ADMIN_UOM_CONVERSION_______VIEW',
  SystemAdminUomConversionCreate = 'SYSTEM_ADMIN_UOM_CONVERSION_______CREATE',
  SystemAdminUomConversionUpdate = 'SYSTEM_ADMIN_UOM_CONVERSION_______UPDATE',
  SystemAdminUomConversionDelete = 'SYSTEM_ADMIN_UOM_CONVERSION_______DELETE',
  SystemAdminCompanySetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_______VIEW',
  SystemAdminCompanySetupUpdate = 'SYSTEM_ADMIN_COMPANY_SETUP_______UPDATE',
  SystemAdminCompanySetupWatermarkLogoSetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_WATERMARK_LOGO_SETUP____VIEW',
  SystemAdminContractSettingsView = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______VIEW',
  GroupBusinessInsightIcon = 'GROUP_BUSINESS_INSIGHT__________ICON',
  GroupBusinessInsightView = 'GROUP_BUSINESS_INSIGHT__________VIEW',
  GroupBusinessInsightKeyKpiView = 'GROUP_BUSINESS_INSIGHT_KEY_KPI_______VIEW',
  GroupBusinessInsightSiteGalleryView = 'GROUP_BUSINESS_INSIGHT_SITE_GALLERY_______VIEW',
  GroupBusinessInsightProjectCostingView = 'GROUP_BUSINESS_INSIGHT_PROJECT_COSTING_______VIEW',
  GroupBusinessInsightProjectPlView = 'GROUP_BUSINESS_INSIGHT_PROJECT_PL_______VIEW',
  GroupBusinessInsightCashFlowView = 'GROUP_BUSINESS_INSIGHT_CASH_FLOW_______VIEW',
  GroupBusinessInsightArView = 'GROUP_BUSINESS_INSIGHT_AR_______VIEW',
  GroupBusinessInsightVoView = 'GROUP_BUSINESS_INSIGHT_VO_______VIEW',
  DefectMgmtIcon = 'DEFECT_MGMT__________ICON',
  DefectMgmtView = 'DEFECT_MGMT__________VIEW',
  DefectMgmtCreate = 'DEFECT_MGMT__________CREATE',
  DefectMgmtUpdate = 'DEFECT_MGMT__________UPDATE',
  DefectMgmtActivityLogCreate = 'DEFECT_MGMT_ACTIVITY_LOG_______CREATE',
  DefectMgmtActivityLogView = 'DEFECT_MGMT_ACTIVITY_LOG_______VIEW',
  DefectMgmtActivityLogUpdate = 'DEFECT_MGMT_ACTIVITY_LOG_______UPDATE',
  DefectMgmtDefectAssignmentView = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______VIEW',
  DefectMgmtDefectAssignmentCreate = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______CREATE',
  DefectMgmtGeneralSettingsIcon = 'DEFECT_MGMT_GENERAL_SETTINGS_______ICON',
  DefectMgmtGeneralSettingsSubcontractPackageView = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____VIEW',
  DefectMgmtGeneralSettingsSubcontractPackageCreate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____CREATE',
  DefectMgmtGeneralSettingsSubcontractPackageUpdate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPDATE',
  DefectMgmtGeneralSettingsSubcontractPackageDelete = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DELETE',
  DefectMgmtGeneralSettingsSubcontractPackageDownload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DOWNLOAD',
  DefectMgmtGeneralSettingsSubcontractPackageUpload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPLOAD',
  SiteAttendanceIcon = 'SITE_ATTENDANCE__________ICON',
  SiteAttendanceView = 'SITE_ATTENDANCE__________VIEW',
  SiteAttendanceWorkerProfileCreate = 'SITE_ATTENDANCE_WORKER_PROFILE_______CREATE',
  SiteAttendanceWorkerProfileView = 'SITE_ATTENDANCE_WORKER_PROFILE_______VIEW',
  SiteAttendanceWorkerProfileUpdate = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPDATE',
  SiteAttendanceWorkerProfileDelete = 'SITE_ATTENDANCE_WORKER_PROFILE_______DELETE',
  SiteAttendanceWorkerProfileDownload = 'SITE_ATTENDANCE_WORKER_PROFILE_______DOWNLOAD',
  SiteAttendanceWorkerProfileUpload = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPLOAD',
  SiteAttendanceWorkerAttendanceCreate = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______CREATE',
  SiteAttendanceWorkerAttendanceView = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______VIEW',
  SiteAttendanceWorkerAttendanceExport = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______EXPORT',
  SiteAttendanceGeneralSettingsIcon = 'SITE_ATTENDANCE_GENERAL_SETTINGS_______ICON',
  SiteAttendanceGeneralSettingsPublicHolidayCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteAttendanceGeneralSettingsPublicHolidayView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteAttendanceGeneralSettingsPublicHolidayUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteAttendanceGeneralSettingsPublicHolidayDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteAttendanceGeneralSettingsPublicHolidayCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteAttendanceGeneralSettingsPublicHolidayActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteAttendanceGeneralSettingsCalendarPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteAttendanceGeneralSettingsCalendarPolicyView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteAttendanceGeneralSettingsCalendarPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SiteAttendanceGeneralSettingsJobTypeView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SiteAttendanceGeneralSettingsJobTypeUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SiteAttendanceGeneralSettingsJobTypeCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SiteAttendanceGeneralSettingsJobTypeActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SiteWagesIcon = 'SITE_WAGES__________ICON',
  SiteWagesWorkerAttendanceView = 'SITE_WAGES_WORKER_ATTENDANCE__________VIEW',
  SiteWagesWorkerAttendanceCreate = 'SITE_WAGES_WORKER_ATTENDANCE__________CREATE',
  SiteWagesView = 'SITE_WAGES__________VIEW',
  SiteWagesDailyRegisteredWorkHoursCreate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______CREATE',
  SiteWagesDailyRegisteredWorkHoursView = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______VIEW',
  SiteWagesDailyRegisteredWorkHoursUpdate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______UPDATE',
  SiteWagesDailyRegisteredWorkHoursDelete = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______DELETE',
  SiteWagesDailyWagesVerificationCreate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______CREATE',
  SiteWagesDailyWagesVerificationView = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______VIEW',
  SiteWagesDailyWagesVerificationUpdate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______UPDATE',
  SiteWagesDailyWagesVerificationDelete = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______DELETE',
  SiteWagesWagesBookView = 'SITE_WAGES_WAGES_BOOK_______VIEW',
  SiteWagesWagesBookCreate = 'SITE_WAGES_WAGES_BOOK_______CREATE',
  SiteWagesWagesBookUpdate = 'SITE_WAGES_WAGES_BOOK_______UPDATE',
  SiteWagesWagesBookDelete = 'SITE_WAGES_WAGES_BOOK_______DELETE',
  SiteWagesWageCycleClosureCreate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______CREATE',
  SiteWagesWageCycleClosureView = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______VIEW',
  SiteWagesWageCycleClosureUpdate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______UPDATE',
  SiteWagesWageCycleClosureDelete = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______DELETE',
  SiteWagesGeneralSettingsIcon = 'SITE_WAGES_GENERAL_SETTINGS_______ICON',
  SiteInventoryIcon = 'SITE_INVENTORY__________ICON',
  SiteInventoryView = 'SITE_INVENTORY__________VIEW',
  SiteInventoryGoodReceiveNoteCreate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CREATE',
  SiteInventoryGoodReceiveNoteView = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______VIEW',
  SiteInventoryGoodReceiveNoteUpdate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______UPDATE',
  SiteInventoryGoodReceiveNoteApproveReject = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  SiteInventoryGoodReceiveNoteCancel = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CANCEL',
  SiteInventoryGoodReceiveNoteDelete = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______DELETE',
  SiteInventoryStockIssueCreate = 'SITE_INVENTORY_STOCK_ISSUE_______CREATE',
  SiteInventoryStockIssueView = 'SITE_INVENTORY_STOCK_ISSUE_______VIEW',
  SiteInventoryStockIssueUpdate = 'SITE_INVENTORY_STOCK_ISSUE_______UPDATE',
  SiteInventoryStockIssueDelete = 'SITE_INVENTORY_STOCK_ISSUE_______DELETE',
  SiteInventoryStockIssueDownload = 'SITE_INVENTORY_STOCK_ISSUE_______DOWNLOAD',
  SiteInventoryStockIssueApproveReject = 'SITE_INVENTORY_STOCK_ISSUE_______APPROVE_REJECT',
  SiteInventoryStockIssueCancel = 'SITE_INVENTORY_STOCK_ISSUE_______CANCEL',
  SiteInventoryStockTransferInCreate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CREATE',
  SiteInventoryStockTransferInView = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______VIEW',
  SiteInventoryStockTransferInUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______UPDATE',
  SiteInventoryStockTransferInDelete = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DELETE',
  SiteInventoryStockTransferInDownload = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DOWNLOAD',
  SiteInventoryStockTransferInApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______APPROVE_REJECT',
  SiteInventoryStockTransferInCancel = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CANCEL',
  SiteInventoryStockTransferOutCreate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CREATE',
  SiteInventoryStockTransferOutView = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______VIEW',
  SiteInventoryStockTransferOutUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______UPDATE',
  SiteInventoryStockTransferOutDelete = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DELETE',
  SiteInventoryStockTransferOutDownload = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DOWNLOAD',
  SiteInventoryStockTransferOutApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______APPROVE_REJECT',
  SiteInventoryStockTransferOutCancel = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CANCEL',
  SiteInventoryStockAdjustmentCreate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CREATE',
  SiteInventoryStockAdjustmentView = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______VIEW',
  SiteInventoryStockAdjustmentUpdate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______UPDATE',
  SiteInventoryStockAdjustmentDelete = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DELETE',
  SiteInventoryStockAdjustmentDownload = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DOWNLOAD',
  SiteInventoryStockAdjustmentApproveReject = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______APPROVE_REJECT',
  SiteInventoryStockAdjustmentCancel = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CANCEL',
  SiteInventoryStockMovementCreate = 'SITE_INVENTORY_STOCK_MOVEMENT_______CREATE',
  SiteInventoryStockMovementView = 'SITE_INVENTORY_STOCK_MOVEMENT_______VIEW',
  SiteInventoryStockMovementUpdate = 'SITE_INVENTORY_STOCK_MOVEMENT_______UPDATE',
  SiteInventoryStockMovementDelete = 'SITE_INVENTORY_STOCK_MOVEMENT_______DELETE',
  SiteInventorySiteUsageComparisonCreate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______CREATE',
  SiteInventorySiteUsageComparisonView = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______VIEW',
  SiteInventorySiteUsageComparisonUpdate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______UPDATE',
  SiteInventorySiteUsageComparisonDelete = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______DELETE',
  SiteInventorySiteUsageComparisonExport = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______EXPORT',
  SiteInventorySiteWorkerAttendanceTrackingView = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______VIEW',
  SiteInventorySiteWorkerAttendanceTrackingExport = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______EXPORT',
  SiteInventoryStockUsageAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______CREATE',
  SiteInventoryStockUsageAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______VIEW',
  SiteInventoryStockUsageAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______DELETE',
  SiteInventoryStockUsageAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageDailyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageDailyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageDailyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageDailyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageDailyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageMonthlyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageMonthlyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageMonthlyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageMonthlyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageMonthlyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______EXPORT',
  SiteInventoryStockMoveInOutAnalysisCreate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______CREATE',
  SiteInventoryStockMoveInOutAnalysisView = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______VIEW',
  SiteInventoryStockMoveInOutAnalysisUpdate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______UPDATE',
  SiteInventoryStockMoveInOutAnalysisDelete = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______DELETE',
  SiteInventoryStockMoveInOutAnalysisExport = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______EXPORT',
  SiteInventorySiteUsageAnalysisCreate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______CREATE',
  SiteInventorySiteUsageAnalysisView = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______VIEW',
  SiteInventorySiteUsageAnalysisUpdate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______UPDATE',
  SiteInventorySiteUsageAnalysisDelete = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______DELETE',
  SiteInventorySiteUsageAnalysisExport = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryDigitalReportingView = 'SITE_INVENTORY_DIGITAL_REPORTING_______VIEW',
  SiteInventoryDigitalReportingStockMovementLedgerReportView = 'SITE_INVENTORY_DIGITAL_REPORTING_STOCK_MOVEMENT_LEDGER_REPORT____VIEW',
  SiteInventoryGeneralSettingsIcon = 'SITE_INVENTORY_GENERAL_SETTINGS_______ICON',
  SiteInventoryGeneralSettingsSiteMaterialCreate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CREATE',
  SiteInventoryGeneralSettingsSiteMaterialView = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____VIEW',
  SiteInventoryGeneralSettingsSiteMaterialUpdate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPDATE',
  SiteInventoryGeneralSettingsSiteMaterialDelete = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DELETE',
  SiteInventoryGeneralSettingsSiteMaterialCancel = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CANCEL',
  SiteInventoryGeneralSettingsSiteMaterialActive = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____ACTIVE',
  SiteInventoryGeneralSettingsSiteMaterialDownload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DOWNLOAD',
  SiteInventoryGeneralSettingsSiteMaterialUpload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPLOAD',
  SiteInventoryContractSettingsIcon = 'SITE_INVENTORY_CONTRACT_SETTINGS_______ICON',
  SiteSurveillanceIcon = 'SITE_SURVEILLANCE__________ICON',
  SiteSurveillanceView = 'SITE_SURVEILLANCE__________VIEW',
  SiteSurveillanceSiteImagesCreate = 'SITE_SURVEILLANCE_SITE_IMAGES_______CREATE',
  SiteSurveillanceSiteImagesView = 'SITE_SURVEILLANCE_SITE_IMAGES_______VIEW',
  SiteSurveillanceSiteImagesUpdate = 'SITE_SURVEILLANCE_SITE_IMAGES_______UPDATE',
  SiteSurveillanceSiteImagesDelete = 'SITE_SURVEILLANCE_SITE_IMAGES_______DELETE',
  SiteSurveillanceLiveVideocamView = 'SITE_SURVEILLANCE_LIVE_VIDEOCAM_______VIEW',
  SiteSurveillanceGeneralSettingsIcon = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_______ICON',
  SiteSurveillanceGeneralSettingsVideoCamSetupCreate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupView = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SiteSurveillanceGeneralSettingsVideoCamSetupUpdate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupDelete = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  SiteSafetyIcon = 'SITE_SAFETY__________ICON',
  SiteSafetyView = 'SITE_SAFETY__________VIEW',
  SiteSafetyCreate = 'SITE_SAFETY__________CREATE',
  BusinessInsightIcon = 'BUSINESS_INSIGHT__________ICON',
  BusinessInsightView = 'BUSINESS_INSIGHT__________VIEW',
  BusinessInsightDefectStatusAnalysisView = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________VIEW',
  BusinessInsightDefectStatusAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________EXPORT',
  BusinessInsightDefectTradeAnalysisView = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________VIEW',
  BusinessInsightDefectTradeAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________EXPORT',
  BusinessInsightDefectSubconAnalysisView = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________VIEW',
  BusinessInsightDefectSubconAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________EXPORT',
  BusinessInsightDefectsLodgedOverTimeView = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________VIEW',
  BusinessInsightDefectsLodgedOverTimeExport = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________EXPORT',
  BusinessInsightGeneralSettingsIcon = 'BUSINESS_INSIGHT_GENERAL_SETTINGS__________ICON',
  DigitalDocumentIcon = 'DIGITAL_DOCUMENT__________ICON',
  DigitalDocumentView = 'DIGITAL_DOCUMENT__________VIEW',
  DigitalDocumentCreate = 'DIGITAL_DOCUMENT__________CREATE',
  DigitalDocumentDelete = 'DIGITAL_DOCUMENT__________DELETE',
  DigitalDocumentGeneralSettingsIcon = 'DIGITAL_DOCUMENT_GENERAL_SETTINGS__________ICON'
}

export type ContactEntity = AuditEntity & {
  __typename?: 'ContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  associateID: Scalars['String'];
  name: Scalars['String'];
  designation?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  email: Scalars['String'];
  relationship?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  userDetail?: Maybe<PlatformUserEntity>;
  worker: WorkerEntity;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
};

export type ContactInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associateID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContractAccountEntity = AuditEntity & {
  __typename?: 'ContractAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  contractWbsID: Scalars['String'];
  contractWbs?: Maybe<WbsEntity>;
  revenueCategoryID: Scalars['String'];
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  subcontractWbsID: Scalars['String'];
  subcontractWbs?: Maybe<WbsEntity>;
  costItemID: Scalars['String'];
  costItem?: Maybe<CostItemEntity>;
};

export type ContractActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractStatus: ContractStatus;
};

export type ContractAdvanceEntity = AuditEntity & {
  __typename?: 'ContractAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  isRecoupment: Scalars['Boolean'];
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractAllocationEntity = AuditEntity & {
  __typename?: 'ContractAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  nonEditable: Scalars['Boolean'];
  contractCN: Array<ContractCnEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
};

export type ContractApprovalLogEntity = AuditEntity & {
  __typename?: 'ContractApprovalLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  toDoID: Scalars['String'];
  approverID: Scalars['String'];
  logDate?: Maybe<Scalars['DateTime']>;
  notifiedDate?: Maybe<Scalars['DateTime']>;
  toDoStatus: ToDoStatus;
  remarks?: Maybe<Scalars['String']>;
  toDo?: Maybe<ContractToDoEntity>;
};

export type ContractBudget = {
  __typename?: 'ContractBudget';
  originalBudgetSum?: Maybe<Scalars['Float']>;
  revisedBudgetSum?: Maybe<Scalars['Float']>;
};

export type ContractCnEntity = AuditEntity & {
  __typename?: 'ContractCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  cancelledClaimAmt?: Maybe<Scalars['Float']>;
  cancelledRetentionAmt?: Maybe<Scalars['Float']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  claimInfo?: Maybe<ContractClaimEntity>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractCnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractCnItemEntity = AuditEntity & {
  __typename?: 'ContractCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
  contractCNID: Scalars['String'];
  contractCN?: Maybe<ContractCnEntity>;
  wbs?: Maybe<WbsEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  contractCNTaxItem?: Maybe<Array<ContractCnTaxItemEntity>>;
};

export type ContractCnTaxItemEntity = AuditEntity & {
  __typename?: 'ContractCNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  contractCNItemID: Scalars['String'];
  contractCNItem?: Maybe<ContractCnItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type ContractCertificateInput = {
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractClaimAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  wbsID: Scalars['String'];
  revenueCategoryID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  contractClaimID?: Maybe<Scalars['String']>;
};

export type ContractClaimAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  wbsBudgetDetailID?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  classificationCodeID?: Maybe<Scalars['String']>;
  latestWbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ContractClaimAllocationEntityLatestWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ContractClaimEntity = AuditEntity & {
  __typename?: 'ContractClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  /** CustomFieldResolver */
  revenueAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingCertifiedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  contractClaimVOAllocation?: Maybe<Array<ContractClaimVoAllocationEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  contractClaimVORevenueWbsAllocation?: Maybe<Array<ContractClaimVoRevenueWbsAllocationEntity>>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  claimDetailRevenueWBS?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimWithDeduction?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  nonEditableAllocation?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  outstandingClaimCertified?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  grossAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  adjustmentInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractClaimEntityRevenueAmtArgs = {
  withTax?: Maybe<Scalars['Boolean']>;
};


export type ContractClaimEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};


export type ContractClaimEntityNonEditableAllocationArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ContractClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ContractClaimVoAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  contractVOID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  contractClaimID?: Maybe<Scalars['String']>;
};

export type ContractClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  contractVOID: Scalars['String'];
  contractVO?: Maybe<ContractVoEntity>;
};

export type ContractClaimVoRevenueWbsAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  contractVORevenueWbsID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  contractClaimID?: Maybe<Scalars['String']>;
};

export type ContractClaimVoRevenueWbsAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimVORevenueWbsAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  contractVORevenueWbsID: Scalars['String'];
  contractVORevenueWbs?: Maybe<ContractVoRevenueWbsEntity>;
};

export type ContractDnEntity = AuditEntity & {
  __typename?: 'ContractDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractDnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ContractDnItemEntity = AuditEntity & {
  __typename?: 'ContractDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
  contractDNID: Scalars['String'];
  contractDN?: Maybe<ContractDnEntity>;
  wbs?: Maybe<WbsEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  contractDNTaxItem?: Maybe<Array<ContractDnTaxItemEntity>>;
};

export type ContractDnTaxItemEntity = AuditEntity & {
  __typename?: 'ContractDNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  contractDNItemID: Scalars['String'];
  contractDNItem?: Maybe<ContractDnItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export enum ContractDocNum {
  ArClaim = 'AR_CLAIM',
  ArMiscIv = 'AR_MISC_IV',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArAdvanceRecoup = 'AR_ADVANCE_RECOUP',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ArInvoice = 'AR_INVOICE',
  ApClaim = 'AP_CLAIM',
  ApMiscIv = 'AP_MISC_IV',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApAdvanceRecoup = 'AP_ADVANCE_RECOUP',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  ApInvoice = 'AP_INVOICE',
  Default = 'DEFAULT',
  Df = 'DF',
  StockMvmt = 'STOCK_MVMT',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Vo = 'VO',
  Svo = 'SVO',
  Fs = 'FS',
  Fd = 'FD',
  ProjectMiscExpense = 'PROJECT_MISC_EXPENSE',
  BudgetTransfer = 'BUDGET_TRANSFER'
}

export type ContractEntity = AuditEntity & {
  __typename?: 'ContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
  companyID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  customerDetail?: Maybe<CustomerEntity>;
  bankAccountID?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccountEntity>;
  taxSchemeID: Scalars['String'];
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  costCenterID?: Maybe<Scalars['String']>;
  costCenter?: Maybe<CostCenterEntity>;
  profitRecogFormulaID?: Maybe<Scalars['String']>;
  profitRecogFormula?: Maybe<ProfitRecogFormulaEntity>;
  contractNo: Scalars['String'];
  contractDate: Scalars['String'];
  contractSum: Scalars['Float'];
  department?: Maybe<Scalars['String']>;
  implementWbs: Scalars['Boolean'];
  pdfDocumentTitle?: Maybe<Scalars['String']>;
  budgetChecker: BudgetChecker;
  contractStatus: ContractStatus;
  VO: Array<ContractVoEntity>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  miscExpenses?: Maybe<Array<MiscExpenseEntity>>;
  negotiatedSupply?: Maybe<Array<NegotiatedSupplyEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  rfq?: Maybe<Array<RfqEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  subcontractTenderDetail?: Maybe<Array<SubcontractTenderEntity>>;
  subcontractDetail?: Maybe<Array<SubcontractEntity>>;
  glExportDetailSource?: Maybe<Array<GlExportDetailSourceEntity>>;
  approvalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  projectBudget: Array<ProjectBudgetEntity>;
  site?: Maybe<Array<SiteEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  defect?: Maybe<Array<DefectManagementEntity>>;
  siteProgress?: Maybe<Array<SiteProgressEntity>>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  profitRecogAssgmt?: Maybe<Array<ProfitRecogAssignmentEntity>>;
  profitRecogGeneration?: Maybe<Array<ProfitRecogGenerationEntity>>;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
  budget?: Maybe<Array<WbsBudgetEntity>>;
  wbs?: Maybe<Array<WbsEntity>>;
  projectExpense?: Maybe<Array<ProjectExpenseEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  billInterest?: Maybe<Array<BillInterestEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  contractMiscInvoice?: Maybe<Array<ContractMiscInvoiceEntity>>;
  contractDN?: Maybe<Array<ContractDnEntity>>;
  contractCN?: Maybe<Array<ContractCnEntity>>;
  contractClientDN?: Maybe<Array<ClientDnEntity>>;
  contractClientCN?: Maybe<Array<ClientCnEntity>>;
  contractInv?: Maybe<Array<ApInvoiceEntity>>;
  contractDebitNote?: Maybe<Array<ApDebitNoteEntity>>;
  contractCreditNote?: Maybe<Array<ApCreditNoteEntity>>;
  contractDNSupplier?: Maybe<Array<DNtoSupplierEntity>>;
  contractCNSupplier?: Maybe<Array<CNtoSupplierEntity>>;
  contractReceipt?: Maybe<Array<ContractReceiptEntity>>;
  contractAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  contractRefund?: Maybe<Array<ContractRefundEntity>>;
  apAdvance?: Maybe<Array<ApAdvanceEntity>>;
  apRefund?: Maybe<Array<ApRefundEntity>>;
  contractUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  wbsBudgetTransfer?: Maybe<Array<WbsBudgetTransferEntity>>;
  wbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  bq?: Maybe<Array<BqEntity>>;
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  docNumHeader?: Maybe<Array<DocNumHeaderEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  /** CustomFieldResolver */
  company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  companyLoader?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  latestBudgetID?: Maybe<Scalars['JSON']>;
  contact?: Maybe<Array<ContactEntity>>;
  /** CustomFieldResolver */
  financialClientReceipt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  latestColAct?: Maybe<Scalars['JSON']>;
  latestCollectionActivity?: Maybe<CollectionActivityEntity>;
  /** CustomFieldResolver */
  contractAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestRunNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  docNumChecking?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  subcontractTenderSummary?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  subcontractSummary?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  purchasingSummary?: Maybe<Scalars['JSON']>;
  costCateg: Array<CostCategoryEntity>;
  /** CustomFieldResolver */
  contractRolesUsersAssigned?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  defectStatusByContract?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  voSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedContractSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  contractPOCounts?: Maybe<Scalars['JSON']>;
  maxRetentionSum: Scalars['Float'];
  /** CustomFieldResolver */
  contractDefectCounts?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  osContractAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  originalBudget?: Maybe<WbsBudgetEntity>;
  /** CustomFieldResolver */
  revisedBudget?: Maybe<WbsBudgetEntity>;
};


export type ContractEntityLatestCollectionActivityArgs = {
  selectedDate?: Maybe<Scalars['String']>;
};


export type ContractEntityCostCategArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
};


export type ContractEntityContractRolesUsersAssignedArgs = {
  softwareCode?: Maybe<Scalars['String']>;
};

export type ContractExtensionInput = {
  extensionDate?: Maybe<ExtensionDateInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractGuaranteeCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
};

export type ContractGuaranteeEntity = AuditEntity & {
  __typename?: 'ContractGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
  contractID: Scalars['String'];
  guaranteeType?: Maybe<GuaranteeTypeEntity>;
};

export type ContractGuaranteeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeStatus?: Maybe<GrntInsStatus>;
};

export type ContractInfo = {
  __typename?: 'ContractInfo';
  contractSum?: Maybe<Scalars['Float']>;
  contractDate?: Maybe<Scalars['DateTime']>;
};

export type ContractInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm?: Maybe<Scalars['Float']>;
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  customerID: Scalars['String'];
  contractNo: Scalars['String'];
  implementWbs: Scalars['Boolean'];
  contractDate: Scalars['String'];
  contractSum: Scalars['Float'];
  pdfDocumentTitle?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  bankAccountID?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  address?: Maybe<Array<AddressInput>>;
  contractStatus?: Maybe<ContractStatus>;
  budgetChecker?: Maybe<BudgetChecker>;
  LADFile?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractInsuranceCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
};

export type ContractInsuranceEntity = AuditEntity & {
  __typename?: 'ContractInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
  contractID: Scalars['String'];
  insuranceType?: Maybe<InsuranceTypeEntity>;
};

export type ContractInsuranceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
  insuranceTypeID: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceStatus?: Maybe<GrntInsStatus>;
};

export type ContractMiscInvoiceEntity = AuditEntity & {
  __typename?: 'ContractMiscInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  isDaywork?: Maybe<Scalars['Boolean']>;
};


export type ContractMiscInvoiceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractMiscInvoiceItemEntity = AuditEntity & {
  __typename?: 'ContractMiscInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
  contractMiscInvoiceID: Scalars['String'];
  contractMiscInvoice?: Maybe<ContractMiscInvoiceEntity>;
  wbs?: Maybe<WbsEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  contractMiscInvoiceTaxItem?: Maybe<Array<ContractMiscInvoiceTaxItemEntity>>;
};

export type ContractMiscInvoiceTaxItemEntity = AuditEntity & {
  __typename?: 'ContractMiscInvoiceTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  contractMiscInvoiceItemID: Scalars['String'];
  contractMiscInvoiceItem?: Maybe<ContractMiscInvoiceItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type ContractNewsEntity = AuditEntity & {
  __typename?: 'ContractNewsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  postStatus: PostStatus;
  image?: Maybe<Scalars['String']>;
  newsRead?: Maybe<Array<ContractNewsReadEntity>>;
};

export type ContractNewsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  content: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  postStatus: PostStatus;
};

export type ContractNewsReadEntity = AuditEntity & {
  __typename?: 'ContractNewsReadEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userID: Scalars['String'];
  newsID: Scalars['String'];
  news?: Maybe<ContractNewsEntity>;
};

export type ContractNewsReadInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  newsID: Scalars['DateTime'];
  userID: Scalars['String'];
};

export type ContractNotificationEntity = AuditEntity & {
  __typename?: 'ContractNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
  workflowID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  notificationRecipient?: Maybe<Array<ContractNotificationRecipientEntity>>;
};

export type ContractNotificationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  senderID: Scalars['String'];
  recipientID: Scalars['String'];
  documentID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  readStatus: ReadStatus;
};

export type ContractNotificationRecipientEntity = AuditEntity & {
  __typename?: 'ContractNotificationRecipientEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notificationID?: Maybe<Scalars['String']>;
  recipientID: Scalars['String'];
  isRead: Scalars['Boolean'];
  notification?: Maybe<ContractNotificationEntity>;
};

export enum ContractPermission {
  HomeExecSummaryView = 'HOME_EXEC_SUMMARY_____________VIEW',
  HomeContractView = 'HOME_CONTRACT_____________VIEW',
  HomeContractCreate = 'HOME_CONTRACT_____________CREATE',
  HomeContractExecSummaryView = 'HOME_CONTRACT_EXEC_SUMMARY__________VIEW',
  HomeContractExecSummaryContractBudgetView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_BUDGET_______VIEW',
  HomeContractExecSummaryContractInfoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______VIEW',
  HomeContractExecSummaryContractInfoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______UPDATE',
  HomeContractExecSummaryContractGuaranteeView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______VIEW',
  HomeContractExecSummaryContractGuaranteeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CREATE',
  HomeContractExecSummaryContractGuaranteeUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______UPDATE',
  HomeContractExecSummaryContractGuaranteeCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CANCEL',
  HomeContractExecSummaryContractInsuranceView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______VIEW',
  HomeContractExecSummaryContractInsuranceCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CREATE',
  HomeContractExecSummaryContractInsuranceUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______UPDATE',
  HomeContractExecSummaryContractInsuranceCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CANCEL',
  HomeContractExecSummaryContractVoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______VIEW',
  HomeContractExecSummaryContractVoCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______CREATE',
  HomeContractExecSummaryContractVoDraft = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______DRAFT',
  HomeContractExecSummaryContractVoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______UPDATE',
  HomeContractExecSummaryContractVoApprove = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______APPROVE',
  HomeContractExecSummaryContractVoReject = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______REJECT',
  HomeContractExecSummaryContractVoHealthIndexView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  HomeContractExecSummaryContractVoHealthIndexDownload = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______DOWNLOAD',
  HomeContractExecSummaryContractVoHealthIndexExport = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______EXPORT',
  HomeContractExecSummaryContractAccountStatusView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientProfileView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_PROFILE____VIEW',
  HomeContractExecSummaryContractAccountStatusAdvancesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeContractExecSummaryContractAccountStatusProgressClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusRetentionClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusCreditNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusReceiptsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RECEIPTS____VIEW',
  HomeContractExecSummaryContractAccountStatusRefundsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusOsReceivableView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_OS_RECEIVABLE____VIEW',
  HomeContractExecSummaryMiscExpensesView = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______VIEW',
  HomeContractExecSummaryMiscExpensesCreate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CREATE',
  HomeContractExecSummaryMiscExpensesUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______UPDATE',
  HomeContractExecSummaryMiscExpensesCancel = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CANCEL',
  HomeContractExecSummaryRetentionView = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeContractExecSummaryRetentionCreate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeContractExecSummaryRetentionUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeContractExecSummaryRetentionAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryExtensionOfTimeView = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeContractExecSummaryExtensionOfTimeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeContractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryContractClosureView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_CLOSURE_______VIEW',
  HomeContractExecSummaryContractTerminationView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_TERMINATION_______VIEW',
  HomeCustomerView = 'HOME_CUSTOMER_____________VIEW',
  HomeCustomerExecSummaryView = 'HOME_CUSTOMER_EXEC_SUMMARY__________VIEW',
  HomeCustomerExecSummaryCustomerProfileView = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______VIEW',
  HomeCustomerExecSummaryCustomerProfileUpdate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______UPDATE',
  HomeCustomerExecSummaryCustomerProfileCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____VIEW',
  HomeCustomerExecSummaryAccountTransactionAdvancesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionProgressClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionDebitNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionCreditNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____VIEW',
  HomeCustomerExecSummaryAccountTransactionReceiptsAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionReceiptsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CREATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRefundsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____VIEW',
  HomeCustomerExecSummaryAccountTransactionRefundsDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRefundsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRefundsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CREATE',
  HomeCustomerExecSummaryAccountTransactionRefundsApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRefundsReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____REJECT',
  HomeCustomerExecSummaryAccountTransactionRefundsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionOsReceivableView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_OS_RECEIVABLE____VIEW',
  HomeCustomerExecSummaryCreditControlCustomerListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_CREDIT_CONTROL_CUSTOMER_LISTING____VIEW',
  HomeCustomerExecSummaryStatementOfAccountCustomerAccountListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_STATEMENT_OF_ACCOUNT_CUSTOMER_ACCOUNT_LISTING____VIEW',
  HomeSubcontractView = 'HOME_SUBCONTRACT_____________VIEW',
  HomeSubcontractCreate = 'HOME_SUBCONTRACT_____________CREATE',
  HomeSubcontractExecSummaryView = 'HOME_SUBCONTRACT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractExecSummarySubcontractInfoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______VIEW',
  HomeSubcontractExecSummarySubcontractInfoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CREATE',
  HomeSubcontractExecSummarySubcontractGuaranteeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______VIEW',
  HomeSubcontractExecSummarySubcontractGuaranteeUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CREATE',
  HomeSubcontractExecSummarySubcontractInsuracneView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______VIEW',
  HomeSubcontractExecSummarySubcontractInsuracneCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______CREATE',
  HomeSubcontractExecSummarySubcontractVoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoApprove = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______APPROVE',
  HomeSubcontractExecSummarySubcontractVoReject = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______REJECT',
  HomeSubcontractExecSummarySubcontractVoDraft = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______DRAFT',
  HomeSubcontractExecSummarySubcontractVoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusSubcontractorProfileView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_SUBCONTRACTOR_PROFILE____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusAdvancesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusProgressClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRetentionClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDebitNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusCreditNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusPaymentsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PAYMENTS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRefundsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDnToSubconView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DN_TO_SUBCON____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusOsPayableView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_OS_PAYABLE____VIEW',
  HomeSubcontractExecSummaryRetentionView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeSubcontractExecSummaryRetentionCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeSubcontractExecSummaryRetentionUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeSubcontractExecSummaryRetentionAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummaryExtensionOfTimeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeSubcontractExecSummaryExtensionOfTimeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeSubcontractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummarySubcontractClosureCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_CLOSURE_______CREATE',
  HomeSubcontractExecSummarySubcontractTerminationCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_TERMINATION_______CREATE',
  HomeSubcontractorAccountCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_____________CREATE',
  HomeSubcontractorAccountView = 'HOME_SUBCONTRACTOR_ACCOUNT_____________VIEW',
  HomeSubcontractorAccountExecSummaryView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CREATE',
  HomeSubcontractorAccountExecSummaryAdvancesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______VIEW',
  HomeSubcontractorAccountExecSummaryAdvancesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryProgressClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryProgressClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryProgressClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryProgressClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryProgressClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryProgressClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryRetentionClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryRetentionClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryRetentionClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryRetentionClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryRetentionClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryRetentionClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryDebitNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryDebitNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryDebitNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryDebitNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryCreditNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryCreditNotesAllocate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______ALLOCATE',
  HomeSubcontractorAccountExecSummaryCreditNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryCreditNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryCreditNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryPaymentsCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CREATE',
  HomeSubcontractorAccountExecSummaryPaymentsAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryPaymentsView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______VIEW',
  HomeSubcontractorAccountExecSummaryPaymentsDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______DRAFT',
  HomeSubcontractorAccountExecSummaryPaymentsApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______APPROVE',
  HomeSubcontractorAccountExecSummaryPaymentsReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______REJECT',
  HomeSubcontractorAccountExecSummaryPaymentsCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______VIEW',
  HomeSubcontractorAccountExecSummaryDnToSubconUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______UPDATE',
  HomeSubcontractorAccountExecSummaryDnToSubconCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryOsPayableView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_OS_PAYABLE_______VIEW',
  HomeProjectPurchasesContractListingView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING__________VIEW',
  HomeProjectPurchasesContractListingExecSummaryView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_______VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CREATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____UPDATE',
  HomeSupplierAccountView = 'HOME_SUPPLIER_ACCOUNT_____________VIEW',
  HomeSupplierAccountCreate = 'HOME_SUPPLIER_ACCOUNT_____________CREATE',
  HomeSupplierAccountExecSummaryView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSupplierAccountExecSummarySupplierProfileView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______VIEW',
  HomeSupplierAccountExecSummarySupplierProfileUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______UPDATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______VIEW',
  HomeSupplierAccountExecSummaryPrefSupplierProductsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______CREATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsDelete = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______DELETE',
  HomeSupplierAccountExecSummarySupplierAccountsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_______VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAttachmentDownload = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS_ATTACHMENT_DOWNLOAD',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsApprove = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____APPROVE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsReject = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____REJECT',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsOsPayableView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_OS_PAYABLE____VIEW',
  HomePlantAndMachineryExecSummaryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY__________VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryOwnedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryOwnedPnmAssignmentHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_ASSIGNMENT_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmMaintenanceHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_MAINTENANCE_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryRentedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryRentedPnmReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______RETURN',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryAssignmentRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryAssignmentRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______RETURN',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______RETURN',
  HomeBusinessInsightView = 'HOME_BUSINESS_INSIGHT_____________VIEW',
  HomeBusinessInsightContractListingProjectCostingView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_______VIEW',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostingSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectPLRevisedContractSumView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_REVISED_CONTRACT_SUM____VIEW',
  HomeBusinessInsightContractListingProjectPLProgressIncomeView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PROGRESS_INCOME____VIEW',
  HomeBusinessInsightContractListingProjectPLSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_______VIEW',
  HomeBusinessInsightContractListingProjectCashFlowReceivedFromClientView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSubcontractorView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSupplierView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowOtherExpensesView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowNetCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_NET_CASH_FLOW____VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_______VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostPerformanceCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectFinancialStatusCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusBillingToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCollectionToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCostToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COST_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusPaymentToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectBudgetAnalysisOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisBudgetVarianceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_BUDGET_VARIANCE____VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____CREATE',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____CREATE',
  HomeSiteManagementSiteListingExecSummaryView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_______VIEW',
  HomeSiteManagementSiteListingExecSummarySiteWorkerAttendanceView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_WORKER_ATTENDANCE____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____CREATE',
  HomeSiteManagementSiteListingExecSummaryGrnUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteProgressUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressDelete = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____DELETE',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____VIEW',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamRecord = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____RECORD',
  HomeDefectManagementContractListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING__________VIEW',
  HomeDefectManagementContractListingDefectListingCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______CREATE',
  HomeDefectManagementContractListingDefectListingUpdate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______UPDATE',
  HomeDefectManagementContractListingDefectListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______VIEW',
  HomeDefectManagementContractListingDefectListingReAssignDefectCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_RE_ASSIGN_DEFECT____CREATE',
  HomeDefectManagementContractListingDefectListingActivityLogCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_ACTIVITY_LOG____CREATE',
  HomeGlInterfaceView = 'HOME_GL_INTERFACE_____________VIEW',
  HomeGlInterfaceAttachmentDownload = 'HOME_GL_INTERFACE__________ATTACHMENT_DOWNLOAD',
  HomeGlInterfaceContractView = 'HOME_GL_INTERFACE_CONTRACT__________VIEW',
  HomeGlInterfaceSubcontractView = 'HOME_GL_INTERFACE_SUBCONTRACT__________VIEW',
  HomeGlInterfaceSupplierView = 'HOME_GL_INTERFACE_SUPPLIER__________VIEW',
  HomeWorkDeskToDoView = 'HOME_WORK_DESK_TO_DO__________VIEW',
  HomeWorkDeskToDoApprove = 'HOME_WORK_DESK_TO_DO__________APPROVE',
  HomeWorkDeskToDoReject = 'HOME_WORK_DESK_TO_DO__________REJECT',
  HomeWorkDeskNotificationView = 'HOME_WORK_DESK_NOTIFICATION__________VIEW',
  GeneralSettingExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_____________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES__________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryEdit = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____EDIT',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____UPDATE',
  GeneralSettingExecSummarySubcontractPackagesView = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________VIEW',
  GeneralSettingExecSummarySubcontractPackagesCreate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________CREATE',
  GeneralSettingExecSummarySubcontractPackagesUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________UPDATE',
  GeneralSettingExecSummarySubcontractPackagesDelete = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________DELETE',
  GeneralSettingExecSummaryPmCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________VIEW',
  GeneralSettingExecSummaryPmCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________CREATE',
  GeneralSettingExecSummaryPmCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryPmCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________CREATE',
  GeneralSettingExecSummaryBuildingMaterialsView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________VIEW',
  GeneralSettingExecSummaryBuildingMaterialsUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______CREATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______VIEW',
  GeneralSettingExecSummaryBuildingMaterialsItemListingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______DELETE',
  GeneralSettingExecSummaryLabourCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________CREATE',
  GeneralSettingExecSummaryLabourCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________VIEW',
  GeneralSettingExecSummaryLabourCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryLabourCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________DELETE',
  GeneralSettingExecSummaryMiscExpenseCreate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________CREATE',
  GeneralSettingExecSummaryMiscExpenseView = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________VIEW',
  GeneralSettingExecSummaryMiscExpenseUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________UPDATE',
  GeneralSettingExecSummaryMiscExpenseDelete = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________DELETE',
  GeneralSettingExecSummaryDocumentNumberingView = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________VIEW',
  GeneralSettingExecSummaryDocumentNumberingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________CREATE',
  GeneralSettingExecSummaryDocumentNumberingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________UPDATE',
  GeneralSettingExecSummaryBankAccountView = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________VIEW',
  GeneralSettingExecSummaryBankAccountCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________CREATE',
  GeneralSettingExecSummaryBankAccountUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________UPDATE',
  GeneralSettingExecSummaryBankAccountDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________DELETE',
  GeneralSettingPlatformCostCenterView = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________VIEW',
  GeneralSettingPlatformCostCenterCreate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________CREATE',
  GeneralSettingPlatformCostCenterUpdate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________UPDATE',
  GeneralSettingPlatformCostCenterDelete = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________DELETE',
  GeneralSettingExecSummaryUomView = 'GENERAL_SETTING_EXEC_SUMMARY_UOM__________VIEW',
  GeneralSettingPlatformGuaranteeTypeView = 'GENERAL_SETTING_PLATFORM_GUARANTEE_TYPE__________VIEW',
  GeneralSettingPlatformInsuranceTypeView = 'GENERAL_SETTING_PLATFORM_INSURANCE_TYPE__________VIEW',
  GeneralSettingExecSummaryTaxSetupView = 'GENERAL_SETTING_EXEC_SUMMARY_TAX_SETUP__________VIEW'
}

export type ContractPermissionEntity = {
  __typename?: 'ContractPermissionEntity';
  ID: Scalars['String'];
  permissionName: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type ContractReceiptEntity = AuditEntity & {
  __typename?: 'ContractReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractReceiptEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractReceiptInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ContractRefundEntity = AuditEntity & {
  __typename?: 'ContractRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractRefundEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractRetentionAllocationEntity = AuditEntity & {
  __typename?: 'ContractRetentionAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type ContractRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  customerID: Scalars['String'];
};

export enum ContractStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Terminated = 'TERMINATED'
}

export type ContractToDoEntity = AuditEntity & {
  __typename?: 'ContractToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  submitterID: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  notifiedDate?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
  approvalLevel?: Maybe<ApprovalLevel>;
  docRefAmt?: Maybe<Scalars['Float']>;
  approvalLog?: Maybe<Array<ContractApprovalLogEntity>>;
};

export type ContractToDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  senderID: Scalars['String'];
  recipientID: Scalars['String'];
  toDoType: ToDoType;
  documentID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
};

export type ContractTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  contractID: Scalars['String'];
  customerID: Scalars['String'];
};

export type ContractTransactionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costClass?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
};

export type ContractTransactionWithItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  contractID: Scalars['String'];
  customerID: Scalars['String'];
  isEInvoice?: Maybe<Scalars['Boolean']>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<ContractTransactionItemInput>>;
};

export type ContractVo = {
  __typename?: 'ContractVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  voSumCount?: Maybe<Scalars['Float']>;
  voOmissionCount?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  voClientCount?: Maybe<Scalars['Float']>;
  voSubconCount?: Maybe<Scalars['Float']>;
};

export type ContractVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type ContractVoDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  contractVOID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  revenueCategoryID: Scalars['String'];
  submissionAmt: Scalars['Float'];
};

export type ContractVoEntity = AuditEntity & {
  __typename?: 'ContractVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  contract: ContractEntity;
  contractClaimVOAllocation?: Maybe<Array<ContractClaimVoAllocationEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  contractClaimVOAlloc?: Maybe<Scalars['JSON']>;
};

export type ContractVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
};

export type ContractVoRevenueWbsEntity = AuditEntity & {
  __typename?: 'ContractVORevenueWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractVOID: Scalars['String'];
  contractVO?: Maybe<ContractVoEntity>;
  wbsBudgetDetailID?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  contractClaimVORevenueWbsAllocation?: Maybe<Array<ContractClaimVoRevenueWbsAllocationEntity>>;
  /** CustomFieldResolver */
  previousRevenueVOAmt?: Maybe<Scalars['JSON']>;
  latestWbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ContractVoRevenueWbsEntityLatestWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type CopyBudgetInput = {
  copyFromBudgetID: Scalars['String'];
  contractID: Scalars['String'];
};

export type CoreAllocationEntity = AuditEntity & {
  __typename?: 'CoreAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
};

export type CoreApprovalLogEntity = AuditEntity & {
  __typename?: 'CoreApprovalLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  toDoID: Scalars['String'];
  approverID: Scalars['String'];
  logDate?: Maybe<Scalars['DateTime']>;
  notifiedDate?: Maybe<Scalars['DateTime']>;
  toDoStatus: ToDoStatus;
  remarks?: Maybe<Scalars['String']>;
};

export type CoreAssociateEntity = AuditEntity & {
  __typename?: 'CoreAssociateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  isCompany: Scalars['Boolean'];
  tinNo?: Maybe<Scalars['String']>;
  eInvoiceType?: Maybe<EInvoiceType>;
};

export type CoreAssociateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreClaimAllocationEntity = AuditEntity & {
  __typename?: 'CoreClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
};

export type CoreClaimAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
};

export type CoreClaimEntity = AuditEntity & {
  __typename?: 'CoreClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
};

export type CoreClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
};

export type CoreClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docDueDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'CoreClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
};

export type CoreClaimVoAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
};

export type CoreConsubconActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconCertificateInput = {
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconExtensionInput = {
  extensionDate?: Maybe<ExtensionDateInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconGuarInsCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconGuarInsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm?: Maybe<Scalars['Float']>;
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type CoreConsubconVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreContractEntity = AuditEntity & {
  __typename?: 'CoreContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
};

export type CoreCreditorEntity = AuditEntity & {
  __typename?: 'CoreCreditorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  isCompany: Scalars['Boolean'];
  tinNo?: Maybe<Scalars['String']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  creditorAC?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  ratingType: RatingType;
  isSelfBilled: Scalars['Boolean'];
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
};

export type CoreDocTypeEntity = AuditEntity & {
  __typename?: 'CoreDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreEInvoiceTransactionEntity = AuditEntity & {
  __typename?: 'CoreEInvoiceTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CoreGuarInsEntity = AuditEntity & {
  __typename?: 'CoreGuarInsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
};

export type CoreGuaranteeEntity = AuditEntity & {
  __typename?: 'CoreGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
};

export type CoreInsuranceEntity = AuditEntity & {
  __typename?: 'CoreInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
};

export type CoreNewsEntity = AuditEntity & {
  __typename?: 'CoreNewsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  postStatus: PostStatus;
  image?: Maybe<Scalars['String']>;
};

export type CoreNewsReadEntity = AuditEntity & {
  __typename?: 'CoreNewsReadEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userID: Scalars['String'];
};

export type CoreNotiRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreNotiRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notiPolicyID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};

export type CoreNotificationEntity = AuditEntity & {
  __typename?: 'CoreNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  recipientID?: Maybe<Scalars['String']>;
  senderID?: Maybe<Scalars['String']>;
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  readStatus: ReadStatus;
};

export type CoreNotificationGuidelineEntity = AuditEntity & {
  __typename?: 'CoreNotificationGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreNotificationGuidelineInput = {
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type CoreNumberFormatEntity = AuditEntity & {
  __typename?: 'CoreNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CorePolicyAssignmentInput = {
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type CorePolicyGuidelineEntity = AuditEntity & {
  __typename?: 'CorePolicyGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePolicyGuidelineInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
};

export type CorePolicyRoleAssignmentEntity = AuditEntity & {
  __typename?: 'CorePolicyRoleAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  policyID: Scalars['String'];
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type CoreReceiptPaymentEntity = AuditEntity & {
  __typename?: 'CoreReceiptPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
};

export type CoreReceiptPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'CoreRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type CoreToDoEntity = AuditEntity & {
  __typename?: 'CoreToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  submitterID: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  notifiedDate?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
  approvalLevel?: Maybe<ApprovalLevel>;
};

export type CoreTransactionEntity = AuditEntity & {
  __typename?: 'CoreTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
};

export type CoreTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
};

export type CoreVoEntity = AuditEntity & {
  __typename?: 'CoreVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
};

export type CostAdjItemEntity = AuditEntity & {
  __typename?: 'CostAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  costItemID: Scalars['String'];
};

export type CostCategoryEntity = AuditEntity & {
  __typename?: 'CostCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  parentCostCategoryID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  pnmCostCat?: Maybe<Scalars['JSON']>;
  isLastNode: Scalars['Boolean'];
  tankType: TankType;
  fuelConsumption?: Maybe<Scalars['JSON']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costClass: CostClass;
  commonStatus: CommonStatus;
  costToDate?: Maybe<Scalars['Float']>;
  committedCost?: Maybe<Scalars['Float']>;
  supplierConnection: Array<SupplierCCategoryEntity>;
  contractConnection: Array<ProjectBudgetEntity>;
  workshopConnection?: Maybe<Array<PnmCategoryWorkshopEntity>>;
  subcontractTrade: Array<SubcontractEntity>;
  miscExpenses?: Maybe<Array<MiscExpenseEntity>>;
  fromWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  toWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  plantMachinery?: Maybe<Array<PlantMachineryEntity>>;
  costSubcontractWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  budget: Array<ProjectBudgetEntity>;
  /** CustomFieldResolver */
  plantMachineryStatus?: Maybe<PmStatus>;
  subconTender: Array<SubcontractTenderEntity>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  defect?: Maybe<Array<DefectEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  wbsUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  subcontractorPackage?: Maybe<Array<SubcontractorCCategoryEntity>>;
  subcontractor?: Maybe<Array<SubcontractorEntity>>;
  supplier?: Maybe<Array<SupplierEntity>>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  /** CustomFieldResolver */
  parentName?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
  parentCostCategory?: Maybe<CostCategoryEntity>;
  childrenCostCategory?: Maybe<Array<CostCategoryEntity>>;
  subcontract: Array<SubcontractEntity>;
  /** CustomFieldResolver */
  childrenIDs?: Maybe<Array<Scalars['String']>>;
};


export type CostCategoryEntityPlantMachineryArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CostCategoryEntityBudgetArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type CostCategoryEntityPlantMachineryStatusArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type CostCategoryImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sequence?: Maybe<Scalars['Float']>;
  costName?: Maybe<Scalars['String']>;
  parentCostName?: Maybe<Scalars['String']>;
  parentCostCategoryId?: Maybe<Scalars['String']>;
  costClass?: Maybe<Scalars['String']>;
  originalCostClass?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  tankType?: Maybe<Scalars['String']>;
  consumptionMethod?: Maybe<Scalars['String']>;
  newCostCategory?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  trackQty?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
  isTank?: Maybe<Scalars['Boolean']>;
  lowerRange?: Maybe<Scalars['Float']>;
  upperRange?: Maybe<Scalars['Float']>;
};

export type CostCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costItems?: Maybe<Array<CostItems>>;
  costClass?: Maybe<CostClass>;
  tankType?: Maybe<TankType>;
  pnmCostCat?: Maybe<PnmCostCatInput>;
  fuelConsumption?: Maybe<FuelConsumptionInput>;
};

export type CostCategoryUpdateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
};

export type CostCenterDeleteInput = {
  ID: Scalars['String'];
};

export type CostCenterEntity = AuditEntity & {
  __typename?: 'CostCenterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  costCenterType: CostCenterType;
  commonStatus: CommonStatus;
  contract?: Maybe<Array<ContractEntity>>;
};

export type CostCenterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
  costCenterType?: Maybe<CostCenterType>;
};

export enum CostCenterType {
  Company = 'COMPANY',
  Contract = 'CONTRACT',
  Bank = 'BANK'
}

export enum CostClass {
  Main = 'MAIN',
  Subcontract = 'SUBCONTRACT',
  Labour = 'LABOUR',
  Misc = 'MISC',
  Material = 'MATERIAL',
  Plant = 'PLANT'
}

export type CostCodeEntity = AuditEntity & {
  __typename?: 'CostCodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  parentCostCodeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  level: Scalars['Float'];
  isLastNode: Scalars['Boolean'];
};

export type CostCodeInput = {
  parentCostCodeID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
};

export type CostEInvoiceAdjItemEntity = AuditEntity & {
  __typename?: 'CostEInvoiceAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
};

export type CostItemDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type CostItemEntity = AuditEntity & {
  __typename?: 'CostItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  uomID?: Maybe<Scalars['String']>;
  UOM?: Maybe<UomEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  lastPurchasePrice?: Maybe<Scalars['Float']>;
  variancePerc?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  onBehalf: Scalars['Boolean'];
  isInventory: Scalars['Boolean'];
  isDaywork: Scalars['Boolean'];
  commonStatus: CommonStatus;
  /** CustomFieldResolver */
  latestTax?: Maybe<TaxObject>;
  poItem?: Maybe<Array<PoItemEntity>>;
  siteMaterialPolicy?: Maybe<Array<SiteMaterialPolicyEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  negotiatedSupplyItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  supplierConnection: Array<PricingSupplierEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  fromWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  toWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  /** CustomFieldResolver */
  latestPurchase?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  isInventoryChecking?: Maybe<Scalars['Boolean']>;
};


export type CostItemEntityLatestTaxArgs = {
  docDate?: Maybe<Scalars['String']>;
};

export type CostItemImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sequence?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['String']>;
  costName?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  newCostItem?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  variancePercentage?: Maybe<Scalars['Float']>;
  onBehalf?: Maybe<Scalars['Boolean']>;
  markupPercentage?: Maybe<Scalars['Float']>;
  isInventory?: Maybe<Scalars['Boolean']>;
};

export type CostItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  uomID: Scalars['String'];
  name: Scalars['String'];
  unitPrice: Scalars['Float'];
  onBehalf: Scalars['Boolean'];
  isInventory: Scalars['Boolean'];
  isDaywork: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  markupPerc?: Maybe<Scalars['Float']>;
  variancePerc?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type CostItems = {
  costCategoryID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  onBehalf?: Maybe<Scalars['Boolean']>;
  markupPerc?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  variancePerc?: Maybe<Scalars['Float']>;
};

export type CostRevEInvoiceAdjItemEntity = AuditEntity & {
  __typename?: 'CostRevEInvoiceAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type CurrencyEntity = AuditEntity & {
  __typename?: 'CurrencyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol: Scalars['String'];
  format: NumericFormatter;
  commonStatus: CommonStatus;
  company: Array<CompanyEntity>;
};

export type CurrencyExchangeEntity = AuditEntity & {
  __typename?: 'CurrencyExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  fromCurrencyID: Scalars['String'];
  toCurrencyID: Scalars['String'];
  effectiveDate: Scalars['String'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  currencyLog?: Maybe<Array<Scalars['JSON']>>;
  /** CustomFieldResolver */
  fromCurrency: Scalars['JSON'];
  /** CustomFieldResolver */
  toCurrency: Scalars['JSON'];
};

export type CurrencyExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromCurrencyID?: Maybe<Scalars['String']>;
  toCurrencyID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['String']>;
  fromAmt?: Maybe<Scalars['Float']>;
  toAmt?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  currencyLog?: Maybe<Array<ExchangeLogInput>>;
};

export type CurrencyNoteEntity = AuditEntity & {
  __typename?: 'CurrencyNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  format: NumericFormatter;
};

export type CurrencySetupDeleteInput = {
  ID: Scalars['String'];
};

export type CurrencySetupInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};

export type CustomerCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'CustomerCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
};

export type CustomerCompanyAssignmentInput = {
  customerID: Scalars['String'];
  companyID: Scalars['String'];
};

export type CustomerDeleteInput = {
  ID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
};

export type CustomerEntity = AuditEntity & {
  __typename?: 'CustomerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  isCompany: Scalars['Boolean'];
  tinNo?: Maybe<Scalars['String']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  genPaymentReminder: Scalars['Boolean'];
  contactPerson: Array<ContactEntity>;
  contract?: Maybe<Array<ContractEntity>>;
  followUp?: Maybe<Array<FollowUpEntity>>;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  customerMiscInvoice?: Maybe<Array<ContractMiscInvoiceEntity>>;
  customerDN?: Maybe<Array<ContractDnEntity>>;
  customerCN?: Maybe<Array<ContractCnEntity>>;
  customerClientDN?: Maybe<Array<ClientDnEntity>>;
  customerClientCN?: Maybe<Array<ClientCnEntity>>;
  customerReceipt?: Maybe<Array<ContractReceiptEntity>>;
  customerAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  customerRefund?: Maybe<Array<ContractRefundEntity>>;
  paymentReminder?: Maybe<Array<DocReminderEntity>>;
  billInterest?: Maybe<Array<BillInterestEntity>>;
  customerCompanyAssignment?: Maybe<Array<CustomerCompanyAssignmentEntity>>;
  projectOpportunity?: Maybe<Array<ProjectOpportunityEntity>>;
  /** CustomFieldResolver */
  activeContract?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfoNoBound?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  clientReceiptInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  clientAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  OSClientAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestCollectionActivity?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  consubconDropdown?: Maybe<Scalars['String']>;
};


export type CustomerEntityContractArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityActiveContractArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityOverdueInvInfoNoBoundArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityClientAgingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityOsClientAgingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityConsubconDropdownArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type CustomerImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  genPaymentReminder?: Maybe<Scalars['Boolean']>;
  contactName: Scalars['String'];
  contactDesignation: Scalars['String'];
  contactEmail?: Maybe<Scalars['String']>;
  contactMobileNo: Scalars['String'];
};

export type CustomerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  genPaymentReminder: Scalars['Boolean'];
  associateID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export type CustomerReminderEntity = AuditEntity & {
  __typename?: 'CustomerReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  paymentReminder1: Scalars['Float'];
  paymentReminder2: Scalars['Float'];
  paymentReminder3: Scalars['Float'];
  paymentReminder4: Scalars['Float'];
  minimumAmt: Scalars['Float'];
  reminderFor: ReminderFor;
  printedCopy?: Maybe<Scalars['Boolean']>;
  emailCopy?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
};

export type CustomerReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  paymentReminder1: Scalars['Float'];
  paymentReminder2: Scalars['Float'];
  paymentReminder3: Scalars['Float'];
  paymentReminder4: Scalars['Float'];
  minimumAmt: Scalars['Float'];
  reminderFor: ReminderFor;
  printedCopy?: Maybe<Scalars['Boolean']>;
  emailCopy?: Maybe<Scalars['Boolean']>;
};

export type DNtoSubconEntity = AuditEntity & {
  __typename?: 'DNtoSubconEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  markupAmt?: Maybe<Scalars['Float']>;
  markupTaxAmt?: Maybe<Scalars['Float']>;
  cancelledClaimAmt?: Maybe<Scalars['Float']>;
  cancelledRetentionAmt?: Maybe<Scalars['Float']>;
  chargeToRevenue: Scalars['Boolean'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  claimInfo?: Maybe<SubcontractClaimEntity>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type DNtoSubconEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type DNtoSubconItemEntity = AuditEntity & {
  __typename?: 'DNtoSubconItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  DNtoSubconID: Scalars['String'];
  DNtoSubcon?: Maybe<DNtoSubconEntity>;
  costItem?: Maybe<CostItemEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  markupAmt?: Maybe<Scalars['Float']>;
  DNtoSubconTaxItem?: Maybe<Array<DNtoSubconTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type DNtoSubconItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type DNtoSubconTaxItemEntity = AuditEntity & {
  __typename?: 'DNtoSubconTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  DNtoSubconItemID: Scalars['String'];
  DNtoSubconItem?: Maybe<DNtoSubconItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type DNtoSupplierEntity = AuditEntity & {
  __typename?: 'DNtoSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type DNtoSupplierEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type DNtoSupplierItemEntity = AuditEntity & {
  __typename?: 'DNtoSupplierItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  DNtoSupplierID: Scalars['String'];
  DNtoSupplier?: Maybe<DNtoSupplierEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  DNtoSupplierTaxItem?: Maybe<Array<DNtoSupplierTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type DNtoSupplierItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type DNtoSupplierTaxItemEntity = AuditEntity & {
  __typename?: 'DNtoSupplierTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  DNtoSupplierItemID: Scalars['String'];
  DNtoSupplierItem?: Maybe<DNtoSupplierItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type DoActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  doStatus: DoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoActionItemInput = {
  grtnID?: Maybe<Scalars['String']>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  doItemID: Scalars['String'];
  poItemID?: Maybe<Scalars['String']>;
  grtnItemID?: Maybe<Scalars['String']>;
  acceptedQty: Scalars['Float'];
  deliveredQty?: Maybe<Scalars['Float']>;
  isInventory?: Maybe<Scalars['Boolean']>;
};

export type DoEntity = AuditEntity & {
  __typename?: 'DOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  deliveryAddress?: Maybe<Scalars['JSON']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doNo: Scalars['String'];
  contactPerson: Scalars['JSON'];
  glExportID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  doStatus: DoStatus;
  /** CustomFieldResolver */
  DODetails?: Maybe<Scalars['JSON']>;
  packedDetail?: Maybe<Array<Scalars['JSON']>>;
  shippedDetail?: Maybe<Array<Scalars['JSON']>>;
  doItem?: Maybe<Array<DoItemEntity>>;
  poItemConnection: Array<DoItemEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  POs: Array<PurchaseOrderEntity>;
  GRTNs: Array<GoodReturnNoteEntity>;
  /** CustomFieldResolver */
  upToDateAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  DOdocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  deliveryStatus?: Maybe<Scalars['JSON']>;
};


export type DoEntityUpToDateAmtArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  taxInclusive?: Maybe<Scalars['Boolean']>;
};


export type DoEntityDOdocAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type DoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  doNo: Scalars['String'];
  siteID: Scalars['String'];
  remarks: Scalars['String'];
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  doStatus: DoStatus;
  docDate?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<ContactPersonInput>;
  deliveryAddress?: Maybe<AddressInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoItem = {
  __typename?: 'DOItem';
  costItemID?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
};

export type DoItemEntity = AuditEntity & {
  __typename?: 'DOItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  deliveryOrderID: Scalars['String'];
  DO?: Maybe<DoEntity>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  POs?: Maybe<PurchaseOrderEntity>;
  grtnID?: Maybe<Scalars['String']>;
  goodReturnNote?: Maybe<GoodReturnNoteEntity>;
  poItemID?: Maybe<Scalars['String']>;
  poItem?: Maybe<PoItemEntity>;
  grtnItemID?: Maybe<Scalars['String']>;
  grtnItem?: Maybe<GoodReturnNoteItemEntity>;
  acceptedQty: Scalars['Float'];
  deliveredQty?: Maybe<Scalars['Float']>;
  acceptedAmt?: Maybe<Scalars['Float']>;
  deliveredAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  doItemAmt?: Maybe<Scalars['Float']>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  goodReturnNoteItem?: Maybe<Array<GoodReturnNoteItemEntity>>;
  /** CustomFieldResolver */
  doItemSubmitted?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  upToDateQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  returnedQty?: Maybe<Scalars['Float']>;
};

export type DoItemInput = {
  grtnID?: Maybe<Scalars['String']>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  POItemsInput: Array<PoItemsInput>;
};

export type DorpaResult = {
  __typename?: 'DORPAResult';
  supplierID?: Maybe<Scalars['String']>;
  doNo?: Maybe<Scalars['String']>;
  poNo?: Maybe<Scalars['String']>;
  descriptions?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  doItems?: Maybe<Array<DoItem>>;
};

export type DOsInput = {
  purchaseOrderID: Scalars['String'];
  poItemID: Scalars['String'];
  receivedQty: Scalars['Float'];
};


export enum DaysofWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type DefectAssignmentEntity = AuditEntity & {
  __typename?: 'DefectAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  defectID: Scalars['String'];
  defect?: Maybe<DefectEntity>;
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  assignmentDate: Scalars['String'];
  expectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type DefectEntity = AuditEntity & {
  __typename?: 'DefectEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  costCategoryID: Scalars['String'];
  trade?: Maybe<CostCategoryEntity>;
  reportedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  activityLog?: Maybe<Array<Scalars['JSON']>>;
  defectStatus: DefectStatus;
  defectAssignment?: Maybe<Array<DefectAssignmentEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type DefectLog = {
  __typename?: 'DefectLog';
  costCategoryID?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DefectManagementEntity = AuditEntity & {
  __typename?: 'DefectManagementEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  defectLog?: Maybe<Array<Scalars['JSON']>>;
  defectStatus: DefectStatus;
  contract?: Maybe<ContractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
};

export enum DefectStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  InProgress = 'IN_PROGRESS',
  Resolved = 'RESOLVED',
  Assigned = 'ASSIGNED',
  Reassigned = 'REASSIGNED',
  SelfAssigned = 'SELF_ASSIGNED'
}

export type DeleteTaxInput = {
  id: Scalars['String'];
};

export type DeliveryOrder = {
  __typename?: 'DeliveryOrder';
  doNo?: Maybe<Scalars['String']>;
};

export type DepartmentGlInterfaceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
};

export type DiaryTypeEntity = AuditEntity & {
  __typename?: 'DiaryTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  followUp: Scalars['Boolean'];
  siteDiary?: Maybe<Array<SiteDiaryEntity>>;
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
};

export type DigitalDocumentInput = {
  refTable: Scalars['String'];
  refID: Scalars['String'];
  associateID?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type DigitalDocuments = AuditEntity & {
  __typename?: 'DigitalDocuments';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  documentFolderID?: Maybe<Scalars['String']>;
  documentsFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
  commonStatus: CommonStatus;
  size: Scalars['String'];
};

export type DirectPoInput = {
  ID?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
  totalAmt: Scalars['Float'];
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  instructions?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseType?: Maybe<PurchaseType>;
  contactPerson?: Maybe<Scalars['JSON']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoGrnMatch = {
  __typename?: 'DoGrnMatch';
  ID?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doAmt?: Maybe<Scalars['Float']>;
  doDate?: Maybe<Scalars['DateTime']>;
};

export type DoGrnMatchInput = {
  ID?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doAmt?: Maybe<Scalars['Float']>;
  doDate?: Maybe<Scalars['DateTime']>;
};

export type DoPackedInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoRejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type DoShippedInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum DoStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  Pendingclose = 'PENDINGCLOSE',
  New = 'NEW',
  Packed = 'PACKED'
}

export type DocNumDetailDeleteInput = {
  ID: Scalars['String'];
};

export type DocNumDetailEntity = AuditEntity & {
  __typename?: 'DocNumDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNumHeaderID: Scalars['String'];
  docNumHeader: DocNumHeaderEntity;
  sequence: Scalars['Float'];
  formatType: Scalars['String'];
  formatValue: Scalars['String'];
  startFrom?: Maybe<Scalars['Float']>;
  nextNumber?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
};

export type DocNumDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docNumHeaderID?: Maybe<Scalars['String']>;
  sequence: Scalars['Float'];
  formatType: Scalars['String'];
  formatValue: Scalars['String'];
  nextNumber?: Maybe<Scalars['Float']>;
  startFrom?: Maybe<Scalars['Float']>;
};

export type DocNumHeaderDeleteInput = {
  ID: Scalars['String'];
};

export type DocNumHeaderEntity = AuditEntity & {
  __typename?: 'DocNumHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  docTypeID?: Maybe<Scalars['String']>;
  docType?: Maybe<ConDocTypeEntity>;
  sampleOutput: Scalars['String'];
  commonStatus: CommonStatus;
  docNumDetail: Array<DocNumDetailEntity>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type DocNumHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
  sampleOutput: Scalars['String'];
};

export type DocNumInput = {
  contractID?: Maybe<Scalars['String']>;
  nextNumber?: Maybe<Scalars['Float']>;
  prefix?: Maybe<Scalars['String']>;
  numberLength?: Maybe<Scalars['Float']>;
  runNumID?: Maybe<Scalars['String']>;
  numFormatID?: Maybe<Scalars['String']>;
};

export type DocRef = AuditEntity & {
  __typename?: 'DocRef';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
};

export type DocRefInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DocReminderEntity = AuditEntity & {
  __typename?: 'DocReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  paymentReminderID: Scalars['String'];
  paymentReminder?: Maybe<PaymentReminderEntity>;
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  reminderSeq?: Maybe<Scalars['Float']>;
  outstandingAmt: Scalars['Float'];
};

export type DocReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  paymentReminderID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  reminderSeq: Scalars['Float'];
  outstandingAmt: Scalars['Float'];
};

export type DocTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docName: Scalars['String'];
  code: Scalars['String'];
  docCode: ContractDocNum;
};

export type DocumentFolderEntity = AuditEntity & {
  __typename?: 'DocumentFolderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentsEntity>>;
};

export type DocumentFolderInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
};

export type DocumentsEntity = AuditEntity & {
  __typename?: 'DocumentsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  documentFolderID?: Maybe<Scalars['String']>;
  documentsFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
  commonStatus: CommonStatus;
};

export type DocumentsInput = {
  fileType?: Maybe<FileType>;
  refTable?: Maybe<RefTable>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  status?: Maybe<CommonStatus>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
};

export type EInvoiceItem = AuditEntity & {
  __typename?: 'EInvoiceItem';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
};

export enum EInvoiceType {
  Individual = 'INDIVIDUAL',
  Company = 'COMPANY',
  Foreigner = 'FOREIGNER',
  Government = 'GOVERNMENT'
}

export type EditTaxInput = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetail>;
  taxId: Scalars['String'];
  deletedId: Array<Scalars['String']>;
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type EvalSelectInput = {
  rfqItemID?: Maybe<Scalars['String']>;
  rfqSubmissionItemID?: Maybe<Scalars['String']>;
};

export type ExchangeLog = {
  __typename?: 'ExchangeLog';
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExchangeLogInput = {
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExportedGlTransferResponse = {
  __typename?: 'ExportedGLTransferResponse';
  AccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  JournalTypeName?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ExtensionDate = {
  __typename?: 'ExtensionDate';
  remarks?: Maybe<Scalars['String']>;
  cncDate?: Maybe<Scalars['DateTime']>;
  extensionDate?: Maybe<Scalars['DateTime']>;
  eDocument?: Maybe<Scalars['String']>;
};

export type ExtensionDateInput = {
  remarks?: Maybe<Scalars['String']>;
  cncDate?: Maybe<Scalars['DateTime']>;
  extensionDate?: Maybe<Scalars['DateTime']>;
  eDocument?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FieldTypeEntity = AuditEntity & {
  __typename?: 'FieldTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  qualificationChecklistItem?: Maybe<Array<QualificationChecklistItemEntity>>;
};

export type FileObjectType = {
  __typename?: 'FileObjectType';
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
};

export enum FileType {
  Image = 'IMAGE',
  Logo = 'LOGO',
  Pdf = 'PDF'
}

export type FinancialStatusDetails = {
  __typename?: 'FinancialStatusDetails';
  costToDateSum?: Maybe<Scalars['Float']>;
  subconBillingSum?: Maybe<Scalars['Float']>;
  supplierInvSum?: Maybe<Scalars['Float']>;
  miscExpSum?: Maybe<Scalars['Float']>;
  subconPaymentRefundSum?: Maybe<Scalars['Float']>;
  subconSuppAdvanceSum?: Maybe<Scalars['Float']>;
  supplierPaymentSumStatus?: Maybe<Scalars['Float']>;
  billToDateSum?: Maybe<Scalars['Float']>;
  collectionSum?: Maybe<Scalars['Float']>;
  clientAdvanceSum?: Maybe<Scalars['Float']>;
  advClientAmt?: Maybe<Scalars['Float']>;
  completionPercentage?: Maybe<Scalars['JSON']>;
  conBilling?: Maybe<Scalars['JSON']>;
  collectionListing?: Maybe<Scalars['JSON']>;
  clientAdvanceListing?: Maybe<Scalars['JSON']>;
  subconBilling?: Maybe<Scalars['JSON']>;
  supplierInvoice?: Maybe<Scalars['JSON']>;
  miscExpenseStatus?: Maybe<Scalars['JSON']>;
  subconPaymentRefund?: Maybe<Scalars['JSON']>;
  subconSuppAdvanceListing?: Maybe<Scalars['JSON']>;
  supplierPaymentStatus?: Maybe<Scalars['JSON']>;
  advClientListing?: Maybe<Scalars['JSON']>;
  miscExpense?: Maybe<Scalars['JSON']>;
  latestBillingDate?: Maybe<Scalars['DateTime']>;
  latestCollectionDate?: Maybe<Scalars['DateTime']>;
  latestSubconBillDate?: Maybe<Scalars['DateTime']>;
  latestSupplierInvDate?: Maybe<Scalars['DateTime']>;
  latestMiscExpStatusDate?: Maybe<Scalars['DateTime']>;
  latestSubconPaymentRefundDate?: Maybe<Scalars['DateTime']>;
  latestSupplierPaymentDateStatus?: Maybe<Scalars['DateTime']>;
  latestClientAdvanceDate?: Maybe<Scalars['DateTime']>;
  latestSubconSuppAdvanceDate?: Maybe<Scalars['DateTime']>;
};

export type FollowUpEntity = AuditEntity & {
  __typename?: 'FollowUpEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  contactPerson: Scalars['JSON'];
  followUpStatus: FollowUpStatus;
  customer?: Maybe<CustomerEntity>;
};

export type FollowUpInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  customerID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  contactPerson: ContactPersonInput;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum FollowUpStatus {
  Call = 'CALL',
  Whatsapp = 'WHATSAPP',
  Audio = 'AUDIO',
  Email = 'EMAIL',
  Message = 'MESSAGE'
}

export type Forex = AuditEntity & {
  __typename?: 'Forex';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
};

export type ForexInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export enum Frequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type FuelConsumption = {
  __typename?: 'FuelConsumption';
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  consumptionMethod?: Maybe<Scalars['String']>;
};

export type FuelConsumptionInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  consumptionMethod?: Maybe<Scalars['String']>;
};

export enum FuelConsumptionMethod {
  Km = 'KM',
  Hour = 'HOUR'
}

export type FuelDischargeDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type FuelDischargeEntity = AuditEntity & {
  __typename?: 'FuelDischargeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  dischargeDate: Scalars['DateTime'];
  docNo?: Maybe<Scalars['String']>;
  dischargeToTable: Scalars['String'];
  dischargeToID: Scalars['String'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  operatorName: Scalars['String'];
  isMalfunction?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['JSON']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  approvalStatus: ApprovalStatus;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
};

export type FuelDischargeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  dischargeDate: Scalars['DateTime'];
  dischargeToTable: Scalars['String'];
  dischargeToID: Scalars['String'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  operatorName: Scalars['String'];
  companyID: Scalars['String'];
  isMalfunction: Scalars['Boolean'];
  attachment?: Maybe<Scalars['Upload']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};

export type FuelDischargeUpdateStatus = {
  approvalStatus: ApprovalStatus;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
};

export type FuelMeterReadingDeleteInput = {
  ID: Scalars['String'];
};

export type FuelMeterReadingEntity = AuditEntity & {
  __typename?: 'FuelMeterReadingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docDate: Scalars['String'];
  lastSupply: Scalars['Float'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  lastDischarge: Scalars['Float'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  balanceCF: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
  /** CustomFieldResolver */
  unionTank?: Maybe<TankMachinery>;
};

export type FuelMeterReadingInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  docDate: Scalars['String'];
  lastSupply: Scalars['Float'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  lastDischarge: Scalars['Float'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  balanceCF: Scalars['Float'];
};

export type FuelSupplyActionInput = {
  companyID: Scalars['String'];
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  approvalStatus: ApprovalStatus;
};

export type FuelSupplyDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type FuelSupplyEntity = AuditEntity & {
  __typename?: 'FuelSupplyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docDate: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  doNo: Scalars['String'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  supplyByTable: Scalars['String'];
  supplyRefID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['JSON']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  approvalStatus: ApprovalStatus;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
};

export type FuelSupplyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  doNo: Scalars['String'];
  docDate: Scalars['String'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  supplyByTable: Scalars['String'];
  supplyRefID: Scalars['String'];
  companyID: Scalars['String'];
  attachment?: Maybe<Scalars['Upload']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};

export type FuelSupplyUpdateStatus = {
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type FuelTankSetupDeleteInput = {
  ID: Scalars['String'];
};

export type FuelTankSetupInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  tankType: TankType;
  name: Scalars['String'];
  regNo: Scalars['String'];
  companyID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  fuelConsumptionLowerLimit?: Maybe<Scalars['Float']>;
  fuelConsumptionUpperLimit?: Maybe<Scalars['Float']>;
  fuelConsumptionMethod: FuelConsumptionMethod;
  serialNo?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Scalars['Upload']>;
};

export type GlAdjustmentDynamicEntity = AuditEntity & {
  __typename?: 'GLAdjustmentDynamicEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  adjustmentTypeID: Scalars['String'];
  adjustmentType?: Maybe<AdjustmentTypeEntity>;
  accountCode: Scalars['String'];
  commonStatus: CommonStatus;
};

export type GlAdjustmentDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  adjustmentTypeID: Scalars['String'];
  accountCode: Scalars['String'];
};

export type GlAssociationEntity = AuditEntity & {
  __typename?: 'GLAssociationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  glInterfaceMaster?: Maybe<Array<GlInterfaceMasterEntity>>;
};

export type GlAssociationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type GlDetailLedgerBatchInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type GlExportDeleteInput = {
  ID: Scalars['String'];
};

export type GlExportDetailEntity = AuditEntity & {
  __typename?: 'GLExportDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glExportID: Scalars['String'];
  accountCode: Scalars['String'];
  type: Scalars['String'];
  associateRefTable: Scalars['String'];
  docRefTable: Scalars['String'];
  amount: Scalars['Float'];
  negative: Scalars['Boolean'];
  commonStatus: CommonStatus;
  glExport?: Maybe<GlExportEntity>;
};

export type GlExportDetailInput = {
  accountCode: Scalars['String'];
  type: Scalars['String'];
  amount: Scalars['Float'];
  negative: Scalars['Boolean'];
  associateRefTable: Scalars['String'];
  claimColumn?: Maybe<Scalars['String']>;
};

export type GlExportDetailSourceEntity = AuditEntity & {
  __typename?: 'GLExportDetailSourceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glExportID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  claimDocAmt?: Maybe<Scalars['Float']>;
  associateRefID: Scalars['String'];
  associateRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  docDate: Scalars['String'];
  docNo: Scalars['String'];
  doNo?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  glExport?: Maybe<GlExportEntity>;
  contract?: Maybe<ContractEntity>;
};

export type GlExportEntity = AuditEntity & {
  __typename?: 'GLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate: Scalars['String'];
  commonStatus: CommonStatus;
  glExportDetail?: Maybe<Array<GlExportDetailEntity>>;
  glExportDetailSource?: Maybe<Array<GlExportDetailSourceEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type GlExportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate: Scalars['String'];
};

export type GlExportPostResponse = {
  __typename?: 'GLExportPostResponse';
  GLPost?: Maybe<Scalars['Boolean']>;
  ExportedGLTransfer?: Maybe<Array<ExportedGlTransferResponse>>;
};

export type GlInterfaceDetailEntity = AuditEntity & {
  __typename?: 'GLInterfaceDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glInterfaceMasterID: Scalars['String'];
  glInterfaceMaster?: Maybe<GlInterfaceMasterEntity>;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfaceDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  glInterfaceMasterID: Scalars['String'];
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfaceDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxTypeID?: Maybe<Scalars['String']>;
  adjustmentTypeID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  accountCode: Scalars['String'];
};

export type GlInterfaceMasterEntity = AuditEntity & {
  __typename?: 'GLInterfaceMasterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  glAssociationID: Scalars['String'];
  glTransactionID: Scalars['String'];
  index?: Maybe<Scalars['Float']>;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
  glInterfaceDetail?: Maybe<GlInterfaceDetailEntity>;
  glAssociation?: Maybe<GlAssociationEntity>;
  glTransaction?: Maybe<GlTransactionEntity>;
};

export type GlInterfaceMasterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associationType: AssociationType;
  transactionType: TransactionType;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfacePolicyEntity = AuditEntity & {
  __typename?: 'GLInterfacePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glIntegration: Scalars['String'];
  glTransactionGeneration: Scalars['String'];
  autoGlExport: Scalars['Boolean'];
};

export type GlInterfaceSetupDeleteInput = {
  ID: Scalars['String'];
};

export type GlItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  doID?: Maybe<Scalars['String']>;
  doItemID?: Maybe<Scalars['String']>;
  costClass?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
};

export type GlLedgerInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
  GLExportDetailInput: Array<GlExportDetailInput>;
};

export type GlMonth = {
  __typename?: 'GLMonth';
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
};

export type GlTaxDynamicEntity = AuditEntity & {
  __typename?: 'GLTaxDynamicEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID: Scalars['String'];
  taxType?: Maybe<ConTaxTypeEntity>;
  accountCode: Scalars['String'];
  commonStatus: CommonStatus;
};

export type GlTaxDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  taxTypeID: Scalars['String'];
  accountCode: Scalars['String'];
};

export type GlTransactionEntity = AuditEntity & {
  __typename?: 'GLTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  glInterfaceMaster?: Maybe<Array<GlInterfaceMasterEntity>>;
};

export type GlTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type GrnEntity = AuditEntity & {
  __typename?: 'GRNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplier: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  doID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  docRef: Scalars['String'];
  cancelRejectDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  grnStatus: DoStatus;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
};

export type GrnItem = {
  __typename?: 'GRNItem';
  costItemID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  uom?: Maybe<Uom>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type GrnItemEntity = AuditEntity & {
  __typename?: 'GRNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  grnID: Scalars['String'];
  doItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  grnAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  grn?: Maybe<GrnEntity>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  uom?: Maybe<UomEntity>;
  costItem?: Maybe<CostItemEntity>;
};

export type GrtnDeleteInput = {
  ID: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnItemDeleteInput = {
  ID: Scalars['String'];
};

export type GrtnItemInput = {
  grtnID?: Maybe<Scalars['String']>;
  doItemID: Scalars['String'];
  returnedQty: Scalars['Float'];
  replacement: Scalars['Boolean'];
};

export type GrtnItemInputGrn = {
  doItemID: Scalars['String'];
  DOItemInput: Array<DoItemInput>;
};

export type GoodReceiveNoteRpaResult = {
  __typename?: 'GoodReceiveNoteRPAResult';
  customerAddress?: Maybe<Scalars['String']>;
  customerAddressRecipient?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  grnDate?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceID?: Maybe<Scalars['String']>;
  invoiceTotal?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  remittanceAddressRecipient?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
  vendorAddress?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
  invoiceItems?: Maybe<Array<GrnItem>>;
};

export type GoodReturnNoteEntity = AuditEntity & {
  __typename?: 'GoodReturnNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  docDate: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  grtnStatus: PurchaseStatus;
  goodReturnNoteItem?: Maybe<Array<GoodReturnNoteItemEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  doItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  GRTNdocAmt?: Maybe<Scalars['Float']>;
};


export type GoodReturnNoteEntityGrtNdocAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type GoodReturnNoteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  siteID: Scalars['String'];
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  docDate: Scalars['String'];
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  grtnStatus?: Maybe<PurchaseStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GoodReturnNoteItemEntity = AuditEntity & {
  __typename?: 'GoodReturnNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  grtnID: Scalars['String'];
  goodReturnNote?: Maybe<GoodReturnNoteEntity>;
  doItemID: Scalars['String'];
  doItem?: Maybe<DoItemEntity>;
  returnedQty: Scalars['Float'];
  outstandingQty?: Maybe<Scalars['Float']>;
  replacement: Scalars['Boolean'];
  returnedDoItem?: Maybe<Array<DoItemEntity>>;
};

export enum GrntInsStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING'
}

export type GroupBizVo = {
  __typename?: 'GroupBizVO';
  contractSum?: Maybe<Scalars['Float']>;
  approvedVoAmt?: Maybe<Scalars['Float']>;
  approvedsubVoAmt?: Maybe<Scalars['Float']>;
  voPerc: Scalars['String'];
  vosubPerc: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type GrtnActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  grtnStatus: PurchaseStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnRejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type GuarInsTypeDeleteInput = {
  ID: Scalars['String'];
};

export type GuarInsTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GuaranteeTypeEntity = AuditEntity & {
  __typename?: 'GuaranteeTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  contract?: Maybe<Array<ContractGuaranteeEntity>>;
  subcontract?: Maybe<Array<SubcontractGuaranteeEntity>>;
};

export type HolidayTypeDeleteInput = {
  ID: Scalars['String'];
};

export type HolidayTypeEntity = AuditEntity & {
  __typename?: 'HolidayTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  wagesRatePolicy?: Maybe<Array<WagesRatePolicyEntity>>;
  commonStatus: CommonStatus;
  calendar?: Maybe<Array<CalendarEntity>>;
  calendarPHP?: Maybe<Array<CalendarPhPolicyEntity>>;
  workingHourPolicy?: Maybe<Array<WorkingHourPolicyEntity>>;
};

export type HolidayTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};

export type HolidayTypeRate = {
  __typename?: 'HolidayTypeRate';
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
};

export type HolidayTypeRateInput = {
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['DateTime']>;
  cpcDate?: Maybe<Scalars['DateTime']>;
  cmgdDate?: Maybe<Scalars['DateTime']>;
  dlpDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  voStartDate?: Maybe<Scalars['DateTime']>;
  voEndDate?: Maybe<Scalars['DateTime']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type IncomingEInvoiceEntity = AuditEntity & {
  __typename?: 'IncomingEInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
};

export type InsuranceTypeEntity = AuditEntity & {
  __typename?: 'InsuranceTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  contract: Array<ContractInsuranceEntity>;
  subcontract?: Maybe<Array<SubcontractInsuranceEntity>>;
};

export type InvitedVendorsEntity = AuditEntity & {
  __typename?: 'InvitedVendorsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  officeNo: Scalars['String'];
  email: Scalars['String'];
  description: Scalars['String'];
};

export type InvoiceRpaResult = {
  __typename?: 'InvoiceRPAResult';
  contractID?: Maybe<Scalars['String']>;
  contractTitle?: Maybe<Scalars['String']>;
  deliveryOrderNo?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceNo?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Items>>;
};

export type ItemApprovedAmountInput = {
  ID?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
};


export type JobTypeDeleteInput = {
  ID: Scalars['String'];
};

export type JobTypeEntity = AuditEntity & {
  __typename?: 'JobTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  marketRate: Scalars['String'];
  worker?: Maybe<Array<WorkerEntity>>;
  commonStatus: CommonStatus;
};

export type JobTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  marketRate?: Maybe<Scalars['String']>;
};

export type LedgerCheckingSummary = {
  __typename?: 'LedgerCheckingSummary';
  isApprovable?: Maybe<Scalars['Boolean']>;
  hasNextLevel?: Maybe<Scalars['Boolean']>;
  hasGuideline?: Maybe<Scalars['Boolean']>;
  validFlow?: Maybe<Scalars['Boolean']>;
};

export type LedgerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  allocationStatus?: Maybe<AllocationStatus>;
};

export type LedgerPdfInput = {
  refID: Scalars['String'];
  refTable: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LocalBankEntity = AuditEntity & {
  __typename?: 'LocalBankEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  countryID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  code: Scalars['String'];
  address: Address;
  contactNo: Scalars['String'];
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type LocalBankInput = {
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  contactNo?: Maybe<Scalars['String']>;
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: UserResponse;
};

export enum LogoPosition {
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT'
}

export type MaintenanceCostReturnInput = {
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
};

export type MaintenanceEntity = AuditEntity & {
  __typename?: 'MaintenanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  plantMachinery?: Maybe<PlantMachineryEntity>;
  workshopID: Scalars['String'];
  workshop?: Maybe<WorkshopEntity>;
  assignmentID?: Maybe<Scalars['String']>;
  assignment?: Maybe<AssignmentEntity>;
  assignedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  assignmentDate: Scalars['DateTime'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  returnDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  usageReading?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLog?: Maybe<Array<Scalars['JSON']>>;
  maintenanceStatus: AsgMainStatus;
};

export type MaintenanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  plantMachineryID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  usageReading: Scalars['Float'];
  cost: Scalars['Float'];
  assignedQty?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
  assignmentID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type MiscExpenseCancellationInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};

export type MiscExpenseEntity = AuditEntity & {
  __typename?: 'MiscExpenseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  docRef: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  docDate: Scalars['String'];
  transactionDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  status: MiscExpenseStatus;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
};

export type MiscExpenseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
  docRef: Scalars['String'];
  baseAmt: Scalars['Float'];
  docAmt: Scalars['Float'];
  taxSchemeID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<MiscExpenseStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum MiscExpenseStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type MonthPeriod = {
  __typename?: 'MonthPeriod';
  month?: Maybe<Scalars['DateTime']>;
  outstandingAmt?: Maybe<Scalars['Float']>;
  dueAmt?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  completionPerc: Scalars['Boolean'];
  updateCompletionPerc: Scalars['Boolean'];
  updateBQ: BqEntity;
  deleteBQ: Scalars['Boolean'];
  createBQ: Scalars['Boolean'];
  createBQWbsAssignment: BqWbsAssignmentEntity;
  updateBQWbsAssignment: BqWbsAssignmentEntity;
  deleteBQWbsAssignment: Scalars['Boolean'];
  assignBqWbsAssignment: Scalars['Boolean'];
  unassignBqWbsAssignment: Scalars['Boolean'];
  createBillInterest: BillInterestEntity;
  updateBillInterest: BillInterestEntity;
  createDocReminder: DocReminderEntity;
  updateDocReminder: DocReminderEntity;
  cancelPaymentReminder: Scalars['Boolean'];
  createPaymentReminder: Scalars['Boolean'];
  createCustomerReminder: Scalars['Boolean'];
  createContract: ContractEntity;
  deleteContractAllAccess: Scalars['Boolean'];
  updateContract: Scalars['Boolean'];
  insertContacts: Scalars['Boolean'];
  actionOnContractStatus: Scalars['Boolean'];
  createContractExtensionDate: Scalars['Boolean'];
  createContractCertificate: Scalars['Boolean'];
  createContractGuarantee: ContractGuaranteeEntity;
  updateContractGuarantee: ContractGuaranteeEntity;
  createContractInsurance: ContractInsuranceEntity;
  updateContractInsurance: ContractInsuranceEntity;
  cancelContractGuarantee: Scalars['Boolean'];
  cancelContractInsurance: Scalars['Boolean'];
  updateProjectBudget: ProjectBudgetEntity;
  deleteProjectBudget: Scalars['Boolean'];
  addOriginalBudget: Scalars['Boolean'];
  addRevisedBudget: Scalars['Boolean'];
  createContractVO: ContractVoEntity;
  updateContractVO: ContractVoEntity;
  actionOnVOStatus: Scalars['Boolean'];
  deleteContractVO: Scalars['Boolean'];
  createContractClaim: ContractClaimEntity;
  updateContractClaim: ContractClaimEntity;
  deleteContractClaim: Scalars['Boolean'];
  submitRetentionClaim: ContractClaimEntity;
  approveRetentionClaim: ContractClaimEntity;
  submitClaim: ContractClaimEntity;
  approveClaim: Scalars['JSON'];
  updateClaimDetails: Scalars['Boolean'];
  rejectClaim: Scalars['Boolean'];
  cancelClaim: Scalars['Boolean'];
  createContractAdvance: ContractAdvanceEntity;
  updateContractAdvance: ContractAdvanceEntity;
  deleteContractAdvance: Scalars['Boolean'];
  cancelContractAdvance: Scalars['Boolean'];
  createContractReceipt: ContractReceiptEntity;
  updateContractReceipt: ContractReceiptEntity;
  deleteContractReceipt: Scalars['Boolean'];
  createContractRefund: ContractRefundEntity;
  updateContractRefund: ContractRefundEntity;
  deleteContractRefund: Scalars['Boolean'];
  cancelContractRefund: Scalars['Boolean'];
  makeContractRefund: ContractRefundEntity;
  cancelContractReceipt: Scalars['Boolean'];
  createContractMiscInvoice: ContractMiscInvoiceEntity;
  updateContractMiscInvoice: ContractMiscInvoiceEntity;
  deleteContractMiscInvoice: Scalars['Boolean'];
  cancelContractMiscInvoice: Scalars['Boolean'];
  createContractDN: ContractDnEntity;
  updateContractDN: ContractDnEntity;
  deleteContractDN: Scalars['Boolean'];
  cancelContractDN: Scalars['Boolean'];
  createContractCN: ContractCnEntity;
  updateContractCN: ContractCnEntity;
  deleteContractCN: Scalars['Boolean'];
  cancelContractCN: Scalars['Boolean'];
  createClientDN: ClientDnEntity;
  updateClientDN: ClientDnEntity;
  deleteClientDN: Scalars['Boolean'];
  cancelClientDN: Scalars['Boolean'];
  createClientCN: ClientCnEntity;
  updateClientCN: ClientCnEntity;
  deleteClientCN: Scalars['Boolean'];
  cancelClientCN: Scalars['Boolean'];
  makeContractAllocation: Scalars['Boolean'];
  resetContractAllocation: Scalars['Boolean'];
  createCostCode: Scalars['Boolean'];
  createCustomerCompanyAssignment: CustomerCompanyAssignmentEntity;
  updateCustomerCompanyAssignment: CustomerCompanyAssignmentEntity;
  assignCustomerCompany: Scalars['Boolean'];
  unassignCustomerCompany: Scalars['Boolean'];
  assignCustomerWithCompany: Scalars['Boolean'];
  unassignCustomerWithCompany: Scalars['Boolean'];
  createCustomer: CustomerEntity;
  updateCustomer: CustomerEntity;
  createPlatformCustomer: CustomerEntity;
  updatePlatformCustomer: CustomerEntity;
  deleteCustomer: Scalars['Boolean'];
  addCustomerContact: Scalars['Boolean'];
  deletePlatformCustomerProfile: Scalars['Boolean'];
  customerImportValidation: Scalars['JSON'];
  createCustomerImport: Scalars['Boolean'];
  createFollowUp: FollowUpEntity;
  updateFollowUp: FollowUpEntity;
  deleteCBExport: Scalars['Boolean'];
  exportCBLedger: Scalars['Boolean'];
  deleteGLExport: Scalars['Boolean'];
  exportGLLedger: Scalars['Boolean'];
  updateGLExport: Scalars['Boolean'];
  createGLAssociation: GlAssociationEntity;
  updateGLAssociation: GlAssociationEntity;
  createGLTransaction: GlTransactionEntity;
  updateGLTransaction: GlTransactionEntity;
  createGLInterfaceMaster: GlInterfaceMasterEntity;
  updateGLInterfaceMaster: GlInterfaceMasterEntity;
  deleteGLInterfaceMaster: Scalars['Boolean'];
  createGLInterfaceDetail: GlInterfaceDetailEntity;
  updateGLInterfaceDetail: GlInterfaceDetailEntity;
  deleteGLInterfaceDetail: Scalars['Boolean'];
  createGLTaxDynamic: GlTaxDynamicEntity;
  updateGLTaxDynamic: GlTaxDynamicEntity;
  deleteGLTaxDynamic: Scalars['Boolean'];
  createGLAdjustmentDynamic: GlAdjustmentDynamicEntity;
  updateGLAdjustmentDynamic: GlAdjustmentDynamicEntity;
  deleteGLAdjustmentDynamic: Scalars['Boolean'];
  cancelGLDynamicSetup: Scalars['Boolean'];
  saveGLAccountCode: Scalars['Boolean'];
  createGLInterfaceData: Scalars['Boolean'];
  insertNewGL: Scalars['Boolean'];
  deleteGLTransaction: Scalars['Boolean'];
  updateDepartmentGLAccountCode: Scalars['Boolean'];
  createGuaranteeType: GuaranteeTypeEntity;
  updateGuaranteeType: GuaranteeTypeEntity;
  deleteGuaranteeType: Scalars['Boolean'];
  createInsuranceType: InsuranceTypeEntity;
  updateInsuranceType: InsuranceTypeEntity;
  cancelGuaranteeType: Scalars['Boolean'];
  activateGuaranteeType: Scalars['Boolean'];
  cancelInsuranceType: Scalars['Boolean'];
  activateInsuranceType: Scalars['Boolean'];
  deleteInsuranceType: Scalars['Boolean'];
  createMiscExpense: Scalars['Boolean'];
  updateMiscExpense: Scalars['Boolean'];
  cancelMiscExpense: Scalars['Boolean'];
  deleteMiscExpense: Scalars['Boolean'];
  createCostCenter: CostCenterEntity;
  updateCostCenter: CostCenterEntity;
  deleteCostCenter: Scalars['Boolean'];
  cancelCostCenter: Scalars['Boolean'];
  createCurrencyExchange: CurrencyExchangeEntity;
  updateCurrencyExchange: Scalars['Boolean'];
  deleteCurrencyExchange: Scalars['Boolean'];
  createConTaxType: ConTaxTypeEntity;
  updateConTaxType: ConTaxTypeEntity;
  deleteConTaxType: Scalars['Boolean'];
  activateConTaxType: Scalars['Boolean'];
  createConTaxScheme: ConTaxSchemeEntity;
  updateConTaxScheme: ConTaxSchemeEntity;
  deleteConTaxScheme: Scalars['Boolean'];
  activateConTaxScheme: Scalars['Boolean'];
  createConTaxEffectiveDate: ConTaxEffectiveDateEntity;
  updateConTaxEffectiveDate: ConTaxEffectiveDateEntity;
  deleteConTaxEffectiveDate: Scalars['Boolean'];
  activateConTaxEffectiveDate: Scalars['Boolean'];
  insertConTax: ConTaxSchemeEntity;
  updateConTax: Scalars['Boolean'];
  deleteTaxScheme: Scalars['Boolean'];
  createProfitRecogFormula: ProfitRecogFormulaEntity;
  updateProfitRecogFormula: ProfitRecogFormulaEntity;
  deleteProfitRecogFormula: Scalars['Boolean'];
  createProfitRecogParameter: ProfitRecogParameterEntity;
  updateProfitRecogParameter: Scalars['Boolean'];
  createProfitRecogAssignment: ProfitRecogAssignmentEntity;
  updateProfitRecogAssignment: ProfitRecogAssignmentEntity;
  createProfitRecogGeneration: ProfitRecogGenerationEntity;
  updateProfitRecogGeneration: ProfitRecogGenerationEntity;
  createProfitRecogGenParamAssignment: ProfitRecogGenParamAssgmtEntity;
  updateProfitRecogGenParamAssignment: ProfitRecogGenParamAssgmtEntity;
  createProfitGeneration: ProfitRecogGenerationEntity;
  updateProfitGeneration: ProfitRecogGenerationEntity;
  updateProfitRecogFormulaAssignment: Scalars['Boolean'];
  createProfitRecogGLAccountCode: ProfitRecogGlAccountCodeEntity;
  updateProfitRecogGLAccountCode: ProfitRecogGlAccountCodeEntity;
  createProfitRecogGLJournal: ProfitRecogGlJournalEntity;
  updateProfitRecogGLJournal: ProfitRecogGlJournalEntity;
  createProjectExpense: ProjectExpenseEntity;
  updateProjectExpense: Scalars['Boolean'];
  deleteProjectExpense: Scalars['Boolean'];
  cancelProjectExpense: Scalars['Boolean'];
  rejectProjectExpense: Scalars['Boolean'];
  createProjectOpportunityAttachment: ProjectOpportunityAttachmentEntity;
  updateProjectOpportunityAttachment: ProjectOpportunityAttachmentEntity;
  deleteProjectOpportunityAttachment: Scalars['Boolean'];
  createProjectOpportunity: ProjectOpportunityEntity;
  updateProjectOpportunity: ProjectOpportunityEntity;
  deleteProjectOpportunity: Scalars['Boolean'];
  createProjectOpportunityActivityLogs: Scalars['Boolean'];
  createProjectOpportunitySubmission: ProjectOpportunitySubmissionEntity;
  updateProjectOpportunitySubmission: ProjectOpportunitySubmissionEntity;
  deleteProjectOpportunitySubmission: Scalars['Boolean'];
  createWBSBudgetExcel: Scalars['Boolean'];
  createBudget: WbsBudgetEntity;
  updateBudget: WbsBudgetEntity;
  submitBudget: WbsBudgetEntity;
  actionOnBudgetStatus: Scalars['Boolean'];
  createBudgetRevision: WbsBudgetEntity;
  copyBudgetContract: Scalars['Boolean'];
  deleteBudget: Scalars['Boolean'];
  switchBudget: Scalars['Boolean'];
  createWbsBudgetDetail: WbsBudgetDetailEntity;
  updateWbsBudgetDetail: WbsBudgetDetailEntity;
  deleteBudgetDetail: Scalars['Boolean'];
  createBudgetDetail: WbsBudgetDetailEntity;
  updateBudgetDetail: Scalars['Boolean'];
  updateBudgetSchedule: Scalars['Boolean'];
  updateWbsDayworkCostRevenue: Scalars['Boolean'];
  createWbsBudgetTransfer: WbsBudgetTransferHeaderEntity;
  updateWbsBudgetTransfer: Scalars['Boolean'];
  deleteBudgetTransfer: Scalars['Boolean'];
  actionOnBudgetTransferStatus: Scalars['Boolean'];
  createWBS: WbsEntity;
  updateWBS: Scalars['Boolean'];
  deleteWBS: Scalars['Boolean'];
  moveSequenceWBS: Scalars['Boolean'];
  defaultWBS: Scalars['Boolean'];
  executeWBSImportStoreProcedure: Scalars['Boolean'];
  WBSImport: Scalars['Boolean'];
  WBSImportValidation: Scalars['JSON'];
  createWbsSchedule: WbsScheduleEntity;
  updateWbsSchedule: WbsScheduleEntity;
  createWBSUtilizedBudget: WbsUtilizedBudgetEntity;
  updateWBSUtilizedBudget: WbsUtilizedBudgetEntity;
  deleteWBSUtilizedBudget: Scalars['Boolean'];
  actionOnUtilizedBudgetStatus: Scalars['Boolean'];
  insertPermissionTable: Scalars['Boolean'];
  insertSitePermissionTable: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  alertPolicySetup: Scalars['Boolean'];
  updateAlertSqlCode: Scalars['JSON'];
  deleteExistPDF: Scalars['Boolean'];
  deleteDocuments: Scalars['Boolean'];
  uploadPDFPO: Scalars['Boolean'];
  uploadPDFDocument: Array<DocumentsEntity>;
  shareAttachment?: Maybe<ShareAttachmentEntity>;
  createContractNews: ContractNewsEntity;
  updateContractNews: ContractNewsEntity;
  deleteContractNews: Scalars['Boolean'];
  createContractNewsRead: ContractNewsReadEntity;
  updateContractNewsRead: ContractNewsReadEntity;
  deleteContractNewsRead: Scalars['Boolean'];
  deleteCollectionActivity: Scalars['Boolean'];
  createCollectionActivity: Scalars['Boolean'];
  deleteColActivity: Scalars['Boolean'];
  updateCollectionActivity: Scalars['Boolean'];
  createMultipleCollectionActivity: Scalars['Boolean'];
  updateCollectionStatus: Scalars['Boolean'];
  patchNewDocNum: Scalars['Boolean'];
  createDocNumRunningNumber: Scalars['Boolean'];
  generateDocumentNumberSetup: Scalars['Boolean'];
  resetGeneralRunningNumber: Scalars['Boolean'];
  resetContractRunningNumber: Scalars['Boolean'];
  contractRunNumChecker: Scalars['Boolean'];
  createContractRunNum: Scalars['Boolean'];
  deleteDocRunningNumber: Scalars['Boolean'];
  createCompany: Scalars['Boolean'];
  createRawCompany: Scalars['Boolean'];
  updateCompany: Scalars['Boolean'];
  updateRawCompany: Scalars['Boolean'];
  deleteCompany: Scalars['Boolean'];
  deleteRawCompany: Scalars['Boolean'];
  updateContact: ContactEntity;
  addContractContact: Scalars['Boolean'];
  addSubcontractContact: Scalars['Boolean'];
  deleteSubcontractData: Scalars['Boolean'];
  deleteSiteData: Scalars['Boolean'];
  deleteContractData: Scalars['Boolean'];
  deleteAssociateData: Scalars['Boolean'];
  deleteLedgersWorkFlow: Scalars['Boolean'];
  deleteSpecificLedgers: Scalars['Boolean'];
  deletePOData: Scalars['Boolean'];
  deleteClientLedgersData: Scalars['Boolean'];
  deleteSubconLedgersData: Scalars['Boolean'];
  deleteSupplierLedgersData: Scalars['Boolean'];
  deleteCostData: Scalars['Boolean'];
  deletePolicyData: Scalars['Boolean'];
  deleteNotificationData: Scalars['Boolean'];
  deleteWorkFlowData: Scalars['Boolean'];
  updateContractData: Scalars['Boolean'];
  updateSubcontractData: Scalars['Boolean'];
  updateClientData: Scalars['Boolean'];
  updateSubconData: Scalars['Boolean'];
  updateSupplierData: Scalars['Boolean'];
  updatePOData: Scalars['Boolean'];
  updateLedgersData: Scalars['Boolean'];
  rejectLedgerTable: Scalars['Boolean'];
  resetRolePermission: Scalars['Boolean'];
  removeSuperUserACL: Scalars['Boolean'];
  patchEditableAllocation: Scalars['Boolean'];
  patchContractAllocation: Scalars['Boolean'];
  patchSupplierAllocation: Scalars['Boolean'];
  patchContractAllocationIDs: Scalars['Boolean'];
  patchRejClaimData: Scalars['Boolean'];
  patchAdjustmentDocRefData: Scalars['Boolean'];
  patchClaimBaseDocAmtData: Scalars['Boolean'];
  patchCancelledAllocClaimData: Scalars['Boolean'];
  patchCNDNClaimData: Scalars['Boolean'];
  patchRetentionClaimReject: Scalars['Boolean'];
  patchRetentionClaimData: Scalars['Boolean'];
  patchSAccountData: Scalars['JSON'];
  patchDocumentsData: Scalars['Boolean'];
  patchCompanyImage: Scalars['Boolean'];
  patchContractConSubconData: Scalars['Boolean'];
  patchRealConDateData: Scalars['Boolean'];
  patchConDateData: Scalars['Boolean'];
  patchVODateData: Scalars['Boolean'];
  patchGuarInsDateData: Scalars['Boolean'];
  insertNewContractBudget: Scalars['Boolean'];
  patchContractClaimAllocation: Scalars['Boolean'];
  patchContractCompany: Scalars['Boolean'];
  patchCompanyInAccount: Scalars['JSON'];
  patchNullDocNoExpenses: Scalars['JSON'];
  patchSiteDocumentData: Scalars['Boolean'];
  patchCostCategoryData: Scalars['Boolean'];
  patchDueDateNullData: Scalars['JSON'];
  patchInvoiceData: Scalars['Boolean'];
  patchNullTrxDateData: Scalars['Boolean'];
  removeAllocData: Scalars['Boolean'];
  patchPlantStatusData: Scalars['Boolean'];
  patchProjectExpense: Scalars['Boolean'];
  patchNullGRTNItemData: Scalars['Boolean'];
  patchNullPOItemData: Scalars['Boolean'];
  patchNullPOSiteData: Scalars['Boolean'];
  patchRfqItemSubmissionIsSelected: Scalars['Boolean'];
  patchDOItemDeliveredQty: Scalars['Boolean'];
  patchDocDueDateNullData: Scalars['Boolean'];
  patchExpectedDateNullData: Scalars['Boolean'];
  patchGRTNTrxDateNullData: Scalars['Boolean'];
  patchMainSiteData: Scalars['Boolean'];
  insertLongLatData: Scalars['Boolean'];
  insertSiteInventoryData: Scalars['Boolean'];
  patchNullDocFolderData: Scalars['Boolean'];
  patchEmptyWorkflow: Scalars['Boolean'];
  patchNullSubconTenderData: Scalars['Boolean'];
  patchSubconCostCategory: Scalars['Boolean'];
  patchWorkerSiteAssignment: Scalars['Boolean'];
  patchSupplierTypeData: Scalars['Boolean'];
  patchSupplierWithSupplierType: Scalars['Boolean'];
  patchWbsCostItemDetail: Scalars['Boolean'];
  patchWbsCostDetail: Scalars['Boolean'];
  patchWbsSchedule: Scalars['Boolean'];
  createDocumentFolder: Scalars['Boolean'];
  updateDocumentFolder: Scalars['Boolean'];
  deleteDocumentFolder: Scalars['Boolean'];
  implementBaseUOM: Scalars['Boolean'];
  extraSystemAdminSetup: Scalars['Boolean'];
  extraGeneralSettingSetup: Scalars['Boolean'];
  extraDBSetup: Scalars['Boolean'];
  extraGLMasterSetup: Scalars['Boolean'];
  extraGLTransactionSetup: Scalars['Boolean'];
  extraGLGenSettingSetup: Scalars['Boolean'];
  platformContactSetup: Scalars['Boolean'];
  initialCurrencySetup: Scalars['Boolean'];
  initialDatabaseSetup: Scalars['Boolean'];
  initialMasterFileSetup: Scalars['Boolean'];
  deleteMasterFile: Scalars['Boolean'];
  initialSiteMasterFileSetup: Scalars['Boolean'];
  deleteSiteMasterFile: Scalars['Boolean'];
  initialGeneralSettingSetup: Scalars['Boolean'];
  deleteGenSetting: Scalars['Boolean'];
  initialGLInterfaceSetup: Scalars['Boolean'];
  initialDocNumSetup: Scalars['Boolean'];
  generateGLMasterFileSetup: Scalars['Boolean'];
  deleteGLMasterFileSetup: Scalars['Boolean'];
  generateGLInitMasterSetup: Scalars['Boolean'];
  deleteGLInitMasterSetup: Scalars['Boolean'];
  initialProfitRecogParameter: Scalars['Boolean'];
  createBatchPaymentDetail: BatchPaymentDetailEntity;
  updateBatchPaymentDetail: BatchPaymentDetailEntity;
  deleteBatchPaymentDetail: Scalars['Boolean'];
  cancelBatchPaymentDetail: Scalars['Boolean'];
  createBatchPaymentHeader: BatchPaymentHeaderEntity;
  updateBatchPaymentHeader: BatchPaymentHeaderEntity;
  deleteBatchPaymentHeader: Scalars['Boolean'];
  cancelBatchPaymentHeader: Scalars['Boolean'];
  submitBatchPayment: Scalars['Boolean'];
  confirmBatchPayment: Scalars['JSON'];
  payBatchPayment: Scalars['JSON'];
  createSiteMaterialPolicy: SiteMaterialPolicyEntity;
  updateSiteMaterialPolicy: SiteMaterialPolicyEntity;
  createSiteMaterialContractPolicy: SiteMaterialContractPolicyEntity;
  updateSiteMaterialContractPolicy: SiteMaterialContractPolicyEntity;
  createMaterialPolicy: Scalars['Boolean'];
  createMaterialContractPolicy: Scalars['Boolean'];
  accountSubscriptionInfo: Scalars['Boolean'];
  createTaxMasterFile: Scalars['Boolean'];
  editTaxMasterFile: Scalars['Boolean'];
  deleteTaxMasterFile: Scalars['Boolean'];
  deactivateTaxMasterFile: Scalars['Boolean'];
  activateTaxMasterFile: Scalars['Boolean'];
  createSuperUser: UserEntity;
  createContractNotification: ContractNotificationEntity;
  updateContractNotification: ContractNotificationEntity;
  deleteContractNotification: Scalars['Boolean'];
  readNotification: Scalars['Boolean'];
  markAsReadNoti: Scalars['Boolean'];
  deleteNoti: Scalars['Boolean'];
  patchWorkDeskData: Scalars['Boolean'];
  CreateContractRole: Scalars['Boolean'];
  UpdateContractRole: Scalars['Boolean'];
  CreateSiteRole: Scalars['Boolean'];
  UpdateSiteRole: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  createAdjustmentType: AdjustmentTypeEntity;
  updateAdjustmentType: AdjustmentTypeEntity;
  deleteAdjustmentType: Scalars['Boolean'];
  createAlert: AlertEntity;
  updateAlert: AlertEntity;
  deleteAlert: Scalars['Boolean'];
  updateAlertAssignment: Scalars['JSON'];
  cancelApprovalPolicy: Scalars['Boolean'];
  cancelApprovalPolicyAssignment: Scalars['Boolean'];
  createApprovalPolicy: ApprovalPolicyEntity;
  updateApprovalPolicy: ApprovalPolicyEntity;
  createApprovalPolicyAssignment: ApprovalPolicyEntity;
  updateApprovalPolicyAssignment: ApprovalPolicyAssignmentEntity;
  deleteApprovalPolicy: Scalars['Boolean'];
  deleteApprovalPolicyAssignment: Scalars['Boolean'];
  deleteMultipleApprovalPolicy: Scalars['Boolean'];
  deleteMultipleApprovalPolicyAssignment: Scalars['Boolean'];
  createBankAccount: BankAccountEntity;
  updateBankAccount: BankAccountEntity;
  deleteBankAccount: Scalars['Boolean'];
  activateBankAccount: Scalars['Boolean'];
  cancelBankAccount: Scalars['Boolean'];
  deleteCalendar: Scalars['Boolean'];
  cancelCalendar: Scalars['Boolean'];
  activateCalendar: Scalars['Boolean'];
  createCalendar: Scalars['Boolean'];
  updateCalendar: Scalars['Boolean'];
  createCalendarPHPolicy: CalendarPhPolicyEntity;
  updateCalendarPHPolicy: CalendarPhPolicyEntity;
  deleteCalendarPHPolicy: Scalars['Boolean'];
  cancelCalendarPHPolicy: Scalars['Boolean'];
  activateCalendarPHPolicy: Scalars['Boolean'];
  createCalendarWeekendPolicy: CalendarWeekendPolicyEntity;
  updateCalendarWeekendPolicy: CalendarWeekendPolicyEntity;
  deleteCalendarWeekendPolicy: Scalars['Boolean'];
  cancelCalendarWeekendPolicy: Scalars['Boolean'];
  activateCalendarWeekendPolicy: Scalars['Boolean'];
  uploadCompanyLogo: Scalars['Boolean'];
  uploadISOLogo: Scalars['Boolean'];
  uploadCompanyISOLogo: Scalars['Boolean'];
  updateWatermarkCompany: Scalars['Boolean'];
  deleteCostCategory: Scalars['Boolean'];
  cancelCostCategory: Scalars['Boolean'];
  activateCostCategory: Scalars['Boolean'];
  /** ensure to pass the costClass in input */
  createBatchCostCategory: Scalars['Boolean'];
  /** ensure to pass the costClass in input */
  createBatchCostCategoryWithoutItem: Scalars['Boolean'];
  createCostCategory: CostCategoryEntity;
  updateCostCategory: Scalars['Boolean'];
  deleteCostCategoryCostItem: Scalars['Boolean'];
  addCostCategory: CostCategoryEntity;
  editCostCategory: Scalars['Boolean'];
  deleteTreeCostCategory: Scalars['Boolean'];
  costCategoryImportValidation: Scalars['JSON'];
  costCategoryImport: Scalars['Boolean'];
  deleteCostItem: Scalars['Boolean'];
  activateCostItem: Scalars['Boolean'];
  /** Create Individual as it will overwrite the previous daywork */
  createCostItem: CostItemEntity;
  /** Update Individual as it will overwrite the previous daywork */
  updateCostItem: CostItemEntity;
  /** Create batch */
  createBatchCostItem: Scalars['Boolean'];
  cancelCostItem: Scalars['Boolean'];
  costItemImport: Scalars['Boolean'];
  costItemImportValidation: Scalars['JSON'];
  createCurrencySetup: CurrencyNoteEntity;
  updateCurrencySetup: CurrencyNoteEntity;
  deleteCurrencySetup: Scalars['Boolean'];
  addDigitalDocuments: Scalars['Boolean'];
  deleteDigitalDocuments: Scalars['Boolean'];
  updateNumberFormat: ConNumberFormatEntity;
  createDocType: ConDocTypeEntity;
  updateDocType: ConDocTypeEntity;
  createRunningNumberSetup: ConRunningNumberSetupEntity;
  updateRunningNumberSetup: ConRunningNumberSetupEntity;
  inactiveDocType: Scalars['Boolean'];
  editNumberFormat: Scalars['Boolean'];
  updateDocNumSetup: Scalars['Boolean'];
  createDocNumHeader: DocNumHeaderEntity;
  updateDocNumHeader: DocNumHeaderEntity;
  createDocNumDetail: DocNumDetailEntity;
  updateDocNumDetail: DocNumDetailEntity;
  createDocumentNumberHeader: Scalars['Boolean'];
  updateDocumentNumberHeader: Scalars['Boolean'];
  updateSystemAdminDocNum: Scalars['Boolean'];
  createHolidayType: HolidayTypeEntity;
  updateHolidayType: HolidayTypeEntity;
  deleteHolidayType: Scalars['Boolean'];
  cancelHolidayType: Scalars['Boolean'];
  activateHolidayType: Scalars['Boolean'];
  createJobType: JobTypeEntity;
  updateJobType: JobTypeEntity;
  deleteJobType: Scalars['Boolean'];
  cancelJobType: Scalars['Boolean'];
  activateJobType: Scalars['Boolean'];
  createPlatformSuperUser: PlatformUserEntity;
  createPlatformUser: PlatformUserEntity;
  updatePlatformUser: Scalars['Boolean'];
  createPlatformPassword: Scalars['Boolean'];
  createPlatformPasswordTest: Scalars['Boolean'];
  activatePlatformUser: Scalars['Boolean'];
  platformLogin: PlatformLoginResponse;
  changePlatformPassword: Scalars['Boolean'];
  platformLogout: Scalars['Boolean'];
  resetPlatformPassword: Scalars['Boolean'];
  uploadProfilePicPlatform: DocumentsEntity;
  forgotPlatformPassword: Scalars['Boolean'];
  createPolicyGuideline: Scalars['Boolean'];
  updatePolicyGuideline: Scalars['Boolean'];
  createPolicyAmountGuideline: Scalars['Boolean'];
  updatePolicyAmountGuideline: Scalars['Boolean'];
  deletePolicyAmountGuideline: Scalars['Boolean'];
  createNotificationGuideline: Scalars['Boolean'];
  updateNotificationGuideline: Scalars['Boolean'];
  createPublicHoliday: PublicHolidayEntity;
  updatePublicHoliday: PublicHolidayEntity;
  deletePublicHoliday: Scalars['Boolean'];
  cancelPublicHoliday: Scalars['Boolean'];
  activatePublicHoliday: Scalars['Boolean'];
  deleteRevenueCategory: Scalars['Boolean'];
  cancelRevenueCategory: Scalars['Boolean'];
  activateRevenueCategory: Scalars['Boolean'];
  /** Create Individual as it will overwrite the previous daywork */
  createRevenueCategory: RevenueCategoryEntity;
  /** Update Individual as it will overwrite the previous daywork */
  updateRevenueCategory: RevenueCategoryEntity;
  /** Create batch */
  createBatchRevenueCategory: Scalars['Boolean'];
  revenueCategoryImportValidation: Scalars['JSON'];
  revenueCategoryImport: Scalars['Boolean'];
  goodReceiveNoteRPA: Array<GoodReceiveNoteRpaResult>;
  invoiceRPA: Array<InvoiceRpaResult>;
  deliveryOrderRPA: DorpaResult;
  updateSAccount: SAccountEntity;
  createSiteMaterial: SiteMaterialEntity;
  updateSiteMaterial: SiteMaterialEntity;
  deleteSiteMaterial: Scalars['Boolean'];
  cancelSiteMaterial: Scalars['Boolean'];
  activateSiteMaterial: Scalars['Boolean'];
  createBulkSiteMaterial: Scalars['Boolean'];
  createSiteWorkers: WorkerEntity;
  updateSiteWorkers: WorkerEntity;
  deleteSiteWorkers: Scalars['Boolean'];
  createSubcontractorType: SubcontractorTypeEntity;
  updateSubcontractorType: SubcontractorTypeEntity;
  deleteSubcontractorType: Scalars['Boolean'];
  cancelSubcontractorType: Scalars['Boolean'];
  activateSubcontractorType: Scalars['Boolean'];
  createSubcontractorTypeFdw: Scalars['Boolean'];
  createSupplierType: SupplierTypeEntity;
  updateSupplierType: SupplierTypeEntity;
  deleteSupplierType: Scalars['Boolean'];
  cancelSupplierType: Scalars['Boolean'];
  activateSupplierType: Scalars['Boolean'];
  createSupplierTypeFdw: Scalars['Boolean'];
  updateUOM: UomEntity;
  deleteUOM: Scalars['Boolean'];
  cancelUOM: Scalars['Boolean'];
  activateUOM: Scalars['Boolean'];
  createUOM: UomEntity;
  createBatchUOM: Scalars['Boolean'];
  uomImport: Scalars['Boolean'];
  uomImportValidation: Scalars['JSON'];
  createUOMExchange: Scalars['Boolean'];
  updateUOMExchange: Scalars['Boolean'];
  deleteUOMExchange: Scalars['Boolean'];
  createUserCompanyAssignment: UserCompanyAssignmentEntity;
  updateUserCompanyAssignment: UserCompanyAssignmentEntity;
  assignUserCompany: Scalars['Boolean'];
  unassignUserCompany: Scalars['Boolean'];
  assignUserWithCompany: Scalars['Boolean'];
  unassignUserWithCompany: Scalars['Boolean'];
  createUser: UserEntity;
  createUserEmail: UserEntity;
  sendInvitationEmail: Scalars['Boolean'];
  resendInvitation: UserEntity;
  updateUser: UserEntity;
  updateUserWithPassword: UserEntity;
  createPassword: Scalars['Boolean'];
  createPasswordTest: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  changePasswordSU: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  uploadProfilePic: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  updateLastEntity: Scalars['Boolean'];
  createVideoCam: VideoCamEntity;
  updateVideoCam: VideoCamEntity;
  deleteVideoCam: Scalars['Boolean'];
  cancelVideoCam: Scalars['Boolean'];
  activateVideoCam: Scalars['Boolean'];
  createWagesRatePolicy: WagesRatePolicyEntity;
  deleteWagesRatePolicy: Scalars['Boolean'];
  cancelWagesRatePolicy: Scalars['Boolean'];
  activateWagesRatePolicy: Scalars['Boolean'];
  updateWagesRatePolicy: Scalars['Boolean'];
  createContractToDo: ContractToDoEntity;
  updateContractToDo: ContractToDoEntity;
  deleteContractToDo: Scalars['Boolean'];
  createNotification: Scalars['Boolean'];
  createToDo: Scalars['Boolean'];
  approveToDo: Scalars['Boolean'];
  rejectToDo: Scalars['Boolean'];
  deleteWorker: Scalars['Boolean'];
  cancelWorker: Scalars['Boolean'];
  createWorker: WorkerEntity;
  updateWorker: WorkerEntity;
  createBulkWorker: Scalars['Boolean'];
  updateWorkerAttendance: WorkerAttendanceEntity;
  deleteWorkerAttendance: Scalars['Boolean'];
  cancelWorkerAttendance: Scalars['Boolean'];
  createWorkerAttendance: WorkerAttendanceEntity;
  calculateWorkerWage: Scalars['Boolean'];
  createBatchWorkerAttendance: Scalars['Boolean'];
  approveRejectWorkDesk: Scalars['Boolean'];
  createNotificationWorkflow: Scalars['JSON'];
  testEmail: Scalars['Boolean'];
  updateWorkingHourPolicy: WorkingHourPolicyEntity;
  deleteWorkingHourPolicy: Scalars['Boolean'];
  cancelWorkingHourPolicy: Scalars['Boolean'];
  activateWorkingHourPolicy: Scalars['Boolean'];
  createWorkingHourPolicy: WorkingHourPolicyEntity;
  updateAssignment: AssignmentEntity;
  createAssignment: Scalars['Boolean'];
  returnFromAssignment: Scalars['Boolean'];
  cancelAssignment: Scalars['Boolean'];
  createFuelDischarge: Scalars['Boolean'];
  updateStatusFuelDischarge: Scalars['Boolean'];
  updateFuelDischarge: Scalars['Boolean'];
  deleteFuelDischarge: Scalars['Boolean'];
  createFuelMeterReading: FuelMeterReadingEntity;
  updateFuelMeterReading: FuelMeterReadingEntity;
  deleteFuelMeterReading: Scalars['Boolean'];
  createFuelSupply: Scalars['Boolean'];
  updateStatusFuelSupply: Scalars['Boolean'];
  updateFuelSupply: Scalars['Boolean'];
  deleteFuelSupply: Scalars['Boolean'];
  actionOnFuelSupplyStatus: Scalars['Boolean'];
  deleteFuelTankSetup: Scalars['Boolean'];
  createFuelTankSetup: Scalars['Boolean'];
  updateFuelTankSetup: Scalars['Boolean'];
  createMaintenance: Scalars['Boolean'];
  updateMaintenance: Scalars['Boolean'];
  cancelMaintenanceCost: Scalars['Boolean'];
  returnFromMaintenance: Scalars['Boolean'];
  createWorkshop: WorkshopEntity;
  updateWorkshop: WorkshopEntity;
  createPNMRental: PnmRentalEntity;
  updatePNMRental: PnmRentalEntity;
  createPlantMachinery: Scalars['Boolean'];
  updatePlantMachinery: Scalars['Boolean'];
  updateDO: Scalars['Boolean'];
  actionOnDoStatus: Scalars['Boolean'];
  cancelCloseDO: Scalars['Boolean'];
  deleteDO: Scalars['Boolean'];
  rejectDO: Scalars['Boolean'];
  submitDO: Scalars['Boolean'];
  packDO: Scalars['Boolean'];
  shipDO: Scalars['Boolean'];
  submitGRTN: Scalars['Boolean'];
  oldCreateUpdateGRTN: Scalars['Boolean'];
  createUpdateGRTN: Scalars['Boolean'];
  approveGRTN: Scalars['Boolean'];
  rejectGRTN: Scalars['Boolean'];
  deleteGRTN: Scalars['Boolean'];
  cancelCloseGRTN: Scalars['Boolean'];
  createNegotiatedSupply: Scalars['Boolean'];
  updateNegotiatedSupply: Scalars['Boolean'];
  cancelNegotiatedSupply: Scalars['Boolean'];
  deleteNegoSupply: Scalars['Boolean'];
  createPO: Scalars['JSON'];
  createDirectPO: Scalars['JSON'];
  updateDirectPO: Scalars['JSON'];
  actionOnPOStatus: Scalars['JSON'];
  cancelClosePO: Scalars['Boolean'];
  deletePO: Scalars['Boolean'];
  purchaseBudgetApproval: Scalars['Boolean'];
  updateWbsPoItem: Scalars['Boolean'];
  createPurchaseReq: PurchaseReqEntity;
  updatePurchaseRequest: Scalars['JSON'];
  actionOnPRStatus: Scalars['JSON'];
  deletePR: Scalars['Boolean'];
  cancelClosePR: Scalars['Boolean'];
  submitEvaluationSelection: Scalars['Boolean'];
  createRFQ: Scalars['Boolean'];
  updateRFQ: Scalars['Boolean'];
  rejectRFQ: Scalars['Boolean'];
  cancelCloseRFQ: Scalars['Boolean'];
  deleteRFQ: Scalars['Boolean'];
  updateRFQSupplierInvitation: RfqSupplierInvitationEntity;
  deleteRFQSupplierInvitation: Scalars['Boolean'];
  inviteSupplier: Scalars['Boolean'];
  uninviteSupplier: Scalars['Boolean'];
  deleteRFQSupplierSubmission: Scalars['Boolean'];
  activeRfqSubmission: Scalars['Boolean'];
  submitRFQQuote: Scalars['Boolean'];
  createSupplierCompanyAssignment: SupplierCompanyAssignmentEntity;
  updateSupplierCompanyAssignment: SupplierCompanyAssignmentEntity;
  assignSupplierCompany: Scalars['Boolean'];
  unassignSupplierCompany: Scalars['Boolean'];
  assignSupplierWithCompany: Scalars['Boolean'];
  unassignSupplierWithCompany: Scalars['Boolean'];
  createPlatformSupplier: SupplierEntity;
  updatePlatformSupplier: SupplierEntity;
  deleteSupplier: Scalars['Boolean'];
  createSupplierCCategory: SupplierCCategoryEntity;
  updateSupplierCCategory: SupplierCCategoryEntity;
  deleteSupplierCCategory: Scalars['Boolean'];
  addSupplierContact: Scalars['Boolean'];
  createBulkSupplier: Scalars['Boolean'];
  addCCategoryInSupplier: Scalars['Boolean'];
  addSupplierInCCategory: Scalars['Boolean'];
  deleteSupplierInCCategory: Scalars['Boolean'];
  supplierImportValidation: Scalars['JSON'];
  createSupplierImport: Scalars['Boolean'];
  createAPInvoice: ApInvoiceEntity;
  updateAPInvoice: ApInvoiceEntity;
  deleteAPInvoice: Scalars['Boolean'];
  createAPInvoiceWithoutDO: ApInvoiceEntity;
  updateAPInvoiceWithoutDO: ApInvoiceEntity;
  deleteAPInvoiceWithoutDO: Scalars['Boolean'];
  createSupplierInvoice: Scalars['Boolean'];
  createSupplierInvoiceV2: Scalars['Boolean'];
  updateSupplierInvoiceV2: Scalars['Boolean'];
  updateSupplierInvoice: Scalars['Boolean'];
  deleteSupplierInvoice: Scalars['Boolean'];
  cancelSupplierInvoice: Scalars['Boolean'];
  createAPAdvance: ApAdvanceEntity;
  updateAPAdvance: ApAdvanceEntity;
  deleteAPAdvance: Scalars['Boolean'];
  createAPPayment: ApPaymentEntity;
  updateAPPayment: ApPaymentEntity;
  deleteAPPayment: Scalars['Boolean'];
  createAPRefund: ApRefundEntity;
  updateAPRefund: ApRefundEntity;
  deleteAPRefund: Scalars['Boolean'];
  makeSupplierPayment: ApPaymentEntity;
  cancelSupplierDeposit: Scalars['Boolean'];
  cancelSupplierPayment: Scalars['Boolean'];
  cancelSupplierRefund: Scalars['Boolean'];
  createAPDebitNote: ApDebitNoteEntity;
  updateAPDebitNote: ApDebitNoteEntity;
  deleteAPDebitNote: Scalars['Boolean'];
  createAPCreditNote: ApCreditNoteEntity;
  updateAPCreditNote: ApCreditNoteEntity;
  deleteAPCreditNote: Scalars['Boolean'];
  createDNtoSupplier: DNtoSupplierEntity;
  updateDNtoSupplier: DNtoSupplierEntity;
  deleteDNtoSupplier: Scalars['Boolean'];
  createCNtoSupplier: CNtoSupplierEntity;
  updateCNtoSupplier: CNtoSupplierEntity;
  deleteCNtoSupplier: Scalars['Boolean'];
  makeSupplierAllocation: Scalars['Boolean'];
  resetSupplierAllocation: Scalars['Boolean'];
  cancelSupplierDN: Scalars['Boolean'];
  cancelSupplierCN: Scalars['Boolean'];
  cancelDNtoSupplier: Scalars['Boolean'];
  cancelCNtoSupplier: Scalars['Boolean'];
  updateSubcontractorInvitation: SubcontractorInvitationEntity;
  deleteSubcontractorInvitation: Scalars['Boolean'];
  inviteSubcontractor: Scalars['Boolean'];
  unInviteSubcontractor: Scalars['Boolean'];
  unInviteSubcontractors: Scalars['Boolean'];
  createSubconRFQSubmission: SubconRfqSubmissionEntity;
  updateSubconRFQSubmission: SubconRfqSubmissionEntity;
  deleteSubconRFQSubmission: Scalars['Boolean'];
  createSubcontractTender: SubcontractTenderEntity;
  updateSubcontractTender: SubcontractTenderEntity;
  deleteSubcontractTender: Scalars['Boolean'];
  tenderToSubconGeneration: Scalars['Boolean'];
  createSubcontractGuarantee: SubcontractGuaranteeEntity;
  updateSubcontractGuarantee: SubcontractGuaranteeEntity;
  deleteSubcontractGuarantee: Scalars['Boolean'];
  createSubcontractInsurance: SubcontractInsuranceEntity;
  updateSubcontractInsurance: SubcontractInsuranceEntity;
  deleteSubcontractInsurance: Scalars['Boolean'];
  cancelSubcontractGuarantee: Scalars['Boolean'];
  cancelSubcontractInsurance: Scalars['Boolean'];
  createSubcontract: SubcontractEntity;
  updateSubcontract: Scalars['Boolean'];
  actionOnSubcontractStatus: Scalars['Boolean'];
  createSubcontractExtensionDate: Scalars['Boolean'];
  createSubcontractCertificate: Scalars['Boolean'];
  actionOnSubcontractStatusWithTrade: Scalars['Boolean'];
  createSubcontractorCompanyAssignment: SubcontractorCompanyAssignmentEntity;
  updateSubcontractorCompanyAssignment: SubcontractorCompanyAssignmentEntity;
  assignSubconCompany: Scalars['Boolean'];
  unassignSubconCompany: Scalars['Boolean'];
  assignSubconWithCompany: Scalars['Boolean'];
  unassignSubconWithCompany: Scalars['Boolean'];
  createSubcontractVO: SubcontractVoEntity;
  updateSubcontractVO: SubcontractVoEntity;
  deleteSubcontractVO: Scalars['Boolean'];
  actionOnSubconVOStatus: Scalars['Boolean'];
  createSubcontractCostWbs: SubcontractCostWbsEntity;
  updateSubcontractCostWbs: SubcontractCostWbsEntity;
  deleteSubcontractCostWbs: Scalars['Boolean'];
  updateSubcontractLedgers: Scalars['Boolean'];
  createSubcontractClaim: SubcontractClaimEntity;
  updateSubcontractClaim: SubcontractClaimEntity;
  deleteSubcontractClaim: Scalars['Boolean'];
  submitSubconRetentionClaim: SubcontractClaimEntity;
  approveSubconRetentionClaim: SubcontractClaimEntity;
  submitSubconClaim: SubcontractClaimEntity;
  approveSubconClaim: Scalars['JSON'];
  updateSubconClaimDetails: Scalars['Boolean'];
  rejectSubconClaim: Scalars['Boolean'];
  cancelSubconClaim: Scalars['Boolean'];
  createSubcontractAdvance: SubcontractAdvanceEntity;
  updateSubcontractAdvance: SubcontractAdvanceEntity;
  deleteSubcontractAdvance: Scalars['Boolean'];
  createSubcontractPayment: SubcontractPaymentEntity;
  updateSubcontractPayment: SubcontractPaymentEntity;
  deleteSubcontractPayment: Scalars['Boolean'];
  createSubcontractRefund: SubcontractRefundEntity;
  updateSubcontractRefund: SubcontractRefundEntity;
  deleteSubcontractRefund: Scalars['Boolean'];
  makeSubconPayment: SubcontractPaymentEntity;
  cancelSubconAdvance: Scalars['Boolean'];
  cancelSubconPayment: Scalars['Boolean'];
  cancelSubconRefund: Scalars['Boolean'];
  createSubcontractMiscInvoice: SubcontractMiscInvoiceEntity;
  updateSubcontractMiscInvoice: SubcontractMiscInvoiceEntity;
  deleteSubcontractMiscInvoice: Scalars['Boolean'];
  createSubcontractDN: SubcontractDnEntity;
  updateSubcontractDN: SubcontractDnEntity;
  deleteSubcontractDN: Scalars['Boolean'];
  createSubcontractCN: SubcontractCnEntity;
  updateSubcontractCN: SubcontractCnEntity;
  deleteSubcontractCN: Scalars['Boolean'];
  createDNtoSubcon: DNtoSubconEntity;
  updateDNtoSubcon: DNtoSubconEntity;
  deleteDNtoSubcon: Scalars['Boolean'];
  createCNtoSubcon: CNtoSubconEntity;
  updateCNtoSubcon: CNtoSubconEntity;
  deleteCNtoSubcon: Scalars['Boolean'];
  makeSubconAllocation: Scalars['Boolean'];
  resetSubcontractAllocation: Scalars['Boolean'];
  cancelSubcontractMiscInvoice: Scalars['Boolean'];
  cancelSubconDN: Scalars['Boolean'];
  cancelSubconCN: Scalars['Boolean'];
  cancelDNtoSubcon: Scalars['Boolean'];
  cancelCNtoSubcon: Scalars['Boolean'];
  createPlatformSubcontractor: SubcontractorEntity;
  updatePlatformSubcontractor: SubcontractorEntity;
  deleteSubcontractor: Scalars['Boolean'];
  createSubcontractorCCategory: SubcontractorCCategoryEntity;
  updateSubcontractorCCategory: SubcontractorCCategoryEntity;
  addSubcontractorContact: Scalars['Boolean'];
  createSubcontractorAndPackage: SubcontractorEntity;
  createBulkSubcon: Scalars['Boolean'];
  addSubcontractorCCategory: Scalars['Boolean'];
  createSubcontractorSetup: Scalars['Boolean'];
  updateSubcontractorSetup: Scalars['Boolean'];
  deleteSubcontractorSetup: Scalars['Boolean'];
  subcontractorImportValidation: Scalars['JSON'];
  createSubcontractorImport: Scalars['Boolean'];
  createSite: Scalars['Boolean'];
  updateSite: Scalars['Boolean'];
  deleteSite: Scalars['Boolean'];
  activateSite: Scalars['Boolean'];
  cancelSite: Scalars['Boolean'];
  insertUpdateSite: Scalars['Boolean'];
  deleteProjectSite: Scalars['Boolean'];
  createSiteImage: SiteImageEntity;
  updateSiteImage: SiteImageEntity;
  deleteSiteImage: Scalars['Boolean'];
  cancelSiteImage: Scalars['Boolean'];
};


export type MutationCompletionPercArgs = {
  input: CompletionInput;
  contractID: Scalars['String'];
};


export type MutationUpdateCompletionPercArgs = {
  completionPerc: Scalars['String'];
  contractID: Scalars['String'];
};


export type MutationUpdateBqArgs = {
  input: BqInput;
};


export type MutationDeleteBqArgs = {
  input: BqInput;
};


export type MutationCreateBqArgs = {
  input: Array<BqInput>;
  contractID: Scalars['String'];
};


export type MutationCreateBqWbsAssignmentArgs = {
  input: BqWbsAssignmentInput;
};


export type MutationUpdateBqWbsAssignmentArgs = {
  input: BqWbsAssignmentInput;
};


export type MutationDeleteBqWbsAssignmentArgs = {
  input: BqWbsAssignmentInput;
};


export type MutationAssignBqWbsAssignmentArgs = {
  bqID: Array<Scalars['String']>;
  wbsID: Scalars['String'];
};


export type MutationUnassignBqWbsAssignmentArgs = {
  bqID: Array<Scalars['String']>;
  wbsID: Scalars['String'];
};


export type MutationCreateBillInterestArgs = {
  input: BillInterestInput;
};


export type MutationUpdateBillInterestArgs = {
  input: BillInterestInput;
};


export type MutationCreateDocReminderArgs = {
  input: DocReminderInput;
};


export type MutationUpdateDocReminderArgs = {
  input: DocReminderInput;
};


export type MutationCancelPaymentReminderArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePaymentReminderArgs = {
  docReminder: Array<DocReminderInput>;
  input: PaymentReminderInput;
};


export type MutationCreateCustomerReminderArgs = {
  inputReminder: CustomerReminderInput;
};


export type MutationCreateContractArgs = {
  siteInput: Array<SiteInput>;
  input: ContractInput;
};


export type MutationDeleteContractAllAccessArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateContractArgs = {
  input: ContractInput;
};


export type MutationInsertContactsArgs = {
  contactInput: Array<ContactPersonInput>;
  maincon: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationActionOnContractStatusArgs = {
  input: ContractActionInput;
  ID: Scalars['String'];
};


export type MutationCreateContractExtensionDateArgs = {
  input: ContractExtensionInput;
  ID: Scalars['String'];
};


export type MutationCreateContractCertificateArgs = {
  input: ContractCertificateInput;
  ID: Scalars['String'];
};


export type MutationCreateContractGuaranteeArgs = {
  input: ContractGuaranteeInput;
};


export type MutationUpdateContractGuaranteeArgs = {
  input: ContractGuaranteeInput;
};


export type MutationCreateContractInsuranceArgs = {
  input: ContractInsuranceInput;
};


export type MutationUpdateContractInsuranceArgs = {
  input: ContractInsuranceInput;
};


export type MutationCancelContractGuaranteeArgs = {
  input: ContractGuaranteeCancellationInput;
};


export type MutationCancelContractInsuranceArgs = {
  input: ContractInsuranceCancellationInput;
};


export type MutationUpdateProjectBudgetArgs = {
  input: ProjectBudgetInput;
};


export type MutationDeleteProjectBudgetArgs = {
  input: ProjectBudgetDeleteInput;
};


export type MutationAddOriginalBudgetArgs = {
  budgetInput: Array<ProjectBudgetInput>;
  contractID: Scalars['String'];
};


export type MutationAddRevisedBudgetArgs = {
  file?: Maybe<Array<Scalars['Upload']>>;
  budgetAmtInput: BudgetAmtInput;
  contractID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateContractVoArgs = {
  voDetails?: Maybe<Array<ContractVoDetailsInput>>;
  input: ContractVoInput;
};


export type MutationUpdateContractVoArgs = {
  voDetails?: Maybe<Array<ContractVoDetailsInput>>;
  input: ContractVoInput;
};


export type MutationActionOnVoStatusArgs = {
  itemApprovedAmount?: Maybe<Array<VoItemApprovedAmountInput>>;
  input: ContractVoActionInput;
  ID: Scalars['String'];
};


export type MutationDeleteContractVoArgs = {
  ID: Scalars['String'];
};


export type MutationCreateContractClaimArgs = {
  input: ContractClaimInput;
};


export type MutationUpdateContractClaimArgs = {
  input: ContractClaimInput;
};


export type MutationDeleteContractClaimArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitRetentionClaimArgs = {
  input: ClaimRetentionInput;
};


export type MutationApproveRetentionClaimArgs = {
  input: ClaimRetentionInput;
};


export type MutationSubmitClaimArgs = {
  voRevenueWbsAlloc?: Maybe<Array<ContractClaimVoRevenueWbsAllocInput>>;
  voAlloc?: Maybe<Array<ContractClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<ContractClaimAllocInput>>;
  deductionLedger?: Maybe<Array<LedgerInput>>;
  input: ContractClaimInput;
};


export type MutationApproveClaimArgs = {
  voRevenueWbsAlloc?: Maybe<Array<ContractClaimVoRevenueWbsAllocInput>>;
  voAlloc?: Maybe<Array<ContractClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<ContractClaimAllocInput>>;
  input: ContractClaimInput;
};


export type MutationUpdateClaimDetailsArgs = {
  input: ClaimDetailsInput;
  ID: Scalars['String'];
};


export type MutationRejectClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: RejectInput;
  ID: Scalars['String'];
};


export type MutationCancelClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractAdvanceArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractAdvanceArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractAdvanceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractReceiptArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractReceiptArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractReceiptArgs = {
  ID: Scalars['String'];
};


export type MutationCreateContractRefundArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractRefundArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractRefundArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationMakeContractRefundArgs = {
  ledger: Array<LedgerInput>;
  input: ContractReceiptInput;
};


export type MutationCancelContractReceiptArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractMiscInvoiceArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateContractMiscInvoiceArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteContractMiscInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractMiscInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateContractDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteContractDnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateContractCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteContractCnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateClientDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateClientDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteClientDnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelClientDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateClientCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateClientCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteClientCnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelClientCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationMakeContractAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetContractAllocationArgs = {
  receiptPayment?: Maybe<Scalars['Boolean']>;
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCreateCostCodeArgs = {
  input: CostCodeInput;
};


export type MutationCreateCustomerCompanyAssignmentArgs = {
  input: CustomerCompanyAssignmentInput;
};


export type MutationUpdateCustomerCompanyAssignmentArgs = {
  input: CustomerCompanyAssignmentInput;
};


export type MutationAssignCustomerCompanyArgs = {
  companyID: Array<Scalars['String']>;
  customerID: Scalars['String'];
};


export type MutationUnassignCustomerCompanyArgs = {
  companyID: Array<Scalars['String']>;
  customerID: Scalars['String'];
};


export type MutationAssignCustomerWithCompanyArgs = {
  customerID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationUnassignCustomerWithCompanyArgs = {
  customerID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationCreateCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationCreatePlatformCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdatePlatformCustomerArgs = {
  input: CustomerInput;
};


export type MutationDeleteCustomerArgs = {
  input: CustomerDeleteInput;
};


export type MutationAddCustomerContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationDeletePlatformCustomerProfileArgs = {
  ID: Scalars['String'];
};


export type MutationCustomerImportValidationArgs = {
  input: Array<CustomerImportInput>;
};


export type MutationCreateCustomerImportArgs = {
  batchID: Scalars['String'];
};


export type MutationCreateFollowUpArgs = {
  input: FollowUpInput;
};


export type MutationUpdateFollowUpArgs = {
  input: FollowUpInput;
};


export type MutationDeleteCbExportArgs = {
  input: CbExportDeleteInput;
};


export type MutationExportCbLedgerArgs = {
  ledgers: Array<CbLedgerInput>;
  input: CbExportInput;
};


export type MutationDeleteGlExportArgs = {
  input: GlExportDeleteInput;
};


export type MutationExportGlLedgerArgs = {
  ledgers: Array<GlLedgerInput>;
  input: GlExportInput;
};


export type MutationUpdateGlExportArgs = {
  input: UpdateGlExportInput;
};


export type MutationCreateGlAssociationArgs = {
  input: GlAssociationInput;
};


export type MutationUpdateGlAssociationArgs = {
  input: GlAssociationInput;
};


export type MutationCreateGlTransactionArgs = {
  input: GlTransactionInput;
};


export type MutationUpdateGlTransactionArgs = {
  input: GlTransactionInput;
};


export type MutationCreateGlInterfaceMasterArgs = {
  input: GlInterfaceMasterInput;
};


export type MutationUpdateGlInterfaceMasterArgs = {
  input: GlInterfaceMasterInput;
};


export type MutationDeleteGlInterfaceMasterArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlInterfaceDetailArgs = {
  input: GlInterfaceDetailInput;
};


export type MutationUpdateGlInterfaceDetailArgs = {
  input: GlInterfaceDetailInput;
};


export type MutationDeleteGlInterfaceDetailArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlTaxDynamicArgs = {
  input: GlTaxDynamicInput;
};


export type MutationUpdateGlTaxDynamicArgs = {
  input: GlTaxDynamicInput;
};


export type MutationDeleteGlTaxDynamicArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlAdjustmentDynamicArgs = {
  input: GlAdjustmentDynamicInput;
};


export type MutationUpdateGlAdjustmentDynamicArgs = {
  input: GlAdjustmentDynamicInput;
};


export type MutationDeleteGlAdjustmentDynamicArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCancelGlDynamicSetupArgs = {
  input?: Maybe<GlInterfaceDynamicInput>;
  ID: Scalars['String'];
};


export type MutationSaveGlAccountCodeArgs = {
  glInterfaceInput: Array<GlInterfaceDynamicInput>;
  accountType: Scalars['String'];
};


export type MutationCreateGlInterfaceDataArgs = {
  input: Array<GlInterfaceDetailInput>;
  accountType: Scalars['String'];
};


export type MutationInsertNewGlArgs = {
  creditAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1?: Maybe<Scalars['String']>;
  debitAccountCode2?: Maybe<Scalars['String']>;
  debitAccountCode1?: Maybe<Scalars['String']>;
  index: Scalars['Float'];
  transactionName: Scalars['String'];
  associateName: Scalars['String'];
};


export type MutationDeleteGlTransactionArgs = {
  transactionName: Scalars['String'];
  associationName: Scalars['String'];
};


export type MutationUpdateDepartmentGlAccountCodeArgs = {
  input: Array<DepartmentGlInterfaceInput>;
};


export type MutationCreateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationUpdateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationDeleteGuaranteeTypeArgs = {
  input: GuarInsTypeDeleteInput;
};


export type MutationCreateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationUpdateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationCancelGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationActivateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationCancelInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationActivateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationDeleteInsuranceTypeArgs = {
  input: GuarInsTypeDeleteInput;
};


export type MutationCreateMiscExpenseArgs = {
  input: MiscExpenseInput;
};


export type MutationUpdateMiscExpenseArgs = {
  input: MiscExpenseInput;
};


export type MutationCancelMiscExpenseArgs = {
  input: MiscExpenseCancellationInput;
  ID: Scalars['String'];
};


export type MutationDeleteMiscExpenseArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCostCenterArgs = {
  input: CostCenterInput;
};


export type MutationUpdateCostCenterArgs = {
  input: CostCenterInput;
};


export type MutationDeleteCostCenterArgs = {
  input: CostCenterDeleteInput;
};


export type MutationCancelCostCenterArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationUpdateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationDeleteCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationCreateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationUpdateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationDeleteConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationActivateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationCreateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationUpdateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationDeleteConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationActivateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationCreateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationUpdateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationDeleteConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationActivateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationInsertConTaxArgs = {
  taxEffectiveInput: Array<TaxEffectiveInput>;
  conTaxSchemeInput: ConTaxSchemeInput;
};


export type MutationUpdateConTaxArgs = {
  taxEffectiveInput: Array<TaxEffectiveInput>;
  conTaxSchemeInput: ConTaxSchemeInput;
};


export type MutationDeleteTaxSchemeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaInput;
};


export type MutationUpdateProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaInput;
};


export type MutationDeleteProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaDeleteInput;
};


export type MutationCreateProfitRecogParameterArgs = {
  input: ProfitRecogParameterInput;
};


export type MutationUpdateProfitRecogParameterArgs = {
  name: Scalars['String'];
};


export type MutationCreateProfitRecogAssignmentArgs = {
  input: ProfitRecogAssignmentInput;
};


export type MutationUpdateProfitRecogAssignmentArgs = {
  input: ProfitRecogAssignmentInput;
};


export type MutationCreateProfitRecogGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationUpdateProfitRecogGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationCreateProfitRecogGenParamAssignmentArgs = {
  input: ProfitRecogGenParamAssgmtInput;
};


export type MutationUpdateProfitRecogGenParamAssignmentArgs = {
  input: ProfitRecogGenParamAssgmtInput;
};


export type MutationCreateProfitGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationUpdateProfitGenerationArgs = {
  profitRecogStatus: ProfitRecogStatus;
  profitRecogGenerationID: Scalars['String'];
  input: ProfitRecogGenerationInput;
};


export type MutationUpdateProfitRecogFormulaAssignmentArgs = {
  input: UpdateProfitRecogFormulaInput;
};


export type MutationCreateProfitRecogGlAccountCodeArgs = {
  input: ProfitRecogGlAccountCodeInput;
};


export type MutationUpdateProfitRecogGlAccountCodeArgs = {
  input: ProfitRecogGlAccountCodeInput;
};


export type MutationCreateProfitRecogGlJournalArgs = {
  input: ProfitRecogGlJournalInput;
};


export type MutationUpdateProfitRecogGlJournalArgs = {
  input: ProfitRecogGlJournalInput;
};


export type MutationCreateProjectExpenseArgs = {
  itemInput?: Maybe<Array<ProjectExpenseItemInput>>;
  input: ProjectExpenseInput;
};


export type MutationUpdateProjectExpenseArgs = {
  itemInput?: Maybe<Array<ProjectExpenseItemInput>>;
  input: ProjectExpenseInput;
};


export type MutationDeleteProjectExpenseArgs = {
  ID: Scalars['String'];
};


export type MutationCancelProjectExpenseArgs = {
  input: ProjectExpenseCancelInput;
  ID: Scalars['String'];
};


export type MutationRejectProjectExpenseArgs = {
  input: ProjectExpenseRejectInput;
  ID: Scalars['String'];
};


export type MutationCreateProjectOpportunityAttachmentArgs = {
  input: ProjectOpportunityAttachmentInput;
};


export type MutationUpdateProjectOpportunityAttachmentArgs = {
  input: ProjectOpportunityAttachmentInput;
};


export type MutationDeleteProjectOpportunityAttachmentArgs = {
  input: ProjectOpportunityAttachmentDeleteInput;
};


export type MutationCreateProjectOpportunityArgs = {
  input: ProjectOpportunityInput;
};


export type MutationUpdateProjectOpportunityArgs = {
  input: ProjectOpportunityInput;
};


export type MutationDeleteProjectOpportunityArgs = {
  input: ProjectOpportunityDeleteInput;
};


export type MutationCreateProjectOpportunityActivityLogsArgs = {
  input: ProjectOpportunityActivityLogInput;
  projectOpportunityID: Scalars['String'];
};


export type MutationCreateProjectOpportunitySubmissionArgs = {
  input: ProjectOpportunitySubmissionInput;
};


export type MutationUpdateProjectOpportunitySubmissionArgs = {
  input: ProjectOpportunitySubmissionInput;
};


export type MutationDeleteProjectOpportunitySubmissionArgs = {
  input: ProjectOpportunitySubmissionDeleteInput;
};


export type MutationCreateWbsBudgetExcelArgs = {
  contractID: Scalars['String'];
  WBSAndBudgetExcelInput: Array<WbsAndBudgetExcelInput>;
};


export type MutationCreateBudgetArgs = {
  input: WbsBudgetInput;
};


export type MutationUpdateBudgetArgs = {
  input: WbsBudgetInput;
};


export type MutationSubmitBudgetArgs = {
  input: WbsBudgetSubmitApproveInput;
};


export type MutationActionOnBudgetStatusArgs = {
  input: WbsBudgetActionInput;
  ID: Scalars['String'];
};


export type MutationCreateBudgetRevisionArgs = {
  input: WbsBudgetRevisionInput;
};


export type MutationCopyBudgetContractArgs = {
  wbsIDs: Array<Scalars['String']>;
  input: CopyBudgetInput;
};


export type MutationDeleteBudgetArgs = {
  ID: Scalars['String'];
};


export type MutationSwitchBudgetArgs = {
  selectedBudgetID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateWbsBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationUpdateWbsBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationDeleteBudgetDetailArgs = {
  IDs: Array<Scalars['String']>;
};


export type MutationCreateBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationUpdateBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationUpdateBudgetScheduleArgs = {
  costAmts: Array<Scalars['Float']>;
  IDs: Array<Scalars['String']>;
  wbsBudgetDetailID: Scalars['String'];
};


export type MutationUpdateWbsDayworkCostRevenueArgs = {
  input: WbsDayworkInput;
};


export type MutationCreateWbsBudgetTransferArgs = {
  itemInput?: Maybe<Array<WbsBudgetTransferItemInput>>;
  input: WbsBudgetTransferInput;
};


export type MutationUpdateWbsBudgetTransferArgs = {
  itemInput?: Maybe<Array<WbsBudgetTransferItemInput>>;
  input: WbsBudgetTransferInput;
};


export type MutationDeleteBudgetTransferArgs = {
  ID: Scalars['String'];
};


export type MutationActionOnBudgetTransferStatusArgs = {
  input: WbsBudgetTransferActionInput;
  ID: Scalars['String'];
};


export type MutationCreateWbsArgs = {
  input: WbsInput;
};


export type MutationUpdateWbsArgs = {
  input: WbsInput;
};


export type MutationDeleteWbsArgs = {
  ID: Scalars['String'];
};


export type MutationMoveSequenceWbsArgs = {
  moveDown?: Maybe<Scalars['Boolean']>;
  ID: Scalars['String'];
};


export type MutationDefaultWbsArgs = {
  isDefault?: Maybe<Scalars['Boolean']>;
  contractID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationExecuteWbsImportStoreProcedureArgs = {
  batchID: Scalars['String'];
};


export type MutationWbsImportArgs = {
  batchID: Scalars['String'];
  contractID: Scalars['String'];
};


export type MutationWbsImportValidationArgs = {
  WBSImportInput: Array<WbsImportInput>;
  contractID: Scalars['String'];
};


export type MutationCreateWbsScheduleArgs = {
  input: WbsScheduleInput;
};


export type MutationUpdateWbsScheduleArgs = {
  input: WbsScheduleInput;
};


export type MutationCreateWbsUtilizedBudgetArgs = {
  input: WbsUtilizedBudgetInput;
};


export type MutationUpdateWbsUtilizedBudgetArgs = {
  input: WbsUtilizedBudgetInput;
};


export type MutationDeleteWbsUtilizedBudgetArgs = {
  input: WbsUtilizedBudgetDeleteInput;
};


export type MutationActionOnUtilizedBudgetStatusArgs = {
  input: WbsUtilizedBudgetActionInput;
  ID: Scalars['String'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<ContractPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<ContractPermission>;
  input: RoleInput;
};


export type MutationUpdateAlertSqlCodeArgs = {
  allCode?: Maybe<Scalars['Boolean']>;
  startCode?: Maybe<Scalars['String']>;
  arrCode?: Maybe<Array<Scalars['String']>>;
};


export type MutationDeleteExistPdfArgs = {
  description?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type MutationDeleteDocumentsArgs = {
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationUploadPdfpoArgs = {
  input: LedgerPdfInput;
};


export type MutationUploadPdfDocumentArgs = {
  input: LedgerPdfInput;
};


export type MutationShareAttachmentArgs = {
  file: Scalars['Upload'];
  contractID?: Maybe<Scalars['String']>;
};


export type MutationCreateContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationUpdateContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationDeleteContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationCreateContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationUpdateContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationDeleteContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationDeleteCollectionActivityArgs = {
  input: CollectionActivityDeleteId;
};


export type MutationCreateCollectionActivityArgs = {
  input: CollectionActivityInput;
};


export type MutationDeleteColActivityArgs = {
  collectionActivityID: Scalars['String'];
};


export type MutationUpdateCollectionActivityArgs = {
  input: CollectionActivityInput;
};


export type MutationCreateMultipleCollectionActivityArgs = {
  input: Array<CollectionActivityInput>;
};


export type MutationUpdateCollectionStatusArgs = {
  input: CollectionActivityStatusInput;
};


export type MutationPatchNewDocNumArgs = {
  docCode: ContractDocNum;
};


export type MutationCreateDocNumRunningNumberArgs = {
  docCode: ContractDocNum;
  accountID: Scalars['String'];
};


export type MutationGenerateDocumentNumberSetupArgs = {
  companyID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationResetGeneralRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationResetContractRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationContractRunNumCheckerArgs = {
  contractID: Scalars['String'];
};


export type MutationCreateContractRunNumArgs = {
  maincon: Scalars['Boolean'];
  contractID: Scalars['String'];
};


export type MutationDeleteDocRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationCreateRawCompanyArgs = {
  input: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyInput;
};


export type MutationUpdateRawCompanyArgs = {
  input: CompanyInput;
};


export type MutationDeleteCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type MutationDeleteRawCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type MutationUpdateContactArgs = {
  input: ContactInput;
};


export type MutationAddContractContactArgs = {
  input: ContactPersonInput;
  contractID: Scalars['String'];
};


export type MutationAddSubcontractContactArgs = {
  contactInput: Array<ContactPersonInput>;
  ID: Scalars['String'];
};


export type MutationDeleteSubcontractDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSiteDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteContractDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteAssociateDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteLedgersWorkFlowArgs = {
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationDeleteSpecificLedgersArgs = {
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationDeletePoDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteClientLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSubconLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSupplierLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteCostDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeletePolicyDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteNotificationDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteWorkFlowDataArgs = {
  accountID: Scalars['String'];
};


export type MutationUpdateContractDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSubcontractDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateClientDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSubconDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSupplierDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdatePoDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateLedgersDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationRejectLedgerTableArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  columnStatus: Scalars['String'];
  tableName: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationResetRolePermissionArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationRemoveSuperUserAclArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchEditableAllocationArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchContractAllocationArgs = {
  accountID?: Maybe<Scalars['String']>;
  maincon: Scalars['Boolean'];
};


export type MutationPatchSupplierAllocationArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchContractAllocationIDsArgs = {
  accountID?: Maybe<Scalars['String']>;
  maincon: Scalars['Boolean'];
};


export type MutationPatchRejClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchAdjustmentDocRefDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchClaimBaseDocAmtDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchCancelledAllocClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchCndnClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchRetentionClaimRejectArgs = {
  accountID?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
};


export type MutationPatchRetentionClaimDataArgs = {
  tableName: Scalars['String'];
};


export type MutationPatchDocumentsDataArgs = {
  insertData: Scalars['Boolean'];
  includeLogo: Scalars['Boolean'];
  accountIDs: Array<Scalars['String']>;
};


export type MutationPatchCompanyImageArgs = {
  accountIDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationPatchContractConSubconDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchRealConDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchConDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchVoDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchGuarInsDateDataArgs = {
  guarantee: Scalars['Boolean'];
  maincon: Scalars['Boolean'];
};


export type MutationPatchCompanyInAccountArgs = {
  deleteDoc: Scalars['Boolean'];
  type: Scalars['String'];
};


export type MutationPatchNullDocNoExpensesArgs = {
  accountID?: Maybe<Scalars['String']>;
  filter: Scalars['String'];
};


export type MutationPatchCostCategoryDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchDueDateNullDataArgs = {
  accountID?: Maybe<Scalars['String']>;
  associate: Scalars['String'];
};


export type MutationPatchNullTrxDateDataArgs = {
  tableName: Scalars['String'];
};


export type MutationRemoveAllocDataArgs = {
  confirmDelete: Scalars['Boolean'];
  tableName: Scalars['String'];
};


export type MutationPatchPlantStatusDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchProjectExpenseArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchMainSiteDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInsertLongLatDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInsertSiteInventoryDataArgs = {
  siteID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationPatchEmptyWorkflowArgs = {
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  IDs: Array<Scalars['String']>;
  refTable: Scalars['String'];
};


export type MutationCreateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationUpdateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationDeleteDocumentFolderArgs = {
  ID: Scalars['String'];
};


export type MutationExtraSystemAdminSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationExtraGeneralSettingSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationExtraGlGenSettingSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationPlatformContactSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInitialCurrencySetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInitialMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteMasterFileArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialSiteMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSiteMasterFileArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialGeneralSettingSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteGenSettingArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialGlInterfaceSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialDocNumSetupArgs = {
  companyID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateBatchPaymentDetailArgs = {
  input: BatchPaymentDetailInput;
};


export type MutationUpdateBatchPaymentDetailArgs = {
  input: BatchPaymentDetailInput;
};


export type MutationDeleteBatchPaymentDetailArgs = {
  input: BatchPaymentDetailDeleteInput;
};


export type MutationCancelBatchPaymentDetailArgs = {
  input: BatchPaymentDetailDeleteInput;
};


export type MutationCreateBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderInput;
};


export type MutationUpdateBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderInput;
};


export type MutationDeleteBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderDeleteInput;
};


export type MutationCancelBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderDeleteInput;
};


export type MutationSubmitBatchPaymentArgs = {
  detailInput: Array<BatchPaymentDetailInput>;
  headerInput: BatchPaymentHeaderInput;
};


export type MutationConfirmBatchPaymentArgs = {
  detailInput: Array<ApprovePaymentDetailInput>;
  companyID?: Maybe<Scalars['String']>;
  headerID: Scalars['String'];
};


export type MutationPayBatchPaymentArgs = {
  detailIDs: Array<Scalars['String']>;
  headerID: Scalars['String'];
};


export type MutationCreateSiteMaterialPolicyArgs = {
  input: SiteMaterialPolicyInput;
};


export type MutationUpdateSiteMaterialPolicyArgs = {
  input: SiteMaterialPolicyInput;
};


export type MutationCreateSiteMaterialContractPolicyArgs = {
  input: SiteMaterialContractPolicyInput;
};


export type MutationUpdateSiteMaterialContractPolicyArgs = {
  input: SiteMaterialContractPolicyInput;
};


export type MutationCreateMaterialPolicyArgs = {
  procurementSubscriber: Scalars['Boolean'];
  materialIDs: Array<SiteMaterialPolicyInput>;
};


export type MutationCreateMaterialContractPolicyArgs = {
  contractID: Scalars['String'];
  procurementSubscriber: Scalars['Boolean'];
  materialIDs: Array<SiteMaterialPolicyInput>;
};


export type MutationCreateTaxMasterFileArgs = {
  input: TaxInput;
};


export type MutationEditTaxMasterFileArgs = {
  input: EditTaxInput;
};


export type MutationDeleteTaxMasterFileArgs = {
  deleteInput: Array<DeleteTaxInput>;
  taxId: Scalars['String'];
};


export type MutationDeactivateTaxMasterFileArgs = {
  taxId: Scalars['String'];
};


export type MutationActivateTaxMasterFileArgs = {
  taxId: Scalars['String'];
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationCreateContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationUpdateContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationDeleteContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationReadNotificationArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type MutationMarkAsReadNotiArgs = {
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationDeleteNotiArgs = {
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationPatchWorkDeskDataArgs = {
  tableName: Scalars['String'];
};


export type MutationCreateContractRoleArgs = {
  permissionArr: Array<ContPermission>;
  input: RoleInput;
};


export type MutationUpdateContractRoleArgs = {
  permissionArr: Array<ContPermission>;
  input: RoleInput;
};


export type MutationCreateSiteRoleArgs = {
  permissionArr: Array<SitePermission>;
  input: RoleInput;
};


export type MutationUpdateSiteRoleArgs = {
  permissionArr: Array<SitePermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  forceDelete?: Maybe<Scalars['Boolean']>;
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  contractID: Scalars['String'];
};


export type MutationCreateAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationUpdateAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationDeleteAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationCreateAlertArgs = {
  input: AlertInput;
};


export type MutationUpdateAlertArgs = {
  input: AlertInput;
};


export type MutationDeleteAlertArgs = {
  input: AlertInput;
};


export type MutationUpdateAlertAssignmentArgs = {
  input: Array<AlertAssignmentInput>;
  isFromAdmin?: Maybe<Scalars['Boolean']>;
};


export type MutationCancelApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCancelApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCreateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationUpdateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationCreateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationUpdateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationDeleteApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationDeleteMultipleApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteMultipleApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationCreateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationUpdateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationDeleteBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationActivateBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationCancelBankAccountArgs = {
  input?: Maybe<BankAccountCancellationInput>;
  ID: Scalars['String'];
};


export type MutationDeleteCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationCancelCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationActivateCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationCreateCalendarArgs = {
  publicHolidayID: Array<Scalars['String']>;
  input: CalendarInput;
};


export type MutationUpdateCalendarArgs = {
  publicHolidayID: Array<Scalars['String']>;
  input: CalendarInput;
};


export type MutationCreateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyInput;
};


export type MutationUpdateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyInput;
};


export type MutationDeleteCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationCancelCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationActivateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationCreateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyInput;
};


export type MutationUpdateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyInput;
};


export type MutationDeleteCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationCancelCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationActivateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationUploadCompanyLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUploadIsoLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUploadCompanyIsoLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
  companyID: Scalars['String'];
};


export type MutationUpdateWatermarkCompanyArgs = {
  companyID: Scalars['String'];
  input: CompanyLogoInput;
};


export type MutationDeleteCostCategoryArgs = {
  input: CostCategoryUpdateInput;
};


export type MutationCancelCostCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationActivateCostCategoryArgs = {
  input: CostCategoryUpdateInput;
};


export type MutationCreateBatchCostCategoryArgs = {
  input: Array<CostCategoryInput>;
};


export type MutationCreateBatchCostCategoryWithoutItemArgs = {
  input: Array<TreeCostCategoryInput>;
};


export type MutationCreateCostCategoryArgs = {
  input: CostCategoryInput;
};


export type MutationUpdateCostCategoryArgs = {
  input: CostCategoryInput;
};


export type MutationDeleteCostCategoryCostItemArgs = {
  ID: Scalars['String'];
};


export type MutationAddCostCategoryArgs = {
  input: TreeCostCategoryInput;
};


export type MutationEditCostCategoryArgs = {
  input: TreeCostCategoryInput;
};


export type MutationDeleteTreeCostCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationCostCategoryImportValidationArgs = {
  CostCategoryImportInput: Array<CostCategoryImportInput>;
};


export type MutationCostCategoryImportArgs = {
  batchID: Scalars['String'];
};


export type MutationDeleteCostItemArgs = {
  input: CostItemDeleteInput;
};


export type MutationActivateCostItemArgs = {
  input: CostItemDeleteInput;
};


export type MutationCreateCostItemArgs = {
  input: CostItemInput;
};


export type MutationUpdateCostItemArgs = {
  input: CostItemInput;
};


export type MutationCreateBatchCostItemArgs = {
  input: Array<CostItemInput>;
  costCategoryID: Scalars['String'];
};


export type MutationCancelCostItemArgs = {
  ID: Scalars['String'];
};


export type MutationCostItemImportArgs = {
  batchID: Scalars['String'];
};


export type MutationCostItemImportValidationArgs = {
  CostItemImportInput: Array<CostItemImportInput>;
};


export type MutationCreateCurrencySetupArgs = {
  input: CurrencySetupInput;
};


export type MutationUpdateCurrencySetupArgs = {
  input: CurrencySetupInput;
};


export type MutationDeleteCurrencySetupArgs = {
  input: CurrencySetupDeleteInput;
};


export type MutationAddDigitalDocumentsArgs = {
  input: DigitalDocumentInput;
};


export type MutationDeleteDigitalDocumentsArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationCreateDocTypeArgs = {
  input: DocTypeInput;
};


export type MutationUpdateDocTypeArgs = {
  input: DocTypeInput;
};


export type MutationCreateRunningNumberSetupArgs = {
  input: RunningNumberSetupInput;
};


export type MutationUpdateRunningNumberSetupArgs = {
  input: RunningNumberSetupInput;
};


export type MutationInactiveDocTypeArgs = {
  ID: Scalars['String'];
};


export type MutationEditNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationUpdateDocNumSetupArgs = {
  input: DocNumInput;
  ID: Scalars['String'];
};


export type MutationCreateDocNumHeaderArgs = {
  input: DocNumHeaderInput;
};


export type MutationUpdateDocNumHeaderArgs = {
  input: DocNumHeaderInput;
};


export type MutationCreateDocNumDetailArgs = {
  input: DocNumDetailInput;
};


export type MutationUpdateDocNumDetailArgs = {
  input: DocNumDetailInput;
};


export type MutationCreateDocumentNumberHeaderArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationUpdateDocumentNumberHeaderArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationUpdateSystemAdminDocNumArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationCreateHolidayTypeArgs = {
  input: HolidayTypeInput;
};


export type MutationUpdateHolidayTypeArgs = {
  input: HolidayTypeInput;
};


export type MutationDeleteHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationCancelHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationActivateHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationCreateJobTypeArgs = {
  input: JobTypeInput;
};


export type MutationUpdateJobTypeArgs = {
  input: JobTypeInput;
};


export type MutationDeleteJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationCancelJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationActivateJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationCreatePlatformSuperUserArgs = {
  softwareName: Scalars['String'];
  input: UserInput;
};


export type MutationCreatePlatformUserArgs = {
  softwareCode: SoftwareCode;
  input: UserInput;
};


export type MutationUpdatePlatformUserArgs = {
  input: UserInput;
};


export type MutationCreatePlatformPasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePlatformPasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivatePlatformUserArgs = {
  token: Scalars['String'];
};


export type MutationPlatformLoginArgs = {
  softwareCode: SoftwareCode;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePlatformPasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationResetPlatformPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUploadProfilePicPlatformArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPlatformPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreatePolicyGuidelineArgs = {
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
};


export type MutationUpdatePolicyGuidelineArgs = {
  input: Array<PolicyAssignmentInput>;
  docRefTable: Scalars['String'];
};


export type MutationCreatePolicyAmountGuidelineArgs = {
  notiRoleUserIDs: Array<NotificationGuidelineInput>;
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
};


export type MutationUpdatePolicyAmountGuidelineArgs = {
  notiRoleUserIDs: Array<NotificationGuidelineInput>;
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
  ID: Scalars['String'];
};


export type MutationDeletePolicyAmountGuidelineArgs = {
  ID: Scalars['String'];
};


export type MutationCreateNotificationGuidelineArgs = {
  input: Array<NotificationGuidelineInput>;
  docRefTable: Scalars['String'];
};


export type MutationUpdateNotificationGuidelineArgs = {
  input: Array<NotificationGuidelineInput>;
  ID: Scalars['String'];
};


export type MutationCreatePublicHolidayArgs = {
  input: PublicHolidayInput;
};


export type MutationUpdatePublicHolidayArgs = {
  input: PublicHolidayInput;
};


export type MutationDeletePublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationCancelPublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationActivatePublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationDeleteRevenueCategoryArgs = {
  input: RevenueCategoryDeleteInput;
};


export type MutationCancelRevenueCategoryArgs = {
  input: RevenueCategoryUpdateInput;
};


export type MutationActivateRevenueCategoryArgs = {
  input: RevenueCategoryUpdateInput;
};


export type MutationCreateRevenueCategoryArgs = {
  input: RevenueCategoryInput;
};


export type MutationUpdateRevenueCategoryArgs = {
  input: RevenueCategoryInput;
};


export type MutationCreateBatchRevenueCategoryArgs = {
  input: Array<RevenueCategoryInput>;
};


export type MutationRevenueCategoryImportValidationArgs = {
  RevenueCategoryImportInput: Array<RevenueCategoryImportInput>;
};


export type MutationRevenueCategoryImportArgs = {
  batchID: Scalars['String'];
};


export type MutationGoodReceiveNoteRpaArgs = {
  files: Array<Scalars['Upload']>;
  siteID?: Maybe<Scalars['String']>;
};


export type MutationInvoiceRpaArgs = {
  contractID: Scalars['String'];
  files: Array<Scalars['Upload']>;
};


export type MutationDeliveryOrderRpaArgs = {
  contractID: Scalars['String'];
  files: Scalars['Upload'];
};


export type MutationUpdateSAccountArgs = {
  input: SAccountInput;
};


export type MutationCreateSiteMaterialArgs = {
  input: SiteMaterialInput;
};


export type MutationUpdateSiteMaterialArgs = {
  input: SiteMaterialInput;
};


export type MutationDeleteSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationCancelSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationActivateSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationCreateBulkSiteMaterialArgs = {
  input: Array<SiteMaterialInput>;
};


export type MutationCreateSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationUpdateSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationDeleteSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationCreateSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationUpdateSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationDeleteSubcontractorTypeArgs = {
  input: SubcontractorTypeDeleteInput;
};


export type MutationCancelSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationActivateSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationCreateSubcontractorTypeFdwArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationUpdateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationDeleteSupplierTypeArgs = {
  input: SupplierTypeDeleteInput;
};


export type MutationCancelSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationActivateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationCreateSupplierTypeFdwArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateUomArgs = {
  input: UomInput;
};


export type MutationDeleteUomArgs = {
  input: UomDeleteInput;
};


export type MutationCancelUomArgs = {
  input: UomDeleteInput;
};


export type MutationActivateUomArgs = {
  input: UomDeleteInput;
};


export type MutationCreateUomArgs = {
  input: UomInput;
};


export type MutationCreateBatchUomArgs = {
  input: Array<UomInput>;
};


export type MutationUomImportArgs = {
  batchID: Scalars['String'];
};


export type MutationUomImportValidationArgs = {
  input: Array<UomImportInput>;
};


export type MutationCreateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationUpdateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationDeleteUomExchangeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUserCompanyAssignmentArgs = {
  input: UserCompanyAssignmentInput;
};


export type MutationUpdateUserCompanyAssignmentArgs = {
  input: UserCompanyAssignmentInput;
};


export type MutationAssignUserCompanyArgs = {
  companyIDs: Array<Scalars['String']>;
  userID: Scalars['String'];
};


export type MutationUnassignUserCompanyArgs = {
  companyIDs: Array<Scalars['String']>;
  userID: Scalars['String'];
};


export type MutationAssignUserWithCompanyArgs = {
  userID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationUnassignUserWithCompanyArgs = {
  userID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateUserEmailArgs = {
  input: UserInput;
};


export type MutationSendInvitationEmailArgs = {
  accountID?: Maybe<Scalars['String']>;
  userIDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationResendInvitationArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationUpdateUserWithPasswordArgs = {
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationUnblockUserArgs = {
  input: PasswordInfo;
};


export type MutationChangePasswordSuArgs = {
  input: PasswordInfo;
};


export type MutationLoginArgs = {
  siteAccess?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationLogoutArgs = {
  entityID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationUploadProfilePicArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationUpdateLastEntityArgs = {
  companyID: Scalars['String'];
};


export type MutationCreateVideoCamArgs = {
  input: VideoCamInput;
};


export type MutationUpdateVideoCamArgs = {
  input: VideoCamInput;
};


export type MutationDeleteVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationCancelVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationActivateVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationCreateWagesRatePolicyArgs = {
  input: WagesRatePolicyInput;
};


export type MutationDeleteWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationCancelWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationActivateWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationUpdateWagesRatePolicyArgs = {
  holTypeRateIDs: Array<HolidayTypeRateInput>;
};


export type MutationCreateContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationUpdateContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationDeleteContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationCreateNotificationArgs = {
  body?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationCreateToDoArgs = {
  docNo: Scalars['String'];
  input: LedgerInput;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationApproveToDoArgs = {
  IDs: Array<Scalars['String']>;
};


export type MutationRejectToDoArgs = {
  remark: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type MutationDeleteWorkerArgs = {
  input: WorkerDeleteInput;
};


export type MutationCancelWorkerArgs = {
  input: WorkerDeleteInput;
};


export type MutationCreateWorkerArgs = {
  siteID: Array<Scalars['String']>;
  input: WorkerInput;
};


export type MutationUpdateWorkerArgs = {
  siteID: Array<Scalars['String']>;
  input: WorkerInput;
};


export type MutationCreateBulkWorkerArgs = {
  input: Array<WorkerInput>;
};


export type MutationUpdateWorkerAttendanceArgs = {
  input: WorkerAttendanceInput;
};


export type MutationDeleteWorkerAttendanceArgs = {
  input: WorkerAttendanceDeleteInput;
};


export type MutationCancelWorkerAttendanceArgs = {
  input: WorkerAttendanceDeleteInput;
};


export type MutationCreateWorkerAttendanceArgs = {
  input: WorkerAttendanceInput;
};


export type MutationCreateBatchWorkerAttendanceArgs = {
  input: Array<BatchWorkAttendanceInput>;
};


export type MutationApproveRejectWorkDeskArgs = {
  workDeskInput: Array<WorkDeskInput>;
};


export type MutationCreateNotificationWorkflowArgs = {
  body?: Maybe<Scalars['String']>;
  workflowID?: Maybe<Scalars['String']>;
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
};


export type MutationTestEmailArgs = {
  includeImage: Scalars['Boolean'];
};


export type MutationUpdateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyInput;
};


export type MutationDeleteWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationCancelWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationActivateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationCreateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyInput;
};


export type MutationUpdateAssignmentArgs = {
  input: AssignmentInput;
};


export type MutationCreateAssignmentArgs = {
  input: AssignmentInput;
};


export type MutationReturnFromAssignmentArgs = {
  input: AssignmentReturnInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCancelAssignmentArgs = {
  input: CancelInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateFuelDischargeArgs = {
  input: FuelDischargeInput;
};


export type MutationUpdateStatusFuelDischargeArgs = {
  input: FuelDischargeUpdateStatus;
  IDs: Array<Scalars['String']>;
};


export type MutationUpdateFuelDischargeArgs = {
  input: FuelDischargeInput;
};


export type MutationDeleteFuelDischargeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateFuelMeterReadingArgs = {
  input: FuelMeterReadingInput;
};


export type MutationUpdateFuelMeterReadingArgs = {
  input: FuelMeterReadingInput;
};


export type MutationDeleteFuelMeterReadingArgs = {
  input: FuelMeterReadingDeleteInput;
};


export type MutationCreateFuelSupplyArgs = {
  input: FuelSupplyInput;
};


export type MutationUpdateStatusFuelSupplyArgs = {
  input: FuelSupplyUpdateStatus;
};


export type MutationUpdateFuelSupplyArgs = {
  input: FuelSupplyInput;
};


export type MutationDeleteFuelSupplyArgs = {
  ID: Scalars['String'];
};


export type MutationActionOnFuelSupplyStatusArgs = {
  input: FuelSupplyActionInput;
  IDs: Array<Scalars['String']>;
};


export type MutationDeleteFuelTankSetupArgs = {
  input: FuelTankSetupDeleteInput;
};


export type MutationCreateFuelTankSetupArgs = {
  input: FuelTankSetupInput;
};


export type MutationUpdateFuelTankSetupArgs = {
  input: FuelTankSetupInput;
};


export type MutationCreateMaintenanceArgs = {
  assignmentID?: Maybe<Scalars['String']>;
  input: MaintenanceInput;
};


export type MutationUpdateMaintenanceArgs = {
  input: MaintenanceInput;
  assignmentID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};


export type MutationCancelMaintenanceCostArgs = {
  input: CancelInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationReturnFromMaintenanceArgs = {
  input: MaintenanceCostReturnInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateWorkshopArgs = {
  input: WorkshopInput;
};


export type MutationUpdateWorkshopArgs = {
  input: WorkshopInput;
};


export type MutationCreatePnmRentalArgs = {
  input: PnmRentalInput;
};


export type MutationUpdatePnmRentalArgs = {
  input: PnmRentalInput;
};


export type MutationCreatePlantMachineryArgs = {
  input: PlantMachineryInput;
};


export type MutationUpdatePlantMachineryArgs = {
  input: PlantMachineryInput;
};


export type MutationUpdateDoArgs = {
  doItemInput: Array<DoItemInput>;
  doInput: UpdateDoInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationActionOnDoStatusArgs = {
  doItemInput: Array<DoActionItemInput>;
  ID: Scalars['String'];
};


export type MutationCancelCloseDoArgs = {
  input: DoActionInput;
  ID: Scalars['String'];
};


export type MutationDeleteDoArgs = {
  doID: Scalars['String'];
};


export type MutationRejectDoArgs = {
  rejectInput?: Maybe<DoRejectInput>;
  ID: Scalars['String'];
};


export type MutationSubmitDoArgs = {
  doItemInput?: Maybe<Array<DoItemInput>>;
  doInput: DoInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationPackDoArgs = {
  packedInput?: Maybe<DoPackedInput>;
  ID: Scalars['String'];
};


export type MutationShipDoArgs = {
  shippedInput?: Maybe<DoShippedInput>;
  ID: Scalars['String'];
};


export type MutationSubmitGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  input: GoodReturnNoteInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationOldCreateUpdateGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  grtnInput: GoodReturnNoteInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationCreateUpdateGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  grtnInput: GoodReturnNoteInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationApproveGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  ID: Scalars['String'];
};


export type MutationRejectGrtnArgs = {
  rejectInput?: Maybe<GrtnRejectInput>;
  ID: Scalars['String'];
};


export type MutationDeleteGrtnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelCloseGrtnArgs = {
  input: GrtnActionInput;
  ID: Scalars['String'];
};


export type MutationCreateNegotiatedSupplyArgs = {
  negoItemInput: Array<NegotiatedSupplyItemInput>;
  negoSupplyInput: NegotiatedSupplyInput;
};


export type MutationUpdateNegotiatedSupplyArgs = {
  negoItemInput: Array<NegotiatedSupplyItemInput>;
  negoSupplyInput: NegotiatedSupplyInput;
  ID: Scalars['String'];
};


export type MutationCancelNegotiatedSupplyArgs = {
  input: NegoSupplyCancellationInput;
  ID: Scalars['String'];
};


export type MutationDeleteNegoSupplyArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePoArgs = {
  poInput: Array<PurchaseOrderInput>;
};


export type MutationCreateDirectPoArgs = {
  poItemInput?: Maybe<Array<PoItemInput>>;
  input: DirectPoInput;
};


export type MutationUpdateDirectPoArgs = {
  poItemInput?: Maybe<Array<PoItemInput>>;
  input: DirectPoInput;
  ID: Scalars['String'];
};


export type MutationActionOnPoStatusArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationCancelClosePoArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationDeletePoArgs = {
  ID: Scalars['String'];
};


export type MutationPurchaseBudgetApprovalArgs = {
  input: PurchaseBudgetInput;
  IDs: Array<Scalars['String']>;
};


export type MutationCreatePurchaseReqArgs = {
  prItemListInput: Array<PrItemInput>;
  purchaseReqInput: PurchaseReqInput;
};


export type MutationUpdatePurchaseRequestArgs = {
  prItemListInput: Array<PrItemInput>;
  purchaseReqInput: PurchaseReqInput;
  ID: Scalars['String'];
};


export type MutationActionOnPrStatusArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationDeletePrArgs = {
  ID: Scalars['String'];
};


export type MutationCancelClosePrArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationSubmitEvaluationSelectionArgs = {
  evalSelectInput: Array<EvalSelectInput>;
  rfqInput: RfqEvalInput;
};


export type MutationCreateRfqArgs = {
  rfqItemInput: Array<RfqItemInput>;
  rfqInput: RfqInput;
};


export type MutationUpdateRfqArgs = {
  rfqItemInput: Array<RfqItemInput>;
  rfqInput: RfqInput;
  ID: Scalars['String'];
};


export type MutationRejectRfqArgs = {
  rfqRejectInput: RfqRejectInput;
  ID: Scalars['String'];
};


export type MutationCancelCloseRfqArgs = {
  input: RfqActionInput;
  ID: Scalars['String'];
};


export type MutationDeleteRfqArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateRfqSupplierInvitationArgs = {
  input: RfqSupplierInvitationInput;
};


export type MutationDeleteRfqSupplierInvitationArgs = {
  input: RfqSupplierInvitationInput;
};


export type MutationInviteSupplierArgs = {
  supplierIDs: Array<Scalars['String']>;
  rfqID: Scalars['String'];
};


export type MutationUninviteSupplierArgs = {
  supplierID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type MutationDeleteRfqSupplierSubmissionArgs = {
  ID: Scalars['String'];
};


export type MutationActiveRfqSubmissionArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitRfqQuoteArgs = {
  input: RfqSubmissionInput;
};


export type MutationCreateSupplierCompanyAssignmentArgs = {
  input: SupplierCompanyAssignmentInput;
};


export type MutationUpdateSupplierCompanyAssignmentArgs = {
  input: SupplierCompanyAssignmentInput;
};


export type MutationAssignSupplierCompanyArgs = {
  companyID: Array<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type MutationUnassignSupplierCompanyArgs = {
  companyID: Array<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type MutationAssignSupplierWithCompanyArgs = {
  supplierID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationUnassignSupplierWithCompanyArgs = {
  supplierID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationCreatePlatformSupplierArgs = {
  input: SupplierInput;
};


export type MutationUpdatePlatformSupplierArgs = {
  input: SupplierInput;
};


export type MutationDeleteSupplierArgs = {
  input: SupplierDeleteInput;
};


export type MutationCreateSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationUpdateSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationDeleteSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationAddSupplierContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationCreateBulkSupplierArgs = {
  supplierInput: Array<BulkSupplierInput>;
};


export type MutationAddCCategoryInSupplierArgs = {
  costCategoryID: Array<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type MutationAddSupplierInCCategoryArgs = {
  supplierID: Array<Scalars['String']>;
  costCategoryID: Scalars['String'];
};


export type MutationDeleteSupplierInCCategoryArgs = {
  supplierID: Array<Scalars['String']>;
  costCategoryID: Scalars['String'];
};


export type MutationSupplierImportValidationArgs = {
  input: Array<SupplierImportInput>;
};


export type MutationCreateSupplierImportArgs = {
  batchID: Scalars['String'];
};


export type MutationCreateApInvoiceArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApInvoiceArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApInvoiceWithoutDoArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApInvoiceWithoutDoArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApInvoiceWithoutDoArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSupplierInvoiceArgs = {
  invDoItemMatchInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationCreateSupplierInvoiceV2Args = {
  isWithDO: Scalars['Boolean'];
  APInvoiceItemInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationUpdateSupplierInvoiceV2Args = {
  isWithDO: Scalars['Boolean'];
  APInvoiceItemInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationUpdateSupplierInvoiceArgs = {
  invDoItemMatchInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationDeleteSupplierInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCancelSupplierInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateApAdvanceArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApAdvanceArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApPaymentArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApPaymentArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApPaymentArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApRefundArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApRefundArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApRefundArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSupplierPaymentArgs = {
  ledger: Array<LedgerInput>;
  input: ApPaymentInput;
};


export type MutationCancelSupplierDepositArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierPaymentArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateApDebitNoteArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApDebitNoteArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApDebitNoteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApCreditNoteArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApCreditNoteArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApCreditNoteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateDNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteDNtoSupplierArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateCNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteCNtoSupplierArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSupplierAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetSupplierAllocationArgs = {
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCancelSupplierDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelDNtoSupplierArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelCNtoSupplierArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationUpdateSubcontractorInvitationArgs = {
  input: SubcontractorInvitationInput;
};


export type MutationDeleteSubcontractorInvitationArgs = {
  input: SubcontractorInvitationInput;
};


export type MutationInviteSubcontractorArgs = {
  subcontractorIDs: Array<Scalars['String']>;
  subcontractTenderID: Scalars['String'];
};


export type MutationUnInviteSubcontractorArgs = {
  subcontractTenderID: Scalars['String'];
  subcontractorID: Scalars['String'];
};


export type MutationUnInviteSubcontractorsArgs = {
  subcontractorIDs: Array<Scalars['String']>;
  subcontractTenderID: Scalars['String'];
};


export type MutationCreateSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationUpdateSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationDeleteSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationCreateSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationUpdateSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationDeleteSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationTenderToSubconGenerationArgs = {
  input: SubconTenderGenerationInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeInput;
};


export type MutationUpdateSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeInput;
};


export type MutationDeleteSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeInsuranceDeleteInput;
};


export type MutationCreateSubcontractInsuranceArgs = {
  input: SubcontractInsuranceInput;
};


export type MutationUpdateSubcontractInsuranceArgs = {
  input: SubcontractInsuranceInput;
};


export type MutationDeleteSubcontractInsuranceArgs = {
  input: SubcontractGuaranteeInsuranceDeleteInput;
};


export type MutationCancelSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeCancellationInput;
};


export type MutationCancelSubcontractInsuranceArgs = {
  input: SubcontractInsuranceCancellationInput;
};


export type MutationCreateSubcontractArgs = {
  wbsInput?: Maybe<Array<SubcontractCostWbsInput>>;
  input: SubcontractInput;
};


export type MutationUpdateSubcontractArgs = {
  wbsInput?: Maybe<Array<SubcontractCostWbsInput>>;
  input: SubcontractInput;
};


export type MutationActionOnSubcontractStatusArgs = {
  input: SubcontractActionInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractExtensionDateArgs = {
  input: SubcontractExtensionInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractCertificateArgs = {
  input: SubcontractCertificateInput;
  ID: Scalars['String'];
};


export type MutationActionOnSubcontractStatusWithTradeArgs = {
  input: SubcontractApproveRejectInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractorCompanyAssignmentArgs = {
  input: SubcontractorCompanyAssignmentInput;
};


export type MutationUpdateSubcontractorCompanyAssignmentArgs = {
  input: SubcontractorCompanyAssignmentInput;
};


export type MutationAssignSubconCompanyArgs = {
  companyID: Array<Scalars['String']>;
  subcontractorID: Scalars['String'];
};


export type MutationUnassignSubconCompanyArgs = {
  companyID: Array<Scalars['String']>;
  subcontractorID: Scalars['String'];
};


export type MutationAssignSubconWithCompanyArgs = {
  subcontractorID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationUnassignSubconWithCompanyArgs = {
  subcontractorID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationCreateSubcontractVoArgs = {
  voDetails?: Maybe<Array<SubconVoDetailsInput>>;
  input: SubcontractVoInput;
};


export type MutationUpdateSubcontractVoArgs = {
  voDetails?: Maybe<Array<SubconVoDetailsInput>>;
  input: SubcontractVoInput;
};


export type MutationDeleteSubcontractVoArgs = {
  ID: Scalars['String'];
};


export type MutationActionOnSubconVoStatusArgs = {
  itemApprovedAmount?: Maybe<Array<ItemApprovedAmountInput>>;
  input?: Maybe<SubcontractVoActionInput>;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractCostWbsArgs = {
  input: SubcontractWbsInput;
};


export type MutationUpdateSubcontractCostWbsArgs = {
  input: SubcontractWbsInput;
};


export type MutationDeleteSubcontractCostWbsArgs = {
  input: SubcontractWbsDeleteInput;
};


export type MutationUpdateSubcontractLedgersArgs = {
  tableName: Scalars['String'];
};


export type MutationCreateSubcontractClaimArgs = {
  input: SubcontractClaimInput;
};


export type MutationUpdateSubcontractClaimArgs = {
  input: SubcontractClaimInput;
};


export type MutationDeleteSubcontractClaimArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitSubconRetentionClaimArgs = {
  input: SubconClaimRetentionInput;
};


export type MutationApproveSubconRetentionClaimArgs = {
  input: SubconClaimRetentionInput;
};


export type MutationSubmitSubconClaimArgs = {
  voCostWbsAlloc?: Maybe<Array<SubconClaimVoCostWbsAllocInput>>;
  voAlloc?: Maybe<Array<SubconClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<SubconClaimAllocInput>>;
  deductionLedger?: Maybe<Array<LedgerInput>>;
  input: SubcontractClaimInput;
};


export type MutationApproveSubconClaimArgs = {
  voCostWbsAlloc?: Maybe<Array<SubconClaimVoCostWbsAllocInput>>;
  voAlloc?: Maybe<Array<SubconClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<SubconClaimAllocInput>>;
  input: SubcontractClaimInput;
};


export type MutationUpdateSubconClaimDetailsArgs = {
  input: ClaimDetailsInput;
  ID: Scalars['String'];
};


export type MutationRejectSubconClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: RejectInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractAdvanceArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractAdvanceArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractPaymentArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractPaymentArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractPaymentArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractRefundArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractRefundArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractRefundArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSubconPaymentArgs = {
  ledger: Array<LedgerInput>;
  input: SubcontractPaymentInput;
};


export type MutationCancelSubconAdvanceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconPaymentArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractMiscInvoiceArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateSubcontractMiscInvoiceArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteSubcontractMiscInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractDnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateSubcontractDnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteSubcontractDnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractCnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateSubcontractCnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteSubcontractCnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateDNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteDNtoSubconArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateCNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteCNtoSubconArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSubconAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetSubcontractAllocationArgs = {
  receiptPayment?: Maybe<Scalars['Boolean']>;
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCancelSubcontractMiscInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelDNtoSubconArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelCNtoSubconArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreatePlatformSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationUpdatePlatformSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationDeleteSubcontractorArgs = {
  input: SubcontractorDeleteId;
};


export type MutationCreateSubcontractorCCategoryArgs = {
  input: SubcontractorCCategoryInput;
};


export type MutationUpdateSubcontractorCCategoryArgs = {
  input: SubcontractorCCategoryInput;
};


export type MutationAddSubcontractorContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationCreateSubcontractorAndPackageArgs = {
  costCategoryID: Array<Scalars['String']>;
  subcontractorInput: SubcontractorInput;
};


export type MutationCreateBulkSubconArgs = {
  subcontractorInput: Array<BulkSubcontractorInput>;
};


export type MutationAddSubcontractorCCategoryArgs = {
  costCategoryID: Array<Scalars['String']>;
  subcontractorID: Scalars['String'];
};


export type MutationCreateSubcontractorSetupArgs = {
  contactInput: Array<ContactPersonInput>;
  costCategoryID: Array<Scalars['String']>;
  input: SubcontractorHeaderInput;
};


export type MutationUpdateSubcontractorSetupArgs = {
  contactInput: Array<ContactPersonInput>;
  costCategoryID: Array<Scalars['String']>;
  input: SubcontractorHeaderInput;
};


export type MutationDeleteSubcontractorSetupArgs = {
  ID: Scalars['String'];
};


export type MutationSubcontractorImportValidationArgs = {
  input: Array<SubcontractorImportInput>;
};


export type MutationCreateSubcontractorImportArgs = {
  batchID: Scalars['String'];
};


export type MutationCreateSiteArgs = {
  siteInput: Array<SiteInput>;
  input: ProjectHeaderInput;
};


export type MutationUpdateSiteArgs = {
  siteInput: Array<SiteInput>;
  input: ProjectHeaderInput;
};


export type MutationDeleteSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationActivateSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationCancelSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationInsertUpdateSiteArgs = {
  siteInput: Array<SiteInput>;
  contractID: Scalars['String'];
};


export type MutationDeleteProjectSiteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSiteImageArgs = {
  input: SiteImageInput;
};


export type MutationUpdateSiteImageArgs = {
  input: SiteImageInput;
};


export type MutationDeleteSiteImageArgs = {
  input: SiteImageDeleteInput;
};


export type MutationCancelSiteImageArgs = {
  input: SiteImageDeleteInput;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Pakistani = 'Pakistani',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export type NationalityTypes = {
  __typename?: 'NationalityTypes';
  nationality: Nationality;
};

export type NegoSupplyCancellationInput = {
  cancellationRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type NegotiatedSupplyEntity = AuditEntity & {
  __typename?: 'NegotiatedSupplyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docNo: Scalars['String'];
  negoSupplyDate: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  status: PurchaseStatus;
  negoSupplyItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
};

export type NegotiatedSupplyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  docNo: Scalars['String'];
  negoSupplyDate: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type NegotiatedSupplyItemEntity = AuditEntity & {
  __typename?: 'NegotiatedSupplyItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  negotiatedSupplyID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  balanceQty?: Maybe<Scalars['Float']>;
  unitPrice: Scalars['Float'];
  costItem?: Maybe<CostItemEntity>;
  poItem?: Maybe<Array<PoItemEntity>>;
  UOM?: Maybe<UomEntity>;
  negoSupply?: Maybe<NegotiatedSupplyEntity>;
  poItemApproved: Array<PoItemEntity>;
  uomExchange?: Maybe<Array<UomExchange>>;
};

export type NegotiatedSupplyItemInput = {
  ID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type NotiRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'NotiRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notiPolicyID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  notification?: Maybe<NotificationGuidelineEntity>;
};

export type NotificationGuidelineEntity = AuditEntity & {
  __typename?: 'NotificationGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
  policyID?: Maybe<Scalars['String']>;
  notiRoleUsers?: Maybe<Array<NotiRoleUserAssignmentEntity>>;
  /** CustomFieldResolver */
  roles: Scalars['JSON'];
};

export type NotificationGuidelineInput = {
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type NumberFormatInput = {
  softwareID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  documentDivider?: Maybe<Scalars['String']>;
  prefixExtra?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
};

export enum NumericFormatter {
  Lumpsum = 'LUMPSUM',
  Thousands = 'THOUSANDS',
  Millions = 'MILLIONS',
  Billions = 'BILLIONS'
}

export type Os = {
  __typename?: 'OS';
  amt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
};

export type PnmCategoryWorkshopEntity = AuditEntity & {
  __typename?: 'PNMCategoryWorkshopEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory: CostCategoryEntity;
  workshopID?: Maybe<Scalars['String']>;
  workshop: WorkshopEntity;
};

export type PnmCostCat = {
  __typename?: 'PNMCostCat';
  quantity?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
};

export type PnmCostCatInput = {
  quantity?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
};

export type PnmRentalEntity = AuditEntity & {
  __typename?: 'PNMRentalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  supplierID?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierEntity>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  rentalRate: RateUomType;
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  requireFuelSupply: Scalars['Boolean'];
  plantMachineryStatus: PlantMachineryStatus;
  company?: Maybe<SubscriptionCompanyType>;
  fuelTotalSupply?: Maybe<Scalars['Float']>;
  fuelTotalDischarge?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousFuelSupplyDischarge?: Maybe<Scalars['JSON']>;
  tankTable: Scalars['String'];
  /** CustomFieldResolver */
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  /** CustomFieldResolver */
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  /** CustomFieldResolver */
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  /** CustomFieldResolver */
  rentalTankSummary?: Maybe<Scalars['JSON']>;
};


export type PnmRentalEntityRentalTankSummaryArgs = {
  companyID: Scalars['String'];
};

export type PnmRentalInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  costCategoryID: Scalars['String'];
  supplierID: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  cost: Scalars['Float'];
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  returnDate?: Maybe<Scalars['String']>;
  requireFuelSupply?: Maybe<Scalars['Boolean']>;
  rentalRate: RateUomType;
  plantMachineryStatus?: Maybe<PlantMachineryStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PoBudgetExceptionEntity = AuditEntity & {
  __typename?: 'POBudgetExceptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  remarks: Scalars['String'];
};

export type PoCancellationInput = {
  supplierID: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  totalAmt: Scalars['Float'];
  instructions?: Maybe<Scalars['String']>;
  poItems: Array<PoItemInput>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PoItemEntity = AuditEntity & {
  __typename?: 'POItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  purchaseOrderID: Scalars['String'];
  purchaseOrder?: Maybe<PurchaseOrderEntity>;
  prItemID?: Maybe<Scalars['String']>;
  prItem?: Maybe<PrItemEntity>;
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  negotiatedSupplyItemID?: Maybe<Scalars['String']>;
  nego?: Maybe<NegotiatedSupplyItemEntity>;
  rfqItemID?: Maybe<Scalars['String']>;
  rfqItem?: Maybe<RfqItemEntity>;
  uomID?: Maybe<Scalars['String']>;
  uom?: Maybe<UomEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  currencyID?: Maybe<Scalars['String']>;
  orderedQty: Scalars['Float'];
  outstandingQty?: Maybe<Scalars['Float']>;
  expectedDate?: Maybe<Scalars['String']>;
  unitPrice: Scalars['Float'];
  taxAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  discountAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  doItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  poItemVariance?: Maybe<Scalars['JSON']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  uomExchange?: Maybe<Array<PoUomExchange>>;
};


export type PoItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type PoItemInput = {
  createdBy?: Maybe<Scalars['String']>;
  orderedQty: Scalars['Float'];
  unitPrice: Scalars['Float'];
  prItemID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  negotiatedSupplyItemID?: Maybe<Scalars['String']>;
  rfqItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  taxAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  discountAmt?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  expectedDate: Scalars['String'];
};

export type PoItemsInput = {
  doItemID?: Maybe<Scalars['String']>;
  poItemID?: Maybe<Scalars['String']>;
  grtnItemID?: Maybe<Scalars['String']>;
  isInventory?: Maybe<Scalars['Boolean']>;
  acceptedQty: Scalars['Float'];
  deliveredQty: Scalars['Float'];
};

export type PrItemEntity = AuditEntity & {
  __typename?: 'PRItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  purchaseReqID: Scalars['String'];
  purchaseReq?: Maybe<PurchaseReqEntity>;
  costItemID: Scalars['String'];
  costItem?: Maybe<CostItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  requestedQty: Scalars['Float'];
  outstandingQty: Scalars['Float'];
  expectedDate?: Maybe<Scalars['String']>;
  markupPerc?: Maybe<Scalars['Float']>;
  negotiatedItemStatus: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  poItem?: Maybe<Array<PoItemEntity>>;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  uomExchange?: Maybe<Array<UomExchanges>>;
  negoSupplySelected?: Maybe<Scalars['JSON']>;
  rfqSubmissionSelected?: Maybe<Scalars['JSON']>;
};


export type PrItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type PrItemEntityNegoSupplySelectedArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type PrItemEntityRfqSubmissionSelectedArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type PrItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  requestedQty: Scalars['Float'];
  markupPerc?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  negotiatedItemStatus?: Maybe<Scalars['Boolean']>;
  expectedDate: Scalars['String'];
};

export type PackedDetail = {
  __typename?: 'PackedDetail';
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type PackedDetailInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type PaymentReminderEntity = AuditEntity & {
  __typename?: 'PaymentReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  reminderDate: Scalars['String'];
  commonStatus: CommonStatus;
  docReminder?: Maybe<Array<DocReminderEntity>>;
  /** CustomFieldResolver */
  payReminderTotal?: Maybe<Scalars['JSON']>;
};

export type PaymentReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  customerID?: Maybe<Scalars['String']>;
  reminderDate: Scalars['String'];
};

export type PkkDetails = {
  __typename?: 'PkkDetails';
  registrationNo?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PkkDetailsInput = {
  registrationNo?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PlantMachineryEntity = AuditEntity & {
  __typename?: 'PlantMachineryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  purchaseDate: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  availableQty?: Maybe<Scalars['Float']>;
  serialNo?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  roadTaxDate?: Maybe<Scalars['String']>;
  insuranceDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  plantMachineryStatus: PlantMachineryStatus;
  assignment?: Maybe<Array<AssignmentEntity>>;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
  tank?: Maybe<Array<TankEntity>>;
  company?: Maybe<SubscriptionCompanyType>;
  /** CustomFieldResolver */
  latestActiveAssignment?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestActiveMaintenance?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  currentPnmStatus?: Maybe<Scalars['String']>;
  fuelTotalSupply?: Maybe<Scalars['Float']>;
  fuelTotalDischarge?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousFuelSupplyDischarge?: Maybe<Scalars['JSON']>;
  tankTable: Scalars['String'];
  /** CustomFieldResolver */
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  /** CustomFieldResolver */
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  /** CustomFieldResolver */
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  /** CustomFieldResolver */
  tankSummary?: Maybe<Scalars['JSON']>;
};


export type PlantMachineryEntityTankSummaryArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type PlantMachineryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  name: Scalars['String'];
  purchaseDate: Scalars['String'];
  companyID: Scalars['String'];
  supplierID?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serialNo?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  roadTaxDate?: Maybe<Scalars['String']>;
  insuranceDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  plantMachineryStatus?: Maybe<PlantMachineryStatus>;
};

export enum PlantMachineryStatus {
  Active = 'ACTIVE',
  Assigned = 'ASSIGNED',
  PartiallyAssigned = 'PARTIALLY_ASSIGNED',
  Maintenance = 'MAINTENANCE',
  OutOfOrder = 'OUT_OF_ORDER',
  Returned = 'RETURNED',
  InTransaction = 'IN_TRANSACTION'
}

export type PlatformLoginResponse = {
  __typename?: 'PlatformLoginResponse';
  accessToken: Scalars['String'];
  user: PlatformUserResponse;
};

export type PlatformUserEntity = AuditEntity & {
  __typename?: 'PlatformUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  associateID: Scalars['String'];
  contactID: Scalars['String'];
  contactDetail: ContactEntity;
  userName: Scalars['String'];
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type PlatformUserResponse = {
  __typename?: 'PlatformUserResponse';
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
};

export type PolicyAssignmentInput = {
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type PolicyGuidelineEntity = AuditEntity & {
  __typename?: 'PolicyGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
  policyRoles: Array<PolicyRoleAssignmentEntity>;
};

export type PolicyGuidelineInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
};

export type PolicyRoleAssignmentEntity = AuditEntity & {
  __typename?: 'PolicyRoleAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  policyID: Scalars['String'];
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
  policy?: Maybe<PolicyGuidelineEntity>;
};

export enum PostStatus {
  Draft = 'DRAFT',
  Posted = 'POSTED'
}

export type PricingSupplierEntity = AuditEntity & {
  __typename?: 'PricingSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  supplierID: Scalars['String'];
  price: Scalars['Float'];
  supplier?: Maybe<SupplierEntity>;
  costItem: CostItemEntity;
};

export type Product = {
  __typename?: 'Product';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogAssignmentEntity = AuditEntity & {
  __typename?: 'ProfitRecogAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  profitRecogFormulaID: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogFormula?: Maybe<ProfitRecogFormulaEntity>;
  contract?: Maybe<ContractEntity>;
};

export type ProfitRecogAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogFormulaDeleteInput = {
  ID: Scalars['String'];
};

export type ProfitRecogFormulaEntity = AuditEntity & {
  __typename?: 'ProfitRecogFormulaEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  title: Scalars['String'];
  percOfCompletion: Scalars['String'];
  cost: Scalars['String'];
  revenue: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogAssgmt?: Maybe<Array<ProfitRecogAssignmentEntity>>;
  contract?: Maybe<Array<ContractEntity>>;
};

export type ProfitRecogFormulaInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  percOfCompletion: Scalars['String'];
  cost: Scalars['String'];
  revenue: Scalars['String'];
};

export type ProfitRecogGlAccountCodeEntity = AuditEntity & {
  __typename?: 'ProfitRecogGLAccountCodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wipCostRecognized: Scalars['String'];
  wipBillingRecognized: Scalars['String'];
  revenuePL: Scalars['String'];
  costPL: Scalars['String'];
};

export type ProfitRecogGlAccountCodeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wipCostRecognized?: Maybe<Scalars['String']>;
  wipBillingRecognized?: Maybe<Scalars['String']>;
  revenuePL?: Maybe<Scalars['String']>;
  costPL?: Maybe<Scalars['String']>;
};

export type ProfitRecogGlJournalEntity = AuditEntity & {
  __typename?: 'ProfitRecogGLJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  profitRecogGenerationID: Scalars['String'];
  wipCostRecAccCode: Scalars['String'];
  wipBillingRecAccCode: Scalars['String'];
  revenuePLAccCode: Scalars['String'];
  costPLAccCode: Scalars['String'];
  profitRecogGeneration: ProfitRecogGenerationEntity;
};

export type ProfitRecogGlJournalInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogGenParamAssgmtEntity = AuditEntity & {
  __typename?: 'ProfitRecogGenParamAssgmtEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  profitRecogGenerationID: Scalars['String'];
  profitRecogParameterID: Scalars['String'];
  amount: Scalars['Float'];
  profitRecogParameter: ProfitRecogParameterEntity;
  profitRecogGeneration: ProfitRecogGenerationEntity;
};

export type ProfitRecogGenParamAssgmtInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogGenerationEntity = AuditEntity & {
  __typename?: 'ProfitRecogGenerationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo: Scalars['String'];
  docDate: Scalars['String'];
  remarks: Scalars['String'];
  percOfCompletion: Scalars['Float'];
  cost: Scalars['Float'];
  revenue: Scalars['Float'];
  profitRecogStatus: ProfitRecogStatus;
  profitRecogGLJournal: Array<ProfitRecogGlJournalEntity>;
  profitRecogGenParamAssgmt: Array<ProfitRecogGenParamAssgmtEntity>;
  contract?: Maybe<ContractEntity>;
};

export type ProfitRecogGenerationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  docDate: Scalars['String'];
  remarks: Scalars['String'];
  percOfCompletion: Scalars['Float'];
  cost: Scalars['Float'];
  revenue: Scalars['Float'];
  profitRecogStatus?: Maybe<ProfitRecogStatus>;
};

export type ProfitRecogParameterEntity = AuditEntity & {
  __typename?: 'ProfitRecogParameterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  sqlScript: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogGenParamAssgmt: Array<ProfitRecogGenParamAssgmtEntity>;
};

export type ProfitRecogParameterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export enum ProfitRecogStatus {
  Active = 'ACTIVE',
  Recognized = 'RECOGNIZED',
  Cancelled = 'CANCELLED'
}

export type ProjectBudgetDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ProjectBudgetEntity = AuditEntity & {
  __typename?: 'ProjectBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  costCategoryID: Scalars['String'];
  costCateg: CostCategoryEntity;
  budgetAmt?: Maybe<Array<Scalars['JSON']>>;
};

export type ProjectBudgetInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  budgetAmt?: Maybe<Array<BudgetAmtInput>>;
};

export type ProjectCostingDetails = {
  __typename?: 'ProjectCostingDetails';
  subContractCommittedCost?: Maybe<Scalars['Float']>;
  materialCommittedCostAmt?: Maybe<Scalars['Float']>;
  plantCommittedCostAmt?: Maybe<Scalars['Float']>;
  labourCommittedCostAmt?: Maybe<Scalars['Float']>;
  miscCommittedCostAmt?: Maybe<Scalars['Float']>;
  balancedToSpendLabour?: Maybe<Scalars['Float']>;
  balancedToSpendMaterial?: Maybe<Scalars['Float']>;
  balancedToSpendMisc?: Maybe<Scalars['Float']>;
  balancedToSpendPlant?: Maybe<Scalars['Float']>;
  balancedToSpendSubcon?: Maybe<Scalars['Float']>;
  CommitedActualCostLabour?: Maybe<Scalars['Float']>;
  CommitedActualCostMaterial?: Maybe<Scalars['Float']>;
  CommitedActualCostMisc?: Maybe<Scalars['Float']>;
  CommitedActualCostPlant?: Maybe<Scalars['Float']>;
  originalBudgetSubcontractAmt?: Maybe<Scalars['Float']>;
  originalBudgetPlantAmt?: Maybe<Scalars['Float']>;
  originalBudgetMiscAmt?: Maybe<Scalars['Float']>;
  originalBudgetMaterialAmt?: Maybe<Scalars['Float']>;
  originalBudgetLabourAmt?: Maybe<Scalars['Float']>;
  revisedBudgetSubcontractAmt?: Maybe<Scalars['Float']>;
  revisedBudgetPlantAmt?: Maybe<Scalars['Float']>;
  revisedBudgetMiscAmt?: Maybe<Scalars['Float']>;
  revisedBudgetMaterialAmt?: Maybe<Scalars['Float']>;
  revisedBudgetLabourAmt?: Maybe<Scalars['Float']>;
};

export type ProjectCostingEntity = AuditEntity & {
  __typename?: 'ProjectCostingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  projectSum: Scalars['Float'];
  balanceToSpend: Scalars['Float'];
  projectCostAmt: Scalars['Float'];
};

export type ProjectExpenseCancelInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};

export type ProjectExpenseEntity = AuditEntity & {
  __typename?: 'ProjectExpenseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docRef: Scalars['String'];
  docDate: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  cancellationDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  status: MiscExpenseStatus;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
};

export type ProjectExpenseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  docRef: Scalars['String'];
  docDate: Scalars['String'];
  transactionDate: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ProjectExpenseItemEntity = AuditEntity & {
  __typename?: 'ProjectExpenseItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  projectExpenseID: Scalars['String'];
  projectExpense?: Maybe<ProjectExpenseEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
};

export type ProjectExpenseItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  projectExpenseID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  costClass?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type ProjectExpenseRejectInput = {
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};

export type ProjectHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
};

export type ProjectOpportunityActivityLog = {
  __typename?: 'ProjectOpportunityActivityLog';
  personInChargeID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
};

export type ProjectOpportunityActivityLogInput = {
  personInChargeID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
};

export type ProjectOpportunityAttachmentDeleteInput = {
  ID: Scalars['String'];
};

export type ProjectOpportunityAttachmentEntity = AuditEntity & {
  __typename?: 'ProjectOpportunityAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  projectOpportunityID: Scalars['String'];
  projectOpportunity?: Maybe<ProjectOpportunityEntity>;
  title: Scalars['String'];
  date: Scalars['String'];
};

export type ProjectOpportunityAttachmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  projectOpportunityID: Scalars['String'];
  title: Scalars['String'];
  date: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ProjectOpportunityDeleteInput = {
  ID: Scalars['String'];
};

export type ProjectOpportunityEntity = AuditEntity & {
  __typename?: 'ProjectOpportunityEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  title: Scalars['String'];
  description: Scalars['String'];
  location: Scalars['String'];
  amount: Scalars['Float'];
  personInChargeID: Scalars['String'];
  closingDateTime: Scalars['DateTime'];
  remarks: Scalars['String'];
  activityLog?: Maybe<Array<Scalars['JSON']>>;
  status: ProjectOpportunityStatus;
  projectOpportunitySubmission?: Maybe<Array<ProjectOpportunitySubmissionEntity>>;
  projectOpportunityAttachment?: Maybe<Array<ProjectOpportunityAttachmentEntity>>;
  personInCharge?: Maybe<UserEntity>;
};

export type ProjectOpportunityInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  customerID: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  location: Scalars['String'];
  personInChargeID?: Maybe<Scalars['String']>;
  closingDateTime: Scalars['DateTime'];
  amount: Scalars['Float'];
  remarks: Scalars['String'];
  status?: Maybe<ProjectOpportunityStatus>;
};

export enum ProjectOpportunityStatus {
  Open = 'OPEN',
  Submitted = 'SUBMITTED',
  Signed = 'SIGNED',
  Lost = 'LOST'
}

export type ProjectOpportunitySubmissionDeleteInput = {
  ID: Scalars['String'];
};

export type ProjectOpportunitySubmissionEntity = AuditEntity & {
  __typename?: 'ProjectOpportunitySubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  projectOpportunityID: Scalars['String'];
  projectOpportunity?: Maybe<ProjectOpportunityEntity>;
  submissionDate: Scalars['String'];
  tenderAmt?: Maybe<Scalars['Float']>;
  remarks: Scalars['String'];
};

export type ProjectOpportunitySubmissionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  projectOpportunityID: Scalars['String'];
  submissionDate: Scalars['String'];
  tenderAmt?: Maybe<Scalars['Float']>;
  remarks: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ProjectPlDetails = {
  __typename?: 'ProjectPLDetails';
  doSumPerCC?: Maybe<Scalars['JSON']>;
  progressClaimListing?: Maybe<Scalars['JSON']>;
  subconClaimListing?: Maybe<Scalars['JSON']>;
  materialDOListing?: Maybe<Scalars['JSON']>;
  labourDOListing?: Maybe<Scalars['JSON']>;
  plantDOListing?: Maybe<Scalars['JSON']>;
  miscDOListing?: Maybe<Scalars['JSON']>;
  voSum?: Maybe<Scalars['Float']>;
  origContractSum?: Maybe<Scalars['Float']>;
  revisedContractSum?: Maybe<Scalars['Float']>;
  totalProgressClaim?: Maybe<Scalars['Float']>;
  totalClaimSum?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  subcontractClaimSum?: Maybe<Scalars['Float']>;
  totalSubconSum?: Maybe<Scalars['Float']>;
};

export type ProjectPlanningEntity = AuditEntity & {
  __typename?: 'ProjectPlanningEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  projectSum: Scalars['Float'];
  totalIncome: Scalars['Float'];
  totalCost: Scalars['Float'];
};

export type PublicHolidayDeleteInput = {
  ID: Scalars['String'];
};

export type PublicHolidayEntity = AuditEntity & {
  __typename?: 'PublicHolidayEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  calendarConnection?: Maybe<Array<CalendarPhPolicyEntity>>;
  commonStatus: CommonStatus;
};

export type PublicHolidayInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};

export type PurchaseActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  purchaseStatus: PurchaseStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseBudgetInput = {
  remarks: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseOrderEntity = AuditEntity & {
  __typename?: 'PurchaseOrderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  siteID?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<SiteEntity>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontract?: Maybe<SubcontractEntity>;
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<ContactPerson>;
  exchangeRate: Scalars['Float'];
  totalAmt: Scalars['Float'];
  taxAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  creditTerm: Scalars['Float'];
  instructions?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  prGenerated: Scalars['Boolean'];
  submissionComment?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  purchaseStatus: PurchaseStatus;
  posConnection?: Maybe<Array<DoItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  upToDateAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  estimatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  deliveryStatus?: Maybe<Scalars['JSON']>;
};


export type PurchaseOrderEntityUpToDateAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};


export type PurchaseOrderEntityEstimatedAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type PurchaseOrderInput = {
  supplierID: Scalars['String'];
  totalAmt: Scalars['Float'];
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
  contactPerson?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['JSON']>;
  poItems: Array<PoItemInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseReqEntity = AuditEntity & {
  __typename?: 'PurchaseReqEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontract?: Maybe<SubcontractEntity>;
  siteID?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<SiteEntity>;
  remarks?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  submittedDate?: Maybe<Scalars['String']>;
  prDate?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  requestedBy: Scalars['String'];
  contactPerson: Scalars['JSON'];
  instructions: Scalars['String'];
  submissionComment?: Maybe<Scalars['String']>;
  purchaseStatus: PurchaseStatus;
  prItem?: Maybe<Array<PrItemEntity>>;
  rfq?: Maybe<Array<RfqEntity>>;
  toDo: Array<ContractToDoEntity>;
  purchaseRFQItem: Array<PrItemEntity>;
  /** CustomFieldResolver */
  isOutstanding?: Maybe<Scalars['Boolean']>;
};

export type PurchaseReqInput = {
  purchaseType: PurchaseType;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  contactPerson: ContactPersonInput;
  creditTerm?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  instructions: Scalars['String'];
  submissionComment?: Maybe<Scalars['String']>;
  requestedBy: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};

export enum PurchaseStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export enum PurchaseType {
  Main = 'MAIN',
  Sub = 'SUB'
}

export type PurchaseWbsBudgetAllocationInput = {
  prItemID: Scalars['String'];
  wbsID: Scalars['String'];
};

export type QualificationChecklistEntity = AuditEntity & {
  __typename?: 'QualificationChecklistEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  includeAttachment: Scalars['Boolean'];
  qualificationChecklistItem?: Maybe<Array<QualificationChecklistItemEntity>>;
};

export type QualificationChecklistItemEntity = AuditEntity & {
  __typename?: 'QualificationChecklistItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  qualificationChecklistID: Scalars['String'];
  qualificationChecklist?: Maybe<QualificationChecklistEntity>;
  fieldTypeID: Scalars['String'];
  fieldType?: Maybe<FieldTypeEntity>;
  title: Scalars['String'];
  isCompulsory: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  negoItemInfo: Scalars['JSON'];
  negoItemCount: Scalars['Float'];
  prInfoCount: Scalars['JSON'];
  lastPRDate?: Maybe<Scalars['String']>;
  lastPRDateOB?: Maybe<Scalars['String']>;
  rfqInfo: Scalars['JSON'];
  rfqCount: Scalars['Float'];
  lastRFQDate?: Maybe<Scalars['String']>;
  poInfoCount: Scalars['JSON'];
  lastPODate?: Maybe<Scalars['String']>;
  lastPODateOB?: Maybe<Scalars['String']>;
  doCount: Scalars['Float'];
  lastDODate?: Maybe<Scalars['String']>;
  grtnCount: Scalars['Float'];
  lastGRTNDate?: Maybe<Scalars['String']>;
  getContractPLAdjustmentTotal: Scalars['Float'];
  getSubcontractPLAdjustmentTotal: Scalars['Float'];
  getSupplierPLAdjustmentTotal: Scalars['Float'];
  getSubcontractPLAdjustmentStatusTotal: Scalars['Float'];
  getContractPLAdjustmentStatusTotal: Scalars['Float'];
  getSupplierPLAdjustmentStatusTotal: Scalars['Float'];
  getAdjustmentByCostClass: Scalars['JSON'];
  budgetingCalculation?: Maybe<Scalars['JSON']>;
  costingCalculation?: Maybe<Scalars['JSON']>;
  getAdvanceClient: Scalars['JSON'];
  getPaidSubconSupplierAmount: Scalars['JSON'];
  outstandingReceivableFromClientListing?: Maybe<Scalars['JSON']>;
  outstandingPayableSubconListing?: Maybe<Scalars['JSON']>;
  outstandingPayableSupplierListing?: Maybe<Scalars['JSON']>;
  paidSubconSupplierListing: Scalars['JSON'];
  advanceClient: AdvanceFromClient;
  receivedFromClient: Scalars['JSON'];
  paidToSubCon: Scalars['JSON'];
  paidToSupplier: Scalars['JSON'];
  advancesOutflowAmt: Scalars['JSON'];
  miscExpense: Scalars['JSON'];
  osReceivableFromClient: Array<ContractClaimEntity>;
  clientLastReceipt?: Maybe<Scalars['DateTime']>;
  subconLastPayment?: Maybe<Scalars['DateTime']>;
  receivableClientLastPayment?: Maybe<Scalars['DateTime']>;
  outstandingAmtClient?: Maybe<Scalars['JSON']>;
  paidSubconSupplierAmount: Scalars['JSON'];
  OSReceivableFromClientListing: Scalars['JSON'];
  subconOSPayableAmount: Scalars['JSON'];
  OSPayableToSubconListing: Scalars['JSON'];
  supplierLastPayment?: Maybe<Scalars['DateTime']>;
  supplierOSPayableAmount: Scalars['JSON'];
  OSPayableToSupplierListing: Scalars['JSON'];
  outstandingReceivableFromClient?: Maybe<Scalars['JSON']>;
  outstandingPayableSubcon?: Maybe<Scalars['JSON']>;
  outstandingPayableSupplier?: Maybe<Scalars['JSON']>;
  associateCalculation: AssociatedDetails;
  getProjectCostingDetail: Scalars['JSON'];
  getCostingCalculation: Scalars['JSON'];
  costCategCount: Scalars['JSON'];
  adjustmentByCostClass: Scalars['JSON'];
  adjustmentItemByCostCateg: Scalars['JSON'];
  APInvoiceWoDOByCostItem: Scalars['JSON'];
  getProjectCostingAmt: Scalars['JSON'];
  getAdjustmentItemByCostCateg: Scalars['JSON'];
  getCostCategCount: Scalars['JSON'];
  getPOsByCategory: Array<PurchaseOrderEntity>;
  getGRTNsByCategoryDO: Array<GoodReturnNoteEntity>;
  getDOsByCategory: Array<DoEntity>;
  getAPInvoiceWoDOByCostItem: Scalars['JSON'];
  getPCYear: Array<Scalars['Float']>;
  getReportYear: Array<Scalars['Float']>;
  getCommCostAmt: Scalars['JSON'];
  financialStatus: FinancialStatusDetails;
  getWbsParentChildrens: Scalars['JSON'];
  projectLedgerWbsRevenueSummary: Scalars['JSON'];
  projectLedgerWbsRetentionSummary: Scalars['JSON'];
  projectLedgerWbsCostSummary: Scalars['JSON'];
  getCostCategoryReport: Array<CostCategoryEntity>;
  getCostItemReport: Array<CostItemEntity>;
  projectPL: ProjectPlDetails;
  getBQ: Array<BqEntity>;
  getCheckingValidationBQ: Scalars['JSON'];
  getBQWbsParentChild: Array<BqEntity>;
  getBQParentChild: Array<BqEntity>;
  getBQWbsAssignment: Array<BqWbsAssignmentEntity>;
  getContractCalendarInfo: Scalars['JSON'];
  getSubconCalendarInfo: Scalars['JSON'];
  getPPCalendarInfo: Scalars['JSON'];
  getBillInterest: Array<BillInterestEntity>;
  getStatementAcctMonths: Scalars['JSON'];
  getClaimRecListByMonth: Scalars['JSON'];
  getCustContractIDs: Scalars['JSON'];
  getClientDebitDocListing: Scalars['JSON'];
  getClientDebitDocEInvoice: Scalars['JSON'];
  getPaymentReminder: Array<PaymentReminderEntity>;
  getDocReminder: Scalars['JSON'];
  getPaymentReminderClaim: Scalars['JSON'];
  getPaymentReminderByCompany: Scalars['JSON'];
  getDebitDoc: Scalars['JSON'];
  getPaymentReminderConCust: Scalars['JSON'];
  getDocReminderDetails: Scalars['JSON'];
  getPaymentReminderLastDate: Scalars['JSON'];
  getPrintReminder: Scalars['JSON'];
  getCustomerReminder: Array<CustomerReminderEntity>;
  getContract: Array<ContractEntity>;
  getCustomContract: Array<ContractEntity>;
  contractVoCalculation: Scalars['JSON'];
  maxRetention: Scalars['Float'];
  claimChecking: Scalars['Boolean'];
  voChecking: Scalars['Boolean'];
  retentionChecking: Scalars['Boolean'];
  validateContractStatus?: Maybe<Scalars['Boolean']>;
  getContractWithoutPerm: Array<ContractEntity>;
  getContractEntityWithAccount: Array<ContractEntity>;
  getContractWithAccount: Scalars['JSON'];
  getContractTitleWithAccount: Scalars['JSON'];
  getContractWithActiveDO: Array<ContractEntity>;
  checkWbsExist: Scalars['Boolean'];
  getUserRoleAssigned: Array<RoleEntity>;
  getContractGuarantee: Array<ContractGuaranteeEntity>;
  getContractInsurance: Array<ContractInsuranceEntity>;
  getProjectBudget: Array<ProjectBudgetEntity>;
  deleteBudgetChecking: Scalars['JSON'];
  budgetCalculation?: Maybe<Scalars['JSON']>;
  getContractVO: Array<ContractVoEntity>;
  getVOHealthIndex: VoHealthIndex;
  getContractAllocation: Array<ContractAllocationEntity>;
  getContractAdvanceAllocation: Scalars['JSON'];
  getContractReceiptAllocation: Scalars['JSON'];
  getContractRefundAllocation: Scalars['JSON'];
  getClaimAllocationLedgers: Scalars['JSON'];
  getContractRetentionClaimAllocation: Scalars['JSON'];
  getContractRetClaimAllocation: Scalars['JSON'];
  getContractCNAllocation: Scalars['JSON'];
  getContractDNAllocation: Scalars['JSON'];
  getContractMiscInvoiceAllocation: Scalars['JSON'];
  getClientCNAllocation: Scalars['JSON'];
  getClientDNAllocation: Scalars['JSON'];
  getContractClaim: Array<ContractClaimEntity>;
  getContractClaimCount: Scalars['Float'];
  getRetentionClaim: Array<ContractClaimEntity>;
  fetchUnallocatedClaimRetention: Scalars['JSON'];
  getProgressClaim: Array<ContractClaimEntity>;
  getContractAdvance: Array<ContractAdvanceEntity>;
  getContractAdvanceCount: Scalars['Float'];
  getContractReceipt: Array<ContractReceiptEntity>;
  getContractReceiptCount: Scalars['Float'];
  getContractRefund: Array<ContractRefundEntity>;
  getContractRefundCount: Scalars['Float'];
  getContractMiscInvoice: Array<ContractMiscInvoiceEntity>;
  getContractMiscInvoiceCount: Scalars['Float'];
  getContractDN: Array<ContractDnEntity>;
  getContractDNCount: Scalars['Float'];
  getContractCN: Array<ContractCnEntity>;
  getContractCNCount: Scalars['Float'];
  getClientDN: Array<ClientDnEntity>;
  getClientDNCount: Scalars['Float'];
  getClientCN: Array<ClientCnEntity>;
  getClientCNCount: Scalars['Float'];
  getCNRevenue: Array<ContractCnEntity>;
  getCostCode: Array<CostCodeEntity>;
  getCustomerCompanyAssignment: Array<CustomerCompanyAssignmentEntity>;
  getCustomerByCompany: Array<CustomerEntity>;
  getCustomer: Array<CustomerEntity>;
  getClientAccounts: Array<CustomerEntity>;
  getFollowUp: Array<FollowUpEntity>;
  getCBExport: Array<CbExportEntity>;
  getContractCB?: Maybe<Scalars['JSON']>;
  getContractCBInfo?: Maybe<Scalars['JSON']>;
  getGLTaxType: Array<Scalars['JSON']>;
  getGLAdjustmentType: Array<Scalars['JSON']>;
  getGLExport: Array<GlExportEntity>;
  GLMonths: Array<GlMonth>;
  getContractGL?: Maybe<Scalars['JSON']>;
  getSubconGL?: Maybe<Scalars['JSON']>;
  getSupplierGL?: Maybe<Scalars['JSON']>;
  getPurchaseGL?: Maybe<Scalars['JSON']>;
  getPOBGL?: Maybe<Scalars['JSON']>;
  getGLClaimDetailLedger: Scalars['JSON'];
  getGLDetailLedger: Scalars['JSON'];
  getGRTNDetailLedger: Scalars['JSON'];
  getDODetailLedger: Array<DoEntity>;
  getBatchGLDetailLedger: Scalars['JSON'];
  checkedGLEditable: Array<Scalars['String']>;
  getGLSetupFile: Scalars['JSON'];
  getGLAssociationFile: Scalars['JSON'];
  getGLTransactionFile: Scalars['JSON'];
  getGLInterfaceMaster: Array<GlInterfaceMasterEntity>;
  getGLInterfaceDetail: Array<GlInterfaceDetailEntity>;
  getGLTaxDynamic: Array<GlTaxDynamicEntity>;
  getGLAdjustmentDynamic: Array<GlAdjustmentDynamicEntity>;
  getGroupBiAccountReceivableSummary?: Maybe<Scalars['JSON']>;
  getGroupBiAccountReceivableDialog: Scalars['JSON'];
  getGroupBiCashFlowSummary: Scalars['JSON'];
  getGroupBiCashFlowDialog: Scalars['JSON'];
  getGroupBiKeyApiSummary: Scalars['JSON'];
  getGroupBiKeyApiDialogSummary: Scalars['JSON'];
  getGroupBiKeyKpiClaim: Scalars['JSON'];
  getGroupBiKeyKpiCollection: Scalars['JSON'];
  getGroupBiProjectCostingSummary: Scalars['JSON'];
  getGroupBIProfitLostSummary: Scalars['JSON'];
  getGroupBiSite: Scalars['JSON'];
  getGroupBISiteGallery: Scalars['JSON'];
  getGroupBiVoSummary?: Maybe<GroupBizVo>;
  getGroupBizVO?: Maybe<Scalars['JSON']>;
  getGroupBizVoSubmenu?: Maybe<Scalars['JSON']>;
  getGuaranteeType: Array<GuaranteeTypeEntity>;
  getInsuranceType: Array<InsuranceTypeEntity>;
  getMiscExpense: Array<MiscExpenseEntity>;
  getMiscExpenseCount: Scalars['Float'];
  miscExpenseBudgetChecking: BudgetInfo;
  getCostCenter: Array<CostCenterEntity>;
  getCurrencyExchange: Array<CurrencyExchangeEntity>;
  getCurrency: Array<CurrencyEntity>;
  getConTaxScheme: Array<ConTaxSchemeEntity>;
  getConTaxSchemeWithPerm: Array<ConTaxSchemeEntity>;
  getConTaxEffectiveDate: Array<ConTaxEffectiveDateEntity>;
  getConTaxType: Array<ConTaxTypeEntity>;
  verifyTaxType: Scalars['String'];
  getTaxCodeGLExport: Scalars['JSON'];
  getFilterTaxCode: Scalars['JSON'];
  getProfitRecogFormula: Array<ProfitRecogFormulaEntity>;
  getProfitRecogParameter: Array<ProfitRecogParameterEntity>;
  getProfitRecogAssignment: Array<ProfitRecogAssignmentEntity>;
  getProfitRecogGeneration: Scalars['JSON'];
  getProfitRecogGenParamAssignment: Array<ProfitRecogGenParamAssgmtEntity>;
  getContractListingProfitRecog: Scalars['JSON'];
  profitRecognitionCalculation: Scalars['JSON'];
  C1_actualCost: Scalars['Float'];
  C2_budgetCost: Scalars['Float'];
  C3_actualRevenue: Scalars['Float'];
  C4_reviseContractSum: Scalars['Float'];
  C5_totalPreviousRecognisedRevenue: Scalars['Float'];
  C6_totalPreviousRecognisedCost: Scalars['Float'];
  C7_percOfCompletion: Scalars['Float'];
  getProfitRecognition: Array<ProfitRecogFormulaEntity>;
  infoLineProfitRecog: Scalars['JSON'];
  getProfitRecogGenerationListing: Scalars['JSON'];
  getProfitRecogGLAccountCode: Array<ProfitRecogGlAccountCodeEntity>;
  getProfitRecogGLJournal: Array<ProfitRecogGlJournalEntity>;
  getProjectExpense: Array<ProjectExpenseEntity>;
  getProjectExpenseItem: Array<ProjectExpenseItemEntity>;
  getProjectExpenseCount: Scalars['Float'];
  getProjectOpportunityAttachment: Array<ProjectOpportunityAttachmentEntity>;
  getProjectOpportunity: Array<ProjectOpportunityEntity>;
  getProjectOpportunitySubmission: Array<ProjectOpportunitySubmissionEntity>;
  getCheckingValidationWBSBudget: Scalars['JSON'];
  getWbsBudget: Array<WbsBudgetEntity>;
  getBudget: Array<WbsBudgetEntity>;
  checkWBSBudgetApproved: Scalars['Boolean'];
  getWbsBudgetDetail: Array<WbsBudgetDetailEntity>;
  getWbsBudgetDetailScheduling: Array<WbsBudgetDetailScheduleEntity>;
  getWbsDayworkCostRevenue: Array<WbsDayworkCostRevenueEntity>;
  getTotalCostRevenue: Scalars['JSON'];
  getCostWBSBudgetDetail: Scalars['JSON'];
  getRevenueWBSBudgetDetail: Scalars['JSON'];
  getWbsBudgetDetailByClass: Scalars['JSON'];
  checkWbsBudgetDetail: WbsBudgetDetailEntity;
  checkMultipleWbsBudgetDetail: Scalars['JSON'];
  checkSubcontractMultipleWbsBudgetDetail: Scalars['JSON'];
  checkExistCostRevenue: Scalars['Boolean'];
  getCostCategoryWbsBudget: Scalars['JSON'];
  getWBSBudgetTransferHeader: Array<WbsBudgetTransferHeaderEntity>;
  getWBSBudgetTransferDetail: Array<WbsBudgetTransferDetailEntity>;
  getWBSParentChild: Scalars['JSON'];
  WBSBudgetTransferResource: Scalars['JSON'];
  getWbsBudgetTransfer: Scalars['JSON'];
  getBudgetTransferCostCategoryBalance: Scalars['JSON'];
  getWBS: Array<WbsEntity>;
  getWbsTotalCostRevenue: Scalars['JSON'];
  getWbsCostAllocationByClass: Scalars['JSON'];
  getMultipleWBS: Array<WbsEntity>;
  getWbsSchedule: Array<WbsScheduleEntity>;
  getWBSUtilizedBudget: Scalars['JSON'];
  getCostCategoryFromWbs: Scalars['JSON'];
  getDrawerPermission: Scalars['JSON'];
  getSiteDrawerPermission: Scalars['JSON'];
  Check: Scalars['Boolean'];
  UserEntities: Array<Scalars['String']>;
  testPermission: Scalars['String'];
  siteTestPermission: Scalars['String'];
  testConPermission: Scalars['String'];
  testSampleEnum: Scalars['String'];
  checkDocExist?: Maybe<Array<DocumentsEntity>>;
  DocumentListing?: Maybe<Array<DocumentsEntity>>;
  cmgdAttachment?: Maybe<Array<DocumentsEntity>>;
  totalDigitalDoc: Scalars['Float'];
  auditLogDetails: Scalars['JSON'];
  getCashFlowLineChartData: Scalars['JSON'];
  getCashFlowGraphData: Scalars['JSON'];
  getContractNews: Array<ContractNewsEntity>;
  getContractNewsRead: Array<ContractNewsReadEntity>;
  getCollectionActivity: Array<CollectionActivityEntity>;
  getCompanyHOR: Array<CompanyEntity>;
  getCompany: Array<CompanyEntity>;
  getRawCompany: Array<SubscriptionCompanyType>;
  getSubscriptionCurrency: Array<SubscriptionCurrencyType>;
  getContact: Array<ContactEntity>;
  getAdjustmentType: Array<AdjustmentTypeEntity>;
  getDocumentFolder?: Maybe<Array<DocumentFolderEntity>>;
  totalDocFolder: Scalars['Float'];
  getLocalBank: Array<LocalBankEntity>;
  getProfitRecogFormulaScript: Scalars['String'];
  getDate: Scalars['JSON'];
  submittedRetClaimChecking: Scalars['JSON'];
  submittedClaimChecking?: Maybe<Scalars['JSON']>;
  approvedClaimChecking: ClaimSummary;
  cancellableClaims: Array<Scalars['String']>;
  approvableLedgerChecking: LedgerCheckingSummary;
  getBatchPaymentDetail: Array<BatchPaymentDetailEntity>;
  getBatchPaymentDetailbySupplier: Scalars['JSON'];
  getBatchPaymentHeader: Array<BatchPaymentHeaderEntity>;
  getBatchPaymentbyStatus: Array<BatchPaymentHeaderEntity>;
  getBatchPaymentbyStatusCount: Scalars['Float'];
  getContractLedgers: Scalars['JSON'];
  getSubcontractorLedgers: Scalars['JSON'];
  getSupplierLedgers: Scalars['JSON'];
  getSiteMaterialPolicy: Array<SiteMaterialPolicyEntity>;
  getSiteMaterialContractPolicy: Array<SiteMaterialContractPolicyEntity>;
  getDefaultMaterialPolicyIDs: Array<Scalars['String']>;
  getSiteMaterialPolicyChecker: Scalars['JSON'];
  calendarTypeDate?: Maybe<Scalars['DateTime']>;
  holidayTypeDate?: Maybe<Scalars['DateTime']>;
  jobTypeDate?: Maybe<Scalars['DateTime']>;
  projectSiteDate?: Maybe<Scalars['DateTime']>;
  publicHolidayDate?: Maybe<Scalars['DateTime']>;
  siteMaterialDate?: Maybe<Scalars['DateTime']>;
  wagesRatePolicyDate?: Maybe<Scalars['DateTime']>;
  workingHourPolicyDate?: Maybe<Scalars['DateTime']>;
  videoCamDate?: Maybe<Scalars['DateTime']>;
  subconSetupDate?: Maybe<Scalars['DateTime']>;
  workerProfileDate?: Maybe<Scalars['DateTime']>;
  siteMaterialPolicyDate?: Maybe<Scalars['DateTime']>;
  associateMenu: Scalars['JSON'];
  totalClients: Scalars['Float'];
  pendingCollectionActivity?: Maybe<Scalars['JSON']>;
  paymentReminderSummary?: Maybe<Scalars['JSON']>;
  clientNotifications: Scalars['JSON'];
  totalDebitClient: Scalars['Float'];
  totalCreditClient: Scalars['Float'];
  totalOutstandingClients: Scalars['Float'];
  totalOsClientInvoices: ClientSoaInfo;
  totalOutstandingAmtClients?: Maybe<Scalars['Float']>;
  supplierProfileDate?: Maybe<Scalars['DateTime']>;
  PrefSupplierProductDate?: Maybe<Scalars['DateTime']>;
  SupplierAccDate?: Maybe<Scalars['DateTime']>;
  contractInfo: ContractInfo;
  contractGuaranteeInfo: Scalars['JSON'];
  contractInsuranceInfo: Scalars['JSON'];
  submittedVONotification: Scalars['Float'];
  contractVO: ContractVo;
  outstandingAdvances: Array<ContractAdvanceEntity>;
  outstandingClaims: Array<ContractClaimEntity>;
  outstandingMiscInvs: Array<ContractMiscInvoiceEntity>;
  retention: RetentionInfo;
  lastSiteProgressDate?: Maybe<Scalars['DateTime']>;
  wbsBudgetInfo?: Maybe<Scalars['JSON']>;
  wbsBudgetRevisionInfo?: Maybe<Scalars['JSON']>;
  utilizedBudgetInfo?: Maybe<Scalars['JSON']>;
  budgetTransferInfo?: Maybe<Scalars['JSON']>;
  lastBqImportDate?: Maybe<Scalars['DateTime']>;
  bqAssignmentInfo?: Maybe<Scalars['JSON']>;
  miscExpInfo?: Maybe<Scalars['JSON']>;
  lastMiscExpDate?: Maybe<Scalars['DateTime']>;
  lastExtensionDate?: Maybe<Scalars['String']>;
  contractClosureDate?: Maybe<Scalars['DateTime']>;
  subcontractInfo: SubontractInfo;
  subconGuaranteeInfo: Scalars['JSON'];
  subconInsuranceInfo: Scalars['JSON'];
  submittedVOSubconNotification: Scalars['Float'];
  subcontractVO: SubcontractVo;
  subconOutstandingAdvances: Array<SubcontractAdvanceEntity>;
  subconOutstandingClaims: Array<SubcontractClaimEntity>;
  subconLastExtensionDate?: Maybe<Scalars['String']>;
  subconClosureDate?: Maybe<Scalars['DateTime']>;
  latestDefectDate?: Maybe<Scalars['DateTime']>;
  latestAssignmentDate?: Maybe<Scalars['DateTime']>;
  siteWorkerAttendanceInfo: Scalars['JSON'];
  MOSCount: Scalars['Float'];
  glExportDate?: Maybe<Scalars['DateTime']>;
  cbExportDate?: Maybe<Scalars['DateTime']>;
  costCategoryDates?: Maybe<Scalars['JSON']>;
  costItemDates?: Maybe<Scalars['JSON']>;
  costCategoryDate?: Maybe<Scalars['DateTime']>;
  costItemDate?: Maybe<Scalars['DateTime']>;
  revenueCategoryDate?: Maybe<Scalars['DateTime']>;
  guaranteeTypeDate?: Maybe<Scalars['DateTime']>;
  insuranceTypeDate?: Maybe<Scalars['DateTime']>;
  workshopDate?: Maybe<Scalars['DateTime']>;
  paymentReminderDate?: Maybe<Scalars['DateTime']>;
  approvalPolicyDate?: Maybe<Scalars['DateTime']>;
  approvalPolicyWithContractDate?: Maybe<Scalars['DateTime']>;
  supplierTypeDate?: Maybe<Scalars['DateTime']>;
  subcontractorTypeDate?: Maybe<Scalars['DateTime']>;
  glAccountCodeDate?: Maybe<Scalars['DateTime']>;
  clientSetupDate?: Maybe<Scalars['DateTime']>;
  supplierSetupDate?: Maybe<Scalars['DateTime']>;
  clientAccDate?: Maybe<Scalars['DateTime']>;
  subconAccDate?: Maybe<Scalars['DateTime']>;
  supplierAccDate?: Maybe<Scalars['DateTime']>;
  purchaseAccDate?: Maybe<Scalars['DateTime']>;
  pobAccDate?: Maybe<Scalars['DateTime']>;
  bankAccDate?: Maybe<Scalars['DateTime']>;
  taxAccDate?: Maybe<Scalars['DateTime']>;
  tradeAccDate?: Maybe<Scalars['DateTime']>;
  materialAccDate?: Maybe<Scalars['DateTime']>;
  plantAccDate?: Maybe<Scalars['DateTime']>;
  labourAccDate?: Maybe<Scalars['DateTime']>;
  miscAccDate?: Maybe<Scalars['DateTime']>;
  departmentDate?: Maybe<Scalars['DateTime']>;
  journalAccDate?: Maybe<Scalars['DateTime']>;
  userDate: Scalars['DateTime'];
  roleDate?: Maybe<Scalars['DateTime']>;
  conAuthDate?: Maybe<Scalars['DateTime']>;
  conAuthCount: Scalars['Float'];
  docNumDate?: Maybe<Scalars['DateTime']>;
  bankAccountDate?: Maybe<Scalars['DateTime']>;
  taxSchemeDate?: Maybe<Scalars['DateTime']>;
  uomDate?: Maybe<Scalars['DateTime']>;
  uomConversionDate?: Maybe<Scalars['DateTime']>;
  companyDate?: Maybe<Scalars['DateTime']>;
  policyDate?: Maybe<Scalars['DateTime']>;
  notificationDate?: Maybe<Scalars['DateTime']>;
  costCenterDate?: Maybe<Scalars['DateTime']>;
  currencyExchangeDate?: Maybe<Scalars['DateTime']>;
  currencySetupDate?: Maybe<Scalars['DateTime']>;
  activeClientCount: Scalars['Float'];
  clientCount: Scalars['JSON'];
  contractCount: Scalars['JSON'];
  activeContractCount: Scalars['Float'];
  activeSiteCount: Scalars['Float'];
  subcontractCount: Scalars['JSON'];
  activeSubcontractCount: Scalars['Float'];
  activeSubcontractTenderCount: Scalars['Float'];
  subcontractorCount: Scalars['JSON'];
  activeSubcontractorCount: Scalars['Float'];
  supplierCount: Scalars['JSON'];
  activeSupplierCount: Scalars['Float'];
  activePlantMachineryCount: Scalars['Float'];
  lastPurchaseDate?: Maybe<Scalars['DateTime']>;
  projectPurchaseCount?: Maybe<Scalars['JSON']>;
  fuelInfo: Scalars['JSON'];
  ownedPNMInfo: Scalars['JSON'];
  rentedPNMInfo: Scalars['JSON'];
  assignmentRecord: Scalars['Float'];
  maintenanceRecord: Scalars['Float'];
  fuelTankCount: Scalars['JSON'];
  clientProfileDate: Scalars['String'];
  submittedCusAVNotification: Scalars['Float'];
  advancesInfo?: Maybe<Scalars['JSON']>;
  claimsInfo?: Maybe<Scalars['JSON']>;
  submittedClaimNotification: Scalars['Float'];
  progressClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedRetClaimNotification: Scalars['Float'];
  retentionClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedCNNotification: Scalars['Float'];
  CNInfo?: Maybe<Scalars['JSON']>;
  submittedDNNotification: Scalars['Float'];
  DNInfo?: Maybe<Scalars['JSON']>;
  submittedMiscInvNotification: Scalars['Float'];
  miscInvInfo?: Maybe<Scalars['JSON']>;
  submittedClientDNNotification: Scalars['Float'];
  clientDNInfo?: Maybe<Scalars['JSON']>;
  submittedClientCNNotification: Scalars['Float'];
  clientCNInfo?: Maybe<Scalars['JSON']>;
  submittedReceiptNotification: Scalars['Float'];
  receiptsInfo?: Maybe<Scalars['JSON']>;
  submittedRefundNotification: Scalars['Float'];
  refundsInfo?: Maybe<Scalars['JSON']>;
  outstandingClient?: Maybe<Scalars['JSON']>;
  unallocatedClient?: Maybe<Scalars['JSON']>;
  conClientProfileDate: Scalars['DateTime'];
  conAdvancesInfo?: Maybe<Scalars['JSON']>;
  conClaimsInfo?: Maybe<Scalars['JSON']>;
  conDNInfo?: Maybe<Scalars['JSON']>;
  conCNInfo?: Maybe<Scalars['JSON']>;
  conReceiptsInfo?: Maybe<Scalars['JSON']>;
  conRefundsInfo?: Maybe<Scalars['JSON']>;
  conClientDNInfo?: Maybe<Scalars['JSON']>;
  conOutstandingClient?: Maybe<Scalars['JSON']>;
  subconProfileDate?: Maybe<Scalars['String']>;
  subconLedgersInfo?: Maybe<Scalars['JSON']>;
  submittedSubconAVNotification: Scalars['Float'];
  subconAdvancesInfo?: Maybe<Scalars['JSON']>;
  subconClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconClaimNotification: Scalars['Float'];
  subconProgressClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconRetClaimNotification: Scalars['Float'];
  subconRetentionClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconMiscInvNotification: Scalars['Float'];
  subconMiscInvInfo?: Maybe<Scalars['JSON']>;
  submittedSubconDNNotification: Scalars['Float'];
  subconDNInfo?: Maybe<Scalars['JSON']>;
  submittedSubconCNNotification: Scalars['Float'];
  subconCNInfo?: Maybe<Scalars['JSON']>;
  submittedPaymentNotification: Scalars['Float'];
  subconPaymentInfo?: Maybe<Scalars['JSON']>;
  submittedSubconRefundNotification: Scalars['Float'];
  subconRefundsInfo?: Maybe<Scalars['JSON']>;
  submittedDNtoSubconNotification: Scalars['Float'];
  DNtoSubconInfo?: Maybe<Scalars['JSON']>;
  submittedCNtoSubconNotification: Scalars['Float'];
  CNtoSubconInfo?: Maybe<Scalars['JSON']>;
  outstandingSubcon?: Maybe<Scalars['JSON']>;
  unallocatedSubcon?: Maybe<Scalars['JSON']>;
  suppProfileDate: Scalars['DateTime'];
  submittedDepositNotification: Scalars['Float'];
  supplierDepositsInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierInvNotification: Scalars['Float'];
  submittedSupplierInvWoDoNotification: Scalars['Float'];
  supplierInvInfo?: Maybe<Scalars['JSON']>;
  supplierInvWoDoInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierDNNotification: Scalars['Float'];
  supplierDNInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierCNNotification: Scalars['Float'];
  supplierCNInfo?: Maybe<Scalars['JSON']>;
  submittedDNToSupplierNotification: Scalars['Float'];
  DNtoSupplierInfo?: Maybe<Scalars['JSON']>;
  submittedCNToSupplierNotification: Scalars['Float'];
  CNtoSupplierInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierPaymentNotification: Scalars['Float'];
  supplierPaymentsInfo?: Maybe<Scalars['JSON']>;
  supplierBatchPaymentsInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierRefundNotification: Scalars['Float'];
  supplierRefundsInfo?: Maybe<Scalars['JSON']>;
  outstandingSupplier?: Maybe<Scalars['JSON']>;
  unallocatedSupplier?: Maybe<Scalars['JSON']>;
  lastStockMovementDate?: Maybe<Scalars['DateTime']>;
  totalSiteCount: Scalars['Float'];
  totalStockIn: Scalars['Float'];
  totalStockOut: Scalars['Float'];
  totalDefect: Scalars['Float'];
  totalSiteSurveillance: Scalars['Float'];
  todayTotalAttendance: Scalars['JSON'];
  workerCount?: Maybe<Scalars['Float']>;
  lastGRNDate?: Maybe<Scalars['DateTime']>;
  stockMovementCount: Scalars['JSON'];
  lastStockMovementDates?: Maybe<Scalars['JSON']>;
  siteImagesCount: Scalars['JSON'];
  videoCamCount: Scalars['Float'];
  workerAttendanceCount?: Maybe<Scalars['JSON']>;
  getDailyRegisteredWorkHours?: Maybe<Scalars['JSON']>;
  getDailyWagesVerification?: Maybe<Scalars['JSON']>;
  getWagesBook?: Maybe<Scalars['JSON']>;
  wageCycleClosureInfo?: Maybe<Scalars['JSON']>;
  getTax: TaxEntity;
  getTaxListing: Array<TaxListingOutput>;
  getTaxSchemeTypeListing: Array<TaxSchemeTypeListingOutput>;
  getTaxSchemeTypeSortBy: Array<TaxSchemeTypeSortByOutput>;
  getTaxScheme: Array<TaxSchemeEntity>;
  getTaxSchemeWithPerm: Array<TaxSchemeEntity>;
  getTaxSchemeDetail: Array<TaxSchemeDetailEntity>;
  getTaxSchemeDetailWithPerm: Array<TaxSchemeDetailEntity>;
  getTaxSchemeDetailItem: Array<TaxSchemeDetailItemEntity>;
  getTaxSchemeDetailItemWithPerm: Array<TaxSchemeDetailEntity>;
  getContractNotification: Array<ContractNotificationEntity>;
  getNotificationListing: Array<Scalars['JSON']>;
  getStandardParameter: Array<Scalars['JSON']>;
  testImagePath: Scalars['JSON'];
  getRole: Array<RoleEntity>;
  getRoleSetup: Array<RoleEntity>;
  getEntityRoleUserAssignment: Array<RoleUserEntAssignmentEntity>;
  getPolicyRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  getContractPermission: Scalars['JSON'];
  getSitePermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Scalars['JSON'];
  getRoleMatrix: Scalars['JSON'];
  getRoleMatrixByIds: Scalars['JSON'];
  getRoleByIds: Array<RoleEntity>;
  getAlert: Array<AlertEntity>;
  getAlertList: Array<AlertEntity>;
  getShowAlertSQL: Scalars['JSON'];
  getAlertAssignment: Array<AlertAssignmentEntity>;
  getApprovalPolicyGuideline: Array<ApprovalPolicyEntity>;
  getApprovalPolicyAssignmentGuideline: Array<ApprovalPolicyAssignmentEntity>;
  getApprovalPolicy?: Maybe<Array<ApprovalPolicyEntity>>;
  getApprovalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  getBankAccount: Array<BankAccountEntity>;
  getBankAccountWithPerm: Array<BankAccountEntity>;
  getCalendar: Array<CalendarEntity>;
  getCalendarPHPolicy: Array<CalendarPhPolicyEntity>;
  getCalendarWeekendPolicy: Array<CalendarWeekendPolicyEntity>;
  logoView?: Maybe<DocumentsEntity>;
  WatermarkView: DocumentsEntity;
  getCostCategory: Array<CostCategoryEntity>;
  getActivePNMCostCategory: Array<CostCategoryEntity>;
  getCostCategoryByClass: Scalars['JSON'];
  getMapCostCategory: Scalars['JSON'];
  getParentChildrenCostCategory: Scalars['JSON'];
  getCostCategoryTree: Scalars['JSON'];
  getSubcontractTradeCostCategory: Scalars['JSON'];
  getNonSelectedCostCategory: Scalars['JSON'];
  getCostItem: Array<CostItemEntity>;
  getCostItemCount: Scalars['Float'];
  getCostItemBySupplier: Array<CostItemEntity>;
  getItemByClass: Array<CostItemEntity>;
  getCostItemByClasses: Array<CostItemEntity>;
  getCostItemPagination: Array<CostItemEntity>;
  getCostCategoryItem: Scalars['JSON'];
  getCostItemByWbs: Scalars['JSON'];
  getMapCostItem: Scalars['JSON'];
  getCostItemByWbsIDs: Scalars['JSON'];
  checkingBudgetQty: Scalars['JSON'];
  getCurrencySetup: Array<CurrencyNoteEntity>;
  getDigitalDocument: Scalars['JSON'];
  getTableDocuments: Array<DigitalDocuments>;
  totalDigitalDocuments: Scalars['JSON'];
  getNumberFormat: Array<ConNumberFormatEntity>;
  getConDocType: Array<ConDocTypeEntity>;
  getDocumentType: Array<ConDocTypeEntity>;
  getDocType: Array<Scalars['JSON']>;
  fetchNextDocNum: Scalars['String'];
  fetchMultipleDocNum: Scalars['JSON'];
  getRunningNumberSetupWithLock: Scalars['Float'];
  /** if true, runningNum is editable */
  runningNumChecking: Scalars['Boolean'];
  projMiscExpRunNumChecking: Scalars['Boolean'];
  getDocNumHeader: Array<DocNumHeaderEntity>;
  getDocNumDetail: Array<DocNumDetailEntity>;
  getSysAdminDocType: Array<Scalars['JSON']>;
  getDocumentNumberHeaderAndDetail: Array<DocNumDetailEntity>;
  getClassificationCode: Array<EInvoiceClassificationEntity>;
  getClassificationCodeCount: Scalars['Float'];
  getGeneralTin: Array<EInvoiceGeneralTinEntity>;
  getGeneralTinCount: Scalars['Float'];
  getMsicCode: Array<EInvoiceMsicEntity>;
  getMsicCodeCount: Scalars['Float'];
  getHolidayType: Array<HolidayTypeEntity>;
  getJobType: Array<JobTypeEntity>;
  loggedInPlatformUserProfile: PlatformUserEntity;
  activePlatformUser: Scalars['Float'];
  isPlatformUserValid: Scalars['Boolean'];
  getPolicyGuideLine: Array<PolicyGuidelineEntity>;
  getPolicyRoleAssignment: Array<PolicyRoleAssignmentEntity>;
  getNotificationGuideLine: Array<NotificationGuidelineEntity>;
  getNotiRoleUserAssignment: Array<NotiRoleUserAssignmentEntity>;
  getPublicHoliday: Array<PublicHolidayEntity>;
  getPublicHolidayByStateYear: Scalars['JSON'];
  getRevenueCategory: Array<RevenueCategoryEntity>;
  getRevenueCategoryCount: Scalars['Float'];
  getSAccount: Array<SAccountEntity>;
  getSiteMaterial: Array<SiteMaterialEntity>;
  getSiteWorkers: Array<WorkerEntity>;
  getSubcontractorType: Array<SubcontractorTypeEntity>;
  getSupplierType: Array<SupplierTypeEntity>;
  getUOM: Array<UomEntity>;
  getUOMWithPerm: Array<UomEntity>;
  getUOMCount: Scalars['Float'];
  getUOMWithPermCount: Scalars['Float'];
  getUOMExchange: Array<UomExchangeEntity>;
  getUOMExchangeWithPerm: Array<UomExchangeEntity>;
  getUomExchangeData: Scalars['JSON'];
  getUOMExchangeList: Scalars['JSON'];
  getUOMExchangeListByWbs: Scalars['JSON'];
  getUserCompanyAssignment: Array<UserCompanyAssignmentEntity>;
  submittedByLedger: Scalars['String'];
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getUsersByAccountAndSoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  getVideoCam: Array<VideoCamEntity>;
  getWagesRatePolicy: Array<WagesRatePolicyEntity>;
  getContractToDo: Array<ContractToDoEntity>;
  getToDoListing?: Maybe<Array<Scalars['JSON']>>;
  workDeskCount: Scalars['Float'];
  toDoCount: Scalars['Float'];
  notificationCount: Scalars['Float'];
  getWorker: Array<WorkerEntity>;
  getWorkerSiteAssignment: Array<WorkerSiteAssignmentEntity>;
  getWorkerFromSite: Array<WorkerEntity>;
  checkingWageType: Scalars['Boolean'];
  getWorkerAttendance: Array<WorkerAttendanceEntity>;
  checkWorkFlow: Scalars['JSON'];
  getToDoWorkflowSummary: Scalars['JSON'];
  getToDoAllPendingDocs: Scalars['JSON'];
  policyChecking?: Maybe<Scalars['JSON']>;
  policyCheckerAPPayment?: Maybe<Scalars['JSON']>;
  getNotificationWorkflowListing: Scalars['JSON'];
  getApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  getAllApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  approvableLedgerWFChecking: Scalars['JSON'];
  getWorkingHourPolicy: Array<WorkingHourPolicyEntity>;
  getHORAssignment: Array<AssignmentEntity>;
  getAssignmentCount: Scalars['Float'];
  getAssignment: Array<AssignmentEntity>;
  getFuelDischarge: Array<FuelDischargeEntity>;
  getFuelDischargeCount: Scalars['Float'];
  getFuelBalance: Scalars['Float'];
  getFuelMeterReading: Array<FuelMeterReadingEntity>;
  getFuelMeterReadingCount: Scalars['Float'];
  fuelMeterReadingSummary: Scalars['JSON'];
  getFuelSupply: Array<FuelSupplyEntity>;
  getFuelSupplyCount: Scalars['Float'];
  getFuelTankSetup: Array<TankEntity>;
  getFuelTankSetupCount: Scalars['Float'];
  getHORMaintenance: Array<MaintenanceEntity>;
  getMaintenanceCount: Scalars['Float'];
  getMaintenance: Array<MaintenanceEntity>;
  getPlantMachinery: Array<PlantMachineryEntity>;
  getWorkshop: Array<WorkshopEntity>;
  getWorkshopForm: Array<WorkshopEntity>;
  getPNMRental: Array<PnmRentalEntity>;
  getPNMRentalCount: Scalars['Float'];
  getPNMByStatus: Array<PlantMachineryEntity>;
  getPNMStatus: Scalars['Boolean'];
  getTank: Array<PlantMachineryEntity>;
  getRentalTank: Array<PnmRentalEntity>;
  getSupplyBy: Scalars['JSON'];
  getSupplyDischargeTo: Scalars['JSON'];
  getContractFuel: Scalars['JSON'];
  getDO: Array<DoEntity>;
  getDOItem: Array<DoItemEntity>;
  getDOCount: Scalars['Float'];
  getPOs: Array<Scalars['JSON']>;
  getGRTNs: Scalars['JSON'];
  totalDOAmt: Scalars['Float'];
  getSupplierByDOStatus: Array<SupplierEntity>;
  checkingCancelCloseDO: Scalars['Boolean'];
  getGRTNBySupplier: Array<GoodReturnNoteEntity>;
  getGoodReturnNote: Array<GoodReturnNoteEntity>;
  getGoodReturnNoteItem: Array<GoodReturnNoteItemEntity>;
  getGoodReturnNoteCount: Scalars['Float'];
  getNegotiatedSupply: Array<NegotiatedSupplyEntity>;
  getNegotiatedSupplyItem: Array<NegotiatedSupplyItemEntity>;
  getNegotiatedSupplyCount: Scalars['Float'];
  verifyNegoSupplyStatus: Scalars['Boolean'];
  verifyNegoSupplyItem: Scalars['JSON'];
  getPurchaseOrder: Array<PurchaseOrderEntity>;
  getPOItem: Array<PoItemEntity>;
  getPurchaseOrderCount: Scalars['Float'];
  getPOByStatus: Array<PurchaseOrderEntity>;
  purchaseBudget: Scalars['JSON'];
  purchaseBudgetAllocation: Scalars['JSON'];
  purchaseWbsBudgetAllocation: Scalars['JSON'];
  getSupplierQuotes: Scalars['JSON'];
  closePOChecking: Scalars['Boolean'];
  getPOBySupplier: Array<PurchaseOrderEntity>;
  getSiteBySupplier: Array<SiteEntity>;
  getSupplierByPOStatus: Array<SupplierEntity>;
  getPOContractName: Scalars['JSON'];
  getPOList: Scalars['JSON'];
  getPONo: Scalars['JSON'];
  getPOCategory: Scalars['JSON'];
  getPOItemByCategory: Scalars['JSON'];
  checkingCancelClosePO: Array<Scalars['String']>;
  getPOCostItemList: Scalars['JSON'];
  getPOSupplierList: Scalars['JSON'];
  getPurchaseReq: Array<PurchaseReqEntity>;
  getPurchaseReqCount: Scalars['Float'];
  getPRForBudgetByOne: PurchaseReqEntity;
  GetPRItemsbyIDs: Array<PrItemInfo>;
  getOutstandingPR: Array<PurchaseReqEntity>;
  getOutstandingPRItems: Array<PrItemEntity>;
  checkingCancelClosePR: Array<Scalars['String']>;
  getRFQ: Array<RfqEntity>;
  getRFQItem: Array<RfqItemEntity>;
  getRFQCount: Scalars['Float'];
  getSuggestedSupplier: Array<SupplierEntity>;
  getRFQSupplierInvited: Array<RfqEntity>;
  getRFQSupplierInvitation: Array<RfqSupplierInvitationEntity>;
  getInvitedSupplier?: Maybe<Scalars['JSON']>;
  getRFQSupplierSubmission: Array<RfqSupplierSubmissionEntity>;
  getPRItemByPRIDForRFQ: Array<Scalars['JSON']>;
  getPurchaseReqWithPR: Array<PurchaseReqEntity>;
  getQuotedRFQ: Array<RfqSupplierSubmissionEntity>;
  getSupplierCompanyAssignment: Array<SupplierCompanyAssignmentEntity>;
  getSupplierByCompany: Array<SupplierEntity>;
  getSupplier: Array<SupplierEntity>;
  getSupplierCCategory: Array<SupplierCCategoryEntity>;
  getMultiSupplier: Array<SupplierEntity>;
  getActiveSupplier: Scalars['JSON'];
  getSupplierCostCategory: Scalars['JSON'];
  getSupplierDepositAllocation: Scalars['JSON'];
  getSupplierPaymentAllocation: Scalars['JSON'];
  getDNtoSupplierAllocation: Scalars['JSON'];
  getCNtoSupplierAllocation: Scalars['JSON'];
  getSupplierCNAllocation: Scalars['JSON'];
  getSupplierDNAllocation: Scalars['JSON'];
  getSupplierInvoiceAllocation: Scalars['JSON'];
  getInvoice: Array<ApInvoiceEntity>;
  getAPInvoice: Array<ApInvoiceEntity>;
  getAPInvoiceWoDO: Array<ApInvoiceEntity>;
  getDOforInvoice?: Maybe<Array<DoEntity>>;
  getAPAdvance: Array<ApAdvanceEntity>;
  getAPAdvanceCount: Scalars['Float'];
  getAPPayment: Array<ApPaymentEntity>;
  getAPPaymentCount: Scalars['Float'];
  getAPRefund: Array<ApRefundEntity>;
  getAPRefundCount: Scalars['Float'];
  getSupplierPaymentByContract: Scalars['JSON'];
  getAPDebitNote: Array<ApDebitNoteEntity>;
  getAPDebitNoteCount: Scalars['Float'];
  getAPCreditNote: Array<ApCreditNoteEntity>;
  getAPCreditNoteCount: Scalars['Float'];
  getDNtoSupplier: Array<DNtoSupplierEntity>;
  getDNtoSupplierCount: Scalars['Float'];
  getCNtoSupplier: Array<CNtoSupplierEntity>;
  getCNtoSupplierCount: Scalars['Float'];
  getSupplierCreditDocEInvoice: Scalars['JSON'];
  getSubcontractorInvitation: Array<SubcontractorInvitationEntity>;
  getSuggestedSubcontractor: Array<SubcontractorEntity>;
  getInvitedSubcontractor?: Maybe<Scalars['JSON']>;
  getSubconRFQSubmission: Array<SubconRfqSubmissionEntity>;
  getSubcontractTender: Array<SubcontractTenderEntity>;
  getSubcontractGuarantee: Array<SubcontractGuaranteeEntity>;
  getSubcontractInsurance: Array<SubcontractInsuranceEntity>;
  getSubcontract: Array<SubcontractEntity>;
  getSubcontractRelated: Array<SubcontractEntity>;
  getSubcontractWithTrade: Array<SubcontractCostWbsEntity>;
  getSubcontractWithAccount: Scalars['JSON'];
  getSubcontractTitleWithAccount: Scalars['JSON'];
  subconBudgetChecking: BudgetInfo;
  subcontractVoCalculation: Scalars['JSON'];
  getSubcontractorCompanyAssignment: Array<SubcontractorCompanyAssignmentEntity>;
  getSubcontractorByCompany: Array<SubcontractorEntity>;
  getSubcontractVO: Array<SubcontractVoEntity>;
  getSubconVOTradeCostCategory: Scalars['JSON'];
  subcontractVOListing: Scalars['JSON'];
  getSubcontractCostWbs: Array<SubcontractCostWbsEntity>;
  getSubconAdvanceAllocation: Scalars['JSON'];
  getSubconPaymentAllocation: Scalars['JSON'];
  getSubconRefundAllocation: Scalars['JSON'];
  getSubconClaimAllocationLedgers: Scalars['JSON'];
  getSubcontractRetentionClaimAllocation: Scalars['JSON'];
  getSubconRetClaimAllocation: Scalars['JSON'];
  getSubconCNAllocation: Scalars['JSON'];
  getSubconDNAllocation: Scalars['JSON'];
  getDNtoSubconAllocation: Scalars['JSON'];
  getCNtoSubconAllocation: Scalars['JSON'];
  getSubcontractClaim: Array<SubcontractClaimEntity>;
  getSubcontractClaimCount: Scalars['Float'];
  getSubconRetentionClaim: Array<SubcontractClaimEntity>;
  getSubconProgressClaim: Array<SubcontractClaimEntity>;
  getSubcontractAdvance: Array<SubcontractAdvanceEntity>;
  getSubcontractAdvanceCount: Scalars['Float'];
  getSubcontractPayment: Array<SubcontractPaymentEntity>;
  getSubcontractPaymentCount: Scalars['Float'];
  getSubcontractRefund: Array<SubcontractRefundEntity>;
  getSubcontractRefundCount: Scalars['Float'];
  getSubcontractMiscInvoice: Array<SubcontractMiscInvoiceEntity>;
  getSubcontractMiscInvoiceCount: Scalars['Float'];
  getSubcontractDN: Array<SubcontractDnEntity>;
  getSubcontractDNCount: Scalars['Float'];
  getSubcontractCN: Array<SubcontractCnEntity>;
  getSubcontractCNCount: Scalars['Float'];
  getDNtoSubcon: Array<DNtoSubconEntity>;
  getDNtoSubconCount: Scalars['Float'];
  getCNtoSubcon: Array<CNtoSubconEntity>;
  getCNtoSubconCount: Scalars['Float'];
  getDNItemsByDNID: Scalars['JSON'];
  getDNtoSubconRevenue: Array<DNtoSubconEntity>;
  getSubconCreditDocEInvoice: Scalars['JSON'];
  getSubcontractor: Array<SubcontractorEntity>;
  getSubcontractorCCategory: Array<SubcontractorCCategoryEntity>;
  getSubcontractorCostCategory: Scalars['JSON'];
  getCostCategoryBySubcon: Scalars['JSON'];
  getSubcontractorAccessibleContract: Scalars['JSON'];
  getProjectSite: Array<ContractEntity>;
  getSite: Array<SiteEntity>;
  verifySite: Scalars['String'];
  getSiteWithAccount: Scalars['JSON'];
  getSiteNameWithAccount: Scalars['JSON'];
  getSiteImage: Array<SiteImageEntity>;
};


export type QueryNegoItemInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryNegoItemCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryPrInfoCountArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPrDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPrDateObArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRfqInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRfqCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastRfqDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryPoInfoCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPoDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPoDateObArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryDoCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastDoDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGrtnCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastGrtnDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetAdjustmentByCostClassArgs = {
  contractID: Scalars['String'];
};


export type QueryBudgetingCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryCostingCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetAdvanceClientArgs = {
  contractID: Scalars['String'];
};


export type QueryGetPaidSubconSupplierAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingReceivableFromClientListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSubconListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSupplierListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryPaidSubconSupplierListingArgs = {
  contractID: Scalars['String'];
};


export type QueryAdvanceClientArgs = {
  contractID: Scalars['String'];
};


export type QueryReceivedFromClientArgs = {
  contractID: Scalars['String'];
};


export type QueryPaidToSubConArgs = {
  contractID: Scalars['String'];
};


export type QueryPaidToSupplierArgs = {
  contractID: Scalars['String'];
};


export type QueryAdvancesOutflowAmtArgs = {
  contractID: Scalars['String'];
};


export type QueryMiscExpenseArgs = {
  contractID: Scalars['String'];
};


export type QueryOsReceivableFromClientArgs = {
  contractID: Scalars['String'];
};


export type QueryClientLastReceiptArgs = {
  contractID: Scalars['String'];
};


export type QuerySubconLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QueryReceivableClientLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingAmtClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryPaidSubconSupplierAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsReceivableFromClientListingArgs = {
  contractID: Scalars['String'];
};


export type QuerySubconOsPayableAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsPayableToSubconListingArgs = {
  contractID: Scalars['String'];
};


export type QuerySupplierLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QuerySupplierOsPayableAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsPayableToSupplierListingArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingReceivableFromClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSubconArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSupplierArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryAssociateCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProjectCostingDetailArgs = {
  costClass: CostClass;
  contractID: Scalars['String'];
};


export type QueryGetCostingCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryCostCategCountArgs = {
  contractID: Scalars['String'];
};


export type QueryAdjustmentByCostClassArgs = {
  contractID: Scalars['String'];
};


export type QueryAdjustmentItemByCostCategArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryApInvoiceWoDoByCostItemArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetProjectCostingAmtArgs = {
  contractID: Scalars['String'];
};


export type QueryGetAdjustmentItemByCostCategArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetCostCategCountArgs = {
  contractID: Scalars['String'];
};


export type QueryGetPOsByCategoryArgs = {
  status?: Maybe<PurchaseStatus>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetGrtNsByCategoryDoArgs = {
  doStatus?: Maybe<DoStatus>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetDOsByCategoryArgs = {
  doStatus?: Maybe<DoStatus>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetApInvoiceWoDoByCostItemArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetPcYearArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetReportYearArgs = {
  endYear?: Maybe<Scalars['Float']>;
  startYear?: Maybe<Scalars['Float']>;
};


export type QueryGetCommCostAmtArgs = {
  subcontract?: Maybe<Scalars['Boolean']>;
  costCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryFinancialStatusArgs = {
  contractID: Scalars['String'];
};


export type QueryGetWbsParentChildrensArgs = {
  wbsID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID: Scalars['String'];
};


export type QueryProjectLedgerWbsRevenueSummaryArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  trxEndDate?: Maybe<Scalars['String']>;
  trxStartDate?: Maybe<Scalars['String']>;
  costItem?: Maybe<Array<Scalars['String']>>;
  revenueID?: Maybe<Array<Scalars['String']>>;
  wbsID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID: Scalars['String'];
};


export type QueryProjectLedgerWbsRetentionSummaryArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  trxEndDate?: Maybe<Scalars['String']>;
  trxStartDate?: Maybe<Scalars['String']>;
  revenueID?: Maybe<Array<Scalars['String']>>;
  wbsID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID: Scalars['String'];
};


export type QueryProjectLedgerWbsCostSummaryArgs = {
  costClass?: Maybe<Array<Scalars['String']>>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  trxEndDate?: Maybe<Scalars['String']>;
  trxStartDate?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Array<Scalars['String']>>;
  costCategoryID?: Maybe<Array<Scalars['String']>>;
  wbsID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID: Scalars['String'];
};


export type QueryGetCostCategoryReportArgs = {
  costClass?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetCostItemReportArgs = {
  costCategoryID?: Maybe<Array<Scalars['String']>>;
};


export type QueryProjectPlArgs = {
  contractID: Scalars['String'];
};


export type QueryGetBqArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCheckingValidationBqArgs = {
  BQExcelInput: Array<BqExcelInput>;
  contractID: Scalars['String'];
};


export type QueryGetBqWbsParentChildArgs = {
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetBqParentChildArgs = {
  contractID: Scalars['String'];
};


export type QueryGetBqWbsAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractCalendarInfoArgs = {
  currDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetSubconCalendarInfoArgs = {
  currDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetPpCalendarInfoArgs = {
  currDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetBillInterestArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  docID?: Maybe<Scalars['String']>;
};


export type QueryGetStatementAcctMonthsArgs = {
  ID: Scalars['String'];
};


export type QueryGetClaimRecListByMonthArgs = {
  ID: Scalars['String'];
  month: Scalars['DateTime'];
};


export type QueryGetCustContractIDsArgs = {
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetClientDebitDocListingArgs = {
  endPeriod: Scalars['Float'];
  startPeriod: Scalars['Float'];
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetClientDebitDocEInvoiceArgs = {
  customerID?: Maybe<Scalars['String']>;
  isEinvoice?: Maybe<Scalars['Boolean']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentReminderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocReminderArgs = {
  customerID?: Maybe<Scalars['String']>;
  paymentReminderID: Scalars['String'];
};


export type QueryGetPaymentReminderClaimArgs = {
  paymentReminderID: Scalars['String'];
};


export type QueryGetPaymentReminderByCompanyArgs = {
  companyID?: Maybe<Scalars['String']>;
  paymentReminderID?: Maybe<Scalars['String']>;
};


export type QueryGetDebitDocArgs = {
  date: Scalars['DateTime'];
  companyID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentReminderConCustArgs = {
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocReminderDetailsArgs = {
  companyID?: Maybe<Scalars['String']>;
  paymentReminderID: Scalars['String'];
};


export type QueryGetPaymentReminderLastDateArgs = {
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetPrintReminderArgs = {
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  paymentReminderID: Scalars['String'];
};


export type QueryGetCustomerReminderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetContractArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryGetCustomContractArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryContractVoCalculationArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryMaxRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryVoCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRetentionCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryValidateContractStatusArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractWithoutPermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryGetContractEntityWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractTitleWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractWithActiveDoArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryCheckWbsExistArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractGuaranteeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractInsuranceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectBudgetArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryDeleteBudgetCheckingArgs = {
  contractID: Scalars['String'];
  costCategoryIDs: Array<Scalars['String']>;
};


export type QueryBudgetCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractVoArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  VOStatus?: Maybe<VoStatus>;
};


export type QueryGetVoHealthIndexArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractAllocationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  debitID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  debitRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetContractAdvanceAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractReceiptAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractRefundAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetClaimAllocationLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetContractRetentionClaimAllocationArgs = {
  contractID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
};


export type QueryGetContractRetClaimAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetContractCnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractDnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetContractMiscInvoiceAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetClientCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetClientDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractClaimCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  isRetention?: Maybe<Scalars['Boolean']>;
};


export type QueryGetRetentionClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFetchUnallocatedClaimRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProgressClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetContractAdvanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractAdvanceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractReceiptArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractReceiptCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractRefundArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractRefundCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractMiscInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractMiscInvoiceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractDnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractDnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractCnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractCnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientDnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientDnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientCnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientCnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCnRevenueArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  withRev?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCostCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  parentCostCodeID?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCustomerCompanyAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetCustomerByCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCustomerArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetClientAccountsArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetFollowUpArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetCbExportArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
};


export type QueryGetContractCbArgs = {
  companyID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryGetContractCbInfoArgs = {
  cbExportID: Scalars['String'];
};


export type QueryGetGlExportArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
};


export type QueryGlMonthsArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetPobglArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetGlClaimDetailLedgerArgs = {
  joinTable?: Maybe<Scalars['String']>;
  claimColumn?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type QueryGetGlDetailLedgerArgs = {
  joinTable?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type QueryGetGrtnDetailLedgerArgs = {
  IDs: Array<Scalars['String']>;
};


export type QueryGetDoDetailLedgerArgs = {
  IDs: Array<Scalars['String']>;
};


export type QueryGetBatchGlDetailLedgerArgs = {
  batchInput: Array<GlDetailLedgerBatchInput>;
};


export type QueryCheckedGlEditableArgs = {
  type: Scalars['String'];
};


export type QueryGetGlSetupFileArgs = {
  accountType: Scalars['String'];
};


export type QueryGetGlAssociationFileArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetGlTransactionFileArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetGlInterfaceMasterArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  associationType?: Maybe<AssociationType>;
  transactionType?: Maybe<TransactionType>;
};


export type QueryGetGlInterfaceDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  glInterfaceMasterID?: Maybe<Scalars['String']>;
};


export type QueryGetGlTaxDynamicArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
};


export type QueryGetGlAdjustmentDynamicArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiAccountReceivableDialogArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyApiSummaryArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyApiDialogSummaryArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyKpiClaimArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyKpiCollectionArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiSiteGalleryArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetGuaranteeTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetInsuranceTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetMiscExpenseArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  status?: Maybe<MiscExpenseStatus>;
};


export type QueryGetMiscExpenseCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  status?: Maybe<MiscExpenseStatus>;
};


export type QueryMiscExpenseBudgetCheckingArgs = {
  amount: Scalars['Float'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
};


export type QueryGetCostCenterArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  status?: Maybe<CostCenterType>;
};


export type QueryGetCurrencyExchangeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromCurrencyID?: Maybe<Scalars['String']>;
  toCurrencyID?: Maybe<Scalars['String']>;
};


export type QueryGetCurrencyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  format?: Maybe<NumericFormatter>;
};


export type QueryGetConTaxSchemeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxClass?: Maybe<TaxClass>;
};


export type QueryGetConTaxSchemeWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxClass?: Maybe<TaxClass>;
};


export type QueryGetConTaxEffectiveDateArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  claimable?: Maybe<Scalars['Boolean']>;
};


export type QueryGetConTaxTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryVerifyTaxTypeArgs = {
  taxSchemeID: Scalars['String'];
};


export type QueryGetTaxCodeGlExportArgs = {
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetFilterTaxCodeArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogFormulaArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogParameterArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetProfitRecogGenerationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProfitRecogGenParamAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetContractListingProfitRecogArgs = {
  companyID: Scalars['String'];
};


export type QueryProfitRecognitionCalculationArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC1_ActualCostArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC2_BudgetCostArgs = {
  inputDate?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryC3_ActualRevenueArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC4_ReviseContractSumArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC5_TotalPreviousRecognisedRevenueArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC6_TotalPreviousRecognisedCostArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC7_PercOfCompletionArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetProfitRecognitionArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryInfoLineProfitRecogArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProfitRecogGenerationListingArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProfitRecogGlAccountCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetProfitRecogGlJournalArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetProjectExpenseArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryGetProjectExpenseItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  projectExpenseID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectExpenseCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryGetProjectOpportunityAttachmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  projectOpportunityID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectOpportunityArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  ProjectOpportunityStatus?: Maybe<ProjectOpportunityStatus>;
};


export type QueryGetProjectOpportunitySubmissionArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  projectOpportunityID?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationWbsBudgetArgs = {
  WBSAndBudgetExcelInput: Array<WbsAndBudgetExcelInput>;
  contractID: Scalars['String'];
};


export type QueryGetWbsBudgetArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
};


export type QueryGetBudgetArgs = {
  version?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryCheckWbsBudgetApprovedArgs = {
  contractID: Scalars['String'];
};


export type QueryGetWbsBudgetDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsBudgetDetailSchedulingArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsDayworkCostRevenueArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryGetTotalCostRevenueArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetCostWbsBudgetDetailArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetRevenueWbsBudgetDetailArgs = {
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryGetWbsBudgetDetailByClassArgs = {
  originalBudget?: Maybe<Scalars['Boolean']>;
  costClass: Scalars['String'];
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryCheckWbsBudgetDetailArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryCheckMultipleWbsBudgetDetailArgs = {
  input: Array<CheckMultipleWbsBudgetDetailInput>;
  docRefID?: Maybe<Scalars['String']>;
  itemChecker: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryCheckSubcontractMultipleWbsBudgetDetailArgs = {
  input: Array<CheckMultipleWbsBudgetDetailInput>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryCheckExistCostRevenueArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetCostCategoryWbsBudgetArgs = {
  isCategory?: Maybe<Scalars['Boolean']>;
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryGetWbsBudgetTransferHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsBudgetTransferDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsBudgetTransferHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsParentChildArgs = {
  isDefault?: Maybe<Scalars['Boolean']>;
  selectedID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryWbsBudgetTransferResourceArgs = {
  wbsID: Scalars['String'];
};


export type QueryGetWbsBudgetTransferArgs = {
  wbsBudgetTransferID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryGetBudgetTransferCostCategoryBalanceArgs = {
  itemInput: Array<WbsBudgetTransferCostCategoryBalanceFilterArgs>;
  contractID: Scalars['String'];
};


export type QueryGetWbsArgs = {
  childrenOnly?: Maybe<Scalars['Boolean']>;
  parentOnly?: Maybe<Scalars['Boolean']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsTotalCostRevenueArgs = {
  budgetID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryGetWbsCostAllocationByClassArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetMultipleWbsArgs = {
  contractIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetWbsScheduleArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Float']>;
};


export type QueryGetWbsUtilizedBudgetArgs = {
  contractID: Scalars['String'];
};


export type QueryGetCostCategoryFromWbsArgs = {
  itemOnly?: Maybe<Scalars['Boolean']>;
  selectedID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryGetDrawerPermissionArgs = {
  pageMode?: Maybe<Scalars['String']>;
};


export type QueryGetSiteDrawerPermissionArgs = {
  pageMode?: Maybe<Scalars['String']>;
};


export type QueryCheckArgs = {
  permIds: Array<Scalars['Float']>;
  userID: Scalars['String'];
};


export type QueryUserEntitiesArgs = {
  userID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
};


export type QueryTestPermissionArgs = {
  conPerm: ContractPermission;
};


export type QuerySiteTestPermissionArgs = {
  sitePerm: SitePermission;
};


export type QueryTestConPermissionArgs = {
  contPerm: ContPermission;
};


export type QueryTestSampleEnumArgs = {
  SampleEnum: SampleEnum;
};


export type QueryCheckDocExistArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryDocumentListingArgs = {
  hasDescription?: Maybe<Scalars['Boolean']>;
  notDescription?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryCmgdAttachmentArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryTotalDigitalDocArgs = {
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
};


export type QueryAuditLogDetailsArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  fieldID: Scalars['String'];
  tableName: Scalars['String'];
};


export type QueryGetCashFlowLineChartDataArgs = {
  contractID: Scalars['String'];
};


export type QueryGetCashFlowGraphDataArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractNewsArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  postStatus?: Maybe<PostStatus>;
};


export type QueryGetContractNewsReadArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  newsID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetCollectionActivityArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyHorArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetRawCompanyArgs = {
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubscriptionCurrencyArgs = {
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetContactArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
};


export type QueryGetAdjustmentTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentFolderArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryTotalDocFolderArgs = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetLocalBankArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogFormulaScriptArgs = {
  name: Scalars['String'];
};


export type QuerySubmittedRetClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryApprovedClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryCancellableClaimsArgs = {
  identifier: Scalars['String'];
};


export type QueryApprovableLedgerCheckingArgs = {
  docAmt?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentDetailbySupplierArgs = {
  companyID?: Maybe<Scalars['String']>;
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentbyStatusArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  StatusArr?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  batchPaymentID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentbyStatusCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  StatusArr?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetContractLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorLedgersArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialContractPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetDefaultMaterialPolicyIDsArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialPolicyCheckerArgs = {
  category: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
};


export type QueryAssociateMenuArgs = {
  companyID?: Maybe<Scalars['String']>;
  associate: Scalars['String'];
};


export type QueryPendingCollectionActivityArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryPaymentReminderSummaryArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalDebitClientArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalCreditClientArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalOutstandingClientsArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalOsClientInvoicesArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalOutstandingAmtClientsArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySupplierProfileDateArgs = {
  ID: Scalars['String'];
};


export type QueryPrefSupplierProductDateArgs = {
  supplierID: Scalars['String'];
};


export type QuerySupplierAccDateArgs = {
  supplierID: Scalars['String'];
};


export type QueryContractInfoArgs = {
  contractID: Scalars['String'];
};


export type QueryContractGuaranteeInfoArgs = {
  contractID: Scalars['String'];
};


export type QueryContractInsuranceInfoArgs = {
  contractID: Scalars['String'];
};


export type QuerySubmittedVoNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryContractVoArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingAdvancesArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingClaimsArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingMiscInvsArgs = {
  contractID: Scalars['String'];
};


export type QueryRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastSiteProgressDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWbsBudgetInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryWbsBudgetRevisionInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryUtilizedBudgetInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryBudgetTransferInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastBqImportDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryBqAssignmentInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryMiscExpInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastMiscExpDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastExtensionDateArgs = {
  contractID: Scalars['String'];
};


export type QueryContractClosureDateArgs = {
  contractID: Scalars['String'];
};


export type QuerySubcontractInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconGuaranteeInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconInsuranceInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubmittedVoSubconNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractVoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconOutstandingAdvancesArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconOutstandingClaimsArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconLastExtensionDateArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconClosureDateArgs = {
  subcontractID: Scalars['String'];
};


export type QueryLatestDefectDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLatestAssignmentDateArgs = {
  defectID?: Maybe<Scalars['String']>;
};


export type QuerySiteWorkerAttendanceInfoArgs = {
  siteID: Scalars['String'];
};


export type QueryMosCountArgs = {
  siteID: Scalars['String'];
};


export type QueryApprovalPolicyDateArgs = {
  parameter: Scalars['String'];
};


export type QueryApprovalPolicyWithContractDateArgs = {
  contractID: Scalars['String'];
  parameter: Scalars['String'];
};


export type QueryConAuthCountArgs = {
  contractID: Scalars['String'];
};


export type QueryDocNumDateArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryBankAccountDateArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveClientCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryClientCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryContractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveContractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSiteCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractorCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSubcontractorCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySupplierCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSupplierCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActivePlantMachineryCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryProjectPurchaseCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFuelInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryOwnedPnmInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryRentedPnmInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryAssignmentRecordArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryMaintenanceRecordArgs = {
  workshopID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFuelTankCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryClientProfileDateArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCusAvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryAdvancesInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryProgressClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedRetClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryRetentionClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedMiscInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryMiscInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClientDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClientDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClientCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClientCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedReceiptNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryReceiptsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingClientArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryConClientProfileDateArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConAdvancesInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConClaimsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConDnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConCnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConReceiptsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConRefundsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConClientDnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConOutstandingClientArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QuerySubconProfileDateArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconLedgersInfoArgs = {
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconAvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconAdvancesInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconProgressClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconRetClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconRetentionClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconMiscInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconMiscInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedPaymentNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconPaymentInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDNtoSubconNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryDNtoSubconInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCNtoSubconNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryCNtoSubconInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingSubconArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedSubconArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySuppProfileDateArgs = {
  supplierID: Scalars['String'];
};


export type QuerySubmittedDepositNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierDepositsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierInvWoDoNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierInvWoDoInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDnToSupplierNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryDNtoSupplierInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCnToSupplierNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryCNtoSupplierInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierPaymentNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierPaymentsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierBatchPaymentsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingSupplierArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedSupplierArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryTotalStockInArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTotalStockOutArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTotalDefectArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTotalSiteSurveillanceArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTodayTotalAttendanceArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWorkerCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLastGrnDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryStockMovementCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLastStockMovementDatesArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QuerySiteImagesCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryVideoCamCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWorkerAttendanceCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetDailyRegisteredWorkHoursArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetDailyWagesVerificationArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWagesBookArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWageCycleClosureInfoArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetTaxArgs = {
  taxID: Scalars['String'];
};


export type QueryGetTaxSchemeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxSchemeWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxSchemeDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetTaxSchemeDetailWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetTaxSchemeDetailItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  seqNo?: Maybe<Scalars['Float']>;
};


export type QueryGetTaxSchemeDetailItemWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  seqNo?: Maybe<Scalars['Float']>;
};


export type QueryGetContractNotificationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  senderID?: Maybe<Scalars['String']>;
  recipientID?: Maybe<Scalars['String']>;
  readStatus?: Maybe<ReadStatus>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
};


export type QueryGetNotificationListingArgs = {
  unread?: Maybe<Scalars['Boolean']>;
};


export type QueryGetRoleArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
};


export type QueryGetRoleSetupArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  contractID: Scalars['String'];
};


export type QueryGetRolePermissionArgs = {
  siteManagement?: Maybe<Scalars['Boolean']>;
};


export type QueryGetUsersRoleContractArgs = {
  contractID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetRoleMatrixArgs = {
  roleID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleMatrixByIdsArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  softwareCode?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleByIdsArgs = {
  softwareCode?: Maybe<Scalars['String']>;
  roleIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetAlertArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type QueryGetAlertListArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetShowAlertSqlArgs = {
  ID: Scalars['String'];
};


export type QueryGetAlertAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  reminder?: Maybe<Scalars['String']>;
  alertID?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyGuidelineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentGuidelineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  approvalPolicyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyArgs = {
  category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentArgs = {
  contractID: Scalars['String'];
  category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBankAccountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};


export type QueryGetBankAccountWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};


export type QueryGetCalendarArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCalendarPhPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
  publicHolidayID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetCalendarWeekendPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLogoViewArgs = {
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryWatermarkViewArgs = {
  opacity?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetActivePnmCostCategoryArgs = {
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetMapCostCategoryArgs = {
  costClass?: Maybe<CostClass>;
  materialOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetParentChildrenCostCategoryArgs = {
  cost?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCostCategoryTreeArgs = {
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSubcontractTradeCostCategoryArgs = {
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetNonSelectedCostCategoryArgs = {
  CommonStatus?: Maybe<CommonStatus>;
  wbsID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetCostItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  isInventory?: Maybe<Scalars['Boolean']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCostItemCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  isInventory?: Maybe<Scalars['Boolean']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCostItemBySupplierArgs = {
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type QueryGetItemByClassArgs = {
  isInventory?: Maybe<Scalars['Boolean']>;
  costClass: CostClass;
};


export type QueryGetCostItemByClassesArgs = {
  isInventory?: Maybe<Scalars['Boolean']>;
  costClass: Array<CostClass>;
};


export type QueryGetCostItemPaginationArgs = {
  limit?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costClass?: Maybe<CostClass>;
  isInventory?: Maybe<Scalars['Boolean']>;
  costCategoryID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCategoryItemArgs = {
  costClass?: Maybe<CostClass>;
  itemOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCostItemByWbsArgs = {
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryGetCostItemByWbsIDsArgs = {
  wbsIDs: Array<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryCheckingBudgetQtyArgs = {
  wbsID: Scalars['String'];
  uomID: Scalars['String'];
  costItemID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetCurrencySetupArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  format?: Maybe<NumericFormatter>;
};


export type QueryGetDigitalDocumentArgs = {
  type: Scalars['String'];
  associateID: Scalars['String'];
};


export type QueryGetTableDocumentsArgs = {
  description?: Maybe<Scalars['String']>;
  refTable: Scalars['String'];
  associateID: Scalars['String'];
  refID: Array<Scalars['String']>;
};


export type QueryTotalDigitalDocumentsArgs = {
  type?: Maybe<Scalars['String']>;
  associateID: Scalars['String'];
};


export type QueryGetNumberFormatArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetConDocTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocTypeArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFetchNextDocNumArgs = {
  claimOnly?: Maybe<Scalars['String']>;
  consubconID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryFetchMultipleDocNumArgs = {
  supplierIDs?: Maybe<Array<Scalars['String']>>;
  numOfDocs?: Maybe<Scalars['Float']>;
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryGetRunningNumberSetupWithLockArgs = {
  contractID?: Maybe<Scalars['String']>;
  numberFormatID: Scalars['String'];
};


export type QueryRunningNumCheckingArgs = {
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryProjMiscExpRunNumCheckingArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetDocNumHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetDocNumDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentNumberHeaderAndDetailArgs = {
  contractID: Scalars['String'];
};


export type QueryGetClassificationCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type QueryGetClassificationCodeCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralTinArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  tinParty?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralTinCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryGetMsicCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
};


export type QueryGetMsicCodeCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryGetHolidayTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetJobTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryIsPlatformUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetPolicyGuideLineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetPolicyRoleAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  policyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
};


export type QueryGetNotificationGuideLineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  policyID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetNotiRoleUserAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  notiPolicyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetPublicHolidayArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetPublicHolidayByStateYearArgs = {
  year: Scalars['String'];
};


export type QueryGetRevenueCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetRevenueCategoryCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSAccountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSiteWorkersArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSubcontractorTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSupplierTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomWithPermCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomExchangeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeListArgs = {
  uomID: Scalars['String'];
};


export type QueryGetUomExchangeListByWbsArgs = {
  uomIDs: Array<Scalars['String']>;
};


export type QueryGetUserCompanyAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedByLedgerArgs = {
  docRefID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetUsersByAccountAndSoftwareArgs = {
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
};


export type QueryGetVideoCamArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWagesRatePolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetContractToDoArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
};


export type QueryGetToDoListingArgs = {
  approved?: Maybe<Scalars['Boolean']>;
};


export type QueryGetWorkerArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetWorkerSiteAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  workerID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerFromSiteArgs = {
  siteID: Scalars['String'];
};


export type QueryCheckingWageTypeArgs = {
  workerID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerAttendanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  workDate?: Maybe<Scalars['String']>;
};


export type QueryCheckWorkFlowArgs = {
  stockMovement?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
};


export type QueryGetToDoWorkflowSummaryArgs = {
  length?: Maybe<Scalars['Boolean']>;
};


export type QueryGetToDoAllPendingDocsArgs = {
  category: Scalars['String'];
};


export type QueryPolicyCheckingArgs = {
  contractID: Scalars['String'];
  category: Scalars['String'];
};


export type QueryPolicyCheckerApPaymentArgs = {
  category: Scalars['String'];
};


export type QueryGetNotificationWorkflowListingArgs = {
  length?: Maybe<Scalars['Boolean']>;
  unread?: Maybe<Scalars['Boolean']>;
};


export type QueryGetApprovalLogArgs = {
  refID: Scalars['String'];
};


export type QueryGetAllApprovalLogArgs = {
  refIDs: Array<Scalars['String']>;
};


export type QueryApprovableLedgerWfCheckingArgs = {
  input: CheckingInput;
};


export type QueryGetWorkingHourPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetHorAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  assignmentStatus?: Maybe<AsgMainStatus>;
};


export type QueryGetAssignmentCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  assignmentStatus?: Maybe<AsgMainStatus>;
};


export type QueryGetAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  assignmentStatus?: Maybe<AsgMainStatus>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetFuelDischargeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetFuelDischargeCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetFuelBalanceArgs = {
  tankID: Scalars['String'];
};


export type QueryGetFuelMeterReadingArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetFuelMeterReadingCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryFuelMeterReadingSummaryArgs = {
  tankID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  date?: Maybe<Scalars['String']>;
};


export type QueryGetFuelSupplyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetFuelSupplyCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetFuelTankSetupArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  tankType?: Maybe<TankType>;
};


export type QueryGetFuelTankSetupCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  tankType?: Maybe<TankType>;
};


export type QueryGetHorMaintenanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  maintenanceStatus?: Maybe<AsgMainStatus>;
  workshopID?: Maybe<Scalars['String']>;
};


export type QueryGetMaintenanceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  maintenanceStatus?: Maybe<AsgMainStatus>;
  workshopID?: Maybe<Scalars['String']>;
};


export type QueryGetMaintenanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  maintenanceStatus?: Maybe<AsgMainStatus>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPlantMachineryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  plantMachineryStatus?: Maybe<PlantMachineryStatus>;
};


export type QueryGetWorkshopArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  workshopStatus?: Maybe<WorkshopStatus>;
};


export type QueryGetWorkshopFormArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  workshopStatus?: Maybe<WorkshopStatus>;
};


export type QueryGetPnmRentalArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPnmRentalCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPnmByStatusArgs = {
  costCategoryID: Scalars['String'];
  status: Array<PlantMachineryStatus>;
};


export type QueryGetPnmStatusArgs = {
  plantMachineryID: Scalars['String'];
};


export type QueryGetTankArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  hasMeterReading?: Maybe<Scalars['Boolean']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetRentalTankArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  hasMeterReading?: Maybe<Scalars['Boolean']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplyByArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplyDischargeToArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractFuelArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetDoArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetDoItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDoCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetPOsArgs = {
  docDate?: Maybe<Scalars['DateTime']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetGrtNsArgs = {
  docDate?: Maybe<Scalars['DateTime']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryTotalDoAmtArgs = {
  doID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierByDoStatusArgs = {
  doStatus: DoStatus;
  contractID: Scalars['String'];
};


export type QueryCheckingCancelCloseDoArgs = {
  ID: Scalars['String'];
};


export type QueryGetGrtnBySupplierArgs = {
  siteID: Scalars['String'];
};


export type QueryGetGoodReturnNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  grtnStatus?: Maybe<PurchaseStatus>;
};


export type QueryGetGoodReturnNoteItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetGoodReturnNoteCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  grtnStatus?: Maybe<PurchaseStatus>;
};


export type QueryGetNegotiatedSupplyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseStatus>;
};


export type QueryGetNegotiatedSupplyItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  negotiatedSupplyID?: Maybe<Scalars['String']>;
};


export type QueryGetNegotiatedSupplyCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseStatus>;
};


export type QueryVerifyNegoSupplyStatusArgs = {
  contractID: Scalars['String'];
};


export type QueryVerifyNegoSupplyItemArgs = {
  costItemID: Scalars['String'];
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetPurchaseOrderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseType?: Maybe<PurchaseType>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};


export type QueryGetPoItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
  negotiatedSupplyItemID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseOrderCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseType?: Maybe<PurchaseType>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};


export type QueryGetPoByStatusArgs = {
  purchaseType?: Maybe<PurchaseType>;
  statusArr: Array<PurchaseStatus>;
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryPurchaseBudgetArgs = {
  contractID: Scalars['String'];
  prInfo: Array<PrInfo>;
};


export type QueryPurchaseBudgetAllocationArgs = {
  contractID: Scalars['String'];
  prItemIDs: Array<Scalars['String']>;
};


export type QueryPurchaseWbsBudgetAllocationArgs = {
  contractID: Scalars['String'];
  input: Array<PurchaseWbsBudgetAllocationInput>;
};


export type QueryGetSupplierQuotesArgs = {
  prItemID: Scalars['String'];
  contractID: Scalars['String'];
  costItemID: Scalars['String'];
};


export type QueryClosePoCheckingArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPoBySupplierArgs = {
  siteID: Scalars['String'];
};


export type QueryGetSupplierByPoStatusArgs = {
  purchaseStatus: PurchaseStatus;
  contractID: Scalars['String'];
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetPoContractNameArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPoListArgs = {
  status?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPoNoArgs = {
  isStatus?: Maybe<Scalars['Boolean']>;
  supplierID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPoCategoryArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPoItemByCategoryArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryCheckingCancelClosePoArgs = {
  ID: Scalars['String'];
};


export type QueryGetPoCostItemListArgs = {
  supplierID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPoSupplierListArgs = {
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseReqArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
};


export type QueryGetPurchaseReqCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
};


export type QueryGetPrForBudgetByOneArgs = {
  contractID: Scalars['String'];
  purchaseType: PurchaseType;
  ID: Scalars['String'];
};


export type QueryGetPrItemsbyIDsArgs = {
  poID?: Maybe<Scalars['String']>;
  IDs: Array<Scalars['String']>;
};


export type QueryGetOutstandingPrArgs = {
  purchaseType: PurchaseType;
  contractID: Scalars['String'];
};


export type QueryGetOutstandingPrItemsArgs = {
  supplierID?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  contractID: Scalars['String'];
};


export type QueryCheckingCancelClosePrArgs = {
  ID: Scalars['String'];
};


export type QueryGetRfqArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<RfqStatus>;
};


export type QueryGetRfqItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
};


export type QueryGetRfqCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<RfqStatus>;
};


export type QueryGetSuggestedSupplierArgs = {
  rfqID: Scalars['String'];
};


export type QueryGetRfqSupplierInvitedArgs = {
  rfqStatus?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type QueryGetRfqSupplierInvitationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetInvitedSupplierArgs = {
  contractID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type QueryGetRfqSupplierSubmissionArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetPrItemByPridForRfqArgs = {
  contractID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryGetPurchaseReqWithPrArgs = {
  contractID: Scalars['String'];
};


export type QueryGetQuotedRfqArgs = {
  supplierID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type QueryGetSupplierCompanyAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierByCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSupplierArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  creditorAC?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetSupplierCCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetMultiSupplierArgs = {
  supplierIDs: Array<Scalars['String']>;
};


export type QueryGetSupplierCostCategoryArgs = {
  costCategoryID: Scalars['String'];
};


export type QueryGetSupplierDepositAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSupplierPaymentAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetDNtoSupplierAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetCNtoSupplierAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSupplierCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSupplierDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSupplierInvoiceAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetApInvoiceArgs = {
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetApInvoiceWoDoArgs = {
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDOforInvoiceArgs = {
  formMode?: Maybe<Scalars['String']>;
  invoiceID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  supplierID: Scalars['String'];
};


export type QueryGetApAdvanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApAdvanceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApPaymentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApPaymentCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApRefundArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApRefundCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSupplierPaymentByContractArgs = {
  contractID: Scalars['String'];
};


export type QueryGetApDebitNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetApDebitNoteCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetApCreditNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetApCreditNoteCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSupplierArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSupplierCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSupplierArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSupplierCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSupplierCreditDocEInvoiceArgs = {
  isSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorInvitationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
};


export type QueryGetSuggestedSubcontractorArgs = {
  subcontractTenderID: Scalars['String'];
};


export type QueryGetInvitedSubcontractorArgs = {
  contractID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
};


export type QueryGetSubconRfqSubmissionArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractorInvitationID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractTenderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractGuaranteeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractInsuranceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractSum?: Maybe<Scalars['Float']>;
  costCategoryID?: Maybe<Scalars['String']>;
  subcontractStatus?: Maybe<ContractStatus>;
};


export type QueryGetSubcontractRelatedArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractWithTradeArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetSubcontractTitleWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QuerySubconBudgetCheckingArgs = {
  amount: Scalars['Float'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
};


export type QuerySubcontractVoCalculationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorCompanyAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorByCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSubcontractVoArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  VOStatus?: Maybe<VoStatus>;
};


export type QueryGetSubconVoTradeCostCategoryArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QuerySubcontractVoListingArgs = {
  contractID: Scalars['String'];
};


export type QueryGetSubcontractCostWbsArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconAdvanceAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSubconPaymentAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconRefundAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubconClaimAllocationLedgersArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractRetentionClaimAllocationArgs = {
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconRetClaimAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubconCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSubconDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetDNtoSubconAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetCNtoSubconAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubcontractClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractClaimCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  isRetention?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSubconRetentionClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconProgressClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractAdvanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractAdvanceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractPaymentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractPaymentCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractRefundArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractRefundCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractMiscInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractMiscInvoiceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractDnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractDnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractCnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractCnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSubconArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSubconCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSubconArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSubconCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDnItemsByDnidArgs = {
  dnID: Scalars['String'];
};


export type QueryGetDNtoSubconRevenueArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  withRev?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSubconCreditDocEInvoiceArgs = {
  isSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetSubcontractorCCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorCostCategoryArgs = {
  costCategoryID: Scalars['String'];
};


export type QueryGetCostCategoryBySubconArgs = {
  subcontractorID: Scalars['String'];
};


export type QueryGetSubcontractorAccessibleContractArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectSiteArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  mainSite?: Maybe<Scalars['Boolean']>;
};


export type QueryVerifySiteArgs = {
  siteID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetSiteNameWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetSiteImageArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};

export enum QuotationStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Inactive = 'INACTIVE'
}

export type RfqActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  rfqStatus: RfqStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type RfqEntity = AuditEntity & {
  __typename?: 'RFQEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  purchaseReqID: Scalars['String'];
  purchaseReq?: Maybe<PurchaseReqEntity>;
  description: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docDate: Scalars['String'];
  expectedDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  rfqStatus: RfqStatus;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  invitedSupplier?: Maybe<Array<RfqSupplierInvitationEntity>>;
  rfqSupplierSubmission?: Maybe<Array<RfqSupplierSubmissionEntity>>;
  supplierRFQInvite: Scalars['JSON'];
  supplier: Array<SupplierEntity>;
  /** CustomFieldResolver */
  totalRfqSubmission?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  submittedSupplierQuotes?: Maybe<Scalars['Float']>;
};


export type RfqEntitySupplierRfqInviteArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type RfqEntitySubmittedSupplierQuotesArgs = {
  supplierID: Scalars['String'];
};

export type RfqEvalInput = {
  ID?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<RfqStatus>;
};

export type RfqInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  purchaseReqID: Scalars['String'];
  description: Scalars['String'];
  expectedDate: Scalars['String'];
  docDate: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  rfqStatus?: Maybe<RfqStatus>;
};

export type RfqItemEntity = AuditEntity & {
  __typename?: 'RFQItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
  prItemID: Scalars['String'];
  prItem?: Maybe<PrItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  quantity: Scalars['Float'];
  rfqSubmissionItem?: Maybe<Array<RfqSubmissionItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  rfqItemSubmissionSelected?: Maybe<Array<RfqItemSubmissionSelectedEntity>>;
  /** CustomFieldResolver */
  supplierSelected?: Maybe<Scalars['Boolean']>;
};


export type RfqItemEntitySupplierSelectedArgs = {
  supplierID?: Maybe<Scalars['String']>;
};

export type RfqItemInput = {
  ID?: Maybe<Scalars['String']>;
  prItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
};

export type RfqItemSubmissionSelectedEntity = AuditEntity & {
  __typename?: 'RFQItemSubmissionSelectedEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqItemID: Scalars['String'];
  rfqItem?: Maybe<RfqItemEntity>;
  rfqSubmissionItemID: Scalars['String'];
  rfqSubmissionItem?: Maybe<RfqSubmissionItemEntity>;
};

export type RfqRejectInput = {
  rejectionDate: Scalars['String'];
  remarks: Scalars['String'];
};

export enum RfqStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Pending = 'PENDING'
}

export type RfqSubmissionInput = {
  ID?: Maybe<Scalars['String']>;
  rfqID: Scalars['String'];
  supplierID: Scalars['String'];
  quotationNo: Scalars['String'];
  docAmt: Scalars['Float'];
  creditTerm: Scalars['Float'];
  submittedDate: Scalars['String'];
  effectiveDate: Scalars['String'];
  expiryDate: Scalars['String'];
  quotationDate: Scalars['String'];
  expectedDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quotationStatus: QuotationStatus;
  purchaseStatus?: Maybe<PurchaseStatus>;
  rfqSubmissionItem: Array<RfqSubmissionItemInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type RfqSubmissionItemEntity = AuditEntity & {
  __typename?: 'RFQSubmissionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqSupplierSubmissionID: Scalars['String'];
  rfqSubmission: RfqSupplierSubmissionEntity;
  rfqItemID: Scalars['String'];
  rfqItem?: Maybe<RfqItemEntity>;
  unitPrice: Scalars['Float'];
  isSelected: Scalars['Boolean'];
  remarks?: Maybe<Scalars['String']>;
  rfqItemSubmissionSelected?: Maybe<Array<RfqItemSubmissionSelectedEntity>>;
  /** CustomFieldResolver */
  cheapest?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  selected?: Maybe<Scalars['JSON']>;
};


export type RfqSubmissionItemEntityCheapestArgs = {
  rfqID?: Maybe<Scalars['String']>;
};

export type RfqSubmissionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  rfqItemID: Scalars['String'];
  unitPrice: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};

export type RfqSupplierInvitationEntity = AuditEntity & {
  __typename?: 'RFQSupplierInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier: SupplierEntity;
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
};

export type RfqSupplierInvitationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  rfqID: Scalars['String'];
};

export type RfqSupplierSubmissionEntity = AuditEntity & {
  __typename?: 'RFQSupplierSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
  supplierID: Scalars['String'];
  supplier: SupplierEntity;
  quotationNo: Scalars['String'];
  docAmt: Scalars['Float'];
  creditTerm: Scalars['Float'];
  submittedDate: Scalars['String'];
  effectiveDate: Scalars['String'];
  expiryDate: Scalars['String'];
  quotationDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  quotationStatus: QuotationStatus;
  rfqSubmissionItem: Array<RfqSubmissionItemEntity>;
};

export enum RateUomType {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Lumpsum = 'LUMPSUM'
}

export enum RatingType {
  Gold = 'GOLD',
  Diamond = 'DIAMOND',
  Platinum = 'PLATINUM'
}

export enum ReadStatus {
  Seen = 'SEEN',
  Read = 'READ',
  Unread = 'UNREAD'
}

export enum ReceiptStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export enum RefTable {
  Contract = 'CONTRACT',
  Client = 'CLIENT',
  Subcontract = 'SUBCONTRACT',
  Supplier = 'SUPPLIER',
  NegotiatedSupply = 'NEGOTIATED_SUPPLY',
  Miscexpense = 'MISCEXPENSE'
}

export type RejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export enum Relationship {
  Supervisor = 'Supervisor',
  Spouse = 'Spouse',
  Family = 'Family',
  Friends = 'Friends'
}

export type RelationshipTypes = {
  __typename?: 'RelationshipTypes';
  relationship: Relationship;
};

export type ReminderDetail = {
  __typename?: 'ReminderDetail';
  reminderSeq?: Maybe<Scalars['Float']>;
  numOfReminder?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type ReminderDetailInput = {
  reminderSeq?: Maybe<Scalars['Float']>;
  numOfReminder?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export enum ReminderFor {
  AllBill = 'ALL_BILL',
  ProgressClaim = 'PROGRESS_CLAIM'
}

export type ReportDevEntity = {
  __typename?: 'ReportDevEntity';
  ID: Scalars['String'];
  ParentID: Scalars['String'];
  ReportName: Scalars['String'];
  FilePath?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Module?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type RetentionInfo = {
  __typename?: 'RetentionInfo';
  retentionSum?: Maybe<Scalars['Float']>;
  releasedSum?: Maybe<Scalars['Float']>;
  retentionBalance?: Maybe<Scalars['Float']>;
};

export type ReturnInput = {
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
};

export type RevEInvoiceAdjItemEntity = AuditEntity & {
  __typename?: 'RevEInvoiceAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
};

export type RevenueCategoryDeleteInput = {
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type RevenueCategoryEntity = AuditEntity & {
  __typename?: 'RevenueCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  isDaywork: Scalars['Boolean'];
  glAccountCode?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  eInvoiceIntegrated: Scalars['Boolean'];
  commonStatus: CommonStatus;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  DNToSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNToSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
};

export type RevenueCategoryImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sequence?: Maybe<Scalars['Float']>;
  revenueName?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
};

export type RevenueCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxSchemeID: Scalars['String'];
  name: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  isDaywork?: Maybe<Scalars['Boolean']>;
  eInvoiceIntegrated?: Maybe<Scalars['Boolean']>;
};

export type RevenueCategoryUpdateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  softwareCode: SoftwareCode;
  rolePerm: Array<RolePermissionAssignmentEntity>;
  conRolePerm: Array<RolePermissionAssignmentEntity>;
  siteRolePerm: Array<RolePermissionAssignmentEntity>;
  /** CustomFieldResolver */
  assignedUserEntity?: Maybe<Scalars['JSON']>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'RolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  accountID?: Maybe<Scalars['String']>;
  permName?: Maybe<Scalars['String']>;
};

export type RoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'RoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type SAccountEntity = AuditEntity & {
  __typename?: 'SAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyName?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  watermarkImplementation: Scalars['Boolean'];
  isoImplementation: Scalars['Boolean'];
  watermarkWording?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['JSON']>;
  logoPosition: LogoPosition;
  isoLogoPosition: LogoPosition;
};

export type SAccountInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  currencyName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  watermarkWording?: Maybe<Scalars['String']>;
  isoImplementation?: Maybe<Scalars['Boolean']>;
  watermarkImplementation?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['JSON']>;
  logoPosition?: Maybe<LogoPosition>;
  isoLogoPosition?: Maybe<LogoPosition>;
};

export type SafetyChecklistEntity = AuditEntity & {
  __typename?: 'SafetyChecklistEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  title: Scalars['String'];
  nextScheduleDate: Scalars['String'];
  endDate: Scalars['String'];
  isActive: Scalars['Boolean'];
  frequency: Frequency;
  checklistCategoryID: Scalars['String'];
  checklistCategory?: Maybe<ChecklistCategoryEntity>;
  commonStatus: CommonStatus;
  siteSafety?: Maybe<Array<SiteSafetyEntity>>;
  safetyChecklistItem?: Maybe<Array<SafetyChecklistItemEntity>>;
};

export type SafetyChecklistItemEntity = AuditEntity & {
  __typename?: 'SafetyChecklistItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  safetyChecklistID: Scalars['String'];
  safetyChecklist?: Maybe<SafetyChecklistEntity>;
  title: Scalars['String'];
  notes: Scalars['String'];
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
};

export enum SampleEnum {
  Demo = 'DEMO',
  Testing = 'TESTING'
}

export type ShareAttachmentEntity = AuditEntity & {
  __typename?: 'ShareAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type ShippedDetail = {
  __typename?: 'ShippedDetail';
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  shippedByID?: Maybe<Scalars['String']>;
  shippedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type ShippedDetailInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  shippedByID?: Maybe<Scalars['String']>;
  shippedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type SiteDeleteInput = {
  ID: Scalars['String'];
};

export type SiteDiaryEntity = AuditEntity & {
  __typename?: 'SiteDiaryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  diaryTypeID: Scalars['String'];
  diaryType?: Maybe<DiaryTypeEntity>;
  diaryDate: Scalars['String'];
  subject: Scalars['String'];
  description: Scalars['String'];
  location: Scalars['String'];
  approvalStatus: ApprovalStatus;
  assignedToID?: Maybe<Scalars['String']>;
  instructionComment?: Maybe<Scalars['String']>;
  targetDate?: Maybe<Scalars['DateTime']>;
  closingDate?: Maybe<Scalars['String']>;
  closingRemark?: Maybe<Scalars['String']>;
};

export type SiteEntity = AuditEntity & {
  __typename?: 'SiteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  stateID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address: Scalars['JSON'];
  contactNo?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mainSite: Scalars['Boolean'];
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  workerSiteAssignment?: Maybe<Array<WorkerSiteAssignmentEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  siteImage?: Maybe<Array<SiteImageEntity>>;
  videoCam?: Maybe<Array<VideoCamEntity>>;
  wageCycle?: Maybe<Array<WageCycleEntity>>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
  grn?: Maybe<Array<GrnEntity>>;
  defect?: Maybe<Array<DefectEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  documentFolder?: Maybe<Array<DocumentFolderEntity>>;
  siteSafety?: Maybe<Array<SiteSafetyEntity>>;
  safetyChecklist?: Maybe<Array<SafetyChecklistEntity>>;
  /** CustomFieldResolver */
  picture?: Maybe<Scalars['Float']>;
  worker: Array<WorkerEntity>;
};

export type SiteImageDeleteInput = {
  ID: Scalars['String'];
};

export type SiteImageEntity = AuditEntity & {
  __typename?: 'SiteImageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  title: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  site?: Maybe<SiteEntity>;
};

export type SiteImageInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  balanceQty?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SiteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  stateID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mainSite?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
};

export type SiteMaterialContractPolicyEntity = AuditEntity & {
  __typename?: 'SiteMaterialContractPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteMaterialPolicyID: Scalars['String'];
  siteMaterialPolicy?: Maybe<SiteMaterialPolicyEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  commonStatus: CommonStatus;
};

export type SiteMaterialContractPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};

export type SiteMaterialDeleteInput = {
  ID: Scalars['String'];
};

export type SiteMaterialEntity = AuditEntity & {
  __typename?: 'SiteMaterialEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  uomID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  stockCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  uom?: Maybe<UomEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  siteMaterialPolicy?: Maybe<Array<SiteMaterialPolicyEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
};

export type SiteMaterialInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SiteMaterialPolicyEntity = AuditEntity & {
  __typename?: 'SiteMaterialPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  commonStatus: CommonStatus;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
};

export type SiteMaterialPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};

export enum SitePermission {
  SiteMgmtIcon = 'SITE_MGMT__________ICON',
  DefectMgmtIcon = 'DEFECT_MGMT__________ICON',
  DefectMgmtView = 'DEFECT_MGMT__________VIEW',
  DefectMgmtCreate = 'DEFECT_MGMT__________CREATE',
  DefectMgmtUpdate = 'DEFECT_MGMT__________UPDATE',
  DefectMgmtActivityLogCreate = 'DEFECT_MGMT_ACTIVITY_LOG_______CREATE',
  DefectMgmtActivityLogView = 'DEFECT_MGMT_ACTIVITY_LOG_______VIEW',
  DefectMgmtActivityLogUpdate = 'DEFECT_MGMT_ACTIVITY_LOG_______UPDATE',
  DefectMgmtDefectAssignmentView = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______VIEW',
  DefectMgmtDefectAssignmentCreate = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______CREATE',
  DefectMgmtGeneralSettingsIcon = 'DEFECT_MGMT_GENERAL_SETTINGS_______ICON',
  DefectMgmtGeneralSettingsSubcontractPackageView = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____VIEW',
  DefectMgmtGeneralSettingsSubcontractPackageCreate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____CREATE',
  DefectMgmtGeneralSettingsSubcontractPackageUpdate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPDATE',
  DefectMgmtGeneralSettingsSubcontractPackageDelete = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DELETE',
  DefectMgmtGeneralSettingsSubcontractPackageDownload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DOWNLOAD',
  DefectMgmtGeneralSettingsSubcontractPackageUpload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPLOAD',
  SiteAttendanceIcon = 'SITE_ATTENDANCE__________ICON',
  SiteAttendanceView = 'SITE_ATTENDANCE__________VIEW',
  SiteAttendanceWorkerProfileCreate = 'SITE_ATTENDANCE_WORKER_PROFILE_______CREATE',
  SiteAttendanceWorkerProfileView = 'SITE_ATTENDANCE_WORKER_PROFILE_______VIEW',
  SiteAttendanceWorkerProfileUpdate = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPDATE',
  SiteAttendanceWorkerProfileDelete = 'SITE_ATTENDANCE_WORKER_PROFILE_______DELETE',
  SiteAttendanceWorkerProfileDownload = 'SITE_ATTENDANCE_WORKER_PROFILE_______DOWNLOAD',
  SiteAttendanceWorkerProfileUpload = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPLOAD',
  SiteAttendanceWorkerAttendanceCreate = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______CREATE',
  SiteAttendanceWorkerAttendanceView = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______VIEW',
  SiteAttendanceWorkerAttendanceExport = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______EXPORT',
  SiteAttendanceGeneralSettingsIcon = 'SITE_ATTENDANCE_GENERAL_SETTINGS_______ICON',
  SiteAttendanceGeneralSettingsPublicHolidayCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteAttendanceGeneralSettingsPublicHolidayView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteAttendanceGeneralSettingsPublicHolidayUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteAttendanceGeneralSettingsPublicHolidayDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteAttendanceGeneralSettingsPublicHolidayCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteAttendanceGeneralSettingsPublicHolidayActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteAttendanceGeneralSettingsCalendarPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteAttendanceGeneralSettingsCalendarPolicyView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteAttendanceGeneralSettingsCalendarPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SiteAttendanceGeneralSettingsJobTypeView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SiteAttendanceGeneralSettingsJobTypeUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SiteAttendanceGeneralSettingsJobTypeCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SiteAttendanceGeneralSettingsJobTypeActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SiteWagesIcon = 'SITE_WAGES__________ICON',
  SiteWagesWorkerAttendanceView = 'SITE_WAGES_WORKER_ATTENDANCE__________VIEW',
  SiteWagesWorkerAttendanceCreate = 'SITE_WAGES_WORKER_ATTENDANCE__________CREATE',
  SiteWagesView = 'SITE_WAGES__________VIEW',
  SiteWagesDailyRegisteredWorkHoursCreate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______CREATE',
  SiteWagesDailyRegisteredWorkHoursView = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______VIEW',
  SiteWagesDailyRegisteredWorkHoursUpdate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______UPDATE',
  SiteWagesDailyRegisteredWorkHoursDelete = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______DELETE',
  SiteWagesDailyWagesVerificationCreate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______CREATE',
  SiteWagesDailyWagesVerificationView = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______VIEW',
  SiteWagesDailyWagesVerificationUpdate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______UPDATE',
  SiteWagesDailyWagesVerificationDelete = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______DELETE',
  SiteWagesWagesBookView = 'SITE_WAGES_WAGES_BOOK_______VIEW',
  SiteWagesWagesBookCreate = 'SITE_WAGES_WAGES_BOOK_______CREATE',
  SiteWagesWagesBookUpdate = 'SITE_WAGES_WAGES_BOOK_______UPDATE',
  SiteWagesWagesBookDelete = 'SITE_WAGES_WAGES_BOOK_______DELETE',
  SiteWagesWageCycleClosureCreate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______CREATE',
  SiteWagesWageCycleClosureView = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______VIEW',
  SiteWagesWageCycleClosureUpdate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______UPDATE',
  SiteWagesWageCycleClosureDelete = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______DELETE',
  SiteWagesGeneralSettingsIcon = 'SITE_WAGES_GENERAL_SETTINGS_______ICON',
  SiteWagesGeneralSettingsPublicHolidayCreate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteWagesGeneralSettingsPublicHolidayView = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteWagesGeneralSettingsPublicHolidayUpdate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteWagesGeneralSettingsPublicHolidayDelete = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteWagesGeneralSettingsPublicHolidayCancel = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteWagesGeneralSettingsPublicHolidayActive = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteWagesGeneralSettingsCalendarPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteWagesGeneralSettingsCalendarPolicyView = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteWagesGeneralSettingsCalendarPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteWagesGeneralSettingsWagesRatePolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____CREATE',
  SiteWagesGeneralSettingsWagesRatePolicyView = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  SiteWagesGeneralSettingsWagesRatePolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  SiteInventoryIcon = 'SITE_INVENTORY__________ICON',
  SiteInventoryView = 'SITE_INVENTORY__________VIEW',
  SiteInventoryGoodReceiveNoteCreate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CREATE',
  SiteInventoryGoodReceiveNoteView = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______VIEW',
  SiteInventoryGoodReceiveNoteUpdate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______UPDATE',
  SiteInventoryGoodReceiveNoteApproveReject = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  SiteInventoryGoodReceiveNoteCancel = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CANCEL',
  SiteInventoryGoodReceiveNoteDelete = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______DELETE',
  SiteInventoryStockIssueCreate = 'SITE_INVENTORY_STOCK_ISSUE_______CREATE',
  SiteInventoryStockIssueView = 'SITE_INVENTORY_STOCK_ISSUE_______VIEW',
  SiteInventoryStockIssueUpdate = 'SITE_INVENTORY_STOCK_ISSUE_______UPDATE',
  SiteInventoryStockIssueDelete = 'SITE_INVENTORY_STOCK_ISSUE_______DELETE',
  SiteInventoryStockIssueDownload = 'SITE_INVENTORY_STOCK_ISSUE_______DOWNLOAD',
  SiteInventoryStockIssueApproveReject = 'SITE_INVENTORY_STOCK_ISSUE_______APPROVE_REJECT',
  SiteInventoryStockIssueCancel = 'SITE_INVENTORY_STOCK_ISSUE_______CANCEL',
  SiteInventoryStockTransferInCreate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CREATE',
  SiteInventoryStockTransferInView = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______VIEW',
  SiteInventoryStockTransferInUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______UPDATE',
  SiteInventoryStockTransferInDelete = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DELETE',
  SiteInventoryStockTransferInDownload = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DOWNLOAD',
  SiteInventoryStockTransferInApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______APPROVE_REJECT',
  SiteInventoryStockTransferInCancel = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CANCEL',
  SiteInventoryStockTransferOutCreate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CREATE',
  SiteInventoryStockTransferOutView = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______VIEW',
  SiteInventoryStockTransferOutUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______UPDATE',
  SiteInventoryStockTransferOutDelete = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DELETE',
  SiteInventoryStockTransferOutDownload = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DOWNLOAD',
  SiteInventoryStockTransferOutApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______APPROVE_REJECT',
  SiteInventoryStockTransferOutCancel = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CANCEL',
  SiteInventoryStockAdjustmentCreate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CREATE',
  SiteInventoryStockAdjustmentView = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______VIEW',
  SiteInventoryStockAdjustmentUpdate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______UPDATE',
  SiteInventoryStockAdjustmentDelete = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DELETE',
  SiteInventoryStockAdjustmentDownload = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DOWNLOAD',
  SiteInventoryStockAdjustmentApproveReject = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______APPROVE_REJECT',
  SiteInventoryStockAdjustmentCancel = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CANCEL',
  SiteInventoryStockMovementCreate = 'SITE_INVENTORY_STOCK_MOVEMENT_______CREATE',
  SiteInventoryStockMovementView = 'SITE_INVENTORY_STOCK_MOVEMENT_______VIEW',
  SiteInventoryStockMovementUpdate = 'SITE_INVENTORY_STOCK_MOVEMENT_______UPDATE',
  SiteInventoryStockMovementDelete = 'SITE_INVENTORY_STOCK_MOVEMENT_______DELETE',
  SiteInventoryStockUsageAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______CREATE',
  SiteInventoryStockUsageAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______VIEW',
  SiteInventoryStockUsageAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______DELETE',
  SiteInventoryStockUsageAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryDigitalReportingView = 'SITE_INVENTORY_DIGITAL_REPORTING_______VIEW',
  SiteInventoryDigitalReportingStockMovementLedgerReportView = 'SITE_INVENTORY_DIGITAL_REPORTING_STOCK_MOVEMENT_LEDGER_REPORT____VIEW',
  SiteInventoryGeneralSettingsIcon = 'SITE_INVENTORY_GENERAL_SETTINGS_______ICON',
  SiteInventoryGeneralSettingsSiteMaterialCreate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CREATE',
  SiteInventoryGeneralSettingsSiteMaterialView = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____VIEW',
  SiteInventoryGeneralSettingsSiteMaterialUpdate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPDATE',
  SiteInventoryGeneralSettingsSiteMaterialDelete = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DELETE',
  SiteInventoryGeneralSettingsSiteMaterialCancel = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CANCEL',
  SiteInventoryGeneralSettingsSiteMaterialActive = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____ACTIVE',
  SiteInventoryGeneralSettingsSiteMaterialDownload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DOWNLOAD',
  SiteInventoryGeneralSettingsSiteMaterialUpload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPLOAD',
  SiteInventoryContractSettingsIcon = 'SITE_INVENTORY_CONTRACT_SETTINGS_______ICON',
  SiteSurveillanceIcon = 'SITE_SURVEILLANCE__________ICON',
  SiteSurveillanceView = 'SITE_SURVEILLANCE__________VIEW',
  SiteSurveillanceSiteImagesCreate = 'SITE_SURVEILLANCE_SITE_IMAGES_______CREATE',
  SiteSurveillanceSiteImagesView = 'SITE_SURVEILLANCE_SITE_IMAGES_______VIEW',
  SiteSurveillanceSiteImagesUpdate = 'SITE_SURVEILLANCE_SITE_IMAGES_______UPDATE',
  SiteSurveillanceSiteImagesDelete = 'SITE_SURVEILLANCE_SITE_IMAGES_______DELETE',
  SiteSurveillanceLiveVideocamView = 'SITE_SURVEILLANCE_LIVE_VIDEOCAM_______VIEW',
  SiteSurveillanceGeneralSettingsIcon = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_______ICON',
  SiteSurveillanceGeneralSettingsVideoCamSetupCreate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupView = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SiteSurveillanceGeneralSettingsVideoCamSetupUpdate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupDelete = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  BusinessInsightIcon = 'BUSINESS_INSIGHT__________ICON',
  BusinessInsightView = 'BUSINESS_INSIGHT__________VIEW',
  BusinessInsightDefectStatusAnalysisView = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________VIEW',
  BusinessInsightDefectStatusAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________EXPORT',
  BusinessInsightDefectTradeAnalysisView = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________VIEW',
  BusinessInsightDefectTradeAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________EXPORT',
  BusinessInsightDefectSubconAnalysisView = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________VIEW',
  BusinessInsightDefectSubconAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________EXPORT',
  BusinessInsightDefectsLodgedOverTimeView = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________VIEW',
  BusinessInsightDefectsLodgedOverTimeExport = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________EXPORT',
  BusinessInsightGeneralSettingsIcon = 'BUSINESS_INSIGHT_GENERAL_SETTINGS__________ICON',
  SiteInventorySiteUsageComparisonCreate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______CREATE',
  SiteInventorySiteUsageComparisonView = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______VIEW',
  SiteInventorySiteUsageComparisonUpdate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______UPDATE',
  SiteInventorySiteUsageComparisonDelete = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______DELETE',
  SiteInventorySiteUsageComparisonExport = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______EXPORT',
  SiteInventorySiteWorkerAttendanceTrackingView = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______VIEW',
  SiteInventorySiteWorkerAttendanceTrackingExport = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______EXPORT',
  SiteInventoryStockUsageDailyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageDailyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageDailyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageDailyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageDailyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageMonthlyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageMonthlyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageMonthlyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageMonthlyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageMonthlyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______EXPORT',
  SiteInventoryStockMoveInOutAnalysisCreate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______CREATE',
  SiteInventoryStockMoveInOutAnalysisView = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______VIEW',
  SiteInventoryStockMoveInOutAnalysisUpdate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______UPDATE',
  SiteInventoryStockMoveInOutAnalysisDelete = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______DELETE',
  SiteInventoryStockMoveInOutAnalysisExport = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______EXPORT',
  SiteInventorySiteUsageAnalysisCreate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______CREATE',
  SiteInventorySiteUsageAnalysisView = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______VIEW',
  SiteInventorySiteUsageAnalysisUpdate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______UPDATE',
  SiteInventorySiteUsageAnalysisDelete = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______DELETE',
  SiteInventorySiteUsageAnalysisExport = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______EXPORT',
  SiteSafetyIcon = 'SITE_SAFETY__________ICON',
  SiteSafetyCreate = 'SITE_SAFETY__________CREATE',
  SiteSafetyView = 'SITE_SAFETY__________VIEW',
  ChecklistCategoryIcon = 'CHECKLIST_CATEGORY__________ICON',
  ChecklistCategoryView = 'CHECKLIST_CATEGORY__________VIEW',
  ChecklistCategoryCreate = 'CHECKLIST_CATEGORY__________CREATE',
  ChecklistCategoryDelete = 'CHECKLIST_CATEGORY__________DELETE',
  SafetyChecklistIcon = 'SAFETY_CHECKLIST__________ICON',
  SafetyChecklistView = 'SAFETY_CHECKLIST__________VIEW',
  SafetyChecklistCreate = 'SAFETY_CHECKLIST__________CREATE',
  SafetyChecklistDelete = 'SAFETY_CHECKLIST__________DELETE',
  DigitalDocumentIcon = 'DIGITAL_DOCUMENT__________ICON',
  DigitalDocumentView = 'DIGITAL_DOCUMENT__________VIEW',
  DigitalDocumentCreate = 'DIGITAL_DOCUMENT__________CREATE',
  DigitalDocumentDelete = 'DIGITAL_DOCUMENT__________DELETE',
  DigitalDocumentGeneralSettingsIcon = 'DIGITAL_DOCUMENT_GENERAL_SETTINGS__________ICON',
  SystemAdminIcon = 'SYSTEM_ADMIN__________ICON',
  SystemAdminView = 'SYSTEM_ADMIN__________VIEW',
  SystemAdminProjectSiteView = 'SYSTEM_ADMIN_PROJECT_SITE_______VIEW',
  SystemAdminProjectSiteCreate = 'SYSTEM_ADMIN_PROJECT_SITE_______CREATE',
  SystemAdminProjectSiteUpdate = 'SYSTEM_ADMIN_PROJECT_SITE_______UPDATE',
  SystemAdminProjectSiteDelete = 'SYSTEM_ADMIN_PROJECT_SITE_______DELETE',
  SystemAdminSubconSetupView = 'SYSTEM_ADMIN_SUBCON_SETUP_______VIEW',
  SystemAdminSubconSetupCreate = 'SYSTEM_ADMIN_SUBCON_SETUP_______CREATE',
  SystemAdminSubconSetupUpdate = 'SYSTEM_ADMIN_SUBCON_SETUP_______UPDATE',
  SystemAdminSubconSetupDelete = 'SYSTEM_ADMIN_SUBCON_SETUP_______DELETE',
  SystemAdminTaxSetupView = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW',
  SystemAdminTaxSetupCreate = 'SYSTEM_ADMIN_TAX_SETUP_______CREATE',
  SystemAdminTaxSetupUpdate = 'SYSTEM_ADMIN_TAX_SETUP_______UPDATE',
  SystemAdminTaxSetupDelete = 'SYSTEM_ADMIN_TAX_SETUP_______DELETE',
  SystemAdminTaxSetupActive = 'SYSTEM_ADMIN_TAX_SETUP_______ACTIVE',
  SystemAdminTaxSetupInactive = 'SYSTEM_ADMIN_TAX_SETUP_______INACTIVE',
  SystemAdminTaxSetupViewHistory = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW_HISTORY',
  SystemAdminUomSetupView = 'SYSTEM_ADMIN_UOM_SETUP_______VIEW',
  SystemAdminUomSetupCreate = 'SYSTEM_ADMIN_UOM_SETUP_______CREATE',
  SystemAdminUomSetupUpdate = 'SYSTEM_ADMIN_UOM_SETUP_______UPDATE',
  SystemAdminUomSetupDelete = 'SYSTEM_ADMIN_UOM_SETUP_______DELETE',
  SystemAdminUomSetupActive = 'SYSTEM_ADMIN_UOM_SETUP_______ACTIVE',
  SystemAdminUomSetupInactive = 'SYSTEM_ADMIN_UOM_SETUP_______INACTIVE',
  SystemAdminUomSetupDownload = 'SYSTEM_ADMIN_UOM_SETUP_______DOWNLOAD',
  SystemAdminUomSetupUpload = 'SYSTEM_ADMIN_UOM_SETUP_______UPLOAD',
  SystemAdminUomConversionView = 'SYSTEM_ADMIN_UOM_CONVERSION_______VIEW',
  SystemAdminUomConversionCreate = 'SYSTEM_ADMIN_UOM_CONVERSION_______CREATE',
  SystemAdminUomConversionUpdate = 'SYSTEM_ADMIN_UOM_CONVERSION_______UPDATE',
  SystemAdminUomConversionDelete = 'SYSTEM_ADMIN_UOM_CONVERSION_______DELETE',
  SystemAdminCompanySetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_______VIEW',
  SystemAdminCompanySetupUpdate = 'SYSTEM_ADMIN_COMPANY_SETUP_______UPDATE',
  SystemAdminCompanySetupWatermarkLogoSetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_WATERMARK_LOGO_SETUP____VIEW',
  SystemAdminContractSettingsView = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______VIEW',
  GeneralSettingsIcon = 'GENERAL_SETTINGS__________ICON',
  GeneralSettingsSubcontractTradeView = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  GeneralSettingsSubcontractTradeCreate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  GeneralSettingsSubcontractTradeUpdate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  GeneralSettingsSubcontractTradeInactive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  GeneralSettingsSubcontractTradeActive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  GeneralSettingsSubcontractTradeDelete = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  GeneralSettingsSubcontractTradeDownload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  GeneralSettingsSubcontractTradeUpload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  GeneralSettingsPublicHolidayView = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  GeneralSettingsPublicHolidayCreate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  GeneralSettingsPublicHolidayUpdate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  GeneralSettingsPublicHolidayInactive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____INACTIVE',
  GeneralSettingsPublicHolidayActive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  GeneralSettingsPublicHolidayDelete = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  GeneralSettingsPublicHolidayCancel = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  GeneralSettingsCalendarPolicyView = 'GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  GeneralSettingsCalendarPolicyCreate = 'GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  GeneralSettingsCalendarPolicyUpdate = 'GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  GeneralSettingsWorkingHoursPolicyView = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____VIEW',
  GeneralSettingsWorkingHoursPolicyUpdate = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  GeneralSettingsJobTypeView = 'GENERAL_SETTINGS_JOB_TYPE____VIEW',
  GeneralSettingsJobTypeCreate = 'GENERAL_SETTINGS_JOB_TYPE____CREATE',
  GeneralSettingsJobTypeUpdate = 'GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  GeneralSettingsJobTypeInactive = 'GENERAL_SETTINGS_JOB_TYPE____INACTIVE',
  GeneralSettingsJobTypeActive = 'GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  GeneralSettingsJobTypeCancel = 'GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  GeneralSettingsJobTypeDelete = 'GENERAL_SETTINGS_JOB_TYPE____DELETE',
  GeneralSettingsWagesRatePolicyUpdate = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  GeneralSettingsWagesRatePolicyView = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  GeneralSettingsWorkerProfileView = 'GENERAL_SETTINGS_WORKER_PROFILE____VIEW',
  GeneralSettingsWorkerProfileCreate = 'GENERAL_SETTINGS_WORKER_PROFILE____CREATE',
  GeneralSettingsWorkerProfileUpdate = 'GENERAL_SETTINGS_WORKER_PROFILE____UPDATE',
  GeneralSettingsBuildingMaterialView = 'GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  GeneralSettingsBuildingMaterialCreate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  GeneralSettingsBuildingMaterialUpdate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsBuildingMaterialInactive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsBuildingMaterialActive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsBuildingMaterialDelete = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  GeneralSettingsBuildingMaterialDownload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsBuildingMaterialUpload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteBuildingMaterialView = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____VIEW',
  GeneralSettingsSiteBuildingMaterialCreate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____CREATE',
  GeneralSettingsSiteBuildingMaterialUpdate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsSiteBuildingMaterialInactive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsSiteBuildingMaterialActive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsSiteBuildingMaterialDelete = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DELETE',
  GeneralSettingsSiteBuildingMaterialDownload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsSiteBuildingMaterialUpload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteMaterialPolicyView = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____VIEW',
  GeneralSettingsSiteMaterialPolicyUpdate = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____UPDATE',
  GeneralSettingsStandardApprovalPolicyView = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_______VIEW',
  GeneralSettingsStandardApprovalPolicyUpdate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____UPDATE',
  GeneralSettingsStandardApprovalPolicyCreate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____CREATE',
  GeneralSettingsVideoCamSetupView = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  GeneralSettingsVideoCamSetupCreate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  GeneralSettingsVideoCamSetupUpdate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  GeneralSettingsVideoCamSetupDelete = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  AccessSecurityIcon = 'ACCESS_SECURITY__________ICON',
  AccessSecurityView = 'ACCESS_SECURITY__________VIEW',
  AccessSecurityUserView = 'ACCESS_SECURITY_USER_______VIEW',
  AccessSecurityUserCreate = 'ACCESS_SECURITY_USER_______CREATE',
  AccessSecurityUserUpdate = 'ACCESS_SECURITY_USER_______UPDATE',
  AccessSecurityUserChangePassword = 'ACCESS_SECURITY_USER_______CHANGE_PASSWORD',
  AccessSecurityUserUnblock = 'ACCESS_SECURITY_USER_______UNBLOCK',
  AccessSecurityUserAssign = 'ACCESS_SECURITY_USER_______ASSIGN',
  AccessSecurityRolesView = 'ACCESS_SECURITY_ROLES_______VIEW',
  AccessSecurityRolesCreate = 'ACCESS_SECURITY_ROLES_______CREATE',
  AccessSecurityRolesUpdate = 'ACCESS_SECURITY_ROLES_______UPDATE',
  AccessSecurityRolesDelete = 'ACCESS_SECURITY_ROLES_______DELETE',
  AccessSecurityDigitalReportView = 'ACCESS_SECURITY_DIGITAL_REPORT_______VIEW',
  AccessSecurityProjectRoleAuthView = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____VIEW',
  AccessSecurityProjectRoleAuthCreate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____CREATE',
  AccessSecurityProjectRoleAuthUpdate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____UPDATE',
  CommonSettingsIcon = 'COMMON_SETTINGS__________ICON',
  CommonSettingsTaxSetupView = 'COMMON_SETTINGS_TAX_SETUP_______VIEW',
  CommonSettingsTaxSetupCreate = 'COMMON_SETTINGS_TAX_SETUP_______CREATE',
  CommonSettingsTaxSetupUpdate = 'COMMON_SETTINGS_TAX_SETUP_______UPDATE',
  CommonSettingsTaxSetupDelete = 'COMMON_SETTINGS_TAX_SETUP_______DELETE',
  CommonSettingsTaxSetupInactive = 'COMMON_SETTINGS_TAX_SETUP_______INACTIVE',
  CommonSettingsTaxSetupActive = 'COMMON_SETTINGS_TAX_SETUP_______ACTIVE',
  CommonSettingsTaxSetupViewHistory = 'COMMON_SETTINGS_TAX_SETUP_______VIEW_HISTORY',
  CommonSettingsUomSetupView = 'COMMON_SETTINGS_UOM_SETUP_______VIEW',
  CommonSettingsUomSetupCreate = 'COMMON_SETTINGS_UOM_SETUP_______CREATE',
  CommonSettingsUomSetupUpdate = 'COMMON_SETTINGS_UOM_SETUP_______UPDATE',
  CommonSettingsUomSetupDelete = 'COMMON_SETTINGS_UOM_SETUP_______DELETE',
  CommonSettingsUomSetupInactive = 'COMMON_SETTINGS_UOM_SETUP_______INACTIVE',
  CommonSettingsUomSetupActive = 'COMMON_SETTINGS_UOM_SETUP_______ACTIVE',
  CommonSettingsUomSetupDownload = 'COMMON_SETTINGS_UOM_SETUP_______DOWNLOAD',
  CommonSettingsUomSetupUpload = 'COMMON_SETTINGS_UOM_SETUP_______UPLOAD',
  CommonSettingsUomConversionSetupView = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______VIEW',
  CommonSettingsUomConversionSetupUpdate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______UPDATE',
  CommonSettingsUomConversionSetupCreate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______CREATE',
  CommonSettingsUomConversionSetupDelete = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______DELETE'
}

export type SitePermissionEntity = {
  __typename?: 'SitePermissionEntity';
  ID: Scalars['String'];
  permissionName: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type SiteProgressEntity = AuditEntity & {
  __typename?: 'SiteProgressEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  imagesInfo?: Maybe<Scalars['JSON']>;
};

export type SiteSafetyEntity = AuditEntity & {
  __typename?: 'SiteSafetyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  safetyChecklistID: Scalars['String'];
  safetyChecklist?: Maybe<SafetyChecklistEntity>;
  docDate: Scalars['String'];
  assignedToID?: Maybe<Scalars['String']>;
  status: SiteSafetyStatus;
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
  assignedTo?: Maybe<UserEntity>;
};

export type SiteSafetyItemEntity = AuditEntity & {
  __typename?: 'SiteSafetyItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteSafetyID: Scalars['String'];
  siteSafety?: Maybe<SiteSafetyEntity>;
  safetyChecklistItemID: Scalars['String'];
  safetyChecklistItem?: Maybe<SafetyChecklistItemEntity>;
  isChecked: Scalars['Boolean'];
  remarks?: Maybe<Scalars['String']>;
  followUp?: Maybe<Scalars['Boolean']>;
  diaryTypeID?: Maybe<Scalars['String']>;
  diaryType?: Maybe<DiaryTypeEntity>;
  location?: Maybe<Scalars['String']>;
};

export enum SiteSafetyStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export type SiteStockLedgerEntity = AuditEntity & {
  __typename?: 'SiteStockLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  trackingDate: Scalars['String'];
  trxType: TrxType;
  costItem?: Maybe<CostItemEntity>;
  uom?: Maybe<UomEntity>;
  site?: Maybe<SiteEntity>;
};

export type SiteSubordinateAssignmentEntity = AuditEntity & {
  __typename?: 'SiteSubordinateAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  assignedTo: Scalars['String'];
  dateAssigned: Scalars['DateTime'];
  status: Scalars['String'];
  targetDate: Scalars['DateTime'];
  location: Scalars['String'];
};

export type SiteToDoEntity = AuditEntity & {
  __typename?: 'SiteToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  notes: Scalars['String'];
  status: Scalars['String'];
  closingDate: Scalars['DateTime'];
  closingRemark: Scalars['String'];
  followUp: Scalars['Boolean'];
  diaryType?: Maybe<Scalars['String']>;
  location: Scalars['String'];
};

export type SiteVideoEntity = AuditEntity & {
  __typename?: 'SiteVideoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  videoCamID: Scalars['String'];
};

export type SiteWorkersInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  siteID: Scalars['String'];
  contactNo: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  timeIn?: Maybe<Scalars['DateTime']>;
  timeOut?: Maybe<Scalars['DateTime']>;
  nationality?: Maybe<Nationality>;
  emergencyContact?: Maybe<Scalars['JSON']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Ifca = 'IFCA',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION',
  ReportDesigner = 'REPORT_DESIGNER'
}

export type StandardAuditEntity = {
  created_ts: Scalars['DateTime'];
  created_by?: Maybe<Scalars['String']>;
  mod_ts: Scalars['DateTime'];
  mod_by?: Maybe<Scalars['String']>;
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type StockMovementEntity = AuditEntity & {
  __typename?: 'StockMovementEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  doID?: Maybe<Scalars['String']>;
  do?: Maybe<DoEntity>;
  grnID?: Maybe<Scalars['String']>;
  grn?: Maybe<GrnEntity>;
  grtnID?: Maybe<Scalars['String']>;
  grtn?: Maybe<GoodReturnNoteEntity>;
  docDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  baseUomID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  rejectionRemark?: Maybe<Scalars['String']>;
  cancellationRemark?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  stockMovementStatus: StockMovementStatus;
  stockMovementType: StockMovementType;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  workFlow?: Maybe<Array<WorkFlowEntity>>;
};

export enum StockMovementStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED'
}

export enum StockMovementType {
  Received = 'RECEIVED',
  Returned = 'RETURNED',
  Issue = 'ISSUE',
  TransferIn = 'TRANSFER_IN',
  TransferOut = 'TRANSFER_OUT',
  Adjustment = 'ADJUSTMENT'
}

export type SubconAdjustmentTransaction = AuditEntity & {
  __typename?: 'SubconAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SubconClaimAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  subcontractCostWbsID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  subcontractClaimID?: Maybe<Scalars['String']>;
};

export type SubconClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docDueDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  docNo?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};

export type SubconClaimVoAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  subcontractVOID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  subcontractClaimID?: Maybe<Scalars['String']>;
};

export type SubconClaimVoCostWbsAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  subcontractVOCostWbsID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  subcontractClaimID?: Maybe<Scalars['String']>;
};

export type SubconCoreTransaction = AuditEntity & {
  __typename?: 'SubconCoreTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
};

export type SubconEInvoiceAdjustmentTransaction = AuditEntity & {
  __typename?: 'SubconEInvoiceAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SubconEInvoiceClaimTransaction = AuditEntity & {
  __typename?: 'SubconEInvoiceClaimTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
};

export type SubconItemTaxCoreTransaction = AuditEntity & {
  __typename?: 'SubconItemTaxCoreTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
};

export type SubconRfqSubmissionEntity = AuditEntity & {
  __typename?: 'SubconRFQSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
  subcontractTender?: Maybe<SubcontractTenderEntity>;
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  submissionDate: Scalars['String'];
  quotationNo: Scalars['String'];
  quotationAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type SubconRfqSubmissionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorInvitationID?: Maybe<Scalars['String']>;
  submissionDate?: Maybe<Scalars['String']>;
  quotationAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
  quotationNo?: Maybe<Scalars['String']>;
};

export type SubconTenderGenerationInput = {
  subcontractorID: Scalars['String'];
  subcontractDate: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  taxSchemeID: Scalars['String'];
  creditTerm: Scalars['Float'];
  subcontractSum: Scalars['Float'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum SubconTenderStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Closed = 'CLOSED'
}

export type SubconVoDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
  wbsID: Scalars['String'];
  submissionAmt: Scalars['Float'];
  subcontractCostWbsID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

export type SubcontractActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractStatus: ContractStatus;
};

export type SubcontractAdvanceEntity = AuditEntity & {
  __typename?: 'SubcontractAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  isRecoupment: Scalars['Boolean'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontractor?: Maybe<SubcontractorEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type SubcontractAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  nonEditable: Scalars['Boolean'];
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
};

export type SubcontractApproveRejectInput = {
  subcontractStatus: ContractStatus;
  contractID: Scalars['String'];
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
};

export type SubcontractCnEntity = AuditEntity & {
  __typename?: 'SubcontractCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractCnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractCnItemEntity = AuditEntity & {
  __typename?: 'SubcontractCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  subcontractCNID: Scalars['String'];
  subcontractCN?: Maybe<SubcontractCnEntity>;
  costItem?: Maybe<CostItemEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  subcontractCNTaxItem?: Maybe<Array<SubcontractCnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractCnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractCnTaxItemEntity = AuditEntity & {
  __typename?: 'SubcontractCNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  subcontractCNItemID: Scalars['String'];
  subcontractCNItem?: Maybe<SubcontractCnItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type SubcontractCertificateInput = {
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractClaimAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractCostWbsID: Scalars['String'];
  subcontractCostWbs?: Maybe<SubcontractCostWbsEntity>;
  classificationCodeID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimEntity = AuditEntity & {
  __typename?: 'SubcontractClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  contract?: Maybe<ContractEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  /** CustomFieldResolver */
  revenueAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingCertifiedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  subcontractClaimAllocation?: Maybe<Array<SubcontractClaimAllocationEntity>>;
  subcontractClaimVOCostWbsAllocation?: Maybe<Array<SubcontractClaimVoCostWbsAllocationEntity>>;
  subcontractClaimVOAllocation?: Maybe<Array<SubcontractClaimVoAllocationEntity>>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  claimDetailCostWBS?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimWithDeduction?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  nonEditableAllocation?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingClaimCertified?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  grossAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  adjustmentInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractClaimEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};


export type SubcontractClaimEntityNonEditableAllocationArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
  subcontractorID: Scalars['String'];
  subcontractID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractVOID: Scalars['String'];
  subcontractVO?: Maybe<SubcontractVoEntity>;
};

export type SubcontractClaimVoCostWbsAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimVOCostWbsAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractVOCostWbsID: Scalars['String'];
  subcontractVOCostWbs?: Maybe<SubcontractVoCostWbsEntity>;
};

export type SubcontractCostWbsEntity = AuditEntity & {
  __typename?: 'SubcontractCostWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractID: Scalars['String'];
  /** CustomFieldResolver */
  claimToDateWithTrade?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  taxSchemeID: Scalars['String'];
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  tradeAmt: Scalars['Float'];
  subcontractVOCostWbs?: Maybe<Array<SubcontractVoCostWbsEntity>>;
  subcontractClaimAllocation?: Maybe<Array<SubcontractClaimAllocationEntity>>;
  /** CustomFieldResolver */
  previousAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  revisedTradeAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  tradeVoAmt?: Maybe<Scalars['Float']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractCostWbsEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractCostWbsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  tradeAmt: Scalars['Float'];
  wbsID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
};

export type SubcontractDnEntity = AuditEntity & {
  __typename?: 'SubcontractDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractDnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractDnItemEntity = AuditEntity & {
  __typename?: 'SubcontractDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  subcontractDNID: Scalars['String'];
  subcontractDN?: Maybe<SubcontractDnEntity>;
  costItem?: Maybe<CostItemEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  subcontractDNTaxItem?: Maybe<Array<SubcontractDnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractDnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractDnTaxItemEntity = AuditEntity & {
  __typename?: 'SubcontractDNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  subcontractDNItemID: Scalars['String'];
  subcontractDNItem?: Maybe<SubcontractDnItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type SubcontractEntity = AuditEntity & {
  __typename?: 'SubcontractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
  contractID: Scalars['String'];
  mainContractDetail?: Maybe<ContractEntity>;
  subcontractorID: Scalars['String'];
  subcontractorDetail?: Maybe<SubcontractorEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  tradeDetail?: Maybe<CostCategoryEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  subcontractTenderID?: Maybe<Scalars['String']>;
  subcontractTender?: Maybe<SubcontractTenderEntity>;
  subcontractNo: Scalars['String'];
  subcontractDate: Scalars['String'];
  subcontractSum: Scalars['Float'];
  nominatedSC: Scalars['Boolean'];
  multipleTrade: Scalars['Boolean'];
  submissionComment?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  maxRetentionSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimToDate?: Maybe<Scalars['Float']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  subcontractStatus: ContractStatus;
  subcontractCostWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  VO?: Maybe<Array<SubcontractVoEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  subcontractMiscInvoice?: Maybe<Array<SubcontractMiscInvoiceEntity>>;
  subcontractDN?: Maybe<Array<SubcontractDnEntity>>;
  subcontractCN?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcon?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcon?: Maybe<Array<CNtoSubconEntity>>;
  payment?: Maybe<Array<SubcontractPaymentEntity>>;
  advance?: Maybe<Array<SubcontractAdvanceEntity>>;
  refund?: Maybe<Array<SubcontractRefundEntity>>;
  /** CustomFieldResolver */
  latestRunNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  docNumChecking?: Maybe<Scalars['JSON']>;
  contact?: Maybe<Array<ContactEntity>>;
  /** CustomFieldResolver */
  subcontractVOSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedSubcontractSum?: Maybe<Scalars['Float']>;
  trade: Array<CostCategoryEntity>;
};

export type SubcontractExtensionInput = {
  extensionDate?: Maybe<ExtensionDateInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractGuaranteeCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID?: Maybe<Scalars['String']>;
};

export type SubcontractGuaranteeEntity = AuditEntity & {
  __typename?: 'SubcontractGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
  subcontractID: Scalars['String'];
  guaranteeType?: Maybe<GuaranteeTypeEntity>;
};

export type SubcontractGuaranteeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeStatus?: Maybe<GrntInsStatus>;
};

export type SubcontractGuaranteeInsuranceDeleteInput = {
  ID: Scalars['String'];
};

export type SubcontractInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm?: Maybe<Scalars['Float']>;
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractNo: Scalars['String'];
  subcontractDate: Scalars['String'];
  nominatedSC: Scalars['Boolean'];
  siteID?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  subcontractSum?: Maybe<Scalars['Float']>;
  costCategoryID?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  subcontractStatus?: Maybe<ContractStatus>;
  LADFile?: Maybe<Array<Scalars['Upload']>>;
  SubconTradeFile?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractInsuranceCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID?: Maybe<Scalars['String']>;
};

export type SubcontractInsuranceEntity = AuditEntity & {
  __typename?: 'SubcontractInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
  subcontractID: Scalars['String'];
  insuranceType?: Maybe<InsuranceTypeEntity>;
};

export type SubcontractInsuranceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  insuranceTypeID: Scalars['String'];
  insuranceStatus?: Maybe<GrntInsStatus>;
  remarks?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
};

export type SubcontractMiscInvoiceEntity = AuditEntity & {
  __typename?: 'SubcontractMiscInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  isDaywork?: Maybe<Scalars['Boolean']>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractMiscInvoiceEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractMiscInvoiceItemEntity = AuditEntity & {
  __typename?: 'SubcontractMiscInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  subcontractMiscInvoiceID: Scalars['String'];
  subcontractMiscInvoice?: Maybe<SubcontractMiscInvoiceEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  subcontractMiscInvoiceTaxItem?: Maybe<Array<SubcontractMiscInvoiceTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractMiscInvoiceItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractMiscInvoiceTaxItemEntity = AuditEntity & {
  __typename?: 'SubcontractMiscInvoiceTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  subcontractMiscInvoiceItemID: Scalars['String'];
  subcontractMiscInvoiceItem?: Maybe<SubcontractMiscInvoiceItemEntity>;
  tax?: Maybe<TaxEntity>;
  taxScheme?: Maybe<TaxSchemeEntity>;
};

export type SubcontractPaymentEntity = AuditEntity & {
  __typename?: 'SubcontractPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  paymentTo: Scalars['String'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractPaymentEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type SubcontractPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  paymentTo?: Maybe<Scalars['String']>;
};

export type SubcontractRefundEntity = AuditEntity & {
  __typename?: 'SubcontractRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractRefundEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractRetentionAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractRetentionAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type SubcontractRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  subcontractorID: Scalars['String'];
};

export type SubcontractTenderEntity = AuditEntity & {
  __typename?: 'SubcontractTenderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  siteID: Scalars['String'];
  costCategoryID: Scalars['String'];
  subcontractID?: Maybe<Scalars['String']>;
  refNo: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  budgetAmt: Scalars['Float'];
  tenderDate: Scalars['String'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
  closeDate: Scalars['String'];
  endTime: Scalars['String'];
  subconTenderStatus: SubconTenderStatus;
  costCategory: CostCategoryEntity;
  subconInvitation?: Maybe<Array<SubcontractorInvitationEntity>>;
  mainContractDetail?: Maybe<ContractEntity>;
  subconRfqSubmission?: Maybe<Array<SubconRfqSubmissionEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
};

export type SubcontractTenderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  budgetAmt?: Maybe<Scalars['Float']>;
  tenderDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  closeDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractID: Scalars['String'];
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractTransactionWithGlItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractID: Scalars['String'];
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  chargeToRevenue?: Maybe<Scalars['Boolean']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  isEInvoice?: Maybe<Scalars['Boolean']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<GlItemInput>>;
};

export type SubcontractVo = {
  __typename?: 'SubcontractVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  voSumCount?: Maybe<Scalars['Float']>;
  voOmissionCount?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  voSubconCount?: Maybe<Scalars['Float']>;
};

export type SubcontractVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractVoCostWbsEntity = AuditEntity & {
  __typename?: 'SubcontractVOCostWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractVOID: Scalars['String'];
  subcontractVO?: Maybe<SubcontractVoEntity>;
  subcontractCostWbsID: Scalars['String'];
  subcontractCostWbs?: Maybe<SubcontractCostWbsEntity>;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  subcontractClaimVOCostWbsAllocation?: Maybe<Array<SubcontractClaimVoCostWbsAllocationEntity>>;
  /** CustomFieldResolver */
  previousTradeVoAmt?: Maybe<Scalars['JSON']>;
};

export type SubcontractVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type SubcontractVoEntity = AuditEntity & {
  __typename?: 'SubcontractVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  subcontract?: Maybe<SubcontractEntity>;
  subcontractVOCostWbs?: Maybe<Array<SubcontractVoCostWbsEntity>>;
  subcontractClaimVOAllocation?: Maybe<Array<SubcontractClaimVoAllocationEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  subcontractClaimVOAlloc?: Maybe<Scalars['JSON']>;
};

export type SubcontractVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  contractID: Scalars['String'];
};

export type SubcontractWbsDeleteInput = {
  ID: Scalars['String'];
};

export type SubcontractWbsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractID: Scalars['String'];
  costCategoryID: Scalars['String'];
  wbsID: Scalars['String'];
  tradeAmt: Scalars['Float'];
  submissionComment: Scalars['String'];
  submissionDate: Scalars['String'];
  taxSchemeID: Scalars['String'];
};

export type SubcontractorCCategoryEntity = AuditEntity & {
  __typename?: 'SubcontractorCCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  costCategoryID: Scalars['String'];
  costCategory: CostCategoryEntity;
  subconPackagesData: Array<Scalars['JSON']>;
};

export type SubcontractorCCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
};

export type SubcontractorCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'SubcontractorCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
};

export type SubcontractorCompanyAssignmentInput = {
  subcontractorID: Scalars['String'];
  companyID: Scalars['String'];
};

export type SubcontractorDeleteId = {
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export type SubcontractorEntity = AuditEntity & {
  __typename?: 'SubcontractorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  isCompany: Scalars['Boolean'];
  tinNo?: Maybe<Scalars['String']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  creditorAC?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  ratingType: RatingType;
  isSelfBilled: Scalars['Boolean'];
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  selfBilledCostCategory?: Maybe<CostCategoryEntity>;
  subcontractorTypeID?: Maybe<Scalars['String']>;
  subcontractorType?: Maybe<SubcontractorTypeEntity>;
  nominatedSC: Scalars['Boolean'];
  paymentTo: Scalars['String'];
  statusRemarks?: Maybe<Scalars['String']>;
  cidbDetails: Scalars['JSON'];
  pkkDetails?: Maybe<Scalars['JSON']>;
  contactPerson: Array<ContactEntity>;
  subcontractorPackage?: Maybe<Array<SubcontractorCCategoryEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  subconClaim: Array<SubcontractClaimEntity>;
  subconAdvance?: Maybe<Array<SubcontractAdvanceEntity>>;
  defect: Array<DefectManagementEntity>;
  subconInvitation?: Maybe<Array<SubcontractorInvitationEntity>>;
  subconRfqSubmission?: Maybe<Array<SubconRfqSubmissionEntity>>;
  defectAssignment?: Maybe<Array<DefectAssignmentEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  subcontractorMiscInvoice?: Maybe<Array<SubcontractMiscInvoiceEntity>>;
  subcontractorDN?: Maybe<Array<SubcontractDnEntity>>;
  subcontractorCN?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcontractor?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcontractor?: Maybe<Array<CNtoSubconEntity>>;
  payment?: Maybe<Array<SubcontractPaymentEntity>>;
  refund?: Maybe<Array<SubcontractRefundEntity>>;
  subcontractorCompanyAssignment?: Maybe<Array<SubcontractorCompanyAssignmentEntity>>;
  /** CustomFieldResolver */
  consubconDropdown?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfoNoBound?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueAdvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  activeSubcontract?: Maybe<Scalars['JSON']>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractorEntityOverdueInvInfoNoBoundArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SubcontractorEntityOverdueAdvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SubcontractorEntityActiveSubcontractArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type SubcontractorHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorName?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  emailAddr?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type SubcontractorImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  nominatedSC: Scalars['Boolean'];
  eftPayment: Scalars['Boolean'];
  paymentTo?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  subcontractTrade?: Maybe<Scalars['String']>;
  contactName: Scalars['String'];
  contactDesignation?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contactMobileNo: Scalars['String'];
  subconType?: Maybe<Scalars['String']>;
  ratingType?: Maybe<RatingType>;
  cidbDetails: CidbDetailsInput;
  pkkDetails?: Maybe<PkkDetailsInput>;
  bankDetails?: Maybe<BankDetailsInput>;
};

export type SubcontractorInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  ratingType: RatingType;
  cidbDetails: CidbDetailsInput;
  paymentTo?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Array<Scalars['String']>>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  statusRemarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  nominatedSC: Scalars['Boolean'];
  creditorAC?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  subcontractorTypeID?: Maybe<Scalars['String']>;
  isSelfBilled?: Maybe<Scalars['Boolean']>;
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
};

export type SubcontractorInvitationEntity = AuditEntity & {
  __typename?: 'SubcontractorInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  subconTender?: Maybe<SubcontractTenderEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  /** CustomFieldResolver */
  RFQInfo: Scalars['JSON'];
};

export type SubcontractorInvitationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractTenderID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractorSubconAccPayment = AuditEntity & {
  __typename?: 'SubcontractorSubconAccPayment';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
};

export type SubcontractorTypeDeleteInput = {
  ID: Scalars['String'];
};

export type SubcontractorTypeEntity = AuditEntity & {
  __typename?: 'SubcontractorTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  description: Scalars['String'];
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  subcontractor?: Maybe<Array<SubcontractorEntity>>;
};

export type SubcontractorTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
};

export type SubontractInfo = {
  __typename?: 'SubontractInfo';
  subcontractSum?: Maybe<Scalars['Float']>;
  subcontractDate?: Maybe<Scalars['DateTime']>;
};

export type SubscriptionCompanyType = {
  __typename?: 'SubscriptionCompanyType';
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
};

export type SubscriptionCurrencyType = {
  __typename?: 'SubscriptionCurrencyType';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  symbol?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export type SupplierAccPaymentEntity = AuditEntity & {
  __typename?: 'SupplierAccPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SupplierAccTransaction = AuditEntity & {
  __typename?: 'SupplierAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
};

export type SupplierAdjustmentTransaction = AuditEntity & {
  __typename?: 'SupplierAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SupplierCCategoryEntity = AuditEntity & {
  __typename?: 'SupplierCCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  costCategoryID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  costCategory?: Maybe<CostCategoryEntity>;
};

export type SupplierCCategoryInput = {
  supplierID: Scalars['String'];
  costCategoryID: Scalars['String'];
};

export type SupplierCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'SupplierCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
};

export type SupplierCompanyAssignmentInput = {
  supplierID: Scalars['String'];
  companyID: Scalars['String'];
};

export type SupplierDeleteInput = {
  ID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
};

export type SupplierEInvoiceAdjustmentTransaction = AuditEntity & {
  __typename?: 'SupplierEInvoiceAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SupplierEntity = AuditEntity & {
  __typename?: 'SupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  isCompany: Scalars['Boolean'];
  tinNo?: Maybe<Scalars['String']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  creditorAC?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  ratingType: RatingType;
  isSelfBilled: Scalars['Boolean'];
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  selfBilledCostCategory?: Maybe<CostCategoryEntity>;
  supplierTypeID?: Maybe<Scalars['String']>;
  supplierType?: Maybe<SupplierTypeEntity>;
  costConnection?: Maybe<Array<SupplierCCategoryEntity>>;
  costItemConnection?: Maybe<Array<PricingSupplierEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  SupplierProduct?: Maybe<Array<SupplierCCategoryEntity>>;
  supplierItems?: Maybe<Array<PricingSupplierEntity>>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  negotiatedSupplyItem?: Maybe<Array<NegotiatedSupplyEntity>>;
  plantMachinery?: Maybe<Array<PlantMachineryEntity>>;
  contactPerson: Array<ContactEntity>;
  rfqSubmission: Array<RfqSupplierSubmissionEntity>;
  invitedRFQ: Array<RfqSupplierInvitationEntity>;
  supplierInv?: Maybe<Array<ApInvoiceEntity>>;
  supplierDN?: Maybe<Array<ApDebitNoteEntity>>;
  supplierCN?: Maybe<Array<ApCreditNoteEntity>>;
  supplierDNS?: Maybe<Array<DNtoSupplierEntity>>;
  cnToSupplier?: Maybe<Array<CNtoSupplierEntity>>;
  supplierAdvance?: Maybe<Array<ApPaymentEntity>>;
  supplierPayment?: Maybe<Array<ApPaymentEntity>>;
  supplierRefund?: Maybe<Array<ApRefundEntity>>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  supplierCompanyAssignment?: Maybe<Array<SupplierCompanyAssignmentEntity>>;
  costCategory: Array<CostCategoryEntity>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  outstandingInfo?: Maybe<Scalars['JSON']>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
  latestSubmittedQuotation?: Maybe<RfqSupplierSubmissionEntity>;
  rfq: Array<RfqEntity>;
  /** CustomFieldResolver */
  indelibleAccount?: Maybe<Scalars['Boolean']>;
};


export type SupplierEntityRfqSubmissionArgs = {
  rfqID?: Maybe<Scalars['String']>;
};


export type SupplierEntityOverdueInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SupplierEntityOutstandingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SupplierEntityLatestSubmittedQuotationArgs = {
  rfqID?: Maybe<Scalars['String']>;
};

export type SupplierImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  eftPayment: Scalars['Boolean'];
  contactName: Scalars['String'];
  contactDesignation?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contactMobileNo: Scalars['String'];
  suppType?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  ratingType?: Maybe<RatingType>;
  bankDetails?: Maybe<BankDetailsInput>;
};

export type SupplierInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  bankDetails?: Maybe<BankDetailsInput>;
  ratingType: RatingType;
  taxSchemeID: Scalars['String'];
  associateID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierTypeID?: Maybe<Scalars['String']>;
  isSelfBilled?: Maybe<Scalars['Boolean']>;
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
};

export type SupplierTypeDeleteInput = {
  ID: Scalars['String'];
};

export type SupplierTypeEntity = AuditEntity & {
  __typename?: 'SupplierTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  description: Scalars['String'];
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  supplier?: Maybe<Array<SupplierEntity>>;
};

export type SupplierTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
};

export type TankEntity = AuditEntity & {
  __typename?: 'TankEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  tankType: TankType;
  name: Scalars['String'];
  regNo: Scalars['String'];
  plantMachineryID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  fuelConsumptionLowerLimit: Scalars['Float'];
  fuelConsumptionUpperLimit: Scalars['Float'];
  fuelConsumptionMethod: FuelConsumptionMethod;
  serialNo?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  plantMachinery?: Maybe<PlantMachineryEntity>;
};

export type TankMachinery = PlantMachineryEntity | PnmRentalEntity;

export type TankObjectType = {
  __typename?: 'TankObjectType';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
};

export enum TankType {
  NoTank = 'NO_TANK',
  FixedTank = 'FIXED_TANK',
  MobileTank = 'MOBILE_TANK'
}

export enum TaxCategory {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE'
}

export enum TaxClass {
  Input = 'INPUT',
  Output = 'OUTPUT'
}

export type TaxDetail = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  accountId?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
};

export type TaxDetails = {
  __typename?: 'TaxDetails';
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxDetailsInput = {
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxEffective = {
  __typename?: 'TaxEffective';
  ID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  modBy?: Maybe<Scalars['String']>;
};

export type TaxEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  modBy?: Maybe<Scalars['String']>;
};

export type TaxInput = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetail>;
};

export type TaxListingOutput = {
  __typename?: 'TaxListingOutput';
  tax_id: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  class_type: Scalars['String'];
  tax_status: Scalars['String'];
  effective_date: Scalars['DateTime'];
  rate: Scalars['String'];
  scheme_type_id: Scalars['String'];
  createdTs: Scalars['DateTime'];
};

export type TaxObject = {
  __typename?: 'TaxObject';
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxDate?: Maybe<Scalars['DateTime']>;
};

export type TaxSchemeTypeListingOutput = {
  __typename?: 'TaxSchemeTypeListingOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  modTs: Scalars['DateTime'];
};

export type TaxSchemeTypeSortByOutput = {
  __typename?: 'TaxSchemeTypeSortByOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  modTs: Scalars['DateTime'];
  count: Scalars['Float'];
};

export type TestServiceInput = {
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  inputStatus?: Maybe<WorkFlowStatus>;
};

export enum ToDoStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Pending = 'PENDING',
  Closed = 'CLOSED'
}

export enum ToDoType {
  ArClaim = 'AR_CLAIM',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArMiscInv = 'AR_MISC_INV',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ApClaim = 'AP_CLAIM',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApMiscInv = 'AP_MISC_INV',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Vo = 'VO',
  Svo = 'SVO',
  Subcontract = 'SUBCONTRACT',
  Df = 'DF',
  Budget = 'BUDGET',
  UtilizeBudget = 'UTILIZE_BUDGET',
  TransferBudget = 'TRANSFER_BUDGET',
  Default = 'DEFAULT',
  Rental = 'RENTAL',
  Assignment = 'ASSIGNMENT',
  Maintenance = 'MAINTENANCE'
}

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type TrackingLog = {
  __typename?: 'TrackingLog';
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLogStatus?: Maybe<Scalars['String']>;
};

export type TrackingLogInput = {
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLogStatus?: Maybe<Scalars['String']>;
};

export enum TransactionStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export enum TransactionType {
  Advances = 'ADVANCES',
  AdvanceRecoupment = 'ADVANCE_RECOUPMENT',
  Claims = 'CLAIMS',
  ClaimsRetention = 'CLAIMS_RETENTION',
  ClaimsMos = 'CLAIMS_MOS',
  ClaimsDaywork = 'CLAIMS_DAYWORK',
  ClaimReversal = 'CLAIM_REVERSAL',
  RetentionRelease = 'RETENTION_RELEASE',
  DebitNote = 'DEBIT_NOTE',
  CreditNote = 'CREDIT_NOTE',
  Receipt = 'RECEIPT',
  Refunds = 'REFUNDS',
  ClientDebitNotes = 'CLIENT_DEBIT_NOTES'
}

export type TreeCostCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costItems?: Maybe<Array<CostItems>>;
  costClass?: Maybe<CostClass>;
  tankType?: Maybe<TankType>;
  pnmCostCat?: Maybe<PnmCostCatInput>;
  fuelConsumption?: Maybe<FuelConsumptionInput>;
  parentCostCategoryID?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
};

export enum TrxType {
  Grn = 'GRN',
  IssueOut = 'ISSUE_OUT',
  TransferOut = 'TRANSFER_OUT',
  TransferIn = 'TRANSFER_IN',
  Wastage = 'WASTAGE'
}

export type Uom = {
  __typename?: 'UOM';
  uomID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type UomDeleteInput = {
  ID: Scalars['String'];
};

export type UomEntity = AuditEntity & {
  __typename?: 'UOMEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  commonStatus: CommonStatus;
  poItem?: Maybe<Array<PoItemEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  negoItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  siteMaterial?: Maybe<Array<SiteMaterialEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  bq?: Maybe<Array<BqEntity>>;
};

export type UomImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  newUOM?: Maybe<Scalars['Boolean']>;
  newName?: Maybe<Scalars['String']>;
  newCode?: Maybe<Scalars['String']>;
};

export type UomInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UomExchangeEntity = AuditEntity & {
  __typename?: 'UomExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  fromUomID: Scalars['String'];
  toUomID: Scalars['String'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  uomLog?: Maybe<Array<Scalars['JSON']>>;
  fromUOM: UomEntity;
  toUOM: UomEntity;
};

export type UomExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
  fromAmt?: Maybe<Scalars['Float']>;
  toAmt?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  uomLog?: Maybe<Array<ExchangeLogInput>>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};

export type UpdateGlExportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  transactionDate: Scalars['String'];
};

export type UpdateProfitRecogFormulaInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  profitRecogFormulaID: Scalars['String'];
};

export type UpdateSubcontractorInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  paymentTo?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  nominatedSC: Scalars['Boolean'];
  ratingType?: Maybe<RatingType>;
  cidbDetails?: Maybe<CidbDetailsInput>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  bankDetails?: Maybe<BankDetailsInput>;
  address?: Maybe<AddressInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UpdateUserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
  confirmedPassword: Scalars['String'];
};


export type UserCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'UserCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  userID: Scalars['String'];
};

export type UserCompanyAssignmentInput = {
  userID: Scalars['String'];
  companyID: Scalars['String'];
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserListInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export enum UserSelection {
  All = 'ALL',
  Superuser = 'SUPERUSER',
  Nonsuper = 'NONSUPER'
}

export type Vo = {
  __typename?: 'VO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  contractSum?: Maybe<Scalars['Float']>;
  revisedContractSum?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
};

export type VoHealthIndex = {
  __typename?: 'VOHealthIndex';
  conVOOmission?: Maybe<Scalars['Float']>;
  conVOSum?: Maybe<Scalars['Float']>;
  conVOPendingOmission?: Maybe<Scalars['Float']>;
  conVOPendingSum?: Maybe<Scalars['Float']>;
  subVOOmission?: Maybe<Scalars['Float']>;
  subVOSum?: Maybe<Scalars['Float']>;
  subVOPendingOmission?: Maybe<Scalars['Float']>;
  subVOPendingSum?: Maybe<Scalars['Float']>;
  totalVO?: Maybe<Scalars['Float']>;
  totalPendingVO?: Maybe<Scalars['Float']>;
  totalSubVO?: Maybe<Scalars['Float']>;
  totalPendingSubVO?: Maybe<Scalars['Float']>;
  totalVOOmission?: Maybe<Scalars['Float']>;
  totalVOSum?: Maybe<Scalars['Float']>;
};

export type VoItemApprovedAmountInput = {
  ID?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
};

export enum VoStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Cancel = 'CANCEL',
  Partial = 'PARTIAL',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Close = 'CLOSE',
  Complete = 'COMPLETE',
  Pending = 'PENDING'
}

export enum VoType {
  Addition = 'ADDITION',
  Omission = 'OMISSION'
}

export type VideoCamDeleteInput = {
  ID: Scalars['String'];
};

export type VideoCamEntity = AuditEntity & {
  __typename?: 'VideoCamEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  name: Scalars['String'];
  networkConnection: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
};

export type VideoCamInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID: Scalars['String'];
  name: Scalars['String'];
  networkConnection: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type VoOmission = {
  __typename?: 'VoOmission';
  omission: Scalars['Float'];
};

export type VoSum = {
  __typename?: 'VoSUM';
  sum?: Maybe<Scalars['Float']>;
};

export type WbsAndBudgetExcelInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  WBSCode?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['String']>;
  RevenueCategory?: Maybe<Scalars['String']>;
  CostItem?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UOM?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  RowNo?: Maybe<Scalars['Float']>;
  BudgetAmt?: Maybe<Scalars['Float']>;
  RevenueAmount?: Maybe<Scalars['Float']>;
};

export type WbsBudgetDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  creditTerm: Scalars['Float'];
  budgetAmt: Scalars['Float'];
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
  budgetType: BudgetType;
  uomID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
};

export type WbsBudgetTransferActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  budgetStatus: Scalars['String'];
  contractID: Scalars['String'];
};

export type WbsBudgetTransferDetailEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsBudgetTransferHeaderID: Scalars['String'];
  wbsBudgetTransferHeader: WbsBudgetTransferHeaderEntity;
  fromCostCategoryID?: Maybe<Scalars['String']>;
  fromCostCategory?: Maybe<CostCategoryEntity>;
  toCostCategoryID?: Maybe<Scalars['String']>;
  toCostCategory?: Maybe<CostCategoryEntity>;
  fromCostItemID?: Maybe<Scalars['String']>;
  fromCostItem?: Maybe<CostItemEntity>;
  toCostItemID?: Maybe<Scalars['String']>;
  toCostItem?: Maybe<CostItemEntity>;
  fromQty: Scalars['Float'];
  toQty: Scalars['Float'];
  transferQty: Scalars['Float'];
  transferAmt: Scalars['Float'];
  remarks: Scalars['String'];
};

export type WbsBudgetTransferEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  fromWbsID: Scalars['String'];
  fromWbs: WbsEntity;
  toWbsID: Scalars['String'];
  toWbs: WbsEntity;
  fromCostCategoryID: Scalars['String'];
  fromCostCategory: CostCategoryEntity;
  toCostCategoryID: Scalars['String'];
  toCostCategory: CostCategoryEntity;
  transferDate: Scalars['String'];
  transferQty: Scalars['Float'];
  transferAmt: Scalars['Float'];
  remarks: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus: BudgetStatus;
};

export type WbsBudgetTransferHeaderEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  contract: ContractEntity;
  fromWbsID: Scalars['String'];
  fromWbs: WbsEntity;
  toWbsID: Scalars['String'];
  toWbs: WbsEntity;
  transferDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus: BudgetStatus;
  wbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
};

export type WbsDayworkInput = {
  ID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
  revenueCategoryID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
};

export type WbsEntity = AuditEntity & {
  __typename?: 'WBSEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  parentWBSID?: Maybe<Scalars['String']>;
  sequence: Scalars['Float'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  isLastNode: Scalars['Boolean'];
  isDefault?: Maybe<Scalars['Boolean']>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  wbsCostSubcontract?: Maybe<Array<SubcontractCostWbsEntity>>;
  wbsSchedule?: Maybe<Array<WbsScheduleEntity>>;
  wbsUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  subcontractAccount?: Maybe<Array<ContractAccountEntity>>;
  fromWbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  toWbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  APInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  bqWbsAssignment?: Maybe<Array<BqWbsAssignmentEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  childrenWbs?: Maybe<Array<WbsEntity>>;
  parentWbs?: Maybe<WbsEntity>;
  budget: Array<WbsBudgetEntity>;
  /** CustomFieldResolver */
  finalSequence?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  originalCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  actualCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  parentName?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  committedCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  sumContractVORevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  originalRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  actualRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  anticipatedProfit?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  wbsProfitStatus?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalDN?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  originalCostByClass?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  costByClass?: Maybe<Scalars['JSON']>;
  originalRevenueDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  revenueDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  originalCostDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  costDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  latestWbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  /** CustomFieldResolver */
  bqAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  bqCount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  wbsParentChildSequence?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  childrenIDs?: Maybe<Array<Scalars['String']>>;
};


export type WbsEntityOriginalCostArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityRevisedCostArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityTotalCostArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalRevenueArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityRevisedRevenueArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityTotalRevenueArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityAnticipatedProfitArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityWbsProfitStatusArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalCostByClassArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityCostByClassArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalRevenueDetailArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityRevenueDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalCostDetailArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityCostDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityLatestWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type WbsImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  parentWbsCode?: Maybe<Scalars['String']>;
  wbsCode?: Maybe<Scalars['String']>;
  wbsName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  costClass?: Maybe<Scalars['String']>;
  costName?: Maybe<Scalars['String']>;
  costItem?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  uom?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  budgetAmount?: Maybe<Scalars['Float']>;
  revenueCategory?: Maybe<Scalars['String']>;
  revenueAmount?: Maybe<Scalars['Float']>;
};

export type WbsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  parentWBSID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type WbsUtilizedBudgetActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  budgetStatus: Scalars['String'];
  contractID: Scalars['String'];
};

export type WbsUtilizedBudgetDeleteInput = {
  ID: Scalars['String'];
};

export type WbsUtilizedBudgetInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
  costCategoryID: Scalars['String'];
  year?: Maybe<Scalars['Float']>;
  utilizedQty?: Maybe<Scalars['Float']>;
  utilizedAmt?: Maybe<Scalars['Float']>;
  budgetStatus?: Maybe<Scalars['String']>;
};

export type WageCycleDetailEntity = AuditEntity & {
  __typename?: 'WageCycleDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wageCycleID: Scalars['String'];
  workerID: Scalars['String'];
  wageAmt: Scalars['Float'];
  wageCycle?: Maybe<WageCycleEntity>;
  worker?: Maybe<WorkerEntity>;
};

export type WageCycleEntity = AuditEntity & {
  __typename?: 'WageCycleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  workerWageID?: Maybe<Scalars['String']>;
  closedBy: Scalars['String'];
  docDate: Scalars['String'];
  site?: Maybe<SiteEntity>;
  wageCycleDetail?: Maybe<Array<WageCycleDetailEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
};

export enum WageStatus {
  Draft = 'DRAFT',
  Submit = 'SUBMIT',
  Reject = 'REJECT',
  Approved = 'APPROVED'
}

export enum WageType {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  None = 'NONE'
}

export type WagesRatePolicyDeleteInput = {
  ID: Scalars['String'];
};

export type WagesRatePolicyEntity = AuditEntity & {
  __typename?: 'WagesRatePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type WagesRatePolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  holidayTypeID?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type WbsBudgetActionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  reason?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WbsBudgetDetailEntity = AuditEntity & {
  __typename?: 'WbsBudgetDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  budgetID: Scalars['String'];
  budget: WbsBudgetEntity;
  wbsID: Scalars['String'];
  wbs: WbsEntity;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  uomID?: Maybe<Scalars['String']>;
  uom?: Maybe<UomEntity>;
  creditTerm: Scalars['Float'];
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  budgetAmt?: Maybe<Scalars['Float']>;
  remark?: Maybe<Scalars['String']>;
  updateSchedule: Scalars['Boolean'];
  budgetType: BudgetType;
  wbsBudgetDetailSchedule?: Maybe<Array<WbsBudgetDetailScheduleEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  /** CustomFieldResolver */
  trackQty?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  originalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  originalQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  reservedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  reservedQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  utilisedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  utilisedQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceQtyPurchasing?: Maybe<Scalars['Float']>;
  fromCostCategory: Scalars['String'];
  toCostCategory: Scalars['String'];
};


export type WbsBudgetDetailEntityTrackQtyArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityOriginalAmtArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityOriginalQtyArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityReservedAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityReservedQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityUtilisedAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityUtilisedQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  prID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceQtyPurchasingArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  poID?: Maybe<Scalars['String']>;
  prID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};

export type WbsBudgetDetailScheduleEntity = AuditEntity & {
  __typename?: 'WbsBudgetDetailScheduleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsBudgetDetailID: Scalars['String'];
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsScheduleID: Scalars['String'];
  wbsSchedule?: Maybe<WbsScheduleEntity>;
  scheduleAmt: Scalars['Float'];
};

export type WbsBudgetEntity = AuditEntity & {
  __typename?: 'WbsBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  copyFromBudgetID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  version: Scalars['Float'];
  budgetStatus: BudgetStatus;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetWbsDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  copyFromBudget?: Maybe<WbsBudgetEntity>;
  wbs: Array<WbsEntity>;
  totalCostRevenue: Scalars['JSON'];
};


export type WbsBudgetEntityTotalCostRevenueArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type WbsBudgetInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
};

export type WbsBudgetRevisionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  copyFromBudgetID: Scalars['String'];
  contractID: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  reason: Scalars['String'];
};

export type WbsBudgetSubmitApproveInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WbsBudgetTransferCostCategoryBalanceFilterArgs = {
  wbsID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
};

export type WbsBudgetTransferInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  docRef?: Maybe<Scalars['String']>;
  fromWbsID: Scalars['String'];
  toWbsID: Scalars['String'];
  transferDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WbsBudgetTransferItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromCostID: Scalars['String'];
  fromIsCostItem: Scalars['Boolean'];
  toCostID: Scalars['String'];
  toIsCostItem: Scalars['Boolean'];
  fromQty: Scalars['Float'];
  toQty: Scalars['Float'];
  transferAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type WbsDayworkCostRevenueEntity = AuditEntity & {
  __typename?: 'WbsDayworkCostRevenueEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
};

export type WbsScheduleEntity = AuditEntity & {
  __typename?: 'WbsScheduleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsID: Scalars['String'];
  wbs: WbsEntity;
  period: Scalars['Float'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  wbsBudgetDetailSchedule?: Maybe<Array<WbsBudgetDetailScheduleEntity>>;
  /** CustomFieldResolver */
  code?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  budgetDetailScheduleAmt?: Maybe<Scalars['Float']>;
};


export type WbsScheduleEntityBudgetDetailScheduleAmtArgs = {
  wbsBudgetDetailID?: Maybe<Scalars['String']>;
};

export type WbsScheduleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wbsID?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type WbsUtilizedBudgetEntity = AuditEntity & {
  __typename?: 'WbsUtilizedBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  year: Scalars['Float'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  utilizedQty?: Maybe<Scalars['Float']>;
  utilizedAmt: Scalars['Float'];
  budgetStatus: BudgetStatus;
};

export type Weekend = {
  __typename?: 'Weekend';
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WeekendInput = {
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WorkDeskInput = {
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  statusName: Scalars['String'];
  refTable: Scalars['String'];
  approvalStatus: WorkFlowStatus;
  approveRejectDate: Scalars['DateTime'];
};

export type WorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'WorkFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workFlowStepID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  approverID?: Maybe<Scalars['String']>;
  approvalDT?: Maybe<Scalars['String']>;
  notifiedDT?: Maybe<Scalars['String']>;
  approvalStatus: WorkFlowStatus;
  statusRemarks: Scalars['String'];
};

export type WorkFlowEntity = AuditEntity & {
  __typename?: 'WorkFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalPolicyAssignmentID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  refID: Scalars['String'];
  refTable: Scalars['String'];
  submitterID: Scalars['String'];
  submitDate: Scalars['String'];
  workFlowStatus: WorkFlowStatus;
  workFlowStep?: Maybe<Array<WorkFlowStepEntity>>;
};

export type WorkFlowInput = {
  accountID?: Maybe<Scalars['String']>;
  approvalPolicyAssignmentID?: Maybe<Scalars['String']>;
  submitterID?: Maybe<Scalars['String']>;
  submitDate?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  workFlowStatus?: Maybe<WorkFlowStatus>;
  createdTs?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export enum WorkFlowStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED'
}

export type WorkFlowStepEntity = AuditEntity & {
  __typename?: 'WorkFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workFlowID: Scalars['String'];
  approvalRoleID: Scalars['String'];
  notifiedDT: Scalars['String'];
  stepNo: Scalars['Float'];
  approvalStatus: WorkFlowStatus;
  statusRemarks: Scalars['String'];
  workFlow?: Maybe<WorkFlowEntity>;
};

export type WorkFlowStepInput = {
  workFlowStepID?: Maybe<Scalars['String']>;
  approverID?: Maybe<Scalars['String']>;
  notifiedDT?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  stepNo?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  approvalPolicyAssignmentID?: Maybe<Scalars['String']>;
  subscriptionAccountID?: Maybe<Scalars['String']>;
  userList?: Maybe<Array<UserListInput>>;
  predicate?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
};

export type WorkerAdvanceEntity = AuditEntity & {
  __typename?: 'WorkerAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workerID: Scalars['String'];
  docDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus: WageStatus;
  worker?: Maybe<WorkerEntity>;
};

export type WorkerAttendanceDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerAttendanceEntity = AuditEntity & {
  __typename?: 'WorkerAttendanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workDate: Scalars['String'];
  temperature?: Maybe<Scalars['Float']>;
  workerID: Scalars['String'];
  siteID: Scalars['String'];
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  worker?: Maybe<WorkerEntity>;
  workerWage: WorkerWageEntity;
  /** CustomFieldResolver */
  attendanceSummary?: Maybe<Scalars['JSON']>;
};

export type WorkerAttendanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  workDate?: Maybe<Scalars['String']>;
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  wageStatus?: Maybe<WageStatus>;
};

export type WorkerDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerDocuments = {
  __typename?: 'WorkerDocuments';
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
};

export type WorkerEntity = AuditEntity & {
  __typename?: 'WorkerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  contactID: Scalars['String'];
  jobTypeID: Scalars['String'];
  jobType?: Maybe<JobTypeEntity>;
  identityNo: Scalars['String'];
  nationality: Nationality;
  workPermitNo?: Maybe<Scalars['String']>;
  docExpiryDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  wageRate: Scalars['Float'];
  wageType: WageType;
  commonStatus: CommonStatus;
  workerAttendance?: Maybe<Array<WorkerAttendanceEntity>>;
  workerAdvance?: Maybe<Array<WorkerAdvanceEntity>>;
  wageCycleDetail?: Maybe<Array<WageCycleDetailEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
  contact: ContactEntity;
  attachment?: Maybe<WorkerDocuments>;
  workerSiteAssignment?: Maybe<Array<WorkerSiteAssignmentEntity>>;
  siteResolver: Array<SiteEntity>;
};

export type WorkerHour = {
  __typename?: 'WorkerHour';
  ID: Scalars['String'];
  workerID?: Maybe<Scalars['String']>;
  verifiedHour: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkerHourInput = {
  ID: Scalars['String'];
  workerID?: Maybe<Scalars['String']>;
  verifiedHour: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  jobTypeID?: Maybe<Scalars['String']>;
  identityNo?: Maybe<Scalars['String']>;
  workPermitNo?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  wageRate?: Maybe<Scalars['Float']>;
  docExpiryDate?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  wageType?: Maybe<WageType>;
  nationality?: Maybe<Nationality>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WorkerSiteAssignmentEntity = AuditEntity & {
  __typename?: 'WorkerSiteAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workerID: Scalars['String'];
  worker?: Maybe<WorkerEntity>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
};

export type WorkerWageCycle = {
  __typename?: 'WorkerWageCycle';
  workerID: Scalars['String'];
  totalWage: Scalars['Float'];
};

export type WorkerWageCycleInput = {
  workerID: Scalars['String'];
  totalWage: Scalars['Float'];
};

export type WorkerWageEntity = AuditEntity & {
  __typename?: 'WorkerWageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  workerID: Scalars['String'];
  workerAttendanceID?: Maybe<Scalars['String']>;
  workDate: Scalars['String'];
  workHour: Scalars['Float'];
  verifiedHour?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus: WageStatus;
  site?: Maybe<SiteEntity>;
  worker?: Maybe<WorkerEntity>;
  workerAttendance?: Maybe<WorkerAttendanceEntity>;
  wageCycle?: Maybe<WageCycleEntity>;
  workerWageHours?: Maybe<Scalars['JSON']>;
  workerWageAmount: Scalars['JSON'];
};


export type WorkerWageEntityWorkerWageAmountArgs = {
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkingHourPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type WorkingHourPolicyEntity = AuditEntity & {
  __typename?: 'WorkingHourPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  shiftStartTime: Scalars['String'];
  shiftEndTime: Scalars['String'];
  halfStartTime: Scalars['String'];
  halfEndTime: Scalars['String'];
  commonStatus: CommonStatus;
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type WorkingHourPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  shiftStartTime?: Maybe<Scalars['String']>;
  shiftEndTime?: Maybe<Scalars['String']>;
  halfStartTime?: Maybe<Scalars['String']>;
  halfEndTime?: Maybe<Scalars['String']>;
};

export type WorkshopEntity = AuditEntity & {
  __typename?: 'WorkshopEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  contactPerson: Array<Scalars['JSON']>;
  workshopStatus: WorkshopStatus;
  ratingType: RatingType;
  costConnection?: Maybe<Array<PnmCategoryWorkshopEntity>>;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
};

export type WorkshopInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  address?: Maybe<AddressInput>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  workshopStatus?: Maybe<WorkshopStatus>;
  ratingType: RatingType;
};

export enum WorkshopStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blacklisted = 'BLACKLISTED'
}

export type AdvanceFromClient = {
  __typename?: 'advanceFromClient';
  advClientListing?: Maybe<Scalars['JSON']>;
  latestAdvClientDate?: Maybe<Scalars['DateTime']>;
  advClientAmt?: Maybe<Scalars['Float']>;
};

export type AssignBqWbsAssignment = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  bqID: Scalars['String'];
};

export type BatchWorkAttendanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  workDate?: Maybe<Scalars['String']>;
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  wageStatus?: Maybe<WageStatus>;
  identityNo?: Maybe<Scalars['String']>;
  totalHour?: Maybe<Scalars['Float']>;
  ot?: Maybe<Scalars['Float']>;
  publicHoliday?: Maybe<Scalars['Float']>;
  weekend?: Maybe<Scalars['Float']>;
};

export type CompletionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  completionPerc: Scalars['Float'];
  contractID?: Maybe<Scalars['String']>;
};

export type DefectLogInput = {
  costCategoryID?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type EInvoiceClassificationEntity = {
  __typename?: 'eInvoiceClassificationEntity';
  id: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EInvoiceGeneralTinEntity = {
  __typename?: 'eInvoiceGeneralTinEntity';
  id: Scalars['String'];
  tinParty?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  displaySeq?: Maybe<Scalars['Float']>;
};

export type EInvoiceMsicEntity = {
  __typename?: 'eInvoiceMsicEntity';
  id: Scalars['String'];
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
  companyMsicAssignment: Array<CompanyMsicAssignmentEntity>;
};

export type Items = {
  __typename?: 'items';
  description?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
};

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type PmStatus = {
  __typename?: 'pmStatus';
  available?: Maybe<Scalars['Float']>;
  assigned?: Maybe<Scalars['Float']>;
  outOfOrder?: Maybe<Scalars['Float']>;
  maintenance?: Maybe<Scalars['Float']>;
};

export type PoUomExchange = {
  __typename?: 'poUOMExchange';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type PrInfo = {
  prItemID: Scalars['String'];
  totalAmt?: Maybe<Scalars['Float']>;
};

export type PrItemInfo = {
  __typename?: 'prItemInfo';
  prItemID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  uomCode?: Maybe<Scalars['String']>;
  rfqItemID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  negoSupplyItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  requestedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['JSON']>;
  prDate?: Maybe<Scalars['DateTime']>;
  docDate?: Maybe<Scalars['DateTime']>;
  expectedDate?: Maybe<Scalars['DateTime']>;
  negotiatedItemStatus?: Maybe<Scalars['Boolean']>;
  supplier?: Maybe<Array<SupplierEntity>>;
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RunningNumberSetupInput = {
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type SubconVo = {
  __typename?: 'subconVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  subcontractSum?: Maybe<Scalars['Float']>;
  revisedSubcontractSum?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  totalPendingVo?: Maybe<Scalars['Float']>;
};

export type TaxDetailEntity = {
  __typename?: 'taxDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxId: Scalars['String'];
  tax: TaxEntity;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
  commonStatus: Scalars['String'];
};

export type TaxEntity = {
  __typename?: 'taxEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  schemeTypeId: Scalars['String'];
  schemeType: TaxSchemeTypeEntity;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  taxDetail: Array<TaxDetailEntity>;
  taxSchemeDetailItem: Array<TaxSchemeDetailItemEntity>;
};

export type TaxOnSeqInput = {
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

/** JsonType */
export type TaxOnSeqObjects = {
  __typename?: 'taxOnSeqObjects';
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type TaxSchemeDetailEntity = {
  __typename?: 'taxSchemeDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  taxScheme: TaxSchemeEntity;
  effectiveDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  taxSchemeDetailItems?: Maybe<Array<TaxSchemeDetailItemEntity>>;
};

export type TaxSchemeDetailItemEntity = {
  __typename?: 'taxSchemeDetailItemEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeDetailId: Scalars['String'];
  taxSchemeDetail: TaxSchemeDetailEntity;
  taxId: Scalars['String'];
  tax: TaxEntity;
  computeMethod: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  commonStatus: Scalars['String'];
};

export type TaxSchemeEntity = {
  __typename?: 'taxSchemeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  commonStatus: Scalars['String'];
  taxSchemeDetails?: Maybe<Array<TaxSchemeDetailEntity>>;
};

export type TaxSchemeTypeEntity = {
  __typename?: 'taxSchemeTypeEntity';
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  softwareCode: Scalars['String'];
  commonStatus: Scalars['String'];
  tax?: Maybe<Array<TaxEntity>>;
};

export type TotalDoAmt = {
  __typename?: 'totalDOAmt';
  total?: Maybe<Scalars['Float']>;
};

export type UnassignBqWbsAssignment = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  bqID: Scalars['String'];
};

export type UomExchange = {
  __typename?: 'uomExchange';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type UomExchanges = {
  __typename?: 'uomExchanges';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type UpdateDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  doNo: Scalars['String'];
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  siteID: Scalars['String'];
  supplierID: Scalars['String'];
  docDate: Scalars['String'];
  remarks: Scalars['String'];
  doStatus: DoStatus;
  contactPerson?: Maybe<ContactPersonInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type ContractClaimCalculationQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type ContractClaimCalculationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'submittedClaimChecking' | 'contractVoCalculation' | 'maxRetention'>
  & { approvedClaimChecking: (
    { __typename?: 'ClaimSummary' }
    & Pick<ClaimSummary, 'previousClaim' | 'previousMOS' | 'previousVOClaim' | 'previousDeduction' | 'previousDayWork' | 'retentionPerc' | 'previousRetentionSum' | 'retentionBalance' | 'advanceSum' | 'advanceRecouped' | 'advanceBalance' | 'deductionSum' | 'deductionRecouped' | 'deductionBalance' | 'submittedDocDate'>
  ) }
);

export type SubconClaimCalculationQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type SubconClaimCalculationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'submittedClaimChecking' | 'subcontractVoCalculation' | 'maxRetention'>
  & { approvedClaimChecking: (
    { __typename?: 'ClaimSummary' }
    & Pick<ClaimSummary, 'previousClaim' | 'previousMOS' | 'previousVOClaim' | 'previousDeduction' | 'previousDayWork' | 'retentionPerc' | 'previousRetentionSum' | 'retentionBalance' | 'advanceSum' | 'advanceRecouped' | 'advanceBalance' | 'deductionSum' | 'deductionRecouped' | 'deductionBalance' | 'submittedDocDate'>
  ) }
);

export type SubmittedClaimCheckingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type SubmittedClaimCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'submittedClaimChecking'>
);

export type ContractVoCalculationQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type ContractVoCalculationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractVoCalculation'>
);

export type SubcontractVoCalculationQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type SubcontractVoCalculationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subcontractVoCalculation'>
);

export type ApprovedClaimCheckingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type ApprovedClaimCheckingQuery = (
  { __typename?: 'Query' }
  & { approvedClaimChecking: (
    { __typename?: 'ClaimSummary' }
    & Pick<ClaimSummary, 'previousClaim' | 'previousMOS' | 'previousVOClaim' | 'previousDeduction' | 'previousDayWork' | 'retentionPerc' | 'previousRetentionSum' | 'retentionBalance' | 'advanceSum' | 'advanceRecouped' | 'advanceBalance' | 'deductionSum' | 'deductionRecouped' | 'deductionBalance' | 'submittedDocDate'>
  ) }
);

export type MaxRetentionAmtQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type MaxRetentionAmtQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'maxRetention'>
);

export type ContractClaimAllocationLedgersQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
}>;


export type ContractClaimAllocationLedgersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getClaimAllocationLedgers'>
);

export type SubcontractClaimAllocationLedgersQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
}>;


export type SubcontractClaimAllocationLedgersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconClaimAllocationLedgers'>
);

export type GetClientExecutiveSummaryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetClientExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'clientProfileDate' | 'submittedCusAVNotification' | 'advancesInfo' | 'claimsInfo' | 'submittedClaimNotification' | 'progressClaimsInfo' | 'submittedRetClaimNotification' | 'retentionClaimsInfo' | 'miscInvInfo' | 'submittedMiscInvNotification' | 'DNInfo' | 'submittedDNNotification' | 'CNInfo' | 'submittedCNNotification' | 'submittedReceiptNotification' | 'receiptsInfo' | 'submittedRefundNotification' | 'refundsInfo' | 'submittedClientDNNotification' | 'clientDNInfo' | 'submittedClientCNNotification' | 'clientCNInfo' | 'outstandingClient' | 'unallocatedClient'>
);

export type GetSubcontractorExecutiveSummaryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconProfileDate' | 'submittedSubconAVNotification' | 'subconAdvancesInfo' | 'subconClaimsInfo' | 'submittedSubconClaimNotification' | 'subconProgressClaimsInfo' | 'submittedSubconRetClaimNotification' | 'subconRetentionClaimsInfo' | 'submittedSubconMiscInvNotification' | 'subconMiscInvInfo' | 'submittedSubconDNNotification' | 'submittedSubconCNNotification' | 'subconDNInfo' | 'subconCNInfo' | 'submittedPaymentNotification' | 'subconPaymentInfo' | 'submittedSubconRefundNotification' | 'subconRefundsInfo' | 'submittedDNtoSubconNotification' | 'DNtoSubconInfo' | 'submittedCNtoSubconNotification' | 'CNtoSubconInfo' | 'outstandingSubcon' | 'unallocatedSubcon'>
);

export type GetSupplierAccountExecutiveSummaryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierAccountExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'submittedDepositNotification' | 'supplierDepositsInfo' | 'submittedSupplierInvNotification' | 'supplierInvInfo' | 'submittedSupplierInvWoDoNotification' | 'supplierInvWoDoInfo' | 'submittedSupplierDNNotification' | 'supplierDNInfo' | 'submittedSupplierCNNotification' | 'supplierCNInfo' | 'submittedDNToSupplierNotification' | 'DNtoSupplierInfo' | 'submittedCNToSupplierNotification' | 'CNtoSupplierInfo' | 'submittedSupplierPaymentNotification' | 'supplierPaymentsInfo' | 'supplierBatchPaymentsInfo' | 'submittedSupplierRefundNotification' | 'supplierRefundsInfo' | 'outstandingSupplier' | 'unallocatedSupplier'>
);

export type ClaimLatestApprovedIdPerContractQueryVariables = Exact<{
  identifier: Scalars['String'];
}>;


export type ClaimLatestApprovedIdPerContractQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'cancellableClaims'>
);

export type ApprovableLedgerCheckingQueryVariables = Exact<{
  docRefID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docAmt?: Maybe<Scalars['Float']>;
}>;


export type ApprovableLedgerCheckingQuery = (
  { __typename?: 'Query' }
  & { approvableLedgerChecking: (
    { __typename?: 'LedgerCheckingSummary' }
    & Pick<LedgerCheckingSummary, 'isApprovable' | 'hasNextLevel' | 'hasGuideline' | 'validFlow'>
  ) }
);

export type GetAdjustmentTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetAdjustmentTypeQuery = (
  { __typename?: 'Query' }
  & { getAdjustmentType: Array<(
    { __typename?: 'AdjustmentTypeEntity' }
    & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
  )> }
);

export type GetDnItemsByDnidQueryVariables = Exact<{
  dnID: Scalars['String'];
}>;


export type GetDnItemsByDnidQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDNItemsByDNID'>
);

export type FetchNextDocNumQueryVariables = Exact<{
  docCode: ContractDocNum;
  claimOnly?: Maybe<Scalars['String']>;
  consubconID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type FetchNextDocNumQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'fetchNextDocNum'>
);

export type FetchMultipleDocNumQueryVariables = Exact<{
  numOfDocs?: Maybe<Scalars['Float']>;
  docCode: ContractDocNum;
  supplierIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type FetchMultipleDocNumQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'fetchMultipleDocNum'>
);

export type SubmittedByLedgerQueryVariables = Exact<{
  docRefTable: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
}>;


export type SubmittedByLedgerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'submittedByLedger'>
);

export type UploadIsoLogoMutationVariables = Exact<{
  file?: Maybe<Scalars['Upload']>;
}>;


export type UploadIsoLogoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadISOLogo'>
);

export type WatermarkViewQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
}>;


export type WatermarkViewQuery = (
  { __typename?: 'Query' }
  & { WatermarkView: (
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'fileName' | 'fileURL' | 'description'>
  ) }
);

export type GetCollectionActivityQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCollectionActivityQuery = (
  { __typename?: 'Query' }
  & { getCollectionActivity: Array<(
    { __typename?: 'CollectionActivityEntity' }
    & Pick<CollectionActivityEntity, 'ID' | 'contactID' | 'contractID' | 'customerID' | 'date' | 'time' | 'description' | 'remark' | 'activityStatus' | 'profileInfo' | 'numOfAttachment'>
    & { contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, customer?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type CreateCollectionActivityMutationVariables = Exact<{
  input: CollectionActivityInput;
}>;


export type CreateCollectionActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCollectionActivity'>
);

export type CreateMultipleCollectionActivityMutationVariables = Exact<{
  input: Array<CollectionActivityInput> | CollectionActivityInput;
}>;


export type CreateMultipleCollectionActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMultipleCollectionActivity'>
);

export type DeleteCollectionActivityMutationVariables = Exact<{
  ID: CollectionActivityDeleteId;
}>;


export type DeleteCollectionActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCollectionActivity'>
);

export type UpdateCollectionActivityMutationVariables = Exact<{
  input: CollectionActivityInput;
}>;


export type UpdateCollectionActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCollectionActivity'>
);

export type GetClientDebitDocListingQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  startPeriod: Scalars['Float'];
  endPeriod: Scalars['Float'];
}>;


export type GetClientDebitDocListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getClientDebitDocListing'>
);

export type UpdateCollectionStatusMutationVariables = Exact<{
  input: CollectionActivityStatusInput;
}>;


export type UpdateCollectionStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCollectionStatus'>
);

export type AddContractContactMutationVariables = Exact<{
  input: ContactPersonInput;
  contractID: Scalars['String'];
}>;


export type AddContractContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addContractContact'>
);

export type DeleteColActivityMutationVariables = Exact<{
  collectionActivityID: Scalars['String'];
}>;


export type DeleteColActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteColActivity'>
);

export type CreditControlCustomerQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type CreditControlCustomerQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'activeContract' | 'associatedStatus' | 'overdueInvInfo' | 'overdueInvInfoNoBound' | 'clientReceiptInfo' | 'clientAgingInfo' | 'currencyID'>
    & { followUp?: Maybe<Array<(
      { __typename?: 'FollowUpEntity' }
      & Pick<FollowUpEntity, 'createdTs'>
    )>> }
  )> }
);

export type GetCustomerCollectionActivityQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetCustomerCollectionActivityQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'associatedStatus' | 'clientAgingInfo' | 'latestCollectionActivity'>
    & { collectionActivity?: Maybe<Array<(
      { __typename?: 'CollectionActivityEntity' }
      & Pick<CollectionActivityEntity, 'ID' | 'date' | 'remark'>
    )>> }
  )> }
);

export type GetCustomerByCompanyCollectionActivityQueryVariables = Exact<{
  companyID: Scalars['String'];
  withoutCompany?: Maybe<Scalars['Boolean']>;
}>;


export type GetCustomerByCompanyCollectionActivityQuery = (
  { __typename?: 'Query' }
  & { getCustomerByCompany: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'associatedStatus' | 'clientAgingInfo' | 'latestCollectionActivity'>
    & { collectionActivity?: Maybe<Array<(
      { __typename?: 'CollectionActivityEntity' }
      & Pick<CollectionActivityEntity, 'ID' | 'date' | 'remark'>
    )>> }
  )> }
);

export type GetContractCollectionActivityQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  selectedDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetContractCollectionActivityQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'customerID' | 'title' | 'contractNo' | 'contractDate' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'revisedContractSum' | 'contractSum' | 'contractStatus' | 'contractAgingInfo'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name'>
    )>, latestCollectionActivity?: Maybe<(
      { __typename?: 'CollectionActivityEntity' }
      & Pick<CollectionActivityEntity, 'ID' | 'date' | 'time' | 'remark' | 'description' | 'activityStatus' | 'profileInfo' | 'numOfAttachment' | 'contactID'>
      & { contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'email' | 'designation' | 'contactNo'>
      )> }
    )>, collectionActivity?: Maybe<Array<(
      { __typename?: 'CollectionActivityEntity' }
      & Pick<CollectionActivityEntity, 'ID' | 'date' | 'remark'>
    )>> }
  )> }
);

export type GetCustomerFollowUpLogQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetCustomerFollowUpLogQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'contactNo'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
    )>, followUp?: Maybe<Array<(
      { __typename?: 'FollowUpEntity' }
      & Pick<FollowUpEntity, 'ID' | 'remarks' | 'contactPerson' | 'followUpStatus' | 'createdTs'>
    )>> }
  )> }
);

export type CreateFollowUpMutationVariables = Exact<{
  input: FollowUpInput;
}>;


export type CreateFollowUpMutation = (
  { __typename?: 'Mutation' }
  & { createFollowUp: (
    { __typename?: 'FollowUpEntity' }
    & Pick<FollowUpEntity, 'ID'>
  ) }
);

export type GetCreditControlSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreditControlSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pendingCollectionActivity' | 'paymentReminderSummary' | 'totalOutstandingClients'>
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'clientAgingInfo'>
  )> }
);

export type GetCreditControlCompanySummaryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetCreditControlCompanySummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pendingCollectionActivity' | 'paymentReminderSummary' | 'totalOutstandingClients' | 'totalOutstandingAmtClients'>
);

export type GetPendingCreditControlQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetPendingCreditControlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pendingCollectionActivity'>
);

export type GetPaymentReminderQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetPaymentReminderQuery = (
  { __typename?: 'Query' }
  & { getPaymentReminder: Array<(
    { __typename?: 'PaymentReminderEntity' }
    & Pick<PaymentReminderEntity, 'ID' | 'reminderDate' | 'commonStatus' | 'payReminderTotal'>
    & { docReminder?: Maybe<Array<(
      { __typename?: 'DocReminderEntity' }
      & Pick<DocReminderEntity, 'ID' | 'paymentReminderID' | 'customerID' | 'docRefTable' | 'docRefID' | 'reminderSeq' | 'outstandingAmt'>
    )>> }
  )> }
);

export type GetCustomerReminderQueryVariables = Exact<{
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetCustomerReminderQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPaymentReminderLastDate'>
  & { getCustomerReminder: Array<(
    { __typename?: 'CustomerReminderEntity' }
    & Pick<CustomerReminderEntity, 'ID' | 'paymentReminder1' | 'paymentReminder2' | 'paymentReminder3' | 'paymentReminder4' | 'minimumAmt' | 'reminderFor' | 'printedCopy' | 'emailCopy' | 'commonStatus'>
  )> }
);

export type GetDebitDocQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
}>;


export type GetDebitDocQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDebitDoc'>
);

export type CreateCustomerReminderMutationVariables = Exact<{
  inputReminder: CustomerReminderInput;
}>;


export type CreateCustomerReminderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCustomerReminder'>
);

export type CreatePaymentReminderMutationVariables = Exact<{
  input: PaymentReminderInput;
  docReminder: Array<DocReminderInput> | DocReminderInput;
}>;


export type CreatePaymentReminderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPaymentReminder'>
);

export type CancelPaymentReminderMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type CancelPaymentReminderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelPaymentReminder'>
);

export type GetPrintReminderQueryVariables = Exact<{
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  paymentReminderID: Scalars['String'];
}>;


export type GetPrintReminderQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPrintReminder'>
);

export type GetPaymentReminderConCustQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
}>;


export type GetPaymentReminderConCustQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPaymentReminderConCust'>
);

export type GetDocReminderDetailsQueryVariables = Exact<{
  paymentReminderID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetDocReminderDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDocReminderDetails'>
);

export type GetPaymentReminderByCompanyQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  paymentReminderID?: Maybe<Scalars['String']>;
}>;


export type GetPaymentReminderByCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPaymentReminderByCompany'>
);

export type GetStatementAcctMonthsQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetStatementAcctMonthsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStatementAcctMonths'>
);

export type GetClaimRecListByMonthQueryVariables = Exact<{
  ID: Scalars['String'];
  month: Scalars['DateTime'];
}>;


export type GetClaimRecListByMonthQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getClaimRecListByMonth'>
);

export type GetCustContractIDsQueryVariables = Exact<{
  customerID: Scalars['String'];
}>;


export type GetCustContractIDsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCustContractIDs'>
);

export type GetSoaClientQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSoaClientQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'associatedStatus' | 'overdueInvInfoNoBound'>
    & { contract?: Maybe<Array<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>> }
  )> }
);

export type GetSoaClientByCompanyQueryVariables = Exact<{
  companyID: Scalars['String'];
  withoutCompany?: Maybe<Scalars['Boolean']>;
}>;


export type GetSoaClientByCompanyQuery = (
  { __typename?: 'Query' }
  & { getCustomerByCompany: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'associatedStatus' | 'overdueInvInfoNoBound'>
    & { contract?: Maybe<Array<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>> }
  )> }
);

export type GetAssociateSummaryQueryVariables = Exact<{
  associate: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetAssociateSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'clientNotifications' | 'associateMenu' | 'totalOutstandingClients' | 'totalOutstandingAmtClients'>
);

export type GetContactQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetContactQuery = (
  { __typename?: 'Query' }
  & { getContact: Array<(
    { __typename?: 'ContactEntity' }
    & Pick<ContactEntity, 'ID' | 'accountID' | 'name' | 'designation' | 'department' | 'jobTitle' | 'contactNo' | 'email' | 'relationship' | 'docRefTable'>
  )> }
);

export type AddCustomerContactMutationVariables = Exact<{
  ID: Scalars['String'];
  platformAccess: Scalars['Boolean'];
  contactInput: Array<ContactPersonInput> | ContactPersonInput;
}>;


export type AddCustomerContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCustomerContact'>
);

export type AddSubcontractorContactMutationVariables = Exact<{
  ID: Scalars['String'];
  platformAccess: Scalars['Boolean'];
  contactInput: Array<ContactPersonInput> | ContactPersonInput;
}>;


export type AddSubcontractorContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSubcontractorContact'>
);

export type AddSupplierContactMutationVariables = Exact<{
  ID: Scalars['String'];
  platformAccess: Scalars['Boolean'];
  contactInput: Array<ContactPersonInput> | ContactPersonInput;
}>;


export type AddSupplierContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSupplierContact'>
);

export type GetCustomerTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetCustomerTitleQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'associatedStatus' | 'activeContract'>
  )> }
);

export type GetSubcontractorTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetSubcontractorTitleQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'associatedStatus' | 'activeSubcontract'>
    & { subcontract?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'subcontractSum' | 'revisedSubcontractSum'>
    )>> }
  )> }
);

export type GetSupplierTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetSupplierTitleQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'address' | 'currencyID' | 'taxSchemeID' | 'gstRegNo'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )> }
  )> }
);

export type AssociateMenuQueryVariables = Exact<{
  associate: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
}>;


export type AssociateMenuQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'associateMenu'>
);

export type GetClassificationCodeQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetClassificationCodeQuery = (
  { __typename?: 'Query' }
  & { getClassificationCode: Array<(
    { __typename?: 'eInvoiceClassificationEntity' }
    & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
  )> }
);

export type GetMsicCodeQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetMsicCodeQuery = (
  { __typename?: 'Query' }
  & { getMsicCode: Array<(
    { __typename?: 'eInvoiceMsicEntity' }
    & Pick<EInvoiceMsicEntity, 'id' | 'msicCode' | 'description' | 'categoryReference'>
  )> }
);

export type GetSelfBilledCostCategoryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetSelfBilledCostCategoryQuery = (
  { __typename?: 'Query' }
  & { getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID' | 'name' | 'description' | 'classificationCodeID' | 'costClass' | 'commonStatus'>
  )> }
);

export type GetCustomerQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'name' | 'registrationNo' | 'tinNo' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'platformAccess' | 'description' | 'email' | 'contactNo' | 'image' | 'imageUrl' | 'address' | 'genPaymentReminder' | 'associatedStatus' | 'creditTerm' | 'taxSchemeID' | 'currencyID' | 'activeContract' | 'clientAgingInfo' | 'eInvoiceType'>
    & { taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxDate' | 'taxRate'>
      )> }
    )>, contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, contract?: Maybe<Array<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'contractSum' | 'contractStatus'>
    )>>, contractClaim?: Maybe<Array<(
      { __typename?: 'ContractClaimEntity' }
      & Pick<ContractClaimEntity, 'ID' | 'transactionStatus' | 'outstandingAmt'>
    )>> }
  )> }
);

export type GetCustomerListQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCustomerListQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'associatedStatus'>
  )> }
);

export type GetCustomerAssocListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCustomerAssocListingQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'clientAgingInfo' | 'overdueInvInfoNoBound' | 'activeContract' | 'associatedStatus' | 'consubconDropdown'>
    & { contract?: Maybe<Array<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>> }
  )> }
);

export type GetClientAccountsQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetClientAccountsQuery = (
  { __typename?: 'Query' }
  & { getClientAccounts: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'registrationNo' | 'contactNo' | 'email' | 'clientAgingInfo' | 'overdueInvInfoNoBound' | 'activeContract' | 'associatedStatus' | 'consubconDropdown' | 'image' | 'imageUrl'>
    & { contract?: Maybe<Array<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>> }
  )> }
);

export type CreateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer: (
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  ) }
);

export type UpdateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: (
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  ) }
);

export type DeleteCustomerMutationVariables = Exact<{
  input: CustomerDeleteInput;
}>;


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomer'>
);

export type CreatePlatformCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type CreatePlatformCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createPlatformCustomer: (
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  ) }
);

export type UpdatePlatformCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type UpdatePlatformCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updatePlatformCustomer: (
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  ) }
);

export type DeletePlatformCustomerMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeletePlatformCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlatformCustomerProfile'>
);

export type GetClientSummaryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetClientSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'clientNotifications' | 'associateMenu' | 'totalClients' | 'totalDebitClient' | 'totalCreditClient' | 'totalOutstandingClients' | 'totalOutstandingAmtClients'>
  & { totalOsClientInvoices: (
    { __typename?: 'ClientSOAInfo' }
    & Pick<ClientSoaInfo, 'totalOsIvCount' | 'totalOsIvAmt'>
  ) }
);

export type GetOutstandingClaimDnQueryVariables = Exact<{
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetOutstandingClaimDnQuery = (
  { __typename?: 'Query' }
  & { getContractClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'claimDocNo' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'outstandingCertifiedAmt' | 'transactionStatus'>
  )>, getContractDN: Array<(
    { __typename?: 'ContractDNEntity' }
    & Pick<ContractDnEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )>, getContractMiscInvoice: Array<(
    { __typename?: 'ContractMiscInvoiceEntity' }
    & Pick<ContractMiscInvoiceEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )> }
);

export type GetCustomerNameQueryVariables = Exact<{
  companyID: Scalars['String'];
  withoutCompany?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCustomerNameQuery = (
  { __typename?: 'Query' }
  & { getCustomerByCompany: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'associatedStatus'>
  )> }
);

export type GetCustomerByCompanyQueryVariables = Exact<{
  companyID: Scalars['String'];
  withoutCompany?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  associatedStatus?: Maybe<AssociatedStatus>;
}>;


export type GetCustomerByCompanyQuery = (
  { __typename?: 'Query' }
  & { getCustomerByCompany: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'accountID' | 'registrationNo' | 'tinNo' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'platformAccess' | 'description' | 'email' | 'contactNo' | 'image' | 'imageUrl' | 'address' | 'genPaymentReminder' | 'associatedStatus' | 'eInvoiceType' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'creditTerm' | 'currencyID' | 'activeContract' | 'clientAgingInfo' | 'overdueInvInfoNoBound' | 'OSClientAgingInfo'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, contract?: Maybe<Array<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'contractSum' | 'contractStatus'>
    )>>, contractClaim?: Maybe<Array<(
      { __typename?: 'ContractClaimEntity' }
      & Pick<ContractClaimEntity, 'ID' | 'transactionStatus' | 'outstandingAmt'>
    )>> }
  )> }
);

export type GetCustomerCompanyAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCustomerCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getCustomerCompanyAssignment: Array<(
    { __typename?: 'CustomerCompanyAssignmentEntity' }
    & Pick<CustomerCompanyAssignmentEntity, 'ID' | 'companyID' | 'customerID'>
    & { customer?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name' | 'registrationNo' | 'description'>
    )> }
  )> }
);

export type AssignCustomerCompanyMutationVariables = Exact<{
  customerID: Scalars['String'];
  companyID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignCustomerCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignCustomerCompany'>
);

export type UnassignCustomerCompanyMutationVariables = Exact<{
  customerID: Scalars['String'];
  companyID: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnassignCustomerCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignCustomerCompany'>
);

export type CreateCustomerImportMutationVariables = Exact<{
  batchID: Scalars['String'];
}>;


export type CreateCustomerImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCustomerImport'>
);

export type CustomerImportValidationMutationVariables = Exact<{
  input: Array<CustomerImportInput> | CustomerImportInput;
}>;


export type CustomerImportValidationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'customerImportValidation'>
);

export type GetSubcontractorQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'paymentTo' | 'email' | 'accountID' | 'registrationNo' | 'tinNo' | 'associatedStatus' | 'contactNo' | 'creditTerm' | 'currencyID' | 'ratingType' | 'cidbDetails' | 'pkkDetails' | 'statusRemarks' | 'bankDetails' | 'gstRegNo' | 'description' | 'gstExpDate' | 'taxSchemeID' | 'image' | 'imageUrl' | 'platformAccess' | 'sstRegNo' | 'address' | 'nominatedSC' | 'activeSubcontract' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'subcontractorTypeID' | 'eInvoiceType' | 'isSelfBilled' | 'msicCodeID' | 'classificationCodeID' | 'selfBilledCostCategoryID'>
    & { taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxDate' | 'taxRate'>
      )> }
    )>, contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, subconClaim: Array<(
      { __typename?: 'SubcontractClaimEntity' }
      & Pick<SubcontractClaimEntity, 'ID' | 'outstandingAmt' | 'outstandingCertifiedAmt' | 'profileInfo'>
    )>, subconAdvance?: Maybe<Array<(
      { __typename?: 'SubcontractAdvanceEntity' }
      & Pick<SubcontractAdvanceEntity, 'ID' | 'outstandingAmt' | 'baseAmt'>
    )>>, subcontract?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractSum' | 'revisedSubcontractSum'>
    )>>, subcontractorType?: Maybe<(
      { __typename?: 'SubcontractorTypeEntity' }
      & Pick<SubcontractorTypeEntity, 'ID' | 'description' | 'glAccountCode' | 'accrualAccountCode'>
    )>, selfBilledCostCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name' | 'description' | 'classificationCodeID' | 'costClass' | 'commonStatus'>
    )>, msicCode?: Maybe<(
      { __typename?: 'eInvoiceMsicEntity' }
      & Pick<EInvoiceMsicEntity, 'id' | 'msicCode' | 'description' | 'categoryReference'>
    )>, classificationCode?: Maybe<(
      { __typename?: 'eInvoiceClassificationEntity' }
      & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
    )> }
  )> }
);

export type GetSubcontractorListQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorListQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name'>
    & { subcontract?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractSum'>
    )>> }
  )> }
);

export type GetSubcontractorAssocListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorAssocListingQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'registrationNo' | 'overdueAdvInfo' | 'overdueInvInfo' | 'overdueInvInfoNoBound' | 'activeSubcontract' | 'associatedStatus' | 'image' | 'imageUrl'>
  )> }
);

export type GetSubcontractorCCategoryQueryVariables = Exact<{
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorCCategoryQuery = (
  { __typename?: 'Query' }
  & { getSubcontractorCCategory: Array<(
    { __typename?: 'SubcontractorCCategoryEntity' }
    & Pick<SubcontractorCCategoryEntity, 'subcontractorID' | 'costCategoryID'>
  )> }
);

export type DeleteSubcontractorMutationVariables = Exact<{
  input: SubcontractorDeleteId;
}>;


export type DeleteSubcontractorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractor'>
);

export type CreatePlatformSubcontractorMutationVariables = Exact<{
  input: SubcontractorInput;
}>;


export type CreatePlatformSubcontractorMutation = (
  { __typename?: 'Mutation' }
  & { createPlatformSubcontractor: (
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name'>
  ) }
);

export type UpdatePlatformSubcontractorMutationVariables = Exact<{
  input: SubcontractorInput;
}>;


export type UpdatePlatformSubcontractorMutation = (
  { __typename?: 'Mutation' }
  & { updatePlatformSubcontractor: (
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name'>
  ) }
);

export type AddSubcontractorCCategoryMutationVariables = Exact<{
  subcontractorID: Scalars['String'];
  costCategoryID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddSubcontractorCCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSubcontractorCCategory'>
);

export type CreateSubcontractorAndPackageMutationVariables = Exact<{
  subcontractorInput: SubcontractorInput;
  costCategoryID: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateSubcontractorAndPackageMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractorAndPackage: (
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name'>
  ) }
);

export type GetSubconClaimDateQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetSubconClaimDateQuery = (
  { __typename?: 'Query' }
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'submittedDate' | 'docDate'>
  )> }
);

export type GetOutstandingSubconDocQueryVariables = Exact<{
  subcontractorID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetOutstandingSubconDocQuery = (
  { __typename?: 'Query' }
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'claimDocNo' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'outstandingCertifiedAmt' | 'transactionStatus'>
  )>, getSubcontractCN: Array<(
    { __typename?: 'SubcontractCNEntity' }
    & Pick<SubcontractCnEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )>, getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )>, getSubcontractMiscInvoice: Array<(
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )>, getCNtoSubcon: Array<(
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )>, getDNtoSubcon: Array<(
    { __typename?: 'DNtoSubconEntity' }
    & Pick<DNtoSubconEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )> }
);

export type GetOutstandingSubconCreditDocQueryVariables = Exact<{
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetOutstandingSubconCreditDocQuery = (
  { __typename?: 'Query' }
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'claimDocNo' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'outstandingCertifiedAmt' | 'transactionStatus'>
  )>, getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )>, getSubcontractMiscInvoice: Array<(
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )>, getCNtoSubcon: Array<(
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )> }
);

export type CreateBulkSubconMutationVariables = Exact<{
  subcontractorInput: Array<BulkSubcontractorInput> | BulkSubcontractorInput;
}>;


export type CreateBulkSubconMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBulkSubcon'>
);

export type GetSubcontractorAccessibleContractQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorAccessibleContractQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractorAccessibleContract'>
);

export type GetSubcontractorByCompanyQueryVariables = Exact<{
  companyID: Scalars['String'];
  withoutCompany?: Maybe<Scalars['Boolean']>;
  associatedStatus?: Maybe<AssociatedStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetSubcontractorByCompanyQuery = (
  { __typename?: 'Query' }
  & { getSubcontractorByCompany: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'paymentTo' | 'email' | 'accountID' | 'registrationNo' | 'tinNo' | 'associatedStatus' | 'contactNo' | 'creditTerm' | 'currencyID' | 'taxSchemeID' | 'ratingType' | 'cidbDetails' | 'pkkDetails' | 'statusRemarks' | 'bankDetails' | 'gstRegNo' | 'description' | 'gstExpDate' | 'image' | 'imageUrl' | 'platformAccess' | 'sstRegNo' | 'address' | 'activeSubcontract' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'nominatedSC' | 'overdueAdvInfo' | 'overdueInvInfoNoBound' | 'overdueInvInfo' | 'eInvoiceType' | 'isSelfBilled' | 'msicCodeID' | 'classificationCodeID' | 'selfBilledCostCategoryID'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, subconClaim: Array<(
      { __typename?: 'SubcontractClaimEntity' }
      & Pick<SubcontractClaimEntity, 'ID' | 'outstandingAmt' | 'outstandingCertifiedAmt' | 'profileInfo'>
    )>, subconAdvance?: Maybe<Array<(
      { __typename?: 'SubcontractAdvanceEntity' }
      & Pick<SubcontractAdvanceEntity, 'ID' | 'outstandingAmt' | 'baseAmt'>
    )>>, subcontract?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractSum' | 'revisedSubcontractSum'>
    )>>, selfBilledCostCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name' | 'description' | 'classificationCodeID' | 'costClass' | 'commonStatus'>
    )>, msicCode?: Maybe<(
      { __typename?: 'eInvoiceMsicEntity' }
      & Pick<EInvoiceMsicEntity, 'id' | 'msicCode' | 'description' | 'categoryReference'>
    )>, classificationCode?: Maybe<(
      { __typename?: 'eInvoiceClassificationEntity' }
      & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
    )> }
  )> }
);

export type CreatePlatformSupplierMutationVariables = Exact<{
  input: SupplierInput;
}>;


export type CreatePlatformSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createPlatformSupplier: (
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name'>
  ) }
);

export type UpdatePlatformSupplierMutationVariables = Exact<{
  input: SupplierInput;
}>;


export type UpdatePlatformSupplierMutation = (
  { __typename?: 'Mutation' }
  & { updatePlatformSupplier: (
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name'>
  ) }
);

export type CreateBulkSupplierMutationVariables = Exact<{
  supplierInput: Array<BulkSupplierInput> | BulkSupplierInput;
}>;


export type CreateBulkSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBulkSupplier'>
);

export type GetSupplierQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
}>;


export type GetSupplierQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'createdTs' | 'createdBy' | 'accountID' | 'taxSchemeID' | 'supplierTypeID' | 'name' | 'registrationNo' | 'tinNo' | 'email' | 'address' | 'currencyID' | 'image' | 'imageUrl' | 'creditTerm' | 'description' | 'platformAccess' | 'outstandingInfo' | 'overdueInvInfo' | 'contactNo' | 'ratingType' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'associatedStatus' | 'bankDetails' | 'indelibleAccount' | 'eInvoiceType' | 'isSelfBilled' | 'msicCodeID' | 'classificationCodeID' | 'selfBilledCostCategoryID'>
    & { taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxDate' | 'taxRate'>
      )> }
    )>, supplierType?: Maybe<(
      { __typename?: 'SupplierTypeEntity' }
      & Pick<SupplierTypeEntity, 'ID' | 'description' | 'glAccountCode' | 'accrualAccountCode'>
    )>, supplierDN?: Maybe<Array<(
      { __typename?: 'APDebitNoteEntity' }
      & Pick<ApDebitNoteEntity, 'ID' | 'docNo' | 'supplierID'>
    )>>, supplierCN?: Maybe<Array<(
      { __typename?: 'APCreditNoteEntity' }
      & Pick<ApCreditNoteEntity, 'ID' | 'docNo' | 'supplierID'>
    )>>, contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, costCategory: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )>, supplierInv?: Maybe<Array<(
      { __typename?: 'APInvoiceEntity' }
      & Pick<ApInvoiceEntity, 'ID' | 'transactionStatus' | 'outstanding'>
    )>>, purchaseOrder?: Maybe<Array<(
      { __typename?: 'PurchaseOrderEntity' }
      & Pick<PurchaseOrderEntity, 'ID' | 'supplierID' | 'contractID' | 'docNo'>
    )>>, negotiatedSupplyItem?: Maybe<Array<(
      { __typename?: 'NegotiatedSupplyEntity' }
      & Pick<NegotiatedSupplyEntity, 'ID' | 'docNo' | 'supplierID'>
    )>>, selfBilledCostCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name' | 'description' | 'classificationCodeID' | 'costClass' | 'commonStatus'>
    )>, msicCode?: Maybe<(
      { __typename?: 'eInvoiceMsicEntity' }
      & Pick<EInvoiceMsicEntity, 'id' | 'msicCode' | 'description' | 'categoryReference'>
    )>, classificationCode?: Maybe<(
      { __typename?: 'eInvoiceClassificationEntity' }
      & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
    )> }
  )> }
);

export type GetSupplierNameQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
}>;


export type GetSupplierNameQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'createdTs' | 'createdBy' | 'name' | 'registrationNo' | 'email' | 'address' | 'image' | 'imageUrl' | 'description' | 'contactNo' | 'ratingType' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'associatedStatus' | 'bankDetails' | 'outstandingInfo' | 'overdueInvInfo'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )> }
  )> }
);

export type GetSupplierListQueryVariables = Exact<{
  companyID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSupplierListQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'image' | 'imageUrl' | 'creditTerm' | 'description' | 'registrationNo' | 'associatedStatus'>
  )> }
);

export type GetSupplierExecutiveSummaryQueryVariables = Exact<{
  supplierID: Scalars['String'];
}>;


export type GetSupplierExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierProfileDate' | 'PrefSupplierProductDate' | 'SupplierAccDate'>
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'registrationNo' | 'overdueInvInfo' | 'outstandingInfo' | 'associatedStatus'>
    & { costCategory: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetSupplierAssocListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSupplierAssocListingQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'registrationNo' | 'overdueInvInfo' | 'outstandingInfo' | 'associatedStatus' | 'image' | 'imageUrl'>
    & { supplierInv?: Maybe<Array<(
      { __typename?: 'APInvoiceEntity' }
      & Pick<ApInvoiceEntity, 'ID' | 'outstanding' | 'outstandingAmt' | 'transactionStatus'>
    )>> }
  )> }
);

export type GetOutstandingSupplierCreditDocQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetOutstandingSupplierCreditDocQuery = (
  { __typename?: 'Query' }
  & { getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstanding' | 'outstandingAmt' | 'transactionStatus'>
  )>, getAPInvoiceWoDO: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstanding' | 'outstandingAmt' | 'transactionStatus'>
  )>, getAPDebitNote: Array<(
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )>, getAPCreditNote: Array<(
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )>, getDNtoSupplier: Array<(
    { __typename?: 'DNtoSupplierEntity' }
    & Pick<DNtoSupplierEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )>, getCNtoSupplier: Array<(
    { __typename?: 'CNtoSupplierEntity' }
    & Pick<CNtoSupplierEntity, 'ID' | 'docNo' | 'docDate' | 'docDueDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )> }
);

export type GetSupplierByCompanyQueryVariables = Exact<{
  companyID: Scalars['String'];
  withoutCompany?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  associatedStatus?: Maybe<AssociatedStatus>;
}>;


export type GetSupplierByCompanyQuery = (
  { __typename?: 'Query' }
  & { getSupplierByCompany: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'createdTs' | 'createdBy' | 'name' | 'registrationNo' | 'tinNo' | 'email' | 'address' | 'currencyID' | 'image' | 'imageUrl' | 'creditTerm' | 'description' | 'platformAccess' | 'outstandingInfo' | 'overdueInvInfo' | 'contactNo' | 'ratingType' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'associatedStatus' | 'bankDetails' | 'accountID' | 'taxSchemeID' | 'supplierTypeID' | 'eInvoiceType' | 'isSelfBilled' | 'msicCodeID' | 'classificationCodeID' | 'selfBilledCostCategoryID'>
    & { supplierDN?: Maybe<Array<(
      { __typename?: 'APDebitNoteEntity' }
      & Pick<ApDebitNoteEntity, 'ID' | 'docNo' | 'supplierID'>
    )>>, supplierCN?: Maybe<Array<(
      { __typename?: 'APCreditNoteEntity' }
      & Pick<ApCreditNoteEntity, 'ID' | 'docNo' | 'supplierID'>
    )>>, contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, costCategory: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )>, supplierInv?: Maybe<Array<(
      { __typename?: 'APInvoiceEntity' }
      & Pick<ApInvoiceEntity, 'ID' | 'transactionStatus' | 'outstanding'>
    )>>, purchaseOrder?: Maybe<Array<(
      { __typename?: 'PurchaseOrderEntity' }
      & Pick<PurchaseOrderEntity, 'ID' | 'supplierID' | 'contractID' | 'docNo'>
    )>>, negotiatedSupplyItem?: Maybe<Array<(
      { __typename?: 'NegotiatedSupplyEntity' }
      & Pick<NegotiatedSupplyEntity, 'ID' | 'docNo' | 'supplierID'>
    )>>, selfBilledCostCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name' | 'description' | 'classificationCodeID' | 'costClass' | 'commonStatus'>
    )> }
  )> }
);

export type CreateSupplierCCategoryMutationVariables = Exact<{
  input: SupplierCCategoryInput;
}>;


export type CreateSupplierCCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createSupplierCCategory: (
    { __typename?: 'SupplierCCategoryEntity' }
    & Pick<SupplierCCategoryEntity, 'costCategoryID'>
  ) }
);

export type UpdateSupplierCCategoryMutationVariables = Exact<{
  input: SupplierCCategoryInput;
}>;


export type UpdateSupplierCCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierCCategory: (
    { __typename?: 'SupplierCCategoryEntity' }
    & Pick<SupplierCCategoryEntity, 'costCategoryID'>
  ) }
);

export type GetSupplierCCategoryQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierCCategoryQuery = (
  { __typename?: 'Query' }
  & { getSupplierCCategory: Array<(
    { __typename?: 'SupplierCCategoryEntity' }
    & Pick<SupplierCCategoryEntity, 'supplierID' | 'costCategoryID'>
  )> }
);

export type AddCCategoryInSupplierMutationVariables = Exact<{
  supplierID: Scalars['String'];
  costCategoryID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddCCategoryInSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCCategoryInSupplier'>
);

export type DeleteSupplierMutationVariables = Exact<{
  input: SupplierDeleteInput;
}>;


export type DeleteSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSupplier'>
);

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'avatar' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'userName' | 'jobTitle' | 'lastestAccessedEntity'>
  ) }
);

export type LoginMutationVariables = Exact<{
  loginId: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken' | 'refreshToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'name' | 'lastestAccessedEntity'>
    ) }
  ) }
);

export type UploadProfilePicMutationVariables = Exact<{
  userID: Scalars['String'];
  file: Scalars['Upload'];
}>;


export type UploadProfilePicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadProfilePic'>
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LogoutMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
}>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type CreatePasswordMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPassword'>
);

export type GetContractCalendarInfoQueryVariables = Exact<{
  currDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetContractCalendarInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractCalendarInfo'>
);

export type GetSubconCalendarInfoQueryVariables = Exact<{
  currDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetSubconCalendarInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconCalendarInfo'>
);

export type GetPpCalendarInfoQueryVariables = Exact<{
  currDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetPpCalendarInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPPCalendarInfo'>
);

export type GetContractOsDebitDocumentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetContractOsDebitDocumentQuery = (
  { __typename?: 'Query' }
  & { getContractClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'contractID' | 'isGlExport' | 'claimDocNo' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt' | 'taxAmt' | 'taxRate' | 'principalAmt' | 'submittedDate'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'creditTerm'>
    )> }
  )>, getContractMiscInvoice: Array<(
    { __typename?: 'ContractMiscInvoiceEntity' }
    & Pick<ContractMiscInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt' | 'contractID' | 'customerID' | 'isDaywork' | 'taxRate' | 'taxAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'creditTerm'>
      & { bankAccount?: Maybe<(
        { __typename?: 'BankAccountEntity' }
        & Pick<BankAccountEntity, 'name' | 'accountNo'>
      )> }
    )>, contractMiscInvoiceItem?: Maybe<Array<(
      { __typename?: 'ContractMiscInvoiceItemEntity' }
      & Pick<ContractMiscInvoiceItemEntity, 'ID' | 'taxSchemeID' | 'revenueCategoryID' | 'wbsID' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )>, getContractDN: Array<(
    { __typename?: 'ContractDNEntity' }
    & Pick<ContractDnEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt' | 'contractID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'creditTerm'>
    )>, contractDNItem?: Maybe<Array<(
      { __typename?: 'ContractDNItemEntity' }
      & Pick<ContractDnItemEntity, 'ID' | 'taxSchemeID' | 'revenueCategoryID' | 'wbsID' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )>, getContractRefund: Array<(
    { __typename?: 'ContractRefundEntity' }
    & Pick<ContractRefundEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt' | 'contractID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'creditTerm'>
    )> }
  )>, getClientCN: Array<(
    { __typename?: 'ClientCNEntity' }
    & Pick<ClientCnEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt' | 'contractID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'creditTerm'>
    )> }
  )> }
);

export type GetContractCustomerPdfQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetContractCustomerPdfQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'pdfDocumentTitle' | 'creditTerm' | 'contractNo' | 'contractSum' | 'revisedContractSum' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'latestBudgetID'>
    & { contractClaim?: Maybe<Array<(
      { __typename?: 'ContractClaimEntity' }
      & Pick<ContractClaimEntity, 'claimDocNo'>
    )>>, contact?: Maybe<Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'contactNo'>
    )>>, bankAccount?: Maybe<(
      { __typename?: 'BankAccountEntity' }
      & Pick<BankAccountEntity, 'ID' | 'name' | 'accountNo'>
    )> }
  )>, getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'address'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'contactNo'>
    )> }
  )> }
);

export type GetClaimPdfDetailQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetClaimPdfDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractVoCalculation' | 'maxRetention'>
);

export type GetAllocationClientAccountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  creditDebitID: Scalars['String'];
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetAllocationClientAccountQuery = (
  { __typename?: 'Query' }
  & { getContractDN: Array<(
    { __typename?: 'ContractDNEntity' }
    & Pick<ContractDnEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getClientCN: Array<(
    { __typename?: 'ClientCNEntity' }
    & Pick<ClientCnEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getContractMiscInvoice: Array<(
    { __typename?: 'ContractMiscInvoiceEntity' }
    & Pick<ContractMiscInvoiceEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getContractClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'claimDocNo' | 'docNo' | 'docDate' | 'allocatedAmt' | 'nonEditableAllocation' | 'description'>
  )>, cancelledClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'claimDocNo' | 'docNo' | 'docDate' | 'allocatedAmt' | 'nonEditableAllocation' | 'description'>
  )>, getContractRefund: Array<(
    { __typename?: 'ContractRefundEntity' }
    & Pick<ContractRefundEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )> }
);

export type MakeContractAllocationMutationVariables = Exact<{
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeContractAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'makeContractAllocation'>
);

export type ResetContractAllocationMutationVariables = Exact<{
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  receiptPayment?: Maybe<Scalars['Boolean']>;
}>;


export type ResetContractAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetContractAllocation'>
);

export type GetContractReceiptAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetContractReceiptAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractReceiptAllocation'>
);

export type GetContractRefundAllocationQueryVariables = Exact<{
  debitID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<Scalars['String']>;
}>;


export type GetContractRefundAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractRefundAllocation'>
);

export type GetContractAdvanceAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetContractAdvanceAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractAdvanceAllocation'>
);

export type GetContractRetClaimAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetContractRetClaimAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractRetClaimAllocation'>
);

export type GetContractCnAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetContractCnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractCNAllocation'>
);

export type GetContractDnAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetContractDnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractDNAllocation'>
);

export type GetContractMiscInvoiceAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetContractMiscInvoiceAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractMiscInvoiceAllocation'>
);

export type GetClientDnAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetClientDnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getClientDNAllocation'>
);

export type GetClientCnAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetClientCnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getClientCNAllocation'>
);

export type GetContractClaimQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetContractClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'claimsInfo'>
  & { getContractClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'createdTs' | 'createdBy' | 'isRetention' | 'modBy' | 'contractID' | 'customerID' | 'currencyID' | 'docNo' | 'claimDocNo' | 'docRef' | 'description' | 'docDate' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'exchangeRate' | 'grossAmt' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'docAmt' | 'docRefID' | 'docRefTable' | 'docDueDate' | 'submittedDate' | 'principalAmt' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt' | 'previousMosAmt' | 'submittedMosAmt' | 'currentMosAmt' | 'retentionAmt' | 'submittedRetentionAmt' | 'balanceRetentionAmt' | 'submittedDayWorkAmt' | 'currentDayWorkAmt' | 'balanceAdvanceAmt' | 'currentAdvRecoupmentAmt' | 'currentDeductionAmt' | 'submittedDeductionAmt' | 'balanceDeductionAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'cancellationDate' | 'cancellationRemarks' | 'voDisplayAmt' | 'adjustmentInfo'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, customer?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name'>
    )>, taxScheme?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, contractClaimAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimAllocationEntity' }
      & Pick<ContractClaimAllocationEntity, 'ID' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'contractClaimID' | 'wbsBudgetDetailID' | 'revenueCategoryID' | 'wbsID'>
      & { revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )>, wbsBudgetDetail?: Maybe<(
        { __typename?: 'WbsBudgetDetailEntity' }
        & Pick<WbsBudgetDetailEntity, 'ID' | 'wbsID' | 'budgetAmt'>
        & { wbs: (
          { __typename?: 'WBSEntity' }
          & Pick<WbsEntity, 'ID' | 'name'>
        ) }
      )>, latestWbsBudgetDetail?: Maybe<(
        { __typename?: 'WbsBudgetDetailEntity' }
        & Pick<WbsBudgetDetailEntity, 'ID' | 'budgetAmt'>
      )> }
    )>>, contractClaimVOAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimVOAllocationEntity' }
      & Pick<ContractClaimVoAllocationEntity, 'ID' | 'contractVOID' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'previousVOClaimAmt'>
      & { contractVO?: Maybe<(
        { __typename?: 'ContractVOEntity' }
        & Pick<ContractVoEntity, 'ID' | 'docNo' | 'docRef'>
      )> }
    )>>, contractClaimVORevenueWbsAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimVORevenueWbsAllocationEntity' }
      & Pick<ContractClaimVoRevenueWbsAllocationEntity, 'ID' | 'contractClaimID' | 'contractVORevenueWbsID' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'previousVOClaimAmt'>
      & { contractVORevenueWbs?: Maybe<(
        { __typename?: 'ContractVORevenueWbsEntity' }
        & Pick<ContractVoRevenueWbsEntity, 'ID' | 'wbsID' | 'revenueCategoryID' | 'approvedAmt' | 'wbsBudgetDetailID'>
        & { contractVO?: Maybe<(
          { __typename?: 'ContractVOEntity' }
          & Pick<ContractVoEntity, 'ID' | 'docNo'>
        )>, wbsBudgetDetail?: Maybe<(
          { __typename?: 'WbsBudgetDetailEntity' }
          & Pick<WbsBudgetDetailEntity, 'ID' | 'wbsID'>
          & { revenueCategory?: Maybe<(
            { __typename?: 'RevenueCategoryEntity' }
            & Pick<RevenueCategoryEntity, 'ID' | 'name'>
          )> }
        )> }
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetContractClaimPdfQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetContractClaimPdfQuery = (
  { __typename?: 'Query' }
  & { getContractClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'createdTs' | 'createdBy' | 'modBy' | 'contractID' | 'customerID' | 'currencyID' | 'docNo' | 'claimDocNo' | 'docRef' | 'description' | 'docDate' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'exchangeRate' | 'grossAmt' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'docAmt' | 'docRefID' | 'docRefTable' | 'docDueDate' | 'submittedDate' | 'principalAmt' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt' | 'previousMosAmt' | 'submittedMosAmt' | 'currentMosAmt' | 'retentionAmt' | 'submittedRetentionAmt' | 'balanceRetentionAmt' | 'submittedDayWorkAmt' | 'currentDayWorkAmt' | 'balanceAdvanceAmt' | 'currentAdvRecoupmentAmt' | 'currentDeductionAmt' | 'submittedDeductionAmt' | 'balanceDeductionAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'cancellationDate' | 'cancellationRemarks' | 'claimDetailRevenueWBS' | 'voDisplayAmt' | 'adjustmentInfo'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, customer?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name'>
    )>, taxScheme?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, contractClaimAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimAllocationEntity' }
      & Pick<ContractClaimAllocationEntity, 'ID' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'contractClaimID' | 'wbsBudgetDetailID' | 'revenueCategoryID' | 'wbsID'>
      & { revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name'>
      )>, wbsBudgetDetail?: Maybe<(
        { __typename?: 'WbsBudgetDetailEntity' }
        & Pick<WbsBudgetDetailEntity, 'ID' | 'wbsID' | 'budgetAmt'>
        & { wbs: (
          { __typename?: 'WBSEntity' }
          & Pick<WbsEntity, 'ID' | 'name'>
        ) }
      )>, latestWbsBudgetDetail?: Maybe<(
        { __typename?: 'WbsBudgetDetailEntity' }
        & Pick<WbsBudgetDetailEntity, 'ID' | 'budgetAmt'>
      )> }
    )>>, contractClaimVOAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimVOAllocationEntity' }
      & Pick<ContractClaimVoAllocationEntity, 'ID' | 'contractVOID' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'previousVOClaimAmt'>
      & { contractVO?: Maybe<(
        { __typename?: 'ContractVOEntity' }
        & Pick<ContractVoEntity, 'ID' | 'docNo' | 'docRef'>
      )> }
    )>>, contractClaimVORevenueWbsAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimVORevenueWbsAllocationEntity' }
      & Pick<ContractClaimVoRevenueWbsAllocationEntity, 'ID' | 'contractClaimID' | 'contractVORevenueWbsID' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'previousVOClaimAmt'>
      & { contractVORevenueWbs?: Maybe<(
        { __typename?: 'ContractVORevenueWbsEntity' }
        & Pick<ContractVoRevenueWbsEntity, 'ID' | 'approvedAmt' | 'wbsBudgetDetailID'>
        & { contractVO?: Maybe<(
          { __typename?: 'ContractVOEntity' }
          & Pick<ContractVoEntity, 'ID' | 'docNo'>
        )>, wbsBudgetDetail?: Maybe<(
          { __typename?: 'WbsBudgetDetailEntity' }
          & Pick<WbsBudgetDetailEntity, 'ID' | 'wbsID'>
          & { revenueCategory?: Maybe<(
            { __typename?: 'RevenueCategoryEntity' }
            & Pick<RevenueCategoryEntity, 'ID' | 'name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GetConProgressClaimQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetConProgressClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'progressClaimsInfo'>
  & { getConProgressClaimCount: Query['getContractClaimCount'] }
  & { getProgressClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationRemarks' | 'cancellationDate' | 'claimDocNo' | 'contractID' | 'currencyID' | 'customerID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'grossAmt' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'outstandingAmt' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'voDisplayAmt' | 'transactionDate' | 'transactionStatus' | 'createdBy' | 'createdTs' | 'modBy' | 'modTs'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, customer?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name'>
    )>, contractClaimAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimAllocationEntity' }
      & Pick<ContractClaimAllocationEntity, 'ID' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'contractClaimID' | 'wbsBudgetDetailID' | 'wbsID' | 'revenueCategoryID'>
    )>>, contractClaimVOAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimVOAllocationEntity' }
      & Pick<ContractClaimVoAllocationEntity, 'ID' | 'contractVOID' | 'contractClaimID' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'previousVOClaimAmt'>
    )>>, contractClaimVORevenueWbsAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimVORevenueWbsAllocationEntity' }
      & Pick<ContractClaimVoRevenueWbsAllocationEntity, 'ID' | 'contractVORevenueWbsID' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'previousVOClaimAmt'>
      & { contractVORevenueWbs?: Maybe<(
        { __typename?: 'ContractVORevenueWbsEntity' }
        & Pick<ContractVoRevenueWbsEntity, 'ID' | 'wbsID' | 'revenueCategoryID'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )> }
);

export type GetConRetentionClaimQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetConRetentionClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'retentionClaimsInfo'>
  & { getConRetentionClaimCount: Query['getContractClaimCount'] }
  & { getRetentionClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'createdTs' | 'createdBy' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'contractID' | 'customerID' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'outstandingAmt' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, customer?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )> }
);

export type CreateContractClaimMutationVariables = Exact<{
  input: ContractClaimInput;
}>;


export type CreateContractClaimMutation = (
  { __typename?: 'Mutation' }
  & { createContractClaim: (
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'accountID'>
  ) }
);

export type SubmitRetentionClaimMutationVariables = Exact<{
  input: ClaimRetentionInput;
}>;


export type SubmitRetentionClaimMutation = (
  { __typename?: 'Mutation' }
  & { submitRetentionClaim: (
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'docNo'>
  ) }
);

export type ApproveRetentionClaimMutationVariables = Exact<{
  input: ClaimRetentionInput;
}>;


export type ApproveRetentionClaimMutation = (
  { __typename?: 'Mutation' }
  & { approveRetentionClaim: (
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'docNo'>
  ) }
);

export type SubmitClaimMutationVariables = Exact<{
  deductionLedger?: Maybe<Array<LedgerInput> | LedgerInput>;
  input: ContractClaimInput;
  claimAlloc?: Maybe<Array<ContractClaimAllocInput> | ContractClaimAllocInput>;
  voAlloc?: Maybe<Array<ContractClaimVoAllocInput> | ContractClaimVoAllocInput>;
  voRevenueWbsAlloc?: Maybe<Array<ContractClaimVoRevenueWbsAllocInput> | ContractClaimVoRevenueWbsAllocInput>;
}>;


export type SubmitClaimMutation = (
  { __typename?: 'Mutation' }
  & { submitClaim: (
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'claimDocNo' | 'docNo'>
  ) }
);

export type ApproveClaimMutationVariables = Exact<{
  input: ContractClaimInput;
  claimAlloc?: Maybe<Array<ContractClaimAllocInput> | ContractClaimAllocInput>;
  voAlloc?: Maybe<Array<ContractClaimVoAllocInput> | ContractClaimVoAllocInput>;
  voRevenueWbsAlloc?: Maybe<Array<ContractClaimVoRevenueWbsAllocInput> | ContractClaimVoRevenueWbsAllocInput>;
}>;


export type ApproveClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveClaim'>
);

export type UpdateContractClaimMutationVariables = Exact<{
  input: ContractClaimInput;
}>;


export type UpdateContractClaimMutation = (
  { __typename?: 'Mutation' }
  & { updateContractClaim: (
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'accountID' | 'claimDocNo' | 'docNo'>
  ) }
);

export type CancelClaimMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
  retentionClaim?: Maybe<Scalars['Boolean']>;
}>;


export type CancelClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClaim'>
);

export type RejectClaimMutationVariables = Exact<{
  ID: Scalars['String'];
  input: RejectInput;
  retentionClaim?: Maybe<Scalars['Boolean']>;
}>;


export type RejectClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectClaim'>
);

export type DeleteContractClaimMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractClaim'>
);

export type GetContractAdvanceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetContractAdvanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'advancesInfo'>
  & { getContractAdvance: Array<(
    { __typename?: 'ContractAdvanceEntity' }
    & Pick<ContractAdvanceEntity, 'ID' | 'modBy' | 'modTs' | 'createdTs' | 'createdBy' | 'isGlExport' | 'isRecoupment' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetContractAdvanceListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetContractAdvanceListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'advancesInfo' | 'getContractAdvanceCount'>
  & { getContractAdvance: Array<(
    { __typename?: 'ContractAdvanceEntity' }
    & Pick<ContractAdvanceEntity, 'ID' | 'modBy' | 'modTs' | 'createdTs' | 'createdBy' | 'isGlExport' | 'isRecoupment' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateContractAdvanceMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type CreateContractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { createContractAdvance: (
    { __typename?: 'ContractAdvanceEntity' }
    & Pick<ContractAdvanceEntity, 'ID' | 'docNo'>
  ) }
);

export type UpdateContractAdvanceMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type UpdateContractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { updateContractAdvance: (
    { __typename?: 'ContractAdvanceEntity' }
    & Pick<ContractAdvanceEntity, 'ID' | 'docNo'>
  ) }
);

export type GetContractReceiptQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetContractReceiptQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'receiptsInfo'>
  & { getContractReceipt: Array<(
    { __typename?: 'ContractReceiptEntity' }
    & Pick<ContractReceiptEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetContractReceiptListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetContractReceiptListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'receiptsInfo' | 'getContractReceiptCount'>
  & { getContractReceipt: Array<(
    { __typename?: 'ContractReceiptEntity' }
    & Pick<ContractReceiptEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateContractReceiptMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type CreateContractReceiptMutation = (
  { __typename?: 'Mutation' }
  & { createContractReceipt: (
    { __typename?: 'ContractReceiptEntity' }
    & Pick<ContractReceiptEntity, 'ID' | 'docNo'>
  ) }
);

export type UpdateContractReceiptMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type UpdateContractReceiptMutation = (
  { __typename?: 'Mutation' }
  & { updateContractReceipt: (
    { __typename?: 'ContractReceiptEntity' }
    & Pick<ContractReceiptEntity, 'ID' | 'docNo'>
  ) }
);

export type GetContractRefundQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetContractRefundQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'refundsInfo'>
  & { getContractRefund: Array<(
    { __typename?: 'ContractRefundEntity' }
    & Pick<ContractRefundEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetContractRefundListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetContractRefundListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'refundsInfo' | 'getContractRefundCount'>
  & { getContractRefund: Array<(
    { __typename?: 'ContractRefundEntity' }
    & Pick<ContractRefundEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateContractRefundMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type CreateContractRefundMutation = (
  { __typename?: 'Mutation' }
  & { createContractRefund: (
    { __typename?: 'ContractRefundEntity' }
    & Pick<ContractRefundEntity, 'ID'>
  ) }
);

export type UpdateContractRefundMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type UpdateContractRefundMutation = (
  { __typename?: 'Mutation' }
  & { updateContractRefund: (
    { __typename?: 'ContractRefundEntity' }
    & Pick<ContractRefundEntity, 'ID'>
  ) }
);

export type MakeContractRefundMutationVariables = Exact<{
  input: ContractReceiptInput;
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeContractRefundMutation = (
  { __typename?: 'Mutation' }
  & { makeContractRefund: (
    { __typename?: 'ContractRefundEntity' }
    & Pick<ContractRefundEntity, 'ID' | 'docNo'>
  ) }
);

export type CancelContractAdvanceMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelContractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractAdvance'>
);

export type CancelContractReceiptMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelContractReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractReceipt'>
);

export type CancelContractRefundMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelContractRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractRefund'>
);

export type DeleteContractAdvanceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractAdvance'>
);

export type DeleteContractReceiptMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractReceipt'>
);

export type DeleteContractRefundMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractRefund'>
);

export type GetContractAdvanceCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetContractAdvanceCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractAdvanceCount'>
);

export type GetContractReceiptCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetContractReceiptCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractReceiptCount'>
);

export type GetContractRefundCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetContractRefundCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractRefundCount'>
);

export type GetContractDnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetContractDnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DNInfo'>
  & { getContractDN: Array<(
    { __typename?: 'ContractDNEntity' }
    & Pick<ContractDnEntity, 'ID' | 'createdTs' | 'createdBy' | 'isGlExport' | 'modBy' | 'modTs' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'submissionComment' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'outstandingAmt' | 'deductionTypeID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, contractDNItem?: Maybe<Array<(
      { __typename?: 'ContractDNItemEntity' }
      & Pick<ContractDnItemEntity, 'ID' | 'taxSchemeID' | 'revenueCategoryID' | 'wbsID' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetContractDnListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetContractDnListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractDNCount' | 'DNInfo'>
  & { getContractDN: Array<(
    { __typename?: 'ContractDNEntity' }
    & Pick<ContractDnEntity, 'ID' | 'createdTs' | 'createdBy' | 'isGlExport' | 'modBy' | 'modTs' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'submissionComment' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'outstandingAmt' | 'deductionTypeID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, contractDNItem?: Maybe<Array<(
      { __typename?: 'ContractDNItemEntity' }
      & Pick<ContractDnItemEntity, 'ID' | 'taxSchemeID' | 'revenueCategoryID' | 'wbsID' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateContractDnMutationVariables = Exact<{
  input: ContractTransactionWithItemInput;
}>;


export type CreateContractDnMutation = (
  { __typename?: 'Mutation' }
  & { createContractDN: (
    { __typename?: 'ContractDNEntity' }
    & Pick<ContractDnEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type UpdateContractDnMutationVariables = Exact<{
  input: ContractTransactionWithItemInput;
}>;


export type UpdateContractDnMutation = (
  { __typename?: 'Mutation' }
  & { updateContractDN: (
    { __typename?: 'ContractDNEntity' }
    & Pick<ContractDnEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type GetContractMiscInvoiceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetContractMiscInvoiceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'miscInvInfo'>
  & { getContractMiscInvoice: Array<(
    { __typename?: 'ContractMiscInvoiceEntity' }
    & Pick<ContractMiscInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modBy' | 'modTs' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'isDaywork' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, contractMiscInvoiceItem?: Maybe<Array<(
      { __typename?: 'ContractMiscInvoiceItemEntity' }
      & Pick<ContractMiscInvoiceItemEntity, 'ID' | 'taxSchemeID' | 'revenueCategoryID' | 'wbsID' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type GetContractMiscInvoiceListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetContractMiscInvoiceListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'miscInvInfo' | 'getContractMiscInvoiceCount'>
  & { getContractMiscInvoice: Array<(
    { __typename?: 'ContractMiscInvoiceEntity' }
    & Pick<ContractMiscInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modBy' | 'modTs' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'isDaywork' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, contractMiscInvoiceItem?: Maybe<Array<(
      { __typename?: 'ContractMiscInvoiceItemEntity' }
      & Pick<ContractMiscInvoiceItemEntity, 'ID' | 'taxSchemeID' | 'revenueCategoryID' | 'wbsID' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type CreateContractMiscInvoiceMutationVariables = Exact<{
  input: ContractTransactionWithItemInput;
}>;


export type CreateContractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createContractMiscInvoice: (
    { __typename?: 'ContractMiscInvoiceEntity' }
    & Pick<ContractMiscInvoiceEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type UpdateContractMiscInvoiceMutationVariables = Exact<{
  input: ContractTransactionWithItemInput;
}>;


export type UpdateContractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateContractMiscInvoice: (
    { __typename?: 'ContractMiscInvoiceEntity' }
    & Pick<ContractMiscInvoiceEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type GetContractCnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetContractCnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CNInfo'>
  & { getContractCN: Array<(
    { __typename?: 'ContractCNEntity' }
    & Pick<ContractCnEntity, 'ID' | 'isGlExport' | 'modBy' | 'modTs' | 'createdTs' | 'createdBy' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'submissionComment' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'outstandingAmt' | 'deductionTypeID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, contractCNItem?: Maybe<Array<(
      { __typename?: 'ContractCNItemEntity' }
      & Pick<ContractCnItemEntity, 'ID' | 'taxSchemeID' | 'revenueCategoryID' | 'wbsID' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetContractCnListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetContractCnListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CNInfo' | 'getContractCNCount'>
  & { getContractCN: Array<(
    { __typename?: 'ContractCNEntity' }
    & Pick<ContractCnEntity, 'ID' | 'isGlExport' | 'modBy' | 'modTs' | 'createdTs' | 'createdBy' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'submissionComment' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'outstandingAmt' | 'deductionTypeID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, contractCNItem?: Maybe<Array<(
      { __typename?: 'ContractCNItemEntity' }
      & Pick<ContractCnItemEntity, 'ID' | 'taxSchemeID' | 'revenueCategoryID' | 'wbsID' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateContractCnMutationVariables = Exact<{
  input: ContractTransactionWithItemInput;
}>;


export type CreateContractCnMutation = (
  { __typename?: 'Mutation' }
  & { createContractCN: (
    { __typename?: 'ContractCNEntity' }
    & Pick<ContractCnEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type UpdateContractCnMutationVariables = Exact<{
  input: ContractTransactionWithItemInput;
}>;


export type UpdateContractCnMutation = (
  { __typename?: 'Mutation' }
  & { updateContractCN: (
    { __typename?: 'ContractCNEntity' }
    & Pick<ContractCnEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type GetClientDnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetClientDnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'clientDNInfo'>
  & { getClientDN: Array<(
    { __typename?: 'ClientDNEntity' }
    & Pick<ClientDnEntity, 'ID' | 'isGlExport' | 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'submissionComment' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'outstandingAmt' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'deductionTypeID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, clientDNItem?: Maybe<Array<(
      { __typename?: 'ClientDNItemEntity' }
      & Pick<ClientDnItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetClientDnListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetClientDnListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'clientDNInfo' | 'getClientDNCount'>
  & { getClientDN: Array<(
    { __typename?: 'ClientDNEntity' }
    & Pick<ClientDnEntity, 'ID' | 'isGlExport' | 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'submissionComment' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'outstandingAmt' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'deductionTypeID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, clientDNItem?: Maybe<Array<(
      { __typename?: 'ClientDNItemEntity' }
      & Pick<ClientDnItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateClientDnMutationVariables = Exact<{
  input: ContractTransactionWithItemInput;
}>;


export type CreateClientDnMutation = (
  { __typename?: 'Mutation' }
  & { createClientDN: (
    { __typename?: 'ClientDNEntity' }
    & Pick<ClientDnEntity, 'accountID' | 'ID'>
  ) }
);

export type UpdateClientDnMutationVariables = Exact<{
  input: ContractTransactionWithItemInput;
}>;


export type UpdateClientDnMutation = (
  { __typename?: 'Mutation' }
  & { updateClientDN: (
    { __typename?: 'ClientDNEntity' }
    & Pick<ClientDnEntity, 'accountID' | 'ID'>
  ) }
);

export type GetClientCnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetClientCnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'clientCNInfo'>
  & { getClientCN: Array<(
    { __typename?: 'ClientCNEntity' }
    & Pick<ClientCnEntity, 'ID' | 'isGlExport' | 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'submissionComment' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'outstandingAmt' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'deductionTypeID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, clientCNItem?: Maybe<Array<(
      { __typename?: 'ClientCNItemEntity' }
      & Pick<ClientCnItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetClientCnListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetClientCnListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'clientCNInfo' | 'getClientCNCount'>
  & { getClientCN: Array<(
    { __typename?: 'ClientCNEntity' }
    & Pick<ClientCnEntity, 'ID' | 'isGlExport' | 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'submissionComment' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'outstandingAmt' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'customerID' | 'contractID' | 'deductionTypeID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, clientCNItem?: Maybe<Array<(
      { __typename?: 'ClientCNItemEntity' }
      & Pick<ClientCnItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateClientCnMutationVariables = Exact<{
  input: ContractTransactionWithItemInput;
}>;


export type CreateClientCnMutation = (
  { __typename?: 'Mutation' }
  & { createClientCN: (
    { __typename?: 'ClientCNEntity' }
    & Pick<ClientCnEntity, 'accountID' | 'ID'>
  ) }
);

export type UpdateClientCnMutationVariables = Exact<{
  input: ContractTransactionWithItemInput;
}>;


export type UpdateClientCnMutation = (
  { __typename?: 'Mutation' }
  & { updateClientCN: (
    { __typename?: 'ClientCNEntity' }
    & Pick<ClientCnEntity, 'accountID' | 'ID'>
  ) }
);

export type CancelContractDnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelContractDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractDN'>
);

export type CancelContractMiscInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelContractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractMiscInvoice'>
);

export type CancelContractCnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelContractCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractCN'>
);

export type CancelClientDnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelClientDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClientDN'>
);

export type CancelClientCnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelClientCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClientCN'>
);

export type DeleteContractCnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractCN'>
);

export type DeleteContractDnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractDN'>
);

export type DeleteContractMiscInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractMiscInvoice'>
);

export type DeleteClientDnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteClientDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteClientDN'>
);

export type DeleteClientCnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteClientCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteClientCN'>
);

export type GetClientAdjustmentQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetClientAdjustmentQuery = (
  { __typename?: 'Query' }
  & { getContractDN: Array<(
    { __typename?: 'ContractDNEntity' }
    & Pick<ContractDnEntity, 'ID' | 'docAmt' | 'isGlExport' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'numOfAttachment'>
  )>, getCNRevenue: Array<(
    { __typename?: 'ContractCNEntity' }
    & Pick<ContractCnEntity, 'ID' | 'docAmt' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'numOfAttachment' | 'cancelledClaimAmt'>
  )>, getClientDN: Array<(
    { __typename?: 'ClientDNEntity' }
    & Pick<ClientDnEntity, 'ID' | 'docAmt' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'numOfAttachment'>
  )>, getClientCN: Array<(
    { __typename?: 'ClientCNEntity' }
    & Pick<ClientCnEntity, 'ID' | 'docAmt' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'numOfAttachment'>
  )> }
);

export type GetContractDnCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetContractDnCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractDNCount'>
);

export type GetContractCnCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetContractCnCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractCNCount'>
);

export type GetContractMiscInvoiceCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetContractMiscInvoiceCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractMiscInvoiceCount'>
);

export type GetClientCnCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetClientCnCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getClientCNCount'>
);

export type GetClientDnCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetClientDnCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getClientDNCount'>
);

export type GetUserCompanyAssignmentQueryVariables = Exact<{
  userID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetUserCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getUserCompanyAssignment: Array<(
    { __typename?: 'UserCompanyAssignmentEntity' }
    & Pick<UserCompanyAssignmentEntity, 'ID' | 'companyID' | 'userID'>
  )> }
);

export type GetSubcontractorAllQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
}>;


export type GetSubcontractorAllQuery = (
  { __typename?: 'Query' }
  & { getSubcontractorByCompany: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'registrationNo'>
  )> }
);

export type GetSupplierAllQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
}>;


export type GetSupplierAllQuery = (
  { __typename?: 'Query' }
  & { getSupplierByCompany: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'registrationNo'>
  )> }
);

export type GetCustomerAllQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
}>;


export type GetCustomerAllQuery = (
  { __typename?: 'Query' }
  & { getCustomerByCompany: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'registrationNo'>
  )> }
);

export type AssignSubconWithCompanyMutationVariables = Exact<{
  companyID: Scalars['String'];
  subcontractorID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignSubconWithCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignSubconWithCompany'>
);

export type UnassignSubconWithCompanyMutationVariables = Exact<{
  companyID: Scalars['String'];
  subcontractorID: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnassignSubconWithCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignSubconWithCompany'>
);

export type AssignSupplierWithCompanyMutationVariables = Exact<{
  companyID: Scalars['String'];
  supplierID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignSupplierWithCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignSupplierWithCompany'>
);

export type UnassignSupplierWithCompanyMutationVariables = Exact<{
  companyID: Scalars['String'];
  supplierID: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnassignSupplierWithCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignSupplierWithCompany'>
);

export type AssignCustomerWithCompanyMutationVariables = Exact<{
  companyID: Scalars['String'];
  customerID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignCustomerWithCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignCustomerWithCompany'>
);

export type UnassignCustomerWithCompanyMutationVariables = Exact<{
  companyID: Scalars['String'];
  customerID: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnassignCustomerWithCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignCustomerWithCompany'>
);

export type AssignUserWithCompanyMutationVariables = Exact<{
  companyID: Scalars['String'];
  userID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignUserWithCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignUserWithCompany'>
);

export type UnassignUserWithCompanyMutationVariables = Exact<{
  companyID: Scalars['String'];
  userID: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnassignUserWithCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignUserWithCompany'>
);

export type CreateCompanyMutationVariables = Exact<{
  input: CompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCompany'>
);

export type UpdateCompanyMutationVariables = Exact<{
  input: CompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompany'>
);

export type DeleteCompanyMutationVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompany'>
);

export type GetCompanyQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'createdTs' | 'modTs' | 'CompanyID' | 'Name' | 'Description' | 'CompanyRegNo' | 'Tin' | 'GroupGSTRegNo' | 'ContactNo' | 'Email' | 'SSTNo' | 'GSTNo' | 'GSTExpiryDate' | 'Image' | 'ImageUrl' | 'CompanyTax' | 'BaseCurrencyID' | 'RecordStatus' | 'WatermarkWording' | 'WatermarkImplementation' | 'ImageLogoPosition' | 'IsoImplementation' | 'IsoLogoPosition' | 'eSign' | 'eSignExpiryDate' | 'eInvIntegrated' | 'eInvStartDate' | 'eInvEndDate'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'address2' | 'country' | 'city' | 'district' | 'state' | 'postCode'>
    )> }
  )> }
);

export type GetCompanyListQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyListQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name' | 'Description' | 'CompanyRegNo' | 'Tin' | 'Image' | 'ImageUrl' | 'RecordStatus'>
  )> }
);

export type GetCompanySubmenuQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanySubmenuQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'createdTs' | 'modTs' | 'CompanyID' | 'Name' | 'Description' | 'CompanyRegNo' | 'Tin' | 'Image' | 'ImageUrl' | 'RecordStatus'>
  )>, getAllCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name' | 'Description' | 'CompanyRegNo' | 'Tin' | 'Image' | 'ImageUrl' | 'RecordStatus'>
  )> }
);

export type GetCompanyHeaderQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyHeaderQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name' | 'CompanyRegNo' | 'ContactNo' | 'SSTNo' | 'GSTNo' | 'Image' | 'ImageBucketFile' | 'ImageLogoPosition' | 'ImageUrl'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'address2' | 'country' | 'city' | 'district' | 'state' | 'postCode'>
    )> }
  )> }
);

export type GetCompanyIsoQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyIsoQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name' | 'IsoImplementation' | 'IsoLogoPosition'>
  )> }
);

export type GetAllCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCompanyQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'ParentCompanyID' | 'AccountID' | 'BaseCurrencyID' | 'Name' | 'Description' | 'CompanyRegNo' | 'Tin' | 'GroupGSTRegNo' | 'Code' | 'ContactNo' | 'Email' | 'SSTNo' | 'GSTNo' | 'GSTExpiryDate' | 'Image' | 'ImageUrl' | 'ImageBucketFile' | 'WebsiteUrl' | 'RegionID' | 'ImageLogoPosition' | 'WatermarkImplementation' | 'WatermarkWording' | 'IsoImplementation' | 'IsoLogoPosition' | 'CompanyTax' | 'RecordStatus' | 'createdBy' | 'createdTs' | 'modBy' | 'modTs' | 'eSign' | 'eSignExpiryDate'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
    )> }
  )> }
);

export type GetCompanySummaryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanySummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'bankAccountDate' | 'docNumDate'>
);

export type GetBqSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetBqSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'wbsBudgetInfo' | 'lastBqImportDate' | 'bqAssignmentInfo'>
  & { contractInfo: (
    { __typename?: 'ContractInfo' }
    & Pick<ContractInfo, 'contractSum' | 'contractDate'>
  ) }
);

export type GetBqQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetBqQuery = (
  { __typename?: 'Query' }
  & { getBQ: Array<(
    { __typename?: 'BQEntity' }
    & Pick<BqEntity, 'billNo' | 'pageNo' | 'itemNo' | 'uomID' | 'description' | 'quantity' | 'rate' | 'amount' | 'isLastNode'>
  )> }
);

export type CreateBqMutationVariables = Exact<{
  input: Array<BqInput> | BqInput;
  contractID: Scalars['String'];
}>;


export type CreateBqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBQ'>
);

export type GetCheckingValidationBqQueryVariables = Exact<{
  BQExcelInput: Array<BqExcelInput> | BqExcelInput;
  contractID: Scalars['String'];
}>;


export type GetCheckingValidationBqQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationBQ'>
);

export type GetBqParentChildQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetBqParentChildQuery = (
  { __typename?: 'Query' }
  & { getBQParentChild: Array<(
    { __typename?: 'BQEntity' }
    & Pick<BqEntity, 'ID' | 'billNo' | 'pageNo' | 'itemNo' | 'uomID' | 'description' | 'quantity' | 'rate' | 'amount' | 'isLastNode'>
    & { uom?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, childrenBq?: Maybe<Array<(
      { __typename?: 'BQEntity' }
      & Pick<BqEntity, 'ID' | 'billNo' | 'pageNo' | 'itemNo' | 'uomID' | 'description' | 'quantity' | 'rate' | 'amount' | 'isLastNode'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )> }
    )>> }
  )> }
);

export type GetBqWbsQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
}>;


export type GetBqWbsQuery = (
  { __typename?: 'Query' }
  & { getWBS: Array<(
    { __typename?: 'WBSEntity' }
    & Pick<WbsEntity, 'ID' | 'name' | 'contractID' | 'parentWBSID' | 'isLastNode' | 'sequence' | 'startDate' | 'endDate' | 'bqAmt' | 'bqCount'>
    & { revenueDetail?: Maybe<Array<(
      { __typename?: 'WbsBudgetDetailEntity' }
      & Pick<WbsBudgetDetailEntity, 'ID' | 'quantity' | 'unitPrice' | 'trackQty' | 'uomID'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'code' | 'name' | 'commonStatus'>
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name' | 'description'>
      )> }
    )>> }
  )> }
);

export type GetBqWbsParentChildQueryVariables = Exact<{
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
}>;


export type GetBqWbsParentChildQuery = (
  { __typename?: 'Query' }
  & { getBQWbsParentChild: Array<(
    { __typename?: 'BQEntity' }
    & Pick<BqEntity, 'ID' | 'uomID' | 'quantity' | 'rate' | 'amount' | 'billNo' | 'pageNo' | 'itemNo' | 'description' | 'isLastNode'>
    & { uom?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, childrenBq?: Maybe<Array<(
      { __typename?: 'BQEntity' }
      & Pick<BqEntity, 'ID' | 'uomID' | 'quantity' | 'rate' | 'amount' | 'billNo' | 'pageNo' | 'itemNo' | 'description' | 'isLastNode'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )> }
    )>> }
  )> }
);

export type AssignBqWbsAssignmentMutationVariables = Exact<{
  wbsID: Scalars['String'];
  bqID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignBqWbsAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignBqWbsAssignment'>
);

export type UnassignBqWbsAssignmentMutationVariables = Exact<{
  wbsID: Scalars['String'];
  bqID: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnassignBqWbsAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignBqWbsAssignment'>
);

export type GetBudgetQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
}>;


export type GetBudgetQuery = (
  { __typename?: 'Query' }
  & { getBudget: Array<(
    { __typename?: 'WbsBudgetEntity' }
    & Pick<WbsBudgetEntity, 'createdTs' | 'createdBy' | 'ID' | 'budgetStatus' | 'contractID' | 'copyFromBudgetID' | 'name' | 'description' | 'reason' | 'version' | 'totalCostRevenue'>
    & { copyFromBudget?: Maybe<(
      { __typename?: 'WbsBudgetEntity' }
      & Pick<WbsBudgetEntity, 'ID' | 'name'>
    )>, wbs: Array<(
      { __typename?: 'WBSEntity' }
      & Pick<WbsEntity, 'ID' | 'parentWBSID' | 'name' | 'startDate' | 'endDate' | 'isLastNode'>
    )> }
  )> }
);

export type GetBudgetDetailQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
}>;


export type GetBudgetDetailQuery = (
  { __typename?: 'Query' }
  & { getBudget: Array<(
    { __typename?: 'WbsBudgetEntity' }
    & Pick<WbsBudgetEntity, 'createdTs' | 'createdBy' | 'ID' | 'contractID' | 'name' | 'description' | 'reason' | 'version' | 'budgetStatus' | 'totalCostRevenue'>
  )> }
);

export type GetBudgetRevisionFormQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
}>;


export type GetBudgetRevisionFormQuery = (
  { __typename?: 'Query' }
  & { getBudget: Array<(
    { __typename?: 'WbsBudgetEntity' }
    & Pick<WbsBudgetEntity, 'ID' | 'name' | 'description' | 'reason' | 'budgetStatus' | 'copyFromBudgetID'>
    & { copyFromBudget?: Maybe<(
      { __typename?: 'WbsBudgetEntity' }
      & Pick<WbsBudgetEntity, 'ID' | 'name'>
    )> }
  )>, getAllBudget: Array<(
    { __typename?: 'WbsBudgetEntity' }
    & Pick<WbsBudgetEntity, 'ID' | 'name' | 'description' | 'reason' | 'budgetStatus' | 'copyFromBudgetID'>
    & { copyFromBudget?: Maybe<(
      { __typename?: 'WbsBudgetEntity' }
      & Pick<WbsBudgetEntity, 'ID' | 'name'>
    )> }
  )>, getBudgetRevision: Array<(
    { __typename?: 'WbsBudgetEntity' }
    & Pick<WbsBudgetEntity, 'ID' | 'name' | 'description' | 'reason' | 'budgetStatus' | 'copyFromBudgetID'>
    & { copyFromBudget?: Maybe<(
      { __typename?: 'WbsBudgetEntity' }
      & Pick<WbsBudgetEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type CopyBudgetContractMutationVariables = Exact<{
  wbsIDs: Array<Scalars['String']> | Scalars['String'];
  input: CopyBudgetInput;
}>;


export type CopyBudgetContractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'copyBudgetContract'>
);

export type SubmitBudgetMutationVariables = Exact<{
  input: WbsBudgetSubmitApproveInput;
}>;


export type SubmitBudgetMutation = (
  { __typename?: 'Mutation' }
  & { submitBudget: (
    { __typename?: 'WbsBudgetEntity' }
    & Pick<WbsBudgetEntity, 'ID' | 'budgetStatus'>
  ) }
);

export type DeleteBudgetMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBudget'>
);

export type CreateBudgetRevisionMutationVariables = Exact<{
  input: WbsBudgetRevisionInput;
}>;


export type CreateBudgetRevisionMutation = (
  { __typename?: 'Mutation' }
  & { createBudgetRevision: (
    { __typename?: 'WbsBudgetEntity' }
    & Pick<WbsBudgetEntity, 'ID' | 'copyFromBudgetID' | 'name' | 'description' | 'reason'>
  ) }
);

export type SwitchBudgetMutationVariables = Exact<{
  ID: Scalars['String'];
  selectedBudgetID: Scalars['String'];
}>;


export type SwitchBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'switchBudget'>
);

export type GetContractQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'pdfDocumentTitle' | 'accountID' | 'contractNo' | 'customerID' | 'companyID' | 'description' | 'bankAccountID' | 'implementWbs' | 'latestBudgetID' | 'contractDate' | 'contractSum' | 'voSum' | 'revisedContractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'ladAmt' | 'ladRate' | 'creditTerm' | 'latestRunNum' | 'docNumChecking' | 'contractPOCounts' | 'contractStatus' | 'subcontractSummary' | 'contactPerson' | 'closureRemarks' | 'closureDate' | 'modBy' | 'extendedDate' | 'extensionDate' | 'budgetChecker'>
    & { wbs?: Maybe<Array<(
      { __typename?: 'WBSEntity' }
      & Pick<WbsEntity, 'ID' | 'name'>
    )>>, bankAccount?: Maybe<(
      { __typename?: 'BankAccountEntity' }
      & Pick<BankAccountEntity, 'ID' | 'name' | 'branch' | 'accountNo' | 'swiftCode'>
    )>, site?: Maybe<Array<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address' | 'mainSite'>
    )>>, customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'address' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )>, subcontractTenderDetail?: Maybe<Array<(
      { __typename?: 'SubcontractTenderEntity' }
      & Pick<SubcontractTenderEntity, 'ID' | 'title'>
    )>>, subcontractDetail?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'contractID' | 'subcontractorID' | 'costCategoryID' | 'subcontractNo' | 'description' | 'subcontractDate' | 'taxSchemeID' | 'subcontractSum' | 'revisedSubcontractSum' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'creditTerm' | 'subcontractStatus'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name' | 'associatedStatus'>
      )> }
    )>>, contact?: Maybe<Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'email' | 'designation' | 'contactNo'>
    )>>, taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxRate' | 'taxDate'>
      )>, taxEffective?: Maybe<Array<(
        { __typename?: 'ConTaxEffectiveDateEntity' }
        & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
      )>> }
    )> }
  )> }
);

export type GetContractListingQueryVariables = Exact<{
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractListingQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'accountID' | 'customerID' | 'companyID' | 'title' | 'pdfDocumentTitle' | 'contractNo' | 'description' | 'contractDate' | 'contractSum' | 'voSum' | 'revisedContractSum' | 'startDate' | 'endDate' | 'contractStatus'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'address' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )> }
  )> }
);

export type GetContractSubmenuQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetContractSubmenuQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'companyID' | 'contractStatus' | 'contractDate' | 'startDate' | 'endDate' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'extensionDate' | 'contractSum' | 'revisedContractSum' | 'implementWbs' | 'closureDate' | 'closureRemarks'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name'>
    )> }
  )>, getAllContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'companyID' | 'contractNo' | 'customerID'>
    & { originalBudget?: Maybe<(
      { __typename?: 'WbsBudgetEntity' }
      & Pick<WbsBudgetEntity, 'ID' | 'budgetStatus' | 'totalCostRevenue'>
    )> }
  )> }
);

export type CreateContractMutationVariables = Exact<{
  input: ContractInput;
  siteInput: Array<SiteInput> | SiteInput;
}>;


export type CreateContractMutation = (
  { __typename?: 'Mutation' }
  & { createContract: (
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'accountID' | 'customerID' | 'taxSchemeID' | 'contractNo' | 'title' | 'description' | 'contractSum' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'cmgdDate' | 'cpcDate' | 'creditTerm' | 'contractDate'>
  ) }
);

export type UpdateContractMutationVariables = Exact<{
  input: ContractInput;
}>;


export type UpdateContractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateContract'>
);

export type GetContractGuaranteeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetContractGuaranteeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractGuaranteeInfo'>
  & { getContractGuarantee: Array<(
    { __typename?: 'ContractGuaranteeEntity' }
    & Pick<ContractGuaranteeEntity, 'ID' | 'modBy' | 'accountID' | 'contractID' | 'docRef' | 'issuer' | 'guaranteeTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'collateralAmt' | 'remarks' | 'cancellationDate' | 'guaranteeStatus'>
    & { guaranteeType?: Maybe<(
      { __typename?: 'GuaranteeTypeEntity' }
      & Pick<GuaranteeTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateContractGuaranteeMutationVariables = Exact<{
  input: ContractGuaranteeInput;
}>;


export type CreateContractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { createContractGuarantee: (
    { __typename?: 'ContractGuaranteeEntity' }
    & Pick<ContractGuaranteeEntity, 'ID'>
  ) }
);

export type UpdateContractGuaranteeMutationVariables = Exact<{
  input: ContractGuaranteeInput;
}>;


export type UpdateContractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { updateContractGuarantee: (
    { __typename?: 'ContractGuaranteeEntity' }
    & Pick<ContractGuaranteeEntity, 'ID'>
  ) }
);

export type GetContractInsuranceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
}>;


export type GetContractInsuranceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractInsuranceInfo'>
  & { getContractInsurance: Array<(
    { __typename?: 'ContractInsuranceEntity' }
    & Pick<ContractInsuranceEntity, 'ID' | 'modBy' | 'accountID' | 'contractID' | 'docRef' | 'issuer' | 'insuranceTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'remarks' | 'cancellationDate' | 'insuranceStatus'>
    & { insuranceType?: Maybe<(
      { __typename?: 'InsuranceTypeEntity' }
      & Pick<InsuranceTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateContractInsuranceMutationVariables = Exact<{
  input: ContractInsuranceInput;
}>;


export type CreateContractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { createContractInsurance: (
    { __typename?: 'ContractInsuranceEntity' }
    & Pick<ContractInsuranceEntity, 'ID'>
  ) }
);

export type UpdateContractInsuranceMutationVariables = Exact<{
  input: ContractInsuranceInput;
}>;


export type UpdateContractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { updateContractInsurance: (
    { __typename?: 'ContractInsuranceEntity' }
    & Pick<ContractInsuranceEntity, 'ID'>
  ) }
);

export type ActionOnContractStatusMutationVariables = Exact<{
  input: ContractActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnContractStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnContractStatus'>
);

export type CreateContractExtensionDateMutationVariables = Exact<{
  input: ContractExtensionInput;
  ID: Scalars['String'];
}>;


export type CreateContractExtensionDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createContractExtensionDate'>
);

export type CancelContractGuaranteeMutationVariables = Exact<{
  input: ContractGuaranteeCancellationInput;
}>;


export type CancelContractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractGuarantee'>
);

export type CancelContractInsuranceMutationVariables = Exact<{
  input: ContractInsuranceCancellationInput;
}>;


export type CancelContractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractInsurance'>
);

export type GetContractSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
  type?: Maybe<Scalars['String']>;
}>;


export type GetContractSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'wbsBudgetInfo' | 'getWbsTotalCostRevenue' | 'wbsBudgetRevisionInfo' | 'utilizedBudgetInfo' | 'budgetTransferInfo' | 'contractGuaranteeInfo' | 'contractInsuranceInfo' | 'submittedVONotification' | 'lastExtensionDate' | 'contractClosureDate' | 'contractVoCalculation' | 'totalDigitalDocuments' | 'conAuthDate' | 'conAuthCount'>
  & { contractInfo: (
    { __typename?: 'ContractInfo' }
    & Pick<ContractInfo, 'contractSum' | 'contractDate'>
  ), getVOHealthIndex: (
    { __typename?: 'VOHealthIndex' }
    & Pick<VoHealthIndex, 'conVOOmission' | 'conVOSum' | 'totalVO' | 'totalPendingVO' | 'totalSubVO' | 'totalPendingSubVO'>
  ), outstandingAdvances: Array<(
    { __typename?: 'ContractAdvanceEntity' }
    & Pick<ContractAdvanceEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, outstandingClaims: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type CreateContractCertificateMutationVariables = Exact<{
  input: ContractCertificateInput;
  ID: Scalars['String'];
}>;


export type CreateContractCertificateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createContractCertificate'>
);

export type GetContractExtensionQueryVariables = Exact<{
  ID: Scalars['String'];
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractExtensionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastExtensionDate'>
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'description' | 'contractDate' | 'contractSum' | 'revisedContractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'creditTerm' | 'contractStatus' | 'extensionDate'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )> }
  )> }
);

export type GetContractTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetContractTitleQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'companyID' | 'customerID' | 'title' | 'pdfDocumentTitle' | 'contractNo' | 'contractDate' | 'startDate' | 'endDate' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'revisedContractSum' | 'contractSum' | 'implementWbs' | 'contractStatus' | 'budgetChecker'>
    & { company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'Name'>
    )>, customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name'>
    )>, site?: Maybe<Array<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'address'>
    )>> }
  )> }
);

export type GetContractWithSiteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetContractWithSiteQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'companyID' | 'customerID' | 'title' | 'contractNo' | 'contractDate' | 'startDate' | 'endDate' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'contractSum' | 'revisedContractSum' | 'implementWbs' | 'contractStatus' | 'taxSchemeID' | 'budgetChecker'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name'>
    )>, site?: Maybe<Array<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address' | 'mainSite'>
    )>>, contact?: Maybe<Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'contactNo' | 'designation' | 'email' | 'name'>
    )>> }
  )> }
);

export type GetContractTitleWoPermQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetContractTitleWoPermQuery = (
  { __typename?: 'Query' }
  & { getContractWithoutPerm: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'companyID' | 'customerID' | 'title' | 'contractNo' | 'contractDate' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'revisedContractSum' | 'contractSum' | 'contractStatus'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name'>
    )>, site?: Maybe<Array<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'address'>
    )>> }
  )> }
);

export type InsertUpdateSiteMutationVariables = Exact<{
  siteInput: Array<SiteInput> | SiteInput;
  contractID: Scalars['String'];
}>;


export type InsertUpdateSiteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'insertUpdateSite'>
);

export type ContractCheckerQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type ContractCheckerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'claimChecking' | 'voChecking'>
  & { getWbsBudget: Array<(
    { __typename?: 'WbsBudgetEntity' }
    & Pick<WbsBudgetEntity, 'ID' | 'contractID' | 'budgetStatus'>
  )> }
);

export type GetCustomerForContractQueryVariables = Exact<{
  associatedStatus?: Maybe<AssociatedStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCustomerForContractQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'name' | 'ID' | 'associatedStatus'>
    & { customerCompanyAssignment?: Maybe<Array<(
      { __typename?: 'CustomerCompanyAssignmentEntity' }
      & Pick<CustomerCompanyAssignmentEntity, 'ID' | 'companyID' | 'customerID'>
    )>> }
  )> }
);

export type GetContractTitleForReportQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetContractTitleForReportQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'companyID' | 'customerID' | 'taxSchemeID' | 'implementWbs'>
  )> }
);

export type GetApprovalPolicyWithContractDateQueryVariables = Exact<{
  parameter: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetApprovalPolicyWithContractDateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'approvalPolicyWithContractDate'>
);

export type GetContractLedgersQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetContractLedgersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractLedgers'>
);

export type GetUserRoleAssignedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRoleAssignedQuery = (
  { __typename?: 'Query' }
  & { getUserRoleAssigned: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
  )> }
);

export type GetDigitalDocumentQueryVariables = Exact<{
  associateID: Scalars['String'];
  type: Scalars['String'];
}>;


export type GetDigitalDocumentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDigitalDocument' | 'totalDigitalDocuments'>
);

export type GetTableDocumentsQueryVariables = Exact<{
  refID: Array<Scalars['String']> | Scalars['String'];
  associateID: Scalars['String'];
  refTable: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type GetTableDocumentsQuery = (
  { __typename?: 'Query' }
  & { getTableDocuments: Array<(
    { __typename?: 'DigitalDocuments' }
    & Pick<DigitalDocuments, 'ID' | 'refID' | 'fileName' | 'fileURL' | 'mediaType' | 'bucketFileName' | 'description' | 'size' | 'createdTs'>
  )> }
);

export type AddDigitalDocumentsMutationVariables = Exact<{
  input: DigitalDocumentInput;
}>;


export type AddDigitalDocumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addDigitalDocuments'>
);

export type DeleteDigitalDocumentsMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteDigitalDocumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDigitalDocuments'>
);

export type GetWbsFinancialQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetWbsFinancialQuery = (
  { __typename?: 'Query' }
  & { getWBS: Array<(
    { __typename?: 'WBSEntity' }
    & Pick<WbsEntity, 'ID' | 'parentWBSID' | 'name' | 'startDate' | 'endDate' | 'isLastNode' | 'sumContractVORevenue' | 'committedCost' | 'actualCost' | 'actualRevenue' | 'totalDN' | 'totalCost' | 'totalRevenue' | 'originalCost' | 'originalRevenue' | 'revisedRevenue' | 'revisedCost' | 'sequence'>
    & { childrenWbs?: Maybe<Array<(
      { __typename?: 'WBSEntity' }
      & Pick<WbsEntity, 'ID' | 'parentWBSID' | 'name' | 'startDate' | 'endDate' | 'isLastNode' | 'sumContractVORevenue' | 'committedCost' | 'actualCost' | 'totalDN' | 'actualRevenue' | 'totalCost' | 'originalCost' | 'originalRevenue' | 'revisedRevenue' | 'revisedCost' | 'totalRevenue' | 'sequence'>
      & { childrenWbs?: Maybe<Array<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'parentWBSID' | 'name' | 'startDate' | 'endDate' | 'isLastNode' | 'sumContractVORevenue' | 'committedCost' | 'totalDN' | 'actualCost' | 'actualRevenue' | 'totalCost' | 'revisedRevenue' | 'totalRevenue' | 'originalCost' | 'revisedCost' | 'sequence'>
      )>> }
    )>> }
  )> }
);

export type GetContractFinancialQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetContractFinancialQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'customerID' | 'title' | 'contractNo' | 'contractDate' | 'startDate' | 'financialClientReceipt' | 'endDate' | 'contractSum' | 'revisedContractSum' | 'voSum'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name'>
    )>, originalBudget?: Maybe<(
      { __typename?: 'WbsBudgetEntity' }
      & Pick<WbsBudgetEntity, 'ID' | 'budgetStatus' | 'totalCostRevenue'>
    )>, revisedBudget?: Maybe<(
      { __typename?: 'WbsBudgetEntity' }
      & Pick<WbsBudgetEntity, 'ID' | 'budgetStatus' | 'totalCostRevenue'>
    )> }
  )> }
);

export type GetContractRetentionQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetContractRetentionQuery = (
  { __typename?: 'Query' }
  & { getRetentionClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'customerID' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )>, retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type GetSubcontractRetentionQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractRetentionQuery = (
  { __typename?: 'Query' }
  & { getSubconRetentionClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'subcontractID' | 'subcontractorID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )>, retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type RetentionFormCheckerQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type RetentionFormCheckerQuery = (
  { __typename?: 'Query' }
  & { retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type RetentionCheckingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type RetentionCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'retentionChecking' | 'submittedRetClaimChecking'>
);

export type SubmittedRetClaimCheckingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type SubmittedRetClaimCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'submittedRetClaimChecking' | 'fetchUnallocatedClaimRetention'>
);

export type GetSubcontractVoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  VOStatus?: Maybe<VoStatus>;
}>;


export type GetSubcontractVoQuery = (
  { __typename?: 'Query' }
  & { getSubcontractVO: Array<(
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'accountID' | 'contractID' | 'subcontractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'approvedAmt' | 'remarks' | 'submittedDate' | 'rejectedDate' | 'docDate' | 'subcontractClaimVOAlloc'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, subcontractClaimVOAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimVOAllocationEntity' }
      & Pick<SubcontractClaimVoAllocationEntity, 'ID' | 'subcontractVOID' | 'previousVOClaimAmt' | 'currentVOClaimAmt' | 'submittedVOClaimAmt'>
    )>>, subcontractVOCostWbs?: Maybe<Array<(
      { __typename?: 'SubcontractVOCostWbsEntity' }
      & Pick<SubcontractVoCostWbsEntity, 'ID' | 'subcontractVOID' | 'subcontractCostWbsID' | 'submissionAmt' | 'approvedAmt'>
      & { subcontractCostWbs?: Maybe<(
        { __typename?: 'SubcontractCostWbsEntity' }
        & Pick<SubcontractCostWbsEntity, 'ID'>
        & { wbs?: Maybe<(
          { __typename?: 'WBSEntity' }
          & Pick<WbsEntity, 'ID' | 'name'>
        )>, costCategory?: Maybe<(
          { __typename?: 'CostCategoryEntity' }
          & Pick<CostCategoryEntity, 'ID' | 'name'>
        )> }
      )> }
    )>> }
  )> }
);

export type CreateSubcontractVoMutationVariables = Exact<{
  input: SubcontractVoInput;
  voDetails?: Maybe<Array<SubconVoDetailsInput> | SubconVoDetailsInput>;
}>;


export type CreateSubcontractVoMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractVO: (
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'ID' | 'docNo'>
  ) }
);

export type UpdateSubcontractVoMutationVariables = Exact<{
  input: SubcontractVoInput;
  voDetails?: Maybe<Array<SubconVoDetailsInput> | SubconVoDetailsInput>;
}>;


export type UpdateSubcontractVoMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractVO: (
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'ID' | 'docRef'>
  ) }
);

export type DeleteSubcontractVoMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractVoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractVO'>
);

export type ActionOnSubconVoStatusMutationVariables = Exact<{
  ID: Scalars['String'];
  input: SubcontractVoActionInput;
  itemApprovedAmount?: Maybe<Array<ItemApprovedAmountInput> | ItemApprovedAmountInput>;
}>;


export type ActionOnSubconVoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnSubconVOStatus'>
);

export type GetSubcontractVoSummaryQueryVariables = Exact<{
  subcontractID: Scalars['String'];
}>;


export type GetSubcontractVoSummaryQuery = (
  { __typename?: 'Query' }
  & { subcontractVO: (
    { __typename?: 'SubcontractVO' }
    & Pick<SubcontractVo, 'voSum' | 'voOmission' | 'voSumCount' | 'voOmissionCount' | 'totalVoAmt'>
  ) }
);

export type GetSubcontractVoListingQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetSubcontractVoListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subcontractVOListing'>
);

export type GetSubconVoQueryVariables = Exact<{
  contractID: Scalars['String'];
  voStatus: VoStatus;
}>;


export type GetSubconVoQuery = (
  { __typename?: 'Query' }
  & { getSubcontractVO: Array<(
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'approvedAmt' | 'submissionAmt' | 'VOType' | 'docNo' | 'docRef' | 'submittedDate' | 'docDate' | 'description'>
  )> }
);

export type GetSubconPendingVoQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetSubconPendingVoQuery = (
  { __typename?: 'Query' }
  & { submitSubVO: Array<(
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'approvedAmt' | 'submissionAmt' | 'VOType' | 'docNo' | 'docRef' | 'submittedDate' | 'docDate' | 'description'>
  )>, pendingSubVO: Array<(
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'approvedAmt' | 'submissionAmt' | 'VOType' | 'docNo' | 'docRef' | 'submittedDate' | 'docDate' | 'description'>
  )> }
);

export type GetSubcontractWithTradeQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractWithTradeQuery = (
  { __typename?: 'Query' }
  & { getSubcontractWithTrade: Array<(
    { __typename?: 'SubcontractCostWbsEntity' }
    & Pick<SubcontractCostWbsEntity, 'ID' | 'wbsID' | 'tradeAmt' | 'costCategoryID' | 'subcontractID' | 'revisedTradeAmt' | 'claimToDateWithTrade'>
    & { wbs?: Maybe<(
      { __typename?: 'WBSEntity' }
      & Pick<WbsEntity, 'ID' | 'name'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'name'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title' | 'subcontractNo' | 'subcontractDate' | 'subcontractorID'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'name'>
      )> }
    )> }
  )> }
);

export type GetSubcontractCostWbsQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractCostWbsQuery = (
  { __typename?: 'Query' }
  & { getSubcontractCostWbs: Array<(
    { __typename?: 'SubcontractCostWbsEntity' }
    & Pick<SubcontractCostWbsEntity, 'ID' | 'wbsID' | 'tradeAmt' | 'costCategoryID' | 'subcontractID' | 'revisedTradeAmt' | 'claimToDateWithTrade'>
    & { wbs?: Maybe<(
      { __typename?: 'WBSEntity' }
      & Pick<WbsEntity, 'ID' | 'name'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'name'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title' | 'subcontractNo' | 'subcontractDate' | 'subcontractorID'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'name'>
      )> }
    )> }
  )> }
);

export type GetSubcontractCostWbsCostCategoryQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractCostWbsCostCategoryQuery = (
  { __typename?: 'Query' }
  & { getSubcontractCostWbs: Array<(
    { __typename?: 'SubcontractCostWbsEntity' }
    & Pick<SubcontractCostWbsEntity, 'costCategoryID' | 'tradeAmt'>
    & { costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'name'>
    )> }
  )> }
);

export type GetSubconVoTradeCostCategoryQueryVariables = Exact<{
  contractID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type GetSubconVoTradeCostCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconVOTradeCostCategory'>
);

export type GetSubcontractQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractStatus?: Maybe<ContractStatus>;
}>;


export type GetSubcontractQuery = (
  { __typename?: 'Query' }
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'contractID' | 'subcontractorID' | 'siteID' | 'costCategoryID' | 'subcontractNo' | 'description' | 'subcontractDate' | 'taxSchemeID' | 'subcontractSum' | 'revisedSubcontractSum' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'maxRetentionSum' | 'targetProfitPerc' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'latestRunNum' | 'docNumChecking' | 'ladAmt' | 'ladRate' | 'creditTerm' | 'subcontractStatus' | 'contactPerson' | 'multipleTrade' | 'submissionComment' | 'extendedDate' | 'extensionDate' | 'closureRemarks' | 'closureDate' | 'modBy' | 'nominatedSC'>
    & { mainContractDetail?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'budgetChecker'>
    )>, subcontractorDetail?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'associatedStatus' | 'name' | 'ID' | 'address' | 'contactNo' | 'registrationNo' | 'gstRegNo' | 'sstRegNo'>
    )>, site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'contractID' | 'address'>
    )>, taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxRate' | 'taxDate'>
      )>, taxEffective?: Maybe<Array<(
        { __typename?: 'ConTaxEffectiveDateEntity' }
        & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
      )>> }
    )>, contact?: Maybe<Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'email' | 'designation' | 'contactNo'>
    )>>, VO?: Maybe<Array<(
      { __typename?: 'SubcontractVOEntity' }
      & Pick<SubcontractVoEntity, 'submissionAmt' | 'approvedAmt'>
      & { subcontractClaimVOAllocation?: Maybe<Array<(
        { __typename?: 'SubcontractClaimVOAllocationEntity' }
        & Pick<SubcontractClaimVoAllocationEntity, 'ID' | 'subcontractClaimID' | 'subcontractVOID' | 'submittedVOClaimAmt' | 'currentVOClaimAmt'>
      )>> }
    )>>, tradeDetail?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )>, subcontractCostWbs?: Maybe<Array<(
      { __typename?: 'SubcontractCostWbsEntity' }
      & Pick<SubcontractCostWbsEntity, 'ID' | 'subcontractID' | 'wbsID' | 'taxSchemeID' | 'costCategoryID' | 'tradeAmt' | 'tradeVoAmt' | 'revisedTradeAmt' | 'previousAmt'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'parentWBSID' | 'name' | 'parentName'>
      )>, taxDetail?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'code' | 'description'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxRate' | 'taxDate'>
        )> }
      )>, costCategory?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'name' | 'description'>
      )>, subcontractClaimAllocation?: Maybe<Array<(
        { __typename?: 'SubcontractClaimAllocationEntity' }
        & Pick<SubcontractClaimAllocationEntity, 'ID' | 'subcontractClaimID' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'subcontractCostWbsID'>
      )>>, subcontractVOCostWbs?: Maybe<Array<(
        { __typename?: 'SubcontractVOCostWbsEntity' }
        & Pick<SubcontractVoCostWbsEntity, 'ID' | 'approvedAmt' | 'previousTradeVoAmt'>
        & { subcontractClaimVOCostWbsAllocation?: Maybe<Array<(
          { __typename?: 'SubcontractClaimVOCostWbsAllocationEntity' }
          & Pick<SubcontractClaimVoCostWbsAllocationEntity, 'ID' | 'subcontractClaimID' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt'>
        )>>, subcontractVO?: Maybe<(
          { __typename?: 'SubcontractVOEntity' }
          & Pick<SubcontractVoEntity, 'ID' | 'VOStatus' | 'VOType' | 'docNo' | 'docRef' | 'description'>
        )> }
      )>> }
    )>> }
  )> }
);

export type GetSubcontractSubmenuQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractSubmenuQuery = (
  { __typename?: 'Query' }
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'subcontractNo' | 'subcontractorID' | 'startDate' | 'endDate' | 'subcontractDate' | 'subcontractStatus' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'extensionDate' | 'subcontractSum' | 'revisedSubcontractSum' | 'closureDate' | 'closureRemarks'>
    & { subcontractorDetail?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'ID' | 'name'>
    )> }
  )>, getSubcontractRelated: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'subcontractNo' | 'subcontractorID'>
  )> }
);

export type GetContractSubcontractListingQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractSubcontractListingQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'customerID' | 'contractNo' | 'description' | 'contractStatus' | 'contractDate' | 'contractSum' | 'voSum' | 'revisedContractSum' | 'implementWbs' | 'subcontractSummary'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'address' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )>, subcontractDetail?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'contractID' | 'subcontractorID' | 'costCategoryID' | 'subcontractNo' | 'description' | 'subcontractDate' | 'taxSchemeID' | 'subcontractSum' | 'revisedSubcontractSum' | 'startDate' | 'subcontractStatus' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'creditTerm'>
      & { tradeDetail?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name'>
      )>, subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name' | 'associatedStatus'>
      )> }
    )>> }
  )> }
);

export type CreateSubcontractMutationVariables = Exact<{
  input: SubcontractInput;
  wbsInput?: Maybe<Array<SubcontractCostWbsInput> | SubcontractCostWbsInput>;
}>;


export type CreateSubcontractMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontract: (
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title'>
  ) }
);

export type UpdateSubcontractMutationVariables = Exact<{
  input: SubcontractInput;
  wbsInput?: Maybe<Array<SubcontractCostWbsInput> | SubcontractCostWbsInput>;
}>;


export type UpdateSubcontractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSubcontract'>
);

export type SubcontractCheckerQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type SubcontractCheckerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'claimChecking' | 'voChecking'>
);

export type GetSubcontractGuaranteeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
}>;


export type GetSubcontractGuaranteeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconGuaranteeInfo'>
  & { getSubcontractGuarantee: Array<(
    { __typename?: 'SubcontractGuaranteeEntity' }
    & Pick<SubcontractGuaranteeEntity, 'ID' | 'modBy' | 'accountID' | 'subcontractID' | 'docRef' | 'guaranteeTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'issuer' | 'collateralAmt' | 'remarks' | 'cancellationDate' | 'guaranteeStatus'>
    & { guaranteeType?: Maybe<(
      { __typename?: 'GuaranteeTypeEntity' }
      & Pick<GuaranteeTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateSubcontractGuaranteeMutationVariables = Exact<{
  input: SubcontractGuaranteeInput;
}>;


export type CreateSubcontractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractGuarantee: (
    { __typename?: 'SubcontractGuaranteeEntity' }
    & Pick<SubcontractGuaranteeEntity, 'ID'>
  ) }
);

export type UpdateSubcontractGuaranteeMutationVariables = Exact<{
  input: SubcontractGuaranteeInput;
}>;


export type UpdateSubcontractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractGuarantee: (
    { __typename?: 'SubcontractGuaranteeEntity' }
    & Pick<SubcontractGuaranteeEntity, 'ID'>
  ) }
);

export type DeleteSubcontractGuaranteeMutationVariables = Exact<{
  input: SubcontractGuaranteeInsuranceDeleteInput;
}>;


export type DeleteSubcontractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractGuarantee'>
);

export type GetSubcontractInsuranceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
}>;


export type GetSubcontractInsuranceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconInsuranceInfo'>
  & { getSubcontractInsurance: Array<(
    { __typename?: 'SubcontractInsuranceEntity' }
    & Pick<SubcontractInsuranceEntity, 'ID' | 'modBy' | 'accountID' | 'subcontractID' | 'docRef' | 'insuranceTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'issuer' | 'remarks' | 'cancellationDate' | 'insuranceStatus'>
    & { insuranceType?: Maybe<(
      { __typename?: 'InsuranceTypeEntity' }
      & Pick<InsuranceTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateSubcontractInsuranceMutationVariables = Exact<{
  input: SubcontractInsuranceInput;
}>;


export type CreateSubcontractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractInsurance: (
    { __typename?: 'SubcontractInsuranceEntity' }
    & Pick<SubcontractInsuranceEntity, 'ID'>
  ) }
);

export type UpdateSubcontractInsuranceMutationVariables = Exact<{
  input: SubcontractInsuranceInput;
}>;


export type UpdateSubcontractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractInsurance: (
    { __typename?: 'SubcontractInsuranceEntity' }
    & Pick<SubcontractInsuranceEntity, 'ID'>
  ) }
);

export type DeleteSubcontractInsuranceMutationVariables = Exact<{
  input: SubcontractGuaranteeInsuranceDeleteInput;
}>;


export type DeleteSubcontractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractInsurance'>
);

export type CancelSubcontractGuaranteeMutationVariables = Exact<{
  input: SubcontractGuaranteeCancellationInput;
}>;


export type CancelSubcontractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubcontractGuarantee'>
);

export type CancelSubcontractInsuranceMutationVariables = Exact<{
  input: SubcontractInsuranceCancellationInput;
}>;


export type CancelSubcontractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubcontractInsurance'>
);

export type GetSubcontractSummaryQueryVariables = Exact<{
  subcontractID: Scalars['String'];
  type?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconGuaranteeInfo' | 'subconInsuranceInfo' | 'submittedVOSubconNotification' | 'subconLastExtensionDate' | 'subconClosureDate' | 'totalDigitalDocuments'>
  & { subcontractInfo: (
    { __typename?: 'SubontractInfo' }
    & Pick<SubontractInfo, 'subcontractSum' | 'subcontractDate'>
  ), subcontractVO: (
    { __typename?: 'SubcontractVO' }
    & Pick<SubcontractVo, 'voSum' | 'voOmission' | 'voSumCount' | 'voOmissionCount' | 'totalVoAmt'>
  ), subconOutstandingAdvances: Array<(
    { __typename?: 'SubcontractAdvanceEntity' }
    & Pick<SubcontractAdvanceEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, subconOutstandingClaims: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type GetIpcSummaryQueryVariables = Exact<{
  subcontractID: Scalars['String'];
}>;


export type GetIpcSummaryQuery = (
  { __typename?: 'Query' }
  & { retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type ActionOnSubcontractStatusMutationVariables = Exact<{
  input: SubcontractActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnSubcontractStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnSubcontractStatus'>
);

export type CreateSubcontractExtensionDateMutationVariables = Exact<{
  input: SubcontractExtensionInput;
  ID: Scalars['String'];
}>;


export type CreateSubcontractExtensionDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSubcontractExtensionDate'>
);

export type CreateSubcontractCertificateMutationVariables = Exact<{
  input: SubcontractCertificateInput;
  ID: Scalars['String'];
}>;


export type CreateSubcontractCertificateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSubcontractCertificate'>
);

export type GetSubcontractExtensionQueryVariables = Exact<{
  ID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractExtensionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconLastExtensionDate'>
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractNo' | 'description' | 'subcontractDate' | 'subcontractSum' | 'revisedSubcontractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'subcontractStatus' | 'extensionDate'>
    & { mainContractDetail?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )> }
  )> }
);

export type GetSubcontractTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractTitleQuery = (
  { __typename?: 'Query' }
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'contractID' | 'subcontractorID' | 'taxSchemeID' | 'title' | 'cpcDate' | 'dlpDate' | 'cmgdDate' | 'subcontractDate' | 'revisedSubcontractSum' | 'subcontractSum' | 'multipleTrade' | 'subcontractStatus'>
    & { mainContractDetail?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'budgetChecker'>
    )>, subcontractorDetail?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'ID' | 'name'>
    )>, taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxRate' | 'taxDate'>
      )>, taxEffective?: Maybe<Array<(
        { __typename?: 'ConTaxEffectiveDateEntity' }
        & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
      )>> }
    )> }
  )> }
);

export type GetSubcontractRelatedQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractRelatedQuery = (
  { __typename?: 'Query' }
  & { getSubcontractRelated: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractStatus'>
  )> }
);

export type SubconBudgetCheckingQueryVariables = Exact<{
  amount: Scalars['Float'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
}>;


export type SubconBudgetCheckingQuery = (
  { __typename?: 'Query' }
  & { subconBudgetChecking: (
    { __typename?: 'BudgetInfo' }
    & Pick<BudgetInfo, 'isExceeded' | 'isAllocated'>
  ) }
);

export type GetSubcontractorForSubcontractQueryVariables = Exact<{
  associatedStatus?: Maybe<AssociatedStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorForSubcontractQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'associatedStatus' | 'nominatedSC'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )> }
  )> }
);

export type GetSubcontractTradeCostCategoryQueryVariables = Exact<{
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
}>;


export type GetSubcontractTradeCostCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractTradeCostCategory'>
);

export type GetWbsForSubcontractQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
}>;


export type GetWbsForSubcontractQuery = (
  { __typename?: 'Query' }
  & { getWBS: Array<(
    { __typename?: 'WBSEntity' }
    & Pick<WbsEntity, 'ID' | 'name'>
    & { latestWbsBudgetDetail?: Maybe<(
      { __typename?: 'WbsBudgetDetailEntity' }
      & Pick<WbsBudgetDetailEntity, 'budgetID' | 'budgetAmt' | 'wbsID' | 'creditTerm' | 'quantity'>
    )> }
  )> }
);

export type GetSubcontractorLedgersQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorLedgersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractorLedgers'>
);

export type ActionOnSubcontractStatusWithTradeMutationVariables = Exact<{
  input: SubcontractApproveRejectInput;
  ID: Scalars['String'];
}>;


export type ActionOnSubcontractStatusWithTradeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnSubcontractStatusWithTrade'>
);

export type GetContractVoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  VOStatus?: Maybe<VoStatus>;
}>;


export type GetContractVoQuery = (
  { __typename?: 'Query' }
  & { getContractVO: Array<(
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'accountID' | 'contractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'approvedAmt' | 'submittedDate' | 'rejectedDate' | 'docDate' | 'remarks' | 'contractClaimVOAlloc'>
    & { contractVORevenueWbs?: Maybe<Array<(
      { __typename?: 'ContractVORevenueWbsEntity' }
      & Pick<ContractVoRevenueWbsEntity, 'ID' | 'contractVOID' | 'previousRevenueVOAmt' | 'wbsID' | 'revenueCategoryID' | 'submissionAmt' | 'approvedAmt'>
      & { wbsBudgetDetail?: Maybe<(
        { __typename?: 'WbsBudgetDetailEntity' }
        & Pick<WbsBudgetDetailEntity, 'ID' | 'wbsID' | 'budgetAmt' | 'revenueCategoryID'>
        & { revenueCategory?: Maybe<(
          { __typename?: 'RevenueCategoryEntity' }
          & Pick<RevenueCategoryEntity, 'ID' | 'name'>
        )> }
      )>, contractClaimVORevenueWbsAllocation?: Maybe<Array<(
        { __typename?: 'ContractClaimVORevenueWbsAllocationEntity' }
        & Pick<ContractClaimVoRevenueWbsAllocationEntity, 'ID' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt' | 'contractVORevenueWbsID'>
        & { contractVORevenueWbs?: Maybe<(
          { __typename?: 'ContractVORevenueWbsEntity' }
          & Pick<ContractVoRevenueWbsEntity, 'wbsID' | 'revenueCategoryID'>
        )> }
      )>> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, contractClaimVOAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimVOAllocationEntity' }
      & Pick<ContractClaimVoAllocationEntity, 'ID' | 'contractClaimID' | 'contractVOID' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt'>
    )>> }
  )> }
);

export type CreateContractVoMutationVariables = Exact<{
  input: ContractVoInput;
  voDetails?: Maybe<Array<ContractVoDetailsInput> | ContractVoDetailsInput>;
}>;


export type CreateContractVoMutation = (
  { __typename?: 'Mutation' }
  & { createContractVO: (
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID' | 'docNo'>
  ) }
);

export type UpdateContractVoMutationVariables = Exact<{
  input: ContractVoInput;
  voDetails?: Maybe<Array<ContractVoDetailsInput> | ContractVoDetailsInput>;
}>;


export type UpdateContractVoMutation = (
  { __typename?: 'Mutation' }
  & { updateContractVO: (
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID' | 'docRef'>
  ) }
);

export type DeleteContractVoMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractVoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractVO'>
);

export type ActionOnVoStatusMutationVariables = Exact<{
  ID: Scalars['String'];
  input: ContractVoActionInput;
  itemApprovedAmount?: Maybe<Array<VoItemApprovedAmountInput> | VoItemApprovedAmountInput>;
}>;


export type ActionOnVoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnVOStatus'>
);

export type GetContractVoSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetContractVoSummaryQuery = (
  { __typename?: 'Query' }
  & { contractVO: (
    { __typename?: 'ContractVO' }
    & Pick<ContractVo, 'voSum' | 'voOmission' | 'voSumCount' | 'voOmissionCount' | 'totalVoAmt'>
  ) }
);

export type GetVoHealthIndexQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetVoHealthIndexQuery = (
  { __typename?: 'Query' }
  & { getVOHealthIndex: (
    { __typename?: 'VOHealthIndex' }
    & Pick<VoHealthIndex, 'conVOOmission' | 'conVOSum' | 'conVOPendingOmission' | 'conVOPendingSum' | 'subVOOmission' | 'subVOSum' | 'subVOPendingOmission' | 'subVOPendingSum' | 'totalVO' | 'totalPendingVO' | 'totalSubVO' | 'totalPendingSubVO' | 'totalVOOmission' | 'totalVOSum'>
  ) }
);

export type GetClientVoQueryVariables = Exact<{
  contractID: Scalars['String'];
  voStatus: VoStatus;
}>;


export type GetClientVoQuery = (
  { __typename?: 'Query' }
  & { getContractVO: Array<(
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID' | 'approvedAmt' | 'submissionAmt' | 'VOType' | 'docNo' | 'docRef' | 'submittedDate' | 'docDate' | 'description'>
  )> }
);

export type GetClientPendingVoQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetClientPendingVoQuery = (
  { __typename?: 'Query' }
  & { submitVO: Array<(
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID' | 'approvedAmt' | 'submissionAmt' | 'VOType' | 'docNo' | 'docRef' | 'submittedDate' | 'docDate' | 'description'>
  )>, pendingVO: Array<(
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID' | 'approvedAmt' | 'submissionAmt' | 'VOType' | 'docNo' | 'docRef' | 'submittedDate' | 'docDate' | 'description'>
  )> }
);

export type GetWbsQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetWbsQuery = (
  { __typename?: 'Query' }
  & { getWBS: Array<(
    { __typename?: 'WBSEntity' }
    & Pick<WbsEntity, 'ID' | 'parentWBSID' | 'finalSequence' | 'contractID' | 'name' | 'parentName' | 'startDate' | 'endDate' | 'isLastNode' | 'isDefault' | 'sequence' | 'childrenIDs' | 'wbsParentChildSequence' | 'totalCost' | 'totalRevenue' | 'costByClass'>
    & { parentWbs?: Maybe<(
      { __typename?: 'WBSEntity' }
      & Pick<WbsEntity, 'ID' | 'name' | 'startDate' | 'endDate'>
    )>, revenueDetail?: Maybe<Array<(
      { __typename?: 'WbsBudgetDetailEntity' }
      & Pick<WbsBudgetDetailEntity, 'ID' | 'wbsID' | 'budgetAmt' | 'creditTerm' | 'previousAmt'>
      & { revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetMultipleWbsQueryVariables = Exact<{
  contractIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetMultipleWbsQuery = (
  { __typename?: 'Query' }
  & { getMultipleWBS: Array<(
    { __typename?: 'WBSEntity' }
    & Pick<WbsEntity, 'ID' | 'parentWBSID' | 'contractID' | 'name' | 'parentName' | 'startDate' | 'endDate' | 'isLastNode' | 'isDefault' | 'sequence' | 'childrenIDs' | 'wbsParentChildSequence'>
    & { parentWbs?: Maybe<(
      { __typename?: 'WBSEntity' }
      & Pick<WbsEntity, 'ID' | 'name' | 'startDate' | 'endDate'>
    )> }
  )> }
);

export type GetWbsNameQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
}>;


export type GetWbsNameQuery = (
  { __typename?: 'Query' }
  & { getWBS: Array<(
    { __typename?: 'WBSEntity' }
    & Pick<WbsEntity, 'ID' | 'parentWBSID' | 'contractID' | 'name' | 'parentName' | 'startDate' | 'endDate' | 'isLastNode'>
    & { parentWbs?: Maybe<(
      { __typename?: 'WBSEntity' }
      & Pick<WbsEntity, 'ID' | 'name' | 'startDate' | 'endDate'>
    )> }
  )> }
);

export type GetWbsTotalCostRevenueQueryVariables = Exact<{
  contractID: Scalars['String'];
  budgetID?: Maybe<Scalars['String']>;
}>;


export type GetWbsTotalCostRevenueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWbsTotalCostRevenue'>
);

export type CreateWbsMutationVariables = Exact<{
  input: WbsInput;
}>;


export type CreateWbsMutation = (
  { __typename?: 'Mutation' }
  & { createWBS: (
    { __typename?: 'WBSEntity' }
    & Pick<WbsEntity, 'ID' | 'sequence'>
  ) }
);

export type UpdateWbsMutationVariables = Exact<{
  input: WbsInput;
}>;


export type UpdateWbsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWBS'>
);

export type DeleteWbsMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteWbsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWBS'>
);

export type MoveSequenceWbsMutationVariables = Exact<{
  moveDown?: Maybe<Scalars['Boolean']>;
  ID: Scalars['String'];
}>;


export type MoveSequenceWbsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'moveSequenceWBS'>
);

export type WbsBudgetTransferResourceQueryVariables = Exact<{
  wbsID: Scalars['String'];
}>;


export type WbsBudgetTransferResourceQuery = (
  { __typename?: 'Query' }
  & { getWbsBudgetDetail: Array<(
    { __typename?: 'WbsBudgetDetailEntity' }
    & Pick<WbsBudgetDetailEntity, 'costCategoryID'>
    & { costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & { costItem?: Maybe<Array<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name'>
        & { costItemID: CostItemEntity['ID'] }
      )>> }
    )> }
  )> }
);

export type GetStatusProjectWbsBudgetingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetStatusProjectWbsBudgetingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'wbsBudgetInfo'>
);

export type GetWbsParentChildQueryVariables = Exact<{
  contractID: Scalars['String'];
  selectedID?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
}>;


export type GetWbsParentChildQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWBSParentChild'>
);

export type GetWbsScheduleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  wbsBudgetDetailID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetWbsScheduleQuery = (
  { __typename?: 'Query' }
  & { getWbsSchedule: Array<(
    { __typename?: 'WbsScheduleEntity' }
    & Pick<WbsScheduleEntity, 'ID' | 'period' | 'startDate' | 'endDate' | 'code' | 'budgetDetailScheduleAmt'>
    & { wbsBudgetDetailSchedule?: Maybe<Array<(
      { __typename?: 'WbsBudgetDetailScheduleEntity' }
      & Pick<WbsBudgetDetailScheduleEntity, 'ID' | 'wbsBudgetDetailID' | 'wbsScheduleID' | 'scheduleAmt'>
    )>> }
  )> }
);

export type ActionOnBudgetStatusMutationVariables = Exact<{
  input: WbsBudgetActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnBudgetStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnBudgetStatus'>
);

export type GetWbsBudgetRevisionQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
}>;


export type GetWbsBudgetRevisionQuery = (
  { __typename?: 'Query' }
  & { getWBS: Array<(
    { __typename?: 'WBSEntity' }
    & Pick<WbsEntity, 'ID' | 'parentWBSID' | 'name' | 'parentName' | 'sequence' | 'startDate' | 'endDate' | 'isLastNode' | 'isDefault' | 'originalCost' | 'totalCost' | 'originalRevenue' | 'totalRevenue' | 'costByClass' | 'originalCostByClass'>
    & { parentWbs?: Maybe<(
      { __typename?: 'WBSEntity' }
      & Pick<WbsEntity, 'ID' | 'name' | 'startDate' | 'endDate'>
    )>, originalRevenueDetail?: Maybe<Array<(
      { __typename?: 'WbsBudgetDetailEntity' }
      & Pick<WbsBudgetDetailEntity, 'ID' | 'budgetAmt' | 'creditTerm'>
      & { revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )> }
    )>>, revenueDetail?: Maybe<Array<(
      { __typename?: 'WbsBudgetDetailEntity' }
      & Pick<WbsBudgetDetailEntity, 'ID' | 'budgetAmt' | 'creditTerm'>
      & { revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )> }
    )>> }
  )> }
);

export type DefaultWbsMutationVariables = Exact<{
  ID: Scalars['String'];
  isDefault: Scalars['Boolean'];
  contractID: Scalars['String'];
}>;


export type DefaultWbsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'defaultWBS'>
);

export type CheckWbsBudgetApprovedQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type CheckWbsBudgetApprovedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkWBSBudgetApproved'>
);

export type GetWbsBudgetQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetWbsBudgetQuery = (
  { __typename?: 'Query' }
  & { getWbsBudget: Array<(
    { __typename?: 'WbsBudgetEntity' }
    & Pick<WbsBudgetEntity, 'ID' | 'contractID' | 'name' | 'description' | 'reason' | 'version' | 'budgetStatus'>
  )> }
);

export type GetWbsBudgetDetailByClassQueryVariables = Exact<{
  costClass: Scalars['String'];
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
  originalBudget?: Maybe<Scalars['Boolean']>;
}>;


export type GetWbsBudgetDetailByClassQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWbsBudgetDetailByClass'>
);

export type CheckWbsBudgetDetailQueryVariables = Exact<{
  costID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type CheckWbsBudgetDetailQuery = (
  { __typename?: 'Query' }
  & { checkWbsBudgetDetail: (
    { __typename?: 'WbsBudgetDetailEntity' }
    & Pick<WbsBudgetDetailEntity, 'ID' | 'budgetID' | 'uomID' | 'costItemID' | 'creditTerm' | 'quantity' | 'unitPrice' | 'budgetAmt' | 'remark' | 'utilisedQty' | 'utilisedAmt' | 'reservedQty' | 'reservedAmt' | 'balanceAmt' | 'balanceQty'>
  ) }
);

export type CheckMultipleWbsBudgetDetailQueryVariables = Exact<{
  contractID: Scalars['String'];
  itemChecker: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
  input: Array<CheckMultipleWbsBudgetDetailInput> | CheckMultipleWbsBudgetDetailInput;
}>;


export type CheckMultipleWbsBudgetDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkMultipleWbsBudgetDetail'>
);

export type CheckSubcontractMultipleWbsBudgetDetailQueryVariables = Exact<{
  contractID: Scalars['String'];
  subcontractID?: Maybe<Scalars['String']>;
  input: Array<CheckMultipleWbsBudgetDetailInput> | CheckMultipleWbsBudgetDetailInput;
}>;


export type CheckSubcontractMultipleWbsBudgetDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkSubcontractMultipleWbsBudgetDetail'>
);

export type GetWbsBudgetTransferHeaderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetWbsBudgetTransferHeaderQuery = (
  { __typename?: 'Query' }
  & { getWBSBudgetTransferHeader: Array<(
    { __typename?: 'WBSBudgetTransferHeaderEntity' }
    & Pick<WbsBudgetTransferHeaderEntity, 'ID' | 'contractID' | 'fromWbsID' | 'toWbsID' | 'transferDate' | 'rejectionDate' | 'rejectionRemarks' | 'budgetStatus'>
    & { fromWbs: (
      { __typename?: 'WBSEntity' }
      & Pick<WbsEntity, 'ID' | 'contractID' | 'name'>
    ), toWbs: (
      { __typename?: 'WBSEntity' }
      & Pick<WbsEntity, 'ID' | 'contractID' | 'name'>
    ) }
  )> }
);

export type CreateWbsBudgetTransferMutationVariables = Exact<{
  input: WbsBudgetTransferInput;
  itemInput?: Maybe<Array<WbsBudgetTransferItemInput> | WbsBudgetTransferItemInput>;
}>;


export type CreateWbsBudgetTransferMutation = (
  { __typename?: 'Mutation' }
  & { createWbsBudgetTransfer: (
    { __typename?: 'WBSBudgetTransferHeaderEntity' }
    & Pick<WbsBudgetTransferHeaderEntity, 'ID' | 'fromWbsID' | 'toWbsID'>
  ) }
);

export type UpdateWbsBudgetTransferMutationVariables = Exact<{
  input: WbsBudgetTransferInput;
  itemInput?: Maybe<Array<WbsBudgetTransferItemInput> | WbsBudgetTransferItemInput>;
}>;


export type UpdateWbsBudgetTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWbsBudgetTransfer'>
);

export type GetWbsBudgetTransferDetailQueryVariables = Exact<{
  wbsBudgetTransferHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetWbsBudgetTransferDetailQuery = (
  { __typename?: 'Query' }
  & { getWBSBudgetTransferDetail: Array<(
    { __typename?: 'WBSBudgetTransferDetailEntity' }
    & Pick<WbsBudgetTransferDetailEntity, 'wbsBudgetTransferHeaderID' | 'fromCostCategoryID' | 'fromCostItemID' | 'toCostCategoryID' | 'toCostItemID' | 'fromQty' | 'toQty' | 'transferQty' | 'transferAmt' | 'remarks'>
    & { fromCostCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'name'>
    )>, fromCostItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'name'>
    )>, toCostCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'name'>
    )>, toCostItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'name'>
    )> }
  )> }
);

export type DeleteBudgetTransferMutationVariables = Exact<{
  deleteBudgetTransferID: Scalars['String'];
}>;


export type DeleteBudgetTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBudgetTransfer'>
);

export type GetWbsBudgetTransferQueryVariables = Exact<{
  contractID: Scalars['String'];
  wbsBudgetTransferID?: Maybe<Scalars['String']>;
}>;


export type GetWbsBudgetTransferQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWbsBudgetTransfer'>
);

export type ActionOnBudgetTransferStatusMutationVariables = Exact<{
  ID: Scalars['String'];
  input: WbsBudgetTransferActionInput;
}>;


export type ActionOnBudgetTransferStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnBudgetTransferStatus'>
);

export type GetCostCategoryWbsBudgetDetailQueryVariables = Exact<{
  costID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetCostCategoryWbsBudgetDetailQuery = (
  { __typename?: 'Query' }
  & { checkWbsBudgetDetail: (
    { __typename?: 'WbsBudgetDetailEntity' }
    & Pick<WbsBudgetDetailEntity, 'ID' | 'budgetID' | 'uomID' | 'costItemID' | 'costCategoryID' | 'creditTerm' | 'quantity' | 'unitPrice' | 'budgetAmt' | 'remark' | 'originalQty' | 'originalAmt' | 'utilisedQty' | 'utilisedAmt' | 'reservedQty' | 'reservedAmt' | 'balanceAmt' | 'balanceQty'>
  ) }
);

export type GetBudgetTransferCostCategoryBalanceQueryVariables = Exact<{
  contractID: Scalars['String'];
  itemInput: Array<WbsBudgetTransferCostCategoryBalanceFilterArgs> | WbsBudgetTransferCostCategoryBalanceFilterArgs;
}>;


export type GetBudgetTransferCostCategoryBalanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBudgetTransferCostCategoryBalance'>
);

export type GetWbsCostAllocationByClassQueryVariables = Exact<{
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
}>;


export type GetWbsCostAllocationByClassQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWbsCostAllocationByClass'>
);

export type GetWbsForCostQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetWbsForCostQuery = (
  { __typename?: 'Query' }
  & { getWBS: Array<(
    { __typename?: 'WBSEntity' }
    & Pick<WbsEntity, 'ID' | 'name' | 'parentWBSID' | 'parentName' | 'startDate' | 'endDate' | 'isLastNode' | 'sequence' | 'originalCost' | 'totalCost' | 'originalRevenue' | 'totalRevenue'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )> }
  )> }
);

export type CheckExistCostRevenueQueryVariables = Exact<{
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
}>;


export type CheckExistCostRevenueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkExistCostRevenue'>
);

export type GetTotalCostRevenueQueryVariables = Exact<{
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
}>;


export type GetTotalCostRevenueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTotalCostRevenue'>
);

export type GetParentChildrenCostCategoryQueryVariables = Exact<{
  commonStatus?: Maybe<CommonStatus>;
  costClass?: Maybe<CostClass>;
}>;


export type GetParentChildrenCostCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getParentChildrenCostCategory'>
);

export type GetCostCategoryTreeQueryVariables = Exact<{
  commonStatus?: Maybe<CommonStatus>;
  costClass?: Maybe<CostClass>;
}>;


export type GetCostCategoryTreeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostCategoryTree'>
);

export type GetMapCostCategoryQueryVariables = Exact<{
  costClass?: Maybe<CostClass>;
  materialOnly?: Maybe<Scalars['Boolean']>;
}>;


export type GetMapCostCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMapCostCategory'>
);

export type GetMapCostItemQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMapCostItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMapCostItem'>
);

export type GetCostWbsBudgetDetailQueryVariables = Exact<{
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
}>;


export type GetCostWbsBudgetDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostWBSBudgetDetail'>
);

export type CreateBudgetDetailMutationVariables = Exact<{
  input: WbsBudgetDetailInput;
}>;


export type CreateBudgetDetailMutation = (
  { __typename?: 'Mutation' }
  & { createBudgetDetail: (
    { __typename?: 'WbsBudgetDetailEntity' }
    & Pick<WbsBudgetDetailEntity, 'wbsID'>
  ) }
);

export type UpdateBudgetDetailMutationVariables = Exact<{
  input: WbsBudgetDetailInput;
}>;


export type UpdateBudgetDetailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBudgetDetail'>
);

export type DeleteBudgetDetailMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteBudgetDetailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBudgetDetail'>
);

export type GetCostItemForWbsQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetCostItemForWbsQuery = (
  { __typename?: 'Query' }
  & { getCostItem: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'name' | 'code'>
  )> }
);

export type GetRevenueWbsBudgetDetailQueryVariables = Exact<{
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
}>;


export type GetRevenueWbsBudgetDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRevenueWBSBudgetDetail'>
);

export type GetWbsBudgetDetailSchedulingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetWbsBudgetDetailSchedulingQuery = (
  { __typename?: 'Query' }
  & { getWbsBudgetDetailScheduling: Array<(
    { __typename?: 'WbsBudgetDetailScheduleEntity' }
    & Pick<WbsBudgetDetailScheduleEntity, 'ID' | 'scheduleAmt' | 'wbsBudgetDetailID' | 'wbsScheduleID'>
    & { wbsBudgetDetail?: Maybe<(
      { __typename?: 'WbsBudgetDetailEntity' }
      & Pick<WbsBudgetDetailEntity, 'ID' | 'budgetID' | 'revenueCategoryID'>
    )>, wbsSchedule?: Maybe<(
      { __typename?: 'WbsScheduleEntity' }
      & Pick<WbsScheduleEntity, 'ID' | 'startDate' | 'endDate'>
    )> }
  )> }
);

export type UpdateBudgetScheduleMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
  costAmts: Array<Scalars['Float']> | Scalars['Float'];
  wbsBudgetDetailID: Scalars['String'];
}>;


export type UpdateBudgetScheduleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBudgetSchedule'>
);

export type GetRevenueBudgetQueryVariables = Exact<{
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetRevenueBudgetQuery = (
  { __typename?: 'Query' }
  & { getRevenueBudget: Array<(
    { __typename?: 'WbsBudgetDetailEntity' }
    & Pick<WbsBudgetDetailEntity, 'ID' | 'wbsID' | 'revenueCategoryID'>
    & { revenueCategory?: Maybe<(
      { __typename?: 'RevenueCategoryEntity' }
      & Pick<RevenueCategoryEntity, 'ID' | 'name' | 'description'>
    )> }
  )> }
);

export type GetCostCategoryWbsBudgetQueryVariables = Exact<{
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
  isCategory?: Maybe<Scalars['Boolean']>;
}>;


export type GetCostCategoryWbsBudgetQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostCategoryWbsBudget'>
);

export type GetCostItemWbsBudgetQueryVariables = Exact<{
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
  isCategory?: Maybe<Scalars['Boolean']>;
}>;


export type GetCostItemWbsBudgetQuery = (
  { __typename?: 'Query' }
  & { getCostItemWbsBudget: Query['getCostCategoryWbsBudget'] }
);

export type GetWbsDayworkCostRevenueQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetWbsDayworkCostRevenueQuery = (
  { __typename?: 'Query' }
  & { getWbsDayworkCostRevenue: Array<(
    { __typename?: 'WbsDayworkCostRevenueEntity' }
    & Pick<WbsDayworkCostRevenueEntity, 'ID' | 'wbsID' | 'contractID' | 'revenueCategoryID' | 'costCategoryID' | 'costItemID'>
  )> }
);

export type UpdateWbsDayworkCostRevenueMutationVariables = Exact<{
  input: WbsDayworkInput;
}>;


export type UpdateWbsDayworkCostRevenueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWbsDayworkCostRevenue'>
);

export type GetCheckingValidationWbsBudgetQueryVariables = Exact<{
  WBSAndBudgetExcelInput: Array<WbsAndBudgetExcelInput> | WbsAndBudgetExcelInput;
  contractID: Scalars['String'];
}>;


export type GetCheckingValidationWbsBudgetQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationWBSBudget'>
);

export type CreateWbsBudgetExcelMutationVariables = Exact<{
  WBSAndBudgetExcelInput: Array<WbsAndBudgetExcelInput> | WbsAndBudgetExcelInput;
  contractID: Scalars['String'];
}>;


export type CreateWbsBudgetExcelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createWBSBudgetExcel'>
);

export type WbsImportMutationVariables = Exact<{
  batchID: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type WbsImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'WBSImport'>
);

export type WbsImportValidationMutationVariables = Exact<{
  WBSImportInput: Array<WbsImportInput> | WbsImportInput;
  contractID: Scalars['String'];
}>;


export type WbsImportValidationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'WBSImportValidation'>
);

export type GetCostCategoryFromWbsQueryVariables = Exact<{
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
  selectedID?: Maybe<Scalars['String']>;
}>;


export type GetCostCategoryFromWbsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostCategoryFromWbs'>
);

export type GetWbsBudgetDetailQueryVariables = Exact<{
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  trackQuantity: Scalars['Boolean'];
}>;


export type GetWbsBudgetDetailQuery = (
  { __typename?: 'Query' }
  & { getWbsBudgetDetail: Array<(
    { __typename?: 'WbsBudgetDetailEntity' }
    & Pick<WbsBudgetDetailEntity, 'ID' | 'quantity' | 'budgetAmt' | 'uomID'>
    & { uom?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'code' | 'name'>
    )> }
  )> }
);

export type GetUoMforUtilizedBudgetQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetUoMforUtilizedBudgetQuery = (
  { __typename?: 'Query' }
  & { getUOM: Array<(
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'code' | 'name'>
  )> }
);

export type GetWbsUtilizedBudgetQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetWbsUtilizedBudgetQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWBSUtilizedBudget'>
);

export type CreateWbsUtilizedBudgetMutationVariables = Exact<{
  input: WbsUtilizedBudgetInput;
}>;


export type CreateWbsUtilizedBudgetMutation = (
  { __typename?: 'Mutation' }
  & { createWBSUtilizedBudget: (
    { __typename?: 'WbsUtilizedBudgetEntity' }
    & Pick<WbsUtilizedBudgetEntity, 'year' | 'ID' | 'utilizedQty' | 'utilizedAmt'>
  ) }
);

export type UpdateWbsUtilizedBudgetMutationVariables = Exact<{
  input: WbsUtilizedBudgetInput;
}>;


export type UpdateWbsUtilizedBudgetMutation = (
  { __typename?: 'Mutation' }
  & { updateWBSUtilizedBudget: (
    { __typename?: 'WbsUtilizedBudgetEntity' }
    & Pick<WbsUtilizedBudgetEntity, 'year' | 'ID' | 'utilizedQty' | 'utilizedAmt'>
  ) }
);

export type DeleteWbsUtilizedBudgetMutationVariables = Exact<{
  input: WbsUtilizedBudgetDeleteInput;
}>;


export type DeleteWbsUtilizedBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWBSUtilizedBudget'>
);

export type ActionOnUtilizedBudgetStatusMutationVariables = Exact<{
  ID: Scalars['String'];
  input: WbsUtilizedBudgetActionInput;
}>;


export type ActionOnUtilizedBudgetStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnUtilizedBudgetStatus'>
);

export type DocumentListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  notDescription?: Maybe<Scalars['String']>;
  hasDescription?: Maybe<Scalars['Boolean']>;
}>;


export type DocumentListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'ID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description' | 'refID'>
  )>> }
);

export type DeleteExistPdfMutationVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type DeleteExistPdfMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteExistPDF'>
);

export type UploadPdfDocumentMutationVariables = Exact<{
  input: LedgerPdfInput;
}>;


export type UploadPdfDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadPDFDocument: Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'ID' | 'refID' | 'fileName' | 'fileURL' | 'mediaType' | 'description'>
  )> }
);

export type UploadPdfpoMutationVariables = Exact<{
  input: LedgerPdfInput;
}>;


export type UploadPdfpoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadPDFPO'>
);

export type TestSampleEnumQueryVariables = Exact<{
  SampleEnum: SampleEnum;
}>;


export type TestSampleEnumQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'testSampleEnum'>
);

export type UploadCompanyLogoMutationVariables = Exact<{
  file?: Maybe<Scalars['Upload']>;
}>;


export type UploadCompanyLogoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadCompanyLogo'>
);

export type LogoViewQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type LogoViewQuery = (
  { __typename?: 'Query' }
  & { logoView?: Maybe<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'fileName' | 'fileURL' | 'mediaType'>
  )> }
);

export type GetFuelConsumptionSummaryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetFuelConsumptionSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'fuelInfo' | 'getRolePermission'>
);

export type GetFuelDischargeQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetFuelDischargeQuery = (
  { __typename?: 'Query' }
  & { getFuelDischarge: Array<(
    { __typename?: 'FuelDischargeEntity' }
    & Pick<FuelDischargeEntity, 'ID' | 'tankID' | 'contractID' | 'dischargeDate' | 'dischargeToTable' | 'dischargeToID' | 'currentDischarge' | 'totalDischarge' | 'operatorName' | 'remarks' | 'signature' | 'rejectionDate' | 'rejectionRemarks' | 'approvalStatus' | 'isMalfunction'>
    & { tank?: Maybe<(
      { __typename?: 'TankObjectType' }
      & Pick<TankObjectType, 'ID' | 'name' | 'regNo' | 'description' | 'image' | 'imageUrl' | 'imageBucketFile'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )> }
  )> }
);

export type GetSupplyDischargeToQueryVariables = Exact<{
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetSupplyDischargeToQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplyDischargeTo'>
);

export type CreateFuelDischargeMutationVariables = Exact<{
  input: FuelDischargeInput;
}>;


export type CreateFuelDischargeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createFuelDischarge'>
);

export type UpdateFuelDischargeMutationVariables = Exact<{
  input: FuelDischargeInput;
}>;


export type UpdateFuelDischargeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFuelDischarge'>
);

export type UpdateStatusFuelDischargeMutationVariables = Exact<{
  input: FuelDischargeUpdateStatus;
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateStatusFuelDischargeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStatusFuelDischarge'>
);

export type DeleteFuelDischargeMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteFuelDischargeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFuelDischarge'>
);

export type GetFuelBalanceQueryVariables = Exact<{
  tankID: Scalars['String'];
}>;


export type GetFuelBalanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getFuelBalance'>
);

export type GetFuelDischargeListingQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetFuelDischargeListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getFuelDischargeCount'>
  & { getFuelDischarge: Array<(
    { __typename?: 'FuelDischargeEntity' }
    & Pick<FuelDischargeEntity, 'ID' | 'tankID' | 'contractID' | 'dischargeDate' | 'dischargeToTable' | 'dischargeToID' | 'currentDischarge' | 'totalDischarge' | 'operatorName' | 'remarks' | 'signature' | 'rejectionDate' | 'rejectionRemarks' | 'approvalStatus' | 'isMalfunction'>
    & { tank?: Maybe<(
      { __typename?: 'TankObjectType' }
      & Pick<TankObjectType, 'ID' | 'name' | 'regNo' | 'description' | 'image' | 'imageUrl' | 'imageBucketFile'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )> }
  )> }
);

export type GetFuelDischargeCountQueryVariables = Exact<{
  tankID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
}>;


export type GetFuelDischargeCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getFuelDischargeCount'>
);

export type GetTankQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  hasMeterReading?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetTankQuery = (
  { __typename?: 'Query' }
  & { getTank: Array<(
    { __typename?: 'PlantMachineryEntity' }
    & Pick<PlantMachineryEntity, 'ID' | 'supplierID' | 'companyID' | 'regNo' | 'name' | 'description' | 'remarks' | 'purchaseDate' | 'quantity' | 'availableQty' | 'serialNo' | 'warrantyDate' | 'roadTaxDate' | 'insuranceDate' | 'cancellationDate' | 'cancellationRemarks' | 'image' | 'imageUrl' | 'plantMachineryStatus' | 'fuelTotalSupply' | 'fuelTotalDischarge' | 'tankTable' | 'costCategoryID' | 'latestActiveAssignment' | 'latestActiveMaintenance' | 'currentPnmStatus' | 'tankSummary'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name' | 'description' | 'email'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name' | 'description'>
    )>, assignment?: Maybe<Array<(
      { __typename?: 'AssignmentEntity' }
      & Pick<AssignmentEntity, 'contractID'>
      & { contract?: Maybe<(
        { __typename?: 'ContractEntity' }
        & Pick<ContractEntity, 'ID' | 'title'>
      )> }
    )>> }
  )> }
);

export type GetFuelSupplyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetFuelSupplyQuery = (
  { __typename?: 'Query' }
  & { getFuelSupply: Array<(
    { __typename?: 'FuelSupplyEntity' }
    & Pick<FuelSupplyEntity, 'ID' | 'tankID' | 'contractID' | 'createdBy' | 'createdTs' | 'docDate' | 'doNo' | 'currentSupply' | 'totalSupply' | 'supplyByTable' | 'supplyRefID' | 'remarks' | 'signature' | 'rejectionDate' | 'rejectionRemarks' | 'approvalStatus'>
    & { tank?: Maybe<(
      { __typename?: 'TankObjectType' }
      & Pick<TankObjectType, 'ID' | 'name' | 'regNo' | 'description' | 'image' | 'imageUrl' | 'imageBucketFile'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )> }
  )> }
);

export type CreateFuelSupplyMutationVariables = Exact<{
  input: FuelSupplyInput;
}>;


export type CreateFuelSupplyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createFuelSupply'>
);

export type UpdateFuelSupplyMutationVariables = Exact<{
  input: FuelSupplyInput;
}>;


export type UpdateFuelSupplyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFuelSupply'>
);

export type DeleteFuelSupplyMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteFuelSupplyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFuelSupply'>
);

export type GetSupplyByQueryVariables = Exact<{
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSupplyByQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplyBy'>
);

export type ActionOnFuelSupplyStatusMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
  input: FuelSupplyActionInput;
}>;


export type ActionOnFuelSupplyStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnFuelSupplyStatus'>
);

export type GetAllApprovalLogQueryVariables = Exact<{
  refIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetAllApprovalLogQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllApprovalLog'>
);

export type GetFuelSupplyListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetFuelSupplyListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getFuelSupplyCount'>
  & { getFuelSupply: Array<(
    { __typename?: 'FuelSupplyEntity' }
    & Pick<FuelSupplyEntity, 'ID' | 'tankID' | 'contractID' | 'docDate' | 'doNo' | 'currentSupply' | 'totalSupply' | 'supplyRefID' | 'supplyByTable' | 'remarks' | 'signature' | 'rejectionDate' | 'rejectionRemarks' | 'approvalStatus'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, tank?: Maybe<(
      { __typename?: 'TankObjectType' }
      & Pick<TankObjectType, 'ID' | 'name'>
    )> }
  )> }
);

export type GetFuelMeterReadingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetFuelMeterReadingQuery = (
  { __typename?: 'Query' }
  & { getFuelMeterReading: Array<(
    { __typename?: 'FuelMeterReadingEntity' }
    & Pick<FuelMeterReadingEntity, 'ID' | 'tankID' | 'remarks' | 'docDate' | 'lastSupply' | 'currentSupply' | 'totalSupply' | 'lastDischarge' | 'currentDischarge' | 'totalDischarge'>
    & { tank?: Maybe<(
      { __typename?: 'TankObjectType' }
      & Pick<TankObjectType, 'ID' | 'name' | 'regNo' | 'description' | 'image' | 'imageUrl' | 'imageBucketFile'>
    )> }
  )> }
);

export type FuelMeterReadingSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
}>;


export type FuelMeterReadingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'fuelMeterReadingSummary'>
);

export type GetContractFuelQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetContractFuelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractFuel'>
);

export type CreateFuelMeterReadingMutationVariables = Exact<{
  input: FuelMeterReadingInput;
}>;


export type CreateFuelMeterReadingMutation = (
  { __typename?: 'Mutation' }
  & { createFuelMeterReading: (
    { __typename?: 'FuelMeterReadingEntity' }
    & Pick<FuelMeterReadingEntity, 'ID' | 'tankID' | 'docDate' | 'lastSupply' | 'currentSupply' | 'totalSupply' | 'lastDischarge' | 'currentDischarge' | 'totalDischarge'>
  ) }
);

export type UpdateFuelMeterReadingMutationVariables = Exact<{
  input: FuelMeterReadingInput;
}>;


export type UpdateFuelMeterReadingMutation = (
  { __typename?: 'Mutation' }
  & { updateFuelMeterReading: (
    { __typename?: 'FuelMeterReadingEntity' }
    & Pick<FuelMeterReadingEntity, 'ID' | 'tankID' | 'docDate' | 'lastSupply' | 'currentSupply' | 'totalSupply' | 'lastDischarge' | 'currentDischarge' | 'totalDischarge'>
  ) }
);

export type DeleteFuelMeterReadingMutationVariables = Exact<{
  input: FuelMeterReadingDeleteInput;
}>;


export type DeleteFuelMeterReadingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFuelMeterReading'>
);

export type GetFuelMeterReadingCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
}>;


export type GetFuelMeterReadingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getFuelMeterReadingCount'>
);

export type GetFuelMeterReadingListingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetFuelMeterReadingListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getFuelMeterReadingCount'>
  & { getFuelMeterReading: Array<(
    { __typename?: 'FuelMeterReadingEntity' }
    & Pick<FuelMeterReadingEntity, 'ID' | 'tankID' | 'remarks' | 'docDate' | 'lastSupply' | 'currentSupply' | 'totalSupply' | 'lastDischarge' | 'currentDischarge' | 'totalDischarge'>
    & { tank?: Maybe<(
      { __typename?: 'TankObjectType' }
      & Pick<TankObjectType, 'ID' | 'name' | 'regNo' | 'description' | 'image' | 'imageUrl' | 'imageBucketFile'>
    )> }
  )> }
);

export type GetFuelTankSetupQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  tankType?: Maybe<TankType>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetFuelTankSetupQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getFuelTankSetupCount'>
  & { getFuelTankSetup: Array<(
    { __typename?: 'TankEntity' }
    & Pick<TankEntity, 'ID' | 'name' | 'regNo' | 'companyID' | 'image' | 'imageUrl' | 'imageBucketFile' | 'tankType' | 'plantMachineryID' | 'remark' | 'fuelConsumptionLowerLimit' | 'fuelConsumptionUpperLimit' | 'fuelConsumptionMethod' | 'serialNo' | 'purchaseDate' | 'warrantyDate'>
  )> }
);

export type CreateFuelTankSetupMutationVariables = Exact<{
  input: FuelTankSetupInput;
}>;


export type CreateFuelTankSetupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createFuelTankSetup'>
);

export type UpdateFuelTankSetupMutationVariables = Exact<{
  input: FuelTankSetupInput;
}>;


export type UpdateFuelTankSetupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFuelTankSetup'>
);

export type DeleteFuelTankSetupMutationVariables = Exact<{
  input: FuelTankSetupDeleteInput;
}>;


export type DeleteFuelTankSetupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFuelTankSetup'>
);

export type GetCbExportQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCbExportQuery = (
  { __typename?: 'Query' }
  & { getCBExport: Array<(
    { __typename?: 'CBExportEntity' }
    & Pick<CbExportEntity, 'ID' | 'startDate' | 'endDate' | 'modTs' | 'transactionDate'>
    & { cbExportDetail?: Maybe<Array<(
      { __typename?: 'CBExportDetailEntity' }
      & Pick<CbExportDetailEntity, 'bankAccountID' | 'branch' | 'accountNo' | 'docRefID' | 'docRefTable' | 'description' | 'docDate' | 'docNo' | 'docRef' | 'docAmt'>
    )>> }
  )> }
);

export type ExportCbLedgerMutationVariables = Exact<{
  ledgers: Array<CbLedgerInput> | CbLedgerInput;
  input: CbExportInput;
}>;


export type ExportCbLedgerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportCBLedger'>
);

export type GetContractCbQueryVariables = Exact<{
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetContractCbQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractCB'>
);

export type GetBatchGlDetailLedgerQueryVariables = Exact<{
  batchInput: Array<GlDetailLedgerBatchInput> | GlDetailLedgerBatchInput;
}>;


export type GetBatchGlDetailLedgerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBatchGLDetailLedger'>
);

export type GetContractCbInfoQueryVariables = Exact<{
  cbExportID: Scalars['String'];
}>;


export type GetContractCbInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractCBInfo'>
);

export type GetFinancialIntegrationSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFinancialIntegrationSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'glExportDate' | 'cbExportDate'>
);

export type GetAllGlInterfaceQueryVariables = Exact<{
  glExportID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type GetAllGlInterfaceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractGL' | 'getSubconGL' | 'getSupplierGL' | 'getPurchaseGL' | 'getPOBGL'>
);

export type GetGlClaimDetailLedgerQueryVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
  docRefTable: Scalars['String'];
  claimColumn?: Maybe<Scalars['String']>;
  joinTable?: Maybe<Scalars['String']>;
}>;


export type GetGlClaimDetailLedgerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLClaimDetailLedger'>
);

export type GetGlDetailLedgerQueryVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
  docRefTable: Scalars['String'];
  joinTable?: Maybe<Scalars['String']>;
}>;


export type GetGlDetailLedgerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLDetailLedger'>
);

export type GetGrtnDetailLedgerQueryVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetGrtnDetailLedgerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGRTNDetailLedger'>
);

export type GetDoDetailLedgerQueryVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetDoDetailLedgerQuery = (
  { __typename?: 'Query' }
  & { getDODetailLedger: Array<(
    { __typename?: 'DOEntity' }
    & Pick<DoEntity, 'ID' | 'docDate' | 'docNo' | 'doNo' | 'DODetails'>
  )> }
);

export type GetGlExportQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetGlExportQuery = (
  { __typename?: 'Query' }
  & { getGLExport: Array<(
    { __typename?: 'GLExportEntity' }
    & Pick<GlExportEntity, 'ID' | 'startDate' | 'endDate' | 'transactionDate'>
    & { glExportDetail?: Maybe<Array<(
      { __typename?: 'GLExportDetailEntity' }
      & Pick<GlExportDetailEntity, 'ID' | 'glExportID' | 'accountCode' | 'type' | 'docRefTable' | 'amount' | 'negative' | 'associateRefTable'>
    )>>, glExportDetailSource?: Maybe<Array<(
      { __typename?: 'GLExportDetailSourceEntity' }
      & Pick<GlExportDetailSourceEntity, 'glExportID' | 'contractID' | 'docRefID' | 'docRefTable'>
    )>> }
  )> }
);

export type ExportGlLedgerMutationVariables = Exact<{
  ledgers: Array<GlLedgerInput> | GlLedgerInput;
  input: GlExportInput;
}>;


export type ExportGlLedgerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportGLLedger'>
);

export type UpdateGlExportMutationVariables = Exact<{
  input: UpdateGlExportInput;
}>;


export type UpdateGlExportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGLExport'>
);

export type GetTaxCodeGlExportQueryVariables = Exact<{
  glExportID?: Maybe<Scalars['String']>;
}>;


export type GetTaxCodeGlExportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTaxCodeGLExport'>
);

export type GetGroupBiAccountReceivableSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupBiAccountReceivableSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiAccountReceivableSummary'>
);

export type GetGroupBiAccountReceivableDialogQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetGroupBiAccountReceivableDialogQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiAccountReceivableDialog'>
);

export type GetGroupBiCashFlowSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupBiCashFlowSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiCashFlowSummary'>
);

export type GetGroupBiCashFlowDialogQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupBiCashFlowDialogQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiCashFlowDialog'>
);

export type GetGroupBiKeyApiSummaryQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetGroupBiKeyApiSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiKeyApiSummary'>
);

export type GetGroupBiKeyApiSubmenuQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetGroupBiKeyApiSubmenuQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiKeyApiDialogSummary'>
);

export type GetGroupBiKeyApiDialogSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupBiKeyApiDialogSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiKeyApiDialogSummary'>
);

export type GetGroupBiKeyKpiClaimQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetGroupBiKeyKpiClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiKeyKpiClaim'>
);

export type GetGroupBiKeyKpiCollectionQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetGroupBiKeyKpiCollectionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiKeyKpiCollection'>
);

export type GetBiSiteQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBiSiteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiSite'>
);

export type GetGroupBiInfoWindowSummaryQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetGroupBiInfoWindowSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiProjectCostingSummary' | 'getGroupBIProfitLostSummary' | 'getGroupBiCashFlowDialog' | 'getGroupBiAccountReceivableDialog' | 'getGroupBizVO'>
);

export type GetGroupBiProfitLostSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupBiProfitLostSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBIProfitLostSummary'>
);

export type GetGroupBiProjectCostingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupBiProjectCostingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBiProjectCostingSummary'>
);

export type GetSiteTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSiteTitleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalSiteCount'>
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'accountID' | 'contractID' | 'name'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )> }
  )> }
);

export type GetSiteImagesCountQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteImagesCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'siteImagesCount'>
);

export type GetSiteImageQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteImageQuery = (
  { __typename?: 'Query' }
  & { getSiteImage: Array<(
    { __typename?: 'SiteImageEntity' }
    & Pick<SiteImageEntity, 'ID' | 'siteID' | 'title' | 'remark' | 'createdTs'>
  )> }
);

export type SiteImageListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
}>;


export type SiteImageListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'fileURL' | 'refID' | 'mediaType'>
  )>> }
);

export type GetGroupBiVoSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupBiVoSummaryQuery = (
  { __typename?: 'Query' }
  & { getGroupBiVoSummary?: Maybe<(
    { __typename?: 'GroupBizVO' }
    & Pick<GroupBizVo, 'contractSum' | 'approvedVoAmt' | 'approvedsubVoAmt' | 'voPerc' | 'vosubPerc'>
  )> }
);

export type GetGroupBizVoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupBizVoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBizVO'>
);

export type GetGroupBizVoSubmenuQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupBizVoSubmenuQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGroupBizVoSubmenu'>
);

export type GetHomeSummaryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetHomeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'activeClientCount' | 'contractCount' | 'subcontractCount' | 'subcontractorCount' | 'supplierCount' | 'activeSubcontractTenderCount' | 'projectPurchaseCount' | 'ownedPNMInfo' | 'rentedPNMInfo' | 'assignmentRecord' | 'maintenanceRecord' | 'activeSiteCount' | 'fuelInfo' | 'getRolePermission' | 'getContractPermission'>
);

export type GetHomeGroupSummaryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetHomeGroupSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'activeClientCount' | 'supplierCount' | 'activeSubcontractTenderCount' | 'ownedPNMInfo' | 'rentedPNMInfo' | 'assignmentRecord' | 'maintenanceRecord' | 'fuelInfo'>
);

export type GetAccessSecurityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccessSecurityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractPermission' | 'getRolePermission'>
);

export type GetHomeCardSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomeCardSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getToDoWorkflowSummary' | 'getNotificationWorkflowListing'>
);

export type GetSiteQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSiteQuery = (
  { __typename?: 'Query' }
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'accountID' | 'contractID' | 'name' | 'address' | 'mainSite' | 'contactNo'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & { customerDetail?: Maybe<(
        { __typename?: 'CustomerEntity' }
        & Pick<CustomerEntity, 'name'>
      )> }
    )> }
  )> }
);

export type GetAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  assignmentStatus?: Maybe<AsgMainStatus>;
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
}>;


export type GetAssignmentQuery = (
  { __typename?: 'Query' }
  & { getAssignment: Array<(
    { __typename?: 'AssignmentEntity' }
    & Pick<AssignmentEntity, 'ID' | 'accountID' | 'description' | 'assignmentDate' | 'startDate' | 'endDate' | 'returnDate' | 'plantMachineryID' | 'siteID' | 'contractID' | 'assignmentStatus' | 'returnRemarks' | 'cancellationDate' | 'cancellationRemarks' | 'assignedQty' | 'outstandingQty' | 'trackingLog' | 'cost' | 'profileInfo'>
    & { site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'address'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, plantMachinery?: Maybe<(
      { __typename?: 'PlantMachineryEntity' }
      & Pick<PlantMachineryEntity, 'createdTs' | 'ID' | 'supplierID' | 'costCategoryID' | 'regNo' | 'name' | 'description' | 'remarks' | 'purchaseDate' | 'quantity' | 'availableQty' | 'serialNo' | 'warrantyDate' | 'roadTaxDate' | 'insuranceDate' | 'cancellationDate' | 'cancellationRemarks' | 'plantMachineryStatus'>
      & { costCategory?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name'>
      )> }
    )>, maintenance?: Maybe<Array<(
      { __typename?: 'MaintenanceEntity' }
      & Pick<MaintenanceEntity, 'ID' | 'maintenanceStatus' | 'assignedQty' | 'outstandingQty' | 'startDate' | 'endDate' | 'returnDate' | 'cancellationDate' | 'usageReading'>
      & { workshop?: Maybe<(
        { __typename?: 'WorkshopEntity' }
        & Pick<WorkshopEntity, 'name' | 'ID'>
      )> }
    )>> }
  )> }
);

export type CreateAssignmentMutationVariables = Exact<{
  input: AssignmentInput;
}>;


export type CreateAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAssignment'>
);

export type UpdateAssignmentMutationVariables = Exact<{
  input: AssignmentInput;
}>;


export type UpdateAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAssignment: (
    { __typename?: 'AssignmentEntity' }
    & Pick<AssignmentEntity, 'ID'>
  ) }
);

export type ReturnFromAssignmentMutationVariables = Exact<{
  ID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  input: AssignmentReturnInput;
}>;


export type ReturnFromAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'returnFromAssignment'>
);

export type CancelAssignmentMutationVariables = Exact<{
  ID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelAssignment'>
);

export type GetAssignmentListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  assignmentStatus?: Maybe<AsgMainStatus>;
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetAssignmentListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAssignmentCount'>
  & { getAssignment: Array<(
    { __typename?: 'AssignmentEntity' }
    & Pick<AssignmentEntity, 'ID' | 'accountID' | 'description' | 'assignmentDate' | 'startDate' | 'endDate' | 'returnDate' | 'plantMachineryID' | 'siteID' | 'contractID' | 'assignmentStatus' | 'returnRemarks' | 'cancellationDate' | 'cancellationRemarks' | 'assignedQty' | 'outstandingQty' | 'trackingLog' | 'cost' | 'profileInfo'>
    & { site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'address'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, plantMachinery?: Maybe<(
      { __typename?: 'PlantMachineryEntity' }
      & Pick<PlantMachineryEntity, 'createdTs' | 'ID' | 'supplierID' | 'costCategoryID' | 'regNo' | 'name' | 'description' | 'remarks' | 'purchaseDate' | 'quantity' | 'availableQty' | 'serialNo' | 'warrantyDate' | 'roadTaxDate' | 'insuranceDate' | 'cancellationDate' | 'cancellationRemarks' | 'plantMachineryStatus'>
      & { costCategory?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name'>
      )> }
    )>, maintenance?: Maybe<Array<(
      { __typename?: 'MaintenanceEntity' }
      & Pick<MaintenanceEntity, 'ID' | 'maintenanceStatus' | 'assignedQty' | 'outstandingQty' | 'startDate' | 'endDate' | 'returnDate' | 'cancellationDate' | 'usageReading'>
      & { workshop?: Maybe<(
        { __typename?: 'WorkshopEntity' }
        & Pick<WorkshopEntity, 'name' | 'ID'>
      )> }
    )>> }
  )> }
);

export type GetMaintenanceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  maintenanceStatus?: Maybe<AsgMainStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
}>;


export type GetMaintenanceQuery = (
  { __typename?: 'Query' }
  & { getMaintenance: Array<(
    { __typename?: 'MaintenanceEntity' }
    & Pick<MaintenanceEntity, 'ID' | 'modTs' | 'createdTs' | 'accountID' | 'plantMachineryID' | 'assignmentID' | 'assignmentDate' | 'startDate' | 'endDate' | 'cost' | 'returnDate' | 'cancellationDate' | 'returnRemarks' | 'cancellationRemarks' | 'description' | 'maintenanceStatus' | 'assignedQty' | 'outstandingQty' | 'trackingLog' | 'workshopID' | 'usageReading'>
    & { assignment?: Maybe<(
      { __typename?: 'AssignmentEntity' }
      & Pick<AssignmentEntity, 'ID'>
      & { contract?: Maybe<(
        { __typename?: 'ContractEntity' }
        & Pick<ContractEntity, 'ID' | 'title'>
      )> }
    )>, workshop?: Maybe<(
      { __typename?: 'WorkshopEntity' }
      & Pick<WorkshopEntity, 'ID' | 'name'>
    )>, plantMachinery?: Maybe<(
      { __typename?: 'PlantMachineryEntity' }
      & Pick<PlantMachineryEntity, 'createdTs' | 'modTs' | 'ID' | 'accountID' | 'supplierID' | 'costCategoryID' | 'regNo' | 'name' | 'description' | 'remarks' | 'purchaseDate' | 'serialNo' | 'warrantyDate' | 'roadTaxDate' | 'insuranceDate' | 'cancellationDate' | 'cancellationRemarks' | 'image' | 'plantMachineryStatus'>
      & { costCategory?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name' | 'description' | 'commonStatus'>
      )> }
    )> }
  )> }
);

export type GetWorkshopFormQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  workshopStatus?: Maybe<WorkshopStatus>;
}>;


export type GetWorkshopFormQuery = (
  { __typename?: 'Query' }
  & { getWorkshopForm: Array<(
    { __typename?: 'WorkshopEntity' }
    & Pick<WorkshopEntity, 'ID' | 'name' | 'modTs' | 'accountID' | 'registrationNo' | 'email' | 'contactNo' | 'description' | 'address' | 'contactPerson' | 'workshopStatus' | 'ratingType'>
  )> }
);

export type CreateMaintenanceMutationVariables = Exact<{
  assignmentID?: Maybe<Scalars['String']>;
  input: MaintenanceInput;
}>;


export type CreateMaintenanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMaintenance'>
);

export type UpdateMaintenanceMutationVariables = Exact<{
  assignmentID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  input: MaintenanceInput;
}>;


export type UpdateMaintenanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMaintenance'>
);

export type ReturnFromMaintenanceMutationVariables = Exact<{
  input: MaintenanceCostReturnInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
}>;


export type ReturnFromMaintenanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'returnFromMaintenance'>
);

export type CancelMaintenanceCostMutationVariables = Exact<{
  input: CancelInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
}>;


export type CancelMaintenanceCostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelMaintenanceCost'>
);

export type GetMaintenanceCountQueryVariables = Exact<{
  workshopID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  maintenanceStatus?: Maybe<AsgMainStatus>;
}>;


export type GetMaintenanceCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMaintenanceCount'>
);

export type GetMaintenanceListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  maintenanceStatus?: Maybe<AsgMainStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetMaintenanceListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMaintenanceCount'>
  & { getMaintenance: Array<(
    { __typename?: 'MaintenanceEntity' }
    & Pick<MaintenanceEntity, 'ID' | 'modTs' | 'createdTs' | 'accountID' | 'plantMachineryID' | 'assignmentID' | 'assignmentDate' | 'startDate' | 'endDate' | 'cost' | 'returnDate' | 'cancellationDate' | 'returnRemarks' | 'cancellationRemarks' | 'description' | 'maintenanceStatus' | 'assignedQty' | 'outstandingQty' | 'trackingLog' | 'workshopID' | 'usageReading'>
    & { assignment?: Maybe<(
      { __typename?: 'AssignmentEntity' }
      & Pick<AssignmentEntity, 'ID'>
      & { contract?: Maybe<(
        { __typename?: 'ContractEntity' }
        & Pick<ContractEntity, 'ID' | 'title'>
      )> }
    )>, workshop?: Maybe<(
      { __typename?: 'WorkshopEntity' }
      & Pick<WorkshopEntity, 'ID' | 'name'>
    )>, plantMachinery?: Maybe<(
      { __typename?: 'PlantMachineryEntity' }
      & Pick<PlantMachineryEntity, 'createdTs' | 'modTs' | 'ID' | 'accountID' | 'supplierID' | 'costCategoryID' | 'regNo' | 'name' | 'description' | 'remarks' | 'purchaseDate' | 'serialNo' | 'warrantyDate' | 'roadTaxDate' | 'insuranceDate' | 'cancellationDate' | 'cancellationRemarks' | 'image' | 'plantMachineryStatus'>
      & { costCategory?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name' | 'description' | 'commonStatus'>
      )> }
    )> }
  )> }
);

export type CreatePnmRentalMutationVariables = Exact<{
  input: PnmRentalInput;
}>;


export type CreatePnmRentalMutation = (
  { __typename?: 'Mutation' }
  & { createPNMRental: (
    { __typename?: 'PNMRentalEntity' }
    & Pick<PnmRentalEntity, 'ID'>
  ) }
);

export type UpdatePnmRentalMutationVariables = Exact<{
  input: PnmRentalInput;
}>;


export type UpdatePnmRentalMutation = (
  { __typename?: 'Mutation' }
  & { updatePNMRental: (
    { __typename?: 'PNMRentalEntity' }
    & Pick<PnmRentalEntity, 'ID'>
  ) }
);

export type GetPnmRentalQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetPnmRentalQuery = (
  { __typename?: 'Query' }
  & { getPNMRental: Array<(
    { __typename?: 'PNMRentalEntity' }
    & Pick<PnmRentalEntity, 'ID' | 'contractID' | 'supplierID' | 'costCategoryID' | 'companyID' | 'name' | 'regNo' | 'description' | 'startDate' | 'endDate' | 'cost' | 'rentalRate' | 'returnDate' | 'returnRemarks' | 'requireFuelSupply' | 'image' | 'plantMachineryStatus'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'companyID'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )>, company?: Maybe<(
      { __typename?: 'SubscriptionCompanyType' }
      & Pick<SubscriptionCompanyType, 'Name' | 'Code'>
    )> }
  )> }
);

export type GetRentalTankQueryVariables = Exact<{
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetRentalTankQuery = (
  { __typename?: 'Query' }
  & { getRentalTank: Array<(
    { __typename?: 'PNMRentalEntity' }
    & Pick<PnmRentalEntity, 'ID' | 'contractID' | 'costCategoryID' | 'companyID' | 'name' | 'description' | 'regNo' | 'startDate' | 'endDate' | 'cost' | 'returnDate' | 'returnRemarks' | 'image' | 'plantMachineryStatus' | 'fuelTotalSupply' | 'fuelTotalDischarge' | 'previousFuelSupplyDischarge' | 'tankTable' | 'rentalTankSummary'>
    & { fuelMeterReading?: Maybe<Array<(
      { __typename?: 'FuelMeterReadingEntity' }
      & Pick<FuelMeterReadingEntity, 'ID' | 'docDate'>
    )>>, fuelSupply?: Maybe<Array<(
      { __typename?: 'FuelSupplyEntity' }
      & Pick<FuelSupplyEntity, 'ID' | 'docDate'>
    )>>, fuelDischarge?: Maybe<Array<(
      { __typename?: 'FuelDischargeEntity' }
      & Pick<FuelDischargeEntity, 'ID' | 'dischargeDate'>
    )>> }
  )> }
);

export type GetPnmRentalListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetPnmRentalListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPNMRentalCount'>
  & { getPNMRental: Array<(
    { __typename?: 'PNMRentalEntity' }
    & Pick<PnmRentalEntity, 'ID' | 'contractID' | 'supplierID' | 'costCategoryID' | 'companyID' | 'name' | 'regNo' | 'description' | 'startDate' | 'endDate' | 'cost' | 'rentalRate' | 'returnDate' | 'returnRemarks' | 'requireFuelSupply' | 'image' | 'plantMachineryStatus'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'companyID'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )>, company?: Maybe<(
      { __typename?: 'SubscriptionCompanyType' }
      & Pick<SubscriptionCompanyType, 'Name' | 'Code'>
    )> }
  )> }
);

export type GetPlantMachineryQueryVariables = Exact<{
  costCategoryID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryStatus?: Maybe<PlantMachineryStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetPlantMachineryQuery = (
  { __typename?: 'Query' }
  & { getPlantMachinery: Array<(
    { __typename?: 'PlantMachineryEntity' }
    & Pick<PlantMachineryEntity, 'ID' | 'regNo' | 'name' | 'costCategoryID' | 'plantMachineryStatus' | 'currentPnmStatus' | 'remarks' | 'modTs' | 'image' | 'imageUrl' | 'purchaseDate' | 'warrantyDate' | 'roadTaxDate' | 'insuranceDate' | 'serialNo' | 'supplierID' | 'cancellationRemarks' | 'availableQty' | 'quantity' | 'latestActiveAssignment'>
    & { costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )>, assignment?: Maybe<Array<(
      { __typename?: 'AssignmentEntity' }
      & Pick<AssignmentEntity, 'assignmentStatus' | 'ID' | 'startDate' | 'endDate' | 'description'>
    )>>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, maintenance?: Maybe<Array<(
      { __typename?: 'MaintenanceEntity' }
      & Pick<MaintenanceEntity, 'maintenanceStatus' | 'ID' | 'startDate' | 'endDate' | 'description'>
      & { workshop?: Maybe<(
        { __typename?: 'WorkshopEntity' }
        & Pick<WorkshopEntity, 'name' | 'ID'>
      )> }
    )>> }
  )> }
);

export type GetPnmByStatusQueryVariables = Exact<{
  status: Array<PlantMachineryStatus> | PlantMachineryStatus;
  costCategoryID: Scalars['String'];
}>;


export type GetPnmByStatusQuery = (
  { __typename?: 'Query' }
  & { getPNMByStatus: Array<(
    { __typename?: 'PlantMachineryEntity' }
    & Pick<PlantMachineryEntity, 'ID' | 'regNo' | 'name' | 'costCategoryID' | 'plantMachineryStatus' | 'serialNo' | 'remarks' | 'availableQty'>
    & { costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'pnmCostCat'>
    )> }
  )> }
);

export type GetCategoryPnmQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetCategoryPnmQuery = (
  { __typename?: 'Query' }
  & { getActivePNMCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID' | 'name' | 'modTs' | 'accountID' | 'commonStatus' | 'costClass' | 'pnmCostCat'>
    & { plantMachineryStatus?: Maybe<(
      { __typename?: 'pmStatus' }
      & Pick<PmStatus, 'available' | 'assigned' | 'outOfOrder' | 'maintenance'>
    )>, plantMachinery?: Maybe<Array<(
      { __typename?: 'PlantMachineryEntity' }
      & Pick<PlantMachineryEntity, 'ID' | 'companyID' | 'costCategoryID' | 'supplierID' | 'regNo' | 'name' | 'imageUrl' | 'currentPnmStatus' | 'plantMachineryStatus' | 'latestActiveAssignment' | 'latestActiveMaintenance' | 'remarks' | 'purchaseDate' | 'warrantyDate' | 'roadTaxDate' | 'quantity' | 'availableQty' | 'insuranceDate' | 'serialNo' | 'modTs' | 'cancellationRemarks'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )>, assignment?: Maybe<Array<(
        { __typename?: 'AssignmentEntity' }
        & Pick<AssignmentEntity, 'assignmentStatus' | 'ID' | 'startDate' | 'endDate' | 'description' | 'assignedQty'>
        & { contract?: Maybe<(
          { __typename?: 'ContractEntity' }
          & Pick<ContractEntity, 'ID' | 'title'>
        )> }
      )>>, maintenance?: Maybe<Array<(
        { __typename?: 'MaintenanceEntity' }
        & Pick<MaintenanceEntity, 'maintenanceStatus' | 'workshopID' | 'ID' | 'startDate' | 'endDate' | 'description' | 'assignedQty' | 'outstandingQty'>
        & { workshop?: Maybe<(
          { __typename?: 'WorkshopEntity' }
          & Pick<WorkshopEntity, 'name'>
        )> }
      )>> }
    )>> }
  )> }
);

export type CreatePlantMachineryMutationVariables = Exact<{
  input: PlantMachineryInput;
}>;


export type CreatePlantMachineryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPlantMachinery'>
);

export type UpdatePlantMachineryMutationVariables = Exact<{
  input: PlantMachineryInput;
}>;


export type UpdatePlantMachineryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePlantMachinery'>
);

export type GetPlantSummaryQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetPlantSummaryQuery = (
  { __typename?: 'Query' }
  & { getPlantMachinery: Array<(
    { __typename?: 'PlantMachineryEntity' }
    & Pick<PlantMachineryEntity, 'ID' | 'costCategoryID' | 'plantMachineryStatus' | 'regNo' | 'name' | 'remarks' | 'purchaseDate'>
  )> }
);

export type PlantMachineryCheckerQueryVariables = Exact<{
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type PlantMachineryCheckerQuery = (
  { __typename?: 'Query' }
  & { getPlantMachinery: Array<(
    { __typename?: 'PlantMachineryEntity' }
    & Pick<PlantMachineryEntity, 'ID' | 'costCategoryID' | 'plantMachineryStatus' | 'regNo' | 'name' | 'remarks' | 'purchaseDate'>
  )> }
);

export type PnmSummaryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type PnmSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ownedPNMInfo' | 'rentedPNMInfo' | 'assignmentRecord' | 'maintenanceRecord'>
);

export type OwnedPnmInfoQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type OwnedPnmInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ownedPNMInfo'>
);

export type RentedPnmInfoQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type RentedPnmInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'rentedPNMInfo'>
);

export type AssignmentRecordQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type AssignmentRecordQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'assignmentRecord'>
);

export type MaintenanceRecordQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
}>;


export type MaintenanceRecordQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'maintenanceRecord'>
);

export type GetPnmStatusQueryVariables = Exact<{
  plantMachineryID: Scalars['String'];
}>;


export type GetPnmStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPNMStatus'>
);

export type UpdateProfitRecogFormulaAssignmentMutationVariables = Exact<{
  input: UpdateProfitRecogFormulaInput;
}>;


export type UpdateProfitRecogFormulaAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProfitRecogFormulaAssignment'>
);

export type GetProfitRecogFormulaQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  CommonStatus?: Maybe<CommonStatus>;
}>;


export type GetProfitRecogFormulaQuery = (
  { __typename?: 'Query' }
  & { getProfitRecogFormula: Array<(
    { __typename?: 'ProfitRecogFormulaEntity' }
    & Pick<ProfitRecogFormulaEntity, 'ID' | 'percOfCompletion' | 'commonStatus' | 'title' | 'cost' | 'revenue'>
  )> }
);

export type CreateProfitRecogFormulaMutationVariables = Exact<{
  input: ProfitRecogFormulaInput;
}>;


export type CreateProfitRecogFormulaMutation = (
  { __typename?: 'Mutation' }
  & { createProfitRecogFormula: (
    { __typename?: 'ProfitRecogFormulaEntity' }
    & Pick<ProfitRecogFormulaEntity, 'ID' | 'title' | 'percOfCompletion' | 'cost' | 'revenue'>
  ) }
);

export type UpdateProfitRecogFormulaMutationVariables = Exact<{
  input: ProfitRecogFormulaInput;
}>;


export type UpdateProfitRecogFormulaMutation = (
  { __typename?: 'Mutation' }
  & { updateProfitRecogFormula: (
    { __typename?: 'ProfitRecogFormulaEntity' }
    & Pick<ProfitRecogFormulaEntity, 'ID'>
  ) }
);

export type UpdateDocumentNumberHeaderMutationVariables = Exact<{
  DocNumDetailInput: Array<DocNumDetailInput> | DocNumDetailInput;
  DocNumHeaderInput: DocNumHeaderInput;
}>;


export type UpdateDocumentNumberHeaderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDocumentNumberHeader'>
);

export type GetDocNumHeaderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetDocNumHeaderQuery = (
  { __typename?: 'Query' }
  & { getDocNumHeader: Array<(
    { __typename?: 'DocNumHeaderEntity' }
    & Pick<DocNumHeaderEntity, 'ID' | 'accountID' | 'contractID' | 'sampleOutput'>
    & { docNumDetail: Array<(
      { __typename?: 'DocNumDetailEntity' }
      & Pick<DocNumDetailEntity, 'sequence' | 'formatType' | 'formatValue' | 'startFrom' | 'nextNumber' | 'docNumHeaderID'>
    )> }
  )> }
);

export type GetDocNumDetailQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetDocNumDetailQuery = (
  { __typename?: 'Query' }
  & { getDocNumDetail: Array<(
    { __typename?: 'DocNumDetailEntity' }
    & Pick<DocNumDetailEntity, 'ID' | 'accountID' | 'sequence' | 'formatType' | 'formatValue' | 'startFrom' | 'nextNumber' | 'docNumHeaderID'>
  )> }
);

export type CreateProfitGenerationMutationVariables = Exact<{
  input: ProfitRecogGenerationInput;
}>;


export type CreateProfitGenerationMutation = (
  { __typename?: 'Mutation' }
  & { createProfitGeneration: (
    { __typename?: 'ProfitRecogGenerationEntity' }
    & Pick<ProfitRecogGenerationEntity, 'createdBy'>
  ) }
);

export type UpdateProfitGenerationMutationVariables = Exact<{
  input: ProfitRecogGenerationInput;
  profitRecogStatus: ProfitRecogStatus;
  profitRecogGenerationID: Scalars['String'];
}>;


export type UpdateProfitGenerationMutation = (
  { __typename?: 'Mutation' }
  & { updateProfitGeneration: (
    { __typename?: 'ProfitRecogGenerationEntity' }
    & Pick<ProfitRecogGenerationEntity, 'createdBy'>
  ) }
);

export type GetProfitRecogGenerationQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetProfitRecogGenerationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getProfitRecogGeneration'>
);

export type GetContractProfitRecogQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetContractProfitRecogQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'profitRecogFormulaID'>
  )> }
);

export type GetRegenerateFormDetailsQueryVariables = Exact<{
  contractID: Scalars['String'];
  inputDate: Scalars['String'];
}>;


export type GetRegenerateFormDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'profitRecognitionCalculation' | 'C1_actualCost' | 'C2_budgetCost' | 'C3_actualRevenue' | 'C4_reviseContractSum' | 'C5_totalPreviousRecognisedRevenue' | 'C6_totalPreviousRecognisedCost' | 'C7_percOfCompletion'>
);

export type GetGenerationListingDetailQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetGenerationListingDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'infoLineProfitRecog'>
);

export type GetContractListingProfitRecogQueryVariables = Exact<{
  companyID: Scalars['String'];
}>;


export type GetContractListingProfitRecogQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractListingProfitRecog'>
);

export type GetProfitRecogGenerationListingQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetProfitRecogGenerationListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getProfitRecogGenerationListing'>
);

export type ProjectBudgetAnalysisQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type ProjectBudgetAnalysisQuery = (
  { __typename?: 'Query' }
  & { associateCalculation: (
    { __typename?: 'AssociatedDetails' }
    & Pick<AssociatedDetails, 'totalCost' | 'latestOriginalDate' | 'latestRevisedDate' | 'originalBudgetTotalSum' | 'budgetVarianceAmt' | 'revisedBudgetTotalSum' | 'revisedBudgetMiscSum' | 'revisedBudgetPlantSum' | 'revisedBudgetLabourSum' | 'revisedBudgetMaterialSum' | 'revisedBudgetSubcontractSum' | 'originalBudgetMiscSum' | 'originalBudgetPlantSum' | 'originalBudgetLabourSum' | 'originalBudgetMaterialSum' | 'originalBudgetSubcontractSum' | 'origSubconItemBudget' | 'origMiscItemBudget' | 'origPlantItemBudget' | 'origLabourItemBudget' | 'origMaterialItemBudget' | 'revMiscItemBudget' | 'revPlantItemBudget' | 'revSubconItemBudget' | 'revLabourItemBudget' | 'revMaterialItemBudget' | 'materialCostTotal' | 'plantCostTotal' | 'labourCostTotal' | 'miscCostTotal' | 'subcontCostTotal'>
  ) }
);

export type BudgetVsActualQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type BudgetVsActualQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAdjustmentByCostClass' | 'getContractPLAdjustmentTotal' | 'getSubcontractPLAdjustmentTotal' | 'getSupplierPLAdjustmentTotal'>
  & { associateCalculation: (
    { __typename?: 'AssociatedDetails' }
    & Pick<AssociatedDetails, 'completionPerc' | 'totalActualCost' | 'revisedBudgetTotalSum' | 'originalBudgetTotalSum' | 'totalCost' | 'revisedBudgetMiscSum' | 'revisedBudgetPlantSum' | 'revisedBudgetLabourSum' | 'revisedBudgetMaterialSum' | 'revisedBudgetSubcontractSum' | 'subconCostToDate' | 'materialCostToDate' | 'plantCostToDate' | 'labourCostToDate' | 'miscCostToDate' | 'originalBudgetMiscSum' | 'originalBudgetPlantSum' | 'originalBudgetLabourSum' | 'originalBudgetMaterialSum' | 'originalBudgetSubcontractSum' | 'transferredSubconBudget' | 'transferredMaterialBudget' | 'transferredLabourBudget' | 'transferredPlantBudget' | 'transferredMiscBudget' | 'miscCommCost' | 'plantCommCost' | 'subconCommCost' | 'labourCommCost' | 'materialCommCost' | 'miscCostTotal' | 'plantCostTotal' | 'labourCostTotal' | 'materialCostTotal' | 'subcontCostTotal' | 'subContractCommittedCost'>
  ) }
);

export type BudgetCalculationQueryVariables = Exact<{
  contractID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
}>;


export type BudgetCalculationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'budgetCalculation' | 'getCostingCalculation'>
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'title'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name'>
    )> }
  )> }
);

export type AddOriginalBudgetMutationVariables = Exact<{
  contractID: Scalars['String'];
  budgetInput: Array<ProjectBudgetInput> | ProjectBudgetInput;
}>;


export type AddOriginalBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addOriginalBudget'>
);

export type AddRevisedBudgetMutationVariables = Exact<{
  ID: Scalars['String'];
  contractID: Scalars['String'];
  budgetAmtInput: BudgetAmtInput;
  file?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type AddRevisedBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addRevisedBudget'>
);

export type GetContractBudgetQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  costClass: CostClass;
  costCategoryID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
}>;


export type GetContractBudgetQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'title'>
    & { costCateg: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'costClass' | 'name'>
      & { budget: Array<(
        { __typename?: 'ProjectBudgetEntity' }
        & Pick<ProjectBudgetEntity, 'ID' | 'createdTs' | 'budgetAmt'>
      )> }
    )> }
  )> }
);

export type GetContractBudgetCalculationQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  costClass: CostClass;
  costCategoryID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
}>;


export type GetContractBudgetCalculationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'budgetCalculation'>
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'title'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name'>
    )>, costCateg: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'costClass' | 'name'>
      & { budget: Array<(
        { __typename?: 'ProjectBudgetEntity' }
        & Pick<ProjectBudgetEntity, 'ID' | 'createdTs' | 'modTs' | 'budgetAmt'>
      )> }
    )> }
  )>, getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID' | 'name' | 'commonStatus'>
    & { budget: Array<(
      { __typename?: 'ProjectBudgetEntity' }
      & Pick<ProjectBudgetEntity, 'ID' | 'createdTs' | 'modTs' | 'budgetAmt'>
    )> }
  )> }
);

export type GetCostCategoryBudgetQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCostCategoryBudgetQuery = (
  { __typename?: 'Query' }
  & { getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID' | 'name'>
    & { budget: Array<(
      { __typename?: 'ProjectBudgetEntity' }
      & Pick<ProjectBudgetEntity, 'ID' | 'costCategoryID' | 'budgetAmt'>
    )> }
  )> }
);

export type DeleteBudgetCheckingQueryVariables = Exact<{
  contractID: Scalars['String'];
  costCategoryIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteBudgetCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'deleteBudgetChecking'>
);

export type DeleteProjectBudgetMutationVariables = Exact<{
  input: ProjectBudgetDeleteInput;
}>;


export type DeleteProjectBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectBudget'>
);

export type CashFlowSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type CashFlowSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'receivedFromClient' | 'paidToSubCon' | 'paidToSupplier' | 'paidSubconSupplierAmount' | 'miscExpense' | 'subconLastPayment' | 'supplierLastPayment' | 'receivableClientLastPayment' | 'subconOSPayableAmount' | 'supplierOSPayableAmount' | 'outstandingAmtClient' | 'advancesOutflowAmt' | 'outstandingReceivableFromClient' | 'outstandingPayableSubcon' | 'outstandingPayableSupplier'>
  & { outstandingClaims: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'outstandingAmt'>
  )>, getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'outstandingAmt'>
  )>, advanceClient: (
    { __typename?: 'advanceFromClient' }
    & Pick<AdvanceFromClient, 'advClientAmt' | 'latestAdvClientDate'>
  ) }
);

export type FinancialStatusSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type FinancialStatusSummaryQuery = (
  { __typename?: 'Query' }
  & { financialStatus: (
    { __typename?: 'FinancialStatusDetails' }
    & Pick<FinancialStatusDetails, 'collectionListing' | 'collectionSum' | 'advClientListing' | 'advClientAmt' | 'miscExpense' | 'miscExpSum'>
  ) }
);

export type GetCashFlowListingQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetCashFlowListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'paidSubconSupplierListing'>
);

export type GetClientOsListQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetClientOsListQuery = (
  { __typename?: 'Query' }
  & { outstandingClaims: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'docAmt' | 'baseAmt' | 'outstandingAmt' | 'docDate' | 'description' | 'claimDocNo' | 'docNo' | 'docRef' | 'ID'>
  )>, outstandingMiscInvs: Array<(
    { __typename?: 'ContractMiscInvoiceEntity' }
    & Pick<ContractMiscInvoiceEntity, 'docAmt' | 'baseAmt' | 'outstandingAmt' | 'docDate' | 'description' | 'docNo' | 'docRef' | 'ID'>
  )> }
);

export type GetOsReceivableFromClientListingQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetOsReceivableFromClientListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OSReceivableFromClientListing'>
);

export type GetOsPayableToSupplierListingQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetOsPayableToSupplierListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OSPayableToSupplierListing'>
);

export type GetOsPayableToSubconListingQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetOsPayableToSubconListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OSPayableToSubconListing'>
);

export type GetSubconOsListQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetSubconOsListQuery = (
  { __typename?: 'Query' }
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'docAmt' | 'baseAmt' | 'outstandingAmt' | 'docDate' | 'description' | 'docNo' | 'ID' | 'docRef' | 'claimDocNo'>
  )> }
);

export type GetCashFlowGraphDataQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetCashFlowGraphDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCashFlowGraphData'>
);

export type OutstandingListingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type OutstandingListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'outstandingReceivableFromClientListing' | 'outstandingPayableSubconListing' | 'outstandingPayableSupplierListing'>
);

export type ProjectCostPerformanceQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type ProjectCostPerformanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractVoCalculation' | 'miscInvInfo' | 'getContractPLAdjustmentTotal' | 'getSubcontractPLAdjustmentTotal' | 'getSupplierPLAdjustmentTotal'>
  & { associateCalculation: (
    { __typename?: 'AssociatedDetails' }
    & Pick<AssociatedDetails, 'cpi' | 'completionPerc' | 'costVariance' | 'earnedValue' | 'totalActualCost' | 'latestActualCostDate' | 'originalBudgetTotalSum' | 'revisedBudgetTotalSum' | 'revisedBudgetSubcontractSum' | 'subconCommCost' | 'materialCommCost' | 'labourCommCost' | 'plantCommCost' | 'miscCommCost'>
  ), getSubcontractMiscInvoice: Array<(
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxAmt' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )> }
);

export type SetCompletionPercMutationVariables = Exact<{
  contractID: Scalars['String'];
  input: CompletionInput;
}>;


export type SetCompletionPercMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completionPerc'>
);

export type ProjectCostingQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type ProjectCostingQuery = (
  { __typename?: 'Query' }
  & { associateCalculation: (
    { __typename?: 'AssociatedDetails' }
    & Pick<AssociatedDetails, 'totalCost' | 'revisedBudgetSubcontractSum' | 'subconCommCost' | 'materialCommCost' | 'labourCommCost' | 'plantCommCost' | 'miscCommCost' | 'subcontCostTotal' | 'materialCostTotal' | 'plantCostTotal' | 'miscCostTotal' | 'labourCostTotal' | 'revisedBudgetMaterialSum' | 'revisedBudgetPlantSum' | 'revisedBudgetLabourSum' | 'revisedBudgetMiscSum' | 'revisedBudgetTotalSum' | 'revMiscItemBudget' | 'revPlantItemBudget' | 'revSubconItemBudget' | 'revLabourItemBudget' | 'revMaterialItemBudget' | 'originalBudgetMiscSum' | 'originalBudgetPlantSum' | 'originalBudgetLabourSum' | 'originalBudgetMaterialSum' | 'originalBudgetSubcontractSum' | 'originalBudgetTotalSum'>
  ) }
);

export type GetProjectCostingBudgetQueryVariables = Exact<{
  ID: Scalars['String'];
  costClass: CostClass;
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetProjectCostingBudgetQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'title' | 'implementWbs'>
    & { costCateg: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'costClass' | 'name' | 'costToDate' | 'committedCost'>
      & { budget: Array<(
        { __typename?: 'ProjectBudgetEntity' }
        & Pick<ProjectBudgetEntity, 'ID' | 'createdTs' | 'budgetAmt'>
      )> }
    )> }
  )> }
);

export type GetProjectCostingDetailQueryVariables = Exact<{
  ID: Scalars['String'];
  costClass: CostClass;
}>;


export type GetProjectCostingDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getProjectCostingDetail' | 'getCostingCalculation'>
);

export type GetCostingCalculationQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetCostingCalculationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostingCalculation' | 'getAdjustmentByCostClass'>
);

export type GetCostCategCountQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetCostCategCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostCategCount'>
);

export type GetSubconOfTradeQueryVariables = Exact<{
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetSubconOfTradeQuery = (
  { __typename?: 'Query' }
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'subcontractDate' | 'subcontractNo' | 'revisedSubcontractSum' | 'claimToDate' | 'subcontractorID' | 'costCategoryID'>
    & { subcontractorDetail?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name'>
    )> }
  )> }
);

export type GetSubconClaimOfTradeQueryVariables = Exact<{
  subcontractID: Scalars['String'];
  transactionStatus: TransactionStatus;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetSubconClaimOfTradeQuery = (
  { __typename?: 'Query' }
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'baseAmt' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'submittedDate' | 'claimDocNo' | 'description' | 'transactionStatus' | 'currentCertifiedAmt' | 'previousCertifiedAmt'>
  )> }
);

export type GetPOsByCategoryQueryVariables = Exact<{
  status?: Maybe<PurchaseStatus>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetPOsByCategoryQuery = (
  { __typename?: 'Query' }
  & { getPOsByCategory: Array<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'ID' | 'docNo' | 'docDate' | 'remarks' | 'supplierID' | 'totalAmt' | 'upToDateAmt'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name'>
    )>, poItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'ID' | 'totalAmt' | 'taxAmt' | 'orderedQty'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'costCategoryID' | 'name'>
      )>, doItem?: Maybe<Array<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'ID' | 'doItemAmt' | 'acceptedQty' | 'upToDateQty'>
        & { poItem?: Maybe<(
          { __typename?: 'POItemEntity' }
          & { costItem?: Maybe<(
            { __typename?: 'CostItemEntity' }
            & Pick<CostItemEntity, 'costCategoryID'>
          )> }
        )>, DO?: Maybe<(
          { __typename?: 'DOEntity' }
          & Pick<DoEntity, 'DODetails' | 'doStatus' | 'upToDateAmt'>
        )> }
      )>> }
    )>> }
  )> }
);

export type GetDOsByCategoryQueryVariables = Exact<{
  doStatus?: Maybe<DoStatus>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  purchaseOrderID?: Maybe<Scalars['String']>;
}>;


export type GetDOsByCategoryQuery = (
  { __typename?: 'Query' }
  & { getDOsByCategory: Array<(
    { __typename?: 'DOEntity' }
    & Pick<DoEntity, 'createdTs' | 'ID' | 'DODetails' | 'doStatus' | 'remarks' | 'docDate' | 'transactionDate' | 'doNo' | 'docNo' | 'supplierID' | 'DOdocAmt'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name'>
    )>, doItem?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & Pick<DoItemEntity, 'ID' | 'acceptedQty' | 'upToDateQty'>
      & { goodReturnNoteItem?: Maybe<Array<(
        { __typename?: 'GoodReturnNoteItemEntity' }
        & Pick<GoodReturnNoteItemEntity, 'doItemID' | 'grtnID'>
        & { goodReturnNote?: Maybe<(
          { __typename?: 'GoodReturnNoteEntity' }
          & Pick<GoodReturnNoteEntity, 'ID'>
        )> }
      )>>, POs?: Maybe<(
        { __typename?: 'PurchaseOrderEntity' }
        & Pick<PurchaseOrderEntity, 'ID' | 'docNo' | 'docDate'>
      )>, poItem?: Maybe<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'totalAmt' | 'orderedQty' | 'taxAmt'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'costCategoryID' | 'name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetGrtNsByCategoryDoQueryVariables = Exact<{
  doStatus?: Maybe<DoStatus>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  purchaseOrderID?: Maybe<Scalars['String']>;
}>;


export type GetGrtNsByCategoryDoQuery = (
  { __typename?: 'Query' }
  & { getGRTNsByCategoryDO: Array<(
    { __typename?: 'GoodReturnNoteEntity' }
    & Pick<GoodReturnNoteEntity, 'ID' | 'docDate' | 'createdTs' | 'docNo' | 'docRef' | 'description' | 'grtnStatus' | 'remarks' | 'supplierID' | 'GRTNdocAmt'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, goodReturnNoteItem?: Maybe<Array<(
      { __typename?: 'GoodReturnNoteItemEntity' }
      & Pick<GoodReturnNoteItemEntity, 'ID' | 'grtnID' | 'doItemID' | 'returnedQty'>
      & { doItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'ID' | 'deliveryOrderID' | 'purchaseOrderID' | 'acceptedQty' | 'upToDateQty' | 'doItemAmt'>
        & { DO?: Maybe<(
          { __typename?: 'DOEntity' }
          & Pick<DoEntity, 'ID' | 'docNo' | 'doStatus'>
        )>, POs?: Maybe<(
          { __typename?: 'PurchaseOrderEntity' }
          & Pick<PurchaseOrderEntity, 'ID' | 'totalAmt' | 'taxAmt'>
        )>, poItem?: Maybe<(
          { __typename?: 'POItemEntity' }
          & Pick<PoItemEntity, 'ID' | 'orderedQty' | 'taxAmt' | 'totalAmt' | 'costItemID' | 'unitPrice'>
          & { costItem?: Maybe<(
            { __typename?: 'CostItemEntity' }
            & Pick<CostItemEntity, 'ID' | 'costCategoryID' | 'name' | 'code' | 'unitPrice'>
            & { costCategory?: Maybe<(
              { __typename?: 'CostCategoryEntity' }
              & Pick<CostCategoryEntity, 'ID' | 'name'>
            )> }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GetCommCostAmtQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  subcontract?: Maybe<Scalars['Boolean']>;
}>;


export type GetCommCostAmtQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCommCostAmt'>
);

export type GetAdjustmentItemByCostCategQueryVariables = Exact<{
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
}>;


export type GetAdjustmentItemByCostCategQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAdjustmentItemByCostCateg'>
);

export type GetApInvoiceWoDoByCostItemQueryVariables = Exact<{
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
}>;


export type GetApInvoiceWoDoByCostItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAPInvoiceWoDOByCostItem'>
);

export type GetPcYearQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetPcYearQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPCYear'>
);

export type GetReportYearQueryVariables = Exact<{
  startYear?: Maybe<Scalars['Float']>;
  endYear?: Maybe<Scalars['Float']>;
}>;


export type GetReportYearQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getReportYear'>
);

export type GetProjectFinancialStatusQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetProjectFinancialStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractPLAdjustmentStatusTotal' | 'getContractPLAdjustmentStatusTotal' | 'getSupplierPLAdjustmentStatusTotal'>
  & { financialStatus: (
    { __typename?: 'FinancialStatusDetails' }
    & Pick<FinancialStatusDetails, 'completionPercentage' | 'billToDateSum' | 'latestBillingDate' | 'latestMiscExpStatusDate' | 'collectionSum' | 'latestCollectionDate' | 'latestSupplierInvDate' | 'latestSubconBillDate' | 'costToDateSum' | 'subconBillingSum' | 'supplierInvSum' | 'miscExpSum' | 'subconPaymentRefundSum' | 'supplierPaymentSumStatus' | 'latestSubconPaymentRefundDate' | 'latestSupplierPaymentDateStatus' | 'conBilling' | 'collectionListing' | 'subconBilling' | 'supplierInvoice' | 'miscExpenseStatus' | 'subconPaymentRefund' | 'supplierPaymentStatus' | 'clientAdvanceListing' | 'clientAdvanceSum' | 'subconSuppAdvanceListing' | 'subconSuppAdvanceSum' | 'latestClientAdvanceDate' | 'latestSubconSuppAdvanceDate'>
  ), associateCalculation: (
    { __typename?: 'AssociatedDetails' }
    & Pick<AssociatedDetails, 'revisedBudgetTotalSum' | 'materialCostTotal' | 'plantCostTotal' | 'labourCostTotal' | 'miscCostTotal' | 'subcontCostTotal'>
  ), projectPL: (
    { __typename?: 'ProjectPLDetails' }
    & Pick<ProjectPlDetails, 'revisedContractSum'>
  ), getContractCN: Array<(
    { __typename?: 'ContractCNEntity' }
    & Pick<ContractCnEntity, 'ID' | 'docNo' | 'docDate' | 'cancelledClaimAmt' | 'docAmt' | 'taxAmt' | 'description' | 'docRef' | 'transactionStatus'>
    & { claimInfo?: Maybe<(
      { __typename?: 'ContractClaimEntity' }
      & Pick<ContractClaimEntity, 'ID' | 'docNo' | 'claimDocNo' | 'docRef' | 'docDate' | 'retentionAmt' | 'revenueAmt' | 'description' | 'transactionStatus'>
    )> }
  )> }
);

export type GetProjectFinancialStatusListQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetProjectFinancialStatusListQuery = (
  { __typename?: 'Query' }
  & { financialStatus: (
    { __typename?: 'FinancialStatusDetails' }
    & Pick<FinancialStatusDetails, 'conBilling' | 'collectionListing' | 'subconBilling' | 'supplierInvoice' | 'miscExpenseStatus' | 'subconPaymentRefund' | 'subconPaymentRefundSum' | 'supplierPaymentStatus'>
  ) }
);

export type GetShareAttachmentMutationVariables = Exact<{
  file: Scalars['Upload'];
  contractID: Scalars['String'];
}>;


export type GetShareAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { shareAttachment?: Maybe<(
    { __typename?: 'ShareAttachmentEntity' }
    & Pick<ShareAttachmentEntity, 'ID' | 'bucketFileName' | 'fileName' | 'fileURL'>
  )> }
);

export type UpdateCompletionPercMutationVariables = Exact<{
  contractID: Scalars['String'];
  completionPerc: Scalars['String'];
}>;


export type UpdateCompletionPercMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompletionPerc'>
);

export type GetMiscExpenseQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  status?: Maybe<MiscExpenseStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetMiscExpenseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastMiscExpDate'>
  & { getMiscExpense: Array<(
    { __typename?: 'MiscExpenseEntity' }
    & Pick<MiscExpenseEntity, 'ID' | 'status' | 'accountID' | 'contractID' | 'costCategoryID' | 'docRef' | 'docNo' | 'docDate' | 'description' | 'docAmt' | 'baseAmt' | 'taxRate' | 'taxAmt' | 'transactionDate' | 'taxSchemeID' | 'profileInfo'>
    & { taxScheme?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxRate' | 'taxDate'>
      )> }
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'name' | 'costClass'>
    )> }
  )> }
);

export type GetMiscExpenseListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<MiscExpenseStatus>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetMiscExpenseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMiscExpenseCount'>
  & { getMiscExpense: Array<(
    { __typename?: 'MiscExpenseEntity' }
    & Pick<MiscExpenseEntity, 'ID' | 'status' | 'docDate' | 'transactionDate' | 'contractID' | 'docRef' | 'docNo' | 'taxAmt' | 'baseAmt' | 'docAmt' | 'description' | 'taxSchemeID' | 'costCategoryID'>
    & { costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'name' | 'costClass'>
    )> }
  )> }
);

export type CreateMiscExpenseMutationVariables = Exact<{
  input: MiscExpenseInput;
}>;


export type CreateMiscExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMiscExpense'>
);

export type UpdateMiscExpenseMutationVariables = Exact<{
  input: MiscExpenseInput;
}>;


export type UpdateMiscExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMiscExpense'>
);

export type CancelMiscExpenseMutationVariables = Exact<{
  input: MiscExpenseCancellationInput;
  ID: Scalars['String'];
}>;


export type CancelMiscExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelMiscExpense'>
);

export type DeleteMiscExpenseMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteMiscExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMiscExpense'>
);

export type GetMiscExpenseCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  status?: Maybe<MiscExpenseStatus>;
}>;


export type GetMiscExpenseCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMiscExpenseCount'>
);

export type GetProjectPlSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetProjectPlSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'miscInvInfo' | 'getAdjustmentByCostClass' | 'getContractPLAdjustmentTotal' | 'getSubcontractPLAdjustmentTotal' | 'getSupplierPLAdjustmentTotal'>
  & { projectPL: (
    { __typename?: 'ProjectPLDetails' }
    & Pick<ProjectPlDetails, 'origContractSum' | 'revisedContractSum' | 'voSum' | 'subcontractClaimSum' | 'subconClaimListing' | 'grossTotal' | 'totalClaimSum' | 'totalProgressClaim' | 'totalSubconSum' | 'progressClaimListing' | 'doSumPerCC' | 'materialDOListing' | 'labourDOListing' | 'plantDOListing' | 'miscDOListing'>
  ), getContractClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'docDate' | 'docNo' | 'docRef' | 'revenueAmt' | 'docAmt' | 'taxAmt' | 'claimDocNo' | 'description' | 'transactionStatus' | 'docRefTable' | 'outstandingAmt' | 'currentCertifiedAmt' | 'previousCertifiedAmt' | 'currentVOClaimAmt' | 'previousVOClaimAmt' | 'currentMosAmt' | 'previousMosAmt'>
  )>, cancelledClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'docDate' | 'docNo' | 'docRef' | 'revenueAmt' | 'docAmt' | 'taxAmt' | 'claimDocNo' | 'description' | 'transactionStatus' | 'docRefTable' | 'outstandingAmt' | 'currentCertifiedAmt' | 'previousCertifiedAmt' | 'currentVOClaimAmt' | 'previousVOClaimAmt' | 'currentMosAmt' | 'previousMosAmt'>
  )>, getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'description' | 'subcontractSum' | 'subcontractNo' | 'createdTs' | 'ID'>
    & { subcontractorDetail?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name'>
    )> }
  )>, financialStatus: (
    { __typename?: 'FinancialStatusDetails' }
    & Pick<FinancialStatusDetails, 'collectionSum'>
  ), associateCalculation: (
    { __typename?: 'AssociatedDetails' }
    & Pick<AssociatedDetails, 'totalCost' | 'subcontCostTotal' | 'subconCommCost' | 'materialCommCost' | 'labourCommCost' | 'plantCommCost' | 'miscCommCost'>
  ), outstandingClaims: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'outstandingAmt' | 'docDate' | 'description' | 'docNo'>
  )>, getContractMiscInvoice: Array<(
    { __typename?: 'ContractMiscInvoiceEntity' }
    & Pick<ContractMiscInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxAmt' | 'transactionStatus' | 'outstandingAmt' | 'numOfAttachment'>
  )>, getSubcontractMiscInvoice: Array<(
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxAmt' | 'description' | 'outstandingAmt' | 'transactionStatus' | 'numOfAttachment'>
  )>, getMiscExpense: Array<(
    { __typename?: 'MiscExpenseEntity' }
    & Pick<MiscExpenseEntity, 'ID' | 'status' | 'accountID' | 'contractID' | 'costCategoryID' | 'docRef' | 'docDate' | 'description' | 'docAmt' | 'baseAmt' | 'taxRate' | 'taxAmt' | 'transactionDate' | 'taxSchemeID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'name' | 'costClass'>
    )> }
  )> }
);

export type GetProjectCostingSubmenuSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetProjectCostingSubmenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'budgetCalculation' | 'miscExpInfo' | 'lastMiscExpDate' | 'receivedFromClient' | 'paidToSubCon' | 'paidToSupplier' | 'advancesOutflowAmt' | 'miscExpense' | 'paidSubconSupplierAmount' | 'getSubcontractPLAdjustmentStatusTotal' | 'getSupplierPLAdjustmentStatusTotal' | 'getContractPLAdjustmentTotal' | 'getSubcontractPLAdjustmentTotal' | 'getSupplierPLAdjustmentTotal' | 'getCostingCalculation'>
  & { associateCalculation: (
    { __typename?: 'AssociatedDetails' }
    & Pick<AssociatedDetails, 'revisedBudgetTotalSum' | 'originalBudgetTotalSum' | 'totalCost' | 'earnedValue' | 'costVariance' | 'subconCostToDate' | 'materialCostToDate' | 'labourCostToDate' | 'plantCostToDate' | 'miscCostToDate'>
  ), financialStatus: (
    { __typename?: 'FinancialStatusDetails' }
    & Pick<FinancialStatusDetails, 'billToDateSum' | 'costToDateSum'>
  ), advanceClient: (
    { __typename?: 'advanceFromClient' }
    & Pick<AdvanceFromClient, 'advClientAmt' | 'latestAdvClientDate'>
  ), getContractMiscInvoice: Array<(
    { __typename?: 'ContractMiscInvoiceEntity' }
    & Pick<ContractMiscInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxAmt' | 'transactionStatus' | 'outstandingAmt'>
  )>, getSubcontractMiscInvoice: Array<(
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxAmt' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )>, projectPL: (
    { __typename?: 'ProjectPLDetails' }
    & Pick<ProjectPlDetails, 'grossTotal' | 'subcontractClaimSum' | 'doSumPerCC'>
  ) }
);

export type GetProjectExpenseQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetProjectExpenseQuery = (
  { __typename?: 'Query' }
  & { getProjectExpense: Array<(
    { __typename?: 'ProjectExpenseEntity' }
    & Pick<ProjectExpenseEntity, 'ID' | 'createdBy' | 'createdTs' | 'contractID' | 'docRef' | 'docNo' | 'docDate' | 'transactionDate' | 'docAmt' | 'taxAmt' | 'description' | 'status' | 'rejectionDate' | 'rejectionRemarks'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, projectExpenseItem?: Maybe<Array<(
      { __typename?: 'ProjectExpenseItemEntity' }
      & Pick<ProjectExpenseItemEntity, 'ID' | 'projectExpenseID' | 'costClass' | 'costCategoryID' | 'wbsID' | 'costItemID' | 'taxSchemeID' | 'docAmt' | 'taxAmt' | 'taxRate' | 'remarks'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'name' | 'parentName'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'code' | 'costCategoryID'>
      )>, costCategory?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name' | 'costClass'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'code' | 'description'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxRate' | 'taxDate' | 'taxSchemeID'>
        )> }
      )> }
    )>> }
  )> }
);

export type CreateProjectExpenseMutationVariables = Exact<{
  input: ProjectExpenseInput;
  itemInput?: Maybe<Array<ProjectExpenseItemInput> | ProjectExpenseItemInput>;
}>;


export type CreateProjectExpenseMutation = (
  { __typename?: 'Mutation' }
  & { createProjectExpense: (
    { __typename?: 'ProjectExpenseEntity' }
    & Pick<ProjectExpenseEntity, 'ID'>
  ) }
);

export type UpdateProjectExpenseMutationVariables = Exact<{
  input: ProjectExpenseInput;
  itemInput?: Maybe<Array<ProjectExpenseItemInput> | ProjectExpenseItemInput>;
}>;


export type UpdateProjectExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProjectExpense'>
);

export type DeleteProjectExpenseMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteProjectExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectExpense'>
);

export type CancelProjectExpenseMutationVariables = Exact<{
  ID: Scalars['String'];
  input: ProjectExpenseCancelInput;
}>;


export type CancelProjectExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelProjectExpense'>
);

export type RejectProjectExpenseMutationVariables = Exact<{
  ID: Scalars['String'];
  input: ProjectExpenseRejectInput;
}>;


export type RejectProjectExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectProjectExpense'>
);

export type GetProjectExpenseCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
}>;


export type GetProjectExpenseCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getProjectExpenseCount'>
);

export type GetProjectExpenseListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetProjectExpenseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getProjectExpenseCount'>
  & { getProjectExpense: Array<(
    { __typename?: 'ProjectExpenseEntity' }
    & Pick<ProjectExpenseEntity, 'ID' | 'contractID' | 'createdBy' | 'createdTs' | 'docRef' | 'docNo' | 'docDate' | 'transactionDate' | 'description' | 'status' | 'docAmt' | 'rejectionDate' | 'rejectionRemarks'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title'>
    )>, projectExpenseItem?: Maybe<Array<(
      { __typename?: 'ProjectExpenseItemEntity' }
      & Pick<ProjectExpenseItemEntity, 'ID' | 'projectExpenseID' | 'costClass' | 'costCategoryID' | 'wbsID' | 'costItemID' | 'taxSchemeID' | 'docAmt' | 'taxAmt' | 'taxRate' | 'remarks'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'name' | 'parentName'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'code' | 'costCategoryID'>
      )>, costCategory?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name' | 'costClass'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'code' | 'description'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxRate' | 'taxDate' | 'taxSchemeID'>
        )> }
      )> }
    )>> }
  )> }
);

export type ProjectLedgerWbsRevenueSummaryQueryVariables = Exact<{
  contractID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  companyID: Scalars['String'];
  wbsID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  revenueID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  trxEndDate?: Maybe<Scalars['String']>;
  trxStartDate?: Maybe<Scalars['String']>;
}>;


export type ProjectLedgerWbsRevenueSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'projectLedgerWbsRevenueSummary'>
);

export type ProjectLedgerWbsRetentionSummaryQueryVariables = Exact<{
  contractID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  companyID: Scalars['String'];
  wbsID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  revenueID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  trxEndDate?: Maybe<Scalars['String']>;
  trxStartDate?: Maybe<Scalars['String']>;
}>;


export type ProjectLedgerWbsRetentionSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'projectLedgerWbsRetentionSummary'>
);

export type ProjectLedgerWbsCostSummaryQueryVariables = Exact<{
  contractID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  companyID: Scalars['String'];
  wbsID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  costItemID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  costCategoryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  costClass?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  trxEndDate?: Maybe<Scalars['String']>;
  trxStartDate?: Maybe<Scalars['String']>;
}>;


export type ProjectLedgerWbsCostSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'projectLedgerWbsCostSummary'>
);

export type GetCostCategoryReportQueryVariables = Exact<{
  costClass?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetCostCategoryReportQuery = (
  { __typename?: 'Query' }
  & { getCostCategoryReport: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID' | 'name' | 'costClass'>
  )> }
);

export type GetCostItemReportQueryVariables = Exact<{
  costCategoryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetCostItemReportQuery = (
  { __typename?: 'Query' }
  & { getCostItemReport: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'name'>
  )> }
);

export type GetRevenueCategoryReportQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetRevenueCategoryReportQuery = (
  { __typename?: 'Query' }
  & { getRevenueCategory: Array<(
    { __typename?: 'RevenueCategoryEntity' }
    & Pick<RevenueCategoryEntity, 'ID' | 'name'>
  )> }
);

export type GetDoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  siteID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetDoQuery = (
  { __typename?: 'Query' }
  & { getDO: Array<(
    { __typename?: 'DOEntity' }
    & Pick<DoEntity, 'ID' | 'supplierID' | 'siteID' | 'rejectionDate' | 'rejectionRemarks' | 'remarks' | 'docDate' | 'transactionDate' | 'docNo' | 'doNo' | 'upToDateAmt' | 'deliveryAddress' | 'contactPerson' | 'doStatus' | 'DODetails' | 'DOdocAmt'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
      & { grn?: Maybe<Array<(
        { __typename?: 'GRNEntity' }
        & Pick<GrnEntity, 'ID' | 'doID' | 'docNo'>
      )>> }
    )>, doItem?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & Pick<DoItemEntity, 'ID' | 'purchaseOrderID' | 'deliveryOrderID' | 'poItemID' | 'grtnItemID' | 'acceptedQty' | 'deliveredQty' | 'upToDateQty' | 'returnedQty'>
      & { poItem?: Maybe<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'purchaseOrderID' | 'outstandingQty' | 'orderedQty' | 'costItemID' | 'taxSchemeID' | 'remarks'>
        & { uom?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'code'>
        )>, costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'name'>
        )> }
      )>, goodReturnNoteItem?: Maybe<Array<(
        { __typename?: 'GoodReturnNoteItemEntity' }
        & Pick<GoodReturnNoteItemEntity, 'grtnID' | 'doItemID' | 'returnedQty' | 'replacement'>
      )>>, apInvoiceItem?: Maybe<Array<(
        { __typename?: 'APInvoiceItemEntity' }
        & Pick<ApInvoiceItemEntity, 'APInvoiceID' | 'ID' | 'doItemID' | 'costItemID'>
      )>> }
    )>>, POs: Array<(
      { __typename?: 'PurchaseOrderEntity' }
      & Pick<PurchaseOrderEntity, 'ID' | 'subcontractID' | 'docNo' | 'docDate' | 'purchaseType' | 'purchaseStatus'>
      & { subcontract?: Maybe<(
        { __typename?: 'SubcontractEntity' }
        & Pick<SubcontractEntity, 'ID' | 'title'>
      )>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'remarks' | 'outstandingQty' | 'orderedQty' | 'costItemID' | 'uomID'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'name'>
        )>, uom?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'name' | 'code'>
        )>, prItem?: Maybe<(
          { __typename?: 'PRItemEntity' }
          & Pick<PrItemEntity, 'ID'>
          & { costItem?: Maybe<(
            { __typename?: 'CostItemEntity' }
            & Pick<CostItemEntity, 'ID' | 'name'>
          )>, purchaseReq?: Maybe<(
            { __typename?: 'PurchaseReqEntity' }
            & Pick<PurchaseReqEntity, 'ID' | 'contactPerson'>
            & { deliveryAddress?: Maybe<(
              { __typename?: 'SiteEntity' }
              & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
            )> }
          )> }
        )> }
      )>> }
    )>, GRTNs: Array<(
      { __typename?: 'GoodReturnNoteEntity' }
      & Pick<GoodReturnNoteEntity, 'ID' | 'docNo' | 'docDate'>
      & { goodReturnNoteItem?: Maybe<Array<(
        { __typename?: 'GoodReturnNoteItemEntity' }
        & Pick<GoodReturnNoteItemEntity, 'ID' | 'outstandingQty' | 'returnedQty' | 'doItemID'>
        & { doItem?: Maybe<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'ID' | 'poItemID'>
          & { poItem?: Maybe<(
            { __typename?: 'POItemEntity' }
            & Pick<PoItemEntity, 'costItemID' | 'uomID' | 'remarks'>
            & { costItem?: Maybe<(
              { __typename?: 'CostItemEntity' }
              & Pick<CostItemEntity, 'ID' | 'name'>
            )>, uom?: Maybe<(
              { __typename?: 'UOMEntity' }
              & Pick<UomEntity, 'ID' | 'name' | 'code'>
            )> }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type SubmitDoMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  doInput: DoInput;
  doItemInput?: Maybe<Array<DoItemInput> | DoItemInput>;
}>;


export type SubmitDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitDO'>
);

export type CancelCloseDoMutationVariables = Exact<{
  ID: Scalars['String'];
  input: DoActionInput;
}>;


export type CancelCloseDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCloseDO'>
);

export type RejectDoMutationVariables = Exact<{
  ID: Scalars['String'];
  rejectInput?: Maybe<DoRejectInput>;
}>;


export type RejectDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectDO'>
);

export type UpdateDoMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  doItemInput: Array<DoItemInput> | DoItemInput;
  doInput: UpdateDoInput;
}>;


export type UpdateDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDO'>
);

export type ActionOnDoStatusMutationVariables = Exact<{
  ID: Scalars['String'];
  doItemInput: Array<DoActionItemInput> | DoActionItemInput;
}>;


export type ActionOnDoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnDoStatus'>
);

export type GetContractWithActiveDoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractWithActiveDoQuery = (
  { __typename?: 'Query' }
  & { getContractWithActiveDO: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'description' | 'creditTerm' | 'implementWbs' | 'budgetChecker'>
    & { taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxRate' | 'taxDate'>
      )>, taxEffective?: Maybe<Array<(
        { __typename?: 'ConTaxEffectiveDateEntity' }
        & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
      )>> }
    )> }
  )> }
);

export type DeleteDoMutationVariables = Exact<{
  doID: Scalars['String'];
}>;


export type DeleteDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDO'>
);

export type GetSupplierByDoStatusQueryVariables = Exact<{
  doStatus: DoStatus;
  contractID: Scalars['String'];
}>;


export type GetSupplierByDoStatusQuery = (
  { __typename?: 'Query' }
  & { getSupplierByDOStatus: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'registrationNo' | 'email' | 'address' | 'currencyID' | 'creditTerm' | 'description' | 'platformAccess' | 'contactNo' | 'ratingType' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'associatedStatus' | 'bankDetails' | 'accountID'>
  )> }
);

export type DeliveryOrderRpaMutationVariables = Exact<{
  files: Scalars['Upload'];
  contractID: Scalars['String'];
}>;


export type DeliveryOrderRpaMutation = (
  { __typename?: 'Mutation' }
  & { deliveryOrderRPA: (
    { __typename?: 'DORPAResult' }
    & Pick<DorpaResult, 'supplierID' | 'doNo' | 'poNo' | 'descriptions' | 'deliveryDate' | 'deliveryAddress'>
    & { doItems?: Maybe<Array<(
      { __typename?: 'DOItem' }
      & Pick<DoItem, 'costItemID' | 'qty'>
    )>> }
  ) }
);

export type CheckingCancelCloseDoQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type CheckingCancelCloseDoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkingCancelCloseDO'>
);

export type GetDoCountQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
}>;


export type GetDoCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDOCount'>
);

export type GetDoListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  siteID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetDoListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDOCount'>
  & { getDO: Array<(
    { __typename?: 'DOEntity' }
    & Pick<DoEntity, 'ID' | 'supplierID' | 'siteID' | 'rejectionDate' | 'rejectionRemarks' | 'remarks' | 'docDate' | 'transactionDate' | 'docNo' | 'doNo' | 'upToDateAmt' | 'deliveryAddress' | 'contactPerson' | 'doStatus' | 'DODetails' | 'DOdocAmt'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
      & { grn?: Maybe<Array<(
        { __typename?: 'GRNEntity' }
        & Pick<GrnEntity, 'ID' | 'doID' | 'docNo'>
      )>> }
    )>, doItem?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & Pick<DoItemEntity, 'ID' | 'purchaseOrderID' | 'deliveryOrderID' | 'poItemID' | 'grtnItemID' | 'acceptedQty' | 'deliveredQty' | 'upToDateQty' | 'returnedQty'>
      & { poItem?: Maybe<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'purchaseOrderID' | 'outstandingQty' | 'orderedQty' | 'costItemID' | 'taxSchemeID' | 'remarks'>
        & { uom?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'code'>
        )>, costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'name' | 'isInventory'>
        )> }
      )>, goodReturnNoteItem?: Maybe<Array<(
        { __typename?: 'GoodReturnNoteItemEntity' }
        & Pick<GoodReturnNoteItemEntity, 'grtnID' | 'doItemID' | 'returnedQty' | 'replacement'>
      )>>, apInvoiceItem?: Maybe<Array<(
        { __typename?: 'APInvoiceItemEntity' }
        & Pick<ApInvoiceItemEntity, 'APInvoiceID' | 'ID' | 'doItemID' | 'costItemID'>
      )>> }
    )>>, POs: Array<(
      { __typename?: 'PurchaseOrderEntity' }
      & Pick<PurchaseOrderEntity, 'ID' | 'subcontractID' | 'docNo' | 'docDate' | 'purchaseType' | 'purchaseStatus'>
      & { subcontract?: Maybe<(
        { __typename?: 'SubcontractEntity' }
        & Pick<SubcontractEntity, 'ID' | 'title'>
      )>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'remarks' | 'outstandingQty' | 'orderedQty' | 'costItemID' | 'uomID'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'name'>
        )>, uom?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'name' | 'code'>
        )>, prItem?: Maybe<(
          { __typename?: 'PRItemEntity' }
          & Pick<PrItemEntity, 'ID'>
          & { costItem?: Maybe<(
            { __typename?: 'CostItemEntity' }
            & Pick<CostItemEntity, 'ID' | 'name'>
          )>, purchaseReq?: Maybe<(
            { __typename?: 'PurchaseReqEntity' }
            & Pick<PurchaseReqEntity, 'ID' | 'contactPerson'>
            & { deliveryAddress?: Maybe<(
              { __typename?: 'SiteEntity' }
              & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
            )> }
          )> }
        )> }
      )>> }
    )>, GRTNs: Array<(
      { __typename?: 'GoodReturnNoteEntity' }
      & Pick<GoodReturnNoteEntity, 'ID' | 'docNo' | 'docDate'>
      & { goodReturnNoteItem?: Maybe<Array<(
        { __typename?: 'GoodReturnNoteItemEntity' }
        & Pick<GoodReturnNoteItemEntity, 'ID' | 'outstandingQty' | 'returnedQty' | 'doItemID'>
        & { doItem?: Maybe<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'ID' | 'poItemID'>
          & { poItem?: Maybe<(
            { __typename?: 'POItemEntity' }
            & Pick<PoItemEntity, 'costItemID' | 'uomID' | 'remarks'>
            & { costItem?: Maybe<(
              { __typename?: 'CostItemEntity' }
              & Pick<CostItemEntity, 'ID' | 'name'>
            )>, uom?: Maybe<(
              { __typename?: 'UOMEntity' }
              & Pick<UomEntity, 'ID' | 'name' | 'code'>
            )> }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type GetGoodReturnNoteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  grtnStatus?: Maybe<PurchaseStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetGoodReturnNoteQuery = (
  { __typename?: 'Query' }
  & { getGoodReturnNote: Array<(
    { __typename?: 'GoodReturnNoteEntity' }
    & Pick<GoodReturnNoteEntity, 'ID' | 'docNo' | 'docDate' | 'transactionDate' | 'contractID' | 'description' | 'grtnStatus' | 'rejectionDate' | 'remarks' | 'docRef' | 'supplierID' | 'siteID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
      & { grn?: Maybe<Array<(
        { __typename?: 'GRNEntity' }
        & Pick<GrnEntity, 'ID' | 'doID' | 'docNo'>
      )>> }
    )>, goodReturnNoteItem?: Maybe<Array<(
      { __typename?: 'GoodReturnNoteItemEntity' }
      & Pick<GoodReturnNoteItemEntity, 'returnedQty' | 'doItemID' | 'replacement'>
      & { goodReturnNote?: Maybe<(
        { __typename?: 'GoodReturnNoteEntity' }
        & Pick<GoodReturnNoteEntity, 'docNo' | 'docDate' | 'docRef' | 'description' | 'supplierID' | 'siteID'>
        & { supplier?: Maybe<(
          { __typename?: 'SupplierEntity' }
          & Pick<SupplierEntity, 'name'>
        )>, site?: Maybe<(
          { __typename?: 'SiteEntity' }
          & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
        )> }
      )>, doItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'acceptedQty'>
        & { DO?: Maybe<(
          { __typename?: 'DOEntity' }
          & Pick<DoEntity, 'doNo' | 'docNo'>
        )>, poItem?: Maybe<(
          { __typename?: 'POItemEntity' }
          & Pick<PoItemEntity, 'remarks'>
          & { costItem?: Maybe<(
            { __typename?: 'CostItemEntity' }
            & Pick<CostItemEntity, 'name'>
            & { UOM?: Maybe<(
              { __typename?: 'UOMEntity' }
              & Pick<UomEntity, 'name' | 'code'>
            )> }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GetGoodReturnNoteItemQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetGoodReturnNoteItemQuery = (
  { __typename?: 'Query' }
  & { getGoodReturnNoteItem: Array<(
    { __typename?: 'GoodReturnNoteItemEntity' }
    & Pick<GoodReturnNoteItemEntity, 'ID' | 'grtnID' | 'doItemID' | 'returnedQty' | 'replacement'>
  )> }
);

export type GetGrtNsQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  contractID?: Maybe<Scalars['String']>;
  IDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetGrtNsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGRTNs'>
);

export type CreateUpdateGrtnMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  grtnItemInput: Array<GrtnItemInput> | GrtnItemInput;
  grtnInput: GoodReturnNoteInput;
}>;


export type CreateUpdateGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUpdateGRTN'>
);

export type DeleteGrtnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGRTN'>
);

export type RejectGrtnMutationVariables = Exact<{
  ID: Scalars['String'];
  rejectInput?: Maybe<GrtnRejectInput>;
}>;


export type RejectGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectGRTN'>
);

export type ApproveGrtnMutationVariables = Exact<{
  ID: Scalars['String'];
  grtnItemInput: Array<GrtnItemInput> | GrtnItemInput;
}>;


export type ApproveGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveGRTN'>
);

export type CancelCloseGrtnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: GrtnActionInput;
}>;


export type CancelCloseGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCloseGRTN'>
);

export type GetGoodReturnNoteCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  grtnStatus?: Maybe<PurchaseStatus>;
}>;


export type GetGoodReturnNoteCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGoodReturnNoteCount'>
);

export type GetGoodReturnNoteListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  grtnStatus?: Maybe<PurchaseStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetGoodReturnNoteListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGoodReturnNoteCount'>
  & { getGoodReturnNote: Array<(
    { __typename?: 'GoodReturnNoteEntity' }
    & Pick<GoodReturnNoteEntity, 'ID' | 'docNo' | 'docDate' | 'transactionDate' | 'contractID' | 'description' | 'grtnStatus' | 'rejectionDate' | 'remarks' | 'docRef' | 'supplierID' | 'siteID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
      & { grn?: Maybe<Array<(
        { __typename?: 'GRNEntity' }
        & Pick<GrnEntity, 'ID' | 'doID' | 'docNo'>
      )>> }
    )>, goodReturnNoteItem?: Maybe<Array<(
      { __typename?: 'GoodReturnNoteItemEntity' }
      & Pick<GoodReturnNoteItemEntity, 'returnedQty' | 'doItemID' | 'replacement'>
      & { goodReturnNote?: Maybe<(
        { __typename?: 'GoodReturnNoteEntity' }
        & Pick<GoodReturnNoteEntity, 'docNo' | 'docDate' | 'docRef' | 'description' | 'supplierID' | 'siteID'>
        & { supplier?: Maybe<(
          { __typename?: 'SupplierEntity' }
          & Pick<SupplierEntity, 'name'>
        )>, site?: Maybe<(
          { __typename?: 'SiteEntity' }
          & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
        )> }
      )>, doItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'acceptedQty'>
        & { DO?: Maybe<(
          { __typename?: 'DOEntity' }
          & Pick<DoEntity, 'doNo' | 'docNo'>
        )>, poItem?: Maybe<(
          { __typename?: 'POItemEntity' }
          & Pick<PoItemEntity, 'remarks'>
          & { costItem?: Maybe<(
            { __typename?: 'CostItemEntity' }
            & Pick<CostItemEntity, 'name'>
            & { UOM?: Maybe<(
              { __typename?: 'UOMEntity' }
              & Pick<UomEntity, 'name' | 'code'>
            )> }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type CreateNegotiatedSupplyMutationVariables = Exact<{
  negoItemInput: Array<NegotiatedSupplyItemInput> | NegotiatedSupplyItemInput;
  negoSupplyInput: NegotiatedSupplyInput;
}>;


export type CreateNegotiatedSupplyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createNegotiatedSupply'>
);

export type UpdateNegotiatedSupplyMutationVariables = Exact<{
  ID: Scalars['String'];
  negoItemInput: Array<NegotiatedSupplyItemInput> | NegotiatedSupplyItemInput;
  negoSupplyInput: NegotiatedSupplyInput;
}>;


export type UpdateNegotiatedSupplyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNegotiatedSupply'>
);

export type CancelNegotiatedSupplyMutationVariables = Exact<{
  ID: Scalars['String'];
  input: NegoSupplyCancellationInput;
}>;


export type CancelNegotiatedSupplyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelNegotiatedSupply'>
);

export type GetNegotiatedSupplyItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetNegotiatedSupplyItemQuery = (
  { __typename?: 'Query' }
  & { getNegotiatedSupplyItem: Array<(
    { __typename?: 'NegotiatedSupplyItemEntity' }
    & Pick<NegotiatedSupplyItemEntity, 'ID' | 'negotiatedSupplyID' | 'costItemID' | 'uomID' | 'remarks' | 'quantity' | 'balanceQty' | 'unitPrice'>
    & { costItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'ID' | 'name' | 'uomID'>
      & { costCategory?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name'>
        & { supplier?: Maybe<Array<(
          { __typename?: 'SupplierEntity' }
          & Pick<SupplierEntity, 'ID' | 'name' | 'associatedStatus'>
        )>> }
      )> }
    )>, poItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'ID' | 'orderedQty' | 'outstandingQty'>
    )>>, UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'code' | 'name'>
    )>, negoSupply?: Maybe<(
      { __typename?: 'NegotiatedSupplyEntity' }
      & Pick<NegotiatedSupplyEntity, 'ID' | 'supplierID' | 'contractID' | 'docNo' | 'negoSupplyDate' | 'startDate' | 'endDate' | 'cancellationDate' | 'cancellationRemarks' | 'status'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )> }
    )> }
  )> }
);

export type GetNegoSupplyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetNegoSupplyQuery = (
  { __typename?: 'Query' }
  & { getNegotiatedSupply: Array<(
    { __typename?: 'NegotiatedSupplyEntity' }
    & Pick<NegotiatedSupplyEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'supplierID' | 'contractID' | 'docNo' | 'negoSupplyDate' | 'remarks' | 'startDate' | 'endDate' | 'cancellationDate' | 'cancellationRemarks' | 'status'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, negoSupplyItem?: Maybe<Array<(
      { __typename?: 'NegotiatedSupplyItemEntity' }
      & Pick<NegotiatedSupplyItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'negotiatedSupplyID' | 'costItemID' | 'uomID' | 'remarks' | 'quantity' | 'balanceQty' | 'unitPrice'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'orderedQty' | 'outstandingQty'>
      )>>, poItemApproved: Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'orderedQty' | 'outstandingQty'>
      )>, UOM?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'code'>
      )> }
    )>> }
  )> }
);

export type GetNegoSupplyListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseStatus>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetNegoSupplyListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNegotiatedSupplyCount'>
  & { getNegotiatedSupply: Array<(
    { __typename?: 'NegotiatedSupplyEntity' }
    & Pick<NegotiatedSupplyEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'supplierID' | 'contractID' | 'docNo' | 'negoSupplyDate' | 'remarks' | 'startDate' | 'endDate' | 'cancellationDate' | 'cancellationRemarks' | 'status'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, negoSupplyItem?: Maybe<Array<(
      { __typename?: 'NegotiatedSupplyItemEntity' }
      & Pick<NegotiatedSupplyItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'negotiatedSupplyID' | 'costItemID' | 'uomID' | 'remarks' | 'quantity' | 'balanceQty' | 'unitPrice'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'orderedQty' | 'outstandingQty'>
      )>>, poItemApproved: Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'orderedQty' | 'outstandingQty'>
      )>, UOM?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'code'>
      )> }
    )>> }
  )> }
);

export type GetPurchaseSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetPurchaseSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'negoItemInfo' | 'validateContractStatus' | 'prInfoCount' | 'lastPRDate' | 'lastPODate' | 'rfqInfo' | 'rfqCount' | 'lastRFQDate' | 'lastPRDateOB' | 'poInfoCount' | 'lastPODateOB' | 'doCount' | 'lastDODate' | 'grtnCount' | 'lastGRTNDate' | 'verifyNegoSupplyStatus'>
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'contractDate' | 'customerID'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetPurchaseContractTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetPurchaseContractTitleQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'customerID' | 'title' | 'contractNo' | 'contractDate' | 'contractStatus' | 'purchasingSummary'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name'>
    )> }
  )> }
);

export type GetCostItemAndUomQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCostItemAndUomQuery = (
  { __typename?: 'Query' }
  & { getCostItem: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'costCategoryID' | 'commonStatus' | 'uomID' | 'name' | 'unitPrice' | 'onBehalf' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )>, getUOM: Array<(
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'name' | 'code' | 'commonStatus'>
  )> }
);

export type GetPurchaseBudgetQueryVariables = Exact<{
  contractID: Scalars['String'];
  prInfo: Array<PrInfo> | PrInfo;
}>;


export type GetPurchaseBudgetQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'purchaseBudget'>
);

export type PurchaseBudgetApprovalMutationVariables = Exact<{
  input: PurchaseBudgetInput;
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type PurchaseBudgetApprovalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'purchaseBudgetApproval'>
);

export type PurchaseBudgetAllocationQueryVariables = Exact<{
  contractID: Scalars['String'];
  prItemIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type PurchaseBudgetAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'purchaseBudgetAllocation'>
);

export type PurchaseWbsBudgetAllocationQueryVariables = Exact<{
  contractID: Scalars['String'];
  input: Array<PurchaseWbsBudgetAllocationInput> | PurchaseWbsBudgetAllocationInput;
}>;


export type PurchaseWbsBudgetAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'purchaseWbsBudgetAllocation'>
);

export type ActionOnPoStatusMutationVariables = Exact<{
  ID: Scalars['String'];
  input: PurchaseActionInput;
}>;


export type ActionOnPoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnPOStatus'>
);

export type GetSupplierQuotesQueryVariables = Exact<{
  costItemID: Scalars['String'];
  contractID: Scalars['String'];
  prItemID: Scalars['String'];
}>;


export type GetSupplierQuotesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierQuotes'>
);

export type GetSupplierForPurchaseOrderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierForPurchaseOrderQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'createdTs' | 'createdBy' | 'address' | 'contactNo' | 'associatedStatus'>
  )> }
);

export type GetUomExchangeListQueryVariables = Exact<{
  uomID: Scalars['String'];
}>;


export type GetUomExchangeListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUOMExchangeList'>
);

export type GetPurchaseOrderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  purchaseType?: Maybe<PurchaseType>;
  contractID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetPurchaseOrderQuery = (
  { __typename?: 'Query' }
  & { getPurchaseOrder: Array<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'ID' | 'accountID' | 'createdBy' | 'createdTs' | 'purchaseType' | 'contractID' | 'docNo' | 'docRef' | 'supplierID' | 'subcontractID' | 'siteID' | 'docDate' | 'docDueDate' | 'expectedDate' | 'instructions' | 'submissionComment' | 'totalAmt' | 'remarks' | 'rejectionDate' | 'cancelCloseDate' | 'cancelCloseRemark' | 'purchaseStatus' | 'prGenerated'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title' | 'ID' | 'description'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name'>
      )> }
    )>, deliveryAddress?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'address'>
    )>, contactPerson?: Maybe<(
      { __typename?: 'ContactPerson' }
      & Pick<ContactPerson, 'name' | 'contactNo'>
    )>, poItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'ID' | 'purchaseOrderID' | 'expectedDate' | 'prItemID' | 'taxSchemeID' | 'wbsID' | 'costItemID' | 'uomID' | 'negotiatedSupplyItemID' | 'rfqItemID' | 'taxAmt' | 'taxRate' | 'remarks' | 'markupAmt' | 'markupPerc' | 'discountAmt' | 'totalAmt' | 'unitPrice' | 'orderedQty' | 'outstandingQty'>
      & { prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ID' | 'markupPerc'>
        & { purchaseReq?: Maybe<(
          { __typename?: 'PurchaseReqEntity' }
          & Pick<PurchaseReqEntity, 'ID' | 'docNo' | 'contactPerson'>
          & { deliveryAddress?: Maybe<(
            { __typename?: 'SiteEntity' }
            & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
          )> }
        )>, costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'markupPerc' | 'name'>
        )> }
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'isClaimable'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxDate' | 'taxRate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'code' | 'unitPrice' | 'markupPerc'>
      )>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, doItem?: Maybe<Array<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'ID' | 'acceptedQty' | 'poItemID' | 'doItemSubmitted' | 'upToDateQty'>
        & { goodReturnNoteItem?: Maybe<Array<(
          { __typename?: 'GoodReturnNoteItemEntity' }
          & Pick<GoodReturnNoteItemEntity, 'ID' | 'grtnID' | 'doItemID' | 'returnedQty'>
        )>> }
      )>>, uomExchange?: Maybe<Array<(
        { __typename?: 'poUOMExchange' }
        & Pick<PoUomExchange, 'uomid' | 'uomname' | 'uomcode'>
      )>>, wbsBudgetDetail?: Maybe<(
        { __typename?: 'WbsBudgetDetailEntity' }
        & Pick<WbsBudgetDetailEntity, 'ID' | 'quantity' | 'unitPrice' | 'trackQty' | 'balanceQty' | 'balanceQtyPurchasing' | 'reservedAmt' | 'utilisedAmt' | 'balanceAmt'>
      )> }
    )>>, posConnection?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & { DO?: Maybe<(
        { __typename?: 'DOEntity' }
        & Pick<DoEntity, 'doNo' | 'doStatus'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetPoForBudgetByOneQueryVariables = Exact<{
  ID: Scalars['String'];
  contractID: Scalars['String'];
  purchaseType: PurchaseType;
}>;


export type GetPoForBudgetByOneQuery = (
  { __typename?: 'Query' }
  & { getPurchaseOrder: Array<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'ID' | 'accountID' | 'siteID' | 'docDate' | 'docNo' | 'prGenerated' | 'purchaseStatus' | 'instructions' | 'purchaseType' | 'remarks' | 'rejectionDate' | 'totalAmt' | 'submissionComment' | 'supplierID'>
    & { deliveryAddress?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'address'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, poItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'ID' | 'purchaseOrderID' | 'outstandingQty' | 'prItemID' | 'taxAmt' | 'taxRate' | 'remarks' | 'markupAmt' | 'costItemID' | 'totalAmt' | 'wbsID' | 'taxSchemeID'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )>, wbsBudgetDetail?: Maybe<(
        { __typename?: 'WbsBudgetDetailEntity' }
        & Pick<WbsBudgetDetailEntity, 'ID' | 'quantity' | 'unitPrice' | 'trackQty' | 'balanceQty' | 'balanceQtyPurchasing' | 'reservedAmt' | 'utilisedAmt' | 'balanceAmt'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'code' | 'unitPrice' | 'markupPerc'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'isClaimable'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxDate' | 'taxRate'>
        )> }
      )>, prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ID' | 'markupPerc'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'markupPerc' | 'name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetPOsQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  contractID?: Maybe<Scalars['String']>;
  IDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetPOsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOs'>
);

export type CreatePoMutationVariables = Exact<{
  poInput: Array<PurchaseOrderInput> | PurchaseOrderInput;
}>;


export type CreatePoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPO'>
);

export type CreateDirectPoMutationVariables = Exact<{
  input: DirectPoInput;
  poItemInput?: Maybe<Array<PoItemInput> | PoItemInput>;
}>;


export type CreateDirectPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDirectPO'>
);

export type UpdateDirectPoMutationVariables = Exact<{
  ID: Scalars['String'];
  input: DirectPoInput;
  poItemInput?: Maybe<Array<PoItemInput> | PoItemInput>;
}>;


export type UpdateDirectPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDirectPO'>
);

export type CancelPoMutationVariables = Exact<{
  input: PurchaseActionInput;
  ID: Scalars['String'];
}>;


export type CancelPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClosePO'>
);

export type DeletePoMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeletePoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePO'>
);

export type ClosePoCheckingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type ClosePoCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'closePOChecking'>
);

export type GetSupplierByPoStatusQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  purchaseStatus: PurchaseStatus;
}>;


export type GetSupplierByPoStatusQuery = (
  { __typename?: 'Query' }
  & { getSupplierByPOStatus: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'registrationNo' | 'email' | 'address' | 'currencyID' | 'creditTerm' | 'description' | 'platformAccess' | 'contactNo' | 'ratingType' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'associatedStatus' | 'bankDetails' | 'accountID'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, costCategory: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetPoListDocNoQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
}>;


export type GetPoListDocNoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOList'>
);

export type GetPoCategoryQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetPoCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOCategory'>
);

export type GetPoItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetPoItemQuery = (
  { __typename?: 'Query' }
  & { getPOItem: Array<(
    { __typename?: 'POItemEntity' }
    & Pick<PoItemEntity, 'ID' | 'orderedQty' | 'outstandingQty' | 'costItemID'>
    & { costItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'ID' | 'name' | 'code' | 'unitPrice' | 'markupPerc'>
    )> }
  )> }
);

export type GetPoItemByCategoryQueryVariables = Exact<{
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetPoItemByCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOItemByCategory'>
);

export type GetPoContractNameQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetPoContractNameQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOContractName'>
);

export type CheckingCancelClosePoQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type CheckingCancelClosePoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkingCancelClosePO'>
);

export type GetPoNoQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  supplierID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  isStatus?: Maybe<Scalars['Boolean']>;
}>;


export type GetPoNoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPONo'>
);

export type GetPoCostItemListQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  supplierID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetPoCostItemListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOCostItemList'>
);

export type GetPoSupplierListQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetPoSupplierListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOSupplierList'>
);

export type GetVerifyNegoSupplyQueryVariables = Exact<{
  costItemID: Scalars['String'];
  contractID: Scalars['String'];
  supplierID: Scalars['String'];
}>;


export type GetVerifyNegoSupplyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verifyNegoSupplyItem'>
);

export type GetPurchaseOrderCountQueryVariables = Exact<{
  purchaseType?: Maybe<PurchaseType>;
  contractID?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
}>;


export type GetPurchaseOrderCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPurchaseOrderCount'>
);

export type GetPurchaseOrderListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  purchaseType?: Maybe<PurchaseType>;
  contractID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetPurchaseOrderListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPurchaseOrderCount'>
  & { getPurchaseOrder: Array<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'ID' | 'accountID' | 'createdBy' | 'createdTs' | 'purchaseType' | 'contractID' | 'docNo' | 'docRef' | 'supplierID' | 'subcontractID' | 'siteID' | 'docDate' | 'docDueDate' | 'expectedDate' | 'instructions' | 'submissionComment' | 'totalAmt' | 'remarks' | 'rejectionDate' | 'cancelCloseDate' | 'cancelCloseRemark' | 'purchaseStatus' | 'prGenerated'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title' | 'ID' | 'description'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name'>
      )> }
    )>, deliveryAddress?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'address'>
    )>, contactPerson?: Maybe<(
      { __typename?: 'ContactPerson' }
      & Pick<ContactPerson, 'name' | 'contactNo'>
    )>, poItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'ID' | 'purchaseOrderID' | 'expectedDate' | 'prItemID' | 'taxSchemeID' | 'wbsID' | 'costItemID' | 'uomID' | 'negotiatedSupplyItemID' | 'rfqItemID' | 'taxAmt' | 'taxRate' | 'remarks' | 'markupAmt' | 'markupPerc' | 'discountAmt' | 'totalAmt' | 'unitPrice' | 'orderedQty' | 'outstandingQty'>
      & { prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ID' | 'markupPerc'>
        & { purchaseReq?: Maybe<(
          { __typename?: 'PurchaseReqEntity' }
          & Pick<PurchaseReqEntity, 'ID' | 'docNo' | 'contactPerson'>
          & { deliveryAddress?: Maybe<(
            { __typename?: 'SiteEntity' }
            & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
          )> }
        )>, costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'markupPerc' | 'name'>
        )> }
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'isClaimable'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxDate' | 'taxRate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'code' | 'unitPrice' | 'markupPerc'>
      )>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, doItem?: Maybe<Array<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'ID' | 'acceptedQty' | 'poItemID' | 'doItemSubmitted' | 'upToDateQty'>
        & { goodReturnNoteItem?: Maybe<Array<(
          { __typename?: 'GoodReturnNoteItemEntity' }
          & Pick<GoodReturnNoteItemEntity, 'ID' | 'grtnID' | 'doItemID' | 'returnedQty'>
        )>> }
      )>>, uomExchange?: Maybe<Array<(
        { __typename?: 'poUOMExchange' }
        & Pick<PoUomExchange, 'uomid' | 'uomname' | 'uomcode'>
      )>> }
    )>>, posConnection?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & { DO?: Maybe<(
        { __typename?: 'DOEntity' }
        & Pick<DoEntity, 'doNo' | 'doStatus'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetPurchaseReqQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetPurchaseReqQuery = (
  { __typename?: 'Query' }
  & { getPurchaseReq: Array<(
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'rejectionDate' | 'cancelCloseDate' | 'cancelCloseRemark' | 'remarks' | 'contractID' | 'docNo' | 'docDate' | 'subcontractID' | 'prDate' | 'expectedDate' | 'siteID' | 'contactPerson' | 'instructions' | 'submissionComment' | 'purchaseType' | 'requestedBy' | 'purchaseStatus'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'description'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name'>
      )> }
    )>, deliveryAddress?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'stateID' | 'contractID' | 'name' | 'address'>
    )>, prItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'ID' | 'purchaseReqID' | 'uomID' | 'wbsID' | 'costItemID' | 'expectedDate' | 'remarks' | 'markupPerc' | 'outstandingQty' | 'requestedQty'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
        & { UOM?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'code'>
        )> }
      )>, wbsBudgetDetail?: Maybe<(
        { __typename?: 'WbsBudgetDetailEntity' }
        & Pick<WbsBudgetDetailEntity, 'ID' | 'quantity' | 'unitPrice' | 'trackQty' | 'reservedQty' | 'utilisedQty' | 'balanceQty' | 'balanceQtyPurchasing'>
      )>, uomExchange?: Maybe<Array<(
        { __typename?: 'uomExchanges' }
        & Pick<UomExchanges, 'uomid' | 'uomname' | 'uomcode'>
      )>>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'prItemID'>
        & { doItem?: Maybe<Array<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'ID' | 'poItemID' | 'acceptedQty' | 'doItemAmt'>
        )>> }
      )>> }
    )>>, purchaseRFQItem: Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'ID' | 'uomID' | 'remarks' | 'purchaseReqID' | 'outstandingQty' | 'requestedQty' | 'costItemID'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
        & { UOM?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'code'>
        )> }
      )> }
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetPrForBudgetByOneQueryVariables = Exact<{
  ID: Scalars['String'];
  contractID: Scalars['String'];
  purchaseType: PurchaseType;
}>;


export type GetPrForBudgetByOneQuery = (
  { __typename?: 'Query' }
  & { getPRForBudgetByOne: (
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'ID' | 'docNo' | 'docDate' | 'prDate' | 'purchaseType' | 'instructions' | 'submissionComment' | 'requestedBy'>
    & { prItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'ID' | 'uomID' | 'wbsID' | 'expectedDate' | 'remarks' | 'markupPerc' | 'purchaseReqID' | 'outstandingQty' | 'requestedQty' | 'costItemID'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
        & { UOM?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'code'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'prItemID'>
        & { doItem?: Maybe<Array<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'ID' | 'poItemID' | 'acceptedQty' | 'doItemAmt'>
        )>> }
      )>>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, wbsBudgetDetail?: Maybe<(
        { __typename?: 'WbsBudgetDetailEntity' }
        & Pick<WbsBudgetDetailEntity, 'ID' | 'quantity' | 'unitPrice' | 'trackQty' | 'reservedQty' | 'utilisedQty' | 'balanceQty' | 'balanceQtyPurchasing'>
      )> }
    )>> }
  ) }
);

export type CreatePurchaseReqMutationVariables = Exact<{
  purchaseReqInput: PurchaseReqInput;
  prItemListInput: Array<PrItemInput> | PrItemInput;
}>;


export type CreatePurchaseReqMutation = (
  { __typename?: 'Mutation' }
  & { createPurchaseReq: (
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'ID' | 'docNo'>
  ) }
);

export type UpdatePurchaseRequestMutationVariables = Exact<{
  purchaseReqInput: PurchaseReqInput;
  prItemListInput: Array<PrItemInput> | PrItemInput;
  ID: Scalars['String'];
}>;


export type UpdatePurchaseRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePurchaseRequest'>
);

export type DeletePrMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeletePrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePR'>
);

export type ActionOnPrStatusMutationVariables = Exact<{
  input: PurchaseActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnPrStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnPRStatus'>
);

export type GetPrItemsbyIDsQueryVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
  poID?: Maybe<Scalars['String']>;
}>;


export type GetPrItemsbyIDsQuery = (
  { __typename?: 'Query' }
  & { GetPRItemsbyIDs: Array<(
    { __typename?: 'prItemInfo' }
    & Pick<PrItemInfo, 'prItemID' | 'costItemID' | 'costItemName' | 'uomID' | 'docDate' | 'uomCode' | 'unitPrice' | 'docNo' | 'requestedQty' | 'outstandingQty' | 'purchaseReqID' | 'negoSupplyItemID' | 'costCategoryID' | 'rfqItemID' | 'remarks' | 'prDate' | 'contactPerson' | 'expectedDate' | 'negotiatedItemStatus' | 'subcontractID' | 'siteID' | 'siteName' | 'wbsID' | 'address' | 'taxSchemeID' | 'markupPerc' | 'taxCode'>
    & { supplier?: Maybe<Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name' | 'address' | 'contactNo'>
    )>> }
  )> }
);

export type CancelClosePrMutationVariables = Exact<{
  ID: Scalars['String'];
  input: PurchaseActionInput;
}>;


export type CancelClosePrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClosePR'>
);

export type GetPurchaseReqWithPrQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetPurchaseReqWithPrQuery = (
  { __typename?: 'Query' }
  & { getPurchaseReqWithPR: Array<(
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'ID' | 'docNo'>
  )> }
);

export type GetPurchaseReqHeaderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetPurchaseReqHeaderQuery = (
  { __typename?: 'Query' }
  & { getPurchaseReq: Array<(
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'ID' | 'docNo'>
  )> }
);

export type GetOutstandingPrQueryVariables = Exact<{
  contractID: Scalars['String'];
  purchaseType: PurchaseType;
}>;


export type GetOutstandingPrQuery = (
  { __typename?: 'Query' }
  & { getOutstandingPR: Array<(
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'rejectionDate' | 'remarks' | 'contractID' | 'docNo' | 'subcontractID' | 'docDate' | 'expectedDate' | 'contactPerson' | 'instructions' | 'purchaseType' | 'requestedBy' | 'purchaseStatus'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title'>
    )>, deliveryAddress?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
    )>, prItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'ID' | 'purchaseReqID' | 'uomID' | 'wbsID' | 'costItemID' | 'expectedDate' | 'markupPerc' | 'requestedQty' | 'outstandingQty' | 'remarks'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
        & { UOM?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'code'>
        )> }
      )>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'prItemID'>
        & { doItem?: Maybe<Array<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'ID' | 'poItemID' | 'acceptedQty' | 'doItemAmt'>
        )>> }
      )>> }
    )>>, purchaseRFQItem: Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'ID' | 'uomID' | 'remarks' | 'purchaseReqID' | 'outstandingQty' | 'requestedQty' | 'costItemID'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
        & { UOM?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'code'>
        )> }
      )> }
    )> }
  )> }
);

export type GetOutstandingPrItemsQueryVariables = Exact<{
  contractID: Scalars['String'];
  purchaseType: PurchaseType;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetOutstandingPrItemsQuery = (
  { __typename?: 'Query' }
  & { getOutstandingPRItems: Array<(
    { __typename?: 'PRItemEntity' }
    & Pick<PrItemEntity, 'ID' | 'uomID' | 'wbsID' | 'costItemID' | 'outstandingQty' | 'requestedQty' | 'description' | 'remarks' | 'negoSupplySelected' | 'rfqSubmissionSelected' | 'purchaseReqID' | 'markupPerc'>
    & { poItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'ID' | 'prItemID'>
      & { doItem?: Maybe<Array<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'ID' | 'poItemID' | 'acceptedQty' | 'doItemAmt'>
      )>> }
    )>>, uom?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'taxSchemeID' | 'markupPerc'>
      & { UOM?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'code'>
      )> }
    )>, purchaseReq?: Maybe<(
      { __typename?: 'PurchaseReqEntity' }
      & Pick<PurchaseReqEntity, 'ID' | 'contractID' | 'docNo' | 'docDate' | 'expectedDate' | 'contactPerson' | 'instructions' | 'purchaseType' | 'requestedBy' | 'purchaseStatus' | 'subcontractID' | 'siteID'>
      & { subcontract?: Maybe<(
        { __typename?: 'SubcontractEntity' }
        & Pick<SubcontractEntity, 'ID' | 'subcontractorID' | 'title'>
        & { subcontractorDetail?: Maybe<(
          { __typename?: 'SubcontractorEntity' }
          & Pick<SubcontractorEntity, 'ID' | 'name'>
        )> }
      )>, deliveryAddress?: Maybe<(
        { __typename?: 'SiteEntity' }
        & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
      )>, purchaseRFQItem: Array<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ID' | 'uomID' | 'remarks' | 'purchaseReqID' | 'outstandingQty' | 'requestedQty' | 'costItemID'>
        & { uom?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'name' | 'code'>
        )>, costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
          & { UOM?: Maybe<(
            { __typename?: 'UOMEntity' }
            & Pick<UomEntity, 'ID' | 'code'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type CheckingCancelClosePrQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type CheckingCancelClosePrQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkingCancelClosePR'>
);

export type GetPurchaseReqCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
}>;


export type GetPurchaseReqCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPurchaseReqCount'>
);

export type GetPurchaseItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  isInventory?: Maybe<Scalars['Boolean']>;
}>;


export type GetPurchaseItemQuery = (
  { __typename?: 'Query' }
  & { getCostItem: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'name' | 'markupPerc' | 'uomID'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )> }
  )> }
);

export type GetPurchaseReqListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetPurchaseReqListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPurchaseReqCount'>
  & { getPurchaseReq: Array<(
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'rejectionDate' | 'cancelCloseDate' | 'cancelCloseRemark' | 'remarks' | 'contractID' | 'docNo' | 'docDate' | 'subcontractID' | 'prDate' | 'expectedDate' | 'siteID' | 'contactPerson' | 'instructions' | 'submissionComment' | 'purchaseType' | 'requestedBy' | 'purchaseStatus'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'description'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name'>
      )> }
    )>, deliveryAddress?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'stateID' | 'contractID' | 'name' | 'address'>
    )>, prItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'ID' | 'uomID' | 'wbsID' | 'expectedDate' | 'remarks' | 'markupPerc' | 'purchaseReqID' | 'outstandingQty' | 'requestedQty' | 'costItemID'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'prItemID'>
        & { doItem?: Maybe<Array<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'ID' | 'poItemID' | 'acceptedQty' | 'doItemAmt'>
        )>> }
      )>>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, wbsBudgetDetail?: Maybe<(
        { __typename?: 'WbsBudgetDetailEntity' }
        & Pick<WbsBudgetDetailEntity, 'ID' | 'quantity' | 'unitPrice' | 'trackQty' | 'reservedQty' | 'utilisedQty' | 'balanceQty' | 'balanceQtyPurchasing'>
      )>, uomExchange?: Maybe<Array<(
        { __typename?: 'uomExchanges' }
        & Pick<UomExchanges, 'uomid' | 'uomname' | 'uomcode'>
      )>>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
        & { UOM?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'code'>
        )> }
      )> }
    )>>, purchaseRFQItem: Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'ID' | 'uomID' | 'remarks' | 'purchaseReqID' | 'outstandingQty' | 'requestedQty' | 'costItemID'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
        & { UOM?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'code'>
        )> }
      )> }
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateRfqMutationVariables = Exact<{
  rfqInput: RfqInput;
  rfqItemInput: Array<RfqItemInput> | RfqItemInput;
}>;


export type CreateRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createRFQ'>
);

export type UpdateRfqMutationVariables = Exact<{
  rfqInput: RfqInput;
  rfqItemInput: Array<RfqItemInput> | RfqItemInput;
  ID: Scalars['String'];
}>;


export type UpdateRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRFQ'>
);

export type SubmitRfqQuoteMutationVariables = Exact<{
  input: RfqSubmissionInput;
}>;


export type SubmitRfqQuoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitRFQQuote'>
);

export type GetRfqListingQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<RfqStatus>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetRfqListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRFQCount'>
  & { getRFQ: Array<(
    { __typename?: 'RFQEntity' }
    & Pick<RfqEntity, 'ID' | 'createdTs' | 'createdBy' | 'accountID' | 'purchaseReqID' | 'contractID' | 'description' | 'docNo' | 'docDate' | 'expectedDate' | 'rfqStatus' | 'totalRfqSubmission'>
    & { purchaseReq?: Maybe<(
      { __typename?: 'PurchaseReqEntity' }
      & Pick<PurchaseReqEntity, 'ID' | 'docNo'>
    )>, invitedSupplier?: Maybe<Array<(
      { __typename?: 'RFQSupplierInvitationEntity' }
      & Pick<RfqSupplierInvitationEntity, 'ID' | 'supplierID' | 'rfqID'>
    )>>, supplier: Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'creditTerm' | 'name' | 'ratingType' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'name' | 'contactNo' | 'email' | 'designation'>
      )> }
    )>, rfqSupplierSubmission?: Maybe<Array<(
      { __typename?: 'RFQSupplierSubmissionEntity' }
      & Pick<RfqSupplierSubmissionEntity, 'ID' | 'rfqID' | 'supplierID'>
    )>> }
  )> }
);

export type GetEvaluationSelectionRfqQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type GetEvaluationSelectionRfqQuery = (
  { __typename?: 'Query' }
  & { getRFQ: Array<(
    { __typename?: 'RFQEntity' }
    & Pick<RfqEntity, 'ID' | 'contractID' | 'description' | 'docNo' | 'docDate' | 'expectedDate' | 'rfqStatus' | 'purchaseReqID'>
    & { purchaseReq?: Maybe<(
      { __typename?: 'PurchaseReqEntity' }
      & Pick<PurchaseReqEntity, 'ID' | 'docNo'>
    )>, rfqItem?: Maybe<Array<(
      { __typename?: 'RFQItemEntity' }
      & Pick<RfqItemEntity, 'ID' | 'accountID' | 'rfqID' | 'prItemID' | 'quantity' | 'uomID'>
      & { rfqSubmissionItem?: Maybe<Array<(
        { __typename?: 'RFQSubmissionItemEntity' }
        & Pick<RfqSubmissionItemEntity, 'ID' | 'rfqSupplierSubmissionID' | 'selected'>
      )>>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'name' | 'code'>
      )>, prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ID' | 'description' | 'remarks'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'name' | 'latestPurchase'>
          & { poItem?: Maybe<Array<(
            { __typename?: 'POItemEntity' }
            & Pick<PoItemEntity, 'ID'>
            & { purchaseOrder?: Maybe<(
              { __typename?: 'PurchaseOrderEntity' }
              & Pick<PurchaseOrderEntity, 'ID' | 'docDate'>
            )> }
          )>> }
        )> }
      )> }
    )>>, supplier: Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'creditTerm' | 'name' | 'ratingType' | 'contactNo' | 'email'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'name' | 'contactNo' | 'email' | 'designation'>
      )>, latestSubmittedQuotation?: Maybe<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'ID' | 'creditTerm' | 'quotationNo' | 'docAmt' | 'submittedDate' | 'remarks'>
        & { rfqSubmissionItem: Array<(
          { __typename?: 'RFQSubmissionItemEntity' }
          & Pick<RfqSubmissionItemEntity, 'ID' | 'selected' | 'rfqItemID' | 'rfqSupplierSubmissionID' | 'cheapest' | 'unitPrice' | 'remarks'>
        )> }
      )> }
    )> }
  )> }
);

export type GetRfqQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type GetRfqQuery = (
  { __typename?: 'Query' }
  & { getRFQ: Array<(
    { __typename?: 'RFQEntity' }
    & Pick<RfqEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'accountID' | 'purchaseReqID' | 'contractID' | 'description' | 'docNo' | 'docDate' | 'expectedDate' | 'rfqStatus' | 'cancelCloseDate' | 'cancelCloseRemark'>
    & { purchaseReq?: Maybe<(
      { __typename?: 'PurchaseReqEntity' }
      & Pick<PurchaseReqEntity, 'docNo' | 'contactPerson'>
    )>, rfqSupplierSubmission?: Maybe<Array<(
      { __typename?: 'RFQSupplierSubmissionEntity' }
      & Pick<RfqSupplierSubmissionEntity, 'ID' | 'rfqID' | 'supplierID'>
    )>>, rfqItem?: Maybe<Array<(
      { __typename?: 'RFQItemEntity' }
      & Pick<RfqItemEntity, 'ID' | 'accountID' | 'rfqID' | 'prItemID' | 'quantity' | 'uomID'>
      & { rfqSubmissionItem?: Maybe<Array<(
        { __typename?: 'RFQSubmissionItemEntity' }
        & Pick<RfqSubmissionItemEntity, 'ID' | 'rfqSupplierSubmissionID' | 'selected'>
      )>>, prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ID' | 'costItemID' | 'wbsID' | 'expectedDate' | 'description' | 'remarks'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'costCategoryID' | 'name'>
        )>, wbs?: Maybe<(
          { __typename?: 'WBSEntity' }
          & Pick<WbsEntity, 'name' | 'parentName'>
        )> }
      )>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'name' | 'code'>
      )>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'rfqItemID' | 'purchaseOrderID'>
        & { purchaseOrder?: Maybe<(
          { __typename?: 'PurchaseOrderEntity' }
          & Pick<PurchaseOrderEntity, 'ID' | 'docNo' | 'docDate'>
        )> }
      )>> }
    )>>, supplier: Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'creditTerm' | 'name' | 'ratingType' | 'contactNo' | 'email'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'name' | 'contactNo' | 'email' | 'designation'>
      )>, latestSubmittedQuotation?: Maybe<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'ID' | 'quotationNo' | 'docAmt' | 'submittedDate' | 'remarks' | 'creditTerm'>
        & { rfqSubmissionItem: Array<(
          { __typename?: 'RFQSubmissionItemEntity' }
          & Pick<RfqSubmissionItemEntity, 'ID' | 'selected' | 'rfqItemID' | 'cheapest' | 'unitPrice' | 'remarks'>
        )> }
      )>, rfqSubmission: Array<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'ID' | 'docAmt' | 'quotationNo' | 'submittedDate' | 'effectiveDate' | 'expiryDate' | 'remarks' | 'creditTerm'>
        & { rfqSubmissionItem: Array<(
          { __typename?: 'RFQSubmissionItemEntity' }
          & Pick<RfqSubmissionItemEntity, 'ID' | 'rfqItemID' | 'unitPrice' | 'remarks'>
          & { rfqSubmission: (
            { __typename?: 'RFQSupplierSubmissionEntity' }
            & Pick<RfqSupplierSubmissionEntity, 'docAmt'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type GetRfqItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
}>;


export type GetRfqItemQuery = (
  { __typename?: 'Query' }
  & { getRFQItem: Array<(
    { __typename?: 'RFQItemEntity' }
    & Pick<RfqItemEntity, 'ID' | 'accountID' | 'rfqID' | 'uomID' | 'quantity'>
    & { rfq?: Maybe<(
      { __typename?: 'RFQEntity' }
      & Pick<RfqEntity, 'contractID' | 'description' | 'docNo' | 'docDate' | 'expectedDate' | 'rfqStatus'>
    )> }
  )> }
);

export type GetRfqSupplierInvitedQueryVariables = Exact<{
  ID: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetRfqSupplierInvitedQuery = (
  { __typename?: 'Query' }
  & { getRFQ: Array<(
    { __typename?: 'RFQEntity' }
    & Pick<RfqEntity, 'ID' | 'docDate' | 'docNo' | 'expectedDate' | 'description'>
    & { rfqItem?: Maybe<Array<(
      { __typename?: 'RFQItemEntity' }
      & Pick<RfqItemEntity, 'ID'>
      & { prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ID'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'name'>
          & { costCategory?: Maybe<(
            { __typename?: 'CostCategoryEntity' }
            & Pick<CostCategoryEntity, 'ID' | 'name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GetRfqSupplierSubmissionQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetRfqSupplierSubmissionQuery = (
  { __typename?: 'Query' }
  & { getRFQSupplierSubmission: Array<(
    { __typename?: 'RFQSupplierSubmissionEntity' }
    & Pick<RfqSupplierSubmissionEntity, 'ID' | 'supplierID' | 'creditTerm' | 'submittedDate' | 'effectiveDate' | 'expiryDate' | 'quotationDate' | 'expectedDate' | 'quotationNo' | 'quotationStatus' | 'docAmt' | 'rfqID' | 'remarks'>
    & { rfq?: Maybe<(
      { __typename?: 'RFQEntity' }
      & Pick<RfqEntity, 'description' | 'rfqStatus'>
    )>, supplier: (
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name' | 'ID'>
      & { rfq: Array<(
        { __typename?: 'RFQEntity' }
        & Pick<RfqEntity, 'description'>
        & { rfqItem?: Maybe<Array<(
          { __typename?: 'RFQItemEntity' }
          & Pick<RfqItemEntity, 'quantity'>
          & { prItem?: Maybe<(
            { __typename?: 'PRItemEntity' }
            & { costItem?: Maybe<(
              { __typename?: 'CostItemEntity' }
              & Pick<CostItemEntity, 'name'>
            )>, uom?: Maybe<(
              { __typename?: 'UOMEntity' }
              & Pick<UomEntity, 'code'>
            )> }
          )> }
        )>> }
      )> }
    ), rfqSubmissionItem: Array<(
      { __typename?: 'RFQSubmissionItemEntity' }
      & Pick<RfqSubmissionItemEntity, 'ID' | 'rfqItemID' | 'unitPrice' | 'remarks'>
      & { rfqItem?: Maybe<(
        { __typename?: 'RFQItemEntity' }
        & Pick<RfqItemEntity, 'quantity'>
        & { prItem?: Maybe<(
          { __typename?: 'PRItemEntity' }
          & { costItem?: Maybe<(
            { __typename?: 'CostItemEntity' }
            & Pick<CostItemEntity, 'name'>
          )>, uom?: Maybe<(
            { __typename?: 'UOMEntity' }
            & Pick<UomEntity, 'code'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type DeleteRfqSupplierSubmissionMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteRfqSupplierSubmissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRFQSupplierSubmission'>
);

export type ActiveRfqSubmissionMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type ActiveRfqSubmissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activeRfqSubmission'>
);

export type GetPrItemByPridForRfqQueryVariables = Exact<{
  ID: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetPrItemByPridForRfqQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPRItemByPRIDForRFQ'>
);

export type GetSuggestedSupplierQueryVariables = Exact<{
  rfqID: Scalars['String'];
}>;


export type GetSuggestedSupplierQuery = (
  { __typename?: 'Query' }
  & { getSuggestedSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'name' | 'ID' | 'ratingType'>
    & { costCategory: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type InviteSupplierMutationVariables = Exact<{
  rfqID: Scalars['String'];
  supplierIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type InviteSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteSupplier'>
);

export type UninviteSupplierMutationVariables = Exact<{
  rfqID: Scalars['String'];
  supplierID: Scalars['String'];
}>;


export type UninviteSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uninviteSupplier'>
);

export type SubmitEvaluationSelectionMutationVariables = Exact<{
  evalSelectInput: Array<EvalSelectInput> | EvalSelectInput;
  rfqInput: RfqEvalInput;
}>;


export type SubmitEvaluationSelectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitEvaluationSelection'>
);

export type GetInvitedSupplierQueryVariables = Exact<{
  rfqID: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetInvitedSupplierQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getInvitedSupplier'>
);

export type RejectRfqMutationVariables = Exact<{
  ID: Scalars['String'];
  rfqRejectInput: RfqRejectInput;
}>;


export type RejectRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectRFQ'>
);

export type CancelCloseRfqMutationVariables = Exact<{
  ID: Scalars['String'];
  input: RfqActionInput;
}>;


export type CancelCloseRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCloseRFQ'>
);

export type DeleteRfqMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRFQ'>
);

export type GetRfqCountQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<RfqStatus>;
}>;


export type GetRfqCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRFQCount'>
);

export type GetProjectOpportunityQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  ProjectOpportunityStatus?: Maybe<ProjectOpportunityStatus>;
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetProjectOpportunityQuery = (
  { __typename?: 'Query' }
  & { getProjectOpportunity: Array<(
    { __typename?: 'ProjectOpportunityEntity' }
    & Pick<ProjectOpportunityEntity, 'ID' | 'activityLog' | 'customerID' | 'title' | 'description' | 'location' | 'personInChargeID' | 'amount' | 'closingDateTime' | 'remarks' | 'status'>
    & { customer?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'email' | 'contactNo'>
    )>, personInCharge?: Maybe<(
      { __typename?: 'UserEntity' }
      & Pick<UserEntity, 'name' | 'email' | 'contactNo'>
    )> }
  )> }
);

export type CreateProjectOpportunityMutationVariables = Exact<{
  input: ProjectOpportunityInput;
}>;


export type CreateProjectOpportunityMutation = (
  { __typename?: 'Mutation' }
  & { createProjectOpportunity: (
    { __typename?: 'ProjectOpportunityEntity' }
    & Pick<ProjectOpportunityEntity, 'ID'>
  ) }
);

export type UpdateProjectOpportunityMutationVariables = Exact<{
  input: ProjectOpportunityInput;
}>;


export type UpdateProjectOpportunityMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectOpportunity: (
    { __typename?: 'ProjectOpportunityEntity' }
    & Pick<ProjectOpportunityEntity, 'ID'>
  ) }
);

export type DeleteProjectOpportunityMutationVariables = Exact<{
  ID: ProjectOpportunityDeleteInput;
}>;


export type DeleteProjectOpportunityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectOpportunity'>
);

export type CreateProjectOpportunityActivityLogsMutationVariables = Exact<{
  input: ProjectOpportunityActivityLogInput;
  projectOpportunityID: Scalars['String'];
}>;


export type CreateProjectOpportunityActivityLogsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProjectOpportunityActivityLogs'>
);

export type GetProjectOpportunitySubmissionQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  projectOpportunityID?: Maybe<Scalars['String']>;
}>;


export type GetProjectOpportunitySubmissionQuery = (
  { __typename?: 'Query' }
  & { getProjectOpportunitySubmission: Array<(
    { __typename?: 'ProjectOpportunitySubmissionEntity' }
    & Pick<ProjectOpportunitySubmissionEntity, 'ID' | 'projectOpportunityID' | 'submissionDate' | 'tenderAmt' | 'remarks'>
  )> }
);

export type CreateProjectOpportunitySubmissionMutationVariables = Exact<{
  input: ProjectOpportunitySubmissionInput;
}>;


export type CreateProjectOpportunitySubmissionMutation = (
  { __typename?: 'Mutation' }
  & { createProjectOpportunitySubmission: (
    { __typename?: 'ProjectOpportunitySubmissionEntity' }
    & Pick<ProjectOpportunitySubmissionEntity, 'ID' | 'projectOpportunityID'>
  ) }
);

export type UpdateProjectOpportunitySubmissionMutationVariables = Exact<{
  input: ProjectOpportunitySubmissionInput;
}>;


export type UpdateProjectOpportunitySubmissionMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectOpportunitySubmission: (
    { __typename?: 'ProjectOpportunitySubmissionEntity' }
    & Pick<ProjectOpportunitySubmissionEntity, 'ID' | 'projectOpportunityID'>
  ) }
);

export type DeleteProjectOpportunitySubmissionMutationVariables = Exact<{
  ID: ProjectOpportunitySubmissionDeleteInput;
}>;


export type DeleteProjectOpportunitySubmissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectOpportunitySubmission'>
);

export type GetProjectOpportunityAttachmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  projectOpportunityID?: Maybe<Scalars['String']>;
}>;


export type GetProjectOpportunityAttachmentQuery = (
  { __typename?: 'Query' }
  & { getProjectOpportunityAttachment: Array<(
    { __typename?: 'ProjectOpportunityAttachmentEntity' }
    & Pick<ProjectOpportunityAttachmentEntity, 'ID' | 'projectOpportunityID' | 'title' | 'date'>
  )> }
);

export type CreateProjectOpportunityAttachmentMutationVariables = Exact<{
  input: ProjectOpportunityAttachmentInput;
}>;


export type CreateProjectOpportunityAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { createProjectOpportunityAttachment: (
    { __typename?: 'ProjectOpportunityAttachmentEntity' }
    & Pick<ProjectOpportunityAttachmentEntity, 'ID' | 'projectOpportunityID'>
  ) }
);

export type UpdateProjectOpportunityAttachmentMutationVariables = Exact<{
  input: ProjectOpportunityAttachmentInput;
}>;


export type UpdateProjectOpportunityAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectOpportunityAttachment: (
    { __typename?: 'ProjectOpportunityAttachmentEntity' }
    & Pick<ProjectOpportunityAttachmentEntity, 'ID' | 'projectOpportunityID'>
  ) }
);

export type DeleteProjectOpportunityAttachmentMutationVariables = Exact<{
  ID: ProjectOpportunityAttachmentDeleteInput;
}>;


export type DeleteProjectOpportunityAttachmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectOpportunityAttachment'>
);

export type GetSubcontractOsDebitDocumentQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetSubcontractOsDebitDocumentQuery = (
  { __typename?: 'Query' }
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'claimDocNo' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt' | 'contractID' | 'subcontractID' | 'transactionType'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'creditTerm'>
    )> }
  )>, getSubcontractMiscInvoice: Array<(
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt' | 'contractID' | 'subcontractID' | 'transactionType'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'creditTerm'>
    )> }
  )>, getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt' | 'contractID' | 'subcontractID' | 'transactionType'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'creditTerm'>
    )> }
  )>, getCNtoSubcon: Array<(
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt' | 'contractID' | 'subcontractID' | 'transactionType'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'creditTerm'>
    )> }
  )> }
);

export type GetSubcontractSubcontractorPdfQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractSubcontractorPdfQuery = (
  { __typename?: 'Query' }
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'creditTerm' | 'contractID' | 'taxSchemeID' | 'progressRetentionPerc' | 'multipleTrade' | 'startDate' | 'endDate' | 'subcontractNo' | 'subcontractSum' | 'revisedSubcontractSum' | 'subcontractVOSum' | 'maxRetentionSum' | 'maxRetentionPerc'>
    & { contact?: Maybe<Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'contactNo'>
    )>> }
  )>, getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'address'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'contactNo'>
    )> }
  )> }
);

export type GetDebitAllocationSubconQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  creditDebitID: Scalars['String'];
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetDebitAllocationSubconQuery = (
  { __typename?: 'Query' }
  & { getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getCNtoSubcon: Array<(
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getSubcontractMiscInvoice: Array<(
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'outstandingAmt' | 'outstandingCertifiedAmt' | 'taxRate' | 'docRef' | 'docNo' | 'claimDocNo' | 'docDate' | 'allocatedAmt' | 'nonEditableAllocation' | 'description'>
  )>, cancelledSubconClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'outstandingAmt' | 'outstandingCertifiedAmt' | 'taxRate' | 'docRef' | 'docNo' | 'claimDocNo' | 'docDate' | 'allocatedAmt' | 'nonEditableAllocation' | 'description'>
  )>, getSubcontractRefund: Array<(
    { __typename?: 'SubcontractRefundEntity' }
    & Pick<SubcontractRefundEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )> }
);

export type GetCreditAllocationSubconQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  creditDebitID: Scalars['String'];
  transactionStatus?: Maybe<TransactionStatus>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetCreditAllocationSubconQuery = (
  { __typename?: 'Query' }
  & { getSubcontractCN: Array<(
    { __typename?: 'SubcontractCNEntity' }
    & Pick<SubcontractCnEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getDNtoSubcon: Array<(
    { __typename?: 'DNtoSubconEntity' }
    & Pick<DNtoSubconEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getSubcontractAdvance: Array<(
    { __typename?: 'SubcontractAdvanceEntity' }
    & Pick<SubcontractAdvanceEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getSubcontractPayment: Array<(
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )> }
);

export type MakeSubconAllocationMutationVariables = Exact<{
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeSubconAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'makeSubconAllocation'>
);

export type ResetSubcontractAllocationMutationVariables = Exact<{
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
}>;


export type ResetSubcontractAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetSubcontractAllocation'>
);

export type GetSubconAdvanceAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetSubconAdvanceAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconAdvanceAllocation'>
);

export type GetSubconPaymentAllocationQueryVariables = Exact<{
  debitID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<Scalars['String']>;
}>;


export type GetSubconPaymentAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconPaymentAllocation'>
);

export type GetSubconRefundAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetSubconRefundAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconRefundAllocation'>
);

export type GetSubconCnAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetSubconCnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconCNAllocation'>
);

export type GetSubconDnAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetSubconDnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconDNAllocation'>
);

export type GetDNtoSubconAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetDNtoSubconAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDNtoSubconAllocation'>
);

export type GetCNtoSubconAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetCNtoSubconAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCNtoSubconAllocation'>
);

export type GetSubcontractClaimQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconClaimsInfo'>
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'createdTs' | 'createdBy' | 'docNo' | 'modTs' | 'modBy' | 'claimDocNo' | 'isRetention' | 'docRef' | 'description' | 'docDate' | 'docDueDate' | 'docAmt' | 'docRefID' | 'docRefTable' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'grossAmt' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'submittedDate' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt' | 'previousMosAmt' | 'submittedMosAmt' | 'currentMosAmt' | 'retentionAmt' | 'submittedRetentionAmt' | 'balanceRetentionAmt' | 'submittedDayWorkAmt' | 'currentDayWorkAmt' | 'balanceAdvanceAmt' | 'currentAdvRecoupmentAmt' | 'currentDeductionAmt' | 'submittedDeductionAmt' | 'balanceDeductionAmt' | 'transactionStatus' | 'contractID' | 'subcontractorID' | 'subcontractID' | 'rejectionDate' | 'rejectionRemarks' | 'cancellationDate' | 'cancellationRemarks' | 'voDisplayAmt' | 'outstandingAmt' | 'adjustmentInfo'>
    & { taxScheme?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'contractNo'>
    )>, subcontractor?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'ID' | 'name'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractNo' | 'startDate' | 'endDate' | 'subcontractSum' | 'revisedSubcontractSum' | 'subcontractVOSum' | 'maxRetentionSum' | 'maxRetentionPerc'>
    )>, subcontractClaimAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimAllocationEntity' }
      & Pick<SubcontractClaimAllocationEntity, 'ID' | 'subcontractClaimID' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'subcontractCostWbsID'>
      & { subcontractCostWbs?: Maybe<(
        { __typename?: 'SubcontractCostWbsEntity' }
        & Pick<SubcontractCostWbsEntity, 'ID' | 'subcontractID' | 'costCategoryID' | 'tradeAmt' | 'previousAmt'>
        & { costCategory?: Maybe<(
          { __typename?: 'CostCategoryEntity' }
          & Pick<CostCategoryEntity, 'ID' | 'name'>
        )>, subcontractVOCostWbs?: Maybe<Array<(
          { __typename?: 'SubcontractVOCostWbsEntity' }
          & Pick<SubcontractVoCostWbsEntity, 'subcontractVOID' | 'previousTradeVoAmt' | 'submissionAmt' | 'approvedAmt'>
          & { subcontractVO?: Maybe<(
            { __typename?: 'SubcontractVOEntity' }
            & Pick<SubcontractVoEntity, 'docRef' | 'submissionAmt' | 'approvedAmt'>
          )> }
        )>> }
      )> }
    )>>, subcontractClaimVOAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimVOAllocationEntity' }
      & Pick<SubcontractClaimVoAllocationEntity, 'ID' | 'subcontractClaimID' | 'previousVOClaimAmt' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'subcontractVOID'>
      & { subcontractVO?: Maybe<(
        { __typename?: 'SubcontractVOEntity' }
        & Pick<SubcontractVoEntity, 'ID' | 'docNo' | 'docRef'>
      )> }
    )>>, subcontractClaimVOCostWbsAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimVOCostWbsAllocationEntity' }
      & Pick<SubcontractClaimVoCostWbsAllocationEntity, 'ID' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt' | 'subcontractVOCostWbsID'>
    )>>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetSubcontractClaimPdfQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractClaimPdfQuery = (
  { __typename?: 'Query' }
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'createdTs' | 'createdBy' | 'docNo' | 'modTs' | 'modBy' | 'claimDocNo' | 'isRetention' | 'docRef' | 'description' | 'docDate' | 'docDueDate' | 'docAmt' | 'docRefID' | 'docRefTable' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'grossAmt' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'submittedDate' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt' | 'previousMosAmt' | 'submittedMosAmt' | 'currentMosAmt' | 'retentionAmt' | 'submittedRetentionAmt' | 'balanceRetentionAmt' | 'submittedDayWorkAmt' | 'currentDayWorkAmt' | 'balanceAdvanceAmt' | 'currentAdvRecoupmentAmt' | 'currentDeductionAmt' | 'submittedDeductionAmt' | 'balanceDeductionAmt' | 'transactionStatus' | 'contractID' | 'subcontractorID' | 'subcontractID' | 'claimDetailCostWBS' | 'rejectionDate' | 'rejectionRemarks' | 'cancellationDate' | 'cancellationRemarks' | 'voDisplayAmt' | 'outstandingAmt' | 'adjustmentInfo'>
    & { taxScheme?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'contractNo'>
    )>, subcontractor?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'ID' | 'name'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractNo' | 'startDate' | 'endDate' | 'subcontractSum' | 'revisedSubcontractSum' | 'subcontractVOSum' | 'maxRetentionSum' | 'maxRetentionPerc'>
    )>, subcontractClaimAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimAllocationEntity' }
      & Pick<SubcontractClaimAllocationEntity, 'ID' | 'subcontractClaimID' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'subcontractCostWbsID'>
      & { subcontractCostWbs?: Maybe<(
        { __typename?: 'SubcontractCostWbsEntity' }
        & Pick<SubcontractCostWbsEntity, 'ID' | 'subcontractID' | 'costCategoryID' | 'tradeAmt' | 'previousAmt'>
        & { costCategory?: Maybe<(
          { __typename?: 'CostCategoryEntity' }
          & Pick<CostCategoryEntity, 'ID' | 'name'>
        )>, subcontractVOCostWbs?: Maybe<Array<(
          { __typename?: 'SubcontractVOCostWbsEntity' }
          & Pick<SubcontractVoCostWbsEntity, 'subcontractVOID' | 'previousTradeVoAmt' | 'submissionAmt' | 'approvedAmt'>
          & { subcontractVO?: Maybe<(
            { __typename?: 'SubcontractVOEntity' }
            & Pick<SubcontractVoEntity, 'docRef' | 'submissionAmt' | 'approvedAmt'>
          )> }
        )>> }
      )> }
    )>>, subcontractClaimVOAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimVOAllocationEntity' }
      & Pick<SubcontractClaimVoAllocationEntity, 'ID' | 'subcontractClaimID' | 'previousVOClaimAmt' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'subcontractVOID'>
      & { subcontractVO?: Maybe<(
        { __typename?: 'SubcontractVOEntity' }
        & Pick<SubcontractVoEntity, 'ID' | 'docNo' | 'docRef'>
      )> }
    )>>, subcontractClaimVOCostWbsAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimVOCostWbsAllocationEntity' }
      & Pick<SubcontractClaimVoCostWbsAllocationEntity, 'ID' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt' | 'subcontractVOCostWbsID'>
    )>>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )> }
  )> }
);

export type GetSubconProgressClaimQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetSubconProgressClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconProgressClaimsInfo'>
  & { getSubconProgressClaimCount: Query['getSubcontractClaimCount'] }
  & { getSubconProgressClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'createdTs' | 'createdBy' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'contractID' | 'subcontractorID' | 'subcontractID' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'grossAmt' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'contractNo'>
    )>, subcontractor?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'ID' | 'name' | 'address' | 'contactNo'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractNo' | 'startDate' | 'endDate' | 'subcontractSum' | 'revisedSubcontractSum' | 'subcontractVOSum' | 'maxRetentionSum' | 'progressRetentionPerc' | 'creditTerm'>
    )>, subcontractClaimAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimAllocationEntity' }
      & Pick<SubcontractClaimAllocationEntity, 'ID' | 'subcontractClaimID' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'subcontractCostWbsID'>
    )>>, subcontractClaimVOAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimVOAllocationEntity' }
      & Pick<SubcontractClaimVoAllocationEntity, 'ID' | 'subcontractClaimID' | 'previousVOClaimAmt' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'subcontractVOID'>
    )>>, subcontractClaimVOCostWbsAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimVOCostWbsAllocationEntity' }
      & Pick<SubcontractClaimVoCostWbsAllocationEntity, 'ID' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt' | 'subcontractVOCostWbsID'>
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )> }
);

export type GetSubconRetentionClaimQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetSubconRetentionClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconRetentionClaimsInfo'>
  & { getSubconRetentionClaimCount: Query['getSubcontractClaimCount'] }
  & { getSubconRetentionClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'createdTs' | 'createdBy' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'contractID' | 'subcontractorID' | 'subcontractID' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, subcontractor?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'ID' | 'name'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )> }
);

export type SubmitSubconRetentionClaimMutationVariables = Exact<{
  input: SubconClaimRetentionInput;
}>;


export type SubmitSubconRetentionClaimMutation = (
  { __typename?: 'Mutation' }
  & { submitSubconRetentionClaim: (
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'claimDocNo'>
  ) }
);

export type ApproveSubconRetentionClaimMutationVariables = Exact<{
  input: SubconClaimRetentionInput;
}>;


export type ApproveSubconRetentionClaimMutation = (
  { __typename?: 'Mutation' }
  & { approveSubconRetentionClaim: (
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'docNo'>
  ) }
);

export type CreateSubcontractClaimMutationVariables = Exact<{
  input: SubcontractClaimInput;
}>;


export type CreateSubcontractClaimMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractClaim: (
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'accountID'>
  ) }
);

export type UpdateSubcontractClaimMutationVariables = Exact<{
  input: SubcontractClaimInput;
}>;


export type UpdateSubcontractClaimMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractClaim: (
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'accountID' | 'ID'>
  ) }
);

export type UpdateSubconClaimDetailsMutationVariables = Exact<{
  input: ClaimDetailsInput;
  ID: Scalars['String'];
}>;


export type UpdateSubconClaimDetailsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSubconClaimDetails'>
);

export type SubmitSubconClaimMutationVariables = Exact<{
  input: SubcontractClaimInput;
  deductionLedger?: Maybe<Array<LedgerInput> | LedgerInput>;
  claimAlloc?: Maybe<Array<SubconClaimAllocInput> | SubconClaimAllocInput>;
  voAlloc?: Maybe<Array<SubconClaimVoAllocInput> | SubconClaimVoAllocInput>;
  voCostWbsAlloc?: Maybe<Array<SubconClaimVoCostWbsAllocInput> | SubconClaimVoCostWbsAllocInput>;
}>;


export type SubmitSubconClaimMutation = (
  { __typename?: 'Mutation' }
  & { submitSubconClaim: (
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'docNo'>
  ) }
);

export type ApproveSubconClaimMutationVariables = Exact<{
  input: SubcontractClaimInput;
  claimAlloc?: Maybe<Array<SubconClaimAllocInput> | SubconClaimAllocInput>;
  voAlloc?: Maybe<Array<SubconClaimVoAllocInput> | SubconClaimVoAllocInput>;
  voCostWbsAlloc?: Maybe<Array<SubconClaimVoCostWbsAllocInput> | SubconClaimVoCostWbsAllocInput>;
}>;


export type ApproveSubconClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveSubconClaim'>
);

export type CancelSubconClaimMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
  retentionClaim?: Maybe<Scalars['Boolean']>;
}>;


export type CancelSubconClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconClaim'>
);

export type RejectSubconClaimMutationVariables = Exact<{
  ID: Scalars['String'];
  input: RejectInput;
  retentionClaim?: Maybe<Scalars['Boolean']>;
}>;


export type RejectSubconClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectSubconClaim'>
);

export type DeleteSubcontractClaimMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractClaim'>
);

export type GetSubconRetClaimAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetSubconRetClaimAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconRetClaimAllocation'>
);

export type GetSubcontractAdvanceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractAdvanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconAdvancesInfo'>
  & { getSubcontractAdvance: Array<(
    { __typename?: 'SubcontractAdvanceEntity' }
    & Pick<SubcontractAdvanceEntity, 'ID' | 'createdTs' | 'createdBy' | 'isGlExport' | 'isRecoupment' | 'modTs' | 'modBy' | 'cancellationRemarks' | 'cancellationDate' | 'rejectionRemarks' | 'rejectionDate' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetSubcontractAdvanceListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractAdvanceListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconAdvancesInfo' | 'getSubcontractAdvanceCount'>
  & { getSubcontractAdvance: Array<(
    { __typename?: 'SubcontractAdvanceEntity' }
    & Pick<SubcontractAdvanceEntity, 'ID' | 'createdTs' | 'createdBy' | 'isGlExport' | 'isRecoupment' | 'modTs' | 'modBy' | 'cancellationRemarks' | 'cancellationDate' | 'rejectionRemarks' | 'rejectionDate' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateSubcontractAdvanceMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type CreateSubcontractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractAdvance: (
    { __typename?: 'SubcontractAdvanceEntity' }
    & Pick<SubcontractAdvanceEntity, 'ID' | 'docNo'>
  ) }
);

export type UpdateSubcontractAdvanceMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type UpdateSubcontractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractAdvance: (
    { __typename?: 'SubcontractAdvanceEntity' }
    & Pick<SubcontractAdvanceEntity, 'ID' | 'docNo'>
  ) }
);

export type GetSubcontractPaymentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractPaymentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconPaymentInfo'>
  & { getSubcontractPayment: Array<(
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'paymentTo' | 'isGlExport' | 'cancellationRemarks' | 'cancellationDate' | 'rejectionRemarks' | 'rejectionDate' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetSubcontractPaymentListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractPaymentListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconPaymentInfo' | 'getSubcontractPaymentCount'>
  & { getSubcontractPayment: Array<(
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'paymentTo' | 'isGlExport' | 'cancellationRemarks' | 'cancellationDate' | 'rejectionRemarks' | 'rejectionDate' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateSubcontractPaymentMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type CreateSubcontractPaymentMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractPayment: (
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID'>
  ) }
);

export type UpdateSubcontractPaymentMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type UpdateSubcontractPaymentMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractPayment: (
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID'>
  ) }
);

export type GetSubcontractRefundQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractRefundQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconRefundsInfo'>
  & { getSubcontractRefund: Array<(
    { __typename?: 'SubcontractRefundEntity' }
    & Pick<SubcontractRefundEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'cancellationRemarks' | 'cancellationDate' | 'rejectionRemarks' | 'rejectionDate' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetSubcontractRefundListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractRefundListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconRefundsInfo' | 'getSubcontractRefundCount'>
  & { getSubcontractRefund: Array<(
    { __typename?: 'SubcontractRefundEntity' }
    & Pick<SubcontractRefundEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'cancellationRemarks' | 'cancellationDate' | 'rejectionRemarks' | 'rejectionDate' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateSubcontractRefundMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type CreateSubcontractRefundMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractRefund: (
    { __typename?: 'SubcontractRefundEntity' }
    & Pick<SubcontractRefundEntity, 'ID' | 'docNo'>
  ) }
);

export type UpdateSubcontractRefundMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type UpdateSubcontractRefundMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractRefund: (
    { __typename?: 'SubcontractRefundEntity' }
    & Pick<SubcontractRefundEntity, 'ID' | 'docNo'>
  ) }
);

export type GetSubcontractPaymentTableQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractPaymentTableQuery = (
  { __typename?: 'Query' }
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'subcontractID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt'>
  )>, getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'subcontractID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt'>
  )> }
);

export type MakeSubconPaymentMutationVariables = Exact<{
  input: SubcontractPaymentInput;
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeSubconPaymentMutation = (
  { __typename?: 'Mutation' }
  & { makeSubconPayment: (
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID' | 'docNo'>
  ) }
);

export type CancelSubconAdvanceMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubconAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconAdvance'>
);

export type CancelSubconPaymentMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubconPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconPayment'>
);

export type CancelSubconRefundMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubconRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconRefund'>
);

export type DeleteSubcontractAdvanceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractAdvance'>
);

export type DeleteSubcontractRefundMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractRefund'>
);

export type DeleteSubcontractPaymentMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractPayment'>
);

export type GetSubcontractAdvanceCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetSubcontractAdvanceCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractAdvanceCount'>
);

export type GetSubcontractPaymentCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetSubcontractPaymentCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractPaymentCount'>
);

export type GetSubcontractRefundCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetSubcontractRefundCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractRefundCount'>
);

export type GetSubcontractMiscInvoiceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractMiscInvoiceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconMiscInvInfo'>
  & { getSubcontractMiscInvoice: Array<(
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'docNo' | 'docRefID' | 'isDaywork' | 'isGlExport' | 'docRef' | 'cancellationDate' | 'cancellationRemarks' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'subcontractorID' | 'profileInfo' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'submissionComment'>
    & { subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
      & { subcontractClaim?: Maybe<Array<(
        { __typename?: 'SubcontractClaimEntity' }
        & Pick<SubcontractClaimEntity, 'ID' | 'docRefID' | 'claimDocNo'>
      )>> }
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, subcontractMiscInvoiceItem?: Maybe<Array<(
      { __typename?: 'SubcontractMiscInvoiceItemEntity' }
      & Pick<SubcontractMiscInvoiceItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type GetSubcontractMiscInvoiceListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractMiscInvoiceListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconMiscInvInfo' | 'getSubcontractMiscInvoiceCount'>
  & { getSubcontractMiscInvoice: Array<(
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'docNo' | 'docRefID' | 'isDaywork' | 'isGlExport' | 'docRef' | 'cancellationDate' | 'cancellationRemarks' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'subcontractorID' | 'profileInfo' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'submissionComment'>
    & { subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
      & { subcontractClaim?: Maybe<Array<(
        { __typename?: 'SubcontractClaimEntity' }
        & Pick<SubcontractClaimEntity, 'ID' | 'docRefID' | 'claimDocNo'>
      )>> }
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, subcontractMiscInvoiceItem?: Maybe<Array<(
      { __typename?: 'SubcontractMiscInvoiceItemEntity' }
      & Pick<SubcontractMiscInvoiceItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'taxRate' | 'taxAmt' | 'remarks'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type CreateSubcontractMiscInvoiceMutationVariables = Exact<{
  input: SubcontractTransactionWithGlItemInput;
}>;


export type CreateSubcontractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractMiscInvoice: (
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type UpdateSubcontractMiscInvoiceMutationVariables = Exact<{
  input: SubcontractTransactionWithGlItemInput;
}>;


export type UpdateSubcontractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractMiscInvoice: (
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type GetSubcontractDnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractDnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconDNInfo'>
  & { getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'createdTs' | 'createdBy' | 'isGlExport' | 'modBy' | 'docNo' | 'docRef' | 'cancellationDate' | 'cancellationRemarks' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'submissionComment' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, subcontractDNItem?: Maybe<Array<(
      { __typename?: 'SubcontractDNItemEntity' }
      & Pick<SubcontractDnItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type GetSubcontractDnListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractDnListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconDNInfo' | 'getSubcontractDNCount'>
  & { getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'createdTs' | 'createdBy' | 'isGlExport' | 'modBy' | 'docNo' | 'docRef' | 'cancellationDate' | 'cancellationRemarks' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'submissionComment' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, subcontractDNItem?: Maybe<Array<(
      { __typename?: 'SubcontractDNItemEntity' }
      & Pick<SubcontractDnItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type CreateSubcontractDnMutationVariables = Exact<{
  input: SubcontractTransactionWithGlItemInput;
}>;


export type CreateSubcontractDnMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractDN: (
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'accountID' | 'ID'>
  ) }
);

export type UpdateSubcontractDnMutationVariables = Exact<{
  input: SubcontractTransactionWithGlItemInput;
}>;


export type UpdateSubcontractDnMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractDN: (
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'accountID' | 'ID'>
  ) }
);

export type GetSubcontractCnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractCnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconCNInfo'>
  & { getSubcontractCN: Array<(
    { __typename?: 'SubcontractCNEntity' }
    & Pick<SubcontractCnEntity, 'ID' | 'createdTs' | 'createdBy' | 'isGlExport' | 'modTs' | 'modBy' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'submissionComment' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, subcontractCNItem?: Maybe<Array<(
      { __typename?: 'SubcontractCNItemEntity' }
      & Pick<SubcontractCnItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type GetSubcontractCnListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractCnListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconCNInfo' | 'getSubcontractCNCount'>
  & { getSubcontractCN: Array<(
    { __typename?: 'SubcontractCNEntity' }
    & Pick<SubcontractCnEntity, 'ID' | 'createdTs' | 'createdBy' | 'isGlExport' | 'modTs' | 'modBy' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'submissionComment' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, subcontractCNItem?: Maybe<Array<(
      { __typename?: 'SubcontractCNItemEntity' }
      & Pick<SubcontractCnItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type CreateSubcontractCnMutationVariables = Exact<{
  input: SubcontractTransactionWithGlItemInput;
}>;


export type CreateSubcontractCnMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractCN: (
    { __typename?: 'SubcontractCNEntity' }
    & Pick<SubcontractCnEntity, 'accountID' | 'ID'>
  ) }
);

export type UpdateSubcontractCnMutationVariables = Exact<{
  input: SubcontractTransactionWithGlItemInput;
}>;


export type UpdateSubcontractCnMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractCN: (
    { __typename?: 'SubcontractCNEntity' }
    & Pick<SubcontractCnEntity, 'accountID' | 'ID'>
  ) }
);

export type GetDNtoSubconQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetDNtoSubconQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DNtoSubconInfo'>
  & { getDNtoSubcon: Array<(
    { __typename?: 'DNtoSubconEntity' }
    & Pick<DNtoSubconEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'chargeToRevenue' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'contractID' | 'subcontractID' | 'subcontractorID' | 'outstandingAmt' | 'submissionComment' | 'docRefID' | 'docRefTable' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title' | 'siteID'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, DNtoSubconItem?: Maybe<Array<(
      { __typename?: 'DNtoSubconItemEntity' }
      & Pick<DNtoSubconItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'revenueCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type GetDNtoSubconListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetDNtoSubconListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DNtoSubconInfo' | 'getDNtoSubconCount'>
  & { getDNtoSubcon: Array<(
    { __typename?: 'DNtoSubconEntity' }
    & Pick<DNtoSubconEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'chargeToRevenue' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'submissionComment' | 'docRefID' | 'docRefTable' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title' | 'siteID'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, DNtoSubconItem?: Maybe<Array<(
      { __typename?: 'DNtoSubconItemEntity' }
      & Pick<DNtoSubconItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'revenueCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type CreateDNtoSubconMutationVariables = Exact<{
  input: SubcontractTransactionWithGlItemInput;
}>;


export type CreateDNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & { createDNtoSubcon: (
    { __typename?: 'DNtoSubconEntity' }
    & Pick<DNtoSubconEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type UpdateDNtoSubconMutationVariables = Exact<{
  input: SubcontractTransactionWithGlItemInput;
}>;


export type UpdateDNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & { updateDNtoSubcon: (
    { __typename?: 'DNtoSubconEntity' }
    & Pick<DNtoSubconEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type GetCNtoSubconQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetCNtoSubconQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CNtoSubconInfo'>
  & { getCNtoSubcon: Array<(
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'chargeToRevenue' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'contractID' | 'subcontractID' | 'subcontractorID' | 'outstandingAmt' | 'submissionComment' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, CNtoSubconItem?: Maybe<Array<(
      { __typename?: 'CNtoSubconItemEntity' }
      & Pick<CNtoSubconItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'revenueCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type GetCNtoSubconListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetCNtoSubconListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CNtoSubconInfo' | 'getCNtoSubconCount'>
  & { getCNtoSubcon: Array<(
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'chargeToRevenue' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'submissionComment' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, CNtoSubconItem?: Maybe<Array<(
      { __typename?: 'CNtoSubconItemEntity' }
      & Pick<CNtoSubconItemEntity, 'ID' | 'costItemID' | 'costCategoryID' | 'revenueCategoryID' | 'taxSchemeID' | 'wbsID' | 'costClass' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, revenueCategory?: Maybe<(
        { __typename?: 'RevenueCategoryEntity' }
        & Pick<RevenueCategoryEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type CreateCNtoSubconMutationVariables = Exact<{
  input: SubcontractTransactionWithGlItemInput;
}>;


export type CreateCNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & { createCNtoSubcon: (
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type UpdateCNtoSubconMutationVariables = Exact<{
  input: SubcontractTransactionWithGlItemInput;
}>;


export type UpdateCNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & { updateCNtoSubcon: (
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type CancelSubcontractMiscInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubcontractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubcontractMiscInvoice'>
);

export type CancelSubconDnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubconDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconDN'>
);

export type CancelSubconCnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubconCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconCN'>
);

export type CancelDNtoSubconMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelDNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelDNtoSubcon'>
);

export type CancelCNtoSubconMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelCNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCNtoSubcon'>
);

export type DeleteSubcontractMiscInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractMiscInvoice'>
);

export type DeleteSubcontractCnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractCN'>
);

export type DeleteSubcontractDnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractDN'>
);

export type DeleteDNtoSubconMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteDNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDNtoSubcon'>
);

export type DeleteCNtoSubconMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteCNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCNtoSubcon'>
);

export type GetSubconAdjustmentQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetSubconAdjustmentQuery = (
  { __typename?: 'Query' }
  & { getCNtoSubcon: Array<(
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'ID' | 'docAmt' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )>, getDNtoSubconRevenue: Array<(
    { __typename?: 'DNtoSubconEntity' }
    & Pick<DNtoSubconEntity, 'ID' | 'docAmt' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'deductionTypeID' | 'cancelledClaimAmt'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )>, getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'docAmt' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )>, getSubcontractCN: Array<(
    { __typename?: 'SubcontractCNEntity' }
    & Pick<SubcontractCnEntity, 'ID' | 'docAmt' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )> }
);

export type GetSubcontractMiscInvoiceCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetSubcontractMiscInvoiceCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractMiscInvoiceCount'>
);

export type GetSubcontractDnCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetSubcontractDnCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractDNCount'>
);

export type GetSubcontractCnCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetSubcontractCnCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractCNCount'>
);

export type GetDNtoSubconCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetDNtoSubconCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDNtoSubconCount'>
);

export type GetCNtoSubconCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetCNtoSubconCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCNtoSubconCount'>
);

export type GetContractSubconTenderQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetContractSubconTenderQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'contractSum' | 'revisedContractSum' | 'contractStatus' | 'subcontractTenderSummary'>
    & { subcontractTenderDetail?: Maybe<Array<(
      { __typename?: 'SubcontractTenderEntity' }
      & Pick<SubcontractTenderEntity, 'ID'>
    )>> }
  )> }
);

export type GetSubcontractTenderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractTenderQuery = (
  { __typename?: 'Query' }
  & { getSubcontractTender: Array<(
    { __typename?: 'SubcontractTenderEntity' }
    & Pick<SubcontractTenderEntity, 'ID' | 'contractID' | 'title' | 'budgetAmt' | 'description' | 'tenderDate' | 'startDate' | 'closeDate' | 'startTime' | 'endTime' | 'modTs' | 'siteID' | 'costCategoryID' | 'refNo' | 'subconTenderStatus'>
    & { subcontract?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'accountID' | 'title' | 'description' | 'subcontractorID' | 'subcontractTenderID' | 'subcontractNo'>
    )>>, subconInvitation?: Maybe<Array<(
      { __typename?: 'SubcontractorInvitationEntity' }
      & Pick<SubcontractorInvitationEntity, 'ID' | 'subcontractorID' | 'subcontractTenderID'>
      & { subcontractor?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name' | 'ratingType' | 'pkkDetails' | 'cidbDetails'>
        & { subconRfqSubmission?: Maybe<Array<(
          { __typename?: 'SubconRFQSubmissionEntity' }
          & Pick<SubconRfqSubmissionEntity, 'ID' | 'quotationAmt'>
        )>>, subcontract?: Maybe<Array<(
          { __typename?: 'SubcontractEntity' }
          & Pick<SubcontractEntity, 'subcontractStatus'>
        )>> }
      )> }
    )>>, subconRfqSubmission?: Maybe<Array<(
      { __typename?: 'SubconRFQSubmissionEntity' }
      & Pick<SubconRfqSubmissionEntity, 'ID' | 'subcontractTenderID' | 'subcontractorID' | 'quotationNo' | 'quotationAmt' | 'submissionDate'>
    )>> }
  )> }
);

export type GetSubcontractTenderSubmissionQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractTenderSubmissionQuery = (
  { __typename?: 'Query' }
  & { getSubcontractTender: Array<(
    { __typename?: 'SubcontractTenderEntity' }
    & Pick<SubcontractTenderEntity, 'ID' | 'title' | 'budgetAmt' | 'description' | 'tenderDate' | 'startDate' | 'closeDate' | 'costCategoryID' | 'modTs' | 'refNo'>
    & { subconInvitation?: Maybe<Array<(
      { __typename?: 'SubcontractorInvitationEntity' }
      & Pick<SubcontractorInvitationEntity, 'ID' | 'subcontractorID' | 'subcontractTenderID'>
      & { subcontractor?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name'>
      )> }
    )>>, subconRfqSubmission?: Maybe<Array<(
      { __typename?: 'SubconRFQSubmissionEntity' }
      & Pick<SubconRfqSubmissionEntity, 'subcontractTenderID' | 'subcontractorID' | 'quotationNo' | 'quotationAmt' | 'submissionDate'>
    )>> }
  )> }
);

export type CreateSubcontractTenderMutationVariables = Exact<{
  input: SubcontractTenderInput;
}>;


export type CreateSubcontractTenderMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractTender: (
    { __typename?: 'SubcontractTenderEntity' }
    & Pick<SubcontractTenderEntity, 'ID'>
  ) }
);

export type UpdateSubcontractTenderMutationVariables = Exact<{
  input: SubcontractTenderInput;
}>;


export type UpdateSubcontractTenderMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractTender: (
    { __typename?: 'SubcontractTenderEntity' }
    & Pick<SubcontractTenderEntity, 'ID'>
  ) }
);

export type InviteSubcontractorMutationVariables = Exact<{
  subcontractorIDs: Array<Scalars['String']> | Scalars['String'];
  subcontractTenderID: Scalars['String'];
}>;


export type InviteSubcontractorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteSubcontractor'>
);

export type UnInviteSubcontractorMutationVariables = Exact<{
  subcontractorID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
}>;


export type UnInviteSubcontractorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unInviteSubcontractor'>
);

export type DeleteSubcontractTenderMutationVariables = Exact<{
  input: SubcontractTenderInput;
}>;


export type DeleteSubcontractTenderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractTender'>
);

export type CreateSubconRfqSubmissionMutationVariables = Exact<{
  input: SubconRfqSubmissionInput;
}>;


export type CreateSubconRfqSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { createSubconRFQSubmission: (
    { __typename?: 'SubconRFQSubmissionEntity' }
    & Pick<SubconRfqSubmissionEntity, 'ID'>
  ) }
);

export type GetSubconRfqSubmissionQueryVariables = Exact<{
  subcontractorInvitationID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSubconRfqSubmissionQuery = (
  { __typename?: 'Query' }
  & { getSubconRFQSubmission: Array<(
    { __typename?: 'SubconRFQSubmissionEntity' }
    & Pick<SubconRfqSubmissionEntity, 'ID' | 'submissionDate' | 'quotationAmt' | 'quotationNo' | 'remarks'>
  )> }
);

export type TenderToSubconGenerationMutationVariables = Exact<{
  ID: Scalars['String'];
  input: SubconTenderGenerationInput;
}>;


export type TenderToSubconGenerationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'tenderToSubconGeneration'>
);

export type GetSubcontractorInvitationQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorInvitationQuery = (
  { __typename?: 'Query' }
  & { getSubcontractorInvitation: Array<(
    { __typename?: 'SubcontractorInvitationEntity' }
    & Pick<SubcontractorInvitationEntity, 'ID' | 'accountID' | 'subcontractorID' | 'subcontractTenderID' | 'remarks'>
  )> }
);

export type GetSuggestedSubcontractorQueryVariables = Exact<{
  subcontractTenderID: Scalars['String'];
}>;


export type GetSuggestedSubcontractorQuery = (
  { __typename?: 'Query' }
  & { getSuggestedSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'name' | 'ID' | 'registrationNo' | 'description' | 'cidbDetails' | 'ratingType' | 'pkkDetails'>
    & { subcontract?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractSum'>
    )>> }
  )> }
);

export type GetInvitedSubcontractorQueryVariables = Exact<{
  contractID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
}>;


export type GetInvitedSubcontractorQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getInvitedSubcontractor'>
);

export type GetSubcontractorSubmenuSummaryQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorSubmenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconProfileDate' | 'subconLedgersInfo'>
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'activeSubcontract'>
  )> }
);

export type GetSubcontractorCompanyAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getSubcontractorCompanyAssignment: Array<(
    { __typename?: 'SubcontractorCompanyAssignmentEntity' }
    & Pick<SubcontractorCompanyAssignmentEntity, 'ID' | 'companyID' | 'subcontractorID'>
    & { subcontractor?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'ID' | 'taxSchemeID' | 'name' | 'registrationNo' | 'email' | 'description'>
    )> }
  )> }
);

export type AssignSubconCompanyMutationVariables = Exact<{
  subcontractorID: Scalars['String'];
  companyID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignSubconCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignSubconCompany'>
);

export type UnassignSubconCompanyMutationVariables = Exact<{
  subcontractorID: Scalars['String'];
  companyID: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnassignSubconCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignSubconCompany'>
);

export type GetSubscriptionCurrencyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionCurrencyQuery = (
  { __typename?: 'Query' }
  & { getSubscriptionCurrency: Array<(
    { __typename?: 'SubscriptionCurrencyType' }
    & Pick<SubscriptionCurrencyType, 'ID' | 'name' | 'code' | 'symbol' | 'commonStatus'>
  )> }
);

export type GetBatchPaymentHeaderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetBatchPaymentHeaderQuery = (
  { __typename?: 'Query' }
  & { getBatchPaymentHeader: Array<(
    { __typename?: 'BatchPaymentHeaderEntity' }
    & Pick<BatchPaymentHeaderEntity, 'ID' | 'description' | 'bankAccountID' | 'submittedDate' | 'submittedBy' | 'paidDate' | 'paidBy' | 'totalAmt'>
    & { batchPaymentDetail?: Maybe<Array<(
      { __typename?: 'BatchPaymentDetailEntity' }
      & Pick<BatchPaymentDetailEntity, 'ID' | 'batchPaymentHeaderID' | 'docRefTable' | 'docRefID' | 'associateRefTable' | 'associateRefID' | 'paymentAmt' | 'APPaymentID'>
    )>> }
  )> }
);

export type GetBatchPaymentDetailQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetBatchPaymentDetailQuery = (
  { __typename?: 'Query' }
  & { getBatchPaymentDetail: Array<(
    { __typename?: 'BatchPaymentDetailEntity' }
    & Pick<BatchPaymentDetailEntity, 'ID' | 'batchPaymentHeaderID' | 'docRefTable' | 'docRefID' | 'associateRefTable' | 'associateRefID' | 'paymentAmt' | 'APPaymentID'>
  )> }
);

export type GetBatchPaymentDetailbySupplierQueryVariables = Exact<{
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetBatchPaymentDetailbySupplierQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBatchPaymentDetailbySupplier'>
);

export type GetBatchPaymentbyStatusQueryVariables = Exact<{
  batchPaymentID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  StatusArr?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
}>;


export type GetBatchPaymentbyStatusQuery = (
  { __typename?: 'Query' }
  & { getBatchPaymentbyStatus: Array<(
    { __typename?: 'BatchPaymentHeaderEntity' }
    & Pick<BatchPaymentHeaderEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'description' | 'companyID' | 'receiptStatus' | 'bankAccountID' | 'submittedDate' | 'submittedBy' | 'paidDate' | 'paidBy' | 'totalAmt' | 'batchPaymentAssocDetail' | 'batchPaymentDocNum'>
    & { bankAccount?: Maybe<(
      { __typename?: 'BankAccountEntity' }
      & Pick<BankAccountEntity, 'ID' | 'name' | 'branch' | 'accountNo'>
    )>, batchPaymentDetail?: Maybe<Array<(
      { __typename?: 'BatchPaymentDetailEntity' }
      & Pick<BatchPaymentDetailEntity, 'ID' | 'batchPaymentHeaderID' | 'docRefTable' | 'docRefID' | 'associateRefTable' | 'associateRefID' | 'paymentAmt' | 'APPaymentID'>
    )>> }
  )> }
);

export type GetBatchPaymentbyStatusListingQueryVariables = Exact<{
  batchPaymentID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  StatusArr?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetBatchPaymentbyStatusListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBatchPaymentbyStatusCount'>
  & { getBatchPaymentbyStatus: Array<(
    { __typename?: 'BatchPaymentHeaderEntity' }
    & Pick<BatchPaymentHeaderEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'description' | 'submittedDate' | 'submittedBy' | 'paidDate' | 'paidBy' | 'totalAmt' | 'batchPaymentAssocDetail' | 'batchPaymentDocNum'>
    & { batchPaymentDetail?: Maybe<Array<(
      { __typename?: 'BatchPaymentDetailEntity' }
      & Pick<BatchPaymentDetailEntity, 'ID'>
    )>> }
  )> }
);

export type CreateBatchPaymentHeaderMutationVariables = Exact<{
  input: BatchPaymentHeaderInput;
}>;


export type CreateBatchPaymentHeaderMutation = (
  { __typename?: 'Mutation' }
  & { createBatchPaymentHeader: (
    { __typename?: 'BatchPaymentHeaderEntity' }
    & Pick<BatchPaymentHeaderEntity, 'ID'>
  ) }
);

export type CreateBatchPaymentDetailMutationVariables = Exact<{
  input: BatchPaymentDetailInput;
}>;


export type CreateBatchPaymentDetailMutation = (
  { __typename?: 'Mutation' }
  & { createBatchPaymentDetail: (
    { __typename?: 'BatchPaymentDetailEntity' }
    & Pick<BatchPaymentDetailEntity, 'ID'>
  ) }
);

export type SubmitBatchPaymentMutationVariables = Exact<{
  headerInput: BatchPaymentHeaderInput;
  detailInput: Array<BatchPaymentDetailInput> | BatchPaymentDetailInput;
}>;


export type SubmitBatchPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitBatchPayment'>
);

export type PayBatchPaymentMutationVariables = Exact<{
  headerID: Scalars['String'];
  detailIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type PayBatchPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'payBatchPayment'>
);

export type ConfirmBatchPaymentMutationVariables = Exact<{
  headerID: Scalars['String'];
  detailInput: Array<ApprovePaymentDetailInput> | ApprovePaymentDetailInput;
}>;


export type ConfirmBatchPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmBatchPayment'>
);

export type GetMultiSupplierQueryVariables = Exact<{
  supplierIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetMultiSupplierQuery = (
  { __typename?: 'Query' }
  & { getMultiSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'address' | 'contactNo' | 'creditTerm' | 'description'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type InvoiceRpaMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
  contractID: Scalars['String'];
}>;


export type InvoiceRpaMutation = (
  { __typename?: 'Mutation' }
  & { invoiceRPA: Array<(
    { __typename?: 'InvoiceRPAResult' }
    & Pick<InvoiceRpaResult, 'supplier' | 'invoiceDate' | 'invoiceNo' | 'contractID' | 'contractTitle' | 'deliveryOrderNo'>
    & { items?: Maybe<Array<(
      { __typename?: 'items' }
      & Pick<Items, 'amount' | 'description' | 'unitPrice' | 'quantity' | 'wbsID' | 'costItemID'>
    )>> }
  )> }
);

export type GetSupplierOsDebitDocumentQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetSupplierOsDebitDocumentQuery = (
  { __typename?: 'Query' }
  & { getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstanding' | 'contractID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'creditTerm'>
    )> }
  )>, getAPInvoiceWoDO: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstanding' | 'contractID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'creditTerm'>
    )> }
  )>, getAPDebitNote: Array<(
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt' | 'contractID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'creditTerm'>
    )> }
  )>, getCNtoSupplier: Array<(
    { __typename?: 'CNtoSupplierEntity' }
    & Pick<CNtoSupplierEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt' | 'contractID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'creditTerm'>
    )> }
  )> }
);

export type GetSupplierDataForAccountingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierDataForAccountingQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'name' | 'ID' | 'accountID' | 'address'>
  )> }
);

export type GetItemByClassQueryVariables = Exact<{
  costClass: CostClass;
}>;


export type GetItemByClassQuery = (
  { __typename?: 'Query' }
  & { getItemByClass: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'name' | 'uomID' | 'createdTs'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )> }
  )> }
);

export type GetContractSupplierPdfQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetContractSupplierPdfQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'creditTerm'>
    & { contact?: Maybe<Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'contactNo'>
    )>> }
  )>, getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'address'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'contactNo'>
    )> }
  )> }
);

export type GetAllocationSupplierQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  creditDebitID: Scalars['String'];
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetAllocationSupplierQuery = (
  { __typename?: 'Query' }
  & { getAPDebitNote: Array<(
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getCNtoSupplier: Array<(
    { __typename?: 'CNtoSupplierEntity' }
    & Pick<CNtoSupplierEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )>, getAPInvoiceWoDO: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'outstandingAmt' | 'taxRate' | 'docRef' | 'docNo' | 'docDate' | 'allocatedAmt' | 'description'>
  )> }
);

export type MakeSupplierAllocationMutationVariables = Exact<{
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeSupplierAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'makeSupplierAllocation'>
);

export type ResetSupplierAllocationMutationVariables = Exact<{
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
}>;


export type ResetSupplierAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetSupplierAllocation'>
);

export type GetDNtoSupplierAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetDNtoSupplierAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDNtoSupplierAllocation'>
);

export type GetCNtoSupplierAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetCNtoSupplierAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCNtoSupplierAllocation'>
);

export type GetSupplierDnAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetSupplierDnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierDNAllocation'>
);

export type GetSupplierCnAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetSupplierCnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierCNAllocation'>
);

export type GetSupplierDepositAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetSupplierDepositAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierDepositAllocation'>
);

export type GetSupplierPaymentAllocationQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<Scalars['String']>;
}>;


export type GetSupplierPaymentAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierPaymentAllocation'>
);

export type CreateSupplierInvoiceMutationVariables = Exact<{
  invoiceInput: ApInvoiceInput;
  ledger: Array<LedgerInput> | LedgerInput;
  invDoItemMatchInput: Array<ApInvoiceItemInput> | ApInvoiceItemInput;
}>;


export type CreateSupplierInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSupplierInvoice'>
);

export type UpdateSupplierInvoiceMutationVariables = Exact<{
  invoiceInput: ApInvoiceInput;
  ledger: Array<LedgerInput> | LedgerInput;
  invDoItemMatchInput: Array<ApInvoiceItemInput> | ApInvoiceItemInput;
}>;


export type UpdateSupplierInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSupplierInvoice'>
);

export type CreateSupplierInvoiceV2MutationVariables = Exact<{
  invoiceInput: ApInvoiceInput;
  ledger: Array<LedgerInput> | LedgerInput;
  APInvoiceItemInput: Array<ApInvoiceItemInput> | ApInvoiceItemInput;
  isWithDO: Scalars['Boolean'];
}>;


export type CreateSupplierInvoiceV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSupplierInvoiceV2'>
);

export type UpdateSupplierInvoiceV2MutationVariables = Exact<{
  invoiceInput: ApInvoiceInput;
  ledger: Array<LedgerInput> | LedgerInput;
  APInvoiceItemInput: Array<ApInvoiceItemInput> | ApInvoiceItemInput;
  isWithDO: Scalars['Boolean'];
}>;


export type UpdateSupplierInvoiceV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSupplierInvoiceV2'>
);

export type GetApInvoiceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetApInvoiceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierInvInfo'>
  & { getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'invoiceAmt' | 'discountAmt' | 'otherChargeAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionRemarks' | 'rejectionDate' | 'supplierID' | 'contractID' | 'grnMatch' | 'outstanding'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, invoiceDo?: Maybe<Array<(
      { __typename?: 'DOEntity' }
      & Pick<DoEntity, 'ID' | 'DODetails'>
    )>>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'APInvoiceItemEntity' }
      & Pick<ApInvoiceItemEntity, 'doItemID' | 'costItemID' | 'taxSchemeID' | 'docAmt' | 'taxAmt' | 'taxRate'>
      & { taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, doItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'ID' | 'deliveryOrderID'>
      )> }
    )>>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetSupplierInvoiceWoDoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetSupplierInvoiceWoDoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierInvInfo'>
  & { getAPInvoiceWoDO: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'discountAmt' | 'otherChargeAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionRemarks' | 'rejectionDate' | 'supplierID' | 'contractID' | 'grnMatch' | 'outstanding'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, invoiceDo?: Maybe<Array<(
      { __typename?: 'DOEntity' }
      & Pick<DoEntity, 'ID' | 'DODetails'>
    )>>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'APInvoiceItemEntity' }
      & Pick<ApInvoiceItemEntity, 'ID' | 'costItemID' | 'taxSchemeID' | 'docAmt' | 'taxAmt' | 'wbsID' | 'taxRate' | 'remarks'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetSupplierInvoiceWoDoListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetSupplierInvoiceWoDoListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierInvInfo'>
  & { getAPInvoiceWoDO: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'discountAmt' | 'otherChargeAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionRemarks' | 'rejectionDate' | 'supplierID' | 'contractID' | 'grnMatch' | 'outstanding'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, invoiceDo?: Maybe<Array<(
      { __typename?: 'DOEntity' }
      & Pick<DoEntity, 'ID' | 'DODetails'>
    )>>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'APInvoiceItemEntity' }
      & Pick<ApInvoiceItemEntity, 'ID' | 'costClass' | 'costCategoryID' | 'costItemID' | 'taxSchemeID' | 'docAmt' | 'taxAmt' | 'wbsID' | 'taxRate' | 'remarks'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name'>
      )> }
    )>> }
  )>, getAPInvoiceWoDOCount: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID'>
  )> }
);

export type GetSupplierInvoiceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetSupplierInvoiceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierInvInfo'>
  & { getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'discountAmt' | 'otherChargeAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionRemarks' | 'rejectionDate' | 'supplierID' | 'contractID' | 'grnMatch' | 'outstanding'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, invoiceDo?: Maybe<Array<(
      { __typename?: 'DOEntity' }
      & Pick<DoEntity, 'ID' | 'DODetails'>
    )>>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'APInvoiceItemEntity' }
      & Pick<ApInvoiceItemEntity, 'ID' | 'costItemID' | 'taxSchemeID' | 'docAmt' | 'taxAmt'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetSupplierInvoiceListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetSupplierInvoiceListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierInvInfo'>
  & { getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'discountAmt' | 'otherChargeAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionRemarks' | 'rejectionDate' | 'supplierID' | 'contractID' | 'grnMatch' | 'outstanding'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, invoiceDo?: Maybe<Array<(
      { __typename?: 'DOEntity' }
      & Pick<DoEntity, 'ID' | 'DODetails'>
    )>>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'APInvoiceItemEntity' }
      & Pick<ApInvoiceItemEntity, 'ID' | 'costItemID' | 'taxSchemeID' | 'docAmt' | 'taxAmt'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )> }
    )>> }
  )>, getAPInvoiceCount: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID'>
  )> }
);

export type GetDOforInvoiceQueryVariables = Exact<{
  invoiceID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  supplierID: Scalars['String'];
  formMode?: Maybe<Scalars['String']>;
}>;


export type GetDOforInvoiceQuery = (
  { __typename?: 'Query' }
  & { getDOforInvoice?: Maybe<Array<(
    { __typename?: 'DOEntity' }
    & Pick<DoEntity, 'ID' | 'contractID' | 'supplierID' | 'docDate' | 'transactionDate' | 'doNo' | 'doStatus' | 'DODetails' | 'upToDateAmt'>
    & { doItem?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & Pick<DoItemEntity, 'ID' | 'doItemAmt' | 'acceptedQty' | 'upToDateQty' | 'returnedQty'>
      & { goodReturnNoteItem?: Maybe<Array<(
        { __typename?: 'GoodReturnNoteItemEntity' }
        & Pick<GoodReturnNoteItemEntity, 'ID' | 'grtnID' | 'doItemID' | 'returnedQty'>
      )>>, poItem?: Maybe<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'totalAmt' | 'taxRate' | 'taxAmt' | 'taxSchemeID' | 'costItemID' | 'unitPrice'>
        & { taxScheme?: Maybe<(
          { __typename?: 'ConTaxSchemeEntity' }
          & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
          & { latestTax?: Maybe<(
            { __typename?: 'TaxObject' }
            & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
          )> }
        )>, costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'name' | 'code' | 'unitPrice' | 'ID'>
        )> }
      )>, grtnItem?: Maybe<(
        { __typename?: 'GoodReturnNoteItemEntity' }
        & Pick<GoodReturnNoteItemEntity, 'ID' | 'grtnID' | 'doItemID' | 'returnedQty' | 'outstandingQty'>
        & { doItem?: Maybe<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'ID'>
          & { poItem?: Maybe<(
            { __typename?: 'POItemEntity' }
            & Pick<PoItemEntity, 'taxRate' | 'taxSchemeID' | 'costItemID' | 'unitPrice'>
            & { taxScheme?: Maybe<(
              { __typename?: 'ConTaxSchemeEntity' }
              & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
              & { latestTax?: Maybe<(
                { __typename?: 'TaxObject' }
                & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
              )> }
            )>, costItem?: Maybe<(
              { __typename?: 'CostItemEntity' }
              & Pick<CostItemEntity, 'ID' | 'code' | 'name'>
            )> }
          )> }
        )> }
      )> }
    )>> }
  )>> }
);

export type CreateApInvoiceWithoutDoMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type CreateApInvoiceWithoutDoMutation = (
  { __typename?: 'Mutation' }
  & { createAPInvoiceWithoutDO: (
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'supplierID' | 'ID'>
  ) }
);

export type UpdateApInvoiceWithoutDoMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type UpdateApInvoiceWithoutDoMutation = (
  { __typename?: 'Mutation' }
  & { updateAPInvoiceWithoutDO: (
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'supplierID' | 'ID'>
  ) }
);

export type GetSupplierInvoiceAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetSupplierInvoiceAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierInvoiceAllocation'>
);

export type CancelSupplierInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierInvoice'>
);

export type DeleteApInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPInvoice'>
);

export type DeleteSupplierInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSupplierInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSupplierInvoice'>
);

export type DeleteApInvoiceWithoutDoMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApInvoiceWithoutDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPInvoiceWithoutDO'>
);

export type GetSupplierInvoiceCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetSupplierInvoiceCountQuery = (
  { __typename?: 'Query' }
  & { getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID'>
  )> }
);

export type GetSupplierInvoiceWoDoCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetSupplierInvoiceWoDoCountQuery = (
  { __typename?: 'Query' }
  & { getAPInvoiceWoDO: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID'>
  )> }
);

export type CreateApRefundMutationVariables = Exact<{
  input: ApPaymentInput;
}>;


export type CreateApRefundMutation = (
  { __typename?: 'Mutation' }
  & { createAPRefund: (
    { __typename?: 'APRefundEntity' }
    & Pick<ApRefundEntity, 'supplierID'>
  ) }
);

export type UpdateApRefundMutationVariables = Exact<{
  input: ApPaymentInput;
}>;


export type UpdateApRefundMutation = (
  { __typename?: 'Mutation' }
  & { updateAPRefund: (
    { __typename?: 'APRefundEntity' }
    & Pick<ApRefundEntity, 'supplierID'>
  ) }
);

export type GetApRefundQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetApRefundQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierRefundsInfo'>
  & { getAPRefund: Array<(
    { __typename?: 'APRefundEntity' }
    & Pick<ApRefundEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'accountID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )> }
  )> }
);

export type GetApRefundListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetApRefundListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierRefundsInfo' | 'getAPRefundCount'>
  & { getAPRefund: Array<(
    { __typename?: 'APRefundEntity' }
    & Pick<ApRefundEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'accountID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )> }
  )> }
);

export type CreateApPaymentMutationVariables = Exact<{
  input: ApPaymentInput;
}>;


export type CreateApPaymentMutation = (
  { __typename?: 'Mutation' }
  & { createAPPayment: (
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'supplierID'>
  ) }
);

export type UpdateApPaymentMutationVariables = Exact<{
  input: ApPaymentInput;
}>;


export type UpdateApPaymentMutation = (
  { __typename?: 'Mutation' }
  & { updateAPPayment: (
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'supplierID'>
  ) }
);

export type GetApPaymentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetApPaymentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierPaymentsInfo'>
  & { getAPPayment: Array<(
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'outstandingAmt'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetApPaymentListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetApPaymentListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierPaymentsInfo' | 'getAPPaymentCount'>
  & { getAPPayment: Array<(
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'outstandingAmt'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateApAdvanceMutationVariables = Exact<{
  input: ApPaymentInput;
}>;


export type CreateApAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { createAPAdvance: (
    { __typename?: 'APAdvanceEntity' }
    & Pick<ApAdvanceEntity, 'supplierID' | 'ID' | 'docNo'>
  ) }
);

export type UpdateApAdvanceMutationVariables = Exact<{
  input: ApPaymentInput;
}>;


export type UpdateApAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { updateAPAdvance: (
    { __typename?: 'APAdvanceEntity' }
    & Pick<ApAdvanceEntity, 'supplierID' | 'ID' | 'docNo'>
  ) }
);

export type GetApAdvanceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetApAdvanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierDepositsInfo'>
  & { getAPAdvance: Array<(
    { __typename?: 'APAdvanceEntity' }
    & Pick<ApAdvanceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'docNo' | 'isGlExport' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetApAdvanceListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetApAdvanceListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierDepositsInfo' | 'getAPAdvanceCount'>
  & { getAPAdvance: Array<(
    { __typename?: 'APAdvanceEntity' }
    & Pick<ApAdvanceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'docNo' | 'isGlExport' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetSupplierPaymentTableQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierPaymentTableQuery = (
  { __typename?: 'Query' }
  & { getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstanding'>
  )>, getAPDebitNote: Array<(
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt'>
  )> }
);

export type MakeSupplierPaymentMutationVariables = Exact<{
  input: ApPaymentInput;
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeSupplierPaymentMutation = (
  { __typename?: 'Mutation' }
  & { makeSupplierPayment: (
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'ID' | 'docNo'>
  ) }
);

export type CancelSupplierDepositMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierDepositMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierDeposit'>
);

export type CancelSupplierRefundMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierRefund'>
);

export type CancelSupplierPaymentMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierPayment'>
);

export type DeleteApAdvanceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPAdvance'>
);

export type DeleteApPaymentMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPPayment'>
);

export type DeleteApRefundMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPRefund'>
);

export type GetApAdvanceCountQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetApAdvanceCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAPAdvanceCount'>
);

export type GetApRefundCountQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetApRefundCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAPRefundCount'>
);

export type GetApPaymentCountQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetApPaymentCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAPPaymentCount'>
);

export type CreateApDebitNoteMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type CreateApDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & { createAPDebitNote: (
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'supplierID' | 'ID'>
  ) }
);

export type UpdateApDebitNoteMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type UpdateApDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateAPDebitNote: (
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'supplierID' | 'ID'>
  ) }
);

export type GetApDebitNoteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetApDebitNoteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierDNInfo'>
  & { getAPDebitNote: Array<(
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'accountID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt' | 'deductionTypeID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APDebitNoteItem?: Maybe<Array<(
      { __typename?: 'APDebitNoteItemEntity' }
      & Pick<ApDebitNoteItemEntity, 'ID' | 'costClass' | 'costCategoryID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type GetApDebitNoteListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetApDebitNoteListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierDNInfo' | 'getAPDebitNoteCount'>
  & { getAPDebitNote: Array<(
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'accountID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt' | 'deductionTypeID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APDebitNoteItem?: Maybe<Array<(
      { __typename?: 'APDebitNoteItemEntity' }
      & Pick<ApDebitNoteItemEntity, 'ID' | 'costClass' | 'costCategoryID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type CreateApCreditNoteMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type CreateApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & { createAPCreditNote: (
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'supplierID' | 'ID'>
  ) }
);

export type UpdateApCreditNoteMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type UpdateApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateAPCreditNote: (
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'supplierID' | 'ID'>
  ) }
);

export type GetApCreditNoteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetApCreditNoteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierCNInfo'>
  & { getAPCreditNote: Array<(
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'accountID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'deductionTypeID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APCreditNoteItem?: Maybe<Array<(
      { __typename?: 'APCreditNoteItemEntity' }
      & Pick<ApCreditNoteItemEntity, 'ID' | 'costClass' | 'costCategoryID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type GetApCreditNoteListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetApCreditNoteListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierCNInfo' | 'getAPCreditNoteCount'>
  & { getAPCreditNote: Array<(
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'accountID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'deductionTypeID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APCreditNoteItem?: Maybe<Array<(
      { __typename?: 'APCreditNoteItemEntity' }
      & Pick<ApCreditNoteItemEntity, 'ID' | 'costClass' | 'costCategoryID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type CreateDNtoSupplierMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type CreateDNtoSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createDNtoSupplier: (
    { __typename?: 'DNtoSupplierEntity' }
    & Pick<DNtoSupplierEntity, 'supplierID' | 'ID' | 'docNo'>
  ) }
);

export type UpdateDNtoSupplierMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type UpdateDNtoSupplierMutation = (
  { __typename?: 'Mutation' }
  & { updateDNtoSupplier: (
    { __typename?: 'DNtoSupplierEntity' }
    & Pick<DNtoSupplierEntity, 'supplierID' | 'ID' | 'docNo'>
  ) }
);

export type GetDNtoSupplierQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetDNtoSupplierQuery = (
  { __typename?: 'Query' }
  & { getDNtoSupplier: Array<(
    { __typename?: 'DNtoSupplierEntity' }
    & Pick<DNtoSupplierEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'docNo' | 'docRef' | 'isGlExport' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'deductionTypeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, DNtoSupplierItem?: Maybe<Array<(
      { __typename?: 'DNtoSupplierItemEntity' }
      & Pick<DNtoSupplierItemEntity, 'ID' | 'costClass' | 'costCategoryID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type GetDNtoSupplierListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetDNtoSupplierListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDNtoSupplierCount'>
  & { getDNtoSupplier: Array<(
    { __typename?: 'DNtoSupplierEntity' }
    & Pick<DNtoSupplierEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'docNo' | 'docRef' | 'isGlExport' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'deductionTypeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, DNtoSupplierItem?: Maybe<Array<(
      { __typename?: 'DNtoSupplierItemEntity' }
      & Pick<DNtoSupplierItemEntity, 'ID' | 'costClass' | 'costCategoryID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type CreateCNtoSupplierMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type CreateCNtoSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createCNtoSupplier: (
    { __typename?: 'CNtoSupplierEntity' }
    & Pick<CNtoSupplierEntity, 'ID' | 'supplierID' | 'docNo'>
  ) }
);

export type UpdateCNtoSupplierMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type UpdateCNtoSupplierMutation = (
  { __typename?: 'Mutation' }
  & { updateCNtoSupplier: (
    { __typename?: 'CNtoSupplierEntity' }
    & Pick<CNtoSupplierEntity, 'ID' | 'supplierID' | 'docNo'>
  ) }
);

export type GetCNtoSupplierQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetCNtoSupplierQuery = (
  { __typename?: 'Query' }
  & { getCNtoSupplier: Array<(
    { __typename?: 'CNtoSupplierEntity' }
    & Pick<CNtoSupplierEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'deductionTypeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, CNtoSupplierItem?: Maybe<Array<(
      { __typename?: 'CNtoSupplierItemEntity' }
      & Pick<CNtoSupplierItemEntity, 'ID' | 'costClass' | 'costCategoryID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type GetCNtoSupplierListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetCNtoSupplierListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCNtoSupplierCount'>
  & { getCNtoSupplier: Array<(
    { __typename?: 'CNtoSupplierEntity' }
    & Pick<CNtoSupplierEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'deductionTypeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, CNtoSupplierItem?: Maybe<Array<(
      { __typename?: 'CNtoSupplierItemEntity' }
      & Pick<CNtoSupplierItemEntity, 'ID' | 'costClass' | 'costCategoryID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'TaxObject' }
          & Pick<TaxObject, 'taxSchemeID' | 'taxRate' | 'taxDate'>
        )> }
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name' | 'parentName'>
      )> }
    )>> }
  )> }
);

export type CancelSupplierDnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierDN'>
);

export type CancelSupplierCnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierCN'>
);

export type CancelDNtoSupplierMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelDNtoSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelDNtoSupplier'>
);

export type CancelCNtoSupplierMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelCNtoSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCNtoSupplier'>
);

export type DeleteApCreditNoteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPCreditNote'>
);

export type DeleteApDebitNoteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPDebitNote'>
);

export type DeleteCNtoSupplierMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteCNtoSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCNtoSupplier'>
);

export type DeleteDNtoSupplierMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteDNtoSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDNtoSupplier'>
);

export type GetSupplierAdjustmentQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetSupplierAdjustmentQuery = (
  { __typename?: 'Query' }
  & { getAPDebitNote: Array<(
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxAmt' | 'description' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )>, getAPCreditNote: Array<(
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxAmt' | 'description' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )>, getCNtoSupplier: Array<(
    { __typename?: 'CNtoSupplierEntity' }
    & Pick<CNtoSupplierEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxAmt' | 'description' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )>, getDNtoSupplier: Array<(
    { __typename?: 'DNtoSupplierEntity' }
    & Pick<DNtoSupplierEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxAmt' | 'description' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )> }
);

export type GetApDebitNoteCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetApDebitNoteCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAPDebitNoteCount'>
);

export type GetApCreditNoteCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetApCreditNoteCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAPCreditNoteCount'>
);

export type GetDNtoSupplierCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetDNtoSupplierCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDNtoSupplierCount'>
);

export type GetCNtoSupplierCountQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetCNtoSupplierCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCNtoSupplierCount'>
);

export type GetSupplierCompanyAssignmentQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getSupplierCompanyAssignment: Array<(
    { __typename?: 'SupplierCompanyAssignmentEntity' }
    & Pick<SupplierCompanyAssignmentEntity, 'ID' | 'supplierID' | 'companyID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name' | 'registrationNo' | 'email'>
    )> }
  )> }
);

export type AssignSupplierCompanyMutationVariables = Exact<{
  supplierID: Scalars['String'];
  companyID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignSupplierCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignSupplierCompany'>
);

export type UnassignSupplierCompanyMutationVariables = Exact<{
  supplierID: Scalars['String'];
  companyID: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnassignSupplierCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignSupplierCompany'>
);

export type GetSupplierLedgersQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierLedgersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierLedgers'>
);

export type GetToDoListingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetToDoListingQuery = (
  { __typename?: 'Query' }
  & { getSubconProgressClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'grossAmt' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'subcontractID' | 'subcontractorID' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { subcontractClaimAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimAllocationEntity' }
      & Pick<SubcontractClaimAllocationEntity, 'ID' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'subcontractCostWbsID'>
    )>>, subcontractClaimVOAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimVOAllocationEntity' }
      & Pick<SubcontractClaimVoAllocationEntity, 'ID' | 'previousVOClaimAmt' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'subcontractVOID'>
    )>>, subcontractClaimVOCostWbsAllocation?: Maybe<Array<(
      { __typename?: 'SubcontractClaimVOCostWbsAllocationEntity' }
      & Pick<SubcontractClaimVoCostWbsAllocationEntity, 'ID' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt' | 'subcontractVOCostWbsID'>
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )>, getProgressClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationRemarks' | 'cancellationDate' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'customerID' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'grossAmt' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { contractClaimAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimAllocationEntity' }
      & Pick<ContractClaimAllocationEntity, 'ID' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'contractClaimID' | 'wbsBudgetDetailID' | 'wbsID' | 'revenueCategoryID'>
    )>>, contractClaimVOAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimVOAllocationEntity' }
      & Pick<ContractClaimVoAllocationEntity, 'ID' | 'contractVOID' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'previousVOClaimAmt'>
    )>>, contractClaimVORevenueWbsAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimVORevenueWbsAllocationEntity' }
      & Pick<ContractClaimVoRevenueWbsAllocationEntity, 'ID' | 'contractVORevenueWbsID' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'previousVOClaimAmt'>
      & { contractVORevenueWbs?: Maybe<(
        { __typename?: 'ContractVORevenueWbsEntity' }
        & Pick<ContractVoRevenueWbsEntity, 'ID' | 'wbsID' | 'revenueCategoryID'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )>, getPurchaseReq: Array<(
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'ID' | 'contractID' | 'subcontractID' | 'docNo' | 'prDate' | 'expectedDate' | 'instructions' | 'purchaseStatus'>
  )>, getPurchaseOrder: Array<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'ID' | 'accountID' | 'contractID' | 'subcontractID' | 'docDate' | 'instructions' | 'totalAmt' | 'purchaseStatus'>
  )>, getSubcontractPayment: Array<(
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'bankAccountID' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
  )>, getAPPayment: Array<(
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'bankAccountID' | 'bankDetails' | 'receiptStatus' | 'supplierID' | 'outstandingAmt'>
  )>, getContractReceipt: Array<(
    { __typename?: 'ContractReceiptEntity' }
    & Pick<ContractReceiptEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'accountID' | 'bankAccountID' | 'bankDetails' | 'receiptStatus' | 'customerID' | 'contractID' | 'outstandingAmt'>
  )>, getContractVO: Array<(
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID' | 'accountID' | 'contractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'approvedAmt' | 'docDate' | 'remarks'>
  )>, getSubcontractVO: Array<(
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'ID' | 'accountID' | 'subcontractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'approvedAmt' | 'remarks' | 'docDate'>
  )> }
);

export type CreateContractNewsMutationVariables = Exact<{
  input: ContractNewsInput;
}>;


export type CreateContractNewsMutation = (
  { __typename?: 'Mutation' }
  & { createContractNews: (
    { __typename?: 'ContractNewsEntity' }
    & Pick<ContractNewsEntity, 'ID'>
  ) }
);

export type UpdateContractNewsMutationVariables = Exact<{
  input: ContractNewsInput;
}>;


export type UpdateContractNewsMutation = (
  { __typename?: 'Mutation' }
  & { updateContractNews: (
    { __typename?: 'ContractNewsEntity' }
    & Pick<ContractNewsEntity, 'ID'>
  ) }
);

export type GetContractNewsQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractNewsQuery = (
  { __typename?: 'Query' }
  & { getContractNews: Array<(
    { __typename?: 'ContractNewsEntity' }
    & Pick<ContractNewsEntity, 'ID' | 'title' | 'content' | 'startDate' | 'endDate' | 'postStatus' | 'createdTs' | 'createdBy'>
  )> }
);

export type GetContractNotificationQueryVariables = Exact<{
  docRefTable?: Maybe<Scalars['String']>;
  recipientID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractNotificationQuery = (
  { __typename?: 'Query' }
  & { getContractNotification: Array<(
    { __typename?: 'ContractNotificationEntity' }
    & Pick<ContractNotificationEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'subject' | 'body' | 'createdTs'>
    & { notificationRecipient?: Maybe<Array<(
      { __typename?: 'ContractNotificationRecipientEntity' }
      & Pick<ContractNotificationRecipientEntity, 'isRead'>
    )>> }
  )> }
);

export type GetContractToDoQueryVariables = Exact<{
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
}>;


export type GetContractToDoQuery = (
  { __typename?: 'Query' }
  & { getContractToDo: Array<(
    { __typename?: 'ContractToDoEntity' }
    & Pick<ContractToDoEntity, 'ID'>
    & { approvalLog?: Maybe<Array<(
      { __typename?: 'ContractApprovalLogEntity' }
      & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
    )>> }
  )> }
);

export type ToDoListingQueryVariables = Exact<{
  approved?: Maybe<Scalars['Boolean']>;
}>;


export type ToDoListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getToDoListing'>
);

export type ReadNotificationMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type ReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'readNotification'>
);

export type ApproveToDoMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type ApproveToDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveToDo'>
);

export type RejectToDoMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
  remark: Scalars['String'];
}>;


export type RejectToDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectToDo'>
);

export type GetNotificationListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNotificationListing'>
);

export type GetToDoWorkflowSummaryQueryVariables = Exact<{
  length?: Maybe<Scalars['Boolean']>;
}>;


export type GetToDoWorkflowSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getToDoWorkflowSummary'>
);

export type GetToDoAllPendingDocsQueryVariables = Exact<{
  category: Scalars['String'];
}>;


export type GetToDoAllPendingDocsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getToDoAllPendingDocs'>
);

export type ApproveRejectWorkDeskMutationVariables = Exact<{
  workDeskInput: Array<WorkDeskInput> | WorkDeskInput;
}>;


export type ApproveRejectWorkDeskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveRejectWorkDesk'>
);

export type PolicyCheckingQueryVariables = Exact<{
  contractID: Scalars['String'];
  category: Scalars['String'];
}>;


export type PolicyCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'policyChecking'>
);

export type PolicyCheckerApPaymentQueryVariables = Exact<{
  category: Scalars['String'];
}>;


export type PolicyCheckerApPaymentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'policyCheckerAPPayment'>
);

export type GetNotificationWorkflowListingQueryVariables = Exact<{
  unread?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['Boolean']>;
}>;


export type GetNotificationWorkflowListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNotificationWorkflowListing'>
);

export type GetApprovalLogQueryVariables = Exact<{
  refID: Scalars['String'];
}>;


export type GetApprovalLogQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getApprovalLog'>
);

export type ApprovableLedgerWfCheckingQueryVariables = Exact<{
  input: CheckingInput;
}>;


export type ApprovableLedgerWfCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'approvableLedgerWFChecking'>
);

export type GetUserQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'jobTitle' | 'contactNo' | 'superUser' | 'commonStatus' | 'userName'>
  ) }
);

export type MarkAsReadNotiMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type MarkAsReadNotiMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markAsReadNoti'>
);

export type DeleteNotiMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteNotiMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNoti'>
);


export const ContractClaimCalculationDocument = gql`
    query contractClaimCalculation($contractID: String) {
  submittedClaimChecking(contractID: $contractID)
  approvedClaimChecking(contractID: $contractID) {
    previousClaim
    previousMOS
    previousVOClaim
    previousDeduction
    previousDayWork
    retentionPerc
    previousRetentionSum
    retentionBalance
    advanceSum
    advanceRecouped
    advanceBalance
    deductionSum
    deductionRecouped
    deductionBalance
    submittedDocDate
  }
  contractVoCalculation(contractID: $contractID)
  maxRetention(contractID: $contractID)
}
    `;

/**
 * __useContractClaimCalculationQuery__
 *
 * To run a query within a React component, call `useContractClaimCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractClaimCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractClaimCalculationQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useContractClaimCalculationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContractClaimCalculationQuery, ContractClaimCalculationQueryVariables>) {
        return ApolloReactHooks.useQuery<ContractClaimCalculationQuery, ContractClaimCalculationQueryVariables>(ContractClaimCalculationDocument, baseOptions);
      }
export function useContractClaimCalculationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractClaimCalculationQuery, ContractClaimCalculationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContractClaimCalculationQuery, ContractClaimCalculationQueryVariables>(ContractClaimCalculationDocument, baseOptions);
        }
export type ContractClaimCalculationQueryHookResult = ReturnType<typeof useContractClaimCalculationQuery>;
export type ContractClaimCalculationLazyQueryHookResult = ReturnType<typeof useContractClaimCalculationLazyQuery>;
export type ContractClaimCalculationQueryResult = ApolloReactCommon.QueryResult<ContractClaimCalculationQuery, ContractClaimCalculationQueryVariables>;
export const SubconClaimCalculationDocument = gql`
    query subconClaimCalculation($subcontractID: String) {
  submittedClaimChecking(subcontractID: $subcontractID)
  approvedClaimChecking(subcontractID: $subcontractID) {
    previousClaim
    previousMOS
    previousVOClaim
    previousDeduction
    previousDayWork
    retentionPerc
    previousRetentionSum
    retentionBalance
    advanceSum
    advanceRecouped
    advanceBalance
    deductionSum
    deductionRecouped
    deductionBalance
    submittedDocDate
  }
  subcontractVoCalculation(subcontractID: $subcontractID)
  maxRetention(subcontractID: $subcontractID)
}
    `;

/**
 * __useSubconClaimCalculationQuery__
 *
 * To run a query within a React component, call `useSubconClaimCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubconClaimCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubconClaimCalculationQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useSubconClaimCalculationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubconClaimCalculationQuery, SubconClaimCalculationQueryVariables>) {
        return ApolloReactHooks.useQuery<SubconClaimCalculationQuery, SubconClaimCalculationQueryVariables>(SubconClaimCalculationDocument, baseOptions);
      }
export function useSubconClaimCalculationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubconClaimCalculationQuery, SubconClaimCalculationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubconClaimCalculationQuery, SubconClaimCalculationQueryVariables>(SubconClaimCalculationDocument, baseOptions);
        }
export type SubconClaimCalculationQueryHookResult = ReturnType<typeof useSubconClaimCalculationQuery>;
export type SubconClaimCalculationLazyQueryHookResult = ReturnType<typeof useSubconClaimCalculationLazyQuery>;
export type SubconClaimCalculationQueryResult = ApolloReactCommon.QueryResult<SubconClaimCalculationQuery, SubconClaimCalculationQueryVariables>;
export const SubmittedClaimCheckingDocument = gql`
    query submittedClaimChecking($contractID: String, $subcontractID: String) {
  submittedClaimChecking(contractID: $contractID, subcontractID: $subcontractID)
}
    `;

/**
 * __useSubmittedClaimCheckingQuery__
 *
 * To run a query within a React component, call `useSubmittedClaimCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedClaimCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedClaimCheckingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useSubmittedClaimCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubmittedClaimCheckingQuery, SubmittedClaimCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<SubmittedClaimCheckingQuery, SubmittedClaimCheckingQueryVariables>(SubmittedClaimCheckingDocument, baseOptions);
      }
export function useSubmittedClaimCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmittedClaimCheckingQuery, SubmittedClaimCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubmittedClaimCheckingQuery, SubmittedClaimCheckingQueryVariables>(SubmittedClaimCheckingDocument, baseOptions);
        }
export type SubmittedClaimCheckingQueryHookResult = ReturnType<typeof useSubmittedClaimCheckingQuery>;
export type SubmittedClaimCheckingLazyQueryHookResult = ReturnType<typeof useSubmittedClaimCheckingLazyQuery>;
export type SubmittedClaimCheckingQueryResult = ApolloReactCommon.QueryResult<SubmittedClaimCheckingQuery, SubmittedClaimCheckingQueryVariables>;
export const ContractVoCalculationDocument = gql`
    query contractVOCalculation($contractID: String) {
  contractVoCalculation(contractID: $contractID)
}
    `;

/**
 * __useContractVoCalculationQuery__
 *
 * To run a query within a React component, call `useContractVoCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractVoCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractVoCalculationQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useContractVoCalculationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContractVoCalculationQuery, ContractVoCalculationQueryVariables>) {
        return ApolloReactHooks.useQuery<ContractVoCalculationQuery, ContractVoCalculationQueryVariables>(ContractVoCalculationDocument, baseOptions);
      }
export function useContractVoCalculationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractVoCalculationQuery, ContractVoCalculationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContractVoCalculationQuery, ContractVoCalculationQueryVariables>(ContractVoCalculationDocument, baseOptions);
        }
export type ContractVoCalculationQueryHookResult = ReturnType<typeof useContractVoCalculationQuery>;
export type ContractVoCalculationLazyQueryHookResult = ReturnType<typeof useContractVoCalculationLazyQuery>;
export type ContractVoCalculationQueryResult = ApolloReactCommon.QueryResult<ContractVoCalculationQuery, ContractVoCalculationQueryVariables>;
export const SubcontractVoCalculationDocument = gql`
    query subcontractVOCalculation($subcontractID: String) {
  subcontractVoCalculation(subcontractID: $subcontractID)
}
    `;

/**
 * __useSubcontractVoCalculationQuery__
 *
 * To run a query within a React component, call `useSubcontractVoCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubcontractVoCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubcontractVoCalculationQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useSubcontractVoCalculationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubcontractVoCalculationQuery, SubcontractVoCalculationQueryVariables>) {
        return ApolloReactHooks.useQuery<SubcontractVoCalculationQuery, SubcontractVoCalculationQueryVariables>(SubcontractVoCalculationDocument, baseOptions);
      }
export function useSubcontractVoCalculationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubcontractVoCalculationQuery, SubcontractVoCalculationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubcontractVoCalculationQuery, SubcontractVoCalculationQueryVariables>(SubcontractVoCalculationDocument, baseOptions);
        }
export type SubcontractVoCalculationQueryHookResult = ReturnType<typeof useSubcontractVoCalculationQuery>;
export type SubcontractVoCalculationLazyQueryHookResult = ReturnType<typeof useSubcontractVoCalculationLazyQuery>;
export type SubcontractVoCalculationQueryResult = ApolloReactCommon.QueryResult<SubcontractVoCalculationQuery, SubcontractVoCalculationQueryVariables>;
export const ApprovedClaimCheckingDocument = gql`
    query approvedClaimChecking($contractID: String, $subcontractID: String) {
  approvedClaimChecking(contractID: $contractID, subcontractID: $subcontractID) {
    previousClaim
    previousMOS
    previousVOClaim
    previousDeduction
    previousDayWork
    retentionPerc
    previousRetentionSum
    retentionBalance
    advanceSum
    advanceRecouped
    advanceBalance
    deductionSum
    deductionRecouped
    deductionBalance
    submittedDocDate
  }
}
    `;

/**
 * __useApprovedClaimCheckingQuery__
 *
 * To run a query within a React component, call `useApprovedClaimCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovedClaimCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovedClaimCheckingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useApprovedClaimCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovedClaimCheckingQuery, ApprovedClaimCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovedClaimCheckingQuery, ApprovedClaimCheckingQueryVariables>(ApprovedClaimCheckingDocument, baseOptions);
      }
export function useApprovedClaimCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovedClaimCheckingQuery, ApprovedClaimCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovedClaimCheckingQuery, ApprovedClaimCheckingQueryVariables>(ApprovedClaimCheckingDocument, baseOptions);
        }
export type ApprovedClaimCheckingQueryHookResult = ReturnType<typeof useApprovedClaimCheckingQuery>;
export type ApprovedClaimCheckingLazyQueryHookResult = ReturnType<typeof useApprovedClaimCheckingLazyQuery>;
export type ApprovedClaimCheckingQueryResult = ApolloReactCommon.QueryResult<ApprovedClaimCheckingQuery, ApprovedClaimCheckingQueryVariables>;
export const MaxRetentionAmtDocument = gql`
    query maxRetentionAmt($contractID: String, $subcontractID: String) {
  maxRetention(contractID: $contractID, subcontractID: $subcontractID)
}
    `;

/**
 * __useMaxRetentionAmtQuery__
 *
 * To run a query within a React component, call `useMaxRetentionAmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaxRetentionAmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaxRetentionAmtQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useMaxRetentionAmtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MaxRetentionAmtQuery, MaxRetentionAmtQueryVariables>) {
        return ApolloReactHooks.useQuery<MaxRetentionAmtQuery, MaxRetentionAmtQueryVariables>(MaxRetentionAmtDocument, baseOptions);
      }
export function useMaxRetentionAmtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MaxRetentionAmtQuery, MaxRetentionAmtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MaxRetentionAmtQuery, MaxRetentionAmtQueryVariables>(MaxRetentionAmtDocument, baseOptions);
        }
export type MaxRetentionAmtQueryHookResult = ReturnType<typeof useMaxRetentionAmtQuery>;
export type MaxRetentionAmtLazyQueryHookResult = ReturnType<typeof useMaxRetentionAmtLazyQuery>;
export type MaxRetentionAmtQueryResult = ApolloReactCommon.QueryResult<MaxRetentionAmtQuery, MaxRetentionAmtQueryVariables>;
export const ContractClaimAllocationLedgersDocument = gql`
    query contractClaimAllocationLedgers($contractID: String, $debitID: String) {
  getClaimAllocationLedgers(contractID: $contractID, debitID: $debitID)
}
    `;

/**
 * __useContractClaimAllocationLedgersQuery__
 *
 * To run a query within a React component, call `useContractClaimAllocationLedgersQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractClaimAllocationLedgersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractClaimAllocationLedgersQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useContractClaimAllocationLedgersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContractClaimAllocationLedgersQuery, ContractClaimAllocationLedgersQueryVariables>) {
        return ApolloReactHooks.useQuery<ContractClaimAllocationLedgersQuery, ContractClaimAllocationLedgersQueryVariables>(ContractClaimAllocationLedgersDocument, baseOptions);
      }
export function useContractClaimAllocationLedgersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractClaimAllocationLedgersQuery, ContractClaimAllocationLedgersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContractClaimAllocationLedgersQuery, ContractClaimAllocationLedgersQueryVariables>(ContractClaimAllocationLedgersDocument, baseOptions);
        }
export type ContractClaimAllocationLedgersQueryHookResult = ReturnType<typeof useContractClaimAllocationLedgersQuery>;
export type ContractClaimAllocationLedgersLazyQueryHookResult = ReturnType<typeof useContractClaimAllocationLedgersLazyQuery>;
export type ContractClaimAllocationLedgersQueryResult = ApolloReactCommon.QueryResult<ContractClaimAllocationLedgersQuery, ContractClaimAllocationLedgersQueryVariables>;
export const SubcontractClaimAllocationLedgersDocument = gql`
    query subcontractClaimAllocationLedgers($subcontractID: String, $creditID: String) {
  getSubconClaimAllocationLedgers(subcontractID: $subcontractID, creditID: $creditID)
}
    `;

/**
 * __useSubcontractClaimAllocationLedgersQuery__
 *
 * To run a query within a React component, call `useSubcontractClaimAllocationLedgersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubcontractClaimAllocationLedgersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubcontractClaimAllocationLedgersQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useSubcontractClaimAllocationLedgersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubcontractClaimAllocationLedgersQuery, SubcontractClaimAllocationLedgersQueryVariables>) {
        return ApolloReactHooks.useQuery<SubcontractClaimAllocationLedgersQuery, SubcontractClaimAllocationLedgersQueryVariables>(SubcontractClaimAllocationLedgersDocument, baseOptions);
      }
export function useSubcontractClaimAllocationLedgersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubcontractClaimAllocationLedgersQuery, SubcontractClaimAllocationLedgersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubcontractClaimAllocationLedgersQuery, SubcontractClaimAllocationLedgersQueryVariables>(SubcontractClaimAllocationLedgersDocument, baseOptions);
        }
export type SubcontractClaimAllocationLedgersQueryHookResult = ReturnType<typeof useSubcontractClaimAllocationLedgersQuery>;
export type SubcontractClaimAllocationLedgersLazyQueryHookResult = ReturnType<typeof useSubcontractClaimAllocationLedgersLazyQuery>;
export type SubcontractClaimAllocationLedgersQueryResult = ApolloReactCommon.QueryResult<SubcontractClaimAllocationLedgersQuery, SubcontractClaimAllocationLedgersQueryVariables>;
export const GetClientExecutiveSummaryDocument = gql`
    query getClientExecutiveSummary($companyID: String, $customerID: String, $contractID: String) {
  clientProfileDate(customerID: $customerID, contractID: $contractID)
  submittedCusAVNotification(customerID: $customerID, contractID: $contractID)
  advancesInfo(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  claimsInfo(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  submittedClaimNotification(customerID: $customerID, contractID: $contractID)
  progressClaimsInfo(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  submittedRetClaimNotification(customerID: $customerID, contractID: $contractID)
  retentionClaimsInfo(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  miscInvInfo(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  submittedMiscInvNotification(customerID: $customerID, contractID: $contractID)
  DNInfo(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  submittedDNNotification(customerID: $customerID, contractID: $contractID)
  CNInfo(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  submittedCNNotification(customerID: $customerID, contractID: $contractID)
  submittedReceiptNotification(customerID: $customerID, contractID: $contractID)
  receiptsInfo(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  submittedRefundNotification(customerID: $customerID, contractID: $contractID)
  refundsInfo(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  submittedClientDNNotification(customerID: $customerID, contractID: $contractID)
  clientDNInfo(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  submittedClientCNNotification(customerID: $customerID, contractID: $contractID)
  clientCNInfo(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  outstandingClient(companyID: $companyID, customerID: $customerID, contractID: $contractID)
  unallocatedClient(customerID: $customerID, contractID: $contractID)
}
    `;

/**
 * __useGetClientExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetClientExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientExecutiveSummaryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      customerID: // value for 'customerID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetClientExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientExecutiveSummaryQuery, GetClientExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientExecutiveSummaryQuery, GetClientExecutiveSummaryQueryVariables>(GetClientExecutiveSummaryDocument, baseOptions);
      }
export function useGetClientExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientExecutiveSummaryQuery, GetClientExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientExecutiveSummaryQuery, GetClientExecutiveSummaryQueryVariables>(GetClientExecutiveSummaryDocument, baseOptions);
        }
export type GetClientExecutiveSummaryQueryHookResult = ReturnType<typeof useGetClientExecutiveSummaryQuery>;
export type GetClientExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetClientExecutiveSummaryLazyQuery>;
export type GetClientExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetClientExecutiveSummaryQuery, GetClientExecutiveSummaryQueryVariables>;
export const GetSubcontractorExecutiveSummaryDocument = gql`
    query getSubcontractorExecutiveSummary($companyID: String, $subcontractID: String, $subcontractorID: String) {
  subconProfileDate(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  submittedSubconAVNotification(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  subconAdvancesInfo(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  subconClaimsInfo(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  submittedSubconClaimNotification(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  subconProgressClaimsInfo(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  submittedSubconRetClaimNotification(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  subconRetentionClaimsInfo(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  submittedSubconMiscInvNotification(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  subconMiscInvInfo(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  submittedSubconDNNotification(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  submittedSubconCNNotification(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  subconDNInfo(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  subconCNInfo(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  submittedPaymentNotification(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  subconPaymentInfo(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  submittedSubconRefundNotification(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  subconRefundsInfo(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  submittedDNtoSubconNotification(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  DNtoSubconInfo(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  submittedCNtoSubconNotification(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  CNtoSubconInfo(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  outstandingSubcon(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  unallocatedSubcon(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractorExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorExecutiveSummaryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractorExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorExecutiveSummaryQuery, GetSubcontractorExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorExecutiveSummaryQuery, GetSubcontractorExecutiveSummaryQueryVariables>(GetSubcontractorExecutiveSummaryDocument, baseOptions);
      }
export function useGetSubcontractorExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorExecutiveSummaryQuery, GetSubcontractorExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorExecutiveSummaryQuery, GetSubcontractorExecutiveSummaryQueryVariables>(GetSubcontractorExecutiveSummaryDocument, baseOptions);
        }
export type GetSubcontractorExecutiveSummaryQueryHookResult = ReturnType<typeof useGetSubcontractorExecutiveSummaryQuery>;
export type GetSubcontractorExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetSubcontractorExecutiveSummaryLazyQuery>;
export type GetSubcontractorExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorExecutiveSummaryQuery, GetSubcontractorExecutiveSummaryQueryVariables>;
export const GetSupplierAccountExecutiveSummaryDocument = gql`
    query getSupplierAccountExecutiveSummary($companyID: String, $supplierID: String) {
  submittedDepositNotification(supplierID: $supplierID, companyID: $companyID)
  supplierDepositsInfo(supplierID: $supplierID, companyID: $companyID)
  submittedSupplierInvNotification(supplierID: $supplierID, companyID: $companyID)
  supplierInvInfo(supplierID: $supplierID, companyID: $companyID)
  submittedSupplierInvWoDoNotification(supplierID: $supplierID, companyID: $companyID)
  supplierInvWoDoInfo(supplierID: $supplierID, companyID: $companyID)
  submittedSupplierDNNotification(supplierID: $supplierID, companyID: $companyID)
  supplierDNInfo(supplierID: $supplierID, companyID: $companyID)
  submittedSupplierCNNotification(supplierID: $supplierID, companyID: $companyID)
  supplierCNInfo(supplierID: $supplierID, companyID: $companyID)
  submittedDNToSupplierNotification(supplierID: $supplierID, companyID: $companyID)
  DNtoSupplierInfo(supplierID: $supplierID, companyID: $companyID)
  submittedCNToSupplierNotification(supplierID: $supplierID, companyID: $companyID)
  CNtoSupplierInfo(supplierID: $supplierID, companyID: $companyID)
  submittedSupplierPaymentNotification(supplierID: $supplierID, companyID: $companyID)
  supplierPaymentsInfo(supplierID: $supplierID, companyID: $companyID)
  supplierBatchPaymentsInfo
  submittedSupplierRefundNotification(supplierID: $supplierID, companyID: $companyID)
  supplierRefundsInfo(supplierID: $supplierID, companyID: $companyID)
  outstandingSupplier(supplierID: $supplierID)
  unallocatedSupplier(supplierID: $supplierID)
}
    `;

/**
 * __useGetSupplierAccountExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetSupplierAccountExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierAccountExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierAccountExecutiveSummaryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetSupplierAccountExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierAccountExecutiveSummaryQuery, GetSupplierAccountExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierAccountExecutiveSummaryQuery, GetSupplierAccountExecutiveSummaryQueryVariables>(GetSupplierAccountExecutiveSummaryDocument, baseOptions);
      }
export function useGetSupplierAccountExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierAccountExecutiveSummaryQuery, GetSupplierAccountExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierAccountExecutiveSummaryQuery, GetSupplierAccountExecutiveSummaryQueryVariables>(GetSupplierAccountExecutiveSummaryDocument, baseOptions);
        }
export type GetSupplierAccountExecutiveSummaryQueryHookResult = ReturnType<typeof useGetSupplierAccountExecutiveSummaryQuery>;
export type GetSupplierAccountExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetSupplierAccountExecutiveSummaryLazyQuery>;
export type GetSupplierAccountExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetSupplierAccountExecutiveSummaryQuery, GetSupplierAccountExecutiveSummaryQueryVariables>;
export const ClaimLatestApprovedIdPerContractDocument = gql`
    query claimLatestApprovedIDPerContract($identifier: String!) {
  cancellableClaims(identifier: $identifier)
}
    `;

/**
 * __useClaimLatestApprovedIdPerContractQuery__
 *
 * To run a query within a React component, call `useClaimLatestApprovedIdPerContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimLatestApprovedIdPerContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimLatestApprovedIdPerContractQuery({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useClaimLatestApprovedIdPerContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimLatestApprovedIdPerContractQuery, ClaimLatestApprovedIdPerContractQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimLatestApprovedIdPerContractQuery, ClaimLatestApprovedIdPerContractQueryVariables>(ClaimLatestApprovedIdPerContractDocument, baseOptions);
      }
export function useClaimLatestApprovedIdPerContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimLatestApprovedIdPerContractQuery, ClaimLatestApprovedIdPerContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimLatestApprovedIdPerContractQuery, ClaimLatestApprovedIdPerContractQueryVariables>(ClaimLatestApprovedIdPerContractDocument, baseOptions);
        }
export type ClaimLatestApprovedIdPerContractQueryHookResult = ReturnType<typeof useClaimLatestApprovedIdPerContractQuery>;
export type ClaimLatestApprovedIdPerContractLazyQueryHookResult = ReturnType<typeof useClaimLatestApprovedIdPerContractLazyQuery>;
export type ClaimLatestApprovedIdPerContractQueryResult = ApolloReactCommon.QueryResult<ClaimLatestApprovedIdPerContractQuery, ClaimLatestApprovedIdPerContractQueryVariables>;
export const ApprovableLedgerCheckingDocument = gql`
    query approvableLedgerChecking($docRefID: String, $docRefTable: String!, $docAmt: Float) {
  approvableLedgerChecking(docRefID: $docRefID, docRefTable: $docRefTable, docAmt: $docAmt) {
    isApprovable
    hasNextLevel
    hasGuideline
    validFlow
  }
}
    `;

/**
 * __useApprovableLedgerCheckingQuery__
 *
 * To run a query within a React component, call `useApprovableLedgerCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovableLedgerCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovableLedgerCheckingQuery({
 *   variables: {
 *      docRefID: // value for 'docRefID'
 *      docRefTable: // value for 'docRefTable'
 *      docAmt: // value for 'docAmt'
 *   },
 * });
 */
export function useApprovableLedgerCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovableLedgerCheckingQuery, ApprovableLedgerCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovableLedgerCheckingQuery, ApprovableLedgerCheckingQueryVariables>(ApprovableLedgerCheckingDocument, baseOptions);
      }
export function useApprovableLedgerCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovableLedgerCheckingQuery, ApprovableLedgerCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovableLedgerCheckingQuery, ApprovableLedgerCheckingQueryVariables>(ApprovableLedgerCheckingDocument, baseOptions);
        }
export type ApprovableLedgerCheckingQueryHookResult = ReturnType<typeof useApprovableLedgerCheckingQuery>;
export type ApprovableLedgerCheckingLazyQueryHookResult = ReturnType<typeof useApprovableLedgerCheckingLazyQuery>;
export type ApprovableLedgerCheckingQueryResult = ApolloReactCommon.QueryResult<ApprovableLedgerCheckingQuery, ApprovableLedgerCheckingQueryVariables>;
export const GetAdjustmentTypeDocument = gql`
    query getAdjustmentType($ID: String, $commonStatus: CommonStatus) {
  getAdjustmentType(ID: $ID, commonStatus: $commonStatus) {
    ID
    code
    description
  }
}
    `;

/**
 * __useGetAdjustmentTypeQuery__
 *
 * To run a query within a React component, call `useGetAdjustmentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdjustmentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdjustmentTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetAdjustmentTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>(GetAdjustmentTypeDocument, baseOptions);
      }
export function useGetAdjustmentTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>(GetAdjustmentTypeDocument, baseOptions);
        }
export type GetAdjustmentTypeQueryHookResult = ReturnType<typeof useGetAdjustmentTypeQuery>;
export type GetAdjustmentTypeLazyQueryHookResult = ReturnType<typeof useGetAdjustmentTypeLazyQuery>;
export type GetAdjustmentTypeQueryResult = ApolloReactCommon.QueryResult<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>;
export const GetDnItemsByDnidDocument = gql`
    query getDNItemsByDNID($dnID: String!) {
  getDNItemsByDNID(dnID: $dnID)
}
    `;

/**
 * __useGetDnItemsByDnidQuery__
 *
 * To run a query within a React component, call `useGetDnItemsByDnidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDnItemsByDnidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDnItemsByDnidQuery({
 *   variables: {
 *      dnID: // value for 'dnID'
 *   },
 * });
 */
export function useGetDnItemsByDnidQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDnItemsByDnidQuery, GetDnItemsByDnidQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDnItemsByDnidQuery, GetDnItemsByDnidQueryVariables>(GetDnItemsByDnidDocument, baseOptions);
      }
export function useGetDnItemsByDnidLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDnItemsByDnidQuery, GetDnItemsByDnidQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDnItemsByDnidQuery, GetDnItemsByDnidQueryVariables>(GetDnItemsByDnidDocument, baseOptions);
        }
export type GetDnItemsByDnidQueryHookResult = ReturnType<typeof useGetDnItemsByDnidQuery>;
export type GetDnItemsByDnidLazyQueryHookResult = ReturnType<typeof useGetDnItemsByDnidLazyQuery>;
export type GetDnItemsByDnidQueryResult = ApolloReactCommon.QueryResult<GetDnItemsByDnidQuery, GetDnItemsByDnidQueryVariables>;
export const FetchNextDocNumDocument = gql`
    query fetchNextDocNum($docCode: ContractDocNum!, $claimOnly: String, $consubconID: String, $companyID: String) {
  fetchNextDocNum(docCode: $docCode, claimOnly: $claimOnly, consubconID: $consubconID, companyID: $companyID)
}
    `;

/**
 * __useFetchNextDocNumQuery__
 *
 * To run a query within a React component, call `useFetchNextDocNumQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchNextDocNumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchNextDocNumQuery({
 *   variables: {
 *      docCode: // value for 'docCode'
 *      claimOnly: // value for 'claimOnly'
 *      consubconID: // value for 'consubconID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useFetchNextDocNumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchNextDocNumQuery, FetchNextDocNumQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchNextDocNumQuery, FetchNextDocNumQueryVariables>(FetchNextDocNumDocument, baseOptions);
      }
export function useFetchNextDocNumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchNextDocNumQuery, FetchNextDocNumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchNextDocNumQuery, FetchNextDocNumQueryVariables>(FetchNextDocNumDocument, baseOptions);
        }
export type FetchNextDocNumQueryHookResult = ReturnType<typeof useFetchNextDocNumQuery>;
export type FetchNextDocNumLazyQueryHookResult = ReturnType<typeof useFetchNextDocNumLazyQuery>;
export type FetchNextDocNumQueryResult = ApolloReactCommon.QueryResult<FetchNextDocNumQuery, FetchNextDocNumQueryVariables>;
export const FetchMultipleDocNumDocument = gql`
    query fetchMultipleDocNum($numOfDocs: Float, $docCode: ContractDocNum!, $supplierIDs: [String!]) {
  fetchMultipleDocNum(numOfDocs: $numOfDocs, docCode: $docCode, supplierIDs: $supplierIDs)
}
    `;

/**
 * __useFetchMultipleDocNumQuery__
 *
 * To run a query within a React component, call `useFetchMultipleDocNumQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMultipleDocNumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMultipleDocNumQuery({
 *   variables: {
 *      numOfDocs: // value for 'numOfDocs'
 *      docCode: // value for 'docCode'
 *      supplierIDs: // value for 'supplierIDs'
 *   },
 * });
 */
export function useFetchMultipleDocNumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchMultipleDocNumQuery, FetchMultipleDocNumQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchMultipleDocNumQuery, FetchMultipleDocNumQueryVariables>(FetchMultipleDocNumDocument, baseOptions);
      }
export function useFetchMultipleDocNumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchMultipleDocNumQuery, FetchMultipleDocNumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchMultipleDocNumQuery, FetchMultipleDocNumQueryVariables>(FetchMultipleDocNumDocument, baseOptions);
        }
export type FetchMultipleDocNumQueryHookResult = ReturnType<typeof useFetchMultipleDocNumQuery>;
export type FetchMultipleDocNumLazyQueryHookResult = ReturnType<typeof useFetchMultipleDocNumLazyQuery>;
export type FetchMultipleDocNumQueryResult = ApolloReactCommon.QueryResult<FetchMultipleDocNumQuery, FetchMultipleDocNumQueryVariables>;
export const SubmittedByLedgerDocument = gql`
    query submittedByLedger($docRefTable: String!, $docRefID: String) {
  submittedByLedger(docRefTable: $docRefTable, docRefID: $docRefID)
}
    `;

/**
 * __useSubmittedByLedgerQuery__
 *
 * To run a query within a React component, call `useSubmittedByLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedByLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedByLedgerQuery({
 *   variables: {
 *      docRefTable: // value for 'docRefTable'
 *      docRefID: // value for 'docRefID'
 *   },
 * });
 */
export function useSubmittedByLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubmittedByLedgerQuery, SubmittedByLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<SubmittedByLedgerQuery, SubmittedByLedgerQueryVariables>(SubmittedByLedgerDocument, baseOptions);
      }
export function useSubmittedByLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmittedByLedgerQuery, SubmittedByLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubmittedByLedgerQuery, SubmittedByLedgerQueryVariables>(SubmittedByLedgerDocument, baseOptions);
        }
export type SubmittedByLedgerQueryHookResult = ReturnType<typeof useSubmittedByLedgerQuery>;
export type SubmittedByLedgerLazyQueryHookResult = ReturnType<typeof useSubmittedByLedgerLazyQuery>;
export type SubmittedByLedgerQueryResult = ApolloReactCommon.QueryResult<SubmittedByLedgerQuery, SubmittedByLedgerQueryVariables>;
export const UploadIsoLogoDocument = gql`
    mutation uploadISOLogo($file: Upload) {
  uploadISOLogo(file: $file)
}
    `;
export type UploadIsoLogoMutationFn = ApolloReactCommon.MutationFunction<UploadIsoLogoMutation, UploadIsoLogoMutationVariables>;

/**
 * __useUploadIsoLogoMutation__
 *
 * To run a mutation, you first call `useUploadIsoLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadIsoLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadIsoLogoMutation, { data, loading, error }] = useUploadIsoLogoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadIsoLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadIsoLogoMutation, UploadIsoLogoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadIsoLogoMutation, UploadIsoLogoMutationVariables>(UploadIsoLogoDocument, baseOptions);
      }
export type UploadIsoLogoMutationHookResult = ReturnType<typeof useUploadIsoLogoMutation>;
export type UploadIsoLogoMutationResult = ApolloReactCommon.MutationResult<UploadIsoLogoMutation>;
export type UploadIsoLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadIsoLogoMutation, UploadIsoLogoMutationVariables>;
export const WatermarkViewDocument = gql`
    query WatermarkView($refID: String, $opacity: String) {
  WatermarkView(refID: $refID, opacity: $opacity) {
    fileName
    fileURL
    description
  }
}
    `;

/**
 * __useWatermarkViewQuery__
 *
 * To run a query within a React component, call `useWatermarkViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatermarkViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatermarkViewQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      opacity: // value for 'opacity'
 *   },
 * });
 */
export function useWatermarkViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WatermarkViewQuery, WatermarkViewQueryVariables>) {
        return ApolloReactHooks.useQuery<WatermarkViewQuery, WatermarkViewQueryVariables>(WatermarkViewDocument, baseOptions);
      }
export function useWatermarkViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WatermarkViewQuery, WatermarkViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WatermarkViewQuery, WatermarkViewQueryVariables>(WatermarkViewDocument, baseOptions);
        }
export type WatermarkViewQueryHookResult = ReturnType<typeof useWatermarkViewQuery>;
export type WatermarkViewLazyQueryHookResult = ReturnType<typeof useWatermarkViewLazyQuery>;
export type WatermarkViewQueryResult = ApolloReactCommon.QueryResult<WatermarkViewQuery, WatermarkViewQueryVariables>;
export const GetCollectionActivityDocument = gql`
    query getCollectionActivity($ID: String, $contractID: String, $customerID: String, $orderByAsc: String) {
  getCollectionActivity(ID: $ID, contractID: $contractID, customerID: $customerID, orderByAsc: $orderByAsc) {
    ID
    contactID
    contact {
      ID
      name
      designation
    }
    contractID
    contract {
      ID
      title
    }
    customerID
    customer {
      ID
      name
    }
    date
    time
    description
    remark
    activityStatus
    profileInfo
    numOfAttachment
  }
}
    `;

/**
 * __useGetCollectionActivityQuery__
 *
 * To run a query within a React component, call `useGetCollectionActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionActivityQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCollectionActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionActivityQuery, GetCollectionActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionActivityQuery, GetCollectionActivityQueryVariables>(GetCollectionActivityDocument, baseOptions);
      }
export function useGetCollectionActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionActivityQuery, GetCollectionActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionActivityQuery, GetCollectionActivityQueryVariables>(GetCollectionActivityDocument, baseOptions);
        }
export type GetCollectionActivityQueryHookResult = ReturnType<typeof useGetCollectionActivityQuery>;
export type GetCollectionActivityLazyQueryHookResult = ReturnType<typeof useGetCollectionActivityLazyQuery>;
export type GetCollectionActivityQueryResult = ApolloReactCommon.QueryResult<GetCollectionActivityQuery, GetCollectionActivityQueryVariables>;
export const CreateCollectionActivityDocument = gql`
    mutation createCollectionActivity($input: CollectionActivityInput!) {
  createCollectionActivity(input: $input)
}
    `;
export type CreateCollectionActivityMutationFn = ApolloReactCommon.MutationFunction<CreateCollectionActivityMutation, CreateCollectionActivityMutationVariables>;

/**
 * __useCreateCollectionActivityMutation__
 *
 * To run a mutation, you first call `useCreateCollectionActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionActivityMutation, { data, loading, error }] = useCreateCollectionActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectionActivityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCollectionActivityMutation, CreateCollectionActivityMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCollectionActivityMutation, CreateCollectionActivityMutationVariables>(CreateCollectionActivityDocument, baseOptions);
      }
export type CreateCollectionActivityMutationHookResult = ReturnType<typeof useCreateCollectionActivityMutation>;
export type CreateCollectionActivityMutationResult = ApolloReactCommon.MutationResult<CreateCollectionActivityMutation>;
export type CreateCollectionActivityMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCollectionActivityMutation, CreateCollectionActivityMutationVariables>;
export const CreateMultipleCollectionActivityDocument = gql`
    mutation createMultipleCollectionActivity($input: [CollectionActivityInput!]!) {
  createMultipleCollectionActivity(input: $input)
}
    `;
export type CreateMultipleCollectionActivityMutationFn = ApolloReactCommon.MutationFunction<CreateMultipleCollectionActivityMutation, CreateMultipleCollectionActivityMutationVariables>;

/**
 * __useCreateMultipleCollectionActivityMutation__
 *
 * To run a mutation, you first call `useCreateMultipleCollectionActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultipleCollectionActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultipleCollectionActivityMutation, { data, loading, error }] = useCreateMultipleCollectionActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMultipleCollectionActivityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMultipleCollectionActivityMutation, CreateMultipleCollectionActivityMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMultipleCollectionActivityMutation, CreateMultipleCollectionActivityMutationVariables>(CreateMultipleCollectionActivityDocument, baseOptions);
      }
export type CreateMultipleCollectionActivityMutationHookResult = ReturnType<typeof useCreateMultipleCollectionActivityMutation>;
export type CreateMultipleCollectionActivityMutationResult = ApolloReactCommon.MutationResult<CreateMultipleCollectionActivityMutation>;
export type CreateMultipleCollectionActivityMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMultipleCollectionActivityMutation, CreateMultipleCollectionActivityMutationVariables>;
export const DeleteCollectionActivityDocument = gql`
    mutation deleteCollectionActivity($ID: CollectionActivityDeleteID!) {
  deleteCollectionActivity(input: $ID)
}
    `;
export type DeleteCollectionActivityMutationFn = ApolloReactCommon.MutationFunction<DeleteCollectionActivityMutation, DeleteCollectionActivityMutationVariables>;

/**
 * __useDeleteCollectionActivityMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionActivityMutation, { data, loading, error }] = useDeleteCollectionActivityMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteCollectionActivityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCollectionActivityMutation, DeleteCollectionActivityMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCollectionActivityMutation, DeleteCollectionActivityMutationVariables>(DeleteCollectionActivityDocument, baseOptions);
      }
export type DeleteCollectionActivityMutationHookResult = ReturnType<typeof useDeleteCollectionActivityMutation>;
export type DeleteCollectionActivityMutationResult = ApolloReactCommon.MutationResult<DeleteCollectionActivityMutation>;
export type DeleteCollectionActivityMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCollectionActivityMutation, DeleteCollectionActivityMutationVariables>;
export const UpdateCollectionActivityDocument = gql`
    mutation updateCollectionActivity($input: CollectionActivityInput!) {
  updateCollectionActivity(input: $input)
}
    `;
export type UpdateCollectionActivityMutationFn = ApolloReactCommon.MutationFunction<UpdateCollectionActivityMutation, UpdateCollectionActivityMutationVariables>;

/**
 * __useUpdateCollectionActivityMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionActivityMutation, { data, loading, error }] = useUpdateCollectionActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionActivityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCollectionActivityMutation, UpdateCollectionActivityMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCollectionActivityMutation, UpdateCollectionActivityMutationVariables>(UpdateCollectionActivityDocument, baseOptions);
      }
export type UpdateCollectionActivityMutationHookResult = ReturnType<typeof useUpdateCollectionActivityMutation>;
export type UpdateCollectionActivityMutationResult = ApolloReactCommon.MutationResult<UpdateCollectionActivityMutation>;
export type UpdateCollectionActivityMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCollectionActivityMutation, UpdateCollectionActivityMutationVariables>;
export const GetClientDebitDocListingDocument = gql`
    query getClientDebitDocListing($companyID: String, $contractID: String, $customerID: String, $startPeriod: Float!, $endPeriod: Float!) {
  getClientDebitDocListing(companyID: $companyID, contractID: $contractID, customerID: $customerID, startPeriod: $startPeriod, endPeriod: $endPeriod)
}
    `;

/**
 * __useGetClientDebitDocListingQuery__
 *
 * To run a query within a React component, call `useGetClientDebitDocListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientDebitDocListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientDebitDocListingQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      startPeriod: // value for 'startPeriod'
 *      endPeriod: // value for 'endPeriod'
 *   },
 * });
 */
export function useGetClientDebitDocListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientDebitDocListingQuery, GetClientDebitDocListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientDebitDocListingQuery, GetClientDebitDocListingQueryVariables>(GetClientDebitDocListingDocument, baseOptions);
      }
export function useGetClientDebitDocListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientDebitDocListingQuery, GetClientDebitDocListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientDebitDocListingQuery, GetClientDebitDocListingQueryVariables>(GetClientDebitDocListingDocument, baseOptions);
        }
export type GetClientDebitDocListingQueryHookResult = ReturnType<typeof useGetClientDebitDocListingQuery>;
export type GetClientDebitDocListingLazyQueryHookResult = ReturnType<typeof useGetClientDebitDocListingLazyQuery>;
export type GetClientDebitDocListingQueryResult = ApolloReactCommon.QueryResult<GetClientDebitDocListingQuery, GetClientDebitDocListingQueryVariables>;
export const UpdateCollectionStatusDocument = gql`
    mutation updateCollectionStatus($input: CollectionActivityStatusInput!) {
  updateCollectionStatus(input: $input)
}
    `;
export type UpdateCollectionStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateCollectionStatusMutation, UpdateCollectionStatusMutationVariables>;

/**
 * __useUpdateCollectionStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionStatusMutation, { data, loading, error }] = useUpdateCollectionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCollectionStatusMutation, UpdateCollectionStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCollectionStatusMutation, UpdateCollectionStatusMutationVariables>(UpdateCollectionStatusDocument, baseOptions);
      }
export type UpdateCollectionStatusMutationHookResult = ReturnType<typeof useUpdateCollectionStatusMutation>;
export type UpdateCollectionStatusMutationResult = ApolloReactCommon.MutationResult<UpdateCollectionStatusMutation>;
export type UpdateCollectionStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCollectionStatusMutation, UpdateCollectionStatusMutationVariables>;
export const AddContractContactDocument = gql`
    mutation addContractContact($input: ContactPersonInput!, $contractID: String!) {
  addContractContact(input: $input, contractID: $contractID)
}
    `;
export type AddContractContactMutationFn = ApolloReactCommon.MutationFunction<AddContractContactMutation, AddContractContactMutationVariables>;

/**
 * __useAddContractContactMutation__
 *
 * To run a mutation, you first call `useAddContractContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContractContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContractContactMutation, { data, loading, error }] = useAddContractContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useAddContractContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddContractContactMutation, AddContractContactMutationVariables>) {
        return ApolloReactHooks.useMutation<AddContractContactMutation, AddContractContactMutationVariables>(AddContractContactDocument, baseOptions);
      }
export type AddContractContactMutationHookResult = ReturnType<typeof useAddContractContactMutation>;
export type AddContractContactMutationResult = ApolloReactCommon.MutationResult<AddContractContactMutation>;
export type AddContractContactMutationOptions = ApolloReactCommon.BaseMutationOptions<AddContractContactMutation, AddContractContactMutationVariables>;
export const DeleteColActivityDocument = gql`
    mutation deleteColActivity($collectionActivityID: String!) {
  deleteColActivity(collectionActivityID: $collectionActivityID)
}
    `;
export type DeleteColActivityMutationFn = ApolloReactCommon.MutationFunction<DeleteColActivityMutation, DeleteColActivityMutationVariables>;

/**
 * __useDeleteColActivityMutation__
 *
 * To run a mutation, you first call `useDeleteColActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteColActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteColActivityMutation, { data, loading, error }] = useDeleteColActivityMutation({
 *   variables: {
 *      collectionActivityID: // value for 'collectionActivityID'
 *   },
 * });
 */
export function useDeleteColActivityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteColActivityMutation, DeleteColActivityMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteColActivityMutation, DeleteColActivityMutationVariables>(DeleteColActivityDocument, baseOptions);
      }
export type DeleteColActivityMutationHookResult = ReturnType<typeof useDeleteColActivityMutation>;
export type DeleteColActivityMutationResult = ApolloReactCommon.MutationResult<DeleteColActivityMutation>;
export type DeleteColActivityMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteColActivityMutation, DeleteColActivityMutationVariables>;
export const CreditControlCustomerDocument = gql`
    query creditControlCustomer($ID: String, $companyID: String, $orderByAsc: String) {
  getCustomer(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    activeContract(companyID: $companyID)
    associatedStatus
    overdueInvInfo
    overdueInvInfoNoBound(companyID: $companyID)
    clientReceiptInfo
    clientAgingInfo(companyID: $companyID)
    currencyID
    followUp {
      createdTs
    }
  }
}
    `;

/**
 * __useCreditControlCustomerQuery__
 *
 * To run a query within a React component, call `useCreditControlCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditControlCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditControlCustomerQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useCreditControlCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>(CreditControlCustomerDocument, baseOptions);
      }
export function useCreditControlCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>(CreditControlCustomerDocument, baseOptions);
        }
export type CreditControlCustomerQueryHookResult = ReturnType<typeof useCreditControlCustomerQuery>;
export type CreditControlCustomerLazyQueryHookResult = ReturnType<typeof useCreditControlCustomerLazyQuery>;
export type CreditControlCustomerQueryResult = ApolloReactCommon.QueryResult<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>;
export const GetCustomerCollectionActivityDocument = gql`
    query getCustomerCollectionActivity($ID: String, $companyID: String, $associatedStatus: AssociatedStatus, $orderByAsc: String, $orderByDesc: String, $take: Float) {
  getCustomer(ID: $ID, associatedStatus: $associatedStatus, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take) {
    ID
    name
    associatedStatus
    clientAgingInfo(companyID: $companyID)
    latestCollectionActivity
    collectionActivity {
      ID
      date
      remark
    }
  }
}
    `;

/**
 * __useGetCustomerCollectionActivityQuery__
 *
 * To run a query within a React component, call `useGetCustomerCollectionActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerCollectionActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerCollectionActivityQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      associatedStatus: // value for 'associatedStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetCustomerCollectionActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerCollectionActivityQuery, GetCustomerCollectionActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerCollectionActivityQuery, GetCustomerCollectionActivityQueryVariables>(GetCustomerCollectionActivityDocument, baseOptions);
      }
export function useGetCustomerCollectionActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerCollectionActivityQuery, GetCustomerCollectionActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerCollectionActivityQuery, GetCustomerCollectionActivityQueryVariables>(GetCustomerCollectionActivityDocument, baseOptions);
        }
export type GetCustomerCollectionActivityQueryHookResult = ReturnType<typeof useGetCustomerCollectionActivityQuery>;
export type GetCustomerCollectionActivityLazyQueryHookResult = ReturnType<typeof useGetCustomerCollectionActivityLazyQuery>;
export type GetCustomerCollectionActivityQueryResult = ApolloReactCommon.QueryResult<GetCustomerCollectionActivityQuery, GetCustomerCollectionActivityQueryVariables>;
export const GetCustomerByCompanyCollectionActivityDocument = gql`
    query getCustomerByCompanyCollectionActivity($companyID: String!, $withoutCompany: Boolean) {
  getCustomerByCompany(companyID: $companyID, withoutCompany: $withoutCompany) {
    ID
    name
    associatedStatus
    clientAgingInfo(companyID: $companyID)
    latestCollectionActivity
    collectionActivity {
      ID
      date
      remark
    }
  }
}
    `;

/**
 * __useGetCustomerByCompanyCollectionActivityQuery__
 *
 * To run a query within a React component, call `useGetCustomerByCompanyCollectionActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerByCompanyCollectionActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerByCompanyCollectionActivityQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      withoutCompany: // value for 'withoutCompany'
 *   },
 * });
 */
export function useGetCustomerByCompanyCollectionActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerByCompanyCollectionActivityQuery, GetCustomerByCompanyCollectionActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerByCompanyCollectionActivityQuery, GetCustomerByCompanyCollectionActivityQueryVariables>(GetCustomerByCompanyCollectionActivityDocument, baseOptions);
      }
export function useGetCustomerByCompanyCollectionActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerByCompanyCollectionActivityQuery, GetCustomerByCompanyCollectionActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerByCompanyCollectionActivityQuery, GetCustomerByCompanyCollectionActivityQueryVariables>(GetCustomerByCompanyCollectionActivityDocument, baseOptions);
        }
export type GetCustomerByCompanyCollectionActivityQueryHookResult = ReturnType<typeof useGetCustomerByCompanyCollectionActivityQuery>;
export type GetCustomerByCompanyCollectionActivityLazyQueryHookResult = ReturnType<typeof useGetCustomerByCompanyCollectionActivityLazyQuery>;
export type GetCustomerByCompanyCollectionActivityQueryResult = ApolloReactCommon.QueryResult<GetCustomerByCompanyCollectionActivityQuery, GetCustomerByCompanyCollectionActivityQueryVariables>;
export const GetContractCollectionActivityDocument = gql`
    query getContractCollectionActivity($ID: String, $customerID: String, $companyID: String, $selectedDate: String, $contractStatus: ContractStatus, $orderByAsc: String, $orderByDesc: String, $take: Float) {
  getContract(ID: $ID, customerID: $customerID, companyID: $companyID, contractStatus: $contractStatus, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take) {
    ID
    customerID
    title
    contractNo
    contractDate
    cmgdDate
    cpcDate
    dlpDate
    revisedContractSum
    contractSum
    contractStatus
    contractAgingInfo
    customerDetail {
      name
    }
    latestCollectionActivity(selectedDate: $selectedDate) {
      ID
      date
      time
      remark
      description
      activityStatus
      profileInfo
      numOfAttachment
      contactID
      contact {
        ID
        name
        email
        designation
        contactNo
      }
    }
    collectionActivity {
      ID
      date
      remark
    }
  }
}
    `;

/**
 * __useGetContractCollectionActivityQuery__
 *
 * To run a query within a React component, call `useGetContractCollectionActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCollectionActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCollectionActivityQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      selectedDate: // value for 'selectedDate'
 *      contractStatus: // value for 'contractStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetContractCollectionActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractCollectionActivityQuery, GetContractCollectionActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractCollectionActivityQuery, GetContractCollectionActivityQueryVariables>(GetContractCollectionActivityDocument, baseOptions);
      }
export function useGetContractCollectionActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractCollectionActivityQuery, GetContractCollectionActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractCollectionActivityQuery, GetContractCollectionActivityQueryVariables>(GetContractCollectionActivityDocument, baseOptions);
        }
export type GetContractCollectionActivityQueryHookResult = ReturnType<typeof useGetContractCollectionActivityQuery>;
export type GetContractCollectionActivityLazyQueryHookResult = ReturnType<typeof useGetContractCollectionActivityLazyQuery>;
export type GetContractCollectionActivityQueryResult = ApolloReactCommon.QueryResult<GetContractCollectionActivityQuery, GetContractCollectionActivityQueryVariables>;
export const GetCustomerFollowUpLogDocument = gql`
    query getCustomerFollowUpLog($ID: String) {
  getCustomer(ID: $ID) {
    ID
    name
    contactNo
    contactPerson {
      ID
      name
      designation
      email
      contactNo
    }
    followUp {
      ID
      remarks
      contactPerson
      followUpStatus
      createdTs
    }
  }
}
    `;

/**
 * __useGetCustomerFollowUpLogQuery__
 *
 * To run a query within a React component, call `useGetCustomerFollowUpLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerFollowUpLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerFollowUpLogQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCustomerFollowUpLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>(GetCustomerFollowUpLogDocument, baseOptions);
      }
export function useGetCustomerFollowUpLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>(GetCustomerFollowUpLogDocument, baseOptions);
        }
export type GetCustomerFollowUpLogQueryHookResult = ReturnType<typeof useGetCustomerFollowUpLogQuery>;
export type GetCustomerFollowUpLogLazyQueryHookResult = ReturnType<typeof useGetCustomerFollowUpLogLazyQuery>;
export type GetCustomerFollowUpLogQueryResult = ApolloReactCommon.QueryResult<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>;
export const CreateFollowUpDocument = gql`
    mutation createFollowUp($input: FollowUpInput!) {
  createFollowUp(input: $input) {
    ID
  }
}
    `;
export type CreateFollowUpMutationFn = ApolloReactCommon.MutationFunction<CreateFollowUpMutation, CreateFollowUpMutationVariables>;

/**
 * __useCreateFollowUpMutation__
 *
 * To run a mutation, you first call `useCreateFollowUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFollowUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFollowUpMutation, { data, loading, error }] = useCreateFollowUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFollowUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFollowUpMutation, CreateFollowUpMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFollowUpMutation, CreateFollowUpMutationVariables>(CreateFollowUpDocument, baseOptions);
      }
export type CreateFollowUpMutationHookResult = ReturnType<typeof useCreateFollowUpMutation>;
export type CreateFollowUpMutationResult = ApolloReactCommon.MutationResult<CreateFollowUpMutation>;
export type CreateFollowUpMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFollowUpMutation, CreateFollowUpMutationVariables>;
export const GetCreditControlSummaryDocument = gql`
    query getCreditControlSummary {
  pendingCollectionActivity
  paymentReminderSummary
  totalOutstandingClients
  getCustomer {
    ID
    clientAgingInfo
  }
}
    `;

/**
 * __useGetCreditControlSummaryQuery__
 *
 * To run a query within a React component, call `useGetCreditControlSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditControlSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditControlSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCreditControlSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditControlSummaryQuery, GetCreditControlSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditControlSummaryQuery, GetCreditControlSummaryQueryVariables>(GetCreditControlSummaryDocument, baseOptions);
      }
export function useGetCreditControlSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditControlSummaryQuery, GetCreditControlSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditControlSummaryQuery, GetCreditControlSummaryQueryVariables>(GetCreditControlSummaryDocument, baseOptions);
        }
export type GetCreditControlSummaryQueryHookResult = ReturnType<typeof useGetCreditControlSummaryQuery>;
export type GetCreditControlSummaryLazyQueryHookResult = ReturnType<typeof useGetCreditControlSummaryLazyQuery>;
export type GetCreditControlSummaryQueryResult = ApolloReactCommon.QueryResult<GetCreditControlSummaryQuery, GetCreditControlSummaryQueryVariables>;
export const GetCreditControlCompanySummaryDocument = gql`
    query getCreditControlCompanySummary($companyID: String) {
  pendingCollectionActivity(companyID: $companyID)
  paymentReminderSummary(companyID: $companyID)
  totalOutstandingClients(companyID: $companyID)
  totalOutstandingAmtClients(companyID: $companyID)
}
    `;

/**
 * __useGetCreditControlCompanySummaryQuery__
 *
 * To run a query within a React component, call `useGetCreditControlCompanySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditControlCompanySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditControlCompanySummaryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetCreditControlCompanySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditControlCompanySummaryQuery, GetCreditControlCompanySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditControlCompanySummaryQuery, GetCreditControlCompanySummaryQueryVariables>(GetCreditControlCompanySummaryDocument, baseOptions);
      }
export function useGetCreditControlCompanySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditControlCompanySummaryQuery, GetCreditControlCompanySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditControlCompanySummaryQuery, GetCreditControlCompanySummaryQueryVariables>(GetCreditControlCompanySummaryDocument, baseOptions);
        }
export type GetCreditControlCompanySummaryQueryHookResult = ReturnType<typeof useGetCreditControlCompanySummaryQuery>;
export type GetCreditControlCompanySummaryLazyQueryHookResult = ReturnType<typeof useGetCreditControlCompanySummaryLazyQuery>;
export type GetCreditControlCompanySummaryQueryResult = ApolloReactCommon.QueryResult<GetCreditControlCompanySummaryQuery, GetCreditControlCompanySummaryQueryVariables>;
export const GetPendingCreditControlDocument = gql`
    query getPendingCreditControl($companyID: String) {
  pendingCollectionActivity(companyID: $companyID)
}
    `;

/**
 * __useGetPendingCreditControlQuery__
 *
 * To run a query within a React component, call `useGetPendingCreditControlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingCreditControlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingCreditControlQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetPendingCreditControlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPendingCreditControlQuery, GetPendingCreditControlQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPendingCreditControlQuery, GetPendingCreditControlQueryVariables>(GetPendingCreditControlDocument, baseOptions);
      }
export function useGetPendingCreditControlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPendingCreditControlQuery, GetPendingCreditControlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPendingCreditControlQuery, GetPendingCreditControlQueryVariables>(GetPendingCreditControlDocument, baseOptions);
        }
export type GetPendingCreditControlQueryHookResult = ReturnType<typeof useGetPendingCreditControlQuery>;
export type GetPendingCreditControlLazyQueryHookResult = ReturnType<typeof useGetPendingCreditControlLazyQuery>;
export type GetPendingCreditControlQueryResult = ApolloReactCommon.QueryResult<GetPendingCreditControlQuery, GetPendingCreditControlQueryVariables>;
export const GetPaymentReminderDocument = gql`
    query getPaymentReminder($contractID: String, $customerID: String, $ID: String, $orderByAsc: String) {
  getPaymentReminder(contractID: $contractID, customerID: $customerID, ID: $ID, orderByAsc: $orderByAsc) {
    ID
    reminderDate
    commonStatus
    docReminder {
      ID
      paymentReminderID
      customerID
      docRefTable
      docRefID
      reminderSeq
      outstandingAmt
    }
    payReminderTotal
  }
}
    `;

/**
 * __useGetPaymentReminderQuery__
 *
 * To run a query within a React component, call `useGetPaymentReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentReminderQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetPaymentReminderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentReminderQuery, GetPaymentReminderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentReminderQuery, GetPaymentReminderQueryVariables>(GetPaymentReminderDocument, baseOptions);
      }
export function useGetPaymentReminderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentReminderQuery, GetPaymentReminderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentReminderQuery, GetPaymentReminderQueryVariables>(GetPaymentReminderDocument, baseOptions);
        }
export type GetPaymentReminderQueryHookResult = ReturnType<typeof useGetPaymentReminderQuery>;
export type GetPaymentReminderLazyQueryHookResult = ReturnType<typeof useGetPaymentReminderLazyQuery>;
export type GetPaymentReminderQueryResult = ApolloReactCommon.QueryResult<GetPaymentReminderQuery, GetPaymentReminderQueryVariables>;
export const GetCustomerReminderDocument = gql`
    query getCustomerReminder($customerID: String) {
  getCustomerReminder {
    ID
    paymentReminder1
    paymentReminder2
    paymentReminder3
    paymentReminder4
    minimumAmt
    reminderFor
    printedCopy
    emailCopy
    commonStatus
  }
  getPaymentReminderLastDate(customerID: $customerID)
}
    `;

/**
 * __useGetCustomerReminderQuery__
 *
 * To run a query within a React component, call `useGetCustomerReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerReminderQuery({
 *   variables: {
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetCustomerReminderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerReminderQuery, GetCustomerReminderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerReminderQuery, GetCustomerReminderQueryVariables>(GetCustomerReminderDocument, baseOptions);
      }
export function useGetCustomerReminderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerReminderQuery, GetCustomerReminderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerReminderQuery, GetCustomerReminderQueryVariables>(GetCustomerReminderDocument, baseOptions);
        }
export type GetCustomerReminderQueryHookResult = ReturnType<typeof useGetCustomerReminderQuery>;
export type GetCustomerReminderLazyQueryHookResult = ReturnType<typeof useGetCustomerReminderLazyQuery>;
export type GetCustomerReminderQueryResult = ApolloReactCommon.QueryResult<GetCustomerReminderQuery, GetCustomerReminderQueryVariables>;
export const GetDebitDocDocument = gql`
    query getDebitDoc($contractID: String, $customerID: String, $companyID: String, $date: DateTime!) {
  getDebitDoc(contractID: $contractID, customerID: $customerID, companyID: $companyID, date: $date)
}
    `;

/**
 * __useGetDebitDocQuery__
 *
 * To run a query within a React component, call `useGetDebitDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebitDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebitDocQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetDebitDocQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDebitDocQuery, GetDebitDocQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDebitDocQuery, GetDebitDocQueryVariables>(GetDebitDocDocument, baseOptions);
      }
export function useGetDebitDocLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDebitDocQuery, GetDebitDocQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDebitDocQuery, GetDebitDocQueryVariables>(GetDebitDocDocument, baseOptions);
        }
export type GetDebitDocQueryHookResult = ReturnType<typeof useGetDebitDocQuery>;
export type GetDebitDocLazyQueryHookResult = ReturnType<typeof useGetDebitDocLazyQuery>;
export type GetDebitDocQueryResult = ApolloReactCommon.QueryResult<GetDebitDocQuery, GetDebitDocQueryVariables>;
export const CreateCustomerReminderDocument = gql`
    mutation createCustomerReminder($inputReminder: CustomerReminderInput!) {
  createCustomerReminder(inputReminder: $inputReminder)
}
    `;
export type CreateCustomerReminderMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerReminderMutation, CreateCustomerReminderMutationVariables>;

/**
 * __useCreateCustomerReminderMutation__
 *
 * To run a mutation, you first call `useCreateCustomerReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerReminderMutation, { data, loading, error }] = useCreateCustomerReminderMutation({
 *   variables: {
 *      inputReminder: // value for 'inputReminder'
 *   },
 * });
 */
export function useCreateCustomerReminderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerReminderMutation, CreateCustomerReminderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerReminderMutation, CreateCustomerReminderMutationVariables>(CreateCustomerReminderDocument, baseOptions);
      }
export type CreateCustomerReminderMutationHookResult = ReturnType<typeof useCreateCustomerReminderMutation>;
export type CreateCustomerReminderMutationResult = ApolloReactCommon.MutationResult<CreateCustomerReminderMutation>;
export type CreateCustomerReminderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerReminderMutation, CreateCustomerReminderMutationVariables>;
export const CreatePaymentReminderDocument = gql`
    mutation createPaymentReminder($input: PaymentReminderInput!, $docReminder: [DocReminderInput!]!) {
  createPaymentReminder(input: $input, docReminder: $docReminder)
}
    `;
export type CreatePaymentReminderMutationFn = ApolloReactCommon.MutationFunction<CreatePaymentReminderMutation, CreatePaymentReminderMutationVariables>;

/**
 * __useCreatePaymentReminderMutation__
 *
 * To run a mutation, you first call `useCreatePaymentReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentReminderMutation, { data, loading, error }] = useCreatePaymentReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      docReminder: // value for 'docReminder'
 *   },
 * });
 */
export function useCreatePaymentReminderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentReminderMutation, CreatePaymentReminderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePaymentReminderMutation, CreatePaymentReminderMutationVariables>(CreatePaymentReminderDocument, baseOptions);
      }
export type CreatePaymentReminderMutationHookResult = ReturnType<typeof useCreatePaymentReminderMutation>;
export type CreatePaymentReminderMutationResult = ApolloReactCommon.MutationResult<CreatePaymentReminderMutation>;
export type CreatePaymentReminderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePaymentReminderMutation, CreatePaymentReminderMutationVariables>;
export const CancelPaymentReminderDocument = gql`
    mutation cancelPaymentReminder($ID: String!) {
  cancelPaymentReminder(ID: $ID)
}
    `;
export type CancelPaymentReminderMutationFn = ApolloReactCommon.MutationFunction<CancelPaymentReminderMutation, CancelPaymentReminderMutationVariables>;

/**
 * __useCancelPaymentReminderMutation__
 *
 * To run a mutation, you first call `useCancelPaymentReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPaymentReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPaymentReminderMutation, { data, loading, error }] = useCancelPaymentReminderMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelPaymentReminderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelPaymentReminderMutation, CancelPaymentReminderMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelPaymentReminderMutation, CancelPaymentReminderMutationVariables>(CancelPaymentReminderDocument, baseOptions);
      }
export type CancelPaymentReminderMutationHookResult = ReturnType<typeof useCancelPaymentReminderMutation>;
export type CancelPaymentReminderMutationResult = ApolloReactCommon.MutationResult<CancelPaymentReminderMutation>;
export type CancelPaymentReminderMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelPaymentReminderMutation, CancelPaymentReminderMutationVariables>;
export const GetPrintReminderDocument = gql`
    query getPrintReminder($customerID: String, $contractID: String, $paymentReminderID: String!) {
  getPrintReminder(customerID: $customerID, contractID: $contractID, paymentReminderID: $paymentReminderID)
}
    `;

/**
 * __useGetPrintReminderQuery__
 *
 * To run a query within a React component, call `useGetPrintReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintReminderQuery({
 *   variables: {
 *      customerID: // value for 'customerID'
 *      contractID: // value for 'contractID'
 *      paymentReminderID: // value for 'paymentReminderID'
 *   },
 * });
 */
export function useGetPrintReminderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrintReminderQuery, GetPrintReminderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrintReminderQuery, GetPrintReminderQueryVariables>(GetPrintReminderDocument, baseOptions);
      }
export function useGetPrintReminderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrintReminderQuery, GetPrintReminderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrintReminderQuery, GetPrintReminderQueryVariables>(GetPrintReminderDocument, baseOptions);
        }
export type GetPrintReminderQueryHookResult = ReturnType<typeof useGetPrintReminderQuery>;
export type GetPrintReminderLazyQueryHookResult = ReturnType<typeof useGetPrintReminderLazyQuery>;
export type GetPrintReminderQueryResult = ApolloReactCommon.QueryResult<GetPrintReminderQuery, GetPrintReminderQueryVariables>;
export const GetPaymentReminderConCustDocument = gql`
    query getPaymentReminderConCust($contractID: String, $customerID: String, $ID: String) {
  getPaymentReminderConCust(contractID: $contractID, customerID: $customerID, ID: $ID)
}
    `;

/**
 * __useGetPaymentReminderConCustQuery__
 *
 * To run a query within a React component, call `useGetPaymentReminderConCustQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentReminderConCustQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentReminderConCustQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetPaymentReminderConCustQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentReminderConCustQuery, GetPaymentReminderConCustQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentReminderConCustQuery, GetPaymentReminderConCustQueryVariables>(GetPaymentReminderConCustDocument, baseOptions);
      }
export function useGetPaymentReminderConCustLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentReminderConCustQuery, GetPaymentReminderConCustQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentReminderConCustQuery, GetPaymentReminderConCustQueryVariables>(GetPaymentReminderConCustDocument, baseOptions);
        }
export type GetPaymentReminderConCustQueryHookResult = ReturnType<typeof useGetPaymentReminderConCustQuery>;
export type GetPaymentReminderConCustLazyQueryHookResult = ReturnType<typeof useGetPaymentReminderConCustLazyQuery>;
export type GetPaymentReminderConCustQueryResult = ApolloReactCommon.QueryResult<GetPaymentReminderConCustQuery, GetPaymentReminderConCustQueryVariables>;
export const GetDocReminderDetailsDocument = gql`
    query getDocReminderDetails($paymentReminderID: String!, $companyID: String) {
  getDocReminderDetails(paymentReminderID: $paymentReminderID, companyID: $companyID)
}
    `;

/**
 * __useGetDocReminderDetailsQuery__
 *
 * To run a query within a React component, call `useGetDocReminderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocReminderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocReminderDetailsQuery({
 *   variables: {
 *      paymentReminderID: // value for 'paymentReminderID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetDocReminderDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocReminderDetailsQuery, GetDocReminderDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocReminderDetailsQuery, GetDocReminderDetailsQueryVariables>(GetDocReminderDetailsDocument, baseOptions);
      }
export function useGetDocReminderDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocReminderDetailsQuery, GetDocReminderDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocReminderDetailsQuery, GetDocReminderDetailsQueryVariables>(GetDocReminderDetailsDocument, baseOptions);
        }
export type GetDocReminderDetailsQueryHookResult = ReturnType<typeof useGetDocReminderDetailsQuery>;
export type GetDocReminderDetailsLazyQueryHookResult = ReturnType<typeof useGetDocReminderDetailsLazyQuery>;
export type GetDocReminderDetailsQueryResult = ApolloReactCommon.QueryResult<GetDocReminderDetailsQuery, GetDocReminderDetailsQueryVariables>;
export const GetPaymentReminderByCompanyDocument = gql`
    query getPaymentReminderByCompany($companyID: String, $paymentReminderID: String) {
  getPaymentReminderByCompany(companyID: $companyID, paymentReminderID: $paymentReminderID)
}
    `;

/**
 * __useGetPaymentReminderByCompanyQuery__
 *
 * To run a query within a React component, call `useGetPaymentReminderByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentReminderByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentReminderByCompanyQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      paymentReminderID: // value for 'paymentReminderID'
 *   },
 * });
 */
export function useGetPaymentReminderByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentReminderByCompanyQuery, GetPaymentReminderByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentReminderByCompanyQuery, GetPaymentReminderByCompanyQueryVariables>(GetPaymentReminderByCompanyDocument, baseOptions);
      }
export function useGetPaymentReminderByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentReminderByCompanyQuery, GetPaymentReminderByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentReminderByCompanyQuery, GetPaymentReminderByCompanyQueryVariables>(GetPaymentReminderByCompanyDocument, baseOptions);
        }
export type GetPaymentReminderByCompanyQueryHookResult = ReturnType<typeof useGetPaymentReminderByCompanyQuery>;
export type GetPaymentReminderByCompanyLazyQueryHookResult = ReturnType<typeof useGetPaymentReminderByCompanyLazyQuery>;
export type GetPaymentReminderByCompanyQueryResult = ApolloReactCommon.QueryResult<GetPaymentReminderByCompanyQuery, GetPaymentReminderByCompanyQueryVariables>;
export const GetStatementAcctMonthsDocument = gql`
    query getStatementAcctMonths($ID: String!) {
  getStatementAcctMonths(ID: $ID)
}
    `;

/**
 * __useGetStatementAcctMonthsQuery__
 *
 * To run a query within a React component, call `useGetStatementAcctMonthsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatementAcctMonthsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatementAcctMonthsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetStatementAcctMonthsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStatementAcctMonthsQuery, GetStatementAcctMonthsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStatementAcctMonthsQuery, GetStatementAcctMonthsQueryVariables>(GetStatementAcctMonthsDocument, baseOptions);
      }
export function useGetStatementAcctMonthsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStatementAcctMonthsQuery, GetStatementAcctMonthsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStatementAcctMonthsQuery, GetStatementAcctMonthsQueryVariables>(GetStatementAcctMonthsDocument, baseOptions);
        }
export type GetStatementAcctMonthsQueryHookResult = ReturnType<typeof useGetStatementAcctMonthsQuery>;
export type GetStatementAcctMonthsLazyQueryHookResult = ReturnType<typeof useGetStatementAcctMonthsLazyQuery>;
export type GetStatementAcctMonthsQueryResult = ApolloReactCommon.QueryResult<GetStatementAcctMonthsQuery, GetStatementAcctMonthsQueryVariables>;
export const GetClaimRecListByMonthDocument = gql`
    query getClaimRecListByMonth($ID: String!, $month: DateTime!) {
  getClaimRecListByMonth(ID: $ID, month: $month)
}
    `;

/**
 * __useGetClaimRecListByMonthQuery__
 *
 * To run a query within a React component, call `useGetClaimRecListByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimRecListByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimRecListByMonthQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useGetClaimRecListByMonthQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimRecListByMonthQuery, GetClaimRecListByMonthQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimRecListByMonthQuery, GetClaimRecListByMonthQueryVariables>(GetClaimRecListByMonthDocument, baseOptions);
      }
export function useGetClaimRecListByMonthLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimRecListByMonthQuery, GetClaimRecListByMonthQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimRecListByMonthQuery, GetClaimRecListByMonthQueryVariables>(GetClaimRecListByMonthDocument, baseOptions);
        }
export type GetClaimRecListByMonthQueryHookResult = ReturnType<typeof useGetClaimRecListByMonthQuery>;
export type GetClaimRecListByMonthLazyQueryHookResult = ReturnType<typeof useGetClaimRecListByMonthLazyQuery>;
export type GetClaimRecListByMonthQueryResult = ApolloReactCommon.QueryResult<GetClaimRecListByMonthQuery, GetClaimRecListByMonthQueryVariables>;
export const GetCustContractIDsDocument = gql`
    query getCustContractIDs($customerID: String!) {
  getCustContractIDs(customerID: $customerID)
}
    `;

/**
 * __useGetCustContractIDsQuery__
 *
 * To run a query within a React component, call `useGetCustContractIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustContractIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustContractIDsQuery({
 *   variables: {
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetCustContractIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustContractIDsQuery, GetCustContractIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustContractIDsQuery, GetCustContractIDsQueryVariables>(GetCustContractIDsDocument, baseOptions);
      }
export function useGetCustContractIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustContractIDsQuery, GetCustContractIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustContractIDsQuery, GetCustContractIDsQueryVariables>(GetCustContractIDsDocument, baseOptions);
        }
export type GetCustContractIDsQueryHookResult = ReturnType<typeof useGetCustContractIDsQuery>;
export type GetCustContractIDsLazyQueryHookResult = ReturnType<typeof useGetCustContractIDsLazyQuery>;
export type GetCustContractIDsQueryResult = ApolloReactCommon.QueryResult<GetCustContractIDsQuery, GetCustContractIDsQueryVariables>;
export const GetSoaClientDocument = gql`
    query getSOAClient($companyID: String, $orderByAsc: String) {
  getCustomer(orderByAsc: $orderByAsc) {
    ID
    name
    associatedStatus
    overdueInvInfoNoBound(companyID: $companyID)
    contract {
      ID
      title
    }
  }
}
    `;

/**
 * __useGetSoaClientQuery__
 *
 * To run a query within a React component, call `useGetSoaClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoaClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoaClientQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSoaClientQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSoaClientQuery, GetSoaClientQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSoaClientQuery, GetSoaClientQueryVariables>(GetSoaClientDocument, baseOptions);
      }
export function useGetSoaClientLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSoaClientQuery, GetSoaClientQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSoaClientQuery, GetSoaClientQueryVariables>(GetSoaClientDocument, baseOptions);
        }
export type GetSoaClientQueryHookResult = ReturnType<typeof useGetSoaClientQuery>;
export type GetSoaClientLazyQueryHookResult = ReturnType<typeof useGetSoaClientLazyQuery>;
export type GetSoaClientQueryResult = ApolloReactCommon.QueryResult<GetSoaClientQuery, GetSoaClientQueryVariables>;
export const GetSoaClientByCompanyDocument = gql`
    query getSOAClientByCompany($companyID: String!, $withoutCompany: Boolean) {
  getCustomerByCompany(companyID: $companyID, withoutCompany: $withoutCompany) {
    ID
    name
    associatedStatus
    overdueInvInfoNoBound(companyID: $companyID)
    contract {
      ID
      title
    }
  }
}
    `;

/**
 * __useGetSoaClientByCompanyQuery__
 *
 * To run a query within a React component, call `useGetSoaClientByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoaClientByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoaClientByCompanyQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      withoutCompany: // value for 'withoutCompany'
 *   },
 * });
 */
export function useGetSoaClientByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSoaClientByCompanyQuery, GetSoaClientByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSoaClientByCompanyQuery, GetSoaClientByCompanyQueryVariables>(GetSoaClientByCompanyDocument, baseOptions);
      }
export function useGetSoaClientByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSoaClientByCompanyQuery, GetSoaClientByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSoaClientByCompanyQuery, GetSoaClientByCompanyQueryVariables>(GetSoaClientByCompanyDocument, baseOptions);
        }
export type GetSoaClientByCompanyQueryHookResult = ReturnType<typeof useGetSoaClientByCompanyQuery>;
export type GetSoaClientByCompanyLazyQueryHookResult = ReturnType<typeof useGetSoaClientByCompanyLazyQuery>;
export type GetSoaClientByCompanyQueryResult = ApolloReactCommon.QueryResult<GetSoaClientByCompanyQuery, GetSoaClientByCompanyQueryVariables>;
export const GetAssociateSummaryDocument = gql`
    query getAssociateSummary($associate: String!, $companyID: String) {
  clientNotifications
  associateMenu(associate: $associate, companyID: $companyID)
  totalOutstandingClients
  totalOutstandingAmtClients
}
    `;

/**
 * __useGetAssociateSummaryQuery__
 *
 * To run a query within a React component, call `useGetAssociateSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssociateSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssociateSummaryQuery({
 *   variables: {
 *      associate: // value for 'associate'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetAssociateSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssociateSummaryQuery, GetAssociateSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssociateSummaryQuery, GetAssociateSummaryQueryVariables>(GetAssociateSummaryDocument, baseOptions);
      }
export function useGetAssociateSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssociateSummaryQuery, GetAssociateSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssociateSummaryQuery, GetAssociateSummaryQueryVariables>(GetAssociateSummaryDocument, baseOptions);
        }
export type GetAssociateSummaryQueryHookResult = ReturnType<typeof useGetAssociateSummaryQuery>;
export type GetAssociateSummaryLazyQueryHookResult = ReturnType<typeof useGetAssociateSummaryLazyQuery>;
export type GetAssociateSummaryQueryResult = ApolloReactCommon.QueryResult<GetAssociateSummaryQuery, GetAssociateSummaryQueryVariables>;
export const GetContactDocument = gql`
    query getContact($ID: String, $associateID: String, $orderByAsc: String) {
  getContact(ID: $ID, associateID: $associateID, orderByAsc: $orderByAsc) {
    ID
    accountID
    name
    designation
    department
    jobTitle
    contactNo
    email
    relationship
    docRefTable
  }
}
    `;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      associateID: // value for 'associateID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, baseOptions);
      }
export function useGetContactLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, baseOptions);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = ApolloReactCommon.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const AddCustomerContactDocument = gql`
    mutation addCustomerContact($ID: String!, $platformAccess: Boolean!, $contactInput: [ContactPersonInput!]!) {
  addCustomerContact(ID: $ID, platformAccess: $platformAccess, contactInput: $contactInput)
}
    `;
export type AddCustomerContactMutationFn = ApolloReactCommon.MutationFunction<AddCustomerContactMutation, AddCustomerContactMutationVariables>;

/**
 * __useAddCustomerContactMutation__
 *
 * To run a mutation, you first call `useAddCustomerContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerContactMutation, { data, loading, error }] = useAddCustomerContactMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      platformAccess: // value for 'platformAccess'
 *      contactInput: // value for 'contactInput'
 *   },
 * });
 */
export function useAddCustomerContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCustomerContactMutation, AddCustomerContactMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCustomerContactMutation, AddCustomerContactMutationVariables>(AddCustomerContactDocument, baseOptions);
      }
export type AddCustomerContactMutationHookResult = ReturnType<typeof useAddCustomerContactMutation>;
export type AddCustomerContactMutationResult = ApolloReactCommon.MutationResult<AddCustomerContactMutation>;
export type AddCustomerContactMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCustomerContactMutation, AddCustomerContactMutationVariables>;
export const AddSubcontractorContactDocument = gql`
    mutation addSubcontractorContact($ID: String!, $platformAccess: Boolean!, $contactInput: [ContactPersonInput!]!) {
  addSubcontractorContact(ID: $ID, platformAccess: $platformAccess, contactInput: $contactInput)
}
    `;
export type AddSubcontractorContactMutationFn = ApolloReactCommon.MutationFunction<AddSubcontractorContactMutation, AddSubcontractorContactMutationVariables>;

/**
 * __useAddSubcontractorContactMutation__
 *
 * To run a mutation, you first call `useAddSubcontractorContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubcontractorContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubcontractorContactMutation, { data, loading, error }] = useAddSubcontractorContactMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      platformAccess: // value for 'platformAccess'
 *      contactInput: // value for 'contactInput'
 *   },
 * });
 */
export function useAddSubcontractorContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSubcontractorContactMutation, AddSubcontractorContactMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSubcontractorContactMutation, AddSubcontractorContactMutationVariables>(AddSubcontractorContactDocument, baseOptions);
      }
export type AddSubcontractorContactMutationHookResult = ReturnType<typeof useAddSubcontractorContactMutation>;
export type AddSubcontractorContactMutationResult = ApolloReactCommon.MutationResult<AddSubcontractorContactMutation>;
export type AddSubcontractorContactMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSubcontractorContactMutation, AddSubcontractorContactMutationVariables>;
export const AddSupplierContactDocument = gql`
    mutation addSupplierContact($ID: String!, $platformAccess: Boolean!, $contactInput: [ContactPersonInput!]!) {
  addSupplierContact(ID: $ID, platformAccess: $platformAccess, contactInput: $contactInput)
}
    `;
export type AddSupplierContactMutationFn = ApolloReactCommon.MutationFunction<AddSupplierContactMutation, AddSupplierContactMutationVariables>;

/**
 * __useAddSupplierContactMutation__
 *
 * To run a mutation, you first call `useAddSupplierContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierContactMutation, { data, loading, error }] = useAddSupplierContactMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      platformAccess: // value for 'platformAccess'
 *      contactInput: // value for 'contactInput'
 *   },
 * });
 */
export function useAddSupplierContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSupplierContactMutation, AddSupplierContactMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSupplierContactMutation, AddSupplierContactMutationVariables>(AddSupplierContactDocument, baseOptions);
      }
export type AddSupplierContactMutationHookResult = ReturnType<typeof useAddSupplierContactMutation>;
export type AddSupplierContactMutationResult = ApolloReactCommon.MutationResult<AddSupplierContactMutation>;
export type AddSupplierContactMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSupplierContactMutation, AddSupplierContactMutationVariables>;
export const GetCustomerTitleDocument = gql`
    query getCustomerTitle($ID: String, $companyID: String, $orderByAsc: String, $limit: Float) {
  getCustomer: getCustomerByCompany(ID: $ID, companyID: $companyID, orderByAsc: $orderByAsc, limit: $limit) {
    ID
    name
    associatedStatus
    activeContract(companyID: $companyID)
  }
}
    `;

/**
 * __useGetCustomerTitleQuery__
 *
 * To run a query within a React component, call `useGetCustomerTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCustomerTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerTitleQuery, GetCustomerTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerTitleQuery, GetCustomerTitleQueryVariables>(GetCustomerTitleDocument, baseOptions);
      }
export function useGetCustomerTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerTitleQuery, GetCustomerTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerTitleQuery, GetCustomerTitleQueryVariables>(GetCustomerTitleDocument, baseOptions);
        }
export type GetCustomerTitleQueryHookResult = ReturnType<typeof useGetCustomerTitleQuery>;
export type GetCustomerTitleLazyQueryHookResult = ReturnType<typeof useGetCustomerTitleLazyQuery>;
export type GetCustomerTitleQueryResult = ApolloReactCommon.QueryResult<GetCustomerTitleQuery, GetCustomerTitleQueryVariables>;
export const GetSubcontractorTitleDocument = gql`
    query getSubcontractorTitle($ID: String, $companyID: String, $orderByAsc: String, $limit: Float) {
  getSubcontractor: getSubcontractorByCompany(ID: $ID, companyID: $companyID, orderByAsc: $orderByAsc, limit: $limit) {
    ID
    name
    associatedStatus
    activeSubcontract(companyID: $companyID)
    subcontract {
      ID
      subcontractSum
      revisedSubcontractSum
    }
  }
}
    `;

/**
 * __useGetSubcontractorTitleQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSubcontractorTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorTitleQuery, GetSubcontractorTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorTitleQuery, GetSubcontractorTitleQueryVariables>(GetSubcontractorTitleDocument, baseOptions);
      }
export function useGetSubcontractorTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorTitleQuery, GetSubcontractorTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorTitleQuery, GetSubcontractorTitleQueryVariables>(GetSubcontractorTitleDocument, baseOptions);
        }
export type GetSubcontractorTitleQueryHookResult = ReturnType<typeof useGetSubcontractorTitleQuery>;
export type GetSubcontractorTitleLazyQueryHookResult = ReturnType<typeof useGetSubcontractorTitleLazyQuery>;
export type GetSubcontractorTitleQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorTitleQuery, GetSubcontractorTitleQueryVariables>;
export const GetSupplierTitleDocument = gql`
    query getSupplierTitle($ID: String, $companyID: String, $orderByAsc: String, $limit: Float) {
  getSupplier: getSupplierByCompany(ID: $ID, companyID: $companyID, orderByAsc: $orderByAsc, limit: $limit) {
    ID
    name
    address
    currencyID
    taxSchemeID
    gstRegNo
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
  }
}
    `;

/**
 * __useGetSupplierTitleQuery__
 *
 * To run a query within a React component, call `useGetSupplierTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSupplierTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>(GetSupplierTitleDocument, baseOptions);
      }
export function useGetSupplierTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>(GetSupplierTitleDocument, baseOptions);
        }
export type GetSupplierTitleQueryHookResult = ReturnType<typeof useGetSupplierTitleQuery>;
export type GetSupplierTitleLazyQueryHookResult = ReturnType<typeof useGetSupplierTitleLazyQuery>;
export type GetSupplierTitleQueryResult = ApolloReactCommon.QueryResult<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>;
export const AssociateMenuDocument = gql`
    query associateMenu($associate: String!, $companyID: String) {
  associateMenu(associate: $associate, companyID: $companyID)
}
    `;

/**
 * __useAssociateMenuQuery__
 *
 * To run a query within a React component, call `useAssociateMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssociateMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssociateMenuQuery({
 *   variables: {
 *      associate: // value for 'associate'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useAssociateMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssociateMenuQuery, AssociateMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<AssociateMenuQuery, AssociateMenuQueryVariables>(AssociateMenuDocument, baseOptions);
      }
export function useAssociateMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssociateMenuQuery, AssociateMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssociateMenuQuery, AssociateMenuQueryVariables>(AssociateMenuDocument, baseOptions);
        }
export type AssociateMenuQueryHookResult = ReturnType<typeof useAssociateMenuQuery>;
export type AssociateMenuLazyQueryHookResult = ReturnType<typeof useAssociateMenuLazyQuery>;
export type AssociateMenuQueryResult = ApolloReactCommon.QueryResult<AssociateMenuQuery, AssociateMenuQueryVariables>;
export const GetClassificationCodeDocument = gql`
    query getClassificationCode($id: String, $companyID: String) {
  getClassificationCode(id: $id, companyID: $companyID) {
    id
    classificationCode
    description
  }
}
    `;

/**
 * __useGetClassificationCodeQuery__
 *
 * To run a query within a React component, call `useGetClassificationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetClassificationCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>(GetClassificationCodeDocument, baseOptions);
      }
export function useGetClassificationCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>(GetClassificationCodeDocument, baseOptions);
        }
export type GetClassificationCodeQueryHookResult = ReturnType<typeof useGetClassificationCodeQuery>;
export type GetClassificationCodeLazyQueryHookResult = ReturnType<typeof useGetClassificationCodeLazyQuery>;
export type GetClassificationCodeQueryResult = ApolloReactCommon.QueryResult<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>;
export const GetMsicCodeDocument = gql`
    query getMsicCode($id: String, $companyID: String) {
  getMsicCode(id: $id, companyID: $companyID) {
    id
    msicCode
    description
    categoryReference
  }
}
    `;

/**
 * __useGetMsicCodeQuery__
 *
 * To run a query within a React component, call `useGetMsicCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsicCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsicCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetMsicCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMsicCodeQuery, GetMsicCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMsicCodeQuery, GetMsicCodeQueryVariables>(GetMsicCodeDocument, baseOptions);
      }
export function useGetMsicCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMsicCodeQuery, GetMsicCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMsicCodeQuery, GetMsicCodeQueryVariables>(GetMsicCodeDocument, baseOptions);
        }
export type GetMsicCodeQueryHookResult = ReturnType<typeof useGetMsicCodeQuery>;
export type GetMsicCodeLazyQueryHookResult = ReturnType<typeof useGetMsicCodeLazyQuery>;
export type GetMsicCodeQueryResult = ApolloReactCommon.QueryResult<GetMsicCodeQuery, GetMsicCodeQueryVariables>;
export const GetSelfBilledCostCategoryDocument = gql`
    query getSelfBilledCostCategory($companyID: String, $orderByAsc: String, $commonStatus: CommonStatus) {
  getCostCategory(companyID: $companyID, orderByAsc: $orderByAsc, commonStatus: $commonStatus) {
    ID
    name
    description
    classificationCodeID
    costClass
    commonStatus
  }
}
    `;

/**
 * __useGetSelfBilledCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetSelfBilledCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfBilledCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfBilledCostCategoryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetSelfBilledCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSelfBilledCostCategoryQuery, GetSelfBilledCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSelfBilledCostCategoryQuery, GetSelfBilledCostCategoryQueryVariables>(GetSelfBilledCostCategoryDocument, baseOptions);
      }
export function useGetSelfBilledCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSelfBilledCostCategoryQuery, GetSelfBilledCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSelfBilledCostCategoryQuery, GetSelfBilledCostCategoryQueryVariables>(GetSelfBilledCostCategoryDocument, baseOptions);
        }
export type GetSelfBilledCostCategoryQueryHookResult = ReturnType<typeof useGetSelfBilledCostCategoryQuery>;
export type GetSelfBilledCostCategoryLazyQueryHookResult = ReturnType<typeof useGetSelfBilledCostCategoryLazyQuery>;
export type GetSelfBilledCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetSelfBilledCostCategoryQuery, GetSelfBilledCostCategoryQueryVariables>;
export const GetCustomerDocument = gql`
    query getCustomer($ID: String, $companyID: String, $orderByAsc: String) {
  getCustomer(ID: $ID, orderByAsc: $orderByAsc) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    name
    registrationNo
    tinNo
    gstRegNo
    gstExpDate
    sstRegNo
    platformAccess
    description
    email
    contactNo
    image
    imageUrl
    address
    genPaymentReminder
    associatedStatus
    creditTerm
    taxSchemeID
    taxDetail {
      ID
      code
      description
      latestTax {
        taxDate
        taxRate
      }
    }
    currencyID
    activeContract(companyID: $companyID)
    clientAgingInfo(companyID: $companyID)
    eInvoiceType
    createdTs
    createdBy
    modTs
    modBy
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    contract {
      ID
      title
      contractSum
      contractStatus
    }
    contractClaim {
      ID
      transactionStatus
      outstandingAmt
    }
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
      }
export function useGetCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = ApolloReactCommon.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetCustomerListDocument = gql`
    query getCustomerList($ID: String, $orderByAsc: String) {
  getCustomer(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    associatedStatus
  }
}
    `;

/**
 * __useGetCustomerListQuery__
 *
 * To run a query within a React component, call `useGetCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCustomerListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, baseOptions);
      }
export function useGetCustomerListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, baseOptions);
        }
export type GetCustomerListQueryHookResult = ReturnType<typeof useGetCustomerListQuery>;
export type GetCustomerListLazyQueryHookResult = ReturnType<typeof useGetCustomerListLazyQuery>;
export type GetCustomerListQueryResult = ApolloReactCommon.QueryResult<GetCustomerListQuery, GetCustomerListQueryVariables>;
export const GetCustomerAssocListingDocument = gql`
    query getCustomerAssocListing($ID: String, $companyID: String, $orderByAsc: String) {
  getCustomer(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    clientAgingInfo(companyID: $companyID)
    overdueInvInfoNoBound(companyID: $companyID)
    activeContract(companyID: $companyID)
    associatedStatus
    consubconDropdown(companyID: $companyID)
    contract {
      ID
      title
    }
  }
}
    `;

/**
 * __useGetCustomerAssocListingQuery__
 *
 * To run a query within a React component, call `useGetCustomerAssocListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAssocListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAssocListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCustomerAssocListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerAssocListingQuery, GetCustomerAssocListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerAssocListingQuery, GetCustomerAssocListingQueryVariables>(GetCustomerAssocListingDocument, baseOptions);
      }
export function useGetCustomerAssocListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerAssocListingQuery, GetCustomerAssocListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerAssocListingQuery, GetCustomerAssocListingQueryVariables>(GetCustomerAssocListingDocument, baseOptions);
        }
export type GetCustomerAssocListingQueryHookResult = ReturnType<typeof useGetCustomerAssocListingQuery>;
export type GetCustomerAssocListingLazyQueryHookResult = ReturnType<typeof useGetCustomerAssocListingLazyQuery>;
export type GetCustomerAssocListingQueryResult = ApolloReactCommon.QueryResult<GetCustomerAssocListingQuery, GetCustomerAssocListingQueryVariables>;
export const GetClientAccountsDocument = gql`
    query getClientAccounts($ID: String, $companyID: String, $orderByAsc: String) {
  getClientAccounts(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    registrationNo
    contactNo
    email
    clientAgingInfo(companyID: $companyID)
    overdueInvInfoNoBound(companyID: $companyID)
    activeContract(companyID: $companyID)
    associatedStatus
    consubconDropdown(companyID: $companyID)
    image
    imageUrl
    contract {
      ID
      title
    }
  }
}
    `;

/**
 * __useGetClientAccountsQuery__
 *
 * To run a query within a React component, call `useGetClientAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientAccountsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetClientAccountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientAccountsQuery, GetClientAccountsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientAccountsQuery, GetClientAccountsQueryVariables>(GetClientAccountsDocument, baseOptions);
      }
export function useGetClientAccountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientAccountsQuery, GetClientAccountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientAccountsQuery, GetClientAccountsQueryVariables>(GetClientAccountsDocument, baseOptions);
        }
export type GetClientAccountsQueryHookResult = ReturnType<typeof useGetClientAccountsQuery>;
export type GetClientAccountsLazyQueryHookResult = ReturnType<typeof useGetClientAccountsLazyQuery>;
export type GetClientAccountsQueryResult = ApolloReactCommon.QueryResult<GetClientAccountsQuery, GetClientAccountsQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($input: CustomerInput!) {
  createCustomer(input: $input) {
    ID
    name
  }
}
    `;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, baseOptions);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: CustomerInput!) {
  updateCustomer(input: $input) {
    ID
    name
  }
}
    `;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer($input: CustomerDeleteInput!) {
  deleteCustomer(input: $input)
}
    `;
export type DeleteCustomerMutationFn = ApolloReactCommon.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, baseOptions);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = ApolloReactCommon.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const CreatePlatformCustomerDocument = gql`
    mutation createPlatformCustomer($input: CustomerInput!) {
  createPlatformCustomer(input: $input) {
    ID
    name
  }
}
    `;
export type CreatePlatformCustomerMutationFn = ApolloReactCommon.MutationFunction<CreatePlatformCustomerMutation, CreatePlatformCustomerMutationVariables>;

/**
 * __useCreatePlatformCustomerMutation__
 *
 * To run a mutation, you first call `useCreatePlatformCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlatformCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlatformCustomerMutation, { data, loading, error }] = useCreatePlatformCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlatformCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlatformCustomerMutation, CreatePlatformCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePlatformCustomerMutation, CreatePlatformCustomerMutationVariables>(CreatePlatformCustomerDocument, baseOptions);
      }
export type CreatePlatformCustomerMutationHookResult = ReturnType<typeof useCreatePlatformCustomerMutation>;
export type CreatePlatformCustomerMutationResult = ApolloReactCommon.MutationResult<CreatePlatformCustomerMutation>;
export type CreatePlatformCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlatformCustomerMutation, CreatePlatformCustomerMutationVariables>;
export const UpdatePlatformCustomerDocument = gql`
    mutation updatePlatformCustomer($input: CustomerInput!) {
  updatePlatformCustomer(input: $input) {
    ID
    name
  }
}
    `;
export type UpdatePlatformCustomerMutationFn = ApolloReactCommon.MutationFunction<UpdatePlatformCustomerMutation, UpdatePlatformCustomerMutationVariables>;

/**
 * __useUpdatePlatformCustomerMutation__
 *
 * To run a mutation, you first call `useUpdatePlatformCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlatformCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlatformCustomerMutation, { data, loading, error }] = useUpdatePlatformCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlatformCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePlatformCustomerMutation, UpdatePlatformCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePlatformCustomerMutation, UpdatePlatformCustomerMutationVariables>(UpdatePlatformCustomerDocument, baseOptions);
      }
export type UpdatePlatformCustomerMutationHookResult = ReturnType<typeof useUpdatePlatformCustomerMutation>;
export type UpdatePlatformCustomerMutationResult = ApolloReactCommon.MutationResult<UpdatePlatformCustomerMutation>;
export type UpdatePlatformCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePlatformCustomerMutation, UpdatePlatformCustomerMutationVariables>;
export const DeletePlatformCustomerDocument = gql`
    mutation deletePlatformCustomer($ID: String!) {
  deletePlatformCustomerProfile(ID: $ID)
}
    `;
export type DeletePlatformCustomerMutationFn = ApolloReactCommon.MutationFunction<DeletePlatformCustomerMutation, DeletePlatformCustomerMutationVariables>;

/**
 * __useDeletePlatformCustomerMutation__
 *
 * To run a mutation, you first call `useDeletePlatformCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlatformCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlatformCustomerMutation, { data, loading, error }] = useDeletePlatformCustomerMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeletePlatformCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePlatformCustomerMutation, DeletePlatformCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePlatformCustomerMutation, DeletePlatformCustomerMutationVariables>(DeletePlatformCustomerDocument, baseOptions);
      }
export type DeletePlatformCustomerMutationHookResult = ReturnType<typeof useDeletePlatformCustomerMutation>;
export type DeletePlatformCustomerMutationResult = ApolloReactCommon.MutationResult<DeletePlatformCustomerMutation>;
export type DeletePlatformCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePlatformCustomerMutation, DeletePlatformCustomerMutationVariables>;
export const GetClientSummaryDocument = gql`
    query getClientSummary($companyID: String) {
  clientNotifications
  associateMenu(associate: "client", companyID: $companyID)
  totalClients
  totalDebitClient
  totalCreditClient
  totalOutstandingClients(companyID: $companyID)
  totalOutstandingAmtClients(companyID: $companyID)
  totalOsClientInvoices(companyID: $companyID) {
    totalOsIvCount
    totalOsIvAmt
  }
}
    `;

/**
 * __useGetClientSummaryQuery__
 *
 * To run a query within a React component, call `useGetClientSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientSummaryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetClientSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientSummaryQuery, GetClientSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientSummaryQuery, GetClientSummaryQueryVariables>(GetClientSummaryDocument, baseOptions);
      }
export function useGetClientSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientSummaryQuery, GetClientSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientSummaryQuery, GetClientSummaryQueryVariables>(GetClientSummaryDocument, baseOptions);
        }
export type GetClientSummaryQueryHookResult = ReturnType<typeof useGetClientSummaryQuery>;
export type GetClientSummaryLazyQueryHookResult = ReturnType<typeof useGetClientSummaryLazyQuery>;
export type GetClientSummaryQueryResult = ApolloReactCommon.QueryResult<GetClientSummaryQuery, GetClientSummaryQueryVariables>;
export const GetOutstandingClaimDnDocument = gql`
    query getOutstandingClaimDN($customerID: String, $transactionStatus: TransactionStatus) {
  getContractClaim(customerID: $customerID, transactionStatus: $transactionStatus) {
    ID
    claimDocNo
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    outstandingCertifiedAmt
    transactionStatus
  }
  getContractDN(customerID: $customerID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
  getContractMiscInvoice(customerID: $customerID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
}
    `;

/**
 * __useGetOutstandingClaimDnQuery__
 *
 * To run a query within a React component, call `useGetOutstandingClaimDnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingClaimDnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingClaimDnQuery({
 *   variables: {
 *      customerID: // value for 'customerID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetOutstandingClaimDnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutstandingClaimDnQuery, GetOutstandingClaimDnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutstandingClaimDnQuery, GetOutstandingClaimDnQueryVariables>(GetOutstandingClaimDnDocument, baseOptions);
      }
export function useGetOutstandingClaimDnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutstandingClaimDnQuery, GetOutstandingClaimDnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutstandingClaimDnQuery, GetOutstandingClaimDnQueryVariables>(GetOutstandingClaimDnDocument, baseOptions);
        }
export type GetOutstandingClaimDnQueryHookResult = ReturnType<typeof useGetOutstandingClaimDnQuery>;
export type GetOutstandingClaimDnLazyQueryHookResult = ReturnType<typeof useGetOutstandingClaimDnLazyQuery>;
export type GetOutstandingClaimDnQueryResult = ApolloReactCommon.QueryResult<GetOutstandingClaimDnQuery, GetOutstandingClaimDnQueryVariables>;
export const GetCustomerNameDocument = gql`
    query getCustomerName($companyID: String!, $withoutCompany: Boolean, $orderByAsc: String, $orderByDesc: String) {
  getCustomerByCompany(companyID: $companyID, withoutCompany: $withoutCompany, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    name
    associatedStatus
  }
}
    `;

/**
 * __useGetCustomerNameQuery__
 *
 * To run a query within a React component, call `useGetCustomerNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerNameQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      withoutCompany: // value for 'withoutCompany'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCustomerNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerNameQuery, GetCustomerNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerNameQuery, GetCustomerNameQueryVariables>(GetCustomerNameDocument, baseOptions);
      }
export function useGetCustomerNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerNameQuery, GetCustomerNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerNameQuery, GetCustomerNameQueryVariables>(GetCustomerNameDocument, baseOptions);
        }
export type GetCustomerNameQueryHookResult = ReturnType<typeof useGetCustomerNameQuery>;
export type GetCustomerNameLazyQueryHookResult = ReturnType<typeof useGetCustomerNameLazyQuery>;
export type GetCustomerNameQueryResult = ApolloReactCommon.QueryResult<GetCustomerNameQuery, GetCustomerNameQueryVariables>;
export const GetCustomerByCompanyDocument = gql`
    query getCustomerByCompany($companyID: String!, $withoutCompany: Boolean, $orderByAsc: String, $orderByDesc: String, $limit: Float, $associatedStatus: AssociatedStatus) {
  getCustomerByCompany(companyID: $companyID, withoutCompany: $withoutCompany, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, limit: $limit, associatedStatus: $associatedStatus) {
    ID
    name
    accountID
    registrationNo
    tinNo
    gstRegNo
    gstExpDate
    sstRegNo
    platformAccess
    description
    email
    contactNo
    image
    imageUrl
    address
    genPaymentReminder
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    associatedStatus
    eInvoiceType
    createdTs
    createdBy
    modTs
    modBy
    creditTerm
    currencyID
    contract {
      ID
      title
      contractSum
      contractStatus
    }
    activeContract(companyID: $companyID)
    contractClaim {
      ID
      transactionStatus
      outstandingAmt
    }
    clientAgingInfo(companyID: $companyID)
    overdueInvInfoNoBound(companyID: $companyID)
    OSClientAgingInfo(companyID: $companyID)
  }
}
    `;

/**
 * __useGetCustomerByCompanyQuery__
 *
 * To run a query within a React component, call `useGetCustomerByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerByCompanyQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      withoutCompany: // value for 'withoutCompany'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      limit: // value for 'limit'
 *      associatedStatus: // value for 'associatedStatus'
 *   },
 * });
 */
export function useGetCustomerByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerByCompanyQuery, GetCustomerByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerByCompanyQuery, GetCustomerByCompanyQueryVariables>(GetCustomerByCompanyDocument, baseOptions);
      }
export function useGetCustomerByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerByCompanyQuery, GetCustomerByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerByCompanyQuery, GetCustomerByCompanyQueryVariables>(GetCustomerByCompanyDocument, baseOptions);
        }
export type GetCustomerByCompanyQueryHookResult = ReturnType<typeof useGetCustomerByCompanyQuery>;
export type GetCustomerByCompanyLazyQueryHookResult = ReturnType<typeof useGetCustomerByCompanyLazyQuery>;
export type GetCustomerByCompanyQueryResult = ApolloReactCommon.QueryResult<GetCustomerByCompanyQuery, GetCustomerByCompanyQueryVariables>;
export const GetCustomerCompanyAssignmentDocument = gql`
    query getCustomerCompanyAssignment($ID: String, $customerID: String, $companyID: String, $orderByAsc: String, $orderByDesc: String) {
  getCustomerCompanyAssignment(ID: $ID, companyID: $companyID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    companyID
    customerID
    customer {
      ID
      name
      registrationNo
      description
    }
  }
}
    `;

/**
 * __useGetCustomerCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetCustomerCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerCompanyAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCustomerCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerCompanyAssignmentQuery, GetCustomerCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerCompanyAssignmentQuery, GetCustomerCompanyAssignmentQueryVariables>(GetCustomerCompanyAssignmentDocument, baseOptions);
      }
export function useGetCustomerCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerCompanyAssignmentQuery, GetCustomerCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerCompanyAssignmentQuery, GetCustomerCompanyAssignmentQueryVariables>(GetCustomerCompanyAssignmentDocument, baseOptions);
        }
export type GetCustomerCompanyAssignmentQueryHookResult = ReturnType<typeof useGetCustomerCompanyAssignmentQuery>;
export type GetCustomerCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetCustomerCompanyAssignmentLazyQuery>;
export type GetCustomerCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetCustomerCompanyAssignmentQuery, GetCustomerCompanyAssignmentQueryVariables>;
export const AssignCustomerCompanyDocument = gql`
    mutation assignCustomerCompany($customerID: String!, $companyID: [String!]!) {
  assignCustomerCompany(customerID: $customerID, companyID: $companyID)
}
    `;
export type AssignCustomerCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignCustomerCompanyMutation, AssignCustomerCompanyMutationVariables>;

/**
 * __useAssignCustomerCompanyMutation__
 *
 * To run a mutation, you first call `useAssignCustomerCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCustomerCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCustomerCompanyMutation, { data, loading, error }] = useAssignCustomerCompanyMutation({
 *   variables: {
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useAssignCustomerCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignCustomerCompanyMutation, AssignCustomerCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignCustomerCompanyMutation, AssignCustomerCompanyMutationVariables>(AssignCustomerCompanyDocument, baseOptions);
      }
export type AssignCustomerCompanyMutationHookResult = ReturnType<typeof useAssignCustomerCompanyMutation>;
export type AssignCustomerCompanyMutationResult = ApolloReactCommon.MutationResult<AssignCustomerCompanyMutation>;
export type AssignCustomerCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignCustomerCompanyMutation, AssignCustomerCompanyMutationVariables>;
export const UnassignCustomerCompanyDocument = gql`
    mutation unassignCustomerCompany($customerID: String!, $companyID: [String!]!) {
  unassignCustomerCompany(customerID: $customerID, companyID: $companyID)
}
    `;
export type UnassignCustomerCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignCustomerCompanyMutation, UnassignCustomerCompanyMutationVariables>;

/**
 * __useUnassignCustomerCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignCustomerCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignCustomerCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCustomerCompanyMutation, { data, loading, error }] = useUnassignCustomerCompanyMutation({
 *   variables: {
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useUnassignCustomerCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignCustomerCompanyMutation, UnassignCustomerCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignCustomerCompanyMutation, UnassignCustomerCompanyMutationVariables>(UnassignCustomerCompanyDocument, baseOptions);
      }
export type UnassignCustomerCompanyMutationHookResult = ReturnType<typeof useUnassignCustomerCompanyMutation>;
export type UnassignCustomerCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignCustomerCompanyMutation>;
export type UnassignCustomerCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignCustomerCompanyMutation, UnassignCustomerCompanyMutationVariables>;
export const CreateCustomerImportDocument = gql`
    mutation createCustomerImport($batchID: String!) {
  createCustomerImport(batchID: $batchID)
}
    `;
export type CreateCustomerImportMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerImportMutation, CreateCustomerImportMutationVariables>;

/**
 * __useCreateCustomerImportMutation__
 *
 * To run a mutation, you first call `useCreateCustomerImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerImportMutation, { data, loading, error }] = useCreateCustomerImportMutation({
 *   variables: {
 *      batchID: // value for 'batchID'
 *   },
 * });
 */
export function useCreateCustomerImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerImportMutation, CreateCustomerImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerImportMutation, CreateCustomerImportMutationVariables>(CreateCustomerImportDocument, baseOptions);
      }
export type CreateCustomerImportMutationHookResult = ReturnType<typeof useCreateCustomerImportMutation>;
export type CreateCustomerImportMutationResult = ApolloReactCommon.MutationResult<CreateCustomerImportMutation>;
export type CreateCustomerImportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerImportMutation, CreateCustomerImportMutationVariables>;
export const CustomerImportValidationDocument = gql`
    mutation customerImportValidation($input: [CustomerImportInput!]!) {
  customerImportValidation(input: $input)
}
    `;
export type CustomerImportValidationMutationFn = ApolloReactCommon.MutationFunction<CustomerImportValidationMutation, CustomerImportValidationMutationVariables>;

/**
 * __useCustomerImportValidationMutation__
 *
 * To run a mutation, you first call `useCustomerImportValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerImportValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerImportValidationMutation, { data, loading, error }] = useCustomerImportValidationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerImportValidationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CustomerImportValidationMutation, CustomerImportValidationMutationVariables>) {
        return ApolloReactHooks.useMutation<CustomerImportValidationMutation, CustomerImportValidationMutationVariables>(CustomerImportValidationDocument, baseOptions);
      }
export type CustomerImportValidationMutationHookResult = ReturnType<typeof useCustomerImportValidationMutation>;
export type CustomerImportValidationMutationResult = ApolloReactCommon.MutationResult<CustomerImportValidationMutation>;
export type CustomerImportValidationMutationOptions = ApolloReactCommon.BaseMutationOptions<CustomerImportValidationMutation, CustomerImportValidationMutationVariables>;
export const GetSubcontractorDocument = gql`
    query getSubcontractor($ID: String, $companyID: String, $orderByAsc: String) {
  getSubcontractor(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    paymentTo
    email
    accountID
    registrationNo
    tinNo
    associatedStatus
    contactNo
    creditTerm
    currencyID
    ratingType
    cidbDetails
    pkkDetails
    statusRemarks
    bankDetails
    gstRegNo
    description
    gstExpDate
    taxSchemeID
    taxDetail {
      ID
      code
      description
      latestTax {
        taxDate
        taxRate
      }
    }
    image
    imageUrl
    platformAccess
    sstRegNo
    address
    description
    nominatedSC
    activeSubcontract(companyID: $companyID)
    createdTs
    createdBy
    modTs
    modBy
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    subconClaim {
      ID
      outstandingAmt
      outstandingCertifiedAmt
      profileInfo
    }
    subconAdvance {
      ID
      outstandingAmt
      baseAmt
    }
    subcontract {
      ID
      title
      subcontractSum
      revisedSubcontractSum
    }
    subcontractorTypeID
    subcontractorType {
      ID
      description
      glAccountCode
      accrualAccountCode
    }
    eInvoiceType
    isSelfBilled
    msicCodeID
    classificationCodeID
    selfBilledCostCategoryID
    selfBilledCostCategory {
      ID
      name
      description
      classificationCodeID
      costClass
      commonStatus
    }
    msicCode {
      id
      msicCode
      description
      categoryReference
    }
    classificationCode {
      id
      classificationCode
      description
    }
  }
}
    `;

/**
 * __useGetSubcontractorQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorQuery, GetSubcontractorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorQuery, GetSubcontractorQueryVariables>(GetSubcontractorDocument, baseOptions);
      }
export function useGetSubcontractorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorQuery, GetSubcontractorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorQuery, GetSubcontractorQueryVariables>(GetSubcontractorDocument, baseOptions);
        }
export type GetSubcontractorQueryHookResult = ReturnType<typeof useGetSubcontractorQuery>;
export type GetSubcontractorLazyQueryHookResult = ReturnType<typeof useGetSubcontractorLazyQuery>;
export type GetSubcontractorQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorQuery, GetSubcontractorQueryVariables>;
export const GetSubcontractorListDocument = gql`
    query getSubcontractorList($ID: String, $orderByAsc: String) {
  getSubcontractor(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    subcontract {
      ID
      title
      subcontractSum
    }
  }
}
    `;

/**
 * __useGetSubcontractorListQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>(GetSubcontractorListDocument, baseOptions);
      }
export function useGetSubcontractorListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>(GetSubcontractorListDocument, baseOptions);
        }
export type GetSubcontractorListQueryHookResult = ReturnType<typeof useGetSubcontractorListQuery>;
export type GetSubcontractorListLazyQueryHookResult = ReturnType<typeof useGetSubcontractorListLazyQuery>;
export type GetSubcontractorListQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>;
export const GetSubcontractorAssocListingDocument = gql`
    query getSubcontractorAssocListing($ID: String, $companyID: String, $orderByAsc: String) {
  getSubcontractor(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    registrationNo
    overdueAdvInfo
    overdueInvInfo
    overdueInvInfoNoBound(companyID: $companyID)
    activeSubcontract
    associatedStatus
    image
    imageUrl
  }
}
    `;

/**
 * __useGetSubcontractorAssocListingQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorAssocListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorAssocListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorAssocListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorAssocListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorAssocListingQuery, GetSubcontractorAssocListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorAssocListingQuery, GetSubcontractorAssocListingQueryVariables>(GetSubcontractorAssocListingDocument, baseOptions);
      }
export function useGetSubcontractorAssocListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorAssocListingQuery, GetSubcontractorAssocListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorAssocListingQuery, GetSubcontractorAssocListingQueryVariables>(GetSubcontractorAssocListingDocument, baseOptions);
        }
export type GetSubcontractorAssocListingQueryHookResult = ReturnType<typeof useGetSubcontractorAssocListingQuery>;
export type GetSubcontractorAssocListingLazyQueryHookResult = ReturnType<typeof useGetSubcontractorAssocListingLazyQuery>;
export type GetSubcontractorAssocListingQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorAssocListingQuery, GetSubcontractorAssocListingQueryVariables>;
export const GetSubcontractorCCategoryDocument = gql`
    query getSubcontractorCCategory($subcontractorID: String) {
  getSubcontractorCCategory(subcontractorID: $subcontractorID) {
    subcontractorID
    costCategoryID
  }
}
    `;

/**
 * __useGetSubcontractorCCategoryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorCCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorCCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorCCategoryQuery({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractorCCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>(GetSubcontractorCCategoryDocument, baseOptions);
      }
export function useGetSubcontractorCCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>(GetSubcontractorCCategoryDocument, baseOptions);
        }
export type GetSubcontractorCCategoryQueryHookResult = ReturnType<typeof useGetSubcontractorCCategoryQuery>;
export type GetSubcontractorCCategoryLazyQueryHookResult = ReturnType<typeof useGetSubcontractorCCategoryLazyQuery>;
export type GetSubcontractorCCategoryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>;
export const DeleteSubcontractorDocument = gql`
    mutation deleteSubcontractor($input: SubcontractorDeleteID!) {
  deleteSubcontractor(input: $input)
}
    `;
export type DeleteSubcontractorMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>;

/**
 * __useDeleteSubcontractorMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractorMutation, { data, loading, error }] = useDeleteSubcontractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSubcontractorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>(DeleteSubcontractorDocument, baseOptions);
      }
export type DeleteSubcontractorMutationHookResult = ReturnType<typeof useDeleteSubcontractorMutation>;
export type DeleteSubcontractorMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractorMutation>;
export type DeleteSubcontractorMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>;
export const CreatePlatformSubcontractorDocument = gql`
    mutation createPlatformSubcontractor($input: SubcontractorInput!) {
  createPlatformSubcontractor(input: $input) {
    ID
    name
  }
}
    `;
export type CreatePlatformSubcontractorMutationFn = ApolloReactCommon.MutationFunction<CreatePlatformSubcontractorMutation, CreatePlatformSubcontractorMutationVariables>;

/**
 * __useCreatePlatformSubcontractorMutation__
 *
 * To run a mutation, you first call `useCreatePlatformSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlatformSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlatformSubcontractorMutation, { data, loading, error }] = useCreatePlatformSubcontractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlatformSubcontractorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlatformSubcontractorMutation, CreatePlatformSubcontractorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePlatformSubcontractorMutation, CreatePlatformSubcontractorMutationVariables>(CreatePlatformSubcontractorDocument, baseOptions);
      }
export type CreatePlatformSubcontractorMutationHookResult = ReturnType<typeof useCreatePlatformSubcontractorMutation>;
export type CreatePlatformSubcontractorMutationResult = ApolloReactCommon.MutationResult<CreatePlatformSubcontractorMutation>;
export type CreatePlatformSubcontractorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlatformSubcontractorMutation, CreatePlatformSubcontractorMutationVariables>;
export const UpdatePlatformSubcontractorDocument = gql`
    mutation updatePlatformSubcontractor($input: SubcontractorInput!) {
  updatePlatformSubcontractor(input: $input) {
    ID
    name
  }
}
    `;
export type UpdatePlatformSubcontractorMutationFn = ApolloReactCommon.MutationFunction<UpdatePlatformSubcontractorMutation, UpdatePlatformSubcontractorMutationVariables>;

/**
 * __useUpdatePlatformSubcontractorMutation__
 *
 * To run a mutation, you first call `useUpdatePlatformSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlatformSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlatformSubcontractorMutation, { data, loading, error }] = useUpdatePlatformSubcontractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlatformSubcontractorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePlatformSubcontractorMutation, UpdatePlatformSubcontractorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePlatformSubcontractorMutation, UpdatePlatformSubcontractorMutationVariables>(UpdatePlatformSubcontractorDocument, baseOptions);
      }
export type UpdatePlatformSubcontractorMutationHookResult = ReturnType<typeof useUpdatePlatformSubcontractorMutation>;
export type UpdatePlatformSubcontractorMutationResult = ApolloReactCommon.MutationResult<UpdatePlatformSubcontractorMutation>;
export type UpdatePlatformSubcontractorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePlatformSubcontractorMutation, UpdatePlatformSubcontractorMutationVariables>;
export const AddSubcontractorCCategoryDocument = gql`
    mutation addSubcontractorCCategory($subcontractorID: String!, $costCategoryID: [String!]!) {
  addSubcontractorCCategory(subcontractorID: $subcontractorID, costCategoryID: $costCategoryID)
}
    `;
export type AddSubcontractorCCategoryMutationFn = ApolloReactCommon.MutationFunction<AddSubcontractorCCategoryMutation, AddSubcontractorCCategoryMutationVariables>;

/**
 * __useAddSubcontractorCCategoryMutation__
 *
 * To run a mutation, you first call `useAddSubcontractorCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubcontractorCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubcontractorCCategoryMutation, { data, loading, error }] = useAddSubcontractorCCategoryMutation({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useAddSubcontractorCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSubcontractorCCategoryMutation, AddSubcontractorCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSubcontractorCCategoryMutation, AddSubcontractorCCategoryMutationVariables>(AddSubcontractorCCategoryDocument, baseOptions);
      }
export type AddSubcontractorCCategoryMutationHookResult = ReturnType<typeof useAddSubcontractorCCategoryMutation>;
export type AddSubcontractorCCategoryMutationResult = ApolloReactCommon.MutationResult<AddSubcontractorCCategoryMutation>;
export type AddSubcontractorCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSubcontractorCCategoryMutation, AddSubcontractorCCategoryMutationVariables>;
export const CreateSubcontractorAndPackageDocument = gql`
    mutation createSubcontractorAndPackage($subcontractorInput: SubcontractorInput!, $costCategoryID: [String!]!) {
  createSubcontractorAndPackage(subcontractorInput: $subcontractorInput, costCategoryID: $costCategoryID) {
    ID
    name
  }
}
    `;
export type CreateSubcontractorAndPackageMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractorAndPackageMutation, CreateSubcontractorAndPackageMutationVariables>;

/**
 * __useCreateSubcontractorAndPackageMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractorAndPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractorAndPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractorAndPackageMutation, { data, loading, error }] = useCreateSubcontractorAndPackageMutation({
 *   variables: {
 *      subcontractorInput: // value for 'subcontractorInput'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useCreateSubcontractorAndPackageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractorAndPackageMutation, CreateSubcontractorAndPackageMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractorAndPackageMutation, CreateSubcontractorAndPackageMutationVariables>(CreateSubcontractorAndPackageDocument, baseOptions);
      }
export type CreateSubcontractorAndPackageMutationHookResult = ReturnType<typeof useCreateSubcontractorAndPackageMutation>;
export type CreateSubcontractorAndPackageMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractorAndPackageMutation>;
export type CreateSubcontractorAndPackageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractorAndPackageMutation, CreateSubcontractorAndPackageMutationVariables>;
export const GetSubconClaimDateDocument = gql`
    query getSubconClaimDate($ID: String) {
  getSubcontractClaim(ID: $ID) {
    ID
    submittedDate
    docDate
  }
}
    `;

/**
 * __useGetSubconClaimDateQuery__
 *
 * To run a query within a React component, call `useGetSubconClaimDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconClaimDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconClaimDateQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSubconClaimDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconClaimDateQuery, GetSubconClaimDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconClaimDateQuery, GetSubconClaimDateQueryVariables>(GetSubconClaimDateDocument, baseOptions);
      }
export function useGetSubconClaimDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconClaimDateQuery, GetSubconClaimDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconClaimDateQuery, GetSubconClaimDateQueryVariables>(GetSubconClaimDateDocument, baseOptions);
        }
export type GetSubconClaimDateQueryHookResult = ReturnType<typeof useGetSubconClaimDateQuery>;
export type GetSubconClaimDateLazyQueryHookResult = ReturnType<typeof useGetSubconClaimDateLazyQuery>;
export type GetSubconClaimDateQueryResult = ApolloReactCommon.QueryResult<GetSubconClaimDateQuery, GetSubconClaimDateQueryVariables>;
export const GetOutstandingSubconDocDocument = gql`
    query getOutstandingSubconDoc($subcontractorID: String, $companyID: String, $transactionStatus: TransactionStatus) {
  getSubcontractClaim(subcontractorID: $subcontractorID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    claimDocNo
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    outstandingCertifiedAmt
    transactionStatus
  }
  getSubcontractCN(subcontractorID: $subcontractorID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
  getSubcontractDN(subcontractorID: $subcontractorID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
  getSubcontractMiscInvoice(subcontractorID: $subcontractorID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
  getCNtoSubcon(subcontractorID: $subcontractorID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
  getDNtoSubcon(subcontractorID: $subcontractorID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
}
    `;

/**
 * __useGetOutstandingSubconDocQuery__
 *
 * To run a query within a React component, call `useGetOutstandingSubconDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingSubconDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingSubconDocQuery({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetOutstandingSubconDocQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutstandingSubconDocQuery, GetOutstandingSubconDocQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutstandingSubconDocQuery, GetOutstandingSubconDocQueryVariables>(GetOutstandingSubconDocDocument, baseOptions);
      }
export function useGetOutstandingSubconDocLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutstandingSubconDocQuery, GetOutstandingSubconDocQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutstandingSubconDocQuery, GetOutstandingSubconDocQueryVariables>(GetOutstandingSubconDocDocument, baseOptions);
        }
export type GetOutstandingSubconDocQueryHookResult = ReturnType<typeof useGetOutstandingSubconDocQuery>;
export type GetOutstandingSubconDocLazyQueryHookResult = ReturnType<typeof useGetOutstandingSubconDocLazyQuery>;
export type GetOutstandingSubconDocQueryResult = ApolloReactCommon.QueryResult<GetOutstandingSubconDocQuery, GetOutstandingSubconDocQueryVariables>;
export const GetOutstandingSubconCreditDocDocument = gql`
    query getOutstandingSubconCreditDoc($subcontractorID: String, $transactionStatus: TransactionStatus) {
  getSubcontractClaim(subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    claimDocNo
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    outstandingCertifiedAmt
    transactionStatus
  }
  getSubcontractDN(subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
  getSubcontractMiscInvoice(subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
  getCNtoSubcon(subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
}
    `;

/**
 * __useGetOutstandingSubconCreditDocQuery__
 *
 * To run a query within a React component, call `useGetOutstandingSubconCreditDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingSubconCreditDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingSubconCreditDocQuery({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetOutstandingSubconCreditDocQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutstandingSubconCreditDocQuery, GetOutstandingSubconCreditDocQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutstandingSubconCreditDocQuery, GetOutstandingSubconCreditDocQueryVariables>(GetOutstandingSubconCreditDocDocument, baseOptions);
      }
export function useGetOutstandingSubconCreditDocLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutstandingSubconCreditDocQuery, GetOutstandingSubconCreditDocQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutstandingSubconCreditDocQuery, GetOutstandingSubconCreditDocQueryVariables>(GetOutstandingSubconCreditDocDocument, baseOptions);
        }
export type GetOutstandingSubconCreditDocQueryHookResult = ReturnType<typeof useGetOutstandingSubconCreditDocQuery>;
export type GetOutstandingSubconCreditDocLazyQueryHookResult = ReturnType<typeof useGetOutstandingSubconCreditDocLazyQuery>;
export type GetOutstandingSubconCreditDocQueryResult = ApolloReactCommon.QueryResult<GetOutstandingSubconCreditDocQuery, GetOutstandingSubconCreditDocQueryVariables>;
export const CreateBulkSubconDocument = gql`
    mutation createBulkSubcon($subcontractorInput: [BulkSubcontractorInput!]!) {
  createBulkSubcon(subcontractorInput: $subcontractorInput)
}
    `;
export type CreateBulkSubconMutationFn = ApolloReactCommon.MutationFunction<CreateBulkSubconMutation, CreateBulkSubconMutationVariables>;

/**
 * __useCreateBulkSubconMutation__
 *
 * To run a mutation, you first call `useCreateBulkSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkSubconMutation, { data, loading, error }] = useCreateBulkSubconMutation({
 *   variables: {
 *      subcontractorInput: // value for 'subcontractorInput'
 *   },
 * });
 */
export function useCreateBulkSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBulkSubconMutation, CreateBulkSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBulkSubconMutation, CreateBulkSubconMutationVariables>(CreateBulkSubconDocument, baseOptions);
      }
export type CreateBulkSubconMutationHookResult = ReturnType<typeof useCreateBulkSubconMutation>;
export type CreateBulkSubconMutationResult = ApolloReactCommon.MutationResult<CreateBulkSubconMutation>;
export type CreateBulkSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBulkSubconMutation, CreateBulkSubconMutationVariables>;
export const GetSubcontractorAccessibleContractDocument = gql`
    query getSubcontractorAccessibleContract($companyID: String, $contractID: String) {
  getSubcontractorAccessibleContract(companyID: $companyID, contractID: $contractID)
}
    `;

/**
 * __useGetSubcontractorAccessibleContractQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorAccessibleContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorAccessibleContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorAccessibleContractQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubcontractorAccessibleContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorAccessibleContractQuery, GetSubcontractorAccessibleContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorAccessibleContractQuery, GetSubcontractorAccessibleContractQueryVariables>(GetSubcontractorAccessibleContractDocument, baseOptions);
      }
export function useGetSubcontractorAccessibleContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorAccessibleContractQuery, GetSubcontractorAccessibleContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorAccessibleContractQuery, GetSubcontractorAccessibleContractQueryVariables>(GetSubcontractorAccessibleContractDocument, baseOptions);
        }
export type GetSubcontractorAccessibleContractQueryHookResult = ReturnType<typeof useGetSubcontractorAccessibleContractQuery>;
export type GetSubcontractorAccessibleContractLazyQueryHookResult = ReturnType<typeof useGetSubcontractorAccessibleContractLazyQuery>;
export type GetSubcontractorAccessibleContractQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorAccessibleContractQuery, GetSubcontractorAccessibleContractQueryVariables>;
export const GetSubcontractorByCompanyDocument = gql`
    query getSubcontractorByCompany($companyID: String!, $withoutCompany: Boolean, $associatedStatus: AssociatedStatus, $orderByAsc: String, $orderByDesc: String, $limit: Float) {
  getSubcontractorByCompany(companyID: $companyID, withoutCompany: $withoutCompany, associatedStatus: $associatedStatus, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, limit: $limit) {
    ID
    name
    paymentTo
    email
    accountID
    registrationNo
    tinNo
    associatedStatus
    contactNo
    creditTerm
    currencyID
    taxSchemeID
    ratingType
    cidbDetails
    pkkDetails
    statusRemarks
    bankDetails
    gstRegNo
    description
    gstExpDate
    image
    imageUrl
    platformAccess
    sstRegNo
    address
    activeSubcontract(companyID: $companyID)
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    createdTs
    createdBy
    modTs
    modBy
    description
    nominatedSC
    subconClaim {
      ID
      outstandingAmt
      outstandingCertifiedAmt
      profileInfo
    }
    subconAdvance {
      ID
      outstandingAmt
      baseAmt
    }
    subcontract {
      ID
      title
      subcontractSum
      revisedSubcontractSum
    }
    overdueAdvInfo(companyID: $companyID)
    overdueInvInfoNoBound(companyID: $companyID)
    overdueInvInfo
    eInvoiceType
    isSelfBilled
    msicCodeID
    classificationCodeID
    selfBilledCostCategoryID
    selfBilledCostCategory {
      ID
      name
      description
      classificationCodeID
      costClass
      commonStatus
    }
    msicCode {
      id
      msicCode
      description
      categoryReference
    }
    classificationCode {
      id
      classificationCode
      description
    }
  }
}
    `;

/**
 * __useGetSubcontractorByCompanyQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorByCompanyQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      withoutCompany: // value for 'withoutCompany'
 *      associatedStatus: // value for 'associatedStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSubcontractorByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorByCompanyQuery, GetSubcontractorByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorByCompanyQuery, GetSubcontractorByCompanyQueryVariables>(GetSubcontractorByCompanyDocument, baseOptions);
      }
export function useGetSubcontractorByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorByCompanyQuery, GetSubcontractorByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorByCompanyQuery, GetSubcontractorByCompanyQueryVariables>(GetSubcontractorByCompanyDocument, baseOptions);
        }
export type GetSubcontractorByCompanyQueryHookResult = ReturnType<typeof useGetSubcontractorByCompanyQuery>;
export type GetSubcontractorByCompanyLazyQueryHookResult = ReturnType<typeof useGetSubcontractorByCompanyLazyQuery>;
export type GetSubcontractorByCompanyQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorByCompanyQuery, GetSubcontractorByCompanyQueryVariables>;
export const CreatePlatformSupplierDocument = gql`
    mutation createPlatformSupplier($input: SupplierInput!) {
  createPlatformSupplier(input: $input) {
    ID
    name
  }
}
    `;
export type CreatePlatformSupplierMutationFn = ApolloReactCommon.MutationFunction<CreatePlatformSupplierMutation, CreatePlatformSupplierMutationVariables>;

/**
 * __useCreatePlatformSupplierMutation__
 *
 * To run a mutation, you first call `useCreatePlatformSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlatformSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlatformSupplierMutation, { data, loading, error }] = useCreatePlatformSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlatformSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlatformSupplierMutation, CreatePlatformSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePlatformSupplierMutation, CreatePlatformSupplierMutationVariables>(CreatePlatformSupplierDocument, baseOptions);
      }
export type CreatePlatformSupplierMutationHookResult = ReturnType<typeof useCreatePlatformSupplierMutation>;
export type CreatePlatformSupplierMutationResult = ApolloReactCommon.MutationResult<CreatePlatformSupplierMutation>;
export type CreatePlatformSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlatformSupplierMutation, CreatePlatformSupplierMutationVariables>;
export const UpdatePlatformSupplierDocument = gql`
    mutation updatePlatformSupplier($input: SupplierInput!) {
  updatePlatformSupplier(input: $input) {
    ID
    name
  }
}
    `;
export type UpdatePlatformSupplierMutationFn = ApolloReactCommon.MutationFunction<UpdatePlatformSupplierMutation, UpdatePlatformSupplierMutationVariables>;

/**
 * __useUpdatePlatformSupplierMutation__
 *
 * To run a mutation, you first call `useUpdatePlatformSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlatformSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlatformSupplierMutation, { data, loading, error }] = useUpdatePlatformSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlatformSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePlatformSupplierMutation, UpdatePlatformSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePlatformSupplierMutation, UpdatePlatformSupplierMutationVariables>(UpdatePlatformSupplierDocument, baseOptions);
      }
export type UpdatePlatformSupplierMutationHookResult = ReturnType<typeof useUpdatePlatformSupplierMutation>;
export type UpdatePlatformSupplierMutationResult = ApolloReactCommon.MutationResult<UpdatePlatformSupplierMutation>;
export type UpdatePlatformSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePlatformSupplierMutation, UpdatePlatformSupplierMutationVariables>;
export const CreateBulkSupplierDocument = gql`
    mutation createBulkSupplier($supplierInput: [BulkSupplierInput!]!) {
  createBulkSupplier(supplierInput: $supplierInput)
}
    `;
export type CreateBulkSupplierMutationFn = ApolloReactCommon.MutationFunction<CreateBulkSupplierMutation, CreateBulkSupplierMutationVariables>;

/**
 * __useCreateBulkSupplierMutation__
 *
 * To run a mutation, you first call `useCreateBulkSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkSupplierMutation, { data, loading, error }] = useCreateBulkSupplierMutation({
 *   variables: {
 *      supplierInput: // value for 'supplierInput'
 *   },
 * });
 */
export function useCreateBulkSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBulkSupplierMutation, CreateBulkSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBulkSupplierMutation, CreateBulkSupplierMutationVariables>(CreateBulkSupplierDocument, baseOptions);
      }
export type CreateBulkSupplierMutationHookResult = ReturnType<typeof useCreateBulkSupplierMutation>;
export type CreateBulkSupplierMutationResult = ApolloReactCommon.MutationResult<CreateBulkSupplierMutation>;
export type CreateBulkSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBulkSupplierMutation, CreateBulkSupplierMutationVariables>;
export const GetSupplierDocument = gql`
    query getSupplier($ID: String, $orderByAsc: String, $associatedStatus: AssociatedStatus) {
  getSupplier(ID: $ID, orderByAsc: $orderByAsc, associatedStatus: $associatedStatus) {
    ID
    createdTs
    createdBy
    accountID
    taxSchemeID
    taxDetail {
      ID
      code
      description
      latestTax {
        taxDate
        taxRate
      }
    }
    supplierTypeID
    supplierType {
      ID
      description
      glAccountCode
      accrualAccountCode
    }
    name
    registrationNo
    tinNo
    email
    address
    currencyID
    image
    imageUrl
    creditTerm
    description
    platformAccess
    outstandingInfo
    overdueInvInfo
    contactNo
    ratingType
    gstRegNo
    gstExpDate
    sstRegNo
    associatedStatus
    bankDetails
    indelibleAccount
    supplierDN {
      ID
      docNo
      supplierID
    }
    supplierCN {
      ID
      docNo
      supplierID
    }
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    costCategory {
      ID
      name
    }
    supplierInv {
      ID
      transactionStatus
      outstanding
    }
    purchaseOrder {
      ID
      supplierID
      contractID
      docNo
    }
    negotiatedSupplyItem {
      ID
      docNo
      supplierID
    }
    eInvoiceType
    isSelfBilled
    msicCodeID
    classificationCodeID
    selfBilledCostCategoryID
    selfBilledCostCategory {
      ID
      name
      description
      classificationCodeID
      costClass
      commonStatus
    }
    msicCode {
      id
      msicCode
      description
      categoryReference
    }
    classificationCode {
      id
      classificationCode
      description
    }
  }
}
    `;

/**
 * __useGetSupplierQuery__
 *
 * To run a query within a React component, call `useGetSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      associatedStatus: // value for 'associatedStatus'
 *   },
 * });
 */
export function useGetSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, baseOptions);
      }
export function useGetSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, baseOptions);
        }
export type GetSupplierQueryHookResult = ReturnType<typeof useGetSupplierQuery>;
export type GetSupplierLazyQueryHookResult = ReturnType<typeof useGetSupplierLazyQuery>;
export type GetSupplierQueryResult = ApolloReactCommon.QueryResult<GetSupplierQuery, GetSupplierQueryVariables>;
export const GetSupplierNameDocument = gql`
    query getSupplierName($ID: String, $companyID: String, $associatedStatus: AssociatedStatus) {
  getSupplier(ID: $ID, companyID: $companyID, associatedStatus: $associatedStatus) {
    ID
    createdTs
    createdBy
    name
    registrationNo
    email
    address
    image
    imageUrl
    description
    contactNo
    ratingType
    gstRegNo
    gstExpDate
    sstRegNo
    associatedStatus
    bankDetails
    outstandingInfo(companyID: $companyID)
    overdueInvInfo(companyID: $companyID)
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
  }
}
    `;

/**
 * __useGetSupplierNameQuery__
 *
 * To run a query within a React component, call `useGetSupplierNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierNameQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      associatedStatus: // value for 'associatedStatus'
 *   },
 * });
 */
export function useGetSupplierNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierNameQuery, GetSupplierNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierNameQuery, GetSupplierNameQueryVariables>(GetSupplierNameDocument, baseOptions);
      }
export function useGetSupplierNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierNameQuery, GetSupplierNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierNameQuery, GetSupplierNameQueryVariables>(GetSupplierNameDocument, baseOptions);
        }
export type GetSupplierNameQueryHookResult = ReturnType<typeof useGetSupplierNameQuery>;
export type GetSupplierNameLazyQueryHookResult = ReturnType<typeof useGetSupplierNameLazyQuery>;
export type GetSupplierNameQueryResult = ApolloReactCommon.QueryResult<GetSupplierNameQuery, GetSupplierNameQueryVariables>;
export const GetSupplierListDocument = gql`
    query getSupplierList($companyID: String!, $orderByAsc: String) {
  getSupplier: getSupplierByCompany(companyID: $companyID, orderByAsc: $orderByAsc) {
    ID
    name
    image
    imageUrl
    creditTerm
    description
    registrationNo
    associatedStatus
  }
}
    `;

/**
 * __useGetSupplierListQuery__
 *
 * To run a query within a React component, call `useGetSupplierListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierListQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSupplierListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierListQuery, GetSupplierListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierListQuery, GetSupplierListQueryVariables>(GetSupplierListDocument, baseOptions);
      }
export function useGetSupplierListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierListQuery, GetSupplierListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierListQuery, GetSupplierListQueryVariables>(GetSupplierListDocument, baseOptions);
        }
export type GetSupplierListQueryHookResult = ReturnType<typeof useGetSupplierListQuery>;
export type GetSupplierListLazyQueryHookResult = ReturnType<typeof useGetSupplierListLazyQuery>;
export type GetSupplierListQueryResult = ApolloReactCommon.QueryResult<GetSupplierListQuery, GetSupplierListQueryVariables>;
export const GetSupplierExecutiveSummaryDocument = gql`
    query getSupplierExecutiveSummary($supplierID: String!) {
  getSupplier(ID: $supplierID) {
    ID
    name
    registrationNo
    overdueInvInfo
    outstandingInfo
    associatedStatus
    costCategory {
      ID
      name
    }
  }
  supplierProfileDate(ID: $supplierID)
  PrefSupplierProductDate(supplierID: $supplierID)
  SupplierAccDate(supplierID: $supplierID)
}
    `;

/**
 * __useGetSupplierExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetSupplierExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierExecutiveSummaryQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetSupplierExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>(GetSupplierExecutiveSummaryDocument, baseOptions);
      }
export function useGetSupplierExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>(GetSupplierExecutiveSummaryDocument, baseOptions);
        }
export type GetSupplierExecutiveSummaryQueryHookResult = ReturnType<typeof useGetSupplierExecutiveSummaryQuery>;
export type GetSupplierExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetSupplierExecutiveSummaryLazyQuery>;
export type GetSupplierExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>;
export const GetSupplierAssocListingDocument = gql`
    query getSupplierAssocListing($ID: String, $orderByAsc: String) {
  getSupplier(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    registrationNo
    overdueInvInfo
    outstandingInfo
    associatedStatus
    image
    imageUrl
    supplierInv {
      ID
      outstanding
      outstandingAmt
      transactionStatus
    }
  }
}
    `;

/**
 * __useGetSupplierAssocListingQuery__
 *
 * To run a query within a React component, call `useGetSupplierAssocListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierAssocListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierAssocListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSupplierAssocListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierAssocListingQuery, GetSupplierAssocListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierAssocListingQuery, GetSupplierAssocListingQueryVariables>(GetSupplierAssocListingDocument, baseOptions);
      }
export function useGetSupplierAssocListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierAssocListingQuery, GetSupplierAssocListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierAssocListingQuery, GetSupplierAssocListingQueryVariables>(GetSupplierAssocListingDocument, baseOptions);
        }
export type GetSupplierAssocListingQueryHookResult = ReturnType<typeof useGetSupplierAssocListingQuery>;
export type GetSupplierAssocListingLazyQueryHookResult = ReturnType<typeof useGetSupplierAssocListingLazyQuery>;
export type GetSupplierAssocListingQueryResult = ApolloReactCommon.QueryResult<GetSupplierAssocListingQuery, GetSupplierAssocListingQueryVariables>;
export const GetOutstandingSupplierCreditDocDocument = gql`
    query getOutstandingSupplierCreditDoc($supplierID: String, $transactionStatus: TransactionStatus, $companyID: String) {
  getAPInvoice(supplierID: $supplierID, transactionStatus: $transactionStatus, companyID: $companyID) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstanding
    outstandingAmt
    transactionStatus
  }
  getAPInvoiceWoDO(supplierID: $supplierID, transactionStatus: $transactionStatus, companyID: $companyID) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstanding
    outstandingAmt
    transactionStatus
  }
  getAPDebitNote(supplierID: $supplierID, transactionStatus: $transactionStatus, companyID: $companyID) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
  getAPCreditNote(supplierID: $supplierID, transactionStatus: $transactionStatus, companyID: $companyID) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
  getDNtoSupplier(supplierID: $supplierID, transactionStatus: $transactionStatus, companyID: $companyID) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
  getCNtoSupplier(supplierID: $supplierID, transactionStatus: $transactionStatus, companyID: $companyID) {
    ID
    docNo
    docDate
    docDueDate
    description
    outstandingAmt
    transactionStatus
  }
}
    `;

/**
 * __useGetOutstandingSupplierCreditDocQuery__
 *
 * To run a query within a React component, call `useGetOutstandingSupplierCreditDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingSupplierCreditDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingSupplierCreditDocQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      transactionStatus: // value for 'transactionStatus'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetOutstandingSupplierCreditDocQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutstandingSupplierCreditDocQuery, GetOutstandingSupplierCreditDocQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutstandingSupplierCreditDocQuery, GetOutstandingSupplierCreditDocQueryVariables>(GetOutstandingSupplierCreditDocDocument, baseOptions);
      }
export function useGetOutstandingSupplierCreditDocLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutstandingSupplierCreditDocQuery, GetOutstandingSupplierCreditDocQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutstandingSupplierCreditDocQuery, GetOutstandingSupplierCreditDocQueryVariables>(GetOutstandingSupplierCreditDocDocument, baseOptions);
        }
export type GetOutstandingSupplierCreditDocQueryHookResult = ReturnType<typeof useGetOutstandingSupplierCreditDocQuery>;
export type GetOutstandingSupplierCreditDocLazyQueryHookResult = ReturnType<typeof useGetOutstandingSupplierCreditDocLazyQuery>;
export type GetOutstandingSupplierCreditDocQueryResult = ApolloReactCommon.QueryResult<GetOutstandingSupplierCreditDocQuery, GetOutstandingSupplierCreditDocQueryVariables>;
export const GetSupplierByCompanyDocument = gql`
    query getSupplierByCompany($companyID: String!, $withoutCompany: Boolean, $orderByAsc: String, $orderByDesc: String, $limit: Float, $associatedStatus: AssociatedStatus) {
  getSupplierByCompany(companyID: $companyID, withoutCompany: $withoutCompany, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, limit: $limit, associatedStatus: $associatedStatus) {
    ID
    createdTs
    createdBy
    name
    registrationNo
    tinNo
    email
    address
    currencyID
    image
    imageUrl
    creditTerm
    description
    platformAccess
    outstandingInfo(companyID: $companyID)
    overdueInvInfo(companyID: $companyID)
    contactNo
    supplierDN {
      ID
      docNo
      supplierID
    }
    supplierCN {
      ID
      docNo
      supplierID
    }
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    description
    ratingType
    gstRegNo
    gstExpDate
    sstRegNo
    associatedStatus
    bankDetails
    accountID
    taxSchemeID
    supplierTypeID
    costCategory {
      ID
      name
    }
    supplierInv {
      ID
      transactionStatus
      outstanding
    }
    purchaseOrder {
      ID
      supplierID
      contractID
      docNo
    }
    negotiatedSupplyItem {
      ID
      docNo
      supplierID
    }
    eInvoiceType
    isSelfBilled
    msicCodeID
    classificationCodeID
    selfBilledCostCategoryID
    selfBilledCostCategory {
      ID
      name
      description
      classificationCodeID
      costClass
      commonStatus
    }
  }
}
    `;

/**
 * __useGetSupplierByCompanyQuery__
 *
 * To run a query within a React component, call `useGetSupplierByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierByCompanyQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      withoutCompany: // value for 'withoutCompany'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      limit: // value for 'limit'
 *      associatedStatus: // value for 'associatedStatus'
 *   },
 * });
 */
export function useGetSupplierByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierByCompanyQuery, GetSupplierByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierByCompanyQuery, GetSupplierByCompanyQueryVariables>(GetSupplierByCompanyDocument, baseOptions);
      }
export function useGetSupplierByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierByCompanyQuery, GetSupplierByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierByCompanyQuery, GetSupplierByCompanyQueryVariables>(GetSupplierByCompanyDocument, baseOptions);
        }
export type GetSupplierByCompanyQueryHookResult = ReturnType<typeof useGetSupplierByCompanyQuery>;
export type GetSupplierByCompanyLazyQueryHookResult = ReturnType<typeof useGetSupplierByCompanyLazyQuery>;
export type GetSupplierByCompanyQueryResult = ApolloReactCommon.QueryResult<GetSupplierByCompanyQuery, GetSupplierByCompanyQueryVariables>;
export const CreateSupplierCCategoryDocument = gql`
    mutation createSupplierCCategory($input: SupplierCCategoryInput!) {
  createSupplierCCategory(input: $input) {
    costCategoryID
  }
}
    `;
export type CreateSupplierCCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>;

/**
 * __useCreateSupplierCCategoryMutation__
 *
 * To run a mutation, you first call `useCreateSupplierCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierCCategoryMutation, { data, loading, error }] = useCreateSupplierCCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>(CreateSupplierCCategoryDocument, baseOptions);
      }
export type CreateSupplierCCategoryMutationHookResult = ReturnType<typeof useCreateSupplierCCategoryMutation>;
export type CreateSupplierCCategoryMutationResult = ApolloReactCommon.MutationResult<CreateSupplierCCategoryMutation>;
export type CreateSupplierCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>;
export const UpdateSupplierCCategoryDocument = gql`
    mutation updateSupplierCCategory($input: SupplierCCategoryInput!) {
  updateSupplierCCategory(input: $input) {
    costCategoryID
  }
}
    `;
export type UpdateSupplierCCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>;

/**
 * __useUpdateSupplierCCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierCCategoryMutation, { data, loading, error }] = useUpdateSupplierCCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>(UpdateSupplierCCategoryDocument, baseOptions);
      }
export type UpdateSupplierCCategoryMutationHookResult = ReturnType<typeof useUpdateSupplierCCategoryMutation>;
export type UpdateSupplierCCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierCCategoryMutation>;
export type UpdateSupplierCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>;
export const GetSupplierCCategoryDocument = gql`
    query getSupplierCCategory($supplierID: String, $costCategoryID: String) {
  getSupplierCCategory(supplierID: $supplierID, costCategoryID: $costCategoryID) {
    supplierID
    costCategoryID
  }
}
    `;

/**
 * __useGetSupplierCCategoryQuery__
 *
 * To run a query within a React component, call `useGetSupplierCCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierCCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierCCategoryQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetSupplierCCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>(GetSupplierCCategoryDocument, baseOptions);
      }
export function useGetSupplierCCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>(GetSupplierCCategoryDocument, baseOptions);
        }
export type GetSupplierCCategoryQueryHookResult = ReturnType<typeof useGetSupplierCCategoryQuery>;
export type GetSupplierCCategoryLazyQueryHookResult = ReturnType<typeof useGetSupplierCCategoryLazyQuery>;
export type GetSupplierCCategoryQueryResult = ApolloReactCommon.QueryResult<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>;
export const AddCCategoryInSupplierDocument = gql`
    mutation addCCategoryInSupplier($supplierID: String!, $costCategoryID: [String!]!) {
  addCCategoryInSupplier(supplierID: $supplierID, costCategoryID: $costCategoryID)
}
    `;
export type AddCCategoryInSupplierMutationFn = ApolloReactCommon.MutationFunction<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>;

/**
 * __useAddCCategoryInSupplierMutation__
 *
 * To run a mutation, you first call `useAddCCategoryInSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCCategoryInSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCCategoryInSupplierMutation, { data, loading, error }] = useAddCCategoryInSupplierMutation({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useAddCCategoryInSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>(AddCCategoryInSupplierDocument, baseOptions);
      }
export type AddCCategoryInSupplierMutationHookResult = ReturnType<typeof useAddCCategoryInSupplierMutation>;
export type AddCCategoryInSupplierMutationResult = ApolloReactCommon.MutationResult<AddCCategoryInSupplierMutation>;
export type AddCCategoryInSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>;
export const DeleteSupplierDocument = gql`
    mutation deleteSupplier($input: SupplierDeleteInput!) {
  deleteSupplier(input: $input)
}
    `;
export type DeleteSupplierMutationFn = ApolloReactCommon.MutationFunction<DeleteSupplierMutation, DeleteSupplierMutationVariables>;

/**
 * __useDeleteSupplierMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierMutation, { data, loading, error }] = useDeleteSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSupplierMutation, DeleteSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSupplierMutation, DeleteSupplierMutationVariables>(DeleteSupplierDocument, baseOptions);
      }
export type DeleteSupplierMutationHookResult = ReturnType<typeof useDeleteSupplierMutation>;
export type DeleteSupplierMutationResult = ApolloReactCommon.MutationResult<DeleteSupplierMutation>;
export type DeleteSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSupplierMutation, DeleteSupplierMutationVariables>;
export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    accountID
    avatar
    name
    email
    contactNo
    department
    superUser
    userName
    jobTitle
    lastestAccessedEntity
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const LoginDocument = gql`
    mutation login($loginId: String!, $password: String!) {
  login(loginId: $loginId, password: $password) {
    accessToken
    refreshToken
    user {
      name
      lastestAccessedEntity
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const UploadProfilePicDocument = gql`
    mutation uploadProfilePic($userID: String!, $file: Upload!) {
  uploadProfilePic(userID: $userID, file: $file)
}
    `;
export type UploadProfilePicMutationFn = ApolloReactCommon.MutationFunction<UploadProfilePicMutation, UploadProfilePicMutationVariables>;

/**
 * __useUploadProfilePicMutation__
 *
 * To run a mutation, you first call `useUploadProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePicMutation, { data, loading, error }] = useUploadProfilePicMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadProfilePicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProfilePicMutation, UploadProfilePicMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadProfilePicMutation, UploadProfilePicMutationVariables>(UploadProfilePicDocument, baseOptions);
      }
export type UploadProfilePicMutationHookResult = ReturnType<typeof useUploadProfilePicMutation>;
export type UploadProfilePicMutationResult = ApolloReactCommon.MutationResult<UploadProfilePicMutation>;
export type UploadProfilePicMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadProfilePicMutation, UploadProfilePicMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($userID: String, $password: String!, $token: String!) {
  resetPassword(userID: $userID, password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout($ID: String, $entityID: String) {
  logout(ID: $ID, entityID: $entityID)
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($input: passwordInfo!) {
  createPassword(input: $input)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const GetContractCalendarInfoDocument = gql`
    query getContractCalendarInfo($currDate: DateTime) {
  getContractCalendarInfo(currDate: $currDate)
}
    `;

/**
 * __useGetContractCalendarInfoQuery__
 *
 * To run a query within a React component, call `useGetContractCalendarInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCalendarInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCalendarInfoQuery({
 *   variables: {
 *      currDate: // value for 'currDate'
 *   },
 * });
 */
export function useGetContractCalendarInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractCalendarInfoQuery, GetContractCalendarInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractCalendarInfoQuery, GetContractCalendarInfoQueryVariables>(GetContractCalendarInfoDocument, baseOptions);
      }
export function useGetContractCalendarInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractCalendarInfoQuery, GetContractCalendarInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractCalendarInfoQuery, GetContractCalendarInfoQueryVariables>(GetContractCalendarInfoDocument, baseOptions);
        }
export type GetContractCalendarInfoQueryHookResult = ReturnType<typeof useGetContractCalendarInfoQuery>;
export type GetContractCalendarInfoLazyQueryHookResult = ReturnType<typeof useGetContractCalendarInfoLazyQuery>;
export type GetContractCalendarInfoQueryResult = ApolloReactCommon.QueryResult<GetContractCalendarInfoQuery, GetContractCalendarInfoQueryVariables>;
export const GetSubconCalendarInfoDocument = gql`
    query getSubconCalendarInfo($currDate: DateTime) {
  getSubconCalendarInfo(currDate: $currDate)
}
    `;

/**
 * __useGetSubconCalendarInfoQuery__
 *
 * To run a query within a React component, call `useGetSubconCalendarInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconCalendarInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconCalendarInfoQuery({
 *   variables: {
 *      currDate: // value for 'currDate'
 *   },
 * });
 */
export function useGetSubconCalendarInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconCalendarInfoQuery, GetSubconCalendarInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconCalendarInfoQuery, GetSubconCalendarInfoQueryVariables>(GetSubconCalendarInfoDocument, baseOptions);
      }
export function useGetSubconCalendarInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconCalendarInfoQuery, GetSubconCalendarInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconCalendarInfoQuery, GetSubconCalendarInfoQueryVariables>(GetSubconCalendarInfoDocument, baseOptions);
        }
export type GetSubconCalendarInfoQueryHookResult = ReturnType<typeof useGetSubconCalendarInfoQuery>;
export type GetSubconCalendarInfoLazyQueryHookResult = ReturnType<typeof useGetSubconCalendarInfoLazyQuery>;
export type GetSubconCalendarInfoQueryResult = ApolloReactCommon.QueryResult<GetSubconCalendarInfoQuery, GetSubconCalendarInfoQueryVariables>;
export const GetPpCalendarInfoDocument = gql`
    query getPPCalendarInfo($currDate: DateTime) {
  getPPCalendarInfo(currDate: $currDate)
}
    `;

/**
 * __useGetPpCalendarInfoQuery__
 *
 * To run a query within a React component, call `useGetPpCalendarInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPpCalendarInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPpCalendarInfoQuery({
 *   variables: {
 *      currDate: // value for 'currDate'
 *   },
 * });
 */
export function useGetPpCalendarInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPpCalendarInfoQuery, GetPpCalendarInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPpCalendarInfoQuery, GetPpCalendarInfoQueryVariables>(GetPpCalendarInfoDocument, baseOptions);
      }
export function useGetPpCalendarInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPpCalendarInfoQuery, GetPpCalendarInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPpCalendarInfoQuery, GetPpCalendarInfoQueryVariables>(GetPpCalendarInfoDocument, baseOptions);
        }
export type GetPpCalendarInfoQueryHookResult = ReturnType<typeof useGetPpCalendarInfoQuery>;
export type GetPpCalendarInfoLazyQueryHookResult = ReturnType<typeof useGetPpCalendarInfoLazyQuery>;
export type GetPpCalendarInfoQueryResult = ApolloReactCommon.QueryResult<GetPpCalendarInfoQuery, GetPpCalendarInfoQueryVariables>;
export const GetContractOsDebitDocumentDocument = gql`
    query getContractOSDebitDocument($ID: String, $contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus, $receiptStatus: ReceiptStatus) {
  getContractClaim(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    contractID
    contract {
      ID
      creditTerm
    }
    isGlExport
    claimDocNo
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
    taxAmt
    taxRate
    principalAmt
    submittedDate
  }
  getContractMiscInvoice(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
    contractID
    customerID
    isDaywork
    taxRate
    taxAmt
    contract {
      ID
      creditTerm
      bankAccount {
        name
        accountNo
      }
    }
    contractMiscInvoiceItem {
      ID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      taxRate
      taxAmt
      remarks
    }
  }
  getContractDN(contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
    contractID
    contract {
      ID
      creditTerm
    }
    contractDNItem {
      ID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      taxRate
      taxAmt
      remarks
    }
  }
  getContractRefund(contractID: $contractID, customerID: $customerID, companyID: $companyID, receiptStatus: $receiptStatus) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
    contractID
    contract {
      ID
      creditTerm
    }
  }
  getClientCN(contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
    contractID
    contract {
      ID
      creditTerm
    }
  }
}
    `;

/**
 * __useGetContractOsDebitDocumentQuery__
 *
 * To run a query within a React component, call `useGetContractOsDebitDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractOsDebitDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractOsDebitDocumentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetContractOsDebitDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractOsDebitDocumentQuery, GetContractOsDebitDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractOsDebitDocumentQuery, GetContractOsDebitDocumentQueryVariables>(GetContractOsDebitDocumentDocument, baseOptions);
      }
export function useGetContractOsDebitDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractOsDebitDocumentQuery, GetContractOsDebitDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractOsDebitDocumentQuery, GetContractOsDebitDocumentQueryVariables>(GetContractOsDebitDocumentDocument, baseOptions);
        }
export type GetContractOsDebitDocumentQueryHookResult = ReturnType<typeof useGetContractOsDebitDocumentQuery>;
export type GetContractOsDebitDocumentLazyQueryHookResult = ReturnType<typeof useGetContractOsDebitDocumentLazyQuery>;
export type GetContractOsDebitDocumentQueryResult = ApolloReactCommon.QueryResult<GetContractOsDebitDocumentQuery, GetContractOsDebitDocumentQueryVariables>;
export const GetContractCustomerPdfDocument = gql`
    query getContractCustomerPdf($contractID: String, $customerID: String) {
  getContract(ID: $contractID) {
    ID
    title
    pdfDocumentTitle
    creditTerm
    contractNo
    contractSum
    revisedContractSum
    startDate
    endDate
    progressRetentionPerc
    latestBudgetID
    contractClaim {
      claimDocNo
    }
    contact {
      ID
      name
      contactNo
    }
    bankAccount {
      ID
      name
      accountNo
    }
  }
  getCustomer(ID: $customerID) {
    ID
    name
    address
    contactPerson {
      ID
      name
      contactNo
    }
  }
}
    `;

/**
 * __useGetContractCustomerPdfQuery__
 *
 * To run a query within a React component, call `useGetContractCustomerPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCustomerPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCustomerPdfQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetContractCustomerPdfQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractCustomerPdfQuery, GetContractCustomerPdfQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractCustomerPdfQuery, GetContractCustomerPdfQueryVariables>(GetContractCustomerPdfDocument, baseOptions);
      }
export function useGetContractCustomerPdfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractCustomerPdfQuery, GetContractCustomerPdfQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractCustomerPdfQuery, GetContractCustomerPdfQueryVariables>(GetContractCustomerPdfDocument, baseOptions);
        }
export type GetContractCustomerPdfQueryHookResult = ReturnType<typeof useGetContractCustomerPdfQuery>;
export type GetContractCustomerPdfLazyQueryHookResult = ReturnType<typeof useGetContractCustomerPdfLazyQuery>;
export type GetContractCustomerPdfQueryResult = ApolloReactCommon.QueryResult<GetContractCustomerPdfQuery, GetContractCustomerPdfQueryVariables>;
export const GetClaimPdfDetailDocument = gql`
    query getClaimPdfDetail($contractID: String) {
  contractVoCalculation(contractID: $contractID)
  maxRetention(contractID: $contractID)
}
    `;

/**
 * __useGetClaimPdfDetailQuery__
 *
 * To run a query within a React component, call `useGetClaimPdfDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimPdfDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimPdfDetailQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetClaimPdfDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimPdfDetailQuery, GetClaimPdfDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimPdfDetailQuery, GetClaimPdfDetailQueryVariables>(GetClaimPdfDetailDocument, baseOptions);
      }
export function useGetClaimPdfDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimPdfDetailQuery, GetClaimPdfDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimPdfDetailQuery, GetClaimPdfDetailQueryVariables>(GetClaimPdfDetailDocument, baseOptions);
        }
export type GetClaimPdfDetailQueryHookResult = ReturnType<typeof useGetClaimPdfDetailQuery>;
export type GetClaimPdfDetailLazyQueryHookResult = ReturnType<typeof useGetClaimPdfDetailLazyQuery>;
export type GetClaimPdfDetailQueryResult = ApolloReactCommon.QueryResult<GetClaimPdfDetailQuery, GetClaimPdfDetailQueryVariables>;
export const GetAllocationClientAccountDocument = gql`
    query getAllocationClientAccount($contractID: String, $creditDebitID: String!, $transactionStatus: TransactionStatus) {
  getContractDN(contractID: $contractID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getClientCN(contractID: $contractID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getContractMiscInvoice(contractID: $contractID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getContractClaim(contractID: $contractID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    claimDocNo
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    nonEditableAllocation(creditDebitID: $creditDebitID)
    description
  }
  cancelledClaim: getContractClaim(contractID: $contractID, transactionStatus: CANCELLED) {
    ID
    outstandingAmt
    taxRate
    docRef
    claimDocNo
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    nonEditableAllocation(creditDebitID: $creditDebitID)
    description
  }
  getContractRefund(contractID: $contractID) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
}
    `;

/**
 * __useGetAllocationClientAccountQuery__
 *
 * To run a query within a React component, call `useGetAllocationClientAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllocationClientAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllocationClientAccountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      creditDebitID: // value for 'creditDebitID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetAllocationClientAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllocationClientAccountQuery, GetAllocationClientAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllocationClientAccountQuery, GetAllocationClientAccountQueryVariables>(GetAllocationClientAccountDocument, baseOptions);
      }
export function useGetAllocationClientAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllocationClientAccountQuery, GetAllocationClientAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllocationClientAccountQuery, GetAllocationClientAccountQueryVariables>(GetAllocationClientAccountDocument, baseOptions);
        }
export type GetAllocationClientAccountQueryHookResult = ReturnType<typeof useGetAllocationClientAccountQuery>;
export type GetAllocationClientAccountLazyQueryHookResult = ReturnType<typeof useGetAllocationClientAccountLazyQuery>;
export type GetAllocationClientAccountQueryResult = ApolloReactCommon.QueryResult<GetAllocationClientAccountQuery, GetAllocationClientAccountQueryVariables>;
export const MakeContractAllocationDocument = gql`
    mutation makeContractAllocation($ledger: [LedgerInput!]!) {
  makeContractAllocation(ledger: $ledger)
}
    `;
export type MakeContractAllocationMutationFn = ApolloReactCommon.MutationFunction<MakeContractAllocationMutation, MakeContractAllocationMutationVariables>;

/**
 * __useMakeContractAllocationMutation__
 *
 * To run a mutation, you first call `useMakeContractAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeContractAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeContractAllocationMutation, { data, loading, error }] = useMakeContractAllocationMutation({
 *   variables: {
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeContractAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeContractAllocationMutation, MakeContractAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeContractAllocationMutation, MakeContractAllocationMutationVariables>(MakeContractAllocationDocument, baseOptions);
      }
export type MakeContractAllocationMutationHookResult = ReturnType<typeof useMakeContractAllocationMutation>;
export type MakeContractAllocationMutationResult = ApolloReactCommon.MutationResult<MakeContractAllocationMutation>;
export type MakeContractAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeContractAllocationMutation, MakeContractAllocationMutationVariables>;
export const ResetContractAllocationDocument = gql`
    mutation resetContractAllocation($creditID: String, $debitID: String, $receiptPayment: Boolean) {
  resetContractAllocation(creditID: $creditID, debitID: $debitID, receiptPayment: $receiptPayment)
}
    `;
export type ResetContractAllocationMutationFn = ApolloReactCommon.MutationFunction<ResetContractAllocationMutation, ResetContractAllocationMutationVariables>;

/**
 * __useResetContractAllocationMutation__
 *
 * To run a mutation, you first call `useResetContractAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetContractAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetContractAllocationMutation, { data, loading, error }] = useResetContractAllocationMutation({
 *   variables: {
 *      creditID: // value for 'creditID'
 *      debitID: // value for 'debitID'
 *      receiptPayment: // value for 'receiptPayment'
 *   },
 * });
 */
export function useResetContractAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetContractAllocationMutation, ResetContractAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetContractAllocationMutation, ResetContractAllocationMutationVariables>(ResetContractAllocationDocument, baseOptions);
      }
export type ResetContractAllocationMutationHookResult = ReturnType<typeof useResetContractAllocationMutation>;
export type ResetContractAllocationMutationResult = ApolloReactCommon.MutationResult<ResetContractAllocationMutation>;
export type ResetContractAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetContractAllocationMutation, ResetContractAllocationMutationVariables>;
export const GetContractReceiptAllocationDocument = gql`
    query getContractReceiptAllocation($creditID: String!) {
  getContractReceiptAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetContractReceiptAllocationQuery__
 *
 * To run a query within a React component, call `useGetContractReceiptAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractReceiptAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractReceiptAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetContractReceiptAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractReceiptAllocationQuery, GetContractReceiptAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractReceiptAllocationQuery, GetContractReceiptAllocationQueryVariables>(GetContractReceiptAllocationDocument, baseOptions);
      }
export function useGetContractReceiptAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractReceiptAllocationQuery, GetContractReceiptAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractReceiptAllocationQuery, GetContractReceiptAllocationQueryVariables>(GetContractReceiptAllocationDocument, baseOptions);
        }
export type GetContractReceiptAllocationQueryHookResult = ReturnType<typeof useGetContractReceiptAllocationQuery>;
export type GetContractReceiptAllocationLazyQueryHookResult = ReturnType<typeof useGetContractReceiptAllocationLazyQuery>;
export type GetContractReceiptAllocationQueryResult = ApolloReactCommon.QueryResult<GetContractReceiptAllocationQuery, GetContractReceiptAllocationQueryVariables>;
export const GetContractRefundAllocationDocument = gql`
    query getContractRefundAllocation($debitID: String, $contractID: String, $receiptStatus: String) {
  getContractRefundAllocation(debitID: $debitID, contractID: $contractID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetContractRefundAllocationQuery__
 *
 * To run a query within a React component, call `useGetContractRefundAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractRefundAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractRefundAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *      contractID: // value for 'contractID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetContractRefundAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractRefundAllocationQuery, GetContractRefundAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractRefundAllocationQuery, GetContractRefundAllocationQueryVariables>(GetContractRefundAllocationDocument, baseOptions);
      }
export function useGetContractRefundAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractRefundAllocationQuery, GetContractRefundAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractRefundAllocationQuery, GetContractRefundAllocationQueryVariables>(GetContractRefundAllocationDocument, baseOptions);
        }
export type GetContractRefundAllocationQueryHookResult = ReturnType<typeof useGetContractRefundAllocationQuery>;
export type GetContractRefundAllocationLazyQueryHookResult = ReturnType<typeof useGetContractRefundAllocationLazyQuery>;
export type GetContractRefundAllocationQueryResult = ApolloReactCommon.QueryResult<GetContractRefundAllocationQuery, GetContractRefundAllocationQueryVariables>;
export const GetContractAdvanceAllocationDocument = gql`
    query getContractAdvanceAllocation($creditID: String!) {
  getContractAdvanceAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetContractAdvanceAllocationQuery__
 *
 * To run a query within a React component, call `useGetContractAdvanceAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAdvanceAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAdvanceAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetContractAdvanceAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractAdvanceAllocationQuery, GetContractAdvanceAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractAdvanceAllocationQuery, GetContractAdvanceAllocationQueryVariables>(GetContractAdvanceAllocationDocument, baseOptions);
      }
export function useGetContractAdvanceAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractAdvanceAllocationQuery, GetContractAdvanceAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractAdvanceAllocationQuery, GetContractAdvanceAllocationQueryVariables>(GetContractAdvanceAllocationDocument, baseOptions);
        }
export type GetContractAdvanceAllocationQueryHookResult = ReturnType<typeof useGetContractAdvanceAllocationQuery>;
export type GetContractAdvanceAllocationLazyQueryHookResult = ReturnType<typeof useGetContractAdvanceAllocationLazyQuery>;
export type GetContractAdvanceAllocationQueryResult = ApolloReactCommon.QueryResult<GetContractAdvanceAllocationQuery, GetContractAdvanceAllocationQueryVariables>;
export const GetContractRetClaimAllocationDocument = gql`
    query getContractRetClaimAllocation($debitID: String!) {
  getContractRetClaimAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetContractRetClaimAllocationQuery__
 *
 * To run a query within a React component, call `useGetContractRetClaimAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractRetClaimAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractRetClaimAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetContractRetClaimAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractRetClaimAllocationQuery, GetContractRetClaimAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractRetClaimAllocationQuery, GetContractRetClaimAllocationQueryVariables>(GetContractRetClaimAllocationDocument, baseOptions);
      }
export function useGetContractRetClaimAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractRetClaimAllocationQuery, GetContractRetClaimAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractRetClaimAllocationQuery, GetContractRetClaimAllocationQueryVariables>(GetContractRetClaimAllocationDocument, baseOptions);
        }
export type GetContractRetClaimAllocationQueryHookResult = ReturnType<typeof useGetContractRetClaimAllocationQuery>;
export type GetContractRetClaimAllocationLazyQueryHookResult = ReturnType<typeof useGetContractRetClaimAllocationLazyQuery>;
export type GetContractRetClaimAllocationQueryResult = ApolloReactCommon.QueryResult<GetContractRetClaimAllocationQuery, GetContractRetClaimAllocationQueryVariables>;
export const GetContractCnAllocationDocument = gql`
    query getContractCNAllocation($creditID: String!) {
  getContractCNAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetContractCnAllocationQuery__
 *
 * To run a query within a React component, call `useGetContractCnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCnAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetContractCnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractCnAllocationQuery, GetContractCnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractCnAllocationQuery, GetContractCnAllocationQueryVariables>(GetContractCnAllocationDocument, baseOptions);
      }
export function useGetContractCnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractCnAllocationQuery, GetContractCnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractCnAllocationQuery, GetContractCnAllocationQueryVariables>(GetContractCnAllocationDocument, baseOptions);
        }
export type GetContractCnAllocationQueryHookResult = ReturnType<typeof useGetContractCnAllocationQuery>;
export type GetContractCnAllocationLazyQueryHookResult = ReturnType<typeof useGetContractCnAllocationLazyQuery>;
export type GetContractCnAllocationQueryResult = ApolloReactCommon.QueryResult<GetContractCnAllocationQuery, GetContractCnAllocationQueryVariables>;
export const GetContractDnAllocationDocument = gql`
    query getContractDNAllocation($debitID: String!) {
  getContractDNAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetContractDnAllocationQuery__
 *
 * To run a query within a React component, call `useGetContractDnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractDnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractDnAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetContractDnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractDnAllocationQuery, GetContractDnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractDnAllocationQuery, GetContractDnAllocationQueryVariables>(GetContractDnAllocationDocument, baseOptions);
      }
export function useGetContractDnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractDnAllocationQuery, GetContractDnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractDnAllocationQuery, GetContractDnAllocationQueryVariables>(GetContractDnAllocationDocument, baseOptions);
        }
export type GetContractDnAllocationQueryHookResult = ReturnType<typeof useGetContractDnAllocationQuery>;
export type GetContractDnAllocationLazyQueryHookResult = ReturnType<typeof useGetContractDnAllocationLazyQuery>;
export type GetContractDnAllocationQueryResult = ApolloReactCommon.QueryResult<GetContractDnAllocationQuery, GetContractDnAllocationQueryVariables>;
export const GetContractMiscInvoiceAllocationDocument = gql`
    query getContractMiscInvoiceAllocation($debitID: String!) {
  getContractMiscInvoiceAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetContractMiscInvoiceAllocationQuery__
 *
 * To run a query within a React component, call `useGetContractMiscInvoiceAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractMiscInvoiceAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractMiscInvoiceAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetContractMiscInvoiceAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractMiscInvoiceAllocationQuery, GetContractMiscInvoiceAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractMiscInvoiceAllocationQuery, GetContractMiscInvoiceAllocationQueryVariables>(GetContractMiscInvoiceAllocationDocument, baseOptions);
      }
export function useGetContractMiscInvoiceAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractMiscInvoiceAllocationQuery, GetContractMiscInvoiceAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractMiscInvoiceAllocationQuery, GetContractMiscInvoiceAllocationQueryVariables>(GetContractMiscInvoiceAllocationDocument, baseOptions);
        }
export type GetContractMiscInvoiceAllocationQueryHookResult = ReturnType<typeof useGetContractMiscInvoiceAllocationQuery>;
export type GetContractMiscInvoiceAllocationLazyQueryHookResult = ReturnType<typeof useGetContractMiscInvoiceAllocationLazyQuery>;
export type GetContractMiscInvoiceAllocationQueryResult = ApolloReactCommon.QueryResult<GetContractMiscInvoiceAllocationQuery, GetContractMiscInvoiceAllocationQueryVariables>;
export const GetClientDnAllocationDocument = gql`
    query getClientDNAllocation($creditID: String!) {
  getClientDNAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetClientDnAllocationQuery__
 *
 * To run a query within a React component, call `useGetClientDnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientDnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientDnAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetClientDnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientDnAllocationQuery, GetClientDnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientDnAllocationQuery, GetClientDnAllocationQueryVariables>(GetClientDnAllocationDocument, baseOptions);
      }
export function useGetClientDnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientDnAllocationQuery, GetClientDnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientDnAllocationQuery, GetClientDnAllocationQueryVariables>(GetClientDnAllocationDocument, baseOptions);
        }
export type GetClientDnAllocationQueryHookResult = ReturnType<typeof useGetClientDnAllocationQuery>;
export type GetClientDnAllocationLazyQueryHookResult = ReturnType<typeof useGetClientDnAllocationLazyQuery>;
export type GetClientDnAllocationQueryResult = ApolloReactCommon.QueryResult<GetClientDnAllocationQuery, GetClientDnAllocationQueryVariables>;
export const GetClientCnAllocationDocument = gql`
    query getClientCNAllocation($debitID: String!) {
  getClientCNAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetClientCnAllocationQuery__
 *
 * To run a query within a React component, call `useGetClientCnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientCnAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetClientCnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientCnAllocationQuery, GetClientCnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientCnAllocationQuery, GetClientCnAllocationQueryVariables>(GetClientCnAllocationDocument, baseOptions);
      }
export function useGetClientCnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientCnAllocationQuery, GetClientCnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientCnAllocationQuery, GetClientCnAllocationQueryVariables>(GetClientCnAllocationDocument, baseOptions);
        }
export type GetClientCnAllocationQueryHookResult = ReturnType<typeof useGetClientCnAllocationQuery>;
export type GetClientCnAllocationLazyQueryHookResult = ReturnType<typeof useGetClientCnAllocationLazyQuery>;
export type GetClientCnAllocationQueryResult = ApolloReactCommon.QueryResult<GetClientCnAllocationQuery, GetClientCnAllocationQueryVariables>;
export const GetContractClaimDocument = gql`
    query getContractClaim($ID: String, $contractID: String, $customerID: String, $companyID: String) {
  getContractClaim(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID) {
    ID
    createdTs
    createdBy
    isRetention
    modBy
    contractID
    contract {
      ID
      title
    }
    customerID
    customer {
      ID
      name
    }
    currencyID
    docNo
    claimDocNo
    docRef
    description
    docDate
    baseAmt
    taxSchemeID
    taxScheme {
      ID
      code
      description
    }
    taxRate
    taxAmt
    exchangeRate
    grossAmt
    localBaseAmt
    localTaxAmt
    accountID
    docAmt
    docRefID
    docRefTable
    docDueDate
    submittedDate
    principalAmt
    previousCertifiedAmt
    submittedCertifiedAmt
    currentCertifiedAmt
    previousVOClaimAmt
    submittedVOClaimAmt
    currentVOClaimAmt
    previousMosAmt
    submittedMosAmt
    currentMosAmt
    retentionAmt
    submittedRetentionAmt
    balanceRetentionAmt
    submittedDayWorkAmt
    currentDayWorkAmt
    balanceAdvanceAmt
    currentAdvRecoupmentAmt
    currentDeductionAmt
    submittedDeductionAmt
    balanceDeductionAmt
    outstandingAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    cancellationDate
    cancellationRemarks
    voDisplayAmt
    adjustmentInfo
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    contractClaimAllocation {
      ID
      previousCertifiedAmt
      submittedCertifiedAmt
      currentCertifiedAmt
      contractClaimID
      wbsBudgetDetailID
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      wbsBudgetDetail {
        ID
        wbsID
        budgetAmt
        wbs {
          ID
          name
        }
      }
      latestWbsBudgetDetail(contractID: $contractID) {
        ID
        budgetAmt
      }
    }
    contractClaimVOAllocation {
      ID
      contractVOID
      contractVO {
        ID
        docNo
        docRef
      }
      currentVOClaimAmt
      submittedVOClaimAmt
      previousVOClaimAmt
    }
    contractClaimVORevenueWbsAllocation {
      ID
      contractClaimID
      contractVORevenueWbsID
      contractVORevenueWbs {
        ID
        wbsID
        revenueCategoryID
        approvedAmt
        contractVO {
          ID
          docNo
        }
        wbsBudgetDetailID
        wbsBudgetDetail {
          ID
          wbsID
          revenueCategory {
            ID
            name
          }
        }
      }
      currentVOClaimAmt
      submittedVOClaimAmt
      previousVOClaimAmt
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  claimsInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractClaimQuery__
 *
 * To run a query within a React component, call `useGetContractClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractClaimQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetContractClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractClaimQuery, GetContractClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractClaimQuery, GetContractClaimQueryVariables>(GetContractClaimDocument, baseOptions);
      }
export function useGetContractClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractClaimQuery, GetContractClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractClaimQuery, GetContractClaimQueryVariables>(GetContractClaimDocument, baseOptions);
        }
export type GetContractClaimQueryHookResult = ReturnType<typeof useGetContractClaimQuery>;
export type GetContractClaimLazyQueryHookResult = ReturnType<typeof useGetContractClaimLazyQuery>;
export type GetContractClaimQueryResult = ApolloReactCommon.QueryResult<GetContractClaimQuery, GetContractClaimQueryVariables>;
export const GetContractClaimPdfDocument = gql`
    query getContractClaimPDF($ID: String, $contractID: String, $customerID: String, $companyID: String) {
  getContractClaim(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID) {
    ID
    createdTs
    createdBy
    modBy
    contractID
    contract {
      ID
      title
    }
    customerID
    customer {
      ID
      name
    }
    currencyID
    docNo
    claimDocNo
    docRef
    description
    docDate
    baseAmt
    taxSchemeID
    taxScheme {
      ID
      code
      description
    }
    taxRate
    taxAmt
    exchangeRate
    grossAmt
    localBaseAmt
    localTaxAmt
    accountID
    docAmt
    docRefID
    docRefTable
    docDueDate
    submittedDate
    principalAmt
    previousCertifiedAmt
    submittedCertifiedAmt
    currentCertifiedAmt
    previousVOClaimAmt
    submittedVOClaimAmt
    currentVOClaimAmt
    previousMosAmt
    submittedMosAmt
    currentMosAmt
    retentionAmt
    submittedRetentionAmt
    balanceRetentionAmt
    submittedDayWorkAmt
    currentDayWorkAmt
    balanceAdvanceAmt
    currentAdvRecoupmentAmt
    currentDeductionAmt
    submittedDeductionAmt
    balanceDeductionAmt
    outstandingAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    cancellationDate
    cancellationRemarks
    claimDetailRevenueWBS
    voDisplayAmt
    adjustmentInfo
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    contractClaimAllocation {
      ID
      previousCertifiedAmt
      submittedCertifiedAmt
      currentCertifiedAmt
      contractClaimID
      wbsBudgetDetailID
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      wbsID
      wbs {
        ID
        name
      }
      wbsBudgetDetail {
        ID
        wbsID
        budgetAmt
        wbs {
          ID
          name
        }
      }
      latestWbsBudgetDetail(contractID: $contractID) {
        ID
        budgetAmt
      }
    }
    contractClaimVOAllocation {
      ID
      contractVOID
      contractVO {
        ID
        docNo
        docRef
      }
      currentVOClaimAmt
      submittedVOClaimAmt
      previousVOClaimAmt
    }
    contractClaimVORevenueWbsAllocation {
      ID
      contractClaimID
      contractVORevenueWbsID
      contractVORevenueWbs {
        ID
        approvedAmt
        contractVO {
          ID
          docNo
        }
        wbsBudgetDetailID
        wbsBudgetDetail {
          ID
          wbsID
          revenueCategory {
            ID
            name
          }
        }
      }
      currentVOClaimAmt
      submittedVOClaimAmt
      previousVOClaimAmt
    }
  }
}
    `;

/**
 * __useGetContractClaimPdfQuery__
 *
 * To run a query within a React component, call `useGetContractClaimPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractClaimPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractClaimPdfQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetContractClaimPdfQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractClaimPdfQuery, GetContractClaimPdfQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractClaimPdfQuery, GetContractClaimPdfQueryVariables>(GetContractClaimPdfDocument, baseOptions);
      }
export function useGetContractClaimPdfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractClaimPdfQuery, GetContractClaimPdfQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractClaimPdfQuery, GetContractClaimPdfQueryVariables>(GetContractClaimPdfDocument, baseOptions);
        }
export type GetContractClaimPdfQueryHookResult = ReturnType<typeof useGetContractClaimPdfQuery>;
export type GetContractClaimPdfLazyQueryHookResult = ReturnType<typeof useGetContractClaimPdfLazyQuery>;
export type GetContractClaimPdfQueryResult = ApolloReactCommon.QueryResult<GetContractClaimPdfQuery, GetContractClaimPdfQueryVariables>;
export const GetConProgressClaimDocument = gql`
    query getConProgressClaim($contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus, $limit: Float, $offSet: Float, $searchValue: String, $orderByDesc: String) {
  getProgressClaim(contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus, limit: $limit, offSet: $offSet, searchValue: $searchValue, orderByDesc: $orderByDesc) {
    ID
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationRemarks
    cancellationDate
    claimDocNo
    contractID
    currencyID
    contractID
    contract {
      ID
      title
    }
    customerID
    customer {
      ID
      name
    }
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    grossAmt
    localBaseAmt
    localDocAmt
    localTaxAmt
    outstandingAmt
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    voDisplayAmt
    transactionDate
    transactionStatus
    contractClaimAllocation {
      ID
      previousCertifiedAmt
      submittedCertifiedAmt
      currentCertifiedAmt
      contractClaimID
      wbsBudgetDetailID
      wbsID
      revenueCategoryID
    }
    contractClaimVOAllocation {
      ID
      contractVOID
      contractClaimID
      currentVOClaimAmt
      submittedVOClaimAmt
      previousVOClaimAmt
    }
    contractClaimVORevenueWbsAllocation {
      ID
      contractVORevenueWbsID
      contractVORevenueWbs {
        ID
        wbsID
        revenueCategoryID
      }
      currentVOClaimAmt
      submittedVOClaimAmt
      previousVOClaimAmt
    }
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    createdBy
    createdTs
    modBy
    modTs
  }
  progressClaimsInfo(contractID: $contractID, customerID: $customerID)
  getConProgressClaimCount: getContractClaimCount(contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus, isRetention: false)
}
    `;

/**
 * __useGetConProgressClaimQuery__
 *
 * To run a query within a React component, call `useGetConProgressClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConProgressClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConProgressClaimQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      limit: // value for 'limit'
 *      offSet: // value for 'offSet'
 *      searchValue: // value for 'searchValue'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetConProgressClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConProgressClaimQuery, GetConProgressClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConProgressClaimQuery, GetConProgressClaimQueryVariables>(GetConProgressClaimDocument, baseOptions);
      }
export function useGetConProgressClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConProgressClaimQuery, GetConProgressClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConProgressClaimQuery, GetConProgressClaimQueryVariables>(GetConProgressClaimDocument, baseOptions);
        }
export type GetConProgressClaimQueryHookResult = ReturnType<typeof useGetConProgressClaimQuery>;
export type GetConProgressClaimLazyQueryHookResult = ReturnType<typeof useGetConProgressClaimLazyQuery>;
export type GetConProgressClaimQueryResult = ApolloReactCommon.QueryResult<GetConProgressClaimQuery, GetConProgressClaimQueryVariables>;
export const GetConRetentionClaimDocument = gql`
    query getConRetentionClaim($companyID: String, $contractID: String, $customerID: String, $transactionStatus: TransactionStatus, $limit: Float, $offSet: Float, $searchValue: String, $orderByDesc: String) {
  getRetentionClaim(companyID: $companyID, contractID: $contractID, customerID: $customerID, transactionStatus: $transactionStatus, limit: $limit, offSet: $offSet, searchValue: $searchValue, orderByDesc: $orderByDesc) {
    ID
    createdTs
    createdBy
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    contractID
    contract {
      ID
      title
    }
    customerID
    customer {
      ID
      name
    }
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    outstandingAmt
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  retentionClaimsInfo(contractID: $contractID, customerID: $customerID)
  getConRetentionClaimCount: getContractClaimCount(contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus, isRetention: true)
}
    `;

/**
 * __useGetConRetentionClaimQuery__
 *
 * To run a query within a React component, call `useGetConRetentionClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConRetentionClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConRetentionClaimQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      transactionStatus: // value for 'transactionStatus'
 *      limit: // value for 'limit'
 *      offSet: // value for 'offSet'
 *      searchValue: // value for 'searchValue'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetConRetentionClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConRetentionClaimQuery, GetConRetentionClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConRetentionClaimQuery, GetConRetentionClaimQueryVariables>(GetConRetentionClaimDocument, baseOptions);
      }
export function useGetConRetentionClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConRetentionClaimQuery, GetConRetentionClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConRetentionClaimQuery, GetConRetentionClaimQueryVariables>(GetConRetentionClaimDocument, baseOptions);
        }
export type GetConRetentionClaimQueryHookResult = ReturnType<typeof useGetConRetentionClaimQuery>;
export type GetConRetentionClaimLazyQueryHookResult = ReturnType<typeof useGetConRetentionClaimLazyQuery>;
export type GetConRetentionClaimQueryResult = ApolloReactCommon.QueryResult<GetConRetentionClaimQuery, GetConRetentionClaimQueryVariables>;
export const CreateContractClaimDocument = gql`
    mutation createContractClaim($input: ContractClaimInput!) {
  createContractClaim(input: $input) {
    ID
    accountID
  }
}
    `;
export type CreateContractClaimMutationFn = ApolloReactCommon.MutationFunction<CreateContractClaimMutation, CreateContractClaimMutationVariables>;

/**
 * __useCreateContractClaimMutation__
 *
 * To run a mutation, you first call `useCreateContractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractClaimMutation, { data, loading, error }] = useCreateContractClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractClaimMutation, CreateContractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractClaimMutation, CreateContractClaimMutationVariables>(CreateContractClaimDocument, baseOptions);
      }
export type CreateContractClaimMutationHookResult = ReturnType<typeof useCreateContractClaimMutation>;
export type CreateContractClaimMutationResult = ApolloReactCommon.MutationResult<CreateContractClaimMutation>;
export type CreateContractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractClaimMutation, CreateContractClaimMutationVariables>;
export const SubmitRetentionClaimDocument = gql`
    mutation submitRetentionClaim($input: ClaimRetentionInput!) {
  submitRetentionClaim(input: $input) {
    ID
    docNo
  }
}
    `;
export type SubmitRetentionClaimMutationFn = ApolloReactCommon.MutationFunction<SubmitRetentionClaimMutation, SubmitRetentionClaimMutationVariables>;

/**
 * __useSubmitRetentionClaimMutation__
 *
 * To run a mutation, you first call `useSubmitRetentionClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRetentionClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRetentionClaimMutation, { data, loading, error }] = useSubmitRetentionClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitRetentionClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitRetentionClaimMutation, SubmitRetentionClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitRetentionClaimMutation, SubmitRetentionClaimMutationVariables>(SubmitRetentionClaimDocument, baseOptions);
      }
export type SubmitRetentionClaimMutationHookResult = ReturnType<typeof useSubmitRetentionClaimMutation>;
export type SubmitRetentionClaimMutationResult = ApolloReactCommon.MutationResult<SubmitRetentionClaimMutation>;
export type SubmitRetentionClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitRetentionClaimMutation, SubmitRetentionClaimMutationVariables>;
export const ApproveRetentionClaimDocument = gql`
    mutation approveRetentionClaim($input: ClaimRetentionInput!) {
  approveRetentionClaim(input: $input) {
    ID
    docNo
  }
}
    `;
export type ApproveRetentionClaimMutationFn = ApolloReactCommon.MutationFunction<ApproveRetentionClaimMutation, ApproveRetentionClaimMutationVariables>;

/**
 * __useApproveRetentionClaimMutation__
 *
 * To run a mutation, you first call `useApproveRetentionClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveRetentionClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveRetentionClaimMutation, { data, loading, error }] = useApproveRetentionClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveRetentionClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveRetentionClaimMutation, ApproveRetentionClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveRetentionClaimMutation, ApproveRetentionClaimMutationVariables>(ApproveRetentionClaimDocument, baseOptions);
      }
export type ApproveRetentionClaimMutationHookResult = ReturnType<typeof useApproveRetentionClaimMutation>;
export type ApproveRetentionClaimMutationResult = ApolloReactCommon.MutationResult<ApproveRetentionClaimMutation>;
export type ApproveRetentionClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveRetentionClaimMutation, ApproveRetentionClaimMutationVariables>;
export const SubmitClaimDocument = gql`
    mutation submitClaim($deductionLedger: [LedgerInput!], $input: ContractClaimInput!, $claimAlloc: [ContractClaimAllocInput!], $voAlloc: [ContractClaimVOAllocInput!], $voRevenueWbsAlloc: [ContractClaimVORevenueWbsAllocInput!]) {
  submitClaim(deductionLedger: $deductionLedger, input: $input, voAlloc: $voAlloc, claimAlloc: $claimAlloc, voRevenueWbsAlloc: $voRevenueWbsAlloc) {
    ID
    claimDocNo
    docNo
  }
}
    `;
export type SubmitClaimMutationFn = ApolloReactCommon.MutationFunction<SubmitClaimMutation, SubmitClaimMutationVariables>;

/**
 * __useSubmitClaimMutation__
 *
 * To run a mutation, you first call `useSubmitClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitClaimMutation, { data, loading, error }] = useSubmitClaimMutation({
 *   variables: {
 *      deductionLedger: // value for 'deductionLedger'
 *      input: // value for 'input'
 *      claimAlloc: // value for 'claimAlloc'
 *      voAlloc: // value for 'voAlloc'
 *      voRevenueWbsAlloc: // value for 'voRevenueWbsAlloc'
 *   },
 * });
 */
export function useSubmitClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitClaimMutation, SubmitClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitClaimMutation, SubmitClaimMutationVariables>(SubmitClaimDocument, baseOptions);
      }
export type SubmitClaimMutationHookResult = ReturnType<typeof useSubmitClaimMutation>;
export type SubmitClaimMutationResult = ApolloReactCommon.MutationResult<SubmitClaimMutation>;
export type SubmitClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitClaimMutation, SubmitClaimMutationVariables>;
export const ApproveClaimDocument = gql`
    mutation approveClaim($input: ContractClaimInput!, $claimAlloc: [ContractClaimAllocInput!], $voAlloc: [ContractClaimVOAllocInput!], $voRevenueWbsAlloc: [ContractClaimVORevenueWbsAllocInput!]) {
  approveClaim(input: $input, voAlloc: $voAlloc, claimAlloc: $claimAlloc, voRevenueWbsAlloc: $voRevenueWbsAlloc)
}
    `;
export type ApproveClaimMutationFn = ApolloReactCommon.MutationFunction<ApproveClaimMutation, ApproveClaimMutationVariables>;

/**
 * __useApproveClaimMutation__
 *
 * To run a mutation, you first call `useApproveClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveClaimMutation, { data, loading, error }] = useApproveClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *      claimAlloc: // value for 'claimAlloc'
 *      voAlloc: // value for 'voAlloc'
 *      voRevenueWbsAlloc: // value for 'voRevenueWbsAlloc'
 *   },
 * });
 */
export function useApproveClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveClaimMutation, ApproveClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveClaimMutation, ApproveClaimMutationVariables>(ApproveClaimDocument, baseOptions);
      }
export type ApproveClaimMutationHookResult = ReturnType<typeof useApproveClaimMutation>;
export type ApproveClaimMutationResult = ApolloReactCommon.MutationResult<ApproveClaimMutation>;
export type ApproveClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveClaimMutation, ApproveClaimMutationVariables>;
export const UpdateContractClaimDocument = gql`
    mutation updateContractClaim($input: ContractClaimInput!) {
  updateContractClaim(input: $input) {
    ID
    accountID
    claimDocNo
    docNo
  }
}
    `;
export type UpdateContractClaimMutationFn = ApolloReactCommon.MutationFunction<UpdateContractClaimMutation, UpdateContractClaimMutationVariables>;

/**
 * __useUpdateContractClaimMutation__
 *
 * To run a mutation, you first call `useUpdateContractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractClaimMutation, { data, loading, error }] = useUpdateContractClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractClaimMutation, UpdateContractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractClaimMutation, UpdateContractClaimMutationVariables>(UpdateContractClaimDocument, baseOptions);
      }
export type UpdateContractClaimMutationHookResult = ReturnType<typeof useUpdateContractClaimMutation>;
export type UpdateContractClaimMutationResult = ApolloReactCommon.MutationResult<UpdateContractClaimMutation>;
export type UpdateContractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractClaimMutation, UpdateContractClaimMutationVariables>;
export const CancelClaimDocument = gql`
    mutation cancelClaim($ID: String!, $input: CancelInput!, $retentionClaim: Boolean) {
  cancelClaim(ID: $ID, input: $input, retentionClaim: $retentionClaim)
}
    `;
export type CancelClaimMutationFn = ApolloReactCommon.MutationFunction<CancelClaimMutation, CancelClaimMutationVariables>;

/**
 * __useCancelClaimMutation__
 *
 * To run a mutation, you first call `useCancelClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClaimMutation, { data, loading, error }] = useCancelClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *      retentionClaim: // value for 'retentionClaim'
 *   },
 * });
 */
export function useCancelClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelClaimMutation, CancelClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelClaimMutation, CancelClaimMutationVariables>(CancelClaimDocument, baseOptions);
      }
export type CancelClaimMutationHookResult = ReturnType<typeof useCancelClaimMutation>;
export type CancelClaimMutationResult = ApolloReactCommon.MutationResult<CancelClaimMutation>;
export type CancelClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelClaimMutation, CancelClaimMutationVariables>;
export const RejectClaimDocument = gql`
    mutation rejectClaim($ID: String!, $input: RejectInput!, $retentionClaim: Boolean) {
  rejectClaim(ID: $ID, input: $input, retentionClaim: $retentionClaim)
}
    `;
export type RejectClaimMutationFn = ApolloReactCommon.MutationFunction<RejectClaimMutation, RejectClaimMutationVariables>;

/**
 * __useRejectClaimMutation__
 *
 * To run a mutation, you first call `useRejectClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectClaimMutation, { data, loading, error }] = useRejectClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *      retentionClaim: // value for 'retentionClaim'
 *   },
 * });
 */
export function useRejectClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectClaimMutation, RejectClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectClaimMutation, RejectClaimMutationVariables>(RejectClaimDocument, baseOptions);
      }
export type RejectClaimMutationHookResult = ReturnType<typeof useRejectClaimMutation>;
export type RejectClaimMutationResult = ApolloReactCommon.MutationResult<RejectClaimMutation>;
export type RejectClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectClaimMutation, RejectClaimMutationVariables>;
export const DeleteContractClaimDocument = gql`
    mutation deleteContractClaim($ID: String!) {
  deleteContractClaim(ID: $ID)
}
    `;
export type DeleteContractClaimMutationFn = ApolloReactCommon.MutationFunction<DeleteContractClaimMutation, DeleteContractClaimMutationVariables>;

/**
 * __useDeleteContractClaimMutation__
 *
 * To run a mutation, you first call `useDeleteContractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractClaimMutation, { data, loading, error }] = useDeleteContractClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractClaimMutation, DeleteContractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractClaimMutation, DeleteContractClaimMutationVariables>(DeleteContractClaimDocument, baseOptions);
      }
export type DeleteContractClaimMutationHookResult = ReturnType<typeof useDeleteContractClaimMutation>;
export type DeleteContractClaimMutationResult = ApolloReactCommon.MutationResult<DeleteContractClaimMutation>;
export type DeleteContractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractClaimMutation, DeleteContractClaimMutationVariables>;
export const GetContractAdvanceDocument = gql`
    query getContractAdvance($ID: String, $contractID: String, $customerID: String, $companyID: String, $orderByAsc: String, $orderByDesc: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getContractAdvance(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    modBy
    modTs
    createdTs
    createdBy
    isGlExport
    isRecoupment
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  advancesInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractAdvanceQuery__
 *
 * To run a query within a React component, call `useGetContractAdvanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAdvanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAdvanceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetContractAdvanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractAdvanceQuery, GetContractAdvanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractAdvanceQuery, GetContractAdvanceQueryVariables>(GetContractAdvanceDocument, baseOptions);
      }
export function useGetContractAdvanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractAdvanceQuery, GetContractAdvanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractAdvanceQuery, GetContractAdvanceQueryVariables>(GetContractAdvanceDocument, baseOptions);
        }
export type GetContractAdvanceQueryHookResult = ReturnType<typeof useGetContractAdvanceQuery>;
export type GetContractAdvanceLazyQueryHookResult = ReturnType<typeof useGetContractAdvanceLazyQuery>;
export type GetContractAdvanceQueryResult = ApolloReactCommon.QueryResult<GetContractAdvanceQuery, GetContractAdvanceQueryVariables>;
export const GetContractAdvanceListingDocument = gql`
    query getContractAdvanceListing($ID: String, $contractID: String, $customerID: String, $companyID: String, $orderByAsc: String, $orderByDesc: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getContractAdvance(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    modBy
    modTs
    createdTs
    createdBy
    isGlExport
    isRecoupment
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  advancesInfo(contractID: $contractID, customerID: $customerID)
  getContractAdvanceCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetContractAdvanceListingQuery__
 *
 * To run a query within a React component, call `useGetContractAdvanceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAdvanceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAdvanceListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetContractAdvanceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractAdvanceListingQuery, GetContractAdvanceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractAdvanceListingQuery, GetContractAdvanceListingQueryVariables>(GetContractAdvanceListingDocument, baseOptions);
      }
export function useGetContractAdvanceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractAdvanceListingQuery, GetContractAdvanceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractAdvanceListingQuery, GetContractAdvanceListingQueryVariables>(GetContractAdvanceListingDocument, baseOptions);
        }
export type GetContractAdvanceListingQueryHookResult = ReturnType<typeof useGetContractAdvanceListingQuery>;
export type GetContractAdvanceListingLazyQueryHookResult = ReturnType<typeof useGetContractAdvanceListingLazyQuery>;
export type GetContractAdvanceListingQueryResult = ApolloReactCommon.QueryResult<GetContractAdvanceListingQuery, GetContractAdvanceListingQueryVariables>;
export const CreateContractAdvanceDocument = gql`
    mutation createContractAdvance($input: ContractReceiptInput!) {
  createContractAdvance(input: $input) {
    ID
    docNo
  }
}
    `;
export type CreateContractAdvanceMutationFn = ApolloReactCommon.MutationFunction<CreateContractAdvanceMutation, CreateContractAdvanceMutationVariables>;

/**
 * __useCreateContractAdvanceMutation__
 *
 * To run a mutation, you first call `useCreateContractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractAdvanceMutation, { data, loading, error }] = useCreateContractAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractAdvanceMutation, CreateContractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractAdvanceMutation, CreateContractAdvanceMutationVariables>(CreateContractAdvanceDocument, baseOptions);
      }
export type CreateContractAdvanceMutationHookResult = ReturnType<typeof useCreateContractAdvanceMutation>;
export type CreateContractAdvanceMutationResult = ApolloReactCommon.MutationResult<CreateContractAdvanceMutation>;
export type CreateContractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractAdvanceMutation, CreateContractAdvanceMutationVariables>;
export const UpdateContractAdvanceDocument = gql`
    mutation updateContractAdvance($input: ContractReceiptInput!) {
  updateContractAdvance(input: $input) {
    ID
    docNo
  }
}
    `;
export type UpdateContractAdvanceMutationFn = ApolloReactCommon.MutationFunction<UpdateContractAdvanceMutation, UpdateContractAdvanceMutationVariables>;

/**
 * __useUpdateContractAdvanceMutation__
 *
 * To run a mutation, you first call `useUpdateContractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractAdvanceMutation, { data, loading, error }] = useUpdateContractAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractAdvanceMutation, UpdateContractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractAdvanceMutation, UpdateContractAdvanceMutationVariables>(UpdateContractAdvanceDocument, baseOptions);
      }
export type UpdateContractAdvanceMutationHookResult = ReturnType<typeof useUpdateContractAdvanceMutation>;
export type UpdateContractAdvanceMutationResult = ApolloReactCommon.MutationResult<UpdateContractAdvanceMutation>;
export type UpdateContractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractAdvanceMutation, UpdateContractAdvanceMutationVariables>;
export const GetContractReceiptDocument = gql`
    query getContractReceipt($ID: String, $contractID: String, $customerID: String, $companyID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float) {
  getContractReceipt(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, receiptStatus: $receiptStatus, take: $take, skip: $skip) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  receiptsInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractReceiptQuery__
 *
 * To run a query within a React component, call `useGetContractReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractReceiptQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetContractReceiptQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractReceiptQuery, GetContractReceiptQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractReceiptQuery, GetContractReceiptQueryVariables>(GetContractReceiptDocument, baseOptions);
      }
export function useGetContractReceiptLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractReceiptQuery, GetContractReceiptQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractReceiptQuery, GetContractReceiptQueryVariables>(GetContractReceiptDocument, baseOptions);
        }
export type GetContractReceiptQueryHookResult = ReturnType<typeof useGetContractReceiptQuery>;
export type GetContractReceiptLazyQueryHookResult = ReturnType<typeof useGetContractReceiptLazyQuery>;
export type GetContractReceiptQueryResult = ApolloReactCommon.QueryResult<GetContractReceiptQuery, GetContractReceiptQueryVariables>;
export const GetContractReceiptListingDocument = gql`
    query getContractReceiptListing($ID: String, $contractID: String, $customerID: String, $companyID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float) {
  getContractReceipt(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, receiptStatus: $receiptStatus, take: $take, skip: $skip) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  receiptsInfo(contractID: $contractID, customerID: $customerID)
  getContractReceiptCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetContractReceiptListingQuery__
 *
 * To run a query within a React component, call `useGetContractReceiptListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractReceiptListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractReceiptListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetContractReceiptListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractReceiptListingQuery, GetContractReceiptListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractReceiptListingQuery, GetContractReceiptListingQueryVariables>(GetContractReceiptListingDocument, baseOptions);
      }
export function useGetContractReceiptListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractReceiptListingQuery, GetContractReceiptListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractReceiptListingQuery, GetContractReceiptListingQueryVariables>(GetContractReceiptListingDocument, baseOptions);
        }
export type GetContractReceiptListingQueryHookResult = ReturnType<typeof useGetContractReceiptListingQuery>;
export type GetContractReceiptListingLazyQueryHookResult = ReturnType<typeof useGetContractReceiptListingLazyQuery>;
export type GetContractReceiptListingQueryResult = ApolloReactCommon.QueryResult<GetContractReceiptListingQuery, GetContractReceiptListingQueryVariables>;
export const CreateContractReceiptDocument = gql`
    mutation createContractReceipt($input: ContractReceiptInput!) {
  createContractReceipt(input: $input) {
    ID
    docNo
  }
}
    `;
export type CreateContractReceiptMutationFn = ApolloReactCommon.MutationFunction<CreateContractReceiptMutation, CreateContractReceiptMutationVariables>;

/**
 * __useCreateContractReceiptMutation__
 *
 * To run a mutation, you first call `useCreateContractReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractReceiptMutation, { data, loading, error }] = useCreateContractReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractReceiptMutation, CreateContractReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractReceiptMutation, CreateContractReceiptMutationVariables>(CreateContractReceiptDocument, baseOptions);
      }
export type CreateContractReceiptMutationHookResult = ReturnType<typeof useCreateContractReceiptMutation>;
export type CreateContractReceiptMutationResult = ApolloReactCommon.MutationResult<CreateContractReceiptMutation>;
export type CreateContractReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractReceiptMutation, CreateContractReceiptMutationVariables>;
export const UpdateContractReceiptDocument = gql`
    mutation updateContractReceipt($input: ContractReceiptInput!) {
  updateContractReceipt(input: $input) {
    ID
    docNo
  }
}
    `;
export type UpdateContractReceiptMutationFn = ApolloReactCommon.MutationFunction<UpdateContractReceiptMutation, UpdateContractReceiptMutationVariables>;

/**
 * __useUpdateContractReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateContractReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractReceiptMutation, { data, loading, error }] = useUpdateContractReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractReceiptMutation, UpdateContractReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractReceiptMutation, UpdateContractReceiptMutationVariables>(UpdateContractReceiptDocument, baseOptions);
      }
export type UpdateContractReceiptMutationHookResult = ReturnType<typeof useUpdateContractReceiptMutation>;
export type UpdateContractReceiptMutationResult = ApolloReactCommon.MutationResult<UpdateContractReceiptMutation>;
export type UpdateContractReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractReceiptMutation, UpdateContractReceiptMutationVariables>;
export const GetContractRefundDocument = gql`
    query getContractRefund($ID: String, $contractID: String, $customerID: String, $companyID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float) {
  getContractRefund(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, receiptStatus: $receiptStatus, take: $take, skip: $skip) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  refundsInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractRefundQuery__
 *
 * To run a query within a React component, call `useGetContractRefundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractRefundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractRefundQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetContractRefundQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractRefundQuery, GetContractRefundQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractRefundQuery, GetContractRefundQueryVariables>(GetContractRefundDocument, baseOptions);
      }
export function useGetContractRefundLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractRefundQuery, GetContractRefundQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractRefundQuery, GetContractRefundQueryVariables>(GetContractRefundDocument, baseOptions);
        }
export type GetContractRefundQueryHookResult = ReturnType<typeof useGetContractRefundQuery>;
export type GetContractRefundLazyQueryHookResult = ReturnType<typeof useGetContractRefundLazyQuery>;
export type GetContractRefundQueryResult = ApolloReactCommon.QueryResult<GetContractRefundQuery, GetContractRefundQueryVariables>;
export const GetContractRefundListingDocument = gql`
    query getContractRefundListing($ID: String, $contractID: String, $customerID: String, $companyID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float) {
  getContractRefund(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, receiptStatus: $receiptStatus, take: $take, skip: $skip) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  refundsInfo(contractID: $contractID, customerID: $customerID)
  getContractRefundCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetContractRefundListingQuery__
 *
 * To run a query within a React component, call `useGetContractRefundListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractRefundListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractRefundListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetContractRefundListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractRefundListingQuery, GetContractRefundListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractRefundListingQuery, GetContractRefundListingQueryVariables>(GetContractRefundListingDocument, baseOptions);
      }
export function useGetContractRefundListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractRefundListingQuery, GetContractRefundListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractRefundListingQuery, GetContractRefundListingQueryVariables>(GetContractRefundListingDocument, baseOptions);
        }
export type GetContractRefundListingQueryHookResult = ReturnType<typeof useGetContractRefundListingQuery>;
export type GetContractRefundListingLazyQueryHookResult = ReturnType<typeof useGetContractRefundListingLazyQuery>;
export type GetContractRefundListingQueryResult = ApolloReactCommon.QueryResult<GetContractRefundListingQuery, GetContractRefundListingQueryVariables>;
export const CreateContractRefundDocument = gql`
    mutation createContractRefund($input: ContractReceiptInput!) {
  createContractRefund(input: $input) {
    ID
  }
}
    `;
export type CreateContractRefundMutationFn = ApolloReactCommon.MutationFunction<CreateContractRefundMutation, CreateContractRefundMutationVariables>;

/**
 * __useCreateContractRefundMutation__
 *
 * To run a mutation, you first call `useCreateContractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractRefundMutation, { data, loading, error }] = useCreateContractRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractRefundMutation, CreateContractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractRefundMutation, CreateContractRefundMutationVariables>(CreateContractRefundDocument, baseOptions);
      }
export type CreateContractRefundMutationHookResult = ReturnType<typeof useCreateContractRefundMutation>;
export type CreateContractRefundMutationResult = ApolloReactCommon.MutationResult<CreateContractRefundMutation>;
export type CreateContractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractRefundMutation, CreateContractRefundMutationVariables>;
export const UpdateContractRefundDocument = gql`
    mutation updateContractRefund($input: ContractReceiptInput!) {
  updateContractRefund(input: $input) {
    ID
  }
}
    `;
export type UpdateContractRefundMutationFn = ApolloReactCommon.MutationFunction<UpdateContractRefundMutation, UpdateContractRefundMutationVariables>;

/**
 * __useUpdateContractRefundMutation__
 *
 * To run a mutation, you first call `useUpdateContractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractRefundMutation, { data, loading, error }] = useUpdateContractRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractRefundMutation, UpdateContractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractRefundMutation, UpdateContractRefundMutationVariables>(UpdateContractRefundDocument, baseOptions);
      }
export type UpdateContractRefundMutationHookResult = ReturnType<typeof useUpdateContractRefundMutation>;
export type UpdateContractRefundMutationResult = ApolloReactCommon.MutationResult<UpdateContractRefundMutation>;
export type UpdateContractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractRefundMutation, UpdateContractRefundMutationVariables>;
export const MakeContractRefundDocument = gql`
    mutation makeContractRefund($input: ContractReceiptInput!, $ledger: [LedgerInput!]!) {
  makeContractRefund(input: $input, ledger: $ledger) {
    ID
    docNo
  }
}
    `;
export type MakeContractRefundMutationFn = ApolloReactCommon.MutationFunction<MakeContractRefundMutation, MakeContractRefundMutationVariables>;

/**
 * __useMakeContractRefundMutation__
 *
 * To run a mutation, you first call `useMakeContractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeContractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeContractRefundMutation, { data, loading, error }] = useMakeContractRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeContractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeContractRefundMutation, MakeContractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeContractRefundMutation, MakeContractRefundMutationVariables>(MakeContractRefundDocument, baseOptions);
      }
export type MakeContractRefundMutationHookResult = ReturnType<typeof useMakeContractRefundMutation>;
export type MakeContractRefundMutationResult = ApolloReactCommon.MutationResult<MakeContractRefundMutation>;
export type MakeContractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeContractRefundMutation, MakeContractRefundMutationVariables>;
export const CancelContractAdvanceDocument = gql`
    mutation cancelContractAdvance($ID: String!, $input: CancelInput!) {
  cancelContractAdvance(ID: $ID, input: $input)
}
    `;
export type CancelContractAdvanceMutationFn = ApolloReactCommon.MutationFunction<CancelContractAdvanceMutation, CancelContractAdvanceMutationVariables>;

/**
 * __useCancelContractAdvanceMutation__
 *
 * To run a mutation, you first call `useCancelContractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractAdvanceMutation, { data, loading, error }] = useCancelContractAdvanceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractAdvanceMutation, CancelContractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractAdvanceMutation, CancelContractAdvanceMutationVariables>(CancelContractAdvanceDocument, baseOptions);
      }
export type CancelContractAdvanceMutationHookResult = ReturnType<typeof useCancelContractAdvanceMutation>;
export type CancelContractAdvanceMutationResult = ApolloReactCommon.MutationResult<CancelContractAdvanceMutation>;
export type CancelContractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractAdvanceMutation, CancelContractAdvanceMutationVariables>;
export const CancelContractReceiptDocument = gql`
    mutation cancelContractReceipt($ID: String!, $input: CancelInput!) {
  cancelContractReceipt(ID: $ID, input: $input)
}
    `;
export type CancelContractReceiptMutationFn = ApolloReactCommon.MutationFunction<CancelContractReceiptMutation, CancelContractReceiptMutationVariables>;

/**
 * __useCancelContractReceiptMutation__
 *
 * To run a mutation, you first call `useCancelContractReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractReceiptMutation, { data, loading, error }] = useCancelContractReceiptMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractReceiptMutation, CancelContractReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractReceiptMutation, CancelContractReceiptMutationVariables>(CancelContractReceiptDocument, baseOptions);
      }
export type CancelContractReceiptMutationHookResult = ReturnType<typeof useCancelContractReceiptMutation>;
export type CancelContractReceiptMutationResult = ApolloReactCommon.MutationResult<CancelContractReceiptMutation>;
export type CancelContractReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractReceiptMutation, CancelContractReceiptMutationVariables>;
export const CancelContractRefundDocument = gql`
    mutation cancelContractRefund($ID: String!, $input: CancelInput!) {
  cancelContractRefund(ID: $ID, input: $input)
}
    `;
export type CancelContractRefundMutationFn = ApolloReactCommon.MutationFunction<CancelContractRefundMutation, CancelContractRefundMutationVariables>;

/**
 * __useCancelContractRefundMutation__
 *
 * To run a mutation, you first call `useCancelContractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractRefundMutation, { data, loading, error }] = useCancelContractRefundMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractRefundMutation, CancelContractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractRefundMutation, CancelContractRefundMutationVariables>(CancelContractRefundDocument, baseOptions);
      }
export type CancelContractRefundMutationHookResult = ReturnType<typeof useCancelContractRefundMutation>;
export type CancelContractRefundMutationResult = ApolloReactCommon.MutationResult<CancelContractRefundMutation>;
export type CancelContractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractRefundMutation, CancelContractRefundMutationVariables>;
export const DeleteContractAdvanceDocument = gql`
    mutation deleteContractAdvance($ID: String!) {
  deleteContractAdvance(ID: $ID)
}
    `;
export type DeleteContractAdvanceMutationFn = ApolloReactCommon.MutationFunction<DeleteContractAdvanceMutation, DeleteContractAdvanceMutationVariables>;

/**
 * __useDeleteContractAdvanceMutation__
 *
 * To run a mutation, you first call `useDeleteContractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractAdvanceMutation, { data, loading, error }] = useDeleteContractAdvanceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractAdvanceMutation, DeleteContractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractAdvanceMutation, DeleteContractAdvanceMutationVariables>(DeleteContractAdvanceDocument, baseOptions);
      }
export type DeleteContractAdvanceMutationHookResult = ReturnType<typeof useDeleteContractAdvanceMutation>;
export type DeleteContractAdvanceMutationResult = ApolloReactCommon.MutationResult<DeleteContractAdvanceMutation>;
export type DeleteContractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractAdvanceMutation, DeleteContractAdvanceMutationVariables>;
export const DeleteContractReceiptDocument = gql`
    mutation deleteContractReceipt($ID: String!) {
  deleteContractReceipt(ID: $ID)
}
    `;
export type DeleteContractReceiptMutationFn = ApolloReactCommon.MutationFunction<DeleteContractReceiptMutation, DeleteContractReceiptMutationVariables>;

/**
 * __useDeleteContractReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteContractReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractReceiptMutation, { data, loading, error }] = useDeleteContractReceiptMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractReceiptMutation, DeleteContractReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractReceiptMutation, DeleteContractReceiptMutationVariables>(DeleteContractReceiptDocument, baseOptions);
      }
export type DeleteContractReceiptMutationHookResult = ReturnType<typeof useDeleteContractReceiptMutation>;
export type DeleteContractReceiptMutationResult = ApolloReactCommon.MutationResult<DeleteContractReceiptMutation>;
export type DeleteContractReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractReceiptMutation, DeleteContractReceiptMutationVariables>;
export const DeleteContractRefundDocument = gql`
    mutation deleteContractRefund($ID: String!) {
  deleteContractRefund(ID: $ID)
}
    `;
export type DeleteContractRefundMutationFn = ApolloReactCommon.MutationFunction<DeleteContractRefundMutation, DeleteContractRefundMutationVariables>;

/**
 * __useDeleteContractRefundMutation__
 *
 * To run a mutation, you first call `useDeleteContractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractRefundMutation, { data, loading, error }] = useDeleteContractRefundMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractRefundMutation, DeleteContractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractRefundMutation, DeleteContractRefundMutationVariables>(DeleteContractRefundDocument, baseOptions);
      }
export type DeleteContractRefundMutationHookResult = ReturnType<typeof useDeleteContractRefundMutation>;
export type DeleteContractRefundMutationResult = ApolloReactCommon.MutationResult<DeleteContractRefundMutation>;
export type DeleteContractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractRefundMutation, DeleteContractRefundMutationVariables>;
export const GetContractAdvanceCountDocument = gql`
    query getContractAdvanceCount($contractID: String, $customerID: String, $companyID: String, $receiptStatus: ReceiptStatus) {
  getContractAdvanceCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetContractAdvanceCountQuery__
 *
 * To run a query within a React component, call `useGetContractAdvanceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAdvanceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAdvanceCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetContractAdvanceCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractAdvanceCountQuery, GetContractAdvanceCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractAdvanceCountQuery, GetContractAdvanceCountQueryVariables>(GetContractAdvanceCountDocument, baseOptions);
      }
export function useGetContractAdvanceCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractAdvanceCountQuery, GetContractAdvanceCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractAdvanceCountQuery, GetContractAdvanceCountQueryVariables>(GetContractAdvanceCountDocument, baseOptions);
        }
export type GetContractAdvanceCountQueryHookResult = ReturnType<typeof useGetContractAdvanceCountQuery>;
export type GetContractAdvanceCountLazyQueryHookResult = ReturnType<typeof useGetContractAdvanceCountLazyQuery>;
export type GetContractAdvanceCountQueryResult = ApolloReactCommon.QueryResult<GetContractAdvanceCountQuery, GetContractAdvanceCountQueryVariables>;
export const GetContractReceiptCountDocument = gql`
    query getContractReceiptCount($contractID: String, $customerID: String, $companyID: String, $receiptStatus: ReceiptStatus) {
  getContractReceiptCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetContractReceiptCountQuery__
 *
 * To run a query within a React component, call `useGetContractReceiptCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractReceiptCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractReceiptCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetContractReceiptCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractReceiptCountQuery, GetContractReceiptCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractReceiptCountQuery, GetContractReceiptCountQueryVariables>(GetContractReceiptCountDocument, baseOptions);
      }
export function useGetContractReceiptCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractReceiptCountQuery, GetContractReceiptCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractReceiptCountQuery, GetContractReceiptCountQueryVariables>(GetContractReceiptCountDocument, baseOptions);
        }
export type GetContractReceiptCountQueryHookResult = ReturnType<typeof useGetContractReceiptCountQuery>;
export type GetContractReceiptCountLazyQueryHookResult = ReturnType<typeof useGetContractReceiptCountLazyQuery>;
export type GetContractReceiptCountQueryResult = ApolloReactCommon.QueryResult<GetContractReceiptCountQuery, GetContractReceiptCountQueryVariables>;
export const GetContractRefundCountDocument = gql`
    query getContractRefundCount($contractID: String, $customerID: String, $companyID: String, $receiptStatus: ReceiptStatus) {
  getContractRefundCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetContractRefundCountQuery__
 *
 * To run a query within a React component, call `useGetContractRefundCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractRefundCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractRefundCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetContractRefundCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractRefundCountQuery, GetContractRefundCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractRefundCountQuery, GetContractRefundCountQueryVariables>(GetContractRefundCountDocument, baseOptions);
      }
export function useGetContractRefundCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractRefundCountQuery, GetContractRefundCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractRefundCountQuery, GetContractRefundCountQueryVariables>(GetContractRefundCountDocument, baseOptions);
        }
export type GetContractRefundCountQueryHookResult = ReturnType<typeof useGetContractRefundCountQuery>;
export type GetContractRefundCountLazyQueryHookResult = ReturnType<typeof useGetContractRefundCountLazyQuery>;
export type GetContractRefundCountQueryResult = ApolloReactCommon.QueryResult<GetContractRefundCountQuery, GetContractRefundCountQueryVariables>;
export const GetContractDnDocument = gql`
    query getContractDN($ID: String, $contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getContractDN(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    isGlExport
    modBy
    modTs
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    submissionComment
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    contractDNItem {
      ID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      taxRate
      taxAmt
      remarks
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  DNInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractDnQuery__
 *
 * To run a query within a React component, call `useGetContractDnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractDnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractDnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetContractDnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractDnQuery, GetContractDnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractDnQuery, GetContractDnQueryVariables>(GetContractDnDocument, baseOptions);
      }
export function useGetContractDnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractDnQuery, GetContractDnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractDnQuery, GetContractDnQueryVariables>(GetContractDnDocument, baseOptions);
        }
export type GetContractDnQueryHookResult = ReturnType<typeof useGetContractDnQuery>;
export type GetContractDnLazyQueryHookResult = ReturnType<typeof useGetContractDnLazyQuery>;
export type GetContractDnQueryResult = ApolloReactCommon.QueryResult<GetContractDnQuery, GetContractDnQueryVariables>;
export const GetContractDnListingDocument = gql`
    query getContractDNListing($ID: String, $contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getContractDN(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    isGlExport
    modBy
    modTs
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    submissionComment
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    contractDNItem {
      ID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      taxRate
      taxAmt
      remarks
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  getContractDNCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus)
  DNInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractDnListingQuery__
 *
 * To run a query within a React component, call `useGetContractDnListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractDnListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractDnListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetContractDnListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractDnListingQuery, GetContractDnListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractDnListingQuery, GetContractDnListingQueryVariables>(GetContractDnListingDocument, baseOptions);
      }
export function useGetContractDnListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractDnListingQuery, GetContractDnListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractDnListingQuery, GetContractDnListingQueryVariables>(GetContractDnListingDocument, baseOptions);
        }
export type GetContractDnListingQueryHookResult = ReturnType<typeof useGetContractDnListingQuery>;
export type GetContractDnListingLazyQueryHookResult = ReturnType<typeof useGetContractDnListingLazyQuery>;
export type GetContractDnListingQueryResult = ApolloReactCommon.QueryResult<GetContractDnListingQuery, GetContractDnListingQueryVariables>;
export const CreateContractDnDocument = gql`
    mutation createContractDN($input: ContractTransactionWithItemInput!) {
  createContractDN(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type CreateContractDnMutationFn = ApolloReactCommon.MutationFunction<CreateContractDnMutation, CreateContractDnMutationVariables>;

/**
 * __useCreateContractDnMutation__
 *
 * To run a mutation, you first call `useCreateContractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractDnMutation, { data, loading, error }] = useCreateContractDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractDnMutation, CreateContractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractDnMutation, CreateContractDnMutationVariables>(CreateContractDnDocument, baseOptions);
      }
export type CreateContractDnMutationHookResult = ReturnType<typeof useCreateContractDnMutation>;
export type CreateContractDnMutationResult = ApolloReactCommon.MutationResult<CreateContractDnMutation>;
export type CreateContractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractDnMutation, CreateContractDnMutationVariables>;
export const UpdateContractDnDocument = gql`
    mutation updateContractDN($input: ContractTransactionWithItemInput!) {
  updateContractDN(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type UpdateContractDnMutationFn = ApolloReactCommon.MutationFunction<UpdateContractDnMutation, UpdateContractDnMutationVariables>;

/**
 * __useUpdateContractDnMutation__
 *
 * To run a mutation, you first call `useUpdateContractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractDnMutation, { data, loading, error }] = useUpdateContractDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractDnMutation, UpdateContractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractDnMutation, UpdateContractDnMutationVariables>(UpdateContractDnDocument, baseOptions);
      }
export type UpdateContractDnMutationHookResult = ReturnType<typeof useUpdateContractDnMutation>;
export type UpdateContractDnMutationResult = ApolloReactCommon.MutationResult<UpdateContractDnMutation>;
export type UpdateContractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractDnMutation, UpdateContractDnMutationVariables>;
export const GetContractMiscInvoiceDocument = gql`
    query getContractMiscInvoice($ID: String, $contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getContractMiscInvoice(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modBy
    modTs
    isGlExport
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    isDaywork
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    contractMiscInvoiceItem {
      ID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      taxRate
      taxAmt
      remarks
    }
  }
  miscInvInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractMiscInvoiceQuery__
 *
 * To run a query within a React component, call `useGetContractMiscInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractMiscInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractMiscInvoiceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetContractMiscInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractMiscInvoiceQuery, GetContractMiscInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractMiscInvoiceQuery, GetContractMiscInvoiceQueryVariables>(GetContractMiscInvoiceDocument, baseOptions);
      }
export function useGetContractMiscInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractMiscInvoiceQuery, GetContractMiscInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractMiscInvoiceQuery, GetContractMiscInvoiceQueryVariables>(GetContractMiscInvoiceDocument, baseOptions);
        }
export type GetContractMiscInvoiceQueryHookResult = ReturnType<typeof useGetContractMiscInvoiceQuery>;
export type GetContractMiscInvoiceLazyQueryHookResult = ReturnType<typeof useGetContractMiscInvoiceLazyQuery>;
export type GetContractMiscInvoiceQueryResult = ApolloReactCommon.QueryResult<GetContractMiscInvoiceQuery, GetContractMiscInvoiceQueryVariables>;
export const GetContractMiscInvoiceListingDocument = gql`
    query getContractMiscInvoiceListing($ID: String, $contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getContractMiscInvoice(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modBy
    modTs
    isGlExport
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    isDaywork
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    contractMiscInvoiceItem {
      ID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      taxRate
      taxAmt
      remarks
    }
  }
  miscInvInfo(contractID: $contractID, customerID: $customerID)
  getContractMiscInvoiceCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetContractMiscInvoiceListingQuery__
 *
 * To run a query within a React component, call `useGetContractMiscInvoiceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractMiscInvoiceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractMiscInvoiceListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetContractMiscInvoiceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractMiscInvoiceListingQuery, GetContractMiscInvoiceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractMiscInvoiceListingQuery, GetContractMiscInvoiceListingQueryVariables>(GetContractMiscInvoiceListingDocument, baseOptions);
      }
export function useGetContractMiscInvoiceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractMiscInvoiceListingQuery, GetContractMiscInvoiceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractMiscInvoiceListingQuery, GetContractMiscInvoiceListingQueryVariables>(GetContractMiscInvoiceListingDocument, baseOptions);
        }
export type GetContractMiscInvoiceListingQueryHookResult = ReturnType<typeof useGetContractMiscInvoiceListingQuery>;
export type GetContractMiscInvoiceListingLazyQueryHookResult = ReturnType<typeof useGetContractMiscInvoiceListingLazyQuery>;
export type GetContractMiscInvoiceListingQueryResult = ApolloReactCommon.QueryResult<GetContractMiscInvoiceListingQuery, GetContractMiscInvoiceListingQueryVariables>;
export const CreateContractMiscInvoiceDocument = gql`
    mutation createContractMiscInvoice($input: ContractTransactionWithItemInput!) {
  createContractMiscInvoice(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type CreateContractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateContractMiscInvoiceMutation, CreateContractMiscInvoiceMutationVariables>;

/**
 * __useCreateContractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateContractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMiscInvoiceMutation, { data, loading, error }] = useCreateContractMiscInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractMiscInvoiceMutation, CreateContractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractMiscInvoiceMutation, CreateContractMiscInvoiceMutationVariables>(CreateContractMiscInvoiceDocument, baseOptions);
      }
export type CreateContractMiscInvoiceMutationHookResult = ReturnType<typeof useCreateContractMiscInvoiceMutation>;
export type CreateContractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateContractMiscInvoiceMutation>;
export type CreateContractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractMiscInvoiceMutation, CreateContractMiscInvoiceMutationVariables>;
export const UpdateContractMiscInvoiceDocument = gql`
    mutation updateContractMiscInvoice($input: ContractTransactionWithItemInput!) {
  updateContractMiscInvoice(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type UpdateContractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<UpdateContractMiscInvoiceMutation, UpdateContractMiscInvoiceMutationVariables>;

/**
 * __useUpdateContractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateContractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMiscInvoiceMutation, { data, loading, error }] = useUpdateContractMiscInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractMiscInvoiceMutation, UpdateContractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractMiscInvoiceMutation, UpdateContractMiscInvoiceMutationVariables>(UpdateContractMiscInvoiceDocument, baseOptions);
      }
export type UpdateContractMiscInvoiceMutationHookResult = ReturnType<typeof useUpdateContractMiscInvoiceMutation>;
export type UpdateContractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<UpdateContractMiscInvoiceMutation>;
export type UpdateContractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractMiscInvoiceMutation, UpdateContractMiscInvoiceMutationVariables>;
export const GetContractCnDocument = gql`
    query getContractCN($ID: String, $contractID: String, $customerID: String, $companyID: String, $orderByAsc: String, $orderByDesc: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getContractCN(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    isGlExport
    modBy
    modTs
    createdTs
    createdBy
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    submissionComment
    description
    docDate
    cancellationDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    contractCNItem {
      ID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      taxRate
      taxAmt
      remarks
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  CNInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractCnQuery__
 *
 * To run a query within a React component, call `useGetContractCnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetContractCnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractCnQuery, GetContractCnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractCnQuery, GetContractCnQueryVariables>(GetContractCnDocument, baseOptions);
      }
export function useGetContractCnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractCnQuery, GetContractCnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractCnQuery, GetContractCnQueryVariables>(GetContractCnDocument, baseOptions);
        }
export type GetContractCnQueryHookResult = ReturnType<typeof useGetContractCnQuery>;
export type GetContractCnLazyQueryHookResult = ReturnType<typeof useGetContractCnLazyQuery>;
export type GetContractCnQueryResult = ApolloReactCommon.QueryResult<GetContractCnQuery, GetContractCnQueryVariables>;
export const GetContractCnListingDocument = gql`
    query getContractCNListing($ID: String, $contractID: String, $customerID: String, $companyID: String, $orderByAsc: String, $orderByDesc: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getContractCN(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    isGlExport
    modBy
    modTs
    createdTs
    createdBy
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    submissionComment
    description
    docDate
    cancellationDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    contractCNItem {
      ID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      taxRate
      taxAmt
      remarks
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  CNInfo(contractID: $contractID, customerID: $customerID)
  getContractCNCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetContractCnListingQuery__
 *
 * To run a query within a React component, call `useGetContractCnListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCnListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCnListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetContractCnListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractCnListingQuery, GetContractCnListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractCnListingQuery, GetContractCnListingQueryVariables>(GetContractCnListingDocument, baseOptions);
      }
export function useGetContractCnListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractCnListingQuery, GetContractCnListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractCnListingQuery, GetContractCnListingQueryVariables>(GetContractCnListingDocument, baseOptions);
        }
export type GetContractCnListingQueryHookResult = ReturnType<typeof useGetContractCnListingQuery>;
export type GetContractCnListingLazyQueryHookResult = ReturnType<typeof useGetContractCnListingLazyQuery>;
export type GetContractCnListingQueryResult = ApolloReactCommon.QueryResult<GetContractCnListingQuery, GetContractCnListingQueryVariables>;
export const CreateContractCnDocument = gql`
    mutation createContractCN($input: ContractTransactionWithItemInput!) {
  createContractCN(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type CreateContractCnMutationFn = ApolloReactCommon.MutationFunction<CreateContractCnMutation, CreateContractCnMutationVariables>;

/**
 * __useCreateContractCnMutation__
 *
 * To run a mutation, you first call `useCreateContractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractCnMutation, { data, loading, error }] = useCreateContractCnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractCnMutation, CreateContractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractCnMutation, CreateContractCnMutationVariables>(CreateContractCnDocument, baseOptions);
      }
export type CreateContractCnMutationHookResult = ReturnType<typeof useCreateContractCnMutation>;
export type CreateContractCnMutationResult = ApolloReactCommon.MutationResult<CreateContractCnMutation>;
export type CreateContractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractCnMutation, CreateContractCnMutationVariables>;
export const UpdateContractCnDocument = gql`
    mutation updateContractCN($input: ContractTransactionWithItemInput!) {
  updateContractCN(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type UpdateContractCnMutationFn = ApolloReactCommon.MutationFunction<UpdateContractCnMutation, UpdateContractCnMutationVariables>;

/**
 * __useUpdateContractCnMutation__
 *
 * To run a mutation, you first call `useUpdateContractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractCnMutation, { data, loading, error }] = useUpdateContractCnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractCnMutation, UpdateContractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractCnMutation, UpdateContractCnMutationVariables>(UpdateContractCnDocument, baseOptions);
      }
export type UpdateContractCnMutationHookResult = ReturnType<typeof useUpdateContractCnMutation>;
export type UpdateContractCnMutationResult = ApolloReactCommon.MutationResult<UpdateContractCnMutation>;
export type UpdateContractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractCnMutation, UpdateContractCnMutationVariables>;
export const GetClientDnDocument = gql`
    query getClientDN($ID: String, $contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getClientDN(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    isGlExport
    modTs
    modBy
    createdTs
    createdBy
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    submissionComment
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    outstandingAmt
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    clientDNItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      taxRate
      taxAmt
      remarks
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  clientDNInfo(customerID: $customerID)
}
    `;

/**
 * __useGetClientDnQuery__
 *
 * To run a query within a React component, call `useGetClientDnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientDnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientDnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetClientDnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientDnQuery, GetClientDnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientDnQuery, GetClientDnQueryVariables>(GetClientDnDocument, baseOptions);
      }
export function useGetClientDnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientDnQuery, GetClientDnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientDnQuery, GetClientDnQueryVariables>(GetClientDnDocument, baseOptions);
        }
export type GetClientDnQueryHookResult = ReturnType<typeof useGetClientDnQuery>;
export type GetClientDnLazyQueryHookResult = ReturnType<typeof useGetClientDnLazyQuery>;
export type GetClientDnQueryResult = ApolloReactCommon.QueryResult<GetClientDnQuery, GetClientDnQueryVariables>;
export const GetClientDnListingDocument = gql`
    query getClientDNListing($ID: String, $contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getClientDN(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    isGlExport
    modTs
    modBy
    createdTs
    createdBy
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    submissionComment
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    outstandingAmt
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    clientDNItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      taxRate
      taxAmt
      remarks
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  clientDNInfo(customerID: $customerID)
  getClientDNCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetClientDnListingQuery__
 *
 * To run a query within a React component, call `useGetClientDnListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientDnListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientDnListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetClientDnListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientDnListingQuery, GetClientDnListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientDnListingQuery, GetClientDnListingQueryVariables>(GetClientDnListingDocument, baseOptions);
      }
export function useGetClientDnListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientDnListingQuery, GetClientDnListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientDnListingQuery, GetClientDnListingQueryVariables>(GetClientDnListingDocument, baseOptions);
        }
export type GetClientDnListingQueryHookResult = ReturnType<typeof useGetClientDnListingQuery>;
export type GetClientDnListingLazyQueryHookResult = ReturnType<typeof useGetClientDnListingLazyQuery>;
export type GetClientDnListingQueryResult = ApolloReactCommon.QueryResult<GetClientDnListingQuery, GetClientDnListingQueryVariables>;
export const CreateClientDnDocument = gql`
    mutation createClientDN($input: ContractTransactionWithItemInput!) {
  createClientDN(input: $input) {
    accountID
    ID
  }
}
    `;
export type CreateClientDnMutationFn = ApolloReactCommon.MutationFunction<CreateClientDnMutation, CreateClientDnMutationVariables>;

/**
 * __useCreateClientDnMutation__
 *
 * To run a mutation, you first call `useCreateClientDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientDnMutation, { data, loading, error }] = useCreateClientDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClientDnMutation, CreateClientDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateClientDnMutation, CreateClientDnMutationVariables>(CreateClientDnDocument, baseOptions);
      }
export type CreateClientDnMutationHookResult = ReturnType<typeof useCreateClientDnMutation>;
export type CreateClientDnMutationResult = ApolloReactCommon.MutationResult<CreateClientDnMutation>;
export type CreateClientDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateClientDnMutation, CreateClientDnMutationVariables>;
export const UpdateClientDnDocument = gql`
    mutation updateClientDN($input: ContractTransactionWithItemInput!) {
  updateClientDN(input: $input) {
    accountID
    ID
  }
}
    `;
export type UpdateClientDnMutationFn = ApolloReactCommon.MutationFunction<UpdateClientDnMutation, UpdateClientDnMutationVariables>;

/**
 * __useUpdateClientDnMutation__
 *
 * To run a mutation, you first call `useUpdateClientDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientDnMutation, { data, loading, error }] = useUpdateClientDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientDnMutation, UpdateClientDnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClientDnMutation, UpdateClientDnMutationVariables>(UpdateClientDnDocument, baseOptions);
      }
export type UpdateClientDnMutationHookResult = ReturnType<typeof useUpdateClientDnMutation>;
export type UpdateClientDnMutationResult = ApolloReactCommon.MutationResult<UpdateClientDnMutation>;
export type UpdateClientDnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientDnMutation, UpdateClientDnMutationVariables>;
export const GetClientCnDocument = gql`
    query getClientCN($ID: String, $contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getClientCN(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    isGlExport
    modTs
    modBy
    createdTs
    createdBy
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    submissionComment
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    outstandingAmt
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    clientCNItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      taxRate
      taxAmt
      remarks
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  clientCNInfo(customerID: $customerID)
}
    `;

/**
 * __useGetClientCnQuery__
 *
 * To run a query within a React component, call `useGetClientCnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientCnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetClientCnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientCnQuery, GetClientCnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientCnQuery, GetClientCnQueryVariables>(GetClientCnDocument, baseOptions);
      }
export function useGetClientCnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientCnQuery, GetClientCnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientCnQuery, GetClientCnQueryVariables>(GetClientCnDocument, baseOptions);
        }
export type GetClientCnQueryHookResult = ReturnType<typeof useGetClientCnQuery>;
export type GetClientCnLazyQueryHookResult = ReturnType<typeof useGetClientCnLazyQuery>;
export type GetClientCnQueryResult = ApolloReactCommon.QueryResult<GetClientCnQuery, GetClientCnQueryVariables>;
export const GetClientCnListingDocument = gql`
    query getClientCNListing($ID: String, $contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getClientCN(ID: $ID, contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    isGlExport
    modTs
    modBy
    createdTs
    createdBy
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    submissionComment
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    outstandingAmt
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    customerID
    contractID
    contract {
      ID
      title
    }
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    clientCNItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      taxRate
      taxAmt
      remarks
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  clientCNInfo(customerID: $customerID)
  getClientCNCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetClientCnListingQuery__
 *
 * To run a query within a React component, call `useGetClientCnListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCnListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientCnListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetClientCnListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientCnListingQuery, GetClientCnListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientCnListingQuery, GetClientCnListingQueryVariables>(GetClientCnListingDocument, baseOptions);
      }
export function useGetClientCnListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientCnListingQuery, GetClientCnListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientCnListingQuery, GetClientCnListingQueryVariables>(GetClientCnListingDocument, baseOptions);
        }
export type GetClientCnListingQueryHookResult = ReturnType<typeof useGetClientCnListingQuery>;
export type GetClientCnListingLazyQueryHookResult = ReturnType<typeof useGetClientCnListingLazyQuery>;
export type GetClientCnListingQueryResult = ApolloReactCommon.QueryResult<GetClientCnListingQuery, GetClientCnListingQueryVariables>;
export const CreateClientCnDocument = gql`
    mutation createClientCN($input: ContractTransactionWithItemInput!) {
  createClientCN(input: $input) {
    accountID
    ID
  }
}
    `;
export type CreateClientCnMutationFn = ApolloReactCommon.MutationFunction<CreateClientCnMutation, CreateClientCnMutationVariables>;

/**
 * __useCreateClientCnMutation__
 *
 * To run a mutation, you first call `useCreateClientCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientCnMutation, { data, loading, error }] = useCreateClientCnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClientCnMutation, CreateClientCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateClientCnMutation, CreateClientCnMutationVariables>(CreateClientCnDocument, baseOptions);
      }
export type CreateClientCnMutationHookResult = ReturnType<typeof useCreateClientCnMutation>;
export type CreateClientCnMutationResult = ApolloReactCommon.MutationResult<CreateClientCnMutation>;
export type CreateClientCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateClientCnMutation, CreateClientCnMutationVariables>;
export const UpdateClientCnDocument = gql`
    mutation updateClientCN($input: ContractTransactionWithItemInput!) {
  updateClientCN(input: $input) {
    accountID
    ID
  }
}
    `;
export type UpdateClientCnMutationFn = ApolloReactCommon.MutationFunction<UpdateClientCnMutation, UpdateClientCnMutationVariables>;

/**
 * __useUpdateClientCnMutation__
 *
 * To run a mutation, you first call `useUpdateClientCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientCnMutation, { data, loading, error }] = useUpdateClientCnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientCnMutation, UpdateClientCnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClientCnMutation, UpdateClientCnMutationVariables>(UpdateClientCnDocument, baseOptions);
      }
export type UpdateClientCnMutationHookResult = ReturnType<typeof useUpdateClientCnMutation>;
export type UpdateClientCnMutationResult = ApolloReactCommon.MutationResult<UpdateClientCnMutation>;
export type UpdateClientCnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientCnMutation, UpdateClientCnMutationVariables>;
export const CancelContractDnDocument = gql`
    mutation cancelContractDN($ID: String!, $input: CancelInput!) {
  cancelContractDN(ID: $ID, input: $input)
}
    `;
export type CancelContractDnMutationFn = ApolloReactCommon.MutationFunction<CancelContractDnMutation, CancelContractDnMutationVariables>;

/**
 * __useCancelContractDnMutation__
 *
 * To run a mutation, you first call `useCancelContractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractDnMutation, { data, loading, error }] = useCancelContractDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractDnMutation, CancelContractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractDnMutation, CancelContractDnMutationVariables>(CancelContractDnDocument, baseOptions);
      }
export type CancelContractDnMutationHookResult = ReturnType<typeof useCancelContractDnMutation>;
export type CancelContractDnMutationResult = ApolloReactCommon.MutationResult<CancelContractDnMutation>;
export type CancelContractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractDnMutation, CancelContractDnMutationVariables>;
export const CancelContractMiscInvoiceDocument = gql`
    mutation cancelContractMiscInvoice($ID: String!, $input: CancelInput!) {
  cancelContractMiscInvoice(ID: $ID, input: $input)
}
    `;
export type CancelContractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<CancelContractMiscInvoiceMutation, CancelContractMiscInvoiceMutationVariables>;

/**
 * __useCancelContractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelContractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractMiscInvoiceMutation, { data, loading, error }] = useCancelContractMiscInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractMiscInvoiceMutation, CancelContractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractMiscInvoiceMutation, CancelContractMiscInvoiceMutationVariables>(CancelContractMiscInvoiceDocument, baseOptions);
      }
export type CancelContractMiscInvoiceMutationHookResult = ReturnType<typeof useCancelContractMiscInvoiceMutation>;
export type CancelContractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<CancelContractMiscInvoiceMutation>;
export type CancelContractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractMiscInvoiceMutation, CancelContractMiscInvoiceMutationVariables>;
export const CancelContractCnDocument = gql`
    mutation cancelContractCN($ID: String!, $input: CancelInput!) {
  cancelContractCN(ID: $ID, input: $input)
}
    `;
export type CancelContractCnMutationFn = ApolloReactCommon.MutationFunction<CancelContractCnMutation, CancelContractCnMutationVariables>;

/**
 * __useCancelContractCnMutation__
 *
 * To run a mutation, you first call `useCancelContractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractCnMutation, { data, loading, error }] = useCancelContractCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractCnMutation, CancelContractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractCnMutation, CancelContractCnMutationVariables>(CancelContractCnDocument, baseOptions);
      }
export type CancelContractCnMutationHookResult = ReturnType<typeof useCancelContractCnMutation>;
export type CancelContractCnMutationResult = ApolloReactCommon.MutationResult<CancelContractCnMutation>;
export type CancelContractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractCnMutation, CancelContractCnMutationVariables>;
export const CancelClientDnDocument = gql`
    mutation cancelClientDN($ID: String!, $input: CancelInput!) {
  cancelClientDN(ID: $ID, input: $input)
}
    `;
export type CancelClientDnMutationFn = ApolloReactCommon.MutationFunction<CancelClientDnMutation, CancelClientDnMutationVariables>;

/**
 * __useCancelClientDnMutation__
 *
 * To run a mutation, you first call `useCancelClientDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClientDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClientDnMutation, { data, loading, error }] = useCancelClientDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelClientDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelClientDnMutation, CancelClientDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelClientDnMutation, CancelClientDnMutationVariables>(CancelClientDnDocument, baseOptions);
      }
export type CancelClientDnMutationHookResult = ReturnType<typeof useCancelClientDnMutation>;
export type CancelClientDnMutationResult = ApolloReactCommon.MutationResult<CancelClientDnMutation>;
export type CancelClientDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelClientDnMutation, CancelClientDnMutationVariables>;
export const CancelClientCnDocument = gql`
    mutation cancelClientCN($ID: String!, $input: CancelInput!) {
  cancelClientCN(ID: $ID, input: $input)
}
    `;
export type CancelClientCnMutationFn = ApolloReactCommon.MutationFunction<CancelClientCnMutation, CancelClientCnMutationVariables>;

/**
 * __useCancelClientCnMutation__
 *
 * To run a mutation, you first call `useCancelClientCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClientCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClientCnMutation, { data, loading, error }] = useCancelClientCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelClientCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelClientCnMutation, CancelClientCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelClientCnMutation, CancelClientCnMutationVariables>(CancelClientCnDocument, baseOptions);
      }
export type CancelClientCnMutationHookResult = ReturnType<typeof useCancelClientCnMutation>;
export type CancelClientCnMutationResult = ApolloReactCommon.MutationResult<CancelClientCnMutation>;
export type CancelClientCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelClientCnMutation, CancelClientCnMutationVariables>;
export const DeleteContractCnDocument = gql`
    mutation deleteContractCN($ID: String!) {
  deleteContractCN(ID: $ID)
}
    `;
export type DeleteContractCnMutationFn = ApolloReactCommon.MutationFunction<DeleteContractCnMutation, DeleteContractCnMutationVariables>;

/**
 * __useDeleteContractCnMutation__
 *
 * To run a mutation, you first call `useDeleteContractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractCnMutation, { data, loading, error }] = useDeleteContractCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractCnMutation, DeleteContractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractCnMutation, DeleteContractCnMutationVariables>(DeleteContractCnDocument, baseOptions);
      }
export type DeleteContractCnMutationHookResult = ReturnType<typeof useDeleteContractCnMutation>;
export type DeleteContractCnMutationResult = ApolloReactCommon.MutationResult<DeleteContractCnMutation>;
export type DeleteContractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractCnMutation, DeleteContractCnMutationVariables>;
export const DeleteContractDnDocument = gql`
    mutation deleteContractDN($ID: String!) {
  deleteContractDN(ID: $ID)
}
    `;
export type DeleteContractDnMutationFn = ApolloReactCommon.MutationFunction<DeleteContractDnMutation, DeleteContractDnMutationVariables>;

/**
 * __useDeleteContractDnMutation__
 *
 * To run a mutation, you first call `useDeleteContractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractDnMutation, { data, loading, error }] = useDeleteContractDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractDnMutation, DeleteContractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractDnMutation, DeleteContractDnMutationVariables>(DeleteContractDnDocument, baseOptions);
      }
export type DeleteContractDnMutationHookResult = ReturnType<typeof useDeleteContractDnMutation>;
export type DeleteContractDnMutationResult = ApolloReactCommon.MutationResult<DeleteContractDnMutation>;
export type DeleteContractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractDnMutation, DeleteContractDnMutationVariables>;
export const DeleteContractMiscInvoiceDocument = gql`
    mutation deleteContractMiscInvoice($ID: String!) {
  deleteContractMiscInvoice(ID: $ID)
}
    `;
export type DeleteContractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteContractMiscInvoiceMutation, DeleteContractMiscInvoiceMutationVariables>;

/**
 * __useDeleteContractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteContractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractMiscInvoiceMutation, { data, loading, error }] = useDeleteContractMiscInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractMiscInvoiceMutation, DeleteContractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractMiscInvoiceMutation, DeleteContractMiscInvoiceMutationVariables>(DeleteContractMiscInvoiceDocument, baseOptions);
      }
export type DeleteContractMiscInvoiceMutationHookResult = ReturnType<typeof useDeleteContractMiscInvoiceMutation>;
export type DeleteContractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteContractMiscInvoiceMutation>;
export type DeleteContractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractMiscInvoiceMutation, DeleteContractMiscInvoiceMutationVariables>;
export const DeleteClientDnDocument = gql`
    mutation deleteClientDN($ID: String!) {
  deleteClientDN(ID: $ID)
}
    `;
export type DeleteClientDnMutationFn = ApolloReactCommon.MutationFunction<DeleteClientDnMutation, DeleteClientDnMutationVariables>;

/**
 * __useDeleteClientDnMutation__
 *
 * To run a mutation, you first call `useDeleteClientDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientDnMutation, { data, loading, error }] = useDeleteClientDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteClientDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteClientDnMutation, DeleteClientDnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteClientDnMutation, DeleteClientDnMutationVariables>(DeleteClientDnDocument, baseOptions);
      }
export type DeleteClientDnMutationHookResult = ReturnType<typeof useDeleteClientDnMutation>;
export type DeleteClientDnMutationResult = ApolloReactCommon.MutationResult<DeleteClientDnMutation>;
export type DeleteClientDnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteClientDnMutation, DeleteClientDnMutationVariables>;
export const DeleteClientCnDocument = gql`
    mutation deleteClientCN($ID: String!) {
  deleteClientCN(ID: $ID)
}
    `;
export type DeleteClientCnMutationFn = ApolloReactCommon.MutationFunction<DeleteClientCnMutation, DeleteClientCnMutationVariables>;

/**
 * __useDeleteClientCnMutation__
 *
 * To run a mutation, you first call `useDeleteClientCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientCnMutation, { data, loading, error }] = useDeleteClientCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteClientCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteClientCnMutation, DeleteClientCnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteClientCnMutation, DeleteClientCnMutationVariables>(DeleteClientCnDocument, baseOptions);
      }
export type DeleteClientCnMutationHookResult = ReturnType<typeof useDeleteClientCnMutation>;
export type DeleteClientCnMutationResult = ApolloReactCommon.MutationResult<DeleteClientCnMutation>;
export type DeleteClientCnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteClientCnMutation, DeleteClientCnMutationVariables>;
export const GetClientAdjustmentDocument = gql`
    query getClientAdjustment($contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus) {
  getContractDN(contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docAmt
    isGlExport
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    numOfAttachment
  }
  getCNRevenue(contractID: $contractID, withRev: false, transactionStatus: APPROVED) {
    ID
    docAmt
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    numOfAttachment
    cancelledClaimAmt
  }
  getClientDN(contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docAmt
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    numOfAttachment
  }
  getClientCN(contractID: $contractID, customerID: $customerID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docAmt
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    numOfAttachment
  }
}
    `;

/**
 * __useGetClientAdjustmentQuery__
 *
 * To run a query within a React component, call `useGetClientAdjustmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAdjustmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientAdjustmentQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetClientAdjustmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientAdjustmentQuery, GetClientAdjustmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientAdjustmentQuery, GetClientAdjustmentQueryVariables>(GetClientAdjustmentDocument, baseOptions);
      }
export function useGetClientAdjustmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientAdjustmentQuery, GetClientAdjustmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientAdjustmentQuery, GetClientAdjustmentQueryVariables>(GetClientAdjustmentDocument, baseOptions);
        }
export type GetClientAdjustmentQueryHookResult = ReturnType<typeof useGetClientAdjustmentQuery>;
export type GetClientAdjustmentLazyQueryHookResult = ReturnType<typeof useGetClientAdjustmentLazyQuery>;
export type GetClientAdjustmentQueryResult = ApolloReactCommon.QueryResult<GetClientAdjustmentQuery, GetClientAdjustmentQueryVariables>;
export const GetContractDnCountDocument = gql`
    query getContractDNCount($contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus) {
  getContractDNCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetContractDnCountQuery__
 *
 * To run a query within a React component, call `useGetContractDnCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractDnCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractDnCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetContractDnCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractDnCountQuery, GetContractDnCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractDnCountQuery, GetContractDnCountQueryVariables>(GetContractDnCountDocument, baseOptions);
      }
export function useGetContractDnCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractDnCountQuery, GetContractDnCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractDnCountQuery, GetContractDnCountQueryVariables>(GetContractDnCountDocument, baseOptions);
        }
export type GetContractDnCountQueryHookResult = ReturnType<typeof useGetContractDnCountQuery>;
export type GetContractDnCountLazyQueryHookResult = ReturnType<typeof useGetContractDnCountLazyQuery>;
export type GetContractDnCountQueryResult = ApolloReactCommon.QueryResult<GetContractDnCountQuery, GetContractDnCountQueryVariables>;
export const GetContractCnCountDocument = gql`
    query getContractCNCount($contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus) {
  getContractCNCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetContractCnCountQuery__
 *
 * To run a query within a React component, call `useGetContractCnCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCnCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCnCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetContractCnCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractCnCountQuery, GetContractCnCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractCnCountQuery, GetContractCnCountQueryVariables>(GetContractCnCountDocument, baseOptions);
      }
export function useGetContractCnCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractCnCountQuery, GetContractCnCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractCnCountQuery, GetContractCnCountQueryVariables>(GetContractCnCountDocument, baseOptions);
        }
export type GetContractCnCountQueryHookResult = ReturnType<typeof useGetContractCnCountQuery>;
export type GetContractCnCountLazyQueryHookResult = ReturnType<typeof useGetContractCnCountLazyQuery>;
export type GetContractCnCountQueryResult = ApolloReactCommon.QueryResult<GetContractCnCountQuery, GetContractCnCountQueryVariables>;
export const GetContractMiscInvoiceCountDocument = gql`
    query getContractMiscInvoiceCount($contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus) {
  getContractMiscInvoiceCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetContractMiscInvoiceCountQuery__
 *
 * To run a query within a React component, call `useGetContractMiscInvoiceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractMiscInvoiceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractMiscInvoiceCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetContractMiscInvoiceCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractMiscInvoiceCountQuery, GetContractMiscInvoiceCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractMiscInvoiceCountQuery, GetContractMiscInvoiceCountQueryVariables>(GetContractMiscInvoiceCountDocument, baseOptions);
      }
export function useGetContractMiscInvoiceCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractMiscInvoiceCountQuery, GetContractMiscInvoiceCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractMiscInvoiceCountQuery, GetContractMiscInvoiceCountQueryVariables>(GetContractMiscInvoiceCountDocument, baseOptions);
        }
export type GetContractMiscInvoiceCountQueryHookResult = ReturnType<typeof useGetContractMiscInvoiceCountQuery>;
export type GetContractMiscInvoiceCountLazyQueryHookResult = ReturnType<typeof useGetContractMiscInvoiceCountLazyQuery>;
export type GetContractMiscInvoiceCountQueryResult = ApolloReactCommon.QueryResult<GetContractMiscInvoiceCountQuery, GetContractMiscInvoiceCountQueryVariables>;
export const GetClientCnCountDocument = gql`
    query getClientCNCount($contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus) {
  getClientCNCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetClientCnCountQuery__
 *
 * To run a query within a React component, call `useGetClientCnCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCnCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientCnCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetClientCnCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientCnCountQuery, GetClientCnCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientCnCountQuery, GetClientCnCountQueryVariables>(GetClientCnCountDocument, baseOptions);
      }
export function useGetClientCnCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientCnCountQuery, GetClientCnCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientCnCountQuery, GetClientCnCountQueryVariables>(GetClientCnCountDocument, baseOptions);
        }
export type GetClientCnCountQueryHookResult = ReturnType<typeof useGetClientCnCountQuery>;
export type GetClientCnCountLazyQueryHookResult = ReturnType<typeof useGetClientCnCountLazyQuery>;
export type GetClientCnCountQueryResult = ApolloReactCommon.QueryResult<GetClientCnCountQuery, GetClientCnCountQueryVariables>;
export const GetClientDnCountDocument = gql`
    query getClientDNCount($contractID: String, $customerID: String, $companyID: String, $transactionStatus: TransactionStatus) {
  getClientDNCount(customerID: $customerID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetClientDnCountQuery__
 *
 * To run a query within a React component, call `useGetClientDnCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientDnCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientDnCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetClientDnCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientDnCountQuery, GetClientDnCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientDnCountQuery, GetClientDnCountQueryVariables>(GetClientDnCountDocument, baseOptions);
      }
export function useGetClientDnCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientDnCountQuery, GetClientDnCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientDnCountQuery, GetClientDnCountQueryVariables>(GetClientDnCountDocument, baseOptions);
        }
export type GetClientDnCountQueryHookResult = ReturnType<typeof useGetClientDnCountQuery>;
export type GetClientDnCountLazyQueryHookResult = ReturnType<typeof useGetClientDnCountLazyQuery>;
export type GetClientDnCountQueryResult = ApolloReactCommon.QueryResult<GetClientDnCountQuery, GetClientDnCountQueryVariables>;
export const GetUserCompanyAssignmentDocument = gql`
    query getUserCompanyAssignment($userID: String, $companyID: String) {
  getUserCompanyAssignment(userID: $userID, companyID: $companyID) {
    ID
    companyID
    userID
  }
}
    `;

/**
 * __useGetUserCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetUserCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCompanyAssignmentQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetUserCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserCompanyAssignmentQuery, GetUserCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserCompanyAssignmentQuery, GetUserCompanyAssignmentQueryVariables>(GetUserCompanyAssignmentDocument, baseOptions);
      }
export function useGetUserCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserCompanyAssignmentQuery, GetUserCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserCompanyAssignmentQuery, GetUserCompanyAssignmentQueryVariables>(GetUserCompanyAssignmentDocument, baseOptions);
        }
export type GetUserCompanyAssignmentQueryHookResult = ReturnType<typeof useGetUserCompanyAssignmentQuery>;
export type GetUserCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetUserCompanyAssignmentLazyQuery>;
export type GetUserCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetUserCompanyAssignmentQuery, GetUserCompanyAssignmentQueryVariables>;
export const GetSubcontractorAllDocument = gql`
    query getSubcontractorAll($companyID: String, $withoutCompany: Boolean) {
  getSubcontractorByCompany(companyID: $companyID, withoutCompany: $withoutCompany) {
    ID
    name
    registrationNo
  }
}
    `;

/**
 * __useGetSubcontractorAllQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      withoutCompany: // value for 'withoutCompany'
 *   },
 * });
 */
export function useGetSubcontractorAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorAllQuery, GetSubcontractorAllQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorAllQuery, GetSubcontractorAllQueryVariables>(GetSubcontractorAllDocument, baseOptions);
      }
export function useGetSubcontractorAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorAllQuery, GetSubcontractorAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorAllQuery, GetSubcontractorAllQueryVariables>(GetSubcontractorAllDocument, baseOptions);
        }
export type GetSubcontractorAllQueryHookResult = ReturnType<typeof useGetSubcontractorAllQuery>;
export type GetSubcontractorAllLazyQueryHookResult = ReturnType<typeof useGetSubcontractorAllLazyQuery>;
export type GetSubcontractorAllQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorAllQuery, GetSubcontractorAllQueryVariables>;
export const GetSupplierAllDocument = gql`
    query getSupplierAll($companyID: String, $withoutCompany: Boolean) {
  getSupplierByCompany(companyID: $companyID, withoutCompany: $withoutCompany) {
    ID
    name
    registrationNo
  }
}
    `;

/**
 * __useGetSupplierAllQuery__
 *
 * To run a query within a React component, call `useGetSupplierAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      withoutCompany: // value for 'withoutCompany'
 *   },
 * });
 */
export function useGetSupplierAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierAllQuery, GetSupplierAllQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierAllQuery, GetSupplierAllQueryVariables>(GetSupplierAllDocument, baseOptions);
      }
export function useGetSupplierAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierAllQuery, GetSupplierAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierAllQuery, GetSupplierAllQueryVariables>(GetSupplierAllDocument, baseOptions);
        }
export type GetSupplierAllQueryHookResult = ReturnType<typeof useGetSupplierAllQuery>;
export type GetSupplierAllLazyQueryHookResult = ReturnType<typeof useGetSupplierAllLazyQuery>;
export type GetSupplierAllQueryResult = ApolloReactCommon.QueryResult<GetSupplierAllQuery, GetSupplierAllQueryVariables>;
export const GetCustomerAllDocument = gql`
    query getCustomerAll($companyID: String, $withoutCompany: Boolean) {
  getCustomerByCompany(companyID: $companyID, withoutCompany: $withoutCompany) {
    ID
    name
    registrationNo
  }
}
    `;

/**
 * __useGetCustomerAllQuery__
 *
 * To run a query within a React component, call `useGetCustomerAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      withoutCompany: // value for 'withoutCompany'
 *   },
 * });
 */
export function useGetCustomerAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerAllQuery, GetCustomerAllQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerAllQuery, GetCustomerAllQueryVariables>(GetCustomerAllDocument, baseOptions);
      }
export function useGetCustomerAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerAllQuery, GetCustomerAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerAllQuery, GetCustomerAllQueryVariables>(GetCustomerAllDocument, baseOptions);
        }
export type GetCustomerAllQueryHookResult = ReturnType<typeof useGetCustomerAllQuery>;
export type GetCustomerAllLazyQueryHookResult = ReturnType<typeof useGetCustomerAllLazyQuery>;
export type GetCustomerAllQueryResult = ApolloReactCommon.QueryResult<GetCustomerAllQuery, GetCustomerAllQueryVariables>;
export const AssignSubconWithCompanyDocument = gql`
    mutation assignSubconWithCompany($companyID: String!, $subcontractorID: [String!]!) {
  assignSubconWithCompany(companyID: $companyID, subcontractorID: $subcontractorID)
}
    `;
export type AssignSubconWithCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignSubconWithCompanyMutation, AssignSubconWithCompanyMutationVariables>;

/**
 * __useAssignSubconWithCompanyMutation__
 *
 * To run a mutation, you first call `useAssignSubconWithCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSubconWithCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSubconWithCompanyMutation, { data, loading, error }] = useAssignSubconWithCompanyMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useAssignSubconWithCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignSubconWithCompanyMutation, AssignSubconWithCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignSubconWithCompanyMutation, AssignSubconWithCompanyMutationVariables>(AssignSubconWithCompanyDocument, baseOptions);
      }
export type AssignSubconWithCompanyMutationHookResult = ReturnType<typeof useAssignSubconWithCompanyMutation>;
export type AssignSubconWithCompanyMutationResult = ApolloReactCommon.MutationResult<AssignSubconWithCompanyMutation>;
export type AssignSubconWithCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignSubconWithCompanyMutation, AssignSubconWithCompanyMutationVariables>;
export const UnassignSubconWithCompanyDocument = gql`
    mutation unassignSubconWithCompany($companyID: String!, $subcontractorID: [String!]!) {
  unassignSubconWithCompany(companyID: $companyID, subcontractorID: $subcontractorID)
}
    `;
export type UnassignSubconWithCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignSubconWithCompanyMutation, UnassignSubconWithCompanyMutationVariables>;

/**
 * __useUnassignSubconWithCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignSubconWithCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignSubconWithCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignSubconWithCompanyMutation, { data, loading, error }] = useUnassignSubconWithCompanyMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useUnassignSubconWithCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignSubconWithCompanyMutation, UnassignSubconWithCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignSubconWithCompanyMutation, UnassignSubconWithCompanyMutationVariables>(UnassignSubconWithCompanyDocument, baseOptions);
      }
export type UnassignSubconWithCompanyMutationHookResult = ReturnType<typeof useUnassignSubconWithCompanyMutation>;
export type UnassignSubconWithCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignSubconWithCompanyMutation>;
export type UnassignSubconWithCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignSubconWithCompanyMutation, UnassignSubconWithCompanyMutationVariables>;
export const AssignSupplierWithCompanyDocument = gql`
    mutation assignSupplierWithCompany($companyID: String!, $supplierID: [String!]!) {
  assignSupplierWithCompany(companyID: $companyID, supplierID: $supplierID)
}
    `;
export type AssignSupplierWithCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignSupplierWithCompanyMutation, AssignSupplierWithCompanyMutationVariables>;

/**
 * __useAssignSupplierWithCompanyMutation__
 *
 * To run a mutation, you first call `useAssignSupplierWithCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSupplierWithCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSupplierWithCompanyMutation, { data, loading, error }] = useAssignSupplierWithCompanyMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useAssignSupplierWithCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignSupplierWithCompanyMutation, AssignSupplierWithCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignSupplierWithCompanyMutation, AssignSupplierWithCompanyMutationVariables>(AssignSupplierWithCompanyDocument, baseOptions);
      }
export type AssignSupplierWithCompanyMutationHookResult = ReturnType<typeof useAssignSupplierWithCompanyMutation>;
export type AssignSupplierWithCompanyMutationResult = ApolloReactCommon.MutationResult<AssignSupplierWithCompanyMutation>;
export type AssignSupplierWithCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignSupplierWithCompanyMutation, AssignSupplierWithCompanyMutationVariables>;
export const UnassignSupplierWithCompanyDocument = gql`
    mutation unassignSupplierWithCompany($companyID: String!, $supplierID: [String!]!) {
  unassignSupplierWithCompany(companyID: $companyID, supplierID: $supplierID)
}
    `;
export type UnassignSupplierWithCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignSupplierWithCompanyMutation, UnassignSupplierWithCompanyMutationVariables>;

/**
 * __useUnassignSupplierWithCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignSupplierWithCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignSupplierWithCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignSupplierWithCompanyMutation, { data, loading, error }] = useUnassignSupplierWithCompanyMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useUnassignSupplierWithCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignSupplierWithCompanyMutation, UnassignSupplierWithCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignSupplierWithCompanyMutation, UnassignSupplierWithCompanyMutationVariables>(UnassignSupplierWithCompanyDocument, baseOptions);
      }
export type UnassignSupplierWithCompanyMutationHookResult = ReturnType<typeof useUnassignSupplierWithCompanyMutation>;
export type UnassignSupplierWithCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignSupplierWithCompanyMutation>;
export type UnassignSupplierWithCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignSupplierWithCompanyMutation, UnassignSupplierWithCompanyMutationVariables>;
export const AssignCustomerWithCompanyDocument = gql`
    mutation assignCustomerWithCompany($companyID: String!, $customerID: [String!]!) {
  assignCustomerWithCompany(companyID: $companyID, customerID: $customerID)
}
    `;
export type AssignCustomerWithCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignCustomerWithCompanyMutation, AssignCustomerWithCompanyMutationVariables>;

/**
 * __useAssignCustomerWithCompanyMutation__
 *
 * To run a mutation, you first call `useAssignCustomerWithCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCustomerWithCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCustomerWithCompanyMutation, { data, loading, error }] = useAssignCustomerWithCompanyMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useAssignCustomerWithCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignCustomerWithCompanyMutation, AssignCustomerWithCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignCustomerWithCompanyMutation, AssignCustomerWithCompanyMutationVariables>(AssignCustomerWithCompanyDocument, baseOptions);
      }
export type AssignCustomerWithCompanyMutationHookResult = ReturnType<typeof useAssignCustomerWithCompanyMutation>;
export type AssignCustomerWithCompanyMutationResult = ApolloReactCommon.MutationResult<AssignCustomerWithCompanyMutation>;
export type AssignCustomerWithCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignCustomerWithCompanyMutation, AssignCustomerWithCompanyMutationVariables>;
export const UnassignCustomerWithCompanyDocument = gql`
    mutation unassignCustomerWithCompany($companyID: String!, $customerID: [String!]!) {
  unassignCustomerWithCompany(companyID: $companyID, customerID: $customerID)
}
    `;
export type UnassignCustomerWithCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignCustomerWithCompanyMutation, UnassignCustomerWithCompanyMutationVariables>;

/**
 * __useUnassignCustomerWithCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignCustomerWithCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignCustomerWithCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCustomerWithCompanyMutation, { data, loading, error }] = useUnassignCustomerWithCompanyMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useUnassignCustomerWithCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignCustomerWithCompanyMutation, UnassignCustomerWithCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignCustomerWithCompanyMutation, UnassignCustomerWithCompanyMutationVariables>(UnassignCustomerWithCompanyDocument, baseOptions);
      }
export type UnassignCustomerWithCompanyMutationHookResult = ReturnType<typeof useUnassignCustomerWithCompanyMutation>;
export type UnassignCustomerWithCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignCustomerWithCompanyMutation>;
export type UnassignCustomerWithCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignCustomerWithCompanyMutation, UnassignCustomerWithCompanyMutationVariables>;
export const AssignUserWithCompanyDocument = gql`
    mutation assignUserWithCompany($companyID: String!, $userID: [String!]!) {
  assignUserWithCompany(companyID: $companyID, userID: $userID)
}
    `;
export type AssignUserWithCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignUserWithCompanyMutation, AssignUserWithCompanyMutationVariables>;

/**
 * __useAssignUserWithCompanyMutation__
 *
 * To run a mutation, you first call `useAssignUserWithCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserWithCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserWithCompanyMutation, { data, loading, error }] = useAssignUserWithCompanyMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useAssignUserWithCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignUserWithCompanyMutation, AssignUserWithCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignUserWithCompanyMutation, AssignUserWithCompanyMutationVariables>(AssignUserWithCompanyDocument, baseOptions);
      }
export type AssignUserWithCompanyMutationHookResult = ReturnType<typeof useAssignUserWithCompanyMutation>;
export type AssignUserWithCompanyMutationResult = ApolloReactCommon.MutationResult<AssignUserWithCompanyMutation>;
export type AssignUserWithCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignUserWithCompanyMutation, AssignUserWithCompanyMutationVariables>;
export const UnassignUserWithCompanyDocument = gql`
    mutation unassignUserWithCompany($companyID: String!, $userID: [String!]!) {
  unassignUserWithCompany(companyID: $companyID, userID: $userID)
}
    `;
export type UnassignUserWithCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignUserWithCompanyMutation, UnassignUserWithCompanyMutationVariables>;

/**
 * __useUnassignUserWithCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignUserWithCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignUserWithCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignUserWithCompanyMutation, { data, loading, error }] = useUnassignUserWithCompanyMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUnassignUserWithCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignUserWithCompanyMutation, UnassignUserWithCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignUserWithCompanyMutation, UnassignUserWithCompanyMutationVariables>(UnassignUserWithCompanyDocument, baseOptions);
      }
export type UnassignUserWithCompanyMutationHookResult = ReturnType<typeof useUnassignUserWithCompanyMutation>;
export type UnassignUserWithCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignUserWithCompanyMutation>;
export type UnassignUserWithCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignUserWithCompanyMutation, UnassignUserWithCompanyMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CompanyInput!) {
  createCompany(input: $input)
}
    `;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($input: CompanyInput!) {
  updateCompany(input: $input)
}
    `;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($CompanyID: String!) {
  deleteCompany(CompanyID: $CompanyID)
}
    `;
export type DeleteCompanyMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, baseOptions);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const GetCompanyDocument = gql`
    query getCompany($companyID: String) {
  getCompany(companyID: $companyID) {
    createdTs
    modTs
    CompanyID
    Name
    Description
    CompanyRegNo
    Tin
    GroupGSTRegNo
    ContactNo
    Email
    SSTNo
    GSTNo
    GSTExpiryDate
    Image
    ImageUrl
    CompanyTax
    BaseCurrencyID
    RecordStatus
    Address {
      address
      address2
      country
      city
      district
      state
      postCode
    }
    WatermarkWording
    WatermarkImplementation
    ImageLogoPosition
    IsoImplementation
    IsoLogoPosition
    eSign
    eSignExpiryDate
    eInvIntegrated
    eInvStartDate
    eInvEndDate
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
      }
export function useGetCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = ApolloReactCommon.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCompanyListDocument = gql`
    query getCompanyList($companyID: String) {
  getCompany(companyID: $companyID) {
    CompanyID
    Name
    Description
    CompanyRegNo
    Tin
    Image
    ImageUrl
    RecordStatus
  }
}
    `;

/**
 * __useGetCompanyListQuery__
 *
 * To run a query within a React component, call `useGetCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyListQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetCompanyListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, baseOptions);
      }
export function useGetCompanyListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, baseOptions);
        }
export type GetCompanyListQueryHookResult = ReturnType<typeof useGetCompanyListQuery>;
export type GetCompanyListLazyQueryHookResult = ReturnType<typeof useGetCompanyListLazyQuery>;
export type GetCompanyListQueryResult = ApolloReactCommon.QueryResult<GetCompanyListQuery, GetCompanyListQueryVariables>;
export const GetCompanySubmenuDocument = gql`
    query getCompanySubmenu($companyID: String) {
  getCompany(companyID: $companyID) {
    createdTs
    modTs
    CompanyID
    Name
    Description
    CompanyRegNo
    Tin
    Image
    ImageUrl
    RecordStatus
  }
  getAllCompany: getCompany(orderByAsc: "Name") {
    CompanyID
    Name
    Description
    CompanyRegNo
    Tin
    Image
    ImageUrl
    RecordStatus
  }
}
    `;

/**
 * __useGetCompanySubmenuQuery__
 *
 * To run a query within a React component, call `useGetCompanySubmenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySubmenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySubmenuQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetCompanySubmenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanySubmenuQuery, GetCompanySubmenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanySubmenuQuery, GetCompanySubmenuQueryVariables>(GetCompanySubmenuDocument, baseOptions);
      }
export function useGetCompanySubmenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanySubmenuQuery, GetCompanySubmenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanySubmenuQuery, GetCompanySubmenuQueryVariables>(GetCompanySubmenuDocument, baseOptions);
        }
export type GetCompanySubmenuQueryHookResult = ReturnType<typeof useGetCompanySubmenuQuery>;
export type GetCompanySubmenuLazyQueryHookResult = ReturnType<typeof useGetCompanySubmenuLazyQuery>;
export type GetCompanySubmenuQueryResult = ApolloReactCommon.QueryResult<GetCompanySubmenuQuery, GetCompanySubmenuQueryVariables>;
export const GetCompanyHeaderDocument = gql`
    query getCompanyHeader($companyID: String) {
  getCompany(companyID: $companyID) {
    CompanyID
    Name
    CompanyRegNo
    ContactNo
    SSTNo
    GSTNo
    Image
    ImageBucketFile
    ImageLogoPosition
    ImageUrl
    Address {
      address
      address2
      country
      city
      district
      state
      postCode
    }
  }
}
    `;

/**
 * __useGetCompanyHeaderQuery__
 *
 * To run a query within a React component, call `useGetCompanyHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyHeaderQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetCompanyHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>(GetCompanyHeaderDocument, baseOptions);
      }
export function useGetCompanyHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>(GetCompanyHeaderDocument, baseOptions);
        }
export type GetCompanyHeaderQueryHookResult = ReturnType<typeof useGetCompanyHeaderQuery>;
export type GetCompanyHeaderLazyQueryHookResult = ReturnType<typeof useGetCompanyHeaderLazyQuery>;
export type GetCompanyHeaderQueryResult = ApolloReactCommon.QueryResult<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>;
export const GetCompanyIsoDocument = gql`
    query getCompanyIso($companyID: String) {
  getCompany(companyID: $companyID) {
    CompanyID
    Name
    IsoImplementation
    IsoLogoPosition
  }
}
    `;

/**
 * __useGetCompanyIsoQuery__
 *
 * To run a query within a React component, call `useGetCompanyIsoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyIsoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyIsoQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetCompanyIsoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyIsoQuery, GetCompanyIsoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyIsoQuery, GetCompanyIsoQueryVariables>(GetCompanyIsoDocument, baseOptions);
      }
export function useGetCompanyIsoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyIsoQuery, GetCompanyIsoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyIsoQuery, GetCompanyIsoQueryVariables>(GetCompanyIsoDocument, baseOptions);
        }
export type GetCompanyIsoQueryHookResult = ReturnType<typeof useGetCompanyIsoQuery>;
export type GetCompanyIsoLazyQueryHookResult = ReturnType<typeof useGetCompanyIsoLazyQuery>;
export type GetCompanyIsoQueryResult = ApolloReactCommon.QueryResult<GetCompanyIsoQuery, GetCompanyIsoQueryVariables>;
export const GetAllCompanyDocument = gql`
    query getAllCompany {
  getCompany {
    CompanyID
    ParentCompanyID
    AccountID
    BaseCurrencyID
    Name
    Description
    CompanyRegNo
    Tin
    GroupGSTRegNo
    Code
    Address {
      ID
      address
      address2
      siteName
      country
      city
      district
      state
      postCode
      postCodeName
      AddressType
    }
    ContactNo
    Email
    SSTNo
    GSTNo
    GSTExpiryDate
    Image
    ImageUrl
    ImageBucketFile
    WebsiteUrl
    RegionID
    ImageLogoPosition
    WatermarkImplementation
    WatermarkWording
    IsoImplementation
    IsoLogoPosition
    CompanyTax
    RecordStatus
    createdBy
    createdTs
    modBy
    modTs
    eSign
    eSignExpiryDate
  }
}
    `;

/**
 * __useGetAllCompanyQuery__
 *
 * To run a query within a React component, call `useGetAllCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCompanyQuery, GetAllCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCompanyQuery, GetAllCompanyQueryVariables>(GetAllCompanyDocument, baseOptions);
      }
export function useGetAllCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCompanyQuery, GetAllCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCompanyQuery, GetAllCompanyQueryVariables>(GetAllCompanyDocument, baseOptions);
        }
export type GetAllCompanyQueryHookResult = ReturnType<typeof useGetAllCompanyQuery>;
export type GetAllCompanyLazyQueryHookResult = ReturnType<typeof useGetAllCompanyLazyQuery>;
export type GetAllCompanyQueryResult = ApolloReactCommon.QueryResult<GetAllCompanyQuery, GetAllCompanyQueryVariables>;
export const GetCompanySummaryDocument = gql`
    query getCompanySummary($companyID: String) {
  bankAccountDate(companyID: $companyID)
  docNumDate(companyID: $companyID)
}
    `;

/**
 * __useGetCompanySummaryQuery__
 *
 * To run a query within a React component, call `useGetCompanySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySummaryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetCompanySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanySummaryQuery, GetCompanySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanySummaryQuery, GetCompanySummaryQueryVariables>(GetCompanySummaryDocument, baseOptions);
      }
export function useGetCompanySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanySummaryQuery, GetCompanySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanySummaryQuery, GetCompanySummaryQueryVariables>(GetCompanySummaryDocument, baseOptions);
        }
export type GetCompanySummaryQueryHookResult = ReturnType<typeof useGetCompanySummaryQuery>;
export type GetCompanySummaryLazyQueryHookResult = ReturnType<typeof useGetCompanySummaryLazyQuery>;
export type GetCompanySummaryQueryResult = ApolloReactCommon.QueryResult<GetCompanySummaryQuery, GetCompanySummaryQueryVariables>;
export const GetBqSummaryDocument = gql`
    query getBqSummary($contractID: String!) {
  contractInfo(contractID: $contractID) {
    contractSum
    contractDate
  }
  wbsBudgetInfo(contractID: $contractID)
  lastBqImportDate(contractID: $contractID)
  bqAssignmentInfo(contractID: $contractID)
}
    `;

/**
 * __useGetBqSummaryQuery__
 *
 * To run a query within a React component, call `useGetBqSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBqSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBqSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetBqSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBqSummaryQuery, GetBqSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBqSummaryQuery, GetBqSummaryQueryVariables>(GetBqSummaryDocument, baseOptions);
      }
export function useGetBqSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBqSummaryQuery, GetBqSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBqSummaryQuery, GetBqSummaryQueryVariables>(GetBqSummaryDocument, baseOptions);
        }
export type GetBqSummaryQueryHookResult = ReturnType<typeof useGetBqSummaryQuery>;
export type GetBqSummaryLazyQueryHookResult = ReturnType<typeof useGetBqSummaryLazyQuery>;
export type GetBqSummaryQueryResult = ApolloReactCommon.QueryResult<GetBqSummaryQuery, GetBqSummaryQueryVariables>;
export const GetBqDocument = gql`
    query getBQ($orderByDesc: String, $orderByAsc: String, $ID: String, $contractID: String) {
  getBQ(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, ID: $ID, contractID: $contractID) {
    billNo
    pageNo
    itemNo
    uomID
    description
    quantity
    rate
    amount
    isLastNode
  }
}
    `;

/**
 * __useGetBqQuery__
 *
 * To run a query within a React component, call `useGetBqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBqQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetBqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBqQuery, GetBqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBqQuery, GetBqQueryVariables>(GetBqDocument, baseOptions);
      }
export function useGetBqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBqQuery, GetBqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBqQuery, GetBqQueryVariables>(GetBqDocument, baseOptions);
        }
export type GetBqQueryHookResult = ReturnType<typeof useGetBqQuery>;
export type GetBqLazyQueryHookResult = ReturnType<typeof useGetBqLazyQuery>;
export type GetBqQueryResult = ApolloReactCommon.QueryResult<GetBqQuery, GetBqQueryVariables>;
export const CreateBqDocument = gql`
    mutation createBQ($input: [BQInput!]!, $contractID: String!) {
  createBQ(input: $input, contractID: $contractID)
}
    `;
export type CreateBqMutationFn = ApolloReactCommon.MutationFunction<CreateBqMutation, CreateBqMutationVariables>;

/**
 * __useCreateBqMutation__
 *
 * To run a mutation, you first call `useCreateBqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBqMutation, { data, loading, error }] = useCreateBqMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useCreateBqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBqMutation, CreateBqMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBqMutation, CreateBqMutationVariables>(CreateBqDocument, baseOptions);
      }
export type CreateBqMutationHookResult = ReturnType<typeof useCreateBqMutation>;
export type CreateBqMutationResult = ApolloReactCommon.MutationResult<CreateBqMutation>;
export type CreateBqMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBqMutation, CreateBqMutationVariables>;
export const GetCheckingValidationBqDocument = gql`
    query getCheckingValidationBQ($BQExcelInput: [BQExcelInput!]!, $contractID: String!) {
  getCheckingValidationBQ(BQExcelInput: $BQExcelInput, contractID: $contractID)
}
    `;

/**
 * __useGetCheckingValidationBqQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationBqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationBqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationBqQuery({
 *   variables: {
 *      BQExcelInput: // value for 'BQExcelInput'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetCheckingValidationBqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationBqQuery, GetCheckingValidationBqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationBqQuery, GetCheckingValidationBqQueryVariables>(GetCheckingValidationBqDocument, baseOptions);
      }
export function useGetCheckingValidationBqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationBqQuery, GetCheckingValidationBqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationBqQuery, GetCheckingValidationBqQueryVariables>(GetCheckingValidationBqDocument, baseOptions);
        }
export type GetCheckingValidationBqQueryHookResult = ReturnType<typeof useGetCheckingValidationBqQuery>;
export type GetCheckingValidationBqLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationBqLazyQuery>;
export type GetCheckingValidationBqQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationBqQuery, GetCheckingValidationBqQueryVariables>;
export const GetBqParentChildDocument = gql`
    query getBQParentChild($contractID: String!) {
  getBQParentChild(contractID: $contractID) {
    ID
    billNo
    pageNo
    itemNo
    uomID
    uom {
      ID
      name
      code
    }
    description
    quantity
    rate
    amount
    isLastNode
    childrenBq {
      ID
      billNo
      pageNo
      itemNo
      uomID
      uom {
        ID
        name
        code
      }
      description
      quantity
      rate
      amount
      isLastNode
    }
  }
}
    `;

/**
 * __useGetBqParentChildQuery__
 *
 * To run a query within a React component, call `useGetBqParentChildQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBqParentChildQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBqParentChildQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetBqParentChildQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBqParentChildQuery, GetBqParentChildQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBqParentChildQuery, GetBqParentChildQueryVariables>(GetBqParentChildDocument, baseOptions);
      }
export function useGetBqParentChildLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBqParentChildQuery, GetBqParentChildQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBqParentChildQuery, GetBqParentChildQueryVariables>(GetBqParentChildDocument, baseOptions);
        }
export type GetBqParentChildQueryHookResult = ReturnType<typeof useGetBqParentChildQuery>;
export type GetBqParentChildLazyQueryHookResult = ReturnType<typeof useGetBqParentChildLazyQuery>;
export type GetBqParentChildQueryResult = ApolloReactCommon.QueryResult<GetBqParentChildQuery, GetBqParentChildQueryVariables>;
export const GetBqWbsDocument = gql`
    query getBQWbs($contractID: String, $budgetID: String) {
  getWBS(contractID: $contractID) {
    ID
    name
    contractID
    parentWBSID
    isLastNode
    sequence
    startDate
    endDate
    bqAmt
    bqCount
    revenueDetail(budgetID: $budgetID) {
      ID
      quantity
      unitPrice
      trackQty
      uomID
      uom {
        ID
        code
        name
        commonStatus
      }
      revenueCategory {
        ID
        name
        description
      }
    }
  }
}
    `;

/**
 * __useGetBqWbsQuery__
 *
 * To run a query within a React component, call `useGetBqWbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBqWbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBqWbsQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      budgetID: // value for 'budgetID'
 *   },
 * });
 */
export function useGetBqWbsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBqWbsQuery, GetBqWbsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBqWbsQuery, GetBqWbsQueryVariables>(GetBqWbsDocument, baseOptions);
      }
export function useGetBqWbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBqWbsQuery, GetBqWbsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBqWbsQuery, GetBqWbsQueryVariables>(GetBqWbsDocument, baseOptions);
        }
export type GetBqWbsQueryHookResult = ReturnType<typeof useGetBqWbsQuery>;
export type GetBqWbsLazyQueryHookResult = ReturnType<typeof useGetBqWbsLazyQuery>;
export type GetBqWbsQueryResult = ApolloReactCommon.QueryResult<GetBqWbsQuery, GetBqWbsQueryVariables>;
export const GetBqWbsParentChildDocument = gql`
    query getBQWbsParentChild($contractID: String!, $wbsID: String!) {
  getBQWbsParentChild(wbsID: $wbsID, contractID: $contractID) {
    ID
    uomID
    uom {
      ID
      name
      code
    }
    quantity
    rate
    amount
    billNo
    pageNo
    itemNo
    description
    isLastNode
    childrenBq(wbsID: $wbsID) {
      ID
      uomID
      uom {
        ID
        name
        code
      }
      quantity
      rate
      amount
      billNo
      pageNo
      itemNo
      description
      isLastNode
    }
  }
}
    `;

/**
 * __useGetBqWbsParentChildQuery__
 *
 * To run a query within a React component, call `useGetBqWbsParentChildQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBqWbsParentChildQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBqWbsParentChildQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      wbsID: // value for 'wbsID'
 *   },
 * });
 */
export function useGetBqWbsParentChildQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBqWbsParentChildQuery, GetBqWbsParentChildQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBqWbsParentChildQuery, GetBqWbsParentChildQueryVariables>(GetBqWbsParentChildDocument, baseOptions);
      }
export function useGetBqWbsParentChildLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBqWbsParentChildQuery, GetBqWbsParentChildQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBqWbsParentChildQuery, GetBqWbsParentChildQueryVariables>(GetBqWbsParentChildDocument, baseOptions);
        }
export type GetBqWbsParentChildQueryHookResult = ReturnType<typeof useGetBqWbsParentChildQuery>;
export type GetBqWbsParentChildLazyQueryHookResult = ReturnType<typeof useGetBqWbsParentChildLazyQuery>;
export type GetBqWbsParentChildQueryResult = ApolloReactCommon.QueryResult<GetBqWbsParentChildQuery, GetBqWbsParentChildQueryVariables>;
export const AssignBqWbsAssignmentDocument = gql`
    mutation assignBqWbsAssignment($wbsID: String!, $bqID: [String!]!) {
  assignBqWbsAssignment(wbsID: $wbsID, bqID: $bqID)
}
    `;
export type AssignBqWbsAssignmentMutationFn = ApolloReactCommon.MutationFunction<AssignBqWbsAssignmentMutation, AssignBqWbsAssignmentMutationVariables>;

/**
 * __useAssignBqWbsAssignmentMutation__
 *
 * To run a mutation, you first call `useAssignBqWbsAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignBqWbsAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignBqWbsAssignmentMutation, { data, loading, error }] = useAssignBqWbsAssignmentMutation({
 *   variables: {
 *      wbsID: // value for 'wbsID'
 *      bqID: // value for 'bqID'
 *   },
 * });
 */
export function useAssignBqWbsAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignBqWbsAssignmentMutation, AssignBqWbsAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignBqWbsAssignmentMutation, AssignBqWbsAssignmentMutationVariables>(AssignBqWbsAssignmentDocument, baseOptions);
      }
export type AssignBqWbsAssignmentMutationHookResult = ReturnType<typeof useAssignBqWbsAssignmentMutation>;
export type AssignBqWbsAssignmentMutationResult = ApolloReactCommon.MutationResult<AssignBqWbsAssignmentMutation>;
export type AssignBqWbsAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignBqWbsAssignmentMutation, AssignBqWbsAssignmentMutationVariables>;
export const UnassignBqWbsAssignmentDocument = gql`
    mutation unassignBqWbsAssignment($wbsID: String!, $bqID: [String!]!) {
  unassignBqWbsAssignment(wbsID: $wbsID, bqID: $bqID)
}
    `;
export type UnassignBqWbsAssignmentMutationFn = ApolloReactCommon.MutationFunction<UnassignBqWbsAssignmentMutation, UnassignBqWbsAssignmentMutationVariables>;

/**
 * __useUnassignBqWbsAssignmentMutation__
 *
 * To run a mutation, you first call `useUnassignBqWbsAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignBqWbsAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignBqWbsAssignmentMutation, { data, loading, error }] = useUnassignBqWbsAssignmentMutation({
 *   variables: {
 *      wbsID: // value for 'wbsID'
 *      bqID: // value for 'bqID'
 *   },
 * });
 */
export function useUnassignBqWbsAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignBqWbsAssignmentMutation, UnassignBqWbsAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignBqWbsAssignmentMutation, UnassignBqWbsAssignmentMutationVariables>(UnassignBqWbsAssignmentDocument, baseOptions);
      }
export type UnassignBqWbsAssignmentMutationHookResult = ReturnType<typeof useUnassignBqWbsAssignmentMutation>;
export type UnassignBqWbsAssignmentMutationResult = ApolloReactCommon.MutationResult<UnassignBqWbsAssignmentMutation>;
export type UnassignBqWbsAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignBqWbsAssignmentMutation, UnassignBqWbsAssignmentMutationVariables>;
export const GetBudgetDocument = gql`
    query getBudget($ID: String, $contractID: String, $version: String, $budgetStatus: BudgetStatus) {
  getBudget(ID: $ID, contractID: $contractID, version: $version, budgetStatus: $budgetStatus) {
    createdTs
    createdBy
    ID
    budgetStatus
    contractID
    copyFromBudgetID
    copyFromBudget {
      ID
      name
    }
    name
    description
    reason
    version
    budgetStatus
    totalCostRevenue(contractID: $contractID)
    wbs {
      ID
      parentWBSID
      name
      startDate
      endDate
      isLastNode
    }
  }
}
    `;

/**
 * __useGetBudgetQuery__
 *
 * To run a query within a React component, call `useGetBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      version: // value for 'version'
 *      budgetStatus: // value for 'budgetStatus'
 *   },
 * });
 */
export function useGetBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBudgetQuery, GetBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBudgetQuery, GetBudgetQueryVariables>(GetBudgetDocument, baseOptions);
      }
export function useGetBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBudgetQuery, GetBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBudgetQuery, GetBudgetQueryVariables>(GetBudgetDocument, baseOptions);
        }
export type GetBudgetQueryHookResult = ReturnType<typeof useGetBudgetQuery>;
export type GetBudgetLazyQueryHookResult = ReturnType<typeof useGetBudgetLazyQuery>;
export type GetBudgetQueryResult = ApolloReactCommon.QueryResult<GetBudgetQuery, GetBudgetQueryVariables>;
export const GetBudgetDetailDocument = gql`
    query getBudgetDetail($ID: String, $contractID: String, $version: String, $budgetStatus: BudgetStatus) {
  getBudget(ID: $ID, contractID: $contractID, version: $version, budgetStatus: $budgetStatus) {
    createdTs
    createdBy
    ID
    contractID
    name
    description
    reason
    version
    budgetStatus
    totalCostRevenue(contractID: $contractID)
  }
}
    `;

/**
 * __useGetBudgetDetailQuery__
 *
 * To run a query within a React component, call `useGetBudgetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      version: // value for 'version'
 *      budgetStatus: // value for 'budgetStatus'
 *   },
 * });
 */
export function useGetBudgetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBudgetDetailQuery, GetBudgetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBudgetDetailQuery, GetBudgetDetailQueryVariables>(GetBudgetDetailDocument, baseOptions);
      }
export function useGetBudgetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBudgetDetailQuery, GetBudgetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBudgetDetailQuery, GetBudgetDetailQueryVariables>(GetBudgetDetailDocument, baseOptions);
        }
export type GetBudgetDetailQueryHookResult = ReturnType<typeof useGetBudgetDetailQuery>;
export type GetBudgetDetailLazyQueryHookResult = ReturnType<typeof useGetBudgetDetailLazyQuery>;
export type GetBudgetDetailQueryResult = ApolloReactCommon.QueryResult<GetBudgetDetailQuery, GetBudgetDetailQueryVariables>;
export const GetBudgetRevisionFormDocument = gql`
    query getBudgetRevisionForm($ID: String, $contractID: String, $version: String, $budgetStatus: BudgetStatus) {
  getBudget(contractID: $contractID, ID: $ID) {
    ID
    name
    description
    reason
    budgetStatus
    copyFromBudgetID
    copyFromBudget {
      ID
      name
    }
  }
  getAllBudget: getBudget(contractID: $contractID, version: $version, budgetStatus: $budgetStatus) {
    ID
    name
    description
    reason
    budgetStatus
    copyFromBudgetID
    copyFromBudget {
      ID
      name
    }
  }
  getBudgetRevision: getBudget(contractID: $contractID, version: "REVISION") {
    ID
    name
    description
    reason
    budgetStatus
    copyFromBudgetID
    copyFromBudget {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetBudgetRevisionFormQuery__
 *
 * To run a query within a React component, call `useGetBudgetRevisionFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetRevisionFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetRevisionFormQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      version: // value for 'version'
 *      budgetStatus: // value for 'budgetStatus'
 *   },
 * });
 */
export function useGetBudgetRevisionFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBudgetRevisionFormQuery, GetBudgetRevisionFormQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBudgetRevisionFormQuery, GetBudgetRevisionFormQueryVariables>(GetBudgetRevisionFormDocument, baseOptions);
      }
export function useGetBudgetRevisionFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBudgetRevisionFormQuery, GetBudgetRevisionFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBudgetRevisionFormQuery, GetBudgetRevisionFormQueryVariables>(GetBudgetRevisionFormDocument, baseOptions);
        }
export type GetBudgetRevisionFormQueryHookResult = ReturnType<typeof useGetBudgetRevisionFormQuery>;
export type GetBudgetRevisionFormLazyQueryHookResult = ReturnType<typeof useGetBudgetRevisionFormLazyQuery>;
export type GetBudgetRevisionFormQueryResult = ApolloReactCommon.QueryResult<GetBudgetRevisionFormQuery, GetBudgetRevisionFormQueryVariables>;
export const CopyBudgetContractDocument = gql`
    mutation copyBudgetContract($wbsIDs: [String!]!, $input: CopyBudgetInput!) {
  copyBudgetContract(wbsIDs: $wbsIDs, input: $input)
}
    `;
export type CopyBudgetContractMutationFn = ApolloReactCommon.MutationFunction<CopyBudgetContractMutation, CopyBudgetContractMutationVariables>;

/**
 * __useCopyBudgetContractMutation__
 *
 * To run a mutation, you first call `useCopyBudgetContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyBudgetContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyBudgetContractMutation, { data, loading, error }] = useCopyBudgetContractMutation({
 *   variables: {
 *      wbsIDs: // value for 'wbsIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyBudgetContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopyBudgetContractMutation, CopyBudgetContractMutationVariables>) {
        return ApolloReactHooks.useMutation<CopyBudgetContractMutation, CopyBudgetContractMutationVariables>(CopyBudgetContractDocument, baseOptions);
      }
export type CopyBudgetContractMutationHookResult = ReturnType<typeof useCopyBudgetContractMutation>;
export type CopyBudgetContractMutationResult = ApolloReactCommon.MutationResult<CopyBudgetContractMutation>;
export type CopyBudgetContractMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyBudgetContractMutation, CopyBudgetContractMutationVariables>;
export const SubmitBudgetDocument = gql`
    mutation submitBudget($input: WbsBudgetSubmitApproveInput!) {
  submitBudget(input: $input) {
    ID
    budgetStatus
  }
}
    `;
export type SubmitBudgetMutationFn = ApolloReactCommon.MutationFunction<SubmitBudgetMutation, SubmitBudgetMutationVariables>;

/**
 * __useSubmitBudgetMutation__
 *
 * To run a mutation, you first call `useSubmitBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBudgetMutation, { data, loading, error }] = useSubmitBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitBudgetMutation, SubmitBudgetMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitBudgetMutation, SubmitBudgetMutationVariables>(SubmitBudgetDocument, baseOptions);
      }
export type SubmitBudgetMutationHookResult = ReturnType<typeof useSubmitBudgetMutation>;
export type SubmitBudgetMutationResult = ApolloReactCommon.MutationResult<SubmitBudgetMutation>;
export type SubmitBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitBudgetMutation, SubmitBudgetMutationVariables>;
export const DeleteBudgetDocument = gql`
    mutation deleteBudget($ID: String!) {
  deleteBudget(ID: $ID)
}
    `;
export type DeleteBudgetMutationFn = ApolloReactCommon.MutationFunction<DeleteBudgetMutation, DeleteBudgetMutationVariables>;

/**
 * __useDeleteBudgetMutation__
 *
 * To run a mutation, you first call `useDeleteBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBudgetMutation, { data, loading, error }] = useDeleteBudgetMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBudgetMutation, DeleteBudgetMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBudgetMutation, DeleteBudgetMutationVariables>(DeleteBudgetDocument, baseOptions);
      }
export type DeleteBudgetMutationHookResult = ReturnType<typeof useDeleteBudgetMutation>;
export type DeleteBudgetMutationResult = ApolloReactCommon.MutationResult<DeleteBudgetMutation>;
export type DeleteBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBudgetMutation, DeleteBudgetMutationVariables>;
export const CreateBudgetRevisionDocument = gql`
    mutation createBudgetRevision($input: WbsBudgetRevisionInput!) {
  createBudgetRevision(input: $input) {
    ID
    copyFromBudgetID
    name
    description
    reason
  }
}
    `;
export type CreateBudgetRevisionMutationFn = ApolloReactCommon.MutationFunction<CreateBudgetRevisionMutation, CreateBudgetRevisionMutationVariables>;

/**
 * __useCreateBudgetRevisionMutation__
 *
 * To run a mutation, you first call `useCreateBudgetRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudgetRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudgetRevisionMutation, { data, loading, error }] = useCreateBudgetRevisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBudgetRevisionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBudgetRevisionMutation, CreateBudgetRevisionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBudgetRevisionMutation, CreateBudgetRevisionMutationVariables>(CreateBudgetRevisionDocument, baseOptions);
      }
export type CreateBudgetRevisionMutationHookResult = ReturnType<typeof useCreateBudgetRevisionMutation>;
export type CreateBudgetRevisionMutationResult = ApolloReactCommon.MutationResult<CreateBudgetRevisionMutation>;
export type CreateBudgetRevisionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBudgetRevisionMutation, CreateBudgetRevisionMutationVariables>;
export const SwitchBudgetDocument = gql`
    mutation switchBudget($ID: String!, $selectedBudgetID: String!) {
  switchBudget(ID: $ID, selectedBudgetID: $selectedBudgetID)
}
    `;
export type SwitchBudgetMutationFn = ApolloReactCommon.MutationFunction<SwitchBudgetMutation, SwitchBudgetMutationVariables>;

/**
 * __useSwitchBudgetMutation__
 *
 * To run a mutation, you first call `useSwitchBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchBudgetMutation, { data, loading, error }] = useSwitchBudgetMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      selectedBudgetID: // value for 'selectedBudgetID'
 *   },
 * });
 */
export function useSwitchBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SwitchBudgetMutation, SwitchBudgetMutationVariables>) {
        return ApolloReactHooks.useMutation<SwitchBudgetMutation, SwitchBudgetMutationVariables>(SwitchBudgetDocument, baseOptions);
      }
export type SwitchBudgetMutationHookResult = ReturnType<typeof useSwitchBudgetMutation>;
export type SwitchBudgetMutationResult = ApolloReactCommon.MutationResult<SwitchBudgetMutation>;
export type SwitchBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<SwitchBudgetMutation, SwitchBudgetMutationVariables>;
export const GetContractDocument = gql`
    query getContract($ID: String, $customerID: String, $companyID: String, $orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContract(ID: $ID, customerID: $customerID, companyID: $companyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    title
    pdfDocumentTitle
    accountID
    contractNo
    customerID
    companyID
    description
    bankAccountID
    implementWbs
    latestBudgetID
    wbs {
      ID
      name
    }
    bankAccount {
      ID
      name
      branch
      accountNo
      swiftCode
    }
    site {
      ID
      contractID
      name
      address
      mainSite
    }
    contractDate
    contractSum
    voSum
    revisedContractSum
    taxSchemeID
    startDate
    endDate
    progressRetentionPerc
    maxRetentionPerc
    targetProfitPerc
    cmgdDate
    cpcDate
    dlpDate
    ladAmt
    ladRate
    creditTerm
    latestRunNum
    docNumChecking
    contractPOCounts
    contractStatus
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      address
      contactNo
    }
    subcontractTenderDetail {
      ID
      title
    }
    subcontractSummary
    subcontractDetail {
      ID
      title
      accountID
      contractID
      subcontractorID
      costCategoryID
      subcontractNo
      description
      subcontractDate
      taxSchemeID
      subcontractSum
      revisedSubcontractSum
      startDate
      endDate
      progressRetentionPerc
      maxRetentionPerc
      targetProfitPerc
      creditTerm
      subcontractorDetail {
        ID
        name
        associatedStatus
      }
      subcontractStatus
    }
    subcontractSummary
    contactPerson
    contact {
      ID
      name
      email
      designation
      contactNo
    }
    closureRemarks
    closureDate
    modBy
    extendedDate
    extensionDate
    taxDetail {
      ID
      code
      description
      taxCategory
      taxTypeID
      taxClass
      createdTs
      commonStatus
      latestTax {
        taxRate
        taxDate
      }
      taxEffective {
        date
        taxRate
        createdTs
      }
    }
    budgetChecker
  }
}
    `;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
      }
export function useGetContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
        }
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractQueryResult = ApolloReactCommon.QueryResult<GetContractQuery, GetContractQueryVariables>;
export const GetContractListingDocument = gql`
    query getContractListing($customerID: String, $companyID: String, $orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContract(customerID: $customerID, companyID: $companyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    accountID
    customerID
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      address
      contactNo
    }
    companyID
    title
    pdfDocumentTitle
    contractNo
    description
    contractDate
    contractSum
    voSum
    revisedContractSum
    startDate
    endDate
    contractStatus
  }
}
    `;

/**
 * __useGetContractListingQuery__
 *
 * To run a query within a React component, call `useGetContractListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractListingQuery({
 *   variables: {
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractListingQuery, GetContractListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractListingQuery, GetContractListingQueryVariables>(GetContractListingDocument, baseOptions);
      }
export function useGetContractListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractListingQuery, GetContractListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractListingQuery, GetContractListingQueryVariables>(GetContractListingDocument, baseOptions);
        }
export type GetContractListingQueryHookResult = ReturnType<typeof useGetContractListingQuery>;
export type GetContractListingLazyQueryHookResult = ReturnType<typeof useGetContractListingLazyQuery>;
export type GetContractListingQueryResult = ApolloReactCommon.QueryResult<GetContractListingQuery, GetContractListingQueryVariables>;
export const GetContractSubmenuDocument = gql`
    query getContractSubmenu($ID: String, $companyID: String, $orderByAsc: String) {
  getContract(ID: $ID, companyID: $companyID) {
    ID
    title
    accountID
    contractNo
    customerID
    customerDetail {
      ID
      name
    }
    companyID
    contractStatus
    contractDate
    startDate
    endDate
    cmgdDate
    cpcDate
    dlpDate
    extensionDate
    contractSum
    revisedContractSum
    implementWbs
    closureDate
    closureRemarks
  }
  getAllContract: getContract(companyID: $companyID, orderByAsc: $orderByAsc) {
    ID
    title
    accountID
    companyID
    contractNo
    customerID
    originalBudget {
      ID
      budgetStatus
      totalCostRevenue
    }
  }
}
    `;

/**
 * __useGetContractSubmenuQuery__
 *
 * To run a query within a React component, call `useGetContractSubmenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSubmenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSubmenuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetContractSubmenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>(GetContractSubmenuDocument, baseOptions);
      }
export function useGetContractSubmenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>(GetContractSubmenuDocument, baseOptions);
        }
export type GetContractSubmenuQueryHookResult = ReturnType<typeof useGetContractSubmenuQuery>;
export type GetContractSubmenuLazyQueryHookResult = ReturnType<typeof useGetContractSubmenuLazyQuery>;
export type GetContractSubmenuQueryResult = ApolloReactCommon.QueryResult<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>;
export const CreateContractDocument = gql`
    mutation createContract($input: ContractInput!, $siteInput: [SiteInput!]!) {
  createContract(input: $input, siteInput: $siteInput) {
    accountID
    customerID
    taxSchemeID
    contractNo
    title
    description
    contractSum
    startDate
    endDate
    progressRetentionPerc
    maxRetentionPerc
    cmgdDate
    cpcDate
    creditTerm
    contractDate
  }
}
    `;
export type CreateContractMutationFn = ApolloReactCommon.MutationFunction<CreateContractMutation, CreateContractMutationVariables>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useCreateContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractMutation, CreateContractMutationVariables>(CreateContractDocument, baseOptions);
      }
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = ApolloReactCommon.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractMutation, CreateContractMutationVariables>;
export const UpdateContractDocument = gql`
    mutation updateContract($input: ContractInput!) {
  updateContract(input: $input)
}
    `;
export type UpdateContractMutationFn = ApolloReactCommon.MutationFunction<UpdateContractMutation, UpdateContractMutationVariables>;

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractMutation, UpdateContractMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(UpdateContractDocument, baseOptions);
      }
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>;
export type UpdateContractMutationResult = ApolloReactCommon.MutationResult<UpdateContractMutation>;
export type UpdateContractMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractMutation, UpdateContractMutationVariables>;
export const GetContractGuaranteeDocument = gql`
    query getContractGuarantee($ID: String, $contractID: String!, $orderByAsc: String) {
  getContractGuarantee(ID: $ID, contractID: $contractID, orderByAsc: $orderByAsc) {
    ID
    modBy
    accountID
    contractID
    docRef
    issuer
    guaranteeTypeID
    description
    docAmt
    startDate
    expiryDate
    collateralAmt
    remarks
    cancellationDate
    guaranteeStatus
    guaranteeType {
      name
    }
  }
  contractGuaranteeInfo(contractID: $contractID)
}
    `;

/**
 * __useGetContractGuaranteeQuery__
 *
 * To run a query within a React component, call `useGetContractGuaranteeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractGuaranteeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractGuaranteeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetContractGuaranteeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>(GetContractGuaranteeDocument, baseOptions);
      }
export function useGetContractGuaranteeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>(GetContractGuaranteeDocument, baseOptions);
        }
export type GetContractGuaranteeQueryHookResult = ReturnType<typeof useGetContractGuaranteeQuery>;
export type GetContractGuaranteeLazyQueryHookResult = ReturnType<typeof useGetContractGuaranteeLazyQuery>;
export type GetContractGuaranteeQueryResult = ApolloReactCommon.QueryResult<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>;
export const CreateContractGuaranteeDocument = gql`
    mutation createContractGuarantee($input: ContractGuaranteeInput!) {
  createContractGuarantee(input: $input) {
    ID
  }
}
    `;
export type CreateContractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>;

/**
 * __useCreateContractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCreateContractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractGuaranteeMutation, { data, loading, error }] = useCreateContractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>(CreateContractGuaranteeDocument, baseOptions);
      }
export type CreateContractGuaranteeMutationHookResult = ReturnType<typeof useCreateContractGuaranteeMutation>;
export type CreateContractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CreateContractGuaranteeMutation>;
export type CreateContractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>;
export const UpdateContractGuaranteeDocument = gql`
    mutation updateContractGuarantee($input: ContractGuaranteeInput!) {
  updateContractGuarantee(input: $input) {
    ID
  }
}
    `;
export type UpdateContractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>;

/**
 * __useUpdateContractGuaranteeMutation__
 *
 * To run a mutation, you first call `useUpdateContractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractGuaranteeMutation, { data, loading, error }] = useUpdateContractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>(UpdateContractGuaranteeDocument, baseOptions);
      }
export type UpdateContractGuaranteeMutationHookResult = ReturnType<typeof useUpdateContractGuaranteeMutation>;
export type UpdateContractGuaranteeMutationResult = ApolloReactCommon.MutationResult<UpdateContractGuaranteeMutation>;
export type UpdateContractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>;
export const GetContractInsuranceDocument = gql`
    query getContractInsurance($ID: String, $contractID: String!) {
  getContractInsurance(ID: $ID, contractID: $contractID) {
    ID
    modBy
    accountID
    contractID
    docRef
    issuer
    insuranceTypeID
    description
    docAmt
    startDate
    expiryDate
    remarks
    cancellationDate
    insuranceStatus
    insuranceType {
      name
    }
  }
  contractInsuranceInfo(contractID: $contractID)
}
    `;

/**
 * __useGetContractInsuranceQuery__
 *
 * To run a query within a React component, call `useGetContractInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractInsuranceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractInsuranceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>(GetContractInsuranceDocument, baseOptions);
      }
export function useGetContractInsuranceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>(GetContractInsuranceDocument, baseOptions);
        }
export type GetContractInsuranceQueryHookResult = ReturnType<typeof useGetContractInsuranceQuery>;
export type GetContractInsuranceLazyQueryHookResult = ReturnType<typeof useGetContractInsuranceLazyQuery>;
export type GetContractInsuranceQueryResult = ApolloReactCommon.QueryResult<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>;
export const CreateContractInsuranceDocument = gql`
    mutation createContractInsurance($input: ContractInsuranceInput!) {
  createContractInsurance(input: $input) {
    ID
  }
}
    `;
export type CreateContractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>;

/**
 * __useCreateContractInsuranceMutation__
 *
 * To run a mutation, you first call `useCreateContractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractInsuranceMutation, { data, loading, error }] = useCreateContractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>(CreateContractInsuranceDocument, baseOptions);
      }
export type CreateContractInsuranceMutationHookResult = ReturnType<typeof useCreateContractInsuranceMutation>;
export type CreateContractInsuranceMutationResult = ApolloReactCommon.MutationResult<CreateContractInsuranceMutation>;
export type CreateContractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>;
export const UpdateContractInsuranceDocument = gql`
    mutation updateContractInsurance($input: ContractInsuranceInput!) {
  updateContractInsurance(input: $input) {
    ID
  }
}
    `;
export type UpdateContractInsuranceMutationFn = ApolloReactCommon.MutationFunction<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>;

/**
 * __useUpdateContractInsuranceMutation__
 *
 * To run a mutation, you first call `useUpdateContractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractInsuranceMutation, { data, loading, error }] = useUpdateContractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>(UpdateContractInsuranceDocument, baseOptions);
      }
export type UpdateContractInsuranceMutationHookResult = ReturnType<typeof useUpdateContractInsuranceMutation>;
export type UpdateContractInsuranceMutationResult = ApolloReactCommon.MutationResult<UpdateContractInsuranceMutation>;
export type UpdateContractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>;
export const ActionOnContractStatusDocument = gql`
    mutation actionOnContractStatus($input: ContractActionInput!, $ID: String!) {
  actionOnContractStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnContractStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>;

/**
 * __useActionOnContractStatusMutation__
 *
 * To run a mutation, you first call `useActionOnContractStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnContractStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnContractStatusMutation, { data, loading, error }] = useActionOnContractStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnContractStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>(ActionOnContractStatusDocument, baseOptions);
      }
export type ActionOnContractStatusMutationHookResult = ReturnType<typeof useActionOnContractStatusMutation>;
export type ActionOnContractStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnContractStatusMutation>;
export type ActionOnContractStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>;
export const CreateContractExtensionDateDocument = gql`
    mutation createContractExtensionDate($input: ContractExtensionInput!, $ID: String!) {
  createContractExtensionDate(input: $input, ID: $ID)
}
    `;
export type CreateContractExtensionDateMutationFn = ApolloReactCommon.MutationFunction<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>;

/**
 * __useCreateContractExtensionDateMutation__
 *
 * To run a mutation, you first call `useCreateContractExtensionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractExtensionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractExtensionDateMutation, { data, loading, error }] = useCreateContractExtensionDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateContractExtensionDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>(CreateContractExtensionDateDocument, baseOptions);
      }
export type CreateContractExtensionDateMutationHookResult = ReturnType<typeof useCreateContractExtensionDateMutation>;
export type CreateContractExtensionDateMutationResult = ApolloReactCommon.MutationResult<CreateContractExtensionDateMutation>;
export type CreateContractExtensionDateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>;
export const CancelContractGuaranteeDocument = gql`
    mutation cancelContractGuarantee($input: ContractGuaranteeCancellationInput!) {
  cancelContractGuarantee(input: $input)
}
    `;
export type CancelContractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>;

/**
 * __useCancelContractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCancelContractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractGuaranteeMutation, { data, loading, error }] = useCancelContractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>(CancelContractGuaranteeDocument, baseOptions);
      }
export type CancelContractGuaranteeMutationHookResult = ReturnType<typeof useCancelContractGuaranteeMutation>;
export type CancelContractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CancelContractGuaranteeMutation>;
export type CancelContractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>;
export const CancelContractInsuranceDocument = gql`
    mutation cancelContractInsurance($input: ContractInsuranceCancellationInput!) {
  cancelContractInsurance(input: $input)
}
    `;
export type CancelContractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>;

/**
 * __useCancelContractInsuranceMutation__
 *
 * To run a mutation, you first call `useCancelContractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractInsuranceMutation, { data, loading, error }] = useCancelContractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>(CancelContractInsuranceDocument, baseOptions);
      }
export type CancelContractInsuranceMutationHookResult = ReturnType<typeof useCancelContractInsuranceMutation>;
export type CancelContractInsuranceMutationResult = ApolloReactCommon.MutationResult<CancelContractInsuranceMutation>;
export type CancelContractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>;
export const GetContractSummaryDocument = gql`
    query getContractSummary($contractID: String!, $type: String) {
  contractInfo(contractID: $contractID) {
    contractSum
    contractDate
  }
  wbsBudgetInfo(contractID: $contractID)
  getWbsTotalCostRevenue(contractID: $contractID)
  wbsBudgetRevisionInfo(contractID: $contractID)
  utilizedBudgetInfo(contractID: $contractID)
  budgetTransferInfo(contractID: $contractID)
  contractGuaranteeInfo(contractID: $contractID)
  contractInsuranceInfo(contractID: $contractID)
  getVOHealthIndex(contractID: $contractID) {
    conVOOmission
    conVOSum
    totalVO
    totalPendingVO
    totalSubVO
    totalPendingSubVO
  }
  outstandingAdvances(contractID: $contractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  outstandingClaims(contractID: $contractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  submittedVONotification(contractID: $contractID)
  lastExtensionDate(contractID: $contractID)
  contractClosureDate(contractID: $contractID)
  retention(contractID: $contractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
  contractVoCalculation(contractID: $contractID)
  totalDigitalDocuments(associateID: $contractID, type: $type)
  conAuthDate
  conAuthCount(contractID: $contractID)
}
    `;

/**
 * __useGetContractSummaryQuery__
 *
 * To run a query within a React component, call `useGetContractSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetContractSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSummaryQuery, GetContractSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSummaryQuery, GetContractSummaryQueryVariables>(GetContractSummaryDocument, baseOptions);
      }
export function useGetContractSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSummaryQuery, GetContractSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSummaryQuery, GetContractSummaryQueryVariables>(GetContractSummaryDocument, baseOptions);
        }
export type GetContractSummaryQueryHookResult = ReturnType<typeof useGetContractSummaryQuery>;
export type GetContractSummaryLazyQueryHookResult = ReturnType<typeof useGetContractSummaryLazyQuery>;
export type GetContractSummaryQueryResult = ApolloReactCommon.QueryResult<GetContractSummaryQuery, GetContractSummaryQueryVariables>;
export const CreateContractCertificateDocument = gql`
    mutation createContractCertificate($input: ContractCertificateInput!, $ID: String!) {
  createContractCertificate(input: $input, ID: $ID)
}
    `;
export type CreateContractCertificateMutationFn = ApolloReactCommon.MutationFunction<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>;

/**
 * __useCreateContractCertificateMutation__
 *
 * To run a mutation, you first call `useCreateContractCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractCertificateMutation, { data, loading, error }] = useCreateContractCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateContractCertificateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>(CreateContractCertificateDocument, baseOptions);
      }
export type CreateContractCertificateMutationHookResult = ReturnType<typeof useCreateContractCertificateMutation>;
export type CreateContractCertificateMutationResult = ApolloReactCommon.MutationResult<CreateContractCertificateMutation>;
export type CreateContractCertificateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>;
export const GetContractExtensionDocument = gql`
    query getContractExtension($ID: String!, $customerID: String, $orderByAsc: String, $orderByDesc: String) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
    accountID
    contractNo
    customerID
    description
    contractDate
    contractSum
    revisedContractSum
    taxSchemeID
    startDate
    endDate
    creditTerm
    contractStatus
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      contactNo
    }
    extensionDate
  }
  lastExtensionDate(contractID: $ID)
}
    `;

/**
 * __useGetContractExtensionQuery__
 *
 * To run a query within a React component, call `useGetContractExtensionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractExtensionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractExtensionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractExtensionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractExtensionQuery, GetContractExtensionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractExtensionQuery, GetContractExtensionQueryVariables>(GetContractExtensionDocument, baseOptions);
      }
export function useGetContractExtensionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractExtensionQuery, GetContractExtensionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractExtensionQuery, GetContractExtensionQueryVariables>(GetContractExtensionDocument, baseOptions);
        }
export type GetContractExtensionQueryHookResult = ReturnType<typeof useGetContractExtensionQuery>;
export type GetContractExtensionLazyQueryHookResult = ReturnType<typeof useGetContractExtensionLazyQuery>;
export type GetContractExtensionQueryResult = ApolloReactCommon.QueryResult<GetContractExtensionQuery, GetContractExtensionQueryVariables>;
export const GetContractTitleDocument = gql`
    query getContractTitle($ID: String, $companyID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String, $take: Float) {
  getContract(ID: $ID, companyID: $companyID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take) {
    ID
    companyID
    company {
      CompanyID
      Name
    }
    customerID
    customerDetail {
      name
    }
    site {
      ID
      name
      address
    }
    title
    pdfDocumentTitle
    contractNo
    contractDate
    startDate
    endDate
    cmgdDate
    cpcDate
    dlpDate
    revisedContractSum
    contractSum
    implementWbs
    contractStatus
    budgetChecker
  }
}
    `;

/**
 * __useGetContractTitleQuery__
 *
 * To run a query within a React component, call `useGetContractTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetContractTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractTitleQuery, GetContractTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractTitleQuery, GetContractTitleQueryVariables>(GetContractTitleDocument, baseOptions);
      }
export function useGetContractTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractTitleQuery, GetContractTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractTitleQuery, GetContractTitleQueryVariables>(GetContractTitleDocument, baseOptions);
        }
export type GetContractTitleQueryHookResult = ReturnType<typeof useGetContractTitleQuery>;
export type GetContractTitleLazyQueryHookResult = ReturnType<typeof useGetContractTitleLazyQuery>;
export type GetContractTitleQueryResult = ApolloReactCommon.QueryResult<GetContractTitleQuery, GetContractTitleQueryVariables>;
export const GetContractWithSiteDocument = gql`
    query getContractWithSite($ID: String, $companyID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String, $take: Float) {
  getContract(ID: $ID, companyID: $companyID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take) {
    ID
    companyID
    customerID
    customerDetail {
      name
    }
    title
    contractNo
    contractDate
    startDate
    endDate
    cmgdDate
    cpcDate
    dlpDate
    contractSum
    revisedContractSum
    implementWbs
    contractStatus
    taxSchemeID
    site {
      ID
      contractID
      name
      address
      mainSite
    }
    budgetChecker
    taxSchemeID
    contact {
      ID
      contactNo
      designation
      email
      name
    }
  }
}
    `;

/**
 * __useGetContractWithSiteQuery__
 *
 * To run a query within a React component, call `useGetContractWithSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractWithSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractWithSiteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetContractWithSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractWithSiteQuery, GetContractWithSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractWithSiteQuery, GetContractWithSiteQueryVariables>(GetContractWithSiteDocument, baseOptions);
      }
export function useGetContractWithSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractWithSiteQuery, GetContractWithSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractWithSiteQuery, GetContractWithSiteQueryVariables>(GetContractWithSiteDocument, baseOptions);
        }
export type GetContractWithSiteQueryHookResult = ReturnType<typeof useGetContractWithSiteQuery>;
export type GetContractWithSiteLazyQueryHookResult = ReturnType<typeof useGetContractWithSiteLazyQuery>;
export type GetContractWithSiteQueryResult = ApolloReactCommon.QueryResult<GetContractWithSiteQuery, GetContractWithSiteQueryVariables>;
export const GetContractTitleWoPermDocument = gql`
    query getContractTitleWoPerm($ID: String, $companyID: String, $customerID: String, $orderByAsc: String, $limit: Float) {
  getContractWithoutPerm(ID: $ID, companyID: $companyID, customerID: $customerID, orderByAsc: $orderByAsc, limit: $limit) {
    ID
    companyID
    customerID
    title
    contractNo
    contractDate
    cmgdDate
    cpcDate
    dlpDate
    revisedContractSum
    contractSum
    contractStatus
    customerDetail {
      ID
      name
    }
    site {
      ID
      name
      address
    }
  }
}
    `;

/**
 * __useGetContractTitleWoPermQuery__
 *
 * To run a query within a React component, call `useGetContractTitleWoPermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTitleWoPermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTitleWoPermQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetContractTitleWoPermQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractTitleWoPermQuery, GetContractTitleWoPermQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractTitleWoPermQuery, GetContractTitleWoPermQueryVariables>(GetContractTitleWoPermDocument, baseOptions);
      }
export function useGetContractTitleWoPermLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractTitleWoPermQuery, GetContractTitleWoPermQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractTitleWoPermQuery, GetContractTitleWoPermQueryVariables>(GetContractTitleWoPermDocument, baseOptions);
        }
export type GetContractTitleWoPermQueryHookResult = ReturnType<typeof useGetContractTitleWoPermQuery>;
export type GetContractTitleWoPermLazyQueryHookResult = ReturnType<typeof useGetContractTitleWoPermLazyQuery>;
export type GetContractTitleWoPermQueryResult = ApolloReactCommon.QueryResult<GetContractTitleWoPermQuery, GetContractTitleWoPermQueryVariables>;
export const InsertUpdateSiteDocument = gql`
    mutation insertUpdateSite($siteInput: [SiteInput!]!, $contractID: String!) {
  insertUpdateSite(siteInput: $siteInput, contractID: $contractID)
}
    `;
export type InsertUpdateSiteMutationFn = ApolloReactCommon.MutationFunction<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>;

/**
 * __useInsertUpdateSiteMutation__
 *
 * To run a mutation, you first call `useInsertUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUpdateSiteMutation, { data, loading, error }] = useInsertUpdateSiteMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useInsertUpdateSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>(InsertUpdateSiteDocument, baseOptions);
      }
export type InsertUpdateSiteMutationHookResult = ReturnType<typeof useInsertUpdateSiteMutation>;
export type InsertUpdateSiteMutationResult = ApolloReactCommon.MutationResult<InsertUpdateSiteMutation>;
export type InsertUpdateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>;
export const ContractCheckerDocument = gql`
    query contractChecker($subcontractID: String, $contractID: String) {
  claimChecking(subcontractID: $subcontractID, contractID: $contractID)
  voChecking(subcontractID: $subcontractID, contractID: $contractID)
  getWbsBudget(contractID: $contractID) {
    ID
    contractID
    budgetStatus
  }
}
    `;

/**
 * __useContractCheckerQuery__
 *
 * To run a query within a React component, call `useContractCheckerQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractCheckerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractCheckerQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useContractCheckerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContractCheckerQuery, ContractCheckerQueryVariables>) {
        return ApolloReactHooks.useQuery<ContractCheckerQuery, ContractCheckerQueryVariables>(ContractCheckerDocument, baseOptions);
      }
export function useContractCheckerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractCheckerQuery, ContractCheckerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContractCheckerQuery, ContractCheckerQueryVariables>(ContractCheckerDocument, baseOptions);
        }
export type ContractCheckerQueryHookResult = ReturnType<typeof useContractCheckerQuery>;
export type ContractCheckerLazyQueryHookResult = ReturnType<typeof useContractCheckerLazyQuery>;
export type ContractCheckerQueryResult = ApolloReactCommon.QueryResult<ContractCheckerQuery, ContractCheckerQueryVariables>;
export const GetCustomerForContractDocument = gql`
    query getCustomerForContract($associatedStatus: AssociatedStatus, $orderByAsc: String) {
  getCustomer(associatedStatus: $associatedStatus, orderByAsc: $orderByAsc) {
    name
    ID
    associatedStatus
    customerCompanyAssignment {
      ID
      companyID
      customerID
    }
  }
}
    `;

/**
 * __useGetCustomerForContractQuery__
 *
 * To run a query within a React component, call `useGetCustomerForContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerForContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerForContractQuery({
 *   variables: {
 *      associatedStatus: // value for 'associatedStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCustomerForContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerForContractQuery, GetCustomerForContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerForContractQuery, GetCustomerForContractQueryVariables>(GetCustomerForContractDocument, baseOptions);
      }
export function useGetCustomerForContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerForContractQuery, GetCustomerForContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerForContractQuery, GetCustomerForContractQueryVariables>(GetCustomerForContractDocument, baseOptions);
        }
export type GetCustomerForContractQueryHookResult = ReturnType<typeof useGetCustomerForContractQuery>;
export type GetCustomerForContractLazyQueryHookResult = ReturnType<typeof useGetCustomerForContractLazyQuery>;
export type GetCustomerForContractQueryResult = ApolloReactCommon.QueryResult<GetCustomerForContractQuery, GetCustomerForContractQueryVariables>;
export const GetContractTitleForReportDocument = gql`
    query getContractTitleForReport($companyID: String, $customerID: String, $orderByAsc: String) {
  getContract(companyID: $companyID, customerID: $customerID, orderByAsc: $orderByAsc) {
    ID
    title
    companyID
    customerID
    taxSchemeID
    implementWbs
  }
}
    `;

/**
 * __useGetContractTitleForReportQuery__
 *
 * To run a query within a React component, call `useGetContractTitleForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTitleForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTitleForReportQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetContractTitleForReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractTitleForReportQuery, GetContractTitleForReportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractTitleForReportQuery, GetContractTitleForReportQueryVariables>(GetContractTitleForReportDocument, baseOptions);
      }
export function useGetContractTitleForReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractTitleForReportQuery, GetContractTitleForReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractTitleForReportQuery, GetContractTitleForReportQueryVariables>(GetContractTitleForReportDocument, baseOptions);
        }
export type GetContractTitleForReportQueryHookResult = ReturnType<typeof useGetContractTitleForReportQuery>;
export type GetContractTitleForReportLazyQueryHookResult = ReturnType<typeof useGetContractTitleForReportLazyQuery>;
export type GetContractTitleForReportQueryResult = ApolloReactCommon.QueryResult<GetContractTitleForReportQuery, GetContractTitleForReportQueryVariables>;
export const GetApprovalPolicyWithContractDateDocument = gql`
    query getApprovalPolicyWithContractDate($parameter: String!, $contractID: String!) {
  approvalPolicyWithContractDate(parameter: $parameter, contractID: $contractID)
}
    `;

/**
 * __useGetApprovalPolicyWithContractDateQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyWithContractDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyWithContractDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyWithContractDateQuery({
 *   variables: {
 *      parameter: // value for 'parameter'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetApprovalPolicyWithContractDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyWithContractDateQuery, GetApprovalPolicyWithContractDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyWithContractDateQuery, GetApprovalPolicyWithContractDateQueryVariables>(GetApprovalPolicyWithContractDateDocument, baseOptions);
      }
export function useGetApprovalPolicyWithContractDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyWithContractDateQuery, GetApprovalPolicyWithContractDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyWithContractDateQuery, GetApprovalPolicyWithContractDateQueryVariables>(GetApprovalPolicyWithContractDateDocument, baseOptions);
        }
export type GetApprovalPolicyWithContractDateQueryHookResult = ReturnType<typeof useGetApprovalPolicyWithContractDateQuery>;
export type GetApprovalPolicyWithContractDateLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyWithContractDateLazyQuery>;
export type GetApprovalPolicyWithContractDateQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyWithContractDateQuery, GetApprovalPolicyWithContractDateQueryVariables>;
export const GetContractLedgersDocument = gql`
    query getContractLedgers($contractID: String!) {
  getContractLedgers(contractID: $contractID)
}
    `;

/**
 * __useGetContractLedgersQuery__
 *
 * To run a query within a React component, call `useGetContractLedgersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractLedgersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractLedgersQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractLedgersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractLedgersQuery, GetContractLedgersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractLedgersQuery, GetContractLedgersQueryVariables>(GetContractLedgersDocument, baseOptions);
      }
export function useGetContractLedgersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractLedgersQuery, GetContractLedgersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractLedgersQuery, GetContractLedgersQueryVariables>(GetContractLedgersDocument, baseOptions);
        }
export type GetContractLedgersQueryHookResult = ReturnType<typeof useGetContractLedgersQuery>;
export type GetContractLedgersLazyQueryHookResult = ReturnType<typeof useGetContractLedgersLazyQuery>;
export type GetContractLedgersQueryResult = ApolloReactCommon.QueryResult<GetContractLedgersQuery, GetContractLedgersQueryVariables>;
export const GetUserRoleAssignedDocument = gql`
    query getUserRoleAssigned {
  getUserRoleAssigned {
    ID
    name
  }
}
    `;

/**
 * __useGetUserRoleAssignedQuery__
 *
 * To run a query within a React component, call `useGetUserRoleAssignedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleAssignedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleAssignedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRoleAssignedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserRoleAssignedQuery, GetUserRoleAssignedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserRoleAssignedQuery, GetUserRoleAssignedQueryVariables>(GetUserRoleAssignedDocument, baseOptions);
      }
export function useGetUserRoleAssignedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserRoleAssignedQuery, GetUserRoleAssignedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserRoleAssignedQuery, GetUserRoleAssignedQueryVariables>(GetUserRoleAssignedDocument, baseOptions);
        }
export type GetUserRoleAssignedQueryHookResult = ReturnType<typeof useGetUserRoleAssignedQuery>;
export type GetUserRoleAssignedLazyQueryHookResult = ReturnType<typeof useGetUserRoleAssignedLazyQuery>;
export type GetUserRoleAssignedQueryResult = ApolloReactCommon.QueryResult<GetUserRoleAssignedQuery, GetUserRoleAssignedQueryVariables>;
export const GetDigitalDocumentDocument = gql`
    query getDigitalDocument($associateID: String!, $type: String!) {
  getDigitalDocument(associateID: $associateID, type: $type)
  totalDigitalDocuments(associateID: $associateID, type: $type)
}
    `;

/**
 * __useGetDigitalDocumentQuery__
 *
 * To run a query within a React component, call `useGetDigitalDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalDocumentQuery({
 *   variables: {
 *      associateID: // value for 'associateID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetDigitalDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDigitalDocumentQuery, GetDigitalDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDigitalDocumentQuery, GetDigitalDocumentQueryVariables>(GetDigitalDocumentDocument, baseOptions);
      }
export function useGetDigitalDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitalDocumentQuery, GetDigitalDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDigitalDocumentQuery, GetDigitalDocumentQueryVariables>(GetDigitalDocumentDocument, baseOptions);
        }
export type GetDigitalDocumentQueryHookResult = ReturnType<typeof useGetDigitalDocumentQuery>;
export type GetDigitalDocumentLazyQueryHookResult = ReturnType<typeof useGetDigitalDocumentLazyQuery>;
export type GetDigitalDocumentQueryResult = ApolloReactCommon.QueryResult<GetDigitalDocumentQuery, GetDigitalDocumentQueryVariables>;
export const GetTableDocumentsDocument = gql`
    query getTableDocuments($refID: [String!]!, $associateID: String!, $refTable: String!, $description: String) {
  getTableDocuments(refID: $refID, associateID: $associateID, refTable: $refTable, description: $description) {
    ID
    refID
    fileName
    fileURL
    mediaType
    bucketFileName
    description
    size
    createdTs
  }
}
    `;

/**
 * __useGetTableDocumentsQuery__
 *
 * To run a query within a React component, call `useGetTableDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableDocumentsQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      associateID: // value for 'associateID'
 *      refTable: // value for 'refTable'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useGetTableDocumentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTableDocumentsQuery, GetTableDocumentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTableDocumentsQuery, GetTableDocumentsQueryVariables>(GetTableDocumentsDocument, baseOptions);
      }
export function useGetTableDocumentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTableDocumentsQuery, GetTableDocumentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTableDocumentsQuery, GetTableDocumentsQueryVariables>(GetTableDocumentsDocument, baseOptions);
        }
export type GetTableDocumentsQueryHookResult = ReturnType<typeof useGetTableDocumentsQuery>;
export type GetTableDocumentsLazyQueryHookResult = ReturnType<typeof useGetTableDocumentsLazyQuery>;
export type GetTableDocumentsQueryResult = ApolloReactCommon.QueryResult<GetTableDocumentsQuery, GetTableDocumentsQueryVariables>;
export const AddDigitalDocumentsDocument = gql`
    mutation addDigitalDocuments($input: DigitalDocumentInput!) {
  addDigitalDocuments(input: $input)
}
    `;
export type AddDigitalDocumentsMutationFn = ApolloReactCommon.MutationFunction<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>;

/**
 * __useAddDigitalDocumentsMutation__
 *
 * To run a mutation, you first call `useAddDigitalDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDigitalDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDigitalDocumentsMutation, { data, loading, error }] = useAddDigitalDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDigitalDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>(AddDigitalDocumentsDocument, baseOptions);
      }
export type AddDigitalDocumentsMutationHookResult = ReturnType<typeof useAddDigitalDocumentsMutation>;
export type AddDigitalDocumentsMutationResult = ApolloReactCommon.MutationResult<AddDigitalDocumentsMutation>;
export type AddDigitalDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>;
export const DeleteDigitalDocumentsDocument = gql`
    mutation deleteDigitalDocuments($ID: String!) {
  deleteDigitalDocuments(ID: $ID)
}
    `;
export type DeleteDigitalDocumentsMutationFn = ApolloReactCommon.MutationFunction<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>;

/**
 * __useDeleteDigitalDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteDigitalDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDigitalDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDigitalDocumentsMutation, { data, loading, error }] = useDeleteDigitalDocumentsMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteDigitalDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>(DeleteDigitalDocumentsDocument, baseOptions);
      }
export type DeleteDigitalDocumentsMutationHookResult = ReturnType<typeof useDeleteDigitalDocumentsMutation>;
export type DeleteDigitalDocumentsMutationResult = ApolloReactCommon.MutationResult<DeleteDigitalDocumentsMutation>;
export type DeleteDigitalDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>;
export const GetWbsFinancialDocument = gql`
    query getWBSFinancial($contractID: String) {
  getWBS(contractID: $contractID) {
    ID
    parentWBSID
    name
    startDate
    endDate
    isLastNode
    sumContractVORevenue
    committedCost
    actualCost
    actualRevenue
    totalDN
    totalCost(contractID: $contractID)
    totalRevenue(contractID: $contractID)
    originalCost(contractID: $contractID)
    originalRevenue(contractID: $contractID)
    revisedRevenue(contractID: $contractID)
    sumContractVORevenue
    revisedCost(contractID: $contractID)
    sequence
    childrenWbs {
      ID
      parentWBSID
      name
      startDate
      endDate
      isLastNode
      sumContractVORevenue
      committedCost
      actualCost
      totalDN
      actualRevenue
      totalCost(contractID: $contractID)
      originalCost(contractID: $contractID)
      originalRevenue(contractID: $contractID)
      revisedRevenue(contractID: $contractID)
      sumContractVORevenue
      revisedCost(contractID: $contractID)
      totalRevenue(contractID: $contractID)
      sequence
      childrenWbs {
        ID
        parentWBSID
        name
        startDate
        endDate
        isLastNode
        sumContractVORevenue
        committedCost
        totalDN
        actualCost
        actualRevenue
        totalCost(contractID: $contractID)
        revisedRevenue(contractID: $contractID)
        sumContractVORevenue
        totalRevenue(contractID: $contractID)
        originalCost(contractID: $contractID)
        revisedCost(contractID: $contractID)
        sequence
      }
    }
  }
}
    `;

/**
 * __useGetWbsFinancialQuery__
 *
 * To run a query within a React component, call `useGetWbsFinancialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsFinancialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsFinancialQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetWbsFinancialQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsFinancialQuery, GetWbsFinancialQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsFinancialQuery, GetWbsFinancialQueryVariables>(GetWbsFinancialDocument, baseOptions);
      }
export function useGetWbsFinancialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsFinancialQuery, GetWbsFinancialQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsFinancialQuery, GetWbsFinancialQueryVariables>(GetWbsFinancialDocument, baseOptions);
        }
export type GetWbsFinancialQueryHookResult = ReturnType<typeof useGetWbsFinancialQuery>;
export type GetWbsFinancialLazyQueryHookResult = ReturnType<typeof useGetWbsFinancialLazyQuery>;
export type GetWbsFinancialQueryResult = ApolloReactCommon.QueryResult<GetWbsFinancialQuery, GetWbsFinancialQueryVariables>;
export const GetContractFinancialDocument = gql`
    query getContractFinancial($ID: String) {
  getContract(ID: $ID) {
    ID
    customerID
    customerDetail {
      name
    }
    title
    contractNo
    contractDate
    startDate
    financialClientReceipt
    endDate
    contractSum
    revisedContractSum
    voSum
    originalBudget {
      ID
      budgetStatus
      totalCostRevenue(contractID: $ID)
    }
    revisedBudget {
      ID
      budgetStatus
      totalCostRevenue(contractID: $ID)
    }
  }
}
    `;

/**
 * __useGetContractFinancialQuery__
 *
 * To run a query within a React component, call `useGetContractFinancialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractFinancialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractFinancialQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetContractFinancialQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractFinancialQuery, GetContractFinancialQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractFinancialQuery, GetContractFinancialQueryVariables>(GetContractFinancialDocument, baseOptions);
      }
export function useGetContractFinancialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractFinancialQuery, GetContractFinancialQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractFinancialQuery, GetContractFinancialQueryVariables>(GetContractFinancialDocument, baseOptions);
        }
export type GetContractFinancialQueryHookResult = ReturnType<typeof useGetContractFinancialQuery>;
export type GetContractFinancialLazyQueryHookResult = ReturnType<typeof useGetContractFinancialLazyQuery>;
export type GetContractFinancialQueryResult = ApolloReactCommon.QueryResult<GetContractFinancialQuery, GetContractFinancialQueryVariables>;
export const GetContractRetentionDocument = gql`
    query getContractRetention($contractID: String) {
  getRetentionClaim(contractID: $contractID) {
    ID
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    customerID
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  retention(contractID: $contractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
}
    `;

/**
 * __useGetContractRetentionQuery__
 *
 * To run a query within a React component, call `useGetContractRetentionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractRetentionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractRetentionQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractRetentionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractRetentionQuery, GetContractRetentionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractRetentionQuery, GetContractRetentionQueryVariables>(GetContractRetentionDocument, baseOptions);
      }
export function useGetContractRetentionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractRetentionQuery, GetContractRetentionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractRetentionQuery, GetContractRetentionQueryVariables>(GetContractRetentionDocument, baseOptions);
        }
export type GetContractRetentionQueryHookResult = ReturnType<typeof useGetContractRetentionQuery>;
export type GetContractRetentionLazyQueryHookResult = ReturnType<typeof useGetContractRetentionLazyQuery>;
export type GetContractRetentionQueryResult = ApolloReactCommon.QueryResult<GetContractRetentionQuery, GetContractRetentionQueryVariables>;
export const GetSubcontractRetentionDocument = gql`
    query getSubcontractRetention($subcontractID: String) {
  getSubconRetentionClaim(subcontractID: $subcontractID) {
    ID
    isGlExport
    accountID
    subcontractID
    subcontractorID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  retention(subcontractID: $subcontractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
}
    `;

/**
 * __useGetSubcontractRetentionQuery__
 *
 * To run a query within a React component, call `useGetSubcontractRetentionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractRetentionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractRetentionQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractRetentionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractRetentionQuery, GetSubcontractRetentionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractRetentionQuery, GetSubcontractRetentionQueryVariables>(GetSubcontractRetentionDocument, baseOptions);
      }
export function useGetSubcontractRetentionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractRetentionQuery, GetSubcontractRetentionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractRetentionQuery, GetSubcontractRetentionQueryVariables>(GetSubcontractRetentionDocument, baseOptions);
        }
export type GetSubcontractRetentionQueryHookResult = ReturnType<typeof useGetSubcontractRetentionQuery>;
export type GetSubcontractRetentionLazyQueryHookResult = ReturnType<typeof useGetSubcontractRetentionLazyQuery>;
export type GetSubcontractRetentionQueryResult = ApolloReactCommon.QueryResult<GetSubcontractRetentionQuery, GetSubcontractRetentionQueryVariables>;
export const RetentionFormCheckerDocument = gql`
    query retentionFormChecker($contractID: String, $subcontractID: String) {
  retention(contractID: $contractID, subcontractID: $subcontractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
}
    `;

/**
 * __useRetentionFormCheckerQuery__
 *
 * To run a query within a React component, call `useRetentionFormCheckerQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetentionFormCheckerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetentionFormCheckerQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useRetentionFormCheckerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RetentionFormCheckerQuery, RetentionFormCheckerQueryVariables>) {
        return ApolloReactHooks.useQuery<RetentionFormCheckerQuery, RetentionFormCheckerQueryVariables>(RetentionFormCheckerDocument, baseOptions);
      }
export function useRetentionFormCheckerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RetentionFormCheckerQuery, RetentionFormCheckerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RetentionFormCheckerQuery, RetentionFormCheckerQueryVariables>(RetentionFormCheckerDocument, baseOptions);
        }
export type RetentionFormCheckerQueryHookResult = ReturnType<typeof useRetentionFormCheckerQuery>;
export type RetentionFormCheckerLazyQueryHookResult = ReturnType<typeof useRetentionFormCheckerLazyQuery>;
export type RetentionFormCheckerQueryResult = ApolloReactCommon.QueryResult<RetentionFormCheckerQuery, RetentionFormCheckerQueryVariables>;
export const RetentionCheckingDocument = gql`
    query retentionChecking($contractID: String, $subcontractID: String) {
  retentionChecking(contractID: $contractID, subcontractID: $subcontractID)
  submittedRetClaimChecking(contractID: $contractID, subcontractID: $subcontractID)
}
    `;

/**
 * __useRetentionCheckingQuery__
 *
 * To run a query within a React component, call `useRetentionCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetentionCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetentionCheckingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useRetentionCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RetentionCheckingQuery, RetentionCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<RetentionCheckingQuery, RetentionCheckingQueryVariables>(RetentionCheckingDocument, baseOptions);
      }
export function useRetentionCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RetentionCheckingQuery, RetentionCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RetentionCheckingQuery, RetentionCheckingQueryVariables>(RetentionCheckingDocument, baseOptions);
        }
export type RetentionCheckingQueryHookResult = ReturnType<typeof useRetentionCheckingQuery>;
export type RetentionCheckingLazyQueryHookResult = ReturnType<typeof useRetentionCheckingLazyQuery>;
export type RetentionCheckingQueryResult = ApolloReactCommon.QueryResult<RetentionCheckingQuery, RetentionCheckingQueryVariables>;
export const SubmittedRetClaimCheckingDocument = gql`
    query submittedRetClaimChecking($contractID: String, $subcontractID: String) {
  submittedRetClaimChecking(contractID: $contractID, subcontractID: $subcontractID)
  fetchUnallocatedClaimRetention(contractID: $contractID, subcontractID: $subcontractID)
}
    `;

/**
 * __useSubmittedRetClaimCheckingQuery__
 *
 * To run a query within a React component, call `useSubmittedRetClaimCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedRetClaimCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedRetClaimCheckingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useSubmittedRetClaimCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubmittedRetClaimCheckingQuery, SubmittedRetClaimCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<SubmittedRetClaimCheckingQuery, SubmittedRetClaimCheckingQueryVariables>(SubmittedRetClaimCheckingDocument, baseOptions);
      }
export function useSubmittedRetClaimCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmittedRetClaimCheckingQuery, SubmittedRetClaimCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubmittedRetClaimCheckingQuery, SubmittedRetClaimCheckingQueryVariables>(SubmittedRetClaimCheckingDocument, baseOptions);
        }
export type SubmittedRetClaimCheckingQueryHookResult = ReturnType<typeof useSubmittedRetClaimCheckingQuery>;
export type SubmittedRetClaimCheckingLazyQueryHookResult = ReturnType<typeof useSubmittedRetClaimCheckingLazyQuery>;
export type SubmittedRetClaimCheckingQueryResult = ApolloReactCommon.QueryResult<SubmittedRetClaimCheckingQuery, SubmittedRetClaimCheckingQueryVariables>;
export const GetSubcontractVoDocument = gql`
    query getSubcontractVO($ID: String, $contractID: String, $subcontractID: String, $orderByAsc: String, $VOStatus: VOStatus) {
  getSubcontractVO(ID: $ID, contractID: $contractID, subcontractID: $subcontractID, orderByAsc: $orderByAsc, VOStatus: $VOStatus) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    accountID
    contractID
    subcontractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    approvedAmt
    remarks
    submittedDate
    rejectedDate
    docDate
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    subcontractClaimVOAlloc
    subcontractClaimVOAllocation {
      ID
      subcontractVOID
      previousVOClaimAmt
      currentVOClaimAmt
      submittedVOClaimAmt
    }
    subcontractVOCostWbs {
      ID
      subcontractVOID
      subcontractCostWbsID
      subcontractCostWbs {
        ID
        wbs {
          ID
          name
        }
        costCategory {
          ID
          name
        }
      }
      submissionAmt
      approvedAmt
    }
  }
}
    `;

/**
 * __useGetSubcontractVoQuery__
 *
 * To run a query within a React component, call `useGetSubcontractVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractVoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *      orderByAsc: // value for 'orderByAsc'
 *      VOStatus: // value for 'VOStatus'
 *   },
 * });
 */
export function useGetSubcontractVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>(GetSubcontractVoDocument, baseOptions);
      }
export function useGetSubcontractVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>(GetSubcontractVoDocument, baseOptions);
        }
export type GetSubcontractVoQueryHookResult = ReturnType<typeof useGetSubcontractVoQuery>;
export type GetSubcontractVoLazyQueryHookResult = ReturnType<typeof useGetSubcontractVoLazyQuery>;
export type GetSubcontractVoQueryResult = ApolloReactCommon.QueryResult<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>;
export const CreateSubcontractVoDocument = gql`
    mutation createSubcontractVO($input: SubcontractVOInput!, $voDetails: [SubconVODetailsInput!]) {
  createSubcontractVO(input: $input, voDetails: $voDetails) {
    ID
    docNo
  }
}
    `;
export type CreateSubcontractVoMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractVoMutation, CreateSubcontractVoMutationVariables>;

/**
 * __useCreateSubcontractVoMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractVoMutation, { data, loading, error }] = useCreateSubcontractVoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      voDetails: // value for 'voDetails'
 *   },
 * });
 */
export function useCreateSubcontractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractVoMutation, CreateSubcontractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractVoMutation, CreateSubcontractVoMutationVariables>(CreateSubcontractVoDocument, baseOptions);
      }
export type CreateSubcontractVoMutationHookResult = ReturnType<typeof useCreateSubcontractVoMutation>;
export type CreateSubcontractVoMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractVoMutation>;
export type CreateSubcontractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractVoMutation, CreateSubcontractVoMutationVariables>;
export const UpdateSubcontractVoDocument = gql`
    mutation updateSubcontractVO($input: SubcontractVOInput!, $voDetails: [SubconVODetailsInput!]) {
  updateSubcontractVO(input: $input, voDetails: $voDetails) {
    ID
    docRef
  }
}
    `;
export type UpdateSubcontractVoMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractVoMutation, UpdateSubcontractVoMutationVariables>;

/**
 * __useUpdateSubcontractVoMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractVoMutation, { data, loading, error }] = useUpdateSubcontractVoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      voDetails: // value for 'voDetails'
 *   },
 * });
 */
export function useUpdateSubcontractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractVoMutation, UpdateSubcontractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractVoMutation, UpdateSubcontractVoMutationVariables>(UpdateSubcontractVoDocument, baseOptions);
      }
export type UpdateSubcontractVoMutationHookResult = ReturnType<typeof useUpdateSubcontractVoMutation>;
export type UpdateSubcontractVoMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractVoMutation>;
export type UpdateSubcontractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractVoMutation, UpdateSubcontractVoMutationVariables>;
export const DeleteSubcontractVoDocument = gql`
    mutation deleteSubcontractVO($ID: String!) {
  deleteSubcontractVO(ID: $ID)
}
    `;
export type DeleteSubcontractVoMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractVoMutation, DeleteSubcontractVoMutationVariables>;

/**
 * __useDeleteSubcontractVoMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractVoMutation, { data, loading, error }] = useDeleteSubcontractVoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractVoMutation, DeleteSubcontractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractVoMutation, DeleteSubcontractVoMutationVariables>(DeleteSubcontractVoDocument, baseOptions);
      }
export type DeleteSubcontractVoMutationHookResult = ReturnType<typeof useDeleteSubcontractVoMutation>;
export type DeleteSubcontractVoMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractVoMutation>;
export type DeleteSubcontractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractVoMutation, DeleteSubcontractVoMutationVariables>;
export const ActionOnSubconVoStatusDocument = gql`
    mutation actionOnSubconVOStatus($ID: String!, $input: SubcontractVOActionInput!, $itemApprovedAmount: [ItemApprovedAmountInput!]) {
  actionOnSubconVOStatus(ID: $ID, input: $input, itemApprovedAmount: $itemApprovedAmount)
}
    `;
export type ActionOnSubconVoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>;

/**
 * __useActionOnSubconVoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnSubconVoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnSubconVoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnSubconVoStatusMutation, { data, loading, error }] = useActionOnSubconVoStatusMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *      itemApprovedAmount: // value for 'itemApprovedAmount'
 *   },
 * });
 */
export function useActionOnSubconVoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>(ActionOnSubconVoStatusDocument, baseOptions);
      }
export type ActionOnSubconVoStatusMutationHookResult = ReturnType<typeof useActionOnSubconVoStatusMutation>;
export type ActionOnSubconVoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnSubconVoStatusMutation>;
export type ActionOnSubconVoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>;
export const GetSubcontractVoSummaryDocument = gql`
    query getSubcontractVOSummary($subcontractID: String!) {
  subcontractVO(subcontractID: $subcontractID) {
    voSum
    voOmission
    voSumCount
    voOmissionCount
    totalVoAmt
  }
}
    `;

/**
 * __useGetSubcontractVoSummaryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractVoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractVoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractVoSummaryQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractVoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>(GetSubcontractVoSummaryDocument, baseOptions);
      }
export function useGetSubcontractVoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>(GetSubcontractVoSummaryDocument, baseOptions);
        }
export type GetSubcontractVoSummaryQueryHookResult = ReturnType<typeof useGetSubcontractVoSummaryQuery>;
export type GetSubcontractVoSummaryLazyQueryHookResult = ReturnType<typeof useGetSubcontractVoSummaryLazyQuery>;
export type GetSubcontractVoSummaryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>;
export const GetSubcontractVoListingDocument = gql`
    query getSubcontractVOListing($contractID: String!) {
  subcontractVOListing(contractID: $contractID)
}
    `;

/**
 * __useGetSubcontractVoListingQuery__
 *
 * To run a query within a React component, call `useGetSubcontractVoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractVoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractVoListingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubcontractVoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractVoListingQuery, GetSubcontractVoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractVoListingQuery, GetSubcontractVoListingQueryVariables>(GetSubcontractVoListingDocument, baseOptions);
      }
export function useGetSubcontractVoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractVoListingQuery, GetSubcontractVoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractVoListingQuery, GetSubcontractVoListingQueryVariables>(GetSubcontractVoListingDocument, baseOptions);
        }
export type GetSubcontractVoListingQueryHookResult = ReturnType<typeof useGetSubcontractVoListingQuery>;
export type GetSubcontractVoListingLazyQueryHookResult = ReturnType<typeof useGetSubcontractVoListingLazyQuery>;
export type GetSubcontractVoListingQueryResult = ApolloReactCommon.QueryResult<GetSubcontractVoListingQuery, GetSubcontractVoListingQueryVariables>;
export const GetSubconVoDocument = gql`
    query getSubconVo($contractID: String!, $voStatus: VOStatus!) {
  getSubcontractVO(contractID: $contractID, VOStatus: $voStatus) {
    approvedAmt
    submissionAmt
    VOType
    docNo
    docRef
    submittedDate
    docDate
    description
  }
}
    `;

/**
 * __useGetSubconVoQuery__
 *
 * To run a query within a React component, call `useGetSubconVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconVoQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      voStatus: // value for 'voStatus'
 *   },
 * });
 */
export function useGetSubconVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconVoQuery, GetSubconVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconVoQuery, GetSubconVoQueryVariables>(GetSubconVoDocument, baseOptions);
      }
export function useGetSubconVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconVoQuery, GetSubconVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconVoQuery, GetSubconVoQueryVariables>(GetSubconVoDocument, baseOptions);
        }
export type GetSubconVoQueryHookResult = ReturnType<typeof useGetSubconVoQuery>;
export type GetSubconVoLazyQueryHookResult = ReturnType<typeof useGetSubconVoLazyQuery>;
export type GetSubconVoQueryResult = ApolloReactCommon.QueryResult<GetSubconVoQuery, GetSubconVoQueryVariables>;
export const GetSubconPendingVoDocument = gql`
    query getSubconPendingVo($contractID: String!) {
  submitSubVO: getSubcontractVO(contractID: $contractID, VOStatus: SUBMIT) {
    approvedAmt
    submissionAmt
    VOType
    docNo
    docRef
    submittedDate
    docDate
    description
  }
  pendingSubVO: getSubcontractVO(contractID: $contractID, VOStatus: PENDING) {
    approvedAmt
    submissionAmt
    VOType
    docNo
    docRef
    submittedDate
    docDate
    description
  }
}
    `;

/**
 * __useGetSubconPendingVoQuery__
 *
 * To run a query within a React component, call `useGetSubconPendingVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconPendingVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconPendingVoQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubconPendingVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconPendingVoQuery, GetSubconPendingVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconPendingVoQuery, GetSubconPendingVoQueryVariables>(GetSubconPendingVoDocument, baseOptions);
      }
export function useGetSubconPendingVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconPendingVoQuery, GetSubconPendingVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconPendingVoQuery, GetSubconPendingVoQueryVariables>(GetSubconPendingVoDocument, baseOptions);
        }
export type GetSubconPendingVoQueryHookResult = ReturnType<typeof useGetSubconPendingVoQuery>;
export type GetSubconPendingVoLazyQueryHookResult = ReturnType<typeof useGetSubconPendingVoLazyQuery>;
export type GetSubconPendingVoQueryResult = ApolloReactCommon.QueryResult<GetSubconPendingVoQuery, GetSubconPendingVoQueryVariables>;
export const GetSubcontractWithTradeDocument = gql`
    query getSubcontractWithTrade($contractID: String, $costCategoryID: String) {
  getSubcontractWithTrade(contractID: $contractID, costCategoryID: $costCategoryID) {
    ID
    wbsID
    wbs {
      ID
      name
    }
    tradeAmt
    costCategoryID
    costCategory {
      name
    }
    subcontractID
    subcontract {
      title
      subcontractNo
      subcontractDate
      subcontractorID
      subcontractorDetail {
        name
      }
    }
    revisedTradeAmt
    claimToDateWithTrade
  }
}
    `;

/**
 * __useGetSubcontractWithTradeQuery__
 *
 * To run a query within a React component, call `useGetSubcontractWithTradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractWithTradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractWithTradeQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetSubcontractWithTradeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractWithTradeQuery, GetSubcontractWithTradeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractWithTradeQuery, GetSubcontractWithTradeQueryVariables>(GetSubcontractWithTradeDocument, baseOptions);
      }
export function useGetSubcontractWithTradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractWithTradeQuery, GetSubcontractWithTradeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractWithTradeQuery, GetSubcontractWithTradeQueryVariables>(GetSubcontractWithTradeDocument, baseOptions);
        }
export type GetSubcontractWithTradeQueryHookResult = ReturnType<typeof useGetSubcontractWithTradeQuery>;
export type GetSubcontractWithTradeLazyQueryHookResult = ReturnType<typeof useGetSubcontractWithTradeLazyQuery>;
export type GetSubcontractWithTradeQueryResult = ApolloReactCommon.QueryResult<GetSubcontractWithTradeQuery, GetSubcontractWithTradeQueryVariables>;
export const GetSubcontractCostWbsDocument = gql`
    query getSubcontractCostWbs($contractID: String, $subcontractID: String, $wbsID: String, $costCategoryID: String) {
  getSubcontractCostWbs(contractID: $contractID, subcontractID: $subcontractID, wbsID: $wbsID, costCategoryID: $costCategoryID) {
    ID
    wbsID
    wbs {
      ID
      name
    }
    tradeAmt
    costCategoryID
    costCategory {
      name
    }
    subcontractID
    subcontract {
      title
      subcontractNo
      subcontractDate
      subcontractorID
      subcontractorDetail {
        name
      }
    }
    revisedTradeAmt
    claimToDateWithTrade
  }
}
    `;

/**
 * __useGetSubcontractCostWbsQuery__
 *
 * To run a query within a React component, call `useGetSubcontractCostWbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractCostWbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractCostWbsQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *      wbsID: // value for 'wbsID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetSubcontractCostWbsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractCostWbsQuery, GetSubcontractCostWbsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractCostWbsQuery, GetSubcontractCostWbsQueryVariables>(GetSubcontractCostWbsDocument, baseOptions);
      }
export function useGetSubcontractCostWbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractCostWbsQuery, GetSubcontractCostWbsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractCostWbsQuery, GetSubcontractCostWbsQueryVariables>(GetSubcontractCostWbsDocument, baseOptions);
        }
export type GetSubcontractCostWbsQueryHookResult = ReturnType<typeof useGetSubcontractCostWbsQuery>;
export type GetSubcontractCostWbsLazyQueryHookResult = ReturnType<typeof useGetSubcontractCostWbsLazyQuery>;
export type GetSubcontractCostWbsQueryResult = ApolloReactCommon.QueryResult<GetSubcontractCostWbsQuery, GetSubcontractCostWbsQueryVariables>;
export const GetSubcontractCostWbsCostCategoryDocument = gql`
    query getSubcontractCostWbsCostCategory($contractID: String, $subcontractID: String, $wbsID: String) {
  getSubcontractCostWbs(contractID: $contractID, subcontractID: $subcontractID, wbsID: $wbsID) {
    costCategoryID
    costCategory {
      name
    }
    tradeAmt
  }
}
    `;

/**
 * __useGetSubcontractCostWbsCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractCostWbsCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractCostWbsCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractCostWbsCostCategoryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *      wbsID: // value for 'wbsID'
 *   },
 * });
 */
export function useGetSubcontractCostWbsCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractCostWbsCostCategoryQuery, GetSubcontractCostWbsCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractCostWbsCostCategoryQuery, GetSubcontractCostWbsCostCategoryQueryVariables>(GetSubcontractCostWbsCostCategoryDocument, baseOptions);
      }
export function useGetSubcontractCostWbsCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractCostWbsCostCategoryQuery, GetSubcontractCostWbsCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractCostWbsCostCategoryQuery, GetSubcontractCostWbsCostCategoryQueryVariables>(GetSubcontractCostWbsCostCategoryDocument, baseOptions);
        }
export type GetSubcontractCostWbsCostCategoryQueryHookResult = ReturnType<typeof useGetSubcontractCostWbsCostCategoryQuery>;
export type GetSubcontractCostWbsCostCategoryLazyQueryHookResult = ReturnType<typeof useGetSubcontractCostWbsCostCategoryLazyQuery>;
export type GetSubcontractCostWbsCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractCostWbsCostCategoryQuery, GetSubcontractCostWbsCostCategoryQueryVariables>;
export const GetSubconVoTradeCostCategoryDocument = gql`
    query getSubconVOTradeCostCategory($contractID: String!, $wbsID: String, $subcontractID: String) {
  getSubconVOTradeCostCategory(contractID: $contractID, wbsID: $wbsID, subcontractID: $subcontractID)
}
    `;

/**
 * __useGetSubconVoTradeCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetSubconVoTradeCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconVoTradeCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconVoTradeCostCategoryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      wbsID: // value for 'wbsID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubconVoTradeCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconVoTradeCostCategoryQuery, GetSubconVoTradeCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconVoTradeCostCategoryQuery, GetSubconVoTradeCostCategoryQueryVariables>(GetSubconVoTradeCostCategoryDocument, baseOptions);
      }
export function useGetSubconVoTradeCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconVoTradeCostCategoryQuery, GetSubconVoTradeCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconVoTradeCostCategoryQuery, GetSubconVoTradeCostCategoryQueryVariables>(GetSubconVoTradeCostCategoryDocument, baseOptions);
        }
export type GetSubconVoTradeCostCategoryQueryHookResult = ReturnType<typeof useGetSubconVoTradeCostCategoryQuery>;
export type GetSubconVoTradeCostCategoryLazyQueryHookResult = ReturnType<typeof useGetSubconVoTradeCostCategoryLazyQuery>;
export type GetSubconVoTradeCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetSubconVoTradeCostCategoryQuery, GetSubconVoTradeCostCategoryQueryVariables>;
export const GetSubcontractDocument = gql`
    query getSubcontract($ID: String, $companyID: String, $contractID: String, $subcontractorID: String, $subcontractStatus: ContractStatus) {
  getSubcontract(ID: $ID, companyID: $companyID, contractID: $contractID, subcontractorID: $subcontractorID, subcontractStatus: $subcontractStatus) {
    ID
    title
    accountID
    contractID
    mainContractDetail {
      ID
      title
      budgetChecker
    }
    subcontractorID
    subcontractorDetail {
      associatedStatus
      name
      ID
      address
      contactNo
      registrationNo
      gstRegNo
      sstRegNo
    }
    siteID
    site {
      ID
      name
      contractID
      address
    }
    costCategoryID
    subcontractNo
    title
    description
    subcontractDate
    taxSchemeID
    taxDetail {
      ID
      code
      description
      taxCategory
      taxTypeID
      taxClass
      createdTs
      commonStatus
      latestTax {
        taxRate
        taxDate
      }
      taxEffective {
        date
        taxRate
        createdTs
      }
    }
    subcontractSum
    revisedSubcontractSum
    startDate
    endDate
    progressRetentionPerc
    maxRetentionPerc
    maxRetentionSum
    targetProfitPerc
    cmgdDate
    cpcDate
    dlpDate
    latestRunNum
    docNumChecking
    ladAmt
    ladRate
    creditTerm
    subcontractStatus
    contactPerson
    multipleTrade
    submissionComment
    extendedDate
    extensionDate
    closureRemarks
    closureDate
    modBy
    contact {
      ID
      name
      email
      designation
      contactNo
    }
    VO {
      submissionAmt
      approvedAmt
      subcontractClaimVOAllocation {
        ID
        subcontractClaimID
        subcontractVOID
        submittedVOClaimAmt
        currentVOClaimAmt
      }
    }
    tradeDetail {
      ID
      name
    }
    nominatedSC
    subcontractCostWbs {
      ID
      subcontractID
      wbsID
      wbs {
        ID
        parentWBSID
        name
        parentName
      }
      taxSchemeID
      taxDetail {
        code
        description
        latestTax {
          taxRate
          taxDate
        }
      }
      costCategoryID
      costCategory {
        name
        description
      }
      tradeAmt
      tradeVoAmt
      revisedTradeAmt
      previousAmt
      subcontractClaimAllocation {
        ID
        subcontractClaimID
        previousCertifiedAmt
        submittedCertifiedAmt
        currentCertifiedAmt
        subcontractCostWbsID
      }
      subcontractVOCostWbs {
        ID
        approvedAmt
        previousTradeVoAmt
        subcontractClaimVOCostWbsAllocation {
          ID
          subcontractClaimID
          previousVOClaimAmt
          submittedVOClaimAmt
          currentVOClaimAmt
        }
        subcontractVO {
          ID
          VOStatus
          VOType
          docNo
          docRef
          description
        }
      }
      subcontractClaimAllocation {
        ID
        previousCertifiedAmt
        submittedCertifiedAmt
        currentCertifiedAmt
        subcontractClaimID
        subcontractCostWbsID
      }
    }
  }
}
    `;

/**
 * __useGetSubcontractQuery__
 *
 * To run a query within a React component, call `useGetSubcontractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      subcontractStatus: // value for 'subcontractStatus'
 *   },
 * });
 */
export function useGetSubcontractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractQuery, GetSubcontractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractQuery, GetSubcontractQueryVariables>(GetSubcontractDocument, baseOptions);
      }
export function useGetSubcontractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractQuery, GetSubcontractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractQuery, GetSubcontractQueryVariables>(GetSubcontractDocument, baseOptions);
        }
export type GetSubcontractQueryHookResult = ReturnType<typeof useGetSubcontractQuery>;
export type GetSubcontractLazyQueryHookResult = ReturnType<typeof useGetSubcontractLazyQuery>;
export type GetSubcontractQueryResult = ApolloReactCommon.QueryResult<GetSubcontractQuery, GetSubcontractQueryVariables>;
export const GetSubcontractSubmenuDocument = gql`
    query getSubcontractSubmenu($ID: String) {
  getSubcontract(ID: $ID) {
    ID
    title
    accountID
    subcontractNo
    subcontractorID
    subcontractorDetail {
      ID
      name
    }
    startDate
    endDate
    subcontractDate
    subcontractStatus
    cmgdDate
    cpcDate
    dlpDate
    extensionDate
    subcontractSum
    revisedSubcontractSum
    closureDate
    closureRemarks
  }
  getSubcontractRelated(subcontractID: $ID) {
    ID
    title
    accountID
    subcontractNo
    subcontractorID
  }
}
    `;

/**
 * __useGetSubcontractSubmenuQuery__
 *
 * To run a query within a React component, call `useGetSubcontractSubmenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractSubmenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractSubmenuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSubcontractSubmenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractSubmenuQuery, GetSubcontractSubmenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractSubmenuQuery, GetSubcontractSubmenuQueryVariables>(GetSubcontractSubmenuDocument, baseOptions);
      }
export function useGetSubcontractSubmenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractSubmenuQuery, GetSubcontractSubmenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractSubmenuQuery, GetSubcontractSubmenuQueryVariables>(GetSubcontractSubmenuDocument, baseOptions);
        }
export type GetSubcontractSubmenuQueryHookResult = ReturnType<typeof useGetSubcontractSubmenuQuery>;
export type GetSubcontractSubmenuLazyQueryHookResult = ReturnType<typeof useGetSubcontractSubmenuLazyQuery>;
export type GetSubcontractSubmenuQueryResult = ApolloReactCommon.QueryResult<GetSubcontractSubmenuQuery, GetSubcontractSubmenuQueryVariables>;
export const GetContractSubcontractListingDocument = gql`
    query getContractSubcontractListing($companyID: String, $orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContract(companyID: $companyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    title
    accountID
    customerID
    contractNo
    description
    contractStatus
    contractDate
    contractSum
    voSum
    revisedContractSum
    implementWbs
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      address
      contactNo
    }
    subcontractSummary
    subcontractDetail {
      ID
      title
      accountID
      contractID
      subcontractorID
      costCategoryID
      subcontractNo
      description
      subcontractDate
      taxSchemeID
      subcontractSum
      revisedSubcontractSum
      startDate
      subcontractStatus
      endDate
      progressRetentionPerc
      maxRetentionPerc
      targetProfitPerc
      creditTerm
      tradeDetail {
        ID
        name
      }
      subcontractorDetail {
        ID
        name
        associatedStatus
      }
      subcontractStatus
    }
  }
}
    `;

/**
 * __useGetContractSubcontractListingQuery__
 *
 * To run a query within a React component, call `useGetContractSubcontractListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSubcontractListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSubcontractListingQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractSubcontractListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSubcontractListingQuery, GetContractSubcontractListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSubcontractListingQuery, GetContractSubcontractListingQueryVariables>(GetContractSubcontractListingDocument, baseOptions);
      }
export function useGetContractSubcontractListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSubcontractListingQuery, GetContractSubcontractListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSubcontractListingQuery, GetContractSubcontractListingQueryVariables>(GetContractSubcontractListingDocument, baseOptions);
        }
export type GetContractSubcontractListingQueryHookResult = ReturnType<typeof useGetContractSubcontractListingQuery>;
export type GetContractSubcontractListingLazyQueryHookResult = ReturnType<typeof useGetContractSubcontractListingLazyQuery>;
export type GetContractSubcontractListingQueryResult = ApolloReactCommon.QueryResult<GetContractSubcontractListingQuery, GetContractSubcontractListingQueryVariables>;
export const CreateSubcontractDocument = gql`
    mutation createSubcontract($input: SubcontractInput!, $wbsInput: [SubcontractCostWbsInput!]) {
  createSubcontract(input: $input, wbsInput: $wbsInput) {
    ID
    title
  }
}
    `;
export type CreateSubcontractMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractMutation, CreateSubcontractMutationVariables>;

/**
 * __useCreateSubcontractMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractMutation, { data, loading, error }] = useCreateSubcontractMutation({
 *   variables: {
 *      input: // value for 'input'
 *      wbsInput: // value for 'wbsInput'
 *   },
 * });
 */
export function useCreateSubcontractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractMutation, CreateSubcontractMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractMutation, CreateSubcontractMutationVariables>(CreateSubcontractDocument, baseOptions);
      }
export type CreateSubcontractMutationHookResult = ReturnType<typeof useCreateSubcontractMutation>;
export type CreateSubcontractMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractMutation>;
export type CreateSubcontractMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractMutation, CreateSubcontractMutationVariables>;
export const UpdateSubcontractDocument = gql`
    mutation updateSubcontract($input: SubcontractInput!, $wbsInput: [SubcontractCostWbsInput!]) {
  updateSubcontract(input: $input, wbsInput: $wbsInput)
}
    `;
export type UpdateSubcontractMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>;

/**
 * __useUpdateSubcontractMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractMutation, { data, loading, error }] = useUpdateSubcontractMutation({
 *   variables: {
 *      input: // value for 'input'
 *      wbsInput: // value for 'wbsInput'
 *   },
 * });
 */
export function useUpdateSubcontractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>(UpdateSubcontractDocument, baseOptions);
      }
export type UpdateSubcontractMutationHookResult = ReturnType<typeof useUpdateSubcontractMutation>;
export type UpdateSubcontractMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractMutation>;
export type UpdateSubcontractMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>;
export const SubcontractCheckerDocument = gql`
    query subcontractChecker($subcontractID: String) {
  claimChecking(subcontractID: $subcontractID)
  voChecking(subcontractID: $subcontractID)
}
    `;

/**
 * __useSubcontractCheckerQuery__
 *
 * To run a query within a React component, call `useSubcontractCheckerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubcontractCheckerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubcontractCheckerQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useSubcontractCheckerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubcontractCheckerQuery, SubcontractCheckerQueryVariables>) {
        return ApolloReactHooks.useQuery<SubcontractCheckerQuery, SubcontractCheckerQueryVariables>(SubcontractCheckerDocument, baseOptions);
      }
export function useSubcontractCheckerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubcontractCheckerQuery, SubcontractCheckerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubcontractCheckerQuery, SubcontractCheckerQueryVariables>(SubcontractCheckerDocument, baseOptions);
        }
export type SubcontractCheckerQueryHookResult = ReturnType<typeof useSubcontractCheckerQuery>;
export type SubcontractCheckerLazyQueryHookResult = ReturnType<typeof useSubcontractCheckerLazyQuery>;
export type SubcontractCheckerQueryResult = ApolloReactCommon.QueryResult<SubcontractCheckerQuery, SubcontractCheckerQueryVariables>;
export const GetSubcontractGuaranteeDocument = gql`
    query getSubcontractGuarantee($ID: String, $subcontractID: String!) {
  getSubcontractGuarantee(ID: $ID, subcontractID: $subcontractID) {
    ID
    modBy
    accountID
    subcontractID
    docRef
    guaranteeTypeID
    description
    docAmt
    startDate
    expiryDate
    issuer
    collateralAmt
    remarks
    cancellationDate
    guaranteeStatus
    guaranteeType {
      name
    }
  }
  subconGuaranteeInfo(subcontractID: $subcontractID)
}
    `;

/**
 * __useGetSubcontractGuaranteeQuery__
 *
 * To run a query within a React component, call `useGetSubcontractGuaranteeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractGuaranteeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractGuaranteeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractGuaranteeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>(GetSubcontractGuaranteeDocument, baseOptions);
      }
export function useGetSubcontractGuaranteeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>(GetSubcontractGuaranteeDocument, baseOptions);
        }
export type GetSubcontractGuaranteeQueryHookResult = ReturnType<typeof useGetSubcontractGuaranteeQuery>;
export type GetSubcontractGuaranteeLazyQueryHookResult = ReturnType<typeof useGetSubcontractGuaranteeLazyQuery>;
export type GetSubcontractGuaranteeQueryResult = ApolloReactCommon.QueryResult<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>;
export const CreateSubcontractGuaranteeDocument = gql`
    mutation createSubcontractGuarantee($input: SubcontractGuaranteeInput!) {
  createSubcontractGuarantee(input: $input) {
    ID
  }
}
    `;
export type CreateSubcontractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>;

/**
 * __useCreateSubcontractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractGuaranteeMutation, { data, loading, error }] = useCreateSubcontractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>(CreateSubcontractGuaranteeDocument, baseOptions);
      }
export type CreateSubcontractGuaranteeMutationHookResult = ReturnType<typeof useCreateSubcontractGuaranteeMutation>;
export type CreateSubcontractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractGuaranteeMutation>;
export type CreateSubcontractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>;
export const UpdateSubcontractGuaranteeDocument = gql`
    mutation updateSubcontractGuarantee($input: SubcontractGuaranteeInput!) {
  updateSubcontractGuarantee(input: $input) {
    ID
  }
}
    `;
export type UpdateSubcontractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>;

/**
 * __useUpdateSubcontractGuaranteeMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractGuaranteeMutation, { data, loading, error }] = useUpdateSubcontractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>(UpdateSubcontractGuaranteeDocument, baseOptions);
      }
export type UpdateSubcontractGuaranteeMutationHookResult = ReturnType<typeof useUpdateSubcontractGuaranteeMutation>;
export type UpdateSubcontractGuaranteeMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractGuaranteeMutation>;
export type UpdateSubcontractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>;
export const DeleteSubcontractGuaranteeDocument = gql`
    mutation deleteSubcontractGuarantee($input: SubcontractGuaranteeInsuranceDeleteInput!) {
  deleteSubcontractGuarantee(input: $input)
}
    `;
export type DeleteSubcontractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractGuaranteeMutation, DeleteSubcontractGuaranteeMutationVariables>;

/**
 * __useDeleteSubcontractGuaranteeMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractGuaranteeMutation, { data, loading, error }] = useDeleteSubcontractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSubcontractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractGuaranteeMutation, DeleteSubcontractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractGuaranteeMutation, DeleteSubcontractGuaranteeMutationVariables>(DeleteSubcontractGuaranteeDocument, baseOptions);
      }
export type DeleteSubcontractGuaranteeMutationHookResult = ReturnType<typeof useDeleteSubcontractGuaranteeMutation>;
export type DeleteSubcontractGuaranteeMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractGuaranteeMutation>;
export type DeleteSubcontractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractGuaranteeMutation, DeleteSubcontractGuaranteeMutationVariables>;
export const GetSubcontractInsuranceDocument = gql`
    query getSubcontractInsurance($ID: String, $subcontractID: String!) {
  getSubcontractInsurance(ID: $ID, subcontractID: $subcontractID) {
    ID
    modBy
    accountID
    subcontractID
    docRef
    insuranceTypeID
    description
    docAmt
    startDate
    expiryDate
    issuer
    remarks
    cancellationDate
    insuranceStatus
    insuranceType {
      name
    }
  }
  subconInsuranceInfo(subcontractID: $subcontractID)
}
    `;

/**
 * __useGetSubcontractInsuranceQuery__
 *
 * To run a query within a React component, call `useGetSubcontractInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractInsuranceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractInsuranceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>(GetSubcontractInsuranceDocument, baseOptions);
      }
export function useGetSubcontractInsuranceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>(GetSubcontractInsuranceDocument, baseOptions);
        }
export type GetSubcontractInsuranceQueryHookResult = ReturnType<typeof useGetSubcontractInsuranceQuery>;
export type GetSubcontractInsuranceLazyQueryHookResult = ReturnType<typeof useGetSubcontractInsuranceLazyQuery>;
export type GetSubcontractInsuranceQueryResult = ApolloReactCommon.QueryResult<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>;
export const CreateSubcontractInsuranceDocument = gql`
    mutation createSubcontractInsurance($input: SubcontractInsuranceInput!) {
  createSubcontractInsurance(input: $input) {
    ID
  }
}
    `;
export type CreateSubcontractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>;

/**
 * __useCreateSubcontractInsuranceMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractInsuranceMutation, { data, loading, error }] = useCreateSubcontractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>(CreateSubcontractInsuranceDocument, baseOptions);
      }
export type CreateSubcontractInsuranceMutationHookResult = ReturnType<typeof useCreateSubcontractInsuranceMutation>;
export type CreateSubcontractInsuranceMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractInsuranceMutation>;
export type CreateSubcontractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>;
export const UpdateSubcontractInsuranceDocument = gql`
    mutation updateSubcontractInsurance($input: SubcontractInsuranceInput!) {
  updateSubcontractInsurance(input: $input) {
    ID
  }
}
    `;
export type UpdateSubcontractInsuranceMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>;

/**
 * __useUpdateSubcontractInsuranceMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractInsuranceMutation, { data, loading, error }] = useUpdateSubcontractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>(UpdateSubcontractInsuranceDocument, baseOptions);
      }
export type UpdateSubcontractInsuranceMutationHookResult = ReturnType<typeof useUpdateSubcontractInsuranceMutation>;
export type UpdateSubcontractInsuranceMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractInsuranceMutation>;
export type UpdateSubcontractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>;
export const DeleteSubcontractInsuranceDocument = gql`
    mutation deleteSubcontractInsurance($input: SubcontractGuaranteeInsuranceDeleteInput!) {
  deleteSubcontractInsurance(input: $input)
}
    `;
export type DeleteSubcontractInsuranceMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractInsuranceMutation, DeleteSubcontractInsuranceMutationVariables>;

/**
 * __useDeleteSubcontractInsuranceMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractInsuranceMutation, { data, loading, error }] = useDeleteSubcontractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSubcontractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractInsuranceMutation, DeleteSubcontractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractInsuranceMutation, DeleteSubcontractInsuranceMutationVariables>(DeleteSubcontractInsuranceDocument, baseOptions);
      }
export type DeleteSubcontractInsuranceMutationHookResult = ReturnType<typeof useDeleteSubcontractInsuranceMutation>;
export type DeleteSubcontractInsuranceMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractInsuranceMutation>;
export type DeleteSubcontractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractInsuranceMutation, DeleteSubcontractInsuranceMutationVariables>;
export const CancelSubcontractGuaranteeDocument = gql`
    mutation cancelSubcontractGuarantee($input: SubcontractGuaranteeCancellationInput!) {
  cancelSubcontractGuarantee(input: $input)
}
    `;
export type CancelSubcontractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>;

/**
 * __useCancelSubcontractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCancelSubcontractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubcontractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubcontractGuaranteeMutation, { data, loading, error }] = useCancelSubcontractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubcontractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>(CancelSubcontractGuaranteeDocument, baseOptions);
      }
export type CancelSubcontractGuaranteeMutationHookResult = ReturnType<typeof useCancelSubcontractGuaranteeMutation>;
export type CancelSubcontractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CancelSubcontractGuaranteeMutation>;
export type CancelSubcontractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>;
export const CancelSubcontractInsuranceDocument = gql`
    mutation cancelSubcontractInsurance($input: SubcontractInsuranceCancellationInput!) {
  cancelSubcontractInsurance(input: $input)
}
    `;
export type CancelSubcontractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>;

/**
 * __useCancelSubcontractInsuranceMutation__
 *
 * To run a mutation, you first call `useCancelSubcontractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubcontractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubcontractInsuranceMutation, { data, loading, error }] = useCancelSubcontractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubcontractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>(CancelSubcontractInsuranceDocument, baseOptions);
      }
export type CancelSubcontractInsuranceMutationHookResult = ReturnType<typeof useCancelSubcontractInsuranceMutation>;
export type CancelSubcontractInsuranceMutationResult = ApolloReactCommon.MutationResult<CancelSubcontractInsuranceMutation>;
export type CancelSubcontractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>;
export const GetSubcontractSummaryDocument = gql`
    query getSubcontractSummary($subcontractID: String!, $type: String) {
  subcontractInfo(subcontractID: $subcontractID) {
    subcontractSum
    subcontractDate
  }
  subconGuaranteeInfo(subcontractID: $subcontractID)
  subconInsuranceInfo(subcontractID: $subcontractID)
  submittedVOSubconNotification(subcontractID: $subcontractID)
  subcontractVO(subcontractID: $subcontractID) {
    voSum
    voOmission
    voSumCount
    voOmissionCount
    totalVoAmt
  }
  subconOutstandingAdvances(subcontractID: $subcontractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  subconOutstandingClaims(subcontractID: $subcontractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  subconLastExtensionDate(subcontractID: $subcontractID)
  subconClosureDate(subcontractID: $subcontractID)
  retention(subcontractID: $subcontractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
  totalDigitalDocuments(associateID: $subcontractID, type: $type)
}
    `;

/**
 * __useGetSubcontractSummaryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractSummaryQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetSubcontractSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>(GetSubcontractSummaryDocument, baseOptions);
      }
export function useGetSubcontractSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>(GetSubcontractSummaryDocument, baseOptions);
        }
export type GetSubcontractSummaryQueryHookResult = ReturnType<typeof useGetSubcontractSummaryQuery>;
export type GetSubcontractSummaryLazyQueryHookResult = ReturnType<typeof useGetSubcontractSummaryLazyQuery>;
export type GetSubcontractSummaryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>;
export const GetIpcSummaryDocument = gql`
    query getIPCSummary($subcontractID: String!) {
  retention(subcontractID: $subcontractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
}
    `;

/**
 * __useGetIpcSummaryQuery__
 *
 * To run a query within a React component, call `useGetIpcSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIpcSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIpcSummaryQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetIpcSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>(GetIpcSummaryDocument, baseOptions);
      }
export function useGetIpcSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>(GetIpcSummaryDocument, baseOptions);
        }
export type GetIpcSummaryQueryHookResult = ReturnType<typeof useGetIpcSummaryQuery>;
export type GetIpcSummaryLazyQueryHookResult = ReturnType<typeof useGetIpcSummaryLazyQuery>;
export type GetIpcSummaryQueryResult = ApolloReactCommon.QueryResult<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>;
export const ActionOnSubcontractStatusDocument = gql`
    mutation actionOnSubcontractStatus($input: SubcontractActionInput!, $ID: String!) {
  actionOnSubcontractStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnSubcontractStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>;

/**
 * __useActionOnSubcontractStatusMutation__
 *
 * To run a mutation, you first call `useActionOnSubcontractStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnSubcontractStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnSubcontractStatusMutation, { data, loading, error }] = useActionOnSubcontractStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnSubcontractStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>(ActionOnSubcontractStatusDocument, baseOptions);
      }
export type ActionOnSubcontractStatusMutationHookResult = ReturnType<typeof useActionOnSubcontractStatusMutation>;
export type ActionOnSubcontractStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnSubcontractStatusMutation>;
export type ActionOnSubcontractStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>;
export const CreateSubcontractExtensionDateDocument = gql`
    mutation createSubcontractExtensionDate($input: SubcontractExtensionInput!, $ID: String!) {
  createSubcontractExtensionDate(input: $input, ID: $ID)
}
    `;
export type CreateSubcontractExtensionDateMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>;

/**
 * __useCreateSubcontractExtensionDateMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractExtensionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractExtensionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractExtensionDateMutation, { data, loading, error }] = useCreateSubcontractExtensionDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateSubcontractExtensionDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>(CreateSubcontractExtensionDateDocument, baseOptions);
      }
export type CreateSubcontractExtensionDateMutationHookResult = ReturnType<typeof useCreateSubcontractExtensionDateMutation>;
export type CreateSubcontractExtensionDateMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractExtensionDateMutation>;
export type CreateSubcontractExtensionDateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>;
export const CreateSubcontractCertificateDocument = gql`
    mutation createSubcontractCertificate($input: SubcontractCertificateInput!, $ID: String!) {
  createSubcontractCertificate(input: $input, ID: $ID)
}
    `;
export type CreateSubcontractCertificateMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>;

/**
 * __useCreateSubcontractCertificateMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractCertificateMutation, { data, loading, error }] = useCreateSubcontractCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateSubcontractCertificateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>(CreateSubcontractCertificateDocument, baseOptions);
      }
export type CreateSubcontractCertificateMutationHookResult = ReturnType<typeof useCreateSubcontractCertificateMutation>;
export type CreateSubcontractCertificateMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractCertificateMutation>;
export type CreateSubcontractCertificateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>;
export const GetSubcontractExtensionDocument = gql`
    query getSubcontractExtension($ID: String!, $orderByAsc: String, $orderByDesc: String) {
  getSubcontract(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
    subcontractNo
    description
    subcontractDate
    subcontractSum
    revisedSubcontractSum
    taxSchemeID
    startDate
    endDate
    subcontractStatus
    mainContractDetail {
      ID
      title
    }
    extensionDate
  }
  subconLastExtensionDate(subcontractID: $ID)
}
    `;

/**
 * __useGetSubcontractExtensionQuery__
 *
 * To run a query within a React component, call `useGetSubcontractExtensionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractExtensionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractExtensionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetSubcontractExtensionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>(GetSubcontractExtensionDocument, baseOptions);
      }
export function useGetSubcontractExtensionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>(GetSubcontractExtensionDocument, baseOptions);
        }
export type GetSubcontractExtensionQueryHookResult = ReturnType<typeof useGetSubcontractExtensionQuery>;
export type GetSubcontractExtensionLazyQueryHookResult = ReturnType<typeof useGetSubcontractExtensionLazyQuery>;
export type GetSubcontractExtensionQueryResult = ApolloReactCommon.QueryResult<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>;
export const GetSubcontractTitleDocument = gql`
    query getSubcontractTitle($ID: String, $companyID: String, $subcontractorID: String, $contractID: String, $orderByAsc: String) {
  getSubcontract(ID: $ID, companyID: $companyID, subcontractorID: $subcontractorID, contractID: $contractID, orderByAsc: $orderByAsc) {
    ID
    contractID
    mainContractDetail {
      ID
      title
      budgetChecker
    }
    subcontractorID
    subcontractorDetail {
      ID
      name
    }
    taxSchemeID
    taxDetail {
      ID
      code
      description
      taxCategory
      taxTypeID
      taxClass
      createdTs
      commonStatus
      latestTax {
        taxRate
        taxDate
      }
      taxEffective {
        date
        taxRate
        createdTs
      }
    }
    title
    cpcDate
    dlpDate
    cmgdDate
    subcontractDate
    revisedSubcontractSum
    subcontractSum
    multipleTrade
    subcontractStatus
  }
}
    `;

/**
 * __useGetSubcontractTitleQuery__
 *
 * To run a query within a React component, call `useGetSubcontractTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractorID: // value for 'subcontractorID'
 *      contractID: // value for 'contractID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>(GetSubcontractTitleDocument, baseOptions);
      }
export function useGetSubcontractTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>(GetSubcontractTitleDocument, baseOptions);
        }
export type GetSubcontractTitleQueryHookResult = ReturnType<typeof useGetSubcontractTitleQuery>;
export type GetSubcontractTitleLazyQueryHookResult = ReturnType<typeof useGetSubcontractTitleLazyQuery>;
export type GetSubcontractTitleQueryResult = ApolloReactCommon.QueryResult<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>;
export const GetSubcontractRelatedDocument = gql`
    query getSubcontractRelated($subcontractID: String) {
  getSubcontractRelated(subcontractID: $subcontractID) {
    ID
    title
    subcontractStatus
  }
}
    `;

/**
 * __useGetSubcontractRelatedQuery__
 *
 * To run a query within a React component, call `useGetSubcontractRelatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractRelatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractRelatedQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractRelatedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>(GetSubcontractRelatedDocument, baseOptions);
      }
export function useGetSubcontractRelatedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>(GetSubcontractRelatedDocument, baseOptions);
        }
export type GetSubcontractRelatedQueryHookResult = ReturnType<typeof useGetSubcontractRelatedQuery>;
export type GetSubcontractRelatedLazyQueryHookResult = ReturnType<typeof useGetSubcontractRelatedLazyQuery>;
export type GetSubcontractRelatedQueryResult = ApolloReactCommon.QueryResult<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>;
export const SubconBudgetCheckingDocument = gql`
    query subconBudgetChecking($amount: Float!, $contractID: String!, $costCategoryID: String!) {
  subconBudgetChecking(amount: $amount, contractID: $contractID, costCategoryID: $costCategoryID) {
    isExceeded
    isAllocated
  }
}
    `;

/**
 * __useSubconBudgetCheckingQuery__
 *
 * To run a query within a React component, call `useSubconBudgetCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubconBudgetCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubconBudgetCheckingQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      contractID: // value for 'contractID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useSubconBudgetCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>(SubconBudgetCheckingDocument, baseOptions);
      }
export function useSubconBudgetCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>(SubconBudgetCheckingDocument, baseOptions);
        }
export type SubconBudgetCheckingQueryHookResult = ReturnType<typeof useSubconBudgetCheckingQuery>;
export type SubconBudgetCheckingLazyQueryHookResult = ReturnType<typeof useSubconBudgetCheckingLazyQuery>;
export type SubconBudgetCheckingQueryResult = ApolloReactCommon.QueryResult<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>;
export const GetSubcontractorForSubcontractDocument = gql`
    query getSubcontractorForSubcontract($associatedStatus: AssociatedStatus, $orderByAsc: String) {
  getSubcontractor(associatedStatus: $associatedStatus, orderByAsc: $orderByAsc) {
    ID
    name
    associatedStatus
    nominatedSC
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
  }
}
    `;

/**
 * __useGetSubcontractorForSubcontractQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorForSubcontractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorForSubcontractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorForSubcontractQuery({
 *   variables: {
 *      associatedStatus: // value for 'associatedStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorForSubcontractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorForSubcontractQuery, GetSubcontractorForSubcontractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorForSubcontractQuery, GetSubcontractorForSubcontractQueryVariables>(GetSubcontractorForSubcontractDocument, baseOptions);
      }
export function useGetSubcontractorForSubcontractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorForSubcontractQuery, GetSubcontractorForSubcontractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorForSubcontractQuery, GetSubcontractorForSubcontractQueryVariables>(GetSubcontractorForSubcontractDocument, baseOptions);
        }
export type GetSubcontractorForSubcontractQueryHookResult = ReturnType<typeof useGetSubcontractorForSubcontractQuery>;
export type GetSubcontractorForSubcontractLazyQueryHookResult = ReturnType<typeof useGetSubcontractorForSubcontractLazyQuery>;
export type GetSubcontractorForSubcontractQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorForSubcontractQuery, GetSubcontractorForSubcontractQueryVariables>;
export const GetSubcontractTradeCostCategoryDocument = gql`
    query getSubcontractTradeCostCategory($contractID: String!, $wbsID: String!) {
  getSubcontractTradeCostCategory(contractID: $contractID, wbsID: $wbsID)
}
    `;

/**
 * __useGetSubcontractTradeCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractTradeCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractTradeCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractTradeCostCategoryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      wbsID: // value for 'wbsID'
 *   },
 * });
 */
export function useGetSubcontractTradeCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractTradeCostCategoryQuery, GetSubcontractTradeCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractTradeCostCategoryQuery, GetSubcontractTradeCostCategoryQueryVariables>(GetSubcontractTradeCostCategoryDocument, baseOptions);
      }
export function useGetSubcontractTradeCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractTradeCostCategoryQuery, GetSubcontractTradeCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractTradeCostCategoryQuery, GetSubcontractTradeCostCategoryQueryVariables>(GetSubcontractTradeCostCategoryDocument, baseOptions);
        }
export type GetSubcontractTradeCostCategoryQueryHookResult = ReturnType<typeof useGetSubcontractTradeCostCategoryQuery>;
export type GetSubcontractTradeCostCategoryLazyQueryHookResult = ReturnType<typeof useGetSubcontractTradeCostCategoryLazyQuery>;
export type GetSubcontractTradeCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractTradeCostCategoryQuery, GetSubcontractTradeCostCategoryQueryVariables>;
export const GetWbsForSubcontractDocument = gql`
    query getWbsForSubcontract($ID: String, $contractID: String, $isLastNode: Boolean) {
  getWBS(ID: $ID, contractID: $contractID, isLastNode: $isLastNode) {
    ID
    name
    latestWbsBudgetDetail(contractID: $contractID) {
      budgetID
      budgetAmt
      wbsID
      creditTerm
      quantity
    }
  }
}
    `;

/**
 * __useGetWbsForSubcontractQuery__
 *
 * To run a query within a React component, call `useGetWbsForSubcontractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsForSubcontractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsForSubcontractQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      isLastNode: // value for 'isLastNode'
 *   },
 * });
 */
export function useGetWbsForSubcontractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsForSubcontractQuery, GetWbsForSubcontractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsForSubcontractQuery, GetWbsForSubcontractQueryVariables>(GetWbsForSubcontractDocument, baseOptions);
      }
export function useGetWbsForSubcontractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsForSubcontractQuery, GetWbsForSubcontractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsForSubcontractQuery, GetWbsForSubcontractQueryVariables>(GetWbsForSubcontractDocument, baseOptions);
        }
export type GetWbsForSubcontractQueryHookResult = ReturnType<typeof useGetWbsForSubcontractQuery>;
export type GetWbsForSubcontractLazyQueryHookResult = ReturnType<typeof useGetWbsForSubcontractLazyQuery>;
export type GetWbsForSubcontractQueryResult = ApolloReactCommon.QueryResult<GetWbsForSubcontractQuery, GetWbsForSubcontractQueryVariables>;
export const GetSubcontractorLedgersDocument = gql`
    query getSubcontractorLedgers($subcontractID: String) {
  getSubcontractorLedgers(subcontractID: $subcontractID)
}
    `;

/**
 * __useGetSubcontractorLedgersQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorLedgersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorLedgersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorLedgersQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractorLedgersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorLedgersQuery, GetSubcontractorLedgersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorLedgersQuery, GetSubcontractorLedgersQueryVariables>(GetSubcontractorLedgersDocument, baseOptions);
      }
export function useGetSubcontractorLedgersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorLedgersQuery, GetSubcontractorLedgersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorLedgersQuery, GetSubcontractorLedgersQueryVariables>(GetSubcontractorLedgersDocument, baseOptions);
        }
export type GetSubcontractorLedgersQueryHookResult = ReturnType<typeof useGetSubcontractorLedgersQuery>;
export type GetSubcontractorLedgersLazyQueryHookResult = ReturnType<typeof useGetSubcontractorLedgersLazyQuery>;
export type GetSubcontractorLedgersQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorLedgersQuery, GetSubcontractorLedgersQueryVariables>;
export const ActionOnSubcontractStatusWithTradeDocument = gql`
    mutation actionOnSubcontractStatusWithTrade($input: SubcontractApproveRejectInput!, $ID: String!) {
  actionOnSubcontractStatusWithTrade(input: $input, ID: $ID)
}
    `;
export type ActionOnSubcontractStatusWithTradeMutationFn = ApolloReactCommon.MutationFunction<ActionOnSubcontractStatusWithTradeMutation, ActionOnSubcontractStatusWithTradeMutationVariables>;

/**
 * __useActionOnSubcontractStatusWithTradeMutation__
 *
 * To run a mutation, you first call `useActionOnSubcontractStatusWithTradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnSubcontractStatusWithTradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnSubcontractStatusWithTradeMutation, { data, loading, error }] = useActionOnSubcontractStatusWithTradeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnSubcontractStatusWithTradeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnSubcontractStatusWithTradeMutation, ActionOnSubcontractStatusWithTradeMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnSubcontractStatusWithTradeMutation, ActionOnSubcontractStatusWithTradeMutationVariables>(ActionOnSubcontractStatusWithTradeDocument, baseOptions);
      }
export type ActionOnSubcontractStatusWithTradeMutationHookResult = ReturnType<typeof useActionOnSubcontractStatusWithTradeMutation>;
export type ActionOnSubcontractStatusWithTradeMutationResult = ApolloReactCommon.MutationResult<ActionOnSubcontractStatusWithTradeMutation>;
export type ActionOnSubcontractStatusWithTradeMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnSubcontractStatusWithTradeMutation, ActionOnSubcontractStatusWithTradeMutationVariables>;
export const GetContractVoDocument = gql`
    query getContractVO($ID: String, $contractID: String, $orderByAsc: String, $VOStatus: VOStatus) {
  getContractVO(ID: $ID, contractID: $contractID, orderByAsc: $orderByAsc, VOStatus: $VOStatus) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    accountID
    contractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    approvedAmt
    submittedDate
    rejectedDate
    docDate
    remarks
    contractVORevenueWbs {
      ID
      contractVOID
      previousRevenueVOAmt
      wbsID
      revenueCategoryID
      submissionAmt
      approvedAmt
      wbsBudgetDetail {
        ID
        wbsID
        budgetAmt
        revenueCategoryID
        revenueCategory {
          ID
          name
        }
      }
      contractClaimVORevenueWbsAllocation {
        ID
        previousVOClaimAmt
        submittedVOClaimAmt
        currentVOClaimAmt
        contractVORevenueWbsID
        contractVORevenueWbs {
          wbsID
          revenueCategoryID
        }
      }
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    contractClaimVOAlloc
    contractClaimVOAllocation {
      ID
      contractClaimID
      contractVOID
      previousVOClaimAmt
      submittedVOClaimAmt
      currentVOClaimAmt
    }
  }
}
    `;

/**
 * __useGetContractVoQuery__
 *
 * To run a query within a React component, call `useGetContractVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractVoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      orderByAsc: // value for 'orderByAsc'
 *      VOStatus: // value for 'VOStatus'
 *   },
 * });
 */
export function useGetContractVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractVoQuery, GetContractVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractVoQuery, GetContractVoQueryVariables>(GetContractVoDocument, baseOptions);
      }
export function useGetContractVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractVoQuery, GetContractVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractVoQuery, GetContractVoQueryVariables>(GetContractVoDocument, baseOptions);
        }
export type GetContractVoQueryHookResult = ReturnType<typeof useGetContractVoQuery>;
export type GetContractVoLazyQueryHookResult = ReturnType<typeof useGetContractVoLazyQuery>;
export type GetContractVoQueryResult = ApolloReactCommon.QueryResult<GetContractVoQuery, GetContractVoQueryVariables>;
export const CreateContractVoDocument = gql`
    mutation createContractVO($input: ContractVOInput!, $voDetails: [ContractVODetailsInput!]) {
  createContractVO(input: $input, voDetails: $voDetails) {
    ID
    docNo
  }
}
    `;
export type CreateContractVoMutationFn = ApolloReactCommon.MutationFunction<CreateContractVoMutation, CreateContractVoMutationVariables>;

/**
 * __useCreateContractVoMutation__
 *
 * To run a mutation, you first call `useCreateContractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractVoMutation, { data, loading, error }] = useCreateContractVoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      voDetails: // value for 'voDetails'
 *   },
 * });
 */
export function useCreateContractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractVoMutation, CreateContractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractVoMutation, CreateContractVoMutationVariables>(CreateContractVoDocument, baseOptions);
      }
export type CreateContractVoMutationHookResult = ReturnType<typeof useCreateContractVoMutation>;
export type CreateContractVoMutationResult = ApolloReactCommon.MutationResult<CreateContractVoMutation>;
export type CreateContractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractVoMutation, CreateContractVoMutationVariables>;
export const UpdateContractVoDocument = gql`
    mutation updateContractVO($input: ContractVOInput!, $voDetails: [ContractVODetailsInput!]) {
  updateContractVO(input: $input, voDetails: $voDetails) {
    ID
    docRef
  }
}
    `;
export type UpdateContractVoMutationFn = ApolloReactCommon.MutationFunction<UpdateContractVoMutation, UpdateContractVoMutationVariables>;

/**
 * __useUpdateContractVoMutation__
 *
 * To run a mutation, you first call `useUpdateContractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractVoMutation, { data, loading, error }] = useUpdateContractVoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      voDetails: // value for 'voDetails'
 *   },
 * });
 */
export function useUpdateContractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractVoMutation, UpdateContractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractVoMutation, UpdateContractVoMutationVariables>(UpdateContractVoDocument, baseOptions);
      }
export type UpdateContractVoMutationHookResult = ReturnType<typeof useUpdateContractVoMutation>;
export type UpdateContractVoMutationResult = ApolloReactCommon.MutationResult<UpdateContractVoMutation>;
export type UpdateContractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractVoMutation, UpdateContractVoMutationVariables>;
export const DeleteContractVoDocument = gql`
    mutation deleteContractVO($ID: String!) {
  deleteContractVO(ID: $ID)
}
    `;
export type DeleteContractVoMutationFn = ApolloReactCommon.MutationFunction<DeleteContractVoMutation, DeleteContractVoMutationVariables>;

/**
 * __useDeleteContractVoMutation__
 *
 * To run a mutation, you first call `useDeleteContractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractVoMutation, { data, loading, error }] = useDeleteContractVoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractVoMutation, DeleteContractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractVoMutation, DeleteContractVoMutationVariables>(DeleteContractVoDocument, baseOptions);
      }
export type DeleteContractVoMutationHookResult = ReturnType<typeof useDeleteContractVoMutation>;
export type DeleteContractVoMutationResult = ApolloReactCommon.MutationResult<DeleteContractVoMutation>;
export type DeleteContractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractVoMutation, DeleteContractVoMutationVariables>;
export const ActionOnVoStatusDocument = gql`
    mutation actionOnVOStatus($ID: String!, $input: ContractVOActionInput!, $itemApprovedAmount: [VOItemApprovedAmountInput!]) {
  actionOnVOStatus(ID: $ID, input: $input, itemApprovedAmount: $itemApprovedAmount)
}
    `;
export type ActionOnVoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>;

/**
 * __useActionOnVoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnVoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnVoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnVoStatusMutation, { data, loading, error }] = useActionOnVoStatusMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *      itemApprovedAmount: // value for 'itemApprovedAmount'
 *   },
 * });
 */
export function useActionOnVoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>(ActionOnVoStatusDocument, baseOptions);
      }
export type ActionOnVoStatusMutationHookResult = ReturnType<typeof useActionOnVoStatusMutation>;
export type ActionOnVoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnVoStatusMutation>;
export type ActionOnVoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>;
export const GetContractVoSummaryDocument = gql`
    query getContractVOSummary($contractID: String!) {
  contractVO(contractID: $contractID) {
    voSum
    voOmission
    voSumCount
    voOmissionCount
    totalVoAmt
  }
}
    `;

/**
 * __useGetContractVoSummaryQuery__
 *
 * To run a query within a React component, call `useGetContractVoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractVoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractVoSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractVoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>(GetContractVoSummaryDocument, baseOptions);
      }
export function useGetContractVoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>(GetContractVoSummaryDocument, baseOptions);
        }
export type GetContractVoSummaryQueryHookResult = ReturnType<typeof useGetContractVoSummaryQuery>;
export type GetContractVoSummaryLazyQueryHookResult = ReturnType<typeof useGetContractVoSummaryLazyQuery>;
export type GetContractVoSummaryQueryResult = ApolloReactCommon.QueryResult<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>;
export const GetVoHealthIndexDocument = gql`
    query getVOHealthIndex($contractID: String!) {
  getVOHealthIndex(contractID: $contractID) {
    conVOOmission
    conVOSum
    conVOPendingOmission
    conVOPendingSum
    subVOOmission
    subVOSum
    subVOPendingOmission
    subVOPendingSum
    totalVO
    totalPendingVO
    totalSubVO
    totalPendingSubVO
    totalVOOmission
    totalVOSum
  }
}
    `;

/**
 * __useGetVoHealthIndexQuery__
 *
 * To run a query within a React component, call `useGetVoHealthIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoHealthIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoHealthIndexQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetVoHealthIndexQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetVoHealthIndexQuery, GetVoHealthIndexQueryVariables>) {
        return ApolloReactHooks.useQuery<GetVoHealthIndexQuery, GetVoHealthIndexQueryVariables>(GetVoHealthIndexDocument, baseOptions);
      }
export function useGetVoHealthIndexLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVoHealthIndexQuery, GetVoHealthIndexQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetVoHealthIndexQuery, GetVoHealthIndexQueryVariables>(GetVoHealthIndexDocument, baseOptions);
        }
export type GetVoHealthIndexQueryHookResult = ReturnType<typeof useGetVoHealthIndexQuery>;
export type GetVoHealthIndexLazyQueryHookResult = ReturnType<typeof useGetVoHealthIndexLazyQuery>;
export type GetVoHealthIndexQueryResult = ApolloReactCommon.QueryResult<GetVoHealthIndexQuery, GetVoHealthIndexQueryVariables>;
export const GetClientVoDocument = gql`
    query getClientVo($contractID: String!, $voStatus: VOStatus!) {
  getContractVO(contractID: $contractID, VOStatus: $voStatus) {
    ID
    approvedAmt
    submissionAmt
    VOType
    docNo
    docRef
    submittedDate
    docDate
    description
  }
}
    `;

/**
 * __useGetClientVoQuery__
 *
 * To run a query within a React component, call `useGetClientVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientVoQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      voStatus: // value for 'voStatus'
 *   },
 * });
 */
export function useGetClientVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientVoQuery, GetClientVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientVoQuery, GetClientVoQueryVariables>(GetClientVoDocument, baseOptions);
      }
export function useGetClientVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientVoQuery, GetClientVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientVoQuery, GetClientVoQueryVariables>(GetClientVoDocument, baseOptions);
        }
export type GetClientVoQueryHookResult = ReturnType<typeof useGetClientVoQuery>;
export type GetClientVoLazyQueryHookResult = ReturnType<typeof useGetClientVoLazyQuery>;
export type GetClientVoQueryResult = ApolloReactCommon.QueryResult<GetClientVoQuery, GetClientVoQueryVariables>;
export const GetClientPendingVoDocument = gql`
    query getClientPendingVo($contractID: String!) {
  submitVO: getContractVO(contractID: $contractID, VOStatus: SUBMIT) {
    ID
    approvedAmt
    submissionAmt
    VOType
    docNo
    docRef
    submittedDate
    docDate
    description
  }
  pendingVO: getContractVO(contractID: $contractID, VOStatus: PENDING) {
    ID
    approvedAmt
    submissionAmt
    VOType
    docNo
    docRef
    submittedDate
    docDate
    description
  }
}
    `;

/**
 * __useGetClientPendingVoQuery__
 *
 * To run a query within a React component, call `useGetClientPendingVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientPendingVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientPendingVoQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetClientPendingVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientPendingVoQuery, GetClientPendingVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientPendingVoQuery, GetClientPendingVoQueryVariables>(GetClientPendingVoDocument, baseOptions);
      }
export function useGetClientPendingVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientPendingVoQuery, GetClientPendingVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientPendingVoQuery, GetClientPendingVoQueryVariables>(GetClientPendingVoDocument, baseOptions);
        }
export type GetClientPendingVoQueryHookResult = ReturnType<typeof useGetClientPendingVoQuery>;
export type GetClientPendingVoLazyQueryHookResult = ReturnType<typeof useGetClientPendingVoLazyQuery>;
export type GetClientPendingVoQueryResult = ApolloReactCommon.QueryResult<GetClientPendingVoQuery, GetClientPendingVoQueryVariables>;
export const GetWbsDocument = gql`
    query getWBS($ID: String, $budgetID: String, $contractID: String) {
  getWBS(ID: $ID, contractID: $contractID) {
    ID
    parentWBSID
    finalSequence
    parentWbs {
      ID
      name
      startDate
      endDate
    }
    contractID
    name
    parentName
    startDate
    endDate
    isLastNode
    isDefault
    sequence
    childrenIDs
    wbsParentChildSequence
    totalCost(budgetID: $budgetID)
    totalRevenue(budgetID: $budgetID)
    costByClass(budgetID: $budgetID)
    revenueDetail(budgetID: $budgetID, contractID: $contractID) {
      ID
      wbsID
      budgetAmt
      creditTerm
      previousAmt
      revenueCategory {
        ID
        name
      }
    }
  }
}
    `;

/**
 * __useGetWbsQuery__
 *
 * To run a query within a React component, call `useGetWbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      budgetID: // value for 'budgetID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetWbsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsQuery, GetWbsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsQuery, GetWbsQueryVariables>(GetWbsDocument, baseOptions);
      }
export function useGetWbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsQuery, GetWbsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsQuery, GetWbsQueryVariables>(GetWbsDocument, baseOptions);
        }
export type GetWbsQueryHookResult = ReturnType<typeof useGetWbsQuery>;
export type GetWbsLazyQueryHookResult = ReturnType<typeof useGetWbsLazyQuery>;
export type GetWbsQueryResult = ApolloReactCommon.QueryResult<GetWbsQuery, GetWbsQueryVariables>;
export const GetMultipleWbsDocument = gql`
    query getMultipleWBS($contractIDs: [String!]) {
  getMultipleWBS(contractIDs: $contractIDs) {
    ID
    parentWBSID
    parentWbs {
      ID
      name
      startDate
      endDate
    }
    contractID
    name
    parentName
    startDate
    endDate
    isLastNode
    isDefault
    sequence
    childrenIDs
    wbsParentChildSequence
  }
}
    `;

/**
 * __useGetMultipleWbsQuery__
 *
 * To run a query within a React component, call `useGetMultipleWbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultipleWbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultipleWbsQuery({
 *   variables: {
 *      contractIDs: // value for 'contractIDs'
 *   },
 * });
 */
export function useGetMultipleWbsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMultipleWbsQuery, GetMultipleWbsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMultipleWbsQuery, GetMultipleWbsQueryVariables>(GetMultipleWbsDocument, baseOptions);
      }
export function useGetMultipleWbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMultipleWbsQuery, GetMultipleWbsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMultipleWbsQuery, GetMultipleWbsQueryVariables>(GetMultipleWbsDocument, baseOptions);
        }
export type GetMultipleWbsQueryHookResult = ReturnType<typeof useGetMultipleWbsQuery>;
export type GetMultipleWbsLazyQueryHookResult = ReturnType<typeof useGetMultipleWbsLazyQuery>;
export type GetMultipleWbsQueryResult = ApolloReactCommon.QueryResult<GetMultipleWbsQuery, GetMultipleWbsQueryVariables>;
export const GetWbsNameDocument = gql`
    query getWBSName($ID: String, $contractID: String, $isLastNode: Boolean) {
  getWBS(ID: $ID, contractID: $contractID, isLastNode: $isLastNode) {
    ID
    parentWBSID
    parentWbs {
      ID
      name
      startDate
      endDate
    }
    contractID
    name
    parentName
    startDate
    endDate
    isLastNode
  }
}
    `;

/**
 * __useGetWbsNameQuery__
 *
 * To run a query within a React component, call `useGetWbsNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsNameQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      isLastNode: // value for 'isLastNode'
 *   },
 * });
 */
export function useGetWbsNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsNameQuery, GetWbsNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsNameQuery, GetWbsNameQueryVariables>(GetWbsNameDocument, baseOptions);
      }
export function useGetWbsNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsNameQuery, GetWbsNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsNameQuery, GetWbsNameQueryVariables>(GetWbsNameDocument, baseOptions);
        }
export type GetWbsNameQueryHookResult = ReturnType<typeof useGetWbsNameQuery>;
export type GetWbsNameLazyQueryHookResult = ReturnType<typeof useGetWbsNameLazyQuery>;
export type GetWbsNameQueryResult = ApolloReactCommon.QueryResult<GetWbsNameQuery, GetWbsNameQueryVariables>;
export const GetWbsTotalCostRevenueDocument = gql`
    query getWbsTotalCostRevenue($contractID: String!, $budgetID: String) {
  getWbsTotalCostRevenue(contractID: $contractID, budgetID: $budgetID)
}
    `;

/**
 * __useGetWbsTotalCostRevenueQuery__
 *
 * To run a query within a React component, call `useGetWbsTotalCostRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsTotalCostRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsTotalCostRevenueQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      budgetID: // value for 'budgetID'
 *   },
 * });
 */
export function useGetWbsTotalCostRevenueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsTotalCostRevenueQuery, GetWbsTotalCostRevenueQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsTotalCostRevenueQuery, GetWbsTotalCostRevenueQueryVariables>(GetWbsTotalCostRevenueDocument, baseOptions);
      }
export function useGetWbsTotalCostRevenueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsTotalCostRevenueQuery, GetWbsTotalCostRevenueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsTotalCostRevenueQuery, GetWbsTotalCostRevenueQueryVariables>(GetWbsTotalCostRevenueDocument, baseOptions);
        }
export type GetWbsTotalCostRevenueQueryHookResult = ReturnType<typeof useGetWbsTotalCostRevenueQuery>;
export type GetWbsTotalCostRevenueLazyQueryHookResult = ReturnType<typeof useGetWbsTotalCostRevenueLazyQuery>;
export type GetWbsTotalCostRevenueQueryResult = ApolloReactCommon.QueryResult<GetWbsTotalCostRevenueQuery, GetWbsTotalCostRevenueQueryVariables>;
export const CreateWbsDocument = gql`
    mutation createWBS($input: WBSInput!) {
  createWBS(input: $input) {
    ID
    sequence
  }
}
    `;
export type CreateWbsMutationFn = ApolloReactCommon.MutationFunction<CreateWbsMutation, CreateWbsMutationVariables>;

/**
 * __useCreateWbsMutation__
 *
 * To run a mutation, you first call `useCreateWbsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWbsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWbsMutation, { data, loading, error }] = useCreateWbsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWbsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWbsMutation, CreateWbsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWbsMutation, CreateWbsMutationVariables>(CreateWbsDocument, baseOptions);
      }
export type CreateWbsMutationHookResult = ReturnType<typeof useCreateWbsMutation>;
export type CreateWbsMutationResult = ApolloReactCommon.MutationResult<CreateWbsMutation>;
export type CreateWbsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWbsMutation, CreateWbsMutationVariables>;
export const UpdateWbsDocument = gql`
    mutation updateWBS($input: WBSInput!) {
  updateWBS(input: $input)
}
    `;
export type UpdateWbsMutationFn = ApolloReactCommon.MutationFunction<UpdateWbsMutation, UpdateWbsMutationVariables>;

/**
 * __useUpdateWbsMutation__
 *
 * To run a mutation, you first call `useUpdateWbsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWbsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWbsMutation, { data, loading, error }] = useUpdateWbsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWbsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWbsMutation, UpdateWbsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWbsMutation, UpdateWbsMutationVariables>(UpdateWbsDocument, baseOptions);
      }
export type UpdateWbsMutationHookResult = ReturnType<typeof useUpdateWbsMutation>;
export type UpdateWbsMutationResult = ApolloReactCommon.MutationResult<UpdateWbsMutation>;
export type UpdateWbsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWbsMutation, UpdateWbsMutationVariables>;
export const DeleteWbsDocument = gql`
    mutation deleteWBS($ID: String!) {
  deleteWBS(ID: $ID)
}
    `;
export type DeleteWbsMutationFn = ApolloReactCommon.MutationFunction<DeleteWbsMutation, DeleteWbsMutationVariables>;

/**
 * __useDeleteWbsMutation__
 *
 * To run a mutation, you first call `useDeleteWbsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWbsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWbsMutation, { data, loading, error }] = useDeleteWbsMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteWbsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWbsMutation, DeleteWbsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteWbsMutation, DeleteWbsMutationVariables>(DeleteWbsDocument, baseOptions);
      }
export type DeleteWbsMutationHookResult = ReturnType<typeof useDeleteWbsMutation>;
export type DeleteWbsMutationResult = ApolloReactCommon.MutationResult<DeleteWbsMutation>;
export type DeleteWbsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWbsMutation, DeleteWbsMutationVariables>;
export const MoveSequenceWbsDocument = gql`
    mutation moveSequenceWBS($moveDown: Boolean, $ID: String!) {
  moveSequenceWBS(moveDown: $moveDown, ID: $ID)
}
    `;
export type MoveSequenceWbsMutationFn = ApolloReactCommon.MutationFunction<MoveSequenceWbsMutation, MoveSequenceWbsMutationVariables>;

/**
 * __useMoveSequenceWbsMutation__
 *
 * To run a mutation, you first call `useMoveSequenceWbsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveSequenceWbsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveSequenceWbsMutation, { data, loading, error }] = useMoveSequenceWbsMutation({
 *   variables: {
 *      moveDown: // value for 'moveDown'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useMoveSequenceWbsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveSequenceWbsMutation, MoveSequenceWbsMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveSequenceWbsMutation, MoveSequenceWbsMutationVariables>(MoveSequenceWbsDocument, baseOptions);
      }
export type MoveSequenceWbsMutationHookResult = ReturnType<typeof useMoveSequenceWbsMutation>;
export type MoveSequenceWbsMutationResult = ApolloReactCommon.MutationResult<MoveSequenceWbsMutation>;
export type MoveSequenceWbsMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveSequenceWbsMutation, MoveSequenceWbsMutationVariables>;
export const WbsBudgetTransferResourceDocument = gql`
    query WBSBudgetTransferResource($wbsID: String!) {
  getWbsBudgetDetail(wbsID: $wbsID) {
    costCategoryID
    costCategory {
      costItem {
        costItemID: ID
        name
      }
    }
  }
}
    `;

/**
 * __useWbsBudgetTransferResourceQuery__
 *
 * To run a query within a React component, call `useWbsBudgetTransferResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbsBudgetTransferResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbsBudgetTransferResourceQuery({
 *   variables: {
 *      wbsID: // value for 'wbsID'
 *   },
 * });
 */
export function useWbsBudgetTransferResourceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WbsBudgetTransferResourceQuery, WbsBudgetTransferResourceQueryVariables>) {
        return ApolloReactHooks.useQuery<WbsBudgetTransferResourceQuery, WbsBudgetTransferResourceQueryVariables>(WbsBudgetTransferResourceDocument, baseOptions);
      }
export function useWbsBudgetTransferResourceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WbsBudgetTransferResourceQuery, WbsBudgetTransferResourceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WbsBudgetTransferResourceQuery, WbsBudgetTransferResourceQueryVariables>(WbsBudgetTransferResourceDocument, baseOptions);
        }
export type WbsBudgetTransferResourceQueryHookResult = ReturnType<typeof useWbsBudgetTransferResourceQuery>;
export type WbsBudgetTransferResourceLazyQueryHookResult = ReturnType<typeof useWbsBudgetTransferResourceLazyQuery>;
export type WbsBudgetTransferResourceQueryResult = ApolloReactCommon.QueryResult<WbsBudgetTransferResourceQuery, WbsBudgetTransferResourceQueryVariables>;
export const GetStatusProjectWbsBudgetingDocument = gql`
    query getStatusProjectWBSBudgeting($contractID: String) {
  wbsBudgetInfo(contractID: $contractID)
}
    `;

/**
 * __useGetStatusProjectWbsBudgetingQuery__
 *
 * To run a query within a React component, call `useGetStatusProjectWbsBudgetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusProjectWbsBudgetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusProjectWbsBudgetingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetStatusProjectWbsBudgetingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStatusProjectWbsBudgetingQuery, GetStatusProjectWbsBudgetingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStatusProjectWbsBudgetingQuery, GetStatusProjectWbsBudgetingQueryVariables>(GetStatusProjectWbsBudgetingDocument, baseOptions);
      }
export function useGetStatusProjectWbsBudgetingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStatusProjectWbsBudgetingQuery, GetStatusProjectWbsBudgetingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStatusProjectWbsBudgetingQuery, GetStatusProjectWbsBudgetingQueryVariables>(GetStatusProjectWbsBudgetingDocument, baseOptions);
        }
export type GetStatusProjectWbsBudgetingQueryHookResult = ReturnType<typeof useGetStatusProjectWbsBudgetingQuery>;
export type GetStatusProjectWbsBudgetingLazyQueryHookResult = ReturnType<typeof useGetStatusProjectWbsBudgetingLazyQuery>;
export type GetStatusProjectWbsBudgetingQueryResult = ApolloReactCommon.QueryResult<GetStatusProjectWbsBudgetingQuery, GetStatusProjectWbsBudgetingQueryVariables>;
export const GetWbsParentChildDocument = gql`
    query getWBSParentChild($contractID: String!, $selectedID: String, $isDefault: Boolean) {
  getWBSParentChild(contractID: $contractID, selectedID: $selectedID, isDefault: $isDefault)
}
    `;

/**
 * __useGetWbsParentChildQuery__
 *
 * To run a query within a React component, call `useGetWbsParentChildQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsParentChildQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsParentChildQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      selectedID: // value for 'selectedID'
 *      isDefault: // value for 'isDefault'
 *   },
 * });
 */
export function useGetWbsParentChildQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsParentChildQuery, GetWbsParentChildQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsParentChildQuery, GetWbsParentChildQueryVariables>(GetWbsParentChildDocument, baseOptions);
      }
export function useGetWbsParentChildLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsParentChildQuery, GetWbsParentChildQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsParentChildQuery, GetWbsParentChildQueryVariables>(GetWbsParentChildDocument, baseOptions);
        }
export type GetWbsParentChildQueryHookResult = ReturnType<typeof useGetWbsParentChildQuery>;
export type GetWbsParentChildLazyQueryHookResult = ReturnType<typeof useGetWbsParentChildLazyQuery>;
export type GetWbsParentChildQueryResult = ApolloReactCommon.QueryResult<GetWbsParentChildQuery, GetWbsParentChildQueryVariables>;
export const GetWbsScheduleDocument = gql`
    query getWbsSchedule($ID: String, $wbsID: String, $wbsBudgetDetailID: String, $orderByAsc: String) {
  getWbsSchedule(ID: $ID, wbsID: $wbsID, orderByAsc: $orderByAsc) {
    ID
    period
    startDate
    endDate
    code
    budgetDetailScheduleAmt(wbsBudgetDetailID: $wbsBudgetDetailID)
    wbsBudgetDetailSchedule {
      ID
      wbsBudgetDetailID
      wbsScheduleID
      scheduleAmt
    }
  }
}
    `;

/**
 * __useGetWbsScheduleQuery__
 *
 * To run a query within a React component, call `useGetWbsScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsScheduleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      wbsID: // value for 'wbsID'
 *      wbsBudgetDetailID: // value for 'wbsBudgetDetailID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetWbsScheduleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsScheduleQuery, GetWbsScheduleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsScheduleQuery, GetWbsScheduleQueryVariables>(GetWbsScheduleDocument, baseOptions);
      }
export function useGetWbsScheduleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsScheduleQuery, GetWbsScheduleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsScheduleQuery, GetWbsScheduleQueryVariables>(GetWbsScheduleDocument, baseOptions);
        }
export type GetWbsScheduleQueryHookResult = ReturnType<typeof useGetWbsScheduleQuery>;
export type GetWbsScheduleLazyQueryHookResult = ReturnType<typeof useGetWbsScheduleLazyQuery>;
export type GetWbsScheduleQueryResult = ApolloReactCommon.QueryResult<GetWbsScheduleQuery, GetWbsScheduleQueryVariables>;
export const ActionOnBudgetStatusDocument = gql`
    mutation actionOnBudgetStatus($input: WbsBudgetActionInput!, $ID: String!) {
  actionOnBudgetStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnBudgetStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnBudgetStatusMutation, ActionOnBudgetStatusMutationVariables>;

/**
 * __useActionOnBudgetStatusMutation__
 *
 * To run a mutation, you first call `useActionOnBudgetStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnBudgetStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnBudgetStatusMutation, { data, loading, error }] = useActionOnBudgetStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnBudgetStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnBudgetStatusMutation, ActionOnBudgetStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnBudgetStatusMutation, ActionOnBudgetStatusMutationVariables>(ActionOnBudgetStatusDocument, baseOptions);
      }
export type ActionOnBudgetStatusMutationHookResult = ReturnType<typeof useActionOnBudgetStatusMutation>;
export type ActionOnBudgetStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnBudgetStatusMutation>;
export type ActionOnBudgetStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnBudgetStatusMutation, ActionOnBudgetStatusMutationVariables>;
export const GetWbsBudgetRevisionDocument = gql`
    query getWBSBudgetRevision($contractID: String, $budgetID: String) {
  getWBS(contractID: $contractID) {
    ID
    parentWBSID
    parentWbs {
      ID
      name
      startDate
      endDate
    }
    name
    parentName
    sequence
    startDate
    endDate
    isLastNode
    isDefault
    originalCost(budgetID: $budgetID)
    totalCost(budgetID: $budgetID)
    originalRevenue(budgetID: $budgetID)
    totalRevenue(budgetID: $budgetID)
    costByClass(budgetID: $budgetID)
    originalCostByClass(budgetID: $budgetID)
    originalRevenueDetail(budgetID: $budgetID) {
      ID
      budgetAmt
      creditTerm
      revenueCategory {
        ID
        name
      }
    }
    revenueDetail(budgetID: $budgetID) {
      ID
      budgetAmt
      creditTerm
      revenueCategory {
        ID
        name
      }
    }
  }
}
    `;

/**
 * __useGetWbsBudgetRevisionQuery__
 *
 * To run a query within a React component, call `useGetWbsBudgetRevisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsBudgetRevisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsBudgetRevisionQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      budgetID: // value for 'budgetID'
 *   },
 * });
 */
export function useGetWbsBudgetRevisionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsBudgetRevisionQuery, GetWbsBudgetRevisionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsBudgetRevisionQuery, GetWbsBudgetRevisionQueryVariables>(GetWbsBudgetRevisionDocument, baseOptions);
      }
export function useGetWbsBudgetRevisionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsBudgetRevisionQuery, GetWbsBudgetRevisionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsBudgetRevisionQuery, GetWbsBudgetRevisionQueryVariables>(GetWbsBudgetRevisionDocument, baseOptions);
        }
export type GetWbsBudgetRevisionQueryHookResult = ReturnType<typeof useGetWbsBudgetRevisionQuery>;
export type GetWbsBudgetRevisionLazyQueryHookResult = ReturnType<typeof useGetWbsBudgetRevisionLazyQuery>;
export type GetWbsBudgetRevisionQueryResult = ApolloReactCommon.QueryResult<GetWbsBudgetRevisionQuery, GetWbsBudgetRevisionQueryVariables>;
export const DefaultWbsDocument = gql`
    mutation defaultWBS($ID: String!, $isDefault: Boolean!, $contractID: String!) {
  defaultWBS(ID: $ID, isDefault: $isDefault, contractID: $contractID)
}
    `;
export type DefaultWbsMutationFn = ApolloReactCommon.MutationFunction<DefaultWbsMutation, DefaultWbsMutationVariables>;

/**
 * __useDefaultWbsMutation__
 *
 * To run a mutation, you first call `useDefaultWbsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDefaultWbsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [defaultWbsMutation, { data, loading, error }] = useDefaultWbsMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      isDefault: // value for 'isDefault'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useDefaultWbsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DefaultWbsMutation, DefaultWbsMutationVariables>) {
        return ApolloReactHooks.useMutation<DefaultWbsMutation, DefaultWbsMutationVariables>(DefaultWbsDocument, baseOptions);
      }
export type DefaultWbsMutationHookResult = ReturnType<typeof useDefaultWbsMutation>;
export type DefaultWbsMutationResult = ApolloReactCommon.MutationResult<DefaultWbsMutation>;
export type DefaultWbsMutationOptions = ApolloReactCommon.BaseMutationOptions<DefaultWbsMutation, DefaultWbsMutationVariables>;
export const CheckWbsBudgetApprovedDocument = gql`
    query checkWBSBudgetApproved($contractID: String!) {
  checkWBSBudgetApproved(contractID: $contractID)
}
    `;

/**
 * __useCheckWbsBudgetApprovedQuery__
 *
 * To run a query within a React component, call `useCheckWbsBudgetApprovedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckWbsBudgetApprovedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckWbsBudgetApprovedQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useCheckWbsBudgetApprovedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckWbsBudgetApprovedQuery, CheckWbsBudgetApprovedQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckWbsBudgetApprovedQuery, CheckWbsBudgetApprovedQueryVariables>(CheckWbsBudgetApprovedDocument, baseOptions);
      }
export function useCheckWbsBudgetApprovedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckWbsBudgetApprovedQuery, CheckWbsBudgetApprovedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckWbsBudgetApprovedQuery, CheckWbsBudgetApprovedQueryVariables>(CheckWbsBudgetApprovedDocument, baseOptions);
        }
export type CheckWbsBudgetApprovedQueryHookResult = ReturnType<typeof useCheckWbsBudgetApprovedQuery>;
export type CheckWbsBudgetApprovedLazyQueryHookResult = ReturnType<typeof useCheckWbsBudgetApprovedLazyQuery>;
export type CheckWbsBudgetApprovedQueryResult = ApolloReactCommon.QueryResult<CheckWbsBudgetApprovedQuery, CheckWbsBudgetApprovedQueryVariables>;
export const GetWbsBudgetDocument = gql`
    query getWbsBudget($ID: String, $contractID: String) {
  getWbsBudget(ID: $ID, contractID: $contractID) {
    ID
    contractID
    name
    description
    reason
    version
    budgetStatus
  }
}
    `;

/**
 * __useGetWbsBudgetQuery__
 *
 * To run a query within a React component, call `useGetWbsBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsBudgetQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetWbsBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsBudgetQuery, GetWbsBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsBudgetQuery, GetWbsBudgetQueryVariables>(GetWbsBudgetDocument, baseOptions);
      }
export function useGetWbsBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsBudgetQuery, GetWbsBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsBudgetQuery, GetWbsBudgetQueryVariables>(GetWbsBudgetDocument, baseOptions);
        }
export type GetWbsBudgetQueryHookResult = ReturnType<typeof useGetWbsBudgetQuery>;
export type GetWbsBudgetLazyQueryHookResult = ReturnType<typeof useGetWbsBudgetLazyQuery>;
export type GetWbsBudgetQueryResult = ApolloReactCommon.QueryResult<GetWbsBudgetQuery, GetWbsBudgetQueryVariables>;
export const GetWbsBudgetDetailByClassDocument = gql`
    query getWBSBudgetDetailByClass($costClass: String!, $budgetID: String!, $wbsID: String!, $originalBudget: Boolean) {
  getWbsBudgetDetailByClass(costClass: $costClass, budgetID: $budgetID, wbsID: $wbsID, originalBudget: $originalBudget)
}
    `;

/**
 * __useGetWbsBudgetDetailByClassQuery__
 *
 * To run a query within a React component, call `useGetWbsBudgetDetailByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsBudgetDetailByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsBudgetDetailByClassQuery({
 *   variables: {
 *      costClass: // value for 'costClass'
 *      budgetID: // value for 'budgetID'
 *      wbsID: // value for 'wbsID'
 *      originalBudget: // value for 'originalBudget'
 *   },
 * });
 */
export function useGetWbsBudgetDetailByClassQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsBudgetDetailByClassQuery, GetWbsBudgetDetailByClassQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsBudgetDetailByClassQuery, GetWbsBudgetDetailByClassQueryVariables>(GetWbsBudgetDetailByClassDocument, baseOptions);
      }
export function useGetWbsBudgetDetailByClassLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsBudgetDetailByClassQuery, GetWbsBudgetDetailByClassQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsBudgetDetailByClassQuery, GetWbsBudgetDetailByClassQueryVariables>(GetWbsBudgetDetailByClassDocument, baseOptions);
        }
export type GetWbsBudgetDetailByClassQueryHookResult = ReturnType<typeof useGetWbsBudgetDetailByClassQuery>;
export type GetWbsBudgetDetailByClassLazyQueryHookResult = ReturnType<typeof useGetWbsBudgetDetailByClassLazyQuery>;
export type GetWbsBudgetDetailByClassQueryResult = ApolloReactCommon.QueryResult<GetWbsBudgetDetailByClassQuery, GetWbsBudgetDetailByClassQueryVariables>;
export const CheckWbsBudgetDetailDocument = gql`
    query checkWbsBudgetDetail($costID: String, $wbsID: String!, $contractID: String!) {
  checkWbsBudgetDetail(costID: $costID, wbsID: $wbsID, contractID: $contractID) {
    ID
    budgetID
    uomID
    costItemID
    creditTerm
    quantity
    unitPrice
    budgetAmt
    remark
    utilisedQty(costID: $costID, wbsID: $wbsID, contractID: $contractID)
    utilisedAmt(costID: $costID, wbsID: $wbsID, contractID: $contractID)
    reservedQty(costID: $costID, wbsID: $wbsID, contractID: $contractID)
    reservedAmt(costID: $costID, wbsID: $wbsID, contractID: $contractID)
    balanceAmt(costID: $costID, wbsID: $wbsID, contractID: $contractID)
    balanceQty(costID: $costID, wbsID: $wbsID, contractID: $contractID)
  }
}
    `;

/**
 * __useCheckWbsBudgetDetailQuery__
 *
 * To run a query within a React component, call `useCheckWbsBudgetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckWbsBudgetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckWbsBudgetDetailQuery({
 *   variables: {
 *      costID: // value for 'costID'
 *      wbsID: // value for 'wbsID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useCheckWbsBudgetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckWbsBudgetDetailQuery, CheckWbsBudgetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckWbsBudgetDetailQuery, CheckWbsBudgetDetailQueryVariables>(CheckWbsBudgetDetailDocument, baseOptions);
      }
export function useCheckWbsBudgetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckWbsBudgetDetailQuery, CheckWbsBudgetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckWbsBudgetDetailQuery, CheckWbsBudgetDetailQueryVariables>(CheckWbsBudgetDetailDocument, baseOptions);
        }
export type CheckWbsBudgetDetailQueryHookResult = ReturnType<typeof useCheckWbsBudgetDetailQuery>;
export type CheckWbsBudgetDetailLazyQueryHookResult = ReturnType<typeof useCheckWbsBudgetDetailLazyQuery>;
export type CheckWbsBudgetDetailQueryResult = ApolloReactCommon.QueryResult<CheckWbsBudgetDetailQuery, CheckWbsBudgetDetailQueryVariables>;
export const CheckMultipleWbsBudgetDetailDocument = gql`
    query checkMultipleWbsBudgetDetail($contractID: String!, $itemChecker: String!, $docRefID: String, $input: [CheckMultipleWbsBudgetDetailInput!]!) {
  checkMultipleWbsBudgetDetail(contractID: $contractID, itemChecker: $itemChecker, docRefID: $docRefID, input: $input)
}
    `;

/**
 * __useCheckMultipleWbsBudgetDetailQuery__
 *
 * To run a query within a React component, call `useCheckMultipleWbsBudgetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckMultipleWbsBudgetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckMultipleWbsBudgetDetailQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      itemChecker: // value for 'itemChecker'
 *      docRefID: // value for 'docRefID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckMultipleWbsBudgetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckMultipleWbsBudgetDetailQuery, CheckMultipleWbsBudgetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckMultipleWbsBudgetDetailQuery, CheckMultipleWbsBudgetDetailQueryVariables>(CheckMultipleWbsBudgetDetailDocument, baseOptions);
      }
export function useCheckMultipleWbsBudgetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckMultipleWbsBudgetDetailQuery, CheckMultipleWbsBudgetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckMultipleWbsBudgetDetailQuery, CheckMultipleWbsBudgetDetailQueryVariables>(CheckMultipleWbsBudgetDetailDocument, baseOptions);
        }
export type CheckMultipleWbsBudgetDetailQueryHookResult = ReturnType<typeof useCheckMultipleWbsBudgetDetailQuery>;
export type CheckMultipleWbsBudgetDetailLazyQueryHookResult = ReturnType<typeof useCheckMultipleWbsBudgetDetailLazyQuery>;
export type CheckMultipleWbsBudgetDetailQueryResult = ApolloReactCommon.QueryResult<CheckMultipleWbsBudgetDetailQuery, CheckMultipleWbsBudgetDetailQueryVariables>;
export const CheckSubcontractMultipleWbsBudgetDetailDocument = gql`
    query checkSubcontractMultipleWbsBudgetDetail($contractID: String!, $subcontractID: String, $input: [CheckMultipleWbsBudgetDetailInput!]!) {
  checkSubcontractMultipleWbsBudgetDetail(contractID: $contractID, subcontractID: $subcontractID, input: $input)
}
    `;

/**
 * __useCheckSubcontractMultipleWbsBudgetDetailQuery__
 *
 * To run a query within a React component, call `useCheckSubcontractMultipleWbsBudgetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSubcontractMultipleWbsBudgetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSubcontractMultipleWbsBudgetDetailQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckSubcontractMultipleWbsBudgetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckSubcontractMultipleWbsBudgetDetailQuery, CheckSubcontractMultipleWbsBudgetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckSubcontractMultipleWbsBudgetDetailQuery, CheckSubcontractMultipleWbsBudgetDetailQueryVariables>(CheckSubcontractMultipleWbsBudgetDetailDocument, baseOptions);
      }
export function useCheckSubcontractMultipleWbsBudgetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckSubcontractMultipleWbsBudgetDetailQuery, CheckSubcontractMultipleWbsBudgetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckSubcontractMultipleWbsBudgetDetailQuery, CheckSubcontractMultipleWbsBudgetDetailQueryVariables>(CheckSubcontractMultipleWbsBudgetDetailDocument, baseOptions);
        }
export type CheckSubcontractMultipleWbsBudgetDetailQueryHookResult = ReturnType<typeof useCheckSubcontractMultipleWbsBudgetDetailQuery>;
export type CheckSubcontractMultipleWbsBudgetDetailLazyQueryHookResult = ReturnType<typeof useCheckSubcontractMultipleWbsBudgetDetailLazyQuery>;
export type CheckSubcontractMultipleWbsBudgetDetailQueryResult = ApolloReactCommon.QueryResult<CheckSubcontractMultipleWbsBudgetDetailQuery, CheckSubcontractMultipleWbsBudgetDetailQueryVariables>;
export const GetWbsBudgetTransferHeaderDocument = gql`
    query getWBSBudgetTransferHeader($ID: String, $contractID: String) {
  getWBSBudgetTransferHeader(ID: $ID, contractID: $contractID) {
    ID
    contractID
    fromWbsID
    fromWbs {
      ID
      contractID
      name
    }
    toWbsID
    toWbs {
      ID
      contractID
      name
    }
    transferDate
    rejectionDate
    rejectionRemarks
    budgetStatus
  }
}
    `;

/**
 * __useGetWbsBudgetTransferHeaderQuery__
 *
 * To run a query within a React component, call `useGetWbsBudgetTransferHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsBudgetTransferHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsBudgetTransferHeaderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetWbsBudgetTransferHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsBudgetTransferHeaderQuery, GetWbsBudgetTransferHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsBudgetTransferHeaderQuery, GetWbsBudgetTransferHeaderQueryVariables>(GetWbsBudgetTransferHeaderDocument, baseOptions);
      }
export function useGetWbsBudgetTransferHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsBudgetTransferHeaderQuery, GetWbsBudgetTransferHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsBudgetTransferHeaderQuery, GetWbsBudgetTransferHeaderQueryVariables>(GetWbsBudgetTransferHeaderDocument, baseOptions);
        }
export type GetWbsBudgetTransferHeaderQueryHookResult = ReturnType<typeof useGetWbsBudgetTransferHeaderQuery>;
export type GetWbsBudgetTransferHeaderLazyQueryHookResult = ReturnType<typeof useGetWbsBudgetTransferHeaderLazyQuery>;
export type GetWbsBudgetTransferHeaderQueryResult = ApolloReactCommon.QueryResult<GetWbsBudgetTransferHeaderQuery, GetWbsBudgetTransferHeaderQueryVariables>;
export const CreateWbsBudgetTransferDocument = gql`
    mutation createWbsBudgetTransfer($input: WbsBudgetTransferInput!, $itemInput: [WbsBudgetTransferItemInput!]) {
  createWbsBudgetTransfer(input: $input, itemInput: $itemInput) {
    ID
    fromWbsID
    toWbsID
  }
}
    `;
export type CreateWbsBudgetTransferMutationFn = ApolloReactCommon.MutationFunction<CreateWbsBudgetTransferMutation, CreateWbsBudgetTransferMutationVariables>;

/**
 * __useCreateWbsBudgetTransferMutation__
 *
 * To run a mutation, you first call `useCreateWbsBudgetTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWbsBudgetTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWbsBudgetTransferMutation, { data, loading, error }] = useCreateWbsBudgetTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateWbsBudgetTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWbsBudgetTransferMutation, CreateWbsBudgetTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWbsBudgetTransferMutation, CreateWbsBudgetTransferMutationVariables>(CreateWbsBudgetTransferDocument, baseOptions);
      }
export type CreateWbsBudgetTransferMutationHookResult = ReturnType<typeof useCreateWbsBudgetTransferMutation>;
export type CreateWbsBudgetTransferMutationResult = ApolloReactCommon.MutationResult<CreateWbsBudgetTransferMutation>;
export type CreateWbsBudgetTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWbsBudgetTransferMutation, CreateWbsBudgetTransferMutationVariables>;
export const UpdateWbsBudgetTransferDocument = gql`
    mutation updateWbsBudgetTransfer($input: WbsBudgetTransferInput!, $itemInput: [WbsBudgetTransferItemInput!]) {
  updateWbsBudgetTransfer(input: $input, itemInput: $itemInput)
}
    `;
export type UpdateWbsBudgetTransferMutationFn = ApolloReactCommon.MutationFunction<UpdateWbsBudgetTransferMutation, UpdateWbsBudgetTransferMutationVariables>;

/**
 * __useUpdateWbsBudgetTransferMutation__
 *
 * To run a mutation, you first call `useUpdateWbsBudgetTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWbsBudgetTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWbsBudgetTransferMutation, { data, loading, error }] = useUpdateWbsBudgetTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateWbsBudgetTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWbsBudgetTransferMutation, UpdateWbsBudgetTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWbsBudgetTransferMutation, UpdateWbsBudgetTransferMutationVariables>(UpdateWbsBudgetTransferDocument, baseOptions);
      }
export type UpdateWbsBudgetTransferMutationHookResult = ReturnType<typeof useUpdateWbsBudgetTransferMutation>;
export type UpdateWbsBudgetTransferMutationResult = ApolloReactCommon.MutationResult<UpdateWbsBudgetTransferMutation>;
export type UpdateWbsBudgetTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWbsBudgetTransferMutation, UpdateWbsBudgetTransferMutationVariables>;
export const GetWbsBudgetTransferDetailDocument = gql`
    query getWBSBudgetTransferDetail($wbsBudgetTransferHeaderID: String) {
  getWBSBudgetTransferDetail(wbsBudgetTransferHeaderID: $wbsBudgetTransferHeaderID) {
    wbsBudgetTransferHeaderID
    fromCostCategoryID
    fromCostCategory {
      name
    }
    fromCostItemID
    fromCostItem {
      name
    }
    toCostCategoryID
    toCostCategory {
      name
    }
    toCostItemID
    toCostItem {
      name
    }
    fromQty
    toQty
    transferQty
    transferAmt
    remarks
  }
}
    `;

/**
 * __useGetWbsBudgetTransferDetailQuery__
 *
 * To run a query within a React component, call `useGetWbsBudgetTransferDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsBudgetTransferDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsBudgetTransferDetailQuery({
 *   variables: {
 *      wbsBudgetTransferHeaderID: // value for 'wbsBudgetTransferHeaderID'
 *   },
 * });
 */
export function useGetWbsBudgetTransferDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsBudgetTransferDetailQuery, GetWbsBudgetTransferDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsBudgetTransferDetailQuery, GetWbsBudgetTransferDetailQueryVariables>(GetWbsBudgetTransferDetailDocument, baseOptions);
      }
export function useGetWbsBudgetTransferDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsBudgetTransferDetailQuery, GetWbsBudgetTransferDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsBudgetTransferDetailQuery, GetWbsBudgetTransferDetailQueryVariables>(GetWbsBudgetTransferDetailDocument, baseOptions);
        }
export type GetWbsBudgetTransferDetailQueryHookResult = ReturnType<typeof useGetWbsBudgetTransferDetailQuery>;
export type GetWbsBudgetTransferDetailLazyQueryHookResult = ReturnType<typeof useGetWbsBudgetTransferDetailLazyQuery>;
export type GetWbsBudgetTransferDetailQueryResult = ApolloReactCommon.QueryResult<GetWbsBudgetTransferDetailQuery, GetWbsBudgetTransferDetailQueryVariables>;
export const DeleteBudgetTransferDocument = gql`
    mutation deleteBudgetTransfer($deleteBudgetTransferID: String!) {
  deleteBudgetTransfer(ID: $deleteBudgetTransferID)
}
    `;
export type DeleteBudgetTransferMutationFn = ApolloReactCommon.MutationFunction<DeleteBudgetTransferMutation, DeleteBudgetTransferMutationVariables>;

/**
 * __useDeleteBudgetTransferMutation__
 *
 * To run a mutation, you first call `useDeleteBudgetTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBudgetTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBudgetTransferMutation, { data, loading, error }] = useDeleteBudgetTransferMutation({
 *   variables: {
 *      deleteBudgetTransferID: // value for 'deleteBudgetTransferID'
 *   },
 * });
 */
export function useDeleteBudgetTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBudgetTransferMutation, DeleteBudgetTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBudgetTransferMutation, DeleteBudgetTransferMutationVariables>(DeleteBudgetTransferDocument, baseOptions);
      }
export type DeleteBudgetTransferMutationHookResult = ReturnType<typeof useDeleteBudgetTransferMutation>;
export type DeleteBudgetTransferMutationResult = ApolloReactCommon.MutationResult<DeleteBudgetTransferMutation>;
export type DeleteBudgetTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBudgetTransferMutation, DeleteBudgetTransferMutationVariables>;
export const GetWbsBudgetTransferDocument = gql`
    query getWbsBudgetTransfer($contractID: String!, $wbsBudgetTransferID: String) {
  getWbsBudgetTransfer(contractID: $contractID, wbsBudgetTransferID: $wbsBudgetTransferID)
}
    `;

/**
 * __useGetWbsBudgetTransferQuery__
 *
 * To run a query within a React component, call `useGetWbsBudgetTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsBudgetTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsBudgetTransferQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      wbsBudgetTransferID: // value for 'wbsBudgetTransferID'
 *   },
 * });
 */
export function useGetWbsBudgetTransferQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsBudgetTransferQuery, GetWbsBudgetTransferQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsBudgetTransferQuery, GetWbsBudgetTransferQueryVariables>(GetWbsBudgetTransferDocument, baseOptions);
      }
export function useGetWbsBudgetTransferLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsBudgetTransferQuery, GetWbsBudgetTransferQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsBudgetTransferQuery, GetWbsBudgetTransferQueryVariables>(GetWbsBudgetTransferDocument, baseOptions);
        }
export type GetWbsBudgetTransferQueryHookResult = ReturnType<typeof useGetWbsBudgetTransferQuery>;
export type GetWbsBudgetTransferLazyQueryHookResult = ReturnType<typeof useGetWbsBudgetTransferLazyQuery>;
export type GetWbsBudgetTransferQueryResult = ApolloReactCommon.QueryResult<GetWbsBudgetTransferQuery, GetWbsBudgetTransferQueryVariables>;
export const ActionOnBudgetTransferStatusDocument = gql`
    mutation actionOnBudgetTransferStatus($ID: String!, $input: WBSBudgetTransferActionInput!) {
  actionOnBudgetTransferStatus(ID: $ID, input: $input)
}
    `;
export type ActionOnBudgetTransferStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnBudgetTransferStatusMutation, ActionOnBudgetTransferStatusMutationVariables>;

/**
 * __useActionOnBudgetTransferStatusMutation__
 *
 * To run a mutation, you first call `useActionOnBudgetTransferStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnBudgetTransferStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnBudgetTransferStatusMutation, { data, loading, error }] = useActionOnBudgetTransferStatusMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActionOnBudgetTransferStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnBudgetTransferStatusMutation, ActionOnBudgetTransferStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnBudgetTransferStatusMutation, ActionOnBudgetTransferStatusMutationVariables>(ActionOnBudgetTransferStatusDocument, baseOptions);
      }
export type ActionOnBudgetTransferStatusMutationHookResult = ReturnType<typeof useActionOnBudgetTransferStatusMutation>;
export type ActionOnBudgetTransferStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnBudgetTransferStatusMutation>;
export type ActionOnBudgetTransferStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnBudgetTransferStatusMutation, ActionOnBudgetTransferStatusMutationVariables>;
export const GetCostCategoryWbsBudgetDetailDocument = gql`
    query getCostCategoryWbsBudgetDetail($costID: String, $wbsID: String!, $contractID: String!) {
  checkWbsBudgetDetail(costID: $costID, wbsID: $wbsID, contractID: $contractID) {
    ID
    budgetID
    uomID
    costItemID
    costCategoryID
    creditTerm
    quantity
    unitPrice
    budgetAmt
    remark
    originalQty(contractID: $contractID)
    originalAmt(contractID: $contractID)
    utilisedQty(costID: $costID, wbsID: $wbsID, contractID: $contractID)
    utilisedAmt(costID: $costID, wbsID: $wbsID, contractID: $contractID)
    reservedQty(costID: $costID, wbsID: $wbsID, contractID: $contractID)
    reservedAmt(costID: $costID, wbsID: $wbsID, contractID: $contractID)
    balanceAmt(costID: $costID, wbsID: $wbsID, contractID: $contractID)
    balanceQty(costID: $costID, wbsID: $wbsID, contractID: $contractID)
  }
}
    `;

/**
 * __useGetCostCategoryWbsBudgetDetailQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryWbsBudgetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryWbsBudgetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryWbsBudgetDetailQuery({
 *   variables: {
 *      costID: // value for 'costID'
 *      wbsID: // value for 'wbsID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetCostCategoryWbsBudgetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryWbsBudgetDetailQuery, GetCostCategoryWbsBudgetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryWbsBudgetDetailQuery, GetCostCategoryWbsBudgetDetailQueryVariables>(GetCostCategoryWbsBudgetDetailDocument, baseOptions);
      }
export function useGetCostCategoryWbsBudgetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryWbsBudgetDetailQuery, GetCostCategoryWbsBudgetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryWbsBudgetDetailQuery, GetCostCategoryWbsBudgetDetailQueryVariables>(GetCostCategoryWbsBudgetDetailDocument, baseOptions);
        }
export type GetCostCategoryWbsBudgetDetailQueryHookResult = ReturnType<typeof useGetCostCategoryWbsBudgetDetailQuery>;
export type GetCostCategoryWbsBudgetDetailLazyQueryHookResult = ReturnType<typeof useGetCostCategoryWbsBudgetDetailLazyQuery>;
export type GetCostCategoryWbsBudgetDetailQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryWbsBudgetDetailQuery, GetCostCategoryWbsBudgetDetailQueryVariables>;
export const GetBudgetTransferCostCategoryBalanceDocument = gql`
    query getBudgetTransferCostCategoryBalance($contractID: String!, $itemInput: [WbsBudgetTransferCostCategoryBalanceFilterArgs!]!) {
  getBudgetTransferCostCategoryBalance(contractID: $contractID, itemInput: $itemInput)
}
    `;

/**
 * __useGetBudgetTransferCostCategoryBalanceQuery__
 *
 * To run a query within a React component, call `useGetBudgetTransferCostCategoryBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetTransferCostCategoryBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetTransferCostCategoryBalanceQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useGetBudgetTransferCostCategoryBalanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBudgetTransferCostCategoryBalanceQuery, GetBudgetTransferCostCategoryBalanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBudgetTransferCostCategoryBalanceQuery, GetBudgetTransferCostCategoryBalanceQueryVariables>(GetBudgetTransferCostCategoryBalanceDocument, baseOptions);
      }
export function useGetBudgetTransferCostCategoryBalanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBudgetTransferCostCategoryBalanceQuery, GetBudgetTransferCostCategoryBalanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBudgetTransferCostCategoryBalanceQuery, GetBudgetTransferCostCategoryBalanceQueryVariables>(GetBudgetTransferCostCategoryBalanceDocument, baseOptions);
        }
export type GetBudgetTransferCostCategoryBalanceQueryHookResult = ReturnType<typeof useGetBudgetTransferCostCategoryBalanceQuery>;
export type GetBudgetTransferCostCategoryBalanceLazyQueryHookResult = ReturnType<typeof useGetBudgetTransferCostCategoryBalanceLazyQuery>;
export type GetBudgetTransferCostCategoryBalanceQueryResult = ApolloReactCommon.QueryResult<GetBudgetTransferCostCategoryBalanceQuery, GetBudgetTransferCostCategoryBalanceQueryVariables>;
export const GetWbsCostAllocationByClassDocument = gql`
    query getWbsCostAllocationByClass($budgetID: String!, $wbsID: String!) {
  getWbsCostAllocationByClass(budgetID: $budgetID, wbsID: $wbsID)
}
    `;

/**
 * __useGetWbsCostAllocationByClassQuery__
 *
 * To run a query within a React component, call `useGetWbsCostAllocationByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsCostAllocationByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsCostAllocationByClassQuery({
 *   variables: {
 *      budgetID: // value for 'budgetID'
 *      wbsID: // value for 'wbsID'
 *   },
 * });
 */
export function useGetWbsCostAllocationByClassQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsCostAllocationByClassQuery, GetWbsCostAllocationByClassQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsCostAllocationByClassQuery, GetWbsCostAllocationByClassQueryVariables>(GetWbsCostAllocationByClassDocument, baseOptions);
      }
export function useGetWbsCostAllocationByClassLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsCostAllocationByClassQuery, GetWbsCostAllocationByClassQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsCostAllocationByClassQuery, GetWbsCostAllocationByClassQueryVariables>(GetWbsCostAllocationByClassDocument, baseOptions);
        }
export type GetWbsCostAllocationByClassQueryHookResult = ReturnType<typeof useGetWbsCostAllocationByClassQuery>;
export type GetWbsCostAllocationByClassLazyQueryHookResult = ReturnType<typeof useGetWbsCostAllocationByClassLazyQuery>;
export type GetWbsCostAllocationByClassQueryResult = ApolloReactCommon.QueryResult<GetWbsCostAllocationByClassQuery, GetWbsCostAllocationByClassQueryVariables>;
export const GetWbsForCostDocument = gql`
    query getWBSForCost($ID: String, $contractID: String) {
  getWBS(ID: $ID, contractID: $contractID) {
    ID
    name
    parentWBSID
    parentName
    startDate
    endDate
    isLastNode
    sequence
    originalCost
    totalCost
    originalRevenue
    totalRevenue
    contract {
      ID
      title
    }
  }
}
    `;

/**
 * __useGetWbsForCostQuery__
 *
 * To run a query within a React component, call `useGetWbsForCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsForCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsForCostQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetWbsForCostQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsForCostQuery, GetWbsForCostQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsForCostQuery, GetWbsForCostQueryVariables>(GetWbsForCostDocument, baseOptions);
      }
export function useGetWbsForCostLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsForCostQuery, GetWbsForCostQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsForCostQuery, GetWbsForCostQueryVariables>(GetWbsForCostDocument, baseOptions);
        }
export type GetWbsForCostQueryHookResult = ReturnType<typeof useGetWbsForCostQuery>;
export type GetWbsForCostLazyQueryHookResult = ReturnType<typeof useGetWbsForCostLazyQuery>;
export type GetWbsForCostQueryResult = ApolloReactCommon.QueryResult<GetWbsForCostQuery, GetWbsForCostQueryVariables>;
export const CheckExistCostRevenueDocument = gql`
    query checkExistCostRevenue($budgetID: String!, $wbsID: String!) {
  checkExistCostRevenue(budgetID: $budgetID, wbsID: $wbsID)
}
    `;

/**
 * __useCheckExistCostRevenueQuery__
 *
 * To run a query within a React component, call `useCheckExistCostRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExistCostRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExistCostRevenueQuery({
 *   variables: {
 *      budgetID: // value for 'budgetID'
 *      wbsID: // value for 'wbsID'
 *   },
 * });
 */
export function useCheckExistCostRevenueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckExistCostRevenueQuery, CheckExistCostRevenueQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckExistCostRevenueQuery, CheckExistCostRevenueQueryVariables>(CheckExistCostRevenueDocument, baseOptions);
      }
export function useCheckExistCostRevenueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckExistCostRevenueQuery, CheckExistCostRevenueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckExistCostRevenueQuery, CheckExistCostRevenueQueryVariables>(CheckExistCostRevenueDocument, baseOptions);
        }
export type CheckExistCostRevenueQueryHookResult = ReturnType<typeof useCheckExistCostRevenueQuery>;
export type CheckExistCostRevenueLazyQueryHookResult = ReturnType<typeof useCheckExistCostRevenueLazyQuery>;
export type CheckExistCostRevenueQueryResult = ApolloReactCommon.QueryResult<CheckExistCostRevenueQuery, CheckExistCostRevenueQueryVariables>;
export const GetTotalCostRevenueDocument = gql`
    query getTotalCostRevenue($budgetID: String!, $wbsID: String!) {
  getTotalCostRevenue(budgetID: $budgetID, wbsID: $wbsID)
}
    `;

/**
 * __useGetTotalCostRevenueQuery__
 *
 * To run a query within a React component, call `useGetTotalCostRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalCostRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalCostRevenueQuery({
 *   variables: {
 *      budgetID: // value for 'budgetID'
 *      wbsID: // value for 'wbsID'
 *   },
 * });
 */
export function useGetTotalCostRevenueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTotalCostRevenueQuery, GetTotalCostRevenueQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTotalCostRevenueQuery, GetTotalCostRevenueQueryVariables>(GetTotalCostRevenueDocument, baseOptions);
      }
export function useGetTotalCostRevenueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTotalCostRevenueQuery, GetTotalCostRevenueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTotalCostRevenueQuery, GetTotalCostRevenueQueryVariables>(GetTotalCostRevenueDocument, baseOptions);
        }
export type GetTotalCostRevenueQueryHookResult = ReturnType<typeof useGetTotalCostRevenueQuery>;
export type GetTotalCostRevenueLazyQueryHookResult = ReturnType<typeof useGetTotalCostRevenueLazyQuery>;
export type GetTotalCostRevenueQueryResult = ApolloReactCommon.QueryResult<GetTotalCostRevenueQuery, GetTotalCostRevenueQueryVariables>;
export const GetParentChildrenCostCategoryDocument = gql`
    query getParentChildrenCostCategory($commonStatus: CommonStatus, $costClass: CostClass) {
  getParentChildrenCostCategory(commonStatus: $commonStatus, costClass: $costClass)
}
    `;

/**
 * __useGetParentChildrenCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetParentChildrenCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentChildrenCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentChildrenCostCategoryQuery({
 *   variables: {
 *      commonStatus: // value for 'commonStatus'
 *      costClass: // value for 'costClass'
 *   },
 * });
 */
export function useGetParentChildrenCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParentChildrenCostCategoryQuery, GetParentChildrenCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParentChildrenCostCategoryQuery, GetParentChildrenCostCategoryQueryVariables>(GetParentChildrenCostCategoryDocument, baseOptions);
      }
export function useGetParentChildrenCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParentChildrenCostCategoryQuery, GetParentChildrenCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParentChildrenCostCategoryQuery, GetParentChildrenCostCategoryQueryVariables>(GetParentChildrenCostCategoryDocument, baseOptions);
        }
export type GetParentChildrenCostCategoryQueryHookResult = ReturnType<typeof useGetParentChildrenCostCategoryQuery>;
export type GetParentChildrenCostCategoryLazyQueryHookResult = ReturnType<typeof useGetParentChildrenCostCategoryLazyQuery>;
export type GetParentChildrenCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetParentChildrenCostCategoryQuery, GetParentChildrenCostCategoryQueryVariables>;
export const GetCostCategoryTreeDocument = gql`
    query getCostCategoryTree($commonStatus: CommonStatus, $costClass: CostClass) {
  getCostCategoryTree(commonStatus: $commonStatus, costClass: $costClass)
}
    `;

/**
 * __useGetCostCategoryTreeQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryTreeQuery({
 *   variables: {
 *      commonStatus: // value for 'commonStatus'
 *      costClass: // value for 'costClass'
 *   },
 * });
 */
export function useGetCostCategoryTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryTreeQuery, GetCostCategoryTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryTreeQuery, GetCostCategoryTreeQueryVariables>(GetCostCategoryTreeDocument, baseOptions);
      }
export function useGetCostCategoryTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryTreeQuery, GetCostCategoryTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryTreeQuery, GetCostCategoryTreeQueryVariables>(GetCostCategoryTreeDocument, baseOptions);
        }
export type GetCostCategoryTreeQueryHookResult = ReturnType<typeof useGetCostCategoryTreeQuery>;
export type GetCostCategoryTreeLazyQueryHookResult = ReturnType<typeof useGetCostCategoryTreeLazyQuery>;
export type GetCostCategoryTreeQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryTreeQuery, GetCostCategoryTreeQueryVariables>;
export const GetMapCostCategoryDocument = gql`
    query getMapCostCategory($costClass: CostClass, $materialOnly: Boolean) {
  getMapCostCategory(costClass: $costClass, materialOnly: $materialOnly)
}
    `;

/**
 * __useGetMapCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetMapCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapCostCategoryQuery({
 *   variables: {
 *      costClass: // value for 'costClass'
 *      materialOnly: // value for 'materialOnly'
 *   },
 * });
 */
export function useGetMapCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMapCostCategoryQuery, GetMapCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMapCostCategoryQuery, GetMapCostCategoryQueryVariables>(GetMapCostCategoryDocument, baseOptions);
      }
export function useGetMapCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMapCostCategoryQuery, GetMapCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMapCostCategoryQuery, GetMapCostCategoryQueryVariables>(GetMapCostCategoryDocument, baseOptions);
        }
export type GetMapCostCategoryQueryHookResult = ReturnType<typeof useGetMapCostCategoryQuery>;
export type GetMapCostCategoryLazyQueryHookResult = ReturnType<typeof useGetMapCostCategoryLazyQuery>;
export type GetMapCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetMapCostCategoryQuery, GetMapCostCategoryQueryVariables>;
export const GetMapCostItemDocument = gql`
    query getMapCostItem {
  getMapCostItem
}
    `;

/**
 * __useGetMapCostItemQuery__
 *
 * To run a query within a React component, call `useGetMapCostItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapCostItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapCostItemQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMapCostItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMapCostItemQuery, GetMapCostItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMapCostItemQuery, GetMapCostItemQueryVariables>(GetMapCostItemDocument, baseOptions);
      }
export function useGetMapCostItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMapCostItemQuery, GetMapCostItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMapCostItemQuery, GetMapCostItemQueryVariables>(GetMapCostItemDocument, baseOptions);
        }
export type GetMapCostItemQueryHookResult = ReturnType<typeof useGetMapCostItemQuery>;
export type GetMapCostItemLazyQueryHookResult = ReturnType<typeof useGetMapCostItemLazyQuery>;
export type GetMapCostItemQueryResult = ApolloReactCommon.QueryResult<GetMapCostItemQuery, GetMapCostItemQueryVariables>;
export const GetCostWbsBudgetDetailDocument = gql`
    query getCostWBSBudgetDetail($budgetID: String!, $wbsID: String!) {
  getCostWBSBudgetDetail(budgetID: $budgetID, wbsID: $wbsID)
}
    `;

/**
 * __useGetCostWbsBudgetDetailQuery__
 *
 * To run a query within a React component, call `useGetCostWbsBudgetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostWbsBudgetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostWbsBudgetDetailQuery({
 *   variables: {
 *      budgetID: // value for 'budgetID'
 *      wbsID: // value for 'wbsID'
 *   },
 * });
 */
export function useGetCostWbsBudgetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostWbsBudgetDetailQuery, GetCostWbsBudgetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostWbsBudgetDetailQuery, GetCostWbsBudgetDetailQueryVariables>(GetCostWbsBudgetDetailDocument, baseOptions);
      }
export function useGetCostWbsBudgetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostWbsBudgetDetailQuery, GetCostWbsBudgetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostWbsBudgetDetailQuery, GetCostWbsBudgetDetailQueryVariables>(GetCostWbsBudgetDetailDocument, baseOptions);
        }
export type GetCostWbsBudgetDetailQueryHookResult = ReturnType<typeof useGetCostWbsBudgetDetailQuery>;
export type GetCostWbsBudgetDetailLazyQueryHookResult = ReturnType<typeof useGetCostWbsBudgetDetailLazyQuery>;
export type GetCostWbsBudgetDetailQueryResult = ApolloReactCommon.QueryResult<GetCostWbsBudgetDetailQuery, GetCostWbsBudgetDetailQueryVariables>;
export const CreateBudgetDetailDocument = gql`
    mutation createBudgetDetail($input: WBSBudgetDetailInput!) {
  createBudgetDetail(input: $input) {
    wbsID
  }
}
    `;
export type CreateBudgetDetailMutationFn = ApolloReactCommon.MutationFunction<CreateBudgetDetailMutation, CreateBudgetDetailMutationVariables>;

/**
 * __useCreateBudgetDetailMutation__
 *
 * To run a mutation, you first call `useCreateBudgetDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudgetDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudgetDetailMutation, { data, loading, error }] = useCreateBudgetDetailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBudgetDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBudgetDetailMutation, CreateBudgetDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBudgetDetailMutation, CreateBudgetDetailMutationVariables>(CreateBudgetDetailDocument, baseOptions);
      }
export type CreateBudgetDetailMutationHookResult = ReturnType<typeof useCreateBudgetDetailMutation>;
export type CreateBudgetDetailMutationResult = ApolloReactCommon.MutationResult<CreateBudgetDetailMutation>;
export type CreateBudgetDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBudgetDetailMutation, CreateBudgetDetailMutationVariables>;
export const UpdateBudgetDetailDocument = gql`
    mutation updateBudgetDetail($input: WBSBudgetDetailInput!) {
  updateBudgetDetail(input: $input)
}
    `;
export type UpdateBudgetDetailMutationFn = ApolloReactCommon.MutationFunction<UpdateBudgetDetailMutation, UpdateBudgetDetailMutationVariables>;

/**
 * __useUpdateBudgetDetailMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetDetailMutation, { data, loading, error }] = useUpdateBudgetDetailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBudgetDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBudgetDetailMutation, UpdateBudgetDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBudgetDetailMutation, UpdateBudgetDetailMutationVariables>(UpdateBudgetDetailDocument, baseOptions);
      }
export type UpdateBudgetDetailMutationHookResult = ReturnType<typeof useUpdateBudgetDetailMutation>;
export type UpdateBudgetDetailMutationResult = ApolloReactCommon.MutationResult<UpdateBudgetDetailMutation>;
export type UpdateBudgetDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBudgetDetailMutation, UpdateBudgetDetailMutationVariables>;
export const DeleteBudgetDetailDocument = gql`
    mutation deleteBudgetDetail($IDs: [String!]!) {
  deleteBudgetDetail(IDs: $IDs)
}
    `;
export type DeleteBudgetDetailMutationFn = ApolloReactCommon.MutationFunction<DeleteBudgetDetailMutation, DeleteBudgetDetailMutationVariables>;

/**
 * __useDeleteBudgetDetailMutation__
 *
 * To run a mutation, you first call `useDeleteBudgetDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBudgetDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBudgetDetailMutation, { data, loading, error }] = useDeleteBudgetDetailMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useDeleteBudgetDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBudgetDetailMutation, DeleteBudgetDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBudgetDetailMutation, DeleteBudgetDetailMutationVariables>(DeleteBudgetDetailDocument, baseOptions);
      }
export type DeleteBudgetDetailMutationHookResult = ReturnType<typeof useDeleteBudgetDetailMutation>;
export type DeleteBudgetDetailMutationResult = ApolloReactCommon.MutationResult<DeleteBudgetDetailMutation>;
export type DeleteBudgetDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBudgetDetailMutation, DeleteBudgetDetailMutationVariables>;
export const GetCostItemForWbsDocument = gql`
    query getCostItemForWbs($ID: String) {
  getCostItem(ID: $ID) {
    ID
    name
    code
  }
}
    `;

/**
 * __useGetCostItemForWbsQuery__
 *
 * To run a query within a React component, call `useGetCostItemForWbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemForWbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemForWbsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCostItemForWbsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemForWbsQuery, GetCostItemForWbsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemForWbsQuery, GetCostItemForWbsQueryVariables>(GetCostItemForWbsDocument, baseOptions);
      }
export function useGetCostItemForWbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemForWbsQuery, GetCostItemForWbsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemForWbsQuery, GetCostItemForWbsQueryVariables>(GetCostItemForWbsDocument, baseOptions);
        }
export type GetCostItemForWbsQueryHookResult = ReturnType<typeof useGetCostItemForWbsQuery>;
export type GetCostItemForWbsLazyQueryHookResult = ReturnType<typeof useGetCostItemForWbsLazyQuery>;
export type GetCostItemForWbsQueryResult = ApolloReactCommon.QueryResult<GetCostItemForWbsQuery, GetCostItemForWbsQueryVariables>;
export const GetRevenueWbsBudgetDetailDocument = gql`
    query getRevenueWBSBudgetDetail($budgetID: String!, $wbsID: String!) {
  getRevenueWBSBudgetDetail(budgetID: $budgetID, wbsID: $wbsID)
}
    `;

/**
 * __useGetRevenueWbsBudgetDetailQuery__
 *
 * To run a query within a React component, call `useGetRevenueWbsBudgetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevenueWbsBudgetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevenueWbsBudgetDetailQuery({
 *   variables: {
 *      budgetID: // value for 'budgetID'
 *      wbsID: // value for 'wbsID'
 *   },
 * });
 */
export function useGetRevenueWbsBudgetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRevenueWbsBudgetDetailQuery, GetRevenueWbsBudgetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRevenueWbsBudgetDetailQuery, GetRevenueWbsBudgetDetailQueryVariables>(GetRevenueWbsBudgetDetailDocument, baseOptions);
      }
export function useGetRevenueWbsBudgetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRevenueWbsBudgetDetailQuery, GetRevenueWbsBudgetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRevenueWbsBudgetDetailQuery, GetRevenueWbsBudgetDetailQueryVariables>(GetRevenueWbsBudgetDetailDocument, baseOptions);
        }
export type GetRevenueWbsBudgetDetailQueryHookResult = ReturnType<typeof useGetRevenueWbsBudgetDetailQuery>;
export type GetRevenueWbsBudgetDetailLazyQueryHookResult = ReturnType<typeof useGetRevenueWbsBudgetDetailLazyQuery>;
export type GetRevenueWbsBudgetDetailQueryResult = ApolloReactCommon.QueryResult<GetRevenueWbsBudgetDetailQuery, GetRevenueWbsBudgetDetailQueryVariables>;
export const GetWbsBudgetDetailSchedulingDocument = gql`
    query getWbsBudgetDetailScheduling($ID: String, $wbsID: String, $costCategoryID: String) {
  getWbsBudgetDetailScheduling(ID: $ID, wbsID: $wbsID, costCategoryID: $costCategoryID) {
    ID
    scheduleAmt
    wbsBudgetDetailID
    wbsBudgetDetail {
      ID
      budgetID
      revenueCategoryID
    }
    wbsScheduleID
    wbsSchedule {
      ID
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useGetWbsBudgetDetailSchedulingQuery__
 *
 * To run a query within a React component, call `useGetWbsBudgetDetailSchedulingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsBudgetDetailSchedulingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsBudgetDetailSchedulingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      wbsID: // value for 'wbsID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetWbsBudgetDetailSchedulingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsBudgetDetailSchedulingQuery, GetWbsBudgetDetailSchedulingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsBudgetDetailSchedulingQuery, GetWbsBudgetDetailSchedulingQueryVariables>(GetWbsBudgetDetailSchedulingDocument, baseOptions);
      }
export function useGetWbsBudgetDetailSchedulingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsBudgetDetailSchedulingQuery, GetWbsBudgetDetailSchedulingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsBudgetDetailSchedulingQuery, GetWbsBudgetDetailSchedulingQueryVariables>(GetWbsBudgetDetailSchedulingDocument, baseOptions);
        }
export type GetWbsBudgetDetailSchedulingQueryHookResult = ReturnType<typeof useGetWbsBudgetDetailSchedulingQuery>;
export type GetWbsBudgetDetailSchedulingLazyQueryHookResult = ReturnType<typeof useGetWbsBudgetDetailSchedulingLazyQuery>;
export type GetWbsBudgetDetailSchedulingQueryResult = ApolloReactCommon.QueryResult<GetWbsBudgetDetailSchedulingQuery, GetWbsBudgetDetailSchedulingQueryVariables>;
export const UpdateBudgetScheduleDocument = gql`
    mutation updateBudgetSchedule($IDs: [String!]!, $costAmts: [Float!]!, $wbsBudgetDetailID: String!) {
  updateBudgetSchedule(IDs: $IDs, costAmts: $costAmts, wbsBudgetDetailID: $wbsBudgetDetailID)
}
    `;
export type UpdateBudgetScheduleMutationFn = ApolloReactCommon.MutationFunction<UpdateBudgetScheduleMutation, UpdateBudgetScheduleMutationVariables>;

/**
 * __useUpdateBudgetScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetScheduleMutation, { data, loading, error }] = useUpdateBudgetScheduleMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *      costAmts: // value for 'costAmts'
 *      wbsBudgetDetailID: // value for 'wbsBudgetDetailID'
 *   },
 * });
 */
export function useUpdateBudgetScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBudgetScheduleMutation, UpdateBudgetScheduleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBudgetScheduleMutation, UpdateBudgetScheduleMutationVariables>(UpdateBudgetScheduleDocument, baseOptions);
      }
export type UpdateBudgetScheduleMutationHookResult = ReturnType<typeof useUpdateBudgetScheduleMutation>;
export type UpdateBudgetScheduleMutationResult = ApolloReactCommon.MutationResult<UpdateBudgetScheduleMutation>;
export type UpdateBudgetScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBudgetScheduleMutation, UpdateBudgetScheduleMutationVariables>;
export const GetRevenueBudgetDocument = gql`
    query getRevenueBudget($wbsID: String, $contractID: String) {
  getRevenueBudget: getWbsBudgetDetail(wbsID: $wbsID, contractID: $contractID) {
    ID
    wbsID
    revenueCategoryID
    revenueCategory {
      ID
      name
      description
    }
  }
}
    `;

/**
 * __useGetRevenueBudgetQuery__
 *
 * To run a query within a React component, call `useGetRevenueBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevenueBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevenueBudgetQuery({
 *   variables: {
 *      wbsID: // value for 'wbsID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetRevenueBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRevenueBudgetQuery, GetRevenueBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRevenueBudgetQuery, GetRevenueBudgetQueryVariables>(GetRevenueBudgetDocument, baseOptions);
      }
export function useGetRevenueBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRevenueBudgetQuery, GetRevenueBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRevenueBudgetQuery, GetRevenueBudgetQueryVariables>(GetRevenueBudgetDocument, baseOptions);
        }
export type GetRevenueBudgetQueryHookResult = ReturnType<typeof useGetRevenueBudgetQuery>;
export type GetRevenueBudgetLazyQueryHookResult = ReturnType<typeof useGetRevenueBudgetLazyQuery>;
export type GetRevenueBudgetQueryResult = ApolloReactCommon.QueryResult<GetRevenueBudgetQuery, GetRevenueBudgetQueryVariables>;
export const GetCostCategoryWbsBudgetDocument = gql`
    query getCostCategoryWbsBudget($wbsID: String!, $contractID: String!, $isCategory: Boolean) {
  getCostCategoryWbsBudget(wbsID: $wbsID, contractID: $contractID, isCategory: $isCategory)
}
    `;

/**
 * __useGetCostCategoryWbsBudgetQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryWbsBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryWbsBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryWbsBudgetQuery({
 *   variables: {
 *      wbsID: // value for 'wbsID'
 *      contractID: // value for 'contractID'
 *      isCategory: // value for 'isCategory'
 *   },
 * });
 */
export function useGetCostCategoryWbsBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryWbsBudgetQuery, GetCostCategoryWbsBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryWbsBudgetQuery, GetCostCategoryWbsBudgetQueryVariables>(GetCostCategoryWbsBudgetDocument, baseOptions);
      }
export function useGetCostCategoryWbsBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryWbsBudgetQuery, GetCostCategoryWbsBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryWbsBudgetQuery, GetCostCategoryWbsBudgetQueryVariables>(GetCostCategoryWbsBudgetDocument, baseOptions);
        }
export type GetCostCategoryWbsBudgetQueryHookResult = ReturnType<typeof useGetCostCategoryWbsBudgetQuery>;
export type GetCostCategoryWbsBudgetLazyQueryHookResult = ReturnType<typeof useGetCostCategoryWbsBudgetLazyQuery>;
export type GetCostCategoryWbsBudgetQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryWbsBudgetQuery, GetCostCategoryWbsBudgetQueryVariables>;
export const GetCostItemWbsBudgetDocument = gql`
    query getCostItemWbsBudget($wbsID: String!, $contractID: String!, $isCategory: Boolean) {
  getCostItemWbsBudget: getCostCategoryWbsBudget(wbsID: $wbsID, contractID: $contractID, isCategory: $isCategory)
}
    `;

/**
 * __useGetCostItemWbsBudgetQuery__
 *
 * To run a query within a React component, call `useGetCostItemWbsBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemWbsBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemWbsBudgetQuery({
 *   variables: {
 *      wbsID: // value for 'wbsID'
 *      contractID: // value for 'contractID'
 *      isCategory: // value for 'isCategory'
 *   },
 * });
 */
export function useGetCostItemWbsBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemWbsBudgetQuery, GetCostItemWbsBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemWbsBudgetQuery, GetCostItemWbsBudgetQueryVariables>(GetCostItemWbsBudgetDocument, baseOptions);
      }
export function useGetCostItemWbsBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemWbsBudgetQuery, GetCostItemWbsBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemWbsBudgetQuery, GetCostItemWbsBudgetQueryVariables>(GetCostItemWbsBudgetDocument, baseOptions);
        }
export type GetCostItemWbsBudgetQueryHookResult = ReturnType<typeof useGetCostItemWbsBudgetQuery>;
export type GetCostItemWbsBudgetLazyQueryHookResult = ReturnType<typeof useGetCostItemWbsBudgetLazyQuery>;
export type GetCostItemWbsBudgetQueryResult = ApolloReactCommon.QueryResult<GetCostItemWbsBudgetQuery, GetCostItemWbsBudgetQueryVariables>;
export const GetWbsDayworkCostRevenueDocument = gql`
    query getWbsDayworkCostRevenue($contractID: String) {
  getWbsDayworkCostRevenue(contractID: $contractID) {
    ID
    wbsID
    contractID
    revenueCategoryID
    costCategoryID
    costItemID
  }
}
    `;

/**
 * __useGetWbsDayworkCostRevenueQuery__
 *
 * To run a query within a React component, call `useGetWbsDayworkCostRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsDayworkCostRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsDayworkCostRevenueQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetWbsDayworkCostRevenueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsDayworkCostRevenueQuery, GetWbsDayworkCostRevenueQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsDayworkCostRevenueQuery, GetWbsDayworkCostRevenueQueryVariables>(GetWbsDayworkCostRevenueDocument, baseOptions);
      }
export function useGetWbsDayworkCostRevenueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsDayworkCostRevenueQuery, GetWbsDayworkCostRevenueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsDayworkCostRevenueQuery, GetWbsDayworkCostRevenueQueryVariables>(GetWbsDayworkCostRevenueDocument, baseOptions);
        }
export type GetWbsDayworkCostRevenueQueryHookResult = ReturnType<typeof useGetWbsDayworkCostRevenueQuery>;
export type GetWbsDayworkCostRevenueLazyQueryHookResult = ReturnType<typeof useGetWbsDayworkCostRevenueLazyQuery>;
export type GetWbsDayworkCostRevenueQueryResult = ApolloReactCommon.QueryResult<GetWbsDayworkCostRevenueQuery, GetWbsDayworkCostRevenueQueryVariables>;
export const UpdateWbsDayworkCostRevenueDocument = gql`
    mutation updateWbsDayworkCostRevenue($input: WBSDayworkInput!) {
  updateWbsDayworkCostRevenue(input: $input)
}
    `;
export type UpdateWbsDayworkCostRevenueMutationFn = ApolloReactCommon.MutationFunction<UpdateWbsDayworkCostRevenueMutation, UpdateWbsDayworkCostRevenueMutationVariables>;

/**
 * __useUpdateWbsDayworkCostRevenueMutation__
 *
 * To run a mutation, you first call `useUpdateWbsDayworkCostRevenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWbsDayworkCostRevenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWbsDayworkCostRevenueMutation, { data, loading, error }] = useUpdateWbsDayworkCostRevenueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWbsDayworkCostRevenueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWbsDayworkCostRevenueMutation, UpdateWbsDayworkCostRevenueMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWbsDayworkCostRevenueMutation, UpdateWbsDayworkCostRevenueMutationVariables>(UpdateWbsDayworkCostRevenueDocument, baseOptions);
      }
export type UpdateWbsDayworkCostRevenueMutationHookResult = ReturnType<typeof useUpdateWbsDayworkCostRevenueMutation>;
export type UpdateWbsDayworkCostRevenueMutationResult = ApolloReactCommon.MutationResult<UpdateWbsDayworkCostRevenueMutation>;
export type UpdateWbsDayworkCostRevenueMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWbsDayworkCostRevenueMutation, UpdateWbsDayworkCostRevenueMutationVariables>;
export const GetCheckingValidationWbsBudgetDocument = gql`
    query getCheckingValidationWBSBudget($WBSAndBudgetExcelInput: [WBSAndBudgetExcelInput!]!, $contractID: String!) {
  getCheckingValidationWBSBudget(WBSAndBudgetExcelInput: $WBSAndBudgetExcelInput, contractID: $contractID)
}
    `;

/**
 * __useGetCheckingValidationWbsBudgetQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationWbsBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationWbsBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationWbsBudgetQuery({
 *   variables: {
 *      WBSAndBudgetExcelInput: // value for 'WBSAndBudgetExcelInput'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetCheckingValidationWbsBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationWbsBudgetQuery, GetCheckingValidationWbsBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationWbsBudgetQuery, GetCheckingValidationWbsBudgetQueryVariables>(GetCheckingValidationWbsBudgetDocument, baseOptions);
      }
export function useGetCheckingValidationWbsBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationWbsBudgetQuery, GetCheckingValidationWbsBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationWbsBudgetQuery, GetCheckingValidationWbsBudgetQueryVariables>(GetCheckingValidationWbsBudgetDocument, baseOptions);
        }
export type GetCheckingValidationWbsBudgetQueryHookResult = ReturnType<typeof useGetCheckingValidationWbsBudgetQuery>;
export type GetCheckingValidationWbsBudgetLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationWbsBudgetLazyQuery>;
export type GetCheckingValidationWbsBudgetQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationWbsBudgetQuery, GetCheckingValidationWbsBudgetQueryVariables>;
export const CreateWbsBudgetExcelDocument = gql`
    mutation createWBSBudgetExcel($WBSAndBudgetExcelInput: [WBSAndBudgetExcelInput!]!, $contractID: String!) {
  createWBSBudgetExcel(WBSAndBudgetExcelInput: $WBSAndBudgetExcelInput, contractID: $contractID)
}
    `;
export type CreateWbsBudgetExcelMutationFn = ApolloReactCommon.MutationFunction<CreateWbsBudgetExcelMutation, CreateWbsBudgetExcelMutationVariables>;

/**
 * __useCreateWbsBudgetExcelMutation__
 *
 * To run a mutation, you first call `useCreateWbsBudgetExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWbsBudgetExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWbsBudgetExcelMutation, { data, loading, error }] = useCreateWbsBudgetExcelMutation({
 *   variables: {
 *      WBSAndBudgetExcelInput: // value for 'WBSAndBudgetExcelInput'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useCreateWbsBudgetExcelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWbsBudgetExcelMutation, CreateWbsBudgetExcelMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWbsBudgetExcelMutation, CreateWbsBudgetExcelMutationVariables>(CreateWbsBudgetExcelDocument, baseOptions);
      }
export type CreateWbsBudgetExcelMutationHookResult = ReturnType<typeof useCreateWbsBudgetExcelMutation>;
export type CreateWbsBudgetExcelMutationResult = ApolloReactCommon.MutationResult<CreateWbsBudgetExcelMutation>;
export type CreateWbsBudgetExcelMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWbsBudgetExcelMutation, CreateWbsBudgetExcelMutationVariables>;
export const WbsImportDocument = gql`
    mutation WBSImport($batchID: String!, $contractID: String!) {
  WBSImport(batchID: $batchID, contractID: $contractID)
}
    `;
export type WbsImportMutationFn = ApolloReactCommon.MutationFunction<WbsImportMutation, WbsImportMutationVariables>;

/**
 * __useWbsImportMutation__
 *
 * To run a mutation, you first call `useWbsImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbsImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbsImportMutation, { data, loading, error }] = useWbsImportMutation({
 *   variables: {
 *      batchID: // value for 'batchID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useWbsImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WbsImportMutation, WbsImportMutationVariables>) {
        return ApolloReactHooks.useMutation<WbsImportMutation, WbsImportMutationVariables>(WbsImportDocument, baseOptions);
      }
export type WbsImportMutationHookResult = ReturnType<typeof useWbsImportMutation>;
export type WbsImportMutationResult = ApolloReactCommon.MutationResult<WbsImportMutation>;
export type WbsImportMutationOptions = ApolloReactCommon.BaseMutationOptions<WbsImportMutation, WbsImportMutationVariables>;
export const WbsImportValidationDocument = gql`
    mutation WBSImportValidation($WBSImportInput: [WBSImportInput!]!, $contractID: String!) {
  WBSImportValidation(WBSImportInput: $WBSImportInput, contractID: $contractID)
}
    `;
export type WbsImportValidationMutationFn = ApolloReactCommon.MutationFunction<WbsImportValidationMutation, WbsImportValidationMutationVariables>;

/**
 * __useWbsImportValidationMutation__
 *
 * To run a mutation, you first call `useWbsImportValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbsImportValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbsImportValidationMutation, { data, loading, error }] = useWbsImportValidationMutation({
 *   variables: {
 *      WBSImportInput: // value for 'WBSImportInput'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useWbsImportValidationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WbsImportValidationMutation, WbsImportValidationMutationVariables>) {
        return ApolloReactHooks.useMutation<WbsImportValidationMutation, WbsImportValidationMutationVariables>(WbsImportValidationDocument, baseOptions);
      }
export type WbsImportValidationMutationHookResult = ReturnType<typeof useWbsImportValidationMutation>;
export type WbsImportValidationMutationResult = ApolloReactCommon.MutationResult<WbsImportValidationMutation>;
export type WbsImportValidationMutationOptions = ApolloReactCommon.BaseMutationOptions<WbsImportValidationMutation, WbsImportValidationMutationVariables>;
export const GetCostCategoryFromWbsDocument = gql`
    query getCostCategoryFromWbs($wbsID: String!, $contractID: String!, $selectedID: String) {
  getCostCategoryFromWbs(wbsID: $wbsID, contractID: $contractID, selectedID: $selectedID)
}
    `;

/**
 * __useGetCostCategoryFromWbsQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryFromWbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryFromWbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryFromWbsQuery({
 *   variables: {
 *      wbsID: // value for 'wbsID'
 *      contractID: // value for 'contractID'
 *      selectedID: // value for 'selectedID'
 *   },
 * });
 */
export function useGetCostCategoryFromWbsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryFromWbsQuery, GetCostCategoryFromWbsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryFromWbsQuery, GetCostCategoryFromWbsQueryVariables>(GetCostCategoryFromWbsDocument, baseOptions);
      }
export function useGetCostCategoryFromWbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryFromWbsQuery, GetCostCategoryFromWbsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryFromWbsQuery, GetCostCategoryFromWbsQueryVariables>(GetCostCategoryFromWbsDocument, baseOptions);
        }
export type GetCostCategoryFromWbsQueryHookResult = ReturnType<typeof useGetCostCategoryFromWbsQuery>;
export type GetCostCategoryFromWbsLazyQueryHookResult = ReturnType<typeof useGetCostCategoryFromWbsLazyQuery>;
export type GetCostCategoryFromWbsQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryFromWbsQuery, GetCostCategoryFromWbsQueryVariables>;
export const GetWbsBudgetDetailDocument = gql`
    query getWbsBudgetDetail($wbsID: String, $costCategoryID: String, $trackQuantity: Boolean!) {
  getWbsBudgetDetail(wbsID: $wbsID, costCategoryID: $costCategoryID) {
    ID
    quantity
    budgetAmt
    uomID
    uom @include(if: $trackQuantity) {
      ID
      code
      name
    }
  }
}
    `;

/**
 * __useGetWbsBudgetDetailQuery__
 *
 * To run a query within a React component, call `useGetWbsBudgetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsBudgetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsBudgetDetailQuery({
 *   variables: {
 *      wbsID: // value for 'wbsID'
 *      costCategoryID: // value for 'costCategoryID'
 *      trackQuantity: // value for 'trackQuantity'
 *   },
 * });
 */
export function useGetWbsBudgetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsBudgetDetailQuery, GetWbsBudgetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsBudgetDetailQuery, GetWbsBudgetDetailQueryVariables>(GetWbsBudgetDetailDocument, baseOptions);
      }
export function useGetWbsBudgetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsBudgetDetailQuery, GetWbsBudgetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsBudgetDetailQuery, GetWbsBudgetDetailQueryVariables>(GetWbsBudgetDetailDocument, baseOptions);
        }
export type GetWbsBudgetDetailQueryHookResult = ReturnType<typeof useGetWbsBudgetDetailQuery>;
export type GetWbsBudgetDetailLazyQueryHookResult = ReturnType<typeof useGetWbsBudgetDetailLazyQuery>;
export type GetWbsBudgetDetailQueryResult = ApolloReactCommon.QueryResult<GetWbsBudgetDetailQuery, GetWbsBudgetDetailQueryVariables>;
export const GetUoMforUtilizedBudgetDocument = gql`
    query getUOMforUtilizedBudget($ID: String) {
  getUOM(ID: $ID) {
    ID
    code
    name
  }
}
    `;

/**
 * __useGetUoMforUtilizedBudgetQuery__
 *
 * To run a query within a React component, call `useGetUoMforUtilizedBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUoMforUtilizedBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUoMforUtilizedBudgetQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUoMforUtilizedBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUoMforUtilizedBudgetQuery, GetUoMforUtilizedBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUoMforUtilizedBudgetQuery, GetUoMforUtilizedBudgetQueryVariables>(GetUoMforUtilizedBudgetDocument, baseOptions);
      }
export function useGetUoMforUtilizedBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUoMforUtilizedBudgetQuery, GetUoMforUtilizedBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUoMforUtilizedBudgetQuery, GetUoMforUtilizedBudgetQueryVariables>(GetUoMforUtilizedBudgetDocument, baseOptions);
        }
export type GetUoMforUtilizedBudgetQueryHookResult = ReturnType<typeof useGetUoMforUtilizedBudgetQuery>;
export type GetUoMforUtilizedBudgetLazyQueryHookResult = ReturnType<typeof useGetUoMforUtilizedBudgetLazyQuery>;
export type GetUoMforUtilizedBudgetQueryResult = ApolloReactCommon.QueryResult<GetUoMforUtilizedBudgetQuery, GetUoMforUtilizedBudgetQueryVariables>;
export const GetWbsUtilizedBudgetDocument = gql`
    query getWBSUtilizedBudget($contractID: String!) {
  getWBSUtilizedBudget(contractID: $contractID)
}
    `;

/**
 * __useGetWbsUtilizedBudgetQuery__
 *
 * To run a query within a React component, call `useGetWbsUtilizedBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsUtilizedBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsUtilizedBudgetQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetWbsUtilizedBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsUtilizedBudgetQuery, GetWbsUtilizedBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsUtilizedBudgetQuery, GetWbsUtilizedBudgetQueryVariables>(GetWbsUtilizedBudgetDocument, baseOptions);
      }
export function useGetWbsUtilizedBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsUtilizedBudgetQuery, GetWbsUtilizedBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsUtilizedBudgetQuery, GetWbsUtilizedBudgetQueryVariables>(GetWbsUtilizedBudgetDocument, baseOptions);
        }
export type GetWbsUtilizedBudgetQueryHookResult = ReturnType<typeof useGetWbsUtilizedBudgetQuery>;
export type GetWbsUtilizedBudgetLazyQueryHookResult = ReturnType<typeof useGetWbsUtilizedBudgetLazyQuery>;
export type GetWbsUtilizedBudgetQueryResult = ApolloReactCommon.QueryResult<GetWbsUtilizedBudgetQuery, GetWbsUtilizedBudgetQueryVariables>;
export const CreateWbsUtilizedBudgetDocument = gql`
    mutation createWBSUtilizedBudget($input: WBSUtilizedBudgetInput!) {
  createWBSUtilizedBudget(input: $input) {
    year
    ID
    utilizedQty
    utilizedAmt
  }
}
    `;
export type CreateWbsUtilizedBudgetMutationFn = ApolloReactCommon.MutationFunction<CreateWbsUtilizedBudgetMutation, CreateWbsUtilizedBudgetMutationVariables>;

/**
 * __useCreateWbsUtilizedBudgetMutation__
 *
 * To run a mutation, you first call `useCreateWbsUtilizedBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWbsUtilizedBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWbsUtilizedBudgetMutation, { data, loading, error }] = useCreateWbsUtilizedBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWbsUtilizedBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWbsUtilizedBudgetMutation, CreateWbsUtilizedBudgetMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWbsUtilizedBudgetMutation, CreateWbsUtilizedBudgetMutationVariables>(CreateWbsUtilizedBudgetDocument, baseOptions);
      }
export type CreateWbsUtilizedBudgetMutationHookResult = ReturnType<typeof useCreateWbsUtilizedBudgetMutation>;
export type CreateWbsUtilizedBudgetMutationResult = ApolloReactCommon.MutationResult<CreateWbsUtilizedBudgetMutation>;
export type CreateWbsUtilizedBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWbsUtilizedBudgetMutation, CreateWbsUtilizedBudgetMutationVariables>;
export const UpdateWbsUtilizedBudgetDocument = gql`
    mutation updateWBSUtilizedBudget($input: WBSUtilizedBudgetInput!) {
  updateWBSUtilizedBudget(input: $input) {
    year
    ID
    utilizedQty
    utilizedAmt
  }
}
    `;
export type UpdateWbsUtilizedBudgetMutationFn = ApolloReactCommon.MutationFunction<UpdateWbsUtilizedBudgetMutation, UpdateWbsUtilizedBudgetMutationVariables>;

/**
 * __useUpdateWbsUtilizedBudgetMutation__
 *
 * To run a mutation, you first call `useUpdateWbsUtilizedBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWbsUtilizedBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWbsUtilizedBudgetMutation, { data, loading, error }] = useUpdateWbsUtilizedBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWbsUtilizedBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWbsUtilizedBudgetMutation, UpdateWbsUtilizedBudgetMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWbsUtilizedBudgetMutation, UpdateWbsUtilizedBudgetMutationVariables>(UpdateWbsUtilizedBudgetDocument, baseOptions);
      }
export type UpdateWbsUtilizedBudgetMutationHookResult = ReturnType<typeof useUpdateWbsUtilizedBudgetMutation>;
export type UpdateWbsUtilizedBudgetMutationResult = ApolloReactCommon.MutationResult<UpdateWbsUtilizedBudgetMutation>;
export type UpdateWbsUtilizedBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWbsUtilizedBudgetMutation, UpdateWbsUtilizedBudgetMutationVariables>;
export const DeleteWbsUtilizedBudgetDocument = gql`
    mutation deleteWBSUtilizedBudget($input: WBSUtilizedBudgetDeleteInput!) {
  deleteWBSUtilizedBudget(input: $input)
}
    `;
export type DeleteWbsUtilizedBudgetMutationFn = ApolloReactCommon.MutationFunction<DeleteWbsUtilizedBudgetMutation, DeleteWbsUtilizedBudgetMutationVariables>;

/**
 * __useDeleteWbsUtilizedBudgetMutation__
 *
 * To run a mutation, you first call `useDeleteWbsUtilizedBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWbsUtilizedBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWbsUtilizedBudgetMutation, { data, loading, error }] = useDeleteWbsUtilizedBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWbsUtilizedBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWbsUtilizedBudgetMutation, DeleteWbsUtilizedBudgetMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteWbsUtilizedBudgetMutation, DeleteWbsUtilizedBudgetMutationVariables>(DeleteWbsUtilizedBudgetDocument, baseOptions);
      }
export type DeleteWbsUtilizedBudgetMutationHookResult = ReturnType<typeof useDeleteWbsUtilizedBudgetMutation>;
export type DeleteWbsUtilizedBudgetMutationResult = ApolloReactCommon.MutationResult<DeleteWbsUtilizedBudgetMutation>;
export type DeleteWbsUtilizedBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWbsUtilizedBudgetMutation, DeleteWbsUtilizedBudgetMutationVariables>;
export const ActionOnUtilizedBudgetStatusDocument = gql`
    mutation actionOnUtilizedBudgetStatus($ID: String!, $input: WBSUtilizedBudgetActionInput!) {
  actionOnUtilizedBudgetStatus(ID: $ID, input: $input)
}
    `;
export type ActionOnUtilizedBudgetStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnUtilizedBudgetStatusMutation, ActionOnUtilizedBudgetStatusMutationVariables>;

/**
 * __useActionOnUtilizedBudgetStatusMutation__
 *
 * To run a mutation, you first call `useActionOnUtilizedBudgetStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnUtilizedBudgetStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnUtilizedBudgetStatusMutation, { data, loading, error }] = useActionOnUtilizedBudgetStatusMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActionOnUtilizedBudgetStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnUtilizedBudgetStatusMutation, ActionOnUtilizedBudgetStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnUtilizedBudgetStatusMutation, ActionOnUtilizedBudgetStatusMutationVariables>(ActionOnUtilizedBudgetStatusDocument, baseOptions);
      }
export type ActionOnUtilizedBudgetStatusMutationHookResult = ReturnType<typeof useActionOnUtilizedBudgetStatusMutation>;
export type ActionOnUtilizedBudgetStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnUtilizedBudgetStatusMutation>;
export type ActionOnUtilizedBudgetStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnUtilizedBudgetStatusMutation, ActionOnUtilizedBudgetStatusMutationVariables>;
export const DocumentListingDocument = gql`
    query DocumentListing($refID: String, $refTable: String, $description: String, $notDescription: String, $hasDescription: Boolean) {
  DocumentListing(refID: $refID, refTable: $refTable, description: $description, notDescription: $notDescription, hasDescription: $hasDescription) {
    ID
    fileName
    fileURL
    mediaType
    createdTs
    description
    refID
  }
}
    `;

/**
 * __useDocumentListingQuery__
 *
 * To run a query within a React component, call `useDocumentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *      description: // value for 'description'
 *      notDescription: // value for 'notDescription'
 *      hasDescription: // value for 'hasDescription'
 *   },
 * });
 */
export function useDocumentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
      }
export function useDocumentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
        }
export type DocumentListingQueryHookResult = ReturnType<typeof useDocumentListingQuery>;
export type DocumentListingLazyQueryHookResult = ReturnType<typeof useDocumentListingLazyQuery>;
export type DocumentListingQueryResult = ApolloReactCommon.QueryResult<DocumentListingQuery, DocumentListingQueryVariables>;
export const DeleteExistPdfDocument = gql`
    mutation deleteExistPDF($refID: String, $description: String) {
  deleteExistPDF(refID: $refID, description: $description)
}
    `;
export type DeleteExistPdfMutationFn = ApolloReactCommon.MutationFunction<DeleteExistPdfMutation, DeleteExistPdfMutationVariables>;

/**
 * __useDeleteExistPdfMutation__
 *
 * To run a mutation, you first call `useDeleteExistPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExistPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExistPdfMutation, { data, loading, error }] = useDeleteExistPdfMutation({
 *   variables: {
 *      refID: // value for 'refID'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useDeleteExistPdfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteExistPdfMutation, DeleteExistPdfMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteExistPdfMutation, DeleteExistPdfMutationVariables>(DeleteExistPdfDocument, baseOptions);
      }
export type DeleteExistPdfMutationHookResult = ReturnType<typeof useDeleteExistPdfMutation>;
export type DeleteExistPdfMutationResult = ApolloReactCommon.MutationResult<DeleteExistPdfMutation>;
export type DeleteExistPdfMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteExistPdfMutation, DeleteExistPdfMutationVariables>;
export const UploadPdfDocumentDocument = gql`
    mutation uploadPDFDocument($input: LedgerPDFInput!) {
  uploadPDFDocument(input: $input) {
    ID
    refID
    fileName
    fileURL
    mediaType
    description
  }
}
    `;
export type UploadPdfDocumentMutationFn = ApolloReactCommon.MutationFunction<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>;

/**
 * __useUploadPdfDocumentMutation__
 *
 * To run a mutation, you first call `useUploadPdfDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPdfDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPdfDocumentMutation, { data, loading, error }] = useUploadPdfDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPdfDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>(UploadPdfDocumentDocument, baseOptions);
      }
export type UploadPdfDocumentMutationHookResult = ReturnType<typeof useUploadPdfDocumentMutation>;
export type UploadPdfDocumentMutationResult = ApolloReactCommon.MutationResult<UploadPdfDocumentMutation>;
export type UploadPdfDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>;
export const UploadPdfpoDocument = gql`
    mutation uploadPDFPO($input: LedgerPDFInput!) {
  uploadPDFPO(input: $input)
}
    `;
export type UploadPdfpoMutationFn = ApolloReactCommon.MutationFunction<UploadPdfpoMutation, UploadPdfpoMutationVariables>;

/**
 * __useUploadPdfpoMutation__
 *
 * To run a mutation, you first call `useUploadPdfpoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPdfpoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPdfpoMutation, { data, loading, error }] = useUploadPdfpoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPdfpoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadPdfpoMutation, UploadPdfpoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadPdfpoMutation, UploadPdfpoMutationVariables>(UploadPdfpoDocument, baseOptions);
      }
export type UploadPdfpoMutationHookResult = ReturnType<typeof useUploadPdfpoMutation>;
export type UploadPdfpoMutationResult = ApolloReactCommon.MutationResult<UploadPdfpoMutation>;
export type UploadPdfpoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadPdfpoMutation, UploadPdfpoMutationVariables>;
export const TestSampleEnumDocument = gql`
    query testSampleEnum($SampleEnum: SampleEnum!) {
  testSampleEnum(SampleEnum: $SampleEnum)
}
    `;

/**
 * __useTestSampleEnumQuery__
 *
 * To run a query within a React component, call `useTestSampleEnumQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestSampleEnumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestSampleEnumQuery({
 *   variables: {
 *      SampleEnum: // value for 'SampleEnum'
 *   },
 * });
 */
export function useTestSampleEnumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TestSampleEnumQuery, TestSampleEnumQueryVariables>) {
        return ApolloReactHooks.useQuery<TestSampleEnumQuery, TestSampleEnumQueryVariables>(TestSampleEnumDocument, baseOptions);
      }
export function useTestSampleEnumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TestSampleEnumQuery, TestSampleEnumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TestSampleEnumQuery, TestSampleEnumQueryVariables>(TestSampleEnumDocument, baseOptions);
        }
export type TestSampleEnumQueryHookResult = ReturnType<typeof useTestSampleEnumQuery>;
export type TestSampleEnumLazyQueryHookResult = ReturnType<typeof useTestSampleEnumLazyQuery>;
export type TestSampleEnumQueryResult = ApolloReactCommon.QueryResult<TestSampleEnumQuery, TestSampleEnumQueryVariables>;
export const UploadCompanyLogoDocument = gql`
    mutation uploadCompanyLogo($file: Upload) {
  uploadCompanyLogo(file: $file)
}
    `;
export type UploadCompanyLogoMutationFn = ApolloReactCommon.MutationFunction<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>;

/**
 * __useUploadCompanyLogoMutation__
 *
 * To run a mutation, you first call `useUploadCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCompanyLogoMutation, { data, loading, error }] = useUploadCompanyLogoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCompanyLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>(UploadCompanyLogoDocument, baseOptions);
      }
export type UploadCompanyLogoMutationHookResult = ReturnType<typeof useUploadCompanyLogoMutation>;
export type UploadCompanyLogoMutationResult = ApolloReactCommon.MutationResult<UploadCompanyLogoMutation>;
export type UploadCompanyLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>;
export const LogoViewDocument = gql`
    query logoView($refID: String, $description: String) {
  logoView(refID: $refID, description: $description) {
    fileName
    fileURL
    mediaType
  }
}
    `;

/**
 * __useLogoViewQuery__
 *
 * To run a query within a React component, call `useLogoViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoViewQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useLogoViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LogoViewQuery, LogoViewQueryVariables>) {
        return ApolloReactHooks.useQuery<LogoViewQuery, LogoViewQueryVariables>(LogoViewDocument, baseOptions);
      }
export function useLogoViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LogoViewQuery, LogoViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LogoViewQuery, LogoViewQueryVariables>(LogoViewDocument, baseOptions);
        }
export type LogoViewQueryHookResult = ReturnType<typeof useLogoViewQuery>;
export type LogoViewLazyQueryHookResult = ReturnType<typeof useLogoViewLazyQuery>;
export type LogoViewQueryResult = ApolloReactCommon.QueryResult<LogoViewQuery, LogoViewQueryVariables>;
export const GetFuelConsumptionSummaryDocument = gql`
    query getFuelConsumptionSummary($companyID: String) {
  fuelInfo(companyID: $companyID)
  getRolePermission
}
    `;

/**
 * __useGetFuelConsumptionSummaryQuery__
 *
 * To run a query within a React component, call `useGetFuelConsumptionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelConsumptionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelConsumptionSummaryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetFuelConsumptionSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFuelConsumptionSummaryQuery, GetFuelConsumptionSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFuelConsumptionSummaryQuery, GetFuelConsumptionSummaryQueryVariables>(GetFuelConsumptionSummaryDocument, baseOptions);
      }
export function useGetFuelConsumptionSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFuelConsumptionSummaryQuery, GetFuelConsumptionSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFuelConsumptionSummaryQuery, GetFuelConsumptionSummaryQueryVariables>(GetFuelConsumptionSummaryDocument, baseOptions);
        }
export type GetFuelConsumptionSummaryQueryHookResult = ReturnType<typeof useGetFuelConsumptionSummaryQuery>;
export type GetFuelConsumptionSummaryLazyQueryHookResult = ReturnType<typeof useGetFuelConsumptionSummaryLazyQuery>;
export type GetFuelConsumptionSummaryQueryResult = ApolloReactCommon.QueryResult<GetFuelConsumptionSummaryQuery, GetFuelConsumptionSummaryQueryVariables>;
export const GetFuelDischargeDocument = gql`
    query getFuelDischarge($orderByDesc: String, $orderByAsc: String, $tankID: String, $companyID: String!, $contractID: String, $approvalStatus: ApprovalStatus, $skip: Float, $take: Float) {
  getFuelDischarge(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, tankID: $tankID, companyID: $companyID, contractID: $contractID, approvalStatus: $approvalStatus, skip: $skip, take: $take) {
    ID
    tankID
    tank {
      ID
      name
      regNo
      description
      image
      imageUrl
      imageBucketFile
    }
    contractID
    contract {
      ID
      title
    }
    dischargeDate
    dischargeToTable
    dischargeToID
    currentDischarge
    totalDischarge
    operatorName
    remarks
    signature
    rejectionDate
    rejectionRemarks
    approvalStatus
    isMalfunction
  }
}
    `;

/**
 * __useGetFuelDischargeQuery__
 *
 * To run a query within a React component, call `useGetFuelDischargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelDischargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelDischargeQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      tankID: // value for 'tankID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      approvalStatus: // value for 'approvalStatus'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetFuelDischargeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFuelDischargeQuery, GetFuelDischargeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFuelDischargeQuery, GetFuelDischargeQueryVariables>(GetFuelDischargeDocument, baseOptions);
      }
export function useGetFuelDischargeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFuelDischargeQuery, GetFuelDischargeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFuelDischargeQuery, GetFuelDischargeQueryVariables>(GetFuelDischargeDocument, baseOptions);
        }
export type GetFuelDischargeQueryHookResult = ReturnType<typeof useGetFuelDischargeQuery>;
export type GetFuelDischargeLazyQueryHookResult = ReturnType<typeof useGetFuelDischargeLazyQuery>;
export type GetFuelDischargeQueryResult = ApolloReactCommon.QueryResult<GetFuelDischargeQuery, GetFuelDischargeQueryVariables>;
export const GetSupplyDischargeToDocument = gql`
    query getSupplyDischargeTo($companyID: String!, $contractID: String, $orderByAsc: String, $orderByDesc: String) {
  getSupplyDischargeTo(companyID: $companyID, contractID: $contractID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc)
}
    `;

/**
 * __useGetSupplyDischargeToQuery__
 *
 * To run a query within a React component, call `useGetSupplyDischargeToQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplyDischargeToQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplyDischargeToQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetSupplyDischargeToQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplyDischargeToQuery, GetSupplyDischargeToQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplyDischargeToQuery, GetSupplyDischargeToQueryVariables>(GetSupplyDischargeToDocument, baseOptions);
      }
export function useGetSupplyDischargeToLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplyDischargeToQuery, GetSupplyDischargeToQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplyDischargeToQuery, GetSupplyDischargeToQueryVariables>(GetSupplyDischargeToDocument, baseOptions);
        }
export type GetSupplyDischargeToQueryHookResult = ReturnType<typeof useGetSupplyDischargeToQuery>;
export type GetSupplyDischargeToLazyQueryHookResult = ReturnType<typeof useGetSupplyDischargeToLazyQuery>;
export type GetSupplyDischargeToQueryResult = ApolloReactCommon.QueryResult<GetSupplyDischargeToQuery, GetSupplyDischargeToQueryVariables>;
export const CreateFuelDischargeDocument = gql`
    mutation createFuelDischarge($input: FuelDischargeInput!) {
  createFuelDischarge(input: $input)
}
    `;
export type CreateFuelDischargeMutationFn = ApolloReactCommon.MutationFunction<CreateFuelDischargeMutation, CreateFuelDischargeMutationVariables>;

/**
 * __useCreateFuelDischargeMutation__
 *
 * To run a mutation, you first call `useCreateFuelDischargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFuelDischargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFuelDischargeMutation, { data, loading, error }] = useCreateFuelDischargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFuelDischargeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFuelDischargeMutation, CreateFuelDischargeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFuelDischargeMutation, CreateFuelDischargeMutationVariables>(CreateFuelDischargeDocument, baseOptions);
      }
export type CreateFuelDischargeMutationHookResult = ReturnType<typeof useCreateFuelDischargeMutation>;
export type CreateFuelDischargeMutationResult = ApolloReactCommon.MutationResult<CreateFuelDischargeMutation>;
export type CreateFuelDischargeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFuelDischargeMutation, CreateFuelDischargeMutationVariables>;
export const UpdateFuelDischargeDocument = gql`
    mutation updateFuelDischarge($input: FuelDischargeInput!) {
  updateFuelDischarge(input: $input)
}
    `;
export type UpdateFuelDischargeMutationFn = ApolloReactCommon.MutationFunction<UpdateFuelDischargeMutation, UpdateFuelDischargeMutationVariables>;

/**
 * __useUpdateFuelDischargeMutation__
 *
 * To run a mutation, you first call `useUpdateFuelDischargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFuelDischargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFuelDischargeMutation, { data, loading, error }] = useUpdateFuelDischargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFuelDischargeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFuelDischargeMutation, UpdateFuelDischargeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFuelDischargeMutation, UpdateFuelDischargeMutationVariables>(UpdateFuelDischargeDocument, baseOptions);
      }
export type UpdateFuelDischargeMutationHookResult = ReturnType<typeof useUpdateFuelDischargeMutation>;
export type UpdateFuelDischargeMutationResult = ApolloReactCommon.MutationResult<UpdateFuelDischargeMutation>;
export type UpdateFuelDischargeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFuelDischargeMutation, UpdateFuelDischargeMutationVariables>;
export const UpdateStatusFuelDischargeDocument = gql`
    mutation updateStatusFuelDischarge($input: FuelDischargeUpdateStatus!, $IDs: [String!]!) {
  updateStatusFuelDischarge(input: $input, IDs: $IDs)
}
    `;
export type UpdateStatusFuelDischargeMutationFn = ApolloReactCommon.MutationFunction<UpdateStatusFuelDischargeMutation, UpdateStatusFuelDischargeMutationVariables>;

/**
 * __useUpdateStatusFuelDischargeMutation__
 *
 * To run a mutation, you first call `useUpdateStatusFuelDischargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusFuelDischargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusFuelDischargeMutation, { data, loading, error }] = useUpdateStatusFuelDischargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useUpdateStatusFuelDischargeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStatusFuelDischargeMutation, UpdateStatusFuelDischargeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStatusFuelDischargeMutation, UpdateStatusFuelDischargeMutationVariables>(UpdateStatusFuelDischargeDocument, baseOptions);
      }
export type UpdateStatusFuelDischargeMutationHookResult = ReturnType<typeof useUpdateStatusFuelDischargeMutation>;
export type UpdateStatusFuelDischargeMutationResult = ApolloReactCommon.MutationResult<UpdateStatusFuelDischargeMutation>;
export type UpdateStatusFuelDischargeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStatusFuelDischargeMutation, UpdateStatusFuelDischargeMutationVariables>;
export const DeleteFuelDischargeDocument = gql`
    mutation deleteFuelDischarge($ID: String!) {
  deleteFuelDischarge(ID: $ID)
}
    `;
export type DeleteFuelDischargeMutationFn = ApolloReactCommon.MutationFunction<DeleteFuelDischargeMutation, DeleteFuelDischargeMutationVariables>;

/**
 * __useDeleteFuelDischargeMutation__
 *
 * To run a mutation, you first call `useDeleteFuelDischargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFuelDischargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFuelDischargeMutation, { data, loading, error }] = useDeleteFuelDischargeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteFuelDischargeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFuelDischargeMutation, DeleteFuelDischargeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFuelDischargeMutation, DeleteFuelDischargeMutationVariables>(DeleteFuelDischargeDocument, baseOptions);
      }
export type DeleteFuelDischargeMutationHookResult = ReturnType<typeof useDeleteFuelDischargeMutation>;
export type DeleteFuelDischargeMutationResult = ApolloReactCommon.MutationResult<DeleteFuelDischargeMutation>;
export type DeleteFuelDischargeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFuelDischargeMutation, DeleteFuelDischargeMutationVariables>;
export const GetFuelBalanceDocument = gql`
    query getFuelBalance($tankID: String!) {
  getFuelBalance(tankID: $tankID)
}
    `;

/**
 * __useGetFuelBalanceQuery__
 *
 * To run a query within a React component, call `useGetFuelBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelBalanceQuery({
 *   variables: {
 *      tankID: // value for 'tankID'
 *   },
 * });
 */
export function useGetFuelBalanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFuelBalanceQuery, GetFuelBalanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFuelBalanceQuery, GetFuelBalanceQueryVariables>(GetFuelBalanceDocument, baseOptions);
      }
export function useGetFuelBalanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFuelBalanceQuery, GetFuelBalanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFuelBalanceQuery, GetFuelBalanceQueryVariables>(GetFuelBalanceDocument, baseOptions);
        }
export type GetFuelBalanceQueryHookResult = ReturnType<typeof useGetFuelBalanceQuery>;
export type GetFuelBalanceLazyQueryHookResult = ReturnType<typeof useGetFuelBalanceLazyQuery>;
export type GetFuelBalanceQueryResult = ApolloReactCommon.QueryResult<GetFuelBalanceQuery, GetFuelBalanceQueryVariables>;
export const GetFuelDischargeListingDocument = gql`
    query getFuelDischargeListing($orderByDesc: String, $orderByAsc: String, $tankID: String, $companyID: String!, $contractID: String, $approvalStatus: ApprovalStatus, $skip: Float, $take: Float, $searchValue: String) {
  getFuelDischarge(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, tankID: $tankID, companyID: $companyID, contractID: $contractID, approvalStatus: $approvalStatus, skip: $skip, take: $take, searchValue: $searchValue) {
    ID
    tankID
    tank {
      ID
      name
      regNo
      description
      image
      imageUrl
      imageBucketFile
    }
    contractID
    contract {
      ID
      title
    }
    dischargeDate
    dischargeToTable
    dischargeToID
    currentDischarge
    totalDischarge
    operatorName
    remarks
    signature
    rejectionDate
    rejectionRemarks
    approvalStatus
    isMalfunction
  }
  getFuelDischargeCount(tankID: $tankID, companyID: $companyID, contractID: $contractID, approvalStatus: $approvalStatus)
}
    `;

/**
 * __useGetFuelDischargeListingQuery__
 *
 * To run a query within a React component, call `useGetFuelDischargeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelDischargeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelDischargeListingQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      tankID: // value for 'tankID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      approvalStatus: // value for 'approvalStatus'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetFuelDischargeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFuelDischargeListingQuery, GetFuelDischargeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFuelDischargeListingQuery, GetFuelDischargeListingQueryVariables>(GetFuelDischargeListingDocument, baseOptions);
      }
export function useGetFuelDischargeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFuelDischargeListingQuery, GetFuelDischargeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFuelDischargeListingQuery, GetFuelDischargeListingQueryVariables>(GetFuelDischargeListingDocument, baseOptions);
        }
export type GetFuelDischargeListingQueryHookResult = ReturnType<typeof useGetFuelDischargeListingQuery>;
export type GetFuelDischargeListingLazyQueryHookResult = ReturnType<typeof useGetFuelDischargeListingLazyQuery>;
export type GetFuelDischargeListingQueryResult = ApolloReactCommon.QueryResult<GetFuelDischargeListingQuery, GetFuelDischargeListingQueryVariables>;
export const GetFuelDischargeCountDocument = gql`
    query getFuelDischargeCount($tankID: String, $companyID: String!, $contractID: String, $approvalStatus: ApprovalStatus) {
  getFuelDischargeCount(tankID: $tankID, companyID: $companyID, contractID: $contractID, approvalStatus: $approvalStatus)
}
    `;

/**
 * __useGetFuelDischargeCountQuery__
 *
 * To run a query within a React component, call `useGetFuelDischargeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelDischargeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelDischargeCountQuery({
 *   variables: {
 *      tankID: // value for 'tankID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      approvalStatus: // value for 'approvalStatus'
 *   },
 * });
 */
export function useGetFuelDischargeCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFuelDischargeCountQuery, GetFuelDischargeCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFuelDischargeCountQuery, GetFuelDischargeCountQueryVariables>(GetFuelDischargeCountDocument, baseOptions);
      }
export function useGetFuelDischargeCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFuelDischargeCountQuery, GetFuelDischargeCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFuelDischargeCountQuery, GetFuelDischargeCountQueryVariables>(GetFuelDischargeCountDocument, baseOptions);
        }
export type GetFuelDischargeCountQueryHookResult = ReturnType<typeof useGetFuelDischargeCountQuery>;
export type GetFuelDischargeCountLazyQueryHookResult = ReturnType<typeof useGetFuelDischargeCountLazyQuery>;
export type GetFuelDischargeCountQueryResult = ApolloReactCommon.QueryResult<GetFuelDischargeCountQuery, GetFuelDischargeCountQueryVariables>;
export const GetTankDocument = gql`
    query getTank($ID: String, $companyID: String!, $contractID: String, $hasMeterReading: Boolean, $orderByAsc: String, $orderByDesc: String) {
  getTank(ID: $ID, companyID: $companyID, contractID: $contractID, hasMeterReading: $hasMeterReading, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    supplierID
    companyID
    regNo
    name
    description
    remarks
    purchaseDate
    quantity
    availableQty
    serialNo
    warrantyDate
    roadTaxDate
    insuranceDate
    cancellationDate
    cancellationRemarks
    image
    imageUrl
    plantMachineryStatus
    fuelTotalSupply
    fuelTotalDischarge
    tankTable
    supplier {
      ID
      name
      description
      email
    }
    costCategoryID
    costCategory {
      ID
      name
      description
    }
    assignment {
      contractID
      contract {
        ID
        title
      }
    }
    latestActiveAssignment
    latestActiveMaintenance
    currentPnmStatus
    costCategory {
      ID
      name
      description
    }
    tankSummary(companyID: $companyID)
  }
}
    `;

/**
 * __useGetTankQuery__
 *
 * To run a query within a React component, call `useGetTankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTankQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      hasMeterReading: // value for 'hasMeterReading'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetTankQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTankQuery, GetTankQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTankQuery, GetTankQueryVariables>(GetTankDocument, baseOptions);
      }
export function useGetTankLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTankQuery, GetTankQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTankQuery, GetTankQueryVariables>(GetTankDocument, baseOptions);
        }
export type GetTankQueryHookResult = ReturnType<typeof useGetTankQuery>;
export type GetTankLazyQueryHookResult = ReturnType<typeof useGetTankLazyQuery>;
export type GetTankQueryResult = ApolloReactCommon.QueryResult<GetTankQuery, GetTankQueryVariables>;
export const GetFuelSupplyDocument = gql`
    query getFuelSupply($ID: String, $tankID: String, $companyID: String, $contractID: String, $orderByDesc: String, $orderByAsc: String, $approvalStatus: ApprovalStatus, $skip: Float, $take: Float) {
  getFuelSupply(ID: $ID, tankID: $tankID, companyID: $companyID, contractID: $contractID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, approvalStatus: $approvalStatus, skip: $skip, take: $take) {
    ID
    tankID
    tank {
      ID
      name
      regNo
      description
      image
      imageUrl
      imageBucketFile
    }
    contractID
    contract {
      ID
      title
    }
    createdBy
    createdTs
    docDate
    doNo
    currentSupply
    totalSupply
    supplyByTable
    supplyRefID
    remarks
    signature
    rejectionDate
    rejectionRemarks
    approvalStatus
  }
}
    `;

/**
 * __useGetFuelSupplyQuery__
 *
 * To run a query within a React component, call `useGetFuelSupplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelSupplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelSupplyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      tankID: // value for 'tankID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      approvalStatus: // value for 'approvalStatus'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetFuelSupplyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFuelSupplyQuery, GetFuelSupplyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFuelSupplyQuery, GetFuelSupplyQueryVariables>(GetFuelSupplyDocument, baseOptions);
      }
export function useGetFuelSupplyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFuelSupplyQuery, GetFuelSupplyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFuelSupplyQuery, GetFuelSupplyQueryVariables>(GetFuelSupplyDocument, baseOptions);
        }
export type GetFuelSupplyQueryHookResult = ReturnType<typeof useGetFuelSupplyQuery>;
export type GetFuelSupplyLazyQueryHookResult = ReturnType<typeof useGetFuelSupplyLazyQuery>;
export type GetFuelSupplyQueryResult = ApolloReactCommon.QueryResult<GetFuelSupplyQuery, GetFuelSupplyQueryVariables>;
export const CreateFuelSupplyDocument = gql`
    mutation createFuelSupply($input: FuelSupplyInput!) {
  createFuelSupply(input: $input)
}
    `;
export type CreateFuelSupplyMutationFn = ApolloReactCommon.MutationFunction<CreateFuelSupplyMutation, CreateFuelSupplyMutationVariables>;

/**
 * __useCreateFuelSupplyMutation__
 *
 * To run a mutation, you first call `useCreateFuelSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFuelSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFuelSupplyMutation, { data, loading, error }] = useCreateFuelSupplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFuelSupplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFuelSupplyMutation, CreateFuelSupplyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFuelSupplyMutation, CreateFuelSupplyMutationVariables>(CreateFuelSupplyDocument, baseOptions);
      }
export type CreateFuelSupplyMutationHookResult = ReturnType<typeof useCreateFuelSupplyMutation>;
export type CreateFuelSupplyMutationResult = ApolloReactCommon.MutationResult<CreateFuelSupplyMutation>;
export type CreateFuelSupplyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFuelSupplyMutation, CreateFuelSupplyMutationVariables>;
export const UpdateFuelSupplyDocument = gql`
    mutation updateFuelSupply($input: FuelSupplyInput!) {
  updateFuelSupply(input: $input)
}
    `;
export type UpdateFuelSupplyMutationFn = ApolloReactCommon.MutationFunction<UpdateFuelSupplyMutation, UpdateFuelSupplyMutationVariables>;

/**
 * __useUpdateFuelSupplyMutation__
 *
 * To run a mutation, you first call `useUpdateFuelSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFuelSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFuelSupplyMutation, { data, loading, error }] = useUpdateFuelSupplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFuelSupplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFuelSupplyMutation, UpdateFuelSupplyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFuelSupplyMutation, UpdateFuelSupplyMutationVariables>(UpdateFuelSupplyDocument, baseOptions);
      }
export type UpdateFuelSupplyMutationHookResult = ReturnType<typeof useUpdateFuelSupplyMutation>;
export type UpdateFuelSupplyMutationResult = ApolloReactCommon.MutationResult<UpdateFuelSupplyMutation>;
export type UpdateFuelSupplyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFuelSupplyMutation, UpdateFuelSupplyMutationVariables>;
export const DeleteFuelSupplyDocument = gql`
    mutation deleteFuelSupply($ID: String!) {
  deleteFuelSupply(ID: $ID)
}
    `;
export type DeleteFuelSupplyMutationFn = ApolloReactCommon.MutationFunction<DeleteFuelSupplyMutation, DeleteFuelSupplyMutationVariables>;

/**
 * __useDeleteFuelSupplyMutation__
 *
 * To run a mutation, you first call `useDeleteFuelSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFuelSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFuelSupplyMutation, { data, loading, error }] = useDeleteFuelSupplyMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteFuelSupplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFuelSupplyMutation, DeleteFuelSupplyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFuelSupplyMutation, DeleteFuelSupplyMutationVariables>(DeleteFuelSupplyDocument, baseOptions);
      }
export type DeleteFuelSupplyMutationHookResult = ReturnType<typeof useDeleteFuelSupplyMutation>;
export type DeleteFuelSupplyMutationResult = ApolloReactCommon.MutationResult<DeleteFuelSupplyMutation>;
export type DeleteFuelSupplyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFuelSupplyMutation, DeleteFuelSupplyMutationVariables>;
export const GetSupplyByDocument = gql`
    query getSupplyBy($companyID: String!, $contractID: String, $orderByAsc: String) {
  getSupplyBy(companyID: $companyID, contractID: $contractID, orderByAsc: $orderByAsc)
}
    `;

/**
 * __useGetSupplyByQuery__
 *
 * To run a query within a React component, call `useGetSupplyByQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplyByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplyByQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSupplyByQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplyByQuery, GetSupplyByQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplyByQuery, GetSupplyByQueryVariables>(GetSupplyByDocument, baseOptions);
      }
export function useGetSupplyByLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplyByQuery, GetSupplyByQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplyByQuery, GetSupplyByQueryVariables>(GetSupplyByDocument, baseOptions);
        }
export type GetSupplyByQueryHookResult = ReturnType<typeof useGetSupplyByQuery>;
export type GetSupplyByLazyQueryHookResult = ReturnType<typeof useGetSupplyByLazyQuery>;
export type GetSupplyByQueryResult = ApolloReactCommon.QueryResult<GetSupplyByQuery, GetSupplyByQueryVariables>;
export const ActionOnFuelSupplyStatusDocument = gql`
    mutation actionOnFuelSupplyStatus($IDs: [String!]!, $input: FuelSupplyActionInput!) {
  actionOnFuelSupplyStatus(IDs: $IDs, input: $input)
}
    `;
export type ActionOnFuelSupplyStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnFuelSupplyStatusMutation, ActionOnFuelSupplyStatusMutationVariables>;

/**
 * __useActionOnFuelSupplyStatusMutation__
 *
 * To run a mutation, you first call `useActionOnFuelSupplyStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnFuelSupplyStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnFuelSupplyStatusMutation, { data, loading, error }] = useActionOnFuelSupplyStatusMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActionOnFuelSupplyStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnFuelSupplyStatusMutation, ActionOnFuelSupplyStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnFuelSupplyStatusMutation, ActionOnFuelSupplyStatusMutationVariables>(ActionOnFuelSupplyStatusDocument, baseOptions);
      }
export type ActionOnFuelSupplyStatusMutationHookResult = ReturnType<typeof useActionOnFuelSupplyStatusMutation>;
export type ActionOnFuelSupplyStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnFuelSupplyStatusMutation>;
export type ActionOnFuelSupplyStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnFuelSupplyStatusMutation, ActionOnFuelSupplyStatusMutationVariables>;
export const GetAllApprovalLogDocument = gql`
    query getAllApprovalLog($refIDs: [String!]!) {
  getAllApprovalLog(refIDs: $refIDs)
}
    `;

/**
 * __useGetAllApprovalLogQuery__
 *
 * To run a query within a React component, call `useGetAllApprovalLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllApprovalLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllApprovalLogQuery({
 *   variables: {
 *      refIDs: // value for 'refIDs'
 *   },
 * });
 */
export function useGetAllApprovalLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllApprovalLogQuery, GetAllApprovalLogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllApprovalLogQuery, GetAllApprovalLogQueryVariables>(GetAllApprovalLogDocument, baseOptions);
      }
export function useGetAllApprovalLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllApprovalLogQuery, GetAllApprovalLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllApprovalLogQuery, GetAllApprovalLogQueryVariables>(GetAllApprovalLogDocument, baseOptions);
        }
export type GetAllApprovalLogQueryHookResult = ReturnType<typeof useGetAllApprovalLogQuery>;
export type GetAllApprovalLogLazyQueryHookResult = ReturnType<typeof useGetAllApprovalLogLazyQuery>;
export type GetAllApprovalLogQueryResult = ApolloReactCommon.QueryResult<GetAllApprovalLogQuery, GetAllApprovalLogQueryVariables>;
export const GetFuelSupplyListingDocument = gql`
    query getFuelSupplyListing($ID: String, $tankID: String, $companyID: String, $contractID: String, $orderByDesc: String, $orderByAsc: String, $approvalStatus: ApprovalStatus, $skip: Float, $take: Float, $searchValue: String) {
  getFuelSupply(ID: $ID, tankID: $tankID, companyID: $companyID, contractID: $contractID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, approvalStatus: $approvalStatus, skip: $skip, take: $take, searchValue: $searchValue) {
    ID
    tankID
    contractID
    contract {
      ID
      title
    }
    docDate
    doNo
    currentSupply
    totalSupply
    supplyRefID
    supplyByTable
    remarks
    signature
    tank {
      ID
      name
    }
    rejectionDate
    rejectionRemarks
    approvalStatus
  }
  getFuelSupplyCount(tankID: $tankID, companyID: $companyID, contractID: $contractID, approvalStatus: $approvalStatus)
}
    `;

/**
 * __useGetFuelSupplyListingQuery__
 *
 * To run a query within a React component, call `useGetFuelSupplyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelSupplyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelSupplyListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      tankID: // value for 'tankID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      approvalStatus: // value for 'approvalStatus'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetFuelSupplyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFuelSupplyListingQuery, GetFuelSupplyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFuelSupplyListingQuery, GetFuelSupplyListingQueryVariables>(GetFuelSupplyListingDocument, baseOptions);
      }
export function useGetFuelSupplyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFuelSupplyListingQuery, GetFuelSupplyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFuelSupplyListingQuery, GetFuelSupplyListingQueryVariables>(GetFuelSupplyListingDocument, baseOptions);
        }
export type GetFuelSupplyListingQueryHookResult = ReturnType<typeof useGetFuelSupplyListingQuery>;
export type GetFuelSupplyListingLazyQueryHookResult = ReturnType<typeof useGetFuelSupplyListingLazyQuery>;
export type GetFuelSupplyListingQueryResult = ApolloReactCommon.QueryResult<GetFuelSupplyListingQuery, GetFuelSupplyListingQueryVariables>;
export const GetFuelMeterReadingDocument = gql`
    query getFuelMeterReading($contractID: String, $tankID: String, $orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float) {
  getFuelMeterReading(contractID: $contractID, tankID: $tankID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take) {
    ID
    tankID
    tank {
      ID
      name
      regNo
      description
      image
      imageUrl
      imageBucketFile
    }
    remarks
    docDate
    lastSupply
    currentSupply
    totalSupply
    lastDischarge
    currentDischarge
    totalDischarge
  }
}
    `;

/**
 * __useGetFuelMeterReadingQuery__
 *
 * To run a query within a React component, call `useGetFuelMeterReadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelMeterReadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelMeterReadingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      tankID: // value for 'tankID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetFuelMeterReadingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFuelMeterReadingQuery, GetFuelMeterReadingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFuelMeterReadingQuery, GetFuelMeterReadingQueryVariables>(GetFuelMeterReadingDocument, baseOptions);
      }
export function useGetFuelMeterReadingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFuelMeterReadingQuery, GetFuelMeterReadingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFuelMeterReadingQuery, GetFuelMeterReadingQueryVariables>(GetFuelMeterReadingDocument, baseOptions);
        }
export type GetFuelMeterReadingQueryHookResult = ReturnType<typeof useGetFuelMeterReadingQuery>;
export type GetFuelMeterReadingLazyQueryHookResult = ReturnType<typeof useGetFuelMeterReadingLazyQuery>;
export type GetFuelMeterReadingQueryResult = ApolloReactCommon.QueryResult<GetFuelMeterReadingQuery, GetFuelMeterReadingQueryVariables>;
export const FuelMeterReadingSummaryDocument = gql`
    query fuelMeterReadingSummary($contractID: String!, $date: String, $tankID: String) {
  fuelMeterReadingSummary(contractID: $contractID, date: $date, tankID: $tankID)
}
    `;

/**
 * __useFuelMeterReadingSummaryQuery__
 *
 * To run a query within a React component, call `useFuelMeterReadingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFuelMeterReadingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFuelMeterReadingSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      date: // value for 'date'
 *      tankID: // value for 'tankID'
 *   },
 * });
 */
export function useFuelMeterReadingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FuelMeterReadingSummaryQuery, FuelMeterReadingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<FuelMeterReadingSummaryQuery, FuelMeterReadingSummaryQueryVariables>(FuelMeterReadingSummaryDocument, baseOptions);
      }
export function useFuelMeterReadingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FuelMeterReadingSummaryQuery, FuelMeterReadingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FuelMeterReadingSummaryQuery, FuelMeterReadingSummaryQueryVariables>(FuelMeterReadingSummaryDocument, baseOptions);
        }
export type FuelMeterReadingSummaryQueryHookResult = ReturnType<typeof useFuelMeterReadingSummaryQuery>;
export type FuelMeterReadingSummaryLazyQueryHookResult = ReturnType<typeof useFuelMeterReadingSummaryLazyQuery>;
export type FuelMeterReadingSummaryQueryResult = ApolloReactCommon.QueryResult<FuelMeterReadingSummaryQuery, FuelMeterReadingSummaryQueryVariables>;
export const GetContractFuelDocument = gql`
    query getContractFuel($companyID: String, $contractID: String) {
  getContractFuel(companyID: $companyID, contractID: $contractID)
}
    `;

/**
 * __useGetContractFuelQuery__
 *
 * To run a query within a React component, call `useGetContractFuelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractFuelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractFuelQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractFuelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractFuelQuery, GetContractFuelQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractFuelQuery, GetContractFuelQueryVariables>(GetContractFuelDocument, baseOptions);
      }
export function useGetContractFuelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractFuelQuery, GetContractFuelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractFuelQuery, GetContractFuelQueryVariables>(GetContractFuelDocument, baseOptions);
        }
export type GetContractFuelQueryHookResult = ReturnType<typeof useGetContractFuelQuery>;
export type GetContractFuelLazyQueryHookResult = ReturnType<typeof useGetContractFuelLazyQuery>;
export type GetContractFuelQueryResult = ApolloReactCommon.QueryResult<GetContractFuelQuery, GetContractFuelQueryVariables>;
export const CreateFuelMeterReadingDocument = gql`
    mutation createFuelMeterReading($input: FuelMeterReadingInput!) {
  createFuelMeterReading(input: $input) {
    ID
    tankID
    docDate
    lastSupply
    currentSupply
    totalSupply
    lastDischarge
    currentDischarge
    totalDischarge
  }
}
    `;
export type CreateFuelMeterReadingMutationFn = ApolloReactCommon.MutationFunction<CreateFuelMeterReadingMutation, CreateFuelMeterReadingMutationVariables>;

/**
 * __useCreateFuelMeterReadingMutation__
 *
 * To run a mutation, you first call `useCreateFuelMeterReadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFuelMeterReadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFuelMeterReadingMutation, { data, loading, error }] = useCreateFuelMeterReadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFuelMeterReadingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFuelMeterReadingMutation, CreateFuelMeterReadingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFuelMeterReadingMutation, CreateFuelMeterReadingMutationVariables>(CreateFuelMeterReadingDocument, baseOptions);
      }
export type CreateFuelMeterReadingMutationHookResult = ReturnType<typeof useCreateFuelMeterReadingMutation>;
export type CreateFuelMeterReadingMutationResult = ApolloReactCommon.MutationResult<CreateFuelMeterReadingMutation>;
export type CreateFuelMeterReadingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFuelMeterReadingMutation, CreateFuelMeterReadingMutationVariables>;
export const UpdateFuelMeterReadingDocument = gql`
    mutation updateFuelMeterReading($input: FuelMeterReadingInput!) {
  updateFuelMeterReading(input: $input) {
    ID
    tankID
    docDate
    lastSupply
    currentSupply
    totalSupply
    lastDischarge
    currentDischarge
    totalDischarge
  }
}
    `;
export type UpdateFuelMeterReadingMutationFn = ApolloReactCommon.MutationFunction<UpdateFuelMeterReadingMutation, UpdateFuelMeterReadingMutationVariables>;

/**
 * __useUpdateFuelMeterReadingMutation__
 *
 * To run a mutation, you first call `useUpdateFuelMeterReadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFuelMeterReadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFuelMeterReadingMutation, { data, loading, error }] = useUpdateFuelMeterReadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFuelMeterReadingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFuelMeterReadingMutation, UpdateFuelMeterReadingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFuelMeterReadingMutation, UpdateFuelMeterReadingMutationVariables>(UpdateFuelMeterReadingDocument, baseOptions);
      }
export type UpdateFuelMeterReadingMutationHookResult = ReturnType<typeof useUpdateFuelMeterReadingMutation>;
export type UpdateFuelMeterReadingMutationResult = ApolloReactCommon.MutationResult<UpdateFuelMeterReadingMutation>;
export type UpdateFuelMeterReadingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFuelMeterReadingMutation, UpdateFuelMeterReadingMutationVariables>;
export const DeleteFuelMeterReadingDocument = gql`
    mutation deleteFuelMeterReading($input: FuelMeterReadingDeleteInput!) {
  deleteFuelMeterReading(input: $input)
}
    `;
export type DeleteFuelMeterReadingMutationFn = ApolloReactCommon.MutationFunction<DeleteFuelMeterReadingMutation, DeleteFuelMeterReadingMutationVariables>;

/**
 * __useDeleteFuelMeterReadingMutation__
 *
 * To run a mutation, you first call `useDeleteFuelMeterReadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFuelMeterReadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFuelMeterReadingMutation, { data, loading, error }] = useDeleteFuelMeterReadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFuelMeterReadingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFuelMeterReadingMutation, DeleteFuelMeterReadingMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFuelMeterReadingMutation, DeleteFuelMeterReadingMutationVariables>(DeleteFuelMeterReadingDocument, baseOptions);
      }
export type DeleteFuelMeterReadingMutationHookResult = ReturnType<typeof useDeleteFuelMeterReadingMutation>;
export type DeleteFuelMeterReadingMutationResult = ApolloReactCommon.MutationResult<DeleteFuelMeterReadingMutation>;
export type DeleteFuelMeterReadingMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFuelMeterReadingMutation, DeleteFuelMeterReadingMutationVariables>;
export const GetFuelMeterReadingCountDocument = gql`
    query getFuelMeterReadingCount($contractID: String, $tankID: String) {
  getFuelMeterReadingCount(contractID: $contractID, tankID: $tankID)
}
    `;

/**
 * __useGetFuelMeterReadingCountQuery__
 *
 * To run a query within a React component, call `useGetFuelMeterReadingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelMeterReadingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelMeterReadingCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      tankID: // value for 'tankID'
 *   },
 * });
 */
export function useGetFuelMeterReadingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFuelMeterReadingCountQuery, GetFuelMeterReadingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFuelMeterReadingCountQuery, GetFuelMeterReadingCountQueryVariables>(GetFuelMeterReadingCountDocument, baseOptions);
      }
export function useGetFuelMeterReadingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFuelMeterReadingCountQuery, GetFuelMeterReadingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFuelMeterReadingCountQuery, GetFuelMeterReadingCountQueryVariables>(GetFuelMeterReadingCountDocument, baseOptions);
        }
export type GetFuelMeterReadingCountQueryHookResult = ReturnType<typeof useGetFuelMeterReadingCountQuery>;
export type GetFuelMeterReadingCountLazyQueryHookResult = ReturnType<typeof useGetFuelMeterReadingCountLazyQuery>;
export type GetFuelMeterReadingCountQueryResult = ApolloReactCommon.QueryResult<GetFuelMeterReadingCountQuery, GetFuelMeterReadingCountQueryVariables>;
export const GetFuelMeterReadingListingDocument = gql`
    query getFuelMeterReadingListing($contractID: String, $tankID: String, $orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $searchValue: String) {
  getFuelMeterReading(contractID: $contractID, tankID: $tankID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, searchValue: $searchValue) {
    ID
    tankID
    tank {
      ID
      name
      regNo
      description
      image
      imageUrl
      imageBucketFile
    }
    remarks
    docDate
    lastSupply
    currentSupply
    totalSupply
    lastDischarge
    currentDischarge
    totalDischarge
  }
  getFuelMeterReadingCount(contractID: $contractID, tankID: $tankID)
}
    `;

/**
 * __useGetFuelMeterReadingListingQuery__
 *
 * To run a query within a React component, call `useGetFuelMeterReadingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelMeterReadingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelMeterReadingListingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      tankID: // value for 'tankID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetFuelMeterReadingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFuelMeterReadingListingQuery, GetFuelMeterReadingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFuelMeterReadingListingQuery, GetFuelMeterReadingListingQueryVariables>(GetFuelMeterReadingListingDocument, baseOptions);
      }
export function useGetFuelMeterReadingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFuelMeterReadingListingQuery, GetFuelMeterReadingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFuelMeterReadingListingQuery, GetFuelMeterReadingListingQueryVariables>(GetFuelMeterReadingListingDocument, baseOptions);
        }
export type GetFuelMeterReadingListingQueryHookResult = ReturnType<typeof useGetFuelMeterReadingListingQuery>;
export type GetFuelMeterReadingListingLazyQueryHookResult = ReturnType<typeof useGetFuelMeterReadingListingLazyQuery>;
export type GetFuelMeterReadingListingQueryResult = ApolloReactCommon.QueryResult<GetFuelMeterReadingListingQuery, GetFuelMeterReadingListingQueryVariables>;
export const GetFuelTankSetupDocument = gql`
    query getFuelTankSetup($ID: String, $companyID: String, $tankType: TankType, $take: Float, $skip: Float, $searchValue: String) {
  getFuelTankSetup(ID: $ID, companyID: $companyID, tankType: $tankType, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    name
    regNo
    companyID
    image
    imageUrl
    imageBucketFile
    tankType
    plantMachineryID
    remark
    fuelConsumptionLowerLimit
    fuelConsumptionUpperLimit
    fuelConsumptionMethod
    serialNo
    purchaseDate
    warrantyDate
  }
  getFuelTankSetupCount(companyID: $companyID, tankType: $tankType)
}
    `;

/**
 * __useGetFuelTankSetupQuery__
 *
 * To run a query within a React component, call `useGetFuelTankSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelTankSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelTankSetupQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      tankType: // value for 'tankType'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetFuelTankSetupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFuelTankSetupQuery, GetFuelTankSetupQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFuelTankSetupQuery, GetFuelTankSetupQueryVariables>(GetFuelTankSetupDocument, baseOptions);
      }
export function useGetFuelTankSetupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFuelTankSetupQuery, GetFuelTankSetupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFuelTankSetupQuery, GetFuelTankSetupQueryVariables>(GetFuelTankSetupDocument, baseOptions);
        }
export type GetFuelTankSetupQueryHookResult = ReturnType<typeof useGetFuelTankSetupQuery>;
export type GetFuelTankSetupLazyQueryHookResult = ReturnType<typeof useGetFuelTankSetupLazyQuery>;
export type GetFuelTankSetupQueryResult = ApolloReactCommon.QueryResult<GetFuelTankSetupQuery, GetFuelTankSetupQueryVariables>;
export const CreateFuelTankSetupDocument = gql`
    mutation createFuelTankSetup($input: FuelTankSetupInput!) {
  createFuelTankSetup(input: $input)
}
    `;
export type CreateFuelTankSetupMutationFn = ApolloReactCommon.MutationFunction<CreateFuelTankSetupMutation, CreateFuelTankSetupMutationVariables>;

/**
 * __useCreateFuelTankSetupMutation__
 *
 * To run a mutation, you first call `useCreateFuelTankSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFuelTankSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFuelTankSetupMutation, { data, loading, error }] = useCreateFuelTankSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFuelTankSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFuelTankSetupMutation, CreateFuelTankSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFuelTankSetupMutation, CreateFuelTankSetupMutationVariables>(CreateFuelTankSetupDocument, baseOptions);
      }
export type CreateFuelTankSetupMutationHookResult = ReturnType<typeof useCreateFuelTankSetupMutation>;
export type CreateFuelTankSetupMutationResult = ApolloReactCommon.MutationResult<CreateFuelTankSetupMutation>;
export type CreateFuelTankSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFuelTankSetupMutation, CreateFuelTankSetupMutationVariables>;
export const UpdateFuelTankSetupDocument = gql`
    mutation updateFuelTankSetup($input: FuelTankSetupInput!) {
  updateFuelTankSetup(input: $input)
}
    `;
export type UpdateFuelTankSetupMutationFn = ApolloReactCommon.MutationFunction<UpdateFuelTankSetupMutation, UpdateFuelTankSetupMutationVariables>;

/**
 * __useUpdateFuelTankSetupMutation__
 *
 * To run a mutation, you first call `useUpdateFuelTankSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFuelTankSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFuelTankSetupMutation, { data, loading, error }] = useUpdateFuelTankSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFuelTankSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFuelTankSetupMutation, UpdateFuelTankSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFuelTankSetupMutation, UpdateFuelTankSetupMutationVariables>(UpdateFuelTankSetupDocument, baseOptions);
      }
export type UpdateFuelTankSetupMutationHookResult = ReturnType<typeof useUpdateFuelTankSetupMutation>;
export type UpdateFuelTankSetupMutationResult = ApolloReactCommon.MutationResult<UpdateFuelTankSetupMutation>;
export type UpdateFuelTankSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFuelTankSetupMutation, UpdateFuelTankSetupMutationVariables>;
export const DeleteFuelTankSetupDocument = gql`
    mutation deleteFuelTankSetup($input: FuelTankSetupDeleteInput!) {
  deleteFuelTankSetup(input: $input)
}
    `;
export type DeleteFuelTankSetupMutationFn = ApolloReactCommon.MutationFunction<DeleteFuelTankSetupMutation, DeleteFuelTankSetupMutationVariables>;

/**
 * __useDeleteFuelTankSetupMutation__
 *
 * To run a mutation, you first call `useDeleteFuelTankSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFuelTankSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFuelTankSetupMutation, { data, loading, error }] = useDeleteFuelTankSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFuelTankSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFuelTankSetupMutation, DeleteFuelTankSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFuelTankSetupMutation, DeleteFuelTankSetupMutationVariables>(DeleteFuelTankSetupDocument, baseOptions);
      }
export type DeleteFuelTankSetupMutationHookResult = ReturnType<typeof useDeleteFuelTankSetupMutation>;
export type DeleteFuelTankSetupMutationResult = ApolloReactCommon.MutationResult<DeleteFuelTankSetupMutation>;
export type DeleteFuelTankSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFuelTankSetupMutation, DeleteFuelTankSetupMutationVariables>;
export const GetCbExportDocument = gql`
    query getCBExport($ID: String, $orderByDesc: String) {
  getCBExport(ID: $ID, orderByDesc: $orderByDesc) {
    ID
    startDate
    endDate
    modTs
    transactionDate
    cbExportDetail {
      bankAccountID
      branch
      accountNo
      docRefID
      docRefTable
      description
      docDate
      docNo
      docRef
      docAmt
    }
  }
}
    `;

/**
 * __useGetCbExportQuery__
 *
 * To run a query within a React component, call `useGetCbExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCbExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCbExportQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCbExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCbExportQuery, GetCbExportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCbExportQuery, GetCbExportQueryVariables>(GetCbExportDocument, baseOptions);
      }
export function useGetCbExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCbExportQuery, GetCbExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCbExportQuery, GetCbExportQueryVariables>(GetCbExportDocument, baseOptions);
        }
export type GetCbExportQueryHookResult = ReturnType<typeof useGetCbExportQuery>;
export type GetCbExportLazyQueryHookResult = ReturnType<typeof useGetCbExportLazyQuery>;
export type GetCbExportQueryResult = ApolloReactCommon.QueryResult<GetCbExportQuery, GetCbExportQueryVariables>;
export const ExportCbLedgerDocument = gql`
    mutation exportCBLedger($ledgers: [CBLedgerInput!]!, $input: CBExportInput!) {
  exportCBLedger(ledgers: $ledgers, input: $input)
}
    `;
export type ExportCbLedgerMutationFn = ApolloReactCommon.MutationFunction<ExportCbLedgerMutation, ExportCbLedgerMutationVariables>;

/**
 * __useExportCbLedgerMutation__
 *
 * To run a mutation, you first call `useExportCbLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCbLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCbLedgerMutation, { data, loading, error }] = useExportCbLedgerMutation({
 *   variables: {
 *      ledgers: // value for 'ledgers'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportCbLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExportCbLedgerMutation, ExportCbLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<ExportCbLedgerMutation, ExportCbLedgerMutationVariables>(ExportCbLedgerDocument, baseOptions);
      }
export type ExportCbLedgerMutationHookResult = ReturnType<typeof useExportCbLedgerMutation>;
export type ExportCbLedgerMutationResult = ApolloReactCommon.MutationResult<ExportCbLedgerMutation>;
export type ExportCbLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<ExportCbLedgerMutation, ExportCbLedgerMutationVariables>;
export const GetContractCbDocument = gql`
    query getContractCB($endDate: String!, $startDate: String!, $companyID: String) {
  getContractCB(endDate: $endDate, startDate: $startDate, companyID: $companyID)
}
    `;

/**
 * __useGetContractCbQuery__
 *
 * To run a query within a React component, call `useGetContractCbQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCbQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCbQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetContractCbQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractCbQuery, GetContractCbQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractCbQuery, GetContractCbQueryVariables>(GetContractCbDocument, baseOptions);
      }
export function useGetContractCbLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractCbQuery, GetContractCbQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractCbQuery, GetContractCbQueryVariables>(GetContractCbDocument, baseOptions);
        }
export type GetContractCbQueryHookResult = ReturnType<typeof useGetContractCbQuery>;
export type GetContractCbLazyQueryHookResult = ReturnType<typeof useGetContractCbLazyQuery>;
export type GetContractCbQueryResult = ApolloReactCommon.QueryResult<GetContractCbQuery, GetContractCbQueryVariables>;
export const GetBatchGlDetailLedgerDocument = gql`
    query getBatchGLDetailLedger($batchInput: [GLDetailLedgerBatchInput!]!) {
  getBatchGLDetailLedger(batchInput: $batchInput)
}
    `;

/**
 * __useGetBatchGlDetailLedgerQuery__
 *
 * To run a query within a React component, call `useGetBatchGlDetailLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchGlDetailLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchGlDetailLedgerQuery({
 *   variables: {
 *      batchInput: // value for 'batchInput'
 *   },
 * });
 */
export function useGetBatchGlDetailLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBatchGlDetailLedgerQuery, GetBatchGlDetailLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBatchGlDetailLedgerQuery, GetBatchGlDetailLedgerQueryVariables>(GetBatchGlDetailLedgerDocument, baseOptions);
      }
export function useGetBatchGlDetailLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatchGlDetailLedgerQuery, GetBatchGlDetailLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBatchGlDetailLedgerQuery, GetBatchGlDetailLedgerQueryVariables>(GetBatchGlDetailLedgerDocument, baseOptions);
        }
export type GetBatchGlDetailLedgerQueryHookResult = ReturnType<typeof useGetBatchGlDetailLedgerQuery>;
export type GetBatchGlDetailLedgerLazyQueryHookResult = ReturnType<typeof useGetBatchGlDetailLedgerLazyQuery>;
export type GetBatchGlDetailLedgerQueryResult = ApolloReactCommon.QueryResult<GetBatchGlDetailLedgerQuery, GetBatchGlDetailLedgerQueryVariables>;
export const GetContractCbInfoDocument = gql`
    query getContractCBInfo($cbExportID: String!) {
  getContractCBInfo(cbExportID: $cbExportID)
}
    `;

/**
 * __useGetContractCbInfoQuery__
 *
 * To run a query within a React component, call `useGetContractCbInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCbInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCbInfoQuery({
 *   variables: {
 *      cbExportID: // value for 'cbExportID'
 *   },
 * });
 */
export function useGetContractCbInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractCbInfoQuery, GetContractCbInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractCbInfoQuery, GetContractCbInfoQueryVariables>(GetContractCbInfoDocument, baseOptions);
      }
export function useGetContractCbInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractCbInfoQuery, GetContractCbInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractCbInfoQuery, GetContractCbInfoQueryVariables>(GetContractCbInfoDocument, baseOptions);
        }
export type GetContractCbInfoQueryHookResult = ReturnType<typeof useGetContractCbInfoQuery>;
export type GetContractCbInfoLazyQueryHookResult = ReturnType<typeof useGetContractCbInfoLazyQuery>;
export type GetContractCbInfoQueryResult = ApolloReactCommon.QueryResult<GetContractCbInfoQuery, GetContractCbInfoQueryVariables>;
export const GetFinancialIntegrationSummaryDocument = gql`
    query getFinancialIntegrationSummary {
  glExportDate
  cbExportDate
}
    `;

/**
 * __useGetFinancialIntegrationSummaryQuery__
 *
 * To run a query within a React component, call `useGetFinancialIntegrationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialIntegrationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialIntegrationSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFinancialIntegrationSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFinancialIntegrationSummaryQuery, GetFinancialIntegrationSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFinancialIntegrationSummaryQuery, GetFinancialIntegrationSummaryQueryVariables>(GetFinancialIntegrationSummaryDocument, baseOptions);
      }
export function useGetFinancialIntegrationSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFinancialIntegrationSummaryQuery, GetFinancialIntegrationSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFinancialIntegrationSummaryQuery, GetFinancialIntegrationSummaryQueryVariables>(GetFinancialIntegrationSummaryDocument, baseOptions);
        }
export type GetFinancialIntegrationSummaryQueryHookResult = ReturnType<typeof useGetFinancialIntegrationSummaryQuery>;
export type GetFinancialIntegrationSummaryLazyQueryHookResult = ReturnType<typeof useGetFinancialIntegrationSummaryLazyQuery>;
export type GetFinancialIntegrationSummaryQueryResult = ApolloReactCommon.QueryResult<GetFinancialIntegrationSummaryQuery, GetFinancialIntegrationSummaryQueryVariables>;
export const GetAllGlInterfaceDocument = gql`
    query getAllGLInterface($glExportID: String, $companyID: String, $startDate: String, $endDate: String) {
  getContractGL(startDate: $startDate, endDate: $endDate, glExportID: $glExportID, companyID: $companyID)
  getSubconGL(startDate: $startDate, endDate: $endDate, glExportID: $glExportID, companyID: $companyID)
  getSupplierGL(startDate: $startDate, endDate: $endDate, glExportID: $glExportID, companyID: $companyID)
  getPurchaseGL(startDate: $startDate, endDate: $endDate, glExportID: $glExportID, companyID: $companyID)
  getPOBGL(startDate: $startDate, endDate: $endDate, glExportID: $glExportID, companyID: $companyID)
}
    `;

/**
 * __useGetAllGlInterfaceQuery__
 *
 * To run a query within a React component, call `useGetAllGlInterfaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGlInterfaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGlInterfaceQuery({
 *   variables: {
 *      glExportID: // value for 'glExportID'
 *      companyID: // value for 'companyID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAllGlInterfaceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllGlInterfaceQuery, GetAllGlInterfaceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllGlInterfaceQuery, GetAllGlInterfaceQueryVariables>(GetAllGlInterfaceDocument, baseOptions);
      }
export function useGetAllGlInterfaceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllGlInterfaceQuery, GetAllGlInterfaceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllGlInterfaceQuery, GetAllGlInterfaceQueryVariables>(GetAllGlInterfaceDocument, baseOptions);
        }
export type GetAllGlInterfaceQueryHookResult = ReturnType<typeof useGetAllGlInterfaceQuery>;
export type GetAllGlInterfaceLazyQueryHookResult = ReturnType<typeof useGetAllGlInterfaceLazyQuery>;
export type GetAllGlInterfaceQueryResult = ApolloReactCommon.QueryResult<GetAllGlInterfaceQuery, GetAllGlInterfaceQueryVariables>;
export const GetGlClaimDetailLedgerDocument = gql`
    query getGLClaimDetailLedger($IDs: [String!]!, $docRefTable: String!, $claimColumn: String, $joinTable: String) {
  getGLClaimDetailLedger(IDs: $IDs, docRefTable: $docRefTable, claimColumn: $claimColumn, joinTable: $joinTable)
}
    `;

/**
 * __useGetGlClaimDetailLedgerQuery__
 *
 * To run a query within a React component, call `useGetGlClaimDetailLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlClaimDetailLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlClaimDetailLedgerQuery({
 *   variables: {
 *      IDs: // value for 'IDs'
 *      docRefTable: // value for 'docRefTable'
 *      claimColumn: // value for 'claimColumn'
 *      joinTable: // value for 'joinTable'
 *   },
 * });
 */
export function useGetGlClaimDetailLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlClaimDetailLedgerQuery, GetGlClaimDetailLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlClaimDetailLedgerQuery, GetGlClaimDetailLedgerQueryVariables>(GetGlClaimDetailLedgerDocument, baseOptions);
      }
export function useGetGlClaimDetailLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlClaimDetailLedgerQuery, GetGlClaimDetailLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlClaimDetailLedgerQuery, GetGlClaimDetailLedgerQueryVariables>(GetGlClaimDetailLedgerDocument, baseOptions);
        }
export type GetGlClaimDetailLedgerQueryHookResult = ReturnType<typeof useGetGlClaimDetailLedgerQuery>;
export type GetGlClaimDetailLedgerLazyQueryHookResult = ReturnType<typeof useGetGlClaimDetailLedgerLazyQuery>;
export type GetGlClaimDetailLedgerQueryResult = ApolloReactCommon.QueryResult<GetGlClaimDetailLedgerQuery, GetGlClaimDetailLedgerQueryVariables>;
export const GetGlDetailLedgerDocument = gql`
    query getGLDetailLedger($IDs: [String!]!, $docRefTable: String!, $joinTable: String) {
  getGLDetailLedger(IDs: $IDs, docRefTable: $docRefTable, joinTable: $joinTable)
}
    `;

/**
 * __useGetGlDetailLedgerQuery__
 *
 * To run a query within a React component, call `useGetGlDetailLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlDetailLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlDetailLedgerQuery({
 *   variables: {
 *      IDs: // value for 'IDs'
 *      docRefTable: // value for 'docRefTable'
 *      joinTable: // value for 'joinTable'
 *   },
 * });
 */
export function useGetGlDetailLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlDetailLedgerQuery, GetGlDetailLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlDetailLedgerQuery, GetGlDetailLedgerQueryVariables>(GetGlDetailLedgerDocument, baseOptions);
      }
export function useGetGlDetailLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlDetailLedgerQuery, GetGlDetailLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlDetailLedgerQuery, GetGlDetailLedgerQueryVariables>(GetGlDetailLedgerDocument, baseOptions);
        }
export type GetGlDetailLedgerQueryHookResult = ReturnType<typeof useGetGlDetailLedgerQuery>;
export type GetGlDetailLedgerLazyQueryHookResult = ReturnType<typeof useGetGlDetailLedgerLazyQuery>;
export type GetGlDetailLedgerQueryResult = ApolloReactCommon.QueryResult<GetGlDetailLedgerQuery, GetGlDetailLedgerQueryVariables>;
export const GetGrtnDetailLedgerDocument = gql`
    query getGRTNDetailLedger($IDs: [String!]!) {
  getGRTNDetailLedger(IDs: $IDs)
}
    `;

/**
 * __useGetGrtnDetailLedgerQuery__
 *
 * To run a query within a React component, call `useGetGrtnDetailLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrtnDetailLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrtnDetailLedgerQuery({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useGetGrtnDetailLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGrtnDetailLedgerQuery, GetGrtnDetailLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGrtnDetailLedgerQuery, GetGrtnDetailLedgerQueryVariables>(GetGrtnDetailLedgerDocument, baseOptions);
      }
export function useGetGrtnDetailLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGrtnDetailLedgerQuery, GetGrtnDetailLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGrtnDetailLedgerQuery, GetGrtnDetailLedgerQueryVariables>(GetGrtnDetailLedgerDocument, baseOptions);
        }
export type GetGrtnDetailLedgerQueryHookResult = ReturnType<typeof useGetGrtnDetailLedgerQuery>;
export type GetGrtnDetailLedgerLazyQueryHookResult = ReturnType<typeof useGetGrtnDetailLedgerLazyQuery>;
export type GetGrtnDetailLedgerQueryResult = ApolloReactCommon.QueryResult<GetGrtnDetailLedgerQuery, GetGrtnDetailLedgerQueryVariables>;
export const GetDoDetailLedgerDocument = gql`
    query getDODetailLedger($IDs: [String!]!) {
  getDODetailLedger(IDs: $IDs) {
    ID
    docDate
    docNo
    doNo
    DODetails
  }
}
    `;

/**
 * __useGetDoDetailLedgerQuery__
 *
 * To run a query within a React component, call `useGetDoDetailLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoDetailLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoDetailLedgerQuery({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useGetDoDetailLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDoDetailLedgerQuery, GetDoDetailLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDoDetailLedgerQuery, GetDoDetailLedgerQueryVariables>(GetDoDetailLedgerDocument, baseOptions);
      }
export function useGetDoDetailLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDoDetailLedgerQuery, GetDoDetailLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDoDetailLedgerQuery, GetDoDetailLedgerQueryVariables>(GetDoDetailLedgerDocument, baseOptions);
        }
export type GetDoDetailLedgerQueryHookResult = ReturnType<typeof useGetDoDetailLedgerQuery>;
export type GetDoDetailLedgerLazyQueryHookResult = ReturnType<typeof useGetDoDetailLedgerLazyQuery>;
export type GetDoDetailLedgerQueryResult = ApolloReactCommon.QueryResult<GetDoDetailLedgerQuery, GetDoDetailLedgerQueryVariables>;
export const GetGlExportDocument = gql`
    query getGLExport($ID: String, $orderByDesc: String) {
  getGLExport(ID: $ID, orderByDesc: $orderByDesc) {
    ID
    startDate
    endDate
    transactionDate
    glExportDetail {
      ID
      glExportID
      accountCode
      type
      docRefTable
      amount
      negative
      associateRefTable
    }
    glExportDetailSource {
      glExportID
      contractID
      docRefID
      docRefTable
    }
  }
}
    `;

/**
 * __useGetGlExportQuery__
 *
 * To run a query within a React component, call `useGetGlExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlExportQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetGlExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlExportQuery, GetGlExportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlExportQuery, GetGlExportQueryVariables>(GetGlExportDocument, baseOptions);
      }
export function useGetGlExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlExportQuery, GetGlExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlExportQuery, GetGlExportQueryVariables>(GetGlExportDocument, baseOptions);
        }
export type GetGlExportQueryHookResult = ReturnType<typeof useGetGlExportQuery>;
export type GetGlExportLazyQueryHookResult = ReturnType<typeof useGetGlExportLazyQuery>;
export type GetGlExportQueryResult = ApolloReactCommon.QueryResult<GetGlExportQuery, GetGlExportQueryVariables>;
export const ExportGlLedgerDocument = gql`
    mutation exportGLLedger($ledgers: [GLLedgerInput!]!, $input: GLExportInput!) {
  exportGLLedger(ledgers: $ledgers, input: $input)
}
    `;
export type ExportGlLedgerMutationFn = ApolloReactCommon.MutationFunction<ExportGlLedgerMutation, ExportGlLedgerMutationVariables>;

/**
 * __useExportGlLedgerMutation__
 *
 * To run a mutation, you first call `useExportGlLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportGlLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportGlLedgerMutation, { data, loading, error }] = useExportGlLedgerMutation({
 *   variables: {
 *      ledgers: // value for 'ledgers'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportGlLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExportGlLedgerMutation, ExportGlLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<ExportGlLedgerMutation, ExportGlLedgerMutationVariables>(ExportGlLedgerDocument, baseOptions);
      }
export type ExportGlLedgerMutationHookResult = ReturnType<typeof useExportGlLedgerMutation>;
export type ExportGlLedgerMutationResult = ApolloReactCommon.MutationResult<ExportGlLedgerMutation>;
export type ExportGlLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<ExportGlLedgerMutation, ExportGlLedgerMutationVariables>;
export const UpdateGlExportDocument = gql`
    mutation updateGLExport($input: UpdateGLExportInput!) {
  updateGLExport(input: $input)
}
    `;
export type UpdateGlExportMutationFn = ApolloReactCommon.MutationFunction<UpdateGlExportMutation, UpdateGlExportMutationVariables>;

/**
 * __useUpdateGlExportMutation__
 *
 * To run a mutation, you first call `useUpdateGlExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlExportMutation, { data, loading, error }] = useUpdateGlExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGlExportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGlExportMutation, UpdateGlExportMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGlExportMutation, UpdateGlExportMutationVariables>(UpdateGlExportDocument, baseOptions);
      }
export type UpdateGlExportMutationHookResult = ReturnType<typeof useUpdateGlExportMutation>;
export type UpdateGlExportMutationResult = ApolloReactCommon.MutationResult<UpdateGlExportMutation>;
export type UpdateGlExportMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGlExportMutation, UpdateGlExportMutationVariables>;
export const GetTaxCodeGlExportDocument = gql`
    query getTaxCodeGLExport($glExportID: String) {
  getTaxCodeGLExport(glExportID: $glExportID)
}
    `;

/**
 * __useGetTaxCodeGlExportQuery__
 *
 * To run a query within a React component, call `useGetTaxCodeGlExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxCodeGlExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxCodeGlExportQuery({
 *   variables: {
 *      glExportID: // value for 'glExportID'
 *   },
 * });
 */
export function useGetTaxCodeGlExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxCodeGlExportQuery, GetTaxCodeGlExportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxCodeGlExportQuery, GetTaxCodeGlExportQueryVariables>(GetTaxCodeGlExportDocument, baseOptions);
      }
export function useGetTaxCodeGlExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxCodeGlExportQuery, GetTaxCodeGlExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxCodeGlExportQuery, GetTaxCodeGlExportQueryVariables>(GetTaxCodeGlExportDocument, baseOptions);
        }
export type GetTaxCodeGlExportQueryHookResult = ReturnType<typeof useGetTaxCodeGlExportQuery>;
export type GetTaxCodeGlExportLazyQueryHookResult = ReturnType<typeof useGetTaxCodeGlExportLazyQuery>;
export type GetTaxCodeGlExportQueryResult = ApolloReactCommon.QueryResult<GetTaxCodeGlExportQuery, GetTaxCodeGlExportQueryVariables>;
export const GetGroupBiAccountReceivableSummaryDocument = gql`
    query getGroupBiAccountReceivableSummary {
  getGroupBiAccountReceivableSummary
}
    `;

/**
 * __useGetGroupBiAccountReceivableSummaryQuery__
 *
 * To run a query within a React component, call `useGetGroupBiAccountReceivableSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiAccountReceivableSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiAccountReceivableSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupBiAccountReceivableSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiAccountReceivableSummaryQuery, GetGroupBiAccountReceivableSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiAccountReceivableSummaryQuery, GetGroupBiAccountReceivableSummaryQueryVariables>(GetGroupBiAccountReceivableSummaryDocument, baseOptions);
      }
export function useGetGroupBiAccountReceivableSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiAccountReceivableSummaryQuery, GetGroupBiAccountReceivableSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiAccountReceivableSummaryQuery, GetGroupBiAccountReceivableSummaryQueryVariables>(GetGroupBiAccountReceivableSummaryDocument, baseOptions);
        }
export type GetGroupBiAccountReceivableSummaryQueryHookResult = ReturnType<typeof useGetGroupBiAccountReceivableSummaryQuery>;
export type GetGroupBiAccountReceivableSummaryLazyQueryHookResult = ReturnType<typeof useGetGroupBiAccountReceivableSummaryLazyQuery>;
export type GetGroupBiAccountReceivableSummaryQueryResult = ApolloReactCommon.QueryResult<GetGroupBiAccountReceivableSummaryQuery, GetGroupBiAccountReceivableSummaryQueryVariables>;
export const GetGroupBiAccountReceivableDialogDocument = gql`
    query getGroupBiAccountReceivableDialog($contractID: String) {
  getGroupBiAccountReceivableDialog(contractID: $contractID)
}
    `;

/**
 * __useGetGroupBiAccountReceivableDialogQuery__
 *
 * To run a query within a React component, call `useGetGroupBiAccountReceivableDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiAccountReceivableDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiAccountReceivableDialogQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetGroupBiAccountReceivableDialogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiAccountReceivableDialogQuery, GetGroupBiAccountReceivableDialogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiAccountReceivableDialogQuery, GetGroupBiAccountReceivableDialogQueryVariables>(GetGroupBiAccountReceivableDialogDocument, baseOptions);
      }
export function useGetGroupBiAccountReceivableDialogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiAccountReceivableDialogQuery, GetGroupBiAccountReceivableDialogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiAccountReceivableDialogQuery, GetGroupBiAccountReceivableDialogQueryVariables>(GetGroupBiAccountReceivableDialogDocument, baseOptions);
        }
export type GetGroupBiAccountReceivableDialogQueryHookResult = ReturnType<typeof useGetGroupBiAccountReceivableDialogQuery>;
export type GetGroupBiAccountReceivableDialogLazyQueryHookResult = ReturnType<typeof useGetGroupBiAccountReceivableDialogLazyQuery>;
export type GetGroupBiAccountReceivableDialogQueryResult = ApolloReactCommon.QueryResult<GetGroupBiAccountReceivableDialogQuery, GetGroupBiAccountReceivableDialogQueryVariables>;
export const GetGroupBiCashFlowSummaryDocument = gql`
    query getGroupBiCashFlowSummary {
  getGroupBiCashFlowSummary
}
    `;

/**
 * __useGetGroupBiCashFlowSummaryQuery__
 *
 * To run a query within a React component, call `useGetGroupBiCashFlowSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiCashFlowSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiCashFlowSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupBiCashFlowSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiCashFlowSummaryQuery, GetGroupBiCashFlowSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiCashFlowSummaryQuery, GetGroupBiCashFlowSummaryQueryVariables>(GetGroupBiCashFlowSummaryDocument, baseOptions);
      }
export function useGetGroupBiCashFlowSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiCashFlowSummaryQuery, GetGroupBiCashFlowSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiCashFlowSummaryQuery, GetGroupBiCashFlowSummaryQueryVariables>(GetGroupBiCashFlowSummaryDocument, baseOptions);
        }
export type GetGroupBiCashFlowSummaryQueryHookResult = ReturnType<typeof useGetGroupBiCashFlowSummaryQuery>;
export type GetGroupBiCashFlowSummaryLazyQueryHookResult = ReturnType<typeof useGetGroupBiCashFlowSummaryLazyQuery>;
export type GetGroupBiCashFlowSummaryQueryResult = ApolloReactCommon.QueryResult<GetGroupBiCashFlowSummaryQuery, GetGroupBiCashFlowSummaryQueryVariables>;
export const GetGroupBiCashFlowDialogDocument = gql`
    query getGroupBiCashFlowDialog {
  getGroupBiCashFlowDialog
}
    `;

/**
 * __useGetGroupBiCashFlowDialogQuery__
 *
 * To run a query within a React component, call `useGetGroupBiCashFlowDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiCashFlowDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiCashFlowDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupBiCashFlowDialogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiCashFlowDialogQuery, GetGroupBiCashFlowDialogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiCashFlowDialogQuery, GetGroupBiCashFlowDialogQueryVariables>(GetGroupBiCashFlowDialogDocument, baseOptions);
      }
export function useGetGroupBiCashFlowDialogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiCashFlowDialogQuery, GetGroupBiCashFlowDialogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiCashFlowDialogQuery, GetGroupBiCashFlowDialogQueryVariables>(GetGroupBiCashFlowDialogDocument, baseOptions);
        }
export type GetGroupBiCashFlowDialogQueryHookResult = ReturnType<typeof useGetGroupBiCashFlowDialogQuery>;
export type GetGroupBiCashFlowDialogLazyQueryHookResult = ReturnType<typeof useGetGroupBiCashFlowDialogLazyQuery>;
export type GetGroupBiCashFlowDialogQueryResult = ApolloReactCommon.QueryResult<GetGroupBiCashFlowDialogQuery, GetGroupBiCashFlowDialogQueryVariables>;
export const GetGroupBiKeyApiSummaryDocument = gql`
    query getGroupBiKeyApiSummary($contractID: String) {
  getGroupBiKeyApiSummary(contractID: $contractID)
}
    `;

/**
 * __useGetGroupBiKeyApiSummaryQuery__
 *
 * To run a query within a React component, call `useGetGroupBiKeyApiSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiKeyApiSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiKeyApiSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetGroupBiKeyApiSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiKeyApiSummaryQuery, GetGroupBiKeyApiSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiKeyApiSummaryQuery, GetGroupBiKeyApiSummaryQueryVariables>(GetGroupBiKeyApiSummaryDocument, baseOptions);
      }
export function useGetGroupBiKeyApiSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiKeyApiSummaryQuery, GetGroupBiKeyApiSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiKeyApiSummaryQuery, GetGroupBiKeyApiSummaryQueryVariables>(GetGroupBiKeyApiSummaryDocument, baseOptions);
        }
export type GetGroupBiKeyApiSummaryQueryHookResult = ReturnType<typeof useGetGroupBiKeyApiSummaryQuery>;
export type GetGroupBiKeyApiSummaryLazyQueryHookResult = ReturnType<typeof useGetGroupBiKeyApiSummaryLazyQuery>;
export type GetGroupBiKeyApiSummaryQueryResult = ApolloReactCommon.QueryResult<GetGroupBiKeyApiSummaryQuery, GetGroupBiKeyApiSummaryQueryVariables>;
export const GetGroupBiKeyApiSubmenuDocument = gql`
    query getGroupBiKeyApiSubmenu($contractID: String) {
  getGroupBiKeyApiDialogSummary(contractID: $contractID)
}
    `;

/**
 * __useGetGroupBiKeyApiSubmenuQuery__
 *
 * To run a query within a React component, call `useGetGroupBiKeyApiSubmenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiKeyApiSubmenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiKeyApiSubmenuQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetGroupBiKeyApiSubmenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiKeyApiSubmenuQuery, GetGroupBiKeyApiSubmenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiKeyApiSubmenuQuery, GetGroupBiKeyApiSubmenuQueryVariables>(GetGroupBiKeyApiSubmenuDocument, baseOptions);
      }
export function useGetGroupBiKeyApiSubmenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiKeyApiSubmenuQuery, GetGroupBiKeyApiSubmenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiKeyApiSubmenuQuery, GetGroupBiKeyApiSubmenuQueryVariables>(GetGroupBiKeyApiSubmenuDocument, baseOptions);
        }
export type GetGroupBiKeyApiSubmenuQueryHookResult = ReturnType<typeof useGetGroupBiKeyApiSubmenuQuery>;
export type GetGroupBiKeyApiSubmenuLazyQueryHookResult = ReturnType<typeof useGetGroupBiKeyApiSubmenuLazyQuery>;
export type GetGroupBiKeyApiSubmenuQueryResult = ApolloReactCommon.QueryResult<GetGroupBiKeyApiSubmenuQuery, GetGroupBiKeyApiSubmenuQueryVariables>;
export const GetGroupBiKeyApiDialogSummaryDocument = gql`
    query getGroupBiKeyApiDialogSummary {
  getGroupBiKeyApiDialogSummary
}
    `;

/**
 * __useGetGroupBiKeyApiDialogSummaryQuery__
 *
 * To run a query within a React component, call `useGetGroupBiKeyApiDialogSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiKeyApiDialogSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiKeyApiDialogSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupBiKeyApiDialogSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiKeyApiDialogSummaryQuery, GetGroupBiKeyApiDialogSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiKeyApiDialogSummaryQuery, GetGroupBiKeyApiDialogSummaryQueryVariables>(GetGroupBiKeyApiDialogSummaryDocument, baseOptions);
      }
export function useGetGroupBiKeyApiDialogSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiKeyApiDialogSummaryQuery, GetGroupBiKeyApiDialogSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiKeyApiDialogSummaryQuery, GetGroupBiKeyApiDialogSummaryQueryVariables>(GetGroupBiKeyApiDialogSummaryDocument, baseOptions);
        }
export type GetGroupBiKeyApiDialogSummaryQueryHookResult = ReturnType<typeof useGetGroupBiKeyApiDialogSummaryQuery>;
export type GetGroupBiKeyApiDialogSummaryLazyQueryHookResult = ReturnType<typeof useGetGroupBiKeyApiDialogSummaryLazyQuery>;
export type GetGroupBiKeyApiDialogSummaryQueryResult = ApolloReactCommon.QueryResult<GetGroupBiKeyApiDialogSummaryQuery, GetGroupBiKeyApiDialogSummaryQueryVariables>;
export const GetGroupBiKeyKpiClaimDocument = gql`
    query getGroupBiKeyKpiClaim($contractID: String) {
  getGroupBiKeyKpiClaim(contractID: $contractID)
}
    `;

/**
 * __useGetGroupBiKeyKpiClaimQuery__
 *
 * To run a query within a React component, call `useGetGroupBiKeyKpiClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiKeyKpiClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiKeyKpiClaimQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetGroupBiKeyKpiClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiKeyKpiClaimQuery, GetGroupBiKeyKpiClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiKeyKpiClaimQuery, GetGroupBiKeyKpiClaimQueryVariables>(GetGroupBiKeyKpiClaimDocument, baseOptions);
      }
export function useGetGroupBiKeyKpiClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiKeyKpiClaimQuery, GetGroupBiKeyKpiClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiKeyKpiClaimQuery, GetGroupBiKeyKpiClaimQueryVariables>(GetGroupBiKeyKpiClaimDocument, baseOptions);
        }
export type GetGroupBiKeyKpiClaimQueryHookResult = ReturnType<typeof useGetGroupBiKeyKpiClaimQuery>;
export type GetGroupBiKeyKpiClaimLazyQueryHookResult = ReturnType<typeof useGetGroupBiKeyKpiClaimLazyQuery>;
export type GetGroupBiKeyKpiClaimQueryResult = ApolloReactCommon.QueryResult<GetGroupBiKeyKpiClaimQuery, GetGroupBiKeyKpiClaimQueryVariables>;
export const GetGroupBiKeyKpiCollectionDocument = gql`
    query getGroupBiKeyKpiCollection($contractID: String) {
  getGroupBiKeyKpiCollection(contractID: $contractID)
}
    `;

/**
 * __useGetGroupBiKeyKpiCollectionQuery__
 *
 * To run a query within a React component, call `useGetGroupBiKeyKpiCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiKeyKpiCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiKeyKpiCollectionQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetGroupBiKeyKpiCollectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiKeyKpiCollectionQuery, GetGroupBiKeyKpiCollectionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiKeyKpiCollectionQuery, GetGroupBiKeyKpiCollectionQueryVariables>(GetGroupBiKeyKpiCollectionDocument, baseOptions);
      }
export function useGetGroupBiKeyKpiCollectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiKeyKpiCollectionQuery, GetGroupBiKeyKpiCollectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiKeyKpiCollectionQuery, GetGroupBiKeyKpiCollectionQueryVariables>(GetGroupBiKeyKpiCollectionDocument, baseOptions);
        }
export type GetGroupBiKeyKpiCollectionQueryHookResult = ReturnType<typeof useGetGroupBiKeyKpiCollectionQuery>;
export type GetGroupBiKeyKpiCollectionLazyQueryHookResult = ReturnType<typeof useGetGroupBiKeyKpiCollectionLazyQuery>;
export type GetGroupBiKeyKpiCollectionQueryResult = ApolloReactCommon.QueryResult<GetGroupBiKeyKpiCollectionQuery, GetGroupBiKeyKpiCollectionQueryVariables>;
export const GetBiSiteDocument = gql`
    query getBISite {
  getGroupBiSite
}
    `;

/**
 * __useGetBiSiteQuery__
 *
 * To run a query within a React component, call `useGetBiSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBiSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBiSiteQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBiSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBiSiteQuery, GetBiSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBiSiteQuery, GetBiSiteQueryVariables>(GetBiSiteDocument, baseOptions);
      }
export function useGetBiSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBiSiteQuery, GetBiSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBiSiteQuery, GetBiSiteQueryVariables>(GetBiSiteDocument, baseOptions);
        }
export type GetBiSiteQueryHookResult = ReturnType<typeof useGetBiSiteQuery>;
export type GetBiSiteLazyQueryHookResult = ReturnType<typeof useGetBiSiteLazyQuery>;
export type GetBiSiteQueryResult = ApolloReactCommon.QueryResult<GetBiSiteQuery, GetBiSiteQueryVariables>;
export const GetGroupBiInfoWindowSummaryDocument = gql`
    query getGroupBiInfoWindowSummary($contractID: String) {
  getGroupBiProjectCostingSummary
  getGroupBIProfitLostSummary
  getGroupBiCashFlowDialog
  getGroupBiAccountReceivableDialog(contractID: $contractID)
  getGroupBizVO
}
    `;

/**
 * __useGetGroupBiInfoWindowSummaryQuery__
 *
 * To run a query within a React component, call `useGetGroupBiInfoWindowSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiInfoWindowSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiInfoWindowSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetGroupBiInfoWindowSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiInfoWindowSummaryQuery, GetGroupBiInfoWindowSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiInfoWindowSummaryQuery, GetGroupBiInfoWindowSummaryQueryVariables>(GetGroupBiInfoWindowSummaryDocument, baseOptions);
      }
export function useGetGroupBiInfoWindowSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiInfoWindowSummaryQuery, GetGroupBiInfoWindowSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiInfoWindowSummaryQuery, GetGroupBiInfoWindowSummaryQueryVariables>(GetGroupBiInfoWindowSummaryDocument, baseOptions);
        }
export type GetGroupBiInfoWindowSummaryQueryHookResult = ReturnType<typeof useGetGroupBiInfoWindowSummaryQuery>;
export type GetGroupBiInfoWindowSummaryLazyQueryHookResult = ReturnType<typeof useGetGroupBiInfoWindowSummaryLazyQuery>;
export type GetGroupBiInfoWindowSummaryQueryResult = ApolloReactCommon.QueryResult<GetGroupBiInfoWindowSummaryQuery, GetGroupBiInfoWindowSummaryQueryVariables>;
export const GetGroupBiProfitLostSummaryDocument = gql`
    query getGroupBIProfitLostSummary {
  getGroupBIProfitLostSummary
}
    `;

/**
 * __useGetGroupBiProfitLostSummaryQuery__
 *
 * To run a query within a React component, call `useGetGroupBiProfitLostSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiProfitLostSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiProfitLostSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupBiProfitLostSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiProfitLostSummaryQuery, GetGroupBiProfitLostSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiProfitLostSummaryQuery, GetGroupBiProfitLostSummaryQueryVariables>(GetGroupBiProfitLostSummaryDocument, baseOptions);
      }
export function useGetGroupBiProfitLostSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiProfitLostSummaryQuery, GetGroupBiProfitLostSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiProfitLostSummaryQuery, GetGroupBiProfitLostSummaryQueryVariables>(GetGroupBiProfitLostSummaryDocument, baseOptions);
        }
export type GetGroupBiProfitLostSummaryQueryHookResult = ReturnType<typeof useGetGroupBiProfitLostSummaryQuery>;
export type GetGroupBiProfitLostSummaryLazyQueryHookResult = ReturnType<typeof useGetGroupBiProfitLostSummaryLazyQuery>;
export type GetGroupBiProfitLostSummaryQueryResult = ApolloReactCommon.QueryResult<GetGroupBiProfitLostSummaryQuery, GetGroupBiProfitLostSummaryQueryVariables>;
export const GetGroupBiProjectCostingSummaryDocument = gql`
    query getGroupBiProjectCostingSummary {
  getGroupBiProjectCostingSummary
}
    `;

/**
 * __useGetGroupBiProjectCostingSummaryQuery__
 *
 * To run a query within a React component, call `useGetGroupBiProjectCostingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiProjectCostingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiProjectCostingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupBiProjectCostingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiProjectCostingSummaryQuery, GetGroupBiProjectCostingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiProjectCostingSummaryQuery, GetGroupBiProjectCostingSummaryQueryVariables>(GetGroupBiProjectCostingSummaryDocument, baseOptions);
      }
export function useGetGroupBiProjectCostingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiProjectCostingSummaryQuery, GetGroupBiProjectCostingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiProjectCostingSummaryQuery, GetGroupBiProjectCostingSummaryQueryVariables>(GetGroupBiProjectCostingSummaryDocument, baseOptions);
        }
export type GetGroupBiProjectCostingSummaryQueryHookResult = ReturnType<typeof useGetGroupBiProjectCostingSummaryQuery>;
export type GetGroupBiProjectCostingSummaryLazyQueryHookResult = ReturnType<typeof useGetGroupBiProjectCostingSummaryLazyQuery>;
export type GetGroupBiProjectCostingSummaryQueryResult = ApolloReactCommon.QueryResult<GetGroupBiProjectCostingSummaryQuery, GetGroupBiProjectCostingSummaryQueryVariables>;
export const GetSiteTitleDocument = gql`
    query getSiteTitle($ID: String, $contractID: String) {
  totalSiteCount
  getSite(ID: $ID, contractID: $contractID, orderByAsc: "name") {
    ID
    accountID
    contractID
    name
    contract {
      ID
      title
    }
  }
}
    `;

/**
 * __useGetSiteTitleQuery__
 *
 * To run a query within a React component, call `useGetSiteTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSiteTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteTitleQuery, GetSiteTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteTitleQuery, GetSiteTitleQueryVariables>(GetSiteTitleDocument, baseOptions);
      }
export function useGetSiteTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteTitleQuery, GetSiteTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteTitleQuery, GetSiteTitleQueryVariables>(GetSiteTitleDocument, baseOptions);
        }
export type GetSiteTitleQueryHookResult = ReturnType<typeof useGetSiteTitleQuery>;
export type GetSiteTitleLazyQueryHookResult = ReturnType<typeof useGetSiteTitleLazyQuery>;
export type GetSiteTitleQueryResult = ApolloReactCommon.QueryResult<GetSiteTitleQuery, GetSiteTitleQueryVariables>;
export const GetSiteImagesCountDocument = gql`
    query getSiteImagesCount($siteID: String) {
  siteImagesCount(siteID: $siteID)
}
    `;

/**
 * __useGetSiteImagesCountQuery__
 *
 * To run a query within a React component, call `useGetSiteImagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteImagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteImagesCountQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteImagesCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteImagesCountQuery, GetSiteImagesCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteImagesCountQuery, GetSiteImagesCountQueryVariables>(GetSiteImagesCountDocument, baseOptions);
      }
export function useGetSiteImagesCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteImagesCountQuery, GetSiteImagesCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteImagesCountQuery, GetSiteImagesCountQueryVariables>(GetSiteImagesCountDocument, baseOptions);
        }
export type GetSiteImagesCountQueryHookResult = ReturnType<typeof useGetSiteImagesCountQuery>;
export type GetSiteImagesCountLazyQueryHookResult = ReturnType<typeof useGetSiteImagesCountLazyQuery>;
export type GetSiteImagesCountQueryResult = ApolloReactCommon.QueryResult<GetSiteImagesCountQuery, GetSiteImagesCountQueryVariables>;
export const GetSiteImageDocument = gql`
    query getSiteImage($ID: String, $orderByDesc: String, $siteID: String) {
  getSiteImage(ID: $ID, siteID: $siteID, orderByDesc: $orderByDesc) {
    ID
    siteID
    title
    remark
    createdTs
  }
}
    `;

/**
 * __useGetSiteImageQuery__
 *
 * To run a query within a React component, call `useGetSiteImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteImageQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteImageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteImageQuery, GetSiteImageQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteImageQuery, GetSiteImageQueryVariables>(GetSiteImageDocument, baseOptions);
      }
export function useGetSiteImageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteImageQuery, GetSiteImageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteImageQuery, GetSiteImageQueryVariables>(GetSiteImageDocument, baseOptions);
        }
export type GetSiteImageQueryHookResult = ReturnType<typeof useGetSiteImageQuery>;
export type GetSiteImageLazyQueryHookResult = ReturnType<typeof useGetSiteImageLazyQuery>;
export type GetSiteImageQueryResult = ApolloReactCommon.QueryResult<GetSiteImageQuery, GetSiteImageQueryVariables>;
export const SiteImageListingDocument = gql`
    query siteImageListing($refID: String, $refTable: String) {
  DocumentListing(refID: $refID, refTable: $refTable) {
    fileURL
    refID
    mediaType
  }
}
    `;

/**
 * __useSiteImageListingQuery__
 *
 * To run a query within a React component, call `useSiteImageListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteImageListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteImageListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *   },
 * });
 */
export function useSiteImageListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SiteImageListingQuery, SiteImageListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SiteImageListingQuery, SiteImageListingQueryVariables>(SiteImageListingDocument, baseOptions);
      }
export function useSiteImageListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SiteImageListingQuery, SiteImageListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SiteImageListingQuery, SiteImageListingQueryVariables>(SiteImageListingDocument, baseOptions);
        }
export type SiteImageListingQueryHookResult = ReturnType<typeof useSiteImageListingQuery>;
export type SiteImageListingLazyQueryHookResult = ReturnType<typeof useSiteImageListingLazyQuery>;
export type SiteImageListingQueryResult = ApolloReactCommon.QueryResult<SiteImageListingQuery, SiteImageListingQueryVariables>;
export const GetGroupBiVoSummaryDocument = gql`
    query getGroupBiVoSummary {
  getGroupBiVoSummary {
    contractSum
    approvedVoAmt
    approvedsubVoAmt
    voPerc
    vosubPerc
  }
}
    `;

/**
 * __useGetGroupBiVoSummaryQuery__
 *
 * To run a query within a React component, call `useGetGroupBiVoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBiVoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBiVoSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupBiVoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBiVoSummaryQuery, GetGroupBiVoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBiVoSummaryQuery, GetGroupBiVoSummaryQueryVariables>(GetGroupBiVoSummaryDocument, baseOptions);
      }
export function useGetGroupBiVoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBiVoSummaryQuery, GetGroupBiVoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBiVoSummaryQuery, GetGroupBiVoSummaryQueryVariables>(GetGroupBiVoSummaryDocument, baseOptions);
        }
export type GetGroupBiVoSummaryQueryHookResult = ReturnType<typeof useGetGroupBiVoSummaryQuery>;
export type GetGroupBiVoSummaryLazyQueryHookResult = ReturnType<typeof useGetGroupBiVoSummaryLazyQuery>;
export type GetGroupBiVoSummaryQueryResult = ApolloReactCommon.QueryResult<GetGroupBiVoSummaryQuery, GetGroupBiVoSummaryQueryVariables>;
export const GetGroupBizVoDocument = gql`
    query getGroupBizVO {
  getGroupBizVO
}
    `;

/**
 * __useGetGroupBizVoQuery__
 *
 * To run a query within a React component, call `useGetGroupBizVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBizVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBizVoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupBizVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBizVoQuery, GetGroupBizVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBizVoQuery, GetGroupBizVoQueryVariables>(GetGroupBizVoDocument, baseOptions);
      }
export function useGetGroupBizVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBizVoQuery, GetGroupBizVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBizVoQuery, GetGroupBizVoQueryVariables>(GetGroupBizVoDocument, baseOptions);
        }
export type GetGroupBizVoQueryHookResult = ReturnType<typeof useGetGroupBizVoQuery>;
export type GetGroupBizVoLazyQueryHookResult = ReturnType<typeof useGetGroupBizVoLazyQuery>;
export type GetGroupBizVoQueryResult = ApolloReactCommon.QueryResult<GetGroupBizVoQuery, GetGroupBizVoQueryVariables>;
export const GetGroupBizVoSubmenuDocument = gql`
    query getGroupBizVoSubmenu {
  getGroupBizVoSubmenu
}
    `;

/**
 * __useGetGroupBizVoSubmenuQuery__
 *
 * To run a query within a React component, call `useGetGroupBizVoSubmenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupBizVoSubmenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupBizVoSubmenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupBizVoSubmenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupBizVoSubmenuQuery, GetGroupBizVoSubmenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGroupBizVoSubmenuQuery, GetGroupBizVoSubmenuQueryVariables>(GetGroupBizVoSubmenuDocument, baseOptions);
      }
export function useGetGroupBizVoSubmenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupBizVoSubmenuQuery, GetGroupBizVoSubmenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGroupBizVoSubmenuQuery, GetGroupBizVoSubmenuQueryVariables>(GetGroupBizVoSubmenuDocument, baseOptions);
        }
export type GetGroupBizVoSubmenuQueryHookResult = ReturnType<typeof useGetGroupBizVoSubmenuQuery>;
export type GetGroupBizVoSubmenuLazyQueryHookResult = ReturnType<typeof useGetGroupBizVoSubmenuLazyQuery>;
export type GetGroupBizVoSubmenuQueryResult = ApolloReactCommon.QueryResult<GetGroupBizVoSubmenuQuery, GetGroupBizVoSubmenuQueryVariables>;
export const GetHomeSummaryDocument = gql`
    query getHomeSummary($companyID: String) {
  activeClientCount(companyID: $companyID)
  contractCount(companyID: $companyID)
  subcontractCount(companyID: $companyID)
  subcontractorCount(companyID: $companyID)
  supplierCount(companyID: $companyID)
  activeSubcontractTenderCount
  projectPurchaseCount(companyID: $companyID)
  ownedPNMInfo(companyID: $companyID)
  rentedPNMInfo(companyID: $companyID)
  assignmentRecord(companyID: $companyID)
  maintenanceRecord(companyID: $companyID)
  activeSiteCount(companyID: $companyID)
  fuelInfo(companyID: $companyID)
  getRolePermission
  getContractPermission
}
    `;

/**
 * __useGetHomeSummaryQuery__
 *
 * To run a query within a React component, call `useGetHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeSummaryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
      }
export function useGetHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
        }
export type GetHomeSummaryQueryHookResult = ReturnType<typeof useGetHomeSummaryQuery>;
export type GetHomeSummaryLazyQueryHookResult = ReturnType<typeof useGetHomeSummaryLazyQuery>;
export type GetHomeSummaryQueryResult = ApolloReactCommon.QueryResult<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>;
export const GetHomeGroupSummaryDocument = gql`
    query getHomeGroupSummary($companyID: String) {
  activeClientCount(companyID: $companyID)
  supplierCount(companyID: $companyID)
  activeSubcontractTenderCount
  ownedPNMInfo(companyID: $companyID)
  rentedPNMInfo(companyID: $companyID)
  assignmentRecord(companyID: $companyID)
  maintenanceRecord(companyID: $companyID)
  fuelInfo(companyID: $companyID)
}
    `;

/**
 * __useGetHomeGroupSummaryQuery__
 *
 * To run a query within a React component, call `useGetHomeGroupSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeGroupSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeGroupSummaryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetHomeGroupSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeGroupSummaryQuery, GetHomeGroupSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHomeGroupSummaryQuery, GetHomeGroupSummaryQueryVariables>(GetHomeGroupSummaryDocument, baseOptions);
      }
export function useGetHomeGroupSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeGroupSummaryQuery, GetHomeGroupSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHomeGroupSummaryQuery, GetHomeGroupSummaryQueryVariables>(GetHomeGroupSummaryDocument, baseOptions);
        }
export type GetHomeGroupSummaryQueryHookResult = ReturnType<typeof useGetHomeGroupSummaryQuery>;
export type GetHomeGroupSummaryLazyQueryHookResult = ReturnType<typeof useGetHomeGroupSummaryLazyQuery>;
export type GetHomeGroupSummaryQueryResult = ApolloReactCommon.QueryResult<GetHomeGroupSummaryQuery, GetHomeGroupSummaryQueryVariables>;
export const GetAccessSecurityDocument = gql`
    query getAccessSecurity {
  getContractPermission
  getRolePermission
}
    `;

/**
 * __useGetAccessSecurityQuery__
 *
 * To run a query within a React component, call `useGetAccessSecurityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessSecurityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessSecurityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccessSecurityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccessSecurityQuery, GetAccessSecurityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccessSecurityQuery, GetAccessSecurityQueryVariables>(GetAccessSecurityDocument, baseOptions);
      }
export function useGetAccessSecurityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccessSecurityQuery, GetAccessSecurityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccessSecurityQuery, GetAccessSecurityQueryVariables>(GetAccessSecurityDocument, baseOptions);
        }
export type GetAccessSecurityQueryHookResult = ReturnType<typeof useGetAccessSecurityQuery>;
export type GetAccessSecurityLazyQueryHookResult = ReturnType<typeof useGetAccessSecurityLazyQuery>;
export type GetAccessSecurityQueryResult = ApolloReactCommon.QueryResult<GetAccessSecurityQuery, GetAccessSecurityQueryVariables>;
export const GetHomeCardSummaryDocument = gql`
    query getHomeCardSummary {
  getToDoWorkflowSummary(length: true)
  getNotificationWorkflowListing(unread: true, length: true)
}
    `;

/**
 * __useGetHomeCardSummaryQuery__
 *
 * To run a query within a React component, call `useGetHomeCardSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeCardSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeCardSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeCardSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeCardSummaryQuery, GetHomeCardSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHomeCardSummaryQuery, GetHomeCardSummaryQueryVariables>(GetHomeCardSummaryDocument, baseOptions);
      }
export function useGetHomeCardSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeCardSummaryQuery, GetHomeCardSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHomeCardSummaryQuery, GetHomeCardSummaryQueryVariables>(GetHomeCardSummaryDocument, baseOptions);
        }
export type GetHomeCardSummaryQueryHookResult = ReturnType<typeof useGetHomeCardSummaryQuery>;
export type GetHomeCardSummaryLazyQueryHookResult = ReturnType<typeof useGetHomeCardSummaryLazyQuery>;
export type GetHomeCardSummaryQueryResult = ApolloReactCommon.QueryResult<GetHomeCardSummaryQuery, GetHomeCardSummaryQueryVariables>;
export const GetSiteDocument = gql`
    query getSite($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $siteID: String, $contractID: String) {
  getSite(orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, ID: $ID, siteID: $siteID, contractID: $contractID) {
    ID
    accountID
    contractID
    name
    address
    mainSite
    contactNo
    contract {
      customerDetail {
        name
      }
    }
  }
}
    `;

/**
 * __useGetSiteQuery__
 *
 * To run a query within a React component, call `useGetSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      siteID: // value for 'siteID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, baseOptions);
      }
export function useGetSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, baseOptions);
        }
export type GetSiteQueryHookResult = ReturnType<typeof useGetSiteQuery>;
export type GetSiteLazyQueryHookResult = ReturnType<typeof useGetSiteLazyQuery>;
export type GetSiteQueryResult = ApolloReactCommon.QueryResult<GetSiteQuery, GetSiteQueryVariables>;
export const GetAssignmentDocument = gql`
    query getAssignment($ID: String, $contractID: String, $companyID: String, $plantMachineryID: String, $orderByAsc: String, $orderByDesc: String, $assignmentStatus: AsgMainStatus, $limit: Float, $offSet: Float) {
  getAssignment(ID: $ID, contractID: $contractID, companyID: $companyID, plantMachineryID: $plantMachineryID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, assignmentStatus: $assignmentStatus, limit: $limit, offSet: $offSet) {
    ID
    accountID
    description
    assignmentDate
    startDate
    endDate
    returnDate
    plantMachineryID
    siteID
    site {
      ID
      name
      address
    }
    contractID
    contract {
      ID
      title
    }
    assignmentStatus
    returnRemarks
    cancellationDate
    cancellationRemarks
    assignedQty
    outstandingQty
    trackingLog
    plantMachinery {
      createdTs
      ID
      supplierID
      costCategoryID
      costCategory {
        ID
        name
      }
      regNo
      name
      description
      remarks
      purchaseDate
      quantity
      availableQty
      serialNo
      warrantyDate
      roadTaxDate
      insuranceDate
      cancellationDate
      cancellationRemarks
      plantMachineryStatus
    }
    cost
    profileInfo
    maintenance {
      ID
      maintenanceStatus
      assignedQty
      outstandingQty
      startDate
      endDate
      returnDate
      cancellationDate
      usageReading
      workshop {
        name
        ID
      }
    }
    site {
      ID
      name
      address
    }
  }
}
    `;

/**
 * __useGetAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      plantMachineryID: // value for 'plantMachineryID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      assignmentStatus: // value for 'assignmentStatus'
 *      limit: // value for 'limit'
 *      offSet: // value for 'offSet'
 *   },
 * });
 */
export function useGetAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, baseOptions);
      }
export function useGetAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, baseOptions);
        }
export type GetAssignmentQueryHookResult = ReturnType<typeof useGetAssignmentQuery>;
export type GetAssignmentLazyQueryHookResult = ReturnType<typeof useGetAssignmentLazyQuery>;
export type GetAssignmentQueryResult = ApolloReactCommon.QueryResult<GetAssignmentQuery, GetAssignmentQueryVariables>;
export const CreateAssignmentDocument = gql`
    mutation createAssignment($input: AssignmentInput!) {
  createAssignment(input: $input)
}
    `;
export type CreateAssignmentMutationFn = ApolloReactCommon.MutationFunction<CreateAssignmentMutation, CreateAssignmentMutationVariables>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAssignmentMutation, CreateAssignmentMutationVariables>(CreateAssignmentDocument, baseOptions);
      }
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = ApolloReactCommon.MutationResult<CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>;
export const UpdateAssignmentDocument = gql`
    mutation updateAssignment($input: AssignmentInput!) {
  updateAssignment(input: $input) {
    ID
  }
}
    `;
export type UpdateAssignmentMutationFn = ApolloReactCommon.MutationFunction<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(UpdateAssignmentDocument, baseOptions);
      }
export type UpdateAssignmentMutationHookResult = ReturnType<typeof useUpdateAssignmentMutation>;
export type UpdateAssignmentMutationResult = ApolloReactCommon.MutationResult<UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;
export const ReturnFromAssignmentDocument = gql`
    mutation returnFromAssignment($ID: String!, $plantMachineryID: String!, $input: AssignmentReturnInput!) {
  returnFromAssignment(ID: $ID, plantMachineryID: $plantMachineryID, input: $input)
}
    `;
export type ReturnFromAssignmentMutationFn = ApolloReactCommon.MutationFunction<ReturnFromAssignmentMutation, ReturnFromAssignmentMutationVariables>;

/**
 * __useReturnFromAssignmentMutation__
 *
 * To run a mutation, you first call `useReturnFromAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnFromAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnFromAssignmentMutation, { data, loading, error }] = useReturnFromAssignmentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      plantMachineryID: // value for 'plantMachineryID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReturnFromAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReturnFromAssignmentMutation, ReturnFromAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<ReturnFromAssignmentMutation, ReturnFromAssignmentMutationVariables>(ReturnFromAssignmentDocument, baseOptions);
      }
export type ReturnFromAssignmentMutationHookResult = ReturnType<typeof useReturnFromAssignmentMutation>;
export type ReturnFromAssignmentMutationResult = ApolloReactCommon.MutationResult<ReturnFromAssignmentMutation>;
export type ReturnFromAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<ReturnFromAssignmentMutation, ReturnFromAssignmentMutationVariables>;
export const CancelAssignmentDocument = gql`
    mutation cancelAssignment($ID: String!, $plantMachineryID: String!, $input: CancelInput!) {
  cancelAssignment(ID: $ID, plantMachineryID: $plantMachineryID, input: $input)
}
    `;
export type CancelAssignmentMutationFn = ApolloReactCommon.MutationFunction<CancelAssignmentMutation, CancelAssignmentMutationVariables>;

/**
 * __useCancelAssignmentMutation__
 *
 * To run a mutation, you first call `useCancelAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAssignmentMutation, { data, loading, error }] = useCancelAssignmentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      plantMachineryID: // value for 'plantMachineryID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelAssignmentMutation, CancelAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelAssignmentMutation, CancelAssignmentMutationVariables>(CancelAssignmentDocument, baseOptions);
      }
export type CancelAssignmentMutationHookResult = ReturnType<typeof useCancelAssignmentMutation>;
export type CancelAssignmentMutationResult = ApolloReactCommon.MutationResult<CancelAssignmentMutation>;
export type CancelAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelAssignmentMutation, CancelAssignmentMutationVariables>;
export const GetAssignmentListingDocument = gql`
    query getAssignmentListing($ID: String, $contractID: String, $companyID: String, $plantMachineryID: String, $orderByAsc: String, $orderByDesc: String, $assignmentStatus: AsgMainStatus, $limit: Float, $offSet: Float, $searchValue: String) {
  getAssignment(ID: $ID, contractID: $contractID, companyID: $companyID, plantMachineryID: $plantMachineryID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, assignmentStatus: $assignmentStatus, limit: $limit, offSet: $offSet, searchValue: $searchValue) {
    ID
    accountID
    description
    assignmentDate
    startDate
    endDate
    returnDate
    plantMachineryID
    siteID
    site {
      ID
      name
      address
    }
    contractID
    contract {
      ID
      title
    }
    assignmentStatus
    returnRemarks
    cancellationDate
    cancellationRemarks
    assignedQty
    outstandingQty
    trackingLog
    plantMachinery {
      createdTs
      ID
      supplierID
      costCategoryID
      costCategory {
        ID
        name
      }
      regNo
      name
      description
      remarks
      purchaseDate
      quantity
      availableQty
      serialNo
      warrantyDate
      roadTaxDate
      insuranceDate
      cancellationDate
      cancellationRemarks
      plantMachineryStatus
    }
    cost
    profileInfo
    maintenance {
      ID
      maintenanceStatus
      assignedQty
      outstandingQty
      startDate
      endDate
      returnDate
      cancellationDate
      usageReading
      workshop {
        name
        ID
      }
    }
    site {
      ID
      name
      address
    }
  }
  getAssignmentCount(companyID: $companyID, assignmentStatus: $assignmentStatus, contractID: $contractID)
}
    `;

/**
 * __useGetAssignmentListingQuery__
 *
 * To run a query within a React component, call `useGetAssignmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      plantMachineryID: // value for 'plantMachineryID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      assignmentStatus: // value for 'assignmentStatus'
 *      limit: // value for 'limit'
 *      offSet: // value for 'offSet'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetAssignmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignmentListingQuery, GetAssignmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignmentListingQuery, GetAssignmentListingQueryVariables>(GetAssignmentListingDocument, baseOptions);
      }
export function useGetAssignmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignmentListingQuery, GetAssignmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignmentListingQuery, GetAssignmentListingQueryVariables>(GetAssignmentListingDocument, baseOptions);
        }
export type GetAssignmentListingQueryHookResult = ReturnType<typeof useGetAssignmentListingQuery>;
export type GetAssignmentListingLazyQueryHookResult = ReturnType<typeof useGetAssignmentListingLazyQuery>;
export type GetAssignmentListingQueryResult = ApolloReactCommon.QueryResult<GetAssignmentListingQuery, GetAssignmentListingQueryVariables>;
export const GetMaintenanceDocument = gql`
    query getMaintenance($ID: String, $workshopID: String, $companyID: String, $plantMachineryID: String, $maintenanceStatus: AsgMainStatus, $orderByAsc: String, $orderByDesc: String, $limit: Float, $offSet: Float) {
  getMaintenance(ID: $ID, workshopID: $workshopID, companyID: $companyID, plantMachineryID: $plantMachineryID, maintenanceStatus: $maintenanceStatus, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, limit: $limit, offSet: $offSet) {
    ID
    modTs
    createdTs
    accountID
    plantMachineryID
    assignmentID
    assignmentDate
    startDate
    endDate
    cost
    returnDate
    cancellationDate
    returnRemarks
    cancellationRemarks
    description
    maintenanceStatus
    assignedQty
    outstandingQty
    trackingLog
    workshopID
    usageReading
    returnDate
    assignment {
      ID
      contract {
        ID
        title
      }
    }
    workshop {
      ID
      name
    }
    plantMachinery {
      createdTs
      modTs
      ID
      accountID
      supplierID
      costCategoryID
      costCategory {
        ID
        name
        description
        commonStatus
      }
      regNo
      name
      description
      remarks
      purchaseDate
      serialNo
      warrantyDate
      roadTaxDate
      insuranceDate
      cancellationDate
      cancellationRemarks
      image
      plantMachineryStatus
      costCategory {
        ID
        name
        description
        commonStatus
      }
    }
  }
}
    `;

/**
 * __useGetMaintenanceQuery__
 *
 * To run a query within a React component, call `useGetMaintenanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaintenanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaintenanceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      workshopID: // value for 'workshopID'
 *      companyID: // value for 'companyID'
 *      plantMachineryID: // value for 'plantMachineryID'
 *      maintenanceStatus: // value for 'maintenanceStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      limit: // value for 'limit'
 *      offSet: // value for 'offSet'
 *   },
 * });
 */
export function useGetMaintenanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMaintenanceQuery, GetMaintenanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMaintenanceQuery, GetMaintenanceQueryVariables>(GetMaintenanceDocument, baseOptions);
      }
export function useGetMaintenanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMaintenanceQuery, GetMaintenanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMaintenanceQuery, GetMaintenanceQueryVariables>(GetMaintenanceDocument, baseOptions);
        }
export type GetMaintenanceQueryHookResult = ReturnType<typeof useGetMaintenanceQuery>;
export type GetMaintenanceLazyQueryHookResult = ReturnType<typeof useGetMaintenanceLazyQuery>;
export type GetMaintenanceQueryResult = ApolloReactCommon.QueryResult<GetMaintenanceQuery, GetMaintenanceQueryVariables>;
export const GetWorkshopFormDocument = gql`
    query getWorkshopForm($ID: String, $orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $workshopStatus: WorkshopStatus) {
  getWorkshopForm(ID: $ID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, workshopStatus: $workshopStatus) {
    ID
    name
    modTs
    accountID
    registrationNo
    email
    contactNo
    description
    address
    contactPerson
    workshopStatus
    ratingType
  }
}
    `;

/**
 * __useGetWorkshopFormQuery__
 *
 * To run a query within a React component, call `useGetWorkshopFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkshopFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkshopFormQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      workshopStatus: // value for 'workshopStatus'
 *   },
 * });
 */
export function useGetWorkshopFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkshopFormQuery, GetWorkshopFormQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkshopFormQuery, GetWorkshopFormQueryVariables>(GetWorkshopFormDocument, baseOptions);
      }
export function useGetWorkshopFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkshopFormQuery, GetWorkshopFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkshopFormQuery, GetWorkshopFormQueryVariables>(GetWorkshopFormDocument, baseOptions);
        }
export type GetWorkshopFormQueryHookResult = ReturnType<typeof useGetWorkshopFormQuery>;
export type GetWorkshopFormLazyQueryHookResult = ReturnType<typeof useGetWorkshopFormLazyQuery>;
export type GetWorkshopFormQueryResult = ApolloReactCommon.QueryResult<GetWorkshopFormQuery, GetWorkshopFormQueryVariables>;
export const CreateMaintenanceDocument = gql`
    mutation createMaintenance($assignmentID: String, $input: MaintenanceInput!) {
  createMaintenance(assignmentID: $assignmentID, input: $input)
}
    `;
export type CreateMaintenanceMutationFn = ApolloReactCommon.MutationFunction<CreateMaintenanceMutation, CreateMaintenanceMutationVariables>;

/**
 * __useCreateMaintenanceMutation__
 *
 * To run a mutation, you first call `useCreateMaintenanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaintenanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaintenanceMutation, { data, loading, error }] = useCreateMaintenanceMutation({
 *   variables: {
 *      assignmentID: // value for 'assignmentID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMaintenanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMaintenanceMutation, CreateMaintenanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMaintenanceMutation, CreateMaintenanceMutationVariables>(CreateMaintenanceDocument, baseOptions);
      }
export type CreateMaintenanceMutationHookResult = ReturnType<typeof useCreateMaintenanceMutation>;
export type CreateMaintenanceMutationResult = ApolloReactCommon.MutationResult<CreateMaintenanceMutation>;
export type CreateMaintenanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMaintenanceMutation, CreateMaintenanceMutationVariables>;
export const UpdateMaintenanceDocument = gql`
    mutation updateMaintenance($assignmentID: String, $ID: String!, $input: MaintenanceInput!) {
  updateMaintenance(assignmentID: $assignmentID, ID: $ID, input: $input)
}
    `;
export type UpdateMaintenanceMutationFn = ApolloReactCommon.MutationFunction<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables>;

/**
 * __useUpdateMaintenanceMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceMutation, { data, loading, error }] = useUpdateMaintenanceMutation({
 *   variables: {
 *      assignmentID: // value for 'assignmentID'
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMaintenanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables>(UpdateMaintenanceDocument, baseOptions);
      }
export type UpdateMaintenanceMutationHookResult = ReturnType<typeof useUpdateMaintenanceMutation>;
export type UpdateMaintenanceMutationResult = ApolloReactCommon.MutationResult<UpdateMaintenanceMutation>;
export type UpdateMaintenanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables>;
export const ReturnFromMaintenanceDocument = gql`
    mutation returnFromMaintenance($input: MaintenanceCostReturnInput!, $plantMachineryID: String!, $ID: String!) {
  returnFromMaintenance(input: $input, plantMachineryID: $plantMachineryID, ID: $ID)
}
    `;
export type ReturnFromMaintenanceMutationFn = ApolloReactCommon.MutationFunction<ReturnFromMaintenanceMutation, ReturnFromMaintenanceMutationVariables>;

/**
 * __useReturnFromMaintenanceMutation__
 *
 * To run a mutation, you first call `useReturnFromMaintenanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnFromMaintenanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnFromMaintenanceMutation, { data, loading, error }] = useReturnFromMaintenanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      plantMachineryID: // value for 'plantMachineryID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useReturnFromMaintenanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReturnFromMaintenanceMutation, ReturnFromMaintenanceMutationVariables>) {
        return ApolloReactHooks.useMutation<ReturnFromMaintenanceMutation, ReturnFromMaintenanceMutationVariables>(ReturnFromMaintenanceDocument, baseOptions);
      }
export type ReturnFromMaintenanceMutationHookResult = ReturnType<typeof useReturnFromMaintenanceMutation>;
export type ReturnFromMaintenanceMutationResult = ApolloReactCommon.MutationResult<ReturnFromMaintenanceMutation>;
export type ReturnFromMaintenanceMutationOptions = ApolloReactCommon.BaseMutationOptions<ReturnFromMaintenanceMutation, ReturnFromMaintenanceMutationVariables>;
export const CancelMaintenanceCostDocument = gql`
    mutation cancelMaintenanceCost($input: CancelInput!, $plantMachineryID: String!, $ID: String!) {
  cancelMaintenanceCost(input: $input, plantMachineryID: $plantMachineryID, ID: $ID)
}
    `;
export type CancelMaintenanceCostMutationFn = ApolloReactCommon.MutationFunction<CancelMaintenanceCostMutation, CancelMaintenanceCostMutationVariables>;

/**
 * __useCancelMaintenanceCostMutation__
 *
 * To run a mutation, you first call `useCancelMaintenanceCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMaintenanceCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMaintenanceCostMutation, { data, loading, error }] = useCancelMaintenanceCostMutation({
 *   variables: {
 *      input: // value for 'input'
 *      plantMachineryID: // value for 'plantMachineryID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelMaintenanceCostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelMaintenanceCostMutation, CancelMaintenanceCostMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelMaintenanceCostMutation, CancelMaintenanceCostMutationVariables>(CancelMaintenanceCostDocument, baseOptions);
      }
export type CancelMaintenanceCostMutationHookResult = ReturnType<typeof useCancelMaintenanceCostMutation>;
export type CancelMaintenanceCostMutationResult = ApolloReactCommon.MutationResult<CancelMaintenanceCostMutation>;
export type CancelMaintenanceCostMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelMaintenanceCostMutation, CancelMaintenanceCostMutationVariables>;
export const GetMaintenanceCountDocument = gql`
    query getMaintenanceCount($workshopID: String, $companyID: String, $plantMachineryID: String, $maintenanceStatus: AsgMainStatus) {
  getMaintenanceCount(workshopID: $workshopID, companyID: $companyID, plantMachineryID: $plantMachineryID, maintenanceStatus: $maintenanceStatus)
}
    `;

/**
 * __useGetMaintenanceCountQuery__
 *
 * To run a query within a React component, call `useGetMaintenanceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaintenanceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaintenanceCountQuery({
 *   variables: {
 *      workshopID: // value for 'workshopID'
 *      companyID: // value for 'companyID'
 *      plantMachineryID: // value for 'plantMachineryID'
 *      maintenanceStatus: // value for 'maintenanceStatus'
 *   },
 * });
 */
export function useGetMaintenanceCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMaintenanceCountQuery, GetMaintenanceCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMaintenanceCountQuery, GetMaintenanceCountQueryVariables>(GetMaintenanceCountDocument, baseOptions);
      }
export function useGetMaintenanceCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMaintenanceCountQuery, GetMaintenanceCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMaintenanceCountQuery, GetMaintenanceCountQueryVariables>(GetMaintenanceCountDocument, baseOptions);
        }
export type GetMaintenanceCountQueryHookResult = ReturnType<typeof useGetMaintenanceCountQuery>;
export type GetMaintenanceCountLazyQueryHookResult = ReturnType<typeof useGetMaintenanceCountLazyQuery>;
export type GetMaintenanceCountQueryResult = ApolloReactCommon.QueryResult<GetMaintenanceCountQuery, GetMaintenanceCountQueryVariables>;
export const GetMaintenanceListingDocument = gql`
    query getMaintenanceListing($ID: String, $workshopID: String, $companyID: String, $plantMachineryID: String, $maintenanceStatus: AsgMainStatus, $orderByAsc: String, $orderByDesc: String, $limit: Float, $offSet: Float, $searchValue: String) {
  getMaintenance(ID: $ID, workshopID: $workshopID, companyID: $companyID, plantMachineryID: $plantMachineryID, maintenanceStatus: $maintenanceStatus, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, limit: $limit, offSet: $offSet, searchValue: $searchValue) {
    ID
    modTs
    createdTs
    accountID
    plantMachineryID
    assignmentID
    assignmentDate
    startDate
    endDate
    cost
    returnDate
    cancellationDate
    returnRemarks
    cancellationRemarks
    description
    maintenanceStatus
    assignedQty
    outstandingQty
    trackingLog
    workshopID
    usageReading
    returnDate
    assignment {
      ID
      contract {
        ID
        title
      }
    }
    workshop {
      ID
      name
    }
    plantMachinery {
      createdTs
      modTs
      ID
      accountID
      supplierID
      costCategoryID
      costCategory {
        ID
        name
        description
        commonStatus
      }
      regNo
      name
      description
      remarks
      purchaseDate
      serialNo
      warrantyDate
      roadTaxDate
      insuranceDate
      cancellationDate
      cancellationRemarks
      image
      plantMachineryStatus
      costCategory {
        ID
        name
        description
        commonStatus
      }
    }
  }
  getMaintenanceCount(workshopID: $workshopID, companyID: $companyID, plantMachineryID: $plantMachineryID, maintenanceStatus: $maintenanceStatus)
}
    `;

/**
 * __useGetMaintenanceListingQuery__
 *
 * To run a query within a React component, call `useGetMaintenanceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaintenanceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaintenanceListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      workshopID: // value for 'workshopID'
 *      companyID: // value for 'companyID'
 *      plantMachineryID: // value for 'plantMachineryID'
 *      maintenanceStatus: // value for 'maintenanceStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      limit: // value for 'limit'
 *      offSet: // value for 'offSet'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetMaintenanceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMaintenanceListingQuery, GetMaintenanceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMaintenanceListingQuery, GetMaintenanceListingQueryVariables>(GetMaintenanceListingDocument, baseOptions);
      }
export function useGetMaintenanceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMaintenanceListingQuery, GetMaintenanceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMaintenanceListingQuery, GetMaintenanceListingQueryVariables>(GetMaintenanceListingDocument, baseOptions);
        }
export type GetMaintenanceListingQueryHookResult = ReturnType<typeof useGetMaintenanceListingQuery>;
export type GetMaintenanceListingLazyQueryHookResult = ReturnType<typeof useGetMaintenanceListingLazyQuery>;
export type GetMaintenanceListingQueryResult = ApolloReactCommon.QueryResult<GetMaintenanceListingQuery, GetMaintenanceListingQueryVariables>;
export const CreatePnmRentalDocument = gql`
    mutation createPNMRental($input: PNMRentalInput!) {
  createPNMRental(input: $input) {
    ID
  }
}
    `;
export type CreatePnmRentalMutationFn = ApolloReactCommon.MutationFunction<CreatePnmRentalMutation, CreatePnmRentalMutationVariables>;

/**
 * __useCreatePnmRentalMutation__
 *
 * To run a mutation, you first call `useCreatePnmRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePnmRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPnmRentalMutation, { data, loading, error }] = useCreatePnmRentalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePnmRentalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePnmRentalMutation, CreatePnmRentalMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePnmRentalMutation, CreatePnmRentalMutationVariables>(CreatePnmRentalDocument, baseOptions);
      }
export type CreatePnmRentalMutationHookResult = ReturnType<typeof useCreatePnmRentalMutation>;
export type CreatePnmRentalMutationResult = ApolloReactCommon.MutationResult<CreatePnmRentalMutation>;
export type CreatePnmRentalMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePnmRentalMutation, CreatePnmRentalMutationVariables>;
export const UpdatePnmRentalDocument = gql`
    mutation updatePNMRental($input: PNMRentalInput!) {
  updatePNMRental(input: $input) {
    ID
  }
}
    `;
export type UpdatePnmRentalMutationFn = ApolloReactCommon.MutationFunction<UpdatePnmRentalMutation, UpdatePnmRentalMutationVariables>;

/**
 * __useUpdatePnmRentalMutation__
 *
 * To run a mutation, you first call `useUpdatePnmRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePnmRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePnmRentalMutation, { data, loading, error }] = useUpdatePnmRentalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePnmRentalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePnmRentalMutation, UpdatePnmRentalMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePnmRentalMutation, UpdatePnmRentalMutationVariables>(UpdatePnmRentalDocument, baseOptions);
      }
export type UpdatePnmRentalMutationHookResult = ReturnType<typeof useUpdatePnmRentalMutation>;
export type UpdatePnmRentalMutationResult = ApolloReactCommon.MutationResult<UpdatePnmRentalMutation>;
export type UpdatePnmRentalMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePnmRentalMutation, UpdatePnmRentalMutationVariables>;
export const GetPnmRentalDocument = gql`
    query getPNMRental($ID: String, $orderByAsc: String, $contractID: String, $companyID: String) {
  getPNMRental(ID: $ID, orderByAsc: $orderByAsc, contractID: $contractID, companyID: $companyID) {
    ID
    contractID
    contract {
      ID
      title
      companyID
    }
    supplierID
    supplier {
      ID
      name
    }
    costCategoryID
    costCategory {
      ID
      name
    }
    companyID
    company {
      Name
      Code
    }
    name
    regNo
    description
    startDate
    endDate
    cost
    rentalRate
    returnDate
    returnRemarks
    requireFuelSupply
    image
    plantMachineryStatus
  }
}
    `;

/**
 * __useGetPnmRentalQuery__
 *
 * To run a query within a React component, call `useGetPnmRentalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPnmRentalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPnmRentalQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetPnmRentalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPnmRentalQuery, GetPnmRentalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPnmRentalQuery, GetPnmRentalQueryVariables>(GetPnmRentalDocument, baseOptions);
      }
export function useGetPnmRentalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPnmRentalQuery, GetPnmRentalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPnmRentalQuery, GetPnmRentalQueryVariables>(GetPnmRentalDocument, baseOptions);
        }
export type GetPnmRentalQueryHookResult = ReturnType<typeof useGetPnmRentalQuery>;
export type GetPnmRentalLazyQueryHookResult = ReturnType<typeof useGetPnmRentalLazyQuery>;
export type GetPnmRentalQueryResult = ApolloReactCommon.QueryResult<GetPnmRentalQuery, GetPnmRentalQueryVariables>;
export const GetRentalTankDocument = gql`
    query getRentalTank($companyID: String!, $contractID: String, $orderByDesc: String) {
  getRentalTank(companyID: $companyID, contractID: $contractID, orderByDesc: $orderByDesc) {
    ID
    contractID
    costCategoryID
    companyID
    name
    description
    regNo
    startDate
    endDate
    cost
    returnDate
    returnRemarks
    image
    plantMachineryStatus
    fuelTotalSupply
    fuelTotalDischarge
    previousFuelSupplyDischarge
    tankTable
    fuelMeterReading {
      ID
      docDate
    }
    fuelSupply {
      ID
      docDate
    }
    fuelDischarge {
      ID
      dischargeDate
    }
    rentalTankSummary(companyID: $companyID)
  }
}
    `;

/**
 * __useGetRentalTankQuery__
 *
 * To run a query within a React component, call `useGetRentalTankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRentalTankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRentalTankQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetRentalTankQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRentalTankQuery, GetRentalTankQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRentalTankQuery, GetRentalTankQueryVariables>(GetRentalTankDocument, baseOptions);
      }
export function useGetRentalTankLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRentalTankQuery, GetRentalTankQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRentalTankQuery, GetRentalTankQueryVariables>(GetRentalTankDocument, baseOptions);
        }
export type GetRentalTankQueryHookResult = ReturnType<typeof useGetRentalTankQuery>;
export type GetRentalTankLazyQueryHookResult = ReturnType<typeof useGetRentalTankLazyQuery>;
export type GetRentalTankQueryResult = ApolloReactCommon.QueryResult<GetRentalTankQuery, GetRentalTankQueryVariables>;
export const GetPnmRentalListingDocument = gql`
    query getPNMRentalListing($ID: String, $orderByAsc: String, $contractID: String, $companyID: String, $skip: Float, $take: Float, $searchValue: String) {
  getPNMRental(ID: $ID, orderByAsc: $orderByAsc, contractID: $contractID, companyID: $companyID, skip: $skip, take: $take, searchValue: $searchValue) {
    ID
    contractID
    contract {
      ID
      title
      companyID
    }
    supplierID
    supplier {
      ID
      name
    }
    costCategoryID
    costCategory {
      ID
      name
    }
    companyID
    company {
      Name
      Code
    }
    name
    regNo
    description
    startDate
    endDate
    cost
    rentalRate
    returnDate
    returnRemarks
    requireFuelSupply
    image
    plantMachineryStatus
  }
  getPNMRentalCount(contractID: $contractID, companyID: $companyID)
}
    `;

/**
 * __useGetPnmRentalListingQuery__
 *
 * To run a query within a React component, call `useGetPnmRentalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPnmRentalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPnmRentalListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetPnmRentalListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPnmRentalListingQuery, GetPnmRentalListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPnmRentalListingQuery, GetPnmRentalListingQueryVariables>(GetPnmRentalListingDocument, baseOptions);
      }
export function useGetPnmRentalListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPnmRentalListingQuery, GetPnmRentalListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPnmRentalListingQuery, GetPnmRentalListingQueryVariables>(GetPnmRentalListingDocument, baseOptions);
        }
export type GetPnmRentalListingQueryHookResult = ReturnType<typeof useGetPnmRentalListingQuery>;
export type GetPnmRentalListingLazyQueryHookResult = ReturnType<typeof useGetPnmRentalListingLazyQuery>;
export type GetPnmRentalListingQueryResult = ApolloReactCommon.QueryResult<GetPnmRentalListingQuery, GetPnmRentalListingQueryVariables>;
export const GetPlantMachineryDocument = gql`
    query getPlantMachinery($costCategoryID: String, $ID: String, $plantMachineryStatus: PlantMachineryStatus, $orderByAsc: String, $orderByDesc: String, $contractID: String, $companyID: String) {
  getPlantMachinery(costCategoryID: $costCategoryID, ID: $ID, plantMachineryStatus: $plantMachineryStatus, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractID: $contractID, companyID: $companyID) {
    ID
    regNo
    name
    costCategoryID
    plantMachineryStatus
    currentPnmStatus
    remarks
    modTs
    image
    imageUrl
    purchaseDate
    warrantyDate
    roadTaxDate
    insuranceDate
    serialNo
    supplierID
    cancellationRemarks
    availableQty
    quantity
    latestActiveAssignment
    costCategory {
      ID
      name
    }
    assignment {
      assignmentStatus
      ID
      startDate
      endDate
      description
    }
    supplier {
      ID
      name
    }
    maintenance {
      maintenanceStatus
      ID
      startDate
      endDate
      description
      workshop {
        name
        ID
      }
    }
  }
}
    `;

/**
 * __useGetPlantMachineryQuery__
 *
 * To run a query within a React component, call `useGetPlantMachineryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlantMachineryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlantMachineryQuery({
 *   variables: {
 *      costCategoryID: // value for 'costCategoryID'
 *      ID: // value for 'ID'
 *      plantMachineryStatus: // value for 'plantMachineryStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetPlantMachineryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPlantMachineryQuery, GetPlantMachineryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPlantMachineryQuery, GetPlantMachineryQueryVariables>(GetPlantMachineryDocument, baseOptions);
      }
export function useGetPlantMachineryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlantMachineryQuery, GetPlantMachineryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPlantMachineryQuery, GetPlantMachineryQueryVariables>(GetPlantMachineryDocument, baseOptions);
        }
export type GetPlantMachineryQueryHookResult = ReturnType<typeof useGetPlantMachineryQuery>;
export type GetPlantMachineryLazyQueryHookResult = ReturnType<typeof useGetPlantMachineryLazyQuery>;
export type GetPlantMachineryQueryResult = ApolloReactCommon.QueryResult<GetPlantMachineryQuery, GetPlantMachineryQueryVariables>;
export const GetPnmByStatusDocument = gql`
    query getPNMByStatus($status: [PlantMachineryStatus!]!, $costCategoryID: String!) {
  getPNMByStatus(status: $status, costCategoryID: $costCategoryID) {
    ID
    regNo
    name
    costCategoryID
    costCategory {
      ID
      pnmCostCat
    }
    plantMachineryStatus
    serialNo
    remarks
    availableQty
  }
}
    `;

/**
 * __useGetPnmByStatusQuery__
 *
 * To run a query within a React component, call `useGetPnmByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPnmByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPnmByStatusQuery({
 *   variables: {
 *      status: // value for 'status'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetPnmByStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPnmByStatusQuery, GetPnmByStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPnmByStatusQuery, GetPnmByStatusQueryVariables>(GetPnmByStatusDocument, baseOptions);
      }
export function useGetPnmByStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPnmByStatusQuery, GetPnmByStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPnmByStatusQuery, GetPnmByStatusQueryVariables>(GetPnmByStatusDocument, baseOptions);
        }
export type GetPnmByStatusQueryHookResult = ReturnType<typeof useGetPnmByStatusQuery>;
export type GetPnmByStatusLazyQueryHookResult = ReturnType<typeof useGetPnmByStatusLazyQuery>;
export type GetPnmByStatusQueryResult = ApolloReactCommon.QueryResult<GetPnmByStatusQuery, GetPnmByStatusQueryVariables>;
export const GetCategoryPnmDocument = gql`
    query getCategoryPNM($ID: String, $companyID: String) {
  getActivePNMCostCategory(ID: $ID, companyID: $companyID) {
    ID
    name
    modTs
    accountID
    commonStatus
    costClass
    pnmCostCat
    plantMachineryStatus(companyID: $companyID) {
      available
      assigned
      outOfOrder
      maintenance
    }
    plantMachinery(companyID: $companyID) {
      ID
      companyID
      costCategoryID
      supplierID
      supplier {
        ID
        name
      }
      regNo
      name
      imageUrl
      currentPnmStatus
      plantMachineryStatus
      latestActiveAssignment
      latestActiveMaintenance
      remarks
      purchaseDate
      warrantyDate
      roadTaxDate
      quantity
      availableQty
      insuranceDate
      serialNo
      supplierID
      modTs
      cancellationRemarks
      assignment {
        assignmentStatus
        ID
        startDate
        endDate
        description
        assignedQty
        contract {
          ID
          title
        }
      }
      maintenance {
        maintenanceStatus
        workshopID
        ID
        startDate
        endDate
        description
        assignedQty
        outstandingQty
        workshop {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetCategoryPnmQuery__
 *
 * To run a query within a React component, call `useGetCategoryPnmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryPnmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryPnmQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetCategoryPnmQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCategoryPnmQuery, GetCategoryPnmQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCategoryPnmQuery, GetCategoryPnmQueryVariables>(GetCategoryPnmDocument, baseOptions);
      }
export function useGetCategoryPnmLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCategoryPnmQuery, GetCategoryPnmQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCategoryPnmQuery, GetCategoryPnmQueryVariables>(GetCategoryPnmDocument, baseOptions);
        }
export type GetCategoryPnmQueryHookResult = ReturnType<typeof useGetCategoryPnmQuery>;
export type GetCategoryPnmLazyQueryHookResult = ReturnType<typeof useGetCategoryPnmLazyQuery>;
export type GetCategoryPnmQueryResult = ApolloReactCommon.QueryResult<GetCategoryPnmQuery, GetCategoryPnmQueryVariables>;
export const CreatePlantMachineryDocument = gql`
    mutation createPlantMachinery($input: PlantMachineryInput!) {
  createPlantMachinery(input: $input)
}
    `;
export type CreatePlantMachineryMutationFn = ApolloReactCommon.MutationFunction<CreatePlantMachineryMutation, CreatePlantMachineryMutationVariables>;

/**
 * __useCreatePlantMachineryMutation__
 *
 * To run a mutation, you first call `useCreatePlantMachineryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlantMachineryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlantMachineryMutation, { data, loading, error }] = useCreatePlantMachineryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlantMachineryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlantMachineryMutation, CreatePlantMachineryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePlantMachineryMutation, CreatePlantMachineryMutationVariables>(CreatePlantMachineryDocument, baseOptions);
      }
export type CreatePlantMachineryMutationHookResult = ReturnType<typeof useCreatePlantMachineryMutation>;
export type CreatePlantMachineryMutationResult = ApolloReactCommon.MutationResult<CreatePlantMachineryMutation>;
export type CreatePlantMachineryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlantMachineryMutation, CreatePlantMachineryMutationVariables>;
export const UpdatePlantMachineryDocument = gql`
    mutation updatePlantMachinery($input: PlantMachineryInput!) {
  updatePlantMachinery(input: $input)
}
    `;
export type UpdatePlantMachineryMutationFn = ApolloReactCommon.MutationFunction<UpdatePlantMachineryMutation, UpdatePlantMachineryMutationVariables>;

/**
 * __useUpdatePlantMachineryMutation__
 *
 * To run a mutation, you first call `useUpdatePlantMachineryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlantMachineryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlantMachineryMutation, { data, loading, error }] = useUpdatePlantMachineryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlantMachineryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePlantMachineryMutation, UpdatePlantMachineryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePlantMachineryMutation, UpdatePlantMachineryMutationVariables>(UpdatePlantMachineryDocument, baseOptions);
      }
export type UpdatePlantMachineryMutationHookResult = ReturnType<typeof useUpdatePlantMachineryMutation>;
export type UpdatePlantMachineryMutationResult = ApolloReactCommon.MutationResult<UpdatePlantMachineryMutation>;
export type UpdatePlantMachineryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePlantMachineryMutation, UpdatePlantMachineryMutationVariables>;
export const GetPlantSummaryDocument = gql`
    query getPlantSummary($ID: String) {
  getPlantMachinery(ID: $ID) {
    ID
    costCategoryID
    plantMachineryStatus
    regNo
    name
    remarks
    purchaseDate
  }
}
    `;

/**
 * __useGetPlantSummaryQuery__
 *
 * To run a query within a React component, call `useGetPlantSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlantSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlantSummaryQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetPlantSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPlantSummaryQuery, GetPlantSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPlantSummaryQuery, GetPlantSummaryQueryVariables>(GetPlantSummaryDocument, baseOptions);
      }
export function useGetPlantSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlantSummaryQuery, GetPlantSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPlantSummaryQuery, GetPlantSummaryQueryVariables>(GetPlantSummaryDocument, baseOptions);
        }
export type GetPlantSummaryQueryHookResult = ReturnType<typeof useGetPlantSummaryQuery>;
export type GetPlantSummaryLazyQueryHookResult = ReturnType<typeof useGetPlantSummaryLazyQuery>;
export type GetPlantSummaryQueryResult = ApolloReactCommon.QueryResult<GetPlantSummaryQuery, GetPlantSummaryQueryVariables>;
export const PlantMachineryCheckerDocument = gql`
    query plantMachineryChecker($costCategoryID: String) {
  getPlantMachinery(costCategoryID: $costCategoryID) {
    ID
    costCategoryID
    plantMachineryStatus
    regNo
    name
    remarks
    purchaseDate
  }
}
    `;

/**
 * __usePlantMachineryCheckerQuery__
 *
 * To run a query within a React component, call `usePlantMachineryCheckerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlantMachineryCheckerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlantMachineryCheckerQuery({
 *   variables: {
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function usePlantMachineryCheckerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlantMachineryCheckerQuery, PlantMachineryCheckerQueryVariables>) {
        return ApolloReactHooks.useQuery<PlantMachineryCheckerQuery, PlantMachineryCheckerQueryVariables>(PlantMachineryCheckerDocument, baseOptions);
      }
export function usePlantMachineryCheckerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlantMachineryCheckerQuery, PlantMachineryCheckerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlantMachineryCheckerQuery, PlantMachineryCheckerQueryVariables>(PlantMachineryCheckerDocument, baseOptions);
        }
export type PlantMachineryCheckerQueryHookResult = ReturnType<typeof usePlantMachineryCheckerQuery>;
export type PlantMachineryCheckerLazyQueryHookResult = ReturnType<typeof usePlantMachineryCheckerLazyQuery>;
export type PlantMachineryCheckerQueryResult = ApolloReactCommon.QueryResult<PlantMachineryCheckerQuery, PlantMachineryCheckerQueryVariables>;
export const PnmSummaryDocument = gql`
    query PNMSummary($companyID: String) {
  ownedPNMInfo(companyID: $companyID)
  rentedPNMInfo(companyID: $companyID)
  assignmentRecord(companyID: $companyID)
  maintenanceRecord(companyID: $companyID)
}
    `;

/**
 * __usePnmSummaryQuery__
 *
 * To run a query within a React component, call `usePnmSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePnmSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePnmSummaryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePnmSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PnmSummaryQuery, PnmSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<PnmSummaryQuery, PnmSummaryQueryVariables>(PnmSummaryDocument, baseOptions);
      }
export function usePnmSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PnmSummaryQuery, PnmSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PnmSummaryQuery, PnmSummaryQueryVariables>(PnmSummaryDocument, baseOptions);
        }
export type PnmSummaryQueryHookResult = ReturnType<typeof usePnmSummaryQuery>;
export type PnmSummaryLazyQueryHookResult = ReturnType<typeof usePnmSummaryLazyQuery>;
export type PnmSummaryQueryResult = ApolloReactCommon.QueryResult<PnmSummaryQuery, PnmSummaryQueryVariables>;
export const OwnedPnmInfoDocument = gql`
    query ownedPNMInfo($companyID: String, $contractID: String) {
  ownedPNMInfo(companyID: $companyID, contractID: $contractID)
}
    `;

/**
 * __useOwnedPnmInfoQuery__
 *
 * To run a query within a React component, call `useOwnedPnmInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnedPnmInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnedPnmInfoQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useOwnedPnmInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OwnedPnmInfoQuery, OwnedPnmInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<OwnedPnmInfoQuery, OwnedPnmInfoQueryVariables>(OwnedPnmInfoDocument, baseOptions);
      }
export function useOwnedPnmInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OwnedPnmInfoQuery, OwnedPnmInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OwnedPnmInfoQuery, OwnedPnmInfoQueryVariables>(OwnedPnmInfoDocument, baseOptions);
        }
export type OwnedPnmInfoQueryHookResult = ReturnType<typeof useOwnedPnmInfoQuery>;
export type OwnedPnmInfoLazyQueryHookResult = ReturnType<typeof useOwnedPnmInfoLazyQuery>;
export type OwnedPnmInfoQueryResult = ApolloReactCommon.QueryResult<OwnedPnmInfoQuery, OwnedPnmInfoQueryVariables>;
export const RentedPnmInfoDocument = gql`
    query rentedPNMInfo($companyID: String, $contractID: String) {
  rentedPNMInfo(companyID: $companyID, contractID: $contractID)
}
    `;

/**
 * __useRentedPnmInfoQuery__
 *
 * To run a query within a React component, call `useRentedPnmInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentedPnmInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentedPnmInfoQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useRentedPnmInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RentedPnmInfoQuery, RentedPnmInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<RentedPnmInfoQuery, RentedPnmInfoQueryVariables>(RentedPnmInfoDocument, baseOptions);
      }
export function useRentedPnmInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RentedPnmInfoQuery, RentedPnmInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RentedPnmInfoQuery, RentedPnmInfoQueryVariables>(RentedPnmInfoDocument, baseOptions);
        }
export type RentedPnmInfoQueryHookResult = ReturnType<typeof useRentedPnmInfoQuery>;
export type RentedPnmInfoLazyQueryHookResult = ReturnType<typeof useRentedPnmInfoLazyQuery>;
export type RentedPnmInfoQueryResult = ApolloReactCommon.QueryResult<RentedPnmInfoQuery, RentedPnmInfoQueryVariables>;
export const AssignmentRecordDocument = gql`
    query assignmentRecord($companyID: String, $contractID: String) {
  assignmentRecord(companyID: $companyID, contractID: $contractID)
}
    `;

/**
 * __useAssignmentRecordQuery__
 *
 * To run a query within a React component, call `useAssignmentRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentRecordQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useAssignmentRecordQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssignmentRecordQuery, AssignmentRecordQueryVariables>) {
        return ApolloReactHooks.useQuery<AssignmentRecordQuery, AssignmentRecordQueryVariables>(AssignmentRecordDocument, baseOptions);
      }
export function useAssignmentRecordLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignmentRecordQuery, AssignmentRecordQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssignmentRecordQuery, AssignmentRecordQueryVariables>(AssignmentRecordDocument, baseOptions);
        }
export type AssignmentRecordQueryHookResult = ReturnType<typeof useAssignmentRecordQuery>;
export type AssignmentRecordLazyQueryHookResult = ReturnType<typeof useAssignmentRecordLazyQuery>;
export type AssignmentRecordQueryResult = ApolloReactCommon.QueryResult<AssignmentRecordQuery, AssignmentRecordQueryVariables>;
export const MaintenanceRecordDocument = gql`
    query maintenanceRecord($companyID: String, $workshopID: String) {
  maintenanceRecord(companyID: $companyID, workshopID: $workshopID)
}
    `;

/**
 * __useMaintenanceRecordQuery__
 *
 * To run a query within a React component, call `useMaintenanceRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceRecordQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      workshopID: // value for 'workshopID'
 *   },
 * });
 */
export function useMaintenanceRecordQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MaintenanceRecordQuery, MaintenanceRecordQueryVariables>) {
        return ApolloReactHooks.useQuery<MaintenanceRecordQuery, MaintenanceRecordQueryVariables>(MaintenanceRecordDocument, baseOptions);
      }
export function useMaintenanceRecordLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MaintenanceRecordQuery, MaintenanceRecordQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MaintenanceRecordQuery, MaintenanceRecordQueryVariables>(MaintenanceRecordDocument, baseOptions);
        }
export type MaintenanceRecordQueryHookResult = ReturnType<typeof useMaintenanceRecordQuery>;
export type MaintenanceRecordLazyQueryHookResult = ReturnType<typeof useMaintenanceRecordLazyQuery>;
export type MaintenanceRecordQueryResult = ApolloReactCommon.QueryResult<MaintenanceRecordQuery, MaintenanceRecordQueryVariables>;
export const GetPnmStatusDocument = gql`
    query getPNMStatus($plantMachineryID: String!) {
  getPNMStatus(plantMachineryID: $plantMachineryID)
}
    `;

/**
 * __useGetPnmStatusQuery__
 *
 * To run a query within a React component, call `useGetPnmStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPnmStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPnmStatusQuery({
 *   variables: {
 *      plantMachineryID: // value for 'plantMachineryID'
 *   },
 * });
 */
export function useGetPnmStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPnmStatusQuery, GetPnmStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPnmStatusQuery, GetPnmStatusQueryVariables>(GetPnmStatusDocument, baseOptions);
      }
export function useGetPnmStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPnmStatusQuery, GetPnmStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPnmStatusQuery, GetPnmStatusQueryVariables>(GetPnmStatusDocument, baseOptions);
        }
export type GetPnmStatusQueryHookResult = ReturnType<typeof useGetPnmStatusQuery>;
export type GetPnmStatusLazyQueryHookResult = ReturnType<typeof useGetPnmStatusLazyQuery>;
export type GetPnmStatusQueryResult = ApolloReactCommon.QueryResult<GetPnmStatusQuery, GetPnmStatusQueryVariables>;
export const UpdateProfitRecogFormulaAssignmentDocument = gql`
    mutation updateProfitRecogFormulaAssignment($input: UpdateProfitRecogFormulaInput!) {
  updateProfitRecogFormulaAssignment(input: $input)
}
    `;
export type UpdateProfitRecogFormulaAssignmentMutationFn = ApolloReactCommon.MutationFunction<UpdateProfitRecogFormulaAssignmentMutation, UpdateProfitRecogFormulaAssignmentMutationVariables>;

/**
 * __useUpdateProfitRecogFormulaAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateProfitRecogFormulaAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfitRecogFormulaAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfitRecogFormulaAssignmentMutation, { data, loading, error }] = useUpdateProfitRecogFormulaAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfitRecogFormulaAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProfitRecogFormulaAssignmentMutation, UpdateProfitRecogFormulaAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProfitRecogFormulaAssignmentMutation, UpdateProfitRecogFormulaAssignmentMutationVariables>(UpdateProfitRecogFormulaAssignmentDocument, baseOptions);
      }
export type UpdateProfitRecogFormulaAssignmentMutationHookResult = ReturnType<typeof useUpdateProfitRecogFormulaAssignmentMutation>;
export type UpdateProfitRecogFormulaAssignmentMutationResult = ApolloReactCommon.MutationResult<UpdateProfitRecogFormulaAssignmentMutation>;
export type UpdateProfitRecogFormulaAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfitRecogFormulaAssignmentMutation, UpdateProfitRecogFormulaAssignmentMutationVariables>;
export const GetProfitRecogFormulaDocument = gql`
    query getProfitRecogFormula($ID: String, $CommonStatus: CommonStatus) {
  getProfitRecogFormula(ID: $ID, commonStatus: $CommonStatus) {
    ID
    percOfCompletion
    commonStatus
    title
    cost
    revenue
  }
}
    `;

/**
 * __useGetProfitRecogFormulaQuery__
 *
 * To run a query within a React component, call `useGetProfitRecogFormulaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfitRecogFormulaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfitRecogFormulaQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      CommonStatus: // value for 'CommonStatus'
 *   },
 * });
 */
export function useGetProfitRecogFormulaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProfitRecogFormulaQuery, GetProfitRecogFormulaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProfitRecogFormulaQuery, GetProfitRecogFormulaQueryVariables>(GetProfitRecogFormulaDocument, baseOptions);
      }
export function useGetProfitRecogFormulaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProfitRecogFormulaQuery, GetProfitRecogFormulaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProfitRecogFormulaQuery, GetProfitRecogFormulaQueryVariables>(GetProfitRecogFormulaDocument, baseOptions);
        }
export type GetProfitRecogFormulaQueryHookResult = ReturnType<typeof useGetProfitRecogFormulaQuery>;
export type GetProfitRecogFormulaLazyQueryHookResult = ReturnType<typeof useGetProfitRecogFormulaLazyQuery>;
export type GetProfitRecogFormulaQueryResult = ApolloReactCommon.QueryResult<GetProfitRecogFormulaQuery, GetProfitRecogFormulaQueryVariables>;
export const CreateProfitRecogFormulaDocument = gql`
    mutation createProfitRecogFormula($input: ProfitRecogFormulaInput!) {
  createProfitRecogFormula(input: $input) {
    ID
    title
    percOfCompletion
    cost
    revenue
  }
}
    `;
export type CreateProfitRecogFormulaMutationFn = ApolloReactCommon.MutationFunction<CreateProfitRecogFormulaMutation, CreateProfitRecogFormulaMutationVariables>;

/**
 * __useCreateProfitRecogFormulaMutation__
 *
 * To run a mutation, you first call `useCreateProfitRecogFormulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfitRecogFormulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfitRecogFormulaMutation, { data, loading, error }] = useCreateProfitRecogFormulaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfitRecogFormulaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProfitRecogFormulaMutation, CreateProfitRecogFormulaMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProfitRecogFormulaMutation, CreateProfitRecogFormulaMutationVariables>(CreateProfitRecogFormulaDocument, baseOptions);
      }
export type CreateProfitRecogFormulaMutationHookResult = ReturnType<typeof useCreateProfitRecogFormulaMutation>;
export type CreateProfitRecogFormulaMutationResult = ApolloReactCommon.MutationResult<CreateProfitRecogFormulaMutation>;
export type CreateProfitRecogFormulaMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProfitRecogFormulaMutation, CreateProfitRecogFormulaMutationVariables>;
export const UpdateProfitRecogFormulaDocument = gql`
    mutation updateProfitRecogFormula($input: ProfitRecogFormulaInput!) {
  updateProfitRecogFormula(input: $input) {
    ID
  }
}
    `;
export type UpdateProfitRecogFormulaMutationFn = ApolloReactCommon.MutationFunction<UpdateProfitRecogFormulaMutation, UpdateProfitRecogFormulaMutationVariables>;

/**
 * __useUpdateProfitRecogFormulaMutation__
 *
 * To run a mutation, you first call `useUpdateProfitRecogFormulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfitRecogFormulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfitRecogFormulaMutation, { data, loading, error }] = useUpdateProfitRecogFormulaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfitRecogFormulaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProfitRecogFormulaMutation, UpdateProfitRecogFormulaMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProfitRecogFormulaMutation, UpdateProfitRecogFormulaMutationVariables>(UpdateProfitRecogFormulaDocument, baseOptions);
      }
export type UpdateProfitRecogFormulaMutationHookResult = ReturnType<typeof useUpdateProfitRecogFormulaMutation>;
export type UpdateProfitRecogFormulaMutationResult = ApolloReactCommon.MutationResult<UpdateProfitRecogFormulaMutation>;
export type UpdateProfitRecogFormulaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfitRecogFormulaMutation, UpdateProfitRecogFormulaMutationVariables>;
export const UpdateDocumentNumberHeaderDocument = gql`
    mutation updateDocumentNumberHeader($DocNumDetailInput: [DocNumDetailInput!]!, $DocNumHeaderInput: DocNumHeaderInput!) {
  updateDocumentNumberHeader(DocNumDetailInput: $DocNumDetailInput, DocNumHeaderInput: $DocNumHeaderInput)
}
    `;
export type UpdateDocumentNumberHeaderMutationFn = ApolloReactCommon.MutationFunction<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>;

/**
 * __useUpdateDocumentNumberHeaderMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentNumberHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentNumberHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentNumberHeaderMutation, { data, loading, error }] = useUpdateDocumentNumberHeaderMutation({
 *   variables: {
 *      DocNumDetailInput: // value for 'DocNumDetailInput'
 *      DocNumHeaderInput: // value for 'DocNumHeaderInput'
 *   },
 * });
 */
export function useUpdateDocumentNumberHeaderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>(UpdateDocumentNumberHeaderDocument, baseOptions);
      }
export type UpdateDocumentNumberHeaderMutationHookResult = ReturnType<typeof useUpdateDocumentNumberHeaderMutation>;
export type UpdateDocumentNumberHeaderMutationResult = ApolloReactCommon.MutationResult<UpdateDocumentNumberHeaderMutation>;
export type UpdateDocumentNumberHeaderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>;
export const GetDocNumHeaderDocument = gql`
    query getDocNumHeader($ID: String, $accountID: String, $contractID: String) {
  getDocNumHeader(ID: $ID, accountID: $accountID, contractID: $contractID) {
    ID
    accountID
    contractID
    sampleOutput
    docNumDetail {
      sequence
      formatType
      formatValue
      startFrom
      nextNumber
      docNumHeaderID
    }
  }
}
    `;

/**
 * __useGetDocNumHeaderQuery__
 *
 * To run a query within a React component, call `useGetDocNumHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocNumHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocNumHeaderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      accountID: // value for 'accountID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetDocNumHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocNumHeaderQuery, GetDocNumHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocNumHeaderQuery, GetDocNumHeaderQueryVariables>(GetDocNumHeaderDocument, baseOptions);
      }
export function useGetDocNumHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocNumHeaderQuery, GetDocNumHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocNumHeaderQuery, GetDocNumHeaderQueryVariables>(GetDocNumHeaderDocument, baseOptions);
        }
export type GetDocNumHeaderQueryHookResult = ReturnType<typeof useGetDocNumHeaderQuery>;
export type GetDocNumHeaderLazyQueryHookResult = ReturnType<typeof useGetDocNumHeaderLazyQuery>;
export type GetDocNumHeaderQueryResult = ApolloReactCommon.QueryResult<GetDocNumHeaderQuery, GetDocNumHeaderQueryVariables>;
export const GetDocNumDetailDocument = gql`
    query getDocNumDetail($ID: String, $accountID: String, $contractID: String) {
  getDocNumDetail(ID: $ID, accountID: $accountID, contractID: $contractID) {
    ID
    accountID
    sequence
    formatType
    formatValue
    startFrom
    nextNumber
    docNumHeaderID
  }
}
    `;

/**
 * __useGetDocNumDetailQuery__
 *
 * To run a query within a React component, call `useGetDocNumDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocNumDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocNumDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      accountID: // value for 'accountID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetDocNumDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocNumDetailQuery, GetDocNumDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocNumDetailQuery, GetDocNumDetailQueryVariables>(GetDocNumDetailDocument, baseOptions);
      }
export function useGetDocNumDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocNumDetailQuery, GetDocNumDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocNumDetailQuery, GetDocNumDetailQueryVariables>(GetDocNumDetailDocument, baseOptions);
        }
export type GetDocNumDetailQueryHookResult = ReturnType<typeof useGetDocNumDetailQuery>;
export type GetDocNumDetailLazyQueryHookResult = ReturnType<typeof useGetDocNumDetailLazyQuery>;
export type GetDocNumDetailQueryResult = ApolloReactCommon.QueryResult<GetDocNumDetailQuery, GetDocNumDetailQueryVariables>;
export const CreateProfitGenerationDocument = gql`
    mutation createProfitGeneration($input: ProfitRecogGenerationInput!) {
  createProfitGeneration(input: $input) {
    createdBy
  }
}
    `;
export type CreateProfitGenerationMutationFn = ApolloReactCommon.MutationFunction<CreateProfitGenerationMutation, CreateProfitGenerationMutationVariables>;

/**
 * __useCreateProfitGenerationMutation__
 *
 * To run a mutation, you first call `useCreateProfitGenerationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfitGenerationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfitGenerationMutation, { data, loading, error }] = useCreateProfitGenerationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfitGenerationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProfitGenerationMutation, CreateProfitGenerationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProfitGenerationMutation, CreateProfitGenerationMutationVariables>(CreateProfitGenerationDocument, baseOptions);
      }
export type CreateProfitGenerationMutationHookResult = ReturnType<typeof useCreateProfitGenerationMutation>;
export type CreateProfitGenerationMutationResult = ApolloReactCommon.MutationResult<CreateProfitGenerationMutation>;
export type CreateProfitGenerationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProfitGenerationMutation, CreateProfitGenerationMutationVariables>;
export const UpdateProfitGenerationDocument = gql`
    mutation updateProfitGeneration($input: ProfitRecogGenerationInput!, $profitRecogStatus: ProfitRecogStatus!, $profitRecogGenerationID: String!) {
  updateProfitGeneration(input: $input, profitRecogStatus: $profitRecogStatus, profitRecogGenerationID: $profitRecogGenerationID) {
    createdBy
  }
}
    `;
export type UpdateProfitGenerationMutationFn = ApolloReactCommon.MutationFunction<UpdateProfitGenerationMutation, UpdateProfitGenerationMutationVariables>;

/**
 * __useUpdateProfitGenerationMutation__
 *
 * To run a mutation, you first call `useUpdateProfitGenerationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfitGenerationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfitGenerationMutation, { data, loading, error }] = useUpdateProfitGenerationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      profitRecogStatus: // value for 'profitRecogStatus'
 *      profitRecogGenerationID: // value for 'profitRecogGenerationID'
 *   },
 * });
 */
export function useUpdateProfitGenerationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProfitGenerationMutation, UpdateProfitGenerationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProfitGenerationMutation, UpdateProfitGenerationMutationVariables>(UpdateProfitGenerationDocument, baseOptions);
      }
export type UpdateProfitGenerationMutationHookResult = ReturnType<typeof useUpdateProfitGenerationMutation>;
export type UpdateProfitGenerationMutationResult = ApolloReactCommon.MutationResult<UpdateProfitGenerationMutation>;
export type UpdateProfitGenerationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfitGenerationMutation, UpdateProfitGenerationMutationVariables>;
export const GetProfitRecogGenerationDocument = gql`
    query getProfitRecogGeneration($contractID: String!) {
  getProfitRecogGeneration(contractID: $contractID)
}
    `;

/**
 * __useGetProfitRecogGenerationQuery__
 *
 * To run a query within a React component, call `useGetProfitRecogGenerationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfitRecogGenerationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfitRecogGenerationQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetProfitRecogGenerationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProfitRecogGenerationQuery, GetProfitRecogGenerationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProfitRecogGenerationQuery, GetProfitRecogGenerationQueryVariables>(GetProfitRecogGenerationDocument, baseOptions);
      }
export function useGetProfitRecogGenerationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProfitRecogGenerationQuery, GetProfitRecogGenerationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProfitRecogGenerationQuery, GetProfitRecogGenerationQueryVariables>(GetProfitRecogGenerationDocument, baseOptions);
        }
export type GetProfitRecogGenerationQueryHookResult = ReturnType<typeof useGetProfitRecogGenerationQuery>;
export type GetProfitRecogGenerationLazyQueryHookResult = ReturnType<typeof useGetProfitRecogGenerationLazyQuery>;
export type GetProfitRecogGenerationQueryResult = ApolloReactCommon.QueryResult<GetProfitRecogGenerationQuery, GetProfitRecogGenerationQueryVariables>;
export const GetContractProfitRecogDocument = gql`
    query getContractProfitRecog($contractID: String) {
  getContract(ID: $contractID) {
    ID
    profitRecogFormulaID
  }
}
    `;

/**
 * __useGetContractProfitRecogQuery__
 *
 * To run a query within a React component, call `useGetContractProfitRecogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractProfitRecogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractProfitRecogQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractProfitRecogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractProfitRecogQuery, GetContractProfitRecogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractProfitRecogQuery, GetContractProfitRecogQueryVariables>(GetContractProfitRecogDocument, baseOptions);
      }
export function useGetContractProfitRecogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractProfitRecogQuery, GetContractProfitRecogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractProfitRecogQuery, GetContractProfitRecogQueryVariables>(GetContractProfitRecogDocument, baseOptions);
        }
export type GetContractProfitRecogQueryHookResult = ReturnType<typeof useGetContractProfitRecogQuery>;
export type GetContractProfitRecogLazyQueryHookResult = ReturnType<typeof useGetContractProfitRecogLazyQuery>;
export type GetContractProfitRecogQueryResult = ApolloReactCommon.QueryResult<GetContractProfitRecogQuery, GetContractProfitRecogQueryVariables>;
export const GetRegenerateFormDetailsDocument = gql`
    query getRegenerateFormDetails($contractID: String!, $inputDate: String!) {
  profitRecognitionCalculation(contractID: $contractID, inputDate: $inputDate)
  C1_actualCost(contractID: $contractID, inputDate: $inputDate)
  C2_budgetCost(contractID: $contractID, inputDate: $inputDate)
  C3_actualRevenue(contractID: $contractID, inputDate: $inputDate)
  C4_reviseContractSum(contractID: $contractID, inputDate: $inputDate)
  C5_totalPreviousRecognisedRevenue(contractID: $contractID, inputDate: $inputDate)
  C6_totalPreviousRecognisedCost(contractID: $contractID, inputDate: $inputDate)
  C7_percOfCompletion(contractID: $contractID, inputDate: $inputDate)
}
    `;

/**
 * __useGetRegenerateFormDetailsQuery__
 *
 * To run a query within a React component, call `useGetRegenerateFormDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegenerateFormDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegenerateFormDetailsQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      inputDate: // value for 'inputDate'
 *   },
 * });
 */
export function useGetRegenerateFormDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRegenerateFormDetailsQuery, GetRegenerateFormDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRegenerateFormDetailsQuery, GetRegenerateFormDetailsQueryVariables>(GetRegenerateFormDetailsDocument, baseOptions);
      }
export function useGetRegenerateFormDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRegenerateFormDetailsQuery, GetRegenerateFormDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRegenerateFormDetailsQuery, GetRegenerateFormDetailsQueryVariables>(GetRegenerateFormDetailsDocument, baseOptions);
        }
export type GetRegenerateFormDetailsQueryHookResult = ReturnType<typeof useGetRegenerateFormDetailsQuery>;
export type GetRegenerateFormDetailsLazyQueryHookResult = ReturnType<typeof useGetRegenerateFormDetailsLazyQuery>;
export type GetRegenerateFormDetailsQueryResult = ApolloReactCommon.QueryResult<GetRegenerateFormDetailsQuery, GetRegenerateFormDetailsQueryVariables>;
export const GetGenerationListingDetailDocument = gql`
    query getGenerationListingDetail($contractID: String!) {
  infoLineProfitRecog(contractID: $contractID)
}
    `;

/**
 * __useGetGenerationListingDetailQuery__
 *
 * To run a query within a React component, call `useGetGenerationListingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenerationListingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenerationListingDetailQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetGenerationListingDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGenerationListingDetailQuery, GetGenerationListingDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGenerationListingDetailQuery, GetGenerationListingDetailQueryVariables>(GetGenerationListingDetailDocument, baseOptions);
      }
export function useGetGenerationListingDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGenerationListingDetailQuery, GetGenerationListingDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGenerationListingDetailQuery, GetGenerationListingDetailQueryVariables>(GetGenerationListingDetailDocument, baseOptions);
        }
export type GetGenerationListingDetailQueryHookResult = ReturnType<typeof useGetGenerationListingDetailQuery>;
export type GetGenerationListingDetailLazyQueryHookResult = ReturnType<typeof useGetGenerationListingDetailLazyQuery>;
export type GetGenerationListingDetailQueryResult = ApolloReactCommon.QueryResult<GetGenerationListingDetailQuery, GetGenerationListingDetailQueryVariables>;
export const GetContractListingProfitRecogDocument = gql`
    query getContractListingProfitRecog($companyID: String!) {
  getContractListingProfitRecog(companyID: $companyID)
}
    `;

/**
 * __useGetContractListingProfitRecogQuery__
 *
 * To run a query within a React component, call `useGetContractListingProfitRecogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractListingProfitRecogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractListingProfitRecogQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetContractListingProfitRecogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractListingProfitRecogQuery, GetContractListingProfitRecogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractListingProfitRecogQuery, GetContractListingProfitRecogQueryVariables>(GetContractListingProfitRecogDocument, baseOptions);
      }
export function useGetContractListingProfitRecogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractListingProfitRecogQuery, GetContractListingProfitRecogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractListingProfitRecogQuery, GetContractListingProfitRecogQueryVariables>(GetContractListingProfitRecogDocument, baseOptions);
        }
export type GetContractListingProfitRecogQueryHookResult = ReturnType<typeof useGetContractListingProfitRecogQuery>;
export type GetContractListingProfitRecogLazyQueryHookResult = ReturnType<typeof useGetContractListingProfitRecogLazyQuery>;
export type GetContractListingProfitRecogQueryResult = ApolloReactCommon.QueryResult<GetContractListingProfitRecogQuery, GetContractListingProfitRecogQueryVariables>;
export const GetProfitRecogGenerationListingDocument = gql`
    query getProfitRecogGenerationListing($contractID: String!) {
  getProfitRecogGenerationListing(contractID: $contractID)
}
    `;

/**
 * __useGetProfitRecogGenerationListingQuery__
 *
 * To run a query within a React component, call `useGetProfitRecogGenerationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfitRecogGenerationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfitRecogGenerationListingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetProfitRecogGenerationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProfitRecogGenerationListingQuery, GetProfitRecogGenerationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProfitRecogGenerationListingQuery, GetProfitRecogGenerationListingQueryVariables>(GetProfitRecogGenerationListingDocument, baseOptions);
      }
export function useGetProfitRecogGenerationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProfitRecogGenerationListingQuery, GetProfitRecogGenerationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProfitRecogGenerationListingQuery, GetProfitRecogGenerationListingQueryVariables>(GetProfitRecogGenerationListingDocument, baseOptions);
        }
export type GetProfitRecogGenerationListingQueryHookResult = ReturnType<typeof useGetProfitRecogGenerationListingQuery>;
export type GetProfitRecogGenerationListingLazyQueryHookResult = ReturnType<typeof useGetProfitRecogGenerationListingLazyQuery>;
export type GetProfitRecogGenerationListingQueryResult = ApolloReactCommon.QueryResult<GetProfitRecogGenerationListingQuery, GetProfitRecogGenerationListingQueryVariables>;
export const ProjectBudgetAnalysisDocument = gql`
    query projectBudgetAnalysis($contractID: String!) {
  associateCalculation(contractID: $contractID) {
    totalCost
    latestOriginalDate
    latestRevisedDate
    originalBudgetTotalSum
    budgetVarianceAmt
    revisedBudgetTotalSum
    revisedBudgetMiscSum
    revisedBudgetPlantSum
    revisedBudgetLabourSum
    revisedBudgetMaterialSum
    revisedBudgetSubcontractSum
    originalBudgetMiscSum
    originalBudgetPlantSum
    originalBudgetLabourSum
    originalBudgetMaterialSum
    originalBudgetSubcontractSum
    origSubconItemBudget
    origMiscItemBudget
    origPlantItemBudget
    origLabourItemBudget
    origMaterialItemBudget
    revMiscItemBudget
    revPlantItemBudget
    revSubconItemBudget
    revLabourItemBudget
    revMaterialItemBudget
    materialCostTotal
    plantCostTotal
    labourCostTotal
    miscCostTotal
    subcontCostTotal
  }
}
    `;

/**
 * __useProjectBudgetAnalysisQuery__
 *
 * To run a query within a React component, call `useProjectBudgetAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectBudgetAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectBudgetAnalysisQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useProjectBudgetAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectBudgetAnalysisQuery, ProjectBudgetAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectBudgetAnalysisQuery, ProjectBudgetAnalysisQueryVariables>(ProjectBudgetAnalysisDocument, baseOptions);
      }
export function useProjectBudgetAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectBudgetAnalysisQuery, ProjectBudgetAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectBudgetAnalysisQuery, ProjectBudgetAnalysisQueryVariables>(ProjectBudgetAnalysisDocument, baseOptions);
        }
export type ProjectBudgetAnalysisQueryHookResult = ReturnType<typeof useProjectBudgetAnalysisQuery>;
export type ProjectBudgetAnalysisLazyQueryHookResult = ReturnType<typeof useProjectBudgetAnalysisLazyQuery>;
export type ProjectBudgetAnalysisQueryResult = ApolloReactCommon.QueryResult<ProjectBudgetAnalysisQuery, ProjectBudgetAnalysisQueryVariables>;
export const BudgetVsActualDocument = gql`
    query budgetVSActual($contractID: String!) {
  associateCalculation(contractID: $contractID) {
    completionPerc
    totalActualCost
    revisedBudgetTotalSum
    originalBudgetTotalSum
    totalCost
    revisedBudgetMiscSum
    revisedBudgetPlantSum
    revisedBudgetLabourSum
    revisedBudgetMaterialSum
    revisedBudgetSubcontractSum
    subconCostToDate
    materialCostToDate
    plantCostToDate
    labourCostToDate
    miscCostToDate
    originalBudgetMiscSum
    originalBudgetPlantSum
    originalBudgetLabourSum
    originalBudgetMaterialSum
    originalBudgetSubcontractSum
    transferredSubconBudget
    transferredMaterialBudget
    transferredLabourBudget
    transferredPlantBudget
    transferredMiscBudget
    miscCommCost
    plantCommCost
    subconCommCost
    labourCommCost
    materialCommCost
    miscCostTotal
    plantCostTotal
    labourCostTotal
    materialCostTotal
    subcontCostTotal
    subContractCommittedCost
  }
  getAdjustmentByCostClass(contractID: $contractID)
  getContractPLAdjustmentTotal(contractID: $contractID)
  getSubcontractPLAdjustmentTotal(contractID: $contractID)
  getSupplierPLAdjustmentTotal(contractID: $contractID)
}
    `;

/**
 * __useBudgetVsActualQuery__
 *
 * To run a query within a React component, call `useBudgetVsActualQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetVsActualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetVsActualQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useBudgetVsActualQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BudgetVsActualQuery, BudgetVsActualQueryVariables>) {
        return ApolloReactHooks.useQuery<BudgetVsActualQuery, BudgetVsActualQueryVariables>(BudgetVsActualDocument, baseOptions);
      }
export function useBudgetVsActualLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BudgetVsActualQuery, BudgetVsActualQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BudgetVsActualQuery, BudgetVsActualQueryVariables>(BudgetVsActualDocument, baseOptions);
        }
export type BudgetVsActualQueryHookResult = ReturnType<typeof useBudgetVsActualQuery>;
export type BudgetVsActualLazyQueryHookResult = ReturnType<typeof useBudgetVsActualLazyQuery>;
export type BudgetVsActualQueryResult = ApolloReactCommon.QueryResult<BudgetVsActualQuery, BudgetVsActualQueryVariables>;
export const BudgetCalculationDocument = gql`
    query budgetCalculation($contractID: String!, $ID: String) {
  budgetCalculation(contractID: $contractID)
  getCostingCalculation(contractID: $contractID)
  getContract(ID: $ID) {
    title
    customerDetail {
      name
    }
  }
}
    `;

/**
 * __useBudgetCalculationQuery__
 *
 * To run a query within a React component, call `useBudgetCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetCalculationQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useBudgetCalculationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BudgetCalculationQuery, BudgetCalculationQueryVariables>) {
        return ApolloReactHooks.useQuery<BudgetCalculationQuery, BudgetCalculationQueryVariables>(BudgetCalculationDocument, baseOptions);
      }
export function useBudgetCalculationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BudgetCalculationQuery, BudgetCalculationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BudgetCalculationQuery, BudgetCalculationQueryVariables>(BudgetCalculationDocument, baseOptions);
        }
export type BudgetCalculationQueryHookResult = ReturnType<typeof useBudgetCalculationQuery>;
export type BudgetCalculationLazyQueryHookResult = ReturnType<typeof useBudgetCalculationLazyQuery>;
export type BudgetCalculationQueryResult = ApolloReactCommon.QueryResult<BudgetCalculationQuery, BudgetCalculationQueryVariables>;
export const AddOriginalBudgetDocument = gql`
    mutation addOriginalBudget($contractID: String!, $budgetInput: [ProjectBudgetInput!]!) {
  addOriginalBudget(contractID: $contractID, budgetInput: $budgetInput)
}
    `;
export type AddOriginalBudgetMutationFn = ApolloReactCommon.MutationFunction<AddOriginalBudgetMutation, AddOriginalBudgetMutationVariables>;

/**
 * __useAddOriginalBudgetMutation__
 *
 * To run a mutation, you first call `useAddOriginalBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOriginalBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOriginalBudgetMutation, { data, loading, error }] = useAddOriginalBudgetMutation({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      budgetInput: // value for 'budgetInput'
 *   },
 * });
 */
export function useAddOriginalBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddOriginalBudgetMutation, AddOriginalBudgetMutationVariables>) {
        return ApolloReactHooks.useMutation<AddOriginalBudgetMutation, AddOriginalBudgetMutationVariables>(AddOriginalBudgetDocument, baseOptions);
      }
export type AddOriginalBudgetMutationHookResult = ReturnType<typeof useAddOriginalBudgetMutation>;
export type AddOriginalBudgetMutationResult = ApolloReactCommon.MutationResult<AddOriginalBudgetMutation>;
export type AddOriginalBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<AddOriginalBudgetMutation, AddOriginalBudgetMutationVariables>;
export const AddRevisedBudgetDocument = gql`
    mutation addRevisedBudget($ID: String!, $contractID: String!, $budgetAmtInput: BudgetAmtInput!, $file: [Upload!]) {
  addRevisedBudget(ID: $ID, contractID: $contractID, budgetAmtInput: $budgetAmtInput, file: $file)
}
    `;
export type AddRevisedBudgetMutationFn = ApolloReactCommon.MutationFunction<AddRevisedBudgetMutation, AddRevisedBudgetMutationVariables>;

/**
 * __useAddRevisedBudgetMutation__
 *
 * To run a mutation, you first call `useAddRevisedBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRevisedBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRevisedBudgetMutation, { data, loading, error }] = useAddRevisedBudgetMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      budgetAmtInput: // value for 'budgetAmtInput'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useAddRevisedBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRevisedBudgetMutation, AddRevisedBudgetMutationVariables>) {
        return ApolloReactHooks.useMutation<AddRevisedBudgetMutation, AddRevisedBudgetMutationVariables>(AddRevisedBudgetDocument, baseOptions);
      }
export type AddRevisedBudgetMutationHookResult = ReturnType<typeof useAddRevisedBudgetMutation>;
export type AddRevisedBudgetMutationResult = ApolloReactCommon.MutationResult<AddRevisedBudgetMutation>;
export type AddRevisedBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRevisedBudgetMutation, AddRevisedBudgetMutationVariables>;
export const GetContractBudgetDocument = gql`
    query getContractBudget($ID: String, $costClass: CostClass!, $costCategoryID: String, $contractID: String!) {
  getContract(ID: $ID) {
    title
    costCateg(costClass: $costClass, costCategoryID: $costCategoryID) {
      ID
      costClass
      name
      budget(contractID: $contractID) {
        ID
        createdTs
        budgetAmt
      }
    }
  }
}
    `;

/**
 * __useGetContractBudgetQuery__
 *
 * To run a query within a React component, call `useGetContractBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractBudgetQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costClass: // value for 'costClass'
 *      costCategoryID: // value for 'costCategoryID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractBudgetQuery, GetContractBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractBudgetQuery, GetContractBudgetQueryVariables>(GetContractBudgetDocument, baseOptions);
      }
export function useGetContractBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractBudgetQuery, GetContractBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractBudgetQuery, GetContractBudgetQueryVariables>(GetContractBudgetDocument, baseOptions);
        }
export type GetContractBudgetQueryHookResult = ReturnType<typeof useGetContractBudgetQuery>;
export type GetContractBudgetLazyQueryHookResult = ReturnType<typeof useGetContractBudgetLazyQuery>;
export type GetContractBudgetQueryResult = ApolloReactCommon.QueryResult<GetContractBudgetQuery, GetContractBudgetQueryVariables>;
export const GetContractBudgetCalculationDocument = gql`
    query getContractBudgetCalculation($ID: String, $costClass: CostClass!, $costCategoryID: String, $contractID: String!) {
  getContract(ID: $ID) {
    title
    customerDetail {
      name
    }
    costCateg(costClass: $costClass, costCategoryID: $costCategoryID) {
      ID
      costClass
      name
      budget(contractID: $contractID) {
        ID
        createdTs
        modTs
        budgetAmt
        modTs
      }
    }
  }
  budgetCalculation(contractID: $contractID)
  getCostCategory(costClass: $costClass, orderByAsc: "name") {
    ID
    name
    commonStatus
    budget {
      ID
      createdTs
      modTs
      budgetAmt
    }
  }
}
    `;

/**
 * __useGetContractBudgetCalculationQuery__
 *
 * To run a query within a React component, call `useGetContractBudgetCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractBudgetCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractBudgetCalculationQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costClass: // value for 'costClass'
 *      costCategoryID: // value for 'costCategoryID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractBudgetCalculationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractBudgetCalculationQuery, GetContractBudgetCalculationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractBudgetCalculationQuery, GetContractBudgetCalculationQueryVariables>(GetContractBudgetCalculationDocument, baseOptions);
      }
export function useGetContractBudgetCalculationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractBudgetCalculationQuery, GetContractBudgetCalculationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractBudgetCalculationQuery, GetContractBudgetCalculationQueryVariables>(GetContractBudgetCalculationDocument, baseOptions);
        }
export type GetContractBudgetCalculationQueryHookResult = ReturnType<typeof useGetContractBudgetCalculationQuery>;
export type GetContractBudgetCalculationLazyQueryHookResult = ReturnType<typeof useGetContractBudgetCalculationLazyQuery>;
export type GetContractBudgetCalculationQueryResult = ApolloReactCommon.QueryResult<GetContractBudgetCalculationQuery, GetContractBudgetCalculationQueryVariables>;
export const GetCostCategoryBudgetDocument = gql`
    query getCostCategoryBudget($ID: String, $costClass: CostClass, $orderByDesc: String) {
  getCostCategory(ID: $ID, costClass: $costClass, orderByDesc: $orderByDesc) {
    ID
    name
    budget {
      ID
      costCategoryID
      budgetAmt
    }
  }
}
    `;

/**
 * __useGetCostCategoryBudgetQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryBudgetQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costClass: // value for 'costClass'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCostCategoryBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryBudgetQuery, GetCostCategoryBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryBudgetQuery, GetCostCategoryBudgetQueryVariables>(GetCostCategoryBudgetDocument, baseOptions);
      }
export function useGetCostCategoryBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryBudgetQuery, GetCostCategoryBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryBudgetQuery, GetCostCategoryBudgetQueryVariables>(GetCostCategoryBudgetDocument, baseOptions);
        }
export type GetCostCategoryBudgetQueryHookResult = ReturnType<typeof useGetCostCategoryBudgetQuery>;
export type GetCostCategoryBudgetLazyQueryHookResult = ReturnType<typeof useGetCostCategoryBudgetLazyQuery>;
export type GetCostCategoryBudgetQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryBudgetQuery, GetCostCategoryBudgetQueryVariables>;
export const DeleteBudgetCheckingDocument = gql`
    query deleteBudgetChecking($contractID: String!, $costCategoryIDs: [String!]!) {
  deleteBudgetChecking(contractID: $contractID, costCategoryIDs: $costCategoryIDs)
}
    `;

/**
 * __useDeleteBudgetCheckingQuery__
 *
 * To run a query within a React component, call `useDeleteBudgetCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteBudgetCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteBudgetCheckingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      costCategoryIDs: // value for 'costCategoryIDs'
 *   },
 * });
 */
export function useDeleteBudgetCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeleteBudgetCheckingQuery, DeleteBudgetCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<DeleteBudgetCheckingQuery, DeleteBudgetCheckingQueryVariables>(DeleteBudgetCheckingDocument, baseOptions);
      }
export function useDeleteBudgetCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeleteBudgetCheckingQuery, DeleteBudgetCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeleteBudgetCheckingQuery, DeleteBudgetCheckingQueryVariables>(DeleteBudgetCheckingDocument, baseOptions);
        }
export type DeleteBudgetCheckingQueryHookResult = ReturnType<typeof useDeleteBudgetCheckingQuery>;
export type DeleteBudgetCheckingLazyQueryHookResult = ReturnType<typeof useDeleteBudgetCheckingLazyQuery>;
export type DeleteBudgetCheckingQueryResult = ApolloReactCommon.QueryResult<DeleteBudgetCheckingQuery, DeleteBudgetCheckingQueryVariables>;
export const DeleteProjectBudgetDocument = gql`
    mutation deleteProjectBudget($input: ProjectBudgetDeleteInput!) {
  deleteProjectBudget(input: $input)
}
    `;
export type DeleteProjectBudgetMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>;

/**
 * __useDeleteProjectBudgetMutation__
 *
 * To run a mutation, you first call `useDeleteProjectBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectBudgetMutation, { data, loading, error }] = useDeleteProjectBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>(DeleteProjectBudgetDocument, baseOptions);
      }
export type DeleteProjectBudgetMutationHookResult = ReturnType<typeof useDeleteProjectBudgetMutation>;
export type DeleteProjectBudgetMutationResult = ApolloReactCommon.MutationResult<DeleteProjectBudgetMutation>;
export type DeleteProjectBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>;
export const CashFlowSummaryDocument = gql`
    query cashFlowSummary($contractID: String!) {
  receivedFromClient(contractID: $contractID)
  paidToSubCon(contractID: $contractID)
  paidToSupplier(contractID: $contractID)
  paidSubconSupplierAmount(contractID: $contractID)
  miscExpense(contractID: $contractID)
  outstandingClaims(contractID: $contractID) {
    outstandingAmt
  }
  getSubcontractClaim(contractID: $contractID) {
    ID
    outstandingAmt
  }
  subconLastPayment(contractID: $contractID)
  supplierLastPayment(contractID: $contractID)
  receivableClientLastPayment(contractID: $contractID)
  subconOSPayableAmount(contractID: $contractID)
  supplierOSPayableAmount(contractID: $contractID)
  advanceClient(contractID: $contractID) {
    advClientAmt
    latestAdvClientDate
  }
  outstandingAmtClient(contractID: $contractID)
  advancesOutflowAmt(contractID: $contractID)
  outstandingReceivableFromClient(contractID: $contractID)
  outstandingPayableSubcon(contractID: $contractID)
  outstandingPayableSupplier(contractID: $contractID)
}
    `;

/**
 * __useCashFlowSummaryQuery__
 *
 * To run a query within a React component, call `useCashFlowSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashFlowSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashFlowSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useCashFlowSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CashFlowSummaryQuery, CashFlowSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<CashFlowSummaryQuery, CashFlowSummaryQueryVariables>(CashFlowSummaryDocument, baseOptions);
      }
export function useCashFlowSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CashFlowSummaryQuery, CashFlowSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CashFlowSummaryQuery, CashFlowSummaryQueryVariables>(CashFlowSummaryDocument, baseOptions);
        }
export type CashFlowSummaryQueryHookResult = ReturnType<typeof useCashFlowSummaryQuery>;
export type CashFlowSummaryLazyQueryHookResult = ReturnType<typeof useCashFlowSummaryLazyQuery>;
export type CashFlowSummaryQueryResult = ApolloReactCommon.QueryResult<CashFlowSummaryQuery, CashFlowSummaryQueryVariables>;
export const FinancialStatusSummaryDocument = gql`
    query financialStatusSummary($contractID: String!) {
  financialStatus(contractID: $contractID) {
    collectionListing
    collectionSum
    advClientListing
    advClientAmt
    miscExpense
    miscExpSum
  }
}
    `;

/**
 * __useFinancialStatusSummaryQuery__
 *
 * To run a query within a React component, call `useFinancialStatusSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialStatusSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialStatusSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useFinancialStatusSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FinancialStatusSummaryQuery, FinancialStatusSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<FinancialStatusSummaryQuery, FinancialStatusSummaryQueryVariables>(FinancialStatusSummaryDocument, baseOptions);
      }
export function useFinancialStatusSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FinancialStatusSummaryQuery, FinancialStatusSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FinancialStatusSummaryQuery, FinancialStatusSummaryQueryVariables>(FinancialStatusSummaryDocument, baseOptions);
        }
export type FinancialStatusSummaryQueryHookResult = ReturnType<typeof useFinancialStatusSummaryQuery>;
export type FinancialStatusSummaryLazyQueryHookResult = ReturnType<typeof useFinancialStatusSummaryLazyQuery>;
export type FinancialStatusSummaryQueryResult = ApolloReactCommon.QueryResult<FinancialStatusSummaryQuery, FinancialStatusSummaryQueryVariables>;
export const GetCashFlowListingDocument = gql`
    query getCashFlowListing($contractID: String!) {
  paidSubconSupplierListing(contractID: $contractID)
}
    `;

/**
 * __useGetCashFlowListingQuery__
 *
 * To run a query within a React component, call `useGetCashFlowListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCashFlowListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCashFlowListingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetCashFlowListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCashFlowListingQuery, GetCashFlowListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCashFlowListingQuery, GetCashFlowListingQueryVariables>(GetCashFlowListingDocument, baseOptions);
      }
export function useGetCashFlowListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCashFlowListingQuery, GetCashFlowListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCashFlowListingQuery, GetCashFlowListingQueryVariables>(GetCashFlowListingDocument, baseOptions);
        }
export type GetCashFlowListingQueryHookResult = ReturnType<typeof useGetCashFlowListingQuery>;
export type GetCashFlowListingLazyQueryHookResult = ReturnType<typeof useGetCashFlowListingLazyQuery>;
export type GetCashFlowListingQueryResult = ApolloReactCommon.QueryResult<GetCashFlowListingQuery, GetCashFlowListingQueryVariables>;
export const GetClientOsListDocument = gql`
    query getClientOSList($contractID: String!) {
  outstandingClaims(contractID: $contractID) {
    docAmt
    baseAmt
    outstandingAmt
    docDate
    description
    claimDocNo
    docNo
    docRef
    ID
  }
  outstandingMiscInvs(contractID: $contractID) {
    docAmt
    baseAmt
    outstandingAmt
    docDate
    description
    docNo
    docRef
    ID
  }
}
    `;

/**
 * __useGetClientOsListQuery__
 *
 * To run a query within a React component, call `useGetClientOsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientOsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientOsListQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetClientOsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientOsListQuery, GetClientOsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientOsListQuery, GetClientOsListQueryVariables>(GetClientOsListDocument, baseOptions);
      }
export function useGetClientOsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientOsListQuery, GetClientOsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientOsListQuery, GetClientOsListQueryVariables>(GetClientOsListDocument, baseOptions);
        }
export type GetClientOsListQueryHookResult = ReturnType<typeof useGetClientOsListQuery>;
export type GetClientOsListLazyQueryHookResult = ReturnType<typeof useGetClientOsListLazyQuery>;
export type GetClientOsListQueryResult = ApolloReactCommon.QueryResult<GetClientOsListQuery, GetClientOsListQueryVariables>;
export const GetOsReceivableFromClientListingDocument = gql`
    query getOSReceivableFromClientListing($contractID: String!) {
  OSReceivableFromClientListing(contractID: $contractID)
}
    `;

/**
 * __useGetOsReceivableFromClientListingQuery__
 *
 * To run a query within a React component, call `useGetOsReceivableFromClientListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOsReceivableFromClientListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOsReceivableFromClientListingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetOsReceivableFromClientListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOsReceivableFromClientListingQuery, GetOsReceivableFromClientListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOsReceivableFromClientListingQuery, GetOsReceivableFromClientListingQueryVariables>(GetOsReceivableFromClientListingDocument, baseOptions);
      }
export function useGetOsReceivableFromClientListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOsReceivableFromClientListingQuery, GetOsReceivableFromClientListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOsReceivableFromClientListingQuery, GetOsReceivableFromClientListingQueryVariables>(GetOsReceivableFromClientListingDocument, baseOptions);
        }
export type GetOsReceivableFromClientListingQueryHookResult = ReturnType<typeof useGetOsReceivableFromClientListingQuery>;
export type GetOsReceivableFromClientListingLazyQueryHookResult = ReturnType<typeof useGetOsReceivableFromClientListingLazyQuery>;
export type GetOsReceivableFromClientListingQueryResult = ApolloReactCommon.QueryResult<GetOsReceivableFromClientListingQuery, GetOsReceivableFromClientListingQueryVariables>;
export const GetOsPayableToSupplierListingDocument = gql`
    query getOSPayableToSupplierListing($contractID: String!) {
  OSPayableToSupplierListing(contractID: $contractID)
}
    `;

/**
 * __useGetOsPayableToSupplierListingQuery__
 *
 * To run a query within a React component, call `useGetOsPayableToSupplierListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOsPayableToSupplierListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOsPayableToSupplierListingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetOsPayableToSupplierListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOsPayableToSupplierListingQuery, GetOsPayableToSupplierListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOsPayableToSupplierListingQuery, GetOsPayableToSupplierListingQueryVariables>(GetOsPayableToSupplierListingDocument, baseOptions);
      }
export function useGetOsPayableToSupplierListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOsPayableToSupplierListingQuery, GetOsPayableToSupplierListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOsPayableToSupplierListingQuery, GetOsPayableToSupplierListingQueryVariables>(GetOsPayableToSupplierListingDocument, baseOptions);
        }
export type GetOsPayableToSupplierListingQueryHookResult = ReturnType<typeof useGetOsPayableToSupplierListingQuery>;
export type GetOsPayableToSupplierListingLazyQueryHookResult = ReturnType<typeof useGetOsPayableToSupplierListingLazyQuery>;
export type GetOsPayableToSupplierListingQueryResult = ApolloReactCommon.QueryResult<GetOsPayableToSupplierListingQuery, GetOsPayableToSupplierListingQueryVariables>;
export const GetOsPayableToSubconListingDocument = gql`
    query getOSPayableToSubconListing($contractID: String!) {
  OSPayableToSubconListing(contractID: $contractID)
}
    `;

/**
 * __useGetOsPayableToSubconListingQuery__
 *
 * To run a query within a React component, call `useGetOsPayableToSubconListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOsPayableToSubconListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOsPayableToSubconListingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetOsPayableToSubconListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOsPayableToSubconListingQuery, GetOsPayableToSubconListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOsPayableToSubconListingQuery, GetOsPayableToSubconListingQueryVariables>(GetOsPayableToSubconListingDocument, baseOptions);
      }
export function useGetOsPayableToSubconListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOsPayableToSubconListingQuery, GetOsPayableToSubconListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOsPayableToSubconListingQuery, GetOsPayableToSubconListingQueryVariables>(GetOsPayableToSubconListingDocument, baseOptions);
        }
export type GetOsPayableToSubconListingQueryHookResult = ReturnType<typeof useGetOsPayableToSubconListingQuery>;
export type GetOsPayableToSubconListingLazyQueryHookResult = ReturnType<typeof useGetOsPayableToSubconListingLazyQuery>;
export type GetOsPayableToSubconListingQueryResult = ApolloReactCommon.QueryResult<GetOsPayableToSubconListingQuery, GetOsPayableToSubconListingQueryVariables>;
export const GetSubconOsListDocument = gql`
    query getSubconOSList($contractID: String!) {
  getSubcontractClaim(contractID: $contractID) {
    docAmt
    baseAmt
    outstandingAmt
    docDate
    description
    docNo
    ID
    docRef
    claimDocNo
  }
}
    `;

/**
 * __useGetSubconOsListQuery__
 *
 * To run a query within a React component, call `useGetSubconOsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconOsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconOsListQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubconOsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconOsListQuery, GetSubconOsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconOsListQuery, GetSubconOsListQueryVariables>(GetSubconOsListDocument, baseOptions);
      }
export function useGetSubconOsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconOsListQuery, GetSubconOsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconOsListQuery, GetSubconOsListQueryVariables>(GetSubconOsListDocument, baseOptions);
        }
export type GetSubconOsListQueryHookResult = ReturnType<typeof useGetSubconOsListQuery>;
export type GetSubconOsListLazyQueryHookResult = ReturnType<typeof useGetSubconOsListLazyQuery>;
export type GetSubconOsListQueryResult = ApolloReactCommon.QueryResult<GetSubconOsListQuery, GetSubconOsListQueryVariables>;
export const GetCashFlowGraphDataDocument = gql`
    query getCashFlowGraphData($contractID: String!) {
  getCashFlowGraphData(contractID: $contractID)
}
    `;

/**
 * __useGetCashFlowGraphDataQuery__
 *
 * To run a query within a React component, call `useGetCashFlowGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCashFlowGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCashFlowGraphDataQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetCashFlowGraphDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCashFlowGraphDataQuery, GetCashFlowGraphDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCashFlowGraphDataQuery, GetCashFlowGraphDataQueryVariables>(GetCashFlowGraphDataDocument, baseOptions);
      }
export function useGetCashFlowGraphDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCashFlowGraphDataQuery, GetCashFlowGraphDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCashFlowGraphDataQuery, GetCashFlowGraphDataQueryVariables>(GetCashFlowGraphDataDocument, baseOptions);
        }
export type GetCashFlowGraphDataQueryHookResult = ReturnType<typeof useGetCashFlowGraphDataQuery>;
export type GetCashFlowGraphDataLazyQueryHookResult = ReturnType<typeof useGetCashFlowGraphDataLazyQuery>;
export type GetCashFlowGraphDataQueryResult = ApolloReactCommon.QueryResult<GetCashFlowGraphDataQuery, GetCashFlowGraphDataQueryVariables>;
export const OutstandingListingDocument = gql`
    query outstandingListing($contractID: String) {
  outstandingReceivableFromClientListing(contractID: $contractID)
  outstandingPayableSubconListing(contractID: $contractID)
  outstandingPayableSupplierListing(contractID: $contractID)
}
    `;

/**
 * __useOutstandingListingQuery__
 *
 * To run a query within a React component, call `useOutstandingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutstandingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutstandingListingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useOutstandingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OutstandingListingQuery, OutstandingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OutstandingListingQuery, OutstandingListingQueryVariables>(OutstandingListingDocument, baseOptions);
      }
export function useOutstandingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OutstandingListingQuery, OutstandingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OutstandingListingQuery, OutstandingListingQueryVariables>(OutstandingListingDocument, baseOptions);
        }
export type OutstandingListingQueryHookResult = ReturnType<typeof useOutstandingListingQuery>;
export type OutstandingListingLazyQueryHookResult = ReturnType<typeof useOutstandingListingLazyQuery>;
export type OutstandingListingQueryResult = ApolloReactCommon.QueryResult<OutstandingListingQuery, OutstandingListingQueryVariables>;
export const ProjectCostPerformanceDocument = gql`
    query projectCostPerformance($contractID: String!) {
  associateCalculation(contractID: $contractID) {
    cpi
    completionPerc
    costVariance
    earnedValue
    totalActualCost
    latestActualCostDate
    originalBudgetTotalSum
    revisedBudgetTotalSum
    revisedBudgetSubcontractSum
    subconCommCost
    materialCommCost
    labourCommCost
    plantCommCost
    miscCommCost
  }
  contractVoCalculation(contractID: $contractID)
  miscInvInfo(contractID: $contractID)
  getContractPLAdjustmentTotal(contractID: $contractID)
  getSubcontractPLAdjustmentTotal(contractID: $contractID)
  getSupplierPLAdjustmentTotal(contractID: $contractID)
  getSubcontractMiscInvoice(contractID: $contractID) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    taxAmt
    description
    outstandingAmt
    transactionStatus
  }
}
    `;

/**
 * __useProjectCostPerformanceQuery__
 *
 * To run a query within a React component, call `useProjectCostPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCostPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCostPerformanceQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useProjectCostPerformanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectCostPerformanceQuery, ProjectCostPerformanceQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectCostPerformanceQuery, ProjectCostPerformanceQueryVariables>(ProjectCostPerformanceDocument, baseOptions);
      }
export function useProjectCostPerformanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectCostPerformanceQuery, ProjectCostPerformanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectCostPerformanceQuery, ProjectCostPerformanceQueryVariables>(ProjectCostPerformanceDocument, baseOptions);
        }
export type ProjectCostPerformanceQueryHookResult = ReturnType<typeof useProjectCostPerformanceQuery>;
export type ProjectCostPerformanceLazyQueryHookResult = ReturnType<typeof useProjectCostPerformanceLazyQuery>;
export type ProjectCostPerformanceQueryResult = ApolloReactCommon.QueryResult<ProjectCostPerformanceQuery, ProjectCostPerformanceQueryVariables>;
export const SetCompletionPercDocument = gql`
    mutation setCompletionPerc($contractID: String!, $input: completionInput!) {
  completionPerc(contractID: $contractID, input: $input)
}
    `;
export type SetCompletionPercMutationFn = ApolloReactCommon.MutationFunction<SetCompletionPercMutation, SetCompletionPercMutationVariables>;

/**
 * __useSetCompletionPercMutation__
 *
 * To run a mutation, you first call `useSetCompletionPercMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCompletionPercMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCompletionPercMutation, { data, loading, error }] = useSetCompletionPercMutation({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCompletionPercMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetCompletionPercMutation, SetCompletionPercMutationVariables>) {
        return ApolloReactHooks.useMutation<SetCompletionPercMutation, SetCompletionPercMutationVariables>(SetCompletionPercDocument, baseOptions);
      }
export type SetCompletionPercMutationHookResult = ReturnType<typeof useSetCompletionPercMutation>;
export type SetCompletionPercMutationResult = ApolloReactCommon.MutationResult<SetCompletionPercMutation>;
export type SetCompletionPercMutationOptions = ApolloReactCommon.BaseMutationOptions<SetCompletionPercMutation, SetCompletionPercMutationVariables>;
export const ProjectCostingDocument = gql`
    query projectCosting($contractID: String!) {
  associateCalculation(contractID: $contractID) {
    totalCost
    revisedBudgetSubcontractSum
    subconCommCost
    materialCommCost
    labourCommCost
    plantCommCost
    miscCommCost
    subcontCostTotal
    materialCostTotal
    plantCostTotal
    miscCostTotal
    labourCostTotal
    revisedBudgetMaterialSum
    revisedBudgetPlantSum
    revisedBudgetLabourSum
    revisedBudgetLabourSum
    revisedBudgetMiscSum
    revisedBudgetTotalSum
    revMiscItemBudget
    revPlantItemBudget
    revSubconItemBudget
    revLabourItemBudget
    revMaterialItemBudget
    originalBudgetMiscSum
    originalBudgetPlantSum
    originalBudgetLabourSum
    originalBudgetMaterialSum
    originalBudgetSubcontractSum
    originalBudgetTotalSum
  }
}
    `;

/**
 * __useProjectCostingQuery__
 *
 * To run a query within a React component, call `useProjectCostingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCostingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCostingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useProjectCostingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectCostingQuery, ProjectCostingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectCostingQuery, ProjectCostingQueryVariables>(ProjectCostingDocument, baseOptions);
      }
export function useProjectCostingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectCostingQuery, ProjectCostingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectCostingQuery, ProjectCostingQueryVariables>(ProjectCostingDocument, baseOptions);
        }
export type ProjectCostingQueryHookResult = ReturnType<typeof useProjectCostingQuery>;
export type ProjectCostingLazyQueryHookResult = ReturnType<typeof useProjectCostingLazyQuery>;
export type ProjectCostingQueryResult = ApolloReactCommon.QueryResult<ProjectCostingQuery, ProjectCostingQueryVariables>;
export const GetProjectCostingBudgetDocument = gql`
    query getProjectCostingBudget($ID: String!, $costClass: CostClass!, $costCategoryID: String) {
  getContract(ID: $ID) {
    title
    implementWbs
    costCateg(costClass: $costClass, costCategoryID: $costCategoryID) {
      ID
      costClass
      name
      costToDate
      committedCost
      budget(contractID: $ID) {
        ID
        createdTs
        budgetAmt
      }
    }
  }
}
    `;

/**
 * __useGetProjectCostingBudgetQuery__
 *
 * To run a query within a React component, call `useGetProjectCostingBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCostingBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCostingBudgetQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costClass: // value for 'costClass'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetProjectCostingBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectCostingBudgetQuery, GetProjectCostingBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectCostingBudgetQuery, GetProjectCostingBudgetQueryVariables>(GetProjectCostingBudgetDocument, baseOptions);
      }
export function useGetProjectCostingBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectCostingBudgetQuery, GetProjectCostingBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectCostingBudgetQuery, GetProjectCostingBudgetQueryVariables>(GetProjectCostingBudgetDocument, baseOptions);
        }
export type GetProjectCostingBudgetQueryHookResult = ReturnType<typeof useGetProjectCostingBudgetQuery>;
export type GetProjectCostingBudgetLazyQueryHookResult = ReturnType<typeof useGetProjectCostingBudgetLazyQuery>;
export type GetProjectCostingBudgetQueryResult = ApolloReactCommon.QueryResult<GetProjectCostingBudgetQuery, GetProjectCostingBudgetQueryVariables>;
export const GetProjectCostingDetailDocument = gql`
    query getProjectCostingDetail($ID: String!, $costClass: CostClass!) {
  getProjectCostingDetail(contractID: $ID, costClass: $costClass)
  getCostingCalculation(contractID: $ID)
}
    `;

/**
 * __useGetProjectCostingDetailQuery__
 *
 * To run a query within a React component, call `useGetProjectCostingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCostingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCostingDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costClass: // value for 'costClass'
 *   },
 * });
 */
export function useGetProjectCostingDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectCostingDetailQuery, GetProjectCostingDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectCostingDetailQuery, GetProjectCostingDetailQueryVariables>(GetProjectCostingDetailDocument, baseOptions);
      }
export function useGetProjectCostingDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectCostingDetailQuery, GetProjectCostingDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectCostingDetailQuery, GetProjectCostingDetailQueryVariables>(GetProjectCostingDetailDocument, baseOptions);
        }
export type GetProjectCostingDetailQueryHookResult = ReturnType<typeof useGetProjectCostingDetailQuery>;
export type GetProjectCostingDetailLazyQueryHookResult = ReturnType<typeof useGetProjectCostingDetailLazyQuery>;
export type GetProjectCostingDetailQueryResult = ApolloReactCommon.QueryResult<GetProjectCostingDetailQuery, GetProjectCostingDetailQueryVariables>;
export const GetCostingCalculationDocument = gql`
    query getCostingCalculation($ID: String!) {
  getCostingCalculation(contractID: $ID)
  getAdjustmentByCostClass(contractID: $ID)
}
    `;

/**
 * __useGetCostingCalculationQuery__
 *
 * To run a query within a React component, call `useGetCostingCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostingCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostingCalculationQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCostingCalculationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostingCalculationQuery, GetCostingCalculationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostingCalculationQuery, GetCostingCalculationQueryVariables>(GetCostingCalculationDocument, baseOptions);
      }
export function useGetCostingCalculationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostingCalculationQuery, GetCostingCalculationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostingCalculationQuery, GetCostingCalculationQueryVariables>(GetCostingCalculationDocument, baseOptions);
        }
export type GetCostingCalculationQueryHookResult = ReturnType<typeof useGetCostingCalculationQuery>;
export type GetCostingCalculationLazyQueryHookResult = ReturnType<typeof useGetCostingCalculationLazyQuery>;
export type GetCostingCalculationQueryResult = ApolloReactCommon.QueryResult<GetCostingCalculationQuery, GetCostingCalculationQueryVariables>;
export const GetCostCategCountDocument = gql`
    query getCostCategCount($ID: String!) {
  getCostCategCount(contractID: $ID)
}
    `;

/**
 * __useGetCostCategCountQuery__
 *
 * To run a query within a React component, call `useGetCostCategCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategCountQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCostCategCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategCountQuery, GetCostCategCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategCountQuery, GetCostCategCountQueryVariables>(GetCostCategCountDocument, baseOptions);
      }
export function useGetCostCategCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategCountQuery, GetCostCategCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategCountQuery, GetCostCategCountQueryVariables>(GetCostCategCountDocument, baseOptions);
        }
export type GetCostCategCountQueryHookResult = ReturnType<typeof useGetCostCategCountQuery>;
export type GetCostCategCountLazyQueryHookResult = ReturnType<typeof useGetCostCategCountLazyQuery>;
export type GetCostCategCountQueryResult = ApolloReactCommon.QueryResult<GetCostCategCountQuery, GetCostCategCountQueryVariables>;
export const GetSubconOfTradeDocument = gql`
    query getSubconOfTrade($costCategoryID: String!, $contractID: String!) {
  getSubcontract(costCategoryID: $costCategoryID, contractID: $contractID) {
    ID
    subcontractDate
    subcontractNo
    revisedSubcontractSum
    claimToDate
    subcontractorID
    subcontractorDetail {
      name
    }
    costCategoryID
  }
}
    `;

/**
 * __useGetSubconOfTradeQuery__
 *
 * To run a query within a React component, call `useGetSubconOfTradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconOfTradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconOfTradeQuery({
 *   variables: {
 *      costCategoryID: // value for 'costCategoryID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubconOfTradeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconOfTradeQuery, GetSubconOfTradeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconOfTradeQuery, GetSubconOfTradeQueryVariables>(GetSubconOfTradeDocument, baseOptions);
      }
export function useGetSubconOfTradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconOfTradeQuery, GetSubconOfTradeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconOfTradeQuery, GetSubconOfTradeQueryVariables>(GetSubconOfTradeDocument, baseOptions);
        }
export type GetSubconOfTradeQueryHookResult = ReturnType<typeof useGetSubconOfTradeQuery>;
export type GetSubconOfTradeLazyQueryHookResult = ReturnType<typeof useGetSubconOfTradeLazyQuery>;
export type GetSubconOfTradeQueryResult = ApolloReactCommon.QueryResult<GetSubconOfTradeQuery, GetSubconOfTradeQueryVariables>;
export const GetSubconClaimOfTradeDocument = gql`
    query getSubconClaimOfTrade($subcontractID: String!, $transactionStatus: TransactionStatus!, $orderByDesc: String) {
  getSubcontractClaim(subcontractID: $subcontractID, transactionStatus: $transactionStatus, orderByDesc: $orderByDesc) {
    ID
    baseAmt
    currentVOClaimAmt
    submittedVOClaimAmt
    submittedDate
    claimDocNo
    description
    transactionStatus
    currentCertifiedAmt
    previousCertifiedAmt
  }
}
    `;

/**
 * __useGetSubconClaimOfTradeQuery__
 *
 * To run a query within a React component, call `useGetSubconClaimOfTradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconClaimOfTradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconClaimOfTradeQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      transactionStatus: // value for 'transactionStatus'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetSubconClaimOfTradeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconClaimOfTradeQuery, GetSubconClaimOfTradeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconClaimOfTradeQuery, GetSubconClaimOfTradeQueryVariables>(GetSubconClaimOfTradeDocument, baseOptions);
      }
export function useGetSubconClaimOfTradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconClaimOfTradeQuery, GetSubconClaimOfTradeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconClaimOfTradeQuery, GetSubconClaimOfTradeQueryVariables>(GetSubconClaimOfTradeDocument, baseOptions);
        }
export type GetSubconClaimOfTradeQueryHookResult = ReturnType<typeof useGetSubconClaimOfTradeQuery>;
export type GetSubconClaimOfTradeLazyQueryHookResult = ReturnType<typeof useGetSubconClaimOfTradeLazyQuery>;
export type GetSubconClaimOfTradeQueryResult = ApolloReactCommon.QueryResult<GetSubconClaimOfTradeQuery, GetSubconClaimOfTradeQueryVariables>;
export const GetPOsByCategoryDocument = gql`
    query getPOsByCategory($status: PurchaseStatus, $contractID: String, $costCategoryID: String) {
  getPOsByCategory(contractID: $contractID, costCategoryID: $costCategoryID, status: $status) {
    ID
    docNo
    docDate
    remarks
    supplierID
    totalAmt
    upToDateAmt
    supplier {
      name
    }
    poItem {
      ID
      totalAmt
      taxAmt
      orderedQty
      costItem {
        costCategoryID
        name
      }
      doItem {
        ID
        doItemAmt
        acceptedQty
        upToDateQty
        poItem {
          costItem {
            costCategoryID
          }
        }
        DO {
          DODetails
          doStatus
          upToDateAmt
        }
      }
    }
  }
}
    `;

/**
 * __useGetPOsByCategoryQuery__
 *
 * To run a query within a React component, call `useGetPOsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPOsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPOsByCategoryQuery({
 *   variables: {
 *      status: // value for 'status'
 *      contractID: // value for 'contractID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetPOsByCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPOsByCategoryQuery, GetPOsByCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPOsByCategoryQuery, GetPOsByCategoryQueryVariables>(GetPOsByCategoryDocument, baseOptions);
      }
export function useGetPOsByCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPOsByCategoryQuery, GetPOsByCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPOsByCategoryQuery, GetPOsByCategoryQueryVariables>(GetPOsByCategoryDocument, baseOptions);
        }
export type GetPOsByCategoryQueryHookResult = ReturnType<typeof useGetPOsByCategoryQuery>;
export type GetPOsByCategoryLazyQueryHookResult = ReturnType<typeof useGetPOsByCategoryLazyQuery>;
export type GetPOsByCategoryQueryResult = ApolloReactCommon.QueryResult<GetPOsByCategoryQuery, GetPOsByCategoryQueryVariables>;
export const GetDOsByCategoryDocument = gql`
    query getDOsByCategory($doStatus: DoStatus, $contractID: String, $costCategoryID: String, $purchaseOrderID: String) {
  getDOsByCategory(doStatus: $doStatus, contractID: $contractID, costCategoryID: $costCategoryID, purchaseOrderID: $purchaseOrderID) {
    createdTs
    ID
    DODetails
    doStatus
    remarks
    docDate
    transactionDate
    doNo
    docNo
    supplierID
    DOdocAmt
    supplier {
      name
    }
    doItem {
      ID
      acceptedQty
      upToDateQty
      goodReturnNoteItem {
        doItemID
        grtnID
        goodReturnNote {
          ID
        }
      }
      POs {
        ID
        docNo
        docDate
      }
      poItem {
        ID
        totalAmt
        orderedQty
        taxAmt
        costItem {
          costCategoryID
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetDOsByCategoryQuery__
 *
 * To run a query within a React component, call `useGetDOsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDOsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDOsByCategoryQuery({
 *   variables: {
 *      doStatus: // value for 'doStatus'
 *      contractID: // value for 'contractID'
 *      costCategoryID: // value for 'costCategoryID'
 *      purchaseOrderID: // value for 'purchaseOrderID'
 *   },
 * });
 */
export function useGetDOsByCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDOsByCategoryQuery, GetDOsByCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDOsByCategoryQuery, GetDOsByCategoryQueryVariables>(GetDOsByCategoryDocument, baseOptions);
      }
export function useGetDOsByCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDOsByCategoryQuery, GetDOsByCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDOsByCategoryQuery, GetDOsByCategoryQueryVariables>(GetDOsByCategoryDocument, baseOptions);
        }
export type GetDOsByCategoryQueryHookResult = ReturnType<typeof useGetDOsByCategoryQuery>;
export type GetDOsByCategoryLazyQueryHookResult = ReturnType<typeof useGetDOsByCategoryLazyQuery>;
export type GetDOsByCategoryQueryResult = ApolloReactCommon.QueryResult<GetDOsByCategoryQuery, GetDOsByCategoryQueryVariables>;
export const GetGrtNsByCategoryDoDocument = gql`
    query getGRTNsByCategoryDO($doStatus: DoStatus, $contractID: String, $costCategoryID: String, $purchaseOrderID: String) {
  getGRTNsByCategoryDO(doStatus: $doStatus, contractID: $contractID, costCategoryID: $costCategoryID, purchaseOrderID: $purchaseOrderID) {
    ID
    docDate
    createdTs
    docNo
    docRef
    description
    grtnStatus
    remarks
    supplierID
    GRTNdocAmt
    supplier {
      ID
      name
    }
    goodReturnNoteItem {
      ID
      grtnID
      doItemID
      returnedQty
      doItemID
      doItem {
        ID
        deliveryOrderID
        purchaseOrderID
        acceptedQty
        upToDateQty
        doItemAmt
        DO {
          ID
          docNo
          doStatus
        }
        POs {
          ID
          totalAmt
          taxAmt
        }
        poItem {
          ID
          orderedQty
          taxAmt
          totalAmt
          costItemID
          unitPrice
          costItem {
            ID
            costCategoryID
            name
            code
            unitPrice
            costCategory {
              ID
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetGrtNsByCategoryDoQuery__
 *
 * To run a query within a React component, call `useGetGrtNsByCategoryDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrtNsByCategoryDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrtNsByCategoryDoQuery({
 *   variables: {
 *      doStatus: // value for 'doStatus'
 *      contractID: // value for 'contractID'
 *      costCategoryID: // value for 'costCategoryID'
 *      purchaseOrderID: // value for 'purchaseOrderID'
 *   },
 * });
 */
export function useGetGrtNsByCategoryDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGrtNsByCategoryDoQuery, GetGrtNsByCategoryDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGrtNsByCategoryDoQuery, GetGrtNsByCategoryDoQueryVariables>(GetGrtNsByCategoryDoDocument, baseOptions);
      }
export function useGetGrtNsByCategoryDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGrtNsByCategoryDoQuery, GetGrtNsByCategoryDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGrtNsByCategoryDoQuery, GetGrtNsByCategoryDoQueryVariables>(GetGrtNsByCategoryDoDocument, baseOptions);
        }
export type GetGrtNsByCategoryDoQueryHookResult = ReturnType<typeof useGetGrtNsByCategoryDoQuery>;
export type GetGrtNsByCategoryDoLazyQueryHookResult = ReturnType<typeof useGetGrtNsByCategoryDoLazyQuery>;
export type GetGrtNsByCategoryDoQueryResult = ApolloReactCommon.QueryResult<GetGrtNsByCategoryDoQuery, GetGrtNsByCategoryDoQueryVariables>;
export const GetCommCostAmtDocument = gql`
    query getCommCostAmt($contractID: String, $costCategoryID: String, $subcontract: Boolean) {
  getCommCostAmt(contractID: $contractID, costCategoryID: $costCategoryID, subcontract: $subcontract)
}
    `;

/**
 * __useGetCommCostAmtQuery__
 *
 * To run a query within a React component, call `useGetCommCostAmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommCostAmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommCostAmtQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      costCategoryID: // value for 'costCategoryID'
 *      subcontract: // value for 'subcontract'
 *   },
 * });
 */
export function useGetCommCostAmtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCommCostAmtQuery, GetCommCostAmtQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCommCostAmtQuery, GetCommCostAmtQueryVariables>(GetCommCostAmtDocument, baseOptions);
      }
export function useGetCommCostAmtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCommCostAmtQuery, GetCommCostAmtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCommCostAmtQuery, GetCommCostAmtQueryVariables>(GetCommCostAmtDocument, baseOptions);
        }
export type GetCommCostAmtQueryHookResult = ReturnType<typeof useGetCommCostAmtQuery>;
export type GetCommCostAmtLazyQueryHookResult = ReturnType<typeof useGetCommCostAmtLazyQuery>;
export type GetCommCostAmtQueryResult = ApolloReactCommon.QueryResult<GetCommCostAmtQuery, GetCommCostAmtQueryVariables>;
export const GetAdjustmentItemByCostCategDocument = gql`
    query getAdjustmentItemByCostCateg($contractID: String!, $costCategoryID: String!) {
  getAdjustmentItemByCostCateg(contractID: $contractID, costCategoryID: $costCategoryID)
}
    `;

/**
 * __useGetAdjustmentItemByCostCategQuery__
 *
 * To run a query within a React component, call `useGetAdjustmentItemByCostCategQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdjustmentItemByCostCategQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdjustmentItemByCostCategQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetAdjustmentItemByCostCategQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdjustmentItemByCostCategQuery, GetAdjustmentItemByCostCategQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdjustmentItemByCostCategQuery, GetAdjustmentItemByCostCategQueryVariables>(GetAdjustmentItemByCostCategDocument, baseOptions);
      }
export function useGetAdjustmentItemByCostCategLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdjustmentItemByCostCategQuery, GetAdjustmentItemByCostCategQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdjustmentItemByCostCategQuery, GetAdjustmentItemByCostCategQueryVariables>(GetAdjustmentItemByCostCategDocument, baseOptions);
        }
export type GetAdjustmentItemByCostCategQueryHookResult = ReturnType<typeof useGetAdjustmentItemByCostCategQuery>;
export type GetAdjustmentItemByCostCategLazyQueryHookResult = ReturnType<typeof useGetAdjustmentItemByCostCategLazyQuery>;
export type GetAdjustmentItemByCostCategQueryResult = ApolloReactCommon.QueryResult<GetAdjustmentItemByCostCategQuery, GetAdjustmentItemByCostCategQueryVariables>;
export const GetApInvoiceWoDoByCostItemDocument = gql`
    query getAPInvoiceWoDOByCostItem($contractID: String!, $costCategoryID: String!) {
  getAPInvoiceWoDOByCostItem(contractID: $contractID, costCategoryID: $costCategoryID)
}
    `;

/**
 * __useGetApInvoiceWoDoByCostItemQuery__
 *
 * To run a query within a React component, call `useGetApInvoiceWoDoByCostItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApInvoiceWoDoByCostItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApInvoiceWoDoByCostItemQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetApInvoiceWoDoByCostItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApInvoiceWoDoByCostItemQuery, GetApInvoiceWoDoByCostItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApInvoiceWoDoByCostItemQuery, GetApInvoiceWoDoByCostItemQueryVariables>(GetApInvoiceWoDoByCostItemDocument, baseOptions);
      }
export function useGetApInvoiceWoDoByCostItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApInvoiceWoDoByCostItemQuery, GetApInvoiceWoDoByCostItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApInvoiceWoDoByCostItemQuery, GetApInvoiceWoDoByCostItemQueryVariables>(GetApInvoiceWoDoByCostItemDocument, baseOptions);
        }
export type GetApInvoiceWoDoByCostItemQueryHookResult = ReturnType<typeof useGetApInvoiceWoDoByCostItemQuery>;
export type GetApInvoiceWoDoByCostItemLazyQueryHookResult = ReturnType<typeof useGetApInvoiceWoDoByCostItemLazyQuery>;
export type GetApInvoiceWoDoByCostItemQueryResult = ApolloReactCommon.QueryResult<GetApInvoiceWoDoByCostItemQuery, GetApInvoiceWoDoByCostItemQueryVariables>;
export const GetPcYearDocument = gql`
    query getPCYear($contractID: String) {
  getPCYear(contractID: $contractID)
}
    `;

/**
 * __useGetPcYearQuery__
 *
 * To run a query within a React component, call `useGetPcYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPcYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPcYearQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPcYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPcYearQuery, GetPcYearQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPcYearQuery, GetPcYearQueryVariables>(GetPcYearDocument, baseOptions);
      }
export function useGetPcYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPcYearQuery, GetPcYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPcYearQuery, GetPcYearQueryVariables>(GetPcYearDocument, baseOptions);
        }
export type GetPcYearQueryHookResult = ReturnType<typeof useGetPcYearQuery>;
export type GetPcYearLazyQueryHookResult = ReturnType<typeof useGetPcYearLazyQuery>;
export type GetPcYearQueryResult = ApolloReactCommon.QueryResult<GetPcYearQuery, GetPcYearQueryVariables>;
export const GetReportYearDocument = gql`
    query getReportYear($startYear: Float, $endYear: Float) {
  getReportYear(startYear: $startYear, endYear: $endYear)
}
    `;

/**
 * __useGetReportYearQuery__
 *
 * To run a query within a React component, call `useGetReportYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportYearQuery({
 *   variables: {
 *      startYear: // value for 'startYear'
 *      endYear: // value for 'endYear'
 *   },
 * });
 */
export function useGetReportYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReportYearQuery, GetReportYearQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReportYearQuery, GetReportYearQueryVariables>(GetReportYearDocument, baseOptions);
      }
export function useGetReportYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReportYearQuery, GetReportYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReportYearQuery, GetReportYearQueryVariables>(GetReportYearDocument, baseOptions);
        }
export type GetReportYearQueryHookResult = ReturnType<typeof useGetReportYearQuery>;
export type GetReportYearLazyQueryHookResult = ReturnType<typeof useGetReportYearLazyQuery>;
export type GetReportYearQueryResult = ApolloReactCommon.QueryResult<GetReportYearQuery, GetReportYearQueryVariables>;
export const GetProjectFinancialStatusDocument = gql`
    query getProjectFinancialStatus($contractID: String!) {
  financialStatus(contractID: $contractID) {
    completionPercentage
    billToDateSum
    latestBillingDate
    latestMiscExpStatusDate
    collectionSum
    latestCollectionDate
    latestSupplierInvDate
    latestSubconBillDate
    costToDateSum
    subconBillingSum
    supplierInvSum
    miscExpSum
    subconPaymentRefundSum
    supplierPaymentSumStatus
    latestSubconPaymentRefundDate
    latestSupplierPaymentDateStatus
    conBilling
    collectionListing
    subconBilling
    supplierInvoice
    miscExpenseStatus
    subconPaymentRefund
    subconPaymentRefundSum
    supplierPaymentStatus
    clientAdvanceListing
    clientAdvanceSum
    subconSuppAdvanceListing
    subconSuppAdvanceSum
    latestClientAdvanceDate
    latestSubconSuppAdvanceDate
  }
  associateCalculation(contractID: $contractID) {
    revisedBudgetTotalSum
    materialCostTotal
    plantCostTotal
    labourCostTotal
    miscCostTotal
    subcontCostTotal
  }
  projectPL(contractID: $contractID) {
    revisedContractSum
  }
  getContractCN(contractID: $contractID, transactionStatus: APPROVED) {
    ID
    docNo
    docDate
    cancelledClaimAmt
    docAmt
    taxAmt
    description
    docRef
    transactionStatus
    claimInfo {
      ID
      docNo
      claimDocNo
      docRef
      docDate
      retentionAmt
      revenueAmt(withTax: true)
      description
      transactionStatus
    }
  }
  getSubcontractPLAdjustmentStatusTotal(contractID: $contractID)
  getContractPLAdjustmentStatusTotal(contractID: $contractID)
  getSupplierPLAdjustmentStatusTotal(contractID: $contractID)
}
    `;

/**
 * __useGetProjectFinancialStatusQuery__
 *
 * To run a query within a React component, call `useGetProjectFinancialStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectFinancialStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectFinancialStatusQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetProjectFinancialStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectFinancialStatusQuery, GetProjectFinancialStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectFinancialStatusQuery, GetProjectFinancialStatusQueryVariables>(GetProjectFinancialStatusDocument, baseOptions);
      }
export function useGetProjectFinancialStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectFinancialStatusQuery, GetProjectFinancialStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectFinancialStatusQuery, GetProjectFinancialStatusQueryVariables>(GetProjectFinancialStatusDocument, baseOptions);
        }
export type GetProjectFinancialStatusQueryHookResult = ReturnType<typeof useGetProjectFinancialStatusQuery>;
export type GetProjectFinancialStatusLazyQueryHookResult = ReturnType<typeof useGetProjectFinancialStatusLazyQuery>;
export type GetProjectFinancialStatusQueryResult = ApolloReactCommon.QueryResult<GetProjectFinancialStatusQuery, GetProjectFinancialStatusQueryVariables>;
export const GetProjectFinancialStatusListDocument = gql`
    query getProjectFinancialStatusList($contractID: String!) {
  financialStatus(contractID: $contractID) {
    conBilling
    collectionListing
    subconBilling
    supplierInvoice
    miscExpenseStatus
    subconPaymentRefund
    subconPaymentRefundSum
    supplierPaymentStatus
  }
}
    `;

/**
 * __useGetProjectFinancialStatusListQuery__
 *
 * To run a query within a React component, call `useGetProjectFinancialStatusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectFinancialStatusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectFinancialStatusListQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetProjectFinancialStatusListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectFinancialStatusListQuery, GetProjectFinancialStatusListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectFinancialStatusListQuery, GetProjectFinancialStatusListQueryVariables>(GetProjectFinancialStatusListDocument, baseOptions);
      }
export function useGetProjectFinancialStatusListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectFinancialStatusListQuery, GetProjectFinancialStatusListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectFinancialStatusListQuery, GetProjectFinancialStatusListQueryVariables>(GetProjectFinancialStatusListDocument, baseOptions);
        }
export type GetProjectFinancialStatusListQueryHookResult = ReturnType<typeof useGetProjectFinancialStatusListQuery>;
export type GetProjectFinancialStatusListLazyQueryHookResult = ReturnType<typeof useGetProjectFinancialStatusListLazyQuery>;
export type GetProjectFinancialStatusListQueryResult = ApolloReactCommon.QueryResult<GetProjectFinancialStatusListQuery, GetProjectFinancialStatusListQueryVariables>;
export const GetShareAttachmentDocument = gql`
    mutation getShareAttachment($file: Upload!, $contractID: String!) {
  shareAttachment(file: $file, contractID: $contractID) {
    ID
    bucketFileName
    fileName
    fileURL
  }
}
    `;
export type GetShareAttachmentMutationFn = ApolloReactCommon.MutationFunction<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>;

/**
 * __useGetShareAttachmentMutation__
 *
 * To run a mutation, you first call `useGetShareAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetShareAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getShareAttachmentMutation, { data, loading, error }] = useGetShareAttachmentMutation({
 *   variables: {
 *      file: // value for 'file'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetShareAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>(GetShareAttachmentDocument, baseOptions);
      }
export type GetShareAttachmentMutationHookResult = ReturnType<typeof useGetShareAttachmentMutation>;
export type GetShareAttachmentMutationResult = ApolloReactCommon.MutationResult<GetShareAttachmentMutation>;
export type GetShareAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>;
export const UpdateCompletionPercDocument = gql`
    mutation updateCompletionPerc($contractID: String!, $completionPerc: String!) {
  updateCompletionPerc(contractID: $contractID, completionPerc: $completionPerc)
}
    `;
export type UpdateCompletionPercMutationFn = ApolloReactCommon.MutationFunction<UpdateCompletionPercMutation, UpdateCompletionPercMutationVariables>;

/**
 * __useUpdateCompletionPercMutation__
 *
 * To run a mutation, you first call `useUpdateCompletionPercMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompletionPercMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompletionPercMutation, { data, loading, error }] = useUpdateCompletionPercMutation({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      completionPerc: // value for 'completionPerc'
 *   },
 * });
 */
export function useUpdateCompletionPercMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompletionPercMutation, UpdateCompletionPercMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompletionPercMutation, UpdateCompletionPercMutationVariables>(UpdateCompletionPercDocument, baseOptions);
      }
export type UpdateCompletionPercMutationHookResult = ReturnType<typeof useUpdateCompletionPercMutation>;
export type UpdateCompletionPercMutationResult = ApolloReactCommon.MutationResult<UpdateCompletionPercMutation>;
export type UpdateCompletionPercMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompletionPercMutation, UpdateCompletionPercMutationVariables>;
export const GetMiscExpenseDocument = gql`
    query getMiscExpense($ID: String, $contractID: String, $costCategoryID: String, $status: MiscExpenseStatus, $orderByAsc: String, $orderByDesc: String) {
  getMiscExpense(ID: $ID, contractID: $contractID, costCategoryID: $costCategoryID, status: $status, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    status
    accountID
    contractID
    costCategoryID
    docRef
    docNo
    docDate
    description
    docAmt
    baseAmt
    taxRate
    taxAmt
    transactionDate
    taxSchemeID
    taxScheme {
      ID
      code
      description
      latestTax {
        taxRate
        taxDate
      }
    }
    contract {
      title
    }
    costCategory {
      name
      costClass
    }
    profileInfo
  }
  lastMiscExpDate(contractID: $contractID)
}
    `;

/**
 * __useGetMiscExpenseQuery__
 *
 * To run a query within a React component, call `useGetMiscExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMiscExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMiscExpenseQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      costCategoryID: // value for 'costCategoryID'
 *      status: // value for 'status'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetMiscExpenseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMiscExpenseQuery, GetMiscExpenseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMiscExpenseQuery, GetMiscExpenseQueryVariables>(GetMiscExpenseDocument, baseOptions);
      }
export function useGetMiscExpenseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMiscExpenseQuery, GetMiscExpenseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMiscExpenseQuery, GetMiscExpenseQueryVariables>(GetMiscExpenseDocument, baseOptions);
        }
export type GetMiscExpenseQueryHookResult = ReturnType<typeof useGetMiscExpenseQuery>;
export type GetMiscExpenseLazyQueryHookResult = ReturnType<typeof useGetMiscExpenseLazyQuery>;
export type GetMiscExpenseQueryResult = ApolloReactCommon.QueryResult<GetMiscExpenseQuery, GetMiscExpenseQueryVariables>;
export const GetMiscExpenseListingDocument = gql`
    query getMiscExpenseListing($ID: String, $companyID: String, $contractID: String, $status: MiscExpenseStatus, $orderByDesc: String, $take: Float, $skip: Float, $searchValue: String) {
  getMiscExpenseCount(companyID: $companyID, contractID: $contractID, status: $status, searchValue: $searchValue)
  getMiscExpense(ID: $ID, companyID: $companyID, contractID: $contractID, status: $status, orderByDesc: $orderByDesc, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    status
    docDate
    transactionDate
    contractID
    docRef
    docNo
    taxAmt
    baseAmt
    docAmt
    description
    taxSchemeID
    costCategoryID
    costCategory {
      name
      costClass
    }
  }
}
    `;

/**
 * __useGetMiscExpenseListingQuery__
 *
 * To run a query within a React component, call `useGetMiscExpenseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMiscExpenseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMiscExpenseListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      status: // value for 'status'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetMiscExpenseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMiscExpenseListingQuery, GetMiscExpenseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMiscExpenseListingQuery, GetMiscExpenseListingQueryVariables>(GetMiscExpenseListingDocument, baseOptions);
      }
export function useGetMiscExpenseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMiscExpenseListingQuery, GetMiscExpenseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMiscExpenseListingQuery, GetMiscExpenseListingQueryVariables>(GetMiscExpenseListingDocument, baseOptions);
        }
export type GetMiscExpenseListingQueryHookResult = ReturnType<typeof useGetMiscExpenseListingQuery>;
export type GetMiscExpenseListingLazyQueryHookResult = ReturnType<typeof useGetMiscExpenseListingLazyQuery>;
export type GetMiscExpenseListingQueryResult = ApolloReactCommon.QueryResult<GetMiscExpenseListingQuery, GetMiscExpenseListingQueryVariables>;
export const CreateMiscExpenseDocument = gql`
    mutation createMiscExpense($input: MiscExpenseInput!) {
  createMiscExpense(input: $input)
}
    `;
export type CreateMiscExpenseMutationFn = ApolloReactCommon.MutationFunction<CreateMiscExpenseMutation, CreateMiscExpenseMutationVariables>;

/**
 * __useCreateMiscExpenseMutation__
 *
 * To run a mutation, you first call `useCreateMiscExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMiscExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMiscExpenseMutation, { data, loading, error }] = useCreateMiscExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMiscExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMiscExpenseMutation, CreateMiscExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMiscExpenseMutation, CreateMiscExpenseMutationVariables>(CreateMiscExpenseDocument, baseOptions);
      }
export type CreateMiscExpenseMutationHookResult = ReturnType<typeof useCreateMiscExpenseMutation>;
export type CreateMiscExpenseMutationResult = ApolloReactCommon.MutationResult<CreateMiscExpenseMutation>;
export type CreateMiscExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMiscExpenseMutation, CreateMiscExpenseMutationVariables>;
export const UpdateMiscExpenseDocument = gql`
    mutation updateMiscExpense($input: MiscExpenseInput!) {
  updateMiscExpense(input: $input)
}
    `;
export type UpdateMiscExpenseMutationFn = ApolloReactCommon.MutationFunction<UpdateMiscExpenseMutation, UpdateMiscExpenseMutationVariables>;

/**
 * __useUpdateMiscExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateMiscExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMiscExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMiscExpenseMutation, { data, loading, error }] = useUpdateMiscExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMiscExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMiscExpenseMutation, UpdateMiscExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMiscExpenseMutation, UpdateMiscExpenseMutationVariables>(UpdateMiscExpenseDocument, baseOptions);
      }
export type UpdateMiscExpenseMutationHookResult = ReturnType<typeof useUpdateMiscExpenseMutation>;
export type UpdateMiscExpenseMutationResult = ApolloReactCommon.MutationResult<UpdateMiscExpenseMutation>;
export type UpdateMiscExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMiscExpenseMutation, UpdateMiscExpenseMutationVariables>;
export const CancelMiscExpenseDocument = gql`
    mutation cancelMiscExpense($input: MiscExpenseCancellationInput!, $ID: String!) {
  cancelMiscExpense(input: $input, ID: $ID)
}
    `;
export type CancelMiscExpenseMutationFn = ApolloReactCommon.MutationFunction<CancelMiscExpenseMutation, CancelMiscExpenseMutationVariables>;

/**
 * __useCancelMiscExpenseMutation__
 *
 * To run a mutation, you first call `useCancelMiscExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMiscExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMiscExpenseMutation, { data, loading, error }] = useCancelMiscExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelMiscExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelMiscExpenseMutation, CancelMiscExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelMiscExpenseMutation, CancelMiscExpenseMutationVariables>(CancelMiscExpenseDocument, baseOptions);
      }
export type CancelMiscExpenseMutationHookResult = ReturnType<typeof useCancelMiscExpenseMutation>;
export type CancelMiscExpenseMutationResult = ApolloReactCommon.MutationResult<CancelMiscExpenseMutation>;
export type CancelMiscExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelMiscExpenseMutation, CancelMiscExpenseMutationVariables>;
export const DeleteMiscExpenseDocument = gql`
    mutation deleteMiscExpense($ID: String!) {
  deleteMiscExpense(ID: $ID)
}
    `;
export type DeleteMiscExpenseMutationFn = ApolloReactCommon.MutationFunction<DeleteMiscExpenseMutation, DeleteMiscExpenseMutationVariables>;

/**
 * __useDeleteMiscExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteMiscExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMiscExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMiscExpenseMutation, { data, loading, error }] = useDeleteMiscExpenseMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteMiscExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMiscExpenseMutation, DeleteMiscExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMiscExpenseMutation, DeleteMiscExpenseMutationVariables>(DeleteMiscExpenseDocument, baseOptions);
      }
export type DeleteMiscExpenseMutationHookResult = ReturnType<typeof useDeleteMiscExpenseMutation>;
export type DeleteMiscExpenseMutationResult = ApolloReactCommon.MutationResult<DeleteMiscExpenseMutation>;
export type DeleteMiscExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMiscExpenseMutation, DeleteMiscExpenseMutationVariables>;
export const GetMiscExpenseCountDocument = gql`
    query getMiscExpenseCount($companyID: String, $contractID: String!, $status: MiscExpenseStatus) {
  getMiscExpenseCount(companyID: $companyID, contractID: $contractID, status: $status)
}
    `;

/**
 * __useGetMiscExpenseCountQuery__
 *
 * To run a query within a React component, call `useGetMiscExpenseCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMiscExpenseCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMiscExpenseCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetMiscExpenseCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMiscExpenseCountQuery, GetMiscExpenseCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMiscExpenseCountQuery, GetMiscExpenseCountQueryVariables>(GetMiscExpenseCountDocument, baseOptions);
      }
export function useGetMiscExpenseCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMiscExpenseCountQuery, GetMiscExpenseCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMiscExpenseCountQuery, GetMiscExpenseCountQueryVariables>(GetMiscExpenseCountDocument, baseOptions);
        }
export type GetMiscExpenseCountQueryHookResult = ReturnType<typeof useGetMiscExpenseCountQuery>;
export type GetMiscExpenseCountLazyQueryHookResult = ReturnType<typeof useGetMiscExpenseCountLazyQuery>;
export type GetMiscExpenseCountQueryResult = ApolloReactCommon.QueryResult<GetMiscExpenseCountQuery, GetMiscExpenseCountQueryVariables>;
export const GetProjectPlSummaryDocument = gql`
    query getProjectPLSummary($contractID: String!) {
  projectPL(contractID: $contractID) {
    origContractSum
    revisedContractSum
    voSum
    subcontractClaimSum
    subconClaimListing
    grossTotal
    totalClaimSum
    totalProgressClaim
    totalSubconSum
    progressClaimListing
    doSumPerCC
    materialDOListing
    labourDOListing
    plantDOListing
    miscDOListing
    subcontractClaimSum
  }
  getContractClaim(contractID: $contractID, transactionStatus: APPROVED) {
    ID
    docDate
    docNo
    docRef
    revenueAmt
    docAmt
    taxAmt
    claimDocNo
    description
    transactionStatus
    docRefTable
    outstandingAmt
    currentCertifiedAmt
    previousCertifiedAmt
    currentVOClaimAmt
    previousVOClaimAmt
    currentMosAmt
    previousMosAmt
  }
  cancelledClaim: getContractClaim(contractID: $contractID, transactionStatus: CANCELLED) {
    ID
    docDate
    docNo
    docRef
    revenueAmt
    docAmt
    taxAmt
    claimDocNo
    description
    transactionStatus
    docRefTable
    outstandingAmt
    currentCertifiedAmt
    previousCertifiedAmt
    currentVOClaimAmt
    previousVOClaimAmt
    currentMosAmt
    previousMosAmt
  }
  getSubcontract(contractID: $contractID) {
    description
    subcontractSum
    subcontractNo
    createdTs
    ID
    subcontractorDetail {
      name
    }
  }
  financialStatus(contractID: $contractID) {
    collectionSum
  }
  associateCalculation(contractID: $contractID) {
    totalCost
    subcontCostTotal
    subconCommCost
    materialCommCost
    labourCommCost
    plantCommCost
    miscCommCost
  }
  outstandingClaims(contractID: $contractID) {
    outstandingAmt
    docDate
    description
    docNo
  }
  getContractMiscInvoice(contractID: $contractID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxAmt
    transactionStatus
    outstandingAmt
    numOfAttachment
  }
  getSubcontractMiscInvoice(contractID: $contractID) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    taxAmt
    description
    outstandingAmt
    transactionStatus
    numOfAttachment
  }
  getMiscExpense(contractID: $contractID) {
    ID
    status
    accountID
    contractID
    costCategoryID
    docRef
    docDate
    description
    docAmt
    baseAmt
    taxRate
    taxAmt
    transactionDate
    taxSchemeID
    contract {
      title
    }
    costCategory {
      name
      costClass
    }
  }
  miscInvInfo(contractID: $contractID)
  getAdjustmentByCostClass(contractID: $contractID)
  getContractPLAdjustmentTotal(contractID: $contractID)
  getSubcontractPLAdjustmentTotal(contractID: $contractID)
  getSupplierPLAdjustmentTotal(contractID: $contractID)
}
    `;

/**
 * __useGetProjectPlSummaryQuery__
 *
 * To run a query within a React component, call `useGetProjectPlSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectPlSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectPlSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetProjectPlSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectPlSummaryQuery, GetProjectPlSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectPlSummaryQuery, GetProjectPlSummaryQueryVariables>(GetProjectPlSummaryDocument, baseOptions);
      }
export function useGetProjectPlSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectPlSummaryQuery, GetProjectPlSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectPlSummaryQuery, GetProjectPlSummaryQueryVariables>(GetProjectPlSummaryDocument, baseOptions);
        }
export type GetProjectPlSummaryQueryHookResult = ReturnType<typeof useGetProjectPlSummaryQuery>;
export type GetProjectPlSummaryLazyQueryHookResult = ReturnType<typeof useGetProjectPlSummaryLazyQuery>;
export type GetProjectPlSummaryQueryResult = ApolloReactCommon.QueryResult<GetProjectPlSummaryQuery, GetProjectPlSummaryQueryVariables>;
export const GetProjectCostingSubmenuSummaryDocument = gql`
    query getProjectCostingSubmenuSummary($contractID: String!) {
  budgetCalculation(contractID: $contractID)
  miscExpInfo(contractID: $contractID)
  lastMiscExpDate(contractID: $contractID)
  associateCalculation(contractID: $contractID) {
    revisedBudgetTotalSum
    originalBudgetTotalSum
    totalCost
    earnedValue
    costVariance
    subconCostToDate
    materialCostToDate
    labourCostToDate
    plantCostToDate
    miscCostToDate
  }
  financialStatus(contractID: $contractID) {
    billToDateSum
    costToDateSum
  }
  receivedFromClient(contractID: $contractID)
  paidToSubCon(contractID: $contractID)
  paidToSupplier(contractID: $contractID)
  advancesOutflowAmt(contractID: $contractID)
  miscExpense(contractID: $contractID)
  advanceClient(contractID: $contractID) {
    advClientAmt
    latestAdvClientDate
  }
  paidSubconSupplierAmount(contractID: $contractID)
  getSubcontractPLAdjustmentStatusTotal(contractID: $contractID)
  getSupplierPLAdjustmentStatusTotal(contractID: $contractID)
  getContractPLAdjustmentTotal(contractID: $contractID)
  getSubcontractPLAdjustmentTotal(contractID: $contractID)
  getSupplierPLAdjustmentTotal(contractID: $contractID)
  getContractMiscInvoice(contractID: $contractID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxAmt
    transactionStatus
    outstandingAmt
  }
  getSubcontractMiscInvoice(contractID: $contractID) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    taxAmt
    description
    outstandingAmt
    transactionStatus
  }
  projectPL(contractID: $contractID) {
    grossTotal
    subcontractClaimSum
    doSumPerCC
  }
  getCostingCalculation(contractID: $contractID)
}
    `;

/**
 * __useGetProjectCostingSubmenuSummaryQuery__
 *
 * To run a query within a React component, call `useGetProjectCostingSubmenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCostingSubmenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCostingSubmenuSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetProjectCostingSubmenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectCostingSubmenuSummaryQuery, GetProjectCostingSubmenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectCostingSubmenuSummaryQuery, GetProjectCostingSubmenuSummaryQueryVariables>(GetProjectCostingSubmenuSummaryDocument, baseOptions);
      }
export function useGetProjectCostingSubmenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectCostingSubmenuSummaryQuery, GetProjectCostingSubmenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectCostingSubmenuSummaryQuery, GetProjectCostingSubmenuSummaryQueryVariables>(GetProjectCostingSubmenuSummaryDocument, baseOptions);
        }
export type GetProjectCostingSubmenuSummaryQueryHookResult = ReturnType<typeof useGetProjectCostingSubmenuSummaryQuery>;
export type GetProjectCostingSubmenuSummaryLazyQueryHookResult = ReturnType<typeof useGetProjectCostingSubmenuSummaryLazyQuery>;
export type GetProjectCostingSubmenuSummaryQueryResult = ApolloReactCommon.QueryResult<GetProjectCostingSubmenuSummaryQuery, GetProjectCostingSubmenuSummaryQueryVariables>;
export const GetProjectExpenseDocument = gql`
    query getProjectExpense($ID: String, $contractID: String, $status: String, $take: Float, $skip: Float) {
  getProjectExpense(ID: $ID, contractID: $contractID, status: $status, take: $take, skip: $skip) {
    ID
    createdBy
    createdTs
    contractID
    contract {
      ID
      title
    }
    docRef
    docNo
    docDate
    transactionDate
    docAmt
    taxAmt
    description
    status
    rejectionDate
    rejectionRemarks
    projectExpenseItem {
      ID
      projectExpenseID
      costClass
      costCategoryID
      wbsID
      wbs {
        name
        parentName
      }
      costItemID
      costItem {
        name
        code
        costCategoryID
      }
      costCategory {
        ID
        name
        costClass
      }
      taxSchemeID
      taxScheme {
        code
        description
        latestTax {
          taxRate
          taxDate
          taxSchemeID
        }
      }
      docAmt
      taxAmt
      taxRate
      remarks
    }
  }
}
    `;

/**
 * __useGetProjectExpenseQuery__
 *
 * To run a query within a React component, call `useGetProjectExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectExpenseQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      status: // value for 'status'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetProjectExpenseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectExpenseQuery, GetProjectExpenseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectExpenseQuery, GetProjectExpenseQueryVariables>(GetProjectExpenseDocument, baseOptions);
      }
export function useGetProjectExpenseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectExpenseQuery, GetProjectExpenseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectExpenseQuery, GetProjectExpenseQueryVariables>(GetProjectExpenseDocument, baseOptions);
        }
export type GetProjectExpenseQueryHookResult = ReturnType<typeof useGetProjectExpenseQuery>;
export type GetProjectExpenseLazyQueryHookResult = ReturnType<typeof useGetProjectExpenseLazyQuery>;
export type GetProjectExpenseQueryResult = ApolloReactCommon.QueryResult<GetProjectExpenseQuery, GetProjectExpenseQueryVariables>;
export const CreateProjectExpenseDocument = gql`
    mutation createProjectExpense($input: ProjectExpenseInput!, $itemInput: [ProjectExpenseItemInput!]) {
  createProjectExpense(input: $input, itemInput: $itemInput) {
    ID
  }
}
    `;
export type CreateProjectExpenseMutationFn = ApolloReactCommon.MutationFunction<CreateProjectExpenseMutation, CreateProjectExpenseMutationVariables>;

/**
 * __useCreateProjectExpenseMutation__
 *
 * To run a mutation, you first call `useCreateProjectExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectExpenseMutation, { data, loading, error }] = useCreateProjectExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateProjectExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectExpenseMutation, CreateProjectExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectExpenseMutation, CreateProjectExpenseMutationVariables>(CreateProjectExpenseDocument, baseOptions);
      }
export type CreateProjectExpenseMutationHookResult = ReturnType<typeof useCreateProjectExpenseMutation>;
export type CreateProjectExpenseMutationResult = ApolloReactCommon.MutationResult<CreateProjectExpenseMutation>;
export type CreateProjectExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectExpenseMutation, CreateProjectExpenseMutationVariables>;
export const UpdateProjectExpenseDocument = gql`
    mutation updateProjectExpense($input: ProjectExpenseInput!, $itemInput: [ProjectExpenseItemInput!]) {
  updateProjectExpense(input: $input, itemInput: $itemInput)
}
    `;
export type UpdateProjectExpenseMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectExpenseMutation, UpdateProjectExpenseMutationVariables>;

/**
 * __useUpdateProjectExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateProjectExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectExpenseMutation, { data, loading, error }] = useUpdateProjectExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateProjectExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectExpenseMutation, UpdateProjectExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectExpenseMutation, UpdateProjectExpenseMutationVariables>(UpdateProjectExpenseDocument, baseOptions);
      }
export type UpdateProjectExpenseMutationHookResult = ReturnType<typeof useUpdateProjectExpenseMutation>;
export type UpdateProjectExpenseMutationResult = ApolloReactCommon.MutationResult<UpdateProjectExpenseMutation>;
export type UpdateProjectExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectExpenseMutation, UpdateProjectExpenseMutationVariables>;
export const DeleteProjectExpenseDocument = gql`
    mutation deleteProjectExpense($ID: String!) {
  deleteProjectExpense(ID: $ID)
}
    `;
export type DeleteProjectExpenseMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectExpenseMutation, DeleteProjectExpenseMutationVariables>;

/**
 * __useDeleteProjectExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteProjectExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectExpenseMutation, { data, loading, error }] = useDeleteProjectExpenseMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteProjectExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectExpenseMutation, DeleteProjectExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectExpenseMutation, DeleteProjectExpenseMutationVariables>(DeleteProjectExpenseDocument, baseOptions);
      }
export type DeleteProjectExpenseMutationHookResult = ReturnType<typeof useDeleteProjectExpenseMutation>;
export type DeleteProjectExpenseMutationResult = ApolloReactCommon.MutationResult<DeleteProjectExpenseMutation>;
export type DeleteProjectExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectExpenseMutation, DeleteProjectExpenseMutationVariables>;
export const CancelProjectExpenseDocument = gql`
    mutation cancelProjectExpense($ID: String!, $input: ProjectExpenseCancelInput!) {
  cancelProjectExpense(ID: $ID, input: $input)
}
    `;
export type CancelProjectExpenseMutationFn = ApolloReactCommon.MutationFunction<CancelProjectExpenseMutation, CancelProjectExpenseMutationVariables>;

/**
 * __useCancelProjectExpenseMutation__
 *
 * To run a mutation, you first call `useCancelProjectExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelProjectExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelProjectExpenseMutation, { data, loading, error }] = useCancelProjectExpenseMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelProjectExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelProjectExpenseMutation, CancelProjectExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelProjectExpenseMutation, CancelProjectExpenseMutationVariables>(CancelProjectExpenseDocument, baseOptions);
      }
export type CancelProjectExpenseMutationHookResult = ReturnType<typeof useCancelProjectExpenseMutation>;
export type CancelProjectExpenseMutationResult = ApolloReactCommon.MutationResult<CancelProjectExpenseMutation>;
export type CancelProjectExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelProjectExpenseMutation, CancelProjectExpenseMutationVariables>;
export const RejectProjectExpenseDocument = gql`
    mutation rejectProjectExpense($ID: String!, $input: ProjectExpenseRejectInput!) {
  rejectProjectExpense(ID: $ID, input: $input)
}
    `;
export type RejectProjectExpenseMutationFn = ApolloReactCommon.MutationFunction<RejectProjectExpenseMutation, RejectProjectExpenseMutationVariables>;

/**
 * __useRejectProjectExpenseMutation__
 *
 * To run a mutation, you first call `useRejectProjectExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectProjectExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectProjectExpenseMutation, { data, loading, error }] = useRejectProjectExpenseMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectProjectExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectProjectExpenseMutation, RejectProjectExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectProjectExpenseMutation, RejectProjectExpenseMutationVariables>(RejectProjectExpenseDocument, baseOptions);
      }
export type RejectProjectExpenseMutationHookResult = ReturnType<typeof useRejectProjectExpenseMutation>;
export type RejectProjectExpenseMutationResult = ApolloReactCommon.MutationResult<RejectProjectExpenseMutation>;
export type RejectProjectExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectProjectExpenseMutation, RejectProjectExpenseMutationVariables>;
export const GetProjectExpenseCountDocument = gql`
    query getProjectExpenseCount($companyID: String, $contractID: String, $status: String) {
  getProjectExpenseCount(companyID: $companyID, contractID: $contractID, status: $status)
}
    `;

/**
 * __useGetProjectExpenseCountQuery__
 *
 * To run a query within a React component, call `useGetProjectExpenseCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectExpenseCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectExpenseCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetProjectExpenseCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectExpenseCountQuery, GetProjectExpenseCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectExpenseCountQuery, GetProjectExpenseCountQueryVariables>(GetProjectExpenseCountDocument, baseOptions);
      }
export function useGetProjectExpenseCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectExpenseCountQuery, GetProjectExpenseCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectExpenseCountQuery, GetProjectExpenseCountQueryVariables>(GetProjectExpenseCountDocument, baseOptions);
        }
export type GetProjectExpenseCountQueryHookResult = ReturnType<typeof useGetProjectExpenseCountQuery>;
export type GetProjectExpenseCountLazyQueryHookResult = ReturnType<typeof useGetProjectExpenseCountLazyQuery>;
export type GetProjectExpenseCountQueryResult = ApolloReactCommon.QueryResult<GetProjectExpenseCountQuery, GetProjectExpenseCountQueryVariables>;
export const GetProjectExpenseListingDocument = gql`
    query getProjectExpenseListing($ID: String, $companyID: String, $contractID: String, $status: String, $orderByDesc: String, $take: Float, $skip: Float, $searchValue: String) {
  getProjectExpenseCount(companyID: $companyID, contractID: $contractID, status: $status, searchValue: $searchValue)
  getProjectExpense(ID: $ID, companyID: $companyID, contractID: $contractID, status: $status, orderByDesc: $orderByDesc, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    contractID
    createdBy
    createdTs
    contract {
      title
    }
    docRef
    docNo
    docDate
    transactionDate
    description
    status
    docAmt
    rejectionDate
    rejectionRemarks
    projectExpenseItem {
      ID
      projectExpenseID
      costClass
      costCategoryID
      wbsID
      wbs {
        name
        parentName
      }
      costItemID
      costItem {
        name
        code
        costCategoryID
      }
      costCategory {
        ID
        name
        costClass
      }
      taxSchemeID
      taxScheme {
        code
        description
        latestTax {
          taxRate
          taxDate
          taxSchemeID
        }
      }
      docAmt
      taxAmt
      taxRate
      remarks
    }
  }
}
    `;

/**
 * __useGetProjectExpenseListingQuery__
 *
 * To run a query within a React component, call `useGetProjectExpenseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectExpenseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectExpenseListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      status: // value for 'status'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetProjectExpenseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectExpenseListingQuery, GetProjectExpenseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectExpenseListingQuery, GetProjectExpenseListingQueryVariables>(GetProjectExpenseListingDocument, baseOptions);
      }
export function useGetProjectExpenseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectExpenseListingQuery, GetProjectExpenseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectExpenseListingQuery, GetProjectExpenseListingQueryVariables>(GetProjectExpenseListingDocument, baseOptions);
        }
export type GetProjectExpenseListingQueryHookResult = ReturnType<typeof useGetProjectExpenseListingQuery>;
export type GetProjectExpenseListingLazyQueryHookResult = ReturnType<typeof useGetProjectExpenseListingLazyQuery>;
export type GetProjectExpenseListingQueryResult = ApolloReactCommon.QueryResult<GetProjectExpenseListingQuery, GetProjectExpenseListingQueryVariables>;
export const ProjectLedgerWbsRevenueSummaryDocument = gql`
    query projectLedgerWbsRevenueSummary($contractID: [String!], $companyID: String!, $wbsID: [String!], $revenueID: [String!], $endDate: String, $startDate: String, $trxEndDate: String, $trxStartDate: String) {
  projectLedgerWbsRevenueSummary(contractID: $contractID, companyID: $companyID, wbsID: $wbsID, revenueID: $revenueID, endDate: $endDate, startDate: $startDate, trxEndDate: $trxEndDate, trxStartDate: $trxStartDate)
}
    `;

/**
 * __useProjectLedgerWbsRevenueSummaryQuery__
 *
 * To run a query within a React component, call `useProjectLedgerWbsRevenueSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectLedgerWbsRevenueSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectLedgerWbsRevenueSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      wbsID: // value for 'wbsID'
 *      revenueID: // value for 'revenueID'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      trxEndDate: // value for 'trxEndDate'
 *      trxStartDate: // value for 'trxStartDate'
 *   },
 * });
 */
export function useProjectLedgerWbsRevenueSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectLedgerWbsRevenueSummaryQuery, ProjectLedgerWbsRevenueSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectLedgerWbsRevenueSummaryQuery, ProjectLedgerWbsRevenueSummaryQueryVariables>(ProjectLedgerWbsRevenueSummaryDocument, baseOptions);
      }
export function useProjectLedgerWbsRevenueSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectLedgerWbsRevenueSummaryQuery, ProjectLedgerWbsRevenueSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectLedgerWbsRevenueSummaryQuery, ProjectLedgerWbsRevenueSummaryQueryVariables>(ProjectLedgerWbsRevenueSummaryDocument, baseOptions);
        }
export type ProjectLedgerWbsRevenueSummaryQueryHookResult = ReturnType<typeof useProjectLedgerWbsRevenueSummaryQuery>;
export type ProjectLedgerWbsRevenueSummaryLazyQueryHookResult = ReturnType<typeof useProjectLedgerWbsRevenueSummaryLazyQuery>;
export type ProjectLedgerWbsRevenueSummaryQueryResult = ApolloReactCommon.QueryResult<ProjectLedgerWbsRevenueSummaryQuery, ProjectLedgerWbsRevenueSummaryQueryVariables>;
export const ProjectLedgerWbsRetentionSummaryDocument = gql`
    query projectLedgerWbsRetentionSummary($contractID: [String!], $companyID: String!, $wbsID: [String!], $revenueID: [String!], $endDate: String, $startDate: String, $trxEndDate: String, $trxStartDate: String) {
  projectLedgerWbsRetentionSummary(contractID: $contractID, companyID: $companyID, wbsID: $wbsID, revenueID: $revenueID, endDate: $endDate, startDate: $startDate, trxEndDate: $trxEndDate, trxStartDate: $trxStartDate)
}
    `;

/**
 * __useProjectLedgerWbsRetentionSummaryQuery__
 *
 * To run a query within a React component, call `useProjectLedgerWbsRetentionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectLedgerWbsRetentionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectLedgerWbsRetentionSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      wbsID: // value for 'wbsID'
 *      revenueID: // value for 'revenueID'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      trxEndDate: // value for 'trxEndDate'
 *      trxStartDate: // value for 'trxStartDate'
 *   },
 * });
 */
export function useProjectLedgerWbsRetentionSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectLedgerWbsRetentionSummaryQuery, ProjectLedgerWbsRetentionSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectLedgerWbsRetentionSummaryQuery, ProjectLedgerWbsRetentionSummaryQueryVariables>(ProjectLedgerWbsRetentionSummaryDocument, baseOptions);
      }
export function useProjectLedgerWbsRetentionSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectLedgerWbsRetentionSummaryQuery, ProjectLedgerWbsRetentionSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectLedgerWbsRetentionSummaryQuery, ProjectLedgerWbsRetentionSummaryQueryVariables>(ProjectLedgerWbsRetentionSummaryDocument, baseOptions);
        }
export type ProjectLedgerWbsRetentionSummaryQueryHookResult = ReturnType<typeof useProjectLedgerWbsRetentionSummaryQuery>;
export type ProjectLedgerWbsRetentionSummaryLazyQueryHookResult = ReturnType<typeof useProjectLedgerWbsRetentionSummaryLazyQuery>;
export type ProjectLedgerWbsRetentionSummaryQueryResult = ApolloReactCommon.QueryResult<ProjectLedgerWbsRetentionSummaryQuery, ProjectLedgerWbsRetentionSummaryQueryVariables>;
export const ProjectLedgerWbsCostSummaryDocument = gql`
    query projectLedgerWbsCostSummary($contractID: [String!], $companyID: String!, $wbsID: [String!], $costItemID: [String!], $costCategoryID: [String!], $costClass: [String!], $endDate: String, $startDate: String, $trxEndDate: String, $trxStartDate: String) {
  projectLedgerWbsCostSummary(contractID: $contractID, companyID: $companyID, wbsID: $wbsID, costItemID: $costItemID, costCategoryID: $costCategoryID, costClass: $costClass, endDate: $endDate, startDate: $startDate, trxEndDate: $trxEndDate, trxStartDate: $trxStartDate)
}
    `;

/**
 * __useProjectLedgerWbsCostSummaryQuery__
 *
 * To run a query within a React component, call `useProjectLedgerWbsCostSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectLedgerWbsCostSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectLedgerWbsCostSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      wbsID: // value for 'wbsID'
 *      costItemID: // value for 'costItemID'
 *      costCategoryID: // value for 'costCategoryID'
 *      costClass: // value for 'costClass'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      trxEndDate: // value for 'trxEndDate'
 *      trxStartDate: // value for 'trxStartDate'
 *   },
 * });
 */
export function useProjectLedgerWbsCostSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectLedgerWbsCostSummaryQuery, ProjectLedgerWbsCostSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectLedgerWbsCostSummaryQuery, ProjectLedgerWbsCostSummaryQueryVariables>(ProjectLedgerWbsCostSummaryDocument, baseOptions);
      }
export function useProjectLedgerWbsCostSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectLedgerWbsCostSummaryQuery, ProjectLedgerWbsCostSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectLedgerWbsCostSummaryQuery, ProjectLedgerWbsCostSummaryQueryVariables>(ProjectLedgerWbsCostSummaryDocument, baseOptions);
        }
export type ProjectLedgerWbsCostSummaryQueryHookResult = ReturnType<typeof useProjectLedgerWbsCostSummaryQuery>;
export type ProjectLedgerWbsCostSummaryLazyQueryHookResult = ReturnType<typeof useProjectLedgerWbsCostSummaryLazyQuery>;
export type ProjectLedgerWbsCostSummaryQueryResult = ApolloReactCommon.QueryResult<ProjectLedgerWbsCostSummaryQuery, ProjectLedgerWbsCostSummaryQueryVariables>;
export const GetCostCategoryReportDocument = gql`
    query getCostCategoryReport($costClass: [String!]) {
  getCostCategoryReport(costClass: $costClass) {
    ID
    name
    costClass
  }
}
    `;

/**
 * __useGetCostCategoryReportQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryReportQuery({
 *   variables: {
 *      costClass: // value for 'costClass'
 *   },
 * });
 */
export function useGetCostCategoryReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryReportQuery, GetCostCategoryReportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryReportQuery, GetCostCategoryReportQueryVariables>(GetCostCategoryReportDocument, baseOptions);
      }
export function useGetCostCategoryReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryReportQuery, GetCostCategoryReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryReportQuery, GetCostCategoryReportQueryVariables>(GetCostCategoryReportDocument, baseOptions);
        }
export type GetCostCategoryReportQueryHookResult = ReturnType<typeof useGetCostCategoryReportQuery>;
export type GetCostCategoryReportLazyQueryHookResult = ReturnType<typeof useGetCostCategoryReportLazyQuery>;
export type GetCostCategoryReportQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryReportQuery, GetCostCategoryReportQueryVariables>;
export const GetCostItemReportDocument = gql`
    query getCostItemReport($costCategoryID: [String!]) {
  getCostItemReport(costCategoryID: $costCategoryID) {
    ID
    name
  }
}
    `;

/**
 * __useGetCostItemReportQuery__
 *
 * To run a query within a React component, call `useGetCostItemReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemReportQuery({
 *   variables: {
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetCostItemReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemReportQuery, GetCostItemReportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemReportQuery, GetCostItemReportQueryVariables>(GetCostItemReportDocument, baseOptions);
      }
export function useGetCostItemReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemReportQuery, GetCostItemReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemReportQuery, GetCostItemReportQueryVariables>(GetCostItemReportDocument, baseOptions);
        }
export type GetCostItemReportQueryHookResult = ReturnType<typeof useGetCostItemReportQuery>;
export type GetCostItemReportLazyQueryHookResult = ReturnType<typeof useGetCostItemReportLazyQuery>;
export type GetCostItemReportQueryResult = ApolloReactCommon.QueryResult<GetCostItemReportQuery, GetCostItemReportQueryVariables>;
export const GetRevenueCategoryReportDocument = gql`
    query getRevenueCategoryReport($companyID: String) {
  getRevenueCategory(companyID: $companyID) {
    ID
    name
  }
}
    `;

/**
 * __useGetRevenueCategoryReportQuery__
 *
 * To run a query within a React component, call `useGetRevenueCategoryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevenueCategoryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevenueCategoryReportQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetRevenueCategoryReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRevenueCategoryReportQuery, GetRevenueCategoryReportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRevenueCategoryReportQuery, GetRevenueCategoryReportQueryVariables>(GetRevenueCategoryReportDocument, baseOptions);
      }
export function useGetRevenueCategoryReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRevenueCategoryReportQuery, GetRevenueCategoryReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRevenueCategoryReportQuery, GetRevenueCategoryReportQueryVariables>(GetRevenueCategoryReportDocument, baseOptions);
        }
export type GetRevenueCategoryReportQueryHookResult = ReturnType<typeof useGetRevenueCategoryReportQuery>;
export type GetRevenueCategoryReportLazyQueryHookResult = ReturnType<typeof useGetRevenueCategoryReportLazyQuery>;
export type GetRevenueCategoryReportQueryResult = ApolloReactCommon.QueryResult<GetRevenueCategoryReportQuery, GetRevenueCategoryReportQueryVariables>;
export const GetDoDocument = gql`
    query getDO($ID: String, $supplierID: String, $contractID: String, $doStatus: DoStatus, $siteID: String, $orderByDesc: String, $orderByAsc: String, $docDate: String, $take: Float, $skip: Float) {
  getDO(ID: $ID, supplierID: $supplierID, contractID: $contractID, doStatus: $doStatus, siteID: $siteID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, docDate: $docDate, take: $take, skip: $skip) {
    ID
    supplierID
    supplier {
      ID
      name
    }
    siteID
    site {
      ID
      contractID
      name
      address
      grn {
        ID
        doID
        docNo
      }
    }
    rejectionDate
    rejectionRemarks
    remarks
    docDate
    transactionDate
    docNo
    doNo
    upToDateAmt
    deliveryAddress
    contactPerson
    doStatus
    doItem {
      ID
      purchaseOrderID
      deliveryOrderID
      poItemID
      poItem {
        ID
        purchaseOrderID
        outstandingQty
        orderedQty
        costItemID
        taxSchemeID
        uom {
          code
        }
        remarks
        costItem {
          ID
          name
        }
      }
      grtnItemID
      acceptedQty
      deliveredQty
      upToDateQty
      returnedQty
      goodReturnNoteItem {
        grtnID
        doItemID
        returnedQty
        replacement
      }
      apInvoiceItem {
        APInvoiceID
        ID
        doItemID
        costItemID
      }
    }
    POs {
      ID
      subcontractID
      subcontract {
        ID
        title
      }
      docNo
      docDate
      purchaseType
      purchaseStatus
      poItem {
        ID
        remarks
        outstandingQty
        orderedQty
        costItemID
        costItem {
          ID
          name
        }
        uomID
        uom {
          ID
          name
          code
        }
        prItem {
          ID
          costItem {
            ID
            name
          }
          purchaseReq {
            ID
            deliveryAddress {
              ID
              contractID
              name
              address
            }
            contactPerson
          }
        }
      }
    }
    GRTNs {
      ID
      docNo
      docDate
      goodReturnNoteItem {
        ID
        outstandingQty
        returnedQty
        doItemID
        doItem {
          ID
          poItemID
          poItem {
            costItemID
            costItem {
              ID
              name
            }
            uomID
            uom {
              ID
              name
              code
            }
            remarks
          }
        }
      }
    }
    DODetails
    siteID
    upToDateAmt
    DOdocAmt
  }
}
    `;

/**
 * __useGetDoQuery__
 *
 * To run a query within a React component, call `useGetDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      doStatus: // value for 'doStatus'
 *      siteID: // value for 'siteID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      docDate: // value for 'docDate'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDoQuery, GetDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDoQuery, GetDoQueryVariables>(GetDoDocument, baseOptions);
      }
export function useGetDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDoQuery, GetDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDoQuery, GetDoQueryVariables>(GetDoDocument, baseOptions);
        }
export type GetDoQueryHookResult = ReturnType<typeof useGetDoQuery>;
export type GetDoLazyQueryHookResult = ReturnType<typeof useGetDoLazyQuery>;
export type GetDoQueryResult = ApolloReactCommon.QueryResult<GetDoQuery, GetDoQueryVariables>;
export const SubmitDoDocument = gql`
    mutation submitDO($ID: String, $doInput: DOInput!, $doItemInput: [DOItemInput!]) {
  submitDO(ID: $ID, doInput: $doInput, doItemInput: $doItemInput)
}
    `;
export type SubmitDoMutationFn = ApolloReactCommon.MutationFunction<SubmitDoMutation, SubmitDoMutationVariables>;

/**
 * __useSubmitDoMutation__
 *
 * To run a mutation, you first call `useSubmitDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDoMutation, { data, loading, error }] = useSubmitDoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      doInput: // value for 'doInput'
 *      doItemInput: // value for 'doItemInput'
 *   },
 * });
 */
export function useSubmitDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitDoMutation, SubmitDoMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitDoMutation, SubmitDoMutationVariables>(SubmitDoDocument, baseOptions);
      }
export type SubmitDoMutationHookResult = ReturnType<typeof useSubmitDoMutation>;
export type SubmitDoMutationResult = ApolloReactCommon.MutationResult<SubmitDoMutation>;
export type SubmitDoMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitDoMutation, SubmitDoMutationVariables>;
export const CancelCloseDoDocument = gql`
    mutation cancelCloseDO($ID: String!, $input: DOActionInput!) {
  cancelCloseDO(ID: $ID, input: $input)
}
    `;
export type CancelCloseDoMutationFn = ApolloReactCommon.MutationFunction<CancelCloseDoMutation, CancelCloseDoMutationVariables>;

/**
 * __useCancelCloseDoMutation__
 *
 * To run a mutation, you first call `useCancelCloseDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCloseDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCloseDoMutation, { data, loading, error }] = useCancelCloseDoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCloseDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCloseDoMutation, CancelCloseDoMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCloseDoMutation, CancelCloseDoMutationVariables>(CancelCloseDoDocument, baseOptions);
      }
export type CancelCloseDoMutationHookResult = ReturnType<typeof useCancelCloseDoMutation>;
export type CancelCloseDoMutationResult = ApolloReactCommon.MutationResult<CancelCloseDoMutation>;
export type CancelCloseDoMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCloseDoMutation, CancelCloseDoMutationVariables>;
export const RejectDoDocument = gql`
    mutation rejectDO($ID: String!, $rejectInput: DoRejectInput) {
  rejectDO(ID: $ID, rejectInput: $rejectInput)
}
    `;
export type RejectDoMutationFn = ApolloReactCommon.MutationFunction<RejectDoMutation, RejectDoMutationVariables>;

/**
 * __useRejectDoMutation__
 *
 * To run a mutation, you first call `useRejectDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectDoMutation, { data, loading, error }] = useRejectDoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      rejectInput: // value for 'rejectInput'
 *   },
 * });
 */
export function useRejectDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectDoMutation, RejectDoMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectDoMutation, RejectDoMutationVariables>(RejectDoDocument, baseOptions);
      }
export type RejectDoMutationHookResult = ReturnType<typeof useRejectDoMutation>;
export type RejectDoMutationResult = ApolloReactCommon.MutationResult<RejectDoMutation>;
export type RejectDoMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectDoMutation, RejectDoMutationVariables>;
export const UpdateDoDocument = gql`
    mutation updateDO($ID: String, $doItemInput: [DOItemInput!]!, $doInput: updateDOInput!) {
  updateDO(doItemInput: $doItemInput, doInput: $doInput, ID: $ID)
}
    `;
export type UpdateDoMutationFn = ApolloReactCommon.MutationFunction<UpdateDoMutation, UpdateDoMutationVariables>;

/**
 * __useUpdateDoMutation__
 *
 * To run a mutation, you first call `useUpdateDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoMutation, { data, loading, error }] = useUpdateDoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      doItemInput: // value for 'doItemInput'
 *      doInput: // value for 'doInput'
 *   },
 * });
 */
export function useUpdateDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDoMutation, UpdateDoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDoMutation, UpdateDoMutationVariables>(UpdateDoDocument, baseOptions);
      }
export type UpdateDoMutationHookResult = ReturnType<typeof useUpdateDoMutation>;
export type UpdateDoMutationResult = ApolloReactCommon.MutationResult<UpdateDoMutation>;
export type UpdateDoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDoMutation, UpdateDoMutationVariables>;
export const ActionOnDoStatusDocument = gql`
    mutation actionOnDoStatus($ID: String!, $doItemInput: [DOActionItemInput!]!) {
  actionOnDoStatus(ID: $ID, doItemInput: $doItemInput)
}
    `;
export type ActionOnDoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnDoStatusMutation, ActionOnDoStatusMutationVariables>;

/**
 * __useActionOnDoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnDoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnDoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnDoStatusMutation, { data, loading, error }] = useActionOnDoStatusMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      doItemInput: // value for 'doItemInput'
 *   },
 * });
 */
export function useActionOnDoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnDoStatusMutation, ActionOnDoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnDoStatusMutation, ActionOnDoStatusMutationVariables>(ActionOnDoStatusDocument, baseOptions);
      }
export type ActionOnDoStatusMutationHookResult = ReturnType<typeof useActionOnDoStatusMutation>;
export type ActionOnDoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnDoStatusMutation>;
export type ActionOnDoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnDoStatusMutation, ActionOnDoStatusMutationVariables>;
export const GetContractWithActiveDoDocument = gql`
    query getContractWithActiveDO($ID: String, $supplierID: String, $companyID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContractWithActiveDO(ID: $ID, supplierID: $supplierID, companyID: $companyID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    title
    accountID
    contractNo
    customerID
    description
    creditTerm
    taxDetail {
      ID
      code
      description
      taxCategory
      taxTypeID
      taxClass
      createdTs
      commonStatus
      latestTax {
        taxRate
        taxDate
      }
      taxEffective {
        date
        taxRate
        createdTs
      }
    }
    implementWbs
    budgetChecker
  }
}
    `;

/**
 * __useGetContractWithActiveDoQuery__
 *
 * To run a query within a React component, call `useGetContractWithActiveDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractWithActiveDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractWithActiveDoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      companyID: // value for 'companyID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractWithActiveDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractWithActiveDoQuery, GetContractWithActiveDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractWithActiveDoQuery, GetContractWithActiveDoQueryVariables>(GetContractWithActiveDoDocument, baseOptions);
      }
export function useGetContractWithActiveDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractWithActiveDoQuery, GetContractWithActiveDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractWithActiveDoQuery, GetContractWithActiveDoQueryVariables>(GetContractWithActiveDoDocument, baseOptions);
        }
export type GetContractWithActiveDoQueryHookResult = ReturnType<typeof useGetContractWithActiveDoQuery>;
export type GetContractWithActiveDoLazyQueryHookResult = ReturnType<typeof useGetContractWithActiveDoLazyQuery>;
export type GetContractWithActiveDoQueryResult = ApolloReactCommon.QueryResult<GetContractWithActiveDoQuery, GetContractWithActiveDoQueryVariables>;
export const DeleteDoDocument = gql`
    mutation deleteDO($doID: String!) {
  deleteDO(doID: $doID)
}
    `;
export type DeleteDoMutationFn = ApolloReactCommon.MutationFunction<DeleteDoMutation, DeleteDoMutationVariables>;

/**
 * __useDeleteDoMutation__
 *
 * To run a mutation, you first call `useDeleteDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDoMutation, { data, loading, error }] = useDeleteDoMutation({
 *   variables: {
 *      doID: // value for 'doID'
 *   },
 * });
 */
export function useDeleteDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDoMutation, DeleteDoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDoMutation, DeleteDoMutationVariables>(DeleteDoDocument, baseOptions);
      }
export type DeleteDoMutationHookResult = ReturnType<typeof useDeleteDoMutation>;
export type DeleteDoMutationResult = ApolloReactCommon.MutationResult<DeleteDoMutation>;
export type DeleteDoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDoMutation, DeleteDoMutationVariables>;
export const GetSupplierByDoStatusDocument = gql`
    query getSupplierByDOStatus($doStatus: DoStatus!, $contractID: String!) {
  getSupplierByDOStatus(contractID: $contractID, doStatus: $doStatus) {
    ID
    name
    registrationNo
    email
    address
    currencyID
    creditTerm
    description
    platformAccess
    contactNo
    description
    ratingType
    gstRegNo
    gstExpDate
    sstRegNo
    associatedStatus
    bankDetails
    accountID
  }
}
    `;

/**
 * __useGetSupplierByDoStatusQuery__
 *
 * To run a query within a React component, call `useGetSupplierByDoStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierByDoStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierByDoStatusQuery({
 *   variables: {
 *      doStatus: // value for 'doStatus'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSupplierByDoStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>(GetSupplierByDoStatusDocument, baseOptions);
      }
export function useGetSupplierByDoStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>(GetSupplierByDoStatusDocument, baseOptions);
        }
export type GetSupplierByDoStatusQueryHookResult = ReturnType<typeof useGetSupplierByDoStatusQuery>;
export type GetSupplierByDoStatusLazyQueryHookResult = ReturnType<typeof useGetSupplierByDoStatusLazyQuery>;
export type GetSupplierByDoStatusQueryResult = ApolloReactCommon.QueryResult<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>;
export const DeliveryOrderRpaDocument = gql`
    mutation deliveryOrderRPA($files: Upload!, $contractID: String!) {
  deliveryOrderRPA(files: $files, contractID: $contractID) {
    supplierID
    doNo
    poNo
    descriptions
    deliveryDate
    deliveryAddress
    doItems {
      costItemID
      qty
    }
  }
}
    `;
export type DeliveryOrderRpaMutationFn = ApolloReactCommon.MutationFunction<DeliveryOrderRpaMutation, DeliveryOrderRpaMutationVariables>;

/**
 * __useDeliveryOrderRpaMutation__
 *
 * To run a mutation, you first call `useDeliveryOrderRpaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliveryOrderRpaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliveryOrderRpaMutation, { data, loading, error }] = useDeliveryOrderRpaMutation({
 *   variables: {
 *      files: // value for 'files'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useDeliveryOrderRpaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeliveryOrderRpaMutation, DeliveryOrderRpaMutationVariables>) {
        return ApolloReactHooks.useMutation<DeliveryOrderRpaMutation, DeliveryOrderRpaMutationVariables>(DeliveryOrderRpaDocument, baseOptions);
      }
export type DeliveryOrderRpaMutationHookResult = ReturnType<typeof useDeliveryOrderRpaMutation>;
export type DeliveryOrderRpaMutationResult = ApolloReactCommon.MutationResult<DeliveryOrderRpaMutation>;
export type DeliveryOrderRpaMutationOptions = ApolloReactCommon.BaseMutationOptions<DeliveryOrderRpaMutation, DeliveryOrderRpaMutationVariables>;
export const CheckingCancelCloseDoDocument = gql`
    query checkingCancelCloseDO($ID: String!) {
  checkingCancelCloseDO(ID: $ID)
}
    `;

/**
 * __useCheckingCancelCloseDoQuery__
 *
 * To run a query within a React component, call `useCheckingCancelCloseDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckingCancelCloseDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckingCancelCloseDoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCheckingCancelCloseDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckingCancelCloseDoQuery, CheckingCancelCloseDoQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckingCancelCloseDoQuery, CheckingCancelCloseDoQueryVariables>(CheckingCancelCloseDoDocument, baseOptions);
      }
export function useCheckingCancelCloseDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckingCancelCloseDoQuery, CheckingCancelCloseDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckingCancelCloseDoQuery, CheckingCancelCloseDoQueryVariables>(CheckingCancelCloseDoDocument, baseOptions);
        }
export type CheckingCancelCloseDoQueryHookResult = ReturnType<typeof useCheckingCancelCloseDoQuery>;
export type CheckingCancelCloseDoLazyQueryHookResult = ReturnType<typeof useCheckingCancelCloseDoLazyQuery>;
export type CheckingCancelCloseDoQueryResult = ApolloReactCommon.QueryResult<CheckingCancelCloseDoQuery, CheckingCancelCloseDoQueryVariables>;
export const GetDoCountDocument = gql`
    query getDOCount($supplierID: String, $contractID: String, $doStatus: DoStatus, $siteID: String, $docDate: String) {
  getDOCount(supplierID: $supplierID, contractID: $contractID, doStatus: $doStatus, siteID: $siteID, docDate: $docDate)
}
    `;

/**
 * __useGetDoCountQuery__
 *
 * To run a query within a React component, call `useGetDoCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoCountQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      doStatus: // value for 'doStatus'
 *      siteID: // value for 'siteID'
 *      docDate: // value for 'docDate'
 *   },
 * });
 */
export function useGetDoCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDoCountQuery, GetDoCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDoCountQuery, GetDoCountQueryVariables>(GetDoCountDocument, baseOptions);
      }
export function useGetDoCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDoCountQuery, GetDoCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDoCountQuery, GetDoCountQueryVariables>(GetDoCountDocument, baseOptions);
        }
export type GetDoCountQueryHookResult = ReturnType<typeof useGetDoCountQuery>;
export type GetDoCountLazyQueryHookResult = ReturnType<typeof useGetDoCountLazyQuery>;
export type GetDoCountQueryResult = ApolloReactCommon.QueryResult<GetDoCountQuery, GetDoCountQueryVariables>;
export const GetDoListingDocument = gql`
    query getDOListing($ID: String, $supplierID: String, $contractID: String, $doStatus: DoStatus, $siteID: String, $orderByDesc: String, $orderByAsc: String, $docDate: String, $take: Float, $skip: Float, $searchValue: String) {
  getDOCount(supplierID: $supplierID, contractID: $contractID, doStatus: $doStatus, siteID: $siteID, docDate: $docDate, searchValue: $searchValue)
  getDO(ID: $ID, supplierID: $supplierID, contractID: $contractID, doStatus: $doStatus, siteID: $siteID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, docDate: $docDate, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    supplierID
    supplier {
      ID
      name
    }
    siteID
    site {
      ID
      contractID
      name
      address
      grn {
        ID
        doID
        docNo
      }
    }
    rejectionDate
    rejectionRemarks
    remarks
    docDate
    transactionDate
    docNo
    doNo
    upToDateAmt
    deliveryAddress
    contactPerson
    doStatus
    doItem {
      ID
      purchaseOrderID
      deliveryOrderID
      poItemID
      poItem {
        ID
        purchaseOrderID
        outstandingQty
        orderedQty
        costItemID
        taxSchemeID
        uom {
          code
        }
        remarks
        costItem {
          ID
          name
          isInventory
        }
      }
      grtnItemID
      acceptedQty
      deliveredQty
      upToDateQty
      returnedQty
      goodReturnNoteItem {
        grtnID
        doItemID
        returnedQty
        replacement
      }
      apInvoiceItem {
        APInvoiceID
        ID
        doItemID
        costItemID
      }
    }
    POs {
      ID
      subcontractID
      subcontract {
        ID
        title
      }
      docNo
      docDate
      purchaseType
      purchaseStatus
      poItem {
        ID
        remarks
        outstandingQty
        orderedQty
        costItemID
        costItem {
          ID
          name
        }
        uomID
        uom {
          ID
          name
          code
        }
        prItem {
          ID
          costItem {
            ID
            name
          }
          purchaseReq {
            ID
            deliveryAddress {
              ID
              contractID
              name
              address
            }
            contactPerson
          }
        }
      }
    }
    GRTNs {
      ID
      docNo
      docDate
      goodReturnNoteItem {
        ID
        outstandingQty
        returnedQty
        doItemID
        doItem {
          ID
          poItemID
          poItem {
            costItemID
            costItem {
              ID
              name
            }
            uomID
            uom {
              ID
              name
              code
            }
            remarks
          }
        }
      }
    }
    DODetails
    siteID
    upToDateAmt
    DOdocAmt
  }
}
    `;

/**
 * __useGetDoListingQuery__
 *
 * To run a query within a React component, call `useGetDoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      doStatus: // value for 'doStatus'
 *      siteID: // value for 'siteID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      docDate: // value for 'docDate'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetDoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDoListingQuery, GetDoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDoListingQuery, GetDoListingQueryVariables>(GetDoListingDocument, baseOptions);
      }
export function useGetDoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDoListingQuery, GetDoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDoListingQuery, GetDoListingQueryVariables>(GetDoListingDocument, baseOptions);
        }
export type GetDoListingQueryHookResult = ReturnType<typeof useGetDoListingQuery>;
export type GetDoListingLazyQueryHookResult = ReturnType<typeof useGetDoListingLazyQuery>;
export type GetDoListingQueryResult = ApolloReactCommon.QueryResult<GetDoListingQuery, GetDoListingQueryVariables>;
export const GetGoodReturnNoteDocument = gql`
    query getGoodReturnNote($ID: String, $contractID: String, $supplierID: String, $orderByDesc: String, $grtnStatus: PurchaseStatus, $take: Float, $skip: Float) {
  getGoodReturnNote(ID: $ID, supplierID: $supplierID, contractID: $contractID, orderByDesc: $orderByDesc, grtnStatus: $grtnStatus, take: $take, skip: $skip) {
    ID
    docNo
    docDate
    transactionDate
    contractID
    description
    grtnStatus
    rejectionDate
    remarks
    docRef
    supplierID
    siteID
    supplier {
      ID
      name
    }
    site {
      ID
      contractID
      name
      address
      grn {
        ID
        doID
        docNo
      }
    }
    site {
      grn {
        ID
        doID
        docNo
      }
    }
    goodReturnNoteItem {
      returnedQty
      doItemID
      replacement
      goodReturnNote {
        docNo
        docDate
        docRef
        description
        supplierID
        siteID
        supplier {
          name
        }
        site {
          ID
          contractID
          name
          address
        }
      }
      doItem {
        acceptedQty
        DO {
          doNo
          docNo
        }
        poItem {
          remarks
          costItem {
            name
            UOM {
              name
              code
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetGoodReturnNoteQuery__
 *
 * To run a query within a React component, call `useGetGoodReturnNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoodReturnNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoodReturnNoteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      orderByDesc: // value for 'orderByDesc'
 *      grtnStatus: // value for 'grtnStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetGoodReturnNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGoodReturnNoteQuery, GetGoodReturnNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGoodReturnNoteQuery, GetGoodReturnNoteQueryVariables>(GetGoodReturnNoteDocument, baseOptions);
      }
export function useGetGoodReturnNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoodReturnNoteQuery, GetGoodReturnNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGoodReturnNoteQuery, GetGoodReturnNoteQueryVariables>(GetGoodReturnNoteDocument, baseOptions);
        }
export type GetGoodReturnNoteQueryHookResult = ReturnType<typeof useGetGoodReturnNoteQuery>;
export type GetGoodReturnNoteLazyQueryHookResult = ReturnType<typeof useGetGoodReturnNoteLazyQuery>;
export type GetGoodReturnNoteQueryResult = ApolloReactCommon.QueryResult<GetGoodReturnNoteQuery, GetGoodReturnNoteQueryVariables>;
export const GetGoodReturnNoteItemDocument = gql`
    query getGoodReturnNoteItem($supplierID: String) {
  getGoodReturnNoteItem(supplierID: $supplierID) {
    ID
    grtnID
    doItemID
    returnedQty
    replacement
  }
}
    `;

/**
 * __useGetGoodReturnNoteItemQuery__
 *
 * To run a query within a React component, call `useGetGoodReturnNoteItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoodReturnNoteItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoodReturnNoteItemQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetGoodReturnNoteItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGoodReturnNoteItemQuery, GetGoodReturnNoteItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGoodReturnNoteItemQuery, GetGoodReturnNoteItemQueryVariables>(GetGoodReturnNoteItemDocument, baseOptions);
      }
export function useGetGoodReturnNoteItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoodReturnNoteItemQuery, GetGoodReturnNoteItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGoodReturnNoteItemQuery, GetGoodReturnNoteItemQueryVariables>(GetGoodReturnNoteItemDocument, baseOptions);
        }
export type GetGoodReturnNoteItemQueryHookResult = ReturnType<typeof useGetGoodReturnNoteItemQuery>;
export type GetGoodReturnNoteItemLazyQueryHookResult = ReturnType<typeof useGetGoodReturnNoteItemLazyQuery>;
export type GetGoodReturnNoteItemQueryResult = ApolloReactCommon.QueryResult<GetGoodReturnNoteItemQuery, GetGoodReturnNoteItemQueryVariables>;
export const GetGrtNsDocument = gql`
    query getGRTNs($supplierID: String, $siteID: String, $docDate: DateTime, $contractID: String, $IDs: [String!]) {
  getGRTNs(supplierID: $supplierID, siteID: $siteID, docDate: $docDate, contractID: $contractID, IDs: $IDs)
}
    `;

/**
 * __useGetGrtNsQuery__
 *
 * To run a query within a React component, call `useGetGrtNsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrtNsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrtNsQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      siteID: // value for 'siteID'
 *      docDate: // value for 'docDate'
 *      contractID: // value for 'contractID'
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useGetGrtNsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGrtNsQuery, GetGrtNsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGrtNsQuery, GetGrtNsQueryVariables>(GetGrtNsDocument, baseOptions);
      }
export function useGetGrtNsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGrtNsQuery, GetGrtNsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGrtNsQuery, GetGrtNsQueryVariables>(GetGrtNsDocument, baseOptions);
        }
export type GetGrtNsQueryHookResult = ReturnType<typeof useGetGrtNsQuery>;
export type GetGrtNsLazyQueryHookResult = ReturnType<typeof useGetGrtNsLazyQuery>;
export type GetGrtNsQueryResult = ApolloReactCommon.QueryResult<GetGrtNsQuery, GetGrtNsQueryVariables>;
export const CreateUpdateGrtnDocument = gql`
    mutation createUpdateGRTN($ID: String, $grtnItemInput: [GRTNItemInput!]!, $grtnInput: GoodReturnNoteInput!) {
  createUpdateGRTN(ID: $ID, grtnItemInput: $grtnItemInput, grtnInput: $grtnInput)
}
    `;
export type CreateUpdateGrtnMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>;

/**
 * __useCreateUpdateGrtnMutation__
 *
 * To run a mutation, you first call `useCreateUpdateGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateGrtnMutation, { data, loading, error }] = useCreateUpdateGrtnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      grtnItemInput: // value for 'grtnItemInput'
 *      grtnInput: // value for 'grtnInput'
 *   },
 * });
 */
export function useCreateUpdateGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>(CreateUpdateGrtnDocument, baseOptions);
      }
export type CreateUpdateGrtnMutationHookResult = ReturnType<typeof useCreateUpdateGrtnMutation>;
export type CreateUpdateGrtnMutationResult = ApolloReactCommon.MutationResult<CreateUpdateGrtnMutation>;
export type CreateUpdateGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>;
export const DeleteGrtnDocument = gql`
    mutation deleteGRTN($ID: String!) {
  deleteGRTN(ID: $ID)
}
    `;
export type DeleteGrtnMutationFn = ApolloReactCommon.MutationFunction<DeleteGrtnMutation, DeleteGrtnMutationVariables>;

/**
 * __useDeleteGrtnMutation__
 *
 * To run a mutation, you first call `useDeleteGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGrtnMutation, { data, loading, error }] = useDeleteGrtnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGrtnMutation, DeleteGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGrtnMutation, DeleteGrtnMutationVariables>(DeleteGrtnDocument, baseOptions);
      }
export type DeleteGrtnMutationHookResult = ReturnType<typeof useDeleteGrtnMutation>;
export type DeleteGrtnMutationResult = ApolloReactCommon.MutationResult<DeleteGrtnMutation>;
export type DeleteGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGrtnMutation, DeleteGrtnMutationVariables>;
export const RejectGrtnDocument = gql`
    mutation rejectGRTN($ID: String!, $rejectInput: GrtnRejectInput) {
  rejectGRTN(ID: $ID, rejectInput: $rejectInput)
}
    `;
export type RejectGrtnMutationFn = ApolloReactCommon.MutationFunction<RejectGrtnMutation, RejectGrtnMutationVariables>;

/**
 * __useRejectGrtnMutation__
 *
 * To run a mutation, you first call `useRejectGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectGrtnMutation, { data, loading, error }] = useRejectGrtnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      rejectInput: // value for 'rejectInput'
 *   },
 * });
 */
export function useRejectGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectGrtnMutation, RejectGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectGrtnMutation, RejectGrtnMutationVariables>(RejectGrtnDocument, baseOptions);
      }
export type RejectGrtnMutationHookResult = ReturnType<typeof useRejectGrtnMutation>;
export type RejectGrtnMutationResult = ApolloReactCommon.MutationResult<RejectGrtnMutation>;
export type RejectGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectGrtnMutation, RejectGrtnMutationVariables>;
export const ApproveGrtnDocument = gql`
    mutation approveGRTN($ID: String!, $grtnItemInput: [GRTNItemInput!]!) {
  approveGRTN(ID: $ID, grtnItemInput: $grtnItemInput)
}
    `;
export type ApproveGrtnMutationFn = ApolloReactCommon.MutationFunction<ApproveGrtnMutation, ApproveGrtnMutationVariables>;

/**
 * __useApproveGrtnMutation__
 *
 * To run a mutation, you first call `useApproveGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveGrtnMutation, { data, loading, error }] = useApproveGrtnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      grtnItemInput: // value for 'grtnItemInput'
 *   },
 * });
 */
export function useApproveGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveGrtnMutation, ApproveGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveGrtnMutation, ApproveGrtnMutationVariables>(ApproveGrtnDocument, baseOptions);
      }
export type ApproveGrtnMutationHookResult = ReturnType<typeof useApproveGrtnMutation>;
export type ApproveGrtnMutationResult = ApolloReactCommon.MutationResult<ApproveGrtnMutation>;
export type ApproveGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveGrtnMutation, ApproveGrtnMutationVariables>;
export const CancelCloseGrtnDocument = gql`
    mutation cancelCloseGRTN($ID: String!, $input: GrtnActionInput!) {
  cancelCloseGRTN(ID: $ID, input: $input)
}
    `;
export type CancelCloseGrtnMutationFn = ApolloReactCommon.MutationFunction<CancelCloseGrtnMutation, CancelCloseGrtnMutationVariables>;

/**
 * __useCancelCloseGrtnMutation__
 *
 * To run a mutation, you first call `useCancelCloseGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCloseGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCloseGrtnMutation, { data, loading, error }] = useCancelCloseGrtnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCloseGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCloseGrtnMutation, CancelCloseGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCloseGrtnMutation, CancelCloseGrtnMutationVariables>(CancelCloseGrtnDocument, baseOptions);
      }
export type CancelCloseGrtnMutationHookResult = ReturnType<typeof useCancelCloseGrtnMutation>;
export type CancelCloseGrtnMutationResult = ApolloReactCommon.MutationResult<CancelCloseGrtnMutation>;
export type CancelCloseGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCloseGrtnMutation, CancelCloseGrtnMutationVariables>;
export const GetGoodReturnNoteCountDocument = gql`
    query getGoodReturnNoteCount($contractID: String, $supplierID: String, $grtnStatus: PurchaseStatus) {
  getGoodReturnNoteCount(supplierID: $supplierID, contractID: $contractID, grtnStatus: $grtnStatus)
}
    `;

/**
 * __useGetGoodReturnNoteCountQuery__
 *
 * To run a query within a React component, call `useGetGoodReturnNoteCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoodReturnNoteCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoodReturnNoteCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      grtnStatus: // value for 'grtnStatus'
 *   },
 * });
 */
export function useGetGoodReturnNoteCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGoodReturnNoteCountQuery, GetGoodReturnNoteCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGoodReturnNoteCountQuery, GetGoodReturnNoteCountQueryVariables>(GetGoodReturnNoteCountDocument, baseOptions);
      }
export function useGetGoodReturnNoteCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoodReturnNoteCountQuery, GetGoodReturnNoteCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGoodReturnNoteCountQuery, GetGoodReturnNoteCountQueryVariables>(GetGoodReturnNoteCountDocument, baseOptions);
        }
export type GetGoodReturnNoteCountQueryHookResult = ReturnType<typeof useGetGoodReturnNoteCountQuery>;
export type GetGoodReturnNoteCountLazyQueryHookResult = ReturnType<typeof useGetGoodReturnNoteCountLazyQuery>;
export type GetGoodReturnNoteCountQueryResult = ApolloReactCommon.QueryResult<GetGoodReturnNoteCountQuery, GetGoodReturnNoteCountQueryVariables>;
export const GetGoodReturnNoteListingDocument = gql`
    query getGoodReturnNoteListing($ID: String, $contractID: String, $supplierID: String, $orderByDesc: String, $grtnStatus: PurchaseStatus, $take: Float, $skip: Float, $searchValue: String) {
  getGoodReturnNoteCount(supplierID: $supplierID, contractID: $contractID, grtnStatus: $grtnStatus, searchValue: $searchValue)
  getGoodReturnNote(ID: $ID, supplierID: $supplierID, contractID: $contractID, orderByDesc: $orderByDesc, grtnStatus: $grtnStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    docNo
    docDate
    transactionDate
    contractID
    description
    grtnStatus
    rejectionDate
    remarks
    docRef
    supplierID
    siteID
    supplier {
      ID
      name
    }
    site {
      ID
      contractID
      name
      address
      grn {
        ID
        doID
        docNo
      }
    }
    site {
      grn {
        ID
        doID
        docNo
      }
    }
    goodReturnNoteItem {
      returnedQty
      doItemID
      replacement
      goodReturnNote {
        docNo
        docDate
        docRef
        description
        supplierID
        siteID
        supplier {
          name
        }
        site {
          ID
          contractID
          name
          address
        }
      }
      doItem {
        acceptedQty
        DO {
          doNo
          docNo
        }
        poItem {
          remarks
          costItem {
            name
            UOM {
              name
              code
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetGoodReturnNoteListingQuery__
 *
 * To run a query within a React component, call `useGetGoodReturnNoteListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoodReturnNoteListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoodReturnNoteListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      orderByDesc: // value for 'orderByDesc'
 *      grtnStatus: // value for 'grtnStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetGoodReturnNoteListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGoodReturnNoteListingQuery, GetGoodReturnNoteListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGoodReturnNoteListingQuery, GetGoodReturnNoteListingQueryVariables>(GetGoodReturnNoteListingDocument, baseOptions);
      }
export function useGetGoodReturnNoteListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoodReturnNoteListingQuery, GetGoodReturnNoteListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGoodReturnNoteListingQuery, GetGoodReturnNoteListingQueryVariables>(GetGoodReturnNoteListingDocument, baseOptions);
        }
export type GetGoodReturnNoteListingQueryHookResult = ReturnType<typeof useGetGoodReturnNoteListingQuery>;
export type GetGoodReturnNoteListingLazyQueryHookResult = ReturnType<typeof useGetGoodReturnNoteListingLazyQuery>;
export type GetGoodReturnNoteListingQueryResult = ApolloReactCommon.QueryResult<GetGoodReturnNoteListingQuery, GetGoodReturnNoteListingQueryVariables>;
export const CreateNegotiatedSupplyDocument = gql`
    mutation createNegotiatedSupply($negoItemInput: [NegotiatedSupplyItemInput!]!, $negoSupplyInput: NegotiatedSupplyInput!) {
  createNegotiatedSupply(negoItemInput: $negoItemInput, negoSupplyInput: $negoSupplyInput)
}
    `;
export type CreateNegotiatedSupplyMutationFn = ApolloReactCommon.MutationFunction<CreateNegotiatedSupplyMutation, CreateNegotiatedSupplyMutationVariables>;

/**
 * __useCreateNegotiatedSupplyMutation__
 *
 * To run a mutation, you first call `useCreateNegotiatedSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNegotiatedSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNegotiatedSupplyMutation, { data, loading, error }] = useCreateNegotiatedSupplyMutation({
 *   variables: {
 *      negoItemInput: // value for 'negoItemInput'
 *      negoSupplyInput: // value for 'negoSupplyInput'
 *   },
 * });
 */
export function useCreateNegotiatedSupplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNegotiatedSupplyMutation, CreateNegotiatedSupplyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNegotiatedSupplyMutation, CreateNegotiatedSupplyMutationVariables>(CreateNegotiatedSupplyDocument, baseOptions);
      }
export type CreateNegotiatedSupplyMutationHookResult = ReturnType<typeof useCreateNegotiatedSupplyMutation>;
export type CreateNegotiatedSupplyMutationResult = ApolloReactCommon.MutationResult<CreateNegotiatedSupplyMutation>;
export type CreateNegotiatedSupplyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNegotiatedSupplyMutation, CreateNegotiatedSupplyMutationVariables>;
export const UpdateNegotiatedSupplyDocument = gql`
    mutation updateNegotiatedSupply($ID: String!, $negoItemInput: [NegotiatedSupplyItemInput!]!, $negoSupplyInput: NegotiatedSupplyInput!) {
  updateNegotiatedSupply(ID: $ID, negoItemInput: $negoItemInput, negoSupplyInput: $negoSupplyInput)
}
    `;
export type UpdateNegotiatedSupplyMutationFn = ApolloReactCommon.MutationFunction<UpdateNegotiatedSupplyMutation, UpdateNegotiatedSupplyMutationVariables>;

/**
 * __useUpdateNegotiatedSupplyMutation__
 *
 * To run a mutation, you first call `useUpdateNegotiatedSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNegotiatedSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNegotiatedSupplyMutation, { data, loading, error }] = useUpdateNegotiatedSupplyMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      negoItemInput: // value for 'negoItemInput'
 *      negoSupplyInput: // value for 'negoSupplyInput'
 *   },
 * });
 */
export function useUpdateNegotiatedSupplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNegotiatedSupplyMutation, UpdateNegotiatedSupplyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNegotiatedSupplyMutation, UpdateNegotiatedSupplyMutationVariables>(UpdateNegotiatedSupplyDocument, baseOptions);
      }
export type UpdateNegotiatedSupplyMutationHookResult = ReturnType<typeof useUpdateNegotiatedSupplyMutation>;
export type UpdateNegotiatedSupplyMutationResult = ApolloReactCommon.MutationResult<UpdateNegotiatedSupplyMutation>;
export type UpdateNegotiatedSupplyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNegotiatedSupplyMutation, UpdateNegotiatedSupplyMutationVariables>;
export const CancelNegotiatedSupplyDocument = gql`
    mutation cancelNegotiatedSupply($ID: String!, $input: NegoSupplyCancellationInput!) {
  cancelNegotiatedSupply(ID: $ID, input: $input)
}
    `;
export type CancelNegotiatedSupplyMutationFn = ApolloReactCommon.MutationFunction<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>;

/**
 * __useCancelNegotiatedSupplyMutation__
 *
 * To run a mutation, you first call `useCancelNegotiatedSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelNegotiatedSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelNegotiatedSupplyMutation, { data, loading, error }] = useCancelNegotiatedSupplyMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelNegotiatedSupplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>(CancelNegotiatedSupplyDocument, baseOptions);
      }
export type CancelNegotiatedSupplyMutationHookResult = ReturnType<typeof useCancelNegotiatedSupplyMutation>;
export type CancelNegotiatedSupplyMutationResult = ApolloReactCommon.MutationResult<CancelNegotiatedSupplyMutation>;
export type CancelNegotiatedSupplyMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>;
export const GetNegotiatedSupplyItemDocument = gql`
    query getNegotiatedSupplyItem($ID: String, $uomID: String, $costItemID: String, $orderByAsc: String) {
  getNegotiatedSupplyItem(ID: $ID, uomID: $uomID, costItemID: $costItemID, orderByAsc: $orderByAsc) {
    ID
    negotiatedSupplyID
    costItemID
    uomID
    remarks
    quantity
    balanceQty
    unitPrice
    costItem {
      costCategory {
        ID
        name
        supplier {
          ID
          name
          associatedStatus
        }
      }
      ID
      name
      uomID
    }
    poItem {
      ID
      orderedQty
      outstandingQty
    }
    UOM {
      ID
      code
      name
    }
    negoSupply {
      ID
      supplierID
      contractID
      docNo
      negoSupplyDate
      startDate
      endDate
      cancellationDate
      cancellationRemarks
      status
      supplier {
        ID
        name
      }
    }
  }
}
    `;

/**
 * __useGetNegotiatedSupplyItemQuery__
 *
 * To run a query within a React component, call `useGetNegotiatedSupplyItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegotiatedSupplyItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegotiatedSupplyItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      uomID: // value for 'uomID'
 *      costItemID: // value for 'costItemID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetNegotiatedSupplyItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>(GetNegotiatedSupplyItemDocument, baseOptions);
      }
export function useGetNegotiatedSupplyItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>(GetNegotiatedSupplyItemDocument, baseOptions);
        }
export type GetNegotiatedSupplyItemQueryHookResult = ReturnType<typeof useGetNegotiatedSupplyItemQuery>;
export type GetNegotiatedSupplyItemLazyQueryHookResult = ReturnType<typeof useGetNegotiatedSupplyItemLazyQuery>;
export type GetNegotiatedSupplyItemQueryResult = ApolloReactCommon.QueryResult<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>;
export const GetNegoSupplyDocument = gql`
    query getNegoSupply($ID: String, $contractID: String) {
  getNegotiatedSupply(ID: $ID, contractID: $contractID) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    supplierID
    contractID
    docNo
    negoSupplyDate
    remarks
    startDate
    endDate
    cancellationDate
    cancellationRemarks
    status
    supplier {
      ID
      name
    }
    negoSupplyItem {
      createdTs
      createdBy
      modTs
      modBy
      ID
      accountID
      negotiatedSupplyID
      costItemID
      uomID
      remarks
      quantity
      balanceQty
      unitPrice
      costItem {
        ID
        name
      }
      poItem {
        ID
        orderedQty
        outstandingQty
      }
      poItemApproved {
        ID
        orderedQty
        outstandingQty
      }
      UOM {
        ID
        code
      }
    }
  }
}
    `;

/**
 * __useGetNegoSupplyQuery__
 *
 * To run a query within a React component, call `useGetNegoSupplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegoSupplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegoSupplyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetNegoSupplyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNegoSupplyQuery, GetNegoSupplyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNegoSupplyQuery, GetNegoSupplyQueryVariables>(GetNegoSupplyDocument, baseOptions);
      }
export function useGetNegoSupplyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNegoSupplyQuery, GetNegoSupplyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNegoSupplyQuery, GetNegoSupplyQueryVariables>(GetNegoSupplyDocument, baseOptions);
        }
export type GetNegoSupplyQueryHookResult = ReturnType<typeof useGetNegoSupplyQuery>;
export type GetNegoSupplyLazyQueryHookResult = ReturnType<typeof useGetNegoSupplyLazyQuery>;
export type GetNegoSupplyQueryResult = ApolloReactCommon.QueryResult<GetNegoSupplyQuery, GetNegoSupplyQueryVariables>;
export const GetNegoSupplyListingDocument = gql`
    query getNegoSupplyListing($ID: String, $contractID: String, $status: PurchaseStatus, $skip: Float, $take: Float, $orderByDesc: String, $searchValue: String) {
  getNegotiatedSupplyCount(contractID: $contractID, status: $status, searchValue: $searchValue)
  getNegotiatedSupply(ID: $ID, contractID: $contractID, status: $status, skip: $skip, take: $take, orderByDesc: $orderByDesc, searchValue: $searchValue) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    supplierID
    contractID
    docNo
    negoSupplyDate
    remarks
    startDate
    endDate
    cancellationDate
    cancellationRemarks
    status
    supplier {
      ID
      name
    }
    negoSupplyItem {
      createdTs
      createdBy
      modTs
      modBy
      ID
      accountID
      negotiatedSupplyID
      costItemID
      uomID
      remarks
      quantity
      balanceQty
      unitPrice
      costItem {
        ID
        name
      }
      poItem {
        ID
        orderedQty
        outstandingQty
      }
      poItemApproved {
        ID
        orderedQty
        outstandingQty
      }
      UOM {
        ID
        code
      }
    }
  }
}
    `;

/**
 * __useGetNegoSupplyListingQuery__
 *
 * To run a query within a React component, call `useGetNegoSupplyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegoSupplyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegoSupplyListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      status: // value for 'status'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      orderByDesc: // value for 'orderByDesc'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetNegoSupplyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNegoSupplyListingQuery, GetNegoSupplyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNegoSupplyListingQuery, GetNegoSupplyListingQueryVariables>(GetNegoSupplyListingDocument, baseOptions);
      }
export function useGetNegoSupplyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNegoSupplyListingQuery, GetNegoSupplyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNegoSupplyListingQuery, GetNegoSupplyListingQueryVariables>(GetNegoSupplyListingDocument, baseOptions);
        }
export type GetNegoSupplyListingQueryHookResult = ReturnType<typeof useGetNegoSupplyListingQuery>;
export type GetNegoSupplyListingLazyQueryHookResult = ReturnType<typeof useGetNegoSupplyListingLazyQuery>;
export type GetNegoSupplyListingQueryResult = ApolloReactCommon.QueryResult<GetNegoSupplyListingQuery, GetNegoSupplyListingQueryVariables>;
export const GetPurchaseSummaryDocument = gql`
    query getPurchaseSummary($contractID: String!) {
  negoItemInfo(contractID: $contractID)
  validateContractStatus(contractID: $contractID)
  prInfoCount(contractID: $contractID)
  lastPRDate(contractID: $contractID)
  lastPODate(contractID: $contractID)
  rfqInfo(contractID: $contractID)
  rfqCount(contractID: $contractID)
  lastRFQDate(contractID: $contractID)
  lastPRDateOB(contractID: $contractID)
  poInfoCount(contractID: $contractID)
  lastPODateOB(contractID: $contractID)
  doCount(contractID: $contractID)
  lastDODate(contractID: $contractID)
  grtnCount(contractID: $contractID)
  lastGRTNDate(contractID: $contractID)
  verifyNegoSupplyStatus(contractID: $contractID)
  getContract(ID: $contractID) {
    ID
    title
    contractDate
    customerID
    customerDetail {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetPurchaseSummaryQuery__
 *
 * To run a query within a React component, call `useGetPurchaseSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPurchaseSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseSummaryQuery, GetPurchaseSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseSummaryQuery, GetPurchaseSummaryQueryVariables>(GetPurchaseSummaryDocument, baseOptions);
      }
export function useGetPurchaseSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseSummaryQuery, GetPurchaseSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseSummaryQuery, GetPurchaseSummaryQueryVariables>(GetPurchaseSummaryDocument, baseOptions);
        }
export type GetPurchaseSummaryQueryHookResult = ReturnType<typeof useGetPurchaseSummaryQuery>;
export type GetPurchaseSummaryLazyQueryHookResult = ReturnType<typeof useGetPurchaseSummaryLazyQuery>;
export type GetPurchaseSummaryQueryResult = ApolloReactCommon.QueryResult<GetPurchaseSummaryQuery, GetPurchaseSummaryQueryVariables>;
export const GetPurchaseContractTitleDocument = gql`
    query getPurchaseContractTitle($ID: String, $companyID: String, $orderByAsc: String, $orderByDesc: String, $take: Float) {
  getContract(ID: $ID, companyID: $companyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take) {
    ID
    customerID
    title
    contractNo
    contractDate
    contractStatus
    purchasingSummary
    customerDetail {
      name
    }
  }
}
    `;

/**
 * __useGetPurchaseContractTitleQuery__
 *
 * To run a query within a React component, call `useGetPurchaseContractTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseContractTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseContractTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPurchaseContractTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseContractTitleQuery, GetPurchaseContractTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseContractTitleQuery, GetPurchaseContractTitleQueryVariables>(GetPurchaseContractTitleDocument, baseOptions);
      }
export function useGetPurchaseContractTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseContractTitleQuery, GetPurchaseContractTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseContractTitleQuery, GetPurchaseContractTitleQueryVariables>(GetPurchaseContractTitleDocument, baseOptions);
        }
export type GetPurchaseContractTitleQueryHookResult = ReturnType<typeof useGetPurchaseContractTitleQuery>;
export type GetPurchaseContractTitleLazyQueryHookResult = ReturnType<typeof useGetPurchaseContractTitleLazyQuery>;
export type GetPurchaseContractTitleQueryResult = ApolloReactCommon.QueryResult<GetPurchaseContractTitleQuery, GetPurchaseContractTitleQueryVariables>;
export const GetCostItemAndUomDocument = gql`
    query getCostItemAndUom($ID: String, $orderByAsc: String) {
  getCostItem(ID: $ID, orderByAsc: $orderByAsc, commonStatus: ACTIVE) {
    ID
    costCategoryID
    commonStatus
    uomID
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
    name
    unitPrice
    onBehalf
    markupPerc
  }
  getUOM(ID: $ID, orderByAsc: $orderByAsc, commonStatus: ACTIVE) {
    ID
    name
    code
    commonStatus
  }
}
    `;

/**
 * __useGetCostItemAndUomQuery__
 *
 * To run a query within a React component, call `useGetCostItemAndUomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemAndUomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemAndUomQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCostItemAndUomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemAndUomQuery, GetCostItemAndUomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemAndUomQuery, GetCostItemAndUomQueryVariables>(GetCostItemAndUomDocument, baseOptions);
      }
export function useGetCostItemAndUomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemAndUomQuery, GetCostItemAndUomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemAndUomQuery, GetCostItemAndUomQueryVariables>(GetCostItemAndUomDocument, baseOptions);
        }
export type GetCostItemAndUomQueryHookResult = ReturnType<typeof useGetCostItemAndUomQuery>;
export type GetCostItemAndUomLazyQueryHookResult = ReturnType<typeof useGetCostItemAndUomLazyQuery>;
export type GetCostItemAndUomQueryResult = ApolloReactCommon.QueryResult<GetCostItemAndUomQuery, GetCostItemAndUomQueryVariables>;
export const GetPurchaseBudgetDocument = gql`
    query getPurchaseBudget($contractID: String!, $prInfo: [prInfo!]!) {
  purchaseBudget(contractID: $contractID, prInfo: $prInfo)
}
    `;

/**
 * __useGetPurchaseBudgetQuery__
 *
 * To run a query within a React component, call `useGetPurchaseBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseBudgetQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      prInfo: // value for 'prInfo'
 *   },
 * });
 */
export function useGetPurchaseBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseBudgetQuery, GetPurchaseBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseBudgetQuery, GetPurchaseBudgetQueryVariables>(GetPurchaseBudgetDocument, baseOptions);
      }
export function useGetPurchaseBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseBudgetQuery, GetPurchaseBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseBudgetQuery, GetPurchaseBudgetQueryVariables>(GetPurchaseBudgetDocument, baseOptions);
        }
export type GetPurchaseBudgetQueryHookResult = ReturnType<typeof useGetPurchaseBudgetQuery>;
export type GetPurchaseBudgetLazyQueryHookResult = ReturnType<typeof useGetPurchaseBudgetLazyQuery>;
export type GetPurchaseBudgetQueryResult = ApolloReactCommon.QueryResult<GetPurchaseBudgetQuery, GetPurchaseBudgetQueryVariables>;
export const PurchaseBudgetApprovalDocument = gql`
    mutation purchaseBudgetApproval($input: PurchaseBudgetInput!, $IDs: [String!]!) {
  purchaseBudgetApproval(input: $input, IDs: $IDs)
}
    `;
export type PurchaseBudgetApprovalMutationFn = ApolloReactCommon.MutationFunction<PurchaseBudgetApprovalMutation, PurchaseBudgetApprovalMutationVariables>;

/**
 * __usePurchaseBudgetApprovalMutation__
 *
 * To run a mutation, you first call `usePurchaseBudgetApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseBudgetApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseBudgetApprovalMutation, { data, loading, error }] = usePurchaseBudgetApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function usePurchaseBudgetApprovalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PurchaseBudgetApprovalMutation, PurchaseBudgetApprovalMutationVariables>) {
        return ApolloReactHooks.useMutation<PurchaseBudgetApprovalMutation, PurchaseBudgetApprovalMutationVariables>(PurchaseBudgetApprovalDocument, baseOptions);
      }
export type PurchaseBudgetApprovalMutationHookResult = ReturnType<typeof usePurchaseBudgetApprovalMutation>;
export type PurchaseBudgetApprovalMutationResult = ApolloReactCommon.MutationResult<PurchaseBudgetApprovalMutation>;
export type PurchaseBudgetApprovalMutationOptions = ApolloReactCommon.BaseMutationOptions<PurchaseBudgetApprovalMutation, PurchaseBudgetApprovalMutationVariables>;
export const PurchaseBudgetAllocationDocument = gql`
    query purchaseBudgetAllocation($contractID: String!, $prItemIDs: [String!]!) {
  purchaseBudgetAllocation(contractID: $contractID, prItemIDs: $prItemIDs)
}
    `;

/**
 * __usePurchaseBudgetAllocationQuery__
 *
 * To run a query within a React component, call `usePurchaseBudgetAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseBudgetAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseBudgetAllocationQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      prItemIDs: // value for 'prItemIDs'
 *   },
 * });
 */
export function usePurchaseBudgetAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchaseBudgetAllocationQuery, PurchaseBudgetAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchaseBudgetAllocationQuery, PurchaseBudgetAllocationQueryVariables>(PurchaseBudgetAllocationDocument, baseOptions);
      }
export function usePurchaseBudgetAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseBudgetAllocationQuery, PurchaseBudgetAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchaseBudgetAllocationQuery, PurchaseBudgetAllocationQueryVariables>(PurchaseBudgetAllocationDocument, baseOptions);
        }
export type PurchaseBudgetAllocationQueryHookResult = ReturnType<typeof usePurchaseBudgetAllocationQuery>;
export type PurchaseBudgetAllocationLazyQueryHookResult = ReturnType<typeof usePurchaseBudgetAllocationLazyQuery>;
export type PurchaseBudgetAllocationQueryResult = ApolloReactCommon.QueryResult<PurchaseBudgetAllocationQuery, PurchaseBudgetAllocationQueryVariables>;
export const PurchaseWbsBudgetAllocationDocument = gql`
    query purchaseWbsBudgetAllocation($contractID: String!, $input: [PurchaseWbsBudgetAllocationInput!]!) {
  purchaseWbsBudgetAllocation(contractID: $contractID, input: $input)
}
    `;

/**
 * __usePurchaseWbsBudgetAllocationQuery__
 *
 * To run a query within a React component, call `usePurchaseWbsBudgetAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseWbsBudgetAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseWbsBudgetAllocationQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseWbsBudgetAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchaseWbsBudgetAllocationQuery, PurchaseWbsBudgetAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchaseWbsBudgetAllocationQuery, PurchaseWbsBudgetAllocationQueryVariables>(PurchaseWbsBudgetAllocationDocument, baseOptions);
      }
export function usePurchaseWbsBudgetAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseWbsBudgetAllocationQuery, PurchaseWbsBudgetAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchaseWbsBudgetAllocationQuery, PurchaseWbsBudgetAllocationQueryVariables>(PurchaseWbsBudgetAllocationDocument, baseOptions);
        }
export type PurchaseWbsBudgetAllocationQueryHookResult = ReturnType<typeof usePurchaseWbsBudgetAllocationQuery>;
export type PurchaseWbsBudgetAllocationLazyQueryHookResult = ReturnType<typeof usePurchaseWbsBudgetAllocationLazyQuery>;
export type PurchaseWbsBudgetAllocationQueryResult = ApolloReactCommon.QueryResult<PurchaseWbsBudgetAllocationQuery, PurchaseWbsBudgetAllocationQueryVariables>;
export const ActionOnPoStatusDocument = gql`
    mutation actionOnPOStatus($ID: String!, $input: PurchaseActionInput!) {
  actionOnPOStatus(ID: $ID, input: $input)
}
    `;
export type ActionOnPoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnPoStatusMutation, ActionOnPoStatusMutationVariables>;

/**
 * __useActionOnPoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnPoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnPoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnPoStatusMutation, { data, loading, error }] = useActionOnPoStatusMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActionOnPoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnPoStatusMutation, ActionOnPoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnPoStatusMutation, ActionOnPoStatusMutationVariables>(ActionOnPoStatusDocument, baseOptions);
      }
export type ActionOnPoStatusMutationHookResult = ReturnType<typeof useActionOnPoStatusMutation>;
export type ActionOnPoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnPoStatusMutation>;
export type ActionOnPoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnPoStatusMutation, ActionOnPoStatusMutationVariables>;
export const GetSupplierQuotesDocument = gql`
    query getSupplierQuotes($costItemID: String!, $contractID: String!, $prItemID: String!) {
  getSupplierQuotes(costItemID: $costItemID, contractID: $contractID, prItemID: $prItemID)
}
    `;

/**
 * __useGetSupplierQuotesQuery__
 *
 * To run a query within a React component, call `useGetSupplierQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierQuotesQuery({
 *   variables: {
 *      costItemID: // value for 'costItemID'
 *      contractID: // value for 'contractID'
 *      prItemID: // value for 'prItemID'
 *   },
 * });
 */
export function useGetSupplierQuotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierQuotesQuery, GetSupplierQuotesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierQuotesQuery, GetSupplierQuotesQueryVariables>(GetSupplierQuotesDocument, baseOptions);
      }
export function useGetSupplierQuotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierQuotesQuery, GetSupplierQuotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierQuotesQuery, GetSupplierQuotesQueryVariables>(GetSupplierQuotesDocument, baseOptions);
        }
export type GetSupplierQuotesQueryHookResult = ReturnType<typeof useGetSupplierQuotesQuery>;
export type GetSupplierQuotesLazyQueryHookResult = ReturnType<typeof useGetSupplierQuotesLazyQuery>;
export type GetSupplierQuotesQueryResult = ApolloReactCommon.QueryResult<GetSupplierQuotesQuery, GetSupplierQuotesQueryVariables>;
export const GetSupplierForPurchaseOrderDocument = gql`
    query getSupplierForPurchaseOrder($ID: String) {
  getSupplier(ID: $ID) {
    ID
    name
    createdTs
    createdBy
    address
    contactNo
    associatedStatus
  }
}
    `;

/**
 * __useGetSupplierForPurchaseOrderQuery__
 *
 * To run a query within a React component, call `useGetSupplierForPurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierForPurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierForPurchaseOrderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSupplierForPurchaseOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierForPurchaseOrderQuery, GetSupplierForPurchaseOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierForPurchaseOrderQuery, GetSupplierForPurchaseOrderQueryVariables>(GetSupplierForPurchaseOrderDocument, baseOptions);
      }
export function useGetSupplierForPurchaseOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierForPurchaseOrderQuery, GetSupplierForPurchaseOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierForPurchaseOrderQuery, GetSupplierForPurchaseOrderQueryVariables>(GetSupplierForPurchaseOrderDocument, baseOptions);
        }
export type GetSupplierForPurchaseOrderQueryHookResult = ReturnType<typeof useGetSupplierForPurchaseOrderQuery>;
export type GetSupplierForPurchaseOrderLazyQueryHookResult = ReturnType<typeof useGetSupplierForPurchaseOrderLazyQuery>;
export type GetSupplierForPurchaseOrderQueryResult = ApolloReactCommon.QueryResult<GetSupplierForPurchaseOrderQuery, GetSupplierForPurchaseOrderQueryVariables>;
export const GetUomExchangeListDocument = gql`
    query getUOMExchangeList($uomID: String!) {
  getUOMExchangeList(uomID: $uomID)
}
    `;

/**
 * __useGetUomExchangeListQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeListQuery({
 *   variables: {
 *      uomID: // value for 'uomID'
 *   },
 * });
 */
export function useGetUomExchangeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
      }
export function useGetUomExchangeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
        }
export type GetUomExchangeListQueryHookResult = ReturnType<typeof useGetUomExchangeListQuery>;
export type GetUomExchangeListLazyQueryHookResult = ReturnType<typeof useGetUomExchangeListLazyQuery>;
export type GetUomExchangeListQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>;
export const GetPurchaseOrderDocument = gql`
    query getPurchaseOrder($ID: String, $purchaseType: PurchaseType, $contractID: String, $orderByDesc: String, $prGenerated: Boolean, $purchaseStatus: PurchaseStatus, $skip: Float, $take: Float) {
  getPurchaseOrder(ID: $ID, purchaseType: $purchaseType, contractID: $contractID, orderByDesc: $orderByDesc, prGenerated: $prGenerated, purchaseStatus: $purchaseStatus, skip: $skip, take: $take) {
    ID
    accountID
    createdBy
    createdTs
    purchaseType
    contractID
    docNo
    docRef
    supplierID
    supplier {
      ID
      name
    }
    subcontractID
    subcontract {
      title
      ID
      description
      subcontractorDetail {
        ID
        name
      }
    }
    siteID
    deliveryAddress {
      ID
      name
      address
    }
    docDate
    docDueDate
    expectedDate
    instructions
    submissionComment
    totalAmt
    remarks
    rejectionDate
    cancelCloseDate
    cancelCloseRemark
    purchaseStatus
    prGenerated
    contactPerson {
      name
      contactNo
    }
    poItem {
      ID
      purchaseOrderID
      expectedDate
      prItemID
      prItem {
        ID
        markupPerc
        purchaseReq {
          ID
          docNo
          deliveryAddress {
            ID
            contractID
            name
            address
          }
          contactPerson
        }
        costItem {
          ID
          markupPerc
          name
        }
      }
      taxSchemeID
      taxScheme {
        ID
        code
        isClaimable
        latestTax {
          taxDate
          taxRate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costItemID
      costItem {
        name
        code
        unitPrice
        markupPerc
      }
      uomID
      uom {
        ID
        name
        code
      }
      negotiatedSupplyItemID
      rfqItemID
      taxAmt
      taxRate
      remarks
      markupAmt
      markupPerc
      doItem {
        ID
        acceptedQty
        poItemID
        doItemSubmitted
        upToDateQty
        goodReturnNoteItem {
          ID
          grtnID
          doItemID
          returnedQty
        }
      }
      discountAmt
      totalAmt
      unitPrice
      orderedQty
      outstandingQty
      uomExchange {
        uomid
        uomname
        uomcode
      }
      wbsBudgetDetail(contractID: $contractID) {
        ID
        quantity
        unitPrice
        trackQty(contractID: $contractID)
        balanceQty(contractID: $contractID)
        balanceQtyPurchasing(contractID: $contractID, poID: $ID)
        reservedAmt(contractID: $contractID)
        utilisedAmt(contractID: $contractID)
        balanceAmt(contractID: $contractID)
      }
    }
    posConnection {
      DO {
        doNo
        doStatus
      }
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
}
    `;

/**
 * __useGetPurchaseOrderQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      purchaseType: // value for 'purchaseType'
 *      contractID: // value for 'contractID'
 *      orderByDesc: // value for 'orderByDesc'
 *      prGenerated: // value for 'prGenerated'
 *      purchaseStatus: // value for 'purchaseStatus'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPurchaseOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>(GetPurchaseOrderDocument, baseOptions);
      }
export function useGetPurchaseOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>(GetPurchaseOrderDocument, baseOptions);
        }
export type GetPurchaseOrderQueryHookResult = ReturnType<typeof useGetPurchaseOrderQuery>;
export type GetPurchaseOrderLazyQueryHookResult = ReturnType<typeof useGetPurchaseOrderLazyQuery>;
export type GetPurchaseOrderQueryResult = ApolloReactCommon.QueryResult<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>;
export const GetPoForBudgetByOneDocument = gql`
    query getPOForBudgetByOne($ID: String!, $contractID: String!, $purchaseType: PurchaseType!) {
  getPurchaseOrder(ID: $ID, contractID: $contractID, purchaseType: $purchaseType) {
    ID
    accountID
    siteID
    deliveryAddress {
      ID
      name
      address
    }
    docDate
    docNo
    prGenerated
    purchaseStatus
    instructions
    purchaseType
    remarks
    rejectionDate
    totalAmt
    submissionComment
    supplierID
    supplier {
      ID
      name
    }
    poItem {
      ID
      purchaseOrderID
      outstandingQty
      prItemID
      taxAmt
      taxRate
      remarks
      markupAmt
      costItemID
      totalAmt
      wbsID
      wbs {
        ID
        name
        parentName
      }
      wbsBudgetDetail(contractID: $contractID) {
        ID
        quantity
        unitPrice
        trackQty(contractID: $contractID)
        balanceQty(contractID: $contractID)
        balanceQtyPurchasing(contractID: $contractID, poID: $ID)
        reservedAmt(contractID: $contractID)
        utilisedAmt(contractID: $contractID)
        balanceAmt(contractID: $contractID)
      }
      costItem {
        name
        code
        unitPrice
        markupPerc
      }
      taxSchemeID
      taxScheme {
        ID
        code
        isClaimable
        latestTax {
          taxDate
          taxRate
        }
      }
      prItem {
        ID
        markupPerc
        costItem {
          ID
          markupPerc
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetPoForBudgetByOneQuery__
 *
 * To run a query within a React component, call `useGetPoForBudgetByOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoForBudgetByOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoForBudgetByOneQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      purchaseType: // value for 'purchaseType'
 *   },
 * });
 */
export function useGetPoForBudgetByOneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoForBudgetByOneQuery, GetPoForBudgetByOneQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoForBudgetByOneQuery, GetPoForBudgetByOneQueryVariables>(GetPoForBudgetByOneDocument, baseOptions);
      }
export function useGetPoForBudgetByOneLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoForBudgetByOneQuery, GetPoForBudgetByOneQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoForBudgetByOneQuery, GetPoForBudgetByOneQueryVariables>(GetPoForBudgetByOneDocument, baseOptions);
        }
export type GetPoForBudgetByOneQueryHookResult = ReturnType<typeof useGetPoForBudgetByOneQuery>;
export type GetPoForBudgetByOneLazyQueryHookResult = ReturnType<typeof useGetPoForBudgetByOneLazyQuery>;
export type GetPoForBudgetByOneQueryResult = ApolloReactCommon.QueryResult<GetPoForBudgetByOneQuery, GetPoForBudgetByOneQueryVariables>;
export const GetPOsDocument = gql`
    query getPOs($supplierID: String, $siteID: String, $docDate: DateTime, $contractID: String, $IDs: [String!]) {
  getPOs(supplierID: $supplierID, siteID: $siteID, docDate: $docDate, contractID: $contractID, IDs: $IDs)
}
    `;

/**
 * __useGetPOsQuery__
 *
 * To run a query within a React component, call `useGetPOsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPOsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPOsQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      siteID: // value for 'siteID'
 *      docDate: // value for 'docDate'
 *      contractID: // value for 'contractID'
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useGetPOsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPOsQuery, GetPOsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPOsQuery, GetPOsQueryVariables>(GetPOsDocument, baseOptions);
      }
export function useGetPOsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPOsQuery, GetPOsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPOsQuery, GetPOsQueryVariables>(GetPOsDocument, baseOptions);
        }
export type GetPOsQueryHookResult = ReturnType<typeof useGetPOsQuery>;
export type GetPOsLazyQueryHookResult = ReturnType<typeof useGetPOsLazyQuery>;
export type GetPOsQueryResult = ApolloReactCommon.QueryResult<GetPOsQuery, GetPOsQueryVariables>;
export const CreatePoDocument = gql`
    mutation createPO($poInput: [PurchaseOrderInput!]!) {
  createPO(poInput: $poInput)
}
    `;
export type CreatePoMutationFn = ApolloReactCommon.MutationFunction<CreatePoMutation, CreatePoMutationVariables>;

/**
 * __useCreatePoMutation__
 *
 * To run a mutation, you first call `useCreatePoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPoMutation, { data, loading, error }] = useCreatePoMutation({
 *   variables: {
 *      poInput: // value for 'poInput'
 *   },
 * });
 */
export function useCreatePoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePoMutation, CreatePoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePoMutation, CreatePoMutationVariables>(CreatePoDocument, baseOptions);
      }
export type CreatePoMutationHookResult = ReturnType<typeof useCreatePoMutation>;
export type CreatePoMutationResult = ApolloReactCommon.MutationResult<CreatePoMutation>;
export type CreatePoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePoMutation, CreatePoMutationVariables>;
export const CreateDirectPoDocument = gql`
    mutation createDirectPO($input: DirectPOInput!, $poItemInput: [POItemInput!]) {
  createDirectPO(input: $input, poItemInput: $poItemInput)
}
    `;
export type CreateDirectPoMutationFn = ApolloReactCommon.MutationFunction<CreateDirectPoMutation, CreateDirectPoMutationVariables>;

/**
 * __useCreateDirectPoMutation__
 *
 * To run a mutation, you first call `useCreateDirectPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectPoMutation, { data, loading, error }] = useCreateDirectPoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      poItemInput: // value for 'poItemInput'
 *   },
 * });
 */
export function useCreateDirectPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDirectPoMutation, CreateDirectPoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDirectPoMutation, CreateDirectPoMutationVariables>(CreateDirectPoDocument, baseOptions);
      }
export type CreateDirectPoMutationHookResult = ReturnType<typeof useCreateDirectPoMutation>;
export type CreateDirectPoMutationResult = ApolloReactCommon.MutationResult<CreateDirectPoMutation>;
export type CreateDirectPoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDirectPoMutation, CreateDirectPoMutationVariables>;
export const UpdateDirectPoDocument = gql`
    mutation updateDirectPO($ID: String!, $input: DirectPOInput!, $poItemInput: [POItemInput!]) {
  updateDirectPO(ID: $ID, input: $input, poItemInput: $poItemInput)
}
    `;
export type UpdateDirectPoMutationFn = ApolloReactCommon.MutationFunction<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>;

/**
 * __useUpdateDirectPoMutation__
 *
 * To run a mutation, you first call `useUpdateDirectPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirectPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirectPoMutation, { data, loading, error }] = useUpdateDirectPoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *      poItemInput: // value for 'poItemInput'
 *   },
 * });
 */
export function useUpdateDirectPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>(UpdateDirectPoDocument, baseOptions);
      }
export type UpdateDirectPoMutationHookResult = ReturnType<typeof useUpdateDirectPoMutation>;
export type UpdateDirectPoMutationResult = ApolloReactCommon.MutationResult<UpdateDirectPoMutation>;
export type UpdateDirectPoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>;
export const CancelPoDocument = gql`
    mutation cancelPO($input: PurchaseActionInput!, $ID: String!) {
  cancelClosePO(input: $input, ID: $ID)
}
    `;
export type CancelPoMutationFn = ApolloReactCommon.MutationFunction<CancelPoMutation, CancelPoMutationVariables>;

/**
 * __useCancelPoMutation__
 *
 * To run a mutation, you first call `useCancelPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPoMutation, { data, loading, error }] = useCancelPoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelPoMutation, CancelPoMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelPoMutation, CancelPoMutationVariables>(CancelPoDocument, baseOptions);
      }
export type CancelPoMutationHookResult = ReturnType<typeof useCancelPoMutation>;
export type CancelPoMutationResult = ApolloReactCommon.MutationResult<CancelPoMutation>;
export type CancelPoMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelPoMutation, CancelPoMutationVariables>;
export const DeletePoDocument = gql`
    mutation deletePO($ID: String!) {
  deletePO(ID: $ID)
}
    `;
export type DeletePoMutationFn = ApolloReactCommon.MutationFunction<DeletePoMutation, DeletePoMutationVariables>;

/**
 * __useDeletePoMutation__
 *
 * To run a mutation, you first call `useDeletePoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePoMutation, { data, loading, error }] = useDeletePoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeletePoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePoMutation, DeletePoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePoMutation, DeletePoMutationVariables>(DeletePoDocument, baseOptions);
      }
export type DeletePoMutationHookResult = ReturnType<typeof useDeletePoMutation>;
export type DeletePoMutationResult = ApolloReactCommon.MutationResult<DeletePoMutation>;
export type DeletePoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePoMutation, DeletePoMutationVariables>;
export const ClosePoCheckingDocument = gql`
    query closePOChecking($ID: String) {
  closePOChecking(ID: $ID)
}
    `;

/**
 * __useClosePoCheckingQuery__
 *
 * To run a query within a React component, call `useClosePoCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosePoCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosePoCheckingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useClosePoCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClosePoCheckingQuery, ClosePoCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClosePoCheckingQuery, ClosePoCheckingQueryVariables>(ClosePoCheckingDocument, baseOptions);
      }
export function useClosePoCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClosePoCheckingQuery, ClosePoCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClosePoCheckingQuery, ClosePoCheckingQueryVariables>(ClosePoCheckingDocument, baseOptions);
        }
export type ClosePoCheckingQueryHookResult = ReturnType<typeof useClosePoCheckingQuery>;
export type ClosePoCheckingLazyQueryHookResult = ReturnType<typeof useClosePoCheckingLazyQuery>;
export type ClosePoCheckingQueryResult = ApolloReactCommon.QueryResult<ClosePoCheckingQuery, ClosePoCheckingQueryVariables>;
export const GetSupplierByPoStatusDocument = gql`
    query getSupplierByPOStatus($supplierID: String, $contractID: String!, $purchaseStatus: PurchaseStatus!) {
  getSupplierByPOStatus(supplierID: $supplierID, contractID: $contractID, purchaseStatus: $purchaseStatus) {
    ID
    name
    registrationNo
    email
    address
    currencyID
    creditTerm
    description
    platformAccess
    contactNo
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    description
    ratingType
    gstRegNo
    gstExpDate
    sstRegNo
    associatedStatus
    bankDetails
    accountID
    costCategory {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetSupplierByPoStatusQuery__
 *
 * To run a query within a React component, call `useGetSupplierByPoStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierByPoStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierByPoStatusQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      purchaseStatus: // value for 'purchaseStatus'
 *   },
 * });
 */
export function useGetSupplierByPoStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierByPoStatusQuery, GetSupplierByPoStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierByPoStatusQuery, GetSupplierByPoStatusQueryVariables>(GetSupplierByPoStatusDocument, baseOptions);
      }
export function useGetSupplierByPoStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierByPoStatusQuery, GetSupplierByPoStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierByPoStatusQuery, GetSupplierByPoStatusQueryVariables>(GetSupplierByPoStatusDocument, baseOptions);
        }
export type GetSupplierByPoStatusQueryHookResult = ReturnType<typeof useGetSupplierByPoStatusQuery>;
export type GetSupplierByPoStatusLazyQueryHookResult = ReturnType<typeof useGetSupplierByPoStatusLazyQuery>;
export type GetSupplierByPoStatusQueryResult = ApolloReactCommon.QueryResult<GetSupplierByPoStatusQuery, GetSupplierByPoStatusQueryVariables>;
export const GetPoListDocNoDocument = gql`
    query getPOListDocNo($contractID: String, $status: String) {
  getPOList(contractID: $contractID, status: $status)
}
    `;

/**
 * __useGetPoListDocNoQuery__
 *
 * To run a query within a React component, call `useGetPoListDocNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoListDocNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoListDocNoQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetPoListDocNoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoListDocNoQuery, GetPoListDocNoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoListDocNoQuery, GetPoListDocNoQueryVariables>(GetPoListDocNoDocument, baseOptions);
      }
export function useGetPoListDocNoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoListDocNoQuery, GetPoListDocNoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoListDocNoQuery, GetPoListDocNoQueryVariables>(GetPoListDocNoDocument, baseOptions);
        }
export type GetPoListDocNoQueryHookResult = ReturnType<typeof useGetPoListDocNoQuery>;
export type GetPoListDocNoLazyQueryHookResult = ReturnType<typeof useGetPoListDocNoLazyQuery>;
export type GetPoListDocNoQueryResult = ApolloReactCommon.QueryResult<GetPoListDocNoQuery, GetPoListDocNoQueryVariables>;
export const GetPoCategoryDocument = gql`
    query getPOCategory($contractID: String) {
  getPOCategory(contractID: $contractID)
}
    `;

/**
 * __useGetPoCategoryQuery__
 *
 * To run a query within a React component, call `useGetPoCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoCategoryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPoCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoCategoryQuery, GetPoCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoCategoryQuery, GetPoCategoryQueryVariables>(GetPoCategoryDocument, baseOptions);
      }
export function useGetPoCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoCategoryQuery, GetPoCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoCategoryQuery, GetPoCategoryQueryVariables>(GetPoCategoryDocument, baseOptions);
        }
export type GetPoCategoryQueryHookResult = ReturnType<typeof useGetPoCategoryQuery>;
export type GetPoCategoryLazyQueryHookResult = ReturnType<typeof useGetPoCategoryLazyQuery>;
export type GetPoCategoryQueryResult = ApolloReactCommon.QueryResult<GetPoCategoryQuery, GetPoCategoryQueryVariables>;
export const GetPoItemDocument = gql`
    query getPOItem($ID: String) {
  getPOItem(ID: $ID) {
    ID
    orderedQty
    outstandingQty
    costItemID
    costItem {
      ID
      name
      code
      unitPrice
      markupPerc
    }
  }
}
    `;

/**
 * __useGetPoItemQuery__
 *
 * To run a query within a React component, call `useGetPoItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetPoItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoItemQuery, GetPoItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoItemQuery, GetPoItemQueryVariables>(GetPoItemDocument, baseOptions);
      }
export function useGetPoItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoItemQuery, GetPoItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoItemQuery, GetPoItemQueryVariables>(GetPoItemDocument, baseOptions);
        }
export type GetPoItemQueryHookResult = ReturnType<typeof useGetPoItemQuery>;
export type GetPoItemLazyQueryHookResult = ReturnType<typeof useGetPoItemLazyQuery>;
export type GetPoItemQueryResult = ApolloReactCommon.QueryResult<GetPoItemQuery, GetPoItemQueryVariables>;
export const GetPoItemByCategoryDocument = gql`
    query getPOItemByCategory($costCategoryID: String) {
  getPOItemByCategory(costCategoryID: $costCategoryID)
}
    `;

/**
 * __useGetPoItemByCategoryQuery__
 *
 * To run a query within a React component, call `useGetPoItemByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoItemByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoItemByCategoryQuery({
 *   variables: {
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetPoItemByCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoItemByCategoryQuery, GetPoItemByCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoItemByCategoryQuery, GetPoItemByCategoryQueryVariables>(GetPoItemByCategoryDocument, baseOptions);
      }
export function useGetPoItemByCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoItemByCategoryQuery, GetPoItemByCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoItemByCategoryQuery, GetPoItemByCategoryQueryVariables>(GetPoItemByCategoryDocument, baseOptions);
        }
export type GetPoItemByCategoryQueryHookResult = ReturnType<typeof useGetPoItemByCategoryQuery>;
export type GetPoItemByCategoryLazyQueryHookResult = ReturnType<typeof useGetPoItemByCategoryLazyQuery>;
export type GetPoItemByCategoryQueryResult = ApolloReactCommon.QueryResult<GetPoItemByCategoryQuery, GetPoItemByCategoryQueryVariables>;
export const GetPoContractNameDocument = gql`
    query getPOContractName($companyID: String, $contractID: String) {
  getPOContractName(companyID: $companyID, contractID: $contractID)
}
    `;

/**
 * __useGetPoContractNameQuery__
 *
 * To run a query within a React component, call `useGetPoContractNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoContractNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoContractNameQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPoContractNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoContractNameQuery, GetPoContractNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoContractNameQuery, GetPoContractNameQueryVariables>(GetPoContractNameDocument, baseOptions);
      }
export function useGetPoContractNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoContractNameQuery, GetPoContractNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoContractNameQuery, GetPoContractNameQueryVariables>(GetPoContractNameDocument, baseOptions);
        }
export type GetPoContractNameQueryHookResult = ReturnType<typeof useGetPoContractNameQuery>;
export type GetPoContractNameLazyQueryHookResult = ReturnType<typeof useGetPoContractNameLazyQuery>;
export type GetPoContractNameQueryResult = ApolloReactCommon.QueryResult<GetPoContractNameQuery, GetPoContractNameQueryVariables>;
export const CheckingCancelClosePoDocument = gql`
    query checkingCancelClosePO($ID: String!) {
  checkingCancelClosePO(ID: $ID)
}
    `;

/**
 * __useCheckingCancelClosePoQuery__
 *
 * To run a query within a React component, call `useCheckingCancelClosePoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckingCancelClosePoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckingCancelClosePoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCheckingCancelClosePoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>(CheckingCancelClosePoDocument, baseOptions);
      }
export function useCheckingCancelClosePoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>(CheckingCancelClosePoDocument, baseOptions);
        }
export type CheckingCancelClosePoQueryHookResult = ReturnType<typeof useCheckingCancelClosePoQuery>;
export type CheckingCancelClosePoLazyQueryHookResult = ReturnType<typeof useCheckingCancelClosePoLazyQuery>;
export type CheckingCancelClosePoQueryResult = ApolloReactCommon.QueryResult<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>;
export const GetPoNoDocument = gql`
    query getPONo($companyID: String, $contractID: [String!], $supplierID: [String!], $isStatus: Boolean) {
  getPONo(companyID: $companyID, contractID: $contractID, supplierID: $supplierID, isStatus: $isStatus)
}
    `;

/**
 * __useGetPoNoQuery__
 *
 * To run a query within a React component, call `useGetPoNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoNoQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      isStatus: // value for 'isStatus'
 *   },
 * });
 */
export function useGetPoNoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoNoQuery, GetPoNoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoNoQuery, GetPoNoQueryVariables>(GetPoNoDocument, baseOptions);
      }
export function useGetPoNoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoNoQuery, GetPoNoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoNoQuery, GetPoNoQueryVariables>(GetPoNoDocument, baseOptions);
        }
export type GetPoNoQueryHookResult = ReturnType<typeof useGetPoNoQuery>;
export type GetPoNoLazyQueryHookResult = ReturnType<typeof useGetPoNoLazyQuery>;
export type GetPoNoQueryResult = ApolloReactCommon.QueryResult<GetPoNoQuery, GetPoNoQueryVariables>;
export const GetPoCostItemListDocument = gql`
    query getPOCostItemList($companyID: String, $contractID: [String!], $supplierID: [String!]) {
  getPOCostItemList(companyID: $companyID, contractID: $contractID, supplierID: $supplierID)
}
    `;

/**
 * __useGetPoCostItemListQuery__
 *
 * To run a query within a React component, call `useGetPoCostItemListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoCostItemListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoCostItemListQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetPoCostItemListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoCostItemListQuery, GetPoCostItemListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoCostItemListQuery, GetPoCostItemListQueryVariables>(GetPoCostItemListDocument, baseOptions);
      }
export function useGetPoCostItemListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoCostItemListQuery, GetPoCostItemListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoCostItemListQuery, GetPoCostItemListQueryVariables>(GetPoCostItemListDocument, baseOptions);
        }
export type GetPoCostItemListQueryHookResult = ReturnType<typeof useGetPoCostItemListQuery>;
export type GetPoCostItemListLazyQueryHookResult = ReturnType<typeof useGetPoCostItemListLazyQuery>;
export type GetPoCostItemListQueryResult = ApolloReactCommon.QueryResult<GetPoCostItemListQuery, GetPoCostItemListQueryVariables>;
export const GetPoSupplierListDocument = gql`
    query getPOSupplierList($companyID: String, $contractID: [String!]) {
  getPOSupplierList(companyID: $companyID, contractID: $contractID)
}
    `;

/**
 * __useGetPoSupplierListQuery__
 *
 * To run a query within a React component, call `useGetPoSupplierListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoSupplierListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoSupplierListQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPoSupplierListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoSupplierListQuery, GetPoSupplierListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoSupplierListQuery, GetPoSupplierListQueryVariables>(GetPoSupplierListDocument, baseOptions);
      }
export function useGetPoSupplierListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoSupplierListQuery, GetPoSupplierListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoSupplierListQuery, GetPoSupplierListQueryVariables>(GetPoSupplierListDocument, baseOptions);
        }
export type GetPoSupplierListQueryHookResult = ReturnType<typeof useGetPoSupplierListQuery>;
export type GetPoSupplierListLazyQueryHookResult = ReturnType<typeof useGetPoSupplierListLazyQuery>;
export type GetPoSupplierListQueryResult = ApolloReactCommon.QueryResult<GetPoSupplierListQuery, GetPoSupplierListQueryVariables>;
export const GetVerifyNegoSupplyDocument = gql`
    query getVerifyNegoSupply($costItemID: String!, $contractID: String!, $supplierID: String!) {
  verifyNegoSupplyItem(costItemID: $costItemID, contractID: $contractID, supplierID: $supplierID)
}
    `;

/**
 * __useGetVerifyNegoSupplyQuery__
 *
 * To run a query within a React component, call `useGetVerifyNegoSupplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVerifyNegoSupplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVerifyNegoSupplyQuery({
 *   variables: {
 *      costItemID: // value for 'costItemID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetVerifyNegoSupplyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetVerifyNegoSupplyQuery, GetVerifyNegoSupplyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetVerifyNegoSupplyQuery, GetVerifyNegoSupplyQueryVariables>(GetVerifyNegoSupplyDocument, baseOptions);
      }
export function useGetVerifyNegoSupplyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVerifyNegoSupplyQuery, GetVerifyNegoSupplyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetVerifyNegoSupplyQuery, GetVerifyNegoSupplyQueryVariables>(GetVerifyNegoSupplyDocument, baseOptions);
        }
export type GetVerifyNegoSupplyQueryHookResult = ReturnType<typeof useGetVerifyNegoSupplyQuery>;
export type GetVerifyNegoSupplyLazyQueryHookResult = ReturnType<typeof useGetVerifyNegoSupplyLazyQuery>;
export type GetVerifyNegoSupplyQueryResult = ApolloReactCommon.QueryResult<GetVerifyNegoSupplyQuery, GetVerifyNegoSupplyQueryVariables>;
export const GetPurchaseOrderCountDocument = gql`
    query getPurchaseOrderCount($purchaseType: PurchaseType, $contractID: String, $prGenerated: Boolean, $purchaseStatus: PurchaseStatus) {
  getPurchaseOrderCount(purchaseType: $purchaseType, contractID: $contractID, prGenerated: $prGenerated, purchaseStatus: $purchaseStatus)
}
    `;

/**
 * __useGetPurchaseOrderCountQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderCountQuery({
 *   variables: {
 *      purchaseType: // value for 'purchaseType'
 *      contractID: // value for 'contractID'
 *      prGenerated: // value for 'prGenerated'
 *      purchaseStatus: // value for 'purchaseStatus'
 *   },
 * });
 */
export function useGetPurchaseOrderCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseOrderCountQuery, GetPurchaseOrderCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseOrderCountQuery, GetPurchaseOrderCountQueryVariables>(GetPurchaseOrderCountDocument, baseOptions);
      }
export function useGetPurchaseOrderCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseOrderCountQuery, GetPurchaseOrderCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseOrderCountQuery, GetPurchaseOrderCountQueryVariables>(GetPurchaseOrderCountDocument, baseOptions);
        }
export type GetPurchaseOrderCountQueryHookResult = ReturnType<typeof useGetPurchaseOrderCountQuery>;
export type GetPurchaseOrderCountLazyQueryHookResult = ReturnType<typeof useGetPurchaseOrderCountLazyQuery>;
export type GetPurchaseOrderCountQueryResult = ApolloReactCommon.QueryResult<GetPurchaseOrderCountQuery, GetPurchaseOrderCountQueryVariables>;
export const GetPurchaseOrderListingDocument = gql`
    query getPurchaseOrderListing($ID: String, $purchaseType: PurchaseType, $contractID: String, $orderByDesc: String, $prGenerated: Boolean, $purchaseStatus: PurchaseStatus, $skip: Float, $take: Float, $searchValue: String) {
  getPurchaseOrderCount(purchaseType: $purchaseType, contractID: $contractID, prGenerated: $prGenerated, purchaseStatus: $purchaseStatus, searchValue: $searchValue)
  getPurchaseOrder(ID: $ID, purchaseType: $purchaseType, contractID: $contractID, orderByDesc: $orderByDesc, prGenerated: $prGenerated, purchaseStatus: $purchaseStatus, skip: $skip, take: $take, searchValue: $searchValue) {
    ID
    accountID
    createdBy
    createdTs
    purchaseType
    contractID
    docNo
    docRef
    supplierID
    supplier {
      ID
      name
    }
    subcontractID
    subcontract {
      title
      ID
      description
      subcontractorDetail {
        ID
        name
      }
    }
    siteID
    deliveryAddress {
      ID
      name
      address
    }
    docDate
    docDueDate
    expectedDate
    instructions
    submissionComment
    totalAmt
    remarks
    rejectionDate
    cancelCloseDate
    cancelCloseRemark
    purchaseStatus
    prGenerated
    contactPerson {
      name
      contactNo
    }
    poItem {
      ID
      purchaseOrderID
      expectedDate
      prItemID
      prItem {
        ID
        markupPerc
        purchaseReq {
          ID
          docNo
          deliveryAddress {
            ID
            contractID
            name
            address
          }
          contactPerson
        }
        costItem {
          ID
          markupPerc
          name
        }
      }
      taxSchemeID
      taxScheme {
        ID
        code
        isClaimable
        latestTax {
          taxDate
          taxRate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costItemID
      costItem {
        name
        code
        unitPrice
        markupPerc
      }
      uomID
      uom {
        ID
        name
        code
      }
      negotiatedSupplyItemID
      rfqItemID
      taxAmt
      taxRate
      remarks
      markupAmt
      markupPerc
      doItem {
        ID
        acceptedQty
        poItemID
        doItemSubmitted
        upToDateQty
        goodReturnNoteItem {
          ID
          grtnID
          doItemID
          returnedQty
        }
      }
      discountAmt
      totalAmt
      unitPrice
      orderedQty
      outstandingQty
      uomExchange {
        uomid
        uomname
        uomcode
      }
    }
    posConnection {
      DO {
        doNo
        doStatus
      }
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
}
    `;

/**
 * __useGetPurchaseOrderListingQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      purchaseType: // value for 'purchaseType'
 *      contractID: // value for 'contractID'
 *      orderByDesc: // value for 'orderByDesc'
 *      prGenerated: // value for 'prGenerated'
 *      purchaseStatus: // value for 'purchaseStatus'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetPurchaseOrderListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseOrderListingQuery, GetPurchaseOrderListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseOrderListingQuery, GetPurchaseOrderListingQueryVariables>(GetPurchaseOrderListingDocument, baseOptions);
      }
export function useGetPurchaseOrderListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseOrderListingQuery, GetPurchaseOrderListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseOrderListingQuery, GetPurchaseOrderListingQueryVariables>(GetPurchaseOrderListingDocument, baseOptions);
        }
export type GetPurchaseOrderListingQueryHookResult = ReturnType<typeof useGetPurchaseOrderListingQuery>;
export type GetPurchaseOrderListingLazyQueryHookResult = ReturnType<typeof useGetPurchaseOrderListingLazyQuery>;
export type GetPurchaseOrderListingQueryResult = ApolloReactCommon.QueryResult<GetPurchaseOrderListingQuery, GetPurchaseOrderListingQueryVariables>;
export const GetPurchaseReqDocument = gql`
    query getPurchaseReq($ID: String, $contractID: String, $prItemID: String, $orderByDesc: String, $purchaseStatus: PurchaseStatus, $purchaseType: PurchaseType, $take: Float, $skip: Float) {
  getPurchaseReq(ID: $ID, contractID: $contractID, prItemID: $prItemID, purchaseStatus: $purchaseStatus, orderByDesc: $orderByDesc, purchaseType: $purchaseType, take: $take, skip: $skip) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    rejectionDate
    cancelCloseDate
    cancelCloseRemark
    remarks
    contractID
    docNo
    docDate
    subcontractID
    contract {
      ID
      title
      implementWbs
    }
    subcontract {
      ID
      title
      description
      subcontractorDetail {
        ID
        name
      }
    }
    prDate
    expectedDate
    siteID
    deliveryAddress {
      ID
      stateID
      contractID
      name
      address
    }
    contactPerson
    instructions
    submissionComment
    purchaseType
    requestedBy
    purchaseStatus
    prItem {
      ID
      purchaseReqID
      uomID
      uom {
        ID
        name
        code
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costItemID
      costItem {
        name
        ID
        uomID
        markupPerc
        UOM {
          ID
          code
        }
      }
      expectedDate
      remarks
      markupPerc
      outstandingQty
      requestedQty
      wbsBudgetDetail(contractID: $contractID) {
        ID
        quantity
        unitPrice
        trackQty(contractID: $contractID)
        reservedQty(contractID: $contractID)
        utilisedQty(contractID: $contractID)
        balanceQty(contractID: $contractID)
        balanceQtyPurchasing(contractID: $contractID, prID: $ID)
        utilisedQty(contractID: $contractID)
      }
      uomExchange {
        uomid
        uomname
        uomcode
      }
      poItem {
        ID
        prItemID
        doItem {
          ID
          poItemID
          acceptedQty
          doItemAmt
        }
      }
    }
    purchaseRFQItem {
      ID
      uomID
      remarks
      uom {
        ID
        name
        code
      }
      purchaseReqID
      outstandingQty
      requestedQty
      costItemID
      costItem {
        name
        ID
        uomID
        markupPerc
        UOM {
          ID
          code
        }
      }
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
}
    `;

/**
 * __useGetPurchaseReqQuery__
 *
 * To run a query within a React component, call `useGetPurchaseReqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseReqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseReqQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      prItemID: // value for 'prItemID'
 *      orderByDesc: // value for 'orderByDesc'
 *      purchaseStatus: // value for 'purchaseStatus'
 *      purchaseType: // value for 'purchaseType'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetPurchaseReqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseReqQuery, GetPurchaseReqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseReqQuery, GetPurchaseReqQueryVariables>(GetPurchaseReqDocument, baseOptions);
      }
export function useGetPurchaseReqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseReqQuery, GetPurchaseReqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseReqQuery, GetPurchaseReqQueryVariables>(GetPurchaseReqDocument, baseOptions);
        }
export type GetPurchaseReqQueryHookResult = ReturnType<typeof useGetPurchaseReqQuery>;
export type GetPurchaseReqLazyQueryHookResult = ReturnType<typeof useGetPurchaseReqLazyQuery>;
export type GetPurchaseReqQueryResult = ApolloReactCommon.QueryResult<GetPurchaseReqQuery, GetPurchaseReqQueryVariables>;
export const GetPrForBudgetByOneDocument = gql`
    query getPRForBudgetByOne($ID: String!, $contractID: String!, $purchaseType: PurchaseType!) {
  getPRForBudgetByOne(ID: $ID, purchaseType: $purchaseType, contractID: $contractID) {
    ID
    docNo
    docDate
    prDate
    purchaseType
    instructions
    submissionComment
    purchaseType
    requestedBy
    prItem {
      ID
      uomID
      wbsID
      expectedDate
      costItem {
        name
        ID
        uomID
        markupPerc
        UOM {
          ID
          code
        }
      }
      wbs {
        ID
        name
        parentName
      }
      remarks
      markupPerc
      poItem {
        ID
        prItemID
        doItem {
          ID
          poItemID
          acceptedQty
          doItemAmt
        }
      }
      uom {
        ID
        name
        code
      }
      purchaseReqID
      outstandingQty
      requestedQty
      costItemID
      wbsBudgetDetail(contractID: $contractID) {
        ID
        quantity
        unitPrice
        trackQty(contractID: $contractID)
        reservedQty(contractID: $contractID)
        utilisedQty(contractID: $contractID)
        balanceQty(contractID: $contractID)
        balanceQtyPurchasing(contractID: $contractID, prID: $ID)
        utilisedQty(contractID: $contractID)
      }
    }
  }
}
    `;

/**
 * __useGetPrForBudgetByOneQuery__
 *
 * To run a query within a React component, call `useGetPrForBudgetByOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrForBudgetByOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrForBudgetByOneQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      purchaseType: // value for 'purchaseType'
 *   },
 * });
 */
export function useGetPrForBudgetByOneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrForBudgetByOneQuery, GetPrForBudgetByOneQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrForBudgetByOneQuery, GetPrForBudgetByOneQueryVariables>(GetPrForBudgetByOneDocument, baseOptions);
      }
export function useGetPrForBudgetByOneLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrForBudgetByOneQuery, GetPrForBudgetByOneQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrForBudgetByOneQuery, GetPrForBudgetByOneQueryVariables>(GetPrForBudgetByOneDocument, baseOptions);
        }
export type GetPrForBudgetByOneQueryHookResult = ReturnType<typeof useGetPrForBudgetByOneQuery>;
export type GetPrForBudgetByOneLazyQueryHookResult = ReturnType<typeof useGetPrForBudgetByOneLazyQuery>;
export type GetPrForBudgetByOneQueryResult = ApolloReactCommon.QueryResult<GetPrForBudgetByOneQuery, GetPrForBudgetByOneQueryVariables>;
export const CreatePurchaseReqDocument = gql`
    mutation createPurchaseReq($purchaseReqInput: PurchaseReqInput!, $prItemListInput: [PRItemInput!]!) {
  createPurchaseReq(purchaseReqInput: $purchaseReqInput, prItemListInput: $prItemListInput) {
    ID
    docNo
  }
}
    `;
export type CreatePurchaseReqMutationFn = ApolloReactCommon.MutationFunction<CreatePurchaseReqMutation, CreatePurchaseReqMutationVariables>;

/**
 * __useCreatePurchaseReqMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseReqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseReqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseReqMutation, { data, loading, error }] = useCreatePurchaseReqMutation({
 *   variables: {
 *      purchaseReqInput: // value for 'purchaseReqInput'
 *      prItemListInput: // value for 'prItemListInput'
 *   },
 * });
 */
export function useCreatePurchaseReqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePurchaseReqMutation, CreatePurchaseReqMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePurchaseReqMutation, CreatePurchaseReqMutationVariables>(CreatePurchaseReqDocument, baseOptions);
      }
export type CreatePurchaseReqMutationHookResult = ReturnType<typeof useCreatePurchaseReqMutation>;
export type CreatePurchaseReqMutationResult = ApolloReactCommon.MutationResult<CreatePurchaseReqMutation>;
export type CreatePurchaseReqMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePurchaseReqMutation, CreatePurchaseReqMutationVariables>;
export const UpdatePurchaseRequestDocument = gql`
    mutation updatePurchaseRequest($purchaseReqInput: PurchaseReqInput!, $prItemListInput: [PRItemInput!]!, $ID: String!) {
  updatePurchaseRequest(purchaseReqInput: $purchaseReqInput, prItemListInput: $prItemListInput, ID: $ID)
}
    `;
export type UpdatePurchaseRequestMutationFn = ApolloReactCommon.MutationFunction<UpdatePurchaseRequestMutation, UpdatePurchaseRequestMutationVariables>;

/**
 * __useUpdatePurchaseRequestMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseRequestMutation, { data, loading, error }] = useUpdatePurchaseRequestMutation({
 *   variables: {
 *      purchaseReqInput: // value for 'purchaseReqInput'
 *      prItemListInput: // value for 'prItemListInput'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useUpdatePurchaseRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePurchaseRequestMutation, UpdatePurchaseRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePurchaseRequestMutation, UpdatePurchaseRequestMutationVariables>(UpdatePurchaseRequestDocument, baseOptions);
      }
export type UpdatePurchaseRequestMutationHookResult = ReturnType<typeof useUpdatePurchaseRequestMutation>;
export type UpdatePurchaseRequestMutationResult = ApolloReactCommon.MutationResult<UpdatePurchaseRequestMutation>;
export type UpdatePurchaseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePurchaseRequestMutation, UpdatePurchaseRequestMutationVariables>;
export const DeletePrDocument = gql`
    mutation deletePR($ID: String!) {
  deletePR(ID: $ID)
}
    `;
export type DeletePrMutationFn = ApolloReactCommon.MutationFunction<DeletePrMutation, DeletePrMutationVariables>;

/**
 * __useDeletePrMutation__
 *
 * To run a mutation, you first call `useDeletePrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrMutation, { data, loading, error }] = useDeletePrMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeletePrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePrMutation, DeletePrMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePrMutation, DeletePrMutationVariables>(DeletePrDocument, baseOptions);
      }
export type DeletePrMutationHookResult = ReturnType<typeof useDeletePrMutation>;
export type DeletePrMutationResult = ApolloReactCommon.MutationResult<DeletePrMutation>;
export type DeletePrMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePrMutation, DeletePrMutationVariables>;
export const ActionOnPrStatusDocument = gql`
    mutation actionOnPRStatus($input: PurchaseActionInput!, $ID: String!) {
  actionOnPRStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnPrStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnPrStatusMutation, ActionOnPrStatusMutationVariables>;

/**
 * __useActionOnPrStatusMutation__
 *
 * To run a mutation, you first call `useActionOnPrStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnPrStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnPrStatusMutation, { data, loading, error }] = useActionOnPrStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnPrStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnPrStatusMutation, ActionOnPrStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnPrStatusMutation, ActionOnPrStatusMutationVariables>(ActionOnPrStatusDocument, baseOptions);
      }
export type ActionOnPrStatusMutationHookResult = ReturnType<typeof useActionOnPrStatusMutation>;
export type ActionOnPrStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnPrStatusMutation>;
export type ActionOnPrStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnPrStatusMutation, ActionOnPrStatusMutationVariables>;
export const GetPrItemsbyIDsDocument = gql`
    query GetPRItemsbyIDs($IDs: [String!]!, $poID: String) {
  GetPRItemsbyIDs(IDs: $IDs, poID: $poID) {
    prItemID
    costItemID
    costItemName
    uomID
    docDate
    uomCode
    unitPrice
    docNo
    requestedQty
    outstandingQty
    purchaseReqID
    negoSupplyItemID
    costCategoryID
    rfqItemID
    remarks
    prDate
    contactPerson
    expectedDate
    negotiatedItemStatus
    subcontractID
    siteID
    siteName
    wbsID
    address
    taxSchemeID
    markupPerc
    taxCode
    supplier {
      ID
      name
      address
      contactNo
    }
  }
}
    `;

/**
 * __useGetPrItemsbyIDsQuery__
 *
 * To run a query within a React component, call `useGetPrItemsbyIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrItemsbyIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrItemsbyIDsQuery({
 *   variables: {
 *      IDs: // value for 'IDs'
 *      poID: // value for 'poID'
 *   },
 * });
 */
export function useGetPrItemsbyIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrItemsbyIDsQuery, GetPrItemsbyIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrItemsbyIDsQuery, GetPrItemsbyIDsQueryVariables>(GetPrItemsbyIDsDocument, baseOptions);
      }
export function useGetPrItemsbyIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrItemsbyIDsQuery, GetPrItemsbyIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrItemsbyIDsQuery, GetPrItemsbyIDsQueryVariables>(GetPrItemsbyIDsDocument, baseOptions);
        }
export type GetPrItemsbyIDsQueryHookResult = ReturnType<typeof useGetPrItemsbyIDsQuery>;
export type GetPrItemsbyIDsLazyQueryHookResult = ReturnType<typeof useGetPrItemsbyIDsLazyQuery>;
export type GetPrItemsbyIDsQueryResult = ApolloReactCommon.QueryResult<GetPrItemsbyIDsQuery, GetPrItemsbyIDsQueryVariables>;
export const CancelClosePrDocument = gql`
    mutation cancelClosePR($ID: String!, $input: PurchaseActionInput!) {
  cancelClosePR(ID: $ID, input: $input)
}
    `;
export type CancelClosePrMutationFn = ApolloReactCommon.MutationFunction<CancelClosePrMutation, CancelClosePrMutationVariables>;

/**
 * __useCancelClosePrMutation__
 *
 * To run a mutation, you first call `useCancelClosePrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClosePrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClosePrMutation, { data, loading, error }] = useCancelClosePrMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelClosePrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelClosePrMutation, CancelClosePrMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelClosePrMutation, CancelClosePrMutationVariables>(CancelClosePrDocument, baseOptions);
      }
export type CancelClosePrMutationHookResult = ReturnType<typeof useCancelClosePrMutation>;
export type CancelClosePrMutationResult = ApolloReactCommon.MutationResult<CancelClosePrMutation>;
export type CancelClosePrMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelClosePrMutation, CancelClosePrMutationVariables>;
export const GetPurchaseReqWithPrDocument = gql`
    query getPurchaseReqWithPR($contractID: String!) {
  getPurchaseReqWithPR(contractID: $contractID) {
    ID
    docNo
  }
}
    `;

/**
 * __useGetPurchaseReqWithPrQuery__
 *
 * To run a query within a React component, call `useGetPurchaseReqWithPrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseReqWithPrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseReqWithPrQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPurchaseReqWithPrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseReqWithPrQuery, GetPurchaseReqWithPrQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseReqWithPrQuery, GetPurchaseReqWithPrQueryVariables>(GetPurchaseReqWithPrDocument, baseOptions);
      }
export function useGetPurchaseReqWithPrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseReqWithPrQuery, GetPurchaseReqWithPrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseReqWithPrQuery, GetPurchaseReqWithPrQueryVariables>(GetPurchaseReqWithPrDocument, baseOptions);
        }
export type GetPurchaseReqWithPrQueryHookResult = ReturnType<typeof useGetPurchaseReqWithPrQuery>;
export type GetPurchaseReqWithPrLazyQueryHookResult = ReturnType<typeof useGetPurchaseReqWithPrLazyQuery>;
export type GetPurchaseReqWithPrQueryResult = ApolloReactCommon.QueryResult<GetPurchaseReqWithPrQuery, GetPurchaseReqWithPrQueryVariables>;
export const GetPurchaseReqHeaderDocument = gql`
    query getPurchaseReqHeader($ID: String, $contractID: String) {
  getPurchaseReq(ID: $ID, contractID: $contractID) {
    ID
    docNo
  }
}
    `;

/**
 * __useGetPurchaseReqHeaderQuery__
 *
 * To run a query within a React component, call `useGetPurchaseReqHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseReqHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseReqHeaderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPurchaseReqHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseReqHeaderQuery, GetPurchaseReqHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseReqHeaderQuery, GetPurchaseReqHeaderQueryVariables>(GetPurchaseReqHeaderDocument, baseOptions);
      }
export function useGetPurchaseReqHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseReqHeaderQuery, GetPurchaseReqHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseReqHeaderQuery, GetPurchaseReqHeaderQueryVariables>(GetPurchaseReqHeaderDocument, baseOptions);
        }
export type GetPurchaseReqHeaderQueryHookResult = ReturnType<typeof useGetPurchaseReqHeaderQuery>;
export type GetPurchaseReqHeaderLazyQueryHookResult = ReturnType<typeof useGetPurchaseReqHeaderLazyQuery>;
export type GetPurchaseReqHeaderQueryResult = ApolloReactCommon.QueryResult<GetPurchaseReqHeaderQuery, GetPurchaseReqHeaderQueryVariables>;
export const GetOutstandingPrDocument = gql`
    query getOutstandingPR($contractID: String!, $purchaseType: PurchaseType!) {
  getOutstandingPR(contractID: $contractID, purchaseType: $purchaseType) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    rejectionDate
    remarks
    contractID
    docNo
    subcontractID
    subcontract {
      ID
      title
    }
    docDate
    expectedDate
    deliveryAddress {
      ID
      contractID
      name
      address
    }
    contactPerson
    instructions
    purchaseType
    requestedBy
    purchaseStatus
    prItem {
      ID
      purchaseReqID
      uomID
      uom {
        ID
        name
        code
      }
      wbsID
      costItemID
      costItem {
        name
        ID
        uomID
        markupPerc
        UOM {
          ID
          code
        }
      }
      expectedDate
      markupPerc
      requestedQty
      outstandingQty
      remarks
      poItem {
        ID
        prItemID
        doItem {
          ID
          poItemID
          acceptedQty
          doItemAmt
        }
      }
    }
    purchaseRFQItem {
      ID
      uomID
      remarks
      uom {
        ID
        name
        code
      }
      purchaseReqID
      outstandingQty
      requestedQty
      costItemID
      costItem {
        name
        ID
        uomID
        markupPerc
        UOM {
          ID
          code
        }
      }
    }
  }
}
    `;

/**
 * __useGetOutstandingPrQuery__
 *
 * To run a query within a React component, call `useGetOutstandingPrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingPrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingPrQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      purchaseType: // value for 'purchaseType'
 *   },
 * });
 */
export function useGetOutstandingPrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutstandingPrQuery, GetOutstandingPrQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutstandingPrQuery, GetOutstandingPrQueryVariables>(GetOutstandingPrDocument, baseOptions);
      }
export function useGetOutstandingPrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutstandingPrQuery, GetOutstandingPrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutstandingPrQuery, GetOutstandingPrQueryVariables>(GetOutstandingPrDocument, baseOptions);
        }
export type GetOutstandingPrQueryHookResult = ReturnType<typeof useGetOutstandingPrQuery>;
export type GetOutstandingPrLazyQueryHookResult = ReturnType<typeof useGetOutstandingPrLazyQuery>;
export type GetOutstandingPrQueryResult = ApolloReactCommon.QueryResult<GetOutstandingPrQuery, GetOutstandingPrQueryVariables>;
export const GetOutstandingPrItemsDocument = gql`
    query getOutstandingPRItems($contractID: String!, $purchaseType: PurchaseType!, $supplierID: String) {
  getOutstandingPRItems(contractID: $contractID, purchaseType: $purchaseType, supplierID: $supplierID) {
    ID
    uomID
    wbsID
    costItemID
    outstandingQty
    requestedQty
    description
    remarks
    poItem {
      ID
      prItemID
      doItem {
        ID
        poItemID
        acceptedQty
        doItemAmt
      }
    }
    uom {
      ID
      name
      code
    }
    costItem {
      name
      ID
      uomID
      taxSchemeID
      markupPerc
      UOM {
        ID
        code
      }
    }
    negoSupplySelected(contractID: $contractID)
    rfqSubmissionSelected(contractID: $contractID)
    purchaseReqID
    markupPerc
    purchaseReq {
      ID
      contractID
      docNo
      docDate
      expectedDate
      contactPerson
      instructions
      purchaseType
      requestedBy
      purchaseStatus
      subcontractID
      subcontract {
        ID
        subcontractorID
        subcontractorDetail {
          ID
          name
        }
        title
      }
      siteID
      deliveryAddress {
        ID
        contractID
        name
        address
      }
      purchaseRFQItem {
        ID
        uomID
        remarks
        uom {
          ID
          name
          code
        }
        purchaseReqID
        outstandingQty
        requestedQty
        costItemID
        costItem {
          name
          ID
          uomID
          markupPerc
          UOM {
            ID
            code
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOutstandingPrItemsQuery__
 *
 * To run a query within a React component, call `useGetOutstandingPrItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingPrItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingPrItemsQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      purchaseType: // value for 'purchaseType'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetOutstandingPrItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutstandingPrItemsQuery, GetOutstandingPrItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutstandingPrItemsQuery, GetOutstandingPrItemsQueryVariables>(GetOutstandingPrItemsDocument, baseOptions);
      }
export function useGetOutstandingPrItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutstandingPrItemsQuery, GetOutstandingPrItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutstandingPrItemsQuery, GetOutstandingPrItemsQueryVariables>(GetOutstandingPrItemsDocument, baseOptions);
        }
export type GetOutstandingPrItemsQueryHookResult = ReturnType<typeof useGetOutstandingPrItemsQuery>;
export type GetOutstandingPrItemsLazyQueryHookResult = ReturnType<typeof useGetOutstandingPrItemsLazyQuery>;
export type GetOutstandingPrItemsQueryResult = ApolloReactCommon.QueryResult<GetOutstandingPrItemsQuery, GetOutstandingPrItemsQueryVariables>;
export const CheckingCancelClosePrDocument = gql`
    query checkingCancelClosePR($ID: String!) {
  checkingCancelClosePR(ID: $ID)
}
    `;

/**
 * __useCheckingCancelClosePrQuery__
 *
 * To run a query within a React component, call `useCheckingCancelClosePrQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckingCancelClosePrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckingCancelClosePrQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCheckingCancelClosePrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>(CheckingCancelClosePrDocument, baseOptions);
      }
export function useCheckingCancelClosePrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>(CheckingCancelClosePrDocument, baseOptions);
        }
export type CheckingCancelClosePrQueryHookResult = ReturnType<typeof useCheckingCancelClosePrQuery>;
export type CheckingCancelClosePrLazyQueryHookResult = ReturnType<typeof useCheckingCancelClosePrLazyQuery>;
export type CheckingCancelClosePrQueryResult = ApolloReactCommon.QueryResult<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>;
export const GetPurchaseReqCountDocument = gql`
    query getPurchaseReqCount($contractID: String, $purchaseStatus: PurchaseStatus, $purchaseType: PurchaseType) {
  getPurchaseReqCount(contractID: $contractID, purchaseStatus: $purchaseStatus, purchaseType: $purchaseType)
}
    `;

/**
 * __useGetPurchaseReqCountQuery__
 *
 * To run a query within a React component, call `useGetPurchaseReqCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseReqCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseReqCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      purchaseStatus: // value for 'purchaseStatus'
 *      purchaseType: // value for 'purchaseType'
 *   },
 * });
 */
export function useGetPurchaseReqCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseReqCountQuery, GetPurchaseReqCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseReqCountQuery, GetPurchaseReqCountQueryVariables>(GetPurchaseReqCountDocument, baseOptions);
      }
export function useGetPurchaseReqCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseReqCountQuery, GetPurchaseReqCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseReqCountQuery, GetPurchaseReqCountQueryVariables>(GetPurchaseReqCountDocument, baseOptions);
        }
export type GetPurchaseReqCountQueryHookResult = ReturnType<typeof useGetPurchaseReqCountQuery>;
export type GetPurchaseReqCountLazyQueryHookResult = ReturnType<typeof useGetPurchaseReqCountLazyQuery>;
export type GetPurchaseReqCountQueryResult = ApolloReactCommon.QueryResult<GetPurchaseReqCountQuery, GetPurchaseReqCountQueryVariables>;
export const GetPurchaseItemDocument = gql`
    query getPurchaseItem($ID: String, $costCategoryID: String, $commonStatus: CommonStatus, $orderByAsc: String, $orderByDesc: String, $isInventory: Boolean) {
  getCostItem(ID: $ID, costCategoryID: $costCategoryID, commonStatus: $commonStatus, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, isInventory: $isInventory) {
    ID
    name
    markupPerc
    uomID
    UOM {
      ID
      name
      code
    }
  }
}
    `;

/**
 * __useGetPurchaseItemQuery__
 *
 * To run a query within a React component, call `useGetPurchaseItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costCategoryID: // value for 'costCategoryID'
 *      commonStatus: // value for 'commonStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      isInventory: // value for 'isInventory'
 *   },
 * });
 */
export function useGetPurchaseItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseItemQuery, GetPurchaseItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseItemQuery, GetPurchaseItemQueryVariables>(GetPurchaseItemDocument, baseOptions);
      }
export function useGetPurchaseItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseItemQuery, GetPurchaseItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseItemQuery, GetPurchaseItemQueryVariables>(GetPurchaseItemDocument, baseOptions);
        }
export type GetPurchaseItemQueryHookResult = ReturnType<typeof useGetPurchaseItemQuery>;
export type GetPurchaseItemLazyQueryHookResult = ReturnType<typeof useGetPurchaseItemLazyQuery>;
export type GetPurchaseItemQueryResult = ApolloReactCommon.QueryResult<GetPurchaseItemQuery, GetPurchaseItemQueryVariables>;
export const GetPurchaseReqListingDocument = gql`
    query getPurchaseReqListing($ID: String, $contractID: String, $prItemID: String, $orderByDesc: String, $purchaseStatus: PurchaseStatus, $purchaseType: PurchaseType, $take: Float, $skip: Float, $searchValue: String) {
  getPurchaseReqCount(contractID: $contractID, purchaseStatus: $purchaseStatus, purchaseType: $purchaseType, searchValue: $searchValue)
  getPurchaseReq(ID: $ID, contractID: $contractID, prItemID: $prItemID, purchaseStatus: $purchaseStatus, orderByDesc: $orderByDesc, purchaseType: $purchaseType, take: $take, skip: $skip, searchValue: $searchValue) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    rejectionDate
    cancelCloseDate
    cancelCloseRemark
    remarks
    contractID
    docNo
    docDate
    subcontractID
    contract {
      ID
      title
      implementWbs
    }
    subcontract {
      ID
      title
      description
      subcontractorDetail {
        ID
        name
      }
    }
    prDate
    expectedDate
    siteID
    deliveryAddress {
      ID
      stateID
      contractID
      name
      address
    }
    contactPerson
    instructions
    submissionComment
    purchaseType
    requestedBy
    purchaseStatus
    prItem {
      ID
      uomID
      wbsID
      wbs {
        ID
        name
        parentName
      }
      expectedDate
      remarks
      markupPerc
      poItem {
        ID
        prItemID
        doItem {
          ID
          poItemID
          acceptedQty
          doItemAmt
        }
      }
      uom {
        ID
        name
        code
      }
      purchaseReqID
      outstandingQty
      requestedQty
      costItemID
      wbsBudgetDetail(contractID: $contractID) {
        ID
        quantity
        unitPrice
        trackQty(contractID: $contractID)
        reservedQty(contractID: $contractID)
        utilisedQty(contractID: $contractID)
        balanceQty(contractID: $contractID)
        balanceQtyPurchasing(contractID: $contractID, prID: $ID)
        utilisedQty(contractID: $contractID)
      }
      uomExchange {
        uomid
        uomname
        uomcode
      }
      costItem {
        name
        ID
        uomID
        markupPerc
        UOM {
          ID
          code
        }
      }
    }
    purchaseRFQItem {
      ID
      uomID
      remarks
      uom {
        ID
        name
        code
      }
      purchaseReqID
      outstandingQty
      requestedQty
      costItemID
      costItem {
        name
        ID
        uomID
        markupPerc
        UOM {
          ID
          code
        }
      }
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
}
    `;

/**
 * __useGetPurchaseReqListingQuery__
 *
 * To run a query within a React component, call `useGetPurchaseReqListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseReqListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseReqListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      prItemID: // value for 'prItemID'
 *      orderByDesc: // value for 'orderByDesc'
 *      purchaseStatus: // value for 'purchaseStatus'
 *      purchaseType: // value for 'purchaseType'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetPurchaseReqListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseReqListingQuery, GetPurchaseReqListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseReqListingQuery, GetPurchaseReqListingQueryVariables>(GetPurchaseReqListingDocument, baseOptions);
      }
export function useGetPurchaseReqListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseReqListingQuery, GetPurchaseReqListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseReqListingQuery, GetPurchaseReqListingQueryVariables>(GetPurchaseReqListingDocument, baseOptions);
        }
export type GetPurchaseReqListingQueryHookResult = ReturnType<typeof useGetPurchaseReqListingQuery>;
export type GetPurchaseReqListingLazyQueryHookResult = ReturnType<typeof useGetPurchaseReqListingLazyQuery>;
export type GetPurchaseReqListingQueryResult = ApolloReactCommon.QueryResult<GetPurchaseReqListingQuery, GetPurchaseReqListingQueryVariables>;
export const CreateRfqDocument = gql`
    mutation createRFQ($rfqInput: RFQInput!, $rfqItemInput: [RFQItemInput!]!) {
  createRFQ(rfqInput: $rfqInput, rfqItemInput: $rfqItemInput)
}
    `;
export type CreateRfqMutationFn = ApolloReactCommon.MutationFunction<CreateRfqMutation, CreateRfqMutationVariables>;

/**
 * __useCreateRfqMutation__
 *
 * To run a mutation, you first call `useCreateRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRfqMutation, { data, loading, error }] = useCreateRfqMutation({
 *   variables: {
 *      rfqInput: // value for 'rfqInput'
 *      rfqItemInput: // value for 'rfqItemInput'
 *   },
 * });
 */
export function useCreateRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRfqMutation, CreateRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRfqMutation, CreateRfqMutationVariables>(CreateRfqDocument, baseOptions);
      }
export type CreateRfqMutationHookResult = ReturnType<typeof useCreateRfqMutation>;
export type CreateRfqMutationResult = ApolloReactCommon.MutationResult<CreateRfqMutation>;
export type CreateRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRfqMutation, CreateRfqMutationVariables>;
export const UpdateRfqDocument = gql`
    mutation updateRFQ($rfqInput: RFQInput!, $rfqItemInput: [RFQItemInput!]!, $ID: String!) {
  updateRFQ(rfqInput: $rfqInput, rfqItemInput: $rfqItemInput, ID: $ID)
}
    `;
export type UpdateRfqMutationFn = ApolloReactCommon.MutationFunction<UpdateRfqMutation, UpdateRfqMutationVariables>;

/**
 * __useUpdateRfqMutation__
 *
 * To run a mutation, you first call `useUpdateRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfqMutation, { data, loading, error }] = useUpdateRfqMutation({
 *   variables: {
 *      rfqInput: // value for 'rfqInput'
 *      rfqItemInput: // value for 'rfqItemInput'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useUpdateRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRfqMutation, UpdateRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRfqMutation, UpdateRfqMutationVariables>(UpdateRfqDocument, baseOptions);
      }
export type UpdateRfqMutationHookResult = ReturnType<typeof useUpdateRfqMutation>;
export type UpdateRfqMutationResult = ApolloReactCommon.MutationResult<UpdateRfqMutation>;
export type UpdateRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRfqMutation, UpdateRfqMutationVariables>;
export const SubmitRfqQuoteDocument = gql`
    mutation submitRFQQuote($input: RFQSubmissionInput!) {
  submitRFQQuote(input: $input)
}
    `;
export type SubmitRfqQuoteMutationFn = ApolloReactCommon.MutationFunction<SubmitRfqQuoteMutation, SubmitRfqQuoteMutationVariables>;

/**
 * __useSubmitRfqQuoteMutation__
 *
 * To run a mutation, you first call `useSubmitRfqQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRfqQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRfqQuoteMutation, { data, loading, error }] = useSubmitRfqQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitRfqQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitRfqQuoteMutation, SubmitRfqQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitRfqQuoteMutation, SubmitRfqQuoteMutationVariables>(SubmitRfqQuoteDocument, baseOptions);
      }
export type SubmitRfqQuoteMutationHookResult = ReturnType<typeof useSubmitRfqQuoteMutation>;
export type SubmitRfqQuoteMutationResult = ApolloReactCommon.MutationResult<SubmitRfqQuoteMutation>;
export type SubmitRfqQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitRfqQuoteMutation, SubmitRfqQuoteMutationVariables>;
export const GetRfqListingDocument = gql`
    query getRFQListing($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $contractID: String, $rfqStatus: RFQStatus, $searchValue: String) {
  getRFQCount(contractID: $contractID, rfqStatus: $rfqStatus, searchValue: $searchValue)
  getRFQ(contractID: $contractID, rfqStatus: $rfqStatus, searchValue: $searchValue, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take) {
    ID
    createdTs
    createdBy
    accountID
    purchaseReqID
    purchaseReq {
      ID
      docNo
    }
    contractID
    description
    docNo
    docDate
    expectedDate
    rfqStatus
    totalRfqSubmission
    invitedSupplier {
      ID
      supplierID
      rfqID
    }
    supplier {
      ID
      creditTerm
      name
      ratingType
      contactNo
      contactPerson {
        name
        contactNo
        email
        designation
      }
    }
    rfqSupplierSubmission {
      ID
      rfqID
      supplierID
    }
  }
}
    `;

/**
 * __useGetRfqListingQuery__
 *
 * To run a query within a React component, call `useGetRfqListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqListingQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      contractID: // value for 'contractID'
 *      rfqStatus: // value for 'rfqStatus'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetRfqListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqListingQuery, GetRfqListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqListingQuery, GetRfqListingQueryVariables>(GetRfqListingDocument, baseOptions);
      }
export function useGetRfqListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqListingQuery, GetRfqListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqListingQuery, GetRfqListingQueryVariables>(GetRfqListingDocument, baseOptions);
        }
export type GetRfqListingQueryHookResult = ReturnType<typeof useGetRfqListingQuery>;
export type GetRfqListingLazyQueryHookResult = ReturnType<typeof useGetRfqListingLazyQuery>;
export type GetRfqListingQueryResult = ApolloReactCommon.QueryResult<GetRfqListingQuery, GetRfqListingQueryVariables>;
export const GetEvaluationSelectionRfqDocument = gql`
    query getEvaluationSelectionRFQ($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $purchaseReqID: String, $contractID: String, $startDate: String, $endDate: String) {
  getRFQ(orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, ID: $ID, purchaseReqID: $purchaseReqID, contractID: $contractID, startDate: $startDate, endDate: $endDate) {
    ID
    contractID
    description
    docNo
    docDate
    expectedDate
    rfqStatus
    purchaseReqID
    purchaseReq {
      ID
      docNo
    }
    rfqItem {
      ID
      accountID
      rfqID
      prItemID
      quantity
      rfqSubmissionItem {
        ID
        rfqSupplierSubmissionID
        selected
      }
      uomID
      quantity
      uom {
        name
        code
      }
      prItem {
        ID
        description
        remarks
        costItem {
          ID
          name
          latestPurchase
          poItem {
            ID
            purchaseOrder {
              ID
              docDate
            }
          }
        }
      }
    }
    supplier {
      ID
      creditTerm
      name
      ratingType
      contactNo
      contactPerson {
        name
        contactNo
        email
        designation
      }
      email
      latestSubmittedQuotation(rfqID: $ID) {
        ID
        creditTerm
        quotationNo
        docAmt
        submittedDate
        remarks
        rfqSubmissionItem {
          ID
          selected
          rfqItemID
          rfqSupplierSubmissionID
          cheapest(rfqID: $ID)
          unitPrice
          remarks
        }
      }
    }
  }
}
    `;

/**
 * __useGetEvaluationSelectionRfqQuery__
 *
 * To run a query within a React component, call `useGetEvaluationSelectionRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvaluationSelectionRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvaluationSelectionRfqQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      purchaseReqID: // value for 'purchaseReqID'
 *      contractID: // value for 'contractID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetEvaluationSelectionRfqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEvaluationSelectionRfqQuery, GetEvaluationSelectionRfqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEvaluationSelectionRfqQuery, GetEvaluationSelectionRfqQueryVariables>(GetEvaluationSelectionRfqDocument, baseOptions);
      }
export function useGetEvaluationSelectionRfqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEvaluationSelectionRfqQuery, GetEvaluationSelectionRfqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEvaluationSelectionRfqQuery, GetEvaluationSelectionRfqQueryVariables>(GetEvaluationSelectionRfqDocument, baseOptions);
        }
export type GetEvaluationSelectionRfqQueryHookResult = ReturnType<typeof useGetEvaluationSelectionRfqQuery>;
export type GetEvaluationSelectionRfqLazyQueryHookResult = ReturnType<typeof useGetEvaluationSelectionRfqLazyQuery>;
export type GetEvaluationSelectionRfqQueryResult = ApolloReactCommon.QueryResult<GetEvaluationSelectionRfqQuery, GetEvaluationSelectionRfqQueryVariables>;
export const GetRfqDocument = gql`
    query getRFQ($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $purchaseReqID: String, $contractID: String, $startDate: String, $endDate: String) {
  getRFQ(orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, ID: $ID, purchaseReqID: $purchaseReqID, contractID: $contractID, startDate: $startDate, endDate: $endDate) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    accountID
    purchaseReqID
    purchaseReq {
      docNo
      contactPerson
    }
    contractID
    description
    docNo
    docDate
    expectedDate
    rfqStatus
    cancelCloseDate
    cancelCloseRemark
    rfqSupplierSubmission {
      ID
      rfqID
      supplierID
    }
    rfqItem {
      ID
      accountID
      rfqID
      prItemID
      quantity
      rfqSubmissionItem {
        ID
        rfqSupplierSubmissionID
        selected
      }
      prItem {
        ID
        costItemID
        costItem {
          ID
          costCategoryID
          name
        }
        wbsID
        wbs {
          name
          parentName
        }
        expectedDate
        description
        remarks
      }
      uomID
      uom {
        name
        code
      }
      quantity
      poItem {
        ID
        rfqItemID
        purchaseOrderID
        purchaseOrder {
          ID
          docNo
          docDate
        }
      }
    }
    supplier {
      ID
      creditTerm
      name
      ratingType
      contactNo
      contactPerson {
        name
        contactNo
        email
        designation
      }
      email
      latestSubmittedQuotation(rfqID: $ID) {
        ID
        quotationNo
        docAmt
        submittedDate
        remarks
        creditTerm
        rfqSubmissionItem {
          ID
          selected
          rfqItemID
          cheapest(rfqID: $ID)
          unitPrice
          remarks
        }
      }
      rfqSubmission(rfqID: $ID) {
        ID
        docAmt
        quotationNo
        submittedDate
        effectiveDate
        expiryDate
        remarks
        creditTerm
        rfqSubmissionItem {
          ID
          rfqItemID
          unitPrice
          remarks
          rfqSubmission {
            docAmt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRfqQuery__
 *
 * To run a query within a React component, call `useGetRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      purchaseReqID: // value for 'purchaseReqID'
 *      contractID: // value for 'contractID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetRfqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqQuery, GetRfqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqQuery, GetRfqQueryVariables>(GetRfqDocument, baseOptions);
      }
export function useGetRfqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqQuery, GetRfqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqQuery, GetRfqQueryVariables>(GetRfqDocument, baseOptions);
        }
export type GetRfqQueryHookResult = ReturnType<typeof useGetRfqQuery>;
export type GetRfqLazyQueryHookResult = ReturnType<typeof useGetRfqLazyQuery>;
export type GetRfqQueryResult = ApolloReactCommon.QueryResult<GetRfqQuery, GetRfqQueryVariables>;
export const GetRfqItemDocument = gql`
    query getRFQItem($ID: String, $rfqID: String) {
  getRFQItem(ID: $ID, rfqID: $rfqID) {
    ID
    accountID
    rfqID
    uomID
    quantity
    rfq {
      contractID
      description
      docNo
      docDate
      expectedDate
      rfqStatus
    }
  }
}
    `;

/**
 * __useGetRfqItemQuery__
 *
 * To run a query within a React component, call `useGetRfqItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      rfqID: // value for 'rfqID'
 *   },
 * });
 */
export function useGetRfqItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqItemQuery, GetRfqItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqItemQuery, GetRfqItemQueryVariables>(GetRfqItemDocument, baseOptions);
      }
export function useGetRfqItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqItemQuery, GetRfqItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqItemQuery, GetRfqItemQueryVariables>(GetRfqItemDocument, baseOptions);
        }
export type GetRfqItemQueryHookResult = ReturnType<typeof useGetRfqItemQuery>;
export type GetRfqItemLazyQueryHookResult = ReturnType<typeof useGetRfqItemLazyQuery>;
export type GetRfqItemQueryResult = ApolloReactCommon.QueryResult<GetRfqItemQuery, GetRfqItemQueryVariables>;
export const GetRfqSupplierInvitedDocument = gql`
    query getRFQSupplierInvited($ID: String!, $contractID: String!) {
  getRFQ(ID: $ID, contractID: $contractID) {
    ID
    docDate
    docNo
    expectedDate
    description
    rfqItem {
      ID
      prItem {
        ID
        costItem {
          ID
          name
          costCategory {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRfqSupplierInvitedQuery__
 *
 * To run a query within a React component, call `useGetRfqSupplierInvitedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqSupplierInvitedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqSupplierInvitedQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetRfqSupplierInvitedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqSupplierInvitedQuery, GetRfqSupplierInvitedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqSupplierInvitedQuery, GetRfqSupplierInvitedQueryVariables>(GetRfqSupplierInvitedDocument, baseOptions);
      }
export function useGetRfqSupplierInvitedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqSupplierInvitedQuery, GetRfqSupplierInvitedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqSupplierInvitedQuery, GetRfqSupplierInvitedQueryVariables>(GetRfqSupplierInvitedDocument, baseOptions);
        }
export type GetRfqSupplierInvitedQueryHookResult = ReturnType<typeof useGetRfqSupplierInvitedQuery>;
export type GetRfqSupplierInvitedLazyQueryHookResult = ReturnType<typeof useGetRfqSupplierInvitedLazyQuery>;
export type GetRfqSupplierInvitedQueryResult = ApolloReactCommon.QueryResult<GetRfqSupplierInvitedQuery, GetRfqSupplierInvitedQueryVariables>;
export const GetRfqSupplierSubmissionDocument = gql`
    query getRFQSupplierSubmission($ID: String, $supplierID: String, $rfqID: String, $orderByDesc: String) {
  getRFQSupplierSubmission(ID: $ID, supplierID: $supplierID, rfqID: $rfqID, orderByDesc: $orderByDesc) {
    ID
    supplierID
    creditTerm
    submittedDate
    effectiveDate
    expiryDate
    quotationDate
    expectedDate
    quotationNo
    quotationStatus
    docAmt
    rfqID
    remarks
    rfq {
      description
      rfqStatus
    }
    supplier {
      name
      ID
      rfq {
        description
        rfqItem {
          quantity
          prItem {
            costItem {
              name
            }
            uom {
              code
            }
          }
        }
      }
    }
    rfqSubmissionItem {
      ID
      rfqItemID
      unitPrice
      remarks
      rfqItem {
        quantity
        prItem {
          costItem {
            name
          }
          uom {
            code
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRfqSupplierSubmissionQuery__
 *
 * To run a query within a React component, call `useGetRfqSupplierSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqSupplierSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqSupplierSubmissionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      rfqID: // value for 'rfqID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetRfqSupplierSubmissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>(GetRfqSupplierSubmissionDocument, baseOptions);
      }
export function useGetRfqSupplierSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>(GetRfqSupplierSubmissionDocument, baseOptions);
        }
export type GetRfqSupplierSubmissionQueryHookResult = ReturnType<typeof useGetRfqSupplierSubmissionQuery>;
export type GetRfqSupplierSubmissionLazyQueryHookResult = ReturnType<typeof useGetRfqSupplierSubmissionLazyQuery>;
export type GetRfqSupplierSubmissionQueryResult = ApolloReactCommon.QueryResult<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>;
export const DeleteRfqSupplierSubmissionDocument = gql`
    mutation deleteRFQSupplierSubmission($ID: String!) {
  deleteRFQSupplierSubmission(ID: $ID)
}
    `;
export type DeleteRfqSupplierSubmissionMutationFn = ApolloReactCommon.MutationFunction<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>;

/**
 * __useDeleteRfqSupplierSubmissionMutation__
 *
 * To run a mutation, you first call `useDeleteRfqSupplierSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRfqSupplierSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRfqSupplierSubmissionMutation, { data, loading, error }] = useDeleteRfqSupplierSubmissionMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteRfqSupplierSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>(DeleteRfqSupplierSubmissionDocument, baseOptions);
      }
export type DeleteRfqSupplierSubmissionMutationHookResult = ReturnType<typeof useDeleteRfqSupplierSubmissionMutation>;
export type DeleteRfqSupplierSubmissionMutationResult = ApolloReactCommon.MutationResult<DeleteRfqSupplierSubmissionMutation>;
export type DeleteRfqSupplierSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>;
export const ActiveRfqSubmissionDocument = gql`
    mutation activeRfqSubmission($ID: String!) {
  activeRfqSubmission(ID: $ID)
}
    `;
export type ActiveRfqSubmissionMutationFn = ApolloReactCommon.MutationFunction<ActiveRfqSubmissionMutation, ActiveRfqSubmissionMutationVariables>;

/**
 * __useActiveRfqSubmissionMutation__
 *
 * To run a mutation, you first call `useActiveRfqSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveRfqSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeRfqSubmissionMutation, { data, loading, error }] = useActiveRfqSubmissionMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActiveRfqSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActiveRfqSubmissionMutation, ActiveRfqSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<ActiveRfqSubmissionMutation, ActiveRfqSubmissionMutationVariables>(ActiveRfqSubmissionDocument, baseOptions);
      }
export type ActiveRfqSubmissionMutationHookResult = ReturnType<typeof useActiveRfqSubmissionMutation>;
export type ActiveRfqSubmissionMutationResult = ApolloReactCommon.MutationResult<ActiveRfqSubmissionMutation>;
export type ActiveRfqSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<ActiveRfqSubmissionMutation, ActiveRfqSubmissionMutationVariables>;
export const GetPrItemByPridForRfqDocument = gql`
    query getPRItemByPRIDForRFQ($ID: String!, $contractID: String!) {
  getPRItemByPRIDForRFQ(ID: $ID, contractID: $contractID)
}
    `;

/**
 * __useGetPrItemByPridForRfqQuery__
 *
 * To run a query within a React component, call `useGetPrItemByPridForRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrItemByPridForRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrItemByPridForRfqQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPrItemByPridForRfqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>(GetPrItemByPridForRfqDocument, baseOptions);
      }
export function useGetPrItemByPridForRfqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>(GetPrItemByPridForRfqDocument, baseOptions);
        }
export type GetPrItemByPridForRfqQueryHookResult = ReturnType<typeof useGetPrItemByPridForRfqQuery>;
export type GetPrItemByPridForRfqLazyQueryHookResult = ReturnType<typeof useGetPrItemByPridForRfqLazyQuery>;
export type GetPrItemByPridForRfqQueryResult = ApolloReactCommon.QueryResult<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>;
export const GetSuggestedSupplierDocument = gql`
    query getSuggestedSupplier($rfqID: String!) {
  getSuggestedSupplier(rfqID: $rfqID) {
    name
    ID
    ratingType
    costCategory {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetSuggestedSupplierQuery__
 *
 * To run a query within a React component, call `useGetSuggestedSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedSupplierQuery({
 *   variables: {
 *      rfqID: // value for 'rfqID'
 *   },
 * });
 */
export function useGetSuggestedSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSuggestedSupplierQuery, GetSuggestedSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSuggestedSupplierQuery, GetSuggestedSupplierQueryVariables>(GetSuggestedSupplierDocument, baseOptions);
      }
export function useGetSuggestedSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSuggestedSupplierQuery, GetSuggestedSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSuggestedSupplierQuery, GetSuggestedSupplierQueryVariables>(GetSuggestedSupplierDocument, baseOptions);
        }
export type GetSuggestedSupplierQueryHookResult = ReturnType<typeof useGetSuggestedSupplierQuery>;
export type GetSuggestedSupplierLazyQueryHookResult = ReturnType<typeof useGetSuggestedSupplierLazyQuery>;
export type GetSuggestedSupplierQueryResult = ApolloReactCommon.QueryResult<GetSuggestedSupplierQuery, GetSuggestedSupplierQueryVariables>;
export const InviteSupplierDocument = gql`
    mutation inviteSupplier($rfqID: String!, $supplierIDs: [String!]!) {
  inviteSupplier(rfqID: $rfqID, supplierIDs: $supplierIDs)
}
    `;
export type InviteSupplierMutationFn = ApolloReactCommon.MutationFunction<InviteSupplierMutation, InviteSupplierMutationVariables>;

/**
 * __useInviteSupplierMutation__
 *
 * To run a mutation, you first call `useInviteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteSupplierMutation, { data, loading, error }] = useInviteSupplierMutation({
 *   variables: {
 *      rfqID: // value for 'rfqID'
 *      supplierIDs: // value for 'supplierIDs'
 *   },
 * });
 */
export function useInviteSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteSupplierMutation, InviteSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteSupplierMutation, InviteSupplierMutationVariables>(InviteSupplierDocument, baseOptions);
      }
export type InviteSupplierMutationHookResult = ReturnType<typeof useInviteSupplierMutation>;
export type InviteSupplierMutationResult = ApolloReactCommon.MutationResult<InviteSupplierMutation>;
export type InviteSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteSupplierMutation, InviteSupplierMutationVariables>;
export const UninviteSupplierDocument = gql`
    mutation uninviteSupplier($rfqID: String!, $supplierID: String!) {
  uninviteSupplier(rfqID: $rfqID, supplierID: $supplierID)
}
    `;
export type UninviteSupplierMutationFn = ApolloReactCommon.MutationFunction<UninviteSupplierMutation, UninviteSupplierMutationVariables>;

/**
 * __useUninviteSupplierMutation__
 *
 * To run a mutation, you first call `useUninviteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninviteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninviteSupplierMutation, { data, loading, error }] = useUninviteSupplierMutation({
 *   variables: {
 *      rfqID: // value for 'rfqID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useUninviteSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UninviteSupplierMutation, UninviteSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UninviteSupplierMutation, UninviteSupplierMutationVariables>(UninviteSupplierDocument, baseOptions);
      }
export type UninviteSupplierMutationHookResult = ReturnType<typeof useUninviteSupplierMutation>;
export type UninviteSupplierMutationResult = ApolloReactCommon.MutationResult<UninviteSupplierMutation>;
export type UninviteSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UninviteSupplierMutation, UninviteSupplierMutationVariables>;
export const SubmitEvaluationSelectionDocument = gql`
    mutation submitEvaluationSelection($evalSelectInput: [EvalSelectInput!]!, $rfqInput: RFQEvalInput!) {
  submitEvaluationSelection(evalSelectInput: $evalSelectInput, rfqInput: $rfqInput)
}
    `;
export type SubmitEvaluationSelectionMutationFn = ApolloReactCommon.MutationFunction<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>;

/**
 * __useSubmitEvaluationSelectionMutation__
 *
 * To run a mutation, you first call `useSubmitEvaluationSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEvaluationSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEvaluationSelectionMutation, { data, loading, error }] = useSubmitEvaluationSelectionMutation({
 *   variables: {
 *      evalSelectInput: // value for 'evalSelectInput'
 *      rfqInput: // value for 'rfqInput'
 *   },
 * });
 */
export function useSubmitEvaluationSelectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>(SubmitEvaluationSelectionDocument, baseOptions);
      }
export type SubmitEvaluationSelectionMutationHookResult = ReturnType<typeof useSubmitEvaluationSelectionMutation>;
export type SubmitEvaluationSelectionMutationResult = ApolloReactCommon.MutationResult<SubmitEvaluationSelectionMutation>;
export type SubmitEvaluationSelectionMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>;
export const GetInvitedSupplierDocument = gql`
    query getInvitedSupplier($rfqID: String!, $contractID: String!) {
  getInvitedSupplier(rfqID: $rfqID, contractID: $contractID)
}
    `;

/**
 * __useGetInvitedSupplierQuery__
 *
 * To run a query within a React component, call `useGetInvitedSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedSupplierQuery({
 *   variables: {
 *      rfqID: // value for 'rfqID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetInvitedSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>(GetInvitedSupplierDocument, baseOptions);
      }
export function useGetInvitedSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>(GetInvitedSupplierDocument, baseOptions);
        }
export type GetInvitedSupplierQueryHookResult = ReturnType<typeof useGetInvitedSupplierQuery>;
export type GetInvitedSupplierLazyQueryHookResult = ReturnType<typeof useGetInvitedSupplierLazyQuery>;
export type GetInvitedSupplierQueryResult = ApolloReactCommon.QueryResult<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>;
export const RejectRfqDocument = gql`
    mutation rejectRFQ($ID: String!, $rfqRejectInput: RFQRejectInput!) {
  rejectRFQ(ID: $ID, rfqRejectInput: $rfqRejectInput)
}
    `;
export type RejectRfqMutationFn = ApolloReactCommon.MutationFunction<RejectRfqMutation, RejectRfqMutationVariables>;

/**
 * __useRejectRfqMutation__
 *
 * To run a mutation, you first call `useRejectRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectRfqMutation, { data, loading, error }] = useRejectRfqMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      rfqRejectInput: // value for 'rfqRejectInput'
 *   },
 * });
 */
export function useRejectRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectRfqMutation, RejectRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectRfqMutation, RejectRfqMutationVariables>(RejectRfqDocument, baseOptions);
      }
export type RejectRfqMutationHookResult = ReturnType<typeof useRejectRfqMutation>;
export type RejectRfqMutationResult = ApolloReactCommon.MutationResult<RejectRfqMutation>;
export type RejectRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectRfqMutation, RejectRfqMutationVariables>;
export const CancelCloseRfqDocument = gql`
    mutation cancelCloseRFQ($ID: String!, $input: RFQActionInput!) {
  cancelCloseRFQ(ID: $ID, input: $input)
}
    `;
export type CancelCloseRfqMutationFn = ApolloReactCommon.MutationFunction<CancelCloseRfqMutation, CancelCloseRfqMutationVariables>;

/**
 * __useCancelCloseRfqMutation__
 *
 * To run a mutation, you first call `useCancelCloseRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCloseRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCloseRfqMutation, { data, loading, error }] = useCancelCloseRfqMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCloseRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCloseRfqMutation, CancelCloseRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCloseRfqMutation, CancelCloseRfqMutationVariables>(CancelCloseRfqDocument, baseOptions);
      }
export type CancelCloseRfqMutationHookResult = ReturnType<typeof useCancelCloseRfqMutation>;
export type CancelCloseRfqMutationResult = ApolloReactCommon.MutationResult<CancelCloseRfqMutation>;
export type CancelCloseRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCloseRfqMutation, CancelCloseRfqMutationVariables>;
export const DeleteRfqDocument = gql`
    mutation deleteRFQ($ID: String!) {
  deleteRFQ(ID: $ID)
}
    `;
export type DeleteRfqMutationFn = ApolloReactCommon.MutationFunction<DeleteRfqMutation, DeleteRfqMutationVariables>;

/**
 * __useDeleteRfqMutation__
 *
 * To run a mutation, you first call `useDeleteRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRfqMutation, { data, loading, error }] = useDeleteRfqMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRfqMutation, DeleteRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRfqMutation, DeleteRfqMutationVariables>(DeleteRfqDocument, baseOptions);
      }
export type DeleteRfqMutationHookResult = ReturnType<typeof useDeleteRfqMutation>;
export type DeleteRfqMutationResult = ApolloReactCommon.MutationResult<DeleteRfqMutation>;
export type DeleteRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRfqMutation, DeleteRfqMutationVariables>;
export const GetRfqCountDocument = gql`
    query getRFQCount($contractID: String, $rfqStatus: RFQStatus) {
  getRFQCount(contractID: $contractID, rfqStatus: $rfqStatus)
}
    `;

/**
 * __useGetRfqCountQuery__
 *
 * To run a query within a React component, call `useGetRfqCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqCountQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      rfqStatus: // value for 'rfqStatus'
 *   },
 * });
 */
export function useGetRfqCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqCountQuery, GetRfqCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqCountQuery, GetRfqCountQueryVariables>(GetRfqCountDocument, baseOptions);
      }
export function useGetRfqCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqCountQuery, GetRfqCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqCountQuery, GetRfqCountQueryVariables>(GetRfqCountDocument, baseOptions);
        }
export type GetRfqCountQueryHookResult = ReturnType<typeof useGetRfqCountQuery>;
export type GetRfqCountLazyQueryHookResult = ReturnType<typeof useGetRfqCountLazyQuery>;
export type GetRfqCountQueryResult = ApolloReactCommon.QueryResult<GetRfqCountQuery, GetRfqCountQueryVariables>;
export const GetProjectOpportunityDocument = gql`
    query getProjectOpportunity($ID: String, $orderByAsc: String, $orderByDesc: String, $ProjectOpportunityStatus: ProjectOpportunityStatus, $customerID: String) {
  getProjectOpportunity(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, ProjectOpportunityStatus: $ProjectOpportunityStatus, customerID: $customerID) {
    ID
    activityLog
    customerID
    customer {
      name
      email
      contactNo
    }
    title
    description
    location
    personInChargeID
    personInCharge {
      name
      email
      contactNo
    }
    amount
    closingDateTime
    remarks
    status
  }
}
    `;

/**
 * __useGetProjectOpportunityQuery__
 *
 * To run a query within a React component, call `useGetProjectOpportunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectOpportunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectOpportunityQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      ProjectOpportunityStatus: // value for 'ProjectOpportunityStatus'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetProjectOpportunityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectOpportunityQuery, GetProjectOpportunityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectOpportunityQuery, GetProjectOpportunityQueryVariables>(GetProjectOpportunityDocument, baseOptions);
      }
export function useGetProjectOpportunityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectOpportunityQuery, GetProjectOpportunityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectOpportunityQuery, GetProjectOpportunityQueryVariables>(GetProjectOpportunityDocument, baseOptions);
        }
export type GetProjectOpportunityQueryHookResult = ReturnType<typeof useGetProjectOpportunityQuery>;
export type GetProjectOpportunityLazyQueryHookResult = ReturnType<typeof useGetProjectOpportunityLazyQuery>;
export type GetProjectOpportunityQueryResult = ApolloReactCommon.QueryResult<GetProjectOpportunityQuery, GetProjectOpportunityQueryVariables>;
export const CreateProjectOpportunityDocument = gql`
    mutation createProjectOpportunity($input: ProjectOpportunityInput!) {
  createProjectOpportunity(input: $input) {
    ID
  }
}
    `;
export type CreateProjectOpportunityMutationFn = ApolloReactCommon.MutationFunction<CreateProjectOpportunityMutation, CreateProjectOpportunityMutationVariables>;

/**
 * __useCreateProjectOpportunityMutation__
 *
 * To run a mutation, you first call `useCreateProjectOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectOpportunityMutation, { data, loading, error }] = useCreateProjectOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectOpportunityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectOpportunityMutation, CreateProjectOpportunityMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectOpportunityMutation, CreateProjectOpportunityMutationVariables>(CreateProjectOpportunityDocument, baseOptions);
      }
export type CreateProjectOpportunityMutationHookResult = ReturnType<typeof useCreateProjectOpportunityMutation>;
export type CreateProjectOpportunityMutationResult = ApolloReactCommon.MutationResult<CreateProjectOpportunityMutation>;
export type CreateProjectOpportunityMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectOpportunityMutation, CreateProjectOpportunityMutationVariables>;
export const UpdateProjectOpportunityDocument = gql`
    mutation updateProjectOpportunity($input: ProjectOpportunityInput!) {
  updateProjectOpportunity(input: $input) {
    ID
  }
}
    `;
export type UpdateProjectOpportunityMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectOpportunityMutation, UpdateProjectOpportunityMutationVariables>;

/**
 * __useUpdateProjectOpportunityMutation__
 *
 * To run a mutation, you first call `useUpdateProjectOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectOpportunityMutation, { data, loading, error }] = useUpdateProjectOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectOpportunityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectOpportunityMutation, UpdateProjectOpportunityMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectOpportunityMutation, UpdateProjectOpportunityMutationVariables>(UpdateProjectOpportunityDocument, baseOptions);
      }
export type UpdateProjectOpportunityMutationHookResult = ReturnType<typeof useUpdateProjectOpportunityMutation>;
export type UpdateProjectOpportunityMutationResult = ApolloReactCommon.MutationResult<UpdateProjectOpportunityMutation>;
export type UpdateProjectOpportunityMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectOpportunityMutation, UpdateProjectOpportunityMutationVariables>;
export const DeleteProjectOpportunityDocument = gql`
    mutation deleteProjectOpportunity($ID: ProjectOpportunityDeleteInput!) {
  deleteProjectOpportunity(input: $ID)
}
    `;
export type DeleteProjectOpportunityMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectOpportunityMutation, DeleteProjectOpportunityMutationVariables>;

/**
 * __useDeleteProjectOpportunityMutation__
 *
 * To run a mutation, you first call `useDeleteProjectOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectOpportunityMutation, { data, loading, error }] = useDeleteProjectOpportunityMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteProjectOpportunityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectOpportunityMutation, DeleteProjectOpportunityMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectOpportunityMutation, DeleteProjectOpportunityMutationVariables>(DeleteProjectOpportunityDocument, baseOptions);
      }
export type DeleteProjectOpportunityMutationHookResult = ReturnType<typeof useDeleteProjectOpportunityMutation>;
export type DeleteProjectOpportunityMutationResult = ApolloReactCommon.MutationResult<DeleteProjectOpportunityMutation>;
export type DeleteProjectOpportunityMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectOpportunityMutation, DeleteProjectOpportunityMutationVariables>;
export const CreateProjectOpportunityActivityLogsDocument = gql`
    mutation createProjectOpportunityActivityLogs($input: ProjectOpportunityActivityLogInput!, $projectOpportunityID: String!) {
  createProjectOpportunityActivityLogs(input: $input, projectOpportunityID: $projectOpportunityID)
}
    `;
export type CreateProjectOpportunityActivityLogsMutationFn = ApolloReactCommon.MutationFunction<CreateProjectOpportunityActivityLogsMutation, CreateProjectOpportunityActivityLogsMutationVariables>;

/**
 * __useCreateProjectOpportunityActivityLogsMutation__
 *
 * To run a mutation, you first call `useCreateProjectOpportunityActivityLogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectOpportunityActivityLogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectOpportunityActivityLogsMutation, { data, loading, error }] = useCreateProjectOpportunityActivityLogsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectOpportunityID: // value for 'projectOpportunityID'
 *   },
 * });
 */
export function useCreateProjectOpportunityActivityLogsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectOpportunityActivityLogsMutation, CreateProjectOpportunityActivityLogsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectOpportunityActivityLogsMutation, CreateProjectOpportunityActivityLogsMutationVariables>(CreateProjectOpportunityActivityLogsDocument, baseOptions);
      }
export type CreateProjectOpportunityActivityLogsMutationHookResult = ReturnType<typeof useCreateProjectOpportunityActivityLogsMutation>;
export type CreateProjectOpportunityActivityLogsMutationResult = ApolloReactCommon.MutationResult<CreateProjectOpportunityActivityLogsMutation>;
export type CreateProjectOpportunityActivityLogsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectOpportunityActivityLogsMutation, CreateProjectOpportunityActivityLogsMutationVariables>;
export const GetProjectOpportunitySubmissionDocument = gql`
    query getProjectOpportunitySubmission($ID: String, $orderByAsc: String, $orderByDesc: String, $projectOpportunityID: String) {
  getProjectOpportunitySubmission(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, projectOpportunityID: $projectOpportunityID) {
    ID
    projectOpportunityID
    submissionDate
    tenderAmt
    remarks
  }
}
    `;

/**
 * __useGetProjectOpportunitySubmissionQuery__
 *
 * To run a query within a React component, call `useGetProjectOpportunitySubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectOpportunitySubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectOpportunitySubmissionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      projectOpportunityID: // value for 'projectOpportunityID'
 *   },
 * });
 */
export function useGetProjectOpportunitySubmissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectOpportunitySubmissionQuery, GetProjectOpportunitySubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectOpportunitySubmissionQuery, GetProjectOpportunitySubmissionQueryVariables>(GetProjectOpportunitySubmissionDocument, baseOptions);
      }
export function useGetProjectOpportunitySubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectOpportunitySubmissionQuery, GetProjectOpportunitySubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectOpportunitySubmissionQuery, GetProjectOpportunitySubmissionQueryVariables>(GetProjectOpportunitySubmissionDocument, baseOptions);
        }
export type GetProjectOpportunitySubmissionQueryHookResult = ReturnType<typeof useGetProjectOpportunitySubmissionQuery>;
export type GetProjectOpportunitySubmissionLazyQueryHookResult = ReturnType<typeof useGetProjectOpportunitySubmissionLazyQuery>;
export type GetProjectOpportunitySubmissionQueryResult = ApolloReactCommon.QueryResult<GetProjectOpportunitySubmissionQuery, GetProjectOpportunitySubmissionQueryVariables>;
export const CreateProjectOpportunitySubmissionDocument = gql`
    mutation createProjectOpportunitySubmission($input: ProjectOpportunitySubmissionInput!) {
  createProjectOpportunitySubmission(input: $input) {
    ID
    projectOpportunityID
  }
}
    `;
export type CreateProjectOpportunitySubmissionMutationFn = ApolloReactCommon.MutationFunction<CreateProjectOpportunitySubmissionMutation, CreateProjectOpportunitySubmissionMutationVariables>;

/**
 * __useCreateProjectOpportunitySubmissionMutation__
 *
 * To run a mutation, you first call `useCreateProjectOpportunitySubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectOpportunitySubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectOpportunitySubmissionMutation, { data, loading, error }] = useCreateProjectOpportunitySubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectOpportunitySubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectOpportunitySubmissionMutation, CreateProjectOpportunitySubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectOpportunitySubmissionMutation, CreateProjectOpportunitySubmissionMutationVariables>(CreateProjectOpportunitySubmissionDocument, baseOptions);
      }
export type CreateProjectOpportunitySubmissionMutationHookResult = ReturnType<typeof useCreateProjectOpportunitySubmissionMutation>;
export type CreateProjectOpportunitySubmissionMutationResult = ApolloReactCommon.MutationResult<CreateProjectOpportunitySubmissionMutation>;
export type CreateProjectOpportunitySubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectOpportunitySubmissionMutation, CreateProjectOpportunitySubmissionMutationVariables>;
export const UpdateProjectOpportunitySubmissionDocument = gql`
    mutation updateProjectOpportunitySubmission($input: ProjectOpportunitySubmissionInput!) {
  updateProjectOpportunitySubmission(input: $input) {
    ID
    projectOpportunityID
  }
}
    `;
export type UpdateProjectOpportunitySubmissionMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectOpportunitySubmissionMutation, UpdateProjectOpportunitySubmissionMutationVariables>;

/**
 * __useUpdateProjectOpportunitySubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateProjectOpportunitySubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectOpportunitySubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectOpportunitySubmissionMutation, { data, loading, error }] = useUpdateProjectOpportunitySubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectOpportunitySubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectOpportunitySubmissionMutation, UpdateProjectOpportunitySubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectOpportunitySubmissionMutation, UpdateProjectOpportunitySubmissionMutationVariables>(UpdateProjectOpportunitySubmissionDocument, baseOptions);
      }
export type UpdateProjectOpportunitySubmissionMutationHookResult = ReturnType<typeof useUpdateProjectOpportunitySubmissionMutation>;
export type UpdateProjectOpportunitySubmissionMutationResult = ApolloReactCommon.MutationResult<UpdateProjectOpportunitySubmissionMutation>;
export type UpdateProjectOpportunitySubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectOpportunitySubmissionMutation, UpdateProjectOpportunitySubmissionMutationVariables>;
export const DeleteProjectOpportunitySubmissionDocument = gql`
    mutation deleteProjectOpportunitySubmission($ID: ProjectOpportunitySubmissionDeleteInput!) {
  deleteProjectOpportunitySubmission(input: $ID)
}
    `;
export type DeleteProjectOpportunitySubmissionMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectOpportunitySubmissionMutation, DeleteProjectOpportunitySubmissionMutationVariables>;

/**
 * __useDeleteProjectOpportunitySubmissionMutation__
 *
 * To run a mutation, you first call `useDeleteProjectOpportunitySubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectOpportunitySubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectOpportunitySubmissionMutation, { data, loading, error }] = useDeleteProjectOpportunitySubmissionMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteProjectOpportunitySubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectOpportunitySubmissionMutation, DeleteProjectOpportunitySubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectOpportunitySubmissionMutation, DeleteProjectOpportunitySubmissionMutationVariables>(DeleteProjectOpportunitySubmissionDocument, baseOptions);
      }
export type DeleteProjectOpportunitySubmissionMutationHookResult = ReturnType<typeof useDeleteProjectOpportunitySubmissionMutation>;
export type DeleteProjectOpportunitySubmissionMutationResult = ApolloReactCommon.MutationResult<DeleteProjectOpportunitySubmissionMutation>;
export type DeleteProjectOpportunitySubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectOpportunitySubmissionMutation, DeleteProjectOpportunitySubmissionMutationVariables>;
export const GetProjectOpportunityAttachmentDocument = gql`
    query getProjectOpportunityAttachment($ID: String, $orderByAsc: String, $orderByDesc: String, $projectOpportunityID: String) {
  getProjectOpportunityAttachment(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, projectOpportunityID: $projectOpportunityID) {
    ID
    projectOpportunityID
    title
    date
  }
}
    `;

/**
 * __useGetProjectOpportunityAttachmentQuery__
 *
 * To run a query within a React component, call `useGetProjectOpportunityAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectOpportunityAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectOpportunityAttachmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      projectOpportunityID: // value for 'projectOpportunityID'
 *   },
 * });
 */
export function useGetProjectOpportunityAttachmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectOpportunityAttachmentQuery, GetProjectOpportunityAttachmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectOpportunityAttachmentQuery, GetProjectOpportunityAttachmentQueryVariables>(GetProjectOpportunityAttachmentDocument, baseOptions);
      }
export function useGetProjectOpportunityAttachmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectOpportunityAttachmentQuery, GetProjectOpportunityAttachmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectOpportunityAttachmentQuery, GetProjectOpportunityAttachmentQueryVariables>(GetProjectOpportunityAttachmentDocument, baseOptions);
        }
export type GetProjectOpportunityAttachmentQueryHookResult = ReturnType<typeof useGetProjectOpportunityAttachmentQuery>;
export type GetProjectOpportunityAttachmentLazyQueryHookResult = ReturnType<typeof useGetProjectOpportunityAttachmentLazyQuery>;
export type GetProjectOpportunityAttachmentQueryResult = ApolloReactCommon.QueryResult<GetProjectOpportunityAttachmentQuery, GetProjectOpportunityAttachmentQueryVariables>;
export const CreateProjectOpportunityAttachmentDocument = gql`
    mutation createProjectOpportunityAttachment($input: ProjectOpportunityAttachmentInput!) {
  createProjectOpportunityAttachment(input: $input) {
    ID
    projectOpportunityID
  }
}
    `;
export type CreateProjectOpportunityAttachmentMutationFn = ApolloReactCommon.MutationFunction<CreateProjectOpportunityAttachmentMutation, CreateProjectOpportunityAttachmentMutationVariables>;

/**
 * __useCreateProjectOpportunityAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateProjectOpportunityAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectOpportunityAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectOpportunityAttachmentMutation, { data, loading, error }] = useCreateProjectOpportunityAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectOpportunityAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectOpportunityAttachmentMutation, CreateProjectOpportunityAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectOpportunityAttachmentMutation, CreateProjectOpportunityAttachmentMutationVariables>(CreateProjectOpportunityAttachmentDocument, baseOptions);
      }
export type CreateProjectOpportunityAttachmentMutationHookResult = ReturnType<typeof useCreateProjectOpportunityAttachmentMutation>;
export type CreateProjectOpportunityAttachmentMutationResult = ApolloReactCommon.MutationResult<CreateProjectOpportunityAttachmentMutation>;
export type CreateProjectOpportunityAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectOpportunityAttachmentMutation, CreateProjectOpportunityAttachmentMutationVariables>;
export const UpdateProjectOpportunityAttachmentDocument = gql`
    mutation updateProjectOpportunityAttachment($input: ProjectOpportunityAttachmentInput!) {
  updateProjectOpportunityAttachment(input: $input) {
    ID
    projectOpportunityID
  }
}
    `;
export type UpdateProjectOpportunityAttachmentMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectOpportunityAttachmentMutation, UpdateProjectOpportunityAttachmentMutationVariables>;

/**
 * __useUpdateProjectOpportunityAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateProjectOpportunityAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectOpportunityAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectOpportunityAttachmentMutation, { data, loading, error }] = useUpdateProjectOpportunityAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectOpportunityAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectOpportunityAttachmentMutation, UpdateProjectOpportunityAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectOpportunityAttachmentMutation, UpdateProjectOpportunityAttachmentMutationVariables>(UpdateProjectOpportunityAttachmentDocument, baseOptions);
      }
export type UpdateProjectOpportunityAttachmentMutationHookResult = ReturnType<typeof useUpdateProjectOpportunityAttachmentMutation>;
export type UpdateProjectOpportunityAttachmentMutationResult = ApolloReactCommon.MutationResult<UpdateProjectOpportunityAttachmentMutation>;
export type UpdateProjectOpportunityAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectOpportunityAttachmentMutation, UpdateProjectOpportunityAttachmentMutationVariables>;
export const DeleteProjectOpportunityAttachmentDocument = gql`
    mutation deleteProjectOpportunityAttachment($ID: ProjectOpportunityAttachmentDeleteInput!) {
  deleteProjectOpportunityAttachment(input: $ID)
}
    `;
export type DeleteProjectOpportunityAttachmentMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectOpportunityAttachmentMutation, DeleteProjectOpportunityAttachmentMutationVariables>;

/**
 * __useDeleteProjectOpportunityAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteProjectOpportunityAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectOpportunityAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectOpportunityAttachmentMutation, { data, loading, error }] = useDeleteProjectOpportunityAttachmentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteProjectOpportunityAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectOpportunityAttachmentMutation, DeleteProjectOpportunityAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectOpportunityAttachmentMutation, DeleteProjectOpportunityAttachmentMutationVariables>(DeleteProjectOpportunityAttachmentDocument, baseOptions);
      }
export type DeleteProjectOpportunityAttachmentMutationHookResult = ReturnType<typeof useDeleteProjectOpportunityAttachmentMutation>;
export type DeleteProjectOpportunityAttachmentMutationResult = ApolloReactCommon.MutationResult<DeleteProjectOpportunityAttachmentMutation>;
export type DeleteProjectOpportunityAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectOpportunityAttachmentMutation, DeleteProjectOpportunityAttachmentMutationVariables>;
export const GetSubcontractOsDebitDocumentDocument = gql`
    query getSubcontractOSDebitDocument($subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus) {
  getSubcontractClaim(subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    claimDocNo
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
    contractID
    subcontractID
    subcontract {
      ID
      creditTerm
    }
    transactionType
  }
  getSubcontractMiscInvoice(subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
    contractID
    subcontractID
    subcontract {
      ID
      creditTerm
    }
    transactionType
  }
  getSubcontractDN(subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
    contractID
    subcontractID
    subcontract {
      ID
      creditTerm
    }
    transactionType
  }
  getCNtoSubcon(subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
    contractID
    subcontractID
    subcontract {
      ID
      creditTerm
    }
    transactionType
  }
}
    `;

/**
 * __useGetSubcontractOsDebitDocumentQuery__
 *
 * To run a query within a React component, call `useGetSubcontractOsDebitDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractOsDebitDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractOsDebitDocumentQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetSubcontractOsDebitDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractOsDebitDocumentQuery, GetSubcontractOsDebitDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractOsDebitDocumentQuery, GetSubcontractOsDebitDocumentQueryVariables>(GetSubcontractOsDebitDocumentDocument, baseOptions);
      }
export function useGetSubcontractOsDebitDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractOsDebitDocumentQuery, GetSubcontractOsDebitDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractOsDebitDocumentQuery, GetSubcontractOsDebitDocumentQueryVariables>(GetSubcontractOsDebitDocumentDocument, baseOptions);
        }
export type GetSubcontractOsDebitDocumentQueryHookResult = ReturnType<typeof useGetSubcontractOsDebitDocumentQuery>;
export type GetSubcontractOsDebitDocumentLazyQueryHookResult = ReturnType<typeof useGetSubcontractOsDebitDocumentLazyQuery>;
export type GetSubcontractOsDebitDocumentQueryResult = ApolloReactCommon.QueryResult<GetSubcontractOsDebitDocumentQuery, GetSubcontractOsDebitDocumentQueryVariables>;
export const GetSubcontractSubcontractorPdfDocument = gql`
    query getSubcontractSubcontractorPdf($subcontractID: String, $subcontractorID: String) {
  getSubcontract(ID: $subcontractID) {
    ID
    title
    creditTerm
    contact {
      ID
      name
      contactNo
    }
    contractID
    taxSchemeID
    progressRetentionPerc
    multipleTrade
    creditTerm
    startDate
    endDate
    subcontractNo
    subcontractSum
    revisedSubcontractSum
    subcontractVOSum
    maxRetentionSum
    maxRetentionPerc
  }
  getSubcontractor(ID: $subcontractorID) {
    ID
    name
    address
    contactPerson {
      ID
      name
      contactNo
    }
  }
}
    `;

/**
 * __useGetSubcontractSubcontractorPdfQuery__
 *
 * To run a query within a React component, call `useGetSubcontractSubcontractorPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractSubcontractorPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractSubcontractorPdfQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractSubcontractorPdfQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractSubcontractorPdfQuery, GetSubcontractSubcontractorPdfQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractSubcontractorPdfQuery, GetSubcontractSubcontractorPdfQueryVariables>(GetSubcontractSubcontractorPdfDocument, baseOptions);
      }
export function useGetSubcontractSubcontractorPdfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractSubcontractorPdfQuery, GetSubcontractSubcontractorPdfQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractSubcontractorPdfQuery, GetSubcontractSubcontractorPdfQueryVariables>(GetSubcontractSubcontractorPdfDocument, baseOptions);
        }
export type GetSubcontractSubcontractorPdfQueryHookResult = ReturnType<typeof useGetSubcontractSubcontractorPdfQuery>;
export type GetSubcontractSubcontractorPdfLazyQueryHookResult = ReturnType<typeof useGetSubcontractSubcontractorPdfLazyQuery>;
export type GetSubcontractSubcontractorPdfQueryResult = ApolloReactCommon.QueryResult<GetSubcontractSubcontractorPdfQuery, GetSubcontractSubcontractorPdfQueryVariables>;
export const GetDebitAllocationSubconDocument = gql`
    query getDebitAllocationSubcon($subcontractID: String, $creditDebitID: String!, $transactionStatus: TransactionStatus) {
  getSubcontractDN(subcontractID: $subcontractID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getCNtoSubcon(subcontractID: $subcontractID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getSubcontractMiscInvoice(subcontractID: $subcontractID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getSubcontractClaim(subcontractID: $subcontractID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    outstandingCertifiedAmt
    taxRate
    docRef
    docNo
    claimDocNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    nonEditableAllocation(creditDebitID: $creditDebitID)
    description
  }
  cancelledSubconClaim: getSubcontractClaim(subcontractID: $subcontractID, transactionStatus: CANCELLED) {
    ID
    outstandingAmt
    outstandingCertifiedAmt
    taxRate
    docRef
    docNo
    claimDocNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    nonEditableAllocation(creditDebitID: $creditDebitID)
    description
  }
  getSubcontractRefund(subcontractID: $subcontractID) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
}
    `;

/**
 * __useGetDebitAllocationSubconQuery__
 *
 * To run a query within a React component, call `useGetDebitAllocationSubconQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebitAllocationSubconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebitAllocationSubconQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      creditDebitID: // value for 'creditDebitID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetDebitAllocationSubconQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDebitAllocationSubconQuery, GetDebitAllocationSubconQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDebitAllocationSubconQuery, GetDebitAllocationSubconQueryVariables>(GetDebitAllocationSubconDocument, baseOptions);
      }
export function useGetDebitAllocationSubconLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDebitAllocationSubconQuery, GetDebitAllocationSubconQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDebitAllocationSubconQuery, GetDebitAllocationSubconQueryVariables>(GetDebitAllocationSubconDocument, baseOptions);
        }
export type GetDebitAllocationSubconQueryHookResult = ReturnType<typeof useGetDebitAllocationSubconQuery>;
export type GetDebitAllocationSubconLazyQueryHookResult = ReturnType<typeof useGetDebitAllocationSubconLazyQuery>;
export type GetDebitAllocationSubconQueryResult = ApolloReactCommon.QueryResult<GetDebitAllocationSubconQuery, GetDebitAllocationSubconQueryVariables>;
export const GetCreditAllocationSubconDocument = gql`
    query getCreditAllocationSubcon($subcontractID: String, $creditDebitID: String!, $transactionStatus: TransactionStatus, $receiptStatus: ReceiptStatus) {
  getSubcontractCN(subcontractID: $subcontractID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getDNtoSubcon(subcontractID: $subcontractID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getSubcontractAdvance(subcontractID: $subcontractID, receiptStatus: $receiptStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getSubcontractPayment(subcontractID: $subcontractID, receiptStatus: $receiptStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
}
    `;

/**
 * __useGetCreditAllocationSubconQuery__
 *
 * To run a query within a React component, call `useGetCreditAllocationSubconQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditAllocationSubconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditAllocationSubconQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      creditDebitID: // value for 'creditDebitID'
 *      transactionStatus: // value for 'transactionStatus'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetCreditAllocationSubconQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditAllocationSubconQuery, GetCreditAllocationSubconQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditAllocationSubconQuery, GetCreditAllocationSubconQueryVariables>(GetCreditAllocationSubconDocument, baseOptions);
      }
export function useGetCreditAllocationSubconLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditAllocationSubconQuery, GetCreditAllocationSubconQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditAllocationSubconQuery, GetCreditAllocationSubconQueryVariables>(GetCreditAllocationSubconDocument, baseOptions);
        }
export type GetCreditAllocationSubconQueryHookResult = ReturnType<typeof useGetCreditAllocationSubconQuery>;
export type GetCreditAllocationSubconLazyQueryHookResult = ReturnType<typeof useGetCreditAllocationSubconLazyQuery>;
export type GetCreditAllocationSubconQueryResult = ApolloReactCommon.QueryResult<GetCreditAllocationSubconQuery, GetCreditAllocationSubconQueryVariables>;
export const MakeSubconAllocationDocument = gql`
    mutation makeSubconAllocation($ledger: [LedgerInput!]!) {
  makeSubconAllocation(ledger: $ledger)
}
    `;
export type MakeSubconAllocationMutationFn = ApolloReactCommon.MutationFunction<MakeSubconAllocationMutation, MakeSubconAllocationMutationVariables>;

/**
 * __useMakeSubconAllocationMutation__
 *
 * To run a mutation, you first call `useMakeSubconAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSubconAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSubconAllocationMutation, { data, loading, error }] = useMakeSubconAllocationMutation({
 *   variables: {
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeSubconAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeSubconAllocationMutation, MakeSubconAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeSubconAllocationMutation, MakeSubconAllocationMutationVariables>(MakeSubconAllocationDocument, baseOptions);
      }
export type MakeSubconAllocationMutationHookResult = ReturnType<typeof useMakeSubconAllocationMutation>;
export type MakeSubconAllocationMutationResult = ApolloReactCommon.MutationResult<MakeSubconAllocationMutation>;
export type MakeSubconAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeSubconAllocationMutation, MakeSubconAllocationMutationVariables>;
export const ResetSubcontractAllocationDocument = gql`
    mutation resetSubcontractAllocation($creditID: String, $debitID: String) {
  resetSubcontractAllocation(creditID: $creditID, debitID: $debitID)
}
    `;
export type ResetSubcontractAllocationMutationFn = ApolloReactCommon.MutationFunction<ResetSubcontractAllocationMutation, ResetSubcontractAllocationMutationVariables>;

/**
 * __useResetSubcontractAllocationMutation__
 *
 * To run a mutation, you first call `useResetSubcontractAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetSubcontractAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetSubcontractAllocationMutation, { data, loading, error }] = useResetSubcontractAllocationMutation({
 *   variables: {
 *      creditID: // value for 'creditID'
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useResetSubcontractAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetSubcontractAllocationMutation, ResetSubcontractAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetSubcontractAllocationMutation, ResetSubcontractAllocationMutationVariables>(ResetSubcontractAllocationDocument, baseOptions);
      }
export type ResetSubcontractAllocationMutationHookResult = ReturnType<typeof useResetSubcontractAllocationMutation>;
export type ResetSubcontractAllocationMutationResult = ApolloReactCommon.MutationResult<ResetSubcontractAllocationMutation>;
export type ResetSubcontractAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetSubcontractAllocationMutation, ResetSubcontractAllocationMutationVariables>;
export const GetSubconAdvanceAllocationDocument = gql`
    query getSubconAdvanceAllocation($debitID: String!) {
  getSubconAdvanceAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetSubconAdvanceAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconAdvanceAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconAdvanceAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconAdvanceAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetSubconAdvanceAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconAdvanceAllocationQuery, GetSubconAdvanceAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconAdvanceAllocationQuery, GetSubconAdvanceAllocationQueryVariables>(GetSubconAdvanceAllocationDocument, baseOptions);
      }
export function useGetSubconAdvanceAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconAdvanceAllocationQuery, GetSubconAdvanceAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconAdvanceAllocationQuery, GetSubconAdvanceAllocationQueryVariables>(GetSubconAdvanceAllocationDocument, baseOptions);
        }
export type GetSubconAdvanceAllocationQueryHookResult = ReturnType<typeof useGetSubconAdvanceAllocationQuery>;
export type GetSubconAdvanceAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconAdvanceAllocationLazyQuery>;
export type GetSubconAdvanceAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconAdvanceAllocationQuery, GetSubconAdvanceAllocationQueryVariables>;
export const GetSubconPaymentAllocationDocument = gql`
    query getSubconPaymentAllocation($debitID: String, $subcontractID: String, $receiptStatus: String) {
  getSubconPaymentAllocation(debitID: $debitID, subcontractID: $subcontractID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetSubconPaymentAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconPaymentAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconPaymentAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconPaymentAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *      subcontractID: // value for 'subcontractID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetSubconPaymentAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconPaymentAllocationQuery, GetSubconPaymentAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconPaymentAllocationQuery, GetSubconPaymentAllocationQueryVariables>(GetSubconPaymentAllocationDocument, baseOptions);
      }
export function useGetSubconPaymentAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconPaymentAllocationQuery, GetSubconPaymentAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconPaymentAllocationQuery, GetSubconPaymentAllocationQueryVariables>(GetSubconPaymentAllocationDocument, baseOptions);
        }
export type GetSubconPaymentAllocationQueryHookResult = ReturnType<typeof useGetSubconPaymentAllocationQuery>;
export type GetSubconPaymentAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconPaymentAllocationLazyQuery>;
export type GetSubconPaymentAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconPaymentAllocationQuery, GetSubconPaymentAllocationQueryVariables>;
export const GetSubconRefundAllocationDocument = gql`
    query getSubconRefundAllocation($creditID: String!) {
  getSubconRefundAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetSubconRefundAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconRefundAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconRefundAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconRefundAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetSubconRefundAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconRefundAllocationQuery, GetSubconRefundAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconRefundAllocationQuery, GetSubconRefundAllocationQueryVariables>(GetSubconRefundAllocationDocument, baseOptions);
      }
export function useGetSubconRefundAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconRefundAllocationQuery, GetSubconRefundAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconRefundAllocationQuery, GetSubconRefundAllocationQueryVariables>(GetSubconRefundAllocationDocument, baseOptions);
        }
export type GetSubconRefundAllocationQueryHookResult = ReturnType<typeof useGetSubconRefundAllocationQuery>;
export type GetSubconRefundAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconRefundAllocationLazyQuery>;
export type GetSubconRefundAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconRefundAllocationQuery, GetSubconRefundAllocationQueryVariables>;
export const GetSubconCnAllocationDocument = gql`
    query getSubconCNAllocation($debitID: String!) {
  getSubconCNAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetSubconCnAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconCnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconCnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconCnAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetSubconCnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconCnAllocationQuery, GetSubconCnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconCnAllocationQuery, GetSubconCnAllocationQueryVariables>(GetSubconCnAllocationDocument, baseOptions);
      }
export function useGetSubconCnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconCnAllocationQuery, GetSubconCnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconCnAllocationQuery, GetSubconCnAllocationQueryVariables>(GetSubconCnAllocationDocument, baseOptions);
        }
export type GetSubconCnAllocationQueryHookResult = ReturnType<typeof useGetSubconCnAllocationQuery>;
export type GetSubconCnAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconCnAllocationLazyQuery>;
export type GetSubconCnAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconCnAllocationQuery, GetSubconCnAllocationQueryVariables>;
export const GetSubconDnAllocationDocument = gql`
    query getSubconDNAllocation($creditID: String!) {
  getSubconDNAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetSubconDnAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconDnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconDnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconDnAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetSubconDnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconDnAllocationQuery, GetSubconDnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconDnAllocationQuery, GetSubconDnAllocationQueryVariables>(GetSubconDnAllocationDocument, baseOptions);
      }
export function useGetSubconDnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconDnAllocationQuery, GetSubconDnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconDnAllocationQuery, GetSubconDnAllocationQueryVariables>(GetSubconDnAllocationDocument, baseOptions);
        }
export type GetSubconDnAllocationQueryHookResult = ReturnType<typeof useGetSubconDnAllocationQuery>;
export type GetSubconDnAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconDnAllocationLazyQuery>;
export type GetSubconDnAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconDnAllocationQuery, GetSubconDnAllocationQueryVariables>;
export const GetDNtoSubconAllocationDocument = gql`
    query getDNtoSubconAllocation($debitID: String!) {
  getDNtoSubconAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetDNtoSubconAllocationQuery__
 *
 * To run a query within a React component, call `useGetDNtoSubconAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSubconAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSubconAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetDNtoSubconAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSubconAllocationQuery, GetDNtoSubconAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSubconAllocationQuery, GetDNtoSubconAllocationQueryVariables>(GetDNtoSubconAllocationDocument, baseOptions);
      }
export function useGetDNtoSubconAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSubconAllocationQuery, GetDNtoSubconAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSubconAllocationQuery, GetDNtoSubconAllocationQueryVariables>(GetDNtoSubconAllocationDocument, baseOptions);
        }
export type GetDNtoSubconAllocationQueryHookResult = ReturnType<typeof useGetDNtoSubconAllocationQuery>;
export type GetDNtoSubconAllocationLazyQueryHookResult = ReturnType<typeof useGetDNtoSubconAllocationLazyQuery>;
export type GetDNtoSubconAllocationQueryResult = ApolloReactCommon.QueryResult<GetDNtoSubconAllocationQuery, GetDNtoSubconAllocationQueryVariables>;
export const GetCNtoSubconAllocationDocument = gql`
    query getCNtoSubconAllocation($creditID: String!) {
  getCNtoSubconAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetCNtoSubconAllocationQuery__
 *
 * To run a query within a React component, call `useGetCNtoSubconAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSubconAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSubconAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetCNtoSubconAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSubconAllocationQuery, GetCNtoSubconAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSubconAllocationQuery, GetCNtoSubconAllocationQueryVariables>(GetCNtoSubconAllocationDocument, baseOptions);
      }
export function useGetCNtoSubconAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSubconAllocationQuery, GetCNtoSubconAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSubconAllocationQuery, GetCNtoSubconAllocationQueryVariables>(GetCNtoSubconAllocationDocument, baseOptions);
        }
export type GetCNtoSubconAllocationQueryHookResult = ReturnType<typeof useGetCNtoSubconAllocationQuery>;
export type GetCNtoSubconAllocationLazyQueryHookResult = ReturnType<typeof useGetCNtoSubconAllocationLazyQuery>;
export type GetCNtoSubconAllocationQueryResult = ApolloReactCommon.QueryResult<GetCNtoSubconAllocationQuery, GetCNtoSubconAllocationQueryVariables>;
export const GetSubcontractClaimDocument = gql`
    query getSubcontractClaim($ID: String, $subcontractID: String, $subcontractorID: String, $companyID: String) {
  getSubcontractClaim(ID: $ID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, companyID: $companyID) {
    ID
    createdTs
    createdBy
    docNo
    modTs
    modBy
    claimDocNo
    isRetention
    docRef
    description
    docDate
    docDueDate
    docAmt
    docRefID
    docRefTable
    baseAmt
    taxSchemeID
    taxScheme {
      ID
      code
      description
    }
    taxRate
    taxAmt
    currencyID
    exchangeRate
    grossAmt
    localBaseAmt
    localTaxAmt
    accountID
    submittedDate
    previousCertifiedAmt
    submittedCertifiedAmt
    currentCertifiedAmt
    previousVOClaimAmt
    submittedVOClaimAmt
    currentVOClaimAmt
    previousMosAmt
    submittedMosAmt
    currentMosAmt
    retentionAmt
    submittedRetentionAmt
    balanceRetentionAmt
    submittedDayWorkAmt
    currentDayWorkAmt
    balanceAdvanceAmt
    currentAdvRecoupmentAmt
    currentDeductionAmt
    submittedDeductionAmt
    balanceDeductionAmt
    transactionStatus
    contractID
    contract {
      ID
      title
      contractNo
    }
    subcontractorID
    subcontractor {
      ID
      name
    }
    subcontractID
    subcontract {
      ID
      title
      subcontractNo
      startDate
      endDate
      subcontractNo
      subcontractSum
      revisedSubcontractSum
      subcontractVOSum
      maxRetentionSum
      maxRetentionPerc
    }
    rejectionDate
    rejectionRemarks
    cancellationDate
    cancellationRemarks
    voDisplayAmt
    outstandingAmt
    modBy
    createdBy
    adjustmentInfo
    subcontractClaimAllocation {
      ID
      subcontractClaimID
      previousCertifiedAmt
      submittedCertifiedAmt
      currentCertifiedAmt
      subcontractCostWbsID
      subcontractCostWbs {
        ID
        subcontractID
        costCategoryID
        costCategory {
          ID
          name
        }
        tradeAmt
        previousAmt
        subcontractVOCostWbs {
          subcontractVOID
          subcontractVO {
            docRef
            submissionAmt
            approvedAmt
          }
          previousTradeVoAmt
          submissionAmt
          approvedAmt
        }
      }
    }
    subcontractClaimVOAllocation {
      ID
      subcontractClaimID
      previousVOClaimAmt
      currentVOClaimAmt
      submittedVOClaimAmt
      subcontractVOID
      subcontractVO {
        ID
        docNo
        docRef
      }
    }
    subcontractClaimVOCostWbsAllocation {
      ID
      previousVOClaimAmt
      submittedVOClaimAmt
      currentVOClaimAmt
      subcontractVOCostWbsID
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  subconClaimsInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractClaimQuery__
 *
 * To run a query within a React component, call `useGetSubcontractClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractClaimQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetSubcontractClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractClaimQuery, GetSubcontractClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractClaimQuery, GetSubcontractClaimQueryVariables>(GetSubcontractClaimDocument, baseOptions);
      }
export function useGetSubcontractClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractClaimQuery, GetSubcontractClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractClaimQuery, GetSubcontractClaimQueryVariables>(GetSubcontractClaimDocument, baseOptions);
        }
export type GetSubcontractClaimQueryHookResult = ReturnType<typeof useGetSubcontractClaimQuery>;
export type GetSubcontractClaimLazyQueryHookResult = ReturnType<typeof useGetSubcontractClaimLazyQuery>;
export type GetSubcontractClaimQueryResult = ApolloReactCommon.QueryResult<GetSubcontractClaimQuery, GetSubcontractClaimQueryVariables>;
export const GetSubcontractClaimPdfDocument = gql`
    query getSubcontractClaimPdf($ID: String, $subcontractID: String, $subcontractorID: String, $companyID: String) {
  getSubcontractClaim(ID: $ID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, companyID: $companyID) {
    ID
    createdTs
    createdBy
    docNo
    modTs
    modBy
    claimDocNo
    isRetention
    docRef
    description
    docDate
    docDueDate
    docAmt
    docRefID
    docRefTable
    baseAmt
    taxSchemeID
    taxScheme {
      ID
      code
      description
    }
    taxRate
    taxAmt
    currencyID
    exchangeRate
    grossAmt
    localBaseAmt
    localTaxAmt
    accountID
    submittedDate
    previousCertifiedAmt
    submittedCertifiedAmt
    currentCertifiedAmt
    previousVOClaimAmt
    submittedVOClaimAmt
    currentVOClaimAmt
    previousMosAmt
    submittedMosAmt
    currentMosAmt
    retentionAmt
    submittedRetentionAmt
    balanceRetentionAmt
    submittedDayWorkAmt
    currentDayWorkAmt
    balanceAdvanceAmt
    currentAdvRecoupmentAmt
    currentDeductionAmt
    submittedDeductionAmt
    balanceDeductionAmt
    transactionStatus
    contractID
    contract {
      ID
      title
      contractNo
    }
    subcontractorID
    subcontractor {
      ID
      name
    }
    subcontractID
    subcontract {
      ID
      title
      subcontractNo
      startDate
      endDate
      subcontractNo
      subcontractSum
      revisedSubcontractSum
      subcontractVOSum
      maxRetentionSum
      maxRetentionPerc
    }
    claimDetailCostWBS
    rejectionDate
    rejectionRemarks
    cancellationDate
    cancellationRemarks
    voDisplayAmt
    outstandingAmt
    modBy
    createdBy
    adjustmentInfo
    subcontractClaimAllocation {
      ID
      subcontractClaimID
      previousCertifiedAmt
      submittedCertifiedAmt
      currentCertifiedAmt
      subcontractCostWbsID
      subcontractCostWbs {
        ID
        subcontractID
        costCategoryID
        costCategory {
          ID
          name
        }
        tradeAmt
        previousAmt
        subcontractVOCostWbs {
          subcontractVOID
          subcontractVO {
            docRef
            submissionAmt
            approvedAmt
          }
          previousTradeVoAmt
          submissionAmt
          approvedAmt
        }
      }
    }
    subcontractClaimVOAllocation {
      ID
      subcontractClaimID
      previousVOClaimAmt
      currentVOClaimAmt
      submittedVOClaimAmt
      subcontractVOID
      subcontractVO {
        ID
        docNo
        docRef
      }
    }
    subcontractClaimVOCostWbsAllocation {
      ID
      previousVOClaimAmt
      submittedVOClaimAmt
      currentVOClaimAmt
      subcontractVOCostWbsID
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
  }
}
    `;

/**
 * __useGetSubcontractClaimPdfQuery__
 *
 * To run a query within a React component, call `useGetSubcontractClaimPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractClaimPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractClaimPdfQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetSubcontractClaimPdfQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractClaimPdfQuery, GetSubcontractClaimPdfQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractClaimPdfQuery, GetSubcontractClaimPdfQueryVariables>(GetSubcontractClaimPdfDocument, baseOptions);
      }
export function useGetSubcontractClaimPdfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractClaimPdfQuery, GetSubcontractClaimPdfQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractClaimPdfQuery, GetSubcontractClaimPdfQueryVariables>(GetSubcontractClaimPdfDocument, baseOptions);
        }
export type GetSubcontractClaimPdfQueryHookResult = ReturnType<typeof useGetSubcontractClaimPdfQuery>;
export type GetSubcontractClaimPdfLazyQueryHookResult = ReturnType<typeof useGetSubcontractClaimPdfLazyQuery>;
export type GetSubcontractClaimPdfQueryResult = ApolloReactCommon.QueryResult<GetSubcontractClaimPdfQuery, GetSubcontractClaimPdfQueryVariables>;
export const GetSubconProgressClaimDocument = gql`
    query getSubconProgressClaim($subcontractID: String, $subcontractorID: String, $companyID: String, $transactionStatus: TransactionStatus, $limit: Float, $offSet: Float, $searchValue: String, $orderByDesc: String) {
  getSubconProgressClaim(subcontractID: $subcontractID, subcontractorID: $subcontractorID, companyID: $companyID, transactionStatus: $transactionStatus, limit: $limit, offSet: $offSet, searchValue: $searchValue, orderByDesc: $orderByDesc) {
    ID
    createdTs
    createdBy
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    contractID
    contract {
      ID
      title
      contractNo
    }
    subcontractorID
    subcontractor {
      ID
      name
      address
      contactNo
    }
    subcontractID
    subcontract {
      ID
      title
      subcontractNo
      startDate
      endDate
      subcontractSum
      revisedSubcontractSum
      subcontractVOSum
      maxRetentionSum
      progressRetentionPerc
      creditTerm
    }
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    grossAmt
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    transactionDate
    transactionStatus
    voDisplayAmt
    subcontractClaimAllocation {
      ID
      subcontractClaimID
      previousCertifiedAmt
      submittedCertifiedAmt
      currentCertifiedAmt
      subcontractCostWbsID
    }
    subcontractClaimVOAllocation {
      ID
      subcontractClaimID
      previousVOClaimAmt
      currentVOClaimAmt
      submittedVOClaimAmt
      subcontractVOID
    }
    subcontractClaimVOCostWbsAllocation {
      ID
      previousVOClaimAmt
      submittedVOClaimAmt
      currentVOClaimAmt
      subcontractVOCostWbsID
    }
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
  }
  subconProgressClaimsInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  getSubconProgressClaimCount: getSubcontractClaimCount(subcontractID: $subcontractID, subcontractorID: $subcontractorID, companyID: $companyID, transactionStatus: $transactionStatus, isRetention: false)
}
    `;

/**
 * __useGetSubconProgressClaimQuery__
 *
 * To run a query within a React component, call `useGetSubconProgressClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconProgressClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconProgressClaimQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      limit: // value for 'limit'
 *      offSet: // value for 'offSet'
 *      searchValue: // value for 'searchValue'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetSubconProgressClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconProgressClaimQuery, GetSubconProgressClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconProgressClaimQuery, GetSubconProgressClaimQueryVariables>(GetSubconProgressClaimDocument, baseOptions);
      }
export function useGetSubconProgressClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconProgressClaimQuery, GetSubconProgressClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconProgressClaimQuery, GetSubconProgressClaimQueryVariables>(GetSubconProgressClaimDocument, baseOptions);
        }
export type GetSubconProgressClaimQueryHookResult = ReturnType<typeof useGetSubconProgressClaimQuery>;
export type GetSubconProgressClaimLazyQueryHookResult = ReturnType<typeof useGetSubconProgressClaimLazyQuery>;
export type GetSubconProgressClaimQueryResult = ApolloReactCommon.QueryResult<GetSubconProgressClaimQuery, GetSubconProgressClaimQueryVariables>;
export const GetSubconRetentionClaimDocument = gql`
    query getSubconRetentionClaim($subcontractID: String, $subcontractorID: String, $companyID: String, $transactionStatus: TransactionStatus, $limit: Float, $offSet: Float, $searchValue: String, $orderByDesc: String) {
  getSubconRetentionClaim(subcontractID: $subcontractID, subcontractorID: $subcontractorID, companyID: $companyID, transactionStatus: $transactionStatus, limit: $limit, offSet: $offSet, searchValue: $searchValue, orderByDesc: $orderByDesc) {
    ID
    createdTs
    createdBy
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    contractID
    contract {
      ID
      title
    }
    subcontractorID
    subcontractor {
      ID
      name
    }
    subcontractID
    subcontract {
      ID
      title
    }
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  subconRetentionClaimsInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  getSubconRetentionClaimCount: getSubcontractClaimCount(subcontractID: $subcontractID, subcontractorID: $subcontractorID, companyID: $companyID, transactionStatus: $transactionStatus, isRetention: true)
}
    `;

/**
 * __useGetSubconRetentionClaimQuery__
 *
 * To run a query within a React component, call `useGetSubconRetentionClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconRetentionClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconRetentionClaimQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      limit: // value for 'limit'
 *      offSet: // value for 'offSet'
 *      searchValue: // value for 'searchValue'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetSubconRetentionClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconRetentionClaimQuery, GetSubconRetentionClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconRetentionClaimQuery, GetSubconRetentionClaimQueryVariables>(GetSubconRetentionClaimDocument, baseOptions);
      }
export function useGetSubconRetentionClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconRetentionClaimQuery, GetSubconRetentionClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconRetentionClaimQuery, GetSubconRetentionClaimQueryVariables>(GetSubconRetentionClaimDocument, baseOptions);
        }
export type GetSubconRetentionClaimQueryHookResult = ReturnType<typeof useGetSubconRetentionClaimQuery>;
export type GetSubconRetentionClaimLazyQueryHookResult = ReturnType<typeof useGetSubconRetentionClaimLazyQuery>;
export type GetSubconRetentionClaimQueryResult = ApolloReactCommon.QueryResult<GetSubconRetentionClaimQuery, GetSubconRetentionClaimQueryVariables>;
export const SubmitSubconRetentionClaimDocument = gql`
    mutation submitSubconRetentionClaim($input: SubconClaimRetentionInput!) {
  submitSubconRetentionClaim(input: $input) {
    ID
    claimDocNo
  }
}
    `;
export type SubmitSubconRetentionClaimMutationFn = ApolloReactCommon.MutationFunction<SubmitSubconRetentionClaimMutation, SubmitSubconRetentionClaimMutationVariables>;

/**
 * __useSubmitSubconRetentionClaimMutation__
 *
 * To run a mutation, you first call `useSubmitSubconRetentionClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSubconRetentionClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSubconRetentionClaimMutation, { data, loading, error }] = useSubmitSubconRetentionClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitSubconRetentionClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitSubconRetentionClaimMutation, SubmitSubconRetentionClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitSubconRetentionClaimMutation, SubmitSubconRetentionClaimMutationVariables>(SubmitSubconRetentionClaimDocument, baseOptions);
      }
export type SubmitSubconRetentionClaimMutationHookResult = ReturnType<typeof useSubmitSubconRetentionClaimMutation>;
export type SubmitSubconRetentionClaimMutationResult = ApolloReactCommon.MutationResult<SubmitSubconRetentionClaimMutation>;
export type SubmitSubconRetentionClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitSubconRetentionClaimMutation, SubmitSubconRetentionClaimMutationVariables>;
export const ApproveSubconRetentionClaimDocument = gql`
    mutation approveSubconRetentionClaim($input: SubconClaimRetentionInput!) {
  approveSubconRetentionClaim(input: $input) {
    ID
    docNo
  }
}
    `;
export type ApproveSubconRetentionClaimMutationFn = ApolloReactCommon.MutationFunction<ApproveSubconRetentionClaimMutation, ApproveSubconRetentionClaimMutationVariables>;

/**
 * __useApproveSubconRetentionClaimMutation__
 *
 * To run a mutation, you first call `useApproveSubconRetentionClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSubconRetentionClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSubconRetentionClaimMutation, { data, loading, error }] = useApproveSubconRetentionClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveSubconRetentionClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveSubconRetentionClaimMutation, ApproveSubconRetentionClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveSubconRetentionClaimMutation, ApproveSubconRetentionClaimMutationVariables>(ApproveSubconRetentionClaimDocument, baseOptions);
      }
export type ApproveSubconRetentionClaimMutationHookResult = ReturnType<typeof useApproveSubconRetentionClaimMutation>;
export type ApproveSubconRetentionClaimMutationResult = ApolloReactCommon.MutationResult<ApproveSubconRetentionClaimMutation>;
export type ApproveSubconRetentionClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveSubconRetentionClaimMutation, ApproveSubconRetentionClaimMutationVariables>;
export const CreateSubcontractClaimDocument = gql`
    mutation createSubcontractClaim($input: SubcontractClaimInput!) {
  createSubcontractClaim(input: $input) {
    accountID
  }
}
    `;
export type CreateSubcontractClaimMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractClaimMutation, CreateSubcontractClaimMutationVariables>;

/**
 * __useCreateSubcontractClaimMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractClaimMutation, { data, loading, error }] = useCreateSubcontractClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractClaimMutation, CreateSubcontractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractClaimMutation, CreateSubcontractClaimMutationVariables>(CreateSubcontractClaimDocument, baseOptions);
      }
export type CreateSubcontractClaimMutationHookResult = ReturnType<typeof useCreateSubcontractClaimMutation>;
export type CreateSubcontractClaimMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractClaimMutation>;
export type CreateSubcontractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractClaimMutation, CreateSubcontractClaimMutationVariables>;
export const UpdateSubcontractClaimDocument = gql`
    mutation updateSubcontractClaim($input: SubcontractClaimInput!) {
  updateSubcontractClaim(input: $input) {
    accountID
    ID
  }
}
    `;
export type UpdateSubcontractClaimMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractClaimMutation, UpdateSubcontractClaimMutationVariables>;

/**
 * __useUpdateSubcontractClaimMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractClaimMutation, { data, loading, error }] = useUpdateSubcontractClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractClaimMutation, UpdateSubcontractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractClaimMutation, UpdateSubcontractClaimMutationVariables>(UpdateSubcontractClaimDocument, baseOptions);
      }
export type UpdateSubcontractClaimMutationHookResult = ReturnType<typeof useUpdateSubcontractClaimMutation>;
export type UpdateSubcontractClaimMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractClaimMutation>;
export type UpdateSubcontractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractClaimMutation, UpdateSubcontractClaimMutationVariables>;
export const UpdateSubconClaimDetailsDocument = gql`
    mutation updateSubconClaimDetails($input: ClaimDetailsInput!, $ID: String!) {
  updateSubconClaimDetails(input: $input, ID: $ID)
}
    `;
export type UpdateSubconClaimDetailsMutationFn = ApolloReactCommon.MutationFunction<UpdateSubconClaimDetailsMutation, UpdateSubconClaimDetailsMutationVariables>;

/**
 * __useUpdateSubconClaimDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateSubconClaimDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubconClaimDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubconClaimDetailsMutation, { data, loading, error }] = useUpdateSubconClaimDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useUpdateSubconClaimDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubconClaimDetailsMutation, UpdateSubconClaimDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubconClaimDetailsMutation, UpdateSubconClaimDetailsMutationVariables>(UpdateSubconClaimDetailsDocument, baseOptions);
      }
export type UpdateSubconClaimDetailsMutationHookResult = ReturnType<typeof useUpdateSubconClaimDetailsMutation>;
export type UpdateSubconClaimDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateSubconClaimDetailsMutation>;
export type UpdateSubconClaimDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubconClaimDetailsMutation, UpdateSubconClaimDetailsMutationVariables>;
export const SubmitSubconClaimDocument = gql`
    mutation submitSubconClaim($input: SubcontractClaimInput!, $deductionLedger: [LedgerInput!], $claimAlloc: [SubconClaimAllocInput!], $voAlloc: [SubconClaimVOAllocInput!], $voCostWbsAlloc: [SubconClaimVOCostWbsAllocInput!]) {
  submitSubconClaim(input: $input, deductionLedger: $deductionLedger, claimAlloc: $claimAlloc, voAlloc: $voAlloc, voCostWbsAlloc: $voCostWbsAlloc) {
    ID
    docNo
  }
}
    `;
export type SubmitSubconClaimMutationFn = ApolloReactCommon.MutationFunction<SubmitSubconClaimMutation, SubmitSubconClaimMutationVariables>;

/**
 * __useSubmitSubconClaimMutation__
 *
 * To run a mutation, you first call `useSubmitSubconClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSubconClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSubconClaimMutation, { data, loading, error }] = useSubmitSubconClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *      deductionLedger: // value for 'deductionLedger'
 *      claimAlloc: // value for 'claimAlloc'
 *      voAlloc: // value for 'voAlloc'
 *      voCostWbsAlloc: // value for 'voCostWbsAlloc'
 *   },
 * });
 */
export function useSubmitSubconClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitSubconClaimMutation, SubmitSubconClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitSubconClaimMutation, SubmitSubconClaimMutationVariables>(SubmitSubconClaimDocument, baseOptions);
      }
export type SubmitSubconClaimMutationHookResult = ReturnType<typeof useSubmitSubconClaimMutation>;
export type SubmitSubconClaimMutationResult = ApolloReactCommon.MutationResult<SubmitSubconClaimMutation>;
export type SubmitSubconClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitSubconClaimMutation, SubmitSubconClaimMutationVariables>;
export const ApproveSubconClaimDocument = gql`
    mutation approveSubconClaim($input: SubcontractClaimInput!, $claimAlloc: [SubconClaimAllocInput!], $voAlloc: [SubconClaimVOAllocInput!], $voCostWbsAlloc: [SubconClaimVOCostWbsAllocInput!]) {
  approveSubconClaim(input: $input, claimAlloc: $claimAlloc, voAlloc: $voAlloc, voCostWbsAlloc: $voCostWbsAlloc)
}
    `;
export type ApproveSubconClaimMutationFn = ApolloReactCommon.MutationFunction<ApproveSubconClaimMutation, ApproveSubconClaimMutationVariables>;

/**
 * __useApproveSubconClaimMutation__
 *
 * To run a mutation, you first call `useApproveSubconClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSubconClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSubconClaimMutation, { data, loading, error }] = useApproveSubconClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *      claimAlloc: // value for 'claimAlloc'
 *      voAlloc: // value for 'voAlloc'
 *      voCostWbsAlloc: // value for 'voCostWbsAlloc'
 *   },
 * });
 */
export function useApproveSubconClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveSubconClaimMutation, ApproveSubconClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveSubconClaimMutation, ApproveSubconClaimMutationVariables>(ApproveSubconClaimDocument, baseOptions);
      }
export type ApproveSubconClaimMutationHookResult = ReturnType<typeof useApproveSubconClaimMutation>;
export type ApproveSubconClaimMutationResult = ApolloReactCommon.MutationResult<ApproveSubconClaimMutation>;
export type ApproveSubconClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveSubconClaimMutation, ApproveSubconClaimMutationVariables>;
export const CancelSubconClaimDocument = gql`
    mutation cancelSubconClaim($ID: String!, $input: CancelInput!, $retentionClaim: Boolean) {
  cancelSubconClaim(ID: $ID, input: $input, retentionClaim: $retentionClaim)
}
    `;
export type CancelSubconClaimMutationFn = ApolloReactCommon.MutationFunction<CancelSubconClaimMutation, CancelSubconClaimMutationVariables>;

/**
 * __useCancelSubconClaimMutation__
 *
 * To run a mutation, you first call `useCancelSubconClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconClaimMutation, { data, loading, error }] = useCancelSubconClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *      retentionClaim: // value for 'retentionClaim'
 *   },
 * });
 */
export function useCancelSubconClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconClaimMutation, CancelSubconClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconClaimMutation, CancelSubconClaimMutationVariables>(CancelSubconClaimDocument, baseOptions);
      }
export type CancelSubconClaimMutationHookResult = ReturnType<typeof useCancelSubconClaimMutation>;
export type CancelSubconClaimMutationResult = ApolloReactCommon.MutationResult<CancelSubconClaimMutation>;
export type CancelSubconClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconClaimMutation, CancelSubconClaimMutationVariables>;
export const RejectSubconClaimDocument = gql`
    mutation rejectSubconClaim($ID: String!, $input: RejectInput!, $retentionClaim: Boolean) {
  rejectSubconClaim(ID: $ID, input: $input, retentionClaim: $retentionClaim)
}
    `;
export type RejectSubconClaimMutationFn = ApolloReactCommon.MutationFunction<RejectSubconClaimMutation, RejectSubconClaimMutationVariables>;

/**
 * __useRejectSubconClaimMutation__
 *
 * To run a mutation, you first call `useRejectSubconClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectSubconClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectSubconClaimMutation, { data, loading, error }] = useRejectSubconClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *      retentionClaim: // value for 'retentionClaim'
 *   },
 * });
 */
export function useRejectSubconClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectSubconClaimMutation, RejectSubconClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectSubconClaimMutation, RejectSubconClaimMutationVariables>(RejectSubconClaimDocument, baseOptions);
      }
export type RejectSubconClaimMutationHookResult = ReturnType<typeof useRejectSubconClaimMutation>;
export type RejectSubconClaimMutationResult = ApolloReactCommon.MutationResult<RejectSubconClaimMutation>;
export type RejectSubconClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectSubconClaimMutation, RejectSubconClaimMutationVariables>;
export const DeleteSubcontractClaimDocument = gql`
    mutation deleteSubcontractClaim($ID: String!) {
  deleteSubcontractClaim(ID: $ID)
}
    `;
export type DeleteSubcontractClaimMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractClaimMutation, DeleteSubcontractClaimMutationVariables>;

/**
 * __useDeleteSubcontractClaimMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractClaimMutation, { data, loading, error }] = useDeleteSubcontractClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractClaimMutation, DeleteSubcontractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractClaimMutation, DeleteSubcontractClaimMutationVariables>(DeleteSubcontractClaimDocument, baseOptions);
      }
export type DeleteSubcontractClaimMutationHookResult = ReturnType<typeof useDeleteSubcontractClaimMutation>;
export type DeleteSubcontractClaimMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractClaimMutation>;
export type DeleteSubcontractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractClaimMutation, DeleteSubcontractClaimMutationVariables>;
export const GetSubconRetClaimAllocationDocument = gql`
    query getSubconRetClaimAllocation($creditID: String!) {
  getSubconRetClaimAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetSubconRetClaimAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconRetClaimAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconRetClaimAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconRetClaimAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetSubconRetClaimAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconRetClaimAllocationQuery, GetSubconRetClaimAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconRetClaimAllocationQuery, GetSubconRetClaimAllocationQueryVariables>(GetSubconRetClaimAllocationDocument, baseOptions);
      }
export function useGetSubconRetClaimAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconRetClaimAllocationQuery, GetSubconRetClaimAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconRetClaimAllocationQuery, GetSubconRetClaimAllocationQueryVariables>(GetSubconRetClaimAllocationDocument, baseOptions);
        }
export type GetSubconRetClaimAllocationQueryHookResult = ReturnType<typeof useGetSubconRetClaimAllocationQuery>;
export type GetSubconRetClaimAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconRetClaimAllocationLazyQuery>;
export type GetSubconRetClaimAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconRetClaimAllocationQuery, GetSubconRetClaimAllocationQueryVariables>;
export const GetSubcontractAdvanceDocument = gql`
    query getSubcontractAdvance($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractAdvance(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    isGlExport
    isRecoupment
    modTs
    modBy
    cancellationRemarks
    cancellationDate
    rejectionRemarks
    rejectionDate
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    subcontract {
      ID
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    outstandingAmt
  }
  subconAdvancesInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractAdvanceQuery__
 *
 * To run a query within a React component, call `useGetSubcontractAdvanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractAdvanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractAdvanceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractAdvanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractAdvanceQuery, GetSubcontractAdvanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractAdvanceQuery, GetSubcontractAdvanceQueryVariables>(GetSubcontractAdvanceDocument, baseOptions);
      }
export function useGetSubcontractAdvanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractAdvanceQuery, GetSubcontractAdvanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractAdvanceQuery, GetSubcontractAdvanceQueryVariables>(GetSubcontractAdvanceDocument, baseOptions);
        }
export type GetSubcontractAdvanceQueryHookResult = ReturnType<typeof useGetSubcontractAdvanceQuery>;
export type GetSubcontractAdvanceLazyQueryHookResult = ReturnType<typeof useGetSubcontractAdvanceLazyQuery>;
export type GetSubcontractAdvanceQueryResult = ApolloReactCommon.QueryResult<GetSubcontractAdvanceQuery, GetSubcontractAdvanceQueryVariables>;
export const GetSubcontractAdvanceListingDocument = gql`
    query getSubcontractAdvanceListing($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractAdvance(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    isGlExport
    isRecoupment
    modTs
    modBy
    cancellationRemarks
    cancellationDate
    rejectionRemarks
    rejectionDate
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    subcontract {
      ID
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    outstandingAmt
  }
  subconAdvancesInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  getSubcontractAdvanceCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetSubcontractAdvanceListingQuery__
 *
 * To run a query within a React component, call `useGetSubcontractAdvanceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractAdvanceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractAdvanceListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractAdvanceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractAdvanceListingQuery, GetSubcontractAdvanceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractAdvanceListingQuery, GetSubcontractAdvanceListingQueryVariables>(GetSubcontractAdvanceListingDocument, baseOptions);
      }
export function useGetSubcontractAdvanceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractAdvanceListingQuery, GetSubcontractAdvanceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractAdvanceListingQuery, GetSubcontractAdvanceListingQueryVariables>(GetSubcontractAdvanceListingDocument, baseOptions);
        }
export type GetSubcontractAdvanceListingQueryHookResult = ReturnType<typeof useGetSubcontractAdvanceListingQuery>;
export type GetSubcontractAdvanceListingLazyQueryHookResult = ReturnType<typeof useGetSubcontractAdvanceListingLazyQuery>;
export type GetSubcontractAdvanceListingQueryResult = ApolloReactCommon.QueryResult<GetSubcontractAdvanceListingQuery, GetSubcontractAdvanceListingQueryVariables>;
export const CreateSubcontractAdvanceDocument = gql`
    mutation createSubcontractAdvance($input: SubcontractPaymentInput!) {
  createSubcontractAdvance(input: $input) {
    ID
    docNo
  }
}
    `;
export type CreateSubcontractAdvanceMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractAdvanceMutation, CreateSubcontractAdvanceMutationVariables>;

/**
 * __useCreateSubcontractAdvanceMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractAdvanceMutation, { data, loading, error }] = useCreateSubcontractAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractAdvanceMutation, CreateSubcontractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractAdvanceMutation, CreateSubcontractAdvanceMutationVariables>(CreateSubcontractAdvanceDocument, baseOptions);
      }
export type CreateSubcontractAdvanceMutationHookResult = ReturnType<typeof useCreateSubcontractAdvanceMutation>;
export type CreateSubcontractAdvanceMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractAdvanceMutation>;
export type CreateSubcontractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractAdvanceMutation, CreateSubcontractAdvanceMutationVariables>;
export const UpdateSubcontractAdvanceDocument = gql`
    mutation updateSubcontractAdvance($input: SubcontractPaymentInput!) {
  updateSubcontractAdvance(input: $input) {
    ID
    docNo
  }
}
    `;
export type UpdateSubcontractAdvanceMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractAdvanceMutation, UpdateSubcontractAdvanceMutationVariables>;

/**
 * __useUpdateSubcontractAdvanceMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractAdvanceMutation, { data, loading, error }] = useUpdateSubcontractAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractAdvanceMutation, UpdateSubcontractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractAdvanceMutation, UpdateSubcontractAdvanceMutationVariables>(UpdateSubcontractAdvanceDocument, baseOptions);
      }
export type UpdateSubcontractAdvanceMutationHookResult = ReturnType<typeof useUpdateSubcontractAdvanceMutation>;
export type UpdateSubcontractAdvanceMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractAdvanceMutation>;
export type UpdateSubcontractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractAdvanceMutation, UpdateSubcontractAdvanceMutationVariables>;
export const GetSubcontractPaymentDocument = gql`
    query getSubcontractPayment($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractPayment(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    paymentTo
    isGlExport
    cancellationRemarks
    cancellationDate
    rejectionRemarks
    rejectionDate
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    bankAccountID
    bankDetails
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  subconPaymentInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractPaymentQuery__
 *
 * To run a query within a React component, call `useGetSubcontractPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractPaymentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractPaymentQuery, GetSubcontractPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractPaymentQuery, GetSubcontractPaymentQueryVariables>(GetSubcontractPaymentDocument, baseOptions);
      }
export function useGetSubcontractPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractPaymentQuery, GetSubcontractPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractPaymentQuery, GetSubcontractPaymentQueryVariables>(GetSubcontractPaymentDocument, baseOptions);
        }
export type GetSubcontractPaymentQueryHookResult = ReturnType<typeof useGetSubcontractPaymentQuery>;
export type GetSubcontractPaymentLazyQueryHookResult = ReturnType<typeof useGetSubcontractPaymentLazyQuery>;
export type GetSubcontractPaymentQueryResult = ApolloReactCommon.QueryResult<GetSubcontractPaymentQuery, GetSubcontractPaymentQueryVariables>;
export const GetSubcontractPaymentListingDocument = gql`
    query getSubcontractPaymentListing($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractPayment(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    paymentTo
    isGlExport
    cancellationRemarks
    cancellationDate
    rejectionRemarks
    rejectionDate
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    bankAccountID
    bankDetails
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  subconPaymentInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  getSubcontractPaymentCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetSubcontractPaymentListingQuery__
 *
 * To run a query within a React component, call `useGetSubcontractPaymentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractPaymentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractPaymentListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractPaymentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractPaymentListingQuery, GetSubcontractPaymentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractPaymentListingQuery, GetSubcontractPaymentListingQueryVariables>(GetSubcontractPaymentListingDocument, baseOptions);
      }
export function useGetSubcontractPaymentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractPaymentListingQuery, GetSubcontractPaymentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractPaymentListingQuery, GetSubcontractPaymentListingQueryVariables>(GetSubcontractPaymentListingDocument, baseOptions);
        }
export type GetSubcontractPaymentListingQueryHookResult = ReturnType<typeof useGetSubcontractPaymentListingQuery>;
export type GetSubcontractPaymentListingLazyQueryHookResult = ReturnType<typeof useGetSubcontractPaymentListingLazyQuery>;
export type GetSubcontractPaymentListingQueryResult = ApolloReactCommon.QueryResult<GetSubcontractPaymentListingQuery, GetSubcontractPaymentListingQueryVariables>;
export const CreateSubcontractPaymentDocument = gql`
    mutation createSubcontractPayment($input: SubcontractPaymentInput!) {
  createSubcontractPayment(input: $input) {
    ID
  }
}
    `;
export type CreateSubcontractPaymentMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractPaymentMutation, CreateSubcontractPaymentMutationVariables>;

/**
 * __useCreateSubcontractPaymentMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractPaymentMutation, { data, loading, error }] = useCreateSubcontractPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractPaymentMutation, CreateSubcontractPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractPaymentMutation, CreateSubcontractPaymentMutationVariables>(CreateSubcontractPaymentDocument, baseOptions);
      }
export type CreateSubcontractPaymentMutationHookResult = ReturnType<typeof useCreateSubcontractPaymentMutation>;
export type CreateSubcontractPaymentMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractPaymentMutation>;
export type CreateSubcontractPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractPaymentMutation, CreateSubcontractPaymentMutationVariables>;
export const UpdateSubcontractPaymentDocument = gql`
    mutation updateSubcontractPayment($input: SubcontractPaymentInput!) {
  updateSubcontractPayment(input: $input) {
    ID
  }
}
    `;
export type UpdateSubcontractPaymentMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractPaymentMutation, UpdateSubcontractPaymentMutationVariables>;

/**
 * __useUpdateSubcontractPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractPaymentMutation, { data, loading, error }] = useUpdateSubcontractPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractPaymentMutation, UpdateSubcontractPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractPaymentMutation, UpdateSubcontractPaymentMutationVariables>(UpdateSubcontractPaymentDocument, baseOptions);
      }
export type UpdateSubcontractPaymentMutationHookResult = ReturnType<typeof useUpdateSubcontractPaymentMutation>;
export type UpdateSubcontractPaymentMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractPaymentMutation>;
export type UpdateSubcontractPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractPaymentMutation, UpdateSubcontractPaymentMutationVariables>;
export const GetSubcontractRefundDocument = gql`
    query getSubcontractRefund($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractRefund(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    cancellationRemarks
    cancellationDate
    rejectionRemarks
    rejectionDate
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  subconRefundsInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractRefundQuery__
 *
 * To run a query within a React component, call `useGetSubcontractRefundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractRefundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractRefundQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractRefundQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractRefundQuery, GetSubcontractRefundQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractRefundQuery, GetSubcontractRefundQueryVariables>(GetSubcontractRefundDocument, baseOptions);
      }
export function useGetSubcontractRefundLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractRefundQuery, GetSubcontractRefundQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractRefundQuery, GetSubcontractRefundQueryVariables>(GetSubcontractRefundDocument, baseOptions);
        }
export type GetSubcontractRefundQueryHookResult = ReturnType<typeof useGetSubcontractRefundQuery>;
export type GetSubcontractRefundLazyQueryHookResult = ReturnType<typeof useGetSubcontractRefundLazyQuery>;
export type GetSubcontractRefundQueryResult = ApolloReactCommon.QueryResult<GetSubcontractRefundQuery, GetSubcontractRefundQueryVariables>;
export const GetSubcontractRefundListingDocument = gql`
    query getSubcontractRefundListing($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractRefund(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    cancellationRemarks
    cancellationDate
    rejectionRemarks
    rejectionDate
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  subconRefundsInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  getSubcontractRefundCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetSubcontractRefundListingQuery__
 *
 * To run a query within a React component, call `useGetSubcontractRefundListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractRefundListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractRefundListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractRefundListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractRefundListingQuery, GetSubcontractRefundListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractRefundListingQuery, GetSubcontractRefundListingQueryVariables>(GetSubcontractRefundListingDocument, baseOptions);
      }
export function useGetSubcontractRefundListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractRefundListingQuery, GetSubcontractRefundListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractRefundListingQuery, GetSubcontractRefundListingQueryVariables>(GetSubcontractRefundListingDocument, baseOptions);
        }
export type GetSubcontractRefundListingQueryHookResult = ReturnType<typeof useGetSubcontractRefundListingQuery>;
export type GetSubcontractRefundListingLazyQueryHookResult = ReturnType<typeof useGetSubcontractRefundListingLazyQuery>;
export type GetSubcontractRefundListingQueryResult = ApolloReactCommon.QueryResult<GetSubcontractRefundListingQuery, GetSubcontractRefundListingQueryVariables>;
export const CreateSubcontractRefundDocument = gql`
    mutation createSubcontractRefund($input: SubcontractPaymentInput!) {
  createSubcontractRefund(input: $input) {
    ID
    docNo
  }
}
    `;
export type CreateSubcontractRefundMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractRefundMutation, CreateSubcontractRefundMutationVariables>;

/**
 * __useCreateSubcontractRefundMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractRefundMutation, { data, loading, error }] = useCreateSubcontractRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractRefundMutation, CreateSubcontractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractRefundMutation, CreateSubcontractRefundMutationVariables>(CreateSubcontractRefundDocument, baseOptions);
      }
export type CreateSubcontractRefundMutationHookResult = ReturnType<typeof useCreateSubcontractRefundMutation>;
export type CreateSubcontractRefundMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractRefundMutation>;
export type CreateSubcontractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractRefundMutation, CreateSubcontractRefundMutationVariables>;
export const UpdateSubcontractRefundDocument = gql`
    mutation updateSubcontractRefund($input: SubcontractPaymentInput!) {
  updateSubcontractRefund(input: $input) {
    ID
    docNo
  }
}
    `;
export type UpdateSubcontractRefundMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractRefundMutation, UpdateSubcontractRefundMutationVariables>;

/**
 * __useUpdateSubcontractRefundMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractRefundMutation, { data, loading, error }] = useUpdateSubcontractRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractRefundMutation, UpdateSubcontractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractRefundMutation, UpdateSubcontractRefundMutationVariables>(UpdateSubcontractRefundDocument, baseOptions);
      }
export type UpdateSubcontractRefundMutationHookResult = ReturnType<typeof useUpdateSubcontractRefundMutation>;
export type UpdateSubcontractRefundMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractRefundMutation>;
export type UpdateSubcontractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractRefundMutation, UpdateSubcontractRefundMutationVariables>;
export const GetSubcontractPaymentTableDocument = gql`
    query getSubcontractPaymentTable($companyID: String, $subcontractorID: String, $subcontractID: String) {
  getSubcontractClaim(companyID: $companyID, subcontractorID: $subcontractorID, subcontractID: $subcontractID) {
    ID
    subcontractID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
  }
  getSubcontractDN(companyID: $companyID, subcontractorID: $subcontractorID, subcontractID: $subcontractID) {
    ID
    subcontractID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
  }
}
    `;

/**
 * __useGetSubcontractPaymentTableQuery__
 *
 * To run a query within a React component, call `useGetSubcontractPaymentTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractPaymentTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractPaymentTableQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractorID: // value for 'subcontractorID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractPaymentTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractPaymentTableQuery, GetSubcontractPaymentTableQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractPaymentTableQuery, GetSubcontractPaymentTableQueryVariables>(GetSubcontractPaymentTableDocument, baseOptions);
      }
export function useGetSubcontractPaymentTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractPaymentTableQuery, GetSubcontractPaymentTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractPaymentTableQuery, GetSubcontractPaymentTableQueryVariables>(GetSubcontractPaymentTableDocument, baseOptions);
        }
export type GetSubcontractPaymentTableQueryHookResult = ReturnType<typeof useGetSubcontractPaymentTableQuery>;
export type GetSubcontractPaymentTableLazyQueryHookResult = ReturnType<typeof useGetSubcontractPaymentTableLazyQuery>;
export type GetSubcontractPaymentTableQueryResult = ApolloReactCommon.QueryResult<GetSubcontractPaymentTableQuery, GetSubcontractPaymentTableQueryVariables>;
export const MakeSubconPaymentDocument = gql`
    mutation makeSubconPayment($input: SubcontractPaymentInput!, $ledger: [LedgerInput!]!) {
  makeSubconPayment(input: $input, ledger: $ledger) {
    ID
    docNo
  }
}
    `;
export type MakeSubconPaymentMutationFn = ApolloReactCommon.MutationFunction<MakeSubconPaymentMutation, MakeSubconPaymentMutationVariables>;

/**
 * __useMakeSubconPaymentMutation__
 *
 * To run a mutation, you first call `useMakeSubconPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSubconPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSubconPaymentMutation, { data, loading, error }] = useMakeSubconPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeSubconPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeSubconPaymentMutation, MakeSubconPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeSubconPaymentMutation, MakeSubconPaymentMutationVariables>(MakeSubconPaymentDocument, baseOptions);
      }
export type MakeSubconPaymentMutationHookResult = ReturnType<typeof useMakeSubconPaymentMutation>;
export type MakeSubconPaymentMutationResult = ApolloReactCommon.MutationResult<MakeSubconPaymentMutation>;
export type MakeSubconPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeSubconPaymentMutation, MakeSubconPaymentMutationVariables>;
export const CancelSubconAdvanceDocument = gql`
    mutation cancelSubconAdvance($ID: String!, $input: CancelInput!) {
  cancelSubconAdvance(ID: $ID, input: $input)
}
    `;
export type CancelSubconAdvanceMutationFn = ApolloReactCommon.MutationFunction<CancelSubconAdvanceMutation, CancelSubconAdvanceMutationVariables>;

/**
 * __useCancelSubconAdvanceMutation__
 *
 * To run a mutation, you first call `useCancelSubconAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconAdvanceMutation, { data, loading, error }] = useCancelSubconAdvanceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubconAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconAdvanceMutation, CancelSubconAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconAdvanceMutation, CancelSubconAdvanceMutationVariables>(CancelSubconAdvanceDocument, baseOptions);
      }
export type CancelSubconAdvanceMutationHookResult = ReturnType<typeof useCancelSubconAdvanceMutation>;
export type CancelSubconAdvanceMutationResult = ApolloReactCommon.MutationResult<CancelSubconAdvanceMutation>;
export type CancelSubconAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconAdvanceMutation, CancelSubconAdvanceMutationVariables>;
export const CancelSubconPaymentDocument = gql`
    mutation cancelSubconPayment($ID: String!, $input: CancelInput!) {
  cancelSubconPayment(ID: $ID, input: $input)
}
    `;
export type CancelSubconPaymentMutationFn = ApolloReactCommon.MutationFunction<CancelSubconPaymentMutation, CancelSubconPaymentMutationVariables>;

/**
 * __useCancelSubconPaymentMutation__
 *
 * To run a mutation, you first call `useCancelSubconPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconPaymentMutation, { data, loading, error }] = useCancelSubconPaymentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubconPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconPaymentMutation, CancelSubconPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconPaymentMutation, CancelSubconPaymentMutationVariables>(CancelSubconPaymentDocument, baseOptions);
      }
export type CancelSubconPaymentMutationHookResult = ReturnType<typeof useCancelSubconPaymentMutation>;
export type CancelSubconPaymentMutationResult = ApolloReactCommon.MutationResult<CancelSubconPaymentMutation>;
export type CancelSubconPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconPaymentMutation, CancelSubconPaymentMutationVariables>;
export const CancelSubconRefundDocument = gql`
    mutation cancelSubconRefund($ID: String!, $input: CancelInput!) {
  cancelSubconRefund(ID: $ID, input: $input)
}
    `;
export type CancelSubconRefundMutationFn = ApolloReactCommon.MutationFunction<CancelSubconRefundMutation, CancelSubconRefundMutationVariables>;

/**
 * __useCancelSubconRefundMutation__
 *
 * To run a mutation, you first call `useCancelSubconRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconRefundMutation, { data, loading, error }] = useCancelSubconRefundMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubconRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconRefundMutation, CancelSubconRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconRefundMutation, CancelSubconRefundMutationVariables>(CancelSubconRefundDocument, baseOptions);
      }
export type CancelSubconRefundMutationHookResult = ReturnType<typeof useCancelSubconRefundMutation>;
export type CancelSubconRefundMutationResult = ApolloReactCommon.MutationResult<CancelSubconRefundMutation>;
export type CancelSubconRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconRefundMutation, CancelSubconRefundMutationVariables>;
export const DeleteSubcontractAdvanceDocument = gql`
    mutation deleteSubcontractAdvance($ID: String!) {
  deleteSubcontractAdvance(ID: $ID)
}
    `;
export type DeleteSubcontractAdvanceMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractAdvanceMutation, DeleteSubcontractAdvanceMutationVariables>;

/**
 * __useDeleteSubcontractAdvanceMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractAdvanceMutation, { data, loading, error }] = useDeleteSubcontractAdvanceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractAdvanceMutation, DeleteSubcontractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractAdvanceMutation, DeleteSubcontractAdvanceMutationVariables>(DeleteSubcontractAdvanceDocument, baseOptions);
      }
export type DeleteSubcontractAdvanceMutationHookResult = ReturnType<typeof useDeleteSubcontractAdvanceMutation>;
export type DeleteSubcontractAdvanceMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractAdvanceMutation>;
export type DeleteSubcontractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractAdvanceMutation, DeleteSubcontractAdvanceMutationVariables>;
export const DeleteSubcontractRefundDocument = gql`
    mutation deleteSubcontractRefund($ID: String!) {
  deleteSubcontractRefund(ID: $ID)
}
    `;
export type DeleteSubcontractRefundMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractRefundMutation, DeleteSubcontractRefundMutationVariables>;

/**
 * __useDeleteSubcontractRefundMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractRefundMutation, { data, loading, error }] = useDeleteSubcontractRefundMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractRefundMutation, DeleteSubcontractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractRefundMutation, DeleteSubcontractRefundMutationVariables>(DeleteSubcontractRefundDocument, baseOptions);
      }
export type DeleteSubcontractRefundMutationHookResult = ReturnType<typeof useDeleteSubcontractRefundMutation>;
export type DeleteSubcontractRefundMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractRefundMutation>;
export type DeleteSubcontractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractRefundMutation, DeleteSubcontractRefundMutationVariables>;
export const DeleteSubcontractPaymentDocument = gql`
    mutation deleteSubcontractPayment($ID: String!) {
  deleteSubcontractPayment(ID: $ID)
}
    `;
export type DeleteSubcontractPaymentMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractPaymentMutation, DeleteSubcontractPaymentMutationVariables>;

/**
 * __useDeleteSubcontractPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractPaymentMutation, { data, loading, error }] = useDeleteSubcontractPaymentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractPaymentMutation, DeleteSubcontractPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractPaymentMutation, DeleteSubcontractPaymentMutationVariables>(DeleteSubcontractPaymentDocument, baseOptions);
      }
export type DeleteSubcontractPaymentMutationHookResult = ReturnType<typeof useDeleteSubcontractPaymentMutation>;
export type DeleteSubcontractPaymentMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractPaymentMutation>;
export type DeleteSubcontractPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractPaymentMutation, DeleteSubcontractPaymentMutationVariables>;
export const GetSubcontractAdvanceCountDocument = gql`
    query getSubcontractAdvanceCount($companyID: String, $subcontractID: String, $subcontractorID: String, $receiptStatus: ReceiptStatus) {
  getSubcontractAdvanceCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetSubcontractAdvanceCountQuery__
 *
 * To run a query within a React component, call `useGetSubcontractAdvanceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractAdvanceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractAdvanceCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetSubcontractAdvanceCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractAdvanceCountQuery, GetSubcontractAdvanceCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractAdvanceCountQuery, GetSubcontractAdvanceCountQueryVariables>(GetSubcontractAdvanceCountDocument, baseOptions);
      }
export function useGetSubcontractAdvanceCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractAdvanceCountQuery, GetSubcontractAdvanceCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractAdvanceCountQuery, GetSubcontractAdvanceCountQueryVariables>(GetSubcontractAdvanceCountDocument, baseOptions);
        }
export type GetSubcontractAdvanceCountQueryHookResult = ReturnType<typeof useGetSubcontractAdvanceCountQuery>;
export type GetSubcontractAdvanceCountLazyQueryHookResult = ReturnType<typeof useGetSubcontractAdvanceCountLazyQuery>;
export type GetSubcontractAdvanceCountQueryResult = ApolloReactCommon.QueryResult<GetSubcontractAdvanceCountQuery, GetSubcontractAdvanceCountQueryVariables>;
export const GetSubcontractPaymentCountDocument = gql`
    query getSubcontractPaymentCount($companyID: String, $subcontractID: String, $subcontractorID: String, $receiptStatus: ReceiptStatus) {
  getSubcontractPaymentCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetSubcontractPaymentCountQuery__
 *
 * To run a query within a React component, call `useGetSubcontractPaymentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractPaymentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractPaymentCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetSubcontractPaymentCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractPaymentCountQuery, GetSubcontractPaymentCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractPaymentCountQuery, GetSubcontractPaymentCountQueryVariables>(GetSubcontractPaymentCountDocument, baseOptions);
      }
export function useGetSubcontractPaymentCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractPaymentCountQuery, GetSubcontractPaymentCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractPaymentCountQuery, GetSubcontractPaymentCountQueryVariables>(GetSubcontractPaymentCountDocument, baseOptions);
        }
export type GetSubcontractPaymentCountQueryHookResult = ReturnType<typeof useGetSubcontractPaymentCountQuery>;
export type GetSubcontractPaymentCountLazyQueryHookResult = ReturnType<typeof useGetSubcontractPaymentCountLazyQuery>;
export type GetSubcontractPaymentCountQueryResult = ApolloReactCommon.QueryResult<GetSubcontractPaymentCountQuery, GetSubcontractPaymentCountQueryVariables>;
export const GetSubcontractRefundCountDocument = gql`
    query getSubcontractRefundCount($companyID: String, $subcontractID: String, $subcontractorID: String, $receiptStatus: ReceiptStatus) {
  getSubcontractRefundCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetSubcontractRefundCountQuery__
 *
 * To run a query within a React component, call `useGetSubcontractRefundCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractRefundCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractRefundCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetSubcontractRefundCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractRefundCountQuery, GetSubcontractRefundCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractRefundCountQuery, GetSubcontractRefundCountQueryVariables>(GetSubcontractRefundCountDocument, baseOptions);
      }
export function useGetSubcontractRefundCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractRefundCountQuery, GetSubcontractRefundCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractRefundCountQuery, GetSubcontractRefundCountQueryVariables>(GetSubcontractRefundCountDocument, baseOptions);
        }
export type GetSubcontractRefundCountQueryHookResult = ReturnType<typeof useGetSubcontractRefundCountQuery>;
export type GetSubcontractRefundCountLazyQueryHookResult = ReturnType<typeof useGetSubcontractRefundCountLazyQuery>;
export type GetSubcontractRefundCountQueryResult = ApolloReactCommon.QueryResult<GetSubcontractRefundCountQuery, GetSubcontractRefundCountQueryVariables>;
export const GetSubcontractMiscInvoiceDocument = gql`
    query getSubcontractMiscInvoice($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractMiscInvoice(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    docNo
    docRefID
    isDaywork
    isGlExport
    docRef
    cancellationDate
    cancellationRemarks
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    subcontractorID
    profileInfo
    contractID
    subcontractID
    outstandingAmt
    submissionComment
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    subcontract {
      title
      subcontractClaim {
        ID
        docRefID
        claimDocNo
      }
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    subcontractMiscInvoiceItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      taxRate
      taxAmt
      remarks
    }
  }
  subconMiscInvInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractMiscInvoiceQuery__
 *
 * To run a query within a React component, call `useGetSubcontractMiscInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractMiscInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractMiscInvoiceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractMiscInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractMiscInvoiceQuery, GetSubcontractMiscInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractMiscInvoiceQuery, GetSubcontractMiscInvoiceQueryVariables>(GetSubcontractMiscInvoiceDocument, baseOptions);
      }
export function useGetSubcontractMiscInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractMiscInvoiceQuery, GetSubcontractMiscInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractMiscInvoiceQuery, GetSubcontractMiscInvoiceQueryVariables>(GetSubcontractMiscInvoiceDocument, baseOptions);
        }
export type GetSubcontractMiscInvoiceQueryHookResult = ReturnType<typeof useGetSubcontractMiscInvoiceQuery>;
export type GetSubcontractMiscInvoiceLazyQueryHookResult = ReturnType<typeof useGetSubcontractMiscInvoiceLazyQuery>;
export type GetSubcontractMiscInvoiceQueryResult = ApolloReactCommon.QueryResult<GetSubcontractMiscInvoiceQuery, GetSubcontractMiscInvoiceQueryVariables>;
export const GetSubcontractMiscInvoiceListingDocument = gql`
    query getSubcontractMiscInvoiceListing($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractMiscInvoice(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    docNo
    docRefID
    isDaywork
    isGlExport
    docRef
    cancellationDate
    cancellationRemarks
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    subcontractorID
    profileInfo
    contractID
    subcontractID
    outstandingAmt
    submissionComment
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    subcontract {
      title
      subcontractClaim {
        ID
        docRefID
        claimDocNo
      }
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    subcontractMiscInvoiceItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      taxRate
      taxAmt
      remarks
    }
  }
  subconMiscInvInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  getSubcontractMiscInvoiceCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetSubcontractMiscInvoiceListingQuery__
 *
 * To run a query within a React component, call `useGetSubcontractMiscInvoiceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractMiscInvoiceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractMiscInvoiceListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractMiscInvoiceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractMiscInvoiceListingQuery, GetSubcontractMiscInvoiceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractMiscInvoiceListingQuery, GetSubcontractMiscInvoiceListingQueryVariables>(GetSubcontractMiscInvoiceListingDocument, baseOptions);
      }
export function useGetSubcontractMiscInvoiceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractMiscInvoiceListingQuery, GetSubcontractMiscInvoiceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractMiscInvoiceListingQuery, GetSubcontractMiscInvoiceListingQueryVariables>(GetSubcontractMiscInvoiceListingDocument, baseOptions);
        }
export type GetSubcontractMiscInvoiceListingQueryHookResult = ReturnType<typeof useGetSubcontractMiscInvoiceListingQuery>;
export type GetSubcontractMiscInvoiceListingLazyQueryHookResult = ReturnType<typeof useGetSubcontractMiscInvoiceListingLazyQuery>;
export type GetSubcontractMiscInvoiceListingQueryResult = ApolloReactCommon.QueryResult<GetSubcontractMiscInvoiceListingQuery, GetSubcontractMiscInvoiceListingQueryVariables>;
export const CreateSubcontractMiscInvoiceDocument = gql`
    mutation createSubcontractMiscInvoice($input: SubcontractTransactionWithGLItemInput!) {
  createSubcontractMiscInvoice(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type CreateSubcontractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractMiscInvoiceMutation, CreateSubcontractMiscInvoiceMutationVariables>;

/**
 * __useCreateSubcontractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractMiscInvoiceMutation, { data, loading, error }] = useCreateSubcontractMiscInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractMiscInvoiceMutation, CreateSubcontractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractMiscInvoiceMutation, CreateSubcontractMiscInvoiceMutationVariables>(CreateSubcontractMiscInvoiceDocument, baseOptions);
      }
export type CreateSubcontractMiscInvoiceMutationHookResult = ReturnType<typeof useCreateSubcontractMiscInvoiceMutation>;
export type CreateSubcontractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractMiscInvoiceMutation>;
export type CreateSubcontractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractMiscInvoiceMutation, CreateSubcontractMiscInvoiceMutationVariables>;
export const UpdateSubcontractMiscInvoiceDocument = gql`
    mutation updateSubcontractMiscInvoice($input: SubcontractTransactionWithGLItemInput!) {
  updateSubcontractMiscInvoice(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type UpdateSubcontractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractMiscInvoiceMutation, UpdateSubcontractMiscInvoiceMutationVariables>;

/**
 * __useUpdateSubcontractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractMiscInvoiceMutation, { data, loading, error }] = useUpdateSubcontractMiscInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractMiscInvoiceMutation, UpdateSubcontractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractMiscInvoiceMutation, UpdateSubcontractMiscInvoiceMutationVariables>(UpdateSubcontractMiscInvoiceDocument, baseOptions);
      }
export type UpdateSubcontractMiscInvoiceMutationHookResult = ReturnType<typeof useUpdateSubcontractMiscInvoiceMutation>;
export type UpdateSubcontractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractMiscInvoiceMutation>;
export type UpdateSubcontractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractMiscInvoiceMutation, UpdateSubcontractMiscInvoiceMutationVariables>;
export const GetSubcontractDnDocument = gql`
    query getSubcontractDN($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractDN(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    isGlExport
    modBy
    docNo
    docRef
    cancellationDate
    cancellationRemarks
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    submissionComment
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    subcontract {
      title
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    subcontractDNItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  subconDNInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractDnQuery__
 *
 * To run a query within a React component, call `useGetSubcontractDnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractDnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractDnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractDnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractDnQuery, GetSubcontractDnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractDnQuery, GetSubcontractDnQueryVariables>(GetSubcontractDnDocument, baseOptions);
      }
export function useGetSubcontractDnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractDnQuery, GetSubcontractDnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractDnQuery, GetSubcontractDnQueryVariables>(GetSubcontractDnDocument, baseOptions);
        }
export type GetSubcontractDnQueryHookResult = ReturnType<typeof useGetSubcontractDnQuery>;
export type GetSubcontractDnLazyQueryHookResult = ReturnType<typeof useGetSubcontractDnLazyQuery>;
export type GetSubcontractDnQueryResult = ApolloReactCommon.QueryResult<GetSubcontractDnQuery, GetSubcontractDnQueryVariables>;
export const GetSubcontractDnListingDocument = gql`
    query getSubcontractDNListing($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractDN(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    isGlExport
    modBy
    docNo
    docRef
    cancellationDate
    cancellationRemarks
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    submissionComment
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    subcontract {
      title
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    subcontractDNItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  subconDNInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  getSubcontractDNCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetSubcontractDnListingQuery__
 *
 * To run a query within a React component, call `useGetSubcontractDnListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractDnListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractDnListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractDnListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractDnListingQuery, GetSubcontractDnListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractDnListingQuery, GetSubcontractDnListingQueryVariables>(GetSubcontractDnListingDocument, baseOptions);
      }
export function useGetSubcontractDnListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractDnListingQuery, GetSubcontractDnListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractDnListingQuery, GetSubcontractDnListingQueryVariables>(GetSubcontractDnListingDocument, baseOptions);
        }
export type GetSubcontractDnListingQueryHookResult = ReturnType<typeof useGetSubcontractDnListingQuery>;
export type GetSubcontractDnListingLazyQueryHookResult = ReturnType<typeof useGetSubcontractDnListingLazyQuery>;
export type GetSubcontractDnListingQueryResult = ApolloReactCommon.QueryResult<GetSubcontractDnListingQuery, GetSubcontractDnListingQueryVariables>;
export const CreateSubcontractDnDocument = gql`
    mutation createSubcontractDN($input: SubcontractTransactionWithGLItemInput!) {
  createSubcontractDN(input: $input) {
    accountID
    ID
  }
}
    `;
export type CreateSubcontractDnMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractDnMutation, CreateSubcontractDnMutationVariables>;

/**
 * __useCreateSubcontractDnMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractDnMutation, { data, loading, error }] = useCreateSubcontractDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractDnMutation, CreateSubcontractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractDnMutation, CreateSubcontractDnMutationVariables>(CreateSubcontractDnDocument, baseOptions);
      }
export type CreateSubcontractDnMutationHookResult = ReturnType<typeof useCreateSubcontractDnMutation>;
export type CreateSubcontractDnMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractDnMutation>;
export type CreateSubcontractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractDnMutation, CreateSubcontractDnMutationVariables>;
export const UpdateSubcontractDnDocument = gql`
    mutation updateSubcontractDN($input: SubcontractTransactionWithGLItemInput!) {
  updateSubcontractDN(input: $input) {
    accountID
    ID
  }
}
    `;
export type UpdateSubcontractDnMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractDnMutation, UpdateSubcontractDnMutationVariables>;

/**
 * __useUpdateSubcontractDnMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractDnMutation, { data, loading, error }] = useUpdateSubcontractDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractDnMutation, UpdateSubcontractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractDnMutation, UpdateSubcontractDnMutationVariables>(UpdateSubcontractDnDocument, baseOptions);
      }
export type UpdateSubcontractDnMutationHookResult = ReturnType<typeof useUpdateSubcontractDnMutation>;
export type UpdateSubcontractDnMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractDnMutation>;
export type UpdateSubcontractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractDnMutation, UpdateSubcontractDnMutationVariables>;
export const GetSubcontractCnDocument = gql`
    query getSubcontractCN($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractCN(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    isGlExport
    modTs
    modBy
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    submissionComment
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    subcontractCNItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  subconCNInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractCnQuery__
 *
 * To run a query within a React component, call `useGetSubcontractCnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractCnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractCnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractCnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractCnQuery, GetSubcontractCnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractCnQuery, GetSubcontractCnQueryVariables>(GetSubcontractCnDocument, baseOptions);
      }
export function useGetSubcontractCnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractCnQuery, GetSubcontractCnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractCnQuery, GetSubcontractCnQueryVariables>(GetSubcontractCnDocument, baseOptions);
        }
export type GetSubcontractCnQueryHookResult = ReturnType<typeof useGetSubcontractCnQuery>;
export type GetSubcontractCnLazyQueryHookResult = ReturnType<typeof useGetSubcontractCnLazyQuery>;
export type GetSubcontractCnQueryResult = ApolloReactCommon.QueryResult<GetSubcontractCnQuery, GetSubcontractCnQueryVariables>;
export const GetSubcontractCnListingDocument = gql`
    query getSubcontractCNListing($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getSubcontractCN(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    isGlExport
    modTs
    modBy
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    submissionComment
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    subcontractCNItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  subconCNInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  getSubcontractCNCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetSubcontractCnListingQuery__
 *
 * To run a query within a React component, call `useGetSubcontractCnListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractCnListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractCnListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSubcontractCnListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractCnListingQuery, GetSubcontractCnListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractCnListingQuery, GetSubcontractCnListingQueryVariables>(GetSubcontractCnListingDocument, baseOptions);
      }
export function useGetSubcontractCnListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractCnListingQuery, GetSubcontractCnListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractCnListingQuery, GetSubcontractCnListingQueryVariables>(GetSubcontractCnListingDocument, baseOptions);
        }
export type GetSubcontractCnListingQueryHookResult = ReturnType<typeof useGetSubcontractCnListingQuery>;
export type GetSubcontractCnListingLazyQueryHookResult = ReturnType<typeof useGetSubcontractCnListingLazyQuery>;
export type GetSubcontractCnListingQueryResult = ApolloReactCommon.QueryResult<GetSubcontractCnListingQuery, GetSubcontractCnListingQueryVariables>;
export const CreateSubcontractCnDocument = gql`
    mutation createSubcontractCN($input: SubcontractTransactionWithGLItemInput!) {
  createSubcontractCN(input: $input) {
    accountID
    ID
  }
}
    `;
export type CreateSubcontractCnMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractCnMutation, CreateSubcontractCnMutationVariables>;

/**
 * __useCreateSubcontractCnMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractCnMutation, { data, loading, error }] = useCreateSubcontractCnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractCnMutation, CreateSubcontractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractCnMutation, CreateSubcontractCnMutationVariables>(CreateSubcontractCnDocument, baseOptions);
      }
export type CreateSubcontractCnMutationHookResult = ReturnType<typeof useCreateSubcontractCnMutation>;
export type CreateSubcontractCnMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractCnMutation>;
export type CreateSubcontractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractCnMutation, CreateSubcontractCnMutationVariables>;
export const UpdateSubcontractCnDocument = gql`
    mutation updateSubcontractCN($input: SubcontractTransactionWithGLItemInput!) {
  updateSubcontractCN(input: $input) {
    accountID
    ID
  }
}
    `;
export type UpdateSubcontractCnMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractCnMutation, UpdateSubcontractCnMutationVariables>;

/**
 * __useUpdateSubcontractCnMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractCnMutation, { data, loading, error }] = useUpdateSubcontractCnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractCnMutation, UpdateSubcontractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractCnMutation, UpdateSubcontractCnMutationVariables>(UpdateSubcontractCnDocument, baseOptions);
      }
export type UpdateSubcontractCnMutationHookResult = ReturnType<typeof useUpdateSubcontractCnMutation>;
export type UpdateSubcontractCnMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractCnMutation>;
export type UpdateSubcontractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractCnMutation, UpdateSubcontractCnMutationVariables>;
export const GetDNtoSubconDocument = gql`
    query getDNtoSubcon($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getDNtoSubcon(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    cancellationDate
    cancellationRemarks
    chargeToRevenue
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    contractID
    subcontractID
    subcontractorID
    outstandingAmt
    submissionComment
    docRefID
    docRefTable
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subcontract {
      title
      siteID
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    DNtoSubconItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  DNtoSubconInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetDNtoSubconQuery__
 *
 * To run a query within a React component, call `useGetDNtoSubconQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSubconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSubconQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetDNtoSubconQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSubconQuery, GetDNtoSubconQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSubconQuery, GetDNtoSubconQueryVariables>(GetDNtoSubconDocument, baseOptions);
      }
export function useGetDNtoSubconLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSubconQuery, GetDNtoSubconQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSubconQuery, GetDNtoSubconQueryVariables>(GetDNtoSubconDocument, baseOptions);
        }
export type GetDNtoSubconQueryHookResult = ReturnType<typeof useGetDNtoSubconQuery>;
export type GetDNtoSubconLazyQueryHookResult = ReturnType<typeof useGetDNtoSubconLazyQuery>;
export type GetDNtoSubconQueryResult = ApolloReactCommon.QueryResult<GetDNtoSubconQuery, GetDNtoSubconQueryVariables>;
export const GetDNtoSubconListingDocument = gql`
    query getDNtoSubconListing($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getDNtoSubcon(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    cancellationDate
    cancellationRemarks
    chargeToRevenue
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    contractID
    subcontractID
    outstandingAmt
    submissionComment
    docRefID
    docRefTable
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subcontract {
      title
      siteID
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    DNtoSubconItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  DNtoSubconInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  getDNtoSubconCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetDNtoSubconListingQuery__
 *
 * To run a query within a React component, call `useGetDNtoSubconListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSubconListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSubconListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetDNtoSubconListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSubconListingQuery, GetDNtoSubconListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSubconListingQuery, GetDNtoSubconListingQueryVariables>(GetDNtoSubconListingDocument, baseOptions);
      }
export function useGetDNtoSubconListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSubconListingQuery, GetDNtoSubconListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSubconListingQuery, GetDNtoSubconListingQueryVariables>(GetDNtoSubconListingDocument, baseOptions);
        }
export type GetDNtoSubconListingQueryHookResult = ReturnType<typeof useGetDNtoSubconListingQuery>;
export type GetDNtoSubconListingLazyQueryHookResult = ReturnType<typeof useGetDNtoSubconListingLazyQuery>;
export type GetDNtoSubconListingQueryResult = ApolloReactCommon.QueryResult<GetDNtoSubconListingQuery, GetDNtoSubconListingQueryVariables>;
export const CreateDNtoSubconDocument = gql`
    mutation createDNtoSubcon($input: SubcontractTransactionWithGLItemInput!) {
  createDNtoSubcon(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type CreateDNtoSubconMutationFn = ApolloReactCommon.MutationFunction<CreateDNtoSubconMutation, CreateDNtoSubconMutationVariables>;

/**
 * __useCreateDNtoSubconMutation__
 *
 * To run a mutation, you first call `useCreateDNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDNtoSubconMutation, { data, loading, error }] = useCreateDNtoSubconMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDNtoSubconMutation, CreateDNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDNtoSubconMutation, CreateDNtoSubconMutationVariables>(CreateDNtoSubconDocument, baseOptions);
      }
export type CreateDNtoSubconMutationHookResult = ReturnType<typeof useCreateDNtoSubconMutation>;
export type CreateDNtoSubconMutationResult = ApolloReactCommon.MutationResult<CreateDNtoSubconMutation>;
export type CreateDNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDNtoSubconMutation, CreateDNtoSubconMutationVariables>;
export const UpdateDNtoSubconDocument = gql`
    mutation updateDNtoSubcon($input: SubcontractTransactionWithGLItemInput!) {
  updateDNtoSubcon(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type UpdateDNtoSubconMutationFn = ApolloReactCommon.MutationFunction<UpdateDNtoSubconMutation, UpdateDNtoSubconMutationVariables>;

/**
 * __useUpdateDNtoSubconMutation__
 *
 * To run a mutation, you first call `useUpdateDNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDNtoSubconMutation, { data, loading, error }] = useUpdateDNtoSubconMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDNtoSubconMutation, UpdateDNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDNtoSubconMutation, UpdateDNtoSubconMutationVariables>(UpdateDNtoSubconDocument, baseOptions);
      }
export type UpdateDNtoSubconMutationHookResult = ReturnType<typeof useUpdateDNtoSubconMutation>;
export type UpdateDNtoSubconMutationResult = ApolloReactCommon.MutationResult<UpdateDNtoSubconMutation>;
export type UpdateDNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDNtoSubconMutation, UpdateDNtoSubconMutationVariables>;
export const GetCNtoSubconDocument = gql`
    query getCNtoSubcon($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getCNtoSubcon(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    cancellationDate
    cancellationRemarks
    chargeToRevenue
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    contractID
    subcontractID
    subcontractorID
    outstandingAmt
    submissionComment
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    CNtoSubconItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  CNtoSubconInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetCNtoSubconQuery__
 *
 * To run a query within a React component, call `useGetCNtoSubconQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSubconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSubconQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetCNtoSubconQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSubconQuery, GetCNtoSubconQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSubconQuery, GetCNtoSubconQueryVariables>(GetCNtoSubconDocument, baseOptions);
      }
export function useGetCNtoSubconLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSubconQuery, GetCNtoSubconQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSubconQuery, GetCNtoSubconQueryVariables>(GetCNtoSubconDocument, baseOptions);
        }
export type GetCNtoSubconQueryHookResult = ReturnType<typeof useGetCNtoSubconQuery>;
export type GetCNtoSubconLazyQueryHookResult = ReturnType<typeof useGetCNtoSubconLazyQuery>;
export type GetCNtoSubconQueryResult = ApolloReactCommon.QueryResult<GetCNtoSubconQuery, GetCNtoSubconQueryVariables>;
export const GetCNtoSubconListingDocument = gql`
    query getCNtoSubconListing($ID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getCNtoSubcon(ID: $ID, companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    cancellationDate
    cancellationRemarks
    chargeToRevenue
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    rejectionDate
    rejectionRemarks
    contractID
    subcontractID
    outstandingAmt
    submissionComment
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    CNtoSubconItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      costCategoryID
      revenueCategoryID
      revenueCategory {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      costClass
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  CNtoSubconInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
  getCNtoSubconCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetCNtoSubconListingQuery__
 *
 * To run a query within a React component, call `useGetCNtoSubconListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSubconListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSubconListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetCNtoSubconListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSubconListingQuery, GetCNtoSubconListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSubconListingQuery, GetCNtoSubconListingQueryVariables>(GetCNtoSubconListingDocument, baseOptions);
      }
export function useGetCNtoSubconListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSubconListingQuery, GetCNtoSubconListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSubconListingQuery, GetCNtoSubconListingQueryVariables>(GetCNtoSubconListingDocument, baseOptions);
        }
export type GetCNtoSubconListingQueryHookResult = ReturnType<typeof useGetCNtoSubconListingQuery>;
export type GetCNtoSubconListingLazyQueryHookResult = ReturnType<typeof useGetCNtoSubconListingLazyQuery>;
export type GetCNtoSubconListingQueryResult = ApolloReactCommon.QueryResult<GetCNtoSubconListingQuery, GetCNtoSubconListingQueryVariables>;
export const CreateCNtoSubconDocument = gql`
    mutation createCNtoSubcon($input: SubcontractTransactionWithGLItemInput!) {
  createCNtoSubcon(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type CreateCNtoSubconMutationFn = ApolloReactCommon.MutationFunction<CreateCNtoSubconMutation, CreateCNtoSubconMutationVariables>;

/**
 * __useCreateCNtoSubconMutation__
 *
 * To run a mutation, you first call `useCreateCNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCNtoSubconMutation, { data, loading, error }] = useCreateCNtoSubconMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCNtoSubconMutation, CreateCNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCNtoSubconMutation, CreateCNtoSubconMutationVariables>(CreateCNtoSubconDocument, baseOptions);
      }
export type CreateCNtoSubconMutationHookResult = ReturnType<typeof useCreateCNtoSubconMutation>;
export type CreateCNtoSubconMutationResult = ApolloReactCommon.MutationResult<CreateCNtoSubconMutation>;
export type CreateCNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCNtoSubconMutation, CreateCNtoSubconMutationVariables>;
export const UpdateCNtoSubconDocument = gql`
    mutation updateCNtoSubcon($input: SubcontractTransactionWithGLItemInput!) {
  updateCNtoSubcon(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type UpdateCNtoSubconMutationFn = ApolloReactCommon.MutationFunction<UpdateCNtoSubconMutation, UpdateCNtoSubconMutationVariables>;

/**
 * __useUpdateCNtoSubconMutation__
 *
 * To run a mutation, you first call `useUpdateCNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCNtoSubconMutation, { data, loading, error }] = useUpdateCNtoSubconMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCNtoSubconMutation, UpdateCNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCNtoSubconMutation, UpdateCNtoSubconMutationVariables>(UpdateCNtoSubconDocument, baseOptions);
      }
export type UpdateCNtoSubconMutationHookResult = ReturnType<typeof useUpdateCNtoSubconMutation>;
export type UpdateCNtoSubconMutationResult = ApolloReactCommon.MutationResult<UpdateCNtoSubconMutation>;
export type UpdateCNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCNtoSubconMutation, UpdateCNtoSubconMutationVariables>;
export const CancelSubcontractMiscInvoiceDocument = gql`
    mutation cancelSubcontractMiscInvoice($ID: String!, $input: CancelInput!) {
  cancelSubcontractMiscInvoice(ID: $ID, input: $input)
}
    `;
export type CancelSubcontractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<CancelSubcontractMiscInvoiceMutation, CancelSubcontractMiscInvoiceMutationVariables>;

/**
 * __useCancelSubcontractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelSubcontractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubcontractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubcontractMiscInvoiceMutation, { data, loading, error }] = useCancelSubcontractMiscInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubcontractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubcontractMiscInvoiceMutation, CancelSubcontractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubcontractMiscInvoiceMutation, CancelSubcontractMiscInvoiceMutationVariables>(CancelSubcontractMiscInvoiceDocument, baseOptions);
      }
export type CancelSubcontractMiscInvoiceMutationHookResult = ReturnType<typeof useCancelSubcontractMiscInvoiceMutation>;
export type CancelSubcontractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<CancelSubcontractMiscInvoiceMutation>;
export type CancelSubcontractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubcontractMiscInvoiceMutation, CancelSubcontractMiscInvoiceMutationVariables>;
export const CancelSubconDnDocument = gql`
    mutation cancelSubconDN($ID: String!, $input: CancelInput!) {
  cancelSubconDN(ID: $ID, input: $input)
}
    `;
export type CancelSubconDnMutationFn = ApolloReactCommon.MutationFunction<CancelSubconDnMutation, CancelSubconDnMutationVariables>;

/**
 * __useCancelSubconDnMutation__
 *
 * To run a mutation, you first call `useCancelSubconDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconDnMutation, { data, loading, error }] = useCancelSubconDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubconDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconDnMutation, CancelSubconDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconDnMutation, CancelSubconDnMutationVariables>(CancelSubconDnDocument, baseOptions);
      }
export type CancelSubconDnMutationHookResult = ReturnType<typeof useCancelSubconDnMutation>;
export type CancelSubconDnMutationResult = ApolloReactCommon.MutationResult<CancelSubconDnMutation>;
export type CancelSubconDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconDnMutation, CancelSubconDnMutationVariables>;
export const CancelSubconCnDocument = gql`
    mutation cancelSubconCN($ID: String!, $input: CancelInput!) {
  cancelSubconCN(ID: $ID, input: $input)
}
    `;
export type CancelSubconCnMutationFn = ApolloReactCommon.MutationFunction<CancelSubconCnMutation, CancelSubconCnMutationVariables>;

/**
 * __useCancelSubconCnMutation__
 *
 * To run a mutation, you first call `useCancelSubconCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconCnMutation, { data, loading, error }] = useCancelSubconCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubconCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconCnMutation, CancelSubconCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconCnMutation, CancelSubconCnMutationVariables>(CancelSubconCnDocument, baseOptions);
      }
export type CancelSubconCnMutationHookResult = ReturnType<typeof useCancelSubconCnMutation>;
export type CancelSubconCnMutationResult = ApolloReactCommon.MutationResult<CancelSubconCnMutation>;
export type CancelSubconCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconCnMutation, CancelSubconCnMutationVariables>;
export const CancelDNtoSubconDocument = gql`
    mutation cancelDNtoSubcon($ID: String!, $input: CancelInput!) {
  cancelDNtoSubcon(ID: $ID, input: $input)
}
    `;
export type CancelDNtoSubconMutationFn = ApolloReactCommon.MutationFunction<CancelDNtoSubconMutation, CancelDNtoSubconMutationVariables>;

/**
 * __useCancelDNtoSubconMutation__
 *
 * To run a mutation, you first call `useCancelDNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDNtoSubconMutation, { data, loading, error }] = useCancelDNtoSubconMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelDNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelDNtoSubconMutation, CancelDNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelDNtoSubconMutation, CancelDNtoSubconMutationVariables>(CancelDNtoSubconDocument, baseOptions);
      }
export type CancelDNtoSubconMutationHookResult = ReturnType<typeof useCancelDNtoSubconMutation>;
export type CancelDNtoSubconMutationResult = ApolloReactCommon.MutationResult<CancelDNtoSubconMutation>;
export type CancelDNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelDNtoSubconMutation, CancelDNtoSubconMutationVariables>;
export const CancelCNtoSubconDocument = gql`
    mutation cancelCNtoSubcon($ID: String!, $input: CancelInput!) {
  cancelCNtoSubcon(ID: $ID, input: $input)
}
    `;
export type CancelCNtoSubconMutationFn = ApolloReactCommon.MutationFunction<CancelCNtoSubconMutation, CancelCNtoSubconMutationVariables>;

/**
 * __useCancelCNtoSubconMutation__
 *
 * To run a mutation, you first call `useCancelCNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCNtoSubconMutation, { data, loading, error }] = useCancelCNtoSubconMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCNtoSubconMutation, CancelCNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCNtoSubconMutation, CancelCNtoSubconMutationVariables>(CancelCNtoSubconDocument, baseOptions);
      }
export type CancelCNtoSubconMutationHookResult = ReturnType<typeof useCancelCNtoSubconMutation>;
export type CancelCNtoSubconMutationResult = ApolloReactCommon.MutationResult<CancelCNtoSubconMutation>;
export type CancelCNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCNtoSubconMutation, CancelCNtoSubconMutationVariables>;
export const DeleteSubcontractMiscInvoiceDocument = gql`
    mutation deleteSubcontractMiscInvoice($ID: String!) {
  deleteSubcontractMiscInvoice(ID: $ID)
}
    `;
export type DeleteSubcontractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractMiscInvoiceMutation, DeleteSubcontractMiscInvoiceMutationVariables>;

/**
 * __useDeleteSubcontractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractMiscInvoiceMutation, { data, loading, error }] = useDeleteSubcontractMiscInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractMiscInvoiceMutation, DeleteSubcontractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractMiscInvoiceMutation, DeleteSubcontractMiscInvoiceMutationVariables>(DeleteSubcontractMiscInvoiceDocument, baseOptions);
      }
export type DeleteSubcontractMiscInvoiceMutationHookResult = ReturnType<typeof useDeleteSubcontractMiscInvoiceMutation>;
export type DeleteSubcontractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractMiscInvoiceMutation>;
export type DeleteSubcontractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractMiscInvoiceMutation, DeleteSubcontractMiscInvoiceMutationVariables>;
export const DeleteSubcontractCnDocument = gql`
    mutation deleteSubcontractCN($ID: String!) {
  deleteSubcontractCN(ID: $ID)
}
    `;
export type DeleteSubcontractCnMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractCnMutation, DeleteSubcontractCnMutationVariables>;

/**
 * __useDeleteSubcontractCnMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractCnMutation, { data, loading, error }] = useDeleteSubcontractCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractCnMutation, DeleteSubcontractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractCnMutation, DeleteSubcontractCnMutationVariables>(DeleteSubcontractCnDocument, baseOptions);
      }
export type DeleteSubcontractCnMutationHookResult = ReturnType<typeof useDeleteSubcontractCnMutation>;
export type DeleteSubcontractCnMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractCnMutation>;
export type DeleteSubcontractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractCnMutation, DeleteSubcontractCnMutationVariables>;
export const DeleteSubcontractDnDocument = gql`
    mutation deleteSubcontractDN($ID: String!) {
  deleteSubcontractDN(ID: $ID)
}
    `;
export type DeleteSubcontractDnMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractDnMutation, DeleteSubcontractDnMutationVariables>;

/**
 * __useDeleteSubcontractDnMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractDnMutation, { data, loading, error }] = useDeleteSubcontractDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractDnMutation, DeleteSubcontractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractDnMutation, DeleteSubcontractDnMutationVariables>(DeleteSubcontractDnDocument, baseOptions);
      }
export type DeleteSubcontractDnMutationHookResult = ReturnType<typeof useDeleteSubcontractDnMutation>;
export type DeleteSubcontractDnMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractDnMutation>;
export type DeleteSubcontractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractDnMutation, DeleteSubcontractDnMutationVariables>;
export const DeleteDNtoSubconDocument = gql`
    mutation deleteDNtoSubcon($ID: String!) {
  deleteDNtoSubcon(ID: $ID)
}
    `;
export type DeleteDNtoSubconMutationFn = ApolloReactCommon.MutationFunction<DeleteDNtoSubconMutation, DeleteDNtoSubconMutationVariables>;

/**
 * __useDeleteDNtoSubconMutation__
 *
 * To run a mutation, you first call `useDeleteDNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDNtoSubconMutation, { data, loading, error }] = useDeleteDNtoSubconMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteDNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDNtoSubconMutation, DeleteDNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDNtoSubconMutation, DeleteDNtoSubconMutationVariables>(DeleteDNtoSubconDocument, baseOptions);
      }
export type DeleteDNtoSubconMutationHookResult = ReturnType<typeof useDeleteDNtoSubconMutation>;
export type DeleteDNtoSubconMutationResult = ApolloReactCommon.MutationResult<DeleteDNtoSubconMutation>;
export type DeleteDNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDNtoSubconMutation, DeleteDNtoSubconMutationVariables>;
export const DeleteCNtoSubconDocument = gql`
    mutation deleteCNtoSubcon($ID: String!) {
  deleteCNtoSubcon(ID: $ID)
}
    `;
export type DeleteCNtoSubconMutationFn = ApolloReactCommon.MutationFunction<DeleteCNtoSubconMutation, DeleteCNtoSubconMutationVariables>;

/**
 * __useDeleteCNtoSubconMutation__
 *
 * To run a mutation, you first call `useDeleteCNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCNtoSubconMutation, { data, loading, error }] = useDeleteCNtoSubconMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteCNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCNtoSubconMutation, DeleteCNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCNtoSubconMutation, DeleteCNtoSubconMutationVariables>(DeleteCNtoSubconDocument, baseOptions);
      }
export type DeleteCNtoSubconMutationHookResult = ReturnType<typeof useDeleteCNtoSubconMutation>;
export type DeleteCNtoSubconMutationResult = ApolloReactCommon.MutationResult<DeleteCNtoSubconMutation>;
export type DeleteCNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCNtoSubconMutation, DeleteCNtoSubconMutationVariables>;
export const GetSubconAdjustmentDocument = gql`
    query getSubconAdjustment($contractID: String, $companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus) {
  getCNtoSubcon(companyID: $companyID, contractID: $contractID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docAmt
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    deductionTypeID
    deductionType {
      ID
      code
    }
  }
  getDNtoSubconRevenue(contractID: $contractID, withRev: false, transactionStatus: APPROVED) {
    ID
    docAmt
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    deductionTypeID
    cancelledClaimAmt
    deductionType {
      ID
      code
    }
  }
  getSubcontractDN(companyID: $companyID, contractID: $contractID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docAmt
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    deductionTypeID
    deductionType {
      ID
      code
    }
  }
  getSubcontractCN(companyID: $companyID, contractID: $contractID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docAmt
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    deductionTypeID
    deductionType {
      ID
      code
    }
  }
}
    `;

/**
 * __useGetSubconAdjustmentQuery__
 *
 * To run a query within a React component, call `useGetSubconAdjustmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconAdjustmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconAdjustmentQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetSubconAdjustmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconAdjustmentQuery, GetSubconAdjustmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconAdjustmentQuery, GetSubconAdjustmentQueryVariables>(GetSubconAdjustmentDocument, baseOptions);
      }
export function useGetSubconAdjustmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconAdjustmentQuery, GetSubconAdjustmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconAdjustmentQuery, GetSubconAdjustmentQueryVariables>(GetSubconAdjustmentDocument, baseOptions);
        }
export type GetSubconAdjustmentQueryHookResult = ReturnType<typeof useGetSubconAdjustmentQuery>;
export type GetSubconAdjustmentLazyQueryHookResult = ReturnType<typeof useGetSubconAdjustmentLazyQuery>;
export type GetSubconAdjustmentQueryResult = ApolloReactCommon.QueryResult<GetSubconAdjustmentQuery, GetSubconAdjustmentQueryVariables>;
export const GetSubcontractMiscInvoiceCountDocument = gql`
    query getSubcontractMiscInvoiceCount($companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus) {
  getSubcontractMiscInvoiceCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetSubcontractMiscInvoiceCountQuery__
 *
 * To run a query within a React component, call `useGetSubcontractMiscInvoiceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractMiscInvoiceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractMiscInvoiceCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetSubcontractMiscInvoiceCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractMiscInvoiceCountQuery, GetSubcontractMiscInvoiceCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractMiscInvoiceCountQuery, GetSubcontractMiscInvoiceCountQueryVariables>(GetSubcontractMiscInvoiceCountDocument, baseOptions);
      }
export function useGetSubcontractMiscInvoiceCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractMiscInvoiceCountQuery, GetSubcontractMiscInvoiceCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractMiscInvoiceCountQuery, GetSubcontractMiscInvoiceCountQueryVariables>(GetSubcontractMiscInvoiceCountDocument, baseOptions);
        }
export type GetSubcontractMiscInvoiceCountQueryHookResult = ReturnType<typeof useGetSubcontractMiscInvoiceCountQuery>;
export type GetSubcontractMiscInvoiceCountLazyQueryHookResult = ReturnType<typeof useGetSubcontractMiscInvoiceCountLazyQuery>;
export type GetSubcontractMiscInvoiceCountQueryResult = ApolloReactCommon.QueryResult<GetSubcontractMiscInvoiceCountQuery, GetSubcontractMiscInvoiceCountQueryVariables>;
export const GetSubcontractDnCountDocument = gql`
    query getSubcontractDNCount($companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus) {
  getSubcontractDNCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetSubcontractDnCountQuery__
 *
 * To run a query within a React component, call `useGetSubcontractDnCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractDnCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractDnCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetSubcontractDnCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractDnCountQuery, GetSubcontractDnCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractDnCountQuery, GetSubcontractDnCountQueryVariables>(GetSubcontractDnCountDocument, baseOptions);
      }
export function useGetSubcontractDnCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractDnCountQuery, GetSubcontractDnCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractDnCountQuery, GetSubcontractDnCountQueryVariables>(GetSubcontractDnCountDocument, baseOptions);
        }
export type GetSubcontractDnCountQueryHookResult = ReturnType<typeof useGetSubcontractDnCountQuery>;
export type GetSubcontractDnCountLazyQueryHookResult = ReturnType<typeof useGetSubcontractDnCountLazyQuery>;
export type GetSubcontractDnCountQueryResult = ApolloReactCommon.QueryResult<GetSubcontractDnCountQuery, GetSubcontractDnCountQueryVariables>;
export const GetSubcontractCnCountDocument = gql`
    query getSubcontractCNCount($companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus) {
  getSubcontractCNCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetSubcontractCnCountQuery__
 *
 * To run a query within a React component, call `useGetSubcontractCnCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractCnCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractCnCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetSubcontractCnCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractCnCountQuery, GetSubcontractCnCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractCnCountQuery, GetSubcontractCnCountQueryVariables>(GetSubcontractCnCountDocument, baseOptions);
      }
export function useGetSubcontractCnCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractCnCountQuery, GetSubcontractCnCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractCnCountQuery, GetSubcontractCnCountQueryVariables>(GetSubcontractCnCountDocument, baseOptions);
        }
export type GetSubcontractCnCountQueryHookResult = ReturnType<typeof useGetSubcontractCnCountQuery>;
export type GetSubcontractCnCountLazyQueryHookResult = ReturnType<typeof useGetSubcontractCnCountLazyQuery>;
export type GetSubcontractCnCountQueryResult = ApolloReactCommon.QueryResult<GetSubcontractCnCountQuery, GetSubcontractCnCountQueryVariables>;
export const GetDNtoSubconCountDocument = gql`
    query getDNtoSubconCount($companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus) {
  getDNtoSubconCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetDNtoSubconCountQuery__
 *
 * To run a query within a React component, call `useGetDNtoSubconCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSubconCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSubconCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetDNtoSubconCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSubconCountQuery, GetDNtoSubconCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSubconCountQuery, GetDNtoSubconCountQueryVariables>(GetDNtoSubconCountDocument, baseOptions);
      }
export function useGetDNtoSubconCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSubconCountQuery, GetDNtoSubconCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSubconCountQuery, GetDNtoSubconCountQueryVariables>(GetDNtoSubconCountDocument, baseOptions);
        }
export type GetDNtoSubconCountQueryHookResult = ReturnType<typeof useGetDNtoSubconCountQuery>;
export type GetDNtoSubconCountLazyQueryHookResult = ReturnType<typeof useGetDNtoSubconCountLazyQuery>;
export type GetDNtoSubconCountQueryResult = ApolloReactCommon.QueryResult<GetDNtoSubconCountQuery, GetDNtoSubconCountQueryVariables>;
export const GetCNtoSubconCountDocument = gql`
    query getCNtoSubconCount($companyID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus) {
  getCNtoSubconCount(companyID: $companyID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetCNtoSubconCountQuery__
 *
 * To run a query within a React component, call `useGetCNtoSubconCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSubconCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSubconCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetCNtoSubconCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSubconCountQuery, GetCNtoSubconCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSubconCountQuery, GetCNtoSubconCountQueryVariables>(GetCNtoSubconCountDocument, baseOptions);
      }
export function useGetCNtoSubconCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSubconCountQuery, GetCNtoSubconCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSubconCountQuery, GetCNtoSubconCountQueryVariables>(GetCNtoSubconCountDocument, baseOptions);
        }
export type GetCNtoSubconCountQueryHookResult = ReturnType<typeof useGetCNtoSubconCountQuery>;
export type GetCNtoSubconCountLazyQueryHookResult = ReturnType<typeof useGetCNtoSubconCountLazyQuery>;
export type GetCNtoSubconCountQueryResult = ApolloReactCommon.QueryResult<GetCNtoSubconCountQuery, GetCNtoSubconCountQueryVariables>;
export const GetContractSubconTenderDocument = gql`
    query getContractSubconTender($companyID: String, $orderByAsc: String) {
  getContract(companyID: $companyID, orderByAsc: $orderByAsc) {
    ID
    title
    contractSum
    revisedContractSum
    contractStatus
    subcontractTenderSummary
    subcontractTenderDetail {
      ID
    }
  }
}
    `;

/**
 * __useGetContractSubconTenderQuery__
 *
 * To run a query within a React component, call `useGetContractSubconTenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSubconTenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSubconTenderQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetContractSubconTenderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSubconTenderQuery, GetContractSubconTenderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSubconTenderQuery, GetContractSubconTenderQueryVariables>(GetContractSubconTenderDocument, baseOptions);
      }
export function useGetContractSubconTenderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSubconTenderQuery, GetContractSubconTenderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSubconTenderQuery, GetContractSubconTenderQueryVariables>(GetContractSubconTenderDocument, baseOptions);
        }
export type GetContractSubconTenderQueryHookResult = ReturnType<typeof useGetContractSubconTenderQuery>;
export type GetContractSubconTenderLazyQueryHookResult = ReturnType<typeof useGetContractSubconTenderLazyQuery>;
export type GetContractSubconTenderQueryResult = ApolloReactCommon.QueryResult<GetContractSubconTenderQuery, GetContractSubconTenderQueryVariables>;
export const GetSubcontractTenderDocument = gql`
    query getSubcontractTender($ID: String, $contractID: String, $orderByAsc: String) {
  getSubcontractTender(ID: $ID, contractID: $contractID, orderByAsc: $orderByAsc) {
    ID
    contractID
    title
    budgetAmt
    description
    tenderDate
    startDate
    closeDate
    startTime
    closeDate
    endTime
    modTs
    siteID
    costCategoryID
    refNo
    subconTenderStatus
    subcontract {
      ID
      accountID
      title
      description
      subcontractorID
      subcontractTenderID
      subcontractNo
    }
    subconInvitation {
      ID
      subcontractorID
      subcontractTenderID
      subcontractor {
        ID
        name
        ratingType
        pkkDetails
        cidbDetails
        subconRfqSubmission {
          ID
          quotationAmt
        }
        subcontract {
          subcontractStatus
        }
      }
    }
    subconRfqSubmission {
      ID
      subcontractTenderID
      subcontractorID
      quotationNo
      quotationAmt
      submissionDate
    }
  }
}
    `;

/**
 * __useGetSubcontractTenderQuery__
 *
 * To run a query within a React component, call `useGetSubcontractTenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractTenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractTenderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractTenderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractTenderQuery, GetSubcontractTenderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractTenderQuery, GetSubcontractTenderQueryVariables>(GetSubcontractTenderDocument, baseOptions);
      }
export function useGetSubcontractTenderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractTenderQuery, GetSubcontractTenderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractTenderQuery, GetSubcontractTenderQueryVariables>(GetSubcontractTenderDocument, baseOptions);
        }
export type GetSubcontractTenderQueryHookResult = ReturnType<typeof useGetSubcontractTenderQuery>;
export type GetSubcontractTenderLazyQueryHookResult = ReturnType<typeof useGetSubcontractTenderLazyQuery>;
export type GetSubcontractTenderQueryResult = ApolloReactCommon.QueryResult<GetSubcontractTenderQuery, GetSubcontractTenderQueryVariables>;
export const GetSubcontractTenderSubmissionDocument = gql`
    query getSubcontractTenderSubmission($ID: String, $contractID: String) {
  getSubcontractTender(ID: $ID, contractID: $contractID) {
    ID
    title
    budgetAmt
    description
    tenderDate
    startDate
    closeDate
    costCategoryID
    modTs
    refNo
    subconInvitation {
      ID
      subcontractorID
      subcontractTenderID
      subcontractor {
        ID
        name
      }
    }
    subconRfqSubmission {
      subcontractTenderID
      subcontractorID
      quotationNo
      quotationAmt
      submissionDate
    }
  }
}
    `;

/**
 * __useGetSubcontractTenderSubmissionQuery__
 *
 * To run a query within a React component, call `useGetSubcontractTenderSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractTenderSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractTenderSubmissionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubcontractTenderSubmissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractTenderSubmissionQuery, GetSubcontractTenderSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractTenderSubmissionQuery, GetSubcontractTenderSubmissionQueryVariables>(GetSubcontractTenderSubmissionDocument, baseOptions);
      }
export function useGetSubcontractTenderSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractTenderSubmissionQuery, GetSubcontractTenderSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractTenderSubmissionQuery, GetSubcontractTenderSubmissionQueryVariables>(GetSubcontractTenderSubmissionDocument, baseOptions);
        }
export type GetSubcontractTenderSubmissionQueryHookResult = ReturnType<typeof useGetSubcontractTenderSubmissionQuery>;
export type GetSubcontractTenderSubmissionLazyQueryHookResult = ReturnType<typeof useGetSubcontractTenderSubmissionLazyQuery>;
export type GetSubcontractTenderSubmissionQueryResult = ApolloReactCommon.QueryResult<GetSubcontractTenderSubmissionQuery, GetSubcontractTenderSubmissionQueryVariables>;
export const CreateSubcontractTenderDocument = gql`
    mutation createSubcontractTender($input: SubcontractTenderInput!) {
  createSubcontractTender(input: $input) {
    ID
  }
}
    `;
export type CreateSubcontractTenderMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractTenderMutation, CreateSubcontractTenderMutationVariables>;

/**
 * __useCreateSubcontractTenderMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractTenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractTenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractTenderMutation, { data, loading, error }] = useCreateSubcontractTenderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractTenderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractTenderMutation, CreateSubcontractTenderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractTenderMutation, CreateSubcontractTenderMutationVariables>(CreateSubcontractTenderDocument, baseOptions);
      }
export type CreateSubcontractTenderMutationHookResult = ReturnType<typeof useCreateSubcontractTenderMutation>;
export type CreateSubcontractTenderMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractTenderMutation>;
export type CreateSubcontractTenderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractTenderMutation, CreateSubcontractTenderMutationVariables>;
export const UpdateSubcontractTenderDocument = gql`
    mutation updateSubcontractTender($input: SubcontractTenderInput!) {
  updateSubcontractTender(input: $input) {
    ID
  }
}
    `;
export type UpdateSubcontractTenderMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractTenderMutation, UpdateSubcontractTenderMutationVariables>;

/**
 * __useUpdateSubcontractTenderMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractTenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractTenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractTenderMutation, { data, loading, error }] = useUpdateSubcontractTenderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractTenderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractTenderMutation, UpdateSubcontractTenderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractTenderMutation, UpdateSubcontractTenderMutationVariables>(UpdateSubcontractTenderDocument, baseOptions);
      }
export type UpdateSubcontractTenderMutationHookResult = ReturnType<typeof useUpdateSubcontractTenderMutation>;
export type UpdateSubcontractTenderMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractTenderMutation>;
export type UpdateSubcontractTenderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractTenderMutation, UpdateSubcontractTenderMutationVariables>;
export const InviteSubcontractorDocument = gql`
    mutation inviteSubcontractor($subcontractorIDs: [String!]!, $subcontractTenderID: String!) {
  inviteSubcontractor(subcontractorIDs: $subcontractorIDs, subcontractTenderID: $subcontractTenderID)
}
    `;
export type InviteSubcontractorMutationFn = ApolloReactCommon.MutationFunction<InviteSubcontractorMutation, InviteSubcontractorMutationVariables>;

/**
 * __useInviteSubcontractorMutation__
 *
 * To run a mutation, you first call `useInviteSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteSubcontractorMutation, { data, loading, error }] = useInviteSubcontractorMutation({
 *   variables: {
 *      subcontractorIDs: // value for 'subcontractorIDs'
 *      subcontractTenderID: // value for 'subcontractTenderID'
 *   },
 * });
 */
export function useInviteSubcontractorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteSubcontractorMutation, InviteSubcontractorMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteSubcontractorMutation, InviteSubcontractorMutationVariables>(InviteSubcontractorDocument, baseOptions);
      }
export type InviteSubcontractorMutationHookResult = ReturnType<typeof useInviteSubcontractorMutation>;
export type InviteSubcontractorMutationResult = ApolloReactCommon.MutationResult<InviteSubcontractorMutation>;
export type InviteSubcontractorMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteSubcontractorMutation, InviteSubcontractorMutationVariables>;
export const UnInviteSubcontractorDocument = gql`
    mutation unInviteSubcontractor($subcontractorID: String!, $subcontractTenderID: String!) {
  unInviteSubcontractor(subcontractorID: $subcontractorID, subcontractTenderID: $subcontractTenderID)
}
    `;
export type UnInviteSubcontractorMutationFn = ApolloReactCommon.MutationFunction<UnInviteSubcontractorMutation, UnInviteSubcontractorMutationVariables>;

/**
 * __useUnInviteSubcontractorMutation__
 *
 * To run a mutation, you first call `useUnInviteSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnInviteSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unInviteSubcontractorMutation, { data, loading, error }] = useUnInviteSubcontractorMutation({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *      subcontractTenderID: // value for 'subcontractTenderID'
 *   },
 * });
 */
export function useUnInviteSubcontractorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnInviteSubcontractorMutation, UnInviteSubcontractorMutationVariables>) {
        return ApolloReactHooks.useMutation<UnInviteSubcontractorMutation, UnInviteSubcontractorMutationVariables>(UnInviteSubcontractorDocument, baseOptions);
      }
export type UnInviteSubcontractorMutationHookResult = ReturnType<typeof useUnInviteSubcontractorMutation>;
export type UnInviteSubcontractorMutationResult = ApolloReactCommon.MutationResult<UnInviteSubcontractorMutation>;
export type UnInviteSubcontractorMutationOptions = ApolloReactCommon.BaseMutationOptions<UnInviteSubcontractorMutation, UnInviteSubcontractorMutationVariables>;
export const DeleteSubcontractTenderDocument = gql`
    mutation deleteSubcontractTender($input: SubcontractTenderInput!) {
  deleteSubcontractTender(input: $input)
}
    `;
export type DeleteSubcontractTenderMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractTenderMutation, DeleteSubcontractTenderMutationVariables>;

/**
 * __useDeleteSubcontractTenderMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractTenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractTenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractTenderMutation, { data, loading, error }] = useDeleteSubcontractTenderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSubcontractTenderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractTenderMutation, DeleteSubcontractTenderMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractTenderMutation, DeleteSubcontractTenderMutationVariables>(DeleteSubcontractTenderDocument, baseOptions);
      }
export type DeleteSubcontractTenderMutationHookResult = ReturnType<typeof useDeleteSubcontractTenderMutation>;
export type DeleteSubcontractTenderMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractTenderMutation>;
export type DeleteSubcontractTenderMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractTenderMutation, DeleteSubcontractTenderMutationVariables>;
export const CreateSubconRfqSubmissionDocument = gql`
    mutation createSubconRFQSubmission($input: SubconRFQSubmissionInput!) {
  createSubconRFQSubmission(input: $input) {
    ID
  }
}
    `;
export type CreateSubconRfqSubmissionMutationFn = ApolloReactCommon.MutationFunction<CreateSubconRfqSubmissionMutation, CreateSubconRfqSubmissionMutationVariables>;

/**
 * __useCreateSubconRfqSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateSubconRfqSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubconRfqSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubconRfqSubmissionMutation, { data, loading, error }] = useCreateSubconRfqSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubconRfqSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubconRfqSubmissionMutation, CreateSubconRfqSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubconRfqSubmissionMutation, CreateSubconRfqSubmissionMutationVariables>(CreateSubconRfqSubmissionDocument, baseOptions);
      }
export type CreateSubconRfqSubmissionMutationHookResult = ReturnType<typeof useCreateSubconRfqSubmissionMutation>;
export type CreateSubconRfqSubmissionMutationResult = ApolloReactCommon.MutationResult<CreateSubconRfqSubmissionMutation>;
export type CreateSubconRfqSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubconRfqSubmissionMutation, CreateSubconRfqSubmissionMutationVariables>;
export const GetSubconRfqSubmissionDocument = gql`
    query getSubconRFQSubmission($subcontractorInvitationID: String, $orderByDesc: String, $orderByAsc: String, $subcontractorID: String, $subcontractTenderID: String, $contractID: String) {
  getSubconRFQSubmission(subcontractorInvitationID: $subcontractorInvitationID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, subcontractorID: $subcontractorID, subcontractTenderID: $subcontractTenderID, contractID: $contractID) {
    ID
    submissionDate
    quotationAmt
    quotationNo
    remarks
  }
}
    `;

/**
 * __useGetSubconRfqSubmissionQuery__
 *
 * To run a query within a React component, call `useGetSubconRfqSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconRfqSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconRfqSubmissionQuery({
 *   variables: {
 *      subcontractorInvitationID: // value for 'subcontractorInvitationID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      subcontractorID: // value for 'subcontractorID'
 *      subcontractTenderID: // value for 'subcontractTenderID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubconRfqSubmissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconRfqSubmissionQuery, GetSubconRfqSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconRfqSubmissionQuery, GetSubconRfqSubmissionQueryVariables>(GetSubconRfqSubmissionDocument, baseOptions);
      }
export function useGetSubconRfqSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconRfqSubmissionQuery, GetSubconRfqSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconRfqSubmissionQuery, GetSubconRfqSubmissionQueryVariables>(GetSubconRfqSubmissionDocument, baseOptions);
        }
export type GetSubconRfqSubmissionQueryHookResult = ReturnType<typeof useGetSubconRfqSubmissionQuery>;
export type GetSubconRfqSubmissionLazyQueryHookResult = ReturnType<typeof useGetSubconRfqSubmissionLazyQuery>;
export type GetSubconRfqSubmissionQueryResult = ApolloReactCommon.QueryResult<GetSubconRfqSubmissionQuery, GetSubconRfqSubmissionQueryVariables>;
export const TenderToSubconGenerationDocument = gql`
    mutation tenderToSubconGeneration($ID: String!, $input: SubconTenderGenerationInput!) {
  tenderToSubconGeneration(ID: $ID, input: $input)
}
    `;
export type TenderToSubconGenerationMutationFn = ApolloReactCommon.MutationFunction<TenderToSubconGenerationMutation, TenderToSubconGenerationMutationVariables>;

/**
 * __useTenderToSubconGenerationMutation__
 *
 * To run a mutation, you first call `useTenderToSubconGenerationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenderToSubconGenerationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenderToSubconGenerationMutation, { data, loading, error }] = useTenderToSubconGenerationMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTenderToSubconGenerationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TenderToSubconGenerationMutation, TenderToSubconGenerationMutationVariables>) {
        return ApolloReactHooks.useMutation<TenderToSubconGenerationMutation, TenderToSubconGenerationMutationVariables>(TenderToSubconGenerationDocument, baseOptions);
      }
export type TenderToSubconGenerationMutationHookResult = ReturnType<typeof useTenderToSubconGenerationMutation>;
export type TenderToSubconGenerationMutationResult = ApolloReactCommon.MutationResult<TenderToSubconGenerationMutation>;
export type TenderToSubconGenerationMutationOptions = ApolloReactCommon.BaseMutationOptions<TenderToSubconGenerationMutation, TenderToSubconGenerationMutationVariables>;
export const GetSubcontractorInvitationDocument = gql`
    query getSubcontractorInvitation($ID: String, $subcontractorID: String, $subcontractTenderID: String) {
  getSubcontractorInvitation(ID: $ID, subcontractorID: $subcontractorID, subcontractTenderID: $subcontractTenderID) {
    ID
    accountID
    subcontractorID
    subcontractTenderID
    remarks
  }
}
    `;

/**
 * __useGetSubcontractorInvitationQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorInvitationQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractorID: // value for 'subcontractorID'
 *      subcontractTenderID: // value for 'subcontractTenderID'
 *   },
 * });
 */
export function useGetSubcontractorInvitationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorInvitationQuery, GetSubcontractorInvitationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorInvitationQuery, GetSubcontractorInvitationQueryVariables>(GetSubcontractorInvitationDocument, baseOptions);
      }
export function useGetSubcontractorInvitationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorInvitationQuery, GetSubcontractorInvitationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorInvitationQuery, GetSubcontractorInvitationQueryVariables>(GetSubcontractorInvitationDocument, baseOptions);
        }
export type GetSubcontractorInvitationQueryHookResult = ReturnType<typeof useGetSubcontractorInvitationQuery>;
export type GetSubcontractorInvitationLazyQueryHookResult = ReturnType<typeof useGetSubcontractorInvitationLazyQuery>;
export type GetSubcontractorInvitationQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorInvitationQuery, GetSubcontractorInvitationQueryVariables>;
export const GetSuggestedSubcontractorDocument = gql`
    query getSuggestedSubcontractor($subcontractTenderID: String!) {
  getSuggestedSubcontractor(subcontractTenderID: $subcontractTenderID) {
    name
    ID
    registrationNo
    description
    cidbDetails
    ratingType
    pkkDetails
    subcontract {
      ID
      title
      subcontractSum
    }
    cidbDetails
  }
}
    `;

/**
 * __useGetSuggestedSubcontractorQuery__
 *
 * To run a query within a React component, call `useGetSuggestedSubcontractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedSubcontractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedSubcontractorQuery({
 *   variables: {
 *      subcontractTenderID: // value for 'subcontractTenderID'
 *   },
 * });
 */
export function useGetSuggestedSubcontractorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSuggestedSubcontractorQuery, GetSuggestedSubcontractorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSuggestedSubcontractorQuery, GetSuggestedSubcontractorQueryVariables>(GetSuggestedSubcontractorDocument, baseOptions);
      }
export function useGetSuggestedSubcontractorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSuggestedSubcontractorQuery, GetSuggestedSubcontractorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSuggestedSubcontractorQuery, GetSuggestedSubcontractorQueryVariables>(GetSuggestedSubcontractorDocument, baseOptions);
        }
export type GetSuggestedSubcontractorQueryHookResult = ReturnType<typeof useGetSuggestedSubcontractorQuery>;
export type GetSuggestedSubcontractorLazyQueryHookResult = ReturnType<typeof useGetSuggestedSubcontractorLazyQuery>;
export type GetSuggestedSubcontractorQueryResult = ApolloReactCommon.QueryResult<GetSuggestedSubcontractorQuery, GetSuggestedSubcontractorQueryVariables>;
export const GetInvitedSubcontractorDocument = gql`
    query getInvitedSubcontractor($contractID: String!, $subcontractTenderID: String!) {
  getInvitedSubcontractor(contractID: $contractID, subcontractTenderID: $subcontractTenderID)
}
    `;

/**
 * __useGetInvitedSubcontractorQuery__
 *
 * To run a query within a React component, call `useGetInvitedSubcontractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedSubcontractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedSubcontractorQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractTenderID: // value for 'subcontractTenderID'
 *   },
 * });
 */
export function useGetInvitedSubcontractorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvitedSubcontractorQuery, GetInvitedSubcontractorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvitedSubcontractorQuery, GetInvitedSubcontractorQueryVariables>(GetInvitedSubcontractorDocument, baseOptions);
      }
export function useGetInvitedSubcontractorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvitedSubcontractorQuery, GetInvitedSubcontractorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvitedSubcontractorQuery, GetInvitedSubcontractorQueryVariables>(GetInvitedSubcontractorDocument, baseOptions);
        }
export type GetInvitedSubcontractorQueryHookResult = ReturnType<typeof useGetInvitedSubcontractorQuery>;
export type GetInvitedSubcontractorLazyQueryHookResult = ReturnType<typeof useGetInvitedSubcontractorLazyQuery>;
export type GetInvitedSubcontractorQueryResult = ApolloReactCommon.QueryResult<GetInvitedSubcontractorQuery, GetInvitedSubcontractorQueryVariables>;
export const GetSubcontractorSubmenuSummaryDocument = gql`
    query getSubcontractorSubmenuSummary($companyID: String, $subcontractID: String, $subcontractorID: String) {
  subconProfileDate(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  subconLedgersInfo(companyID: $companyID, subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  getSubcontractor(ID: $subcontractorID) {
    ID
    name
    activeSubcontract(companyID: $companyID)
  }
}
    `;

/**
 * __useGetSubcontractorSubmenuSummaryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorSubmenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorSubmenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorSubmenuSummaryQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractorSubmenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorSubmenuSummaryQuery, GetSubcontractorSubmenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorSubmenuSummaryQuery, GetSubcontractorSubmenuSummaryQueryVariables>(GetSubcontractorSubmenuSummaryDocument, baseOptions);
      }
export function useGetSubcontractorSubmenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorSubmenuSummaryQuery, GetSubcontractorSubmenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorSubmenuSummaryQuery, GetSubcontractorSubmenuSummaryQueryVariables>(GetSubcontractorSubmenuSummaryDocument, baseOptions);
        }
export type GetSubcontractorSubmenuSummaryQueryHookResult = ReturnType<typeof useGetSubcontractorSubmenuSummaryQuery>;
export type GetSubcontractorSubmenuSummaryLazyQueryHookResult = ReturnType<typeof useGetSubcontractorSubmenuSummaryLazyQuery>;
export type GetSubcontractorSubmenuSummaryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorSubmenuSummaryQuery, GetSubcontractorSubmenuSummaryQueryVariables>;
export const GetSubcontractorCompanyAssignmentDocument = gql`
    query getSubcontractorCompanyAssignment($ID: String, $companyID: String, $subcontractorID: String, $orderByDesc: String, $orderByAsc: String) {
  getSubcontractorCompanyAssignment(ID: $ID, companyID: $companyID, subcontractorID: $subcontractorID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    companyID
    subcontractorID
    subcontractor {
      ID
      taxSchemeID
      name
      registrationNo
      email
      description
    }
  }
}
    `;

/**
 * __useGetSubcontractorCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorCompanyAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      subcontractorID: // value for 'subcontractorID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorCompanyAssignmentQuery, GetSubcontractorCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorCompanyAssignmentQuery, GetSubcontractorCompanyAssignmentQueryVariables>(GetSubcontractorCompanyAssignmentDocument, baseOptions);
      }
export function useGetSubcontractorCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorCompanyAssignmentQuery, GetSubcontractorCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorCompanyAssignmentQuery, GetSubcontractorCompanyAssignmentQueryVariables>(GetSubcontractorCompanyAssignmentDocument, baseOptions);
        }
export type GetSubcontractorCompanyAssignmentQueryHookResult = ReturnType<typeof useGetSubcontractorCompanyAssignmentQuery>;
export type GetSubcontractorCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetSubcontractorCompanyAssignmentLazyQuery>;
export type GetSubcontractorCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorCompanyAssignmentQuery, GetSubcontractorCompanyAssignmentQueryVariables>;
export const AssignSubconCompanyDocument = gql`
    mutation assignSubconCompany($subcontractorID: String!, $companyID: [String!]!) {
  assignSubconCompany(subcontractorID: $subcontractorID, companyID: $companyID)
}
    `;
export type AssignSubconCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignSubconCompanyMutation, AssignSubconCompanyMutationVariables>;

/**
 * __useAssignSubconCompanyMutation__
 *
 * To run a mutation, you first call `useAssignSubconCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSubconCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSubconCompanyMutation, { data, loading, error }] = useAssignSubconCompanyMutation({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useAssignSubconCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignSubconCompanyMutation, AssignSubconCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignSubconCompanyMutation, AssignSubconCompanyMutationVariables>(AssignSubconCompanyDocument, baseOptions);
      }
export type AssignSubconCompanyMutationHookResult = ReturnType<typeof useAssignSubconCompanyMutation>;
export type AssignSubconCompanyMutationResult = ApolloReactCommon.MutationResult<AssignSubconCompanyMutation>;
export type AssignSubconCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignSubconCompanyMutation, AssignSubconCompanyMutationVariables>;
export const UnassignSubconCompanyDocument = gql`
    mutation unassignSubconCompany($subcontractorID: String!, $companyID: [String!]!) {
  unassignSubconCompany(subcontractorID: $subcontractorID, companyID: $companyID)
}
    `;
export type UnassignSubconCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignSubconCompanyMutation, UnassignSubconCompanyMutationVariables>;

/**
 * __useUnassignSubconCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignSubconCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignSubconCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignSubconCompanyMutation, { data, loading, error }] = useUnassignSubconCompanyMutation({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useUnassignSubconCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignSubconCompanyMutation, UnassignSubconCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignSubconCompanyMutation, UnassignSubconCompanyMutationVariables>(UnassignSubconCompanyDocument, baseOptions);
      }
export type UnassignSubconCompanyMutationHookResult = ReturnType<typeof useUnassignSubconCompanyMutation>;
export type UnassignSubconCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignSubconCompanyMutation>;
export type UnassignSubconCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignSubconCompanyMutation, UnassignSubconCompanyMutationVariables>;
export const GetSubscriptionCurrencyDocument = gql`
    query getSubscriptionCurrency {
  getSubscriptionCurrency {
    ID
    name
    code
    symbol
    commonStatus
  }
}
    `;

/**
 * __useGetSubscriptionCurrencyQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionCurrencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionCurrencyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubscriptionCurrencyQuery, GetSubscriptionCurrencyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubscriptionCurrencyQuery, GetSubscriptionCurrencyQueryVariables>(GetSubscriptionCurrencyDocument, baseOptions);
      }
export function useGetSubscriptionCurrencyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubscriptionCurrencyQuery, GetSubscriptionCurrencyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubscriptionCurrencyQuery, GetSubscriptionCurrencyQueryVariables>(GetSubscriptionCurrencyDocument, baseOptions);
        }
export type GetSubscriptionCurrencyQueryHookResult = ReturnType<typeof useGetSubscriptionCurrencyQuery>;
export type GetSubscriptionCurrencyLazyQueryHookResult = ReturnType<typeof useGetSubscriptionCurrencyLazyQuery>;
export type GetSubscriptionCurrencyQueryResult = ApolloReactCommon.QueryResult<GetSubscriptionCurrencyQuery, GetSubscriptionCurrencyQueryVariables>;
export const GetBatchPaymentHeaderDocument = gql`
    query getBatchPaymentHeader($ID: String) {
  getBatchPaymentHeader(ID: $ID) {
    ID
    description
    bankAccountID
    submittedDate
    submittedBy
    paidDate
    paidBy
    totalAmt
    batchPaymentDetail {
      ID
      batchPaymentHeaderID
      docRefTable
      docRefID
      associateRefTable
      associateRefID
      paymentAmt
      APPaymentID
    }
  }
}
    `;

/**
 * __useGetBatchPaymentHeaderQuery__
 *
 * To run a query within a React component, call `useGetBatchPaymentHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchPaymentHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchPaymentHeaderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetBatchPaymentHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBatchPaymentHeaderQuery, GetBatchPaymentHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBatchPaymentHeaderQuery, GetBatchPaymentHeaderQueryVariables>(GetBatchPaymentHeaderDocument, baseOptions);
      }
export function useGetBatchPaymentHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatchPaymentHeaderQuery, GetBatchPaymentHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBatchPaymentHeaderQuery, GetBatchPaymentHeaderQueryVariables>(GetBatchPaymentHeaderDocument, baseOptions);
        }
export type GetBatchPaymentHeaderQueryHookResult = ReturnType<typeof useGetBatchPaymentHeaderQuery>;
export type GetBatchPaymentHeaderLazyQueryHookResult = ReturnType<typeof useGetBatchPaymentHeaderLazyQuery>;
export type GetBatchPaymentHeaderQueryResult = ApolloReactCommon.QueryResult<GetBatchPaymentHeaderQuery, GetBatchPaymentHeaderQueryVariables>;
export const GetBatchPaymentDetailDocument = gql`
    query getBatchPaymentDetail($ID: String) {
  getBatchPaymentDetail(ID: $ID) {
    ID
    batchPaymentHeaderID
    docRefTable
    docRefID
    associateRefTable
    associateRefID
    paymentAmt
    APPaymentID
  }
}
    `;

/**
 * __useGetBatchPaymentDetailQuery__
 *
 * To run a query within a React component, call `useGetBatchPaymentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchPaymentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchPaymentDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetBatchPaymentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBatchPaymentDetailQuery, GetBatchPaymentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBatchPaymentDetailQuery, GetBatchPaymentDetailQueryVariables>(GetBatchPaymentDetailDocument, baseOptions);
      }
export function useGetBatchPaymentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatchPaymentDetailQuery, GetBatchPaymentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBatchPaymentDetailQuery, GetBatchPaymentDetailQueryVariables>(GetBatchPaymentDetailDocument, baseOptions);
        }
export type GetBatchPaymentDetailQueryHookResult = ReturnType<typeof useGetBatchPaymentDetailQuery>;
export type GetBatchPaymentDetailLazyQueryHookResult = ReturnType<typeof useGetBatchPaymentDetailLazyQuery>;
export type GetBatchPaymentDetailQueryResult = ApolloReactCommon.QueryResult<GetBatchPaymentDetailQuery, GetBatchPaymentDetailQueryVariables>;
export const GetBatchPaymentDetailbySupplierDocument = gql`
    query getBatchPaymentDetailbySupplier($batchPaymentHeaderID: String, $companyID: String) {
  getBatchPaymentDetailbySupplier(batchPaymentHeaderID: $batchPaymentHeaderID, companyID: $companyID)
}
    `;

/**
 * __useGetBatchPaymentDetailbySupplierQuery__
 *
 * To run a query within a React component, call `useGetBatchPaymentDetailbySupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchPaymentDetailbySupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchPaymentDetailbySupplierQuery({
 *   variables: {
 *      batchPaymentHeaderID: // value for 'batchPaymentHeaderID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetBatchPaymentDetailbySupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBatchPaymentDetailbySupplierQuery, GetBatchPaymentDetailbySupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBatchPaymentDetailbySupplierQuery, GetBatchPaymentDetailbySupplierQueryVariables>(GetBatchPaymentDetailbySupplierDocument, baseOptions);
      }
export function useGetBatchPaymentDetailbySupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatchPaymentDetailbySupplierQuery, GetBatchPaymentDetailbySupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBatchPaymentDetailbySupplierQuery, GetBatchPaymentDetailbySupplierQueryVariables>(GetBatchPaymentDetailbySupplierDocument, baseOptions);
        }
export type GetBatchPaymentDetailbySupplierQueryHookResult = ReturnType<typeof useGetBatchPaymentDetailbySupplierQuery>;
export type GetBatchPaymentDetailbySupplierLazyQueryHookResult = ReturnType<typeof useGetBatchPaymentDetailbySupplierLazyQuery>;
export type GetBatchPaymentDetailbySupplierQueryResult = ApolloReactCommon.QueryResult<GetBatchPaymentDetailbySupplierQuery, GetBatchPaymentDetailbySupplierQueryVariables>;
export const GetBatchPaymentbyStatusDocument = gql`
    query getBatchPaymentbyStatus($batchPaymentID: String, $companyID: String, $StatusArr: String, $limit: Float, $offSet: Float) {
  getBatchPaymentbyStatus(batchPaymentID: $batchPaymentID, companyID: $companyID, StatusArr: $StatusArr, limit: $limit, offSet: $offSet) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    description
    companyID
    receiptStatus
    bankAccountID
    bankAccount {
      ID
      name
      branch
      accountNo
    }
    submittedDate
    submittedBy
    paidDate
    paidBy
    totalAmt
    batchPaymentAssocDetail
    batchPaymentDocNum
    batchPaymentDetail {
      ID
      batchPaymentHeaderID
      docRefTable
      docRefID
      associateRefTable
      associateRefID
      paymentAmt
      APPaymentID
    }
  }
}
    `;

/**
 * __useGetBatchPaymentbyStatusQuery__
 *
 * To run a query within a React component, call `useGetBatchPaymentbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchPaymentbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchPaymentbyStatusQuery({
 *   variables: {
 *      batchPaymentID: // value for 'batchPaymentID'
 *      companyID: // value for 'companyID'
 *      StatusArr: // value for 'StatusArr'
 *      limit: // value for 'limit'
 *      offSet: // value for 'offSet'
 *   },
 * });
 */
export function useGetBatchPaymentbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBatchPaymentbyStatusQuery, GetBatchPaymentbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBatchPaymentbyStatusQuery, GetBatchPaymentbyStatusQueryVariables>(GetBatchPaymentbyStatusDocument, baseOptions);
      }
export function useGetBatchPaymentbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatchPaymentbyStatusQuery, GetBatchPaymentbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBatchPaymentbyStatusQuery, GetBatchPaymentbyStatusQueryVariables>(GetBatchPaymentbyStatusDocument, baseOptions);
        }
export type GetBatchPaymentbyStatusQueryHookResult = ReturnType<typeof useGetBatchPaymentbyStatusQuery>;
export type GetBatchPaymentbyStatusLazyQueryHookResult = ReturnType<typeof useGetBatchPaymentbyStatusLazyQuery>;
export type GetBatchPaymentbyStatusQueryResult = ApolloReactCommon.QueryResult<GetBatchPaymentbyStatusQuery, GetBatchPaymentbyStatusQueryVariables>;
export const GetBatchPaymentbyStatusListingDocument = gql`
    query getBatchPaymentbyStatusListing($batchPaymentID: String, $companyID: String, $StatusArr: String, $limit: Float, $offSet: Float, $searchValue: String) {
  getBatchPaymentbyStatus(batchPaymentID: $batchPaymentID, companyID: $companyID, StatusArr: $StatusArr, limit: $limit, offSet: $offSet, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    description
    submittedDate
    submittedBy
    paidDate
    paidBy
    totalAmt
    batchPaymentAssocDetail
    batchPaymentDocNum
    batchPaymentDetail {
      ID
    }
  }
  getBatchPaymentbyStatusCount(companyID: $companyID, StatusArr: $StatusArr, searchValue: $searchValue)
}
    `;

/**
 * __useGetBatchPaymentbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetBatchPaymentbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchPaymentbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchPaymentbyStatusListingQuery({
 *   variables: {
 *      batchPaymentID: // value for 'batchPaymentID'
 *      companyID: // value for 'companyID'
 *      StatusArr: // value for 'StatusArr'
 *      limit: // value for 'limit'
 *      offSet: // value for 'offSet'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetBatchPaymentbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBatchPaymentbyStatusListingQuery, GetBatchPaymentbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBatchPaymentbyStatusListingQuery, GetBatchPaymentbyStatusListingQueryVariables>(GetBatchPaymentbyStatusListingDocument, baseOptions);
      }
export function useGetBatchPaymentbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatchPaymentbyStatusListingQuery, GetBatchPaymentbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBatchPaymentbyStatusListingQuery, GetBatchPaymentbyStatusListingQueryVariables>(GetBatchPaymentbyStatusListingDocument, baseOptions);
        }
export type GetBatchPaymentbyStatusListingQueryHookResult = ReturnType<typeof useGetBatchPaymentbyStatusListingQuery>;
export type GetBatchPaymentbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetBatchPaymentbyStatusListingLazyQuery>;
export type GetBatchPaymentbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetBatchPaymentbyStatusListingQuery, GetBatchPaymentbyStatusListingQueryVariables>;
export const CreateBatchPaymentHeaderDocument = gql`
    mutation createBatchPaymentHeader($input: BatchPaymentHeaderInput!) {
  createBatchPaymentHeader(input: $input) {
    ID
  }
}
    `;
export type CreateBatchPaymentHeaderMutationFn = ApolloReactCommon.MutationFunction<CreateBatchPaymentHeaderMutation, CreateBatchPaymentHeaderMutationVariables>;

/**
 * __useCreateBatchPaymentHeaderMutation__
 *
 * To run a mutation, you first call `useCreateBatchPaymentHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchPaymentHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchPaymentHeaderMutation, { data, loading, error }] = useCreateBatchPaymentHeaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchPaymentHeaderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchPaymentHeaderMutation, CreateBatchPaymentHeaderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchPaymentHeaderMutation, CreateBatchPaymentHeaderMutationVariables>(CreateBatchPaymentHeaderDocument, baseOptions);
      }
export type CreateBatchPaymentHeaderMutationHookResult = ReturnType<typeof useCreateBatchPaymentHeaderMutation>;
export type CreateBatchPaymentHeaderMutationResult = ApolloReactCommon.MutationResult<CreateBatchPaymentHeaderMutation>;
export type CreateBatchPaymentHeaderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchPaymentHeaderMutation, CreateBatchPaymentHeaderMutationVariables>;
export const CreateBatchPaymentDetailDocument = gql`
    mutation createBatchPaymentDetail($input: BatchPaymentDetailInput!) {
  createBatchPaymentDetail(input: $input) {
    ID
  }
}
    `;
export type CreateBatchPaymentDetailMutationFn = ApolloReactCommon.MutationFunction<CreateBatchPaymentDetailMutation, CreateBatchPaymentDetailMutationVariables>;

/**
 * __useCreateBatchPaymentDetailMutation__
 *
 * To run a mutation, you first call `useCreateBatchPaymentDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchPaymentDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchPaymentDetailMutation, { data, loading, error }] = useCreateBatchPaymentDetailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchPaymentDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchPaymentDetailMutation, CreateBatchPaymentDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchPaymentDetailMutation, CreateBatchPaymentDetailMutationVariables>(CreateBatchPaymentDetailDocument, baseOptions);
      }
export type CreateBatchPaymentDetailMutationHookResult = ReturnType<typeof useCreateBatchPaymentDetailMutation>;
export type CreateBatchPaymentDetailMutationResult = ApolloReactCommon.MutationResult<CreateBatchPaymentDetailMutation>;
export type CreateBatchPaymentDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchPaymentDetailMutation, CreateBatchPaymentDetailMutationVariables>;
export const SubmitBatchPaymentDocument = gql`
    mutation submitBatchPayment($headerInput: BatchPaymentHeaderInput!, $detailInput: [BatchPaymentDetailInput!]!) {
  submitBatchPayment(headerInput: $headerInput, detailInput: $detailInput)
}
    `;
export type SubmitBatchPaymentMutationFn = ApolloReactCommon.MutationFunction<SubmitBatchPaymentMutation, SubmitBatchPaymentMutationVariables>;

/**
 * __useSubmitBatchPaymentMutation__
 *
 * To run a mutation, you first call `useSubmitBatchPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBatchPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBatchPaymentMutation, { data, loading, error }] = useSubmitBatchPaymentMutation({
 *   variables: {
 *      headerInput: // value for 'headerInput'
 *      detailInput: // value for 'detailInput'
 *   },
 * });
 */
export function useSubmitBatchPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitBatchPaymentMutation, SubmitBatchPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitBatchPaymentMutation, SubmitBatchPaymentMutationVariables>(SubmitBatchPaymentDocument, baseOptions);
      }
export type SubmitBatchPaymentMutationHookResult = ReturnType<typeof useSubmitBatchPaymentMutation>;
export type SubmitBatchPaymentMutationResult = ApolloReactCommon.MutationResult<SubmitBatchPaymentMutation>;
export type SubmitBatchPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitBatchPaymentMutation, SubmitBatchPaymentMutationVariables>;
export const PayBatchPaymentDocument = gql`
    mutation payBatchPayment($headerID: String!, $detailIDs: [String!]!) {
  payBatchPayment(headerID: $headerID, detailIDs: $detailIDs)
}
    `;
export type PayBatchPaymentMutationFn = ApolloReactCommon.MutationFunction<PayBatchPaymentMutation, PayBatchPaymentMutationVariables>;

/**
 * __usePayBatchPaymentMutation__
 *
 * To run a mutation, you first call `usePayBatchPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayBatchPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payBatchPaymentMutation, { data, loading, error }] = usePayBatchPaymentMutation({
 *   variables: {
 *      headerID: // value for 'headerID'
 *      detailIDs: // value for 'detailIDs'
 *   },
 * });
 */
export function usePayBatchPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayBatchPaymentMutation, PayBatchPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<PayBatchPaymentMutation, PayBatchPaymentMutationVariables>(PayBatchPaymentDocument, baseOptions);
      }
export type PayBatchPaymentMutationHookResult = ReturnType<typeof usePayBatchPaymentMutation>;
export type PayBatchPaymentMutationResult = ApolloReactCommon.MutationResult<PayBatchPaymentMutation>;
export type PayBatchPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<PayBatchPaymentMutation, PayBatchPaymentMutationVariables>;
export const ConfirmBatchPaymentDocument = gql`
    mutation confirmBatchPayment($headerID: String!, $detailInput: [ApprovePaymentDetailInput!]!) {
  confirmBatchPayment(headerID: $headerID, detailInput: $detailInput)
}
    `;
export type ConfirmBatchPaymentMutationFn = ApolloReactCommon.MutationFunction<ConfirmBatchPaymentMutation, ConfirmBatchPaymentMutationVariables>;

/**
 * __useConfirmBatchPaymentMutation__
 *
 * To run a mutation, you first call `useConfirmBatchPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBatchPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBatchPaymentMutation, { data, loading, error }] = useConfirmBatchPaymentMutation({
 *   variables: {
 *      headerID: // value for 'headerID'
 *      detailInput: // value for 'detailInput'
 *   },
 * });
 */
export function useConfirmBatchPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmBatchPaymentMutation, ConfirmBatchPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmBatchPaymentMutation, ConfirmBatchPaymentMutationVariables>(ConfirmBatchPaymentDocument, baseOptions);
      }
export type ConfirmBatchPaymentMutationHookResult = ReturnType<typeof useConfirmBatchPaymentMutation>;
export type ConfirmBatchPaymentMutationResult = ApolloReactCommon.MutationResult<ConfirmBatchPaymentMutation>;
export type ConfirmBatchPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmBatchPaymentMutation, ConfirmBatchPaymentMutationVariables>;
export const GetMultiSupplierDocument = gql`
    query getMultiSupplier($supplierIDs: [String!]!) {
  getMultiSupplier(supplierIDs: $supplierIDs) {
    ID
    name
    address
    contactNo
    creditTerm
    contactPerson {
      ID
      name
    }
    description
  }
}
    `;

/**
 * __useGetMultiSupplierQuery__
 *
 * To run a query within a React component, call `useGetMultiSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultiSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultiSupplierQuery({
 *   variables: {
 *      supplierIDs: // value for 'supplierIDs'
 *   },
 * });
 */
export function useGetMultiSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMultiSupplierQuery, GetMultiSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMultiSupplierQuery, GetMultiSupplierQueryVariables>(GetMultiSupplierDocument, baseOptions);
      }
export function useGetMultiSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMultiSupplierQuery, GetMultiSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMultiSupplierQuery, GetMultiSupplierQueryVariables>(GetMultiSupplierDocument, baseOptions);
        }
export type GetMultiSupplierQueryHookResult = ReturnType<typeof useGetMultiSupplierQuery>;
export type GetMultiSupplierLazyQueryHookResult = ReturnType<typeof useGetMultiSupplierLazyQuery>;
export type GetMultiSupplierQueryResult = ApolloReactCommon.QueryResult<GetMultiSupplierQuery, GetMultiSupplierQueryVariables>;
export const InvoiceRpaDocument = gql`
    mutation invoiceRPA($files: [Upload!]!, $contractID: String!) {
  invoiceRPA(files: $files, contractID: $contractID) {
    supplier
    invoiceDate
    invoiceNo
    contractID
    contractTitle
    deliveryOrderNo
    items {
      amount
      description
      unitPrice
      quantity
      wbsID
      costItemID
    }
  }
}
    `;
export type InvoiceRpaMutationFn = ApolloReactCommon.MutationFunction<InvoiceRpaMutation, InvoiceRpaMutationVariables>;

/**
 * __useInvoiceRpaMutation__
 *
 * To run a mutation, you first call `useInvoiceRpaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceRpaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceRpaMutation, { data, loading, error }] = useInvoiceRpaMutation({
 *   variables: {
 *      files: // value for 'files'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useInvoiceRpaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceRpaMutation, InvoiceRpaMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceRpaMutation, InvoiceRpaMutationVariables>(InvoiceRpaDocument, baseOptions);
      }
export type InvoiceRpaMutationHookResult = ReturnType<typeof useInvoiceRpaMutation>;
export type InvoiceRpaMutationResult = ApolloReactCommon.MutationResult<InvoiceRpaMutation>;
export type InvoiceRpaMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceRpaMutation, InvoiceRpaMutationVariables>;
export const GetSupplierOsDebitDocumentDocument = gql`
    query getSupplierOSDebitDocument($supplierID: String, $contractID: String, $companyID: String, $transactionStatus: TransactionStatus) {
  getAPInvoice(supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docDueDate
    docAmt
    baseAmt
    description
    outstanding
    contractID
    contract {
      ID
      creditTerm
    }
  }
  getAPInvoiceWoDO(supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docDueDate
    docAmt
    baseAmt
    description
    outstanding
    contractID
    contract {
      ID
      creditTerm
    }
  }
  getAPDebitNote(supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docDueDate
    docAmt
    baseAmt
    description
    outstandingAmt
    contractID
    contract {
      ID
      creditTerm
    }
  }
  getCNtoSupplier(supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docDueDate
    docAmt
    baseAmt
    description
    outstandingAmt
    contractID
    contract {
      ID
      creditTerm
    }
  }
}
    `;

/**
 * __useGetSupplierOsDebitDocumentQuery__
 *
 * To run a query within a React component, call `useGetSupplierOsDebitDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierOsDebitDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierOsDebitDocumentQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetSupplierOsDebitDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierOsDebitDocumentQuery, GetSupplierOsDebitDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierOsDebitDocumentQuery, GetSupplierOsDebitDocumentQueryVariables>(GetSupplierOsDebitDocumentDocument, baseOptions);
      }
export function useGetSupplierOsDebitDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierOsDebitDocumentQuery, GetSupplierOsDebitDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierOsDebitDocumentQuery, GetSupplierOsDebitDocumentQueryVariables>(GetSupplierOsDebitDocumentDocument, baseOptions);
        }
export type GetSupplierOsDebitDocumentQueryHookResult = ReturnType<typeof useGetSupplierOsDebitDocumentQuery>;
export type GetSupplierOsDebitDocumentLazyQueryHookResult = ReturnType<typeof useGetSupplierOsDebitDocumentLazyQuery>;
export type GetSupplierOsDebitDocumentQueryResult = ApolloReactCommon.QueryResult<GetSupplierOsDebitDocumentQuery, GetSupplierOsDebitDocumentQueryVariables>;
export const GetSupplierDataForAccountingDocument = gql`
    query getSupplierDataForAccounting($ID: String) {
  getSupplier(ID: $ID) {
    name
    ID
    accountID
    address
  }
}
    `;

/**
 * __useGetSupplierDataForAccountingQuery__
 *
 * To run a query within a React component, call `useGetSupplierDataForAccountingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierDataForAccountingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierDataForAccountingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSupplierDataForAccountingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierDataForAccountingQuery, GetSupplierDataForAccountingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierDataForAccountingQuery, GetSupplierDataForAccountingQueryVariables>(GetSupplierDataForAccountingDocument, baseOptions);
      }
export function useGetSupplierDataForAccountingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierDataForAccountingQuery, GetSupplierDataForAccountingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierDataForAccountingQuery, GetSupplierDataForAccountingQueryVariables>(GetSupplierDataForAccountingDocument, baseOptions);
        }
export type GetSupplierDataForAccountingQueryHookResult = ReturnType<typeof useGetSupplierDataForAccountingQuery>;
export type GetSupplierDataForAccountingLazyQueryHookResult = ReturnType<typeof useGetSupplierDataForAccountingLazyQuery>;
export type GetSupplierDataForAccountingQueryResult = ApolloReactCommon.QueryResult<GetSupplierDataForAccountingQuery, GetSupplierDataForAccountingQueryVariables>;
export const GetItemByClassDocument = gql`
    query getItemByClass($costClass: CostClass!) {
  getItemByClass(costClass: $costClass) {
    ID
    name
    uomID
    createdTs
    UOM {
      ID
      name
      code
    }
  }
}
    `;

/**
 * __useGetItemByClassQuery__
 *
 * To run a query within a React component, call `useGetItemByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemByClassQuery({
 *   variables: {
 *      costClass: // value for 'costClass'
 *   },
 * });
 */
export function useGetItemByClassQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemByClassQuery, GetItemByClassQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemByClassQuery, GetItemByClassQueryVariables>(GetItemByClassDocument, baseOptions);
      }
export function useGetItemByClassLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemByClassQuery, GetItemByClassQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemByClassQuery, GetItemByClassQueryVariables>(GetItemByClassDocument, baseOptions);
        }
export type GetItemByClassQueryHookResult = ReturnType<typeof useGetItemByClassQuery>;
export type GetItemByClassLazyQueryHookResult = ReturnType<typeof useGetItemByClassLazyQuery>;
export type GetItemByClassQueryResult = ApolloReactCommon.QueryResult<GetItemByClassQuery, GetItemByClassQueryVariables>;
export const GetContractSupplierPdfDocument = gql`
    query getContractSupplierPdf($contractID: String, $supplierID: String) {
  getContract(ID: $contractID) {
    ID
    title
    creditTerm
    contact {
      ID
      name
      contactNo
    }
  }
  getSupplier(ID: $supplierID) {
    ID
    name
    address
    contactPerson {
      ID
      name
      contactNo
    }
  }
}
    `;

/**
 * __useGetContractSupplierPdfQuery__
 *
 * To run a query within a React component, call `useGetContractSupplierPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSupplierPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSupplierPdfQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetContractSupplierPdfQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSupplierPdfQuery, GetContractSupplierPdfQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSupplierPdfQuery, GetContractSupplierPdfQueryVariables>(GetContractSupplierPdfDocument, baseOptions);
      }
export function useGetContractSupplierPdfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSupplierPdfQuery, GetContractSupplierPdfQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSupplierPdfQuery, GetContractSupplierPdfQueryVariables>(GetContractSupplierPdfDocument, baseOptions);
        }
export type GetContractSupplierPdfQueryHookResult = ReturnType<typeof useGetContractSupplierPdfQuery>;
export type GetContractSupplierPdfLazyQueryHookResult = ReturnType<typeof useGetContractSupplierPdfLazyQuery>;
export type GetContractSupplierPdfQueryResult = ApolloReactCommon.QueryResult<GetContractSupplierPdfQuery, GetContractSupplierPdfQueryVariables>;
export const GetAllocationSupplierDocument = gql`
    query getAllocationSupplier($contractID: String, $supplierID: String, $creditDebitID: String!, $transactionStatus: TransactionStatus) {
  getAPDebitNote(contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getCNtoSupplier(contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getAPInvoice(contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
  getAPInvoiceWoDO(contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
    outstandingAmt
    taxRate
    docRef
    docNo
    docDate
    allocatedAmt(creditDebitID: $creditDebitID)
    description
  }
}
    `;

/**
 * __useGetAllocationSupplierQuery__
 *
 * To run a query within a React component, call `useGetAllocationSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllocationSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllocationSupplierQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      creditDebitID: // value for 'creditDebitID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetAllocationSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllocationSupplierQuery, GetAllocationSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllocationSupplierQuery, GetAllocationSupplierQueryVariables>(GetAllocationSupplierDocument, baseOptions);
      }
export function useGetAllocationSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllocationSupplierQuery, GetAllocationSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllocationSupplierQuery, GetAllocationSupplierQueryVariables>(GetAllocationSupplierDocument, baseOptions);
        }
export type GetAllocationSupplierQueryHookResult = ReturnType<typeof useGetAllocationSupplierQuery>;
export type GetAllocationSupplierLazyQueryHookResult = ReturnType<typeof useGetAllocationSupplierLazyQuery>;
export type GetAllocationSupplierQueryResult = ApolloReactCommon.QueryResult<GetAllocationSupplierQuery, GetAllocationSupplierQueryVariables>;
export const MakeSupplierAllocationDocument = gql`
    mutation makeSupplierAllocation($ledger: [LedgerInput!]!) {
  makeSupplierAllocation(ledger: $ledger)
}
    `;
export type MakeSupplierAllocationMutationFn = ApolloReactCommon.MutationFunction<MakeSupplierAllocationMutation, MakeSupplierAllocationMutationVariables>;

/**
 * __useMakeSupplierAllocationMutation__
 *
 * To run a mutation, you first call `useMakeSupplierAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSupplierAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSupplierAllocationMutation, { data, loading, error }] = useMakeSupplierAllocationMutation({
 *   variables: {
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeSupplierAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeSupplierAllocationMutation, MakeSupplierAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeSupplierAllocationMutation, MakeSupplierAllocationMutationVariables>(MakeSupplierAllocationDocument, baseOptions);
      }
export type MakeSupplierAllocationMutationHookResult = ReturnType<typeof useMakeSupplierAllocationMutation>;
export type MakeSupplierAllocationMutationResult = ApolloReactCommon.MutationResult<MakeSupplierAllocationMutation>;
export type MakeSupplierAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeSupplierAllocationMutation, MakeSupplierAllocationMutationVariables>;
export const ResetSupplierAllocationDocument = gql`
    mutation resetSupplierAllocation($creditID: String, $debitID: String) {
  resetSupplierAllocation(creditID: $creditID, debitID: $debitID)
}
    `;
export type ResetSupplierAllocationMutationFn = ApolloReactCommon.MutationFunction<ResetSupplierAllocationMutation, ResetSupplierAllocationMutationVariables>;

/**
 * __useResetSupplierAllocationMutation__
 *
 * To run a mutation, you first call `useResetSupplierAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetSupplierAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetSupplierAllocationMutation, { data, loading, error }] = useResetSupplierAllocationMutation({
 *   variables: {
 *      creditID: // value for 'creditID'
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useResetSupplierAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetSupplierAllocationMutation, ResetSupplierAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetSupplierAllocationMutation, ResetSupplierAllocationMutationVariables>(ResetSupplierAllocationDocument, baseOptions);
      }
export type ResetSupplierAllocationMutationHookResult = ReturnType<typeof useResetSupplierAllocationMutation>;
export type ResetSupplierAllocationMutationResult = ApolloReactCommon.MutationResult<ResetSupplierAllocationMutation>;
export type ResetSupplierAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetSupplierAllocationMutation, ResetSupplierAllocationMutationVariables>;
export const GetDNtoSupplierAllocationDocument = gql`
    query getDNtoSupplierAllocation($debitID: String!) {
  getDNtoSupplierAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetDNtoSupplierAllocationQuery__
 *
 * To run a query within a React component, call `useGetDNtoSupplierAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSupplierAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSupplierAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetDNtoSupplierAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSupplierAllocationQuery, GetDNtoSupplierAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSupplierAllocationQuery, GetDNtoSupplierAllocationQueryVariables>(GetDNtoSupplierAllocationDocument, baseOptions);
      }
export function useGetDNtoSupplierAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSupplierAllocationQuery, GetDNtoSupplierAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSupplierAllocationQuery, GetDNtoSupplierAllocationQueryVariables>(GetDNtoSupplierAllocationDocument, baseOptions);
        }
export type GetDNtoSupplierAllocationQueryHookResult = ReturnType<typeof useGetDNtoSupplierAllocationQuery>;
export type GetDNtoSupplierAllocationLazyQueryHookResult = ReturnType<typeof useGetDNtoSupplierAllocationLazyQuery>;
export type GetDNtoSupplierAllocationQueryResult = ApolloReactCommon.QueryResult<GetDNtoSupplierAllocationQuery, GetDNtoSupplierAllocationQueryVariables>;
export const GetCNtoSupplierAllocationDocument = gql`
    query getCNtoSupplierAllocation($creditID: String!) {
  getCNtoSupplierAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetCNtoSupplierAllocationQuery__
 *
 * To run a query within a React component, call `useGetCNtoSupplierAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSupplierAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSupplierAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetCNtoSupplierAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSupplierAllocationQuery, GetCNtoSupplierAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSupplierAllocationQuery, GetCNtoSupplierAllocationQueryVariables>(GetCNtoSupplierAllocationDocument, baseOptions);
      }
export function useGetCNtoSupplierAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSupplierAllocationQuery, GetCNtoSupplierAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSupplierAllocationQuery, GetCNtoSupplierAllocationQueryVariables>(GetCNtoSupplierAllocationDocument, baseOptions);
        }
export type GetCNtoSupplierAllocationQueryHookResult = ReturnType<typeof useGetCNtoSupplierAllocationQuery>;
export type GetCNtoSupplierAllocationLazyQueryHookResult = ReturnType<typeof useGetCNtoSupplierAllocationLazyQuery>;
export type GetCNtoSupplierAllocationQueryResult = ApolloReactCommon.QueryResult<GetCNtoSupplierAllocationQuery, GetCNtoSupplierAllocationQueryVariables>;
export const GetSupplierDnAllocationDocument = gql`
    query getSupplierDNAllocation($creditID: String!) {
  getSupplierDNAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetSupplierDnAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierDnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierDnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierDnAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetSupplierDnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierDnAllocationQuery, GetSupplierDnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierDnAllocationQuery, GetSupplierDnAllocationQueryVariables>(GetSupplierDnAllocationDocument, baseOptions);
      }
export function useGetSupplierDnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierDnAllocationQuery, GetSupplierDnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierDnAllocationQuery, GetSupplierDnAllocationQueryVariables>(GetSupplierDnAllocationDocument, baseOptions);
        }
export type GetSupplierDnAllocationQueryHookResult = ReturnType<typeof useGetSupplierDnAllocationQuery>;
export type GetSupplierDnAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierDnAllocationLazyQuery>;
export type GetSupplierDnAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierDnAllocationQuery, GetSupplierDnAllocationQueryVariables>;
export const GetSupplierCnAllocationDocument = gql`
    query getSupplierCNAllocation($debitID: String!) {
  getSupplierCNAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetSupplierCnAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierCnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierCnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierCnAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetSupplierCnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>(GetSupplierCnAllocationDocument, baseOptions);
      }
export function useGetSupplierCnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>(GetSupplierCnAllocationDocument, baseOptions);
        }
export type GetSupplierCnAllocationQueryHookResult = ReturnType<typeof useGetSupplierCnAllocationQuery>;
export type GetSupplierCnAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierCnAllocationLazyQuery>;
export type GetSupplierCnAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>;
export const GetSupplierDepositAllocationDocument = gql`
    query getSupplierDepositAllocation($debitID: String!) {
  getSupplierDepositAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetSupplierDepositAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierDepositAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierDepositAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierDepositAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetSupplierDepositAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>(GetSupplierDepositAllocationDocument, baseOptions);
      }
export function useGetSupplierDepositAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>(GetSupplierDepositAllocationDocument, baseOptions);
        }
export type GetSupplierDepositAllocationQueryHookResult = ReturnType<typeof useGetSupplierDepositAllocationQuery>;
export type GetSupplierDepositAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierDepositAllocationLazyQuery>;
export type GetSupplierDepositAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>;
export const GetSupplierPaymentAllocationDocument = gql`
    query getSupplierPaymentAllocation($companyID: String, $debitID: String, $supplierID: String, $receiptStatus: String) {
  getSupplierPaymentAllocation(companyID: $companyID, debitID: $debitID, supplierID: $supplierID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetSupplierPaymentAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierPaymentAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierPaymentAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierPaymentAllocationQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      debitID: // value for 'debitID'
 *      supplierID: // value for 'supplierID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetSupplierPaymentAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>(GetSupplierPaymentAllocationDocument, baseOptions);
      }
export function useGetSupplierPaymentAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>(GetSupplierPaymentAllocationDocument, baseOptions);
        }
export type GetSupplierPaymentAllocationQueryHookResult = ReturnType<typeof useGetSupplierPaymentAllocationQuery>;
export type GetSupplierPaymentAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierPaymentAllocationLazyQuery>;
export type GetSupplierPaymentAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>;
export const CreateSupplierInvoiceDocument = gql`
    mutation createSupplierInvoice($invoiceInput: APInvoiceInput!, $ledger: [LedgerInput!]!, $invDoItemMatchInput: [APInvoiceItemInput!]!) {
  createSupplierInvoice(invoiceInput: $invoiceInput, ledger: $ledger, invDoItemMatchInput: $invDoItemMatchInput)
}
    `;
export type CreateSupplierInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>;

/**
 * __useCreateSupplierInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateSupplierInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierInvoiceMutation, { data, loading, error }] = useCreateSupplierInvoiceMutation({
 *   variables: {
 *      invoiceInput: // value for 'invoiceInput'
 *      ledger: // value for 'ledger'
 *      invDoItemMatchInput: // value for 'invDoItemMatchInput'
 *   },
 * });
 */
export function useCreateSupplierInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>(CreateSupplierInvoiceDocument, baseOptions);
      }
export type CreateSupplierInvoiceMutationHookResult = ReturnType<typeof useCreateSupplierInvoiceMutation>;
export type CreateSupplierInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateSupplierInvoiceMutation>;
export type CreateSupplierInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>;
export const UpdateSupplierInvoiceDocument = gql`
    mutation updateSupplierInvoice($invoiceInput: APInvoiceInput!, $ledger: [LedgerInput!]!, $invDoItemMatchInput: [APInvoiceItemInput!]!) {
  updateSupplierInvoice(invoiceInput: $invoiceInput, ledger: $ledger, invDoItemMatchInput: $invDoItemMatchInput)
}
    `;
export type UpdateSupplierInvoiceMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>;

/**
 * __useUpdateSupplierInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierInvoiceMutation, { data, loading, error }] = useUpdateSupplierInvoiceMutation({
 *   variables: {
 *      invoiceInput: // value for 'invoiceInput'
 *      ledger: // value for 'ledger'
 *      invDoItemMatchInput: // value for 'invDoItemMatchInput'
 *   },
 * });
 */
export function useUpdateSupplierInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>(UpdateSupplierInvoiceDocument, baseOptions);
      }
export type UpdateSupplierInvoiceMutationHookResult = ReturnType<typeof useUpdateSupplierInvoiceMutation>;
export type UpdateSupplierInvoiceMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierInvoiceMutation>;
export type UpdateSupplierInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>;
export const CreateSupplierInvoiceV2Document = gql`
    mutation createSupplierInvoiceV2($invoiceInput: APInvoiceInput!, $ledger: [LedgerInput!]!, $APInvoiceItemInput: [APInvoiceItemInput!]!, $isWithDO: Boolean!) {
  createSupplierInvoiceV2(invoiceInput: $invoiceInput, ledger: $ledger, APInvoiceItemInput: $APInvoiceItemInput, isWithDO: $isWithDO)
}
    `;
export type CreateSupplierInvoiceV2MutationFn = ApolloReactCommon.MutationFunction<CreateSupplierInvoiceV2Mutation, CreateSupplierInvoiceV2MutationVariables>;

/**
 * __useCreateSupplierInvoiceV2Mutation__
 *
 * To run a mutation, you first call `useCreateSupplierInvoiceV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierInvoiceV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierInvoiceV2Mutation, { data, loading, error }] = useCreateSupplierInvoiceV2Mutation({
 *   variables: {
 *      invoiceInput: // value for 'invoiceInput'
 *      ledger: // value for 'ledger'
 *      APInvoiceItemInput: // value for 'APInvoiceItemInput'
 *      isWithDO: // value for 'isWithDO'
 *   },
 * });
 */
export function useCreateSupplierInvoiceV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierInvoiceV2Mutation, CreateSupplierInvoiceV2MutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierInvoiceV2Mutation, CreateSupplierInvoiceV2MutationVariables>(CreateSupplierInvoiceV2Document, baseOptions);
      }
export type CreateSupplierInvoiceV2MutationHookResult = ReturnType<typeof useCreateSupplierInvoiceV2Mutation>;
export type CreateSupplierInvoiceV2MutationResult = ApolloReactCommon.MutationResult<CreateSupplierInvoiceV2Mutation>;
export type CreateSupplierInvoiceV2MutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierInvoiceV2Mutation, CreateSupplierInvoiceV2MutationVariables>;
export const UpdateSupplierInvoiceV2Document = gql`
    mutation updateSupplierInvoiceV2($invoiceInput: APInvoiceInput!, $ledger: [LedgerInput!]!, $APInvoiceItemInput: [APInvoiceItemInput!]!, $isWithDO: Boolean!) {
  updateSupplierInvoiceV2(invoiceInput: $invoiceInput, ledger: $ledger, APInvoiceItemInput: $APInvoiceItemInput, isWithDO: $isWithDO)
}
    `;
export type UpdateSupplierInvoiceV2MutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierInvoiceV2Mutation, UpdateSupplierInvoiceV2MutationVariables>;

/**
 * __useUpdateSupplierInvoiceV2Mutation__
 *
 * To run a mutation, you first call `useUpdateSupplierInvoiceV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierInvoiceV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierInvoiceV2Mutation, { data, loading, error }] = useUpdateSupplierInvoiceV2Mutation({
 *   variables: {
 *      invoiceInput: // value for 'invoiceInput'
 *      ledger: // value for 'ledger'
 *      APInvoiceItemInput: // value for 'APInvoiceItemInput'
 *      isWithDO: // value for 'isWithDO'
 *   },
 * });
 */
export function useUpdateSupplierInvoiceV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierInvoiceV2Mutation, UpdateSupplierInvoiceV2MutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierInvoiceV2Mutation, UpdateSupplierInvoiceV2MutationVariables>(UpdateSupplierInvoiceV2Document, baseOptions);
      }
export type UpdateSupplierInvoiceV2MutationHookResult = ReturnType<typeof useUpdateSupplierInvoiceV2Mutation>;
export type UpdateSupplierInvoiceV2MutationResult = ApolloReactCommon.MutationResult<UpdateSupplierInvoiceV2Mutation>;
export type UpdateSupplierInvoiceV2MutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierInvoiceV2Mutation, UpdateSupplierInvoiceV2MutationVariables>;
export const GetApInvoiceDocument = gql`
    query getAPInvoice($ID: String, $companyID: String, $contractID: String, $supplierID: String, $transactionStatus: TransactionStatus) {
  getAPInvoice(ID: $ID, companyID: $companyID, contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docDueDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    invoiceAmt
    discountAmt
    otherChargeAmt
    outstandingAmt
    transactionStatus
    rejectionRemarks
    rejectionDate
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
    }
    grnMatch
    outstanding
    invoiceDo {
      ID
      DODetails
    }
    APInvoiceItem {
      doItemID
      costItemID
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      docAmt
      taxAmt
      taxRate
      costItem {
        ID
        name
      }
      doItem {
        ID
        deliveryOrderID
      }
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  supplierInvInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApInvoiceQuery__
 *
 * To run a query within a React component, call `useGetApInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApInvoiceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetApInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApInvoiceQuery, GetApInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApInvoiceQuery, GetApInvoiceQueryVariables>(GetApInvoiceDocument, baseOptions);
      }
export function useGetApInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApInvoiceQuery, GetApInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApInvoiceQuery, GetApInvoiceQueryVariables>(GetApInvoiceDocument, baseOptions);
        }
export type GetApInvoiceQueryHookResult = ReturnType<typeof useGetApInvoiceQuery>;
export type GetApInvoiceLazyQueryHookResult = ReturnType<typeof useGetApInvoiceLazyQuery>;
export type GetApInvoiceQueryResult = ApolloReactCommon.QueryResult<GetApInvoiceQuery, GetApInvoiceQueryVariables>;
export const GetSupplierInvoiceWoDoDocument = gql`
    query getSupplierInvoiceWoDO($ID: String, $companyID: String, $contractID: String, $supplierID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float) {
  getAPInvoiceWoDO(ID: $ID, companyID: $companyID, contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus, take: $take, skip: $skip) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docDueDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    discountAmt
    otherChargeAmt
    outstandingAmt
    transactionStatus
    rejectionRemarks
    rejectionDate
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    grnMatch
    outstanding
    invoiceDo {
      ID
      DODetails
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APInvoiceItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      docAmt
      taxAmt
      wbsID
      taxRate
      remarks
      wbs {
        ID
        name
      }
    }
  }
  supplierInvInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetSupplierInvoiceWoDoQuery__
 *
 * To run a query within a React component, call `useGetSupplierInvoiceWoDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierInvoiceWoDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierInvoiceWoDoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetSupplierInvoiceWoDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierInvoiceWoDoQuery, GetSupplierInvoiceWoDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierInvoiceWoDoQuery, GetSupplierInvoiceWoDoQueryVariables>(GetSupplierInvoiceWoDoDocument, baseOptions);
      }
export function useGetSupplierInvoiceWoDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierInvoiceWoDoQuery, GetSupplierInvoiceWoDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierInvoiceWoDoQuery, GetSupplierInvoiceWoDoQueryVariables>(GetSupplierInvoiceWoDoDocument, baseOptions);
        }
export type GetSupplierInvoiceWoDoQueryHookResult = ReturnType<typeof useGetSupplierInvoiceWoDoQuery>;
export type GetSupplierInvoiceWoDoLazyQueryHookResult = ReturnType<typeof useGetSupplierInvoiceWoDoLazyQuery>;
export type GetSupplierInvoiceWoDoQueryResult = ApolloReactCommon.QueryResult<GetSupplierInvoiceWoDoQuery, GetSupplierInvoiceWoDoQueryVariables>;
export const GetSupplierInvoiceWoDoListingDocument = gql`
    query getSupplierInvoiceWoDOListing($ID: String, $companyID: String, $contractID: String, $supplierID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float) {
  getAPInvoiceWoDO(ID: $ID, companyID: $companyID, contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus, take: $take, skip: $skip) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docDueDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    discountAmt
    otherChargeAmt
    outstandingAmt
    transactionStatus
    rejectionRemarks
    rejectionDate
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    grnMatch
    outstanding
    invoiceDo {
      ID
      DODetails
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APInvoiceItem {
      ID
      costClass
      costCategoryID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      docAmt
      taxAmt
      wbsID
      taxRate
      remarks
      wbs {
        ID
        name
      }
    }
  }
  supplierInvInfo(supplierID: $supplierID)
  getAPInvoiceWoDOCount: getAPInvoiceWoDO(companyID: $companyID, contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
  }
}
    `;

/**
 * __useGetSupplierInvoiceWoDoListingQuery__
 *
 * To run a query within a React component, call `useGetSupplierInvoiceWoDoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierInvoiceWoDoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierInvoiceWoDoListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetSupplierInvoiceWoDoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierInvoiceWoDoListingQuery, GetSupplierInvoiceWoDoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierInvoiceWoDoListingQuery, GetSupplierInvoiceWoDoListingQueryVariables>(GetSupplierInvoiceWoDoListingDocument, baseOptions);
      }
export function useGetSupplierInvoiceWoDoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierInvoiceWoDoListingQuery, GetSupplierInvoiceWoDoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierInvoiceWoDoListingQuery, GetSupplierInvoiceWoDoListingQueryVariables>(GetSupplierInvoiceWoDoListingDocument, baseOptions);
        }
export type GetSupplierInvoiceWoDoListingQueryHookResult = ReturnType<typeof useGetSupplierInvoiceWoDoListingQuery>;
export type GetSupplierInvoiceWoDoListingLazyQueryHookResult = ReturnType<typeof useGetSupplierInvoiceWoDoListingLazyQuery>;
export type GetSupplierInvoiceWoDoListingQueryResult = ApolloReactCommon.QueryResult<GetSupplierInvoiceWoDoListingQuery, GetSupplierInvoiceWoDoListingQueryVariables>;
export const GetSupplierInvoiceDocument = gql`
    query getSupplierInvoice($ID: String, $supplierID: String, $contractID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float) {
  getAPInvoice(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docDueDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    discountAmt
    otherChargeAmt
    outstandingAmt
    transactionStatus
    rejectionRemarks
    rejectionDate
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
    }
    grnMatch
    outstanding
    invoiceDo {
      ID
      DODetails
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APInvoiceItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      docAmt
      taxAmt
    }
  }
  supplierInvInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetSupplierInvoiceQuery__
 *
 * To run a query within a React component, call `useGetSupplierInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierInvoiceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetSupplierInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierInvoiceQuery, GetSupplierInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierInvoiceQuery, GetSupplierInvoiceQueryVariables>(GetSupplierInvoiceDocument, baseOptions);
      }
export function useGetSupplierInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierInvoiceQuery, GetSupplierInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierInvoiceQuery, GetSupplierInvoiceQueryVariables>(GetSupplierInvoiceDocument, baseOptions);
        }
export type GetSupplierInvoiceQueryHookResult = ReturnType<typeof useGetSupplierInvoiceQuery>;
export type GetSupplierInvoiceLazyQueryHookResult = ReturnType<typeof useGetSupplierInvoiceLazyQuery>;
export type GetSupplierInvoiceQueryResult = ApolloReactCommon.QueryResult<GetSupplierInvoiceQuery, GetSupplierInvoiceQueryVariables>;
export const GetSupplierInvoiceListingDocument = gql`
    query getSupplierInvoiceListing($ID: String, $supplierID: String, $contractID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float) {
  getAPInvoice(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docDueDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    discountAmt
    otherChargeAmt
    outstandingAmt
    transactionStatus
    rejectionRemarks
    rejectionDate
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    grnMatch
    outstanding
    invoiceDo {
      ID
      DODetails
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APInvoiceItem {
      ID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      docAmt
      taxAmt
    }
  }
  supplierInvInfo(supplierID: $supplierID)
  getAPInvoiceCount: getAPInvoice(companyID: $companyID, contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
  }
}
    `;

/**
 * __useGetSupplierInvoiceListingQuery__
 *
 * To run a query within a React component, call `useGetSupplierInvoiceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierInvoiceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierInvoiceListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetSupplierInvoiceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierInvoiceListingQuery, GetSupplierInvoiceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierInvoiceListingQuery, GetSupplierInvoiceListingQueryVariables>(GetSupplierInvoiceListingDocument, baseOptions);
      }
export function useGetSupplierInvoiceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierInvoiceListingQuery, GetSupplierInvoiceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierInvoiceListingQuery, GetSupplierInvoiceListingQueryVariables>(GetSupplierInvoiceListingDocument, baseOptions);
        }
export type GetSupplierInvoiceListingQueryHookResult = ReturnType<typeof useGetSupplierInvoiceListingQuery>;
export type GetSupplierInvoiceListingLazyQueryHookResult = ReturnType<typeof useGetSupplierInvoiceListingLazyQuery>;
export type GetSupplierInvoiceListingQueryResult = ApolloReactCommon.QueryResult<GetSupplierInvoiceListingQuery, GetSupplierInvoiceListingQueryVariables>;
export const GetDOforInvoiceDocument = gql`
    query getDOforInvoice($invoiceID: String, $contractID: String!, $supplierID: String!, $formMode: String) {
  getDOforInvoice(invoiceID: $invoiceID, contractID: $contractID, supplierID: $supplierID, formMode: $formMode) {
    ID
    contractID
    supplierID
    docDate
    transactionDate
    doNo
    doStatus
    DODetails
    doItem {
      ID
      doItemAmt
      acceptedQty
      upToDateQty
      returnedQty
      goodReturnNoteItem {
        ID
        grtnID
        doItemID
        returnedQty
      }
      poItem {
        ID
        totalAmt
        taxRate
        taxAmt
        taxSchemeID
        taxScheme {
          ID
          code
          latestTax {
            taxSchemeID
            taxRate
            taxDate
          }
        }
        costItemID
        unitPrice
        costItem {
          name
          code
          unitPrice
          ID
        }
      }
      grtnItem {
        ID
        grtnID
        doItemID
        returnedQty
        outstandingQty
        doItem {
          ID
          poItem {
            taxRate
            taxSchemeID
            taxScheme {
              ID
              code
              latestTax {
                taxSchemeID
                taxRate
                taxDate
              }
            }
            costItemID
            costItem {
              ID
              code
              name
            }
            unitPrice
          }
        }
      }
    }
    upToDateAmt
    upToDateAmt
  }
}
    `;

/**
 * __useGetDOforInvoiceQuery__
 *
 * To run a query within a React component, call `useGetDOforInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDOforInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDOforInvoiceQuery({
 *   variables: {
 *      invoiceID: // value for 'invoiceID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      formMode: // value for 'formMode'
 *   },
 * });
 */
export function useGetDOforInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDOforInvoiceQuery, GetDOforInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDOforInvoiceQuery, GetDOforInvoiceQueryVariables>(GetDOforInvoiceDocument, baseOptions);
      }
export function useGetDOforInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDOforInvoiceQuery, GetDOforInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDOforInvoiceQuery, GetDOforInvoiceQueryVariables>(GetDOforInvoiceDocument, baseOptions);
        }
export type GetDOforInvoiceQueryHookResult = ReturnType<typeof useGetDOforInvoiceQuery>;
export type GetDOforInvoiceLazyQueryHookResult = ReturnType<typeof useGetDOforInvoiceLazyQuery>;
export type GetDOforInvoiceQueryResult = ApolloReactCommon.QueryResult<GetDOforInvoiceQuery, GetDOforInvoiceQueryVariables>;
export const CreateApInvoiceWithoutDoDocument = gql`
    mutation createAPInvoiceWithoutDO($input: APTransactionInput!) {
  createAPInvoiceWithoutDO(input: $input) {
    supplierID
    ID
  }
}
    `;
export type CreateApInvoiceWithoutDoMutationFn = ApolloReactCommon.MutationFunction<CreateApInvoiceWithoutDoMutation, CreateApInvoiceWithoutDoMutationVariables>;

/**
 * __useCreateApInvoiceWithoutDoMutation__
 *
 * To run a mutation, you first call `useCreateApInvoiceWithoutDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApInvoiceWithoutDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApInvoiceWithoutDoMutation, { data, loading, error }] = useCreateApInvoiceWithoutDoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApInvoiceWithoutDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApInvoiceWithoutDoMutation, CreateApInvoiceWithoutDoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApInvoiceWithoutDoMutation, CreateApInvoiceWithoutDoMutationVariables>(CreateApInvoiceWithoutDoDocument, baseOptions);
      }
export type CreateApInvoiceWithoutDoMutationHookResult = ReturnType<typeof useCreateApInvoiceWithoutDoMutation>;
export type CreateApInvoiceWithoutDoMutationResult = ApolloReactCommon.MutationResult<CreateApInvoiceWithoutDoMutation>;
export type CreateApInvoiceWithoutDoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApInvoiceWithoutDoMutation, CreateApInvoiceWithoutDoMutationVariables>;
export const UpdateApInvoiceWithoutDoDocument = gql`
    mutation updateAPInvoiceWithoutDO($input: APTransactionInput!) {
  updateAPInvoiceWithoutDO(input: $input) {
    supplierID
    ID
  }
}
    `;
export type UpdateApInvoiceWithoutDoMutationFn = ApolloReactCommon.MutationFunction<UpdateApInvoiceWithoutDoMutation, UpdateApInvoiceWithoutDoMutationVariables>;

/**
 * __useUpdateApInvoiceWithoutDoMutation__
 *
 * To run a mutation, you first call `useUpdateApInvoiceWithoutDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApInvoiceWithoutDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApInvoiceWithoutDoMutation, { data, loading, error }] = useUpdateApInvoiceWithoutDoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApInvoiceWithoutDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApInvoiceWithoutDoMutation, UpdateApInvoiceWithoutDoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApInvoiceWithoutDoMutation, UpdateApInvoiceWithoutDoMutationVariables>(UpdateApInvoiceWithoutDoDocument, baseOptions);
      }
export type UpdateApInvoiceWithoutDoMutationHookResult = ReturnType<typeof useUpdateApInvoiceWithoutDoMutation>;
export type UpdateApInvoiceWithoutDoMutationResult = ApolloReactCommon.MutationResult<UpdateApInvoiceWithoutDoMutation>;
export type UpdateApInvoiceWithoutDoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApInvoiceWithoutDoMutation, UpdateApInvoiceWithoutDoMutationVariables>;
export const GetSupplierInvoiceAllocationDocument = gql`
    query getSupplierInvoiceAllocation($creditID: String!) {
  getSupplierInvoiceAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetSupplierInvoiceAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierInvoiceAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierInvoiceAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierInvoiceAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetSupplierInvoiceAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierInvoiceAllocationQuery, GetSupplierInvoiceAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierInvoiceAllocationQuery, GetSupplierInvoiceAllocationQueryVariables>(GetSupplierInvoiceAllocationDocument, baseOptions);
      }
export function useGetSupplierInvoiceAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierInvoiceAllocationQuery, GetSupplierInvoiceAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierInvoiceAllocationQuery, GetSupplierInvoiceAllocationQueryVariables>(GetSupplierInvoiceAllocationDocument, baseOptions);
        }
export type GetSupplierInvoiceAllocationQueryHookResult = ReturnType<typeof useGetSupplierInvoiceAllocationQuery>;
export type GetSupplierInvoiceAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierInvoiceAllocationLazyQuery>;
export type GetSupplierInvoiceAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierInvoiceAllocationQuery, GetSupplierInvoiceAllocationQueryVariables>;
export const CancelSupplierInvoiceDocument = gql`
    mutation cancelSupplierInvoice($ID: String!, $input: CancelInput!) {
  cancelSupplierInvoice(ID: $ID, input: $input)
}
    `;
export type CancelSupplierInvoiceMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>;

/**
 * __useCancelSupplierInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelSupplierInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierInvoiceMutation, { data, loading, error }] = useCancelSupplierInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>(CancelSupplierInvoiceDocument, baseOptions);
      }
export type CancelSupplierInvoiceMutationHookResult = ReturnType<typeof useCancelSupplierInvoiceMutation>;
export type CancelSupplierInvoiceMutationResult = ApolloReactCommon.MutationResult<CancelSupplierInvoiceMutation>;
export type CancelSupplierInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>;
export const DeleteApInvoiceDocument = gql`
    mutation deleteAPInvoice($ID: String!) {
  deleteAPInvoice(ID: $ID)
}
    `;
export type DeleteApInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>;

/**
 * __useDeleteApInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteApInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApInvoiceMutation, { data, loading, error }] = useDeleteApInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>(DeleteApInvoiceDocument, baseOptions);
      }
export type DeleteApInvoiceMutationHookResult = ReturnType<typeof useDeleteApInvoiceMutation>;
export type DeleteApInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteApInvoiceMutation>;
export type DeleteApInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>;
export const DeleteSupplierInvoiceDocument = gql`
    mutation deleteSupplierInvoice($ID: String!) {
  deleteSupplierInvoice(ID: $ID)
}
    `;
export type DeleteSupplierInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteSupplierInvoiceMutation, DeleteSupplierInvoiceMutationVariables>;

/**
 * __useDeleteSupplierInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierInvoiceMutation, { data, loading, error }] = useDeleteSupplierInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSupplierInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSupplierInvoiceMutation, DeleteSupplierInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSupplierInvoiceMutation, DeleteSupplierInvoiceMutationVariables>(DeleteSupplierInvoiceDocument, baseOptions);
      }
export type DeleteSupplierInvoiceMutationHookResult = ReturnType<typeof useDeleteSupplierInvoiceMutation>;
export type DeleteSupplierInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteSupplierInvoiceMutation>;
export type DeleteSupplierInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSupplierInvoiceMutation, DeleteSupplierInvoiceMutationVariables>;
export const DeleteApInvoiceWithoutDoDocument = gql`
    mutation deleteAPInvoiceWithoutDO($ID: String!) {
  deleteAPInvoiceWithoutDO(ID: $ID)
}
    `;
export type DeleteApInvoiceWithoutDoMutationFn = ApolloReactCommon.MutationFunction<DeleteApInvoiceWithoutDoMutation, DeleteApInvoiceWithoutDoMutationVariables>;

/**
 * __useDeleteApInvoiceWithoutDoMutation__
 *
 * To run a mutation, you first call `useDeleteApInvoiceWithoutDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApInvoiceWithoutDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApInvoiceWithoutDoMutation, { data, loading, error }] = useDeleteApInvoiceWithoutDoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApInvoiceWithoutDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApInvoiceWithoutDoMutation, DeleteApInvoiceWithoutDoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApInvoiceWithoutDoMutation, DeleteApInvoiceWithoutDoMutationVariables>(DeleteApInvoiceWithoutDoDocument, baseOptions);
      }
export type DeleteApInvoiceWithoutDoMutationHookResult = ReturnType<typeof useDeleteApInvoiceWithoutDoMutation>;
export type DeleteApInvoiceWithoutDoMutationResult = ApolloReactCommon.MutationResult<DeleteApInvoiceWithoutDoMutation>;
export type DeleteApInvoiceWithoutDoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApInvoiceWithoutDoMutation, DeleteApInvoiceWithoutDoMutationVariables>;
export const GetSupplierInvoiceCountDocument = gql`
    query getSupplierInvoiceCount($companyID: String, $contractID: String, $supplierID: String, $transactionStatus: TransactionStatus) {
  getAPInvoice(companyID: $companyID, contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
  }
}
    `;

/**
 * __useGetSupplierInvoiceCountQuery__
 *
 * To run a query within a React component, call `useGetSupplierInvoiceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierInvoiceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierInvoiceCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetSupplierInvoiceCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierInvoiceCountQuery, GetSupplierInvoiceCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierInvoiceCountQuery, GetSupplierInvoiceCountQueryVariables>(GetSupplierInvoiceCountDocument, baseOptions);
      }
export function useGetSupplierInvoiceCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierInvoiceCountQuery, GetSupplierInvoiceCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierInvoiceCountQuery, GetSupplierInvoiceCountQueryVariables>(GetSupplierInvoiceCountDocument, baseOptions);
        }
export type GetSupplierInvoiceCountQueryHookResult = ReturnType<typeof useGetSupplierInvoiceCountQuery>;
export type GetSupplierInvoiceCountLazyQueryHookResult = ReturnType<typeof useGetSupplierInvoiceCountLazyQuery>;
export type GetSupplierInvoiceCountQueryResult = ApolloReactCommon.QueryResult<GetSupplierInvoiceCountQuery, GetSupplierInvoiceCountQueryVariables>;
export const GetSupplierInvoiceWoDoCountDocument = gql`
    query getSupplierInvoiceWoDOCount($companyID: String, $contractID: String, $supplierID: String, $transactionStatus: TransactionStatus) {
  getAPInvoiceWoDO(companyID: $companyID, contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
  }
}
    `;

/**
 * __useGetSupplierInvoiceWoDoCountQuery__
 *
 * To run a query within a React component, call `useGetSupplierInvoiceWoDoCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierInvoiceWoDoCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierInvoiceWoDoCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetSupplierInvoiceWoDoCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierInvoiceWoDoCountQuery, GetSupplierInvoiceWoDoCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierInvoiceWoDoCountQuery, GetSupplierInvoiceWoDoCountQueryVariables>(GetSupplierInvoiceWoDoCountDocument, baseOptions);
      }
export function useGetSupplierInvoiceWoDoCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierInvoiceWoDoCountQuery, GetSupplierInvoiceWoDoCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierInvoiceWoDoCountQuery, GetSupplierInvoiceWoDoCountQueryVariables>(GetSupplierInvoiceWoDoCountDocument, baseOptions);
        }
export type GetSupplierInvoiceWoDoCountQueryHookResult = ReturnType<typeof useGetSupplierInvoiceWoDoCountQuery>;
export type GetSupplierInvoiceWoDoCountLazyQueryHookResult = ReturnType<typeof useGetSupplierInvoiceWoDoCountLazyQuery>;
export type GetSupplierInvoiceWoDoCountQueryResult = ApolloReactCommon.QueryResult<GetSupplierInvoiceWoDoCountQuery, GetSupplierInvoiceWoDoCountQueryVariables>;
export const CreateApRefundDocument = gql`
    mutation createAPRefund($input: APPaymentInput!) {
  createAPRefund(input: $input) {
    supplierID
  }
}
    `;
export type CreateApRefundMutationFn = ApolloReactCommon.MutationFunction<CreateApRefundMutation, CreateApRefundMutationVariables>;

/**
 * __useCreateApRefundMutation__
 *
 * To run a mutation, you first call `useCreateApRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApRefundMutation, { data, loading, error }] = useCreateApRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApRefundMutation, CreateApRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApRefundMutation, CreateApRefundMutationVariables>(CreateApRefundDocument, baseOptions);
      }
export type CreateApRefundMutationHookResult = ReturnType<typeof useCreateApRefundMutation>;
export type CreateApRefundMutationResult = ApolloReactCommon.MutationResult<CreateApRefundMutation>;
export type CreateApRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApRefundMutation, CreateApRefundMutationVariables>;
export const UpdateApRefundDocument = gql`
    mutation updateAPRefund($input: APPaymentInput!) {
  updateAPRefund(input: $input) {
    supplierID
  }
}
    `;
export type UpdateApRefundMutationFn = ApolloReactCommon.MutationFunction<UpdateApRefundMutation, UpdateApRefundMutationVariables>;

/**
 * __useUpdateApRefundMutation__
 *
 * To run a mutation, you first call `useUpdateApRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApRefundMutation, { data, loading, error }] = useUpdateApRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApRefundMutation, UpdateApRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApRefundMutation, UpdateApRefundMutationVariables>(UpdateApRefundDocument, baseOptions);
      }
export type UpdateApRefundMutationHookResult = ReturnType<typeof useUpdateApRefundMutation>;
export type UpdateApRefundMutationResult = ApolloReactCommon.MutationResult<UpdateApRefundMutation>;
export type UpdateApRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApRefundMutation, UpdateApRefundMutationVariables>;
export const GetApRefundDocument = gql`
    query getAPRefund($ID: String, $supplierID: String, $contractID: String, $companyID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getAPRefund(ID: $ID, supplierID: $supplierID, companyID: $companyID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    accountID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
    }
    bankAccountID
    bankDetails
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
  }
  supplierRefundsInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApRefundQuery__
 *
 * To run a query within a React component, call `useGetApRefundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApRefundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApRefundQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApRefundQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApRefundQuery, GetApRefundQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApRefundQuery, GetApRefundQueryVariables>(GetApRefundDocument, baseOptions);
      }
export function useGetApRefundLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApRefundQuery, GetApRefundQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApRefundQuery, GetApRefundQueryVariables>(GetApRefundDocument, baseOptions);
        }
export type GetApRefundQueryHookResult = ReturnType<typeof useGetApRefundQuery>;
export type GetApRefundLazyQueryHookResult = ReturnType<typeof useGetApRefundLazyQuery>;
export type GetApRefundQueryResult = ApolloReactCommon.QueryResult<GetApRefundQuery, GetApRefundQueryVariables>;
export const GetApRefundListingDocument = gql`
    query getAPRefundListing($ID: String, $supplierID: String, $contractID: String, $companyID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getAPRefund(ID: $ID, supplierID: $supplierID, companyID: $companyID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    accountID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
    }
    bankAccountID
    bankDetails
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
  }
  supplierRefundsInfo(supplierID: $supplierID)
  getAPRefundCount(supplierID: $supplierID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetApRefundListingQuery__
 *
 * To run a query within a React component, call `useGetApRefundListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApRefundListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApRefundListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApRefundListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApRefundListingQuery, GetApRefundListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApRefundListingQuery, GetApRefundListingQueryVariables>(GetApRefundListingDocument, baseOptions);
      }
export function useGetApRefundListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApRefundListingQuery, GetApRefundListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApRefundListingQuery, GetApRefundListingQueryVariables>(GetApRefundListingDocument, baseOptions);
        }
export type GetApRefundListingQueryHookResult = ReturnType<typeof useGetApRefundListingQuery>;
export type GetApRefundListingLazyQueryHookResult = ReturnType<typeof useGetApRefundListingLazyQuery>;
export type GetApRefundListingQueryResult = ApolloReactCommon.QueryResult<GetApRefundListingQuery, GetApRefundListingQueryVariables>;
export const CreateApPaymentDocument = gql`
    mutation createAPPayment($input: APPaymentInput!) {
  createAPPayment(input: $input) {
    supplierID
  }
}
    `;
export type CreateApPaymentMutationFn = ApolloReactCommon.MutationFunction<CreateApPaymentMutation, CreateApPaymentMutationVariables>;

/**
 * __useCreateApPaymentMutation__
 *
 * To run a mutation, you first call `useCreateApPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApPaymentMutation, { data, loading, error }] = useCreateApPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApPaymentMutation, CreateApPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApPaymentMutation, CreateApPaymentMutationVariables>(CreateApPaymentDocument, baseOptions);
      }
export type CreateApPaymentMutationHookResult = ReturnType<typeof useCreateApPaymentMutation>;
export type CreateApPaymentMutationResult = ApolloReactCommon.MutationResult<CreateApPaymentMutation>;
export type CreateApPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApPaymentMutation, CreateApPaymentMutationVariables>;
export const UpdateApPaymentDocument = gql`
    mutation updateAPPayment($input: APPaymentInput!) {
  updateAPPayment(input: $input) {
    supplierID
  }
}
    `;
export type UpdateApPaymentMutationFn = ApolloReactCommon.MutationFunction<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>;

/**
 * __useUpdateApPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateApPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApPaymentMutation, { data, loading, error }] = useUpdateApPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>(UpdateApPaymentDocument, baseOptions);
      }
export type UpdateApPaymentMutationHookResult = ReturnType<typeof useUpdateApPaymentMutation>;
export type UpdateApPaymentMutationResult = ApolloReactCommon.MutationResult<UpdateApPaymentMutation>;
export type UpdateApPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>;
export const GetApPaymentDocument = gql`
    query getAPPayment($ID: String, $supplierID: String, $companyID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getAPPayment(ID: $ID, supplierID: $supplierID, companyID: $companyID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    bankAccountID
    bankDetails
    outstandingAmt
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  supplierPaymentsInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApPaymentQuery__
 *
 * To run a query within a React component, call `useGetApPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApPaymentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApPaymentQuery, GetApPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApPaymentQuery, GetApPaymentQueryVariables>(GetApPaymentDocument, baseOptions);
      }
export function useGetApPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApPaymentQuery, GetApPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApPaymentQuery, GetApPaymentQueryVariables>(GetApPaymentDocument, baseOptions);
        }
export type GetApPaymentQueryHookResult = ReturnType<typeof useGetApPaymentQuery>;
export type GetApPaymentLazyQueryHookResult = ReturnType<typeof useGetApPaymentLazyQuery>;
export type GetApPaymentQueryResult = ApolloReactCommon.QueryResult<GetApPaymentQuery, GetApPaymentQueryVariables>;
export const GetApPaymentListingDocument = gql`
    query getAPPaymentListing($ID: String, $supplierID: String, $companyID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getAPPayment(ID: $ID, supplierID: $supplierID, companyID: $companyID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    bankAccountID
    bankDetails
    outstandingAmt
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  supplierPaymentsInfo(supplierID: $supplierID)
  getAPPaymentCount(supplierID: $supplierID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetApPaymentListingQuery__
 *
 * To run a query within a React component, call `useGetApPaymentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApPaymentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApPaymentListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApPaymentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApPaymentListingQuery, GetApPaymentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApPaymentListingQuery, GetApPaymentListingQueryVariables>(GetApPaymentListingDocument, baseOptions);
      }
export function useGetApPaymentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApPaymentListingQuery, GetApPaymentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApPaymentListingQuery, GetApPaymentListingQueryVariables>(GetApPaymentListingDocument, baseOptions);
        }
export type GetApPaymentListingQueryHookResult = ReturnType<typeof useGetApPaymentListingQuery>;
export type GetApPaymentListingLazyQueryHookResult = ReturnType<typeof useGetApPaymentListingLazyQuery>;
export type GetApPaymentListingQueryResult = ApolloReactCommon.QueryResult<GetApPaymentListingQuery, GetApPaymentListingQueryVariables>;
export const CreateApAdvanceDocument = gql`
    mutation createAPAdvance($input: APPaymentInput!) {
  createAPAdvance(input: $input) {
    supplierID
    ID
    docNo
  }
}
    `;
export type CreateApAdvanceMutationFn = ApolloReactCommon.MutationFunction<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>;

/**
 * __useCreateApAdvanceMutation__
 *
 * To run a mutation, you first call `useCreateApAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApAdvanceMutation, { data, loading, error }] = useCreateApAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>(CreateApAdvanceDocument, baseOptions);
      }
export type CreateApAdvanceMutationHookResult = ReturnType<typeof useCreateApAdvanceMutation>;
export type CreateApAdvanceMutationResult = ApolloReactCommon.MutationResult<CreateApAdvanceMutation>;
export type CreateApAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>;
export const UpdateApAdvanceDocument = gql`
    mutation updateAPAdvance($input: APPaymentInput!) {
  updateAPAdvance(input: $input) {
    supplierID
    ID
    docNo
  }
}
    `;
export type UpdateApAdvanceMutationFn = ApolloReactCommon.MutationFunction<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>;

/**
 * __useUpdateApAdvanceMutation__
 *
 * To run a mutation, you first call `useUpdateApAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApAdvanceMutation, { data, loading, error }] = useUpdateApAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>(UpdateApAdvanceDocument, baseOptions);
      }
export type UpdateApAdvanceMutationHookResult = ReturnType<typeof useUpdateApAdvanceMutation>;
export type UpdateApAdvanceMutationResult = ApolloReactCommon.MutationResult<UpdateApAdvanceMutation>;
export type UpdateApAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>;
export const GetApAdvanceDocument = gql`
    query getAPAdvance($ID: String, $supplierID: String, $contractID: String, $companyID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getAPAdvance(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    docNo
    isGlExport
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  supplierDepositsInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApAdvanceQuery__
 *
 * To run a query within a React component, call `useGetApAdvanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApAdvanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApAdvanceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApAdvanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApAdvanceQuery, GetApAdvanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApAdvanceQuery, GetApAdvanceQueryVariables>(GetApAdvanceDocument, baseOptions);
      }
export function useGetApAdvanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApAdvanceQuery, GetApAdvanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApAdvanceQuery, GetApAdvanceQueryVariables>(GetApAdvanceDocument, baseOptions);
        }
export type GetApAdvanceQueryHookResult = ReturnType<typeof useGetApAdvanceQuery>;
export type GetApAdvanceLazyQueryHookResult = ReturnType<typeof useGetApAdvanceLazyQuery>;
export type GetApAdvanceQueryResult = ApolloReactCommon.QueryResult<GetApAdvanceQuery, GetApAdvanceQueryVariables>;
export const GetApAdvanceListingDocument = gql`
    query getAPAdvanceListing($ID: String, $supplierID: String, $contractID: String, $companyID: String, $receiptStatus: ReceiptStatus, $take: Float, $skip: Float, $searchValue: String) {
  getAPAdvance(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    docNo
    isGlExport
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  supplierDepositsInfo(supplierID: $supplierID)
  getAPAdvanceCount(supplierID: $supplierID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetApAdvanceListingQuery__
 *
 * To run a query within a React component, call `useGetApAdvanceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApAdvanceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApAdvanceListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApAdvanceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApAdvanceListingQuery, GetApAdvanceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApAdvanceListingQuery, GetApAdvanceListingQueryVariables>(GetApAdvanceListingDocument, baseOptions);
      }
export function useGetApAdvanceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApAdvanceListingQuery, GetApAdvanceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApAdvanceListingQuery, GetApAdvanceListingQueryVariables>(GetApAdvanceListingDocument, baseOptions);
        }
export type GetApAdvanceListingQueryHookResult = ReturnType<typeof useGetApAdvanceListingQuery>;
export type GetApAdvanceListingLazyQueryHookResult = ReturnType<typeof useGetApAdvanceListingLazyQuery>;
export type GetApAdvanceListingQueryResult = ApolloReactCommon.QueryResult<GetApAdvanceListingQuery, GetApAdvanceListingQueryVariables>;
export const GetSupplierPaymentTableDocument = gql`
    query getSupplierPaymentTable($supplierID: String, $companyID: String) {
  getAPInvoice(supplierID: $supplierID, companyID: $companyID) {
    ID
    docNo
    docRef
    docDate
    docDueDate
    docAmt
    baseAmt
    description
    outstanding
  }
  getAPDebitNote(supplierID: $supplierID, companyID: $companyID) {
    ID
    docNo
    docRef
    docDate
    docDueDate
    docAmt
    baseAmt
    description
    outstandingAmt
  }
}
    `;

/**
 * __useGetSupplierPaymentTableQuery__
 *
 * To run a query within a React component, call `useGetSupplierPaymentTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierPaymentTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierPaymentTableQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetSupplierPaymentTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierPaymentTableQuery, GetSupplierPaymentTableQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierPaymentTableQuery, GetSupplierPaymentTableQueryVariables>(GetSupplierPaymentTableDocument, baseOptions);
      }
export function useGetSupplierPaymentTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierPaymentTableQuery, GetSupplierPaymentTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierPaymentTableQuery, GetSupplierPaymentTableQueryVariables>(GetSupplierPaymentTableDocument, baseOptions);
        }
export type GetSupplierPaymentTableQueryHookResult = ReturnType<typeof useGetSupplierPaymentTableQuery>;
export type GetSupplierPaymentTableLazyQueryHookResult = ReturnType<typeof useGetSupplierPaymentTableLazyQuery>;
export type GetSupplierPaymentTableQueryResult = ApolloReactCommon.QueryResult<GetSupplierPaymentTableQuery, GetSupplierPaymentTableQueryVariables>;
export const MakeSupplierPaymentDocument = gql`
    mutation makeSupplierPayment($input: APPaymentInput!, $ledger: [LedgerInput!]!) {
  makeSupplierPayment(input: $input, ledger: $ledger) {
    ID
    docNo
  }
}
    `;
export type MakeSupplierPaymentMutationFn = ApolloReactCommon.MutationFunction<MakeSupplierPaymentMutation, MakeSupplierPaymentMutationVariables>;

/**
 * __useMakeSupplierPaymentMutation__
 *
 * To run a mutation, you first call `useMakeSupplierPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSupplierPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSupplierPaymentMutation, { data, loading, error }] = useMakeSupplierPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeSupplierPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeSupplierPaymentMutation, MakeSupplierPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeSupplierPaymentMutation, MakeSupplierPaymentMutationVariables>(MakeSupplierPaymentDocument, baseOptions);
      }
export type MakeSupplierPaymentMutationHookResult = ReturnType<typeof useMakeSupplierPaymentMutation>;
export type MakeSupplierPaymentMutationResult = ApolloReactCommon.MutationResult<MakeSupplierPaymentMutation>;
export type MakeSupplierPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeSupplierPaymentMutation, MakeSupplierPaymentMutationVariables>;
export const CancelSupplierDepositDocument = gql`
    mutation cancelSupplierDeposit($ID: String!, $input: CancelInput!) {
  cancelSupplierDeposit(ID: $ID, input: $input)
}
    `;
export type CancelSupplierDepositMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierDepositMutation, CancelSupplierDepositMutationVariables>;

/**
 * __useCancelSupplierDepositMutation__
 *
 * To run a mutation, you first call `useCancelSupplierDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierDepositMutation, { data, loading, error }] = useCancelSupplierDepositMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierDepositMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierDepositMutation, CancelSupplierDepositMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierDepositMutation, CancelSupplierDepositMutationVariables>(CancelSupplierDepositDocument, baseOptions);
      }
export type CancelSupplierDepositMutationHookResult = ReturnType<typeof useCancelSupplierDepositMutation>;
export type CancelSupplierDepositMutationResult = ApolloReactCommon.MutationResult<CancelSupplierDepositMutation>;
export type CancelSupplierDepositMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierDepositMutation, CancelSupplierDepositMutationVariables>;
export const CancelSupplierRefundDocument = gql`
    mutation cancelSupplierRefund($ID: String!, $input: CancelInput!) {
  cancelSupplierRefund(ID: $ID, input: $input)
}
    `;
export type CancelSupplierRefundMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierRefundMutation, CancelSupplierRefundMutationVariables>;

/**
 * __useCancelSupplierRefundMutation__
 *
 * To run a mutation, you first call `useCancelSupplierRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierRefundMutation, { data, loading, error }] = useCancelSupplierRefundMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierRefundMutation, CancelSupplierRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierRefundMutation, CancelSupplierRefundMutationVariables>(CancelSupplierRefundDocument, baseOptions);
      }
export type CancelSupplierRefundMutationHookResult = ReturnType<typeof useCancelSupplierRefundMutation>;
export type CancelSupplierRefundMutationResult = ApolloReactCommon.MutationResult<CancelSupplierRefundMutation>;
export type CancelSupplierRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierRefundMutation, CancelSupplierRefundMutationVariables>;
export const CancelSupplierPaymentDocument = gql`
    mutation cancelSupplierPayment($ID: String!, $input: CancelInput!) {
  cancelSupplierPayment(ID: $ID, input: $input)
}
    `;
export type CancelSupplierPaymentMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierPaymentMutation, CancelSupplierPaymentMutationVariables>;

/**
 * __useCancelSupplierPaymentMutation__
 *
 * To run a mutation, you first call `useCancelSupplierPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierPaymentMutation, { data, loading, error }] = useCancelSupplierPaymentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierPaymentMutation, CancelSupplierPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierPaymentMutation, CancelSupplierPaymentMutationVariables>(CancelSupplierPaymentDocument, baseOptions);
      }
export type CancelSupplierPaymentMutationHookResult = ReturnType<typeof useCancelSupplierPaymentMutation>;
export type CancelSupplierPaymentMutationResult = ApolloReactCommon.MutationResult<CancelSupplierPaymentMutation>;
export type CancelSupplierPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierPaymentMutation, CancelSupplierPaymentMutationVariables>;
export const DeleteApAdvanceDocument = gql`
    mutation deleteAPAdvance($ID: String!) {
  deleteAPAdvance(ID: $ID)
}
    `;
export type DeleteApAdvanceMutationFn = ApolloReactCommon.MutationFunction<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>;

/**
 * __useDeleteApAdvanceMutation__
 *
 * To run a mutation, you first call `useDeleteApAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApAdvanceMutation, { data, loading, error }] = useDeleteApAdvanceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>(DeleteApAdvanceDocument, baseOptions);
      }
export type DeleteApAdvanceMutationHookResult = ReturnType<typeof useDeleteApAdvanceMutation>;
export type DeleteApAdvanceMutationResult = ApolloReactCommon.MutationResult<DeleteApAdvanceMutation>;
export type DeleteApAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>;
export const DeleteApPaymentDocument = gql`
    mutation deleteAPPayment($ID: String!) {
  deleteAPPayment(ID: $ID)
}
    `;
export type DeleteApPaymentMutationFn = ApolloReactCommon.MutationFunction<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>;

/**
 * __useDeleteApPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteApPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApPaymentMutation, { data, loading, error }] = useDeleteApPaymentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>(DeleteApPaymentDocument, baseOptions);
      }
export type DeleteApPaymentMutationHookResult = ReturnType<typeof useDeleteApPaymentMutation>;
export type DeleteApPaymentMutationResult = ApolloReactCommon.MutationResult<DeleteApPaymentMutation>;
export type DeleteApPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>;
export const DeleteApRefundDocument = gql`
    mutation deleteAPRefund($ID: String!) {
  deleteAPRefund(ID: $ID)
}
    `;
export type DeleteApRefundMutationFn = ApolloReactCommon.MutationFunction<DeleteApRefundMutation, DeleteApRefundMutationVariables>;

/**
 * __useDeleteApRefundMutation__
 *
 * To run a mutation, you first call `useDeleteApRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApRefundMutation, { data, loading, error }] = useDeleteApRefundMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApRefundMutation, DeleteApRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApRefundMutation, DeleteApRefundMutationVariables>(DeleteApRefundDocument, baseOptions);
      }
export type DeleteApRefundMutationHookResult = ReturnType<typeof useDeleteApRefundMutation>;
export type DeleteApRefundMutationResult = ApolloReactCommon.MutationResult<DeleteApRefundMutation>;
export type DeleteApRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApRefundMutation, DeleteApRefundMutationVariables>;
export const GetApAdvanceCountDocument = gql`
    query getAPAdvanceCount($supplierID: String, $contractID: String, $companyID: String, $receiptStatus: ReceiptStatus) {
  getAPAdvanceCount(supplierID: $supplierID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetApAdvanceCountQuery__
 *
 * To run a query within a React component, call `useGetApAdvanceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApAdvanceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApAdvanceCountQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetApAdvanceCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApAdvanceCountQuery, GetApAdvanceCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApAdvanceCountQuery, GetApAdvanceCountQueryVariables>(GetApAdvanceCountDocument, baseOptions);
      }
export function useGetApAdvanceCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApAdvanceCountQuery, GetApAdvanceCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApAdvanceCountQuery, GetApAdvanceCountQueryVariables>(GetApAdvanceCountDocument, baseOptions);
        }
export type GetApAdvanceCountQueryHookResult = ReturnType<typeof useGetApAdvanceCountQuery>;
export type GetApAdvanceCountLazyQueryHookResult = ReturnType<typeof useGetApAdvanceCountLazyQuery>;
export type GetApAdvanceCountQueryResult = ApolloReactCommon.QueryResult<GetApAdvanceCountQuery, GetApAdvanceCountQueryVariables>;
export const GetApRefundCountDocument = gql`
    query getAPRefundCount($supplierID: String, $contractID: String, $companyID: String, $receiptStatus: ReceiptStatus) {
  getAPRefundCount(supplierID: $supplierID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetApRefundCountQuery__
 *
 * To run a query within a React component, call `useGetApRefundCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApRefundCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApRefundCountQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetApRefundCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApRefundCountQuery, GetApRefundCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApRefundCountQuery, GetApRefundCountQueryVariables>(GetApRefundCountDocument, baseOptions);
      }
export function useGetApRefundCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApRefundCountQuery, GetApRefundCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApRefundCountQuery, GetApRefundCountQueryVariables>(GetApRefundCountDocument, baseOptions);
        }
export type GetApRefundCountQueryHookResult = ReturnType<typeof useGetApRefundCountQuery>;
export type GetApRefundCountLazyQueryHookResult = ReturnType<typeof useGetApRefundCountLazyQuery>;
export type GetApRefundCountQueryResult = ApolloReactCommon.QueryResult<GetApRefundCountQuery, GetApRefundCountQueryVariables>;
export const GetApPaymentCountDocument = gql`
    query getAPPaymentCount($supplierID: String, $contractID: String, $companyID: String, $receiptStatus: ReceiptStatus) {
  getAPPaymentCount(supplierID: $supplierID, contractID: $contractID, companyID: $companyID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetApPaymentCountQuery__
 *
 * To run a query within a React component, call `useGetApPaymentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApPaymentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApPaymentCountQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetApPaymentCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApPaymentCountQuery, GetApPaymentCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApPaymentCountQuery, GetApPaymentCountQueryVariables>(GetApPaymentCountDocument, baseOptions);
      }
export function useGetApPaymentCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApPaymentCountQuery, GetApPaymentCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApPaymentCountQuery, GetApPaymentCountQueryVariables>(GetApPaymentCountDocument, baseOptions);
        }
export type GetApPaymentCountQueryHookResult = ReturnType<typeof useGetApPaymentCountQuery>;
export type GetApPaymentCountLazyQueryHookResult = ReturnType<typeof useGetApPaymentCountLazyQuery>;
export type GetApPaymentCountQueryResult = ApolloReactCommon.QueryResult<GetApPaymentCountQuery, GetApPaymentCountQueryVariables>;
export const CreateApDebitNoteDocument = gql`
    mutation createAPDebitNote($input: APTransactionInput!) {
  createAPDebitNote(input: $input) {
    supplierID
    ID
  }
}
    `;
export type CreateApDebitNoteMutationFn = ApolloReactCommon.MutationFunction<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>;

/**
 * __useCreateApDebitNoteMutation__
 *
 * To run a mutation, you first call `useCreateApDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApDebitNoteMutation, { data, loading, error }] = useCreateApDebitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>(CreateApDebitNoteDocument, baseOptions);
      }
export type CreateApDebitNoteMutationHookResult = ReturnType<typeof useCreateApDebitNoteMutation>;
export type CreateApDebitNoteMutationResult = ApolloReactCommon.MutationResult<CreateApDebitNoteMutation>;
export type CreateApDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>;
export const UpdateApDebitNoteDocument = gql`
    mutation updateAPDebitNote($input: APTransactionInput!) {
  updateAPDebitNote(input: $input) {
    supplierID
    ID
  }
}
    `;
export type UpdateApDebitNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>;

/**
 * __useUpdateApDebitNoteMutation__
 *
 * To run a mutation, you first call `useUpdateApDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApDebitNoteMutation, { data, loading, error }] = useUpdateApDebitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>(UpdateApDebitNoteDocument, baseOptions);
      }
export type UpdateApDebitNoteMutationHookResult = ReturnType<typeof useUpdateApDebitNoteMutation>;
export type UpdateApDebitNoteMutationResult = ApolloReactCommon.MutationResult<UpdateApDebitNoteMutation>;
export type UpdateApDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>;
export const GetApDebitNoteDocument = gql`
    query getAPDebitNote($ID: String, $supplierID: String, $contractID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getAPDebitNote(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    accountID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APDebitNoteItem {
      ID
      costClass
      costCategoryID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  supplierDNInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApDebitNoteQuery__
 *
 * To run a query within a React component, call `useGetApDebitNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApDebitNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApDebitNoteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApDebitNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>(GetApDebitNoteDocument, baseOptions);
      }
export function useGetApDebitNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>(GetApDebitNoteDocument, baseOptions);
        }
export type GetApDebitNoteQueryHookResult = ReturnType<typeof useGetApDebitNoteQuery>;
export type GetApDebitNoteLazyQueryHookResult = ReturnType<typeof useGetApDebitNoteLazyQuery>;
export type GetApDebitNoteQueryResult = ApolloReactCommon.QueryResult<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>;
export const GetApDebitNoteListingDocument = gql`
    query getAPDebitNoteListing($ID: String, $supplierID: String, $contractID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getAPDebitNote(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    accountID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APDebitNoteItem {
      ID
      costClass
      costCategoryID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  supplierDNInfo(supplierID: $supplierID)
  getAPDebitNoteCount(companyID: $companyID, supplierID: $supplierID, contractID: $contractID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetApDebitNoteListingQuery__
 *
 * To run a query within a React component, call `useGetApDebitNoteListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApDebitNoteListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApDebitNoteListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApDebitNoteListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApDebitNoteListingQuery, GetApDebitNoteListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApDebitNoteListingQuery, GetApDebitNoteListingQueryVariables>(GetApDebitNoteListingDocument, baseOptions);
      }
export function useGetApDebitNoteListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApDebitNoteListingQuery, GetApDebitNoteListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApDebitNoteListingQuery, GetApDebitNoteListingQueryVariables>(GetApDebitNoteListingDocument, baseOptions);
        }
export type GetApDebitNoteListingQueryHookResult = ReturnType<typeof useGetApDebitNoteListingQuery>;
export type GetApDebitNoteListingLazyQueryHookResult = ReturnType<typeof useGetApDebitNoteListingLazyQuery>;
export type GetApDebitNoteListingQueryResult = ApolloReactCommon.QueryResult<GetApDebitNoteListingQuery, GetApDebitNoteListingQueryVariables>;
export const CreateApCreditNoteDocument = gql`
    mutation createAPCreditNote($input: APTransactionInput!) {
  createAPCreditNote(input: $input) {
    supplierID
    ID
  }
}
    `;
export type CreateApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>;

/**
 * __useCreateApCreditNoteMutation__
 *
 * To run a mutation, you first call `useCreateApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApCreditNoteMutation, { data, loading, error }] = useCreateApCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>(CreateApCreditNoteDocument, baseOptions);
      }
export type CreateApCreditNoteMutationHookResult = ReturnType<typeof useCreateApCreditNoteMutation>;
export type CreateApCreditNoteMutationResult = ApolloReactCommon.MutationResult<CreateApCreditNoteMutation>;
export type CreateApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>;
export const UpdateApCreditNoteDocument = gql`
    mutation updateAPCreditNote($input: APTransactionInput!) {
  updateAPCreditNote(input: $input) {
    supplierID
    ID
  }
}
    `;
export type UpdateApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>;

/**
 * __useUpdateApCreditNoteMutation__
 *
 * To run a mutation, you first call `useUpdateApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApCreditNoteMutation, { data, loading, error }] = useUpdateApCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>(UpdateApCreditNoteDocument, baseOptions);
      }
export type UpdateApCreditNoteMutationHookResult = ReturnType<typeof useUpdateApCreditNoteMutation>;
export type UpdateApCreditNoteMutationResult = ApolloReactCommon.MutationResult<UpdateApCreditNoteMutation>;
export type UpdateApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>;
export const GetApCreditNoteDocument = gql`
    query getAPCreditNote($ID: String, $supplierID: String, $contractID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getAPCreditNote(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    accountID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    outstandingAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APCreditNoteItem {
      ID
      costClass
      costCategoryID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  supplierCNInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApCreditNoteQuery__
 *
 * To run a query within a React component, call `useGetApCreditNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditNoteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApCreditNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>(GetApCreditNoteDocument, baseOptions);
      }
export function useGetApCreditNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>(GetApCreditNoteDocument, baseOptions);
        }
export type GetApCreditNoteQueryHookResult = ReturnType<typeof useGetApCreditNoteQuery>;
export type GetApCreditNoteLazyQueryHookResult = ReturnType<typeof useGetApCreditNoteLazyQuery>;
export type GetApCreditNoteQueryResult = ApolloReactCommon.QueryResult<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>;
export const GetApCreditNoteListingDocument = gql`
    query getAPCreditNoteListing($ID: String, $supplierID: String, $contractID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getAPCreditNote(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    accountID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    outstandingAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APCreditNoteItem {
      ID
      costClass
      costCategoryID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  supplierCNInfo(supplierID: $supplierID)
  getAPCreditNoteCount(companyID: $companyID, supplierID: $supplierID, contractID: $contractID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetApCreditNoteListingQuery__
 *
 * To run a query within a React component, call `useGetApCreditNoteListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditNoteListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditNoteListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApCreditNoteListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditNoteListingQuery, GetApCreditNoteListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditNoteListingQuery, GetApCreditNoteListingQueryVariables>(GetApCreditNoteListingDocument, baseOptions);
      }
export function useGetApCreditNoteListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditNoteListingQuery, GetApCreditNoteListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditNoteListingQuery, GetApCreditNoteListingQueryVariables>(GetApCreditNoteListingDocument, baseOptions);
        }
export type GetApCreditNoteListingQueryHookResult = ReturnType<typeof useGetApCreditNoteListingQuery>;
export type GetApCreditNoteListingLazyQueryHookResult = ReturnType<typeof useGetApCreditNoteListingLazyQuery>;
export type GetApCreditNoteListingQueryResult = ApolloReactCommon.QueryResult<GetApCreditNoteListingQuery, GetApCreditNoteListingQueryVariables>;
export const CreateDNtoSupplierDocument = gql`
    mutation createDNtoSupplier($input: APTransactionInput!) {
  createDNtoSupplier(input: $input) {
    supplierID
    ID
    docNo
  }
}
    `;
export type CreateDNtoSupplierMutationFn = ApolloReactCommon.MutationFunction<CreateDNtoSupplierMutation, CreateDNtoSupplierMutationVariables>;

/**
 * __useCreateDNtoSupplierMutation__
 *
 * To run a mutation, you first call `useCreateDNtoSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDNtoSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDNtoSupplierMutation, { data, loading, error }] = useCreateDNtoSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDNtoSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDNtoSupplierMutation, CreateDNtoSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDNtoSupplierMutation, CreateDNtoSupplierMutationVariables>(CreateDNtoSupplierDocument, baseOptions);
      }
export type CreateDNtoSupplierMutationHookResult = ReturnType<typeof useCreateDNtoSupplierMutation>;
export type CreateDNtoSupplierMutationResult = ApolloReactCommon.MutationResult<CreateDNtoSupplierMutation>;
export type CreateDNtoSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDNtoSupplierMutation, CreateDNtoSupplierMutationVariables>;
export const UpdateDNtoSupplierDocument = gql`
    mutation updateDNtoSupplier($input: APTransactionInput!) {
  updateDNtoSupplier(input: $input) {
    supplierID
    ID
    docNo
  }
}
    `;
export type UpdateDNtoSupplierMutationFn = ApolloReactCommon.MutationFunction<UpdateDNtoSupplierMutation, UpdateDNtoSupplierMutationVariables>;

/**
 * __useUpdateDNtoSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateDNtoSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDNtoSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDNtoSupplierMutation, { data, loading, error }] = useUpdateDNtoSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDNtoSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDNtoSupplierMutation, UpdateDNtoSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDNtoSupplierMutation, UpdateDNtoSupplierMutationVariables>(UpdateDNtoSupplierDocument, baseOptions);
      }
export type UpdateDNtoSupplierMutationHookResult = ReturnType<typeof useUpdateDNtoSupplierMutation>;
export type UpdateDNtoSupplierMutationResult = ApolloReactCommon.MutationResult<UpdateDNtoSupplierMutation>;
export type UpdateDNtoSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDNtoSupplierMutation, UpdateDNtoSupplierMutationVariables>;
export const GetDNtoSupplierDocument = gql`
    query getDNtoSupplier($ID: String, $supplierID: String, $contractID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getDNtoSupplier(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    docNo
    docRef
    isGlExport
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    DNtoSupplierItem {
      ID
      costClass
      costCategoryID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
}
    `;

/**
 * __useGetDNtoSupplierQuery__
 *
 * To run a query within a React component, call `useGetDNtoSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSupplierQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetDNtoSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>(GetDNtoSupplierDocument, baseOptions);
      }
export function useGetDNtoSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>(GetDNtoSupplierDocument, baseOptions);
        }
export type GetDNtoSupplierQueryHookResult = ReturnType<typeof useGetDNtoSupplierQuery>;
export type GetDNtoSupplierLazyQueryHookResult = ReturnType<typeof useGetDNtoSupplierLazyQuery>;
export type GetDNtoSupplierQueryResult = ApolloReactCommon.QueryResult<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>;
export const GetDNtoSupplierListingDocument = gql`
    query getDNtoSupplierListing($ID: String, $supplierID: String, $contractID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getDNtoSupplier(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    docNo
    docRef
    isGlExport
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    DNtoSupplierItem {
      ID
      costClass
      costCategoryID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  getDNtoSupplierCount(companyID: $companyID, supplierID: $supplierID, contractID: $contractID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetDNtoSupplierListingQuery__
 *
 * To run a query within a React component, call `useGetDNtoSupplierListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSupplierListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSupplierListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetDNtoSupplierListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSupplierListingQuery, GetDNtoSupplierListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSupplierListingQuery, GetDNtoSupplierListingQueryVariables>(GetDNtoSupplierListingDocument, baseOptions);
      }
export function useGetDNtoSupplierListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSupplierListingQuery, GetDNtoSupplierListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSupplierListingQuery, GetDNtoSupplierListingQueryVariables>(GetDNtoSupplierListingDocument, baseOptions);
        }
export type GetDNtoSupplierListingQueryHookResult = ReturnType<typeof useGetDNtoSupplierListingQuery>;
export type GetDNtoSupplierListingLazyQueryHookResult = ReturnType<typeof useGetDNtoSupplierListingLazyQuery>;
export type GetDNtoSupplierListingQueryResult = ApolloReactCommon.QueryResult<GetDNtoSupplierListingQuery, GetDNtoSupplierListingQueryVariables>;
export const CreateCNtoSupplierDocument = gql`
    mutation createCNtoSupplier($input: APTransactionInput!) {
  createCNtoSupplier(input: $input) {
    ID
    supplierID
    docNo
  }
}
    `;
export type CreateCNtoSupplierMutationFn = ApolloReactCommon.MutationFunction<CreateCNtoSupplierMutation, CreateCNtoSupplierMutationVariables>;

/**
 * __useCreateCNtoSupplierMutation__
 *
 * To run a mutation, you first call `useCreateCNtoSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCNtoSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCNtoSupplierMutation, { data, loading, error }] = useCreateCNtoSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCNtoSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCNtoSupplierMutation, CreateCNtoSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCNtoSupplierMutation, CreateCNtoSupplierMutationVariables>(CreateCNtoSupplierDocument, baseOptions);
      }
export type CreateCNtoSupplierMutationHookResult = ReturnType<typeof useCreateCNtoSupplierMutation>;
export type CreateCNtoSupplierMutationResult = ApolloReactCommon.MutationResult<CreateCNtoSupplierMutation>;
export type CreateCNtoSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCNtoSupplierMutation, CreateCNtoSupplierMutationVariables>;
export const UpdateCNtoSupplierDocument = gql`
    mutation updateCNtoSupplier($input: APTransactionInput!) {
  updateCNtoSupplier(input: $input) {
    ID
    supplierID
    docNo
  }
}
    `;
export type UpdateCNtoSupplierMutationFn = ApolloReactCommon.MutationFunction<UpdateCNtoSupplierMutation, UpdateCNtoSupplierMutationVariables>;

/**
 * __useUpdateCNtoSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateCNtoSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCNtoSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCNtoSupplierMutation, { data, loading, error }] = useUpdateCNtoSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCNtoSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCNtoSupplierMutation, UpdateCNtoSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCNtoSupplierMutation, UpdateCNtoSupplierMutationVariables>(UpdateCNtoSupplierDocument, baseOptions);
      }
export type UpdateCNtoSupplierMutationHookResult = ReturnType<typeof useUpdateCNtoSupplierMutation>;
export type UpdateCNtoSupplierMutationResult = ApolloReactCommon.MutationResult<UpdateCNtoSupplierMutation>;
export type UpdateCNtoSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCNtoSupplierMutation, UpdateCNtoSupplierMutationVariables>;
export const GetCNtoSupplierDocument = gql`
    query getCNtoSupplier($ID: String, $supplierID: String, $contractID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getCNtoSupplier(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    CNtoSupplierItem {
      ID
      costClass
      costCategoryID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
}
    `;

/**
 * __useGetCNtoSupplierQuery__
 *
 * To run a query within a React component, call `useGetCNtoSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSupplierQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetCNtoSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSupplierQuery, GetCNtoSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSupplierQuery, GetCNtoSupplierQueryVariables>(GetCNtoSupplierDocument, baseOptions);
      }
export function useGetCNtoSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSupplierQuery, GetCNtoSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSupplierQuery, GetCNtoSupplierQueryVariables>(GetCNtoSupplierDocument, baseOptions);
        }
export type GetCNtoSupplierQueryHookResult = ReturnType<typeof useGetCNtoSupplierQuery>;
export type GetCNtoSupplierLazyQueryHookResult = ReturnType<typeof useGetCNtoSupplierLazyQuery>;
export type GetCNtoSupplierQueryResult = ApolloReactCommon.QueryResult<GetCNtoSupplierQuery, GetCNtoSupplierQueryVariables>;
export const GetCNtoSupplierListingDocument = gql`
    query getCNtoSupplierListing($ID: String, $supplierID: String, $contractID: String, $companyID: String, $transactionStatus: TransactionStatus, $take: Float, $skip: Float, $searchValue: String) {
  getCNtoSupplier(ID: $ID, supplierID: $supplierID, contractID: $contractID, companyID: $companyID, transactionStatus: $transactionStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    CNtoSupplierItem {
      ID
      costClass
      costCategoryID
      costItemID
      costItem {
        ID
        name
      }
      taxSchemeID
      taxScheme {
        ID
        code
        latestTax {
          taxSchemeID
          taxRate
          taxDate
        }
      }
      wbsID
      wbs {
        ID
        name
        parentName
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  getCNtoSupplierCount(companyID: $companyID, supplierID: $supplierID, contractID: $contractID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetCNtoSupplierListingQuery__
 *
 * To run a query within a React component, call `useGetCNtoSupplierListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSupplierListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSupplierListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      companyID: // value for 'companyID'
 *      transactionStatus: // value for 'transactionStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetCNtoSupplierListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSupplierListingQuery, GetCNtoSupplierListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSupplierListingQuery, GetCNtoSupplierListingQueryVariables>(GetCNtoSupplierListingDocument, baseOptions);
      }
export function useGetCNtoSupplierListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSupplierListingQuery, GetCNtoSupplierListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSupplierListingQuery, GetCNtoSupplierListingQueryVariables>(GetCNtoSupplierListingDocument, baseOptions);
        }
export type GetCNtoSupplierListingQueryHookResult = ReturnType<typeof useGetCNtoSupplierListingQuery>;
export type GetCNtoSupplierListingLazyQueryHookResult = ReturnType<typeof useGetCNtoSupplierListingLazyQuery>;
export type GetCNtoSupplierListingQueryResult = ApolloReactCommon.QueryResult<GetCNtoSupplierListingQuery, GetCNtoSupplierListingQueryVariables>;
export const CancelSupplierDnDocument = gql`
    mutation cancelSupplierDN($ID: String!, $input: CancelInput!) {
  cancelSupplierDN(ID: $ID, input: $input)
}
    `;
export type CancelSupplierDnMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierDnMutation, CancelSupplierDnMutationVariables>;

/**
 * __useCancelSupplierDnMutation__
 *
 * To run a mutation, you first call `useCancelSupplierDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierDnMutation, { data, loading, error }] = useCancelSupplierDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierDnMutation, CancelSupplierDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierDnMutation, CancelSupplierDnMutationVariables>(CancelSupplierDnDocument, baseOptions);
      }
export type CancelSupplierDnMutationHookResult = ReturnType<typeof useCancelSupplierDnMutation>;
export type CancelSupplierDnMutationResult = ApolloReactCommon.MutationResult<CancelSupplierDnMutation>;
export type CancelSupplierDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierDnMutation, CancelSupplierDnMutationVariables>;
export const CancelSupplierCnDocument = gql`
    mutation cancelSupplierCN($ID: String!, $input: CancelInput!) {
  cancelSupplierCN(ID: $ID, input: $input)
}
    `;
export type CancelSupplierCnMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierCnMutation, CancelSupplierCnMutationVariables>;

/**
 * __useCancelSupplierCnMutation__
 *
 * To run a mutation, you first call `useCancelSupplierCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierCnMutation, { data, loading, error }] = useCancelSupplierCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierCnMutation, CancelSupplierCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierCnMutation, CancelSupplierCnMutationVariables>(CancelSupplierCnDocument, baseOptions);
      }
export type CancelSupplierCnMutationHookResult = ReturnType<typeof useCancelSupplierCnMutation>;
export type CancelSupplierCnMutationResult = ApolloReactCommon.MutationResult<CancelSupplierCnMutation>;
export type CancelSupplierCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierCnMutation, CancelSupplierCnMutationVariables>;
export const CancelDNtoSupplierDocument = gql`
    mutation cancelDNtoSupplier($ID: String!, $input: CancelInput!) {
  cancelDNtoSupplier(ID: $ID, input: $input)
}
    `;
export type CancelDNtoSupplierMutationFn = ApolloReactCommon.MutationFunction<CancelDNtoSupplierMutation, CancelDNtoSupplierMutationVariables>;

/**
 * __useCancelDNtoSupplierMutation__
 *
 * To run a mutation, you first call `useCancelDNtoSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDNtoSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDNtoSupplierMutation, { data, loading, error }] = useCancelDNtoSupplierMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelDNtoSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelDNtoSupplierMutation, CancelDNtoSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelDNtoSupplierMutation, CancelDNtoSupplierMutationVariables>(CancelDNtoSupplierDocument, baseOptions);
      }
export type CancelDNtoSupplierMutationHookResult = ReturnType<typeof useCancelDNtoSupplierMutation>;
export type CancelDNtoSupplierMutationResult = ApolloReactCommon.MutationResult<CancelDNtoSupplierMutation>;
export type CancelDNtoSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelDNtoSupplierMutation, CancelDNtoSupplierMutationVariables>;
export const CancelCNtoSupplierDocument = gql`
    mutation cancelCNtoSupplier($ID: String!, $input: CancelInput!) {
  cancelCNtoSupplier(ID: $ID, input: $input)
}
    `;
export type CancelCNtoSupplierMutationFn = ApolloReactCommon.MutationFunction<CancelCNtoSupplierMutation, CancelCNtoSupplierMutationVariables>;

/**
 * __useCancelCNtoSupplierMutation__
 *
 * To run a mutation, you first call `useCancelCNtoSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCNtoSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCNtoSupplierMutation, { data, loading, error }] = useCancelCNtoSupplierMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCNtoSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCNtoSupplierMutation, CancelCNtoSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCNtoSupplierMutation, CancelCNtoSupplierMutationVariables>(CancelCNtoSupplierDocument, baseOptions);
      }
export type CancelCNtoSupplierMutationHookResult = ReturnType<typeof useCancelCNtoSupplierMutation>;
export type CancelCNtoSupplierMutationResult = ApolloReactCommon.MutationResult<CancelCNtoSupplierMutation>;
export type CancelCNtoSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCNtoSupplierMutation, CancelCNtoSupplierMutationVariables>;
export const DeleteApCreditNoteDocument = gql`
    mutation deleteAPCreditNote($ID: String!) {
  deleteAPCreditNote(ID: $ID)
}
    `;
export type DeleteApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>;

/**
 * __useDeleteApCreditNoteMutation__
 *
 * To run a mutation, you first call `useDeleteApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApCreditNoteMutation, { data, loading, error }] = useDeleteApCreditNoteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>(DeleteApCreditNoteDocument, baseOptions);
      }
export type DeleteApCreditNoteMutationHookResult = ReturnType<typeof useDeleteApCreditNoteMutation>;
export type DeleteApCreditNoteMutationResult = ApolloReactCommon.MutationResult<DeleteApCreditNoteMutation>;
export type DeleteApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>;
export const DeleteApDebitNoteDocument = gql`
    mutation deleteAPDebitNote($ID: String!) {
  deleteAPDebitNote(ID: $ID)
}
    `;
export type DeleteApDebitNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>;

/**
 * __useDeleteApDebitNoteMutation__
 *
 * To run a mutation, you first call `useDeleteApDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApDebitNoteMutation, { data, loading, error }] = useDeleteApDebitNoteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>(DeleteApDebitNoteDocument, baseOptions);
      }
export type DeleteApDebitNoteMutationHookResult = ReturnType<typeof useDeleteApDebitNoteMutation>;
export type DeleteApDebitNoteMutationResult = ApolloReactCommon.MutationResult<DeleteApDebitNoteMutation>;
export type DeleteApDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>;
export const DeleteCNtoSupplierDocument = gql`
    mutation deleteCNtoSupplier($ID: String!) {
  deleteCNtoSupplier(ID: $ID)
}
    `;
export type DeleteCNtoSupplierMutationFn = ApolloReactCommon.MutationFunction<DeleteCNtoSupplierMutation, DeleteCNtoSupplierMutationVariables>;

/**
 * __useDeleteCNtoSupplierMutation__
 *
 * To run a mutation, you first call `useDeleteCNtoSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCNtoSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCNtoSupplierMutation, { data, loading, error }] = useDeleteCNtoSupplierMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteCNtoSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCNtoSupplierMutation, DeleteCNtoSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCNtoSupplierMutation, DeleteCNtoSupplierMutationVariables>(DeleteCNtoSupplierDocument, baseOptions);
      }
export type DeleteCNtoSupplierMutationHookResult = ReturnType<typeof useDeleteCNtoSupplierMutation>;
export type DeleteCNtoSupplierMutationResult = ApolloReactCommon.MutationResult<DeleteCNtoSupplierMutation>;
export type DeleteCNtoSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCNtoSupplierMutation, DeleteCNtoSupplierMutationVariables>;
export const DeleteDNtoSupplierDocument = gql`
    mutation deleteDNtoSupplier($ID: String!) {
  deleteDNtoSupplier(ID: $ID)
}
    `;
export type DeleteDNtoSupplierMutationFn = ApolloReactCommon.MutationFunction<DeleteDNtoSupplierMutation, DeleteDNtoSupplierMutationVariables>;

/**
 * __useDeleteDNtoSupplierMutation__
 *
 * To run a mutation, you first call `useDeleteDNtoSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDNtoSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDNtoSupplierMutation, { data, loading, error }] = useDeleteDNtoSupplierMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteDNtoSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDNtoSupplierMutation, DeleteDNtoSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDNtoSupplierMutation, DeleteDNtoSupplierMutationVariables>(DeleteDNtoSupplierDocument, baseOptions);
      }
export type DeleteDNtoSupplierMutationHookResult = ReturnType<typeof useDeleteDNtoSupplierMutation>;
export type DeleteDNtoSupplierMutationResult = ApolloReactCommon.MutationResult<DeleteDNtoSupplierMutation>;
export type DeleteDNtoSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDNtoSupplierMutation, DeleteDNtoSupplierMutationVariables>;
export const GetSupplierAdjustmentDocument = gql`
    query getSupplierAdjustment($companyID: String, $contractID: String, $supplierID: String, $transactionStatus: TransactionStatus) {
  getAPDebitNote(companyID: $companyID, contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    taxAmt
    description
    deductionTypeID
    deductionType {
      ID
      code
    }
  }
  getAPCreditNote(companyID: $companyID, contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    taxAmt
    description
    deductionTypeID
    deductionType {
      ID
      code
    }
  }
  getCNtoSupplier(companyID: $companyID, contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    taxAmt
    description
    deductionTypeID
    deductionType {
      ID
      code
    }
  }
  getDNtoSupplier(companyID: $companyID, contractID: $contractID, supplierID: $supplierID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    taxAmt
    description
    deductionTypeID
    deductionType {
      ID
      code
    }
  }
}
    `;

/**
 * __useGetSupplierAdjustmentQuery__
 *
 * To run a query within a React component, call `useGetSupplierAdjustmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierAdjustmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierAdjustmentQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetSupplierAdjustmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierAdjustmentQuery, GetSupplierAdjustmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierAdjustmentQuery, GetSupplierAdjustmentQueryVariables>(GetSupplierAdjustmentDocument, baseOptions);
      }
export function useGetSupplierAdjustmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierAdjustmentQuery, GetSupplierAdjustmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierAdjustmentQuery, GetSupplierAdjustmentQueryVariables>(GetSupplierAdjustmentDocument, baseOptions);
        }
export type GetSupplierAdjustmentQueryHookResult = ReturnType<typeof useGetSupplierAdjustmentQuery>;
export type GetSupplierAdjustmentLazyQueryHookResult = ReturnType<typeof useGetSupplierAdjustmentLazyQuery>;
export type GetSupplierAdjustmentQueryResult = ApolloReactCommon.QueryResult<GetSupplierAdjustmentQuery, GetSupplierAdjustmentQueryVariables>;
export const GetApDebitNoteCountDocument = gql`
    query getAPDebitNoteCount($companyID: String, $supplierID: String, $contractID: String, $transactionStatus: TransactionStatus) {
  getAPDebitNoteCount(companyID: $companyID, supplierID: $supplierID, contractID: $contractID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetApDebitNoteCountQuery__
 *
 * To run a query within a React component, call `useGetApDebitNoteCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApDebitNoteCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApDebitNoteCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetApDebitNoteCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApDebitNoteCountQuery, GetApDebitNoteCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApDebitNoteCountQuery, GetApDebitNoteCountQueryVariables>(GetApDebitNoteCountDocument, baseOptions);
      }
export function useGetApDebitNoteCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApDebitNoteCountQuery, GetApDebitNoteCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApDebitNoteCountQuery, GetApDebitNoteCountQueryVariables>(GetApDebitNoteCountDocument, baseOptions);
        }
export type GetApDebitNoteCountQueryHookResult = ReturnType<typeof useGetApDebitNoteCountQuery>;
export type GetApDebitNoteCountLazyQueryHookResult = ReturnType<typeof useGetApDebitNoteCountLazyQuery>;
export type GetApDebitNoteCountQueryResult = ApolloReactCommon.QueryResult<GetApDebitNoteCountQuery, GetApDebitNoteCountQueryVariables>;
export const GetApCreditNoteCountDocument = gql`
    query getAPCreditNoteCount($companyID: String, $supplierID: String, $contractID: String, $transactionStatus: TransactionStatus) {
  getAPCreditNoteCount(companyID: $companyID, supplierID: $supplierID, contractID: $contractID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetApCreditNoteCountQuery__
 *
 * To run a query within a React component, call `useGetApCreditNoteCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditNoteCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditNoteCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetApCreditNoteCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditNoteCountQuery, GetApCreditNoteCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditNoteCountQuery, GetApCreditNoteCountQueryVariables>(GetApCreditNoteCountDocument, baseOptions);
      }
export function useGetApCreditNoteCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditNoteCountQuery, GetApCreditNoteCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditNoteCountQuery, GetApCreditNoteCountQueryVariables>(GetApCreditNoteCountDocument, baseOptions);
        }
export type GetApCreditNoteCountQueryHookResult = ReturnType<typeof useGetApCreditNoteCountQuery>;
export type GetApCreditNoteCountLazyQueryHookResult = ReturnType<typeof useGetApCreditNoteCountLazyQuery>;
export type GetApCreditNoteCountQueryResult = ApolloReactCommon.QueryResult<GetApCreditNoteCountQuery, GetApCreditNoteCountQueryVariables>;
export const GetDNtoSupplierCountDocument = gql`
    query getDNtoSupplierCount($companyID: String, $supplierID: String, $contractID: String, $transactionStatus: TransactionStatus) {
  getDNtoSupplierCount(companyID: $companyID, supplierID: $supplierID, contractID: $contractID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetDNtoSupplierCountQuery__
 *
 * To run a query within a React component, call `useGetDNtoSupplierCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSupplierCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSupplierCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetDNtoSupplierCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSupplierCountQuery, GetDNtoSupplierCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSupplierCountQuery, GetDNtoSupplierCountQueryVariables>(GetDNtoSupplierCountDocument, baseOptions);
      }
export function useGetDNtoSupplierCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSupplierCountQuery, GetDNtoSupplierCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSupplierCountQuery, GetDNtoSupplierCountQueryVariables>(GetDNtoSupplierCountDocument, baseOptions);
        }
export type GetDNtoSupplierCountQueryHookResult = ReturnType<typeof useGetDNtoSupplierCountQuery>;
export type GetDNtoSupplierCountLazyQueryHookResult = ReturnType<typeof useGetDNtoSupplierCountLazyQuery>;
export type GetDNtoSupplierCountQueryResult = ApolloReactCommon.QueryResult<GetDNtoSupplierCountQuery, GetDNtoSupplierCountQueryVariables>;
export const GetCNtoSupplierCountDocument = gql`
    query getCNtoSupplierCount($companyID: String, $supplierID: String, $contractID: String, $transactionStatus: TransactionStatus) {
  getCNtoSupplierCount(companyID: $companyID, supplierID: $supplierID, contractID: $contractID, transactionStatus: $transactionStatus)
}
    `;

/**
 * __useGetCNtoSupplierCountQuery__
 *
 * To run a query within a React component, call `useGetCNtoSupplierCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSupplierCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSupplierCountQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetCNtoSupplierCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSupplierCountQuery, GetCNtoSupplierCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSupplierCountQuery, GetCNtoSupplierCountQueryVariables>(GetCNtoSupplierCountDocument, baseOptions);
      }
export function useGetCNtoSupplierCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSupplierCountQuery, GetCNtoSupplierCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSupplierCountQuery, GetCNtoSupplierCountQueryVariables>(GetCNtoSupplierCountDocument, baseOptions);
        }
export type GetCNtoSupplierCountQueryHookResult = ReturnType<typeof useGetCNtoSupplierCountQuery>;
export type GetCNtoSupplierCountLazyQueryHookResult = ReturnType<typeof useGetCNtoSupplierCountLazyQuery>;
export type GetCNtoSupplierCountQueryResult = ApolloReactCommon.QueryResult<GetCNtoSupplierCountQuery, GetCNtoSupplierCountQueryVariables>;
export const GetSupplierCompanyAssignmentDocument = gql`
    query getSupplierCompanyAssignment($supplierID: String, $companyID: String) {
  getSupplierCompanyAssignment(supplierID: $supplierID, companyID: $companyID) {
    ID
    supplierID
    supplier {
      ID
      name
      registrationNo
      email
    }
    companyID
  }
}
    `;

/**
 * __useGetSupplierCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetSupplierCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierCompanyAssignmentQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetSupplierCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierCompanyAssignmentQuery, GetSupplierCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierCompanyAssignmentQuery, GetSupplierCompanyAssignmentQueryVariables>(GetSupplierCompanyAssignmentDocument, baseOptions);
      }
export function useGetSupplierCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierCompanyAssignmentQuery, GetSupplierCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierCompanyAssignmentQuery, GetSupplierCompanyAssignmentQueryVariables>(GetSupplierCompanyAssignmentDocument, baseOptions);
        }
export type GetSupplierCompanyAssignmentQueryHookResult = ReturnType<typeof useGetSupplierCompanyAssignmentQuery>;
export type GetSupplierCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetSupplierCompanyAssignmentLazyQuery>;
export type GetSupplierCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetSupplierCompanyAssignmentQuery, GetSupplierCompanyAssignmentQueryVariables>;
export const AssignSupplierCompanyDocument = gql`
    mutation assignSupplierCompany($supplierID: String!, $companyID: [String!]!) {
  assignSupplierCompany(supplierID: $supplierID, companyID: $companyID)
}
    `;
export type AssignSupplierCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignSupplierCompanyMutation, AssignSupplierCompanyMutationVariables>;

/**
 * __useAssignSupplierCompanyMutation__
 *
 * To run a mutation, you first call `useAssignSupplierCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSupplierCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSupplierCompanyMutation, { data, loading, error }] = useAssignSupplierCompanyMutation({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useAssignSupplierCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignSupplierCompanyMutation, AssignSupplierCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignSupplierCompanyMutation, AssignSupplierCompanyMutationVariables>(AssignSupplierCompanyDocument, baseOptions);
      }
export type AssignSupplierCompanyMutationHookResult = ReturnType<typeof useAssignSupplierCompanyMutation>;
export type AssignSupplierCompanyMutationResult = ApolloReactCommon.MutationResult<AssignSupplierCompanyMutation>;
export type AssignSupplierCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignSupplierCompanyMutation, AssignSupplierCompanyMutationVariables>;
export const UnassignSupplierCompanyDocument = gql`
    mutation unassignSupplierCompany($supplierID: String!, $companyID: [String!]!) {
  unassignSupplierCompany(supplierID: $supplierID, companyID: $companyID)
}
    `;
export type UnassignSupplierCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignSupplierCompanyMutation, UnassignSupplierCompanyMutationVariables>;

/**
 * __useUnassignSupplierCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignSupplierCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignSupplierCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignSupplierCompanyMutation, { data, loading, error }] = useUnassignSupplierCompanyMutation({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useUnassignSupplierCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignSupplierCompanyMutation, UnassignSupplierCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignSupplierCompanyMutation, UnassignSupplierCompanyMutationVariables>(UnassignSupplierCompanyDocument, baseOptions);
      }
export type UnassignSupplierCompanyMutationHookResult = ReturnType<typeof useUnassignSupplierCompanyMutation>;
export type UnassignSupplierCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignSupplierCompanyMutation>;
export type UnassignSupplierCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignSupplierCompanyMutation, UnassignSupplierCompanyMutationVariables>;
export const GetSupplierLedgersDocument = gql`
    query getSupplierLedgers($supplierID: String, $contractID: String) {
  getSupplierLedgers(supplierID: $supplierID, contractID: $contractID)
}
    `;

/**
 * __useGetSupplierLedgersQuery__
 *
 * To run a query within a React component, call `useGetSupplierLedgersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierLedgersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierLedgersQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSupplierLedgersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierLedgersQuery, GetSupplierLedgersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierLedgersQuery, GetSupplierLedgersQueryVariables>(GetSupplierLedgersDocument, baseOptions);
      }
export function useGetSupplierLedgersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierLedgersQuery, GetSupplierLedgersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierLedgersQuery, GetSupplierLedgersQueryVariables>(GetSupplierLedgersDocument, baseOptions);
        }
export type GetSupplierLedgersQueryHookResult = ReturnType<typeof useGetSupplierLedgersQuery>;
export type GetSupplierLedgersLazyQueryHookResult = ReturnType<typeof useGetSupplierLedgersLazyQuery>;
export type GetSupplierLedgersQueryResult = ApolloReactCommon.QueryResult<GetSupplierLedgersQuery, GetSupplierLedgersQueryVariables>;
export const GetToDoListingDocument = gql`
    query getToDoListing($contractID: String, $customerID: String, $subcontractID: String, $subcontractorID: String, $supplierID: String) {
  getSubconProgressClaim(subcontractorID: $subcontractorID, subcontractID: $subcontractID) {
    ID
    isGlExport
    accountID
    subcontractClaimAllocation {
      ID
      previousCertifiedAmt
      submittedCertifiedAmt
      currentCertifiedAmt
      subcontractCostWbsID
    }
    subcontractClaimVOAllocation {
      ID
      previousVOClaimAmt
      currentVOClaimAmt
      submittedVOClaimAmt
      subcontractVOID
    }
    subcontractClaimVOCostWbsAllocation {
      ID
      previousVOClaimAmt
      submittedVOClaimAmt
      currentVOClaimAmt
      subcontractVOCostWbsID
    }
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    grossAmt
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    subcontractID
    subcontractorID
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  getProgressClaim(contractID: $contractID, customerID: $customerID) {
    ID
    accountID
    contractClaimAllocation {
      ID
      previousCertifiedAmt
      submittedCertifiedAmt
      currentCertifiedAmt
      contractClaimID
      wbsBudgetDetailID
      wbsID
      revenueCategoryID
    }
    contractClaimVOAllocation {
      ID
      contractVOID
      currentVOClaimAmt
      submittedVOClaimAmt
      previousVOClaimAmt
    }
    contractClaimVORevenueWbsAllocation {
      ID
      contractVORevenueWbs {
        ID
        wbsID
        revenueCategoryID
      }
      contractVORevenueWbsID
      currentVOClaimAmt
      submittedVOClaimAmt
      previousVOClaimAmt
    }
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationRemarks
    cancellationDate
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    customerID
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    grossAmt
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  getPurchaseReq(contractID: $contractID) {
    ID
    contractID
    subcontractID
    docNo
    prDate
    expectedDate
    instructions
    purchaseStatus
  }
  getPurchaseOrder(contractID: $contractID) {
    ID
    accountID
    contractID
    subcontractID
    docDate
    instructions
    totalAmt
    purchaseStatus
  }
  getSubcontractPayment(subcontractorID: $subcontractorID, subcontractID: $subcontractID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    bankAccountID
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
  }
  getAPPayment(supplierID: $supplierID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    bankAccountID
    bankDetails
    receiptStatus
    supplierID
    outstandingAmt
  }
  getContractReceipt(contractID: $contractID, customerID: $customerID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    accountID
    bankAccountID
    bankDetails
    receiptStatus
    customerID
    contractID
    outstandingAmt
  }
  getContractVO(contractID: $contractID) {
    ID
    accountID
    contractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    approvedAmt
    docDate
    remarks
  }
  getSubcontractVO(subcontractID: $subcontractID) {
    ID
    accountID
    subcontractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    approvedAmt
    remarks
    docDate
  }
}
    `;

/**
 * __useGetToDoListingQuery__
 *
 * To run a query within a React component, call `useGetToDoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToDoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToDoListingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetToDoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetToDoListingQuery, GetToDoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetToDoListingQuery, GetToDoListingQueryVariables>(GetToDoListingDocument, baseOptions);
      }
export function useGetToDoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetToDoListingQuery, GetToDoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetToDoListingQuery, GetToDoListingQueryVariables>(GetToDoListingDocument, baseOptions);
        }
export type GetToDoListingQueryHookResult = ReturnType<typeof useGetToDoListingQuery>;
export type GetToDoListingLazyQueryHookResult = ReturnType<typeof useGetToDoListingLazyQuery>;
export type GetToDoListingQueryResult = ApolloReactCommon.QueryResult<GetToDoListingQuery, GetToDoListingQueryVariables>;
export const CreateContractNewsDocument = gql`
    mutation createContractNews($input: ContractNewsInput!) {
  createContractNews(input: $input) {
    ID
  }
}
    `;
export type CreateContractNewsMutationFn = ApolloReactCommon.MutationFunction<CreateContractNewsMutation, CreateContractNewsMutationVariables>;

/**
 * __useCreateContractNewsMutation__
 *
 * To run a mutation, you first call `useCreateContractNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractNewsMutation, { data, loading, error }] = useCreateContractNewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractNewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractNewsMutation, CreateContractNewsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractNewsMutation, CreateContractNewsMutationVariables>(CreateContractNewsDocument, baseOptions);
      }
export type CreateContractNewsMutationHookResult = ReturnType<typeof useCreateContractNewsMutation>;
export type CreateContractNewsMutationResult = ApolloReactCommon.MutationResult<CreateContractNewsMutation>;
export type CreateContractNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractNewsMutation, CreateContractNewsMutationVariables>;
export const UpdateContractNewsDocument = gql`
    mutation updateContractNews($input: ContractNewsInput!) {
  updateContractNews(input: $input) {
    ID
  }
}
    `;
export type UpdateContractNewsMutationFn = ApolloReactCommon.MutationFunction<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>;

/**
 * __useUpdateContractNewsMutation__
 *
 * To run a mutation, you first call `useUpdateContractNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractNewsMutation, { data, loading, error }] = useUpdateContractNewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractNewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>(UpdateContractNewsDocument, baseOptions);
      }
export type UpdateContractNewsMutationHookResult = ReturnType<typeof useUpdateContractNewsMutation>;
export type UpdateContractNewsMutationResult = ApolloReactCommon.MutationResult<UpdateContractNewsMutation>;
export type UpdateContractNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>;
export const GetContractNewsDocument = gql`
    query getContractNews($ID: String, $orderByAsc: String, $orderByDesc: String) {
  getContractNews(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
    content
    startDate
    endDate
    postStatus
    createdTs
    createdBy
  }
}
    `;

/**
 * __useGetContractNewsQuery__
 *
 * To run a query within a React component, call `useGetContractNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractNewsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractNewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractNewsQuery, GetContractNewsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractNewsQuery, GetContractNewsQueryVariables>(GetContractNewsDocument, baseOptions);
      }
export function useGetContractNewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractNewsQuery, GetContractNewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractNewsQuery, GetContractNewsQueryVariables>(GetContractNewsDocument, baseOptions);
        }
export type GetContractNewsQueryHookResult = ReturnType<typeof useGetContractNewsQuery>;
export type GetContractNewsLazyQueryHookResult = ReturnType<typeof useGetContractNewsLazyQuery>;
export type GetContractNewsQueryResult = ApolloReactCommon.QueryResult<GetContractNewsQuery, GetContractNewsQueryVariables>;
export const GetContractNotificationDocument = gql`
    query getContractNotification($docRefTable: String, $recipientID: String, $orderByDesc: String) {
  getContractNotification(docRefTable: $docRefTable, recipientID: $recipientID, orderByDesc: $orderByDesc) {
    ID
    accountID
    docRefID
    docRefTable
    subject
    body
    createdTs
    notificationRecipient {
      isRead
    }
  }
}
    `;

/**
 * __useGetContractNotificationQuery__
 *
 * To run a query within a React component, call `useGetContractNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractNotificationQuery({
 *   variables: {
 *      docRefTable: // value for 'docRefTable'
 *      recipientID: // value for 'recipientID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractNotificationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractNotificationQuery, GetContractNotificationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractNotificationQuery, GetContractNotificationQueryVariables>(GetContractNotificationDocument, baseOptions);
      }
export function useGetContractNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractNotificationQuery, GetContractNotificationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractNotificationQuery, GetContractNotificationQueryVariables>(GetContractNotificationDocument, baseOptions);
        }
export type GetContractNotificationQueryHookResult = ReturnType<typeof useGetContractNotificationQuery>;
export type GetContractNotificationLazyQueryHookResult = ReturnType<typeof useGetContractNotificationLazyQuery>;
export type GetContractNotificationQueryResult = ApolloReactCommon.QueryResult<GetContractNotificationQuery, GetContractNotificationQueryVariables>;
export const GetContractToDoDocument = gql`
    query getContractToDo($docRefTable: String, $docRefID: String) {
  getContractToDo(docRefTable: $docRefTable, docRefID: $docRefID) {
    ID
    approvalLog {
      ID
      accountID
      toDoID
      approverID
      logDate
      notifiedDate
      toDoStatus
      remarks
    }
  }
}
    `;

/**
 * __useGetContractToDoQuery__
 *
 * To run a query within a React component, call `useGetContractToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractToDoQuery({
 *   variables: {
 *      docRefTable: // value for 'docRefTable'
 *      docRefID: // value for 'docRefID'
 *   },
 * });
 */
export function useGetContractToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractToDoQuery, GetContractToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractToDoQuery, GetContractToDoQueryVariables>(GetContractToDoDocument, baseOptions);
      }
export function useGetContractToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractToDoQuery, GetContractToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractToDoQuery, GetContractToDoQueryVariables>(GetContractToDoDocument, baseOptions);
        }
export type GetContractToDoQueryHookResult = ReturnType<typeof useGetContractToDoQuery>;
export type GetContractToDoLazyQueryHookResult = ReturnType<typeof useGetContractToDoLazyQuery>;
export type GetContractToDoQueryResult = ApolloReactCommon.QueryResult<GetContractToDoQuery, GetContractToDoQueryVariables>;
export const ToDoListingDocument = gql`
    query ToDoListing($approved: Boolean) {
  getToDoListing(approved: $approved)
}
    `;

/**
 * __useToDoListingQuery__
 *
 * To run a query within a React component, call `useToDoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useToDoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToDoListingQuery({
 *   variables: {
 *      approved: // value for 'approved'
 *   },
 * });
 */
export function useToDoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ToDoListingQuery, ToDoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ToDoListingQuery, ToDoListingQueryVariables>(ToDoListingDocument, baseOptions);
      }
export function useToDoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ToDoListingQuery, ToDoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ToDoListingQuery, ToDoListingQueryVariables>(ToDoListingDocument, baseOptions);
        }
export type ToDoListingQueryHookResult = ReturnType<typeof useToDoListingQuery>;
export type ToDoListingLazyQueryHookResult = ReturnType<typeof useToDoListingLazyQuery>;
export type ToDoListingQueryResult = ApolloReactCommon.QueryResult<ToDoListingQuery, ToDoListingQueryVariables>;
export const ReadNotificationDocument = gql`
    mutation readNotification($ID: String) {
  readNotification(ID: $ID)
}
    `;
export type ReadNotificationMutationFn = ApolloReactCommon.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, baseOptions);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = ApolloReactCommon.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const ApproveToDoDocument = gql`
    mutation approveToDo($IDs: [String!]!) {
  approveToDo(IDs: $IDs)
}
    `;
export type ApproveToDoMutationFn = ApolloReactCommon.MutationFunction<ApproveToDoMutation, ApproveToDoMutationVariables>;

/**
 * __useApproveToDoMutation__
 *
 * To run a mutation, you first call `useApproveToDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveToDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveToDoMutation, { data, loading, error }] = useApproveToDoMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useApproveToDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveToDoMutation, ApproveToDoMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveToDoMutation, ApproveToDoMutationVariables>(ApproveToDoDocument, baseOptions);
      }
export type ApproveToDoMutationHookResult = ReturnType<typeof useApproveToDoMutation>;
export type ApproveToDoMutationResult = ApolloReactCommon.MutationResult<ApproveToDoMutation>;
export type ApproveToDoMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveToDoMutation, ApproveToDoMutationVariables>;
export const RejectToDoDocument = gql`
    mutation rejectToDo($IDs: [String!]!, $remark: String!) {
  rejectToDo(IDs: $IDs, remark: $remark)
}
    `;
export type RejectToDoMutationFn = ApolloReactCommon.MutationFunction<RejectToDoMutation, RejectToDoMutationVariables>;

/**
 * __useRejectToDoMutation__
 *
 * To run a mutation, you first call `useRejectToDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectToDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectToDoMutation, { data, loading, error }] = useRejectToDoMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *      remark: // value for 'remark'
 *   },
 * });
 */
export function useRejectToDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectToDoMutation, RejectToDoMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectToDoMutation, RejectToDoMutationVariables>(RejectToDoDocument, baseOptions);
      }
export type RejectToDoMutationHookResult = ReturnType<typeof useRejectToDoMutation>;
export type RejectToDoMutationResult = ApolloReactCommon.MutationResult<RejectToDoMutation>;
export type RejectToDoMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectToDoMutation, RejectToDoMutationVariables>;
export const GetNotificationListingDocument = gql`
    query getNotificationListing {
  getNotificationListing
}
    `;

/**
 * __useGetNotificationListingQuery__
 *
 * To run a query within a React component, call `useGetNotificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationListingQuery, GetNotificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationListingQuery, GetNotificationListingQueryVariables>(GetNotificationListingDocument, baseOptions);
      }
export function useGetNotificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationListingQuery, GetNotificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationListingQuery, GetNotificationListingQueryVariables>(GetNotificationListingDocument, baseOptions);
        }
export type GetNotificationListingQueryHookResult = ReturnType<typeof useGetNotificationListingQuery>;
export type GetNotificationListingLazyQueryHookResult = ReturnType<typeof useGetNotificationListingLazyQuery>;
export type GetNotificationListingQueryResult = ApolloReactCommon.QueryResult<GetNotificationListingQuery, GetNotificationListingQueryVariables>;
export const GetToDoWorkflowSummaryDocument = gql`
    query getToDoWorkflowSummary($length: Boolean) {
  getToDoWorkflowSummary(length: $length)
}
    `;

/**
 * __useGetToDoWorkflowSummaryQuery__
 *
 * To run a query within a React component, call `useGetToDoWorkflowSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToDoWorkflowSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToDoWorkflowSummaryQuery({
 *   variables: {
 *      length: // value for 'length'
 *   },
 * });
 */
export function useGetToDoWorkflowSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetToDoWorkflowSummaryQuery, GetToDoWorkflowSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetToDoWorkflowSummaryQuery, GetToDoWorkflowSummaryQueryVariables>(GetToDoWorkflowSummaryDocument, baseOptions);
      }
export function useGetToDoWorkflowSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetToDoWorkflowSummaryQuery, GetToDoWorkflowSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetToDoWorkflowSummaryQuery, GetToDoWorkflowSummaryQueryVariables>(GetToDoWorkflowSummaryDocument, baseOptions);
        }
export type GetToDoWorkflowSummaryQueryHookResult = ReturnType<typeof useGetToDoWorkflowSummaryQuery>;
export type GetToDoWorkflowSummaryLazyQueryHookResult = ReturnType<typeof useGetToDoWorkflowSummaryLazyQuery>;
export type GetToDoWorkflowSummaryQueryResult = ApolloReactCommon.QueryResult<GetToDoWorkflowSummaryQuery, GetToDoWorkflowSummaryQueryVariables>;
export const GetToDoAllPendingDocsDocument = gql`
    query getToDoAllPendingDocs($category: String!) {
  getToDoAllPendingDocs(category: $category)
}
    `;

/**
 * __useGetToDoAllPendingDocsQuery__
 *
 * To run a query within a React component, call `useGetToDoAllPendingDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToDoAllPendingDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToDoAllPendingDocsQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetToDoAllPendingDocsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetToDoAllPendingDocsQuery, GetToDoAllPendingDocsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetToDoAllPendingDocsQuery, GetToDoAllPendingDocsQueryVariables>(GetToDoAllPendingDocsDocument, baseOptions);
      }
export function useGetToDoAllPendingDocsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetToDoAllPendingDocsQuery, GetToDoAllPendingDocsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetToDoAllPendingDocsQuery, GetToDoAllPendingDocsQueryVariables>(GetToDoAllPendingDocsDocument, baseOptions);
        }
export type GetToDoAllPendingDocsQueryHookResult = ReturnType<typeof useGetToDoAllPendingDocsQuery>;
export type GetToDoAllPendingDocsLazyQueryHookResult = ReturnType<typeof useGetToDoAllPendingDocsLazyQuery>;
export type GetToDoAllPendingDocsQueryResult = ApolloReactCommon.QueryResult<GetToDoAllPendingDocsQuery, GetToDoAllPendingDocsQueryVariables>;
export const ApproveRejectWorkDeskDocument = gql`
    mutation approveRejectWorkDesk($workDeskInput: [WorkDeskInput!]!) {
  approveRejectWorkDesk(workDeskInput: $workDeskInput)
}
    `;
export type ApproveRejectWorkDeskMutationFn = ApolloReactCommon.MutationFunction<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>;

/**
 * __useApproveRejectWorkDeskMutation__
 *
 * To run a mutation, you first call `useApproveRejectWorkDeskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveRejectWorkDeskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveRejectWorkDeskMutation, { data, loading, error }] = useApproveRejectWorkDeskMutation({
 *   variables: {
 *      workDeskInput: // value for 'workDeskInput'
 *   },
 * });
 */
export function useApproveRejectWorkDeskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>(ApproveRejectWorkDeskDocument, baseOptions);
      }
export type ApproveRejectWorkDeskMutationHookResult = ReturnType<typeof useApproveRejectWorkDeskMutation>;
export type ApproveRejectWorkDeskMutationResult = ApolloReactCommon.MutationResult<ApproveRejectWorkDeskMutation>;
export type ApproveRejectWorkDeskMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>;
export const PolicyCheckingDocument = gql`
    query policyChecking($contractID: String!, $category: String!) {
  policyChecking(contractID: $contractID, category: $category)
}
    `;

/**
 * __usePolicyCheckingQuery__
 *
 * To run a query within a React component, call `usePolicyCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyCheckingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      category: // value for 'category'
 *   },
 * });
 */
export function usePolicyCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PolicyCheckingQuery, PolicyCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<PolicyCheckingQuery, PolicyCheckingQueryVariables>(PolicyCheckingDocument, baseOptions);
      }
export function usePolicyCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PolicyCheckingQuery, PolicyCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PolicyCheckingQuery, PolicyCheckingQueryVariables>(PolicyCheckingDocument, baseOptions);
        }
export type PolicyCheckingQueryHookResult = ReturnType<typeof usePolicyCheckingQuery>;
export type PolicyCheckingLazyQueryHookResult = ReturnType<typeof usePolicyCheckingLazyQuery>;
export type PolicyCheckingQueryResult = ApolloReactCommon.QueryResult<PolicyCheckingQuery, PolicyCheckingQueryVariables>;
export const PolicyCheckerApPaymentDocument = gql`
    query policyCheckerAPPayment($category: String!) {
  policyCheckerAPPayment(category: $category)
}
    `;

/**
 * __usePolicyCheckerApPaymentQuery__
 *
 * To run a query within a React component, call `usePolicyCheckerApPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyCheckerApPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyCheckerApPaymentQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function usePolicyCheckerApPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PolicyCheckerApPaymentQuery, PolicyCheckerApPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<PolicyCheckerApPaymentQuery, PolicyCheckerApPaymentQueryVariables>(PolicyCheckerApPaymentDocument, baseOptions);
      }
export function usePolicyCheckerApPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PolicyCheckerApPaymentQuery, PolicyCheckerApPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PolicyCheckerApPaymentQuery, PolicyCheckerApPaymentQueryVariables>(PolicyCheckerApPaymentDocument, baseOptions);
        }
export type PolicyCheckerApPaymentQueryHookResult = ReturnType<typeof usePolicyCheckerApPaymentQuery>;
export type PolicyCheckerApPaymentLazyQueryHookResult = ReturnType<typeof usePolicyCheckerApPaymentLazyQuery>;
export type PolicyCheckerApPaymentQueryResult = ApolloReactCommon.QueryResult<PolicyCheckerApPaymentQuery, PolicyCheckerApPaymentQueryVariables>;
export const GetNotificationWorkflowListingDocument = gql`
    query getNotificationWorkflowListing($unread: Boolean, $length: Boolean) {
  getNotificationWorkflowListing(unread: $unread, length: $length)
}
    `;

/**
 * __useGetNotificationWorkflowListingQuery__
 *
 * To run a query within a React component, call `useGetNotificationWorkflowListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationWorkflowListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationWorkflowListingQuery({
 *   variables: {
 *      unread: // value for 'unread'
 *      length: // value for 'length'
 *   },
 * });
 */
export function useGetNotificationWorkflowListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationWorkflowListingQuery, GetNotificationWorkflowListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationWorkflowListingQuery, GetNotificationWorkflowListingQueryVariables>(GetNotificationWorkflowListingDocument, baseOptions);
      }
export function useGetNotificationWorkflowListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationWorkflowListingQuery, GetNotificationWorkflowListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationWorkflowListingQuery, GetNotificationWorkflowListingQueryVariables>(GetNotificationWorkflowListingDocument, baseOptions);
        }
export type GetNotificationWorkflowListingQueryHookResult = ReturnType<typeof useGetNotificationWorkflowListingQuery>;
export type GetNotificationWorkflowListingLazyQueryHookResult = ReturnType<typeof useGetNotificationWorkflowListingLazyQuery>;
export type GetNotificationWorkflowListingQueryResult = ApolloReactCommon.QueryResult<GetNotificationWorkflowListingQuery, GetNotificationWorkflowListingQueryVariables>;
export const GetApprovalLogDocument = gql`
    query getApprovalLog($refID: String!) {
  getApprovalLog(refID: $refID)
}
    `;

/**
 * __useGetApprovalLogQuery__
 *
 * To run a query within a React component, call `useGetApprovalLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalLogQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *   },
 * });
 */
export function useGetApprovalLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalLogQuery, GetApprovalLogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalLogQuery, GetApprovalLogQueryVariables>(GetApprovalLogDocument, baseOptions);
      }
export function useGetApprovalLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalLogQuery, GetApprovalLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalLogQuery, GetApprovalLogQueryVariables>(GetApprovalLogDocument, baseOptions);
        }
export type GetApprovalLogQueryHookResult = ReturnType<typeof useGetApprovalLogQuery>;
export type GetApprovalLogLazyQueryHookResult = ReturnType<typeof useGetApprovalLogLazyQuery>;
export type GetApprovalLogQueryResult = ApolloReactCommon.QueryResult<GetApprovalLogQuery, GetApprovalLogQueryVariables>;
export const ApprovableLedgerWfCheckingDocument = gql`
    query approvableLedgerWFChecking($input: CheckingInput!) {
  approvableLedgerWFChecking(input: $input)
}
    `;

/**
 * __useApprovableLedgerWfCheckingQuery__
 *
 * To run a query within a React component, call `useApprovableLedgerWfCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovableLedgerWfCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovableLedgerWfCheckingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApprovableLedgerWfCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>(ApprovableLedgerWfCheckingDocument, baseOptions);
      }
export function useApprovableLedgerWfCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>(ApprovableLedgerWfCheckingDocument, baseOptions);
        }
export type ApprovableLedgerWfCheckingQueryHookResult = ReturnType<typeof useApprovableLedgerWfCheckingQuery>;
export type ApprovableLedgerWfCheckingLazyQueryHookResult = ReturnType<typeof useApprovableLedgerWfCheckingLazyQuery>;
export type ApprovableLedgerWfCheckingQueryResult = ApolloReactCommon.QueryResult<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>;
export const GetUserDocument = gql`
    query getUser($ID: String!) {
  getUser(ID: $ID) {
    ID
    name
    department
    email
    jobTitle
    contactNo
    superUser
    commonStatus
    userName
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const MarkAsReadNotiDocument = gql`
    mutation markAsReadNoti($IDs: [String!]!) {
  markAsReadNoti(IDs: $IDs)
}
    `;
export type MarkAsReadNotiMutationFn = ApolloReactCommon.MutationFunction<MarkAsReadNotiMutation, MarkAsReadNotiMutationVariables>;

/**
 * __useMarkAsReadNotiMutation__
 *
 * To run a mutation, you first call `useMarkAsReadNotiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadNotiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadNotiMutation, { data, loading, error }] = useMarkAsReadNotiMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useMarkAsReadNotiMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkAsReadNotiMutation, MarkAsReadNotiMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkAsReadNotiMutation, MarkAsReadNotiMutationVariables>(MarkAsReadNotiDocument, baseOptions);
      }
export type MarkAsReadNotiMutationHookResult = ReturnType<typeof useMarkAsReadNotiMutation>;
export type MarkAsReadNotiMutationResult = ApolloReactCommon.MutationResult<MarkAsReadNotiMutation>;
export type MarkAsReadNotiMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkAsReadNotiMutation, MarkAsReadNotiMutationVariables>;
export const DeleteNotiDocument = gql`
    mutation deleteNoti($IDs: [String!]!) {
  deleteNoti(IDs: $IDs)
}
    `;
export type DeleteNotiMutationFn = ApolloReactCommon.MutationFunction<DeleteNotiMutation, DeleteNotiMutationVariables>;

/**
 * __useDeleteNotiMutation__
 *
 * To run a mutation, you first call `useDeleteNotiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotiMutation, { data, loading, error }] = useDeleteNotiMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useDeleteNotiMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNotiMutation, DeleteNotiMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNotiMutation, DeleteNotiMutationVariables>(DeleteNotiDocument, baseOptions);
      }
export type DeleteNotiMutationHookResult = ReturnType<typeof useDeleteNotiMutation>;
export type DeleteNotiMutationResult = ApolloReactCommon.MutationResult<DeleteNotiMutation>;
export type DeleteNotiMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNotiMutation, DeleteNotiMutationVariables>;